import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { sumBy } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import { type BulkUpdateTasksSchema } from '@motion/zod/client'

import { useI18N } from '~/global/contexts'
import { useBulkUpdateTasks as useBulkUpdateTasksCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useBulkUpdateTasks = () => {
  const { pluralize } = useI18N()
  const { mutateAsync: bulkUpdate } = useBulkUpdateTasksCall()

  return useCallback(
    async (data: BulkUpdateTasksSchema['bulkUpdateTasksInWorkspaces']) => {
      try {
        await bulkUpdate({ bulkUpdateTasksInWorkspaces: data })

        const count = sumBy(data, (d) => d.taskIds.length)

        showToast(
          'neutral',
          templateStr('Updating {{item}}...', {
            item: pluralize(count, '{{count}} task', '{{count}} tasks'),
          }),
          {
            duration: 2000,
          }
        )
      } catch (e) {
        Sentry.captureException(e)
        showErrorToast(e)
      }

      return undefined
    },
    [bulkUpdate, pluralize]
  )
}
