import { DatePicker } from '@motion/ui/base'
import { type RangeDateFilterSchema } from '@motion/zod/client'

import { type DatePickerContentProps } from './types'

export function RangeDatePicker(
  props: DatePickerContentProps<RangeDateFilterSchema>
) {
  const { value, onSelect } = props
  return (
    <DatePicker
      weekStartDay={props.calendarStartDay}
      value={[value.value.from, value.value.to]}
      onChange={(val) => {
        if (Array.isArray(val)) {
          onSelect({ ...value, value: { from: val[0], to: val[1] } })
        }
      }}
      mode='range'
    />
  )
}
