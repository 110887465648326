import { type StageSchema } from '@motion/rpc-types'
import { Button, Tooltip } from '@motion/ui/base'
import { getEtaLabel, normalizeStageDeadlineStatus } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { type BadgeSize, EtaBadge } from '~/global/components/badges'

import { BadgeTooltip } from './badge-tooltip'

import { useExtendedNoneEta } from '../../hooks'
import { StageEtaPopover } from '../popover'

type ConnectedStageEtaBadgeProps = {
  stage: StageSchema
  size?: BadgeSize
  hideTooltip?: boolean
}

export const ConnectedStageEtaBadge = ({
  stage,
  size = 'small',
  hideTooltip = false,
}: ConnectedStageEtaBadgeProps) => {
  const hasPastDueM2 = useHasTreatment('past-due-m2')
  const extendedDeadlineStatus = useExtendedNoneEta({
    entity: stage,
    type: 'stage',
    options: {
      normalizeToAtRisk: true,
    },
  })

  if (!hasPastDueM2) return null

  const deadlineStatus = normalizeStageDeadlineStatus(stage, {
    normalizeToAtRisk: false,
  })

  return (
    <StageEtaPopover entity={stage}>
      <Tooltip
        renderContent={() =>
          hideTooltip ? undefined : <BadgeTooltip entity={stage} type='stage' />
        }
      >
        <Button
          aria-label={getEtaLabel(extendedDeadlineStatus, 'stage')}
          sentiment='neutral'
          variant='muted'
          disabled={deadlineStatus === 'none'}
          size='xsmall'
          iconOnly
        >
          <EtaBadge value={extendedDeadlineStatus} size={size} />
        </Button>
      </Tooltip>
    </StageEtaPopover>
  )
}
