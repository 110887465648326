import { classed } from '@motion/theme'
import { parseCustomFieldInfoFromMaybeDelimitedKey } from '@motion/ui-logic'

import { type BadgeSize } from '~/global/components/badges'
import {
  getLabelComponent,
  type TaskFieldLabelProps,
} from '~/global/components/labels'
import { type FC } from 'react'

import { TabAddItem } from './tab-add-item'

import { type GroupedNode, isDateGroup, type Tree } from '../../grouping'
import { DateGroupCurrentTag } from '../date-group-current-tag'

export type TabHeaderProps<T extends GroupedNode> = {
  item: Tree<T>
  active: boolean
  size?: BadgeSize
  shouldShowAdd?: boolean
  shouldShowCountType?: boolean
  clickableLabel?: boolean
}

export const TabHeader = <T extends GroupedNode>({
  clickableLabel = true,
  ...props
}: TabHeaderProps<T>) => {
  const node = props.item.item

  if (node == null) {
    return <div>{props.item.key}</div>
  }

  const type = getType(node.value.type)
  const Label = getLabelComponent(type) as FC<TaskFieldLabelProps<any>>

  return (
    <Container>
      <TextContainer active={props.active}>
        <Label
          value={node.value.value}
          size={props.size}
          keyProp={node.value.key}
          // @ts-expect-error - If type is `project`, then we need to pass this prop down
          clickable={clickableLabel}
          type={type === 'number' ? node.value.type : undefined}
        />
        <Count value={node.count} />
      </TextContainer>
      <div className='flex items-center gap-2'>
        {isDateGroup(node.value) && <DateGroupCurrentTag item={node.value} />}
        {props.shouldShowAdd && <TabAddItem item={props.item} />}
      </div>
    </Container>
  )
}
type CountProps = {
  value: number
  unit?: string
  size?: BadgeSize
}
const Count = (props: CountProps) => {
  const text = props.unit ? `${props.value} ${props.unit}` : String(props.value)

  return (
    <span className='text-semantic-neutral-text-subtle text-sm leading-4 uppercase font-normal'>
      {text}
    </span>
  )
}

const Container = classed('div', {
  base: 'flex justify-between items-center truncate gap-2',
})

const TextContainer = classed('div', {
  base: `flex gap-2 items-center min-w-0 truncate`,
  variants: {
    active: {
      true: 'text-semantic-neutral-text-default',
      false: 'text-semantic-neutral-text-subtle',
    },
  },
})

function getType(type: string) {
  const maybeCustomField = parseCustomFieldInfoFromMaybeDelimitedKey(type)
  if (maybeCustomField != null) {
    return maybeCustomField.customFieldType
  }

  return type
}
