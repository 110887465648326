import { useAppDispatch, useAppSelector } from '../../state/hooks'
import {
  rejectPrompt,
  resolvePrompt,
  selectPrompt,
} from '../../state/modalsSlice'
import { PhoneNumberModal } from '../Modals/PhoneNumberModal/PhoneNumberModal'

const type = 'phoneNumber'
export const PhoneNumberModalHandler = () => {
  const dispatch = useAppDispatch()
  const prompt = useAppSelector((state) => selectPrompt(state, type))

  const handleConfirmation = (value: string) => {
    dispatch(resolvePrompt({ result: value, type }))
  }

  const handleReject = (visible: boolean) => {
    if (!visible) {
      dispatch(rejectPrompt(type))
    }
  }

  if (!prompt?.visible) return null
  return (
    <PhoneNumberModal
      visibleHandler={handleReject}
      // current is required for backwards compatibility with Calendar.js
      onConfirmRef={{ current: handleConfirmation }}
    />
  )
}
