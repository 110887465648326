import { type Group } from '@motion/ui/base'
import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { type FilterTarget } from '@motion/ui-logic/pm/data'
import { groupInto } from '@motion/utils/array'
import { type IdFilterSchema } from '@motion/zod/client'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'
import { type ReactNode, useMemo } from 'react'

import { type AllowedFields, type ExtendedMatchingFieldOption } from './types'

import { GroupedItemDropdown } from '../../grouped-item-dropdown'
import { getNarrowedFilter } from '../utils'

type Props = {
  close(): void

  customField: CustomFieldSchemaByType<AllowedFields>
  getAllItems: () => ExtendedMatchingFieldOption[]

  placeholder: string
  renderItem(item: ExtendedMatchingFieldOption): NonNullable<ReactNode>
  renderHeader(item: Group<ExtendedMatchingFieldOption>): NonNullable<ReactNode>

  target: FilterTarget
}

export const ConnectedGroupedFieldDropdown = (props: Props) => {
  const { customField, getAllItems } = props
  const [filters, setFilters] = useCustomFieldFilter(props.target, customField)
  const filter = getNarrowedFilter(filters)

  const items = useMemo(() => {
    const items = getAllItems()
    const groups = groupInto(items, (p) => p.value).map((g) => ({
      key: g.key,
      items: g.items,
    }))

    return {
      all: items,
      groups,
    }
  }, [getAllItems])

  function getSelected() {
    return filters == null
      ? []
      : (Object.values(filters)
          .flatMap(({ value }) =>
            value.map((id) => items.all.find((u) => u.id === id))
          )
          .filter(Boolean) ?? [])
  }

  const selected = getSelected()

  const onSelect = (values: ExtendedMatchingFieldOption[] | null) => {
    if (values == null || values.length === 0) {
      return setFilters(null)
    }

    const newFilterValue = values.reduce<Record<string, IdFilterSchema>>(
      (acc, value) => {
        const operator = 'in' as const

        acc[value.customFieldId] ??= {
          operator,
          value: [],
          inverse: filter?.inverse ?? false,
        } satisfies IdFilterSchema

        acc[value.customFieldId] = {
          ...acc[value.customFieldId],
          value: [...acc[value.customFieldId].value, value.id],
        }

        return acc
      },
      {}
    )

    return setFilters(newFilterValue)
  }

  return (
    <GroupedItemDropdown
      items={items}
      selected={selected}
      onSelect={onSelect}
      close={props.close}
      placeholder={props.placeholder}
      renderItem={props.renderItem}
      renderHeader={props.renderHeader}
    />
  )
}
