import { AdjustmentsSolid, PlusSolid } from '@motion/icons'
import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'

import { type FilterDefinition } from './types'

type AddFilterButtonProps = {
  definitions: FilterDefinition[]
  onSelect(def: FilterDefinition): void
  variant: 'initial' | 'additional'
}

export const AddFilterButton = (props: AddFilterButtonProps) => {
  const { definitions, onSelect } = props

  if (definitions.length === 0) return null

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          items={definitions}
          renderItem={(item) => item.label}
          computeKey={(item) => item.key}
          computeSelected={() => false}
          itemType='select'
          onSelect={(item) => {
            onSelect(item)
            close()
          }}
          searchable
        />
      )}
    >
      <div className='flex'>
        {props.variant === 'additional' ? (
          <AddAdditionalFilterButton />
        ) : (
          <AddFirstFilterButton />
        )}
      </div>
    </PopoverTrigger>
  )
}
const AddFirstFilterButton = () => {
  return (
    <Button sentiment='neutral' size='small'>
      <AdjustmentsSolid />
      Filters
    </Button>
  )
}

const AddAdditionalFilterButton = () => {
  return (
    <Button sentiment='neutral' size='small' variant='muted'>
      <PlusSolid />
      Filters
    </Button>
  )
}
