import {
  useSharedStateContext,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useDeleteView as useDeleteViewRpc } from '~/global/rpc/v2/views'
import { useCallback } from 'react'

import { useSelectView } from './use-select-view'

import { DEFAULT_VIEW, TeamScheduleActiveViewOptionsKey } from '../../context'
import {
  createEntityCache,
  TeamSchedulePageContextKey,
} from '../../context/page-data-context'
import { type TeamScheduleView } from '../../types'

export const useDeleteView = () => {
  const setPageContext = useSharedStateSendOnly(TeamSchedulePageContextKey)
  const sharedStateApi = useSharedStateContext()

  const deleteView = useDeleteViewRpc()
  const selectView = useSelectView()
  const modalApi = useModalApi()

  return useCallback(
    async (view: TeamScheduleView) => {
      const result = await modalApi.prompt('confirm', {
        analytics: {
          name: 'delete-saved-view',
        },
        confirmButtonText: 'Delete',
        destructive: true,
        description: `Are you sure you want to delete the '${view.name}' view?`,
        title: 'Delete View?',
      })

      if (result === ModalDismissed || result === false) return
      await deleteView.mutate({ viewId: view.id })

      setPageContext((prev) => {
        return {
          ...prev,
          views: createEntityCache(
            prev.views.all.filter((x) => x.id !== view.id)
          ),
        }
      })

      if (
        sharedStateApi.get(TeamScheduleActiveViewOptionsKey).viewId === view.id
      ) {
        selectView(DEFAULT_VIEW.id)
      }
    },
    [sharedStateApi, deleteView, modalApi, selectView, setPageContext]
  )
}
