import { BanSolid, DotSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import {
  formatToReadableWeekDayMonth,
  getTaskScheduledDateString,
  templateStr,
} from '@motion/ui-logic'

import { PastDueBlockerChainTooltip } from '~/areas/tasks/components'
import { useI18N } from '~/global/contexts'
import { type TaskWithRelations } from '~/global/proxies'

export const ScheduleReason = ({ task }: { task: TaskWithRelations }) => {
  const taskScheduledDate = getTaskScheduledDateString(task)
  const { pluralize } = useI18N()

  return (
    <Reason>
      {templateStr('{{scheduledDate}} {{dueDate}}', {
        scheduledDate: taskScheduledDate,
        dueDate: task?.dueDate
          ? templateStr('(due {{date}})', {
              date: formatToReadableWeekDayMonth(task.dueDate),
            })
          : '',
      })}

      {task.type === 'NORMAL' && task.blockedByTasks.length > 0 && (
        <>
          <DotSolid
            height={2}
            width={2}
            className='text-semantic-neutral-icon-default'
          />
          <Tooltip
            renderContent={() => <PastDueBlockerChainTooltip task={task} />}
            asChild
          >
            <BlockersSection>
              <BanSolid
                height={11}
                width={11}
                className='text-semantic-neutral-icon-default'
              />
              <span>
                {templateStr('{{count}} {{blockerText}}', {
                  count: task.blockedByTasks.length,
                  blockerText: pluralize(
                    task.blockedByTasks.length,
                    'blocker',
                    'blockers'
                  ),
                })}
              </span>
            </BlockersSection>
          </Tooltip>
        </>
      )}
    </Reason>
  )
}

const Reason = classed('div', {
  base: `text-semantic-neutral-text-subtle text-xs flex items-center gap-1.5`,
})

const BlockersSection = classed('div', {
  base: `flex items-center gap-1`,
})
