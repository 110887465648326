import { Tooltip, type TooltipProps } from '@motion/ui/base'
import { formatTimeRange, templateStr } from '@motion/ui-logic'
import { type TaskSchema } from '@motion/zod/client'

import { ProjectLabel } from '~/global/components/labels'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { DateTime } from 'luxon'
import { type ReactNode } from 'react'

interface TaskNameTooltipProps {
  task: Partial<
    Pick<
      TaskSchema,
      'scheduledStart' | 'workspaceId' | 'projectId' | 'scheduledEnd' | 'name'
    >
  >
  children: ReactNode
  asChild?: TooltipProps['asChild']
}

export function TaskNameTooltip({
  task,
  asChild,
  children,
}: TaskNameTooltipProps) {
  return (
    <Tooltip
      asChild={asChild}
      renderContent={() => <TaskNameTooltipContent task={task} />}
    >
      {children}
    </Tooltip>
  )
}

export interface TaskNameTooltipContentProps {
  task: TaskNameTooltipProps['task']
}

export function TaskNameTooltipContent({ task }: TaskNameTooltipContentProps) {
  const workspace = useWorkspaceById(task.workspaceId)
  const project = useProject(task.projectId)

  if (workspace == null) return null

  return (
    <div className='text-left flex flex-col gap-2'>
      <div className='text-semantic-neutral-icon-subtle text-xs truncate flex gap-1'>
        {templateStr('{{workspaceName}} / {{project}}', {
          workspaceName: workspace.name,
          project: <ProjectLabel value={project} />,
        })}
      </div>
      <div>{task.name}</div>
      <div className='text-xs'>
        {task.scheduledStart &&
          task.scheduledEnd &&
          formatTimeRange(
            DateTime.fromISO(task.scheduledStart),
            DateTime.fromISO(task.scheduledEnd)
          )}
      </div>
    </div>
  )
}
