import { getPrioritiesForTask } from '@motion/ui-logic'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskPriorityUpdater } from '../updaters'

export function useTaskPriorityData(task: TaskSchema) {
  const updater = useTaskPriorityUpdater()
  const priorities = getPrioritiesForTask(task)

  const isMeeting = isMeetingTask(task)

  const hasValidData = !isMeeting

  const disabled =
    task.type === 'RECURRING_INSTANCE' || task.archivedTime != null || isMeeting

  const onChange = useCallback(
    (priority: TaskSchema['priorityLevel']) => {
      void updater(task, priority)
    },
    [task, updater]
  )

  return {
    onChange,
    priorities,
    disabled,
    hasValidData,
  }
}
