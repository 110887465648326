import { DotsHorizontalSolid, TrashSolid } from '@motion/icons'
import { ActionDropdown, IconButton } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import {
  useDeleteProjectTaskTemplate,
  useDeleteTaskTemplate,
} from '../../../../global/rpc'
import { useTemplateTaskForm } from '../hooks'

export const TemplateTaskDotsDropdown = () => {
  const { options, actions } = useTemplateTaskForm()

  const { savedTemplateProject, savedTemplateTask } = options
  const { closeModal } = actions

  const modalApi = useModalApi()
  const { mutateAsync: deleteTemplateTask } = useDeleteTaskTemplate()
  const { mutateAsync: deleteTemplateProjectTask } =
    useDeleteProjectTaskTemplate()

  if (!savedTemplateTask) return null

  const handleDeleteTask = async () => {
    if (!savedTemplateTask) return

    const response = await modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-template-task',
      },
      destructive: true,
      title: 'Are you sure you want to delete this template task?',
      description:
        "This action is permanent and you won't be able to recover the template task.",
      confirmButtonText: 'Delete template task',
    })

    if (response === ModalDismissed) {
      return
    }
    if (savedTemplateProject) {
      await deleteTemplateProjectTask({
        taskId: savedTemplateTask.task.id ?? '',
        workspaceId: savedTemplateTask?.workspaceId,
      })
    } else {
      await deleteTemplateTask({
        id: savedTemplateTask.id,
        workspaceId: savedTemplateTask?.workspaceId,
      })
    }

    await closeModal()
  }

  return (
    <ActionDropdown
      items={[
        {
          prefix: <TrashSolid />,
          content: 'Delete',
          destructive: true,
          onAction: handleDeleteTask,
        },
      ]}
    >
      <IconButton
        size='small'
        variant='muted'
        sentiment='neutral'
        icon={DotsHorizontalSolid}
        aria-label='Template task options'
      />
    </ActionDropdown>
  )
}
