import { FieldLabel } from '@motion/ui/forms'

import { Combobox } from '~/components/Common'
import { daysSpanChoices, startsInChoices } from '~/storageConstants'
import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const AvailabilityDateRangeRow = () => {
  const {
    template: {
      state: { daysSpan, startsIn },
      setters: { setDaysSpan, setStartsIn },
    },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow
      title='Date range'
      subtitle='Choose how far ahead guests can book meetings with you'
    >
      <div className='space-y-4'>
        <div>
          <FieldLabel label='Show availabilities for the next'>
            <Combobox
              className='max-w-[250px]'
              autoComplete
              value={daysSpan}
              onChange={setDaysSpan}
              options={daysSpanChoices.map((time) => ({
                label: time,
                value: time,
              }))}
            />
          </FieldLabel>
        </div>

        <div>
          <FieldLabel label='Only show availabilities starting'>
            <Combobox
              className='max-w-[250px]'
              autoComplete
              value={startsIn}
              onChange={setStartsIn}
              options={startsInChoices.map((time) => ({
                label: time,
                value: time,
              }))}
            />
          </FieldLabel>
        </div>
      </div>
    </SectionRow>
  )
}
