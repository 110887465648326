import { getCacheEntryValue } from '@motion/rpc-cache'
import {
  ActiveFilterKey,
  type EntityFilterState,
  getDefaultFilterState,
} from '@motion/ui-logic/pm/data'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { isEqual } from '@motion/utils/core'
import { type ExperimentValues } from '@motion/web-common/flags'
import { client } from '@motion/web-common/rpc'
import { DB, readPersistedStateMany } from '@motion/web-common/storage'

import { type ViewState, ViewStateKey } from '../../view-state'
import { DEFAULT_VIEW_STATE } from '../../view-state/serialization'
import { fromViewDefinition, getDefaultView } from '../../views'
import { type PageType } from '../types'

const PRELOAD_KEYS = [ActiveFilterKey, ViewStateKey]

export const loadState = async (
  prefix: string,
  args: { featureFlags: ExperimentValues }
) => {
  await DB.open()
  const state = await readPersistedStateMany(DB.state, PRELOAD_KEYS, { prefix })
  if (prefix.endsWith('_archived')) {
    return state
  }
  const pageType = getPageTypeFromPrefix(prefix)
  const defaultFilterState = getDefaultFilterState(pageType)

  const view = fromViewDefinition(
    getDefaultView(pageType, {
      isFlowProject:
        pageType === 'project' &&
        isFlowProject(
          getCacheEntryValue(client, 'projects', prefix.split('_')[1])
        ),
    })
  )
  if (isEqual(state.get(ActiveFilterKey), defaultFilterState)) {
    state.set(ActiveFilterKey, view.filter)
  }

  if (isEqual(state.get(ViewStateKey), DEFAULT_VIEW_STATE)) {
    state.set(ViewStateKey, view.view)
  }

  return state
}

function getPageTypeFromPrefix(prefix: string): PageType {
  if (prefix.startsWith('workspace_')) return 'workspace'
  if (prefix.startsWith('project_')) return 'project'
  if (prefix.startsWith('all-tasks')) return 'all-tasks'
  if (prefix.startsWith('all-projects')) return 'all-projects'
  if (prefix.startsWith('my-tasks')) return 'my-tasks'
  if (prefix.startsWith('folder_')) return 'folder'
  return 'workspace'
}

export async function getSavedState(
  prefix: string,
  args: { featureFlags: ExperimentValues }
) {
  const state = await loadState(prefix, { featureFlags: args.featureFlags })
  const filterState = state.get(ActiveFilterKey) as
    | EntityFilterState
    | undefined
  const viewState = state.get(ViewStateKey) as ViewState | undefined
  const type = filterState?.target ?? 'tasks'
  const page = viewState?.page ?? 'list'
  return { type, page }
}
