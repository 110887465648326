import { PlusSolid } from '@motion/icons'
import { Button, Tooltip } from '@motion/ui/base'
import { type NormalTaskSchema } from '@motion/zod/client'

import { BlockersTypeDropdown } from './blockers-type-dropdown'

export type BlockersDropdownProps = {
  task: NormalTaskSchema | undefined
  type: 'blocks' | 'blockedBy'
  sameProject: boolean
  disabled?: boolean
}

export function BlockersDropdown(props: BlockersDropdownProps) {
  const { task, type, sameProject, disabled } = props

  const trigger = (
    <Tooltip
      asChild
      content={
        task == null
          ? 'You must create a task before adding blockers'
          : !sameProject
            ? 'You must save the task before adding blockers'
            : undefined
      }
    >
      <Button
        variant='muted'
        sentiment='neutral'
        size='small'
        disabled={disabled}
      >
        <PlusSolid />
        Add task
      </Button>
    </Tooltip>
  )

  if (task == null) {
    return trigger
  }

  return (
    <BlockersTypeDropdown task={task} type={type}>
      {trigger}
    </BlockersTypeDropdown>
  )
}
