import { type FullCalendarEvent } from '~/areas/calendar/utils'

export function disableEventEditable<T extends FullCalendarEvent>(event: T): T {
  return {
    ...event,
    durationEditable: false,
    startEditable: false,
    resourceEditable: false,
    editable: false,
  }
}
