import { XSolid } from '@motion/icons'
import { type DayVerbose } from '@motion/rpc-types'
import { UnstyledModal } from '@motion/ui/base'
import { AvailabilityEvent } from '@motion/ui/calendar'
import { ConditionalWrapper } from '@motion/ui/utils'
import { byValue, Compare } from '@motion/utils/array'
import { parseDate } from '@motion/utils/dates'
import { logInDev } from '@motion/web-base/logging'

import {
  type DateSelectArg,
  type EventContentArg,
  type EventDropArg,
  type EventMountArg,
} from '@fullcalendar/core'
import interactionPlugin, {
  type EventResizeDoneArg,
} from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { type OpenTimezoneModalProps } from '~/areas/calendar/components/calendar-header/timezone-group/types'
import { useTimezoneSettings } from '~/global/hooks'
import { getTzAbbr } from '~/utils/time'
import { DateTime, type WeekdayNumbers } from 'luxon'
import { nanoid } from 'nanoid'
import { useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { TimePickerCopyTimesDropdown } from './TimePickerCopyTimesDropdown/TimePickerCopyTimesDropdown'

import { type ScheduleByDow } from '../../../../../types/bookingTypes'
import {
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  SubParagraph,
  TextButton,
} from '../../../../Common'
import { FullCalendarThemeSelector } from '../../../../Common/FullCalendarThemeSelector/FullCalendarThemeSelector'
import { parseTime } from '../../template-form/template-form.utils'

export interface DayRangeType {
  range: string
  preferred: boolean
}

export interface SlotType {
  start: string
  end: string
  id: string
  editable: boolean
  preferred: boolean
}

interface AvailabilityTimePickerModalProps {
  visible: boolean
  visibleHandler: (visible: boolean) => void
  days: ScheduleByDow
  daysHandler: (days: ScheduleByDow) => void
  isOnboarding?: boolean
  openTimezoneModal: (props: OpenTimezoneModalProps) => void
  customScheduleTimezone?: string | null
  customScheduleTimezoneHandler: (timezone: string | null) => void
}

const DAY_NAME_TO_INDEX: Record<DayVerbose, WeekdayNumbers> = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
}

function getWeekdayIndex(name: DayVerbose): WeekdayNumbers {
  if (!DAY_NAME_TO_INDEX.hasOwnProperty(name)) {
    throw new Error(`Invalid weekday name: ${name}`)
  }
  return DAY_NAME_TO_INDEX[name]
}

export const AvailabilityTimePickerModal = ({
  visible,
  visibleHandler = () => {},
  days,
  daysHandler = () => {},
  isOnboarding = false,
  openTimezoneModal = () => {},
  customScheduleTimezone,
  customScheduleTimezoneHandler = () => {},
}: AvailabilityTimePickerModalProps) => {
  const [slots, setSlots] = useState<SlotType[]>([])
  const { defaultTimezone } = useTimezoneSettings({ useDefaults: true })
  const calendarTempRef = useRef<FullCalendar>()
  const calendarRef = useCallback((calendar: FullCalendar) => {
    if (calendar) {
      calendarTempRef.current = calendar
    } else {
    }
  }, [])
  const availabilityChangedRef = useRef(false)
  const frameRef = useRef()

  const processDays = useCallback((daysInput: ScheduleByDow) => {
    const newSlots: SlotType[] = []

    Object.keys(daysInput).forEach((dow) => {
      const rangeObjs = daysInput[dow as DayVerbose]
      rangeObjs.forEach((rangeObj) => {
        const [start, end] = rangeObj.range.split('-')
        try {
          const startTime = parseTime(start)
          const endTime = parseTime(end)

          newSlots.push({
            preferred: !!rangeObj.preferred,
            editable: true,
            end: endTime
              .set({ weekday: getWeekdayIndex(dow as DayVerbose) })
              .toISO(),
            id: nanoid(),
            start: startTime
              .set({ weekday: getWeekdayIndex(dow as DayVerbose) })
              .toISO(),
          })
        } catch (err) {
          return
        }
      })
    })

    setSlots(newSlots)
  }, [])

  useEffect(() => {
    if (!availabilityChangedRef.current) {
      processDays(days)
    }
  }, [processDays, days])

  const formatDaysOutput = useCallback((slotsArr: SlotType[]) => {
    const tempDays: ScheduleByDow = {
      Friday: [],
      Monday: [],
      Saturday: [],
      Sunday: [],
      Thursday: [],
      Tuesday: [],
      Wednesday: [],
    }

    slotsArr.forEach((slot: SlotType) => {
      const day = parseDate(slot.start).toFormat('cccc')
      tempDays[day as DayVerbose].push({
        preferred: slot.preferred,
        range: [
          parseDate(slot.start).toFormat('h:mma'),
          parseDate(slot.end).toFormat('h:mma'),
        ].join('-'),
      })
    })

    return tempDays
  }, [])

  const submitHandler = useCallback(() => {
    const tempDays = formatDaysOutput(slots)
    daysHandler(tempDays)
    if (isOnboarding) {
      visibleHandler(!!tempDays) // includes validation
    } else {
      visibleHandler(false)
    }
  }, [daysHandler, visibleHandler, slots, formatDaysOutput, isOnboarding])

  const processEndTime = useCallback((end: string) => {
    if (DateTime.fromISO(end).equals(DateTime.fromISO(end).startOf('day'))) {
      return DateTime.fromISO(end).minus({ minutes: 1 }).toISO()
    }
    return end
  }, [])

  const onAvailabilitySelect = useCallback(
    (info: DateSelectArg) => {
      if (!info || !info.jsEvent) {
        logInDev('dragging info not found', info)
        return
      }

      const id = nanoid()
      const tempList = [...slots]
      info.endStr = processEndTime(info.endStr)
      tempList.push({
        preferred: false,
        editable: true,
        end: info.endStr,
        id,
        start: info.startStr,
      })
      tempList.sort(byValue((v) => parseDate(v.start), Compare.dateTime))
      setSlots(tempList)

      calendarTempRef.current?.getApi().unselect()
      availabilityChangedRef.current = true
    },
    [slots, availabilityChangedRef, processEndTime]
  )

  const deleteEvent = useCallback(
    (info: EventMountArg | EventContentArg) => {
      const tempList = [...slots]
      const deleteIdx = tempList.findIndex((item) => item.id === info.event.id)
      if (deleteIdx !== -1) {
        tempList.splice(deleteIdx, 1)
        setSlots(tempList)
        availabilityChangedRef.current = true
      }
    },
    [slots]
  )

  const clearTimes = useCallback(() => {
    setSlots([])
    availabilityChangedRef.current = true
  }, [])

  const changeAvailabilitySlot = useCallback(
    (info: EventDropArg | EventResizeDoneArg) => {
      const tempList = [...slots]
      const slotIndex = tempList.findIndex((slot) => slot.id === info.event.id)
      if (slotIndex !== -1) {
        tempList[slotIndex].start = info.event.startStr
        tempList[slotIndex].end = processEndTime(info.event.endStr)
        tempList.sort(byValue((v) => parseDate(v.start), Compare.dateTime))
        setSlots(tempList)
        availabilityChangedRef.current = true
      }
    },
    [slots, availabilityChangedRef, processEndTime]
  )

  const markPreferred = useCallback(
    (info: EventMountArg | EventContentArg) => {
      const tempList = [...slots]
      const slotIndex = tempList.findIndex((slot) => slot.id === info.event.id)
      if (slotIndex !== -1) {
        tempList[slotIndex].preferred = !tempList[slotIndex].preferred
        setSlots(tempList)
        availabilityChangedRef.current = true
      }
    },
    [slots]
  )

  return (
    <ConditionalWrapper
      condition={!isOnboarding}
      wrapper={(children) => (
        <UnstyledModal
          withAnimation
          overlayClassName='bg-modal-overlay'
          visible={visible}
          onClose={() => visibleHandler(false)}
        >
          <div className='bg-modal-bg rounded-lg shadow-lg'>{children}</div>
        </UnstyledModal>
      )}
    >
      <div
        className={twMerge(
          'flex max-h-[95vh] min-h-[70vh] min-w-[70vw] max-w-[95vw] flex-col'
        )}
        style={{
          height: isOnboarding ? 'calc(100vh - 300px)' : '715px',
          width: isOnboarding ? '100%' : '954px',
        }}
        // @ts-expect-error - ref type
        ref={frameRef}
      >
        {!isOnboarding && (
          <div className='border-light-500 dark:border-dark-1000 flex items-center justify-between border-b px-4 py-3'>
            <Paragraph className='text-base font-semibold'>
              Drag to select times
            </Paragraph>
            <div className='flex items-center gap-5'>
              <div className='flex items-center gap-2'>
                <SecondaryButton
                  onClick={() => {
                    openTimezoneModal({
                      changeHandler: (value) => {
                        customScheduleTimezoneHandler(value)
                      },
                      showDefault: true,
                    })
                  }}
                >
                  {customScheduleTimezone !== 'default'
                    ? getTzAbbr(customScheduleTimezone)
                    : `Use Default Timezone (${getTzAbbr(defaultTimezone)})`}
                </SecondaryButton>
                <SecondaryButton onClick={clearTimes}>
                  Clear Times
                </SecondaryButton>
              </div>
              <TextButton icon={XSolid} onClick={() => visibleHandler(false)} />
            </div>
          </div>
        )}

        <div className='calendar-main-container calendar-weekly'>
          <div className='calendar-wrapper'>
            <FullCalendarThemeSelector>
              <FullCalendar
                ref={calendarRef}
                plugins={[interactionPlugin, timeGridPlugin]}
                initialView='timeGridWeek'
                events={slots.map((slot) => ({
                  ...slot,
                  extendedProps: {
                    preferred: slot.preferred,
                  },
                }))}
                nowIndicator={false}
                height='100%'
                scrollTime='09:00:00'
                allDaySlot
                allDayContent={() => <div />} // hack for chrome 91 rendering
                dayHeaders
                dayHeaderContent={(info) => {
                  if (info) {
                    const day = DateTime.fromJSDate(info.date).toFormat('cccc')
                    return (
                      <div className='flex w-full items-center justify-between'>
                        <div className='dark:text-dark-400 text-light-1100 text-sm'>
                          {day.slice(0, 3).toUpperCase()}
                        </div>
                        <TimePickerCopyTimesDropdown
                          currentDay={day}
                          slots={slots}
                          setSlotsHandler={setSlots}
                        />
                      </div>
                    )
                  }
                }}
                dragScroll={false}
                selectable
                select={onAvailabilitySelect}
                droppable={false}
                selectMirror
                editable
                eventResizableFromStart
                eventDurationEditable
                slotDuration='00:15'
                slotLabelInterval='01:00'
                slotLabelContent={(info) => {
                  return (
                    <div className='w-10'>
                      <div className='absolute right-2 -top-1.5 text-semantic-neutral-text-subtle text-[10px]'>
                        {info.text}
                      </div>
                    </div>
                  )
                }}
                eventDrop={changeAvailabilitySlot}
                eventResize={changeAvailabilitySlot}
                eventContent={(event) => {
                  const { start, end } = event.event
                  if (!start) return null
                  return (
                    <AvailabilityEvent
                      startTime={start}
                      endTime={end ?? start}
                      onRemove={() => deleteEvent(event)}
                      preferred={event.event.extendedProps.preferred}
                      onClickPreferred={() => markPreferred(event)}
                    />
                  )
                }}
                eventTimeFormat={{
                  hour: 'numeric',
                  meridiem: false,
                  minute: '2-digit',
                  omitZeroMinute: true,
                }}
                headerToolbar={false}
              />
            </FullCalendarThemeSelector>
          </div>
        </div>

        <div className='dark:border-dark-1000 border-light-500 flex w-full items-center justify-between border-t py-3 px-4'>
          {!isOnboarding && (
            <SubParagraph>
              Preferred times will be marked &quot;preferred&quot; on the
              booking page. All available times will be shown.
            </SubParagraph>
          )}
          <div className='flex items-center gap-2'>
            {!isOnboarding && (
              <SecondaryButton onClick={() => visibleHandler(false)}>
                Cancel
              </SecondaryButton>
            )}
            <PrimaryButton onClick={submitHandler}>
              {isOnboarding ? 'Next' : 'Save times'}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  )
}
