import { type ContactRecord } from '@motion/rpc/types'

import { BackendApiService } from './BackendApiService'

export class ContactsService extends BackendApiService {
  static id = 'ContactsService' as const
  async search({
    search,
    teamOnly,
  }: {
    search: string
    teamOnly?: boolean
  }): Promise<ContactRecord> {
    return this.request(
      `contacts?search=${encodeURIComponent(search)}${
        teamOnly ? '&teamOnly=true' : ''
      }`,
      'GET'
    )
  }
}

export const instance = new ContactsService()
