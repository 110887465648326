import { classed } from '@motion/theme'

import { SharedFormShell } from '~/areas/task-project/components'

export const FormShell = classed('form', SharedFormShell, {
  base: `
    w-[var(--min-modal-width,894px)] min-w-[600px]
  `,
})

export const Header = classed('header', {
  base: `flex pl-4 pr-3 py-3 justify-between items-center self-stretch border-b border-modal-border`,
})

export const HeaderText = classed('h2', {
  base: `text-semantic-neutral-text-default font-semibold text-base`,
})
