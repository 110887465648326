import { ErrorBoundary } from '~/global/components'

import { SlimCommentBox } from './comment'
import { History } from './history'

import { useCreateComment } from '../hooks'
import { ActivityFeedProvider } from '../providers'
import {
  type ActivityFeedType,
  type CommentSubmitPayload,
  feedTypeToRpcType,
} from '../types'

export type ActivityFeedProps = {
  workspaceId: string
  onCommentChange?: (value: string) => void
} & ActivityFeedType

/**
 * Renders the Activity Feed for a target type, such as a Task or Project.
 */
export function ActivityFeed(props: ActivityFeedProps) {
  const { type, target, onCommentChange } = props
  const createComment = useCreateComment()

  const handleSubmit = async ({
    body,
    createdByUserId,
  }: CommentSubmitPayload) => {
    createComment({
      body,
      createdByUserId,
      type: feedTypeToRpcType[type],
      targetId: target.id,
    })
  }

  return (
    <ErrorBoundary errorMessage="Activity feed couldn't be loaded">
      <ActivityFeedProvider {...props}>
        <div className='flex flex-col gap-4 w-full transition-all overflow-y-hidden h-full'>
          <SlimCommentBox onSubmit={handleSubmit} onChange={onCommentChange} />

          <History />
        </div>
      </ActivityFeedProvider>
    </ErrorBoundary>
  )
}
