import { useOnValueChange } from '@motion/react-core/hooks'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { stats } from '@motion/web-common/performance'

import { useSidebarSearchContext } from '~/areas/search/hook'
import { useMemo } from 'react'
import { type Params, useLocation, useMatches } from 'react-router'

type MotionRouteMatch = {
  pathname: string
  handle: {
    template: string
  }
  params: Params<string>
}

function generatePathSlug(match: MotionRouteMatch, queryParams: string) {
  const pathSlug = Object.keys(match.params).reduce((acc, key) => {
    const value = match.params[key]
    if (!value) return acc
    return acc.replace(value, '_')
  }, match.pathname)

  const params = new URLSearchParams(queryParams)
  const keys = Array.from(params.keys()).sort().join('_')

  const path = [pathSlug, keys].filter(Boolean).join('_q_')
  return path
}

export const RecordPageView = () => {
  const matches = useMatches()
  const location = useLocation()
  const { hasSearch } = useSidebarSearchContext()
  const sidebarNavState = hasSearch ? 'collapsed' : 'expanded'

  const lastMatch = matches[matches.length - 1]

  const slug = useMemo(() => {
    return generatePathSlug(lastMatch as MotionRouteMatch, location.search)

    // only want to reevaluate on search and pathname
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, lastMatch.pathname])

  useOnValueChange(
    slug,
    () => {
      recordAnalyticsEvent('PAGE_VIEW_PATH', {
        sidebarNavState,
        slug,
      })

      stats.increment('page_view_path', 1, [`slug:${slug}`])
    },
    {
      triggerOnFirstRender: true,
    }
  )

  return null
}
