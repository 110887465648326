import { UserSolid } from '@motion/icons'
import { CalendarPalette } from '@motion/ui/calendar'
import { type ColorId } from '@motion/ui-logic/calendar'

import { forwardRef } from 'react'

export type CalendarAvatarProps = {
  colorId: ColorId
}

export const CalendarAvatar = forwardRef<HTMLDivElement, CalendarAvatarProps>(
  function CalendarAvatar({ colorId }, ref) {
    return (
      <CalendarPalette colorId={colorId}>
        <div
          ref={ref}
          className='size-4 rounded bg-calendar-palette-highlight-default text-primitives-global-white flex items-center justify-center shrink-0'
        >
          <UserSolid className='size-3' />
        </div>
      </CalendarPalette>
    )
  }
)
