import { type TaskSchema } from './types'

export type BucketProps = {
  id: string
}

type PivotArgs<T> = {
  tasks: readonly T[]
  columns: BucketProps[]
  rows: BucketProps[]

  getRowId(task: T): string | null
  getColumnId(task: T): string | null
}

export function pivot<T>(args: PivotArgs<T>) {
  const { getRowId, getColumnId, tasks } = args

  const grid = new Grid<T>({ rows: args.rows, columns: args.columns })

  for (const task of tasks) {
    const row = getRowId(task)
    const col = getColumnId(task)
    if (row == null || col == null) continue
    grid.add(row, col, task)
  }

  return grid
}

export function mergeDurationBy(
  tasks: TaskSchema[],
  mergeKey: (task: TaskSchema) => string
): TaskSchema[] {
  const cache = new Map<string, TaskSchema>()
  for (const task of tasks) {
    const key = mergeKey(task)
    const existingTask = cache.get(key)
    if (existingTask == null) {
      cache.set(key, task)
      continue
    }
    cache.set(key, {
      ...existingTask,
      duration: (existingTask.duration ?? 0) + (task.duration ?? 0),
    })
  }
  return Array.from(cache.values())
}

type Cell<T> = {
  rowId: string
  columnId: string
  items: T[]
}

type GridProps = {
  rows: BucketProps[]
  columns: BucketProps[]
}
class Grid<T> {
  private cells: Cell<T>[] = []
  private rows: BucketProps[] = []
  private cols: BucketProps[] = []

  constructor(args: GridProps) {
    this.rows = args.rows
    this.cols = args.columns
  }

  add(row: string, col: string, item: T) {
    this.cell(row, col).items.push(item)
  }

  key(row: string, col: string): string {
    return `${row}|${col}`
  }

  cell(row: string, col: string): Cell<T> {
    const found = this.cells.find((x) => x.rowId === row && x.columnId === col)
    if (found) return found
    const newCell = {
      rowId: row,
      columnId: col,
      items: [],
    }
    this.cells.push(newCell)
    return newCell
  }

  getRowItems(row: string): T[] {
    return this.cells.filter((x) => x.rowId === row).flatMap((x) => x.items)
  }

  *cellsInOrder() {
    for (let rowIndex = 0; rowIndex < this.rows.length; rowIndex++) {
      for (let colIndex = 0; colIndex < this.cols.length; colIndex++) {
        const row = this.rows[rowIndex]
        const col = this.cols[colIndex]
        yield {
          id: this.key(row.id, col.id),
          ...this.cell(row.id, col.id),
          rowIndex: rowIndex,
          colIndex: colIndex,
        }
      }
    }
  }
}
