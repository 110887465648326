import { type CalendarEventSchemaV2 } from '@motion/rpc-types'
import { useModalApi } from '@motion/web-common/modals'

import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useCallback } from 'react'

export function useEventModal() {
  const modalApi = useModalApi()
  const navigateToModal = useNavigateByGlobalModalId()

  /**
   * Open either the event/meeting modal for the given event.
   * If the event has a meetingTaskId, open the meeting modal. Otherwise, open the event modal.
   */
  return useCallback(
    (event: CalendarEventSchemaV2) => {
      if (event.meetingTaskId) {
        return navigateToModal(
          'meeting',
          { mTask: event.meetingTaskId },
          { eventIdOverride: event.id }
        )
      }

      modalApi.open('event-modal', {
        eventId: event.id,
      })
    },
    [modalApi, navigateToModal]
  )
}
