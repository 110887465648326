import { useProjectUpdater } from '~/areas/project/hooks'
import { LabelsField, type LabelsFieldProps } from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledLabelsField = () => {
  const { form } = useProjectForm()
  const updateProject = useProjectUpdater()

  const { control, watch } = form

  const { field } = useController({
    name: 'labelIds',
    control,
  })

  const id = watch('id')
  const selectedLabelIds = watch('labelIds')
  const workspaceId = watch('workspaceId')

  const onChange: LabelsFieldProps['onChange'] = async (labelIds) => {
    if (id != null) {
      return await updateProject(id, { labelIds })
    }

    field.onChange(labelIds)
  }

  return (
    <LabelsField
      selectedLabelIds={selectedLabelIds}
      workspaceId={workspaceId}
      onChange={onChange}
    />
  )
}
