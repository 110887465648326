import { CalendarPlusSolid } from '@motion/icons'
import { isCanceledStatus } from '@motion/shared/common'
import {
  ButtonGroup,
  IconButton,
  Tooltip,
  useContextMenu,
} from '@motion/ui/base'
import { isInUnvisitedStage } from '@motion/ui-logic/pm/project'
import { isMeetingTask, isSchedulingTask } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { AttachmentCountButton } from '~/areas/attachments/components'
import { useGetProjectTaskAttachments } from '~/areas/attachments/hooks'
import { EventActionList } from '~/areas/event/components'
import { SchedulingEventDropdown } from '~/areas/task-project/components'
import { TaskActionList } from '~/areas/tasks/components'
import {
  useTaskAutoscheduleUpdater,
  useTaskStatusData,
} from '~/areas/tasks/hooks'
import { MotionLink } from '~/global/components'
import {
  AutoScheduleLabel,
  type AutoScheduleLabelProps,
} from '~/global/components/labels'
import { FilteredWorkspaceContext } from '~/global/contexts'
import { useTaskModalUrl } from '~/global/navigation'
import { type TaskWithRelations } from '~/global/proxies'
import { useNavigate } from 'react-router'

import { Card, CardInfoRow, CardTitle } from './components'
import {
  CustomFieldsSection,
  ProjectField,
  TaskAssigneeField,
  TaskBlockersField,
  TaskDeadlineField,
  TaskDurationField,
  TaskLabelsField,
  TaskPriorityField,
  TaskStatusField,
} from './fields'

type TaskCardProps = {
  task: TaskWithRelations
  dragging?: boolean
}
export const TaskCard = ({ task, dragging }: TaskCardProps) => {
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()
  const updateTaskAutoschedule = useTaskAutoscheduleUpdater()
  const { isStatusAllowed } = useTaskStatusData(task)
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_KANBAN_RIGHT_CLICK', {
        type: 'task',
      })
    },
  })
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')
  const attachmentsWithDefinition = useGetProjectTaskAttachments({
    targetId: task.id,
    targetType: 'TEAM_TASK',
  })
  const isSchedulingTaskType = isSchedulingTask(task)
  const isMeetingTaskType = isMeetingTask(task)

  const relatedMeetingTask = isSchedulingTaskType ? task.meetingTask : null

  const isGhost = isInUnvisitedStage(task)

  const handleAutoScheduleChange: AutoScheduleLabelProps['onChange'] = (
    checked
  ) => {
    void updateTaskAutoschedule(task, checked)
  }

  const taskUrl = buildTaskModalUrl({
    task: task.id,
    isMeeting: isMeetingTaskType,
  })

  const attachmentsCount = hasFlowsAttachments
    ? attachmentsWithDefinition.length
    : task.type === 'NORMAL'
      ? task.uploadedFileIds?.length
      : 0

  return (
    <FilteredWorkspaceContext statuses={isStatusAllowed}>
      <Card
        dragging={dragging}
        onClick={() => navigate(taskUrl)}
        draggable='false'
        onContextMenu={handleContextMenu}
        padded={false}
        isGhost={isGhost}
      >
        <ProjectField task={task} />
        <div className='p-2 flex gap-2 flex-col'>
          <CardInfoRow>
            {!isMeetingTaskType && <TaskPriorityField task={task} />}
            <TaskStatusField task={task} />
            <div className='flex-1' />
            <ButtonGroup>
              {task.type === 'NORMAL' && task.blockedByTaskIds.length > 0 && (
                <TaskBlockersField type='blockedBy' task={task} />
              )}
              {task.type === 'NORMAL' && task.blockingTaskIds.length > 0 && (
                <TaskBlockersField type='blocks' task={task} />
              )}
              {relatedMeetingTask != null && (
                <MotionLink
                  url={buildTaskModalUrl({
                    task: relatedMeetingTask.id,
                    isMeeting: true,
                  })}
                  className='text-semantic-primary-text-default underline'
                >
                  View event
                </MotionLink>
              )}
              {!isMeetingTaskType && (
                <AutoScheduleLabel
                  task={task}
                  onChange={handleAutoScheduleChange}
                  position='toggle-right'
                  isUnvisitedStage={isGhost}
                />
              )}
              <SchedulingEventDropdown taskId={task.id}>
                <Tooltip asChild content='Schedule event'>
                  <IconButton
                    icon={CalendarPlusSolid}
                    size='small'
                    sentiment='primary'
                    variant='outlined'
                  />
                </Tooltip>
              </SchedulingEventDropdown>
            </ButtonGroup>
          </CardInfoRow>
          <CardTitle canceled={isCanceledStatus(task.status)}>
            {task.name}
          </CardTitle>
          <CardInfoRow
            spaceBetween={!isMeetingTaskType}
            reverse={isMeetingTaskType}
          >
            <TaskDurationField task={task} />
            <TaskDeadlineField task={task} />

            <TaskAssigneeField task={task} />
          </CardInfoRow>
          <CardInfoRow spaceBetween>
            <TaskLabelsField task={task} />
            {task.type === 'NORMAL' && (
              <AttachmentCountButton
                targetId={task.id}
                targetType='TEAM_TASK'
                count={attachmentsCount}
              />
            )}
          </CardInfoRow>

          <CustomFieldsSection task={task} />
        </div>
      </Card>
      {task.type === 'NORMAL' && (
        <ContextMenuPopover
          renderContent={({ close }) =>
            isMeetingTask(task) ? (
              <EventActionList close={close} eventId={task.meetingEventId} />
            ) : (
              <TaskActionList close={close} task={task} />
            )
          }
        />
      )}
    </FilteredWorkspaceContext>
  )
}
