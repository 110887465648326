import { type Calendar } from '@motion/rpc/types'
import { type CalendarSchema } from '@motion/zod/client'

import {
  useAllCalendars,
  useFrequentlyMetCalendars,
  useMyCalendars,
} from '~/areas/calendar/hooks'
import { useUpdateCalendars } from '~/global/rpc/v2'
import {
  selectEmailAccounts,
  selectEmailAccountsMap,
  selectMainEmailAccountId,
} from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

export function useCalendarList() {
  const { calendars } = useAllCalendars()
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const mainEmailAccountId = useAppSelector(selectMainEmailAccountId)
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const frequentlyMetCalendars = useFrequentlyMetCalendars()
  const myCalendars = useMyCalendars()
  const { mutateAsync: updateCalendars } = useUpdateCalendars()

  const onCreateNewCalendars = async (
    calendars: Calendar[] | CalendarSchema[]
  ): Promise<CalendarSchema[]> => {
    const updatedCalendars = await updateCalendars({
      calendars: calendars.map((c) => ({
        id: c.id,
        emailAccountId: c.emailAccountId,
        isEnabled: false,
        isInFrequentlyMet: true,
        isNew: true,
        title: c.title,
      })),
    })

    const ids = calendars.map((c) => `${c.emailAccountId}${c.title}`)

    // `updatedCalendars` are ALL calendars for the specific user. Filter down
    // to the calendars we just created
    return updatedCalendars.calendars.filter((c) =>
      ids.includes(`${c.emailAccountId}${c.title}`)
    )
  }

  return {
    calendars,
    emailAccounts,
    emailAccountsMap,
    frequentlyMetCalendars,
    mainEmailAccountId,
    myCalendars,
    onCreateNewCalendars,
  }
}
