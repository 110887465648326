import {
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'

import { useCallback } from 'react'

import { DEFAULT_VIEW, TeamScheduleActiveViewOptionsKey } from '../../context'
import { TeamSchedulePageContextKey } from '../../context/page-data-context'
import { fromViewDefinition } from '../utils'

export const useSelectView = () => {
  const [ctx] = useSharedState(TeamSchedulePageContextKey)
  const setViewState = useSharedStateSendOnly(TeamScheduleActiveViewOptionsKey)
  const setFilterState = useSharedStateSendOnly(ActiveFilterKey)

  return useCallback(
    (id: string) => {
      const view = ctx.views.byId[id] ?? DEFAULT_VIEW
      const local = fromViewDefinition(view)
      setFilterState(local.filter)
      setViewState(local.view)
    },
    [ctx.views.byId, setFilterState, setViewState]
  )
}
