import { type COLOR } from '@motion/shared/common'
import { type ProjectsV2CreateRequestSchema } from '@motion/zod/client'

import { type TemplateProjectFormFields } from './types'

export function convertProjectForm(
  fields: TemplateProjectFormFields,
  isUpdatingProject = false
): ProjectsV2CreateRequestSchema {
  return {
    workspaceId: fields.workspaceId,
    name: fields.name,
    description: fields.description,
    priorityLevel: fields.priorityLevel,
    managerId: fields.managerId,
    statusId: fields.statusId,
    labelIds: fields.labelIds,
    color: fields.color as COLOR,
  } satisfies ProjectsV2CreateRequestSchema
}
