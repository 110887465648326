import { ExclamationCircleSolid, TrashSolid } from '@motion/icons'
import { type EmailAccount } from '@motion/rpc/types'

import { twMerge } from 'tailwind-merge'

import { Paragraph, PrimaryButton, TextButton } from '../../Common'

type Props = {
  emailAccount: EmailAccount
  onRemoveEmailAccount: (emailAccount: EmailAccount) => void
  onStartEmailAuth: () => void
}

/**
 * Renders the Email Account Tree node for when an email account status is
 * not OK. The component does not render a button that allows collapsing of
 * its children, and additionally renders action buttons to either re-auth
 * or remove the email account.
 *
 * @param param0
 * @returns
 */
export const EmailAccountTreeNodeWithError = ({
  emailAccount,
  onRemoveEmailAccount,
  onStartEmailAuth,
}: Props) => {
  return (
    <div
      className={twMerge(
        'group relative flex w-full items-center space-x-2 rounded border-none bg-red-100 py-2 px-2 dark:bg-red-800'
      )}
    >
      <ExclamationCircleSolid className='h-5 w-5 text-red-500' />
      <div className='flex h-full flex-col overflow-hidden'>
        <Paragraph className='mr-2 truncate font-medium'>
          {emailAccount.email}
        </Paragraph>
        <Paragraph className='mr-2 truncate text-xs text-red-800 dark:text-red-200'>
          Account disconnected
        </Paragraph>
      </div>
      <div className='flex grow items-center justify-end gap-1'>
        <PrimaryButton size='small' onClick={onStartEmailAuth}>
          Reconnect
        </PrimaryButton>
        <TextButton
          icon={TrashSolid}
          onClick={() => onRemoveEmailAccount(emailAccount)}
        />
      </div>
    </div>
  )
}
