import { StartDateSolid } from '@motion/icons'

import {
  StartDateDropdown,
  type StartDateDropdownProps,
} from '~/areas/project-management/components'
import { DateTriggerButton } from '~/areas/task-project/components'
import { useTaskStartDateUpdater } from '~/areas/tasks/hooks'
import { DateTime } from 'luxon'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

export const StartDateField = () => {
  const { form, disabledFields } = useTaskForm()
  const updateTaskStartDate = useTaskStartDateUpdater()

  const { control, watch } = form

  const { field } = useController({
    name: 'startDate',
    control,
  })

  const selectedStartDate = field.value
  const taskId = watch('id')
  const itemType = watch('type')
  const projectId = watch('projectId')
  const priorityLevel = watch('priorityLevel')
  const stageDefinitionId = watch('stageDefinitionId')

  const isRecurring = itemType === 'RECURRING_TASK'
  const isASAP = priorityLevel === 'ASAP'

  const today = DateTime.now().toISODate()

  const onChange: StartDateDropdownProps['onChange'] = (value) => {
    if (taskId != null) {
      return updateTaskStartDate(taskId, value)
    }

    field.onChange(value)
  }

  return (
    <StartDateDropdown
      value={selectedStartDate}
      onChange={onChange}
      disableClearing={isRecurring || isASAP}
      contextProps={
        taskId != null
          ? { taskId }
          : {
              projectId,
              stageDefinitionId,
            }
      }
      dropdownTarget='task'
    >
      <DateTriggerButton
        label='Start date'
        icon={<StartDateSolid />}
        disabled={disabledFields.has('startDate')}
        onClear={
          selectedStartDate === today ? undefined : () => onChange(today)
        }
        date={isASAP ? null : selectedStartDate}
        prefix={isRecurring ? 'Starting' : ''}
        placeholder={isASAP ? 'ASAP' : 'None'}
        clearTooltipContent='Reset start date to today'
      />
    </StartDateDropdown>
  )
}
