import {
  type ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useState } from 'react'

import { isStackRow } from './utils'
import { VirtualList } from './virtual-list'

import { type GroupedNode, type Tree } from '../../grouping'
import { useViewState } from '../../view-state'
import { useOOOEventsForRowFn } from '../hooks'
import { OOO_ROW_TYPE, STACKED_PROJECTS_ROW_TYPE } from '../shared-constants'
import { organizeProjectsIntoRows } from '../utils'

type ContainerProps<T extends GroupedNode> = {
  tree: Tree<T>
}

export const TableContainer = <T extends GroupedNode>(
  props: ContainerProps<T>
) => {
  const getOOOEventsForRow = useOOOEventsForRowFn()

  const [viewState] = useViewState()
  const stackProjects = viewState.groupBy.stackProjects

  const [expanded, setExpanded] = useState<ExpandedState>(() => {
    const data: Record<string, boolean> = {}

    function expand(item: Tree<T>) {
      // Only group nodes have children. Leafs don't
      if (item.children != null) {
        data[item.qualifiedKey] = true
        item.children.forEach(expand)
      }
    }

    props.tree.children.forEach(expand)
    return data
  })

  const table = useReactTable({
    data: props.tree.values,
    columns: [],
    state: {
      expanded: expanded,
    },
    filterFromLeafRows: true,
    getRowId: (row) => row.qualifiedKey,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => {
      let children: GroupedNode[] | undefined = row.children

      // Stack projects if the setting is on
      if (stackProjects && isStackRow(row, props.tree)) {
        const rowProjects = row.children ?? []
        const stackedProjects = organizeProjectsIntoRows(
          rowProjects?.map((p) => p.value.value)
        )
        const stackedRow: GroupedNode[] = stackedProjects.map((p) => {
          return {
            count: p.length,
            items: p,
            key: p.map((p) => p.id).join('|'),
            parent: row,
            qualifiedKey: row.qualifiedKey + '/' + p.map((p) => p.id).join('|'),
            value: {
              type: STACKED_PROJECTS_ROW_TYPE,
              key: p.map((p) => p.id).join('|'),
              value: { name: 'Stacked Projects' },
            },
          }
        })
        children = stackedRow
      }

      // Add out of office row if necessary
      const oooEvents = getOOOEventsForRow({ original: row } as Row<any>)
      if (oooEvents?.length && children) {
        children = [
          {
            count: oooEvents.length,
            items: oooEvents,
            key: row.qualifiedKey + '/' + OOO_ROW_TYPE,
            parent: row,
            qualifiedKey: row.qualifiedKey + '/' + OOO_ROW_TYPE,
            value: {
              type: OOO_ROW_TYPE,
              key: row.qualifiedKey + '/' + OOO_ROW_TYPE,
              value: { name: 'OOO Events' },
            },
          },
          ...children,
        ]
      }
      return children as T[] | undefined
    },
    getExpandedRowModel: getExpandedRowModel(),
  })

  const { rows } = table.getRowModel()

  const toggleExpandAllRows = useCallback(
    (expand: boolean) => {
      table.toggleAllRowsExpanded(expand)
    },
    [table]
  )

  // Expand all by default
  useEffect(() => {
    toggleExpandAllRows(true)
  }, [toggleExpandAllRows])

  return (
    <VirtualList
      rows={rows}
      maxDepth={props.tree.maxDepth}
      toggleExpandAllRows={toggleExpandAllRows}
    />
  )
}
