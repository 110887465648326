import { useSharedState } from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { byProperty, cascade, Compare, ordered } from '@motion/utils/array'
import { type UserInfoSchema } from '@motion/zod/client'

import { AppWorkspaceContext, type EntityCache } from '~/global/contexts'
import { useMemo } from 'react'

import { TeamScheduleActiveViewOptionsKey } from '../context'

type GetSortedUsersArgs = {
  users: EntityCache<UserInfoSchema>
  selectedUsers: string[] | null
  order: string[] | null
}
export function getSortedUsers({
  users,
  selectedUsers,
  order,
}: GetSortedUsersArgs) {
  const activeUsers = (
    selectedUsers == null || selectedUsers.length === 0
      ? users.all
      : selectedUsers.map((id) => users.byId[id])
  ).filter((user) => user != null && !user.deleted)

  const sortedUsers = order?.length
    ? [...activeUsers].sort(
        cascade(
          byProperty('id', ordered(order)),
          byProperty('name', Compare.caseInsensitive)
        )
      )
    : [...activeUsers].sort(byProperty('name', Compare.caseInsensitive))

  return sortedUsers
}

export const useSortedUsers = () => {
  const [filterState] = useSharedState(ActiveFilterKey)
  const [viewState] = useSharedState(TeamScheduleActiveViewOptionsKey)
  const [ctx] = useSharedState(AppWorkspaceContext)

  return useMemo(() => {
    return getSortedUsers({
      users: ctx.users,
      selectedUsers: filterState.tasks.filters.assigneeUserIds?.value ?? null,
      order: viewState.rowOrder,
    })
  }, [ctx.users, filterState.tasks.filters.assigneeUserIds, viewState.rowOrder])
}
