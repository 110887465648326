import { Checkbox, type CheckboxProps } from '@motion/ui/forms'
import { handleIsAllDayChange } from '@motion/ui-logic'

import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventAllDayField = () => {
  const { isLoading, isReadOnly } = useEventModalState()
  const { form, hiddenFields } = useEventForm()
  const { control } = form

  const { field } = useController({
    name: 'isAllDay',
    control,
  })

  if (isLoading || hiddenFields.has('isAllDay')) return null

  const onChange: CheckboxProps['onChange'] = async (value) => {
    const updates = handleIsAllDayChange(value, {
      start: form.getValues('start'),
      end: form.getValues('end'),
    })

    field.onChange(updates.isAllDay)
    form.setValue('start', updates.start, { shouldDirty: true })
    form.setValue('end', updates.end, { shouldDirty: true })
    form.setValue('status', updates.status, { shouldDirty: true })
  }

  return (
    <Checkbox
      disabled={isReadOnly}
      label={
        // Same style as the muted neutral button
        <span className='text-[10px] leading-4 text-button-neutral-muted-text-default font-semibold disabled:text-button-neutral-muted-text-disabled'>
          All day
        </span>
      }
      size='small'
      checked={field.value}
      onChange={onChange}
    />
  )
}
