import { generatePath } from 'react-router'

export const pmv2DefaultPath = `/web/pm/workspaces/my-tasks`
const pmv2WorkspacePath = `/web/pm/workspaces/:workspaceId`
const pmv2WorkspaceKanbanPath = `/web/pm/workspaces/:workspaceId/kanban`
const pmv2ProjectPath = `/web/pm/workspaces/:workspaceId/projects/:projectId`
const pmv2SchedulePath = `/web/pm/schedule`
const pmv2DefaultArchivePath = `/web/pm/workspaces/my-tasks/archive`

// This should be kept around for a while after pmv2 is rolled out so that any old links still work.
export const generatePMRevampRedirectUrl = (originalUrl: string) => {
  try {
    const oldSearchParams = new URL(originalUrl).searchParams
    const workspaceParam = oldSearchParams.get('workspace') || 'my-tasks'
    const forWorkspaceParam = oldSearchParams.get('forWorkspace')
    const taskParam = oldSearchParams.get('task')
    const projectParam = oldSearchParams.get('project')
    const pageParam = oldSearchParams.get('page')
    const kanbanParam = oldSearchParams.get('kanban')

    // old url is schedule page
    if (pageParam === 'schedule') {
      return pmv2SchedulePath
    }

    // old url is archive page
    if (pageParam === 'archive') {
      return pmv2DefaultArchivePath
    }

    // old url is project modal
    if (projectParam && workspaceParam) {
      const searchParams = new URLSearchParams({ project: 'edit' })
      const redirectPath = generatePath(pmv2ProjectPath, {
        workspaceId: forWorkspaceParam ?? workspaceParam,
        projectId: projectParam,
      })
      return `${redirectPath}?${searchParams}`
    }

    // old url is task modal
    const path =
      kanbanParam === 'true' ? pmv2WorkspaceKanbanPath : pmv2WorkspacePath
    const redirectPath = generatePath(path, {
      workspaceId: workspaceParam,
    })
    if (taskParam) {
      const searchParams = new URLSearchParams({ task: taskParam })
      return `${redirectPath}?${searchParams}`
    }

    return redirectPath
  } catch (err) {
    // if something goes wrong return default
    return pmv2DefaultPath
  }
}
