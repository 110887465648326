import { PopoverTrigger, SearchableList } from '@motion/ui/base'

import { type WorkspaceStageDefinition } from '~/global/contexts'

import { RestrictWidth } from '../../../components'
import { BulkStageLabel } from '../bulk-stage-label'
import { DropdownContentTopSection } from '../dropdown-content-top-section'
import { DropdownContainer } from '../styled'

type BulkStageDropdownProps = Omit<BulkStageDropdownContentProps, 'close'> & {
  children?: React.ReactNode
}

export function BulkStageDropdown({
  children,
  ...rest
}: BulkStageDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkStageDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type BulkStageDropdownContentProps = {
  close: () => void
  onChange: (stageDefinitionIds: string[]) => void
  selectedStageDefinitionIds: string[]
  filteredStageDefinitions: WorkspaceStageDefinition[][]
}

function BulkStageDropdownContent({
  close,
  onChange,
  selectedStageDefinitionIds,
  filteredStageDefinitions,
}: BulkStageDropdownContentProps) {
  return (
    <DropdownContainer>
      <DropdownContentTopSection>
        Only showing stages shared between all tasks you selected
      </DropdownContentTopSection>
      <SearchableList
        searchable
        items={filteredStageDefinitions}
        computeKey={(items) => items[0].name}
        computeSearchValue={(items) => items[0].name}
        computeSelected={(items) =>
          selectedStageDefinitionIds.some((id) =>
            items.some((l) => l.id === id)
          )
        }
        renderItem={(items) => (
          <RestrictWidth>
            <BulkStageLabel items={items} />
          </RestrictWidth>
        )}
        onSelect={(items) => {
          close()
          onChange(items.map((stageDefinition) => stageDefinition.id))
        }}
      />
    </DropdownContainer>
  )
}
