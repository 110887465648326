import type { WorkspaceSchema } from '@motion/zod/client'

import { WorkspaceDropdown } from '~/global/components/dropdowns'

export type CreateProjectWorkspaceDropdownProps = {
  selectedWorkspaceId: string
  onChange: (workspace: WorkspaceSchema) => void
  children: React.ReactNode
}

export const CreateProjectWorkspaceDropdown = ({
  selectedWorkspaceId,
  onChange,
  children,
}: CreateProjectWorkspaceDropdownProps) => {
  return (
    <WorkspaceDropdown
      onChange={onChange}
      selectedWorkspaceId={selectedWorkspaceId}
      withAllWorkspacesOption
    >
      {children}
    </WorkspaceDropdown>
  )
}
