import { type NotificationPreferenceId } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { Events } from '../../analyticsEvents'
import { request } from '../apiUtils'

export type UserNotificationsResponse = Record<
  NotificationPreferenceId,
  boolean
> & {
  userId: string
}

export type UserNotificationsPayload = Partial<
  Record<NotificationPreferenceId, boolean>
>

export const get = async () => {
  return (await request(
    'notifications/preferences',
    'GET'
  )) as UserNotificationsResponse
}

export const update = async (payload: UserNotificationsPayload) => {
  recordAnalyticsEvent(
    Events.PROJECT_MANAGEMENT_UPDATE_NOTIFICATION_PREFERENCES
  )
  return (await request(
    'notifications/preferences',
    'PATCH',
    __BACKEND_HOST__,
    payload
  )) as UserNotificationsResponse
}
