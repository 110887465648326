import {
  type TemplateProjectType,
  type TemplateTaskType,
} from '@motion/rpc/types'
import {
  type LegacyProjectTemplateSchema,
  type LegacyProjectTemplateTaskSchema,
} from '@motion/zod/client'

export function legacyProjectTemplateToTemplateProjectType(
  legacyProjectTemplate: LegacyProjectTemplateSchema | null | undefined
): TemplateProjectType | undefined {
  if (legacyProjectTemplate == null) {
    return undefined
  }

  return {
    ...legacyProjectTemplate,
    project: {
      ...legacyProjectTemplate.project,
      description: legacyProjectTemplate.project.description ?? '',
      managerId: legacyProjectTemplate.project.managerId ?? undefined,
      tasks: legacyProjectTemplate.project.tasks
        .map((task) =>
          convertLegacyProjectTemplateTaskSchemaToTemplateTaskType(task)
        )
        .filter(Boolean),
      labels: legacyProjectTemplate.project.labelIds
        .map((labelId) => {
          return {
            labelId,
            projectId: legacyProjectTemplate.project.id,
          }
        })
        .filter(Boolean),
    },
  }
}

function convertLegacyProjectTemplateTaskSchemaToTemplateTaskType(
  task: LegacyProjectTemplateTaskSchema
): TemplateTaskType['task'] {
  return {
    ...task,
    blockingTasks: task.blockedByTaskIds.map((id) => ({
      blockedId: id,
    })),
    blockedTasks: task.blockingTaskIds.map((id) => ({ blockedId: id })),
    assignees: [],
    isChunkedTask: false,
    isReminderTask: false,
    projectId: task.projectId ?? '',
    minimumDuration: task.minimumDuration ?? 0,
    schedule: task.scheduleId ?? '',
    description: task.description ?? '',
    createdTime: '',
    labels: task.labelIds.map((id) => ({ labelId: id, taskId: task.id })),
  }
}
