import { DateTime } from 'luxon'

export const formatAutoScheduleDuration = (durationInDays: number) => {
  const formattedDuration = DateTime.now()
    .plus({ days: durationInDays })
    .diff(DateTime.now())
    .shiftTo('months')
    .toHuman({ maximumFractionDigits: 0, listStyle: 'long' })

  if (formattedDuration === '1 month') {
    return '4 weeks'
  }

  return formattedDuration
}
