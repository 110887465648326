import { type TaskDefinitionFormFields } from '@motion/ui-logic/pm/project'

import { useInitialFormData as useTaskInitialFormData } from '~/areas/tasks/modals/task-modal/hooks'

import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export function useInitialFormData(): TaskDefinitionFormFields {
  const { initialTaskData, taskDefinition } = useTaskDefinitionModalState()

  const initialFormData = useTaskInitialFormData(initialTaskData)

  if (!taskDefinition) {
    throw new Error('Task definition is required')
  }

  return {
    ...initialFormData,
    id: taskDefinition.id,
    scheduleMeetingWithinDays: taskDefinition.scheduleMeetingWithinDays,
  }
}
