import { Button } from '@motion/ui/base'

const ERROR_FALLBACK_TITLE = 'Oops!'
const ERROR_FALLBACK_MESSAGE = 'An error occurred while fetching the attachment'

export type AttachmentErrorContentProps = {
  title?: string
  message?: string
  closeModal: () => void
}

export function AttachmentErrorContent({
  title = ERROR_FALLBACK_TITLE,
  message = ERROR_FALLBACK_MESSAGE,
  closeModal,
}: AttachmentErrorContentProps) {
  return (
    <div className='flex flex-col items-center gap-2'>
      <span className='text-lg'>{title}</span>
      <span className='text-sm mb-6'>{message}</span>
      <Button
        onClick={closeModal}
        aria-label='Close attachment view'
        sentiment='onDark'
        variant='outlined'
      >
        Close
      </Button>
    </div>
  )
}
