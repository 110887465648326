import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTemplateTaskFormHandlers } from '../template-task-form-handlers'
import { getHiddenAndDisabledFields } from '../template-task-hidden-disabled-fields'
import { type TemplateTaskFormFields } from '../types'
import { useTemplateTaskFormOptions } from '../use-template-task-form-options'

export function useTemplateTaskForm() {
  const form = useFormContext<TemplateTaskFormFields>()
  const options = useTemplateTaskFormOptions()
  const actions = useTemplateTaskFormHandlers()

  return useMemo(
    () => ({
      form,
      options,
      actions,
      ...getHiddenAndDisabledFields(form, options),
    }),
    [actions, form, options]
  )
}
