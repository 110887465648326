import { Sidebar, type SidebarProps } from '@motion/ui/base'

import { type SidebarKeys, useSidebarState } from '~/global/hooks'
import { type PropsWithChildren } from 'react'

type StatefulSidebarProps = PropsWithChildren<
  Omit<SidebarProps, 'open'> & {
    sidebarKey: SidebarKeys
    allowClose?: boolean
  }
>

export const StatefulSidebar = ({
  sidebarKey,
  children,
  onResizeStop,
  onOpenChange,
  allowClose = true,
  hideCloseButton = !allowClose,
  ...props
}: StatefulSidebarProps) => {
  const [state, setState] = useSidebarState(sidebarKey, props.initialWidth)

  return (
    <Sidebar
      open={!allowClose || state.open}
      initialWidth={state.width}
      onResizeStop={(width) => {
        setState({
          width,
          open: true,
        })

        if (onResizeStop) {
          onResizeStop(width)
        }
      }}
      onOpenChange={(open) => {
        if (!allowClose) return

        setState({ open: open })

        if (onOpenChange) {
          onOpenChange(open)
        }
      }}
      hideCloseButton={hideCloseButton}
      {...props}
    >
      {children}
    </Sidebar>
  )
}
