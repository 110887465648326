import { isFlowVariableKey } from '@motion/shared/flows'

import { ModalFieldButton } from '~/areas/task-project/components'
import { ConnectedUserLabel } from '~/global/components/labels'
import { useController } from 'react-hook-form'

import { RoleLabel } from '../../../components/role-label'
import { StageTaskAssigneeDropdown } from '../../../components/stages/fields'
import { roleToFlowTemplateAssignee } from '../../../components/stages/utils/role-to-flow-template-assignee'
import { getSelectedRole } from '../../../utils'
import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const ControlledAssigneeField = () => {
  const { form } = useTaskDefinitionForm()
  const { flowFormData } = useTaskDefinitionModalState()

  if (flowFormData == null) {
    throw new Error('flowFormData is required')
  }

  const { control, watch } = form

  const { field } = useController({
    name: 'assigneeUserId',
    control,
  })

  const workspaceId = watch('workspaceId')

  const { roles } = flowFormData
  const flowTemplateRoles = roles.map(roleToFlowTemplateAssignee)

  const selectedRole = getSelectedRole(flowTemplateRoles, field.value)

  return (
    <StageTaskAssigneeDropdown
      selectedUserId={field.value}
      onChange={field.onChange}
      workspaceId={workspaceId}
      dropdownRoles={flowTemplateRoles}
      hideAddRole
    >
      <ModalFieldButton label='Assignee'>
        {isFlowVariableKey(field.value) && selectedRole != null ? (
          <RoleLabel role={selectedRole} />
        ) : (
          <ConnectedUserLabel userId={field.value} />
        )}
      </ModalFieldButton>
    </StageTaskAssigneeDropdown>
  )
}
