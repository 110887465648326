import { FlowTemplateTable } from './flow-template-table'
import { StageTable } from './stage-table'

export function ProjectTemplateTable() {
  return (
    <>
      <FlowTemplateTable />
      <StageTable />
    </>
  )
}
