import { Sentry } from '@motion/web-base/sentry'

import { useUpsertPageViewsSettings } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { extractPageViewOrderId, type PageViewOrderId } from '../utils'

export function useReorderViews(pageViewOrderId: PageViewOrderId | null) {
  const { mutateAsync: updateViewsOrder } = useUpsertPageViewsSettings()

  return useCallback(
    async (itemIds: string[]) => {
      const { type, id } = extractPageViewOrderId(pageViewOrderId)

      if (type == null || id == null) {
        Sentry.captureMessage('Cannot reorder the views', {
          extra: {
            pageViewOrderId,
          },
        })
        showErrorToast('Cannot reorder the views')
        return
      }

      try {
        await updateViewsOrder({
          id,
          type,
          viewOrder: itemIds,
        })
      } catch (err) {
        Sentry.captureException(err)
        showErrorToast(err)
      }
    },
    [pageViewOrderId, updateViewsOrder]
  )
}
