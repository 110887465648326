import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { type EventContentArg } from '@fullcalendar/core'
import { parseFullCalendarEventId } from '~/state/calendarEvents/calendarEventsUtils'

import { CreateNewCalendarEvent } from './create-new-event'
import { NewCalendarEvent } from './new-calendar-event'
import { SelectExistingCalendarEvent } from './select-existing-event'

export function ScheduleAssistantCalendarEventContainer(arg: EventContentArg) {
  const { type, id, modifier } = parseFullCalendarEventId(arg.event.id)

  if (id == null) {
    if (type === 'new_event') {
      return <NewCalendarEvent eventArg={arg} />
    }

    Sentry.captureException('Calendar event id is missing during parsing', {
      extra: { event: arg.event },
      tags: { position: 'ScheduleAssistantCalendarEventContainer' },
    })
    return null
  }

  switch (type) {
    case 'event':
      if (modifier === 'create_new') {
        return <CreateNewCalendarEvent eventArg={arg} />
      } else if (modifier === 'select_existing') {
        return <SelectExistingCalendarEvent id={id} eventArg={arg} />
      }
      return null
    case 'new_event':
      return <NewCalendarEvent eventArg={arg} />
    default:
      logInDev(`Unexpected calendar event type: ${type}`)
      return null
  }
}
