import { Shortcut } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import type { FlowTemplateFormTask } from '@motion/ui-logic/pm/project'

import { stripVariableKeysFromTaskDefinition } from '../../../utils'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export interface TaskDefinitionPrevNextTooltipContent {
  taskDefinition: FlowTemplateFormTask
  shortcut: string
  isNext?: boolean
}

export function TaskDefinitionPrevNextTooltipContent({
  taskDefinition,
  shortcut,
  isNext = false,
}: TaskDefinitionPrevNextTooltipContent) {
  const { flowFormData } = useTaskDefinitionModalState()

  if (taskDefinition == null || flowFormData == null) return null

  const { textVariables } = flowFormData

  const { name } = stripVariableKeysFromTaskDefinition(
    taskDefinition,
    textVariables.map((variable) => variable.key)
  )

  return (
    <div className='flex flex-col gap-1 items-center'>
      <p>
        {templateStr('{{previousNext}} task: {{name}}', {
          previousNext: isNext ? 'Next' : 'Previous',
          name,
        })}
      </p>
      <Shortcut shortcut={shortcut} size='small' />
    </div>
  )
}
