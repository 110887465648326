import { templateStr } from '@motion/ui-logic'

export const getGhostDisabledTooltipContent = (
  type: 'workspace' | 'project'
) => {
  return templateStr(
    `This task can’t be moved to another {{type}} because it’s in an unstarted stage. Please move it to the current stage before moving it to a different {{type}}.`,
    { type }
  )
}
