import { ButtonGroup, Tooltip } from '@motion/ui/base'

type PanelHeaderProps = {
  name: string
  children: React.ReactNode
}

export function PanelHeader({ name, children }: PanelHeaderProps) {
  return (
    <div className='p-3 gap-3 flex items-start'>
      <Tooltip content={name} asChild>
        <h3 className='flex-1 text-semantic-neutral-text-default font-bold text-base line-clamp-2 text-ellipsis break-words'>
          {name}
        </h3>
      </Tooltip>

      <ButtonGroup size='small'>{children}</ButtonGroup>
    </div>
  )
}
