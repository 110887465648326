export const Section = ({
  title,
  children,
  action,
}: {
  title: string
  children: React.ReactNode
  action?: React.ReactNode
}) => {
  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-col'>
        <p className='text-sm font-semibold pb-3 text-semantic-neutral-text-default'>
          {title}
        </p>
        {children}
      </div>
      {action}
    </div>
  )
}
