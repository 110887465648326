export function generateMarkdownTable<
  T extends Record<any, unknown>,
  K = keyof T,
>(
  data: T[],
  headers: K[],
  lookupColumn: (key: K) => string,
  lookupData: (item: T, key: keyof T) => string
) {
  if (data.length === 0 || headers.length === 0) {
    return ''
  }

  const headerRow = headers.reduce(
    (acc, key) => {
      acc.push(lookupColumn(key), '|')
      return acc
    },
    ['|']
  )

  const separatorRow = headers.reduce(
    (acc) => {
      acc.push('---', '|')
      return acc
    },
    ['|']
  )

  const dataRows = data.reduce<string[]>((acc, item) => {
    const row = headers.reduce(
      (accRow, key) => {
        accRow.push(lookupData(item, key), '|')
        return accRow
      },
      ['|']
    )
    acc.push(row.join(' '))
    return acc
  }, [])

  return [
    headerRow.join(' '),
    separatorRow.join(' '),
    dataRows.join('\n'),
  ].join('\n')
}

export function generateHTMLTable<T extends Record<any, unknown>, K = keyof T>(
  data: T[],
  headers: K[],
  lookupColumn: (key: K) => string,
  lookupData: (item: T, key: keyof T) => string
) {
  if (data.length === 0 || headers.length === 0) {
    return ''
  }

  const headerRow = headers.reduce<string[]>((acc, h) => {
    acc.push('<th>', lookupColumn(h), '</th>')
    return acc
  }, [])

  const dataRows = data.reduce<string[]>((acc, item) => {
    const row = headers.reduce<string[]>((accRow, key) => {
      accRow.push('<td>', lookupData(item, key), '</td>')
      return accRow
    }, [])
    acc.push('<tr>', row.join(''), '</tr>')
    return acc
  }, [])

  return [
    '<table><tr>',
    headerRow.join(''),
    '</tr>',
    dataRows.join('\n'),
    '</table>',
  ].join('\n')
}
