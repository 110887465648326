import {
  createMergeReducer,
  createStateKeyFilter,
  on,
  SharedStateProvider,
} from '@motion/react-core/shared-state'

import { AppWorkspaceContext } from '~/global/contexts'
import { type ReactNode } from 'react'

type AllWorkspacesContextProps = {
  children: ReactNode
  name?: string
}

/**
 * Provide the 'root' scope AppWorkspaceContext to any children
 */
export const AllWorkspacesContext = (props: AllWorkspacesContextProps) => (
  <SharedStateProvider
    name={props.name}
    merge={pipeToRoot}
    filter={onlyWorkspaceFilter}
  >
    {props.children}
  </SharedStateProvider>
)

const pipeToRoot = createMergeReducer(
  on(AppWorkspaceContext, (_, api) =>
    api.get(AppWorkspaceContext, { scope: 'root' })
  )
)

const onlyWorkspaceFilter = createStateKeyFilter({
  include: [AppWorkspaceContext],
})
