import { type NumberMetadataSchema } from '@motion/shared/custom-fields'
import {
  type NumberCustomField,
  parseCustomFieldInfoFromMaybeDelimitedKey,
  type TypedNameKey,
} from '@motion/ui-logic'

import {
  CUSTOM_FIELD_NO_VALUE,
  useAvailableCustomFieldsGroupedByTypedName,
  // eslint-disable-next-line import-x/no-restricted-paths
} from '~/areas/project-management/pages/pm-v3/grouping/custom-fields'

import { Truncate } from './url'

export type CustomFieldTypedNumberLabelProps = {
  type?: string
  value: number
}
export function CustomFieldTypedNumberLabel({
  type,
  value,
}: CustomFieldTypedNumberLabelProps) {
  const customFields = useAvailableCustomFieldsGroupedByTypedName()
  const customFieldInfo = type
    ? parseCustomFieldInfoFromMaybeDelimitedKey(type)
    : null

  if (!customFieldInfo) {
    return <NumberLabel format='plain' value={value} />
  }

  const customFieldKey = `${customFieldInfo.customFieldType}/${customFieldInfo.name}`

  const format = (
    customFields[customFieldKey as TypedNameKey]?.[0] as NumberCustomField
  ).metadata?.format

  return <NumberLabel format={format} value={value} />
}

export type NumberLabelProps = {
  format?: NumberMetadataSchema['format']
  value: number | typeof CUSTOM_FIELD_NO_VALUE
}

export function NumberLabel({ format = 'plain', value }: NumberLabelProps) {
  let parsedValue: string | number = value

  if (value === CUSTOM_FIELD_NO_VALUE) {
    return <Truncate>{value}</Truncate>
  }
  if (format) {
    const formatter = new Intl.NumberFormat(undefined, {
      style: format === 'percent' ? 'percent' : 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      useGrouping: format === 'formatted' ? true : false,
    })

    if (format === 'percent') {
      parsedValue = value / 100
    }
    parsedValue = formatter.format(
      typeof parsedValue === 'number' ? parsedValue : parseFloat(parsedValue)
    )
  }

  return <Truncate>{parsedValue}</Truncate>
}
