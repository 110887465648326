import { isObjectNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { ConfirmationModal } from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { StageLabel } from '~/global/components/labels'
import { useState } from 'react'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'complete-project': PromptCallbacks<CompleteSetting> & {
      stage?: StageDefinitionSchema
    }
  }
}

type CompleteSetting = 'all' | 'visited' | 'none'

export const CompleteProjectModal = ({
  close,
  stage,
}: ModalTriggerComponentProps<'complete-project'>) => {
  const [completeSetting, setCompleteSetting] =
    useState<CompleteSetting | null>(null)

  return (
    <ConfirmationModal
      closeLabel='Cancel'
      description={
        stage == null || isObjectNoneId(stage) ? (
          <CompleteProjectModalBody
            completeSetting={completeSetting}
            setCompleteSetting={setCompleteSetting}
          />
        ) : (
          <CompleteFlowProjectModalBody
            completeSetting={completeSetting}
            setCompleteSetting={setCompleteSetting}
            stage={stage}
          />
        )
      }
      title='Complete project'
      visible
      onClose={close}
      action={{
        disabled: completeSetting == null,
        onAction: () => completeSetting && close(completeSetting),
        label: 'Complete',
      }}
    />
  )
}

type SharedProps = {
  completeSetting: CompleteSetting | null
  setCompleteSetting: (completeSetting: CompleteSetting) => void
}

const CompleteFlowProjectModalBody = ({
  completeSetting,
  setCompleteSetting,
  stage,
}: SharedProps & { stage: StageDefinitionSchema }) => {
  return (
    <div className='flex flex-col gap-4 pt-1'>
      <RadioContainer>
        <RadioButton
          renderLabel={() => (
            <div className='flex items-center gap-1 grow-0 w-full pr-4'>
              <strong className='text-nowrap'>
                Complete all tasks up to current stage:
              </strong>

              <StageLabel value={stage} />
            </div>
          )}
          checked={completeSetting === 'visited'}
          onChange={() => setCompleteSetting('visited')}
        />
        <p className='text-semantic-neutral-text-default pl-6'>
          Motion will automatically complete all tasks in this project up to the
          current stage.
        </p>
      </RadioContainer>

      <RadioContainer>
        <RadioButton
          label={
            <span>
              <strong>Complete all tasks:</strong>
            </span>
          }
          checked={completeSetting === 'all'}
          onChange={() => setCompleteSetting('all')}
        />
        <p className='text-semantic-neutral-text-default pl-6'>
          Motion will automatically complete all tasks in this project including
          all tasks in stages that have not started yet
        </p>
      </RadioContainer>

      <RadioContainer>
        <RadioButton
          label={
            <span>
              <strong>Don’t Complete any tasks</strong>
            </span>
          }
          checked={completeSetting === 'none'}
          onChange={() => setCompleteSetting('none')}
        />
        <p className='text-semantic-neutral-text-default pl-6'>
          Motion will not automatically complete any tasks in this project. They
          will be unscheduled.
        </p>
      </RadioContainer>
    </div>
  )
}

const RadioContainer = classed('div', {
  base: 'flex flex-col gap-1',
})

function CompleteProjectModalBody({
  completeSetting,
  setCompleteSetting,
}: SharedProps) {
  return (
    <div className='flex flex-col gap-2 pt-1'>
      <RadioButton
        label='Complete project only'
        checked={completeSetting === 'none'}
        onChange={() => setCompleteSetting('none')}
      />
      <RadioButton
        label='Complete project and all tasks'
        checked={completeSetting === 'all'}
        onChange={() => setCompleteSetting('all')}
      />
    </div>
  )
}
