import { Button } from '@motion/ui/base'
import { isMobileExperience } from '@motion/web-base/env'

import { YoutubeVideo } from '../../../components/Common/YoutubeVideo/YoutubeVideo'
import { useOnboardingVideo } from '../../../localServices/experiments'

export interface OneMinuteVideoProps {
  onComplete: () => void
}

export function OneMinuteVideo({ onComplete }: OneMinuteVideoProps) {
  const isMobile = isMobileExperience()

  const amplitudePayload = useOnboardingVideo()

  return (
    <div className='flex flex-grow flex-col items-center pb-[100px] w-full h-full px-6 gap-6 lg:gap-8 mt-12'>
      <h1 className='text-light-1200 text-lg lg:text-xl lg:leading-normal font-semibold text-center'>
        {amplitudePayload.text}
      </h1>
      <YoutubeVideo
        videoId={amplitudePayload.videoId}
        onEnd={onComplete}
        muteOnStart={false}
      />
      {isMobile && (
        <Button onClick={onComplete} fullWidth>
          <div className='w-full text-center'>Continue</div>
        </Button>
      )}
    </div>
  )
}
