import { getIsTeamTrialSetToCancel } from '@motion/ui-logic'
import {
  isTeamSubscription,
  isValidStatus,
} from '@motion/web-common/subscriptions'

import { useMotionLocalStorage } from '~/localServices/storage'
import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'
import { type StripeSubscription } from '~/state/userSlice'
import { selectStripeSubscription } from '~/state/userSlice'

import { useIsNoCardTrialing } from './use-is-no-card-trialing'

export function useUpsellInfo() {
  const team = useAppSelector(selectTeam)
  const userStripeSubscription = useAppSelector(selectStripeSubscription)

  const isTeamTrial = getIsTeamTrialSetToCancel(team?.pmTeamSubscription)
  const isNoCardTrialing = useIsNoCardTrialing()

  const { stripeSubscription } = useMotionLocalStorage(['stripeSubscription'])

  const isIndividualNoCardTrial = !team && isNoCardTrialing

  const isOnTeamMonthly =
    ((!isTeamTrial || team?.pmTeamSubscription?.status === 'active') &&
      team?.pmTeamSubscription?.isMonthly &&
      team?.pmTeamSubscription?.status !== 'canceled') ??
    false

  const isIndividualOrLegacyTeam =
    (userStripeSubscription &&
      teamIsIndividualOrLegacy(userStripeSubscription)) ??
    false

  const showTeamAnnualUpgrade = isOnTeamMonthly || isIndividualOrLegacyTeam

  const showIndividualAnnualUpgrade =
    isIndividualOrLegacyTeam &&
    userStripeSubscription?.plan?.interval === 'month' &&
    !isIndividualNoCardTrial

  return {
    isIndividualNoCardTrial,
    isOnTeamMonthly,
    showIndividualAnnualUpgrade,
    showTeamAnnualUpgrade,
    stripeSubscription,
    team,
  }
}

const teamIsIndividualOrLegacy = (
  stripeSubscription: StripeSubscription
): boolean =>
  !isTeamSubscription(stripeSubscription) ||
  !isValidStatus(stripeSubscription.status)
