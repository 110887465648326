import { useOnValueChange } from '@motion/react-core/hooks'
import { type TaskUrlSearchParams } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  ConnectedCreateFlowTemplateModal,
  ConnectedUrlFlowTemplateModal,
  type FlowTemplateUrlSearchParams,
} from '~/areas/flows'
import { type ProjectUrlSearchParams } from '~/areas/project/modals/project-modal/utils'
import { type SetupProjectSearchParams } from '~/areas/project/modals/setup-project-modal/utils'
import {
  TemplateProjectModal,
  TemplateTaskModal,
} from '~/areas/project-management/modals'
import { ConnectedTaskModal } from '~/areas/tasks/modals/task-modal'
import { useSearchParams, useUriByRouteId } from '~/routing'
import { useNavigate } from 'react-router'

import { type AttachmentSearchParams } from '../attachments/modals/attachment-preview-modal'
import { ConnectedCreateProjectModal } from '../project/modals/create-project-modal'
import { ConnectedProjectModal } from '../project/modals/project-modal'
import { ConnectedSetupProjectModal } from '../project/modals/setup-project-modal'

function useAttachmentUrlRedirect() {
  const navigate = useNavigate()
  const getRouteUri = useUriByRouteId()

  const { attachment } = useSearchParams<AttachmentSearchParams>()

  useOnValueChange(
    attachment,
    (attachment) => {
      if (attachment == null) return

      const attachmentUri = getRouteUri('attachment-preview', {
        attachmentId: attachment,
      })

      recordAnalyticsEvent('ATTACHMENT_LINK_REDIRECTED')

      navigate(attachmentUri)
    },
    {
      triggerOnFirstRender: true,
    }
  )
}

export const GlobalTaskProjectModals = () => {
  const { task, project, template, ...params } = useSearchParams<
    TaskUrlSearchParams &
      ProjectUrlSearchParams &
      FlowTemplateUrlSearchParams &
      SetupProjectSearchParams
  >()

  // Temporary redirect for old attachment URLs
  useAttachmentUrlRedirect()

  const isProjectCreate = project === 'create'
  const isTemplateCreate = template === 'create'

  const showProjectModal = project != null && !isProjectCreate
  const showProjectSetupModal = isProjectCreate && params.flowTemplateId != null
  const showCreateProjectModal = isProjectCreate && !showProjectSetupModal

  const showFlowTemplateModal =
    template != null &&
    template !== 'create' &&
    !showProjectModal &&
    !showProjectSetupModal &&
    task == null
  const showCreateFlowTemplateModal =
    isTemplateCreate && !showProjectModal && task == null

  const shouldRenderTemplateTaskModal = task != null && template === 'new'
  const shouldRenderTemplateProjectModal =
    showProjectModal && template === 'new'

  return (
    <>
      {shouldRenderTemplateProjectModal ? (
        <TemplateProjectModal />
      ) : (
        <ConnectedProjectModal open={showProjectModal} />
      )}

      {shouldRenderTemplateTaskModal ? (
        <TemplateTaskModal />
      ) : (
        <ConnectedTaskModal open={task != null} />
      )}

      <ConnectedCreateProjectModal open={showCreateProjectModal} />
      <ConnectedSetupProjectModal open={showProjectSetupModal} />
      <ConnectedCreateFlowTemplateModal open={showCreateFlowTemplateModal} />
      <ConnectedUrlFlowTemplateModal open={showFlowTemplateModal} />
    </>
  )
}
