import { isDateBetweenDates, parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import {
  useScheduleAssistantCalendarContext,
  useSendScheduleAssistantCalendarContext,
} from './use-calendar-state'

export function useScheduleAssistantCalendarDateNavigation() {
  const setScheduleAssistantState = useSendScheduleAssistantCalendarContext()
  const [{ selectedDate, syncSessionRange }] =
    useScheduleAssistantCalendarContext()

  const handleDateChange = (op: number) => {
    const newDate = selectedDate.plus({ days: 7 * op })

    // Check if date is within syncSessionRange
    if (
      syncSessionRange != null &&
      !isDateBetweenDates(
        parseDate(syncSessionRange.start),
        parseDate(syncSessionRange.end),
        newDate
      )
    ) {
      return
    }

    setScheduleAssistantState({ selectedDate: newDate })
  }

  const handleGoToToday = () => {
    const now = DateTime.now()

    setScheduleAssistantState({ selectedDate: now.startOf('day') })
    // TODO: Revisit analytics
    // recordAnalyticsEvent('CALENDAR_CHANGE_BASE_DATE', {
    //   direction: 'today',
    //   calendarView,
    // })
  }

  return {
    today: handleGoToToday,
    back: () => handleDateChange(-1),
    forward: () => handleDateChange(1),
  }
}
