import { SHORT_TASK_DURATION } from '@motion/ui-logic'

import { type UseFormReturn } from 'react-hook-form'

import {
  type TemplateTaskFormFields,
  type TemplateTaskFormOptions,
} from './types'

export function getHiddenAndDisabledFields(
  form: UseFormReturn<TemplateTaskFormFields>,
  options: TemplateTaskFormOptions
) {
  const { currentUserId, chunkDurations } = options

  const isAutoScheduled = form.watch('isAutoScheduled')
  const priority = form.watch('priorityLevel')
  const assigneeId = form.watch('assigneeId')
  const duration = form.watch('duration')

  const isOwnTask = currentUserId != null && currentUserId === assigneeId

  const hiddenFields: Set<keyof TemplateTaskFormFields> = new Set()
  const disabledFields: Set<keyof TemplateTaskFormFields> = new Set()

  if (!isOwnTask) {
    // We can select a schedule only for task assigned to ourselves
    hiddenFields.add('scheduleId')
  }

  disabledFields.add('workspaceId')
  disabledFields.add('projectId')
  disabledFields.add('startDate')
  disabledFields.add('deadlineDate')

  // Only autoscheduled tasks can have a schedule
  if (!isAutoScheduled) {
    hiddenFields.add('scheduleId')
  }

  if (duration === SHORT_TASK_DURATION) {
    hiddenFields.add('startDate')
    hiddenFields.add('scheduleId')
  }

  if (priority === 'ASAP') {
    disabledFields.add('startDate')
    disabledFields.add('deadlineDate')
  }

  if (chunkDurations.length === 0 || !isAutoScheduled) {
    hiddenFields.add('minChunkDuration')
  }

  return { hiddenFields, disabledFields }
}
