import {
  type ActionItem,
  ActionList,
  DatePicker,
  PopoverButton,
  PopoverTrigger,
} from '@motion/ui/base'
import { type LogicalDateFilterSchema } from '@motion/zod/client'

import { useMemo } from 'react'

import { type DatePickerContentProps } from './types'

const DATE_CONDITIONS: {
  id: LogicalDateFilterSchema['operator']
  label: string
}[] = [
  { id: 'lt', label: 'Before' },
  { id: 'lte', label: 'On or before' },
  { id: 'equals', label: 'On' },
  { id: 'gte', label: 'On or after' },
  { id: 'gt', label: 'After' },
]

export function LogicalDatePicker(
  props: DatePickerContentProps<LogicalDateFilterSchema>
) {
  const { value, onSelect } = props

  const options = useMemo<ActionItem[]>(() => {
    return DATE_CONDITIONS.map<ActionItem>((opt) => {
      return {
        id: opt.id,
        content: opt.label,
        onAction: () => {
          onSelect({ ...value, operator: opt.id })
        },
      }
    })
  }, [onSelect, value])

  const selectedOption =
    DATE_CONDITIONS.find((x) => x.id === props.value.operator) ??
    DATE_CONDITIONS[2]

  return (
    <div className='flex flex-col gap-2'>
      <PopoverTrigger
        renderPopover={({ close }) => (
          <ActionList items={options} onActionAnyItem={close} />
        )}
      >
        <PopoverButton>{selectedOption.label}</PopoverButton>
      </PopoverTrigger>
      <DatePicker
        weekStartDay={props.calendarStartDay}
        value={[value.value]}
        onChange={(val) => {
          if (typeof val === 'string') {
            props.onSelect({ ...props.value, value: val })
          }
        }}
        mode='single'
      />
    </div>
  )
}
