import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { isoDateToPixel } from './date-array-utils'

// Project length when no start or end date is set
export const DEFAULT_PROJECT_LENGTH_IN_WEEKS = 1

export const getProjectStartAndEnd = (
  project: ProjectSchema,
  dayPx: number
) => {
  const startDate = project.startDate
    ? isoDateToPixel(project.startDate, dayPx)
    : project.dueDate
      ? isoDateToPixel(
          DateTime.fromISO(project.dueDate)
            .minus({
              weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS,
            })
            .toISO(),
          dayPx
        )
      : 0

  const dueDate = project.dueDate
    ? isoDateToPixel(
        // Add one day to the due date to make it inclusive
        DateTime.fromISO(project.dueDate).plus({ day: 1 }).toISO(),
        dayPx
      )
    : project.startDate
      ? isoDateToPixel(
          DateTime.fromISO(project.startDate)
            .plus({
              weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS,
            })
            .toISO(),
          dayPx
        )
      : 0

  return {
    start: startDate,
    end: dueDate,
  }
}
