import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useAllWorkspaces } from '~/global/hooks'
import { type ReactNode } from 'react'

export type MultiWorkspaceDropdownProps = MultiWorkspaceDropdownContentProps & {
  children?: ReactNode
}

export const MultiWorkspaceDropdown = (props: MultiWorkspaceDropdownProps) => {
  const { children, ...rest } = props

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => <MultiWorkspaceDropdownContent {...rest} />}
    >
      {children}
    </PopoverTrigger>
  )
}

type MultiWorkspaceDropdownContentProps = {
  onChange: (workspace: WorkspaceSchema[]) => void
  selectedWorkspaceIds: WorkspaceSchema['id'][]
  filterWorkspaces?: (workspace: WorkspaceSchema) => boolean
}
export const MultiWorkspaceDropdownContent = ({
  onChange,
  selectedWorkspaceIds,
  filterWorkspaces,
}: MultiWorkspaceDropdownContentProps) => {
  const items = useAllWorkspaces({ scope: 'root' })
  const workspaces = filterWorkspaces ? items.filter(filterWorkspaces) : items

  return (
    <SearchableList
      itemType='checkbox'
      items={workspaces}
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={(item) => selectedWorkspaceIds.includes(item.id)}
      onSelect={onChange}
      renderItem={(item) => (
        <span className='max-w-xs truncate'>{item.name}</span>
      )}
      inputProps={{ placeholder: 'Choose workspace...' }}
    />
  )
}
