import {
  AutoscheduleSolid,
  BellSolid,
  BellXSolid,
  CalendarSolid,
} from '@motion/icons'
import { FieldButton, PopoverTrigger } from '@motion/ui/base'
import { isValidTaskDeadlineDateOption } from '@motion/ui-logic/pm/task'

import { type DeadlineDropdownProps } from '~/areas/project-management/components'
import { WarnOnToggle } from '~/areas/project-management/components/warn-on-toggle'
import { DateTriggerButton } from '~/areas/task-project/components'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskDeadlineUpdater, useTaskUpdater } from '~/areas/tasks/hooks'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

export const DeadlineField = () => {
  const { form, disabledFields } = useTaskForm()
  const updateTaskDeadline = useTaskDeadlineUpdater()

  const { control, watch } = form

  const { field } = useController({
    name: 'dueDate',
    control,
  })

  const selectedDeadlineDate = field.value

  const taskId = watch('id')
  const startDate = watch('startDate')
  const type = watch('type')
  const projectId = watch('projectId')
  const stageDefinitionId = watch('stageDefinitionId')

  const isDisabled = disabledFields.has('dueDate')

  const isAutoScheduled = watch('isAutoScheduled')
  const priorityLevel = watch('priorityLevel')
  const isASAP = priorityLevel === 'ASAP'

  // This is simply a type check because recurring tasks don't have deadline
  if (type === 'RECURRING_TASK') return null

  const onChange: DeadlineDropdownProps['onChange'] = (value) => {
    if (taskId != null) {
      return updateTaskDeadline(taskId, value)
    }

    field.onChange(value)
  }

  return (
    <TaskDeadlineDropdown
      contextProps={
        taskId != null
          ? { taskId }
          : {
              projectId,
              stageDefinitionId,
            }
      }
      value={selectedDeadlineDate}
      onChange={(value) => {
        onChange(value)
      }}
      isValidDateOption={(date) =>
        isValidTaskDeadlineDateOption({ type, startDate }, date)
      }
    >
      <DateTriggerButton
        label='Deadline'
        icon={isAutoScheduled ? <AutoscheduleSolid /> : <CalendarSolid />}
        disabled={isDisabled}
        onClear={
          selectedDeadlineDate == null ? undefined : () => onChange(null)
        }
        date={isASAP ? null : selectedDeadlineDate}
        placeholder={isASAP ? 'ASAP' : 'No deadline'}
        clearTooltipContent='Remove deadline'
        endButton={
          <DeadlineWarnButton
            disabled={isDisabled}
            isAutoScheduled={isAutoScheduled}
          />
        }
        sentiment={isAutoScheduled ? 'ai' : 'neutral'}
      />
    </TaskDeadlineDropdown>
  )
}

type DeadlineWarnButtonProps = {
  isAutoScheduled: boolean
  disabled: boolean
}

function DeadlineWarnButton({
  disabled,
  isAutoScheduled,
}: DeadlineWarnButtonProps) {
  const {
    hiddenFields,
    form: { watch },
  } = useTaskForm()
  const ignoreOnPastDue = watch('ignoreWarnOnPastDue')

  return (
    <PopoverTrigger
      renderPopover={() => (
        <div className='p-2'>
          {!hiddenFields.has('ignoreWarnOnPastDue') && <FormWarnOnToggle />}
        </div>
      )}
    >
      <FieldButton
        size='xsmall'
        variant='outlined'
        sentiment={isAutoScheduled ? 'ai' : 'neutral'}
        iconOnly
        disabled={disabled}
      >
        <div className='p-0.5'>
          {ignoreOnPastDue ? <BellXSolid /> : <BellSolid />}
        </div>
      </FieldButton>
    </PopoverTrigger>
  )
}

const FormWarnOnToggle = () => {
  const { form, disabledFields } = useTaskForm()
  const updateTask = useTaskUpdater()

  const { control, watch } = form

  const { field } = useController({
    name: 'ignoreWarnOnPastDue',
    control,
  })

  const taskId = watch('id')
  const ignoreOnPastDue = watch('ignoreWarnOnPastDue')
  const disabled = disabledFields.has('ignoreWarnOnPastDue')

  const onChange = (value: boolean) => {
    if (taskId != null) {
      return updateTask(taskId, { ignoreWarnOnPastDue: value })
    }

    field.onChange(value)
  }

  return (
    <WarnOnToggle
      checked={!ignoreOnPastDue}
      disabled={disabled}
      onChange={(checked) => onChange(!checked)}
    />
  )
}
