import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useUploadWorkspaceImage } from '~/areas/project-management/hooks'
import { useMemo, useState } from 'react'

import {
  ActivityFeedContext,
  type ActivityFeedContextApi,
} from './activity-feed-context'

import { type ActivityFeedType } from '../types'

export type ActivityFeedProviderProps = {
  children: React.ReactNode
  workspaceId: string
} & ActivityFeedType

export function ActivityFeedProvider({
  children,
  type,
  target,
  workspaceId,
}: ActivityFeedProviderProps) {
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null)
  const onUploadImage = useUploadWorkspaceImage(workspaceId, {
    taskId: type === 'task' ? target.id : '',
    projectId: type === 'project' ? target.id : '',
    isRecurringTask: false,
  })

  const value = useMemo<ActivityFeedContextApi>(() => {
    if (type === 'project') {
      return {
        workspaceId,
        type,
        target: target as ProjectSchema,
        onUploadImage,
        editingCommentId,
        setEditingCommentId,
      }
    }

    return {
      workspaceId,
      type,
      target: target as TaskSchema,
      onUploadImage,
      editingCommentId,
      setEditingCommentId,
    }
  }, [type, workspaceId, target, onUploadImage, editingCommentId])

  return (
    <ActivityFeedContext.Provider value={value}>
      {children}
    </ActivityFeedContext.Provider>
  )
}
