import { API } from '@motion/rpc'
import { createQueryFilter, MODEL_CACHE_KEY } from '@motion/rpc-cache'
import { templateStr } from '@motion/ui-logic'

const KEYS_TO_UPDATE = [
  MODEL_CACHE_KEY as string[],
  API.tasksV2.queryKeys.query as unknown as string[],
  ['v2/tasks', 'by-id', '{{taskId}}'],
  ['v2/scheduled-entities'],
]

export function getTaskQueryFilters(taskId: string) {
  return createQueryFilter(
    KEYS_TO_UPDATE.map((key) => key.map((k) => templateStr(k, { taskId })))
  )
}
