import { isNoneId } from '@motion/shared/identifiers'
import { Button, Tooltip } from '@motion/ui/base'
import { createNoneProject } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type WorkspaceSchema } from '@motion/zod/client'

import {
  getGhostDisabledTooltipContent,
  WorkspaceSectionContainer,
  WorkspaceSectionTitle,
} from '~/areas/task-project/components'
import {
  StageField,
  type StageFieldProps,
  WorkspaceField,
  WorkspaceProjectField,
} from '~/areas/task-project/fields'
import {
  useMoveTaskToWorkspaceProject,
  useTaskStageUpdater,
} from '~/areas/tasks/hooks'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useTaskModalState } from '../contexts'
import { useTaskForm } from '../hooks'

/**
 *
 * @deprecated replaced with ControlledWorkspaceFolderProjectField that supports folders
 */
export const ControlledWorkspaceProjectField = () => {
  const { toggleSidePanel, sidePanelOpen } = useTaskModalState()
  const { form, disabledFields, hiddenFields } = useTaskForm()
  const { control, watch } = form
  const context = useRouteAnalyticsMetadata()
  const updateTaskStage = useTaskStageUpdater()
  const moveTaskTo = useMoveTaskToWorkspaceProject()

  const { field: workspaceIdField } = useController({
    name: 'workspaceId',
    control,
  })
  const { field: projectIdField } = useController({
    name: 'projectId',
    control,
  })

  const { field: stageIdField } = useController({
    name: 'stageDefinitionId',
    control,
  })

  const taskId = watch('id')

  const selectedWorkspace = useWorkspaceById(workspaceIdField.value)
  const selectedProject =
    useProject(projectIdField.value) ??
    createNoneProject(workspaceIdField.value)

  const isUnvisitedStage = watch('isUnvisitedStage')
  const isGhost =
    isUnvisitedStage &&
    disabledFields.has('workspaceId') &&
    disabledFields.has('projectId')

  if (selectedWorkspace == null) return null

  const hasProject = !isNoneId(selectedProject.id)

  const updateWorkspaceProject = async (
    newWorkspaceId: string,
    newProjectId: string | null
  ) => {
    if (
      newWorkspaceId === selectedWorkspace.id &&
      newProjectId === selectedProject.id
    ) {
      return
    }

    // Creating a new task
    if (taskId == null) {
      if (newWorkspaceId !== workspaceIdField.value) {
        workspaceIdField.onChange(newWorkspaceId)
      }
      if (newProjectId !== projectIdField.value) {
        projectIdField.onChange(newProjectId)
      }

      return
    }

    if (newProjectId == null && sidePanelOpen) {
      toggleSidePanel()
    }

    // When updating an existing task, we trigger the update right away
    moveTaskTo(taskId, newWorkspaceId, newProjectId)
  }

  const onWorkspaceChange = (newWorkspace: WorkspaceSchema) =>
    updateWorkspaceProject(newWorkspace.id, null)

  const onWorkspaceProjectChange = (
    newWorkspaceId: string,
    newProjectId: string | null
  ) => updateWorkspaceProject(newWorkspaceId, newProjectId)

  const onStageChange: StageFieldProps['onChange'] = (stage) => {
    if (taskId != null) {
      return updateTaskStage(taskId, stage)
    }

    stageIdField.onChange(stage.id)
  }

  return (
    <WorkspaceSectionContainer>
      <WorkspaceSectionTitle
        showProject={!hiddenFields.has('projectId')}
        showStage={stageIdField.value != null}
      />

      <Tooltip
        content={!isGhost ? null : getGhostDisabledTooltipContent('workspace')}
      >
        <WorkspaceField
          workspaceId={workspaceIdField.value}
          onChange={onWorkspaceChange}
          disabled={disabledFields.has('workspaceId')}
        />
      </Tooltip>

      {!hiddenFields.has('projectId') && (
        <Tooltip
          content={!isGhost ? null : getGhostDisabledTooltipContent('project')}
        >
          <WorkspaceProjectField
            workspaceId={workspaceIdField.value}
            projectId={projectIdField.value}
            onChange={onWorkspaceProjectChange}
            disabled={disabledFields.has('projectId')}
            suffix={
              hasProject ? (
                <Button
                  size='xsmall'
                  variant='outlined'
                  sentiment='neutral'
                  onClick={() => {
                    toggleSidePanel()
                    recordAnalyticsEvent(
                      sidePanelOpen
                        ? 'TASK_SIDEBAR_CLOSE'
                        : 'TASK_SIDEBAR_OPEN',
                      context
                    )
                  }}
                >
                  {sidePanelOpen ? 'Close' : 'Open'}
                </Button>
              ) : undefined
            }
          />
        </Tooltip>
      )}

      {stageIdField.value != null && (
        <StageField
          workspaceId={workspaceIdField.value}
          projectId={projectIdField.value}
          stageDefinitionId={stageIdField.value}
          onChange={onStageChange}
        />
      )}
    </WorkspaceSectionContainer>
  )
}
