import { useFullCalendarStructuredEvents } from './use-full-calendar-transform'

import { useCalendarState, useStateScheduledEntities } from '../../hooks'

/*
  Grabs the events from state and returns them in a format that FullCalendar can understand.
*/
export const useCalendarFcEvents = () => {
  const [calendarState] = useCalendarState()
  const { data } = useStateScheduledEntities()

  return useFullCalendarStructuredEvents({
    newEventData: calendarState.selectedCalendarEvent?.new,
    scheduledEntities: data?.scheduledEntities,
    selectedDate: calendarState.selectedDate,
  })
}
