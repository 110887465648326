import { CalendarSolid } from '@motion/icons'

import { type ReactNode } from 'react'

import { GenericField } from './generic-field'

interface DateFieldProps {
  children: ReactNode
}

export const DateField = (props: DateFieldProps) => {
  const { children } = props

  return <GenericField icon={CalendarSolid}>{children}</GenericField>
}
