import { useIsTaskStarted } from '~/areas/tasks/hooks'
import { type ScheduledTaskWithRelation } from '~/global/proxies'

import { useNearestAgendaTask } from './use-nearest-agenda-task'

export function useStartedAgendaTask(): ScheduledTaskWithRelation | null {
  const nearestTask = useNearestAgendaTask()

  const { isStarted, isSiblingStarted } = useIsTaskStarted(nearestTask?.id)

  if (nearestTask?.task == null || !isStarted || isSiblingStarted) {
    return null
  }

  return nearestTask
}
