import { TextField } from '@motion/ui/forms'
import { Sentry } from '@motion/web-base/sentry'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useState } from 'react'

import { PMOnboardingProgressBar } from './shared/pm-onboarding-progress-bar'
import { WorkspaceTable } from './shared/WorkspaceTable'
import { type Workspace } from './types'

import { PrimaryButton } from '../../../components/Common'

type CreateWorkspaceProps = {
  workspace: Workspace
  onUpdateWorkspaceName: (workspaceName: string) => void
  onComplete: () => Promise<any>
}

export function CreateWorkspace({
  workspace,
  onUpdateWorkspaceName,
  onComplete,
}: CreateWorkspaceProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { selectIsOnboarding } = useOnboardingApi()
  const isOnboarding = selectIsOnboarding()

  async function onSubmit() {
    setError('')
    setLoading(true)

    await onComplete().catch((error) => {
      Sentry.captureException(error, {
        tags: { position: 'onboardingCreateWorkspace' },
      })
      setLoading(false)
      setError(error.message)
    })
  }

  return (
    <div className='lg:mt-12 h-full w-full'>
      <div className='mt-4 flex lg:h-full lg:mt-0'>
        <div className='px-6 lg:px-0 flex flex-1 lg:-translate-y-16 flex-col items-center lg:justify-center'>
          <div className='lg:w-[400px]'>
            {!isOnboarding && <PMOnboardingProgressBar numFilled={1} />}
            <h1 className='text-lg lg:text-[28px] lg:leading-normal font-semibold select-none text-semantic-neutral-text-default'>
              Create your first workspace
            </h1>

            <p className='text-semantic-neutral-text-subtle mt-4 text-base'>
              A workspace is a space where you can organize your tasks and
              projects individually or with a team.
            </p>

            <div className='mt-4'>
              <div className=''>
                <TextField
                  autoFocus
                  onChange={onUpdateWorkspaceName}
                  placeholder='Enter workspace name'
                  value={workspace.workspaceName}
                />

                {error && (
                  <div className='break-words px-2 py-2 pr-20'>
                    <p className='text-sm text-red-600'>{error}</p>
                  </div>
                )}
              </div>

              <div className='mt-4 flex justify-end'>
                <PrimaryButton
                  onClick={onSubmit}
                  loading={loading}
                  className='w-full lg:w-auto'
                  disabled={!workspace.workspaceName.trim().length}
                >
                  Next
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className='h-full flex-[1.2_1.2_0%] hidden lg:flex'>
          <WorkspaceTable workspace={workspace} step='workspaceName' />
        </div>
      </div>
    </div>
  )
}
