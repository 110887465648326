import { type FolderSchema, type ProjectSchema } from '@motion/rpc-types'
import { type VirtualizedTreeNode } from '@motion/ui/base'

import { type LeafNodeType } from './hooks'

export const getNodeType = (
  node: VirtualizedTreeNode,
  folders: Record<string, FolderSchema>,
  projects: Record<string, ProjectSchema>
): LeafNodeType | 'NONE' => {
  const folder = folders[node.id]
  const project = projects[node.id]

  if (folder?.type === 'WORKSPACE') return 'WORKSPACE'
  if (folder?.type === 'USER') return 'FOLDER'
  if (project) return 'PROJECT'
  return 'NONE'
}
