import { useSharedState } from '@motion/react-core/shared-state'
import { Button, SearchableDropdown } from '@motion/ui/base'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { isDefaultViewId } from '../../../../../utils'
import { DEFAULT_VIEW, TeamScheduleActiveViewOptionsKey } from '../../context'
import { TeamSchedulePageContextKey } from '../../context/page-data-context'
import { areViewsEqual, toViewDefinition } from '../utils'

export const ConnectedSaveViewButton = () => {
  const modalApi = useModalApi()

  const [ctx] = useSharedState(TeamSchedulePageContextKey)
  const [filterState] = useSharedState(ActiveFilterKey)
  const [viewState] = useSharedState(TeamScheduleActiveViewOptionsKey)

  const definition = toViewDefinition(filterState, viewState)

  const currentView = ctx.views.byId[viewState.viewId] ?? DEFAULT_VIEW

  if (areViewsEqual(definition, currentView.definition)) {
    return null
  }

  const isDefaultView = isDefaultViewId(viewState.viewId)
  function onSaveView(args: { asNew: boolean; personal: boolean }) {
    recordAnalyticsEvent('TEAM_SCHEDULE_SAVE_VIEW', args)
    modalApi.open('save-team-view', args)
  }

  const selectableOptions = [
    !isDefaultView && {
      id: 'saveToCurrentView',
      isPrivate: currentView.isPrivate,
      label: 'Save changes to current view',
      newView: false,
    },
    {
      id: 'newPersonalView',
      isPrivate: true,
      label: 'Save as new personal view',
      newView: true,
    },
    {
      id: 'newWorkspaceView',
      isPrivate: false,
      label: 'Save as new workspace view',
      newView: true,
    },
  ].filter(Boolean)

  return (
    <SearchableDropdown
      items={selectableOptions}
      renderItem={(item) => item.label}
      onChange={(item) =>
        onSaveView({ personal: item.isPrivate, asNew: item.newView })
      }
    >
      <Button onClick={() => null} size='small' variant='muted'>
        Save view
      </Button>
    </SearchableDropdown>
  )
}
