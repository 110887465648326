import {
  type AttendeeStatus,
  type CalendarEventSchemaV2,
} from '@motion/zod/client'

import { useHostCalendar } from '~/areas/calendar/hooks'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useCallback, useMemo } from 'react'

import { useUpdateEventRSVP } from '../actions'

export const useEventRSVPData = (
  event: Pick<
    CalendarEventSchemaV2,
    'id' | 'attendees' | 'calendarId' | 'email' | 'organizer'
  >
) => {
  const updateRSVP = useUpdateEventRSVP()
  const { hostCalendar } = useHostCalendar(event.email, event.calendarId)
  const emailAccounts = useAppSelector(selectEmailAccounts)

  const curUserAttendee = event.attendees.find((a) => a.email === event.email)
  const rsvp = curUserAttendee?.status ?? 'tentative'

  const onChangeRSVP = useCallback(
    async (status: AttendeeStatus) => {
      return updateRSVP(event.id, status)
    },
    [event, updateRSVP]
  )

  const canRSVP = useMemo(() => {
    const isOwnEventAndAttendee = emailAccounts.some(
      (emailAccount) =>
        emailAccount.email === event.email &&
        event.attendees.some((att) => att.email === emailAccount.email)
    )

    return (
      isOwnEventAndAttendee &&
      // The organizer in Outlook/Icloud is always a confirmed attendee and that cannot be changed
      (event.organizer?.email !== event.email ||
        hostCalendar?.providerType === 'GOOGLE')
    )
  }, [
    emailAccounts,
    event.attendees,
    event.email,
    event.organizer?.email,
    hostCalendar?.providerType,
  ])

  return {
    canRSVP,
    userRSVP: rsvp,
    onChangeRSVP,
  }
}
