import {
  AssigneeDropdown,
  type AssigneeDropdownProps,
} from '~/areas/project-management/components'

export type MultiPersonProps = Omit<
  Extract<AssigneeDropdownProps, { type: 'multi' }>,
  'type' | 'removeUnassignedOption'
>

export const MultiPersonDropdown = (props: MultiPersonProps) => (
  <AssigneeDropdown {...props} type='multi' removeUnassignedOption>
    {props.children}
  </AssigneeDropdown>
)
