import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { useEmptyResultsIcon } from '~/global/hooks'

import {
  isSearchingAttachments,
  isSearchingEvents,
  isSearchingTasks,
  isValidSearch,
  type SearchType,
} from '../utils'

interface EmptyResultsProps {
  searchQuery: string
  searchType: SearchType
  disableSearchTypeEvents: boolean
  onClickTasks: () => void
  onClickArchive: () => void
  onClickEvents: () => void
  onClickAttachments: () => void
}

export const EmptyResults = (props: EmptyResultsProps) => {
  const {
    searchQuery,
    searchType,
    disableSearchTypeEvents,
    onClickTasks,
    onClickArchive,
    onClickEvents,
    onClickAttachments,
  } = props

  const Illustration = useEmptyResultsIcon()

  return (
    <div className='grid place-items-center h-full'>
      <div className='flex flex-col gap-4 items-center'>
        <Illustration className='w-32 h-32' />
        {isValidSearch(searchQuery) ? (
          <>
            <div className='flex flex-col gap-2 items-center'>
              <span className='text-semantic-neutral-text-default font-semibold text-center break-all'>
                {templateStr('No {{type}} for “{{ searchQuery }}”', {
                  type: isSearchingTasks(searchType)
                    ? 'tasks'
                    : isSearchingEvents(searchType)
                      ? 'events'
                      : 'attachments',
                  searchQuery,
                })}
              </span>
              <span className='text-semantic-neutral-text-subtle'>
                {isSearchingTasks(searchType)
                  ? searchType.archived
                    ? "The task you're looking for might not be archived"
                    : "The task you're looking for might be archived."
                  : templateStr('Are you searching for a {{entities}}?', {
                      entities: [
                        !isSearchingTasks(searchType) && 'task',
                        !isSearchingEvents(searchType) && 'event',
                        !isSearchingAttachments(searchType) && 'attachment',
                      ]
                        .filter(Boolean)
                        .join(' or '),
                    })}
              </span>
            </div>
            <SearchAlternativesOptions
              onClickTasks={onClickTasks}
              onClickArchive={onClickArchive}
              onClickEvents={onClickEvents}
              onClickAttachments={onClickAttachments}
              showTasksButton={
                !isSearchingTasks(searchType) ||
                (isSearchingTasks(searchType) && searchType.archived)
              }
              showArchiveButton={
                isSearchingTasks(searchType) && !searchType.archived
              }
              showEventsButton={
                !isSearchingEvents(searchType) && !disableSearchTypeEvents
              }
              showAttachmentsButton={!isSearchingAttachments(searchType)}
            />
          </>
        ) : (
          <span className='text-semantic-neutral-text-subtle font-semibold'>
            {templateStr('Search for a {{entities}} above', {
              entities: [
                'task',
                'project',
                !disableSearchTypeEvents && 'event',
                'attachment',
              ]
                .filter(Boolean)
                .reduce((result, entity, index, entities) => {
                  if (!result) return entity

                  if (index === entities.length - 1)
                    return `${result} or ${entity}`

                  return `${result}, ${entity}`
                }, ''),
            })}
          </span>
        )}
      </div>
    </div>
  )
}

export type SearchAlternativesOptionsProps = Pick<
  EmptyResultsProps,
  'onClickTasks' | 'onClickArchive' | 'onClickEvents' | 'onClickAttachments'
> & {
  showTasksButton: boolean
  showEventsButton: boolean
  showAttachmentsButton: boolean
  showArchiveButton?: boolean
}

export function SearchAlternativesOptions({
  onClickTasks,
  onClickArchive,
  onClickEvents,
  onClickAttachments,
  showTasksButton,
  showArchiveButton,
  showEventsButton,
  showAttachmentsButton,
}: SearchAlternativesOptionsProps) {
  return (
    <div className='w-full flex flex-col gap-3 items-center'>
      {showArchiveButton && (
        <>
          <Button
            sentiment='neutral'
            variant='outlined'
            onClick={onClickArchive}
          >
            Search archived tasks
          </Button>
          <div className='w-full h-px border-b border-semantic-neutral-border-subtle' />
        </>
      )}

      <div className='flex gap-2'>
        {showTasksButton && (
          <Button sentiment='neutral' variant='outlined' onClick={onClickTasks}>
            Search tasks instead
          </Button>
        )}
        {showEventsButton && (
          <Button
            sentiment='neutral'
            variant='outlined'
            onClick={onClickEvents}
          >
            Search events instead
          </Button>
        )}
        {showAttachmentsButton && (
          <Button
            sentiment='neutral'
            variant='outlined'
            onClick={onClickAttachments}
          >
            Search attachments instead
          </Button>
        )}
      </div>
    </div>
  )
}
