import { ActionList, showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type TaskSchema } from '@motion/zod/client'

import { useAddEventToProject } from '~/areas/event/hooks'
import { useWorkspaceFns } from '~/global/hooks'

export type SchedulingEventDropdownContentProps = {
  close: () => void
  taskId: TaskSchema['id']
  onAction?: () => void
}

export function SchedulingEventDropdownContent({
  close,
  taskId,
  onAction,
}: SchedulingEventDropdownContentProps) {
  const modalApi = useModalApi()
  const { getWorkspaceProjectByTaskId } = useWorkspaceFns()
  const addEventToProject = useAddEventToProject()

  const onChooseExistingEvent = async (eventId: string) => {
    try {
      const project = getWorkspaceProjectByTaskId(taskId)

      if (project == null) {
        throw new Error(
          'Project not found when adding event to project in Schedule Assistant'
        )
      }

      await addEventToProject(eventId, project.workspaceId, project.id, taskId)

      showToast('success', 'Event added to project')
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          taskId,
          eventId,
        },
        tags: {
          position: 'SchedulingEventDropdownContent.onChooseExistingEvent',
        },
      })
      showToast('error', 'Failed to add event to project')
    }
  }

  return (
    <ActionList
      onActionAnyItem={() => {
        close()
        onAction?.()
      }}
      items={[
        {
          content: 'Create event',
          onAction: () => {
            recordAnalyticsEvent('SCHEDULING_EVENT_DROPDOWN_CLICK', {
              type: 'create-event',
            })
            modalApi.open('event-modal', {
              schedulingTaskId: taskId,
            })
          },
        },
        {
          content: 'Choose existing event',
          onAction: () => {
            recordAnalyticsEvent('SCHEDULING_EVENT_DROPDOWN_CLICK', {
              type: 'select-existing',
            })

            void modalApi.prompt('schedule-assistant', {
              mode: 'select-existing',
              onValue(value) {
                void onChooseExistingEvent(value.id)
                close()
              },
              onDismiss() {
                close()
              },
            })
          },
        },
      ]}
    />
  )
}
