import { PlaySolid, StopSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { Button, Tooltip } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { formatTimeRange } from '@motion/ui-logic'

import {
  useIsTaskStarted,
  useStartTask,
  useStopTask,
} from '~/areas/tasks/hooks'
import { useProject } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { useNavigate } from 'react-router'

import {
  EventContainer,
  EventContentContainer,
  EventHeader,
  EventMessage,
  EventOptionsContainer,
} from './common'

export const UpcomingTaskEvent = ({
  entity,
}: {
  entity: ScheduledTaskWithRelation
}) => {
  const { task } = entity
  const project = useProject(task?.projectId)
  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()
  const startTask = useStartTask()
  const stopTask = useStopTask()
  const { isStarted, isSiblingStarted } = useIsTaskStarted(task?.id)

  if (!task) return null

  const handleEditTask = () => {
    navigate(buildTaskModalUrl({ task: task.id }))
  }

  const isOngoing = isStarted && !isSiblingStarted

  return (
    <ProjectPalette color={(project?.color as COLOR) ?? 'gray'}>
      <EventContainer eventType='task'>
        <EventContentContainer onClick={handleEditTask}>
          <EventHeader>{task.name}</EventHeader>

          <EventMessage>
            {formatTimeRange(entity.schedule.start, entity.schedule.end)}
          </EventMessage>
        </EventContentContainer>

        <EventOptionsContainer>
          {isOngoing ? (
            <Tooltip content='Stop task'>
              <Button
                iconOnly
                onClick={async () => {
                  await stopTask(task.id, { source: 'upcomingTaskEvent' })
                }}
                size='small'
              >
                <StopSolid />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip content='Start task'>
              <Button
                iconOnly
                onClick={async () => {
                  await startTask(task.id, {
                    source: 'upcomingTaskEvent',
                  })
                }}
                size='small'
              >
                <PlaySolid />
              </Button>
            </Tooltip>
          )}
        </EventOptionsContainer>
      </EventContainer>
    </ProjectPalette>
  )
}
