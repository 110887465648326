import { TravelTimes } from '@motion/ui/calendar'

import { type EventContentArg } from '@fullcalendar/core'
import { getEventColorHue } from '~/areas/event/utils'
import { useCachedItem } from '~/global/cache'

import { type FullCalendarMotionEvent } from '../../../../../areas/calendar/utils'

type Props = {
  id: string
  event: EventContentArg
}

export const CalendarEventTravelTime = (props: Props) => {
  const { extendedProps } = props.event.event

  const {
    colorId,
    calendarColorId,
    travelTimeBefore,
    travelTimeAfter,
    meetingTaskProject,
  } = extendedProps as FullCalendarMotionEvent['extendedProps']

  const event = useCachedItem('calendarEvents', props.id)

  if (!event) {
    return null
  }

  const travelBefore = travelTimeBefore ?? 0
  const travelAfter = travelTimeAfter ?? 0
  const eventStartTime = new Date(event.start)
  const eventEndTime = new Date(event.end)

  const eventColorHue = getEventColorHue(colorId, {
    projectColor: meetingTaskProject?.color,
    calendarColorId: calendarColorId,
  })

  return (
    <TravelTimes
      colorHue={eventColorHue}
      startTime={eventStartTime}
      endTime={eventEndTime}
      travelBefore={travelBefore}
      travelAfter={travelAfter}
    />
  )
}
