import { type ReactNode, useCallback, useMemo, useState } from 'react'

import { AutoSaveContext, type AutoSaveValue } from './autosave-context'

export type AutoSaveProviderProps = {
  children: ReactNode
  suppressSaveToast?: boolean
}

export const AutoSaveProvider = ({
  children,
  suppressSaveToast = false,
}: AutoSaveProviderProps) => {
  const [lastSaved, setLastSaved] = useState<AutoSaveValue['lastSaved']>()

  const resetLastSavedTime = useCallback(() => setLastSaved(undefined), [])

  const value = useMemo<AutoSaveValue>(() => {
    return {
      get lastSaved() {
        return lastSaved
      },
      get shouldSupressSavingToast() {
        return suppressSaveToast
      },
      setLastSavedTime: setLastSaved,
      resetLastSavedTime,
    }
  }, [lastSaved, suppressSaveToast, resetLastSavedTime])

  return (
    <AutoSaveContext.Provider value={value}>
      {children}
    </AutoSaveContext.Provider>
  )
}
