import { type WorkspaceSchema } from '@motion/rpc-types'
import { type CustomFieldValuesSchema } from '@motion/shared/custom-fields'
import { type CustomFieldFieldArrayValue } from '@motion/ui-logic'

import { AddCustomFieldButton } from '~/areas/project-management/custom-fields/add-custom-field-button'

import { CustomFieldUISwitch } from './custom-field-ui-switch'

export type CustomFieldsSidebarProps = {
  fields: CustomFieldFieldArrayValue[]
  workspaceId: WorkspaceSchema['id']
  disabled?: boolean
  onChange: (
    customField: CustomFieldFieldArrayValue,
    value: CustomFieldValuesSchema['value'],
    index: number
  ) => void
}

export function CustomFieldsSidebar({
  fields,
  workspaceId,
  disabled = false,
  onChange,
}: CustomFieldsSidebarProps) {
  return (
    <>
      {fields.map((customField, index) => (
        <CustomFieldUISwitch
          key={customField.instanceId}
          customField={{ ...customField, workspaceId }}
          disabled={disabled}
          value={customField.value}
          onChange={(value) => {
            onChange(
              customField,
              value as CustomFieldValuesSchema['value'],
              index
            )
          }}
        />
      ))}

      {!disabled && (
        <div className='pt-1'>
          <AddCustomFieldButton
            workspaceId={workspaceId}
            sentiment='neutral'
            size='small'
            usePopover
          />
        </div>
      )}
    </>
  )
}
