import { LabelsField, type LabelsFieldProps } from '~/areas/task-project/fields'
import { useTaskLabelsUpdater } from '~/areas/tasks/hooks'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventLabelsField = () => {
  const { form } = useEventForm()
  const { isLoading, initialMeetingTask } = useEventModalState()
  const updateTaskLabels = useTaskLabelsUpdater()

  const workspaceId = form.watch('workspaceId')
  const { field } = useController({
    name: 'labelIds',
    control: form.control,
  })

  if (isLoading || workspaceId == null) return null

  const onChange: LabelsFieldProps['onChange'] = (labelIds) => {
    if (initialMeetingTask != null) {
      return updateTaskLabels(initialMeetingTask.id, labelIds)
    }

    field.onChange(labelIds)
  }

  return (
    <LabelsField
      selectedLabelIds={field.value}
      workspaceId={workspaceId}
      onChange={onChange}
    />
  )
}
