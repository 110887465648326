import { ChatAlt2Solid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { useToggleIntercom } from '../../../utils/toggleIntercom'

export const ProductSpecialistSection = () => {
  const toggleIntercom = useToggleIntercom()

  return (
    <div className='bg-semantic-primary-bg-default p-4 flex flex-col gap-3 rounded'>
      <div className='flex flex-row items-center gap-3'>
        <ChatAlt2Solid className='w-4 h-4 text-semantic-primary-icon-default' />
        <span className='text-sm font-semibold'>
          Get live 1:1 help with one of our product specialists
        </span>
      </div>
      <span className='text-xs'>
        Our product specialists have mentored thousands of users on how to get
        the most out of Motion.
      </span>
      <div>
        <Button size='small' onClick={toggleIntercom}>
          Chat with product specialist now
        </Button>
      </div>
    </div>
  )
}
