import { FormModal, Toggle } from '@motion/ui/base'
import { getChunkDurations } from '@motion/ui-logic'

import { useState } from 'react'

import { type DoTaskASAPProps } from './types'

import { MinChunkDurationDropdown } from '../../../areas/project-management/components'
import { SubParagraph } from '../../Common'

type DoTaskASAPModalProps = {
  onConfirm: (isChunkedTask: boolean, minimumDuration?: number | null) => void
  onClose: () => void
} & DoTaskASAPProps

export const DoTaskASAPModal = ({
  onConfirm,
  onClose,
  task,
}: DoTaskASAPModalProps) => {
  const chunkDurations = getChunkDurations(task.duration ?? null)
  const canChunkTask = chunkDurations.length > 0

  const [isChunkedTask, setIsChunkedTask] = useState(canChunkTask)
  const [minimumDuration, setMinimumDuration] = useState<number | null>(15)

  return (
    <FormModal
      visible
      onClose={onClose}
      submitAction={{
        text: 'Save',
        onAction() {
          onConfirm(isChunkedTask, isChunkedTask ? minimumDuration : undefined)
          onClose()
        },
      }}
      title='Do this task ASAP'
    >
      <div className='flex flex-col gap-3'>
        <div className='text-semantic-neutral-text-default'>
          <p>We&apos;ll try to schedule this task before all other tasks.</p>
          {canChunkTask && (
            <p>Divide this task into chunks to start doing it earlier.</p>
          )}
        </div>
        {canChunkTask && (
          <Toggle
            checked={isChunkedTask}
            onChange={(event) => setIsChunkedTask(event.currentTarget.checked)}
          >
            Allow split into chunks
          </Toggle>
        )}
        {isChunkedTask && (
          <div className='flex items-center gap-2'>
            <SubParagraph className='dark:text-dark-400 font-semibold'>
              Minimum chunk duration
            </SubParagraph>
            <MinChunkDurationDropdown
              onChange={setMinimumDuration}
              selectedMinChunkDuration={minimumDuration}
              chunkDurations={getChunkDurations(task.duration ?? null)}
            />
          </div>
        )}
      </div>
    </FormModal>
  )
}
