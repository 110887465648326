import { getTimeZoneShortName } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

export const timeStringToMin = (timeString: string) => {
  return parseInt(timeString.split(' ')[0])
}

/**
 * @deprecated Use `getTimeZoneShortName` instead
 */
export const getTzAbbr = (timezoneName: string | undefined | null) => {
  try {
    // @ts-expect-error The timezone should be defined, but old callers may pass undefined or null
    const timezoneNameAsString: string = timezoneName
    return getTimeZoneShortName(timezoneNameAsString)
  } catch (ex) {
    Sentry.captureException(
      new Error('Unable to parse timezone', { cause: ex }),
      {
        extra: {
          timezoneName,
        },
      }
    )
    return timezoneName
  }
}
