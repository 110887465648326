import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { time } from '@motion/utils/debug'

import { type TaskWithRelations } from '~/global/proxies'
import { useEffect, useMemo } from 'react'

import { useTreeForTaskNavigation, useTreeGroupOverrides } from './hooks'

import { CurrentTreeKey } from '../grouping'
import {
  buildTreeGroup,
  type GroupDefinition,
  type GroupedNode,
} from '../grouping'
import { KanbanBoard } from '../kanban'
import { type TreeListRowValueType } from '../tree-list/types'
import { useViewState } from '../view-state'

export type TaskKanbanBoardProps = {
  tasks: TaskWithRelations[]
  groupBy: GroupDefinition<TaskWithRelations>[]
  hideEmptyGroups: boolean
}

export const TaskKanbanBoard = (props: TaskKanbanBoardProps) => {
  const [viewState] = useViewState()

  const overrides = useTreeGroupOverrides('tasks')

  const grouped = useMemo(() => {
    return time('build-tree-group.total', () => {
      return buildTreeGroup(props.groupBy, overrides)
        .add('task', props.tasks)
        .buildTree<GroupedNode<TreeListRowValueType>>({
          hideEmptyGroups: props.hideEmptyGroups,
          sortOrder: viewState.groupBy.order,
        })
    })
  }, [
    props.groupBy,
    props.tasks,
    props.hideEmptyGroups,
    viewState.groupBy.order,
    overrides,
  ])

  const setCurrentTree = useSharedStateSendOnly(CurrentTreeKey)
  useEffect(() => setCurrentTree(grouped), [grouped, setCurrentTree])
  useTreeForTaskNavigation(grouped)

  return <KanbanBoard tree={grouped} />
}
