import { QuestionMarkCircleSolid } from '@motion/icons'
import { CalendarProviderType } from '@motion/rpc/types'
import { type EmailAccountCreateSchema } from '@motion/rpc-types'
import { Button, IconButton, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { Controller, type UseFormReturn } from 'react-hook-form'

import { useCreateEmailAccount, useUpdateEmailAccount } from './rpc-hooks'

import { selectEmailAccounts } from '../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../state/hooks'

export type ICloudForm = EmailAccountCreateSchema

type AddICloudFormProps = {
  onBack: () => void
  form: UseFormReturn<ICloudForm>
  onSubmit: (emailAccountId: string, skipConfigurationModals?: boolean) => void
}

export const AddICloudForm = ({
  onBack,
  form,
  onSubmit,
}: AddICloudFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = form

  const emailAccounts = useAppSelector(selectEmailAccounts)
  const create = useCreateEmailAccount()
  const update = useUpdateEmailAccount()

  const submitForm = async (data: ICloudForm) => {
    recordAnalyticsEvent('ICLOUD_CREDENTIALS_MODAL_SAVE')

    const existingAccountId = emailAccounts.find(
      (account) =>
        account.providerType === CalendarProviderType.APPLE &&
        account.email === data.email
    )?.id

    try {
      let res
      if (existingAccountId) {
        res = await update.mutateAsync({ id: existingAccountId, ...data })
      } else {
        res = await create.mutateAsync(data)
      }

      if (res.status === 'OK') {
        recordAnalyticsEvent('ICLOUD_CREDENTIALS_MODAL_SAVE_SUCCESS')
        onSubmit(res.id, !!existingAccountId)
      }

      if (
        res.status === 'NO_CALENDAR_ACCESS' ||
        res.status === 'INVALID_CREDENTIALS'
      ) {
        setError('type', {})
        recordAnalyticsEvent('ICLOUD_CREDENTIALS_MODAL_SAVE_ERROR')
      }

      if (res.status === 'UNKNOWN_ERROR') {
        setError('password', {
          message: 'An unknown error occurred',
        })
        recordAnalyticsEvent('ICLOUD_CREDENTIALS_MODAL_SAVE_ERROR')
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError('password', {
          message: err?.message,
        })
      }

      recordAnalyticsEvent('ICLOUD_CREDENTIALS_MODAL_SAVE_ERROR')
    }
  }

  return (
    <div className='flex flex-col gap-4'>
      <form onSubmit={handleSubmit(submitForm)} className='flex flex-col gap-3'>
        <div className='flex flex-col gap-1'>
          <Controller
            control={control}
            name='email'
            rules={{
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            }}
            render={({ field }) => (
              <div className='font-semibold'>
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  sentiment={errors.email ? 'error' : undefined}
                  label='iCloud email (@icloud.com preferred)'
                  type='email'
                  name={field.name}
                  onBlur={field.onBlur}
                  autoFocus
                />
              </div>
            )}
          />
          {errors.email && (
            <p className='text-semantic-error-text-default text-xs'>
              Please enter a valid email address.
            </p>
          )}
        </div>
        <div className='flex flex-col gap-1'>
          <label
            className='text-semantic-neutral-text-subtle text-xs flex items-center font-semibold -mb-1'
            htmlFor='password'
          >
            App-specific password
            <span className='text-semantic-error-text-default ml-0.5 '>
              (not your iCloud Password)
            </span>
            <Tooltip content='Don’t use your iCloud password here. Generate an app-specific password in iCloud. Go back to see instructions on how to do this.'>
              <IconButton
                icon={QuestionMarkCircleSolid}
                size='small'
                sentiment='neutral'
                variant='muted'
                aria-label='Get help about app-specific password requirement'
              />
            </Tooltip>
          </label>
          <Controller
            control={control}
            name='password'
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                value={field.value}
                onChange={field.onChange}
                sentiment={errors.password ? 'error' : undefined}
                type='password'
                onBlur={field.onBlur}
                name={field.name}
              />
            )}
          />
          {errors.password && (
            <p className='text-semantic-error-text-default text-xs'>
              {errors.password.message}
            </p>
          )}
        </div>
        {errors.type && (
          <p className='text-semantic-error-text-default text-xs'>
            Couldn&apos;t connect. Make sure you&apos;re using the correct
            iCloud email and{' '}
            <button
              type='button'
              className='underline text-semantic-primary-text-default'
              onClick={onBack}
            >
              app-specific password.
            </button>
          </p>
        )}
        <Button
          onClick={handleSubmit(submitForm)}
          fullWidth
          type='submit'
          loading={create.isLoading || update.isLoading}
        >
          <div className='flex w-full items-center justify-center'>Connect</div>
        </Button>
      </form>
      <Button
        sentiment='neutral'
        variant='muted'
        onClick={onBack}
        loading={create.isLoading || update.isLoading}
        fullWidth
      >
        <div className='flex w-full items-center justify-center'>Back</div>
      </Button>
    </div>
  )
}
