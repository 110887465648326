import { PencilSolid } from '@motion/icons'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import { useFlowTemplateForm } from '../hooks'

export const ControlledDescriptionField = () => {
  const modalApi = useModalApi()
  const { form } = useFlowTemplateForm()
  const { control, watch } = form

  const { field } = useController({
    name: 'description',
    control,
  })

  const workspaceId = watch('workspaceId')

  const openDescriptionModal = async () => {
    const response = await modalApi.prompt('description-modal', {
      workspaceId,
      description: field.value,
      title: 'Edit project description',
    })

    if (response !== ModalDismissed) {
      field.onChange(response)
    }
  }

  return (
    <ModalFieldButton label='Description' onClick={openDescriptionModal}>
      <PencilSolid />
      Edit
    </ModalFieldButton>
  )
}
