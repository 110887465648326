import { useCallback } from 'react'

import { usePlannerProps } from '../context'
import { isoDateToPixel } from '../utils'

export const useScrollToDate = () => {
  const [plannerProps, setPlannerProps] = usePlannerProps()

  return useCallback(
    (isoDate: string | null) => {
      if (isoDate == null) return

      // Get pixel position of date
      // Negative because we are scrolling to the left
      const pixel = -isoDateToPixel(isoDate, plannerProps.dayPx)

      // Set scroll position
      setPlannerProps((prev) => ({
        ...prev,
        scrollTo: pixel,
      }))
    },
    [plannerProps.dayPx, setPlannerProps]
  )
}
