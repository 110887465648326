import { isCompletedStatus } from '@motion/shared/common'
import { sleep } from '@motion/utils/promise'

import {
  useCompleteProject,
  useProjectStatusUpdater,
} from '~/areas/project/hooks'
import { StatusField, type StatusFieldProps } from '~/areas/task-project/fields'
import { useWorkspaceFns } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useProjectForm, useSubmitForm } from '../hooks'

export const ControlledStatusField = () => {
  const { form } = useProjectForm()
  const updateProjectStatus = useProjectStatusUpdater()

  const completeProject = useCompleteProject()
  const submitForm = useSubmitForm()

  const { control, watch } = form
  const projectId = watch('id')

  const { getStatusById } = useWorkspaceFns()

  const { field } = useController({
    name: 'statusId',
    control,
  })
  const workspaceId = watch('workspaceId')

  const onChange: StatusFieldProps['onChange'] = async (statusId) => {
    if (projectId != null) {
      return await updateProjectStatus(projectId, statusId)
    }

    const status = getStatusById(statusId)
    if (projectId == null || !(status && isCompletedStatus(status))) {
      field.onChange(statusId)
      return
    }

    // Sleeping a bit to make sure the dropdown gets closed before doing anything else to avoid messing up the focus
    await sleep(1)

    // Make sure we reset any potential previous status change
    form.resetField('statusId')
    const completed = await completeProject(projectId)
    if (!completed) {
      return
    }
    form.handleSubmit(submitForm)()
  }

  return (
    <StatusField
      value={field.value}
      onChange={onChange}
      workspaceId={workspaceId}
    />
  )
}
