import { errorInDev } from '@motion/web-base/logging'

import { type Stripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'

import { ResubscribeIndividual } from './resubscribe-individual'
import { ResubscribeTeam } from './ResubscribeTeam'

import { ElementsWrapper } from '../../../components/Common/Stripe/ElementsWrapper'
import { type Team } from '../../../state/TeamTypes'
import { getStripe } from '../../../utils/stripe'

interface ResubscribeProps {
  clientSecret: string | null
  userEmail: string
  team?: Team
}

const ResubscribeWrapperComponent = ({
  clientSecret,
  team,
  userEmail,
}: ResubscribeProps) => {
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [secret, setClientSecret] = useState<string | null>(clientSecret)
  const [error, setError] = useState<string | null>(null)

  useEffect(function prepStripe() {
    const load = async () => {
      const stripe = await getStripe()
      setStripe(stripe)
    }

    load().catch(errorInDev)
  }, [])

  if (!stripe || !secret) {
    return null
  }

  return (
    <ElementsWrapper key={secret} stripe={stripe} clientSecret={secret}>
      {team ? (
        <ResubscribeTeam
          error={error}
          onRerender={(secret) => setClientSecret(secret)}
          setError={setError}
          team={team}
          userEmail={userEmail}
        />
      ) : (
        <ResubscribeIndividual
          userEmail={userEmail}
          onRerender={(clientSecret, error) => {
            setError(error)
            setClientSecret(clientSecret)
          }}
        />
      )}
    </ElementsWrapper>
  )
}

export const ResubscribeWrapper = React.memo(ResubscribeWrapperComponent)
