import {
  formatDurationToShort,
  NO_DURATION,
  SHORT_TASK_DURATION,
} from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useTaskCompletedDurationData } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction, CellText } from './components'

type TaskCompletedDurationCellProps = {
  task: TaskWithRelations
}
export const TaskCompletedDurationCell = ({
  task,
}: TaskCompletedDurationCellProps) => {
  const {
    onChange,
    choices: completedChoices,
    completedDuration,
    isFake,
    disabled,
    hasValidData,
  } = useTaskCompletedDurationData(task)

  if (!hasValidData) {
    return <CellText>-</CellText>
  }

  const showDuration =
    task.duration !== SHORT_TASK_DURATION && task.duration !== NO_DURATION

  return (
    <DurationDropdown
      value={completedDuration}
      choices={completedChoices}
      onChange={(value) => value && onChange(value)}
    >
      <CellAction disabled={disabled} isFake={isFake}>
        <span className='truncate'>
          {showDuration ? formatDurationToShort(completedDuration) : ''}
        </span>
      </CellAction>
    </DurationDropdown>
  )
}
