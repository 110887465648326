import {
  type ProjectSchema,
  type StageSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { type DeadlineStatus } from '@motion/shared/common'
import {
  getProjectEtaTitle,
  getProjectNoEtaReason,
  getStageEtaTitle,
  getStageNoEtaReason,
  getTaskEtaTitle,
  getTaskNoEtaReason,
  normalizeProjectDeadlineStatus,
  normalizeStageDeadlineStatus,
  normalizeTaskDeadlineStatus,
} from '@motion/ui-logic'

import { useI18N } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { type ReactNode, useMemo } from 'react'

import { useProjectStageToShow } from '../../hooks'

type EtaBadgeTooltipProps =
  | {
      entity: TaskSchema
      type: 'task'
    }
  | {
      entity: StageSchema
      type: 'stage'
    }
  | {
      entity: ProjectSchema
      type: 'project'
    }

const ACTION_RESOLUTION_TEXT = 'Click to see how to resolve this'

const DeadlineStatusResolutionText: Record<DeadlineStatus, string> = {
  none: '',
  'on-track': 'Click to see more details',
  'ahead-of-schedule': 'Click to see how to optimize this',
  'at-risk': ACTION_RESOLUTION_TEXT,
  'missed-deadline': ACTION_RESOLUTION_TEXT,
  'scheduled-past-deadline': ACTION_RESOLUTION_TEXT,
}

export const BadgeTooltip = ({ entity, type }: EtaBadgeTooltipProps) => {
  const { pluralize } = useI18N()

  const stageToShow = useProjectStageToShow(type === 'project' ? entity : null)
  const { getWorkspaceStatuses } = useWorkspaceFns()

  const [bannerTitle, deadlineStatus] = useMemo<
    [string | ReactNode, DeadlineStatus]
  >(() => {
    let deadlineStatus: DeadlineStatus | undefined

    switch (type) {
      case 'task':
        deadlineStatus = normalizeTaskDeadlineStatus(entity)
        if (deadlineStatus === 'none')
          return [
            getTaskNoEtaReason(
              entity,
              getWorkspaceStatuses(entity.workspaceId)
            ),
            deadlineStatus,
          ]
        return [getTaskEtaTitle(entity), deadlineStatus]
      case 'stage':
        deadlineStatus = normalizeStageDeadlineStatus(entity)
        if (deadlineStatus === 'none')
          return [getStageNoEtaReason(entity), deadlineStatus]
        return [getStageEtaTitle(entity), deadlineStatus]
      case 'project':
        if (stageToShow) {
          deadlineStatus = normalizeStageDeadlineStatus(stageToShow)
          return [getStageEtaTitle(stageToShow), deadlineStatus]
        }
        deadlineStatus = normalizeProjectDeadlineStatus(entity)
        if (deadlineStatus === 'none')
          return [
            getProjectNoEtaReason(
              entity,
              getWorkspaceStatuses(entity.workspaceId)
            ),
            deadlineStatus,
          ]
        return [getProjectEtaTitle(entity, pluralize), deadlineStatus]
    }
  }, [type, entity, getWorkspaceStatuses, stageToShow, pluralize])

  const resolutionText = DeadlineStatusResolutionText[deadlineStatus]

  return (
    <div className='flex flex-col'>
      <div className='text-sm font-medium'>{bannerTitle}</div>
      {resolutionText && <span className='text-xs'>{resolutionText}</span>}
    </div>
  )
}
