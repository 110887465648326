import { type CalendarStartDay } from '@motion/ui-logic/calendar'
import { logEvent } from '@motion/web-base/analytics'

import { useCallback, useEffect, useState } from 'react'

import { Events } from '../../../../analyticsEvents'
import api from '../../../../chromeApi/chromeApiContentScript'
import { persistSettings } from '../../../../localServices/firebase/web'
import { useAppSelector } from '../../../../state/hooks'
import { selectTheme } from '../../../../state/settingsSlice'
import { type MotionTheme } from '../../../../types/settingsTypes'
import { Combobox, SubParagraph } from '../../../Common'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

export const ViewSettings = () => {
  const [calendarStartDay, setCalendarStartDay] =
    useState<CalendarStartDay>('sunday')
  const currentTheme = useAppSelector(selectTheme)

  useEffect(() => {
    const getInitialData = async () => {
      const { calendarStartDay } = await api.storage.local.get([
        'calendarStartDay',
      ])
      setCalendarStartDay(calendarStartDay)
    }

    void getInitialData()

    const storageListener = (changes: any) => {
      if ('calendarStartDay' in changes) {
        setCalendarStartDay(changes.calendarStartDay.newValue)
      }
    }
    api.storage.onChanged.addListener(storageListener)
    return () => api.storage.onChanged.removeListener(storageListener)
  }, [])

  const onDefaultStartDayChange = useCallback(async (val: CalendarStartDay) => {
    setCalendarStartDay(val)
    await api.storage.local.set({ calendarStartDay: val })
    void logEvent(Events.CALENDAR_SETTINGS_START_DAY_CHANGE)
  }, [])

  const onThemeChange = useCallback(async (theme: MotionTheme) => {
    void logEvent(Events.THEME_CHANGED, { theme })
    void persistSettings({ theme })
  }, [])

  return (
    <SettingPage title='Theme' className='gap-4'>
      <div className='flex w-full flex-col gap-2'>
        <SubParagraph className='dark:text-dark-400'>
          Start week on
        </SubParagraph>
        <Combobox
          autoComplete
          className='w-full max-w-full'
          options={[
            { label: 'Sunday', value: 'sunday' },
            { label: 'Monday', value: 'monday' },
          ]}
          value={calendarStartDay}
          onChange={onDefaultStartDayChange}
        />
      </div>
      <div className='flex w-full flex-col gap-2'>
        <SubParagraph className='dark:text-dark-400'>Theme</SubParagraph>
        <Combobox
          autoComplete
          className='w-full max-w-full'
          options={[
            { label: 'Use system setting', value: 'systemSettings' },
            { label: 'Dark', value: 'dark' },
            { label: 'Light', value: 'light' },
          ]}
          value={currentTheme}
          onChange={onThemeChange}
        />
      </div>
    </SettingPage>
  )
}
