type StringID = {
  id?: string | null
}

export const replaceInPlace = <T extends StringID>(
  arr: T[],
  itemToUpdate: T
) => {
  const arrIndex = arr.findIndex((x) => x.id === itemToUpdate.id)

  if (arrIndex === -1) return arr

  return arr.splice(arrIndex, 1, itemToUpdate)
}

type Falsy = false | 0 | '' | null | undefined

// Removes all falsy values from an array while preserving the type
export const excludeFalsy = <T>(arr: T[]): Exclude<T, Falsy>[] => {
  return arr.filter(Boolean) as Exclude<T, Falsy>[]
}
