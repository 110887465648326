import { type PMTaskType } from '@motion/rpc/types'

import { useTemplateTaskForm } from './use-template-task-form'

export function useTaskModalProjectTasks() {
  const { form, options } = useTemplateTaskForm()

  const { watch } = form
  const { savedTemplateProject } = options

  const projectId = watch('projectId')

  const projectTasks = (savedTemplateProject?.project.tasks.map((t) => ({
    ...t,
    labels: t.labelIds.map((id) => ({
      labelId: id,
    })),
    blockingTasks: t.blockingTaskIds.map((id) => ({
      blockedId: id,
    })),
    blockedTasks: t.blockedByTaskIds.map((id) => ({ blockedId: id })),
    schedule: t.scheduleId ?? undefined,
    templateProjectId: savedTemplateProject?.id,
  })) ?? []) as unknown as PMTaskType[]

  return { projectTasks, projectId }
}
