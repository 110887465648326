import { LightBulbSolid } from '@motion/icons'

type CalloutProps = {
  label: string
}

export function Callout({ label }: CalloutProps) {
  return (
    <div className='rounded-md shadow-sm p-2 flex items-start gap-3 border bg-semantic-neutral-bg-subtle border-semantic-neutral-border-default'>
      <LightBulbSolid className='text-semantic-neutral-icon-default size-4 shrink-0' />

      <p className='text-xs text-semantic-neutral-text-default'>{label}</p>
    </div>
  )
}
