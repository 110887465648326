import {
  AssigneeDropdown,
  type AssigneeDropdownProps,
} from '~/areas/project-management/components'

export type PersonDropdownProps = Omit<
  Exclude<AssigneeDropdownProps, { type: 'multi' }>,
  'type'
>

export const PersonDropdown = (props: PersonDropdownProps) => (
  <AssigneeDropdown {...props} type='single'>
    {props.children}
  </AssigneeDropdown>
)
