import { type CreateZoomMeetingDto } from '@motion/rpc-types'

import { DateTime } from 'luxon'

import { BackendApiService } from './BackendApiService'

import type { ZoomCreateMeetingResponse } from '../utils/conferenceUtils'

const zoomMeetingUrl = 'zoom/meeting'

/**
 * Service for handling Zoom Meeting CRUD and related Auth actions
 */
export class ZoomService extends BackendApiService {
  static id = 'ZoomService' as const

  async createMeeting(
    data: CreateZoomMeetingDto
  ): Promise<ZoomCreateMeetingResponse> {
    const { topic, recurringRule, duration } = data

    const now = DateTime.now()
    const startTime = data.startTime ? DateTime.fromISO(data.startTime) : now

    return this.request(`${zoomMeetingUrl}`, 'POST', {
      topic,
      startTime: startTime.toUTC().toISO().split('.')[0] + 'Z',
      duration,
      recurringRule,
    })
  }
}

export const handler = new ZoomService()
