import { Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'
import { useHasTreatment } from '@motion/web-common/flags'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useProjectUpdater } from '~/areas/project/hooks'
import {
  WorkspaceSectionContainer,
  WorkspaceSectionTitle,
} from '~/areas/task-project/components'
import {
  FolderField,
  type FolderFieldProps,
  WorkspaceField,
} from '~/areas/task-project/fields'
import { type WorkspaceDropdownProps } from '~/global/components/dropdowns'
import { useProject, useWorkspaceById, useWorkspaceFns } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { ActiveStageField } from './active-stage-field'

import { useProjectForm } from '../hooks'

export const ControlledWorkspaceFolderField = () => {
  const modalApi = useModalApi()
  const { form } = useProjectForm()
  const updateProject = useProjectUpdater()
  const { getWorkspaceById } = useWorkspaceFns()

  const hasPastDueM2 = useHasTreatment('past-due-m2')

  const { control, watch } = form

  const { field: workspaceField } = useController({
    name: 'workspaceId',
    control,
  })

  const { field: folderField } = useController({
    name: 'folderId',
    control,
  })

  const { field: activeStageIdField } = useController({
    name: 'activeStageDefinitionId',
    control,
  })
  const projectId = watch('id')
  const project = useProject(projectId)

  const isFlow = isFlowProject(project)

  const selectedWorkspace = useWorkspaceById(workspaceField.value)
  if (selectedWorkspace == null) return null

  const handleSelect = async (
    workspaceId: string,
    folderId: string | null = null
  ) => {
    const newWorkspace = getWorkspaceById(workspaceId)

    if (!newWorkspace) {
      throw new Error('Workspace not found')
    }

    // Creating a new project
    const projectId = form.getValues('id')
    if (projectId == null) {
      workspaceField.onChange(workspaceId)
      folderField.onChange(folderId)
      return
    }

    if (workspaceField.value !== newWorkspace.id) {
      // Sleeping a bit to make sure the dropdown gets closed before prompting the modal to keep the focus within the modal
      await sleep(1)

      const confirmResponse = await modalApi.prompt('confirm', {
        analytics: {
          name: 'move-project-workspace',
        },
        title: templateStr(
          'Are you sure you want to move this project from {{ oldWorkspace }} to {{ newWorkspace }}?',
          {
            oldWorkspace: selectedWorkspace.name,
            newWorkspace: newWorkspace.name,
          }
        ),
        description:
          'Assignees, labels and statuses may be reset if they are not available in the other workspace.',
        confirmButtonText: 'Move project',
        closeButtonText: 'Cancel',
      })

      if (confirmResponse === ModalDismissed) return

      // Clean slate custom fields to avoid error switching to new workspace.
      // See https://usemotion.slack.com/archives/C06RERLCSKY/p1720829815040409?thread_ts=1720819604.715499&cid=C06RERLCSKY.
      form.reset({
        ...form.getValues(),
        customFieldValuesFieldArray: [],
      })
    }

    if (project == null) {
      throw new Error('Project not found')
    }

    void updateProject(project, {
      workspaceId,
      folderId,
    })
  }
  const onWorkspaceSelect: WorkspaceDropdownProps['onChange'] = async (
    item
  ) => {
    if (isFlow) return

    // When updating an existing project, we're triggering the update right away
    handleSelect(item.id)
  }

  const onFolderSelect: FolderFieldProps['onChange'] = async ({
    workspaceId,
    folderId,
  }) => {
    handleSelect(workspaceId, folderId)
  }

  return (
    <WorkspaceSectionContainer>
      <WorkspaceSectionTitle
        showStage={!hasPastDueM2 && activeStageIdField.value != null}
        showWorkspace={!hasPastDueM2}
        showProject={!hasPastDueM2}
      />

      <Tooltip
        content={
          isFlow
            ? 'Workspace can’t be changed for projects using a workflow'
            : undefined
        }
      >
        <WorkspaceField
          workspaceId={workspaceField.value}
          onChange={onWorkspaceSelect}
          disabled={isFlow}
        />
      </Tooltip>

      <FolderField
        folderId={folderField.value}
        workspaceId={workspaceField.value}
        onChange={onFolderSelect}
        computeDisabled={({ workspace }) =>
          isFlow && workspace.id !== workspaceField.value
        }
      />
      <ActiveStageField />
    </WorkspaceSectionContainer>
  )
}
