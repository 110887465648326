import { type UploadedFileSchema } from '@motion/rpc-types'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useState } from 'react'

import { AttachmentsModalProjectContent } from './components/attachments-modal-project-content'
import { AttachmentsModalTaskContent } from './components/attachments-modal-task-content'

export type AttachmentsModalProps = {
  targetId: NonNullable<UploadedFileSchema['targetId']>
  targetType: NonNullable<UploadedFileSchema['targetType']>
}

export function AttachmentsModal({
  targetId,
  targetType,
  close,
}: ModalTriggerComponentProps<'attachments'>) {
  const [searchQuery, setSearchQuery] = useState('')

  if (!targetId || !targetType) return null

  if (targetType === 'PROJECT')
    return (
      <AttachmentsModalProjectContent
        close={close}
        projectId={targetId}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    )

  if (targetType === 'TEAM_TASK')
    return (
      <AttachmentsModalTaskContent
        close={close}
        taskId={targetId}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    )

  return null
}
