import { type NormalTaskSchema } from '@motion/rpc-types'
import { templateStr } from '@motion/ui-logic'

import { useBlockerTasks } from '~/areas/tasks/hooks'
import { useI18N } from '~/global/contexts'

import { BlockerChain } from './blocker-chain'

export type PastDueBlockerChainTooltipProps = {
  task: NormalTaskSchema
}

export const PastDueBlockerChainTooltip = ({
  task,
}: PastDueBlockerChainTooltipProps) => {
  const { pluralize } = useI18N()
  const { blockedBy: blockers } = useBlockerTasks({
    taskId: task.id,
  })

  if (blockers.length === 0) {
    return null
  }

  return (
    <div className='flex flex-col gap-2 text-sm leading-5 py-0.5 px-1 text-left'>
      <span className='text-tooltip-text-subtle'>
        {templateStr('This task has {{count}} {{pluralized}}:', {
          count: blockers.length,
          pluralized: pluralize(blockers.length, 'blocker', 'blockers'),
        })}
      </span>

      <BlockerChain task={task} chain={blockers} />

      <span className='italic text-tooltip-text-subtle'>
        If this seems wrong, try removing the blocking relationships for this
        task.
      </span>
    </div>
  )
}
