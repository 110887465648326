import './calendarEventsWebsocketEvents'

import {
  createEntityAdapter,
  createSlice,
  type EntityState,
  nanoid,
  type PayloadAction,
} from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import { serializeFullCalendarEventId } from './calendarEventsUtils'

import { type FullCalendarEvent } from '../../areas/calendar/utils'
import type { RootState } from '../types'

export const calendarAvailabilitiesAdapter =
  createEntityAdapter<FullCalendarEvent>({
    sortComparer: (a, b) => {
      if (a.start && b.start) {
        const aStart = DateTime.fromISO(a.start)
        const bStart = DateTime.fromISO(b.start)
        if (aStart > bStart) {
          return 1
        } else if (aStart < bStart) {
          return -1
        }
        return 0
      }
      return 0
    },
  })

export interface CalendarEventsState {
  /**
   * Availability
   */
  selectedAvailabilityFullCalendarEvents: EntityState<FullCalendarEvent>
}

export const initialCalendarEventsState: CalendarEventsState = {
  selectedAvailabilityFullCalendarEvents:
    calendarAvailabilitiesAdapter.getInitialState(),
}

export const calendarEventsSlice = createSlice({
  initialState: initialCalendarEventsState,
  name: 'calendarEvents',
  reducers: {
    addAvailabilityEvent: (
      state: CalendarEventsState,
      action: PayloadAction<{
        calendarId: string
        email: string
        event: Partial<FullCalendarEvent> &
          Pick<FullCalendarEvent, 'start' | 'end'>
      }>
    ) => {
      const tempId = nanoid()
      const newAvailabilityEvent: FullCalendarEvent = {
        ...action.payload.event,
        id: serializeFullCalendarEventId({
          id: tempId,
          type: 'availability',
        }),
        title: '',
      }
      calendarAvailabilitiesAdapter.addOne(
        state.selectedAvailabilityFullCalendarEvents,
        newAvailabilityEvent
      )
    },
    clearSelectedAvailabilityFullCalendarEvents: (
      state: CalendarEventsState
    ) => {
      calendarAvailabilitiesAdapter.removeAll(
        state.selectedAvailabilityFullCalendarEvents
      )
    },
    removeAvailabilityEvent: (
      state: CalendarEventsState,
      action: PayloadAction<{ id: string }>
    ) => {
      calendarAvailabilitiesAdapter.removeOne(
        state.selectedAvailabilityFullCalendarEvents,
        action.payload.id
      )
    },
    reset: () => initialCalendarEventsState,
    updateAvailabilityEvent: (
      state: CalendarEventsState,
      action: PayloadAction<{ id: string; changes: Partial<FullCalendarEvent> }>
    ) => {
      calendarAvailabilitiesAdapter.updateOne(
        state.selectedAvailabilityFullCalendarEvents,
        {
          changes: action.payload.changes,
          id: serializeFullCalendarEventId({
            id: action.payload.id,
            type: 'availability',
          }),
        }
      )
    },
  },
})

export const {
  addAvailabilityEvent,
  clearSelectedAvailabilityFullCalendarEvents,
  removeAvailabilityEvent,
  reset,
  updateAvailabilityEvent,
} = calendarEventsSlice.actions

export const calendarAvailabilitiesSelectors =
  calendarAvailabilitiesAdapter.getSelectors(
    (state: RootState) =>
      state.calendarEvents.selectedAvailabilityFullCalendarEvents
  )

export const calendarEventsReducer = calendarEventsSlice.reducer
