import { classed } from '@motion/theme'

import { ColorLabel } from '~/global/components/labels'
import { type ProjectWithRelations } from '~/global/proxies'

import { CellText } from './components'

type ColorCellProps = { item: ProjectWithRelations }

export const ColorCell = (props: ColorCellProps) => {
  return (
    <ColorCellContainer>
      <ColorLabel color={props.item.color} />
    </ColorCellContainer>
  )
}

const ColorCellContainer = classed(CellText, 'flex items-center')
