import { EnsureBookingDataLoaded } from '~/areas/calendar/booking/hooks'
import { useTimezoneSettings } from '~/global/hooks'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import api from '../../chromeApi/chromeApiContentScript'
import { useAppDispatch } from '../../state/hooks'
import { setSchedules } from '../../state/settingsSlice'
import { setSecondaryTimezone } from '../../state/timezone-slice'

export const SettingsPage = () => {
  const dispatch = useAppDispatch()

  const { defaultTimezone, secondaryTimezone } = useTimezoneSettings({
    useDefaults: true,
  })

  useEffect(() => {
    const getInitialData = async () => {
      const { schedules } = await api.storage.local.get(['schedules'])

      dispatch(
        setSecondaryTimezone(
          secondaryTimezone || defaultTimezone || 'America/Los_Angeles'
        )
      )
      dispatch(setSchedules(schedules))
    }

    void getInitialData()

    const storageListener = (changes: any) => {
      if ('calendarTimezone' in changes) {
        dispatch(setSecondaryTimezone(changes.calendarTimezone.newValue))
      }
      if ('schedules' in changes) {
        dispatch(setSchedules(changes.schedules.newValue))
      }
    }

    api.storage.onChanged.addListener(storageListener)

    return () => api.storage.onChanged.removeListener(storageListener)
  }, [dispatch, defaultTimezone, secondaryTimezone])

  return (
    <EnsureBookingDataLoaded>
      <div
        className='flex h-full w-full items-start justify-start overflow-hidden bg-semantic-neutral-bg-disabled'
        data-testid='app-settings'
      >
        <Outlet />
      </div>
    </EnsureBookingDataLoaded>
  )
}
