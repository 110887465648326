import { useModalStatus } from '@motion/web-common/modals'

import { ChangeMainCalendarModal } from '../Modals/change-main-calendar-modal/change-main-calendar-modal'

export const ChangeMainCalendarModalHandler = () => {
  const status = useModalStatus('change-main-calendar')

  const onComplete = () => {
    // TODO
  }

  if (!status.visible) {
    return null
  }

  return (
    <ChangeMainCalendarModal
      emailAccount={status.props.emailAccount}
      mainEmailAccount={status.props.mainEmailAccount}
      onComplete={onComplete}
    />
  )
}
