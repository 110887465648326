import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { useHasTreatment } from '@motion/web-common/flags'

import { NameSearchButton } from '~/areas/project-management/components/filters'

import { useViewState } from '../view-state'

export const ConnectedNameSearchButton = () => {
  const hasSavedViewsAsTabs = useHasTreatment('saved-views-as-tabs')
  const [viewState, setViewState] = useViewState()
  const [filterState] = useActiveFilter()

  const filterTargetName = filterState.target === 'tasks' ? 'tasks' : 'projects'

  return (
    <NameSearchButton
      value={viewState.search ?? ''}
      onSearch={(search) => setViewState((prev) => ({ ...prev, search }))}
      placeholder={
        hasSavedViewsAsTabs ? 'Search' : `Search ${filterTargetName}`
      }
    />
  )
}
