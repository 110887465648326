import { classed } from '@motion/theme'
import { TextField } from '@motion/ui/forms'

import { NAME_FIELD_RULE } from '~/global/rules'
import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationNameField = () => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'name',
    control: formContext.control,
    rules: NAME_FIELD_RULE,
  })

  return (
    <NameContainer>
      <StyledTextField
        size='small'
        variant='minimal'
        onChange={field.onChange}
        value={field.value}
        autoFocus
        fullWidth
        placeholder='Name'
      />
    </NameContainer>
  )
}

const NameContainer = classed('div', {
  base: `
    w-full
    text-semantic-neutral-text-subtle
  `,
})

const StyledTextField = classed(TextField, {
  base: `
    placeholder:text-semantic-neutral-text-subtle
  `,
})
