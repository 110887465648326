import { useDebouncedValue } from '@motion/react-core/hooks'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useModalStatus } from '@motion/web-common/modals'
import { useLocalStorage } from '@motion/web-common/storage'
import { type SearchSchema } from '@motion/zod/client'

import { useActiveCalendars } from '~/areas/calendar/hooks'
import {
  useCalendarEventsSearch,
  useRecentlyOpenedEntities,
  useSearch,
} from '~/global/rpc'
import { useInNoExternalCalendarsMode } from '~/hooks'
import { useEffect, useMemo, useState } from 'react'

import { SearchContextProvider } from './search-context'
import { SearchModal, type SearchModalProps } from './search-modal'
import {
  type CalendarEventSearchSchema,
  isSearchingAttachments,
  isSearchingEvents,
  isSearchingTasks,
  isValidSearch,
  type SearchType,
} from './utils'

import { type ModalTriggerComponentProps } from '../modal-trigger'

const defaultSearchParams: SearchType = {
  type: 'tasks',
  archived: false,
  showCompleted: true,
}

export type ConnectedSearchModalProps = {
  initialQuery?: string
}

export const ConnectedSearchModal = () => {
  const modal = useModalStatus('search')

  const [inputQuery, setInputQuery] = useState('')
  const [searchType, setSearchType] = useLocalStorage(
    'searchShowCompleted',
    defaultSearchParams as SearchType
  )

  const initialQuery = modal.visible ? modal?.props?.initialQuery : ''

  useEffect(() => {
    if (initialQuery) {
      setInputQuery(initialQuery)
    }
  }, [initialQuery])

  useEffect(() => {
    if (modal.visible) return

    // Reset the input query after 2 min when the modal closes
    const id = setTimeout(() => {
      setInputQuery('')
    }, 120_000)

    return () => {
      clearTimeout(id)
    }
  }, [modal.visible])

  if (!modal.visible) {
    return null
  }

  return (
    <InnerConnectedSearchModal
      {...{
        inputQuery,
        setInputQuery,
        searchType: searchType ?? defaultSearchParams,
        setSearchType,
      }}
      close={modal.close}
    />
  )
}

type InnerConnectedSearchModalProps = ModalTriggerComponentProps<'search'> & {
  inputQuery: string
  setInputQuery: (value: string) => void
  searchType: SearchType
  setSearchType: (value: SearchType) => void
}

const InnerConnectedSearchModal = ({
  inputQuery,
  setInputQuery,
  searchType,
  setSearchType,
  close,
}: InnerConnectedSearchModalProps) => {
  useOnMountAnalyticsEvent('SEARCH_TRIGGERED')

  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const activeCalendars = useActiveCalendars()

  const searchQuery = useDebouncedValue(inputQuery, { timeoutMs: 250 })

  const {
    data: recentlyOpenedEntities,
    isInitialLoading: isLoadingRecentlyOpened,
  } = useRecentlyOpenedEntities(void 0, {
    enabled: !isValidSearch(searchQuery),
  })

  const { data: searchResults, isInitialLoading: isLoadingSearch } = useSearch(
    {
      filters:
        isSearchingTasks(searchType) && !searchType.showCompleted
          ? [
              {
                field: 'completed',
                operator: 'equals',
                value: ['exclude'],
              },
            ]
          : undefined,
      query: searchQuery,
      entities: getSearchTaskEntities(searchType),
      limit: 25,
    },
    {
      enabled:
        (isSearchingTasks(searchType) || isSearchingAttachments(searchType)) &&
        isValidSearch(searchQuery),
    }
  )

  const { data: searchEvents, isInitialLoading: isLoadingCalendarEvents } =
    useCalendarEventsSearch(
      { query: searchQuery },
      { enabled: isSearchingEvents(searchType) && isValidSearch(searchQuery) }
    )
  const calendarSearchEvents: CalendarEventSearchSchema[] = useMemo(() => {
    return (
      searchEvents?.map((e, idx) => ({
        ...e,
        // TODO - Fix the id on the backend. It's an empty string - https://usemotion.slack.com/archives/C06CDKBAHGC/p1706926962835109
        id: `${idx}-${e.providerId}`,
        entityType: 'event',
      })) ?? []
    )
  }, [searchEvents])

  const handleSearch: SearchModalProps['onSearch'] = (query, newSearchType) => {
    if (
      isSearchingTasks(newSearchType) &&
      newSearchType.archived &&
      isSearchingTasks(searchType) &&
      !searchType.archived
    ) {
      recordAnalyticsEvent('SEARCH_ARCHIVED_TRIGGERED')
    }

    setInputQuery(query)
    setSearchType(newSearchType)
  }

  const searchingUnarchivedTasks =
    isSearchingTasks(searchType) && !searchType.archived

  const results =
    searchingUnarchivedTasks && !isValidSearch(searchQuery)
      ? recentlyOpenedEntities
      : isSearchingTasks(searchType) || isSearchingAttachments(searchType)
        ? searchResults
        : calendarSearchEvents

  const pending = inputQuery !== searchQuery

  const loading = !isValidSearch(searchQuery)
    ? isLoadingRecentlyOpened
    : isSearchingTasks(searchType)
      ? isLoadingSearch
      : isLoadingCalendarEvents

  useEffect(() => {
    if (
      isValidSearch(searchQuery) &&
      !loading &&
      !pending &&
      results?.length === 0
    ) {
      recordAnalyticsEvent('SEARCH_NO_RESULTS', {
        type: isSearchingTasks(searchType) ? 'tasks_projects' : 'events',
      })
    }
  }, [loading, pending, results, searchQuery, searchType])

  return (
    <SearchContextProvider activeCalendars={activeCalendars}>
      <SearchModal
        searchQuery={inputQuery}
        searchType={searchType}
        disableSearchTypeEvents={noExternalCalendarsMode}
        onSearch={handleSearch}
        onClose={close}
        loading={loading}
        pending={pending}
        searchResults={results}
      />
    </SearchContextProvider>
  )
}

function getSearchTaskEntities(
  searchType: SearchType
): SearchSchema['entities'] {
  if (searchType.type === 'attachments') {
    return ['uploadedFile']
  }

  if (searchType.type !== 'tasks') return

  if (searchType.archived) {
    return ['archivedTask']
  }

  return ['task', 'project']
}
