import { classed } from '@motion/theme'

export const BodyContainer = classed('div', {
  base: 'flex flex-row h-full min-h-80',
})

export const LeftSideContainer = classed('div', {
  base: 'flex flex-col p-4 gap-[30px] w-[442px]',
})

export const RightSideContainer = classed('div', {
  base: 'bg-semantic-neutral-bg-disabled',
})

export const TimelinesContainer = classed('div', {
  base: 'flex flex-col gap-3',
})

export const TimelineSectionContainer = classed('div', {
  base: 'flex flex-col gap-1 w-full',
})

export const TimelineSectionTitle = classed('div', {
  base: 'text-xs font-semibold text-semantic-neutral-text-default',
})
