import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { getIsTeamTrialSetToCancel, templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { Events } from '~/analyticsEvents'
import { HorizontalDivider } from '~/components/Common/Divider/HorizontalDivider'
import { useIndividualSubscription } from '~/global/rpc/subscriptions'
import { useAppDispatch } from '~/state/hooks'
import { cancelSubscription } from '~/state/teamSlice'
import { type Team } from '~/state/TeamTypes'
import { getSubscription } from '~/state/userSlice'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'

export interface CancelTeamPlanModalProps {
  team: Team
}

export const CancelTeamPlanModal = ({ team }: CancelTeamPlanModalProps) => {
  const modalApi = useModalApi()
  const dispatch = useAppDispatch()
  const [cancelReason, setCancelReason] = useState<string>('')

  const isTrial = getIsTeamTrialSetToCancel(team?.pmTeamSubscription)

  const individualSubscription = useIndividualSubscription()

  const isSecondary =
    individualSubscription && individualSubscription.status !== 'canceled'

  const endDateString = DateTime.fromISO(
    team.pmTeamSubscription?.endDate || ''
  ).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' })

  const onCancel = useCallback(async () => {
    await dispatch(
      cancelSubscription({ cancellationReason: cancelReason, teamId: team.id })
    )
    recordAnalyticsEvent(
      isTrial
        ? Events.TEAM_BILLING_CANCEL_TRIAL
        : Events.TEAM_BILLING_CANCEL_SUB
    )
    await dispatch(getSubscription())
    modalApi.dismiss()
  }, [cancelReason, dispatch, isTrial, modalApi, team.id])

  return (
    <FormModal
      visible
      title='Are you sure you want to cancel?'
      onClose={() => modalApi.dismiss()}
      submitAction={{
        text: 'Cancel plan',
        destructive: true,
        disabled: cancelReason.length === 0,
        onAction: onCancel,
      }}
    >
      <div className='flex w-[450px] flex-col gap-2'>
        <p className='mx-1 text-sm'>
          {templateStr(
            "{{youStr}} team members will lose access to Motion. You'll still have access to Motion until your plan ends on {{endDateString}}.",
            { youStr: isSecondary ? 'Your ' : 'You and', endDateString }
          )}
        </p>
        <HorizontalDivider className='my-2' />
        <p className='mx-1 text-xs'>
          We&apos;re sorry to see you go! Can you let us know why you&apos;re
          cancelling? Your feedback will help us improve Motion.
        </p>
        <TextField
          multiline
          placeholder='Enter Message'
          onChange={setCancelReason}
          autoFocus
        />
      </div>
    </FormModal>
  )
}
