import { type ProjectSchema } from '@motion/rpc-types'

import { ProgressSummary } from './progress-summary'

import { CellText } from '../components'

export type ProjectCompletedDurationCellProps = {
  project: ProjectSchema
}

export const ProjectCompletedDurationCell = ({
  project,
}: ProjectCompletedDurationCellProps) => {
  return (
    <CellText className='flex-nowrap gap-2'>
      <ProgressSummary
        percentageComplete={project.completion}
        completedDuration={project.completedDuration}
      />
    </CellText>
  )
}
