import { isNoneId } from '@motion/shared/identifiers'
import { useFieldFilter } from '@motion/ui-logic/pm/data'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  groupInto,
} from '@motion/utils/array'

import { Label } from '~/global/components/labels'
import { useAppDataContext } from '~/global/contexts'
import { useMemo } from 'react'

import { GroupedFilterButton } from './grouped-filter-button'
import { WorkspaceItem, WorkspaceItemGroupHeader } from './workspace-item'

import { buildIdFilter, useWorkspaceFilter } from '../../utils'
import { type DropdownFilterProps } from '../types'

export const ConnectedLabelFilter = (props: DropdownFilterProps) => {
  const ctx = useAppDataContext()
  const [filter, setFilter] = useFieldFilter(ctx, props.target, 'labelIds')

  const byWorkspace = useWorkspaceFilter()
  const labels = useMemo(() => {
    const all = ctx.labels
      .all()
      .filter(byWorkspace)
      .filter((x) => !x.deleted)
      .sort(
        cascade(
          byProperty('name', Compare.caseInsensitive),
          byValue(
            (item) => ctx.workspaces.byId(item.workspaceId)?.name,
            Compare.caseInsensitive
          )
        )
      )

    const groups = groupInto(all, (p) => p.name).sort(
      cascade(
        byValue(
          (item) => (isNoneId(item.items[0].id) ? 0 : 1),
          Compare.numeric
        ),
        byProperty('key', Compare.caseInsensitive)
      )
    )

    return {
      all,
      groups,
    }
  }, [byWorkspace, ctx.labels, ctx.workspaces])

  const selected =
    filter?.value
      ?.map((id) => (id == null ? null : ctx.labels.byId(id)))
      .filter(Boolean) ?? []

  return (
    <GroupedFilterButton
      groups={labels.groups}
      selected={selected}
      onSelected={(value) => setFilter(buildIdFilter(value, filter))}
      label='Labels'
      renderItem={({ item }) => (
        <WorkspaceItem item={item}>
          <Label value={item} />
        </WorkspaceItem>
      )}
      renderHeader={({ group }) => (
        <WorkspaceItemGroupHeader group={group}>
          <Label value={group.items[0]} />
        </WorkspaceItemGroupHeader>
      )}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      searchPlaceholder='Choose labels ...'
    />
  )
}
