import { isNoneId } from '@motion/shared/identifiers'
import {
  type AllAvailableCustomFieldSchema,
  type CustomFieldSchemaByType,
} from '@motion/ui-logic'
import { createNoneUser } from '@motion/ui-logic/pm/data'
import { byValue, Compare, orderedAtEnd, uniqueBy } from '@motion/utils/array'

import {
  areDateFields,
  areMultiPersonFields,
  areMultiSelectFields,
  areNumberFields,
  arePersonFields,
  areSelectFields,
  areTextFields,
  areUrlFields,
  isDateValue,
  isMultiPersonValue,
  isMultiSelectValue,
  isNumberValue,
  isPersonValue,
  isSelectValue,
  isTextValue,
  isUrlValue,
} from '~/areas/project-management/custom-fields'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import {
  CUSTOM_FIELD_NO_VALUE,
  CUSTOM_FIELD_NONE_ID,
  getPersonInitialValues,
  getUniqueSelectOptionsByIds,
} from './utils'

import {
  type CustomFieldDateRow,
  type CustomFieldMultiPersonRow,
  type CustomFieldMultiSelectRow,
  type CustomFieldNumberRow,
  type CustomFieldPersonRow,
  type CustomFieldSelectRow,
  type CustomFieldTextRow,
  type CustomFieldUrlRow,
} from '../../tree-list/types'
import { type GroupArgs } from '../definitions'
import { type GroupDefinition } from '../utils/multi-group'

const GROUPING_STRING_SORT = Compare.string.with({
  null: 'at-end',
  empty: 'at-end',
  trim: true,
})

export const groupByCustomField = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  args: GroupArgs,
  customFieldsGroupedByName: AllAvailableCustomFieldSchema[]
):
  | GroupDefinition<TItem, CustomFieldTextRow['value']>
  | GroupDefinition<TItem, CustomFieldMultiSelectRow['value']>
  | GroupDefinition<TItem, CustomFieldSelectRow['value']>
  | GroupDefinition<TItem, CustomFieldMultiPersonRow['value']>
  | GroupDefinition<TItem, CustomFieldPersonRow['value']>
  | GroupDefinition<TItem, CustomFieldUrlRow['value']>
  | GroupDefinition<TItem, CustomFieldNumberRow['value']>
  | GroupDefinition<TItem, CustomFieldDateRow['value']>
  | null => {
  if (areMultiSelectFields(customFieldsGroupedByName)) {
    return getCustomFieldMultiSelectGroupDefinition(
      customFieldsGroupedByName,
      args
    )
  } else if (areSelectFields(customFieldsGroupedByName)) {
    return getCustomFieldSelectGroupDefinition(customFieldsGroupedByName, args)
  } else if (areTextFields(customFieldsGroupedByName)) {
    return getCustomFieldTextGroupDefinition(customFieldsGroupedByName)
  } else if (areMultiPersonFields(customFieldsGroupedByName)) {
    return getCustomFieldMultiPersonGroupDefinition(
      customFieldsGroupedByName,
      args
    )
  } else if (arePersonFields(customFieldsGroupedByName)) {
    return getCustomFieldPersonGroupDefinition(customFieldsGroupedByName, args)
  } else if (areDateFields(customFieldsGroupedByName)) {
    return getCustomFieldDateGroupDefinition(customFieldsGroupedByName)
  } else if (areNumberFields(customFieldsGroupedByName)) {
    return getCustomFieldNumberGroupDefinition(customFieldsGroupedByName)
  } else if (areUrlFields(customFieldsGroupedByName)) {
    return getCustomFieldUrlGroupDefinition(customFieldsGroupedByName)
  }

  return null
}

const getCustomFieldTextGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  textFieldsGroupedByName: CustomFieldSchemaByType<'text'>[]
): GroupDefinition<TItem, CustomFieldTextRow['value']> => {
  const { name, type } = textFieldsGroupedByName[0]

  return {
    type: `text/${name}`,
    name,
    groupType: type,
    isBounded: false,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return null

      const matchingField = textFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return null

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null
      if (isTextValue(matchingValue)) {
        return matchingValue.value
      }

      return null
    },
    initialValues: [
      {
        key: CUSTOM_FIELD_NONE_ID,
        value: CUSTOM_FIELD_NO_VALUE,
        workspaceIds: [CUSTOM_FIELD_NONE_ID],
      },
    ],
    keyOf: (value) => value ?? CUSTOM_FIELD_NONE_ID,
    sortBy: byValue(
      (x) => x.key,
      orderedAtEnd([CUSTOM_FIELD_NONE_ID], GROUPING_STRING_SORT)
    ),
  }
}

const FAKE_SELECT_OPTION: CustomFieldMultiSelectRow['value'] = {
  value: CUSTOM_FIELD_NO_VALUE,
  color: 'grey',
  id: CUSTOM_FIELD_NONE_ID,
  customFieldId: CUSTOM_FIELD_NONE_ID,
  workspaceId: CUSTOM_FIELD_NONE_ID,
}

const getCustomFieldMultiSelectGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  multiSelectFieldsGroupedByName: CustomFieldSchemaByType<'multiSelect'>[],
  args: GroupArgs
): GroupDefinition<TItem, CustomFieldMultiSelectRow['value']> => {
  const { name, type } = multiSelectFieldsGroupedByName[0]

  const fieldIds = multiSelectFieldsGroupedByName.map((f) => f.id)

  return {
    type: `multiSelect/${name}`,
    name,
    groupType: type,
    isBounded: true,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return FAKE_SELECT_OPTION

      const matchingField = multiSelectFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return FAKE_SELECT_OPTION

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null

      if (isMultiSelectValue(matchingValue)) {
        const uniqueOptions = getUniqueSelectOptionsByIds(
          matchingField.metadata.options,
          matchingValue.value
        )

        if (uniqueOptions.length === 0) {
          return FAKE_SELECT_OPTION
        }

        return uniqueOptions.map((opt) => ({
          ...opt,
          type: matchingField.type,
          customFieldId: matchingField.id,
          workspaceId: matchingField.workspaceId,
        }))
      }

      return FAKE_SELECT_OPTION
    },
    initialValues: [
      {
        key: FAKE_SELECT_OPTION.id,
        value: FAKE_SELECT_OPTION,
        workspaceIds: [FAKE_SELECT_OPTION.workspaceId],
      },
      ...uniqueBy(
        args.ctx.customFields.all
          .filter(
            (field) =>
              field.type === 'multiSelect' && fieldIds.includes(field.id)
          )
          .flatMap((f) =>
            (f as CustomFieldSchemaByType<'multiSelect'>).metadata.options.map(
              (o) => ({
                ...o,
                workspaceId: f.workspaceId,
                customFieldId: f.id,
              })
            )
          )
          .map((o) => ({
            key: o.value,
            value: o,
            workspaceIds: [o.workspaceId],
          })),
        (field) => field.key
      ),
    ],
    keyOf: (option) => (isNoneId(option.id) ? option.id : option.value),
    sortBy: byValue(
      (x) => x.key,
      orderedAtEnd([CUSTOM_FIELD_NONE_ID], GROUPING_STRING_SORT)
    ),
  }
}

const getCustomFieldSelectGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  selectFieldsGroupedByName: CustomFieldSchemaByType<'select'>[],
  args: GroupArgs
): GroupDefinition<TItem, CustomFieldSelectRow['value']> => {
  const { name, type } = selectFieldsGroupedByName[0]

  const fieldIds = selectFieldsGroupedByName.map((f) => f.id)

  return {
    type: `select/${name}`,
    name,
    groupType: type,
    isBounded: true,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return FAKE_SELECT_OPTION

      const matchingField = selectFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return FAKE_SELECT_OPTION

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null

      if (isSelectValue(matchingValue)) {
        const uniqueOptions = getUniqueSelectOptionsByIds(
          matchingField.metadata.options,
          matchingValue.value != null ? [matchingValue.value] : null
        )

        if (uniqueOptions.length === 0) {
          return FAKE_SELECT_OPTION
        }

        return uniqueOptions.map((opt) => ({
          ...opt,
          type: matchingField.type,
          customFieldId: matchingField.id,
          workspaceId: matchingField.workspaceId,
        }))
      }

      return FAKE_SELECT_OPTION
    },
    initialValues: [
      {
        key: FAKE_SELECT_OPTION.id,
        value: FAKE_SELECT_OPTION,
        workspaceIds: [FAKE_SELECT_OPTION.workspaceId],
      },
      ...uniqueBy(
        args.ctx.customFields.all
          .filter(
            (field) => field.type === 'select' && fieldIds.includes(field.id)
          )
          .flatMap((f) =>
            (f as CustomFieldSchemaByType<'select'>).metadata.options.map(
              (o) => ({
                ...o,
                workspaceId: f.workspaceId,
                customFieldId: f.id,
              })
            )
          )
          .map((o) => ({
            key: o.value,
            value: o,
            workspaceIds: [o.workspaceId],
          })),
        (field) => field.key
      ),
    ],
    keyOf: (option) => (isNoneId(option.id) ? option.id : option.value),
    sortBy: byValue(
      (x) => x.key,
      orderedAtEnd([CUSTOM_FIELD_NONE_ID], GROUPING_STRING_SORT)
    ),
  }
}

const getCustomFieldMultiPersonGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  multiPersonFieldsGroupedByName: CustomFieldSchemaByType<'multiPerson'>[],
  { lookup, ctx }: GroupArgs
): GroupDefinition<TItem, CustomFieldMultiPersonRow['value']> => {
  const { name } = multiPersonFieldsGroupedByName[0]

  return {
    type: `multiPerson/${name}`,
    name,
    groupType: 'multiSelect',
    isBounded: true,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return createNoneUser(item.workspaceId)

      const matchingField = multiPersonFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return createNoneUser(item.workspaceId)

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null

      if (
        isMultiPersonValue(matchingValue) &&
        matchingValue.value != null &&
        matchingValue.value.length > 0
      ) {
        return matchingValue.value
          .map((userId) => lookup('users', userId))
          .filter(Boolean)
      }

      return createNoneUser(item.workspaceId)
    },
    initialValues: getPersonInitialValues(ctx),
    keyOf: (user) => user.name,
    sortBy: byValue((item) => item.value?.name, GROUPING_STRING_SORT),
  }
}

const getCustomFieldPersonGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  personFieldsGroupedByName: CustomFieldSchemaByType<'person'>[],
  { lookup, ctx }: GroupArgs
): GroupDefinition<TItem, CustomFieldPersonRow['value']> => {
  const { name } = personFieldsGroupedByName[0]

  return {
    type: `person/${name}`,
    name,
    groupType: 'select',
    isBounded: true,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return createNoneUser(item.workspaceId)

      const matchingField = personFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return createNoneUser(item.workspaceId)

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null

      if (isPersonValue(matchingValue) && matchingValue.value != null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return lookup('users', matchingValue.value)!
      }

      return createNoneUser(item.workspaceId)
    },
    initialValues: getPersonInitialValues(ctx),
    keyOf: (user) => user.name,
    sortBy: byValue((item) => item.value?.name, GROUPING_STRING_SORT),
  }
}

const getCustomFieldDateGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  dateFieldsGroupedByName: CustomFieldSchemaByType<'date'>[]
): GroupDefinition<TItem, CustomFieldDateRow['value']> => {
  const { name, type } = dateFieldsGroupedByName[0]

  return {
    type: `date/${name}`,
    name,
    groupType: type,
    isBounded: false,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return null

      const matchingField = dateFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return null

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null

      if (isDateValue(matchingValue) && matchingValue.value != null) {
        return matchingValue.value
      }

      return null
    },
    initialValues: [
      {
        key: CUSTOM_FIELD_NONE_ID,
        value: CUSTOM_FIELD_NO_VALUE,
        workspaceIds: [CUSTOM_FIELD_NONE_ID],
      },
    ],
    keyOf: (value) => value ?? CUSTOM_FIELD_NONE_ID,
    sortBy: byValue(
      (x) => x.key,
      orderedAtEnd([CUSTOM_FIELD_NONE_ID], GROUPING_STRING_SORT)
    ),
  }
}

const getCustomFieldNumberGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  numberFieldsGroupedByName: CustomFieldSchemaByType<'number'>[]
): GroupDefinition<TItem, CustomFieldNumberRow['value']> => {
  const { name, type } = numberFieldsGroupedByName[0]

  return {
    type: `number/${name}`,
    name,
    groupType: type,
    isBounded: false,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return null

      const matchingField = numberFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return null

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null

      if (isNumberValue(matchingValue)) {
        return matchingValue.value?.toString() ?? null
      }

      return null
    },
    initialValues: [
      {
        key: CUSTOM_FIELD_NONE_ID,
        value: CUSTOM_FIELD_NO_VALUE,
        workspaceIds: [CUSTOM_FIELD_NONE_ID],
      },
    ],
    keyOf: (value) => value ?? CUSTOM_FIELD_NONE_ID,
    sortBy: byValue(
      (x) => x.key,
      orderedAtEnd([CUSTOM_FIELD_NONE_ID], GROUPING_STRING_SORT)
    ),
  }
}

const getCustomFieldUrlGroupDefinition = <
  TItem extends TaskWithRelations | ProjectWithRelations,
>(
  urlFieldsGroupedByName: CustomFieldSchemaByType<'url'>[]
): GroupDefinition<TItem, CustomFieldUrlRow['value']> => {
  const { name } = urlFieldsGroupedByName[0]

  return {
    type: `url/${name}`,
    name,
    groupType: 'text',
    isBounded: false,
    accessor: (item) => {
      if (item.type !== 'NORMAL') return null

      const matchingField = urlFieldsGroupedByName.find(
        (field) => field.workspaceId === item.workspaceId
      )
      if (!matchingField) return null

      const matchingValue = item.customFieldValues?.[matchingField.id] ?? null

      if (isUrlValue(matchingValue)) {
        return matchingValue.value
      }

      return null
    },
    initialValues: [
      {
        key: CUSTOM_FIELD_NONE_ID,
        value: CUSTOM_FIELD_NO_VALUE,
        workspaceIds: [CUSTOM_FIELD_NONE_ID],
      },
    ],
    keyOf: (value) => value ?? CUSTOM_FIELD_NONE_ID,
    sortBy: byValue(
      (x) => x.key,
      orderedAtEnd([CUSTOM_FIELD_NONE_ID], GROUPING_STRING_SORT)
    ),
  }
}
