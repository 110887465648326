import { EndTimeField } from './end-time-field'
import { StartDateField } from './start-date-field'
import { StartTimeField } from './start-time-field'

export function EventTimeSection() {
  return (
    <div className='flex flex-col gap-2'>
      <StartDateField />

      <div className='flex gap-1.5'>
        <StartTimeField />
        <span className='text-semantic-neutral-text-subtle'>-</span>
        <EndTimeField />
      </div>
    </div>
  )
}
