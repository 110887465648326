import { type PMTaskType } from '@motion/rpc/types'

import { TemplateTaskLine } from './template-task-line'

type SidebarTasksProps = {
  tasks: PMTaskType[]
  onRemove?: (blocker: PMTaskType) => void
  onClickTask?: () => void
}

/**
 * @deprecated - Only used for legacy templates. Use `SidebarTaskList` instead
 */
export function TemplateSidebarTasks({
  tasks,
  onRemove,
  onClickTask,
}: SidebarTasksProps) {
  if (tasks.length === 0) {
    return null
  }

  return (
    <ul className='gap-2 flex flex-col'>
      {tasks.map((task) => (
        <li key={task.id}>
          <TemplateTaskLine
            task={task}
            onRemove={onRemove}
            onClickTask={onClickTask}
          />
        </li>
      ))}
    </ul>
  )
}
