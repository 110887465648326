import { useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectResponseSchema } from '@motion/zod/client'

import { useCopyProjectModalLinkToClipboard } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

import { ProjectResult } from './project-result'

import { stripEmTags } from '../../utils'

export interface ConnectedProjectResultProps {
  item: ProjectResponseSchema
  active: boolean
  onItemClick: () => void
}

export function ConnectedProjectResult({
  item,
  active,
  onItemClick,
}: ConnectedProjectResultProps) {
  const navigate = useNavigate()
  const buildProjectModalUrl = useProjectModalUrl()
  const copyProjectLink = useCopyProjectModalLinkToClipboard()

  const handleClick = () => {
    onItemClick()
    navigate(buildProjectModalUrl({ project: item.id }))
    recordAnalyticsEvent('SEARCH_RESULT_OPENED', { type: 'project' })
  }

  const handleCopyLink = () => {
    copyProjectLink({
      workspaceId: item.workspaceId,
      projectId: item.id,
      projectName: stripEmTags(item.name),
    })
  }

  useShortcut('mod+l', handleCopyLink, { enabled: active })

  return (
    <ProjectResult
      item={item}
      onClick={handleClick}
      onCopyLink={handleCopyLink}
    />
  )
}
