import { HttpValidationError } from '@motion/rpc'
import { showToast } from '@motion/ui/base'
import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'

import { getCustomFieldValidationErrorMessage } from '~/areas/project-management/custom-fields/errors'
import { showErrorToast } from '~/global/toasts'

export function handleProjectUpdateErrors(
  err: Error,
  customFields: AllAvailableCustomFieldSchema[]
) {
  if (err instanceof HttpValidationError) {
    const errorMessages = err.body.errors.reduce<string[]>(
      (acc, validationError) => {
        const customFieldErrorMsg = getCustomFieldValidationErrorMessage(
          validationError,
          customFields
        )

        if (customFieldErrorMsg) {
          return [...acc, customFieldErrorMsg]
        }
        return acc
      },
      []
    )

    const ToastErrorBody = (
      <div className='flex flex-col gap-2'>
        <strong>Fix the following errors to save:</strong>
        <ol className='list-inside list-decimal'>
          {errorMessages.map((m) => (
            <li key={m}>{m}</li>
          ))}
        </ol>
      </div>
    )

    return showToast('error', ToastErrorBody)
  }

  showErrorToast(err, 'There was an error updating the project.')
}
