import { type UploadedFileSchema } from '@motion/rpc-types'
import { useModalApi } from '@motion/web-common/modals'

import { type Dispatch, type SetStateAction, useCallback, useMemo } from 'react'

import { AttachmentGroupsEmptyState } from './components/attachment-groups-empty-state'
import { AttachmentGroupsProjectGroup } from './components/attachment-groups-project-group'
import { AttachmentGroupsTaskGroup } from './components/attachment-groups-task-group.tsx'
import { useFilterAttachmentGroups } from './hooks/use-filter-attachment-groups'
import { type AttachmentGroup } from './types'
import { getParentTarget } from './utils'

export type AttachmentGroupsProps = {
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
  groups: AttachmentGroup[]
  variant?: 'default' | 'compact'
}

export function AttachmentGroups({
  searchQuery,
  setSearchQuery,
  groups,
  variant = 'default',
}: AttachmentGroupsProps) {
  const modalApi = useModalApi()

  const { filteredGroups } = useFilterAttachmentGroups({
    groups,
    query: searchQuery,
  })

  const attachmentIds = useMemo(
    () => filteredGroups.flatMap((group) => group.uploadedFileIds),
    [filteredGroups]
  )

  const handleAttachmentClick = useCallback(
    (attachmentId: UploadedFileSchema['id']) => {
      modalApi.open('attachment-preview', {
        attachmentId,
        attachmentIds,
      })
    },
    [modalApi, attachmentIds]
  )

  const isEmpty = filteredGroups.length === 0

  if (isEmpty) {
    const { targetId, targetType, workspaceId } = getParentTarget(groups)

    return (
      <AttachmentGroupsEmptyState
        query={searchQuery}
        onClearQuery={() => setSearchQuery('')}
        targetId={targetId}
        targetType={targetType}
        workspaceId={workspaceId}
      />
    )
  }

  return (
    <div className='flex flex-col'>
      {filteredGroups.map((group) => {
        if (!group.targetId) return null

        if (group.targetType === 'PROJECT') {
          return (
            <AttachmentGroupsProjectGroup
              key={group.targetId}
              projectId={group.targetId}
              uploadedFileIds={group.uploadedFileIds}
              searchQuery={searchQuery}
              variant={variant}
              withWrapper={groups.length > 1}
              onAttachmentClick={handleAttachmentClick}
            />
          )
        }

        return (
          <AttachmentGroupsTaskGroup
            key={group.targetId}
            taskId={group.targetId}
            uploadedFileIds={group.uploadedFileIds}
            searchQuery={searchQuery}
            variant={variant}
            withWrapper={groups.length > 1}
            onAttachmentClick={handleAttachmentClick}
          />
        )
      })}
    </div>
  )
}
