import { MotionLink } from '~/global/components'

export const NoTemplatesFound = () => {
  return (
    <div className='flex flex-col gap-1 py-1.5 px-2'>
      <p>No templates found</p>

      <MotionLink
        url='https://help.usemotion.com/product-and-feature-guides/projects/templates-unlocking-efficiency#why-use-templates'
        external
        className='text-semantic-primary-text-default underline'
      >
        Learn how to create a template
      </MotionLink>
    </div>
  )
}
