import { SearchableTreeMultiSelect } from '@motion/ui/base'

import { useWorkspacesDropdownTree } from './hooks'
import { getNodeType } from './utils'
import { type WorkspacesTreeMultiSelectDropdownProps } from './workspaces-tree-multi-select-dropdown'
import { WorkspacesTreeNodeIcon } from './workspaces-tree-node-label'

export type WorkspacesSearchableTreeMultiSelectProps = Pick<
  WorkspacesTreeMultiSelectDropdownProps,
  | 'selectedIds'
  | 'leafNodeType'
  | 'onChange'
  | 'computeDisabled'
  | 'hideNoProject'
> & { closePopover: () => void }

export const WorkspacesSearchableTreeMultiSelect = ({
  selectedIds,
  leafNodeType = 'PROJECT',
  onChange,
  computeDisabled,
  hideNoProject = false,
}: WorkspacesSearchableTreeMultiSelectProps) => {
  const noneItemLabel =
    leafNodeType === 'PROJECT'
      ? 'No project'
      : leafNodeType === 'FOLDER'
        ? 'No folder'
        : undefined

  const { rootNode, models } = useWorkspacesDropdownTree({
    leafNodeType,
    noneItemLabel,
    computeDisabled,
    hideNoProject,
  })

  return (
    <SearchableTreeMultiSelect
      rootNode={rootNode}
      onSelect={onChange}
      selectedIds={selectedIds}
      renderLabel={(node) => <span className='truncate'>{node.label}</span>}
      computeSearchable={(node) => {
        const type = getNodeType(node, models.folders, models.projects)
        return type === leafNodeType
      }}
      renderIcon={(node) => (
        <WorkspacesTreeNodeIcon
          id={node.id}
          nodeType={getNodeType(node, models.folders, models.projects)}
          leafNodeType={leafNodeType}
        />
      )}
    />
  )
}
