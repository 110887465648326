import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useI18N } from '~/global/contexts'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { type ActionArgs } from './types'

// Update each parent task sequentially to ignore warnings (cannot bulk update recurring parent tasks)
export const useUpdateParentTasksWarnings = (args: ActionArgs) => {
  const { tasks, setIsLoading, type } = args
  const updateTask = useTaskUpdater()
  const { pluralize } = useI18N()

  return useCallback(async () => {
    const uniqueParentIds = Array.from(
      new Set(
        tasks
          .map((task) =>
            task.type === 'RECURRING_INSTANCE'
              ? task.parentRecurringTaskId
              : null
          )
          .filter(Boolean)
      )
    )

    recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_IGNORE_WARNING_PARENT_TASK', {
      type,
      numTasks: uniqueParentIds.length,
    })

    setIsLoading(true)

    const updatedTaskCalls = await Promise.allSettled(
      uniqueParentIds.map((taskId) => {
        try {
          return updateTask(taskId, {
            ignoreWarnOnPastDue: true,
          })
        } catch (error) {
          return Promise.reject(error)
        }
      })
    )

    const tasksUnableToUpdate = updatedTaskCalls.filter(
      (result) => result.status === 'rejected'
    )

    if (tasksUnableToUpdate.length) {
      showErrorToast(
        templateStr('{{numTasks}} {{tasks}} could not be updated', {
          numTasks: tasksUnableToUpdate.length,
          tasks: pluralize(tasksUnableToUpdate.length, 'task', 'tasks'),
        })
      )
    } else {
      showToast(
        'success',
        templateStr(
          '{{numTasks}} {{tasks}} updated to ignore all future warnings',
          {
            numTasks: uniqueParentIds.length,
            tasks: pluralize(uniqueParentIds.length, 'task', 'tasks'),
          }
        )
      )
    }

    setIsLoading(false)
  }, [pluralize, setIsLoading, tasks, type, updateTask])
}
