import type { StageDefinitionSchema } from '@motion/rpc-types'

import { StageLabel, type StageLabelProps } from '~/global/components/labels'

export type RelativeDateLabelProps = {
  value: Pick<StageDefinitionSchema, 'name' | 'color'>
  size?: StageLabelProps['size']
}

export const RelativeDateLabel = ({ value, size }: RelativeDateLabelProps) => {
  return <StageLabel value={value} size={size} />
}
