import { API } from '@motion/rpc'
import { createQueryFilter, MotionCache } from '@motion/rpc-cache'
import { type UpdateProjectDefinitionSchema } from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'

export function applyOptimisticProjectDefinitionUpdates(
  client: QueryClient,
  id: string,
  updates: UpdateProjectDefinitionSchema
) {
  const { rollback } = MotionCache.patch(
    client,
    createQueryFilter([API.workspacesV2.queryKeys.root]),
    'projectDefinitions',
    { [id]: updates }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
