import { AnalyticsMetadata } from '@motion/web-base/analytics'

import { type ReactNode } from 'react'

import { type RouteAnalyticsMetadataParams } from './types'

type SectionAnalyticsMetadataProps = {
  children: ReactNode
  name: RouteAnalyticsMetadataParams['section']
}
export const SectionAnalyticsMetadata = ({
  name,
  children,
}: SectionAnalyticsMetadataProps) => {
  return (
    <AnalyticsMetadata data={{ section: name }}>{children}</AnalyticsMetadata>
  )
}
