import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import {
  getSegmentAnalytics,
  getTrackingCookies,
  recordAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useLocalStorage } from '@motion/web-common/storage'

import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'
import { useMemo, useState } from 'react'

import {
  DEFAULT_BOOKING_CSM,
  INVITE_MEMBER_SIZES,
  type InviteMemberSize,
  ORGANIZATION_SIZES,
  type OrganizationSize,
  type Position,
  POSITION_LABELS,
} from './utils'

import { BookDemoCallModal } from '../shared/book-demo-modal'
import { SubmittedInfoModal } from '../shared/submitted-info-modal'

const GREATER_THAN_TEN_COMPANY_SIZES = ORGANIZATION_SIZES.filter(
  (size) => size !== 'Just me' && size !== '2-4' && size !== '5-10'
)

type KycCompanyQuestionnaireProps = {
  userEmail: string
  goToNextPage: () => void
  skipBookingPage: () => void
}

export function KycCompanyQuestionnaire({
  userEmail,
  goToNextPage,
  skipBookingPage,
}: KycCompanyQuestionnaireProps) {
  const team = useAppSelector(selectTeam)
  const [kycResults, setKycResults] = useLocalStorage('kycResults')
  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')
  const bookDemoModalEnabled = useHasTreatment(
    'onboarding-demo-booking-kyc-11-24'
  )
  const cookieData = getTrackingCookies()

  const [isBookDemoModalOpen, setIsBookDemoModalOpen] = useState(false)
  const [isClientServicesCompany, setIsClientServicesCompany] = useState<
    boolean | null
  >(kycResults?.isClientServicesCompany ?? null)
  const [currentPosition, setCurrentPosition] = useState<Position | null>(
    kycResults?.currentPosition ?? null
  )
  const [companySize, setCompanySize] = useState<OrganizationSize | null>(
    kycResults?.companySize ?? null
  )
  const [inviteMemberSize, setInviteMemberSize] =
    useState<InviteMemberSize | null>(kycResults?.inviteMemberSize ?? null)

  const isEligibleForDemoCall = useMemo(() => {
    const companySizeGreaterThanTen = GREATER_THAN_TEN_COMPANY_SIZES.includes(
      companySize ?? ''
    )
    const inviteMemberSizeGreaterThanFourteen =
      inviteMemberSize === 'Between 15 and 49' ||
      inviteMemberSize === '50 or greater'

    return (
      bookDemoModalEnabled &&
      companySizeGreaterThanTen &&
      inviteMemberSizeGreaterThanFourteen
    )
  }, [bookDemoModalEnabled, companySize, inviteMemberSize])

  const cannotSubmit =
    companySize == null ||
    isClientServicesCompany == null ||
    inviteMemberSize == null ||
    currentPosition == null

  function updateKycResults<T extends keyof NonNullable<typeof kycResults>>(
    key: T,
    value: NonNullable<typeof kycResults>[T]
  ) {
    setKycResults({
      ...kycResults,
      [key]: value,
    })
  }

  function handleSubmit() {
    if (cannotSubmit) return

    const isClientServicesCompany = kycResults?.isClientServicesCompany ?? false

    recordAnalyticsEvent('KYC_COMPANY_QUESTIONNAIRE_SUBMIT', {
      isClientServicesCompany,
      currentPosition,
      companySize: companySize === 'Just me' ? '1' : companySize,
      inviteMemberSize,
    })

    // track exposure for when users would have seen booking page
    if (team?.id) {
      recordAnalyticsEvent('CSM_BOOKING_EVALUATE', {
        teamId: team.id,
        treatment: DEFAULT_BOOKING_CSM,
      })
    }

    const isManager = kycResults?.currentPosition !== 'individual_contributor'
    const isOnboardingICP = isClientServicesCompany || isManager
    const bucketSeatCount = team?.pmTeamSubscription?.bucketSeats || 0
    const companySizeGreaterThanTen =
      GREATER_THAN_TEN_COMPANY_SIZES.includes(companySize)
    const isEligibleForBooking =
      bucketSeatCount >= 10 ||
      (bucketSeatCount >= 5 && companySizeGreaterThanTen) ||
      (shouldUseNoCcTrial && companySizeGreaterThanTen && isManager)

    if (isOnboardingICP) {
      getSegmentAnalytics()?.track('ONBOARDING_ICP', {
        isTeam: !!team,
      })
    }

    if (isEligibleForBooking) {
      return goToNextPage() // still gated by flag
    }

    skipBookingPage()
  }

  return (
    <div className='w-full flex justify-center pt-[42px]'>
      <div className='flex w-[700px] flex-col items-center gap-10'>
        <div className='flex flex-col items-center gap-4 self-stretch'>
          <PageTitle>🙋 Let’s personalize Motion even more for you</PageTitle>
        </div>
        <QuestionSection>
          <div className='flex flex-col justify-center items-center gap-1.5'>
            <QuestionTitle>
              Is your company a client-services company?
            </QuestionTitle>
            <span className='text-semantic-neutral-text-subtle text-center text-sm font-light w-[902px]'>
              A client services business provides specialized services like
              consulting, marketing, or legal advice directly to clients. It is
              a business where most of the projects are repeatable and can be
              templatized into a Standard Operating Procedure
            </span>
          </div>
          <AnswerGroup>
            <Button
              onClick={() => {
                setIsClientServicesCompany(true)
                updateKycResults('isClientServicesCompany', true)
              }}
              sentiment={isClientServicesCompany ? 'primary' : 'neutral'}
              variant={isClientServicesCompany ? 'outlined' : 'solid'}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setIsClientServicesCompany(false)
                updateKycResults('isClientServicesCompany', false)
              }}
              sentiment={
                isClientServicesCompany === false ? 'primary' : 'neutral'
              }
              variant={isClientServicesCompany === false ? 'outlined' : 'solid'}
            >
              No
            </Button>
          </AnswerGroup>
        </QuestionSection>
        <QuestionSection>
          <QuestionTitle>How large is your company?</QuestionTitle>
          <AnswerGroup>
            {ORGANIZATION_SIZES.map((orgSize) => (
              <Button
                key={orgSize}
                onClick={() => {
                  setCompanySize(orgSize)
                  updateKycResults('companySize', orgSize)
                }}
                sentiment={companySize === orgSize ? 'primary' : 'neutral'}
                variant={companySize === orgSize ? 'outlined' : 'solid'}
              >
                {orgSize}
              </Button>
            ))}
          </AnswerGroup>
        </QuestionSection>
        <QuestionSection>
          <QuestionTitle>My current position at my company is:</QuestionTitle>
          <AnswerGroup>
            {POSITION_LABELS.map(({ label, value }) => (
              <Button
                key={value}
                onClick={() => {
                  setCurrentPosition(value)
                  updateKycResults('currentPosition', value)
                }}
                sentiment={currentPosition === value ? 'primary' : 'neutral'}
                variant={currentPosition === value ? 'outlined' : 'solid'}
              >
                {label}
              </Button>
            ))}
          </AnswerGroup>
        </QuestionSection>
        <QuestionSection>
          <QuestionTitle>
            How many team members do you plan to have using Motion?
          </QuestionTitle>
          <AnswerGroup>
            {INVITE_MEMBER_SIZES.map((inviteSize) => (
              <Button
                key={inviteSize}
                onClick={() => {
                  setInviteMemberSize(inviteSize)
                  updateKycResults('inviteMemberSize', inviteSize)
                }}
                sentiment={
                  inviteMemberSize === inviteSize ? 'primary' : 'neutral'
                }
                variant={inviteMemberSize === inviteSize ? 'outlined' : 'solid'}
              >
                {inviteSize}
              </Button>
            ))}
          </AnswerGroup>
        </QuestionSection>
        <Button
          disabled={cannotSubmit}
          onClick={() => {
            if (isEligibleForDemoCall) {
              setIsBookDemoModalOpen(true)
            } else {
              handleSubmit()
            }
          }}
        >
          Continue
        </Button>
      </div>

      <BookDemoCallModal
        isOpen={isBookDemoModalOpen}
        onClose={() => {
          setIsBookDemoModalOpen(false)
          handleSubmit()
        }}
        userEmail={userEmail}
      />
      <SubmittedInfoModal
        isOpen={
          cookieData.onboarding_survey_submitted === 'true' &&
          bookDemoModalEnabled
        }
        onClose={handleSubmit}
      />
    </div>
  )
}

export const PageTitle = classed('h4', {
  base: `
    text-semantic-neutral-text-default text-center
    text-[32px] leading-[42px] font-bold
  `,
})

const QuestionSection = classed('div', {
  base: `
    flex flex-col justify-center items-center gap-4 self-stretch
  `,
})

const QuestionTitle = classed('span', {
  base: `
    text-semantic-neutral-text-default text-center text-base leading-4 font-medium
  `,
})

const AnswerGroup = classed('div', {
  base: `
    flex items-center gap-2.5 whitespace-nowrap min-w-[300px] justify-center
  `,
})
