import { type DateCustomField } from '@motion/ui-logic'

import { CustomField } from '~/areas/project-management/custom-fields'
import { type FC } from 'react'

import { CellText } from '../../components'
import { type ControlledCustomFieldProps } from '../types'

export const ControlledDateCell: FC<
  ControlledCustomFieldProps<DateCustomField>
> = ({ value, onSubmit, customField, disabled }) => {
  return (
    <CellText withHover isFake={disabled}>
      <CustomField.Date
        name={customField.definition.name}
        value={value}
        onChange={onSubmit}
        disabled={disabled}
        hideIcon
        noHoverStyle
      />
    </CellText>
  )
}
