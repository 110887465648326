/* eslint-disable react-refresh/only-export-components */
import { type TaskDefinitionSchema } from '@motion/rpc-types'
import {
  findStageForTask,
  type FlowTemplateFormFields,
  mapTaskDefinitionToTask,
} from '@motion/ui-logic/pm/project'

import { type InitialTaskData } from '~/areas/tasks/modals/task-modal/hooks'
import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useMemo,
  useState,
} from 'react'

export type TaskDefinitionModalState = {
  initialTaskData: InitialTaskData
  taskDefinition: TaskDefinitionSchema | null
  flowFormData: FlowTemplateFormFields | null
  isTaskDirty: boolean
  setTaskDirtyStates: Dispatch<SetStateAction<Record<string, boolean>>>
  setTaskId: (taskId: string) => void
}

const defaultValue: TaskDefinitionModalState = {
  initialTaskData: {
    isLoading: false,
    hasError: false,
    task: undefined,
    parentTask: undefined,
  },
  taskDefinition: null,
  flowFormData: null,
  isTaskDirty: false,
  setTaskDirtyStates: () => {},
  setTaskId: () => {},
}

export const TaskDefinitionModalStateContext = createContext(defaultValue)

export type TaskDefinitionModalStateProviderProps = {
  children: ReactNode
  workspaceId: string
  taskDefinitionId: string
  flowFormData: FlowTemplateFormFields
  initialDirtyTasks: Record<string, boolean>
}

export function TaskDefinitionModalStateProvider({
  children,
  workspaceId,
  taskDefinitionId,
  flowFormData,
  initialDirtyTasks,
}: TaskDefinitionModalStateProviderProps) {
  const [taskDirtyStates, setTaskDirtyStates] =
    useState<Record<string, boolean>>(initialDirtyTasks)
  // Task ID can be changed to navigate to a different task
  const [taskId, setTaskId] = useState<string>(taskDefinitionId)

  const existingTaskDefinition = flowFormData.stages
    .flatMap((stage) => stage.tasks)
    .find((task) => task.id === taskId)

  if (!existingTaskDefinition) {
    throw new Error('Task definition not found')
  }

  const initialStageDefinitionId = findStageForTask(
    flowFormData.stages,
    taskId
  )?.id

  if (!initialStageDefinitionId) {
    throw new Error('Stage definition ID not found')
  }

  const value = useMemo(() => {
    const initialTaskData: InitialTaskData = {
      task: mapTaskDefinitionToTask(
        existingTaskDefinition,
        workspaceId,
        initialStageDefinitionId
      ),
      parentTask: undefined,
      isLoading: false,
      hasError: false,
    }

    return {
      initialTaskData,
      taskDefinition: existingTaskDefinition,
      flowFormData,
      isTaskDirty: taskDirtyStates[taskId] ?? false,
      setTaskDirtyStates,
      setTaskId,
    }
  }, [
    existingTaskDefinition,
    workspaceId,
    initialStageDefinitionId,
    flowFormData,
    taskDirtyStates,
    taskId,
  ])

  return (
    <TaskDefinitionModalStateContext.Provider value={value}>
      {children}
    </TaskDefinitionModalStateContext.Provider>
  )
}
