import { type ProjectSchema, type UploadedFileSchema } from '@motion/rpc-types'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { Attachments, AttachmentUploadButton } from '~/areas/attachments'
import {
  useGetProjectAttachmentsCount,
  useGetProjectTaskAttachments,
} from '~/areas/attachments/hooks'
import { sortAttachmentIds } from '~/areas/attachments/utils'
import { UncontrolledCollapsableContainer } from '~/areas/task-project/components'
import { useProjectTasks } from '~/areas/task-project/hooks/use-project-tasks'
import { useProject } from '~/global/hooks'
import { useCallback, useState } from 'react'

type ProjectAttachmentsCollapsableProps = {
  projectId: ProjectSchema['id']
}

export const ProjectAttachmentsCollapsable = ({
  projectId,
}: ProjectAttachmentsCollapsableProps) => {
  const [expanded, setExpanded] = useState(false)

  const modalApi = useModalApi()

  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')

  const project = useProject(projectId)

  const { data: tasks } = useProjectTasks({
    projectId: project?.id,
    workspaceId: project?.workspaceId,
  })
  const projectAttachments = useGetProjectTaskAttachments({
    targetId: projectId,
    targetType: 'PROJECT',
  })

  const attachmentIds = hasFlowsAttachments
    ? projectAttachments.map((attachment) => attachment.id)
    : sortAttachmentIds(project?.uploadedFileIds ?? [])

  const handleToggle = () => setExpanded((expanded) => !expanded)

  const handleAttachmentUpload = () => setExpanded(true)

  const handleAttachmentClick = useCallback(
    (attachmentId: UploadedFileSchema['id']) => {
      modalApi.open('attachment-preview', {
        attachmentId,
        attachmentIds,
      })
    },
    [modalApi, attachmentIds]
  )

  const { childAttachmentCount: childAttachmentCountWithDefinitions } =
    useGetProjectAttachmentsCount({ projectId })

  const childAttachmentCount = hasFlowsAttachments
    ? childAttachmentCountWithDefinitions
    : (tasks?.flatMap((task) => task.uploadedFileIds ?? []).length ?? 0)

  // Attachments are only available for normal
  if (project?.type !== 'NORMAL') {
    return null
  }

  return (
    <UncontrolledCollapsableContainer
      title={`Attachments (${attachmentIds.length})`}
      headerActions={
        <AttachmentUploadButton
          targetId={projectId}
          targetType='PROJECT'
          workspaceId={project.workspaceId}
          onUpload={handleAttachmentUpload}
        />
      }
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='pl-7'>
        <Attachments
          attachmentIds={attachmentIds}
          childAttachmentCount={childAttachmentCount}
          targetId={projectId}
          targetType='PROJECT'
          limit={3}
          onAttachmentClick={handleAttachmentClick}
        />
      </div>
    </UncontrolledCollapsableContainer>
  )
}
