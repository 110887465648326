import { PopoverButton, SearchableDropdown, showToast } from '@motion/ui/base'
import { FieldLabel, TextField } from '@motion/ui/forms'
import {
  getFormatOptions,
  getNameValidationRules,
  hasFormatOptions,
  hasTypeOptions,
} from '@motion/ui-logic'

import { type GetCustomFieldsCategoriesResponse } from '~/areas/project-management/hooks'
import { useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { FormatOptionField } from './format-option-field'
import { OptionFields } from './option-fields'

type Props = {
  isEditCustomField?: boolean
} & GetCustomFieldsCategoriesResponse

export const CustomFieldSettings = (props: Props) => {
  const { watch } = useFormContext()
  const fieldType = watch('type')

  return (
    <div className='w-[450px] flex flex-col gap-4'>
      <FieldName />

      <FieldType {...props} />

      {hasTypeOptions(fieldType) && <OptionFields />}
      {hasFormatOptions(fieldType) && (
        <FormatOptionField formatOptions={getFormatOptions(fieldType)} />
      )}
    </div>
  )
}

function FieldName() {
  const { watch, control } = useFormContext()
  const fieldType = watch('type')

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'name',
    control,
    rules: getNameValidationRules(fieldType),
  })

  useEffect(() => {
    if (error == null) return

    if (error.type === 'maxLength') {
      showToast(
        'error',
        `The field name is too long (maximum is ${
          getNameValidationRules(fieldType).maxLength
        } characters)`
      )
    }

    if (error.type === 'required') {
      showToast('error', `Name is required`)
    }
  }, [error, fieldType])

  return (
    <TextField
      autoFocus
      value={field.value}
      onChange={field.onChange}
      name={field.name}
      label='Field name'
    />
  )
}

function FieldType({
  categoriesConfigList,
  categoryMap,
  isEditCustomField,
}: Props) {
  const { control } = useFormContext()

  const { field } = useController({
    name: 'type',
    control,
    rules: {
      required: true,
    },
  })

  const selectedType:
    | GetCustomFieldsCategoriesResponse['categoryMap'][string]
    | null = categoryMap[field.value] ?? null

  return (
    <FieldLabel label='Type'>
      <SearchableDropdown
        selectedItem={selectedType}
        onChange={(category) => field.onChange(category.id)}
        items={categoriesConfigList}
        renderItem={(category) => <span>{category.name}</span>}
      >
        <PopoverButton disabled={isEditCustomField}>
          {selectedType?.name ?? 'Unknown'}
        </PopoverButton>
      </SearchableDropdown>
    </FieldLabel>
  )
}
