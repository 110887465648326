import {
  API,
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  getCacheEntryValue,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
  useStoreModelsFn,
} from '@motion/rpc-cache'
import { StageAdjusterStrategy } from '@motion/shared/flows'
import { stageAdjusterFn } from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import { type ProjectSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import {
  applyOptimisticProjectDelete,
  applyOptimisticProjectUpdates,
  optimisticUpdateStageTasks,
} from '~/global/cache'

export const projectCacheKeysToUpdate = [
  API.workspacesV2.queryKeys.root,
  MODEL_CACHE_KEY,
]
const projectCacheQueryFilter = createQueryFilter(projectCacheKeysToUpdate)

type LazyByIdApi = ApiTypes<typeof API.projectsV2.getLazyById>
export const useReadProjectFn = () => {
  const storeModels = useStoreModelsFn()
  const client = useQueryClient()
  const queryOptionsOf = useQueryOptionsFactory(API.projectsV2.getLazyById)

  return async (
    id: LazyByIdApi['args']['projectId'],
    opts?: LazyByIdApi['UseQueryOptions']
  ): Promise<ProjectSchema | undefined> => {
    const cached = getCacheEntryValue(client, 'projects', id)
    if (cached) return cached

    const queryArgs = queryOptionsOf({ projectId: id }, opts)

    const response = await client.fetchQuery({
      ...queryArgs,
      queryFn: async (ctx) => {
        const response = await queryArgs.queryFn(ctx as any)
        storeModels(response.models)
        return response
      },
    })
    return response.models.projects[response.id]
  }
}

const useCreateProjectMutation = createUseMutation(API.projectsV2.create)
export const useCreateProject = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.create>
) => {
  const queryClient = useQueryClient()

  return useCreateProjectMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    ...opts,
  })
}

const useCreateProjectFromTaskMutation = createUseMutation(
  API.projectsV2.createFromTask
)
export const useCreateProjectFromTask = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.createFromTask>
) => {
  const queryClient = useQueryClient()

  return useCreateProjectFromTaskMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    ...opts,
  })
}

const useApplyDefinitionToProjectMutation = createUseMutation(
  API.projectsV2.applyDefinitionToProject
)
export const useApplyDefinitionToProject = () => {
  const queryClient = useQueryClient()
  return useApplyDefinitionToProjectMutation({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(projectCacheKeysToUpdate),
        data
      )
    },
  })
}

const useUpdateProjectMutation = createUseMutation(API.projectsV2.update)
export const useUpdateProject = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.update>
) => {
  const queryClient = useQueryClient()
  // This will make the mutation to optimistically update the data in the cache
  return useUpdateProjectMutation({
    onSuccess: (data, vars) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)

      if (vars.folderId || vars.folderItemOrder) {
        queryClient.invalidateQueries(API.folders.queryKeys.getAll)
      }
    },
    onMutate: async ({ projectId, ...updatedProject }) => {
      const cacheUpdates = await applyOptimisticProjectUpdates(
        queryClient,
        projectId,
        updatedProject
      )

      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },

    ...opts,
  })
}

const useDeleteProjectMutation = createUseMutation(API.projectsV2.deleteProject)
export const useDeleteProject = () => {
  const queryClient = useQueryClient()
  return useDeleteProjectMutation({
    onMutate: ({ projectId }) => {
      const cacheUpdates = applyOptimisticProjectDelete(queryClient, projectId)
      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
    },
  })
}

const useCompleteProjectMutation = createUseMutation(
  API.projectsV2.completeProject
)
export const useCompleteProject = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.completeProject>
) => {
  const queryClient = useQueryClient()

  return useCompleteProjectMutation({
    onMutate: async ({ projectId, statusId }) => {
      const cacheUpdates = await applyOptimisticProjectUpdates(
        queryClient,
        projectId,
        { statusId }
      )
      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
    },
    ...opts,
  })
}

const useResolveProjectMutation = createUseMutation(API.projectsV2.resolve)
export const useResolveProject = () => {
  const queryClient = useQueryClient()

  return useResolveProjectMutation({
    onMutate: async ({ projectId, statusId }) => {
      const cacheUpdates = await applyOptimisticProjectUpdates(
        queryClient,
        projectId,
        { statusId }
      )
      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
    },
  })
}

const useUpdateProjectStageDueDateMutation = createUseMutation(
  API.projectsV2.updateProjectStageDueDate
)
export const useUpdateProjectStageDueDate = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.updateProjectStageDueDate>
) => {
  const queryClient = useQueryClient()
  const readProject = useReadProjectFn()

  return useUpdateProjectStageDueDateMutation({
    onMutate: async (args) => {
      const project = await readProject(args.projectId)
      if (project == null) return

      let cacheUpdates: OptimisticUpdateValue[] = []
      try {
        let newProjectStart: string | undefined
        let newProjectDeadline: string | undefined
        let newProjectStages = [...project.stages]
        const stageAdjusterStrategy =
          args.dateAdjustmentStrategy === 'ABSORB'
            ? StageAdjusterStrategy.ABSORB
            : args.dateAdjustmentStrategy === 'SHIFT'
              ? StageAdjusterStrategy.SHIFT
              : null

        stageAdjusterFn({
          params: {
            startDate: project.startDate,
            dueDate: project.dueDate,
            stageDueDates: project.stages,
          },
          onAction: (adjuster) => {
            adjuster.prepareStageAdjustment({
              strategy: {
                before:
                  stageAdjusterStrategy ?? StageAdjusterStrategy.ACCORDION,
                after: stageAdjusterStrategy ?? StageAdjusterStrategy.SHIFT,
              },
              stageDefinitionId: args.stageDefinitionId,
              value: args.dueDate,
            })
          },
          onResult: ({ startDate, dueDate, stageDueDates }) => {
            newProjectStart = startDate
            newProjectDeadline = dueDate

            const mappedNewStages = project.stages.map((s) => {
              const adjustedStage = stageDueDates.find(
                (stage) => stage.stageDefinitionId === s.stageDefinitionId
              )

              if (adjustedStage == null) return s

              return { ...s, dueDate: adjustedStage.dueDate }
            })

            newProjectStages = mappedNewStages
          },
        })

        const stageTaskUpdates = optimisticUpdateStageTasks(
          queryClient,
          args.stageDefinitionId,
          project,
          newProjectStages
        )

        cacheUpdates = [...stageTaskUpdates]

        const updatedProject = {
          ...project,
          startDate: newProjectStart ?? project.startDate,
          dueDate: newProjectDeadline ?? project.dueDate,
          stages: newProjectStages,
        }

        cacheUpdates.push(
          await applyOptimisticProjectUpdates(
            queryClient,
            args.projectId,
            updatedProject
          )
        )
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useUpdateProjectStageDueDateMutation',
          },
        })
      }

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue[] | undefined
      }
      cacheUpdates?.forEach((update) => update.rollback())
    },
    ...opts,
  })
}

const useShiftProjectMutation = createUseMutation(
  API.projectsV2.shiftProjectDates
)
export const useShiftProject = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.shiftProjectDates>
) => {
  const queryClient = useQueryClient()
  return useShiftProjectMutation({
    // TODO: Optimistically update the project definition
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    ...opts,
  })
}

const useSetProjectToStageMutation = createUseMutation(
  API.projectsV2.setProjectToStage
)
export const useSetProjectToStage = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.setProjectToStage>
) => {
  const queryClient = useQueryClient()
  const readProject = useReadProjectFn()

  return useSetProjectToStageMutation({
    onMutate: async (args) => {
      const project = await readProject(args.projectId)
      if (project == null) return

      const cacheUpdates = await applyOptimisticProjectUpdates(
        queryClient,
        args.projectId,
        { ...project, activeStageDefinitionId: args.stageDefinitionId }
      )

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
    },
    ...opts,
  })
}

const useAdvanceProjectStageMutation = createUseMutation(
  API.projectsV2.advanceProjectStage
)
export const useAdvanceProjectStage = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.advanceProjectStage>
) => {
  const queryClient = useQueryClient()

  return useAdvanceProjectStageMutation({
    onMutate: async (args) => {
      const cacheUpdates = await applyOptimisticProjectUpdates(
        queryClient,
        args.project.id,
        { ...args.project, activeStageDefinitionId: args.nextStageDefinitionId }
      )

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }
      cacheUpdates?.rollback()
    },
    ...opts,
  })
}

const useCompleteProjectStageMutation = createUseMutation(
  API.projectsV2.completeProjectStage
)
export const useCompleteProjectStage = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.completeProjectStage>
) => {
  const queryClient = useQueryClient()
  return useCompleteProjectStageMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    ...opts,
  })
}

const useCancelProjectStageMutation = createUseMutation(
  API.projectsV2.cancelProjectStage
)
export const useCancelProjectStage = (
  opts?: ApiUseMutationOptions<typeof API.projectsV2.cancelProjectStage>
) => {
  const queryClient = useQueryClient()
  return useCancelProjectStageMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, projectCacheQueryFilter, data)
    },
    ...opts,
  })
}

const useBulkUpdateProjectsMutation = createUseMutation(
  API.projectsV2.bulkUpdateProjects
)
export const useBulkUpdateProjects = () => {
  const client = useQueryClient()

  return useBulkUpdateProjectsMutation({
    onMutate: async ({ bulkUpdateProjectsInWorkspaces }) => {
      const cacheUpdatePromises = bulkUpdateProjectsInWorkspaces.flatMap(
        ({ projectIds, update }) => {
          const projectUpdates = projectIds
            .map((projectId) => {
              const cachedProject = getCacheEntryValue(
                client,
                'projects',
                projectId
              )

              if (!cachedProject) return null

              if (update.type === 'bulk-project-location-update') {
                return applyOptimisticProjectUpdates(client, projectId, {
                  folderId: update.folderId,
                })
              }

              return null
            })
            .filter(Boolean)

          return projectUpdates
        }
      )

      const cacheUpdates = await Promise.all(cacheUpdatePromises)

      return { cacheUpdates }
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue[]
      }

      cacheUpdates.forEach((cache) => cache.rollback())
    },
  })
}
