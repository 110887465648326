import { Checkbox } from '@motion/ui/forms'

import { CustomFieldsSidebar } from '~/areas/task-project/components'
import { useFieldArray } from 'react-hook-form'

import { useSetupProjectForm } from '../../hooks'

export const CustomFieldsStepContent = () => {
  const { form } = useSetupProjectForm()
  const { control, setValue } = form

  const workspaceId = form.watch('workspaceId')

  const customFieldSyncInstanceIds = form.watch('customFieldSyncInstanceIds')

  const { fields, update } = useFieldArray({
    control,
    name: 'customFieldValuesFieldArray',
  })

  const isSynced = (instanceId: string) => {
    return customFieldSyncInstanceIds.includes(instanceId)
  }

  const setSynced = (instanceId: string, synced: boolean) => {
    if (synced) {
      setValue(
        'customFieldSyncInstanceIds',
        [...customFieldSyncInstanceIds, instanceId],
        { shouldTouch: true, shouldDirty: true }
      )
    } else {
      setValue(
        'customFieldSyncInstanceIds',
        [...customFieldSyncInstanceIds].filter((id) => id !== instanceId),
        { shouldTouch: true, shouldDirty: true }
      )
    }
  }

  const toggleSynced = (instanceId: string) => {
    const synced = isSynced(instanceId)
    setSynced(instanceId, !synced)
  }

  return (
    <div className='flex gap-2'>
      <div className='flex flex-col truncate'>
        <CustomFieldsSidebar
          fields={fields}
          workspaceId={workspaceId}
          onChange={(customField, value, index) => {
            if (!fields[index].value) {
              setSynced(customField.instanceId, true)
            }
            update(index, {
              ...customField,
              value: value as any,
            })
          }}
        />
      </div>
      <div className='flex flex-col shrink-0'>
        {fields.map((field) => (
          <div
            key={field.instanceId}
            className='flex items-center text-semantic-neutral-text-disabled text-xs h-[30px]'
          >
            <Checkbox
              onChange={() => {
                toggleSynced(field.instanceId)
              }}
              checked={isSynced(field.instanceId)}
            />
            <span>Apply to all tasks</span>
          </div>
        ))}
      </div>
    </div>
  )
}
