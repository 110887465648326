import { Tooltip } from '@motion/ui/base'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type DeadlineDelayBadgeProps = {
  dayNumberTooltipContent: ReactNode
  deadlineDelayMinutes: number
  deadlineDelayDays: number
}

export function DeadlineDelayBadge({
  dayNumberTooltipContent,
  deadlineDelayMinutes,
  deadlineDelayDays,
}: DeadlineDelayBadgeProps) {
  return (
    <Tooltip renderContent={() => dayNumberTooltipContent} asChild>
      <span
        className={twMerge(
          'font-semibold text-[10px] leading-3',
          deadlineDelayMinutes < 0 && 'text-semantic-error-text-default',
          deadlineDelayMinutes >= 0 && 'text-semantic-success-text-default'
        )}
      >
        {deadlineDelayDays}d
      </span>
    </Tooltip>
  )
}
