import { type AmplitudeExperimentName } from '@motion/web-common/flags'

const permanentExperiments = [
  'release-notes',
  'auto-schedule-range',
  'new_maintenance_mode',
  'feature_flagged_banner',
] as const

export const AmplitudeExperimentNames = [
  ...permanentExperiments,
  'cancellation-interception-discount',
  'mobile-push-notifications-release-v3',
  'stripe-trial-length',
  'onboarding-video',
  'email-signin-checkout',
  'onboarding-iframes',
  'no-cc-combined-trial',
  'skip-firebase-connection-error',
  'cookie-alert',
  'team-pricing',
  'change-email',
  'cookie-auth-only',
  'no-cc-b2b-everywhere',
  // Flows
  'flows-better-resize-stages',
  'flows-m5-reusable-stages',
  'flows-pwt-attachments',
  'flows-relative-dates',
  'flows-folders',
  // KFS
  'kill-firestore-onboarding-settings-dual-write',
  'kill-firestore-onboarding-settings-read-v2',
  'kill-firestore-conference-settings-dual-write',
  'kill-firestore-conference-settings-read',
  'team-trial-for-individual',
  'show-prices-on-plan-selection-page',
  'b2b-on-mobile',
  'workspace-folders',
  'pinned-views',
  'duplicate-custom-field',

  'optimize-gantt',
] as const

export type { AmplitudeExperimentName }

export type AmplitudeExperiments = Record<
  AmplitudeExperimentName,
  string | undefined
>
