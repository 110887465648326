import { classed } from '@motion/theme'
import { type Group } from '@motion/ui/base'
import { isStageCanceled, isStageCompleted } from '@motion/ui-logic/pm/project'
import { createLookupByKey } from '@motion/utils/object'
import { useHasTreatment } from '@motion/web-common/flags'
import { type StageSchema } from '@motion/zod/client'

import {
  type ExpandedState,
  type ExpandedStateList,
  getCoreRowModel,
  getExpandedRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'
import { type Range, useVirtualizer } from '@tanstack/react-virtual'
import { useProject } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useSidebarTaskContext } from './context'
import { SidebarTasksGroupHeader } from './header'
import { InlineAddTaskButton } from './project-panel/components/inline-add-tasks-button'
import { StageTimeline } from './stage-timeline'
import { TaskPanelTaskLine } from './task-line'
import {
  HEADER_GROUP_HEIGHT,
  INLINE_ADD_TASK_BUTTON_HEIGHT,
  INLINE_ADD_TASK_HEIGHT,
  SPACER_HEIGHT,
  TASK_LINE_HEIGHT,
} from './utils'

import { type SidebarTasksGroup } from '../../hooks'
import { type SortBy } from '../../utils'

type Props<SK extends keyof typeof SortBy> = {
  groupedTasks: SidebarTasksGroup[]
  sort: SK
  projectId: string
}

function getIsGroupDefaultExpanded<SK extends keyof typeof SortBy>(
  projectStages: StageSchema[] | undefined,
  sort: SK
): ((value: SidebarTasksGroup) => boolean) | undefined {
  return (item) => {
    if (sort === 'STAGES') {
      const thisStage = projectStages?.find(
        (stage) => stage.stageDefinitionId === item.key
      )

      if (thisStage == null) {
        return true
      }

      const shouldBeCollapsed =
        isStageCompleted(thisStage) || isStageCanceled(thisStage)

      return !shouldBeCollapsed
    }

    return true
  }
}

export function SidebarTasks<SK extends keyof typeof SortBy>({
  groupedTasks,
  projectId,
  sort,
}: Props<SK>) {
  const project = useProject(projectId)
  const stages = project?.stages

  const [groupExpanded, setGroupExpanded] = useState<ExpandedState>(
    createLookupByKey(
      groupedTasks,
      'key',
      getIsGroupDefaultExpanded(stages, sort)
    )
  )
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')
  const { enableInlineAdd } = useSidebarTaskContext()

  useEffect(() => {
    setGroupExpanded(
      createLookupByKey(
        groupedTasks,
        'key',
        getIsGroupDefaultExpanded(stages, sort)
      )
    )
    // We want to reset the expanded state if the project or sort changes only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id, sort])

  const [addTaskExpanded, setAddTaskExpanded] = useState<ExpandedStateList>(
    createLookupByKey(groupedTasks, 'key', () => false)
  )

  const table = useReactTable({
    data: groupedTasks,
    columns: [
      {
        accessorFn: (row) => row,
        header: 'Default',
      },
    ],
    state: {
      expanded: groupExpanded,
    },
    filterFromLeafRows: true,
    getRowId: (row) => row.key,
    onExpandedChange: setGroupExpanded,
    getCoreRowModel: getCoreRowModel(),
    // @ts-expect-error - fine
    getSubRows: (row) => {
      return row.items
    },
    getExpandedRowModel: getExpandedRowModel(),
  })

  const { rows } = table.getRowModel()

  const usingGroups = !['DEFAULT_NONE', 'BLOCKERS'].includes(sort)
  const rowsToUse = usingGroups ? rows : (rows[0]?.subRows ?? [])

  const tableContainerRef = useRef<HTMLTableElement>(null)

  const rangeExtractor = useCallback((range: Range) => {
    // Full page of rows
    return Array.from(
      { length: Math.min(range.endIndex + range.overscan, range.count) },
      (_, i) => i
    )
  }, [])

  function getRowHeight(row: Row<Group<NormalTaskWithRelations>>): number {
    const isGroupHeader = row.getParentRow() == null

    return isGroupHeader
      ? HEADER_GROUP_HEIGHT +
          SPACER_HEIGHT +
          (addTaskExpanded[row.id] ? INLINE_ADD_TASK_HEIGHT : 0)
      : TASK_LINE_HEIGHT
  }

  const rowVirtualizer = useVirtualizer({
    count: rowsToUse.length,
    estimateSize: (index) => {
      const row = rowsToUse[index]
      return getRowHeight(row)
    },
    getScrollElement: () => tableContainerRef.current,
    getItemKey: (index) => rowsToUse[index].id,
    measureElement(element, entry, instance) {
      const index = instance.indexFromElement(element)
      const row = rowsToUse[index]
      const baseHeight = getRowHeight(row)
      if (isTaskRow(row) && virtualItems) {
        const { showAddTaskButton } = shouldShowAddTaskButton({
          index,
          virtualItems,
          rowsToUse,
          row,
          sort,
          hasBetterResizeStages,
          enableInlineAdd,
        })
        return (
          baseHeight + (showAddTaskButton ? INLINE_ADD_TASK_BUTTON_HEIGHT : 0)
        )
      }

      return baseHeight
    },
    rangeExtractor,
    overscan: 10,
  })

  if (rowsToUse.length === 0) {
    return null
  }

  const virtualItems = rowVirtualizer.getVirtualItems()
  const range = {
    startIndex: rowVirtualizer.range?.startIndex ?? 0,
    endIndex: rowVirtualizer.range?.endIndex ?? 0,
    overscan: rowVirtualizer.options.overscan,
    count: rowVirtualizer.options.count,
  }

  const showStageTimeline =
    sort === 'STAGES' && stages != null && stages.length > 0 && project != null

  return (
    <>
      {showStageTimeline && <StageTimeline project={project} stages={stages} />}
      <TableContainer
        ref={tableContainerRef}
        className='scrollbar-gutter-stable'
      >
        <div
          className='flex flex-col whitespace-nowrap text-ellipsis'
          style={{
            height: rowVirtualizer.getTotalSize(),
          }}
        >
          {virtualItems.map((virtual, index) => {
            const row = rowsToUse[virtual.index]

            const rowHeight = getRowHeight(row)

            const visible =
              !isTaskRow(row) ||
              virtual.index >= range.startIndex - range.overscan

            if (!isTaskRow(row)) {
              return (
                <React.Fragment key={row.id}>
                  {virtual.index !== 0 && <div className='h-4' />}

                  <SidebarTasksGroupHeader
                    key={row.id}
                    index={virtual.index}
                    measureElement={rowVirtualizer.measureElement}
                    sort={sort}
                    groupId={row.id}
                    row={row}
                    isAddTaskExpanded={addTaskExpanded[row.id]}
                    toggleAddTaskExpanded={() =>
                      setAddTaskExpanded((expanded) => ({
                        ...expanded,
                        [row.id]: !expanded[row.id],
                      }))
                    }
                  />
                </React.Fragment>
              )
            }

            const { showAddTaskButton, parentRow } = shouldShowAddTaskButton({
              index,
              virtualItems,
              rowsToUse,
              row,
              sort,
              hasBetterResizeStages,
              enableInlineAdd,
            })

            return (
              <RowShell
                key={row.id}
                ref={rowVirtualizer.measureElement}
                data-index={virtual.index}
                style={{
                  height: showAddTaskButton
                    ? rowHeight + INLINE_ADD_TASK_BUTTON_HEIGHT
                    : rowHeight,
                }}
              >
                {visible && (
                  <TaskPanelTaskLine
                    className={
                      sort === 'STAGES' && hasBetterResizeStages
                        ? 'pl-6'
                        : undefined
                    }
                    task={row.original}
                  />
                )}
                {showAddTaskButton && parentRow && (
                  <InlineAddTaskButton
                    onClick={() => {
                      setAddTaskExpanded((expanded) => ({
                        ...expanded,
                        [parentRow.id]: !expanded[parentRow.id],
                      }))
                    }}
                  />
                )}
              </RowShell>
            )
          })}
        </div>
      </TableContainer>
    </>
  )
}

type ShouldShowAddTaskButtonArgs = {
  index: number
  virtualItems: any[]
  rowsToUse: Row<any>[]
  row: Row<any>
  sort: keyof typeof SortBy
  hasBetterResizeStages: boolean
  enableInlineAdd: boolean
}
function shouldShowAddTaskButton({
  index,
  virtualItems,
  rowsToUse,
  row,
  sort,
  hasBetterResizeStages,
  enableInlineAdd,
}: ShouldShowAddTaskButtonArgs): {
  showAddTaskButton: boolean
  parentRow: Row<any> | undefined
} {
  if (!hasBetterResizeStages || sort !== 'STAGES' || !enableInlineAdd) {
    return {
      showAddTaskButton: false,
      parentRow: undefined,
    }
  }

  const nextVirtual = virtualItems[index + 1]
  const nextRow = nextVirtual ? rowsToUse[nextVirtual.index] : null
  const parentRow = row.getParentRow()

  return {
    showAddTaskButton: Boolean(
      parentRow &&
        ((nextRow && !isTaskRow(nextRow)) || index === virtualItems.length - 1)
    ),
    parentRow,
  }
}

const isTaskRow = (row: Row<any>): row is Row<NormalTaskWithRelations> =>
  row.getParentRow() != null

const TableContainer = classed(
  'div',
  'h-full overflow-y-auto overflow-x-hidden'
)

export const RowShell = classed('div', 'flex truncate flex-col')
