import { useMemo } from 'react'

import { useBulkOpsFields } from '../contexts'

export function useBulkOpsCanSave() {
  const ctx = useBulkOpsFields()

  return useMemo(
    () =>
      Object.values(ctx.fields).some((value) =>
        Array.isArray(value) ? value.length > 0 : value !== undefined
      ),
    [ctx]
  )
}
