import {
  ArchiveSolid,
  CreateProjectSolid,
  DuplicateOutline,
  DuplicateSolid,
  ExternalLinkSolid,
  LinkSolid,
  PlayTriangleSolid,
  ProjectCubeSolid,
  PuzzleSolid,
  StopSolid,
  TrashSolid,
} from '@motion/icons'
import { useClosure } from '@motion/react-core/hooks'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/rpc-types'
import { isAutoScheduledStatus } from '@motion/shared/common'
import { type ActionItem, type ActionSection } from '@motion/ui/base'
import {
  canBeArchived,
  canCreateProjectFromTask,
  canDuplicateTask,
  canSaveAsTemplate,
  canStartTask,
  canStopTask,
} from '@motion/ui-logic/pm/task'
import { useModalApi } from '@motion/web-common/modals'

import { useTemplateTaskModalUrl } from '~/areas/project-management/modals/template-task-modal/hooks'
import { useCopyTaskLinkToClipboard, useWorkspaceFns } from '~/global/hooks'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { wrapWithAnalytics } from './utils'

import {
  useArchiveTask,
  useCreateProjectFromTask,
  useDeleteTask,
  useDuplicateTask,
  useIsTaskStarted,
  useStartTask,
  useStopTask,
} from '../../hooks'

export type SectionProps = {
  task: TaskSchema | RecurringTaskSchema
  parentChunkOrDirectTask: TaskSchema | RecurringTaskSchema
}

export type OpenLinkSectionOptions = {
  copyShortcutLabel?: string
  onNavigateAway?: () => Promise<void> | void
  hideOpenTask?: boolean
}

export function useOpenLinkSection({
  copyShortcutLabel,
  hideOpenTask = false,
  onNavigateAway,
}: OpenLinkSectionOptions) {
  const navigateToModal = useNavigateByGlobalModalId()
  const copyTaskLinkToClipboard = useCopyTaskLinkToClipboard()

  return useCallback(
    ({ parentChunkOrDirectTask }: SectionProps): ActionSection => {
      return {
        items: [
          {
            prefix: <LinkSolid />,
            content: 'Copy link',
            shortcut: copyShortcutLabel,
            onAction: wrapWithAnalytics('copy_link')(() => {
              copyTaskLinkToClipboard({
                workspaceId: parentChunkOrDirectTask.workspaceId,
                taskName: parentChunkOrDirectTask.name,
                taskId: parentChunkOrDirectTask.id,
              })
            }),
          },
          !hideOpenTask && {
            prefix: <ExternalLinkSolid />,
            content: 'Open task',
            onAction: wrapWithAnalytics('open')(() => {
              navigateToModal('task', { task: parentChunkOrDirectTask.id })
            }),
          },
          parentChunkOrDirectTask.type !== 'RECURRING_TASK' &&
            parentChunkOrDirectTask.projectId != null && {
              prefix: <ProjectCubeSolid />,
              content: 'View project',
              onAction: wrapWithAnalytics('view_project')(async () => {
                if (parentChunkOrDirectTask.projectId == null) return

                await onNavigateAway?.()
                navigateToModal('project', {
                  project: parentChunkOrDirectTask.projectId,
                })
              }),
            },
        ],
      }
    },
    [
      copyShortcutLabel,
      copyTaskLinkToClipboard,
      hideOpenTask,
      navigateToModal,
      onNavigateAway,
    ]
  )
}

export type DuplicateSectionOptions = {
  duplicateShortcutLabel?: string
  onNavigateAway?: () => Promise<void> | void
}

export function useDuplicateSection({
  duplicateShortcutLabel,
  onNavigateAway,
}: DuplicateSectionOptions) {
  const modalApi = useModalApi()
  const buildTemplateTaskUrl = useTemplateTaskModalUrl()
  const createProjectFromTask = useCreateProjectFromTask({ onNavigateAway })
  const navigate = useNavigate()
  const duplicateTask = useDuplicateTask()

  return useCallback(
    ({ parentChunkOrDirectTask }: SectionProps): ActionSection => {
      const canDuplicate = canDuplicateTask(parentChunkOrDirectTask)

      return {
        items: [
          canDuplicate && {
            content: 'Duplicate task',
            prefix: <DuplicateOutline />,
            shortcut: duplicateShortcutLabel,
            onAction: wrapWithAnalytics('duplicate')(() =>
              duplicateTask(parentChunkOrDirectTask)
            ),
          },
          canDuplicate && {
            prefix: <DuplicateSolid />,
            content: 'Bulk duplicate task',
            onAction: wrapWithAnalytics('bulk_duplicate')(() => {
              modalApi.prompt('task-bulk-duplicate', {
                task: parentChunkOrDirectTask,
              })
            }),
          },
          canCreateProjectFromTask(parentChunkOrDirectTask) && {
            prefix: <CreateProjectSolid />,
            content: 'Create project from task',
            onAction: wrapWithAnalytics('create_project_from_task')(async () =>
              createProjectFromTask(parentChunkOrDirectTask)
            ),
          },
          canSaveAsTemplate(parentChunkOrDirectTask) && {
            prefix: <PuzzleSolid />,
            content: 'Save as template',
            onAction: wrapWithAnalytics('save_as_template')(async () => {
              navigate(
                buildTemplateTaskUrl({ task: parentChunkOrDirectTask.id })
              )
            }),
          },
        ],
      }
    },
    [
      buildTemplateTaskUrl,
      createProjectFromTask,
      duplicateShortcutLabel,
      duplicateTask,
      modalApi,
      navigate,
    ]
  )
}

export type ArchiveDeleteSectionOptions = {
  onDeleteConfirmed?: () => void
}

export function useArchiveDeleteSection(
  options: ArchiveDeleteSectionOptions = {}
) {
  const deleteTask = useDeleteTask()
  const archiveTask = useArchiveTask()

  const onDeleteConfirmed = useClosure(options.onDeleteConfirmed ?? (() => {}))

  return useCallback(
    ({ task }: SectionProps): ActionSection => {
      const showArchiveOption = canBeArchived(task) || task.isAutoScheduled
      const archiveTooltip = task.isAutoScheduled
        ? 'Archiving auto-scheduled tasks is not supported.'
        : undefined

      return {
        items: [
          showArchiveOption && {
            prefix: <ArchiveSolid />,
            content: 'Archive',
            onAction: wrapWithAnalytics('archive')(() => archiveTask(task)),
            disabled: showArchiveOption && archiveTooltip != null,
            tooltip: archiveTooltip,
          },
          {
            prefix: <TrashSolid />,
            content: 'Delete task',
            destructive: true,
            onAction: wrapWithAnalytics('delete')(() =>
              deleteTask(task.id, { onConfirmation: onDeleteConfirmed })
            ),
          },
        ],
      }
    },
    [archiveTask, deleteTask, onDeleteConfirmed]
  )
}

export function useStartStopActionItems(taskId: string) {
  const startTask = useStartTask()
  const stopTask = useStopTask()

  const { isStarted, startedTask } = useIsTaskStarted(taskId)
  const { getStatusById } = useWorkspaceFns()

  return useCallback(
    ({ task, parentChunkOrDirectTask }: SectionProps): ActionItem[] => {
      const startableDuration = !!task.duration
      const isAutoscheduleable = isAutoScheduledStatus(
        getStatusById(task.statusId)
      )

      return [
        canStartTask(task) &&
          !isStarted && {
            content: 'Start task now',
            prefix: <PlayTriangleSolid />,
            onAction: wrapWithAnalytics('start')(async () => {
              // If this task has chunks, prefer to start an incomplete chunk.
              await startTask(task.id, {
                // Because this action is accessible from the task modal, we should suggest
                // the remaining task duration (if there is no remaining duration, suggest a reasonable default)
                suggestedDuration:
                  (task.type === 'NORMAL' ||
                    task.type === 'RECURRING_INSTANCE') &&
                  task.duration != null &&
                  task.completedDuration != null &&
                  task.completedDuration < task.duration
                    ? task.duration - task.completedDuration
                    : 30,
                source: 'taskActionDropdown',
              })
            }),
            disabled: !startableDuration || !isAutoscheduleable,
            tooltip: !startableDuration
              ? "This task can't be started because of its duration"
              : !isAutoscheduleable
                ? "This task can't be started because of its status"
                : null,
          },
        canStopTask(task) &&
          startedTask != null && {
            content: 'Stop task',
            prefix: <StopSolid />,
            onAction: wrapWithAnalytics('stop')(async () => {
              await stopTask(startedTask.id, { source: 'taskActionDropdown' })
            }),
          },
      ]
    },
    [getStatusById, isStarted, startTask, startedTask, stopTask]
  )
}
