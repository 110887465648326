import { classed } from '@motion/theme'
import { ProgressCircle, type ProgressCircleProps } from '@motion/ui/base'
import { type StageVariant } from '@motion/ui-logic/pm/project'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { StageBadge } from '~/global/components/badges'
import { type MouseEventHandler } from 'react'

export interface StageProgressCircleProps
  extends Omit<
    ProgressCircleProps,
    'children' | 'size' | 'sentiment' | 'value' | 'onContextMenu'
  > {
  stageDefinition: StageDefinitionSchema
  percentageComplete: number
  onContextMenu?: MouseEventHandler<SVGSVGElement>
  variant?: StageVariant
}

export const StageProgressCircle: React.FC<StageProgressCircleProps> = ({
  stageDefinition,
  percentageComplete,
  onContextMenu,
  variant,
  ...progressCircleProps
}) => {
  return (
    <>
      <ProgressCircle
        labelHidden
        value={percentageComplete}
        {...progressCircleProps}
      />
      <StageBadgeWrapper>
        <StageBadge
          value={stageDefinition}
          hideTooltip
          onContextMenu={onContextMenu}
          variant={variant}
        />
      </StageBadgeWrapper>
    </>
  )
}

const StageBadgeWrapper = classed(
  'div',
  'absolute inset-0 flex items-center justify-center',
  'z-1'
)
