import { useCallback } from 'react'

import { useScreenValues } from './use-screen-values'

import { usePlannerProps, useScrollPosition } from '../context'
import { pixelToDate } from '../utils'

// Return date for pixel considering the scrollPosition and offSet
export const useGetDateFromPixel = () => {
  const [plannerProps] = usePlannerProps()
  const [scrollPosition] = useScrollPosition()
  const { positionOffset } = useScreenValues()

  return useCallback(
    (xPosition: number) => {
      return pixelToDate(
        xPosition - positionOffset - scrollPosition,
        plannerProps.dayPx
      )
    },
    [plannerProps.dayPx, positionOffset, scrollPosition]
  )
}
