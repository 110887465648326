import { API, createUseMutation, createUseQuery } from '@motion/rpc'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

export const useTemplatesByWorkspaceId = createUseQuery(
  API.templates.getTemplatesByWorkspaceId
)

const useCreateTemplateTaskMutation = createUseMutation(
  API.templates.createTemplateTask
)
export const useCreateTemplateTask = () =>
  useCreateTemplateTaskMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_TEMPLATE_TASK')
    },
  })

const useCreateTemplateProjectMutation = createUseMutation(
  API.templates.createTemplateProject
)
export const useCreateTemplateProject = () =>
  useCreateTemplateProjectMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_TEMPLATE_PROJECT')
    },
  })

const useCreateTemplateProjectTaskMutation = createUseMutation(
  API.templates.createTemplateProjectTask
)
export const useCreateTemplateProjectTask = () =>
  useCreateTemplateProjectTaskMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_TEMPLATE_PROJECT')
    },
  })

const useUpdateTemplateProjectMutation = createUseMutation(
  API.templates.updateTemplateProject
)
export const useUpdateTemplateProject = () =>
  useUpdateTemplateProjectMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_TEMPLATE_PROJECT')
    },
  })

const useUpdateTemplateTaskMutation = createUseMutation(
  API.templates.updateTemplateTask
)
export const useUpdateTemplateTask = () =>
  useUpdateTemplateTaskMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_TEMPLATE_TASK')
    },
  })

const useUpdateTemplateProjectTaskMutation = createUseMutation(
  API.templates.updateTemplateProjectTask
)
export const useUpdateTemplateProjectTask = () =>
  useUpdateTemplateProjectTaskMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_TEMPLATE_PROJECT_TASK')
    },
  })

const useDeleteProjectTemplateMutation = createUseMutation(
  API.templates.deleteTemplateProject
)
export const useDeleteProjectTemplate = () =>
  useDeleteProjectTemplateMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_TEMPLATE_PROJECT')
    },
  })

const useDeleteTaskTemplateMutation = createUseMutation(
  API.templates.deleteTemplateTask
)
export const useDeleteTaskTemplate = () =>
  useDeleteTaskTemplateMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_TEMPLATE_TASK')
    },
  })

const useDeleteProjectTaskTemplateMutation = createUseMutation(
  API.templates.deleteTemplateProjectTask
)
export const useDeleteProjectTaskTemplate = () =>
  useDeleteProjectTaskTemplateMutation({
    onMutate: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_TEMPLATE_PROJECT_TASK')
    },
  })
