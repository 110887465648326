import { ClockSolid, ExclamationCircleSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import {
  PAST_DUE_TASK_MESSAGE,
  SCHEDULE_OVERRIDDEN_AND_PAST_DUE_TASK_MESSAGE,
  SCHEDULE_OVERRIDDEN_TASK_MESSAGE,
} from '~/areas/tasks/utils'
import { twMerge } from 'tailwind-merge'

type TaskDeadlineWarningProps = {
  isPastDue: boolean
  isUnfit: boolean
  isScheduleOverridden: boolean
}

export const TaskDeadlineWarning = ({
  isPastDue,
  isUnfit,
  isScheduleOverridden,
}: TaskDeadlineWarningProps) => {
  if (!isPastDue && !isUnfit && !isScheduleOverridden) {
    return null
  }

  return (
    <InnerTaskDeadlineWarning
      isPastDue={isPastDue}
      isUnfit={isUnfit}
      isScheduleOverridden={isScheduleOverridden}
    />
  )
}

function InnerTaskDeadlineWarning({
  isPastDue,
  isUnfit,
  isScheduleOverridden,
}: TaskDeadlineWarningProps) {
  const tooltipContent = isUnfit ? (
    'Could not fit'
  ) : (
    <div className='flex flex-col gap-2 text-left'>
      {isPastDue && isScheduleOverridden && (
        <p>{SCHEDULE_OVERRIDDEN_AND_PAST_DUE_TASK_MESSAGE}</p>
      )}
      {isPastDue && !isScheduleOverridden && <p>{PAST_DUE_TASK_MESSAGE}</p>}
      {!isPastDue && isScheduleOverridden && (
        <p>{SCHEDULE_OVERRIDDEN_TASK_MESSAGE}</p>
      )}
    </div>
  )

  return (
    <Tooltip renderContent={() => tooltipContent}>
      {!isUnfit && isScheduleOverridden ? (
        <ClockSolid
          className={twMerge(
            'h-3 w-3',
            isPastDue
              ? 'text-semantic-error-bg-strong-default'
              : 'text-semantic-neutral-icon-default'
          )}
        />
      ) : (
        <ExclamationCircleSolid className='h-3 w-3 text-semantic-error-bg-strong-default' />
      )}
    </Tooltip>
  )
}
