import { isSchedulingTask } from '@motion/ui-logic/pm/task'

import { StatusField } from '~/areas/task-project/fields'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const ControlledStatusField = () => {
  const { form } = useTaskDefinitionForm()
  const {
    initialTaskData: { task: initialTask },
  } = useTaskDefinitionModalState()

  const { control, watch } = form

  const { field } = useController({
    name: 'statusId',
    control,
  })

  const workspaceId = watch('workspaceId')

  const isSchedulingTaskType = useMemo(
    () => isSchedulingTask(initialTask),
    [initialTask]
  )

  return (
    <StatusField
      value={field.value}
      onChange={field.onChange}
      workspaceId={workspaceId}
      shouldExcludeResolved
      shouldExcludeCanceled
      isScheduledTask={isSchedulingTaskType}
    />
  )
}
