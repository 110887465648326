import { classed } from '@motion/theme'

import React, { Children, type ReactElement } from 'react'

import { useAlertContext } from './AlertsContext'

type AlertContainerProps = {
  children: ReactElement
  id: string
  priority: number
}

export const AlertContainer = ({
  children,
  id,
  priority,
}: AlertContainerProps) => {
  const { open, setOpen } = useAlertContext({
    id,
    priority,
  })

  return (
    <AlertPositionContainer>
      {Children.map(children, (child) => {
        return React.cloneElement(child, {
          open,
          setOpen,
        })
      })}
    </AlertPositionContainer>
  )
}

export const AlertPositionContainer = classed(
  'div',
  'fixed bottom-6 right-6 flex'
)
