import {
  ExclamationCircleSolid,
  PencilSolid,
  PriorityASAPSolid,
  StageSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { type ActionItem } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { HardDeadlineTooltipIcon } from '~/areas/project-management/components'
import {
  useDoTaskASAP,
  useResolveTask,
  useTaskStageUpdater,
  useTaskUpdater,
} from '~/areas/tasks/hooks'
import { ConnectedStageLabel } from '~/global/components/labels'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useMemo } from 'react'

import { type ActionOptions } from './types'
import { useNextTaskStage } from './use-next-task-stage'

export const useSingleTaskActions = (
  task: TaskSchema,
  actionList: ActionOptions[] = [],
  onClose?: () => void
) => {
  const { completeTask, cancelTask } = useResolveTask()
  const navigateToModal = useNavigateByGlobalModalId()
  const updateTask = useTaskUpdater()
  const doTaskAsap = useDoTaskASAP()
  const stageUpdater = useTaskStageUpdater()
  const stageDefinition = useNextTaskStage(task)

  return useMemo<ActionItem[]>(() => {
    return actionList
      .map((action) => {
        switch (action) {
          case 'moveToNextStage': {
            if (stageDefinition) {
              return {
                content: templateStr(
                  'Move task to next stage: {{stageLabel}}',
                  {
                    stageLabel: <ConnectedStageLabel id={stageDefinition.id} />,
                  }
                ),
                prefix: <StageSolid />,
                onAction: () => {
                  stageUpdater(task.id, stageDefinition)
                  onClose?.()
                },
              }
            }

            return undefined
          }
          case 'doASAP':
            return {
              // TODO: Fix this color issue
              prefix: (
                <PriorityASAPSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Do ASAP',
              onAction: () => {
                doTaskAsap(task.id)
                onClose?.()
              },
            }
          case 'hardDeadline':
            return {
              // TODO: Fix this color issue
              prefix: (
                <ExclamationCircleSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Turn on hard deadline',
              suffix: <HardDeadlineTooltipIcon />,
              onAction: () => {
                updateTask(task, { deadlineType: 'HARD' })
                onClose?.()
              },
            }
          case 'complete':
            return {
              content: 'Complete task',
              prefix: <StatusCompletedSolid />,
              onAction: () => {
                completeTask(task.id)
                onClose?.()
              },
            }
          case 'cancel':
            return {
              content: 'Cancel task',
              prefix: (
                <StatusCanceledSolid className='text-dropdown-item-icon-default' />
              ),
              onAction: () => {
                cancelTask(task.id)
                onClose?.()
              },
            }
          case 'edit':
            return {
              content: 'Edit task',
              prefix: <PencilSolid />,
              onAction: () => {
                navigateToModal('task', { task: task.id })
                onClose?.()
              },
            }
          default:
            return null
        }
      })
      .filter(Boolean) as ActionItem[]
  }, [
    actionList,
    cancelTask,
    completeTask,
    doTaskAsap,
    navigateToModal,
    onClose,
    stageDefinition,
    stageUpdater,
    task,
    updateTask,
  ])
}
