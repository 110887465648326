import {
  type ETADateOptions,
  type ETADateReturn,
  getEtaDateOptions,
} from '@motion/ui-logic'
import type { ProjectSchema } from '@motion/zod/client'

import { type DateTime } from 'luxon'
import { useMemo, useState } from 'react'

type UseEtaOptionsProps = {
  project: Pick<ProjectSchema, 'estimatedCompletionTime'>
}

type UseEtaOptionsReturn = {
  dateOptions: ETADateReturn | null
  selectedOption: ETADateOptions
  selectedDate: DateTime | null
  setSelectedOption: (option: ETADateOptions) => void
}

export function useEtaOptions({
  project,
}: UseEtaOptionsProps): UseEtaOptionsReturn {
  const [selectedOption, setSelectedOption] =
    useState<ETADateOptions>('projectedDate')

  const projectedDate = project.estimatedCompletionTime
    ? project.estimatedCompletionTime
    : null

  const dateOptions = useMemo(() => {
    if (!projectedDate) return null

    return getEtaDateOptions(projectedDate)
  }, [projectedDate])

  const selectedDate = dateOptions?.[selectedOption] ?? null

  return { dateOptions, selectedOption, selectedDate, setSelectedOption }
}
