const FC_DIVIDER = '|'

type FullCalendarEventType = 'task' | 'event' | 'new_event' | 'availability'

type FullCalendarEventId = {
  type: FullCalendarEventType
  id?: string
  modifier?: string
}

export const serializeFullCalendarEventId = (
  id: FullCalendarEventId
): string => {
  return [id.type, id.id, id.modifier].filter(Boolean).join(FC_DIVIDER)
}

export const parseFullCalendarEventId = (id: string): FullCalendarEventId => {
  if (id === '') {
    return {
      type: 'new_event',
    }
  }

  const parts = id.split(FC_DIVIDER)
  return {
    id: parts[1],
    modifier: parts[2],
    type: parts[0],
  } as FullCalendarEventId
}
