import { ButtonTabs } from '@motion/ui/base'

import { useMemo } from 'react'

import { usePageData } from '../routes'

type PageType = 'list' | 'kanban' | 'gantt' | 'dashboard'

export type PageSelectorProps = {
  value: PageType
  onChange(value: PageType): void
}

const PAGE_OPTIONS: {
  value: PageType
  label: string
}[] = [
  {
    value: 'list',
    label: 'List',
  },
  {
    value: 'kanban',
    label: 'Kanban',
  },
  {
    value: 'gantt',
    label: 'Gantt',
  },
]

export const PageSelector = (props: PageSelectorProps) => {
  const data = usePageData()

  const page_options = useMemo(() => {
    const options = !data.lock.projectId
      ? [...PAGE_OPTIONS]
      : PAGE_OPTIONS.filter((option) => option.value !== 'gantt')

    return options
  }, [data.lock.projectId])

  return (
    <ButtonTabs
      size='small'
      activeValue={props.value}
      items={page_options.map((opt) => ({
        value: opt.value,
        content: opt.label,
        onAction: () => props.onChange(opt.value),
      }))}
    />
  )
}
