import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

export type SidebarLabelProps = {
  icon?: ReactNode
  children: ReactNode
}

export function SidebarLabel({ icon, children }: SidebarLabelProps) {
  return (
    <LabelContainer>
      {icon}

      <div className='text-sm text-semantic-neutral-text-subtle'>
        {children}
      </div>
    </LabelContainer>
  )
}

const LabelContainer = classed('div', 'flex flex-1 items-center gap-2')
