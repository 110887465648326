import { formatDurationTime, formatDurationToShort } from '@motion/ui-logic'
import type { ScheduleSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

export type CurrentTaskProgressData = {
  timeLeftLabel: string
  totalTimeLabel: string
  progress: number
}

export function useCurrentTaskProgressData(
  schedule: ScheduleSchema
): CurrentTaskProgressData {
  const taskStartTime = DateTime.fromISO(schedule.start)
  const taskEndTime = DateTime.fromISO(schedule.end)

  const timeLeft = taskEndTime?.diffNow()
  const totalTime = taskEndTime?.diff(taskStartTime)

  const timeLeftLabel = formatDurationTime(timeLeft?.as('minutes'))
  const totalTimeLabel = formatDurationToShort(totalTime)

  const totalTimeInSeconds = totalTime.as('seconds')
  const secondsCompleted = totalTime.as('seconds') - timeLeft.as('seconds')
  const progress = Math.round((secondsCompleted / totalTimeInSeconds) * 100)

  return { timeLeftLabel, totalTimeLabel, progress }
}
