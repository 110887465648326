import { RechartsStackedBarChart } from '@motion/web-charts/bar'

import { useChartQuery } from '~/areas/charts/rpc'
import { useLookup } from '~/global/cache'
import { UserBadge } from '~/global/components/badges'
import { UserLabel } from '~/global/components/labels/user'
import { useCallback } from 'react'

import { colorOfScheduledStatus, labelOfScheduledStatus } from './utils'

import { GridTile } from '../components/grid-tile'
import { type GridTileLayoutProps } from '../components/types'

type TasksByAssigneeAndStatusChartProps = GridTileLayoutProps & {}

export const TasksByAssigneeAndStatusChart = (
  props: TasksByAssigneeAndStatusChartProps
) => {
  const lookup = useLookup()

  const response = useChartQuery({
    source: 'tasks',
    aggregate: {
      type: 'count',
    },
    filters: [
      {
        completed: 'exclude',
        archived: 'exclude',
        type: ['NORMAL'],
      },
    ],
    groupBy: [
      {
        field: 'assigneeUserId',
      },
      {
        field: 'scheduledStatus',
      },
    ],
  })

  const getColor = useCallback((group: string, key: string, index: number) => {
    if (group === 'scheduledStatus') {
      return colorOfScheduledStatus(key as any)
    }
  }, [])

  const getLabel = useCallback(
    (group: string, key: string, index: number) => {
      if (group === 'scheduledStatus') {
        return labelOfScheduledStatus(key as any)
      }
      if (group === 'assigneeUserId') {
        const user = lookup('users', key)

        return {
          legend: user.name,
          tooltip: <UserLabel value={user} />,
          axis: (
            <UserBadge
              key={user?.id ?? key}
              value={user}
              size='normal'
              proxyProfileUri
            />
          ),
        }
      }
    },
    [lookup]
  )

  if (!response.data?.data.length) return null
  return (
    <GridTile title='Tasks by assignee' {...props}>
      <RechartsStackedBarChart
        data={response.data.data}
        categoryKey='assigneeUserId'
        groupKey='scheduledStatus'
        colorAccessor={getColor}
        labelAccessor={getLabel}
      />
    </GridTile>
  )
}
