import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useModalListOrder } from '~/global/contexts'
import { useEffect } from 'react'

interface UseModalListForNavigationParams {
  tasks?: TaskSchema['id'][]
  projects?: ProjectSchema['id'][]
}

export function useModalListForNavigation({
  tasks,
  projects,
}: UseModalListForNavigationParams) {
  const {
    tasks: { setListIds: setTaskListIds },
    projects: { setListIds: setProjectListIds },
  } = useModalListOrder()

  useEffect(() => {
    if (tasks != null) {
      setTaskListIds(tasks)
    }
    if (projects != null) {
      setProjectListIds(projects)
    }
  }, [setProjectListIds, setTaskListIds, tasks, projects])
}
