import { PencilSolid, TrashSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { ButtonGroup, IconButton } from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

type SettingItem = {
  id: string
  onDelete?: () => void
  onEdit?: () => void
  onEditUrl?: string
  label: React.ReactNode
  description?: React.ReactNode
}

export type SettingTableProps = {
  emptyContainer?: React.ReactNode
  items: SettingItem[]
  title?: React.ReactNode
}
export const SettingTable = ({
  emptyContainer,
  items,
  title,
}: SettingTableProps) => {
  const hasItems = items.length > 0
  const showEmptyContainer = !hasItems && !!emptyContainer

  return (
    <div className={twMerge('flex flex-col gap-3', !hasItems && 'mb-8')}>
      {title && <SettingTitle>{title}</SettingTitle>}
      <SettingContainer>
        {showEmptyContainer && <SettingRow>{emptyContainer}</SettingRow>}
        {items.map((item) => (
          <SettingItem {...item} key={item.id} />
        ))}
      </SettingContainer>
    </div>
  )
}

function SettingItem(item: SettingItem) {
  const renderButtonGroup = !!item.onEdit || !!item.onDelete
  const hasEdit = !!item.onEditUrl || item.onEdit

  return (
    <SettingRow>
      <div className='flex flex-col flex-grow gap-1 text-semantic-neutral-icon-default'>
        <div className='text-semantic-neutral-text-default'>{item.label}</div>
        {item.description && (
          <div className='text-semantic-neutral-text-default text-xs'>
            {item.description}
          </div>
        )}
      </div>
      {renderButtonGroup && (
        <ButtonGroup>
          {hasEdit && (
            <IconButton
              icon={PencilSolid}
              onClick={item.onEdit ?? undefined}
              url={item.onEditUrl ?? undefined}
              sentiment='neutral'
              variant='muted'
            />
          )}
          {item.onDelete && (
            <IconButton
              icon={TrashSolid}
              onClick={item.onDelete}
              sentiment='neutral'
              variant='muted'
            />
          )}
        </ButtonGroup>
      )}
    </SettingRow>
  )
}

export const SettingTitle = classed('div', {
  base: `text-semantic-neutral-text-default font-semibold text-[16px]`,
})

export const SettingContainer = classed('div', {
  base: `border rounded border-b-0 border-semantic-neutral-border-default bg-semantic-neutral-bg-default w-full`,
})

export const SettingRow = classed('div', {
  base: 'flex py-3 border-b border-b-semantic-neutral-border-default px-4 justify-between items-center self-stretch text-field-text-default gap-3',
})

export const UneditableField = classed('span', {
  base: 'px-2 py-1.5 text-base text-field-text-default bg-field-bg-default flex flex-grow rounded border-field-border-default border',
})
