import { getWeekOf } from '@motion/utils/dates'

import { type UseQueryOptions } from '@tanstack/react-query'
import { useCalendarStartDay } from '~/areas/calendar/hooks'
import {
  type GetScheduleAssistantEventsApi,
  useGetScheduleAssistantEvents,
} from '~/global/rpc'
import { useMemo } from 'react'

import { useScheduleAssistantCalendarContext } from './use-calendar-state'

export function useStateGetScheduleAssistantEvents(
  userIds: string[],
  opts?: UseQueryOptions<GetScheduleAssistantEventsApi['data']>
) {
  const startDay = useCalendarStartDay()
  const [calendarState] = useScheduleAssistantCalendarContext()

  const range = useMemo(() => {
    const weekOf = getWeekOf(calendarState.selectedDate, {
      startingDay: startDay,
    })

    return {
      start: weekOf.start.toISO(),
      end: weekOf.end.endOf('day').toISO(),
    }
  }, [calendarState.selectedDate, startDay])

  return useGetScheduleAssistantEvents(
    {
      userIds,
      range,
    },
    opts
  )
}
