import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { isNoneId } from '@motion/shared/identifiers'
import { byProperty, ordered } from '@motion/utils/array'
import { createLookupById } from '@motion/utils/object'
import {
  type ProjectSchema,
  type StageSchema,
  type TaskSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useCachedItem, useLookup } from '~/global/cache'
import { AppWorkspaceContext } from '~/global/contexts'

import { type WorkspaceProjectsOptions } from './types'

export function useAllProjects(
  options: Partial<WorkspaceProjectsOptions> = {}
): ProjectSchema[] {
  const { includeNoProject = false } = options

  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    includeNoProject
      ? state.projects.all
      : filterNoneProject(state.projects.all)
  )
}

export function useAllProjectsById(): Record<
  ProjectSchema['id'],
  ProjectSchema
> {
  const lookup = useLookup()
  return createLookupById(lookup('projects'))
}

export function useProjects(
  workspaceId: WorkspaceSchema['id'] | null | undefined,
  options: Partial<WorkspaceProjectsOptions> = {}
): ProjectSchema[] {
  const { includeNoProject = false } = options

  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.projects.all.filter((p) => {
      const shouldIncludeNoProject = includeNoProject ? true : !isNoneId(p.id)

      return p.workspaceId === workspaceId && shouldIncludeNoProject
    })
  )
}

export function useProject(
  id: ProjectSchema['id'] | null | undefined
): ProjectSchema | null {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (id == null) return null

    const project = state.projects.byId[id] ?? null
    if (project == null) return null

    if (project.projectDefinitionId != null) {
      const projectDef =
        state.projectDefinitions.byId[project.projectDefinitionId]
      if (projectDef != null) {
        return orderProjectStages(
          project,
          projectDef.stages.map((s) => s.id)
        )
      }
    }

    return project
  })
}

export function useProjectByStageId(
  stageId: StageSchema['id']
): ProjectSchema | null {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state) =>
      state.projects.all.find((p) => p.stages.some((s) => s.id === stageId)) ??
      null
  )
}

export function useProjectByTaskId(
  taskId: TaskSchema['id'] | null | undefined
): ProjectSchema | null {
  const task = useCachedItem('tasks', taskId)
  return useProject(task != null ? task.projectId : null)
}

function filterNoneProject(projects: ProjectSchema[]) {
  return projects.filter((p) => !isNoneId(p.id))
}

function orderProjectStages(
  project: ProjectSchema,
  stageDefinitionIds: string[]
): ProjectSchema {
  return {
    ...project,
    stages: project.stages.toSorted(
      byProperty('stageDefinitionId', ordered(stageDefinitionIds))
    ),
  }
}
