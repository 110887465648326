import { isNoneId } from '@motion/shared/identifiers'
import { getPrefixFromMaybeCustomFieldKey } from '@motion/ui-logic'

import { type GroupedNode, type GroupFieldType, type Tree } from './multi-group'

import { organizeProjectsIntoRows } from '../../planner/utils'
import { type DateRow, type TreeListRowValueType } from '../../tree-list/types'
import { type ViewStateGroupByField } from '../../view-state/types'
import { CUSTOM_FIELD_NO_VALUE } from '../custom-fields'

export const isNoneGroup = (group: GroupedNode) => {
  return (
    isNoneId(group.key) ||
    !group.value?.value ||
    isNoneId(group.value?.value?.id ?? '') ||
    group.key === 'undefined' ||
    group.key === CUSTOM_FIELD_NO_VALUE
  )
}

export const isDateGroup = (
  item: (TreeListRowValueType | { type: string; value: any }) &
    ViewStateGroupByField
): item is DateRow & ViewStateGroupByField => {
  return GROUP_BY_DATE_DEFAULT_KEYS.includes(item.type)
}

export const GROUP_BY_DATE_DEFAULT_KEYS = [
  'deadline',
  'createdAt',
  'completedAt',
  'updatedAt',
  'startDate',
  'scheduledDate',
] as const

export const isViewStateGroupByDateKey = (
  key: string
): key is (typeof GROUP_BY_DATE_DEFAULT_KEYS)[number] | `date/${string}` => {
  return (
    GROUP_BY_DATE_DEFAULT_KEYS.includes(key) ||
    getPrefixFromMaybeCustomFieldKey(key) === 'date'
  )
}

const groupTypeLabels: Record<GroupFieldType, string> = {
  select: 'Select',
  multiSelect: 'Multi select',
  date: 'Date',
  number: 'Number',
  text: 'Text',
}

export const getGroupNameFromGroupType = (type: GroupFieldType) => {
  return groupTypeLabels[type]
}

export const noneField = { id: 'none', label: 'None' } as const

export function getOrderedIdsFromTree<T extends GroupedNode = GroupedNode>(
  tree: Tree<T>
) {
  function walk(node: GroupedNode<any>, arr: string[]) {
    if (node.children) {
      node.children.forEach((n) => walk(n, arr))
    } else if (node.value) {
      arr.push(node.value.value.id)
    }

    return arr
  }

  const ids: string[] = []
  tree.values.forEach((node) => walk(node, ids))
  return ids
}

export function getOrderedIdsFromPlannerTree<
  T extends GroupedNode = GroupedNode,
>(node: Tree<T>): string[] {
  const ids: string[] = []

  // If the node has children, recursively get their IDs
  if (node.children && node.children.length) {
    node.children.forEach((childNode) => {
      // Recursively call the getOrderedIdsFromPlannerTree function for each child node
      const childIds = getOrderedIdsFromPlannerTree(childNode)

      ids.push(...childIds)
    })
  }

  // If the node has values, organize the projects into rows and get their IDs
  else if (node.values) {
    // Organize the projects into rows
    const stackedItems = organizeProjectsIntoRows(node.allItems)

    stackedItems.forEach((rowItems) => {
      // Iterate over each project in the row and push its ID to the IDs array
      rowItems.forEach((item) => {
        ids.push(item.id)
      })
    })
  }

  return ids
}
