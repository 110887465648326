import { CalendarEvent } from '@motion/ui/calendar'
import { Sentry } from '@motion/web-base/sentry'

import { type EventContentArg } from '@fullcalendar/core'
import { type FullCalendarMotionCreateNewEvent } from '~/areas/calendar/utils'

type CreateNewCalendarEventProps = {
  eventArg: EventContentArg
}

export function CreateNewCalendarEvent({
  eventArg,
}: CreateNewCalendarEventProps) {
  const { start, end, allDay, title, extendedProps } = eventArg.event

  const { isPast } = eventArg

  const { colorId, variant } =
    extendedProps as FullCalendarMotionCreateNewEvent['extendedProps']

  if (!start) {
    Sentry.captureException("Calendar event doesn't have a start time", {
      extra: {
        event: eventArg,
      },
    })
    return null
  }

  return (
    <CalendarEvent
      colorId={colorId}
      startTime={start}
      endTime={end ?? start}
      allDay={allDay}
      name={title}
      past={isPast}
      variant={variant}
    />
  )
}
