import { StartDateSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { formatMonthDay, getQuickActionsForStartDate } from '@motion/ui-logic'

import { DateTime } from 'luxon'

import { FieldButton } from '../components'
import { BulkDateDropdown } from '../components/dropdowns/bulk-date-dropdown'
import { useBulkOpsField } from '../contexts'
import { useBulkFieldValidation } from '../hooks'

export function StartDateField() {
  const [startDate, setStartDate] = useBulkOpsField('startDate')
  const [dueDate] = useBulkOpsField('dueDate')
  const [priority] = useBulkOpsField('priorityLevel')

  const active = startDate != null

  const isValidDateOption = (date: DateTime) => {
    if (dueDate == null) return true

    return date.toISODate() <= dueDate
  }

  const todayDate = DateTime.now().startOf('day')

  const { isValid: canUpdateStartDate, errMsg } = useBulkFieldValidation([
    () => ({
      isValid: priority !== 'ASAP',
      errMsg: "Can't update the start date of tasks with ASAP priority",
    }),
  ])

  return (
    <Tooltip content={errMsg}>
      <BulkDateDropdown
        disableClearing
        defaultDate={todayDate}
        getQuickActions={getQuickActionsForStartDate}
        selectedDate={startDate}
        onChange={setStartDate}
        isValidDateOption={isValidDateOption}
      >
        <FieldButton
          active={active}
          disabled={!canUpdateStartDate}
          analyticsName='start-date'
          onRemove={() => setStartDate(undefined)}
        >
          <StartDateSolid />
          {active ? formatMonthDay(startDate) : 'Start date'}
        </FieldButton>
      </BulkDateDropdown>
    </Tooltip>
  )
}
