import { classed } from '@motion/theme'

export const AgendaItemWrapper = classed('div', {
  base: 'flex rounded px-1 hover:bg-semantic-neutral-bg-hover items-start',
})

export const EventLabel = classed('span', {
  base: 'text-sm font-normal truncate',
})

export const EventSubtitle = classed(
  'span',
  'text-[10px] text-semantic-neutral-text-subtle leading-3 w-full flex justify-between'
)
