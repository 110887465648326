import {
  type SetupIntentResponse,
  type SubscriptionResponse,
} from '../../state/corrilySlice'
import { BackendApiService } from '../BackendApiService'

export class CorrilyService extends BackendApiService {
  static id = 'CorrilyService' as const

  async createSetupIntent(): Promise<SetupIntentResponse | undefined> {
    try {
      return (await this.request(
        'users/setupIntent',
        'POST'
      )) as SetupIntentResponse
    } catch (error) {
      // Hide the warning - this most likely failed because of idempotency issues
      // Not exactly sure why this is happening but this does not impact the user
    }
  }

  async createSubscription(
    isMonthly: boolean,
    trialLength: number,
    setupIntentId?: string
  ): Promise<SubscriptionResponse> {
    return (await this.request('users/subscription', 'POST', {
      isMonthly,
      setupIntentId,
      trialLength,
    })) as SubscriptionResponse
  }
}

export const handler = new CorrilyService()
