import { DotsVerticalSolid, FilledChevronRightSolid } from '@motion/icons'
import { type EmailAccount } from '@motion/rpc/types'
import { classed } from '@motion/theme'

import { type MouseEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

import { DropdownMenu, Paragraph, SecondaryButton } from '../../Common'

type Props = {
  emailAccount: EmailAccount
  isExpanded: boolean
  isMainEmailAccount: boolean
  onNodeExpanded: (emailAccount: EmailAccount, expanded: boolean) => void
  onRemoveEmailAccount: (emailAccount: EmailAccount) => void
  onStartEmailAuth: () => void
  showOptions?: boolean
}

/**
 * Renders the Email Account Tree node, which will always have child nodes
 * containing the associated calendar items. The node can be expanded and
 * collapsed to show/hide the nested calendars.
 *
 * @param param0
 * @returns
 */
export const EmailAccountTreeNode = ({
  emailAccount,
  isMainEmailAccount,
  isExpanded,
  onRemoveEmailAccount,
  onNodeExpanded,
  showOptions,
}: Props) => {
  return (
    <button
      className={twMerge(
        'group relative flex w-full items-center border-none bg-transparent py-2 group'
      )}
      onClick={() => onNodeExpanded(emailAccount, !isExpanded)}
    >
      <div
        className='cursor-pointer h-full flex items-center px-0.5 text-semantic-neutral-icon-subtle group-hover:text-semantic-neutral-icon-default transition-colors'
        onClick={(e) => {
          preventBubble(e)
          onNodeExpanded(emailAccount, !isExpanded)
        }}
      >
        <ExpandIcon expanded={isExpanded} />
      </div>
      <div className='flex h-full items-center overflow-hidden'>
        <Paragraph className='mr-2 truncate font-medium'>
          {emailAccount.email}
        </Paragraph>
      </div>
      {!isMainEmailAccount && showOptions && (
        <div className='absolute right-1 hidden group-hover:flex'>
          <DropdownMenu
            trigger={<SecondaryButton size='small' icon={DotsVerticalSolid} />}
            options={[
              {
                className: 'text-red-500',
                label: 'Remove this account',
                onSelect: () => onRemoveEmailAccount(emailAccount),
              },
            ]}
          />
        </div>
      )}
    </button>
  )
}

const ExpandIcon = classed(FilledChevronRightSolid, {
  base: 'transition-transform w-4 h-4 mr-1 ',
  variants: {
    expanded: {
      true: 'rotate-90',
    },
  },
})

const preventBubble: MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}
