import { Button, showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { deleteUserData, downloadUserData } from '~/global/rpc'

import { Description } from './description'
import { Section } from './section'

import { clearAuthState } from '../../../../localServices/auth/utils'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

export const PrivacySettings = () => {
  const modalApi = useModalApi()

  const { mutateAsync: deleteData } = deleteUserData()
  const { mutateAsync: downloadData } = downloadUserData()

  function handleDeleteUser() {
    void modalApi.prompt('confirm-delete-item', {
      analytics: {
        name: 'delete-motion-account',
      },
      async onValue(value) {
        if (value === true) {
          recordAnalyticsEvent('GDPR_DELETE_USER')
          await deleteData()
          await clearAuthState()
        }
      },
      titleValue: 'your information',
      deleteMessage: 'CONFIRM DELETE',
      description: (
        <div className='text-left'>
          Your Motion account will be deleted and you&apos;ll lose access to
          Motion. <br />
          Please refer to our{' '}
          <a
            className='text-semantic-primary-text-default'
            href='https://www.usemotion.com/terms'
            rel='noreferrer'
            target='_blank'
          >
            refund policy
          </a>
          .
        </div>
      ),
      deleteButtonLabelValue: 'information',
    })
  }

  return (
    <SettingPage title='Privacy'>
      <div className='flex flex-col gap-y-8'>
        <Section
          title='Download your data'
          action={
            <Button
              variant='outlined'
              sentiment='neutral'
              size='small'
              onClick={async () => {
                await downloadData()
                showToast(
                  'success',
                  `Data requested. We'll send you an email once it's ready.`
                )
              }}
            >
              Request data
            </Button>
          }
        >
          <Description text='Get a copy of your data in Motion.' />
        </Section>

        {/* The database column backing this was removed. If we want to re add it, we'll need to reimplement the backend functionality */}
        {/* <HasExperiment name='cookie-alert'>*/}
        {/*  <Section title='Cookies'>*/}
        {/*    <div className='flex flex-col gap-y-3'>*/}
        {/*      <CookieSection*/}
        {/*        title='Strictly necessary cookies'*/}
        {/*        subtitle='These cookies are needed to make Motion work.'*/}
        {/*        isCheckboxChecked*/}
        {/*        isCheckboxDisabled*/}
        {/*        onChange={() => {*/}
        {/*          void 0*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <CookieSection*/}
        {/*        title='Functional cookies'*/}
        {/*        subtitle='These cookies help us to improve Motion.'*/}
        {/*        isCheckboxChecked={functionalCookiesChecked}*/}
        {/*        isCheckboxDisabled={false}*/}
        {/*        onChange={async (isChecked) => {*/}
        {/*          setAreFunctionalCookiesChecked(isChecked)*/}
        {/*          await updateCookiePrefs({ allowOptionalCookies: isChecked })*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </Section>*/}
        {/* </HasExperiment>*/}

        <Section
          title='Delete information'
          action={
            <Button sentiment='error' size='small' onClick={handleDeleteUser}>
              Delete data
            </Button>
          }
        >
          <Description text='Delete all the information associated with your account.' />
        </Section>
      </div>
    </SettingPage>
  )
}
