import { type SvgIcon } from '@motion/icons'

import { type ReactNode } from 'react'

interface GenericFieldProps {
  icon?: SvgIcon
  children: ReactNode
}

export const GenericField = (props: GenericFieldProps) => {
  const { icon: Icon, children } = props

  return (
    <div className='flex items-center gap-1'>
      {Icon && (
        <Icon className='inline-block w-3 h-3 text-semantic-neutral-icon-subtle' />
      )}
      {children}
    </div>
  )
}
