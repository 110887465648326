import { isNoneId } from '@motion/shared/identifiers'
import { ConditionalWrapper } from '@motion/ui/utils'
import { type ProjectSchema } from '@motion/zod/client'

import { type BadgeSize, ProjectBadge } from '~/global/components/badges'
import { useProject } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'

import { MotionLink } from '../motion-link'

export type ProjectLabelProps = {
  value: ProjectSchema | null
  size?: BadgeSize
  nameOnly?: boolean
  /**
   * If true, the label will be clickable and will open the project modal
   */
  clickable?: boolean
}

export const ProjectLabel = ({
  size,
  value,
  nameOnly = false,
  clickable = false,
}: ProjectLabelProps) => {
  const buildProjectModalUrl = useProjectModalUrl()
  const name = value == null ? 'No project' : value.name

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  return (
    <ConditionalWrapper
      condition={clickable && value != null && !isNoneId(value.id)}
      wrapper={(children) => (
        <MotionLink
          url={buildProjectModalUrl({
            // @ts-expect-error the ConditionalWrapper condition ensures that value is not null
            project: value.id,
          })}
          className='overflow-hidden'
        >
          {children}
        </MotionLink>
      )}
    >
      {nameOnly ? (
        label
      ) : (
        <div className='flex gap-1 items-center overflow-hidden leading-4'>
          <ProjectBadge value={value} size={size} hideTooltip />
          {label}
        </div>
      )}
    </ConditionalWrapper>
  )
}

export type ConnectedProjectLabelProps = Omit<ProjectLabelProps, 'value'> & {
  id: ProjectSchema['id'] | null
}

export function ConnectedProjectLabel({
  id,
  ...rest
}: ConnectedProjectLabelProps) {
  const project = useProject(id)

  return <ProjectLabel value={project} {...rest} />
}
