import type {
  FolderFolderItemSchema,
  FolderWithItemsSchema,
  RecursiveFolderItemSchema,
} from '@motion/zod/client'

type FindFolderItemSearchItem =
  | FolderWithItemsSchema
  | RecursiveFolderItemSchema

export type FindFolderItemCallback = (
  item: RecursiveFolderItemSchema
) => boolean

export type FindFolderItemReturn = [
  RecursiveFolderItemSchema,
  ...FolderFolderItemSchema[],
]

export function findFolderItem(
  folders: FolderWithItemsSchema,
  cb: FindFolderItemCallback
): FindFolderItemReturn | null {
  const search = (
    item: FindFolderItemSearchItem,
    tree: FindFolderItemSearchItem[] = []
  ): FindFolderItemReturn | null => {
    const newTree = [item, ...tree] as FindFolderItemReturn

    if ('items' in item) {
      for (const child of item.items) {
        if (cb(child)) {
          return [child, ...newTree] as FindFolderItemReturn
        }

        const result = search(child, newTree)

        if (result) {
          return result
        }
      }
    }

    return null
  }

  return search(folders)
}
