import { useCustomFieldsRecord } from '~/areas/custom-fields/hooks'

import { type KanbanCardField } from './use-kanban-card-field-view-state'

export const useFilteredCardItems = (
  items: KanbanCardField[],
  workspaceId: string
) => {
  const customFields = useCustomFieldsRecord(workspaceId)

  return items.filter((item) => customFields[item.id] != null)
}
