import { isOneTimeEvent } from '~/areas/calendar/utils'
import { useMemo } from 'react'

import { useSelectExistingScheduleEntities } from './use-select-existing-schedule-entities'

export function useUserCalendarEvents(opts?: { enabled?: boolean }) {
  const { data, isLoading } = useSelectExistingScheduleEntities(opts)

  return useMemo(() => {
    if (!data || isLoading)
      return {
        calendarEvents: [],
        isLoading,
      }

    const calendarEvents = data.ids
      .map((id) => data.models.calendarEvents[id])
      .filter(Boolean)
      .filter(isOneTimeEvent)

    return { calendarEvents, isLoading }
  }, [data, isLoading])
}
