import { StyledField } from '@motion/ui/forms'

import { twMerge } from 'tailwind-merge'

interface AvailabilityMessageProps {
  richLinks: boolean
  message: string
  availabilityHoverHandler?: (availability: boolean) => void
}

export const AvailabilityMessage = ({
  richLinks,
  message,
  availabilityHoverHandler = () => {},
}: AvailabilityMessageProps) => {
  return (
    <StyledField
      onMouseEnter={() =>
        availabilityHoverHandler && availabilityHoverHandler(true)
      }
      onMouseLeave={() =>
        availabilityHoverHandler && availabilityHoverHandler(false)
      }
    >
      <div
        dangerouslySetInnerHTML={{ __html: message }}
        className={twMerge(
          richLinks ? 'whitespace-normal' : 'whitespace-pre-wrap'
        )}
      />
    </StyledField>
  )
}
