import { BlockedBySolid, BlocksSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

import { type ReactNode } from 'react'

const HeaderContainer = classed('div', {
  base: `
    text-[9px]
    h-5 flex items-center px-2 gap-1
    [&_[data-icon]]:w-3
    [&_[data-icon]]:h-3
  `,
  variants: {
    variant: {
      ready: `
        bg-semantic-success-bg-default
        text-semantic-success-text-default
    `,
      blockedBy: `
        bg-semantic-error-bg-default
        [&_[data-icon]]:text-semantic-error-icon-default
        text-semantic-error-text-default
      `,
      blocking: `
        bg-semantic-neutral-bg-subtle
        [&_[data-icon]]:text-semantic-warning-icon-default
        text-semantic-neutral-text-subtle
      `,
    },
  },
  defaultVariants: {
    variant: 'ready',
  },
})

export type CalendarBlockersHeaderProps = {
  children: ReactNode
  tagText?: string
  variant: VariantProps<typeof HeaderContainer>['variant']
}

export const CalendarBlockersHeader = (props: CalendarBlockersHeaderProps) => {
  const { children, variant, tagText } = props
  const Icon =
    variant === 'blockedBy'
      ? BlockedBySolid
      : variant === 'blocking'
        ? BlocksSolid
        : null
  return (
    <HeaderContainer variant={variant}>
      {Icon && <Icon />}
      <span className='grow font-medium'>{children}</span>
      {tagText && <span>{tagText}</span>}
    </HeaderContainer>
  )
}
