import { isEnabledStage } from '@motion/ui-logic/pm/project'
import type { StageSchema } from '@motion/zod/client'

export type StageGroup = {
  dueDateKey: string
  stages: StageSchema[]
}

export const groupStagesByDueDateAndFilterSkipped = (
  stages: StageSchema[]
): StageGroup[] => {
  return stages
    .filter(isEnabledStage) // Filter out skipped stages
    .reduce((accumulatedGroups: StageGroup[], currentStage) => {
      const lastGroup = accumulatedGroups[accumulatedGroups.length - 1]

      if (lastGroup && lastGroup.dueDateKey === currentStage.dueDate) {
        lastGroup.stages.push(currentStage)
      } else {
        accumulatedGroups.push({
          dueDateKey: currentStage.dueDate,
          stages: [currentStage],
        })
      }

      return accumulatedGroups
    }, [])
}
