import { type Model } from '@motion/rpc-cache'
import { type AllModelsSchema } from '@motion/rpc-types'
import { type Group } from '@motion/utils/array'

import { createContext } from 'react'

export type BulkOpsGroups<T extends keyof AllModelsSchema> = {
  workspaceGroups: Group<Model<T>, string>[]
  projectDefinitionGroups: Group<Model<T>, string>[]
}

const defaultValue: BulkOpsGroups<'tasks' | 'projects'> = {
  workspaceGroups: [],
  projectDefinitionGroups: [],
}

export const BulkOpsGroupsContext = createContext(defaultValue)
