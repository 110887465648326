import {
  type DragOverEvent,
  type DragStartEvent,
  type UniqueIdentifier,
} from '@dnd-kit/core'
import { useCallback } from 'react'

import { type GroupedNode, type Tree } from '../../../grouping'
import { findContainer, isContainer } from '../utils'

type KanbanDndHooksArgs<T extends GroupedNode> = {
  laneItems: Tree<T>
  setActiveId: (id: UniqueIdentifier | null) => void
  setOverGroupId: (id: UniqueIdentifier | null) => void
}

export const useKanbanDndHooks = <T extends GroupedNode>({
  setActiveId,
  setOverGroupId,
  laneItems,
}: KanbanDndHooksArgs<T>) => {
  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveId(event.active.id)
    },
    [setActiveId]
  )

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const overId = over?.id
      if (overId == null || isContainer(laneItems, active.id)) {
        return
      }

      const overContainer = findContainer(laneItems, overId)
      setOverGroupId(overContainer?.key ?? null)
    },
    [laneItems, setOverGroupId]
  )

  const handleDragCancel = useCallback(() => {
    setActiveId(null)
    setOverGroupId(null)
  }, [setActiveId, setOverGroupId])

  return {
    handleDragStart,
    handleDragOver,
    handleDragCancel,
  }
}
