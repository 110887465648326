import { type CustomFieldValuesSchema } from '@motion/rpc-types'
import {
  type BulkOpsCustomFieldsSchema,
  type FieldOperationSchema,
} from '@motion/shared/custom-fields'
import { templateStr, truncateAtSpace } from '@motion/ui-logic'

import { CustomFieldValueBadge } from '~/areas/custom-fields/badges'
import { useCustomFieldById } from '~/areas/custom-fields/hooks'
import {
  CustomFieldValueDisplay,
  type CustomFieldValueLabelProps,
} from '~/areas/custom-fields/labels'

import { operationsLabel } from '../constants'

type BulkCustomFieldValueLabelProps = Omit<
  CustomFieldValueLabelProps,
  'customField' | 'value'
> & {
  instanceId: string
  value: BulkOpsCustomFieldsSchema
}

export const BulkCustomFieldValueLabel = ({
  instanceId,
  value,
  size,
}: BulkCustomFieldValueLabelProps) => {
  const customField = useCustomFieldById(instanceId)

  if (customField == null) return null

  const { operation, value: finalvalue } = getOperationAndValue(value)

  return (
    <div className='flex gap-1 items-center overflow-hidden leading-4'>
      <CustomFieldValueBadge value={finalvalue} size={size} />
      <span className='text-nowrap'>
        {templateStr('{{customFieldName}}:', {
          customFieldName: truncateAtSpace(customField.name, 15),
        })}
      </span>
      {operation != null && operation !== 'add' && (
        <span className='text-semantic-error-text-default capitalize'>
          {operationsLabel[operation]}
        </span>
      )}
      <div className='overflow-hidden'>
        <CustomFieldValueDisplay
          value={finalvalue}
          customField={customField}
          size={size}
        />
      </div>
    </div>
  )
}

function getOperationAndValue(value: BulkOpsCustomFieldsSchema): {
  operation: FieldOperationSchema | null
  value: CustomFieldValuesSchema
} {
  if (Array.isArray(value.value) && value.value.length > 0) {
    return {
      operation: value.value[0].operation,
      value: {
        type: value.type,
        value: value.value.map((v) => v.id),
      },
    } as any
  }

  return { operation: null, value: value as any }
}
