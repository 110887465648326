import { FieldButton } from '@motion/ui/base'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

import { useCalendarStartDay } from '~/areas/calendar/hooks'
import { mergeDateWithTime } from '~/areas/project-management/utils'
import { SimpleDateDropdown } from '~/global/components/dropdowns'

import {
  useScheduleAssistantCalendarContext,
  useUpdateSelectedCalendarEvent,
} from '../../../hooks'

export function StartDateField() {
  const [{ selectedCalendarEvent }] = useScheduleAssistantCalendarContext()
  const updateSelectedEvent = useUpdateSelectedCalendarEvent()
  const calendarStartDay = useCalendarStartDay()

  if (selectedCalendarEvent?.new == null) return null

  const startValue = selectedCalendarEvent.new.start
  const endValue = selectedCalendarEvent.new.end

  const onChange = (newValue: string) => {
    updateSelectedEvent({
      new: {
        start: mergeDateWithTime(
          parseDate(newValue),
          parseDate(startValue)
        ).toISO(),
        end: mergeDateWithTime(
          parseDate(newValue),
          parseDate(endValue)
        ).toISO(),
      },
    })
  }

  return (
    <SimpleDateDropdown
      value={startValue}
      calendarStartDay={calendarStartDay}
      disabledDate={() => false}
      onChange={(value) => {
        if (!value) return
        try {
          onChange(value)
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              value,
              position: 'EventFormDateField',
            },
          })
        }
      }}
    >
      <FieldButton aria-label='Start date time' size='small' fullWidth>
        {startValue != null
          ? parseDate(startValue).toFormat('MMM d')
          : 'No date'}
      </FieldButton>
    </SimpleDateDropdown>
  )
}
