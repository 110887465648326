import {
  ChevronDownOutline,
  ChevronUpOutline,
  RefreshOutline,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, HoverRow } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { type TaskWithRelations } from '~/global/proxies'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { RowCheckbox, RowContainer, ScheduleReason } from './common'
import { ActionsContainer } from './common/actions-container'
import { Details, TaskTextArea, TaskTitle, TaskTitleBar } from './styled'

type MissedInstancesRowProps = {
  parentId: string
  instances: TaskWithRelations[]
}

export const MissedInstancesRow = ({
  parentId,
  instances,
}: MissedInstancesRowProps) => {
  const { data: task } = useTaskByIdV2({ id: parentId })

  const workspaceStatus = useWorkspaceStatusById(task?.statusId ?? '')
  const [isShowingAllInstances, setIsShowingAllInstances] = useState(false)

  // Reset the expanded state when the instances change (e.g. when the user updates other instances)
  // such that only one is left, we should collapse the row
  useEffect(
    function resetExpandedStateOnInstancesChange() {
      if (instances.length === 1) {
        setIsShowingAllInstances(false)
      }
    },
    [instances]
  )

  if (!task) return null

  const analyticType =
    instances.length > 1 ? 'RECURRING_PARENT' : 'RECURRING_INSTANCE'

  const toggleInstances = (value: boolean) => {
    recordAnalyticsEvent('PAST_DUE_MODAL_INSTANCES_TOGGLE', { open: value })
    setIsShowingAllInstances(value)
  }

  return (
    <>
      <RowContainer
        type={analyticType}
        taskId={parentId}
        onClickOverride={
          instances.length > 1
            ? () => toggleInstances(!isShowingAllInstances)
            : undefined
        }
        actions={
          <ActionsContainer tasks={instances} type={analyticType} showAllText />
        }
      >
        <Details>
          <RowCheckbox tasks={instances} type={analyticType} />
          {workspaceStatus && (
            <div className='-mt-[5px]'>
              <StatusBadge value={workspaceStatus} />
            </div>
          )}

          <TaskTextArea>
            <TaskTitleBar>
              <TaskTitle>{task.name}</TaskTitle>
              <RefreshOutline
                className='text-semantic-neutral-icon-default shrink-0'
                width={14}
                height={14}
              />
            </TaskTitleBar>
            <div className='-mt-1'>
              {instances.length > 1 ? (
                <div className='-ml-2'>
                  <Button
                    sentiment='neutral'
                    variant='muted'
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleInstances(!isShowingAllInstances)
                    }}
                  >
                    <span>{instances.length} instances</span>
                    {isShowingAllInstances ? (
                      <ChevronUpOutline width={16} height={16} />
                    ) : (
                      <ChevronDownOutline width={16} height={16} />
                    )}
                  </Button>
                </div>
              ) : (
                <ScheduleReason task={instances[0]} />
              )}
            </div>
          </TaskTextArea>
        </Details>
      </RowContainer>
      {isShowingAllInstances && (
        <div className='ml-7 flex flex-col'>
          {instances.map((instance) => (
            <SingleInstanceRow key={instance.id} instance={instance} />
          ))}
        </div>
      )}
    </>
  )
}

const SingleInstanceRow = ({ instance }: { instance: TaskWithRelations }) => {
  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  return (
    <InstanceRowContainer
      actions={
        <ActionsContainer type='RECURRING_INSTANCE' tasks={[instance]} />
      }
      override='show'
      actionsCentered
    >
      <div
        className='flex items-center h-full justify-center gap-2'
        onClick={() => navigate(buildTaskModalUrl({ task: instance.id }))}
      >
        <div className='mt-[3px]'>
          <RowCheckbox tasks={[instance]} type='RECURRING_INSTANCE' />
        </div>
        <TaskTitleBar>
          <ScheduleReason task={instance} />
        </TaskTitleBar>
      </div>
    </InstanceRowContainer>
  )
}

const InstanceRowContainer = classed(HoverRow, {
  base: `
    size-full
    pl-2 pr-3 gap-2
    py-1
    rounded
    cursor-pointer
    text-semantic-neutral-text-default
    hover:bg-semantic-neutral-bg-active-default
  `,
})
