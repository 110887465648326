import { classed } from '@motion/theme'

export const FieldContainer = classed(
  'div',
  'flex gap-4 items-center',
  'ml-1',
  'h-5',
  'rounded border border-transparent',
  'hover:bg-semantic-neutral-bg-hover',
  'focus-within:border-field-border-focus'
)

export const FieldNameColumn = classed('span', {
  base: `
    flex-1
    text-2xs truncate
    text-semantic-neutral-text-subtle
  `,
})

export const FieldComponentColumn = classed('div', {
  base: `
    flex-[2]
    truncate
  `,
})
