import { parseCustomFieldInfoFromMaybeDelimitedKey } from '@motion/ui-logic'

import {
  type CustomFieldDateRow,
  type CustomFieldNumberRow,
  type CustomFieldTextRow,
} from '../../../types'
import { GroupHeaderWithFieldName } from '../../group-headers'

export function CustomFieldTextGroupHeader({
  item,
}: {
  item: (CustomFieldTextRow | CustomFieldNumberRow | CustomFieldDateRow) & {
    key: string
  }
}) {
  const res = parseCustomFieldInfoFromMaybeDelimitedKey(item.type)
  if (res == null) return null
  const { name } = res

  return (
    <GroupHeaderWithFieldName fieldName={name}>
      <span className='truncate'>{item.value}</span>
    </GroupHeaderWithFieldName>
  )
}
