import { createEvent } from '@motion/react-core/hooks'

import { type ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import {
  type BulkOpsAction,
  BulkOpsContext,
  type BulkOpsTarget,
  type BulkOpsValue,
} from './bulk-ops-context'

import { useBulkOpsFilterReset } from '../../hooks/use-bulk-ops-filter-reset'

type BulkOpsProviderProps = {
  target: BulkOpsTarget
  children?: ReactNode
}

export function BulkOpsProvider({ target, children }: BulkOpsProviderProps) {
  const [selectedIds, setSelectedIds] = useState<BulkOpsValue['selectedIds']>(
    []
  )

  const actionCallbackMap = useRef<
    Record<BulkOpsAction, ReturnType<typeof createEvent>>
  >({
    'select-all': createEvent(),
    'unselect-all': createEvent(),
  })

  useBulkOpsFilterReset(() => {
    setSelectedIds([])
  })

  /**
   * To subscribe to an action from other components, please use the `useBulkOpsSubscribeAction` hook
   */
  const onAction = useCallback(
    (action: BulkOpsAction, fn: () => void) => {
      actionCallbackMap.current[action].subscribe(fn)

      return () => {
        actionCallbackMap.current[action].unsubscribe(fn)
      }
    },
    [actionCallbackMap]
  )

  const triggerAction = useCallback(
    (action: BulkOpsAction) => {
      actionCallbackMap.current[action].fire({})
    },
    [actionCallbackMap]
  )

  const value = useMemo<BulkOpsValue>(() => {
    return {
      target,
      selectedIds,
      setSelectedIds,
      triggerAction,
      onAction,
    }
  }, [target, onAction, selectedIds, triggerAction])

  return (
    <BulkOpsContext.Provider value={value}>{children}</BulkOpsContext.Provider>
  )
}
