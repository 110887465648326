import { classed } from '@motion/theme'

import { OpenLeftSidebarButton } from '~/areas/sidebar/components'

import { AgendaTreeView, CurrentTaskSection } from './components'

export function AgendaView() {
  return (
    <AgendaColumn>
      <LeftSidebarButton />
      <CurrentTaskSection />
      <AgendaTreeView />
    </AgendaColumn>
  )
}

function LeftSidebarButton() {
  return (
    <div className='absolute -ml-10 -mt-6'>
      <OpenLeftSidebarButton />
    </div>
  )
}

export const AgendaColumn = classed('div', {
  base: 'flex relative flex-col size-full pl-12 pt-8 overflow-hidden bg-calendar-bg-default',
})
