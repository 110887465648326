export type CreateProjectSearchParams = {
  forTaskId?: string
  forWorkspace?: string
  project: 'create'
}

export const clearCreateProjectParams = (searchParams: URLSearchParams) => {
  searchParams.delete('forTaskId')
  searchParams.delete('project')
  return searchParams
}
