import { useSharedState } from '@motion/react-core/shared-state'
import { useModalApi } from '@motion/web-common/modals'

import { ProjectManagementViewDropdown } from '../../../../../components/project-management-view-dropdown'
import { DEFAULT_VIEW, TeamScheduleActiveViewOptionsKey } from '../../context'
import { TeamSchedulePageContextKey } from '../../context/page-data-context'
import { type TeamScheduleView } from '../../types'
import { useSelectView } from '../hooks'
import { useDeleteView } from '../hooks/use-delete-view'

export const ConnectedSelectViewButton = () => {
  const [ctx] = useSharedState(TeamSchedulePageContextKey)
  const [viewState] = useSharedState(TeamScheduleActiveViewOptionsKey)

  const selected = ctx.views.byId[viewState.viewId] ?? DEFAULT_VIEW

  const modalApi = useModalApi()

  const deleteView = useDeleteView()
  const selectView = useSelectView()

  return (
    <SelectViewButton
      views={ctx.views.all}
      selected={selected}
      onSelect={(view) => {
        selectView(view.id)
      }}
      onDelete={(view) => deleteView(view)}
      onCreate={() =>
        modalApi.open('save-team-view', {
          asNew: true,
          personal: selected.isPrivate,
        })
      }
    />
  )
}

type SelectViewButtonProps = {
  views: TeamScheduleView[]
  selected: TeamScheduleView

  onSelect(view: TeamScheduleView): void
  onDelete(view: TeamScheduleView): void
  onCreate(): void
}

export const SelectViewButton = (props: SelectViewButtonProps) => {
  const { views, selected, onSelect, onDelete, onCreate } = props

  return (
    <ProjectManagementViewDropdown
      views={views}
      selected={selected}
      onSelect={onSelect}
      onDelete={onDelete}
      onCreateNew={onCreate}
    />
  )
}
