import { useAllWorkspaceActiveMembers } from '~/global/hooks'

import { useBulkFilteredData } from '../use-bulk-filtered-data'

export function useBulkFilteredUsers() {
  const workspaceMembers = useAllWorkspaceActiveMembers()

  const filteredUsers = useBulkFilteredData(
    workspaceMembers,
    (member) => member.user.id
  )

  return filteredUsers
}
