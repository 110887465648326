import { type UpdateTeamTaskDto } from '@motion/rpc-types'
import { type NormalTaskSchema, type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export function useTaskCompletedDurationUpdater() {
  const updateTask = useTaskUpdater()
  const readTaskFn = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      completedDuration: NormalTaskSchema['completedDuration']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTaskFn(taskOrId) : taskOrId

      if (
        task == null ||
        task.type === 'RECURRING_TASK' ||
        task.type === 'CHUNK'
      ) {
        throw new Error(
          'Cannot update the completed duration of an invalid task',
          {
            cause: {
              task,
              taskType: task?.type,
            },
          }
        )
      }

      const updates: Omit<UpdateTeamTaskDto, 'id'> = {
        completedDuration,
      }

      await updateTask(task, updates)
    },
    [readTaskFn, updateTask]
  )
}
