import { TabList, type TabListProps } from '@motion/ui/base'

import { useWorkspaceById } from '~/global/hooks'
import { matchPath } from 'react-router'
import { Outlet, useLocation } from 'react-router-dom'

import { SettingPage } from '../../../components/Settings/Components/SettingPage'

type WorkspaceSettingsProps = {
  workspaceId: string
}
export const WorkspaceSettings = ({ workspaceId }: WorkspaceSettingsProps) => {
  const workspace = useWorkspaceById(workspaceId)

  const location = useLocation()
  const pathName = matchPath(
    'web/settings/workspace/:workspaceId/:tab',
    location.pathname
  )
  const tab = pathName?.params?.tab

  if (!workspace) {
    // For the case right after you delete a workspace
    return null
  }
  const baseUrl = `/web/settings/workspace/${workspaceId}`

  const tabs: TabListProps['items'] = [
    {
      value: 'overview',
      content: 'Overview',
      url: `${baseUrl}/overview`,
    },
    {
      value: 'statuses',
      content: 'Statuses',
      url: `${baseUrl}/statuses`,
    },
    {
      value: 'project-templates',
      content: 'Project Templates',
      url: `${baseUrl}/project-templates`,
    },
    {
      value: 'task-templates',
      content: 'Task Templates',
      url: `${baseUrl}/task-templates`,
    },
    {
      value: 'custom-fields',
      content: 'Custom Fields',
      url: `${baseUrl}/custom-fields`,
    },
    {
      value: 'labels',
      content: 'Labels',
      url: `${baseUrl}/labels`,
    },
  ]

  function renderTabGroup() {
    return (
      <div className='flex px-12 items-start gap-4 self-stretch bg-calendar-bg-default'>
        <TabList activeValue={tab ?? 'overview'} items={tabs} />
      </div>
    )
  }

  return (
    <SettingPage
      className='gap-4'
      tabGroup={renderTabGroup()}
      title={workspace.name}
    >
      <Outlet />
    </SettingPage>
  )
}
