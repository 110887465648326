import { ArchiveField } from './archive-field'
import { AssigneeField } from './assignee-field'
import { AutoscheduleField } from './autoschedule-field'
import { DeleteField } from './delete-field'
import { DueDateField } from './due-date-field'
import { DurationField } from './duration-field'
import { LabelsField } from './labels-field'
import { MoveField } from './move-field'
import { PriorityField } from './priority-field'
import { StageField } from './stage-field'
import { StartDateField } from './start-date-field'
import { StatusField } from './status-field'

import { type BulkOpsConfig, useBulkOpsConfig } from '../hooks'

export function CoreFields() {
  const config = useBulkOpsConfig()

  return <CoreFieldsContent {...config} />
}

type CoreFieldsContentProps = BulkOpsConfig

function CoreFieldsContent({
  showStatusField,
  showPriorityField,
  showDurationField,
  showAssigneeField,
  showStartDateField,
  showDueDateField,
  showStageField,
  showAutoscheduleField,
  showLabelsField,
  showMoveField,
  showArchiveField,
  showDeleteField,
}: CoreFieldsContentProps) {
  return (
    <>
      {showStatusField && <StatusField />}
      {showPriorityField && <PriorityField />}
      {showDurationField && <DurationField />}
      {showAssigneeField && <AssigneeField />}
      {showStartDateField && <StartDateField />}
      {showDueDateField && <DueDateField />}
      {showStageField && <StageField />}
      {showAutoscheduleField && <AutoscheduleField />}
      {showLabelsField && <LabelsField />}
      {showMoveField && <MoveField />}
      {showArchiveField && <ArchiveField />}
      {showDeleteField && <DeleteField />}
    </>
  )
}
