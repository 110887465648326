import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { clearProjectForParams } from '../../project-modal/utils'
import { clearCreateProjectParams } from '../utils'

export const useCloseCreateProjectModal = () => {
  const [, setSearchParams] = useSearchParams()

  return useCallback(() => {
    setSearchParams((prev) => {
      clearCreateProjectParams(prev)
      clearProjectForParams(prev)
      return prev
    })
  }, [setSearchParams])
}
