import { ALL_FILTER_DEFINITIONS } from '~/areas/project-management/filters/definitions'

const filtersToExclude = [
  'managerIds',
  'createdTime',
  'completedTime',
  'updatedTime',
  'dueDate',
  'estimatedCompletionTime',
]
export const definitions = ALL_FILTER_DEFINITIONS.filter(
  (x) => !filtersToExclude.includes(x.key)
)
