import type { NormalTaskSchema, TaskSchema } from '@motion/rpc-types'

import { useAutoSchedule } from '~/areas/task-project/hooks'
import { useTaskDeadlineBadgeData } from '~/areas/tasks/hooks'
import { isBlockerUnfit } from '~/areas/tasks/utils'
import { DeadlineDelayBadge } from '~/global/components/badges'
import { twMerge } from 'tailwind-merge'

import { BlockerLinesSvg } from './blocker-lines-svg'

export type BlockerChainProps = {
  chain: NormalTaskSchema[]
  task: TaskSchema
  variant?: 'default' | 'unfit'
}

export const BlockerChain = ({
  chain,
  task,
  variant = 'default',
}: BlockerChainProps) => {
  return (
    <div className='flex text-semantic-neutral-text-subtle relative'>
      <div className='absolute left-0 top-0'>
        <BlockerLinesSvg chainLength={chain.length} />
      </div>

      <div className='w-full flex flex-col flex-1 gap-2 pl-3 text-sm'>
        {chain.map((b) => {
          return <BlockerTaskLine key={b.id} task={b} variant={variant} />
        })}

        <div className='pl-[42px] text-tooltip-text'>
          <span>{task.name}</span>
        </div>
      </div>
    </div>
  )
}

const NOT_SCHEDULED = 'Not scheduled'

const BlockerTaskLine = ({
  task,
  variant,
}: {
  task: NormalTaskSchema
  variant?: BlockerChainProps['variant']
}) => {
  const { formattedScheduledDate, isAutoScheduled } = useAutoSchedule(task)

  const isUnfit = isBlockerUnfit(task)
  const { deadlineDelayMinutes, deadlineDelayDays } =
    useTaskDeadlineBadgeData(task)

  return (
    <div className='grid grid-cols-[1fr_48px_24px] items-center gap-3'>
      <div
        className={twMerge(
          'truncate',
          variant === 'unfit' && isUnfit
            ? 'text-semantic-warning-text-default'
            : 'text-tooltip-text-subtle'
        )}
      >
        {variant === 'unfit' && isUnfit && '*'}
        {task.name}
      </div>

      <span className='text-right text-nowrap'>
        {isAutoScheduled ? formattedScheduledDate : NOT_SCHEDULED}
      </span>

      {isAutoScheduled && (
        <div className='text-right'>
          <DeadlineDelayBadge
            dayNumberTooltipContent={null}
            deadlineDelayDays={deadlineDelayDays}
            deadlineDelayMinutes={deadlineDelayMinutes}
          />
        </div>
      )}
    </div>
  )
}
