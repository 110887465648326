import { createContext } from 'react'

import { type TemplateTaskFormOptions } from './types'

const defaultValue: TemplateTaskFormOptions = {
  currentUserId: null,
  workspaces: [],
  selectedWorkspace: undefined,
  members: [],
  activeMembers: [],
  priorities: [],
  durations: [],
  chunkDurations: [],
  schedules: [],
}

export const TemplateTaskFormOptionsContext = createContext(defaultValue)
