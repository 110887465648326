import { type StageDefinitionSchema } from '@motion/zod/client'

import { StageLabel } from '~/global/components/labels'
import { twMerge } from 'tailwind-merge'

export type StageTagsProps = {
  stages: readonly Pick<StageDefinitionSchema, 'name' | 'color' | 'id'>[]
  isColumn?: boolean
  hideScroll?: boolean
}

export const StageTags = ({ stages, isColumn, hideScroll }: StageTagsProps) => {
  return (
    <div
      className={twMerge(
        'flex gap-1 shrink-0',
        isColumn ? 'flex-col gap-[3px]' : 'flex-row items-center',
        hideScroll ? 'truncate' : 'overflow-auto'
      )}
    >
      {stages.map((stage) => (
        <div key={stage.id} className='flex shrink-0'>
          <StageLabel value={stage} size='small' />
        </div>
      ))}
    </div>
  )
}
