import { PopoverTrigger, SearchableList } from '@motion/ui/base'

import { type ReactNode, useMemo } from 'react'

import {
  createHalfHourTimeChoices,
  halfHourTimeChoices,
} from '../../modals/template-task-modal/utils'

const None = 'None'

type EmptyValue = {
  allowEmpty: true
  value: string | null
  onChange: (time: string | null) => void
}

type NonEmptyValue = {
  allowEmpty?: false
  value: string
  onChange: (time: string) => void
}

type TimeDropdownProps = {
  constraints?: {
    start: string
    end: string
  }
  children: ReactNode
} & { allowEmpty?: boolean } & (EmptyValue | NonEmptyValue)

export function TimeDropdown(props: TimeDropdownProps) {
  const { allowEmpty, constraints, onChange, value, children } = props

  const items = useMemo(() => {
    const values =
      constraints?.start != null
        ? createHalfHourTimeChoices(constraints.start, constraints.end)
        : halfHourTimeChoices

    return allowEmpty ? [None, ...values] : values
  }, [allowEmpty, constraints?.start, constraints?.end])

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={items}
          computeKey={(item) => item}
          computeSelected={(item) => item === value}
          onSelect={(item) => {
            if (allowEmpty) {
              onChange(item === None ? null : item)
            } else {
              onChange(item)
            }

            close()
          }}
          renderItem={(item) => item}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
