import { useModalApi } from '@motion/web-common/modals'

import { useIsIapSubscription } from '~/global/hooks'
import { useIndividualSubscription } from '~/global/rpc'
import { type SettingPageOptions } from '~/pages/settings/types'
import { matchPath, useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { SettingsSidebar } from './SettingsSidebar'

import { routeFrom, useSearchParams } from '../../../routing'

export const ConnectedSettingsSidebar = () => {
  const modalApi = useModalApi()
  const navigate = useNavigate()
  const params = useParams()
  const individualSubscription = useIndividualSubscription()
  const hasIndividualSub =
    individualSubscription?.status === 'active' ||
    individualSubscription?.status === 'trialing'
  const isIapSubscription = useIsIapSubscription()

  const query = useSearchParams<{
    workspaceId?: string
  }>()

  const location = useLocation()
  const routeUri = new URL(routeFrom(location), window.location.origin)
  const routeMatch = matchPath(
    { path: '/settings/:page', end: false },
    routeUri.pathname
  )

  let page = (routeMatch?.params.page ?? 'calendar') as SettingPageOptions

  if (page === 'team') {
    const nestedRouteMatch = matchPath(
      { path: '/settings/team/:page', end: false },
      routeUri.pathname
    )
    if (nestedRouteMatch?.params.page) {
      page = ('team/' + nestedRouteMatch?.params.page) as SettingPageOptions
    }
  }

  return (
    <SettingsSidebar
      navigate={navigate}
      settingsPage={page}
      selectedWorkspaceId={query.workspaceId ?? params.workspaceId}
      showReferralUpsell={hasIndividualSub && !isIapSubscription}
      onCreateWorkspace={() =>
        modalApi.prompt('add-workspace', {
          onValue: (workspaceId) => {
            return navigate(`/web/settings/workspace/${workspaceId}/overview`)
          },
        })
      }
    />
  )
}
