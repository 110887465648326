import {
  API,
  createUseMutation,
  createUseQuery,
  createUseQueryFn,
} from '@motion/rpc'
import { MotionCache } from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'

// TODO: update this once endpoint is ready
export const useUpdateEmail = createUseMutation(API.users.updateEmail)
export const useUpdateName = createUseMutation(API.users.updateName)
export const useUpdatePassword = createUseMutation(API.users.changePasswordApi)

export const useDisconnectCalendars = createUseMutation(
  API.users.disconnectCalendars
)
export const useDeleteAccount = createUseMutation(API.users.deleteAccount)

const useDeleteProfilePictureMutation = createUseMutation(
  API.users.deleteProfilePic
)

export const useCurrentUser = createUseQuery(API.usersV2.getCurrentUser, {
  select(data) {
    return data.models.users[data.id]
  },
})

const useCurrentUserFn = createUseQueryFn(API.usersV2.getCurrentUser)

export const useDeleteProfilePicture = () => {
  const getCurrentUser = useCurrentUserFn()
  const client = useQueryClient()

  return useDeleteProfilePictureMutation({
    async onSuccess(data, variables, context) {
      await getCurrentUser(undefined, { staleTime: 0 }).then((user) => {
        MotionCache.upsert(client, { queryKey: ['model-cache'] }, user)
      })
    },
  })
}
