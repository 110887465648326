import { createPlaceholderId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { uniqueId } from '@motion/utils/core'

import {
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
} from '~/areas/flows/utils'

import {
  type CreateProjectDefinitionSchemaWithoutWorkspaceFields,
  getVariableKeyByName,
  getWrappedVariableKeyByName,
} from './utils'

import { createNewRole, DEFAULT_TEXT_VARIABLES } from '../../utils'

export const nonProfitCommunityPreset = () => {
  const roles = [
    createNewRole('Community Outreach Officer'),
    createNewRole('Program Director'),
    createNewRole('Financial Analyst'),
    createNewRole('Marketing Coordinator'),
    createNewRole('Volunteer Coordinator'),
  ]
  const textVariables = [...DEFAULT_TEXT_VARIABLES]

  return {
    labelIds: [],
    name: 'Community Impact Project',
    description:
      '<p>Use this template for a non profit or community outreach organization</p>',
    managerId: null,
    folderId: undefined,
    priorityLevel: 'MEDIUM' as const,
    color: 'gray' as const,
    variables: [...roles, ...textVariables],
    stages: [
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Assessment and Planning',
        color: 'yellow',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 10,
        tasks: [
          {
            name: `Community assessment for ${getWrappedVariableKeyByName('Project name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Community Outreach Officer',
              roles
            ),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Identify project goals and objectives`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Develop project plan and timeline`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 480,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(480),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Secure partnerships and sponsorships`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 480,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(480),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Budget formulation and approval`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Financial Analyst',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Community consultation and feedback for ${getWrappedVariableKeyByName('Project name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Community Outreach Officer',
              roles
            ),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Fundraising and Resourcing',
        color: 'blue',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 20,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Plan and execute fundraising events`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Apply for grants and sponsorships`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Develop and distribute marketing materials`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Marketing Coordinator',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Donors and stakeholders kick-off for ${getWrappedVariableKeyByName('Project name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Manage online fundraising campaigns`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Marketing Coordinator',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Secure donations and resources`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Volunteer Recruitment',
        color: 'purple',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 15,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Develop volunteer recruitment plan`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Volunteer Coordinator',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Volunteer information session for ${getWrappedVariableKeyByName('Project name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Volunteer Coordinator',
              roles
            ),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Conduct volunteer interviews and selection`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Volunteer Coordinator',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Train volunteers in project activities and goals`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Volunteer Coordinator',
              roles
            ),
            duration: 960,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(960),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Assign volunteers to specific tasks and teams`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Project Implementation',
        color: 'green',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 30,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Launch project activities`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Create Specific Project Implementation Tasks`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Prepare project reports and goal tracking`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Progress update on ${getWrappedVariableKeyByName('Project name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Project Wrap-Up',
        color: 'pink',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 25,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Prepare financial reports`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Financial Analyst',
              roles
            ),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Finalize project assessment`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Project name', textVariables)} - Organize project closing ceremony`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Program Director',
              roles
            ),
            duration: 480,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(480),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
    ],
  } satisfies CreateProjectDefinitionSchemaWithoutWorkspaceFields
}
