import { CalendarSolid } from '@motion/icons'
import { StageAdjusterStrategy } from '@motion/shared/flows'
import { Button } from '@motion/ui/base'
import { stageAdjusterFn, type StageArg } from '@motion/ui-logic/pm/project'
import { getDateButtonText } from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'

import { DeadlineDropdown } from '~/areas/project-management/components'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import {
  useSetupProjectDateRangeColors,
  useSetupProjectForm,
} from '../../../hooks'

export type Props = {
  stageDueDatesPath: `stageDueDates.${number}`
  isError?: boolean
}

export const ControlledStageDeadlineField = ({
  stageDueDatesPath,
  isError: isErrorProp,
}: Props) => {
  const { form } = useSetupProjectForm()

  const { control, watch, getValues, setValue } = form

  const { field } = useController({
    name: `${stageDueDatesPath}.dueDate`,
    control,
  })

  const projectId = watch('projectId')
  const stageDueDates = watch('stageDueDates')

  const stageDueDate = watch(stageDueDatesPath)
  const { dueDate: currentValue, stageDefinitionId } = stageDueDate

  const dateButtonString = getDateButtonText(currentValue, {
    placeholder: 'None',
  })

  const index = stageDueDates.findIndex(
    (stage) => stage.stageDefinitionId === stageDefinitionId
  )

  const isError =
    isErrorProp || doesLaterStageHaveEarlierDueDate(stageDueDates, index)

  const projectStart = watch('startDate')
  const projectDeadline = watch('dueDate')

  const additionalQuickActions = useMemo(
    () => [
      {
        label: 'Project start',
        value: DateTime.fromISO(projectStart),
      },
      {
        label: 'Project deadline',
        value: DateTime.fromISO(projectDeadline),
      },
    ],
    [projectDeadline, projectStart]
  )

  const stageDateRangeColors = useSetupProjectDateRangeColors()

  return (
    <DeadlineDropdown
      value={currentValue}
      onChange={(value) => {
        if (value == null) return
        const date = parseDate(value).toISODate()
        stageAdjusterFn({
          params: {
            startDate: getValues('startDate'),
            dueDate: getValues('dueDate'),
            stageDueDates: getValues('stageDueDates'),
          },
          onAction: (adjuster) => {
            adjuster.prepareStageAdjustment({
              strategy: {
                before: StageAdjusterStrategy.ACCORDION,
                after: StageAdjusterStrategy.SHIFT,
              },
              stageDefinitionId,
              value,
            })
          },
          onResult: ({ startDate, dueDate, stageDueDates }) => {
            if (stageDueDates[index]) {
              stageDueDates[index].dueDate = date
            }
            setValue('startDate', startDate)
            setValue('stageDueDates', stageDueDates)
            setValue('dueDate', dueDate)
            field.onChange(date)
          },
        })
      }}
      hideTimeOptions
      disableClearing
      dropdownTarget='stage'
      contextProps={{
        projectId,
        stageDefinitionId,
      }}
      additionalQuickActions={additionalQuickActions}
      overrides={{
        stageDateRangeColors,
      }}
    >
      <Button
        variant='outlined'
        sentiment={isError ? 'error' : 'neutral'}
        size='small'
      >
        <div className='text-nowrap text-left font-normal flex flex-row items-center gap-[6px] min-w-[90px]'>
          <CalendarSolid />
          {dateButtonString}
        </div>
      </Button>
    </DeadlineDropdown>
  )
}

function doesLaterStageHaveEarlierDueDate(
  stageDueDates: StageArg[],
  index: number
): boolean {
  return stageDueDates.some(
    (stage, i) =>
      i > index &&
      stage.dueDate != null &&
      stage.dueDate < stageDueDates[index].dueDate
  )
}
