import { INDIVIDUAL_DEFAULT_TRIAL_LENGTH } from '~/areas/onboarding/constants'

import { type UseOnboardingVideoPayload } from './types'
import { useExperiment } from './useHasExperiment'

const DEFAULT_ONBOARDING_VIDEO_PAYLOAD = {
  videoId: 'no-1For9tSU',
  text: 'Watch this 1min video on how you can save hours each week using Motion',
}

export const useOnboardingVideo = (): UseOnboardingVideoPayload => {
  const { payload } = useExperiment('onboarding-video')
  return {
    ...DEFAULT_ONBOARDING_VIDEO_PAYLOAD,
    ...payload,
  }
}

export const useOnboardingIframeUrl = (page: string): string => {
  const payload = useExperiment('onboarding-iframes').payload as
    | { [key: string]: { url: string } }
    | undefined
  return payload?.[page]?.url ?? ''
}

export const useTrialLength = (): number => {
  const payload = useExperiment('stripe-trial-length').payload as
    | { days: number }
    | undefined
  return payload?.days ?? INDIVIDUAL_DEFAULT_TRIAL_LENGTH
}
