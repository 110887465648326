import { AttachmentOutline } from '@motion/icons'
import { templateStr } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useGetProjectTaskAttachments } from '~/areas/attachments/hooks'
import { type AttachmentsModalProps } from '~/areas/attachments/modals'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type MouseEvent } from 'react'

import { CellAction } from './components'

type AttachmentsCellProps = Pick<
  AttachmentsModalProps,
  'targetId' | 'targetType'
> & {
  targetName: string
  count?: number
  derivedCount?: number
}

export const AttachmentsCell = ({
  targetId,
  targetType,
  targetName,
  count,
  derivedCount,
}: AttachmentsCellProps) => {
  const modalsApi = useModalApi()

  if (!count) return null

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    modalsApi.open('attachments', {
      targetId,
      targetType,
    })
  }

  return (
    <CellAction
      onClick={handleClick}
      aria-label={templateStr('View attachments for {{entity}} {{name}}', {
        entity: targetType === 'PROJECT' ? 'project' : 'task',
        name: targetName,
      })}
    >
      <AttachmentOutline
        width={16}
        height={16}
        className='text-semantic-neutral-icon-subtle'
      />
      {count}
      {derivedCount && ` (${derivedCount})`}
    </CellAction>
  )
}

export type ProjectAttachmentsCellProps = {
  item: ProjectWithRelations
}

export function ProjectAttachmentsCell({ item }: ProjectAttachmentsCellProps) {
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')
  const attachments = useGetProjectTaskAttachments({
    targetId: item.id,
    targetType: 'PROJECT',
  })

  const count = hasFlowsAttachments
    ? attachments.length
    : item.uploadedFileIds.length

  return (
    <AttachmentsCell
      count={count}
      targetId={item.id}
      targetType='PROJECT'
      targetName={item.name}
    />
  )
}

export type TaskAttachmentsCellProps = {
  item: TaskWithRelations
}

export function TaskAttachmentsCell({ item }: TaskAttachmentsCellProps) {
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')
  const attachments = useGetProjectTaskAttachments({
    targetId: item.id,
    targetType: 'TEAM_TASK',
  })

  if (item.type !== 'NORMAL') return null

  const count = hasFlowsAttachments
    ? attachments.length
    : item.uploadedFileIds?.length

  return (
    <AttachmentsCell
      count={count}
      targetId={item.id}
      targetType='TEAM_TASK'
      targetName={item.name}
    />
  )
}
