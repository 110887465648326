import { useActiveFilter } from '@motion/ui-logic/pm/data'

import { useSortSections } from './use-sort-sections'
import { SORT_NONE } from './utils'

import { useRecordAnalyticsSortEvent } from '../../analytics'
import {
  SortButtonGroup,
  type SortButtonGroupProps,
} from '../../header/sort-button'
import { useViewState } from '../../view-state'

export const ConnectedSortButton = () => {
  const [viewState, setViewState] = useViewState()
  const [filterState] = useActiveFilter()
  const recordAnalyticsSortEvent = useRecordAnalyticsSortEvent()

  const sections = useSortSections()

  const onChange: SortButtonGroupProps['onChange'] = (value) => {
    setViewState((prev) => ({
      ...prev,
      sortBy:
        value === SORT_NONE.id
          ? null
          : {
              field: value,
              direction: 'asc',
            },
    }))

    recordAnalyticsSortEvent(value, 'asc')
  }

  const onChangeDirection = () => {
    if (viewState.sortBy == null) return

    setViewState((prev) => ({
      ...prev,
      sortBy:
        prev.sortBy != null
          ? {
              field: prev.sortBy.field,
              direction: prev.sortBy.direction === 'asc' ? 'desc' : 'asc',
            }
          : null,
    }))

    recordAnalyticsSortEvent(undefined, viewState.sortBy.direction)
  }

  const fieldValue = viewState.sortBy?.field ?? ''
  const direction = viewState.sortBy?.direction
  // Hide sort button if we are stacking projects and grouping by fields
  const ganttDisabled =
    viewState.page === 'gantt' &&
    viewState.groupBy.stackProjects &&
    viewState.groupBy.fields.length > 0

  return (
    <SortButtonGroup
      value={fieldValue}
      sections={sections}
      onChange={onChange}
      onChangeDirection={onChangeDirection}
      direction={direction}
      filterTarget={filterState.target}
      disabled={ganttDisabled}
      disabledTooltip={
        ganttDisabled
          ? 'Stacked projects cannot be sorted in Gantt view'
          : undefined
      }
    />
  )
}
