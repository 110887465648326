import { CalendarSolid, XSolid } from '@motion/icons'
import { IconButton } from '@motion/ui/base'

import { TimeDropdown } from '~/areas/project-management/components'
import {
  mergeDateWithTime,
  TIME_FORMAT,
} from '~/areas/project-management/utils'
import { DateTime } from 'luxon'

type DateDropdownHeaderProps = {
  value: DateTime | null
  formatDateDisplay: (date: DateTime) => string
  disableClearing: boolean
  onChange: (value: string | null) => void
  showTimeOptions: boolean
  renderSuffix?: () => React.ReactNode
}

export function DateDropdownHeader({
  value,
  formatDateDisplay,
  disableClearing,
  onChange: onChangeFromProps,
  showTimeOptions,
  renderSuffix,
}: DateDropdownHeaderProps) {
  return (
    <div className='border-b border-dropdown-border px-3 pb-2 flex items-center gap-1 justify-between'>
      <div className='flex flex-row items-center gap-1 text-semantic-neutral-text-default'>
        <CalendarSolid className='w-4 h-4 text-semantic-neutral-icon-subtle' />
        {value ? formatDateDisplay(value) : 'None'}
        {value && !disableClearing && (
          <IconButton
            icon={XSolid}
            variant='muted'
            sentiment='neutral'
            size='xsmall'
            onClick={() => onChangeFromProps(null)}
          />
        )}
      </div>
      {value && showTimeOptions && (
        <TimeDropdown
          value={value.toFormat(TIME_FORMAT)}
          onChange={(newValue) => {
            onChangeFromProps(
              mergeDateWithTime(
                value,
                DateTime.fromFormat(newValue, TIME_FORMAT)
              ).toISO()
            )
          }}
        >
          <input
            type='text'
            placeholder='Add time'
            className='w-[70px] focus:outline-0 bg-transparent text-semantic-neutral-text-default'
            value={value.toFormat(TIME_FORMAT).toLocaleLowerCase()}
            onChange={() => null}
          />
        </TimeDropdown>
      )}
      {renderSuffix?.()}
    </div>
  )
}
