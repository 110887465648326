import { type IdFilterSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useFieldFilter } from './context'

export function useWorkspaceFilter<T extends { workspaceId: string }>() {
  const [filter] = useFieldFilter('workspaces', 'ids')
  return useCallback(
    (item: T) => {
      if (filter == null) return true
      if (filter.value.length === 0) return true
      return filter.value.includes(item.workspaceId)
    },
    [filter]
  )
}

export function buildIdFilter<T extends { id: string }>(
  value: T[] | null,
  filter: IdFilterSchema | null
): IdFilterSchema | null {
  return value == null || value.length === 0
    ? null
    : {
        operator: 'in',
        value: value.map((x) => x.id),
        inverse: filter?.inverse ?? false,
      }
}
