import { type CalendarOptions } from '@fullcalendar/core'
import { CalendarEventContainer } from '~/components/Calendar/components/Main/CalendarEvents/CalendarEventContainer'
import { useMemo } from 'react'

import { useCalendarFcEvents } from './use-calendar-fc-events'
import { useMainCalendarHandlers } from './use-main-calendar-handlers'

import { useCalendarCommonProps } from '../../hooks/use-calendar-common-props'

export const useMainCalendarProps = (): CalendarOptions => {
  const commonProps = useCalendarCommonProps()
  const events = useCalendarFcEvents()
  const handlers = useMainCalendarHandlers()

  return useMemo(() => {
    return {
      ...commonProps,
      eventContent: CalendarEventContainer,
      // Triggered when an empty calendar slot is selected by dragging. Clicking an event trigger eventClick.
      select: handlers.onEmptySlotDragEnd,
      // Triggered when an empty calendar slot is clicked.
      dateClick: handlers.onEmptySlotClick,
      events,
    }
  }, [
    commonProps,
    events,
    handlers.onEmptySlotDragEnd,
    handlers.onEmptySlotClick,
  ])
}
