import { PencilSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { AvailabilityQuestionEditorModal } from '~/components/Booking/components/Modals/AvailabilityQuestionEditorModal/AvailabilityQuestionEditorModal'
import { useContext, useState } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const BookingQuestionsRow = () => {
  const [questionsVisible, setQuestionsVisible] = useState(false)

  const {
    template: {
      state: { questions },
      setters: { setQuestions },
    },
  } = useContext(BookingTemplateContext)

  return (
    <>
      <SectionRow
        title='Invitee questions'
        subtitle="If you'd like to ask invitee some questions before they book a time."
      >
        <div>
          <Button
            variant='outlined'
            sentiment='neutral'
            onClick={() => {
              setQuestionsVisible(true)
              recordAnalyticsEvent('CALENDAR_AVAILABILITIES_OPEN_QUESTIONS')
            }}
          >
            <PencilSolid /> Edit Questions
          </Button>
        </div>
      </SectionRow>

      {questionsVisible && (
        <AvailabilityQuestionEditorModal
          visible
          questions={questions}
          saveHandler={setQuestions}
          visibleHandler={setQuestionsVisible}
        />
      )}
    </>
  )
}
