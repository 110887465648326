import { systemData } from '@motion/web-base/env'

import { useEffect, useState } from 'react'

export function useIsShiftKeyPressed() {
  const [isShiftKeyPressed, setIsShiftKeyPressed] = useState(false)

  useEffect(function handleShiftKeyPressAndRelease() {
    const handleKeyDown = (event: KeyboardEvent) => {
      /**
       * On macOS, we don't receive keyUp events while the Meta key is pressed. To avoid
       * the shift key being stuck pressed, we reset it here.
       *
       * More info:
       * - https://stackoverflow.com/q/11818637
       * - https://github.com/JohannesKlauss/react-hotkeys-hook/blob/main/src/isHotkeyPressed.ts
       */
      if (systemData.os === 'macos' && event.metaKey) {
        setIsShiftKeyPressed(false)
      } else if (event.shiftKey) {
        setIsShiftKeyPressed(true)
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.shiftKey) {
        setIsShiftKeyPressed(false)
      }
    }

    const handleWindowBlur = () => {
      setIsShiftKeyPressed(false)
    }

    window.addEventListener('blur', handleWindowBlur)
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('blur', handleWindowBlur)
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return isShiftKeyPressed
}
