import { Sentry } from '@motion/web-base/sentry'

import { useEffect, useMemo } from 'react'

import { type SelectOption } from '../types'

export function useMultiSelectFieldSelectedValues(
  selectedItemIds: string[] | unknown,
  options: SelectOption[]
) {
  // TODO @nmurphy remove after debugging
  useEffect(() => {
    if (!Array.isArray(selectedItemIds)) {
      Sentry.captureException(
        new Error(
          `useMultiSelectFieldSelectedValues: selectedItemIds should be an array`
        ),
        {
          extra: {
            selectedItemIds,
          },
        }
      )
    }
  }, [selectedItemIds])

  return useMemo(() => {
    if (!Array.isArray(selectedItemIds)) {
      return []
    }

    return selectedItemIds
      .map((id) => {
        const match = options.find((l) => l.id === id)
        if (match == null) return null

        return {
          id: match.id,
          name: match.value,
          color: match.color,
        }
      })
      .filter(Boolean)
  }, [selectedItemIds, options])
}
