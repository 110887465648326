import { PopoverButton, SearchableDropdown } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useController } from 'react-hook-form'

import { useFlowTemplateForm } from '../../hooks'

const EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS = [
  { id: 'DAYS', label: 'days' },
  { id: 'WEEKS', label: 'weeks' },
  { id: 'MONTHS', label: 'months' },
]

export const ExpectedDurationUnitSelector = ({
  deadlineIntervalPath,
}: {
  deadlineIntervalPath: `stages.${number}.deadlineInterval`
}) => {
  const {
    form: { control },
  } = useFlowTemplateForm()

  const deadlineIntervalUnitPath = `${deadlineIntervalPath}.unit` as const

  const { field: expectedDurationUnit } = useController({
    name: deadlineIntervalUnitPath,
    control,
  })

  const selectedItem =
    EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS.find(
      (item) => item.id === expectedDurationUnit.value
    ) ?? EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS[0]

  return (
    <ExpectedDurationUnitDropdown
      selectedItem={selectedItem}
      onChange={(item) => {
        expectedDurationUnit.onChange(item.id)
        recordAnalyticsEvent('FLOW_TEMPLATE_STAGE_DURATION_OPTION_SELECTED', {
          option: item.id,
        })
      }}
    >
      <PopoverButton className='w-min h-6'>
        <span className='text-semantic-neutral-text-default'>
          {selectedItem.label}
        </span>
      </PopoverButton>
    </ExpectedDurationUnitDropdown>
  )
}

const ExpectedDurationUnitDropdown = ({
  selectedItem,
  onChange,
  children,
}: {
  selectedItem: (typeof EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS)[number]
  onChange: (
    item: (typeof EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS)[number]
  ) => void
  children: React.ReactNode
}) => {
  return (
    <SearchableDropdown
      items={EXPECTED_DURATION_UNIT_DROPDOWN_ITEMS}
      selectedItem={selectedItem}
      renderItem={(item) => <span>{item.label}</span>}
      onChange={onChange}
    >
      {children}
    </SearchableDropdown>
  )
}
