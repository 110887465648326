import { ActionList } from '@motion/ui/base'

import { useTextVariableActionSections } from './use-text-variable-action-sections'

export type TextVariablePopoverContentProps = {
  close: () => void
  name: string
  variableKey: string
}

export const TextVariablePopoverContent = ({
  close,
  name,
  variableKey,
}: TextVariablePopoverContentProps) => {
  const sections = useTextVariableActionSections({ name, variableKey })

  return <ActionList sections={sections} onActionAnyItem={close} />
}
