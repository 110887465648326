import {
  AllTasksGradientSolid,
  MyTasksGradientSolid,
  ProjectGradientSolid,
  type SvgIcon,
} from '@motion/icons'
import { classed } from '@motion/theme'

import { ConnectedFolderBreadcrumbs } from '~/areas/folders/components'
import type {
  PMPageUrlParams,
  RouteData,
} from '~/areas/project-management/pages/pm-v3/routes/types'
import { useLookup } from '~/global/cache'
import { MotionLink } from '~/global/components'
import {
  type BadgeSize,
  getBadgeSizeInPixels,
} from '~/global/components/badges'
import { BreadcrumbsLabel } from '~/global/components/labels'
import { useUriByRouteId } from '~/routing'
import { useParams } from 'react-router-dom'

type HeaderBreadcrumbsProps = {
  page: RouteData['page']
}

export function HeaderBreadcrumbs({ page }: HeaderBreadcrumbsProps) {
  switch (page) {
    case 'workspace':
    case 'folder':
    case 'project':
      return <ConnectedWorkspaceFolderProjectBreadcrumbs />

    case 'my-tasks':
    case 'all-tasks':
    case 'all-projects':
      return <PageBreadcrumbs page={page} />
    default:
      return null
  }
}

type PageBreadcrumbsProps = {
  page: 'all-tasks' | 'all-projects' | 'my-tasks'
}

function PageBreadcrumbs({ page }: PageBreadcrumbsProps) {
  const { icon, label } = getIconAndLabelFromPage(page)

  return (
    <BreadcrumbRow>
      <BreadcrumbsLabel
        clickable
        size='small'
        crumbs={[
          {
            type: 'custom',
            value: (
              <PageLabel
                size='small'
                icon={icon}
                label={label}
                routeId={page}
              />
            ),
          },
        ]}
      />
    </BreadcrumbRow>
  )
}

function getIconAndLabelFromPage(page: PageBreadcrumbsProps['page']) {
  if (page === 'all-tasks') {
    return { icon: AllTasksGradientSolid, label: 'All Tasks' }
  }
  if (page === 'my-tasks') {
    return { icon: MyTasksGradientSolid, label: 'My Tasks' }
  }
  if (page === 'all-projects') {
    return { icon: ProjectGradientSolid, label: 'All Projects' }
  }

  throw new Error('Unknown page for breadcrumbs')
}

function ConnectedWorkspaceFolderProjectBreadcrumbs() {
  const lookup = useLookup()
  const { workspaceId, folderId, projectId } = useParams<PMPageUrlParams>()

  const leafNodeId =
    projectId ??
    folderId ??
    lookup('folders').find((item) => item.targetId === workspaceId)?.id
  if (!leafNodeId) return null
  return (
    <BreadcrumbRow>
      <ConnectedFolderBreadcrumbs
        leafNodeId={leafNodeId}
        size='small'
        clickable
      />
    </BreadcrumbRow>
  )
}

type PageLabelProps = {
  icon: SvgIcon
  label: string
  size: BadgeSize
  routeId: 'all-tasks' | 'my-tasks' | 'all-projects'
}

function PageLabel({ icon: Icon, label, size, routeId }: PageLabelProps) {
  const getRouteUri = useUriByRouteId({ noDefaults: true })

  const url = getRouteUri(routeId)

  const pixelSize = getBadgeSizeInPixels(size)
  return (
    <MotionLink url={url} className='overflow-hidden'>
      <span className='inline-flex gap-1 items-center overflow-hidden leading-4'>
        <Icon height={pixelSize} width={pixelSize} />
        {label}
      </span>
    </MotionLink>
  )
}

const BreadcrumbRow = classed(
  'div',
  `
  flex flex-row gap-1
  text-2xs
  text-semantic-neutral-text-subtle

  [&_a]:px-1
  [&_a]:rounded
  [&_a:hover]:text-inherit
  [&_a:hover]:bg-semantic-neutral-surface-bg-subtlest
`
)
