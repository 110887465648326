import { classed } from '@motion/theme'
import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { range } from '@motion/utils/array'

import { useAvailableGroupByFields } from '../components'
import { isViewStateGroupByDateKey, noneField } from '../grouping'
import { RowFieldSelect } from '../header'
import { useViewState, type ViewState } from '../view-state'

export const KanbanNoGroupsState = () => {
  const [, setViewState] = useViewState()
  const [filterState] = useActiveFilter()

  const getAvailableGroupByFields = useAvailableGroupByFields()

  const options = getAvailableGroupByFields(filterState.target)
  const numColumns = 8

  return (
    <div className='p-4 h-full w-full flex flex-row items-start gap-2'>
      <SkeletonColumn>
        <p className='text-semantic-neutral-text-subtle text-xs font-semibold'>
          Select field to group by
        </p>
        <RowFieldSelect
          available={[noneField, ...options]}
          selected={noneField}
          onSelect={(item) => {
            setViewState((prev) => {
              const key = item.id
              const by = isViewStateGroupByDateKey(key) ? 'day' : undefined

              return {
                ...prev,
                groupBy: {
                  ...prev.groupBy,
                  fields: [{ key, by }],
                },
              } satisfies ViewState
            })
          }}
        />
      </SkeletonColumn>
      {range(numColumns).map((_, i) => (
        <SkeletonColumn key={i} />
      ))}
    </div>
  )
}

const SkeletonColumn = classed('div', {
  base: `
      flex flex-col
      h-full w-[282px]
      bg-semantic-neutral-surface-bg-subtlest
      gap-2 rounded p-3
    `,
})
