import { type ProjectSchema } from '@motion/zod/client'

import { useAgendaTaskActions } from '~/areas/agenda/hooks'

import { TaskItemActionButton } from './task-item-action-button'

type OpenProjectButtonProps = {
  projectId: ProjectSchema['id']
}

export function OpenProjectButton({ projectId }: OpenProjectButtonProps) {
  const { openProject } = useAgendaTaskActions({
    taskId: '',
    projectId,
  })

  return (
    <TaskItemActionButton action='open-project' onClick={openProject}>
      Open project
    </TaskItemActionButton>
  )
}
