import { logInDev } from '@motion/web-base/logging'

export type Func = (...args: any[]) => any

const registeredActions = new Map<string, Func>()

export const createActionString = (method: string, prefix?: string) => {
  return prefix ? `${prefix}-${method}` : method
}

export const isActionRegistered = (method: string, action?: string) => {
  return registeredActions.has(createActionString(method, action))
}

/**
 * Executes a registered function. The function handle synchronous and
 * asynchronous return types appropriately, and will always return a promise.
 *
 * @param action
 * @param args
 * @returns
 */
export const fire = async (action: string, args: any[]): Promise<any> => {
  if (!registeredActions.has(action)) {
    throw new Error(`Action ${action} not registered!`)
  }

  logInDev(`Firing ${action} with args`, args)
  // @ts-expect-error typescript can't figure out the the check above checks if it's defined.
  return registeredActions.get(action)(...args)
}
