import { CalendarSolid } from '@motion/icons'
import { type ProjectSchema } from '@motion/rpc-types'
import { Button, PopoverTrigger } from '@motion/ui/base'
import {
  type ETADateOptions,
  getEtaDateOptions,
  normalizeProjectDeadlineStatus,
  templateStr,
} from '@motion/ui-logic'
import { safeParseDate } from '@motion/utils/dates'

import { useProjectDeadlineUpdater } from '~/areas/project/hooks'
import { useMemo, useState } from 'react'

import { ProjectEtaActions } from './eta-actions'
import { ProjectExtendDeadlineRow } from './extend-deadline-row'
import { type ProjectEtaPopoverContentProps } from './types'

import { useProjectStageToShow } from '../../../hooks'
import {
  OptimizeProjectButton,
  ProjectedDateOptions,
  ReviewProjectDatesButton,
} from '../../common'
import { EtaBanner } from '../banner'
import { SHOW_DATE_OPTION_STATUSES } from '../constants'
import { OverdueTasksSection } from '../overdue-tasks'
import { StageEtaPopoverContent } from '../stage'
import { PopoverContainer } from '../styled'
import { type CommonPopoverProps } from '../types'
import { shouldShowReviewDatesButton } from '../utils'

export const ProjectEtaPopover = ({
  entity,
  children,
  config = {},
}: CommonPopoverProps<ProjectSchema>) => {
  // In the instance that the project is at risk, we should instead show
  // the first stage that is missed-deadline, or otherwise the first stage that is
  // scheduled-past-deadline.
  const stageToShow = useProjectStageToShow(entity)

  return (
    <PopoverTrigger
      renderPopover={({ close }) => {
        if (stageToShow) {
          return (
            <StageEtaPopoverContent
              project={entity}
              stage={stageToShow}
              onClose={close}
            />
          )
        }

        const deadlineStatus = normalizeProjectDeadlineStatus(entity)
        if (deadlineStatus === 'none') return null

        return (
          <ProjectEtaPopoverContent
            project={entity}
            onClose={close}
            config={config}
          />
        )
      }}
    >
      {children}
    </PopoverTrigger>
  )
}

export const ProjectEtaPopoverContent = (
  props: ProjectEtaPopoverContentProps
) => {
  const { project, onClose } = props

  const [selectedOption, setSelectedOption] =
    useState<ETADateOptions>('projectedDate')
  const deadlineStatus = normalizeProjectDeadlineStatus(project)
  const updateProject = useProjectDeadlineUpdater()

  const projectedDate = project.estimatedCompletionTime
    ? project.estimatedCompletionTime
    : null

  const dateOptions = useMemo(() => {
    if (!projectedDate) return null

    return getEtaDateOptions(projectedDate)
  }, [projectedDate])

  const updateDateToSelectedOption = () => {
    if (!dateOptions || !dateOptions[selectedOption]) return

    const optionDate = dateOptions[selectedOption]
    const parsedDate = safeParseDate(optionDate)
    if (!parsedDate) return

    updateProject(project, parsedDate.toISO())
    onClose()
  }

  return (
    <PopoverContainer>
      <EtaBanner entity={project} type='project' />
      {project.estimatedCompletionTime &&
        dateOptions &&
        SHOW_DATE_OPTION_STATUSES.includes(deadlineStatus) && (
          <div className='p-3 flex flex-col gap-3'>
            <ProjectExtendDeadlineRow {...props} />
            <ProjectedDateOptions
              options={dateOptions}
              selectedOption={selectedOption}
              onChooseOption={setSelectedOption}
            />
            <div className='flex justify-end gap-2'>
              {shouldShowReviewDatesButton(project) && (
                <ReviewProjectDatesButton
                  project={project}
                  sentiment='primary'
                  variant='muted'
                  size='small'
                />
              )}
              <Button
                sentiment='primary'
                variant='solid'
                onClick={updateDateToSelectedOption}
                size='small'
              >
                Extend deadline
              </Button>
            </div>
          </div>
        )}
      {(deadlineStatus === 'missed-deadline' ||
        deadlineStatus === 'scheduled-past-deadline') && (
        <OverdueTasksSection project={project} onClose={onClose} />
      )}
      {deadlineStatus === 'ahead-of-schedule' && (
        <div className='p-3'>
          <div className='flex items-start gap-2'>
            <CalendarSolid className='size-4 min-w-[16px] text-semantic-neutral-icon-default' />
            <div className='flex flex-col gap-1'>
              <span className='text-semantic-neutral-text-default text-xs font-medium'>
                {templateStr('Optimize project {{hasStages}}', {
                  hasStages:
                    project.stages.length > 0
                      ? '& stage deadlines'
                      : 'deadline',
                })}
              </span>
              <span className='text-semantic-neutral-text-subtle text-2xs font-normal'>
                Motion will automatically choose dates to ensure your project
                gets done faster.
              </span>
            </div>
            <div className='ml-auto'>
              <OptimizeProjectButton
                sentiment='primary'
                variant='solid'
                size='xsmall'
                project={project}
              />
            </div>
          </div>
        </div>
      )}
      <ProjectEtaActions {...props} />
    </PopoverContainer>
  )
}
