import { merge } from '@motion/utils/core'

import {
  defaultSidebarState,
  type SidebarState,
  useSidebarState,
} from '~/global/hooks/sidebars'
import { useCallback, useMemo } from 'react'

import type { BladeState } from '../right-sidebar/components'

type CalendarSidebarState = SidebarState & {
  sections: {
    [key: string]: boolean
    mine: boolean
    frequent: boolean
    accounts: boolean
  }
  blades: {
    agenda: BladeState
  }
}

const defaultCalendarSidebarState: CalendarSidebarState = {
  ...defaultSidebarState,
  sections: { mine: true, frequent: true, accounts: true },
  blades: {
    agenda: 'peek',
  },
}

export const useCalendarSidebarState = () => {
  const [localStorageState, setSidebarState] =
    useSidebarState<CalendarSidebarState>('calendar')

  const sidebarState = useMemo(
    () =>
      merge(
        {},
        defaultCalendarSidebarState,
        localStorageState
      ) as CalendarSidebarState,
    [localStorageState]
  )

  const toggleSidebar = useCallback(
    (newOpenState: boolean = !sidebarState.open) => {
      setSidebarState({
        open: newOpenState,
      })
    },
    [setSidebarState, sidebarState.open]
  )

  const toggleSidebarSection = useCallback(
    (
      section: Exclude<keyof typeof sidebarState.sections, number>,
      newOpenState?: boolean
    ) => {
      setSidebarState((currentState) => ({
        sections: {
          ...currentState.sections,
          [section]: newOpenState ?? !currentState.sections[section] ?? true,
        },
      }))
    },
    [setSidebarState, sidebarState]
  )

  return {
    sidebarState,
    setSidebarState,
    toggleSidebar,
    toggleSidebarSection,
  }
}
