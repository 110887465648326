import { type AttendeeStatus } from '@motion/zod/client'

export function getRsvpLabel(rsvp: AttendeeStatus) {
  switch (rsvp) {
    case 'needsAction':
      return 'Needs Action'
    case 'declined':
      return 'Declined'
    case 'tentative':
      return 'Maybe'
    case 'accepted':
      return 'Accepted'
  }
}
