import { twMerge } from 'tailwind-merge'

type SubParagraphProps = {
  className?: string
}

export const SubParagraph = ({
  className = '',
  children,
}: SubParagraphProps & JSX.IntrinsicElements['p']) => {
  const colorClasses = 'text-light-1100 dark:text-dark-300'

  return (
    <p className={twMerge('!mb-0 text-xs', colorClasses, className)}>
      {children}
    </p>
  )
}
