import { PencilSolid } from '@motion/icons'
import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { focusFirstParentFocusableNode } from '@motion/ui/utils'
import {
  addSchemeIfNeeded,
  getDisplayableLink,
  type TextCustomField,
} from '@motion/ui-logic'

import {
  CustomField,
  type CustomFieldInputProps,
  validateUrl,
} from '~/areas/project-management/custom-fields'
import { type FC, useRef, useState } from 'react'

import { CellText } from '../../components'
import { type ControlledCustomFieldProps } from '../types'

export const ControlledUrlCell: FC<
  ControlledCustomFieldProps<TextCustomField>
> = ({ value, onSubmit: submitHandler, customField, disabled }) => {
  const ref = useRef<HTMLLabelElement>(null)
  const [isDirty, setIsDirty] = useState(false)
  const [internalValue, setInternalValue] = useDependantState(
    () => value,
    [value],
    { freezeDependencyUpdates: isDirty }
  )
  const [isEditing, setIsEditing] = useState(internalValue ? false : true)

  const onChange = (v: string) => {
    setInternalValue(v)
  }

  const onSubmit = useDebouncedCallback(async () => {
    if (internalValue === value) {
      setIsDirty(false)
      setIsEditing(!value)
      return
    }

    let parsedValue = internalValue
    if (parsedValue) {
      parsedValue = addSchemeIfNeeded(parsedValue)
    }

    try {
      await submitHandler(parsedValue)
      const isValidUrl = validateUrl(parsedValue)
      setIsEditing(isValidUrl ? false : true)

      // Focus on the cell if input is still focused

      if (ref.current != null && ref.current.contains(document.activeElement)) {
        focusFirstParentFocusableNode(ref.current)
      }
    } catch (error) {
      setInternalValue(value)
    } finally {
      setIsDirty(false)
    }
  }, 400)

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key } = evt

    if (key === 'Enter') {
      return onSubmit()
    }
  }

  if (!isEditing) {
    return (
      <CellText withHover isFake={disabled}>
        <a
          className={`flex items-center px-2 py-1 gap-2 text-xs font-normal truncate
          hover:text-semantic-primary-text-default text-semantic-neutral-text-default w-full`}
          href={internalValue ? internalValue : undefined}
          target='_blank'
          rel='noopener noreferrer'
        >
          {internalValue ? getDisplayableLink(internalValue) : 'None'}
        </a>
        <div
          className='p-1'
          tabIndex={0}
          onClick={(e) => {
            e.preventDefault()
            setIsEditing(true)
          }}
        >
          <PencilSolid
            className='text-semantic-neutral-icon-subtle cursor-pointer'
            height={16}
            width={16}
          />
        </div>
      </CellText>
    )
  }

  return (
    <CellText withHover isFake={disabled}>
      <CustomField.Url
        ref={ref}
        name={customField.definition.name}
        value={internalValue ?? ''}
        onFocus={() => setIsDirty(true)}
        onChange={onChange}
        onBlur={onSubmit}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        hideIcon
      />
    </CellText>
  )
}
