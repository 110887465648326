import {
  ExclamationCircleSolid,
  ExternalLinkOutline,
  QuestionMarkCircleSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, showToast, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { LoadingSvg } from '~/components/Common/Icons/LoadingSvg'
import { selectUrlPrefix } from '~/state/booking'
import { useAppSelector } from '~/state/hooks'
import { createFullTemplateUrl } from '~/utils/booking-utils'
import { type PropsWithChildren, useCallback, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'

import { AvailabilityAttendanceRequirementsRow } from './components/availability-attendance-requirements-row'
import { AvailabilityBufferTimeRow } from './components/availability-buffer-time-row'
import { AvailabilityDateRangeRow } from './components/availability-date-range-row'
import { AvailabilityLimitMeetingsRow } from './components/availability-limit-meetings-row'
import { AvailabilityScheduleRow } from './components/availability-schedule-row'
import { BookingLinkRow } from './components/booking-link-row'
import { BookingQuestionsRow } from './components/booking-questions-row'
import { BookingReminderRow } from './components/booking-reminder-row'
import { EventDurationRow } from './components/event-duration-row'
import { EventGuestsRow } from './components/event-guests-row'
import { EventHostRow } from './components/event-host-row'
import { EventLocationRow } from './components/event-location-row'
import { EventNameRow } from './components/event-name-row'

import { BookingTemplateContext } from '../../booking-template-context'
import { useBookingLoadingState } from '../../hooks'
import { useBookingTemplateBySlug } from '../../hooks/use-booking-template-by-slug'
import { useBookingTemplateIdBySlug } from '../../hooks/use-booking-template-id-by-slug'
import { BookingShell } from '../../shell'

export const BookingEditLinkPage = () => {
  const { slug } = useParams()
  const isBookingReady = useBookingLoadingState()
  const templateId = useBookingTemplateIdBySlug(slug)

  const isTemplateMatched = !!slug && !!templateId
  const isTemplateNew = !slug

  const PageShell = useCallback(
    ({ children }: PropsWithChildren) => (
      <BookingShell
        heading={`${isTemplateNew ? 'Create' : 'Edit'} booking link`}
        showBackButton
      >
        {children}
      </BookingShell>
    ),
    [isTemplateNew]
  )

  if (!isBookingReady) {
    return (
      <PageShell>
        <div className='flex h-full w-full items-center justify-center'>
          <LoadingSvg />
        </div>
      </PageShell>
    )
  }

  if (!isTemplateNew && !isTemplateMatched) {
    return (
      <PageShell>
        <div className='w-full h-full flex items-center justify-center'>
          <div className='flex flex-col gap-4 text-center'>
            <div className='m-auto'>
              <QuestionMarkCircleSolid className='size-10' />
            </div>
            <p className='font-semibold text-lg'>{`This booking link doesn't exist`}</p>
            <p className='text-semantic-neutral-text-subtle'>
              It may have been deleted.
            </p>
            <div className='m-auto'>
              <Button
                variant='outlined'
                sentiment='neutral'
                url='/web/calendar?booking'
              >
                Back to booking
              </Button>
            </div>
          </div>
        </div>
      </PageShell>
    )
  }

  return (
    <PageShell>
      <Page slug={isTemplateNew ? '' : slug} isTemplateNew={isTemplateNew} />
    </PageShell>
  )
}

type PageProps = {
  slug: string
  isTemplateNew: boolean
}

const Page = ({ slug, isTemplateNew }: PageProps) => {
  const navigate = useNavigate()
  const hostRef = useRef<HTMLDivElement | null>(null)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const individualUrlPrefix = useAppSelector(selectUrlPrefix)
  const bookingTemplate = useBookingTemplateBySlug({
    slug,
    scrollContainerRef,
  })

  const {
    hostCalendar,
    template: { state, setters, submitHandler },
  } = bookingTemplate

  const isHostCalendarMissing = !hostCalendar
  const urlPrefix = individualUrlPrefix ?? ''

  const handleViewBookingPage = () => {
    window.open(
      createFullTemplateUrl(urlPrefix, state.linkSlug),
      '_blank',
      'noopener'
    )

    recordAnalyticsEvent('CALENDAR_AVAILABILITIES_TEMPLATE_OPEN_LINK')
  }

  const handleSave = async () => {
    const success = await submitHandler()

    if (!success) return

    showToast(
      'success',
      `Booking link ${isTemplateNew ? 'created' : 'saved'} successfully`
    )

    if (isTemplateNew) {
      navigate(
        {
          pathname: `/web/calendar/booking/${state.linkSlug}`,
        },
        {
          replace: true,
        }
      )
    }
  }

  return (
    <BookingTemplateContext.Provider value={bookingTemplate}>
      <div className='text-semantic-neutral-text-default border-b-semantic-neutral-border-default border-b px-6 py-3.5'>
        <Container className='flex flex-row items-center justify-between'>
          <div className='flex items-center gap-2'>
            <div className='inline-block w-[250px]'>
              <TextField
                autoFocus={isTemplateNew}
                onChange={(value) => void setters.setName(value)}
                placeholder='Template name (only you see this)'
                value={state.name}
                fullWidth
              />
            </div>

            {isHostCalendarMissing && (
              <Tooltip
                content='This booking link is missing a host calendar.'
                asChild
              >
                <ExclamationCircleSolid
                  className='text-semantic-error-icon-default size-5 cursor-pointer'
                  onClick={() =>
                    hostRef.current?.scrollIntoView({ behavior: 'smooth' })
                  }
                />
              </Tooltip>
            )}
          </div>

          <div className='flex gap-2'>
            {state.bookingLinkId && (
              <Button
                sentiment='neutral'
                variant='muted'
                onClick={handleViewBookingPage}
              >
                View booking page{' '}
                <ExternalLinkOutline className='!size-[16px]' />
              </Button>
            )}

            <Button sentiment='neutral' url='/web/calendar'>
              Cancel
            </Button>

            <Button
              sentiment='primary'
              loading={state.loading}
              disabled={isHostCalendarMissing}
              onClick={handleSave}
            >
              {isTemplateNew ? 'Create template' : 'Save changes'}
            </Button>
          </div>
        </Container>
      </div>

      {state.errorMessage && (
        <div className='bg-semantic-error-bg-default text-semantic-error-text-default border-semantic-error-border-default -mt-px border-y px-6 py-2'>
          <Container>{state.errorMessage}</Container>
        </div>
      )}

      <div className='overflow-auto pt-4 pb-14 px-6 space-y-12'>
        <Container>
          <SectionHeading>Availability</SectionHeading>
          <SectionContainer>
            <AvailabilityScheduleRow />
            <AvailabilityDateRangeRow />
            <AvailabilityAttendanceRequirementsRow />
            <AvailabilityBufferTimeRow />
            <AvailabilityLimitMeetingsRow />
          </SectionContainer>
        </Container>

        <Container>
          <SectionHeading>Event Details</SectionHeading>
          <SectionContainer>
            <EventNameRow />
            <EventDurationRow />
            <EventLocationRow />
            <EventHostRow hostRef={hostRef} />
            <EventGuestsRow />
          </SectionContainer>
        </Container>

        <Container>
          <SectionHeading>Booking Link</SectionHeading>
          <SectionContainer>
            <BookingLinkRow />
            <BookingReminderRow />
            <BookingQuestionsRow />
          </SectionContainer>
        </Container>
      </div>
    </BookingTemplateContext.Provider>
  )
}

const Container = classed('div', 'max-w-[1000px] mx-auto w-full')
const SectionHeading = classed('h3', 'font-semibold text-base mb-3')
const SectionContainer = classed(
  'div',
  'border-semantic-neutral-border-default divide-semantic-neutral-border-default flex w-full flex-col divide-y rounded border'
)
