import { getCacheEntryValue, MotionCache } from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'
import { getProjectQueryFilters, getTaskQueryFilters } from '~/global/cache'
import { useDeleteFileMutation } from '~/global/rpc/v2/files'

export const useDeleteAttachment = () => {
  const queryClient = useQueryClient()

  return useDeleteFileMutation({
    onSuccess: (_, { id, targetId, targetType }) => {
      if (!targetId) {
        return
      }

      // Retrieve the target object from the cache
      const target = getCacheEntryValue(
        queryClient,
        targetType === 'TEAM_TASK' ? 'tasks' : 'projects',
        targetId
      )

      if (!target || !('uploadedFileIds' in target)) {
        return
      }

      const currentUploadedFiles = target.uploadedFileIds || []

      const modelName = targetType === 'TEAM_TASK' ? 'tasks' : 'projects'

      const taskQueryFilter = getTaskQueryFilters(targetId)
      const projectQueryFilter = getProjectQueryFilters(targetId)

      const queryFilter =
        targetType === 'TEAM_TASK' ? taskQueryFilter : projectQueryFilter

      // Update the cache to remove the deleted file
      MotionCache.upsert(queryClient, queryFilter, {
        models: {
          [modelName]: {
            [targetId]: {
              ...target,
              uploadedFileIds: currentUploadedFiles.filter(
                (uploadedFileId) => uploadedFileId !== id
              ),
            },
          },
        },
      })

      MotionCache.delete(queryClient, queryFilter, ['uploadedFiles'], [id])

      queryClient.invalidateQueries(queryFilter)
    },
  })
}
