import { type PMTaskType } from '@motion/rpc/types'
import { isRecurringTaskParent } from '@motion/ui-logic'

export type TaskBlockers = {
  blockingTasks: PMTaskType[]
  blockedTasks: PMTaskType[]
}

export const useTaskModalBlockers = ({
  task,
  projectTasks,
}: {
  task: PMTaskType | undefined
  projectTasks: PMTaskType[] | undefined
}): TaskBlockers => {
  if (task == null || isRecurringTaskParent(task) || projectTasks == null) {
    return { blockingTasks: [], blockedTasks: [] }
  }

  const blockingTasks =
    task?.blockingTasks
      ?.map((blocker) => {
        const foundBlocker = projectTasks.find(
          (task) => task.id === blocker.blockedId
        )
        if (!foundBlocker) return null
        return foundBlocker
      })
      .filter(Boolean) ?? []
  const blockedTasks = projectTasks.filter(
    (projectTask) =>
      'blockingTasks' in projectTask &&
      projectTask.blockingTasks?.some(
        (blocker) => blocker.blockedId === task?.id
      )
  )

  return { blockingTasks, blockedTasks }
}
