import { ExclamationTriangleSolid } from '@motion/icons'

import { type ReactNode } from 'react'

export const DropdownContentTopSection = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <div className='flex flex-row items-center gap-2 p-2 text-xs text-semantic-neutral-text-subtle bg-semantic-neutral-surface-overlay-bg-subtle'>
      <ExclamationTriangleSolid className='text-semantic-neutral-icon-default self-start' />
      {children}
    </div>
  )
}
