import {
  DarkThemeSolid,
  LightThemeSolid,
  SystemThemeSolid,
} from '@motion/icons'
import { SYSTEM_THEMES } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { persistSettings } from '~/localServices/firebase/web'
import { useAppSelector } from '~/state/hooks'
import { selectTheme } from '~/state/settingsSlice'
import { twMerge } from 'tailwind-merge'

export const ConnectedThemeSettings = () => {
  const currentTheme = useAppSelector(selectTheme)
  const options = [
    {
      label: 'Light',
      Icon: LightThemeSolid,
      value: SYSTEM_THEMES.LIGHT,
    },
    {
      Icon: DarkThemeSolid,
      label: 'Dark',
      value: SYSTEM_THEMES.DARK,
    },
    {
      Icon: SystemThemeSolid,
      label: 'System',
      value: SYSTEM_THEMES.SYSTEM_SETTINGS,
    },
  ]

  return (
    <div className='flex gap-2 flex-grow'>
      {options.map((option) => {
        const ThemeIcon = option.Icon
        return (
          <div
            className='flex flex-col gap-2 flex-grow text-center'
            key={option.value}
            onClick={() => {
              recordAnalyticsEvent('THEME_CHANGED', {
                theme:
                  option.value === 'systemSettings' ? 'system' : option.value,
              })
              void persistSettings({ theme: option.value })
            }}
          >
            <ThemeIcon
              className={twMerge(
                'w-[80px] h-[49px] rounded p-0.5',
                currentTheme === option.value &&
                  'border-2 border-semantic-primary-border-active p-0'
              )}
            />
            <span className='text-semantic-neutral-text-subtle text-xs'>
              {option.label}
            </span>
          </div>
        )
      })}
    </div>
  )
}
