import { type ProjectSchema } from '@motion/rpc-types'
import { isMeetingTask } from '@motion/ui-logic/pm/task'

import {
  type NormalTaskWithRelations,
  type ProjectWithRelations,
  type RecurringInstanceWithRelations,
  type UnchunkedTaskWithRelations,
} from '~/global/proxies'

export function getNonMeetingProperty<
  T extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema
    | UnchunkedTaskWithRelations
    | ProjectWithRelations,
  R extends keyof T,
>(item: T, key: R): T[R] | null {
  return 'meetingEventId' in item && isMeetingTask(item)
    ? null
    : (item[key] as T[R])
}
