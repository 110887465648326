import { templateStr } from '@motion/ui-logic'

import { SelectTriggerValues } from '~/areas/project-management/custom-fields'
import { useI18N } from '~/global/contexts'

import { ActivityValue } from '../activity-value'

type ActivityMultiSelectProps = {
  oldValues: string[]
  newValues: string[]
  customFieldId: string
  name: string
}

export function ActivityMultiSelect({
  oldValues,
  newValues,
  customFieldId,
  name,
}: ActivityMultiSelectProps) {
  const { pluralize } = useI18N()

  /**
   * The API returns an oldValue and newValue, if we are adding a new item for example,
   * oldValue would contain [A] and newValue would be [A,B].
   * In this case, we are only interested in B, the added value.
   */
  const addedValues = newValues.filter((value) => !oldValues.includes(value))
  const removedValues = oldValues.filter((value) => !newValues.includes(value))

  return templateStr(
    `{{removedValues}}{{and}}{{addedValues}} {{direction}} {{name}}`,
    {
      and: addedValues.length > 0 && removedValues.length > 0 ? ' and ' : '',
      direction: removedValues.length > 0 ? 'from' : 'to',
      removedValues:
        addedValues.length > 0 ? (
          <>
            {`added ${pluralize(addedValues.length, 'label', 'labels')} `}
            <SelectTriggerValues
              selectedItemIds={addedValues}
              customFieldId={customFieldId}
              hideIcon
              size='small'
              type='multiSelect'
              usePrettyLabels={false}
            />
          </>
        ) : null,
      addedValues:
        removedValues.length > 0 ? (
          <>
            {`removed ${pluralize(removedValues.length, 'label', 'labels')} `}
            <SelectTriggerValues
              selectedItemIds={removedValues}
              customFieldId={customFieldId}
              hideIcon
              size='small'
              type='multiSelect'
              usePrettyLabels={false}
            />
          </>
        ) : null,
      name: <ActivityValue>{name}</ActivityValue>,
    }
  )
}
