import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import type { RootState } from './types'

export interface TimezoneState {
  availabilityTimezone: string
  secondaryTimezone: string | null
  extraTimezones: string[]
}

export const initialTimezoneState: TimezoneState = {
  availabilityTimezone: DateTime.local().zoneName,
  extraTimezones: [],
  secondaryTimezone: null,
}

export const mainSlice = createSlice({
  initialState: initialTimezoneState,
  name: 'timezone',
  reducers: {
    reset: () => initialTimezoneState,
    setAvailabilityTimezone: (
      state: TimezoneState,
      action: PayloadAction<string | null | undefined>
    ) => {
      state.availabilityTimezone = action.payload ?? DateTime.local().zoneName
    },
    setExtraTimezones: (
      state: TimezoneState,
      action: PayloadAction<string[]>
    ) => {
      state.extraTimezones = action.payload
    },
    setSecondaryTimezone: (
      state: TimezoneState,
      action: PayloadAction<string>
    ) => {
      state.secondaryTimezone = action.payload
    },
  },
})

export const {
  setAvailabilityTimezone,
  setExtraTimezones,
  setSecondaryTimezone,
  reset,
} = mainSlice.actions

export const selectAvailabilityTimezone = (state: RootState) =>
  state.timezone.availabilityTimezone
export const selectExtraTimezones = (state: RootState) =>
  state.timezone.extraTimezones

export const timezoneReducer = mainSlice.reducer
