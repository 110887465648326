import { byValue, Compare } from '@motion/utils/array'

import { type ScheduledEntityWithRelations } from '~/global/proxies'
import { DateTime } from 'luxon'

/*
 * Given the list of upcoming scheduled entities, this function will return the ongoing and upcoming events.
 * @param scheduledEntities - The list of scheduled entities to filter.
 * @param
 * @returns An object containing the ongoing and upcoming events.
 */
export function getOngoingAndUpcomingEvents(
  scheduledEntities: ScheduledEntityWithRelations[]
) {
  const now = DateTime.now()
  // Ignore timeless events
  scheduledEntities = scheduledEntities.filter(
    (entity) => !entity.schedule.timeless
  )

  // Filter out events that the current user has declined
  const filterNonRsvp = (entity: ScheduledEntityWithRelations) => {
    // Check if entity is an event and its event data is included in the entity
    if (entity.type !== 'EVENT') {
      return true
    }

    const { event } = entity
    if (!event) {
      return true
    }

    // Find current user attendee
    const curUserAttendee = event?.attendees?.find(
      (a) => a.email === event.email
    )
    if (!curUserAttendee) {
      return true
    }

    if (curUserAttendee.status === 'declined') {
      return false
    }

    return true
  }

  const ongoingEvents = scheduledEntities
    .filter((entity) => {
      const start = DateTime.fromISO(entity.schedule.start)
      const end = DateTime.fromISO(entity.schedule.end)
      return start <= now && end >= now
    })
    .filter(filterNonRsvp)
    .sort(byValue((item) => item.schedule.start, Compare.string))

  const upcomingEvents = scheduledEntities
    .filter((entity) => {
      const start = DateTime.fromISO(entity.schedule.start)
      return start > now
    })
    .filter(filterNonRsvp)
    .sort(byValue((item) => item.schedule.start, Compare.string))

  return {
    ongoingEvents,
    upcomingEvents,
  }
}
