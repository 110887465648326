import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import PlayerStates from 'youtube-player/dist/constants/PlayerStates'
import { type YouTubePlayer } from 'youtube-player/dist/types'

export const muteAndPlay = async (
  player: YouTubePlayer,
  muteOnStart = true,
  attempt = 0
) => {
  try {
    // Avoid infinite loop
    if (attempt > 3) {
      return
    }

    logInDev('Mute and play attempt', attempt)

    if (!player) {
      return
    }

    const state = await player.getPlayerState()

    if (!player.mute || !player.playVideo) {
      // Retry if player does not exist
      logInDev('Player not ready, retrying')
      await muteAndPlay(player, muteOnStart, attempt + 1)
      return
    }

    // If buffering or in any other state other than playing, we'll force play the video and mute it
    if (state !== PlayerStates.PLAYING) {
      logInDev('Player ready, muting and playing')
      if (muteOnStart) {
        void player.mute()
      }
      void player.playVideo()
    }
  } catch (e) {
    Sentry.captureException(e, { tags: { position: 'muteAndPlay' } })
  }

  return
}
