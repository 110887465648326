import {
  createStateKey,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

type LastCreatedIdState = {
  id: TaskSchema['id'] | ProjectSchema['id'] | null
  ts: number
}

type PivotTableState = {
  lastCreated: LastCreatedIdState | null
  lastSelectedRowId: string | null
}

const defaultValue: PivotTableState = {
  lastCreated: null,
  lastSelectedRowId: null,
}

const pivotTableState = createStateKey<PivotTableState>('pivot-table-state', {
  defaultValue: defaultValue,
})

export function usePivotTableState<T extends keyof PivotTableState>(
  key: T
): PivotTableState[T] {
  const [state] = useSharedState(pivotTableState)
  return state[key]
}

export function usePivotTableStateSendOnly<T extends keyof PivotTableState>(
  key: T
) {
  const setState = useSharedStateSendOnly(pivotTableState)

  return useCallback(
    (value: PivotTableState[T]) => {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }))
    },
    [setState, key]
  )
}

export const useSetLastCreatedId = () => {
  const setState = usePivotTableStateSendOnly('lastCreated')

  return useCallback(
    (id: string) => {
      setState({ id, ts: new Date().getTime() })
    },
    [setState]
  )
}

export const useHighlightLastCreated = (id: string) => {
  const state = usePivotTableState('lastCreated')

  if (state == null) return { highlighted: false }

  // Check that it was added to the list within the last second.
  const dt = new Date().getTime() - state.ts
  return {
    highlighted: Boolean(state.id && id === state.id && dt < 1000),
  }
}
