import { type Calendar } from '@motion/rpc/types'
import { type CalendarSchema } from '@motion/zod/client'

import { CalendarNotFoundWarning } from './calendar-not-found-warning'

export function renderCalendarIcons(calendar: Calendar | CalendarSchema) {
  // TODO: reenable this once calendar statuses / fmw are resolved
  const isNotFound = calendar.status === 'NOT_FOUND' && false

  if (isNotFound) {
    return <CalendarNotFoundWarning calendar={calendar} />
  }

  return null
}
