import { formatDurationTime, timeDurations } from '@motion/ui-logic'
import { byValue, Compare } from '@motion/utils/array'

import { useMemo } from 'react'

type Choices = {
  label: string
  value: number
}[]

export const useCompletedChoices = (
  selectedCompletedDuration: number,
  taskDuration: number
): Choices => {
  return useMemo(() => {
    const choices = timeDurations.filter(
      (duration) =>
        duration.value != null &&
        duration.value > 0 &&
        duration.value <= taskDuration
    ) as Choices

    choices.push({
      value: 0,
      label: formatDurationTime(0),
    })

    if (!choices.some((choice) => choice.value === selectedCompletedDuration)) {
      choices.push({
        value: selectedCompletedDuration,
        label: formatDurationTime(selectedCompletedDuration),
      })
    }

    if (!choices.some((choice) => choice.value === taskDuration)) {
      choices.push({
        value: taskDuration,
        label: formatDurationTime(taskDuration),
      })
    }
    choices.sort(byValue((choice) => choice.value, Compare.numeric))

    return choices
  }, [selectedCompletedDuration, taskDuration])
}
