import { XSolid } from '@motion/icons'
import { IconButton, Portal, Sidebar } from '@motion/ui/base'

import { type PropsWithChildren } from 'react'

import { GLOBAL_SIDEBAR_PANEL_CONTAINER_ID } from '../constants'
import { useGlobalSidebarContext } from '../hooks'

export type SidebarExpandablePanelProps = PropsWithChildren<{
  open: boolean
  onClose: () => void
}>

export const SidebarExpandablePanel = ({
  open,
  onClose,
  children,
}: SidebarExpandablePanelProps) => {
  const portalParent = document.getElementById(
    GLOBAL_SIDEBAR_PANEL_CONTAINER_ID
  )

  if (!portalParent) return null

  return (
    <Portal container={portalParent}>
      <div className='flex z-10 absolute top-0 left-0 h-full'>
        <Sidebar
          open={open}
          closedWidth={0}
          minWidth={240}
          initialWidth={240}
          maxWidth={240 * 1.5}
          hideCloseButton
        >
          <div className='bg-semantic-neutral-surface-raised-bg-default flex min-h-full border-r border-semantic-neutral-border-default shadow-md'>
            {children}
          </div>
        </Sidebar>
      </div>

      {open && (
        <div
          className='fixed top-0 left-0 z-0 bg-black/25 w-screen h-screen'
          onClick={onClose}
        />
      )}
    </Portal>
  )
}

export type SidebarExpandablePanelHeaderProps = {
  title: string
}

export const SidebarExpandablePanelHeader = ({
  title,
}: SidebarExpandablePanelHeaderProps) => {
  const { setOpenedPanel } = useGlobalSidebarContext()

  return (
    <div className='flex flex-row items-center justify-between'>
      <h4 className='text-semantic-neutral-text-default text-xs font-semibold'>
        {title}
      </h4>

      <IconButton
        icon={XSolid}
        sentiment='neutral'
        variant='muted'
        size='small'
        onClick={() => void setOpenedPanel(null)}
      />
    </div>
  )
}
