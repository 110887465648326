import { type SocketEvent } from '@motion/shared/websockets'

import { refreshCalendars, refreshScheduledEntities } from './listeners'
import { type Context } from './types'

import { log } from '../log'

export function handleCalendarWebsocketEvent(ctx: Context, event: SocketEvent) {
  log.info('received calendar event', event)
  switch (event.type) {
    case 'calendar.updated':
      return ctx.batch.append(refreshScheduledEntities())
    case 'calendarEntry.updated':
    case 'calendarEntry.created':
    case 'calendarEntry.deleted':
      return ctx.batch.append(refreshCalendars())
  }
}
