import { type ConnectedChartProps } from '~/areas/charts'
import { useAppDataContext } from '~/global/contexts/transforms'
import { type ComponentType, useMemo } from 'react'

import { type DashboardChartProps } from './types'

import { mapCellSchemaToChartQuery } from '../utils'

/**
 * HOC component that converts a dashboard chart cell into a chart query
 */
export function ChartQueryProvider<T extends ConnectedChartProps>(
  ChartComponent: ComponentType<T>
) {
  const WithChartQuery = ({ cell, ...props }: DashboardChartProps) => {
    const ctx = useAppDataContext()
    const chartQuery = useMemo(
      () => mapCellSchemaToChartQuery(ctx, cell),
      [cell, ctx]
    )

    return <ChartComponent {...(props as T)} chartQuery={chartQuery} />
  }

  return WithChartQuery
}
