import { SearchSolid } from '@motion/icons'
import { type UploadedFileSchema } from '@motion/rpc-types'
import { TitleModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { AttachmentUploadButton } from '~/areas/attachments/attachment-upload-button'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { type Dispatch, type ReactNode, type SetStateAction } from 'react'

export type AttachmentsModalContentProps = Pick<
  ModalTriggerComponentProps<'attachments'>,
  'targetId' | 'targetType' | 'close'
> & {
  workspaceId: NonNullable<UploadedFileSchema['workspaceId']>
  attachmentCount: number
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
  children: ReactNode
}

export function AttachmentsModalContent({
  close,
  targetId,
  targetType,
  workspaceId,
  attachmentCount,
  searchQuery,
  setSearchQuery,
  children,
}: AttachmentsModalContentProps) {
  return (
    <TitleModal
      title={`Attachments (${attachmentCount})`}
      onClose={close}
      bodyProps={{
        className: 'flex flex-col p-0 w-[650px] h-[490px]',
      }}
      withAnimation
      visible
    >
      <div className='flex items-center gap-2 px-4 py-3 bg-semantic-neutral-surface-raised-bg-subtlest [&>*:first-child]:flex-1'>
        <TextField
          rounded='default'
          prefix={<SearchSolid />}
          placeholder='Search'
          value={searchQuery}
          onChange={setSearchQuery}
          showClearButton
        />

        <AttachmentUploadButton
          targetId={targetId}
          targetType={targetType}
          workspaceId={workspaceId}
        />
      </div>
      <div className='flex-1 px-4 pt-2 overflow-y-auto'>{children}</div>
    </TitleModal>
  )
}
