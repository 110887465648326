import {
  INDIVIDUAL_ANNUAL_PRICE,
  INDIVIDUAL_MONTHLY_PRICE,
} from '@motion/ui-logic/billing'
import {
  getSegmentAnalytics,
  getTrackingCookies,
} from '@motion/web-base/analytics'
import { isMobileExperience } from '@motion/web-base/env'
import { errorInDev } from '@motion/web-base/logging'
import { BillingPaymentPage } from '@motion/web-billing'
import { useAuth, useAuthenticatedUser } from '@motion/web-common/auth'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  type StripeElements,
  type StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import { LoadingPage } from '~/components/LoadingPage'
import { createSubscription } from '~/state/corrilySlice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { selectDisplayName } from '~/state/userSlice'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

interface ResubscribeIndividualProps {
  userEmail: string
  onRerender: (clientSecret: string, error: string) => void
}

export const ResubscribeIndividual = ({
  userEmail,
  onRerender,
}: ResubscribeIndividualProps) => {
  const [isAnnual, setIsAnnual] = useState(true)
  const isMobile = isMobileExperience()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>('')
  const [hasSelectedCard, setHasSelectedCard] = useState(true)

  const stripe = useStripe()
  const elements = useElements()

  const { signOut } = useAuth()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { uid: userId } = useAuthenticatedUser()
  const userDisplayName = useAppSelector(selectDisplayName)

  useEffect(function logAnalytics() {
    getSegmentAnalytics()?.track('add_to_cart', {
      category: 'checkout',
      url: window.location.href,
    })
  }, [])

  if (!stripe) {
    return <LoadingPage id='resubscribe-individual' />
  }

  const logPurchaseEvent = (
    subscriptionId: string,
    cookieData: Record<string, string | undefined>
  ) => {
    try {
      const eventData = {
        action_source: 'website',
        category: 'checkout',
        currency: 'usd',
        customer_id: userId,
        isTeam: false,
        label: isAnnual ? 'motion_annual' : 'motion_monthly',
        messageId: `${subscriptionId}-resubscribe`,
        subscription_id: subscriptionId,
        trialLength: 0,
        url: window.location.href,
        value: isAnnual ? INDIVIDUAL_ANNUAL_PRICE : INDIVIDUAL_MONTHLY_PRICE,
        ...cookieData,
      }
      const traitData = {
        traits: {
          email: userEmail,
          firstName: userDisplayName?.split(' ')[0]?.toLowerCase(),
          lastName: userDisplayName?.split(' ')[1]?.toLowerCase(),
        },
      }
      getSegmentAnalytics()?.track(
        'success_purchase_resubscribe',
        eventData,
        traitData
      )
    } catch (e) {
      errorInDev(e)
    }
  }

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setHasSelectedCard(event.value.type === 'card')
    setIsPaymentFilledOut(event.complete)
  }

  const processPayment = async () => {
    setIsSubmitting(true)
    setErrorMessage(null)

    try {
      const result = await stripe.confirmSetup({
        elements: elements as StripeElements,
        redirect: 'if_required',
      })
      if (result?.error) {
        setErrorMessage(result?.error?.message || 'Error')
        setIsSubmitting(false)
        return
      }

      const setupIntent: { id: string } = result.setupIntent
      let subscriptionId: string

      const response = await dispatch(
        createSubscription({
          isMonthly: !isAnnual,
          setupIntentId: setupIntent.id,
          trialLength: 0,
        })
      ).unwrap()
      if (response.error || !response.subscription) {
        const errorMessage = response.error || 'Unable to create subscription'
        setErrorMessage(errorMessage)
        setIsSubmitting(false)
        if (response.clientSecret) {
          onRerender(response.clientSecret, errorMessage)
        }
        return
      }
      subscriptionId = response.subscription.id

      const cookieData = getTrackingCookies()

      logPurchaseEvent(subscriptionId, cookieData)
      window.location.reload()
    } catch (e: any) {
      setIsSubmitting(false)
      setErrorMessage(e?.message)
    }
  }

  return (
    <div className='flex flex-col w-full min-h-full lg:flex-row'>
      <div className='flex grow justify-center gap-y-6 py-6 px-3'>
        <BillingPaymentPage
          paymentElement={
            <PaymentElement onChange={handlePaymentElementChange} />
          }
          isAnnual={isAnnual}
          setIsAnnual={setIsAnnual}
          isMobile={isMobile}
          userEmail={userEmail}
          onChangeEmail={() => {
            signOut()
            navigate('/checkout')
          }}
          hasSelectedCard={hasSelectedCard}
          error={errorMessage}
          isPaymentFilledOut={isPaymentFilledOut}
          isSubmitting={isSubmitting}
          handleSubmit={processPayment}
          isTeam={false}
        />
      </div>
    </div>
  )
}
