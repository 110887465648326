import { useProjectUpdater } from '~/areas/project/hooks'
import {
  DescriptionField,
  type DescriptionFieldProps,
} from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledDescriptionField = () => {
  const updateProject = useProjectUpdater()

  const { form } = useProjectForm()
  const { control, watch } = form

  const { field } = useController({
    name: 'description',
    control,
  })

  const workspaceId = watch('workspaceId')
  const id = watch('id')

  const onChange: DescriptionFieldProps['onChange'] = async (value) => {
    if (id != null) {
      form.setValue('description', value)
      return await updateProject(id, { description: value })
    }
    field.onChange(value)
  }

  return (
    <DescriptionField
      value={field.value}
      onChange={onChange}
      workspaceId={workspaceId}
      projectId={id}
    />
  )
}
