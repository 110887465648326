import { type ProjectWithRelations } from '~/global/proxies'
import { useMemo } from 'react'

import { convertCustomFieldToColumn } from './custom-fields'
import { convertProjectFieldToColumn } from './utils'

import {
  type CustomFieldDefinition,
  isCustomFieldColumn,
  useProjectFields,
} from '../../fields'

export const useProjectColumns = () => {
  const projectFields = useProjectFields()

  return useMemo(() => {
    return projectFields.map((f) => {
      if (isCustomFieldColumn(f.id)) {
        return convertCustomFieldToColumn(
          f as CustomFieldDefinition<ProjectWithRelations>
        )
      }
      return convertProjectFieldToColumn(f)
    })
  }, [projectFields])
}
