import { type ChartQueryRequestSchema } from '@motion/rpc-types'

export class ChartQueryError extends Error {
  readonly chartQuery: ChartQueryRequestSchema

  constructor(message: string, chartQuery: ChartQueryRequestSchema) {
    super(message)
    this.name = 'ChartQueryError'
    this.chartQuery = chartQuery

    Error.captureStackTrace(this, ChartQueryError)
  }
}
