import {
  type CustomFieldFilters,
  type EntityType,
  type RemoveCustomFieldFilters,
  type SelectedFilter,
  useEntityFilter as useEntityFilterBase,
  useFieldFilter as useFieldFilterBase,
} from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useAppDataContext } from '~/global/contexts'
import { useCallback } from 'react'

export const useEntityFilterState = <T extends EntityType>(entity: T) => {
  const ctx = useAppDataContext()
  return useEntityFilterBase(ctx, entity)
}

export type UseFieldFilter<TType> = [TType, (value: TType) => void]
type SelectedEntityFilters<TEntity extends EntityType> =
  SelectedFilter<TEntity>['filters']

export function useFieldFilter<
  TEntity extends EntityType,
  TKey extends keyof RemoveCustomFieldFilters<SelectedEntityFilters<TEntity>>,
>(
  entity: TEntity,
  field: TKey
): UseFieldFilter<
  RemoveCustomFieldFilters<SelectedEntityFilters<TEntity>>[TKey]
>
export function useFieldFilter<
  TEntity extends EntityType,
  TKey extends keyof CustomFieldFilters,
  TCustomFieldKey extends keyof CustomFieldFilters[TKey],
>(
  entity: TEntity,
  field: TKey,
  customFieldKey: TCustomFieldKey
): UseFieldFilter<NonNullable<CustomFieldFilters[TKey]>[TCustomFieldKey] | null>
export function useFieldFilter<
  TEntity extends EntityType,
  TKey extends keyof SelectedEntityFilters<TEntity>,
  TCustomFieldKey extends string,
>(entity: TEntity, field: TKey, customFieldKey?: TCustomFieldKey) {
  const ctx = useAppDataContext()

  const [filter, setter] = useFieldFilterBase(
    ctx,
    entity,
    field,
    customFieldKey
  )

  const setFilter = useCallback(
    (value: SelectedFilter<TEntity>['filters'][TKey]) => {
      setter(value)
      if (value != null) {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_FILTER', {
          type: entity,
          filter: String(field),
          // @ts-expect-error - fine
          operator: value?.operator ?? 'none',
        })
      }
    },
    [entity, field, setter]
  )

  return [filter, setFilter] as const
}
