import { isLogicalFilter } from '@motion/ui-logic/pm/data'

import { LogicalDatePicker } from './logical-date-picker'
import { RangeDatePicker } from './range-date-picker'
import { RelativeDatePickerContent } from './relative-date-picker'
import { type DatePickerContentProps } from './types'

import { FormatFilter } from '../format'

export const DatePickerContent = (props: DatePickerContentProps) => {
  if (props.value.operator === 'defined') return null
  if (props.value.operator === 'range') {
    return <RangeDatePicker value={props.value} onSelect={props.onSelect} />
  }

  if (isLogicalFilter(props.value)) {
    return (
      <LogicalDatePicker
        value={props.value}
        onSelect={props.onSelect}
        calendarStartDay={props.calendarStartDay}
      />
    )
  }

  if (props.value.operator === 'relative') {
    return (
      <RelativeDatePickerContent
        calendarStartDay={props.calendarStartDay}
        value={props.value}
        onSelect={props.onSelect}
      />
    )
  }

  return (
    <div className='text-sm text-semantic-neutral-text-subtle flex justify-center'>
      <FormatFilter value={props.value} seperator='-' />
    </div>
  )
}
