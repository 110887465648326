import { logInDev, makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { doCheckStripeSubscription } from '~/utils/stripe'

import * as api from '../chromeApi/webappChromeApiBackground'

const log = makeLog('webapp-background')
export const initWebApp = async (userId: string) => {
  // Indicate that we've started initialization of the background script, which
  // should make the frontend show the loading spinner.
  await api.sendContentScriptMessage({}, 'initBackground')
  Sentry.addBreadcrumb({ message: 'initBackground' })

  log.time('check sub', async () => {
    Sentry.addBreadcrumb({ message: 'checking sub' })
    await doCheckStripeSubscription(true)
    Sentry.addBreadcrumb({ message: 'finished checking sub' })
  })

  return true
}

/**
 * Indicate to the content script that the background has loaded
 */
export const sendBackgroundLoaded = async (userId: string | null = null) => {
  logInDev('sendBackgroundLoaded')

  const message = {
    event: 'backgroundLoaded',
  }
  Sentry.addBreadcrumb({ message: 'send background loaded' })
  logInDev('Sending background loaded', message)
  void api.runtime.sendMessage(message)
}
