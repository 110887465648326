import { useMotionLocalStorage } from '~/localServices/storage'

// This hook returns the Stripe customer balance as a formatted currency string
export function useCustomerCreditBalance() {
  const { stripeCustomer } = useMotionLocalStorage(['stripeCustomer'])

  if (!stripeCustomer?.balance) {
    return
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format((-1 * stripeCustomer.balance) / 100)
}
