import { classed } from '@motion/theme'
import { formatToReadableWeekDayMonth } from '@motion/ui-logic'

import { useMemo } from 'react'

import { usePlannerProps, useScrollPosition } from '../context'
import { useCursorPosition, useScreenValues } from '../hooks'
import { dayOffsetToDate } from '../utils'

type ScrollingDateIndicator = {
  containerNode: HTMLDivElement | null
}

export const ScrollingDateIndicator = (props: ScrollingDateIndicator) => {
  const rawCursorPosition = useCursorPosition()

  const [scrollPosition] = useScrollPosition()
  const [plannerProps] = usePlannerProps()
  const { positionOffset } = useScreenValues()

  const cursorRelativePosX = Math.floor(
    rawCursorPosition.x - (plannerProps.containerRect?.left || 0)
  )

  const cursorRelativePosY = 6
  const currentDayOffset = Math.floor(
    (cursorRelativePosX - scrollPosition - positionOffset) / plannerProps.dayPx
  )

  const currentDate = useMemo(
    () => formatToReadableWeekDayMonth(dayOffsetToDate(currentDayOffset)),
    [currentDayOffset]
  )

  const cursorStyles = {
    transform: `translate3D(${cursorRelativePosX}px,${cursorRelativePosY}px,0)`,
  }

  return (
    <>
      <Container
        className='text-semantic-neutral-text-subtle z-[5] text-xs whitespace-nowrap w-auto opacity-0 transition-opacity group-hover/scrolling-container:opacity-100'
        style={cursorStyles}
      >
        <div className='-translate-x-1/2 -translate-y-1/4 px-0.5 text-2xs bg-semantic-neutral-surface-bg-subtle flex items-center justify-center rounded'>
          {currentDate}
        </div>
        <div className='w-px h-1 bg-semantic-neutral-border-strong -translate-x-1/2 -translate-y-1/4' />
      </Container>
    </>
  )
}

const Container = classed(
  'div',
  'absolute top-4 pointer-events-none transition-opacity -translate-x-1/2'
)
