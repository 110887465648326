import { XSolid } from '@motion/icons'
import { Button, ButtonGroup, IconButton } from '@motion/ui/base'
import { formatDateRange, formatToReadableWeekDayMonth } from '@motion/ui-logic'
import {
  type DATE_FILTER_TYPES,
  type DateFilterSchema,
  type ViewDateFilterSchema,
} from '@motion/zod/client'

import { DateFilterDropdown } from '~/areas/project-management/components/filters/dropdowns/base-filter-dropdown/base-date-filter-dropdown'
import { DateTime } from 'luxon'

import { type DropdownFilterProps } from '../types'

type FilterDateButtonProps = DropdownFilterProps & {
  label: string
  value: ViewDateFilterSchema | null
  onChange(value: DateFilterSchema | null): void
}

export const FilterDateButton = (props: FilterDateButtonProps) => {
  const value = props.value

  const onClear = () => {
    props.onChange(null)
  }
  const onSelect = (values: {
    [key in DATE_FILTER_TYPES]?: string
  }) => {
    if (values['on-date']) {
      const dateValue = DateTime.fromISO(values['on-date'])
      return props.onChange({
        operator: 'equals',
        value: dateValue.toISODate(),
      })
    }
    const from = values['after-date']
      ? DateTime.fromISO(values['after-date'])
      : undefined
    const to = values['before-date']
      ? DateTime.fromISO(values['before-date'])
      : undefined

    if (from && to) {
      return props.onChange({
        operator: 'range',
        value: {
          from: from.startOf('day').toISO(),
          to: to.endOf('day').toISO(),
        },
      })
    }
    if (from) {
      return props.onChange({
        operator: 'gte',
        value: from.startOf('day').toISO(),
      })
    }
    if (to) {
      return props.onChange({
        operator: 'lt',
        value: to.startOf('day').toISO(),
      })
    }
  }

  return (
    <DateFilterDropdown
      onClear={onClear}
      onSave={onSelect}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
    >
      <ButtonGroup segmented nowrap>
        <Button sentiment='neutral' variant='outlined' size='small'>
          {props.label}: {formatText(value)}
        </Button>
        {value != null && (
          <IconButton
            sentiment='neutral'
            variant='outlined'
            size='small'
            icon={XSolid}
            onClick={(e) => {
              e.stopPropagation()
              props.onChange(null)
            }}
          />
        )}
      </ButtonGroup>
    </DateFilterDropdown>
  )
}

function formatText(value: ViewDateFilterSchema | null) {
  if (value == null) {
    return 'None'
  }

  if (value.operator === 'range') {
    return formatDateRange(value.value.from, value.value.to)
  }
  if (value.operator === 'equals') {
    return formatToReadableWeekDayMonth(value.value)
  }
  if (value.operator === 'gte') {
    return `After ${formatToReadableWeekDayMonth(value.value)}`
  }
  if (value.operator === 'lt') {
    return `Before ${formatToReadableWeekDayMonth(value.value)}`
  }

  return 'Unknown'
}
