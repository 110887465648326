import { getBaseHeaders } from '@motion/rpc'
import { type CreateTeamDto } from '@motion/rpc-types'
import { MOTION_CLIENT_HEADER } from '@motion/shared/common'
import { getMotionClient } from '@motion/web-base/env'
import { firebase } from '@motion/web-common/firebase'

import { getCurrentUserToken } from '~/utils/auth'
import { v4 as uuidv4 } from 'uuid'

import api from '../chromeApi/chromeApiBackground'
import {
  type PMTeamSubscriptionType,
  type PMTeamType,
  type PMWorkspaceType,
} from '../components/ProjectManagement/types'
import { type BillingError, type Team } from '../state/TeamTypes'
import { type UserType } from '../types/User'

export interface FirebaseTeamUsers {
  [id: string]: {
    email: string
    joined: boolean
    name: string
    uid: string
  }
}

export interface SetupIntentResult {
  clientSecret: string
  id: string
}

export interface TeamResponse {
  userId: UserType['id']
  team: PMTeamType
  personalWorkspace: PMWorkspaceType
}

export interface FirebaseTeam {
  id: string
  invited: string[]
  name: string
  users: FirebaseTeamUsers
  pmTeamSubscription: PMTeamSubscriptionType
}

export interface FirebaseTeamResponse {
  joinedTeam?: FirebaseTeam
  invitedTeams?: FirebaseTeam[]
  domain?: string
}

export interface MutationResponse extends FirebaseTeamResponse {
  success: boolean
  error?: string
}

export interface CheckTeamEligibilityResponse {
  isEligible: boolean
  error?: string
}

interface Card {
  brand: string
  last4: string
}

export interface PaymentMethodResponse {
  id: string
  card: Card
  error?: string
  message?: string
}

export interface TeamsService {
  getPmTeam: () => Promise<TeamResponse>
  get: () => Promise<FirebaseTeamResponse>
  cancelSubscription: (
    teamId: string,
    cancellationReason: string
  ) => Promise<PMTeamSubscriptionType>
  checkTeamEligibility: (email: string) => Promise<CheckTeamEligibilityResponse>
  createTeam: (dto: CreateTeamDto) => Promise<MutationResponse>
  createSetupIntent: (name: string) => Promise<SetupIntentResult>
  join: (teamId: string) => Promise<MutationResponse>
  reenableSubscription: (teamId: string) => Promise<PMTeamSubscriptionType>
  removeInvite: (teamId: string, email: string) => Promise<MutationResponse>
  rename: (teamId: string, name: string) => Promise<MutationResponse>
  resubscribe: (
    teamId: string,
    setupIntentId: string,
    isMonthly: boolean,
    memberEmails: string[]
  ) => Promise<Team | BillingError>
  reusableSetupIntent: (teamId: string) => Promise<SetupIntentResult>
  switchBillingCycle: (teamId: string) => Promise<MutationResponse>
  updatePaymentMethod: (
    teamId: string,
    setupIntentId: string
  ) => Promise<PaymentMethodResponse>
}

// TODO: Rework this to be more generic
const request = async (
  path: string,
  method: string,
  body?: Record<string, any> | string
) => {
  let stringifiedBody
  if (method.toLowerCase() !== 'get') {
    stringifiedBody =
      typeof body === 'string' ? body : JSON.stringify({ ...body })
  }
  const token = await getCurrentUserToken()
  const reqId = uuidv4()

  const fetchOptions: Partial<RequestInit> = {
    headers: {
      ...getBaseHeaders({ token, contentType: 'json', reqId }),
      'x-motion-web-version': __SENTRY_RELEASE__,
      [MOTION_CLIENT_HEADER]: getMotionClient(),
    },
    method,
    credentials: 'include',
  }
  if (method.toLowerCase() !== 'get') {
    fetchOptions.body = stringifiedBody
  }
  const response = await fetch(`${__BACKEND_HOST__}/${path}`, fetchOptions)
  const res = (await response.json()) as FirebaseTeamResponse
  if (res.joinedTeam) {
    await api.storage.local.set({ invitedTeams: [], team: res.joinedTeam })
  } else if (res.invitedTeams) {
    await api.storage.local.set({ invitedTeams: res.invitedTeams })
  }

  return res
}

// TODO: Consolidate both of the below calls into one
const getPmTeam = async (): Promise<TeamResponse> => {
  return (await request('teams?isNew=true', 'get')) as TeamResponse
}

const get = async (): Promise<FirebaseTeamResponse> => {
  return {
    ...(await request('teams/current', 'get')),
    domain: firebase.auth().currentUser?.email?.split('@')[1],
  }
}

const cancelSubscription = async (
  teamId: string,
  cancellationReason: string
): Promise<PMTeamSubscriptionType> => {
  const res = (await request(`teams/${teamId}/cancel`, 'POST', {
    cancellationReason,
  })) as PMTeamSubscriptionType

  return res
}

const createTeam = async (dto: CreateTeamDto) => {
  const res = await request('teams', 'POST', {
    ...dto,
  })

  let response

  if ('joinedTeam' in res) {
    response = res.joinedTeam as Team
  } else {
    response = res as Team
  }

  // Fetch created team and update storage
  await get()
  return response
}

const createSetupIntent = async (name: string, forceNewCustomer = false) => {
  return (await request('teams/setupIntent', 'POST', {
    name,
    forceNewCustomer,
  })) as SetupIntentResult
}

const join = async (teamId: string) => {
  const res = (await request(
    `teams/${teamId}/join`,
    'POST'
  )) as MutationResponse

  // Fetch created team and update storage
  await get()
  return res
}

const checkTeamEligibility = async (email: string) => {
  return (await request(
    `teams/checkTeamEligibility`,
    'POST',
    JSON.stringify({ email })
  )) as CheckTeamEligibilityResponse
}

const reenableSubscription = async (teamId: string) => {
  return (await request(
    `teams/${teamId}/reenableSubscription`,
    'POST'
  )) as PMTeamSubscriptionType
}

const removeInvite = async (teamId: string, email: string) => {
  return (await request(
    `teams/${teamId}/invites/${encodeURIComponent(email)}`,
    'DELETE'
  )) as MutationResponse
}

const rename = async (teamId: string, name: string) => {
  return (await request(`teams/${teamId}/rename`, 'POST', {
    name,
  })) as MutationResponse
}

const reusableSetupIntent = async (teamId: string) => {
  return (await request(
    `teams/${teamId}/setupIntent`,
    'POST'
  )) as SetupIntentResult
}

const switchBillingCycle = async (teamId: string) => {
  return (await request(`teams/${teamId}/upgrade`, 'POST')) as MutationResponse
}

export const teamsService = {
  cancelSubscription,
  checkTeamEligibility,
  createTeam,
  createSetupIntent,
  get,
  getPmTeam,
  join,
  reenableSubscription,
  removeInvite,
  rename,
  reusableSetupIntent,
  switchBillingCycle,
}
