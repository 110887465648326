import { PopoverTrigger } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { AssigneeDropdownContent } from './components'
import {
  type AssigneeDropdownContentProps,
  type MultiAssigneeDropdownContentProps,
  type SingleAssigneeDropdownContentProps,
} from './types'

export type AssigneeDropdownProps = (
  | Omit<SingleAssigneeDropdownContentProps, 'close'>
  | Omit<MultiAssigneeDropdownContentProps, 'close'>
) & {
  workspaceId: AssigneeDropdownContentProps['workspaceId']
  children?: ReactNode
  onClose?: () => void
}

export const AssigneeDropdown = ({
  children,
  onClose,
  ...rest
}: AssigneeDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <AssigneeDropdownContent
          close={() => {
            close()
            onClose?.()
          }}
          {...rest}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
