import { useCallback } from 'react'
import semverGte from 'semver/functions/gte'

import { useDesktopVersion } from './use-desktop-version'

export function useHasMinimumDesktopVersion() {
  const currentVersion = useDesktopVersion()

  return useCallback(
    (minVersion: string) => {
      return semverGte(currentVersion, minVersion)
    },
    [currentVersion]
  )
}
