import { Date } from './date'
import { MultiPerson } from './multi-person'
import { MultiSelect } from './multi-select'
import { Number } from './number'
import { Person } from './person'
import { Select } from './select'
import { Text } from './text'
import { Url } from './url'

export const TaskModalCustomField = {
  Date,
  MultiSelect,
  Number,
  Select,
  Text,
  Url,
  Person,
  MultiPerson,
}
