import { getMinimumBucket } from '@motion/ui-logic/billing'
import { errorInDev } from '@motion/web-base/logging'

import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useResubscribeTeam } from '~/global/rpc/team'
import { useState } from 'react'

import { ConnectedPaymentSection } from '../../../components/Team/components/Common/PaymentSection'
import { type ProcessPaymentProps } from '../../../components/Team/types/process-payment-props'
import { useAppSelector } from '../../../state/hooks'
import { type Team } from '../../../state/TeamTypes'
import { selectEmail } from '../../../state/userSlice'
import { getUniqueEmails } from '../../../utils/teamUtils'

interface ResubscribeTeamProps {
  error: string | null
  userEmail: string
  team: Team
  onRerender: (clientSecret: string) => void
  setError: (error: string) => void
}

export const ResubscribeTeam = ({
  error,
  onRerender,
  setError,
  team,
  userEmail,
}: ResubscribeTeamProps) => {
  const email = useAppSelector(selectEmail)
  const uniqueEmailsSet = getUniqueEmails(team)
  const emails = Array.from(uniqueEmailsSet).filter(
    (email) => email !== userEmail
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const { mutateAsync: resubscribeTeam } = useResubscribeTeam()

  if (!stripe || !elements) {
    // Haven't finished loading stripe yet
    return null
  }

  const processPayment = async ({
    isAnnual,
    selectedEmails,
    bucketSeats,
  }: ProcessPaymentProps) => {
    setIsSubmitting(true)
    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })
    if (result.error) {
      errorInDev(result.error)
      setIsSubmitting(false)
      setError(result.error.message ?? '')
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const setupIntent = result.setupIntent!
    const resubscribeResult = await resubscribeTeam({
      isMonthly: !isAnnual,
      memberEmails: selectedEmails || [],
      setupIntentId: setupIntent.id,
      teamId: team.id,
      seats: bucketSeats,
    })
    if ('error' in resubscribeResult) {
      errorInDev(resubscribeResult.error)
      setIsSubmitting(false)
      setError(resubscribeResult.error)
      onRerender(resubscribeResult.clientSecret)
      return
    }
    setIsSubmitting(false)
    window.location.reload()
  }

  return (
    <div className='bg-light-300 dark:bg-dark-1100 flex h-full w-full items-center justify-center'>
      <ConnectedPaymentSection
        buttonText='Restart team plan'
        emails={emails}
        isSubmitting={isSubmitting}
        processPayment={processPayment}
        stripeError={error ?? undefined}
        userEmail={email}
        initialSeats={Math.max(
          getMinimumBucket(team.pmTeamSubscription?.bucketSeats || 0),
          getMinimumBucket(emails.length + 1)
        )}
      />
    </div>
  )
}
