import { type statusColorOptions } from '@motion/ui-logic'

interface ItemsWithColorType {
  color: string
}

// Given an array of items with a color property, find a color in the colors array (array of strings) that is either unused or randomized
export const findUnusedColor = (
  items: ItemsWithColorType[],
  colors: typeof statusColorOptions
) => {
  // Given a list of labels, find the first unused color from labelColorOptions
  const usedColors = items.map((item) => item.color)
  const unusedColors = colors.filter((color) => !usedColors.includes(color))
  if (unusedColors.length > 0) {
    return unusedColors[0]
  }
  // Return random color
  return colors[Math.floor(Math.random() * colors.length)]
}
