import { type SelectCustomField } from '@motion/ui-logic'

import {
  CustomField,
  SelectTriggerValues,
} from '~/areas/project-management/custom-fields'
import { type ControlledCustomFieldProps } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { CellAction } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { type FC } from 'react'

export const ControlledSelect: FC<
  ControlledCustomFieldProps<SelectCustomField>
> = ({ value, onSubmit, customField, disabled }) => {
  const hasValue = value != null

  return (
    <CustomField.Select
      selectedItemId={value}
      onChange={onSubmit}
      workspaceId={customField.definition.workspaceId}
      customFieldId={customField.definition.id}
      disabled={disabled}
    >
      <CellAction isFake={disabled} disabled={disabled} unstyled>
        <SelectTriggerValues
          selectedItemIds={hasValue ? [value] : []}
          customFieldId={customField.definition.id}
          type='select'
          size='xsmall'
          hideIcon={hasValue}
        />
      </CellAction>
    </CustomField.Select>
  )
}
