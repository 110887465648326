import { UserSolid } from '@motion/icons'
import { Button, SearchableList } from '@motion/ui/base'
import { type FilterTarget } from '@motion/ui-logic/pm/data'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { PriorityLabel } from '~/global/components/labels'
import { useAppWorkspaceContext } from '~/global/contexts'
import { useCallback } from 'react'

import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedPriorityFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(props.target, 'priorities')

  return (
    <FilterItem
      label='Priority'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...(value ?? {}), inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedPriorityDropdown applyTo={props.target} close={close} />
      )}
    >
      <MultiSelectFilterValue
        Icon={UserSolid}
        type='priorities'
        ids={value?.value ?? null}
      />
    </FilterItem>
  )
}

type ConnectedPriorityDropdownProps = {
  applyTo: FilterTarget
  close(): void
}

const ConnectedPriorityDropdown = (props: ConnectedPriorityDropdownProps) => {
  const [filter, setFilter] = useFieldFilter(props.applyTo, 'priorities')
  const [ctx] = useAppWorkspaceContext()

  const computeSelected = useCallback(
    (value: PriorityLevelSchema) => filter?.value.includes(value) ?? false,
    [filter]
  )

  return (
    <div>
      <SearchableList
        inputProps={{ placeholder: 'Choose priority ...' }}
        itemType='checkbox'
        items={ctx.priorities.all}
        renderItem={(value) => <PriorityLabel value={value} />}
        searchable={false}
        computeSelected={computeSelected}
        onSelect={(values) => {
          setFilter({
            operator: 'in',
            inverse: filter?.inverse ?? false,
            value: values,
          })
        }}
        computeKey={(item) => item}
      />
      <div className='border-t border-dropdown-border py-2 px-2 flex justify-between'>
        <Button
          onClick={() => {
            setFilter(null)
            props.close()
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            setFilter({
              operator: 'in',
              inverse: filter?.inverse ?? false,
              value: ctx.priorities.all,
            })
          }}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Select all
        </Button>
      </div>
    </div>
  )
}
