import { type Row } from '@tanstack/react-table'

import { type GroupedNode, type Tree } from '../../grouping'
import {
  ADD_ROW_HEIGHT,
  HEADER_ROW_HEIGHT,
  MULTI_PROJECT_ROW_HEIGHT,
  SINGLE_PROJECT_ROW_HEIGHT,
} from '../shared-constants'
import { organizeProjectsIntoRowsLegacy } from '../utils'

export const isStackRow = <T extends GroupedNode>(row: T, tree: Tree<T>) => {
  if (
    tree.groups.length > 0 &&
    row.value.type === tree.groups[tree.groups.length - 1].type
  ) {
    return true
  }

  return false
}

export const calculateRowHeight = (rowType: string, children: any[] = []) => {
  // If the row is a stacked row (grouped projects), then we need to calculate the height
  if (rowType === 'stacked-row') {
    if (children.length === 0) {
      return MULTI_PROJECT_ROW_HEIGHT
    }

    const totalRows = children.length
    return totalRows * MULTI_PROJECT_ROW_HEIGHT
  }

  if (rowType === 'add-project-row') {
    return ADD_ROW_HEIGHT
  }

  if (rowType === 'project') {
    return SINGLE_PROJECT_ROW_HEIGHT
  }

  return HEADER_ROW_HEIGHT
}

export const getHeightAndStackedProjects = <T extends GroupedNode>(params: {
  row: Row<T>
  tree: Tree<T>
  stackProjects: boolean
}) => {
  const { row, tree, stackProjects } = params

  const isStackedRow = isStackRow(row.original, tree)

  if (stackProjects && isStackedRow) {
    const rowProjects = row.original.children ?? []
    const stackedProjects = organizeProjectsIntoRowsLegacy(
      rowProjects?.map((p) => p.value.value)
    )

    return {
      rowHeight: calculateRowHeight('stacked-row', stackedProjects),
      stackedProjects,
    }
  }

  return {
    rowHeight: calculateRowHeight(row.original.value.type),
  }
}
