import { Banner, type BannerProps } from '@motion/ui/base'

import { useDeleteTask, useTaskUpdater } from '~/areas/tasks/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useCloseTaskModal, useTaskForm } from '../hooks'

export const ArchiveBanner = () => {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  const deleteTask = useDeleteTask()
  const closeModal = useCloseTaskModal()

  const { form } = useTaskForm()

  const restoreTask = useCallback(
    async (taskId: string) => {
      const task = await readTask(taskId)

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot restore a Chunk task')
      }

      return updateTask(task, { archivedTime: null })
    },
    [readTask, updateTask]
  )

  const bannerActions: BannerProps['actions'] = [
    {
      label: 'Restore task',
      onAction: async () => {
        const taskId = form.getValues('id')
        if (!taskId) return

        try {
          await restoreTask(taskId)
        } catch (e) {
          showErrorToast(e)
        }
      },
    },
    {
      label: 'Delete permanently',
      onAction: () => {
        const taskId = form.getValues('id')
        if (!taskId) return

        void deleteTask(taskId, { onConfirmation: () => closeModal() })
      },
    },
  ]

  return (
    <Banner sentiment='error' size='small' actions={bannerActions}>
      This task is archived.
    </Banner>
  )
}
