import { useLookup } from '~/global/cache'
import {
  BreadcrumbsLabel,
  type BreadWrapperPiece,
  FolderLabel,
} from '~/global/components/labels'
import { useMemo } from 'react'

import { useFindFolderItem } from '../hooks'

export type ConnectedFolderBreadcrumbsProps = {
  leafNodeId: string | null
  size?: 'small' | 'normal'
  abbreviated?: 'start' | 'middle' | 'none'
  clickable?: boolean
  nameOnly?: boolean
  omitWorkspace?: boolean
}

export const ConnectedFolderBreadcrumbs = (
  props: ConnectedFolderBreadcrumbsProps
) => {
  const {
    leafNodeId,
    abbreviated = 'none',
    size = 'normal',
    clickable = false,
    nameOnly = false,
    omitWorkspace = false,
  } = props
  const findFolderItem = useFindFolderItem()
  const lookupFn = useLookup()

  const crumbs: BreadWrapperPiece[] = useMemo(() => {
    let crumbs: BreadWrapperPiece[] = []
    let path =
      findFolderItem((item) => item.itemId === leafNodeId)
        ?.reverse()
        .filter(
          (item) => !('type' in item && item.type === 'USER_WORKSPACES')
        ) ?? []

    for (const item of path) {
      switch (item.itemType) {
        case 'FOLDER': {
          const model = lookupFn('folders', item.itemId)
          if (model?.type === 'WORKSPACE') {
            if (!omitWorkspace) {
              crumbs.push({ type: 'workspace', value: model.targetId })
            }
            break
          }
          crumbs.push({ type: 'folder', value: item.itemId })
          break
        }
        case 'PROJECT': {
          crumbs.push({ type: 'project', value: item.itemId })
          break
        }
      }
    }
    if (abbreviated === 'middle' && crumbs.length > 2) {
      crumbs = [
        crumbs[0],
        { type: 'text', value: '...' },
        crumbs[crumbs.length - 1],
      ]
    }
    if (abbreviated === 'start' && crumbs.length > 2) {
      crumbs = [
        { type: 'text', value: '...' },
        crumbs[crumbs.length - 2],
        crumbs[crumbs.length - 1],
      ]
    }
    return crumbs
  }, [abbreviated, findFolderItem, leafNodeId, lookupFn, omitWorkspace])

  if (crumbs.length < 1) {
    return <FolderLabel value={null} size={size} nameOnly={nameOnly} />
  }

  return (
    <BreadcrumbsLabel
      crumbs={crumbs}
      size={size}
      clickable={clickable}
      nameOnly={nameOnly}
    />
  )
}
