import type {
  Location as RouterLocation,
  Path as RoutePath,
} from 'react-router'
/**
 * Formats a route to use as a hash
 * @param route the route to format
 * @returns the formatted hash
 */
export function formatHash(route: string) {
  if (route.startsWith('#')) {
    route = route.slice(1)
  }
  if (!route.startsWith('/')) {
    route = '/' + route
  }
  return '#' + route
}

/**
 * Strips the #/ from a hash
 * @param hash the hash to strip
 * @returns the route
 */
export function routeFromHash(hash: string) {
  if (hash.startsWith('#')) {
    hash = hash.slice(1)
  }
  if (hash.startsWith('/')) {
    hash = hash.slice(1)
  }
  return hash
}

export function currentRoute() {
  return routeFrom(window.location)
}

export function locationToString(
  loc: Location | URL | RouterLocation | RouteParams
) {
  return [loc.pathname, loc.search, loc.hash].filter(Boolean).join('')
}

export type RouteParams = {
  pathname: string
  hash: string
  search: string
}
/**
 * Returns a normalized relative path from a location.
 * For 'legacy' app, it just returns the `hash`
 * For the combined app it strips offs the `/web` and makes it appear like a hash
 * @param loc
 * @returns The normalized path in the form of /<page>?<search>
 */
export function routeFrom(loc: RouteParams) {
  return normalizeRoute(loc)
}

/**
 * Returns a full url for the route
 * @param route
 * @returns
 */
export function uriFromRoute(route: string) {
  if (route.startsWith('#')) {
    route = route.slice(1)
  }
  if (route.startsWith('/')) {
    route = route.slice(1)
  }

  if (route.startsWith('web/')) {
    route = route.slice(4)
  }
  return new URL(`/web/${route}`, window.location.origin)
}

export function pathForRoute(route: string): RoutePath {
  const uri = uriFromRoute(route)
  return {
    pathname: uri.pathname,
    search: uri.search,
    hash: uri.hash,
  }
}

export function relativeUriFromRoute(route: string) {
  const uri = uriFromRoute(route)
  return locationToString(uri)
}

function normalizeRoute(route: RouteParams) {
  let { pathname: path, hash, search } = route

  hash = migrate(hash)

  if (path === '/web') {
    path = '/web/'
  }
  if (path.startsWith('/')) {
    path = path.slice(1)
  }
  if (path.startsWith('web/')) {
    path = path.slice(4)
  }

  return '/' + [path, hash].filter(Boolean).join('/') + search
}

function migrate(path: string) {
  if (path.startsWith('#')) {
    path = path.slice(1)
  }
  if (path.startsWith('/')) {
    path = path.slice(1)
  }

  if (path === 'schedulerSettings' || path === 'bookingSettings') {
    path = 'settings/booking'
  }

  if (path === 'scheduleSettings') {
    path = 'settings/schedule'
  }

  return path
}

export function normalizeUri(params: RouteParams) {
  const route = normalizeRoute(params)

  return `/web${route}`
}

export function navigateToMobileSplashScreen() {
  window.location.href = 'https://www.usemotion.com/checkout-success-mobile'
}
