import { isFlowProject } from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

export function shouldShowReviewDatesButton(project: ProjectSchema) {
  const projectHasBehindDeadlineStatus =
    project.deadlineStatus === 'scheduled-past-deadline' ||
    project.deadlineStatus === 'missed-deadline'

  return projectHasBehindDeadlineStatus && isFlowProject(project)
}
