import { type BulkProjectUpdateSchema } from '@motion/shared/projects'
import { type Group } from '@motion/utils/array'
import { type BulkUpdateSchema } from '@motion/zod/client'

import { useBulkUpdateProjects } from '~/areas/project/hooks'
import { useBulkUpdateTasks } from '~/areas/tasks/hooks'
import { useCallback } from 'react'

import { type BulkOpsItem, useBulkOpsGroups } from '../../contexts'

/**
 * This hook is used to update all selected tasks given a type of update action, such as `bulk-archive`, `bulk-delete`.
 * More commonly used by derived version of this hook such as `useBulkArchiveTasks`.
 */
export function useBulkUpdateSelection() {
  const { workspaceGroups } = useBulkOpsGroups()

  const bulkUpdateTasks = useBulkUpdateTasks()
  const bulkUpdateProjects = useBulkUpdateProjects()

  const bulkUpdateTaskSelection = useCallback(
    async (
      updates:
        | BulkUpdateSchema
        | ((group: Group<BulkOpsItem, string>) => BulkUpdateSchema)
    ) => {
      const updateData = workspaceGroups.map((group) => {
        return {
          currentWorkspaceId: group.key,
          taskIds: group.items.map((t) => t.id),
          update: typeof updates === 'function' ? updates(group) : updates,
        }
      })

      return bulkUpdateTasks(updateData)
    },
    [workspaceGroups, bulkUpdateTasks]
  )

  const bulkUpdateProjectSelection = useCallback(
    async (
      updates:
        | BulkProjectUpdateSchema
        | ((group: Group<BulkOpsItem, string>) => BulkProjectUpdateSchema)
    ) => {
      const updateData = workspaceGroups.map((group) => {
        return {
          currentWorkspaceId: group.key,
          projectIds: group.items.map((p) => p.id),
          update: typeof updates === 'function' ? updates(group) : updates,
        }
      })

      return bulkUpdateProjects(updateData)
    },
    [workspaceGroups, bulkUpdateProjects]
  )

  return {
    bulkUpdateTaskSelection,
    bulkUpdateProjectSelection,
  }
}
