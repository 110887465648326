import { Button, type ButtonProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type AnalyticEvents } from '@motion/web-base/analytics/events'

import { forwardRef } from 'react'

type TaskItemActionButtonProps = {
  children: React.ReactNode | string
  action: AnalyticEvents['AGENDA_TASK_ITEM_BUTTON_CLICKED']['action']
  onClick?: (e: React.MouseEvent) => void
} & ButtonProps

export const TaskItemActionButton = forwardRef<
  HTMLButtonElement,
  TaskItemActionButtonProps
>(({ children, onClick, action, ...buttonProps }, ref) => {
  return (
    <Button
      size='small'
      sentiment='neutral'
      variant='outlined'
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        recordAnalyticsEvent('AGENDA_TASK_ITEM_BUTTON_CLICKED', {
          action,
        })
        onClick?.(e)
      }}
      ref={ref}
      {...buttonProps}
    >
      {children}
    </Button>
  )
})

TaskItemActionButton.displayName = 'TaskItemActionButton'
