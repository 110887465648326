import { TrashSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useSetting } from '@motion/web-common/settings'

import { Events } from '~/analyticsEvents'
import {
  CalendarArrows,
  DateTitle,
} from '~/areas/calendar/components/calendar-header/components'
import { TimezoneGroup } from '~/areas/calendar/components/calendar-header/timezone-group/timezone-group'
import {
  useCalendarDateNavigation,
  useCalendarState,
} from '~/areas/calendar/hooks'
import { clearSelectedAvailabilityFullCalendarEvents } from '~/state/calendarEvents/calendarEventsSlice'
import { useAppDispatch } from '~/state/hooks'

export const BookingTimeslotCalendarHeader = () => {
  const [state] = useCalendarState()
  const dispatch = useAppDispatch()
  const [calendarStartDay] = useSetting('calendarStartDay', 'sunday')
  const calendarNavigation = useCalendarDateNavigation()

  const handleClearAvailabilities = () => {
    dispatch(clearSelectedAvailabilityFullCalendarEvents())
    recordAnalyticsEvent(Events.CALENDAR_AVAILABILITIES_CLEAR)
  }

  const baseDateTime = state.selectedDate

  return (
    <div className='relative py-3 px-4 flex flex-row items-center justify-between'>
      <div className='flex gap-1.5'>
        <TimezoneGroup localTimezone={baseDateTime.zoneName} />

        <Button
          variant='outlined'
          sentiment='neutral'
          size='small'
          onClick={() => void calendarNavigation.today()}
        >
          Today
        </Button>

        <CalendarArrows
          forward={calendarNavigation.forward}
          back={calendarNavigation.back}
        />

        <DateTitle
          baseDate={baseDateTime}
          timezone={baseDateTime.zoneName}
          calendarStartDay={calendarStartDay}
          view={state.calendarView === 'week' ? 'weekly' : 'daily'}
        />
      </div>

      <Button
        size='small'
        variant='outlined'
        sentiment='neutral'
        onClick={handleClearAvailabilities}
      >
        <TrashSolid />
        Clear all times
      </Button>
    </div>
  )
}
