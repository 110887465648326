import { formatToShortTaskDuration } from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useTaskDurationData } from '~/areas/tasks/hooks'
import type { TaskWithRelations } from '~/global/proxies'

import { CellAction } from '../components'

export type TaskDurationCellProps = {
  task: TaskWithRelations
}

export const TaskDurationCell = ({ task }: TaskDurationCellProps) => {
  const { onChange, disabled } = useTaskDurationData(task)

  return (
    <DurationDropdown value={task.duration} onChange={onChange}>
      <CellAction disabled={disabled}>
        <span className='truncate'>
          {formatToShortTaskDuration(task.duration)}
        </span>
      </CellAction>
    </DurationDropdown>
  )
}
