import { classed } from '@motion/theme'

import { SharedGridShell } from '~/areas/task-project/components/styled'

export const GridShell = classed('div', SharedGridShell, {
  base: `
  `,
  variants: {
    withFooter: {
      true: `
      [[data-columns="2"]_&]:[grid-template-areas:'header_aside''main_aside''mainfooter_asidefooter']
      [[data-columns="3"]_&]:[grid-template-areas:'header_aside_list''main_aside_list''mainfooter_aside_listfooter']
    `,
      false: `
    [[data-columns="2"]_&]:[grid-template-areas:'header_aside''main_aside''mainfooter_aside']
    [[data-columns="3"]_&]:[grid-template-areas:'header_aside_list''main_aside_list''mainfooter_aside_list']
    `,
    },
  },
})

const MainContentPaddings = classed('div', {
  base: `
    px-6
    modal-lg:px-10
    `,
})

export const MainHeader = classed(MainContentPaddings, {
  base: `
    [grid-area:header]
    py-4
    flex flex-col gap-2
    modal-lg:pt-6
  `,
})

export const MainScrollableContent = classed(MainContentPaddings, {
  base: `
    [grid-area:main]
    overflow-auto
    flex flex-col gap-3
    modal-lg:pb-6
  `,
})

export const ShellMainFooter = classed('footer', {
  base: `
    [grid-area:mainfooter]
    py-3
    px-6
    modal-lg:py-4
    modal-lg:px-10
    flex items-center
  `,
})

export const SidebarDetailsStyles = classed('aside', {
  base: `
    bg-semantic-neutral-surface-raised-bg-subtlest
    border-l-[0.5px] border-r-[0.5px] border-semantic-neutral-border-default
  `,
})

export const SidebarDetails = classed('aside', SidebarDetailsStyles, {
  base: `
  [grid-area:aside]
  h-full overflow-auto
  bg-semantic-neutral-surface-raised-bg-subtlest

  [[data-columns="2"]_&]:rounded-r-lg
  `,
})

export const SidebarDetailsFooter = classed('div', SidebarDetailsStyles, {
  base: `
    [grid-area:asidefooter]
    flex gap-3 items-center justify-end
    rounded-none rounded-br-lg
    whitespace-nowrap

    p-3
    modal-lg:px-5
    `,
})

export const SidebarListStyles = classed('aside', {
  base: `
    bg-semantic-neutral-surface-raised-bg-subtle
    rounded-tr-lg
  `,
})

export const SidebarList = classed('aside', SidebarListStyles, {
  base: `
  [grid-area:list]
  h-full overflow-auto

  [[data-columns="3"]_&]:rounded-br-lg
  `,
})

export const SidebarListFooter = classed('div', SidebarListStyles, {
  base: `
    [grid-area:listfooter]
    flex gap-3 items-center justify-end
    rounded-none rounded-br-lg
    whitespace-nowrap

    p-3
    modal-lg:px-5
    `,
})
