import { MotionCache } from '@motion/rpc-cache'
import { byValue, Compare, groupBy } from '@motion/utils/array'
import { values } from '@motion/utils/object'
import {
  type CalendarEventSchemaV2,
  type CalendarSchema,
} from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { getEventUniqueKey } from '~/areas/event/utils'
import { getCalendarEventsQueryFilters, useLookup } from '~/global/cache'
import { useMemo } from 'react'

/**
 * Returns all the events matching a specific event key
 * (computed via `getEventUniqueKey`)
 * from an initial event id
 *
 * @param eventId
 * @returns CalendarEventSchemaV2[] - list of events
 */
export function useAllEventsMatchingKey(
  eventId: CalendarEventSchemaV2['id'] | null | undefined
): CalendarEventSchemaV2[] {
  const lookup = useLookup()
  const client = useQueryClient()

  return useMemo<CalendarEventSchemaV2[]>(() => {
    if (eventId == null) return []

    const initialEvent = lookup('calendarEvents', eventId)
    if (initialEvent == null) return []

    // Doing this could eventually be very expensive depending on the number of events we have in the cache
    const allEvents = lookup('calendarEvents')
    const allEventsByEventKey = groupBy(allEvents, (e) => getEventUniqueKey(e))

    const eventsMatchingKey =
      allEventsByEventKey[getEventUniqueKey(initialEvent)] ?? []

    // Clean up all old event ids that have an old sync session id/time
    const eventIdsToRemove = getEventIdsToCleanup(eventsMatchingKey)
    if (eventIdsToRemove.length > 0) {
      MotionCache.delete(
        client,
        getCalendarEventsQueryFilters(),
        'calendarEvents',
        eventIdsToRemove
      )
      return eventsMatchingKey.filter((e) => !eventIdsToRemove.includes(e.id))
    }

    return eventsMatchingKey
  }, [client, eventId, lookup])
}

/**
 * Returns all the events (excluding recurring events) matching a specific event key that are in the list of calendars
 * (computed via `getEventUniqueKey`)
 * from an initial event id
 *
 * @param eventId
 * @returns CalendarEventSchemaV2[] - list of events excluding recurring events
 */
export function useAllEventsMatchingKeyInCalendars(
  eventId: CalendarEventSchemaV2['id'] | null | undefined,
  calendars: CalendarSchema[]
) {
  const allEvents = useAllEventsMatchingKey(eventId)

  return useMemo(() => {
    return allEvents.filter(
      (event) =>
        event.type !== 'RECURRING_EVENT' &&
        calendars.some((cal) => cal.id === event.calendarId)
    )
  }, [calendars, allEvents])
}

// exported for testing purpose
export function getEventIdsToCleanup(
  events: Pick<
    CalendarEventSchemaV2,
    'id' | 'calendarId' | 'syncSession' | 'type'
  >[]
): CalendarEventSchemaV2['id'][] {
  const eventsByCalendarId = groupBy(events, (e) => e.calendarId)

  const idsToClean = values(eventsByCalendarId).reduce<
    CalendarEventSchemaV2['id'][]
  >((acc, events) => {
    if (events.length < 2) return acc

    const [mostRecentEvent, ...otherEvents] = events.sort(
      byValue((e) => e.syncSession.createdTime, Compare.string.desc)
    )

    const olderEventIds = otherEvents
      .filter(
        (e) =>
          e.syncSession.createdTime < mostRecentEvent.syncSession.createdTime
      )
      .map((e) => e.id)

    acc.push(...olderEventIds)
    return acc
  }, [])

  return idsToClean
}
