import { QuestionMarkCircleSolid } from '@motion/icons'
import { Toggle, Tooltip } from '@motion/ui/base'

export type WarnOnToggleProps = {
  checked: boolean
  disabled?: boolean
  onChange: (warnOnPastDue: boolean) => void
}

export const WarnOnToggle = (props: WarnOnToggleProps) => {
  const { checked, onChange, disabled } = props
  return (
    <div className='flex items-center gap-1'>
      <Toggle
        checked={checked}
        disabled={disabled}
        size='small'
        onChange={() => onChange(!checked)}
      >
        Warn if past due
      </Toggle>
      <Tooltip
        content={`We'll notify you if this task is scheduled after the deadline.`}
      >
        <QuestionMarkCircleSolid className='w-4 h-4 text-semantic-neutral-icon-subtle' />
      </Tooltip>
    </div>
  )
}
