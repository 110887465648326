import { type CustomFieldValuesSchema } from '@motion/shared/custom-fields'

import { type WorkspaceCustomField } from '~/areas/custom-fields/types'

export const getCustomFieldValueTotalMeta = <
  T extends { customFieldValues: Record<string, CustomFieldValuesSchema> },
>(
  items: T[],
  customField: WorkspaceCustomField,
  workspaceIdToFieldId: Record<string, string> | undefined
) => {
  if (
    customField.type !== 'multiPerson' &&
    customField.type !== 'person' &&
    customField.type !== 'multiSelect' &&
    customField.type !== 'number' &&
    customField.type !== 'select'
  )
    return null

  const sameNameFieldIds = Object.values(
    workspaceIdToFieldId ?? { [customField.workspaceId]: customField.id }
  )

  const values = items
    .flatMap((item) => {
      if (!('customFieldValues' in item)) return null

      return sameNameFieldIds.flatMap(
        (fieldId) => item.customFieldValues[fieldId]?.value
      )
    })
    .filter(Boolean)

  if (customField.type === 'number') {
    const count = values.reduce<number>((acc, value) => {
      if (typeof value !== 'number') return acc

      return acc + value
    }, 0)

    if (count === 0) return null

    return {
      count:
        customField.metadata.format === 'percent'
          ? `${count.toFixed(2)}%`
          : count,
    }
  }

  if (customField.type === 'multiSelect' || customField.type === 'select') {
    const count = [...new Set(values)].length
    if (count === 0) return null

    return {
      count,
      suffix: {
        one: 'item',
        other: 'items',
      },
    }
  }

  if (customField.type === 'person' || customField.type === 'multiPerson') {
    const count = [...new Set(values)].length
    if (count === 0) return null

    return {
      count,
      suffix: {
        one: 'person',
        other: 'people',
      },
    }
  }
}
