import { useDependantState } from '@motion/react-core/hooks'
import { TextField, type TextFieldProps } from '@motion/ui/forms'
import { getWindowData } from '@motion/web-base/env'
import { type TaskSchema } from '@motion/zod/client'

import { type KeyboardEvent, useState } from 'react'

export type NameFieldProps = {
  value: TaskSchema['name']
  onChange: (value: TaskSchema['name']) => void
  triggerOnChangeOnBlur?: boolean
  error?: boolean
  disabled?: TextFieldProps['disabled']
  placeholder?: TextFieldProps['placeholder']
  label?: TextFieldProps['label']
}

export const NameField = ({
  value,
  onChange,
  triggerOnChangeOnBlur = false,
  error = false,
  disabled = false,
  placeholder,
  label,
}: NameFieldProps) => {
  const { isMac } = getWindowData()

  const [isDirty, setIsDirty] = useState(false)
  const [internalName, setInternalName] = useDependantState(
    () => value,
    [value],
    { freezeDependencyUpdates: isDirty }
  )

  const internalOnChange: TextFieldProps['onChange'] = async (value) => {
    if (triggerOnChangeOnBlur) {
      setInternalName(value)
      setIsDirty(true)
    } else {
      onChange(value)
    }
  }

  const submitChange = async () => {
    if (triggerOnChangeOnBlur) {
      const finalName = internalName.trim()
      if (value === finalName) return

      setIsDirty(false)
      onChange(finalName)
    }
  }

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      (isMac && e.metaKey && e.key === 's') ||
      (!isMac && e.ctrlKey && e.key === 's') ||
      e.key === 'Escape'
    ) {
      e.preventDefault()
      submitChange()
    }
  }

  return (
    <TextField
      multiline
      autoFocus
      noResize
      sentiment={error ? 'error' : undefined}
      placeholder={placeholder}
      label={label}
      labelHidden
      size='large'
      disabled={disabled}
      value={internalName}
      onChange={internalOnChange}
      onBlur={() => submitChange()}
      onKeyDown={onKeyDown}
      autoSize={{ minRows: 1, maxRows: 5 }}
      variant='minimal'
      // @ts-expect-error - TODO tleunen - Add support for passing a `font-` tailwind className only
      className='font-semibold'
    />
  )
}
