import type { NormalTaskSchema } from '@motion/rpc-types'

import type { LookupFn } from '~/global/cache'

/**
 * Finds the chain of blockers leading to an unfit task using depth-first search.
 * Returns the chain of tasks containing all unfit blockers.
 * Returns an empty array if no unfit blocker is found.
 */
export const findUnfitBlockerChain = (
  lookup: LookupFn,
  immediateBlockers: NormalTaskSchema[],
  visited: Set<string> = new Set()
): NormalTaskSchema[] => {
  let unfitChain: NormalTaskSchema[] = []

  for (const blocker of immediateBlockers) {
    // Skip if we've already visited this task
    if (visited.has(blocker.id)) continue

    // Mark this task as visited
    visited.add(blocker.id)

    // Get blockers of this blocker
    const blockerTasks = blocker.blockedByTaskIds
      .map((id) => lookup('tasks', id))
      .filter((t): t is NormalTaskSchema => t?.type === 'NORMAL')

    // Recursively get chains from this blocker's blockers, passing the visited set
    const chainedBlockers = findUnfitBlockerChain(lookup, blockerTasks, visited)

    // If this blocker is unfit or we found unfit blockers in the chain
    if (isBlockerUnfit(blocker) || chainedBlockers.length > 0) {
      unfitChain = [...chainedBlockers, blocker]

      break // We can break after finding the first chain since we want one complete path
    }
  }

  return unfitChain
}

export function isBlockerUnfit(blocker: NormalTaskSchema): boolean {
  return (
    blocker.isUnfit ||
    blocker.scheduledStatus?.startsWith('UNFIT') ||
    !blocker.isAutoScheduled
  )
}
