import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useCreateWorkspaceMember as useCreateWorkspaceMemberCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useCreateWorkspaceMember = () => {
  const {
    mutateAsync: createWorkspaceMember,
    isLoading,
    isError,
  } = useCreateWorkspaceMemberCall()

  const context = useRouteAnalyticsMetadata()

  return {
    createWorkspaceMember: useCallback(
      async (data: { workspaceId: WorkspaceSchema['id']; userId: string }) => {
        try {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBER', {
            view: context.page === 'settings' ? 'settings' : 'inline',
          })

          const response = await createWorkspaceMember(data)

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'create-workspace-member',
            },
          })

          showErrorToast(e)
        }
      },
      [createWorkspaceMember, context.page]
    ),
    isLoading,
    isError,
  }
}
