import { FieldButton } from '@motion/ui/base'
import { formatTime } from '@motion/ui-logic'
import { type DateLike, parseDate } from '@motion/utils/dates'

import { SearchableTimeDropdown } from '~/areas/project-management/components'
import { type DateTime } from 'luxon'
import { useCallback } from 'react'

type TimeFieldProps = {
  startDateTime: DateLike
  value: DateLike
  onChange: (value: DateLike) => void
  ariaLabel?: string
  showDuration?: boolean
}

export function TimeField({
  value,
  startDateTime,
  onChange,
  ariaLabel,
  showDuration,
}: TimeFieldProps) {
  const parsedValue = parseDate(value)
  const parsedStartDateTime = parseDate(startDateTime)
  const formatted = formatTime(value)

  const onTimeChange = useCallback(
    (value: DateTime) => {
      onChange(value)
    },
    [onChange]
  )

  return (
    <SearchableTimeDropdown
      startDateTime={parsedStartDateTime}
      value={parsedValue.toISO()}
      onChange={onTimeChange}
      showDuration={showDuration}
    >
      <FieldButton fullWidth aria-label={ariaLabel} size='small'>
        {formatted}
      </FieldButton>
    </SearchableTimeDropdown>
  )
}
