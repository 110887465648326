import { type Schedule } from '@motion/rpc-types'

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'
import type { RootState } from './types'

import { defaultSchedules } from '../storageConstants'
import {
  type MotionTheme,
  type ScheduleCollection,
  type UserSettings,
} from '../types/settingsTypes'

export interface SettingsState extends UserSettings {}

export const initialSettingsState: SettingsState = {
  schedules: defaultSchedules,
}

const settingsService = getProxy('SettingsService')

export const updateSchedules = createAsyncThunk(
  'settings/updateSchedules',
  async (schedules: Record<string, Schedule>) => {
    return await settingsService.updateSchedules(schedules)
  }
)

export const deleteSchedule = createAsyncThunk(
  'settings/deleteSchedule',
  async (scheduleId: string) => {
    return await settingsService.deleteSchedule(scheduleId)
  }
)

export const settingsSlice = createSlice({
  initialState: initialSettingsState,
  name: 'Settings',
  reducers: {
    reset: () => initialSettingsState,
    setSchedules: (
      state: SettingsState,
      action: PayloadAction<ScheduleCollection>
    ) => {
      state.schedules = action.payload
    },
    setTheme: (state: SettingsState, action: PayloadAction<MotionTheme>) => {
      state.theme = action.payload
      window.localStorage.setItem('theme', action.payload)
    },
  },
})

export const { setSchedules, setTheme, reset } = settingsSlice.actions

export const selectSchedules = (state: RootState) => state.settings.schedules
export const selectTheme = (state: RootState) =>
  state.settings.theme ?? window?.localStorage?.getItem('theme') ?? 'light'

export const settingsReducer = settingsSlice.reducer
