import { type Row } from '@tanstack/react-table'
import {
  defaultRangeExtractor,
  type Range,
  useVirtualizer,
} from '@tanstack/react-virtual'
import { useCallback, useMemo, useRef } from 'react'

import { ColumnNameContainer } from './column-name-container'
import { StackedRowContainer } from './stacked-row-container'

import { type GroupedNode } from '../../grouping'
import { useSidebarSize } from '../hooks'
import {
  BASE_SIDEBAR_WIDTH,
  OOO_ROW_TYPE,
  ZIndexMap,
} from '../shared-constants'

type VirtualListProps<T> = {
  rows: Row<T>[]
  maxDepth: number
  toggleExpandAllRows: (expand: boolean) => void
}
export const VirtualList = <T extends GroupedNode>(
  props: VirtualListProps<T>
) => {
  const { rows, maxDepth, toggleExpandAllRows } = props

  const parentRef = useRef<HTMLDivElement>(null)

  const sidebarSize = useSidebarSize()

  const stickyIndexes = useMemo(
    () =>
      rows.reduce((indices, row, index) => {
        if (row.original.value.type === OOO_ROW_TYPE) {
          indices.push(index)
        }
        return indices
      }, [] as number[]),
    [rows]
  )

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 80,
    getScrollElement: () => parentRef.current,
    getItemKey: (index) => rows[index].id,
    overscan: 0,
    rangeExtractor: useCallback(
      (range: Range) => {
        const next = new Set([
          ...[...stickyIndexes]
            .reverse()
            .filter((index) => range.startIndex >= index),
          ...defaultRangeExtractor(range),
        ])

        return [...next].sort((a, b) => a - b)
      },
      [stickyIndexes]
    ),
  })

  const virtualItems = rowVirtualizer.getVirtualItems()

  return (
    <div
      className='overflow-y-auto w-full h-full overflow-x-hidden pb-4'
      ref={parentRef}
    >
      <div
        className='relative min-h-full'
        style={{
          height: rowVirtualizer.getTotalSize(),
        }}
      >
        <ColumnNameContainer
          style={{
            width: sidebarSize,
            minWidth: BASE_SIDEBAR_WIDTH,
            zIndex: ZIndexMap.nameBackgroundContainer,
          }}
        />
        {virtualItems.map((virtualItem, currentIndex) => {
          const row = rows[virtualItem.index]
          const rowAbove = rows[virtualItem.index - 1]

          return (
            <div
              key={row.id}
              ref={rowVirtualizer.measureElement}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: virtualItem.start,
              }}
              data-index={virtualItem.index}
            >
              <StackedRowContainer
                row={row}
                rowAbove={rowAbove}
                maxDepth={maxDepth}
                toggleExpandAllRows={toggleExpandAllRows}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
