import { Button, showToast, TitleModal } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useDeleteAccount } from '~/global/rpc/user'
import { useAuth } from '~/localServices/auth'

export function ConnectedDeleteAccountModal({
  close,
}: ModalTriggerComponentProps<'delete-account'>) {
  const { logout } = useAuth()

  const { mutateAsync: deleteAccount } = useDeleteAccount({
    onError: (error) => {
      if (error.message) {
        showToast('error', `Failed to delete account: ${error.message}`)
        return
      }

      showToast('error', 'Failed to delete account')
    },
  })
  const handleDelete = async () => {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_DELETE_ACCOUNT')
    await deleteAccount()
    showToast('neutral', 'Account deletion initiated.', { duration: 10_000 })
    setTimeout(() => {
      void logout()
    }, 3000)
  }
  return <DeleteAccountModal close={close} handleDelete={handleDelete} />
}
type DeleteAccountModalProps = ModalTriggerComponentProps<'delete-account'> & {
  handleDelete: () => Promise<void>
}
export function DeleteAccountModal({
  close,
  handleDelete,
}: DeleteAccountModalProps) {
  return (
    <TitleModal
      cancelAction={{
        text: 'Cancel',
      }}
      onClose={close}
      title='More Options'
      visible
    >
      <div className='flex flex-col items-start gap-2.5'>
        <div className='flex gap-12 w-[568px] items-center'>
          <div className='flex flex-col items-start gap-1 flex-[1_0_0]'>
            <span className='text-semantic-neutral-text-default text-sm font-semibold'>
              Permanently delete data
            </span>
            <span className='text-semantic-neutral-text-subtle text-sm font-normal h-[60px]'>
              Permanently delete my account, Motion data and disconnect all
              connected calendars from Motion.
            </span>
          </div>
          <Button onClick={handleDelete} sentiment='error' size='small'>
            Permanently delete data
          </Button>
        </div>
        <span className='text-tag-red-strong-bg text-sm font-bold w-[568px]'>
          Do not use this option if you simply want to cancel your trial or
          subscription, which you can do under Billing: Manage Plan.
        </span>
      </div>
    </TitleModal>
  )
}
