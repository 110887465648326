import { SearchableList } from '@motion/ui/base'

import { FilterDropdownButton } from './filter-dropdown-button'
import { type ArrayConditionOption, type ConditionOption } from './types'

type ConditionButtonProps = {
  value: string
  options: ConditionOption[]
  onChange(value: string): void
}

export const ConditionButton = (props: ConditionButtonProps) => {
  const selected =
    props.options.find((x) => x.id === props.value) ?? props.options[0]

  if (props.options.length === 1) {
    return (
      <div className='px-[6px] py-[2px] flex items-center'>
        {selected?.label || 'unknown'}
      </div>
    )
  }

  return (
    <FilterDropdownButton
      isInverted={selected.id === 'true'}
      renderDropdown={({ close }) => (
        <ConditionSelector
          conditions={props.options}
          value={selected.id}
          onSelect={(value: string) => {
            props.onChange(value)
            close()
          }}
        />
      )}
    >
      {selected?.label || 'unknown'}
    </FilterDropdownButton>
  )
}

export type ConditionSelectorProps = {
  value: string | null
  conditions: ConditionOption[]
  onSelect(value: string | string[]): void
}

export type ConditionArraySelectorProps = {
  value: string[] | null
  conditions: ArrayConditionOption[]
  onSelect(value: string[] | string): void
}

export const ConditionSelector = (
  props: ConditionSelectorProps | ConditionArraySelectorProps
) => {
  return (
    <SearchableList
      itemType='select'
      searchable={false}
      renderItem={(item) => item.label}
      computeSelected={(item) =>
        Array.isArray(item.id)
          ? item.id.length === props.value?.length &&
            item.id.every((elem) => props.value?.includes(elem))
          : item.id === props.value
      }
      items={props.conditions}
      computeKey={(item) => {
        if (Array.isArray(item.id)) {
          return item.id.join(',')
        }
        return item.id
      }}
      onSelect={(item: ConditionOption | ArrayConditionOption) =>
        props.onSelect(item.id)
      }
    />
  )
}
