import { useDependantState } from '@motion/react-core/hooks'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useProject } from '~/global/hooks'
import { useCallback, useState } from 'react'

import { DescriptionEditor } from '../../../description-editor'

type DescriptionTabProps = {
  projectId: ProjectSchema['id']
}

export function DescriptionTab({ projectId }: DescriptionTabProps) {
  const project = useProject(projectId)
  const updateProject = useProjectUpdater()

  if (project == null) {
    throw new Error('Project not found when rendering DescriptionTab')
  }

  const [isDirty, setIsDirty] = useState(false)
  const [internalDescription, setInternalDescription] = useDependantState(
    () => project.description,
    [project.description],
    { freezeDependencyUpdates: isDirty }
  )

  const onChange = useCallback(
    (value: string) => {
      setIsDirty(true)
      setInternalDescription(value)
    },
    [setInternalDescription]
  )

  // Save the description when the editor loses focus
  const onBlur = useCallback(
    async (value: string) => {
      if (!isDirty) return

      await updateProject(project, {
        description: value,
      })

      setInternalDescription(value)
      setIsDirty(false)
    },
    [isDirty, project, setInternalDescription, updateProject]
  )

  return (
    <div className='px-3 mt-2 pb-2 h-full overflow-auto'>
      {project && (
        <DescriptionEditor
          value={internalDescription}
          onChange={onChange}
          onBlur={onBlur}
          workspaceId={project?.workspaceId}
          projectId={projectId}
          autoFocus={false}
          variant='sidepanel'
        />
      )}
    </div>
  )
}
