import { type UploadedFileSchema } from '@motion/rpc-types'
import { isPlaceholderId } from '@motion/shared/identifiers'
import { Tooltip } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { Attachments, AttachmentUploadButton } from '~/areas/attachments'
import { UncontrolledCollapsableContainer } from '~/areas/task-project/components'
import { useProjectDefinition } from '~/global/hooks'
import { useCallback, useMemo, useState } from 'react'

import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const TaskDefinitionAttachmentsCollapsable = () => {
  const modalApi = useModalApi()
  const {
    form: { watch },
  } = useTaskDefinitionForm()
  const { flowFormData } = useTaskDefinitionModalState()
  const taskId = watch('id')

  const parentFlowTemplate = useProjectDefinition(flowFormData?.id)
  const attachmentIds = useMemo(() => {
    return (
      parentFlowTemplate?.stages
        .flatMap((stage) => stage.tasks)
        .find((task) => task.id === taskId)?.uploadedFileIds || []
    )
  }, [parentFlowTemplate, taskId])

  const workspaceId = watch('workspaceId')
  const [expanded, setExpanded] = useState(false)
  const handleAttachmentClick = useCallback(
    (attachmentId: UploadedFileSchema['id']) => {
      modalApi.open('attachment-preview', {
        attachmentId,
        attachmentIds,
      })
    },
    [modalApi, attachmentIds]
  )

  if (!taskId || !workspaceId) {
    return null
  }

  const handleToggle = () => setExpanded((expanded) => !expanded)
  const handleAttachmentUpload = () => setExpanded(true)

  return (
    <UncontrolledCollapsableContainer
      title={`Attachments (${attachmentIds.length})`}
      headerActions={
        <Tooltip
          content={
            isPlaceholderId(taskId)
              ? 'Cannot upload attachments until the template is saved'
              : undefined
          }
        >
          <AttachmentUploadButton
            disabled={isPlaceholderId(taskId)}
            targetId={taskId}
            targetType='TASK_DEFINITION'
            workspaceId={workspaceId}
            onUpload={handleAttachmentUpload}
          />
        </Tooltip>
      }
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='pl-7'>
        <Attachments
          attachmentIds={attachmentIds}
          targetId={taskId}
          targetType='TASK_DEFINITION'
          onAttachmentClick={handleAttachmentClick}
        />
      </div>
    </UncontrolledCollapsableContainer>
  )
}
