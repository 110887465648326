import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { type SupportedStringFilterSchema } from '@motion/ui-logic/pm/data'
import { createLookupByKey } from '@motion/utils/object'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'

import { TextFilterDropdown } from './text-filter-dropdown'
import { type TextFilterAllowedFields } from './types'
import { getDisplayText } from './utils'

import { type DropdownFilterProps } from '../../../../types'
import { FilterItem } from '../../filter-item'
import { getNarrowedFilter } from '../utils'

type Props = DropdownFilterProps & {
  groupedFields: CustomFieldSchemaByType<TextFilterAllowedFields>[]
}

export const ConnectedCustomFieldTextFilter = (props: Props) => {
  const { groupedFields } = props
  const firstField = groupedFields[0]
  const [filters, setFilterValue] = useCustomFieldFilter(
    props.target,
    firstField
  )
  const filter = getNarrowedFilter(filters)

  const applyFilter = (schema: SupportedStringFilterSchema | null) => {
    if (schema == null) {
      setFilterValue(null)
    } else {
      const newFilter = schema
      if (newFilter.operator !== 'empty' && newFilter.value == null) {
        newFilter.value = ''
      }
      const record = createLookupByKey(groupedFields, 'id', () => newFilter)
      setFilterValue(record)
    }
  }

  const displayText = getDisplayText(filters)

  return (
    <FilterItem
      label={firstField.name}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setFilterValue(null)}
      canInvert
      inverted={filter?.inverse}
      onInvertChanged={(invert) => {
        if (filter == null) return
        applyFilter({ ...filter, inverse: invert })
      }}
      renderDropdown={({ close }) => (
        <TextFilterDropdown
          target={props.target}
          customField={firstField}
          onSubmit={applyFilter}
          close={close}
        />
      )}
    >
      {displayText != null ? (
        <div className='flex gap-1 items-center overflow-hidden'>
          <span className='truncate'>{displayText}</span>
        </div>
      ) : null}
    </FilterItem>
  )
}
