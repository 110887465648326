import { Toggle } from '@motion/ui/base'

import { TeamScheduleActiveViewOptionsKey, useViewOptions } from '../../context'

export type WeekendsToggleProps = {
  checked: boolean
  onChange(value: boolean): void
}
export const WeekendsToggle = (props: WeekendsToggleProps) => {
  return (
    <Toggle
      size='small'
      checked={props.checked}
      onChange={(e) => props.onChange(e.target.checked)}
    >
      Weekends
    </Toggle>
  )
}

export const ConnectedWeekendsToggle = () => {
  const [viewOptions, setViewOptions] = useViewOptions(
    TeamScheduleActiveViewOptionsKey
  )
  return (
    <WeekendsToggle
      checked={!viewOptions.hideWeekends}
      onChange={(value) => {
        setViewOptions((prev) => ({
          ...prev,
          hideWeekends: !value,
        }))
      }}
    />
  )
}
