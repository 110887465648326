import { convertScheduleIntoEvents } from '@motion/ui-logic'
import { useSetting } from '@motion/web-common/settings'
import {
  type CalendarEventSchemaV2,
  type CalendarSchema,
} from '@motion/zod/client'

import { toEventModel } from '~/components/Calendar/components/Main/utils'
import { type PMTaskType } from '~/components/ProjectManagement/types'
import { useLookup } from '~/global/cache'
import { useTimezoneSettings } from '~/global/hooks'
import { type ScheduledEntityWithRelations } from '~/global/proxies'
import { selectEmailAccountsMap } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { DateTime, Interval } from 'luxon'

import { useCalendarStartDay, useMyCalendars } from '../../hooks'
import {
  createNewFullCalendarMotionEvent,
  eventToFullCalendarEvent,
  type FullCalendarEvent,
  type FullCalendarTaskEvent,
  type NewEventType,
} from '../../utils'

type FullCalendarStructuredEventsArgs = {
  selectedDate: DateTime
  scheduledEntities?: ScheduledEntityWithRelations[]
  newEventData?: NewEventType
}

export const useFullCalendarStructuredEvents = ({
  selectedDate,
  scheduledEntities = undefined,
  newEventData = undefined,
}: FullCalendarStructuredEventsArgs) => {
  const lookup = useLookup()
  const emailAccountMap = useAppSelector(selectEmailAccountsMap)
  const myCalendars = useMyCalendars()
  const [schedules] = useSetting('schedules', {})
  const calendarStartDay = useCalendarStartDay()

  const workSchedule = schedules?.work

  const { defaultTimezone } = useTimezoneSettings({
    useDefaults: true,
  })

  const newEvent = newEventData
    ? createNewFullCalendarMotionEvent(newEventData)
    : null

  if (!scheduledEntities) return newEvent ? [newEvent] : []

  const fullCalendarEvents: (FullCalendarEvent | undefined)[] = []
  const blockedIntervals: Interval[] = []

  for (const entity of scheduledEntities) {
    switch (entity.type) {
      case 'TASK':
      case 'CHUNK':
        const task = entity.task
        if (!task) continue

        fullCalendarEvents.push({
          id: `task|${entity.id}`,
          start: entity.schedule.start,
          end: entity.schedule.end,
          allDay: entity.schedule.timeless,
          resourceId: 'mine',
          resourceEditable: !entity.schedule.timeless,
          durationEditable: !entity.schedule.timeless,
          startEditable: !entity.schedule.timeless,
          title: task.name,
          extendedProps: {
            task: task as PMTaskType,
            chunkInfo:
              entity.type === 'CHUNK'
                ? {
                    chunkNumber: entity.chunkNumber,
                    chunkTotal: entity.chunkTotal,
                  }
                : undefined,
          },
        } satisfies FullCalendarTaskEvent)
        break
      case 'EVENT':
        const calendarEvent = entity.event
        if (!calendarEvent) continue

        fullCalendarEvents.push(
          eventToFullCalendarEvent({
            calendarEvent,
            entity,
            emailAccountMap,
            lookupFn: lookup,
          })
        )
        if (isAllDayBlockingEvent(calendarEvent, myCalendars)) {
          blockedIntervals.push(
            Interval.fromDateTimes(
              DateTime.fromISO(entity.schedule.start),
              DateTime.fromISO(entity.schedule.end)
            )
          )
        }
        break
      case 'BLOCKING_TIMESLOT':
        blockedIntervals.push(
          Interval.fromDateTimes(
            DateTime.fromISO(entity.schedule.start),
            DateTime.fromISO(entity.schedule.end)
          )
        )
        break
    }
  }

  const nonWorkHourBackgroundEvents = workSchedule?.schedule
    ? convertScheduleIntoEvents(workSchedule.schedule, {
        baseDate: selectedDate,
        zone: defaultTimezone,
        startingDay: calendarStartDay,
      }).map((event) => ({
        ...event,
        id: `non-work-hours-${event.start}`,
        title: '',
        groupId: 'non-work-hours',
        display: 'inverse-background' as const,
      }))
    : []

  const blockingTimeslotEvents = Interval.merge(blockedIntervals).map(
    (interval) => {
      return {
        id: `blocking-timeslot-${interval.start.toISO()}`,
        title: '',
        start: interval.start.toISO(),
        end: interval.end.toISO(),
        groupId: 'blocking-timeslots',
        display: 'background' as const,
      }
    }
  )

  return [
    ...fullCalendarEvents,
    ...blockingTimeslotEvents,
    ...nonWorkHourBackgroundEvents,
    newEvent,
  ]
    .filter(Boolean)
    .flatMap(toEventModel)
}

function isAllDayBlockingEvent(
  calendarEvent: CalendarEventSchemaV2,
  userMyCalendars: CalendarSchema[]
) {
  return (
    calendarEvent.isAllDay &&
    calendarEvent.status === 'BUSY' &&
    userMyCalendars.some(
      (calendar) => calendar.id === calendarEvent.calendarUniqueId
    )
  )
}
