import { useClosure } from '@motion/react-core/hooks'
import { useActiveFilter } from '@motion/ui-logic/pm/data'

import { useEffect } from 'react'

export function useBulkOpsFilterReset(fn: () => void) {
  const [filters] = useActiveFilter()
  const closure = useClosure(fn)

  useEffect(() => {
    closure()
    // We want this to be called when the filters change
  }, [closure, filters])
}
