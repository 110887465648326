import { DocumentDuplicateSolid } from '@motion/icons'
import { showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { formatDurationTime } from '@motion/ui-logic'

import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'

import { copyToClipboard } from '../../../../../localServices/clipboard'
import { useAppSelector } from '../../../../../state/hooks'
import { selectUser } from '../../../../../state/userSlice'
import {
  defaultAvailabilityTemplate,
  reminderEmailVariables,
} from '../../../../../storageConstants'
import { Paragraph, SubParagraph, TextButton } from '../../../../Common'
import { ActionDialog } from '../../../../Common/Dialog/ActionDialog/ActionDialog'

type PopulateTemplateTextProps = {
  recipientName?: string
  reminderEmailTime: number
  conferenceLink?: string
  authorFirstName?: string
}

const populateTemplateText = (
  text: string,
  {
    recipientName = 'John',
    reminderEmailTime,
    conferenceLink = 'https://company.zoom.us',
    authorFirstName,
  }: PopulateTemplateTextProps
) => {
  let newText = text
  newText = newText.split('$Invitee first name$').join(recipientName)
  newText = newText
    .split('$Time until meeting$')
    .join(formatDurationTime(reminderEmailTime))
  newText = newText
    .split('$Meeting time$')
    .join(
      DateTime.now()
        .plus({ minutes: reminderEmailTime })
        .toFormat('h:mma, LLL d')
    )
  newText = newText.split('$Meeting link$').join(conferenceLink)
  newText = newText
    .split('$Host first name$')
    .join(authorFirstName?.split(' ')[0])
  return newText
}

interface ReminderEmailEditorProps {
  visible: boolean
  visibleHandler: (visible: boolean) => void
  reminderEmailSubject: string
  reminderEmailBody: string
  reminderEmailSubjectHandler: (reminderEmailSubject: string) => void
  reminderEmailBodyHandler: (reminderEmailBody: string) => void
  reminderEmailTime: number
}

export const ReminderEmailEditorModal = ({
  visible,
  visibleHandler,
  reminderEmailSubject,
  reminderEmailBody,
  reminderEmailSubjectHandler,
  reminderEmailBodyHandler,
  reminderEmailTime,
}: ReminderEmailEditorProps) => {
  const user = useAppSelector(selectUser)
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [populateBody, setPopulatedBody] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const initRef = useRef(false)

  const submitHandler = useCallback(() => {
    if (!subject || !body) {
      setErrorMessage('Please enter a subject and body for the email reminder.')
      return
    }
    reminderEmailSubjectHandler(subject)
    reminderEmailBodyHandler(body)
    visibleHandler(false)
  }, [
    visibleHandler,
    reminderEmailSubjectHandler,
    reminderEmailBodyHandler,
    subject,
    body,
  ])

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true
      setSubject(
        reminderEmailSubject || defaultAvailabilityTemplate.reminderEmailSubject
      )
      const newBody =
        reminderEmailBody || defaultAvailabilityTemplate.reminderEmailBody
      setBody(newBody)
      setPopulatedBody(
        populateTemplateText(newBody, {
          authorFirstName: user && user.displayName.split(' ')[0],
          reminderEmailTime,
        })
      )
    }
  }, [reminderEmailBody, reminderEmailSubject, reminderEmailTime, user])

  const bodyHandler = useCallback(
    (text: string) => {
      setBody(text)
      setPopulatedBody(
        populateTemplateText(text, {
          authorFirstName: user && user.displayName.split(' ')[0],
          reminderEmailTime,
        })
      )
    },
    [reminderEmailTime, user]
  )

  return (
    <ActionDialog
      isOpen={visible}
      onClose={() => visibleHandler(false)}
      title='Reminder email message'
      actionButtonText='Save'
      actionButtonHandler={submitHandler}
      errorMessage={errorMessage}
      size='large'
      className='w-[600px]'
    >
      <div className='flex gap-3'>
        <div className='flex w-96 flex-col justify-start gap-4'>
          <div className='flex flex-col gap-1'>
            <Paragraph className='font-semibold'>Subject</Paragraph>
            <TextField
              multiline
              placeholder='Subject'
              value={subject}
              autoSize={{ minRows: 1 }}
              onChange={(value) => {
                setSubject(value)
              }}
            />
          </div>

          <div className='flex flex-col gap-1'>
            <Paragraph className='font-semibold'>Body</Paragraph>
            <TextField
              multiline
              placeholder='Body'
              autoSize={{ minRows: 15, maxRows: 25 }}
              value={body}
              onChange={(value) => {
                bodyHandler(value)
              }}
            />
          </div>
        </div>
        <div className='flex w-52 flex-col gap-4'>
          <div className='flex flex-col gap-3'>
            <Paragraph className='font-semibold'>Example</Paragraph>
            <SubParagraph className='whitespace-pre-wrap'>
              {populateBody}
            </SubParagraph>
          </div>
          <div className='flex flex-col gap-3'>
            <Paragraph className='font-semibold'>Variables</Paragraph>
            <div className='flex flex-col gap-2'>
              {reminderEmailVariables.map((varString) => (
                <div className='flex gap-1' key={varString}>
                  <SubParagraph>{varString}</SubParagraph>
                  <TextButton
                    icon={DocumentDuplicateSolid}
                    onClick={() => {
                      void copyToClipboard({ text: varString })
                      showToast('neutral', 'Copied!')
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ActionDialog>
  )
}
