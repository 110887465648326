import { getNextRank } from '@motion/shared/pm'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useCreateStatus, useWorkspaceFns } from '~/global/hooks'

import { NewStatusModal } from './new-status-modal'

export type ConnectedNewStatusModalProps = {
  workspaceId: string
}

export function ConnectedNewStatusModal({
  workspaceId,
  close,
}: ModalTriggerComponentProps<'add-status'>) {
  const { getWorkspaceStatuses } = useWorkspaceFns()
  const { createStatus } = useCreateStatus()

  const addStatus = async (name: string, color: string) => {
    const newStatus = await createStatus({
      workspaceId,
      name,
      color,
      sortPosition: getNextRank(getWorkspaceStatuses(workspaceId).length),
      autoScheduleEnabled: true,
    })

    if (newStatus) {
      close(newStatus)
    }
  }

  return <NewStatusModal onClose={() => close()} onSave={addStatus} />
}
