import { FieldButton, type FieldButtonProps } from '@motion/ui/base'

import { LabelDropdown } from '~/global/components/dropdowns'
import { useWorkspaceLabels } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { LabelName } from '../../template-task-modal/components/form-label-dropdown'
import { useProjectForm } from '../hooks'

export type FormLabelDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
> & { showCountIfMoreThan?: number }

export const FormLabelDropdown = ({
  showCountIfMoreThan,
  ...buttonProps
}: FormLabelDropdownProps) => {
  const { form } = useProjectForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'labelIds',
    control,
  })

  const selectedLabelIds = watch('labelIds')
  const selectedWorkspaceId = watch('workspaceId')

  const labels = useWorkspaceLabels(selectedWorkspaceId)

  return (
    <LabelDropdown
      selectedLabelIds={selectedLabelIds}
      workspaceId={selectedWorkspaceId}
      onChange={(labelIds) => {
        field.onChange(labelIds)
      }}
    >
      <FieldButton {...buttonProps} wrap>
        <LabelName
          labels={labels}
          selectedIds={selectedLabelIds}
          showCountIfMoreThan={showCountIfMoreThan}
        />
      </FieldButton>
    </LabelDropdown>
  )
}
