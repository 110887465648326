import { type ModalTriggerComponentProps } from '~/areas/modals'
import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from 'react'

export type FlowsModalState = {
  dirtyTasksMap: Record<string, boolean>
  setDirtyTasksMap: Dispatch<SetStateAction<Record<string, boolean>>>
  /**
   * The mode of the modal. If undefined, it is the default mode.
   */
  mode?: ModalTriggerComponentProps<'flows-template-modal'>['mode']
}

const defaultValue: FlowsModalState = {
  dirtyTasksMap: {},
  setDirtyTasksMap: () => {},
}

export const FlowsModalStateContext = createContext(defaultValue)

export function useFlowsModalState() {
  return useContext(FlowsModalStateContext)
}
