import { MotionCache } from '@motion/rpc-cache'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { userSettingsQueryFilter } from '@motion/web-common/settings'

import { useQueryClient } from '@tanstack/react-query'
import { TaskModalBody } from '~/areas/tasks/modals/task-modal'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useEffect, useState } from 'react'

import { OptionSpaceDesktopSuccess } from './OptionSpaceDesktopSuccess/OptionSpaceDesktopSuccess'

import {
  useOnDesktopEvent,
  useSendToDesktop,
} from '../../localServices/desktop'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import {
  selectIsDesktopSubWindow,
  setIsDesktopSubWindow,
} from '../../state/mainSlice'

export const OptionSpaceDesktopContainer = () => {
  const [showTaskModal, setShowTaskModal] = useState(true)

  const dispatch = useAppDispatch()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [createdTaskId, setCreatedTaskId] = useState<string | undefined>()
  const isDesktopSubWindow = useAppSelector(selectIsDesktopSubWindow)
  const client = useQueryClient()

  const sendToDesktop = useSendToDesktop()

  useEffect(
    function setDesktopSubWindow() {
      dispatch(setIsDesktopSubWindow(true))
    },
    [dispatch]
  )

  // Hide the form and then reshow it to reset the form
  const resetForm = () => {
    setShowSuccessMessage(false)
    setShowTaskModal(false)
    setInterval(() => {
      setShowTaskModal(true)
    }, 100)
  }

  useOnDesktopEvent('openOptionSpace', () => {
    // Record analytics event for opening the task modal
    recordAnalyticsEvent('TASK_OPEN', {
      desktopOptionSpace: true,
    })

    if (showSuccessMessage) {
      resetForm()
    }
  })

  useOnDesktopEvent('updateUserSettings', (settings) => {
    MotionCache.upsert(client, userSettingsQueryFilter, settings)
    resetForm()
  })

  const closeWindow = () => {
    sendToDesktop('cancelOptionSpace')
    resetForm()
  }

  // When hiding the form, we need to reset the form
  useOnDesktopEvent('closeOptionSpace', resetForm)

  const { data: selectedTask } = useTaskByIdV2(
    { id: createdTaskId ?? '' },
    { enabled: !!createdTaskId }
  )

  if (showSuccessMessage && selectedTask != null) {
    return (
      <OptionSpaceDesktopSuccess
        createdTask={selectedTask}
        onClose={closeWindow}
        onCreateAnotherTask={() => {
          setShowSuccessMessage(false)
        }}
      />
    )
  }

  // In order to correctly track events for the child component, we need to ensure
  // desktopSubWindow is set correctly before rendering the TaskModal component
  if (!isDesktopSubWindow) return null

  // When the window is closed, we need to stop rendering the TaskModal component
  // which will make the next render using the default values again
  if (!showTaskModal) return null

  return (
    <TaskModalBody
      fromDesktopOptionSpace
      close={() => closeWindow()}
      onSave={(task) => {
        setCreatedTaskId(task?.id)
        setShowSuccessMessage(true)
      }}
    />
  )
}
