import { classed } from '@motion/theme'

import { DateTime } from 'luxon'
import { memo, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { LineContainer } from './common'

import { usePlannerProps, useScrollPosition } from '../context'
import { useScreenValues, useTimelineValues } from '../hooks'

export const TimelineDividers = memo(function TimelineDividers() {
  return (
    <div className='absolute inset-0'>
      <WeekLines />
    </div>
  )
})

const WeekLines = () => {
  const [plannerProps] = usePlannerProps()
  const [scrollPosition] = useScrollPosition()
  const { positionOffset } = useScreenValues()
  const { weekCols } = useTimelineValues()

  const weekendWidth = plannerProps.dayPx * 2

  const weekDividers = useMemo(() => {
    return weekCols.map(({ position, date }, index) => {
      const isCurrentWeek =
        date.weekNumber === DateTime.now().weekNumber + 1 &&
        date.year === DateTime.now().year
      const isNextMonth = date.month !== weekCols[index - 1]?.date.month

      if (isCurrentWeek) {
        const daysSinceStartOfWeek =
          DateTime.now().weekday - DateTime.now().startOf('week').weekday

        return (
          <div
            className='absolute top-0 flex h-full'
            key={`week-col-${date.year}-${date.month}-${date.day}`}
            style={{
              width: plannerProps.dayPx * 7,
              left: -daysSinceStartOfWeek * plannerProps.dayPx,
            }}
          >
            {isCurrentWeek && (
              <div
                className={twMerge(
                  `relative top-0 h-full bg-semantic-primary-bg-default dark:opacity-35`
                )}
                style={{
                  width: plannerProps.dayPx * 5,
                }}
              />
            )}
            <WeekendLine
              isCurrentWeek
              isNextMonth={false}
              style={{
                width: weekendWidth,
              }}
            />
          </div>
        )
      }

      return (
        <WeekendLine
          key={`week-col-${date.year}-${date.month}-${date.day}`}
          isCurrentWeek={false}
          isNextMonth={isNextMonth}
          style={{ left: position - weekendWidth, width: weekendWidth }}
        />
      )
    })
  }, [plannerProps.dayPx, weekCols, weekendWidth])

  return (
    <LineContainer
      className='border-collapse'
      style={{
        transform: `translateX(${scrollPosition + positionOffset}px)`,
      }}
    >
      {weekDividers}
    </LineContainer>
  )
}

const WeekendLine = classed('div', {
  base: `
    h-full
    bg-repeating-linear-gradient from-[4px] to-[8px]
    from-semantic-neutral-surface-bg-subtlest to-semantic-neutral-surface-bg-subtlest
  `,
  variants: {
    isCurrentWeek: {
      true: `from-semantic-primary-bg-default to-semantic-primary-bg-default relative`,
      false: `absolute`,
    },
    isNextMonth: {
      true: `border-r border-dashed border-semantic-neutral-border-strong`,
      false: ``,
    },
  },
  defaultVariants: {
    isCurrentWeek: false,
    isNextMonth: false,
  },
  compoundVariants: [
    {
      isCurrentWeek: true,
      isNextMonth: true,
      className: 'border-r border-dashed border-semantic-primary-border-strong',
    },
  ],
})
