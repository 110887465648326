import { FieldButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { getChunkDurations } from '@motion/ui-logic'

import { ModalFieldButton } from '~/areas/task-project/components'

import { SubItemIndentMarker } from './styled'

type MinChunkDurationFieldProps = {
  value: number | null
  duration: number | null
  onChange: (value: number | null) => void
}

export const MinChunkDurationField = ({
  value,
  duration,
  onChange,
}: MinChunkDurationFieldProps) => {
  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <ModalFieldButton label='Min chunk duration'>
        <MinChunkDurationDropdown
          value={value}
          duration={duration}
          onChange={onChange}
        />
      </ModalFieldButton>
    </div>
  )
}

const MinChunkDurationDropdown = ({
  value,
  duration,
  onChange,
}: MinChunkDurationFieldProps) => {
  const chunkDurations = getChunkDurations(duration)

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <MinChunkDurationDropdownContent
          close={close}
          onChange={onChange}
          chunkDurations={chunkDurations}
          selectedMinChunkDuration={value}
        />
      )}
    >
      <FieldButton variant='muted' size='small' iconOnly>
        <span className='whitespace-nowrap'>
          {chunkDurations.find((item) => item.value === value)?.label ??
            'No Chunks'}
        </span>
      </FieldButton>
    </PopoverTrigger>
  )
}

type MinChunkDurationDropdownContentProps = {
  close: () => void
  onChange: (value: number | null) => void
  chunkDurations: { label: string; value: number | null }[]
  selectedMinChunkDuration: number | null
}

const MinChunkDurationDropdownContent = ({
  close,
  onChange,
  chunkDurations,
  selectedMinChunkDuration,
}: MinChunkDurationDropdownContentProps) => {
  return (
    <SearchableList
      searchable={false}
      items={chunkDurations}
      computeKey={(item) => String(item.value)}
      computeSelected={(item) => item.value === selectedMinChunkDuration}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
    />
  )
}
