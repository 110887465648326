export function findFirstDuplicateName<T extends { name: string }>(
  arr: T[]
): T | undefined {
  const nameSet = new Set<string>()

  const duplicateName = arr.find(({ name }) => {
    const lowerCaseName = name.toLowerCase()
    if (nameSet.has(lowerCaseName)) {
      return true
    }
    nameSet.add(lowerCaseName)
    return false
  })

  return duplicateName
}

export const getFlowTemplateFormErrorMessage = (
  error: any
): string | undefined => {
  if (!error) return

  let message = error.message

  if (!message && typeof error === 'object') {
    if (isNameError(error)) {
      message = error.name.message
    } else if (isTaskError(error)) {
      const firstTask = error.tasks.filter(Boolean)[0]
      if (isNameError(firstTask)) {
        message = firstTask.name.message
      }
    } else if (isRoleError(error)) {
      message = error.color.message
    } else if (isStageDeadlineError(error)) {
      message = error.deadlineIntervalDays.message
    }
  }

  return typeof message === 'string' && message ? message : undefined
}

function isNameError(err: any): err is { name: { message: string } } {
  return (
    'name' in err &&
    err.name != null &&
    typeof err.name === 'object' &&
    'message' in err.name &&
    typeof err.name.message === 'string'
  )
}

const isTaskError = (
  err: any
): err is { tasks: { name: { message: string } }[] } => {
  if ('tasks' in err && err.tasks != null && Array.isArray(err.tasks)) {
    const filteredErrors = err.tasks.filter(Boolean)
    if (
      filteredErrors.length > 0 &&
      filteredErrors[0] != null &&
      typeof filteredErrors[0] === 'object'
    ) {
      return true
    }
  }

  return false
}

function isRoleError(err: any): err is { color: { message: string } } {
  return 'color' in err && typeof err === 'object' && 'message' in err.color
}

function isStageDeadlineError(
  err: any
): err is { deadlineIntervalDays: { message: string } } {
  if (
    'deadlineIntervalDays' in err &&
    err.deadlineIntervalDays != null &&
    typeof err.deadlineIntervalDays === 'object' &&
    'message' in err.deadlineIntervalDays
  ) {
    return true
  }
  return false
}
