import { useAuthenticatedUser } from '@motion/web-common/auth'

import { DEFAULT_CALENDAR_COLOR } from '~/areas/calendar/utils'
import { useTeamMemberByUserId } from '~/global/hooks/team'

import { GuestItem } from './guest-item'

import {
  useScheduleAssistantCalendarContext,
  useSendScheduleAssistantCalendarContext,
} from '../../../hooks'
import { type UserScheduleInfo } from '../../../types'

type TeamMemberGuestItemProps = {
  userId: string
  scheduleInfo?: UserScheduleInfo
}

export function TeamMemberGuestItem({
  userId,
  scheduleInfo,
}: TeamMemberGuestItemProps) {
  const { email: userEmail } = useAuthenticatedUser()
  const [calendarState] = useScheduleAssistantCalendarContext()
  const updatedCalendarState = useSendScheduleAssistantCalendarContext()

  const teamMember = useTeamMemberByUserId(userId)

  if (calendarState.mode !== 'create-new' || teamMember == null) {
    return null
  }

  const { hiddenGuestUserIds, hostEmail } = calendarState

  const name = teamMember.user.name
  const email = teamMember.user.email
  const isMyCalendar = email === userEmail

  const checked = !hiddenGuestUserIds.includes(userId)

  const onCheckboxChange = (checked: boolean) => {
    updatedCalendarState({
      hiddenGuestUserIds: checked
        ? hiddenGuestUserIds.filter((id) => id !== userId)
        : [...hiddenGuestUserIds, userId],
    })
  }

  const onRemove = () => {
    updatedCalendarState({
      attendees: calendarState.attendees.filter(
        (attendee) => attendee.email !== teamMember.user.email
      ),
    })
  }

  return (
    <GuestItem
      checked={checked}
      onCheckboxChange={onCheckboxChange}
      colorId={isMyCalendar ? DEFAULT_CALENDAR_COLOR : scheduleInfo?.colorId}
      name={name}
      email={email}
      profileUrl={teamMember.user.picture ?? undefined}
      timezone={scheduleInfo?.timezone}
      isHost={email === hostEmail}
      isMyCalendar={isMyCalendar}
      onRemove={onRemove}
    />
  )
}
