import { useModalApi } from '@motion/web-common/modals'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useCurrentModal } from '~/areas/modals'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useCallback } from 'react'

import { useCloseMeetingModal } from './use-close-meeting-modal'

export function useNavigateFromEventToEvent() {
  const currentModal = useCurrentModal<'event-modal'>()
  const navigateToModal = useNavigateByGlobalModalId()
  const closeMeetingModal = useCloseMeetingModal()
  const modalApi = useModalApi()

  return useCallback(
    (from: CalendarEventSchemaV2, to: CalendarEventSchemaV2) => {
      // from meeting modal
      if (from.meetingTaskId != null) {
        if (to.meetingTaskId != null) {
          return navigateToModal(
            'meeting',
            { mTask: to.meetingTaskId },
            { eventIdOverride: to.id }
          )
        }

        closeMeetingModal()
        modalApi.open('event-modal', { eventId: to.id })
        return
      }

      // from event modal to meeting
      if (to.meetingTaskId != null) {
        currentModal.dismiss()
        return navigateToModal('meeting', { mTask: to.meetingTaskId })
      }

      // from event to event
      currentModal.updateProps({ eventId: to.id })
    },
    [closeMeetingModal, currentModal, modalApi, navigateToModal]
  )
}
