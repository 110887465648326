import { createContext, useContext } from 'react'

import { type EventModalState } from './types'

export const EventModalStateContext = createContext<EventModalState | null>(
  null
)

export function useEventModalState() {
  const ctx = useContext(EventModalStateContext)
  if (ctx == null) {
    throw new Error('Missing EventModalStateContext provider')
  }
  return ctx
}
