import { TaskSolid } from '@motion/icons'
import { FormModal, PopoverButton } from '@motion/ui/base'
import { formatDurationTime, timeDurations } from '@motion/ui-logic'
import { byValue, Compare } from '@motion/utils/array'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { DurationDropdown } from '~/areas/project-management/components'
import { useMemo, useState } from 'react'

export interface ConnectedStartTaskModalProps {
  name: string
  suggestedDuration: number
}

export const StartTaskModal = ({
  name,
  suggestedDuration,
  close,
}: ModalTriggerComponentProps<'start-task'>) => {
  const [duration, setDuration] = useState(suggestedDuration)

  const choices = useMemo(() => {
    const choices = timeDurations.filter(
      (duration) => duration.value != null && duration.value > 0
    )

    if (!choices.some((item) => item.value === suggestedDuration)) {
      choices.push({
        value: suggestedDuration,
        label: formatDurationTime(suggestedDuration),
      })
      choices.sort(byValue((item) => item.value, Compare.numeric))
    }
    return choices
  }, [suggestedDuration])

  return (
    <FormModal
      visible
      onClose={close}
      submitAction={{
        text: 'Start',
        onAction: () => {
          close(duration)
        },
      }}
      title='Start task now'
    >
      <div className='flex flex-col items-start gap-4 self-stretch min-w-[400px]'>
        <div className='text-semantic-neutral-text-subtle flex items-center justify-center gap-1'>
          <TaskSolid className='w-[14px] h-[14px] text-semantic-neutral-icon-subtle' />
          <div className='truncate max-w-[400px] text-xs'>{name}</div>
        </div>
        <div className='text-semantic-neutral-text-default'>
          How long are you going to work on this task now?
        </div>
        <div className='w-full'>
          <DurationDropdown
            value={duration}
            onChange={(duration) => {
              setDuration(duration ?? 0)
            }}
            choices={choices}
          >
            <PopoverButton>{formatDurationTime(duration)}</PopoverButton>
          </DurationDropdown>
        </div>

        <div className='text-semantic-neutral-text-subtle text-xs'>
          We&apos;ll move current task(s) to a different time.
        </div>
      </div>
    </FormModal>
  )
}
