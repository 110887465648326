import { classed } from '@motion/theme'

export const ModalBody = classed('div', {
  base: `
    p-6
    bg-modal-bg
    rounded-lg shadow-lg
    w-[751px] max-w-[calc(100vw-56px)]
`,
})

export const FormShell = classed('form', {
  base: `
    flex flex-col gap-8 h-full
  `,
})

export const Header = classed('header', {
  base: `
    flex justify-between items-center
  `,
})

export const Footer = classed('footer', {
  base: `
    flex justify-end items-center gap-2 mt-auto
  `,
})

export const Section = classed('section', {
  base: `
    flex flex-col gap-3
  `,
})

export const SectionTitle = classed('h4', {
  base: `
    text-sm font-semibold text-semantic-neutral-text-default
  `,
})

export const SectionSubtitle = classed('p', {
  base: `
    text-sm text-semantic-neutral-text-subtle
  `,
})
