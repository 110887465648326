import { selectBookingTemplates } from '~/state/booking'
import { useAppSelector } from '~/state/hooks'
import { sortBookingLinkTemplates } from '~/utils/booking-utils'
import { useMemo } from 'react'

export const useBookingTemplateIdBySlug = (slug?: string): string | null => {
  const bookingTemplates = useAppSelector(selectBookingTemplates)

  const matchedTemplate = useMemo(() => {
    if (!slug) return null

    return (
      sortBookingLinkTemplates(bookingTemplates).find(
        (template) => template.linkSlug === slug
      ) ?? null
    )
  }, [bookingTemplates, slug])

  return matchedTemplate?.id ?? null
}
