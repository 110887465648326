import { groupInto, uniqueBy } from '@motion/utils/array'

import { useProjectDefinitionForTask } from '~/areas/flows'
import { useMemo } from 'react'

import {
  isBulkOpsTaskGroups,
  useBulkOpsGroups,
  useBulkOpsState,
} from '../../contexts'

/**
 * For stages, we want to group by project definition instead of workspace.
 */
export function useBulkFilteredStageDefinitions() {
  const { target } = useBulkOpsState()
  const { projectDefinitionGroups } = useBulkOpsGroups()
  const getTaskProjectDefinition = useProjectDefinitionForTask()

  return useMemo(() => {
    if (!isBulkOpsTaskGroups(projectDefinitionGroups, target)) return []

    const selectedTaskStages = uniqueBy(
      projectDefinitionGroups
        .flatMap((group) => group.items)
        .flatMap((task) => {
          const projectDefinition = getTaskProjectDefinition(task)

          if (projectDefinition == null) return []

          return projectDefinition.stages.map((stage) => ({
            ...stage,
            projectDefinitionId: projectDefinition.id,
            workspaceId: projectDefinition.workspaceId,
          }))
        }),
      (s) => s.id
    )

    const grouped = groupInto(selectedTaskStages, (s) => s.name)
      // All the stages should exist in one of the project definitions
      .filter((g) => g.items.length === projectDefinitionGroups.length)
      .map((group) => group.items)

    return grouped
  }, [target, projectDefinitionGroups, getTaskProjectDefinition])
}
