import { TagSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { LabelDropdown } from '~/global/components/dropdowns'
import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationLabelsField = ({
  workspaceId,
}: {
  workspaceId: string
}) => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'labelIds',
    control: formContext.control,
  })

  return (
    <LabelDropdown
      workspaceId={workspaceId}
      selectedLabelIds={field.value ?? []}
      onChange={field.onChange}
    >
      <Button
        iconOnly={!field.value?.length}
        sentiment='neutral'
        variant='outlined'
        size='xsmall'
      >
        <div className='p-0.5 flex items-center gap-0.5'>
          <TagSolid />
          {!!field.value?.length && (
            <span className='truncate text-[10px] leading-[12px]'>
              {field.value.length}
            </span>
          )}
        </div>
      </Button>
    </LabelDropdown>
  )
}
