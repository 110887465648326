import {
  AdditionalTimeline,
  ExtendedTimeline,
  PercentageSavedText,
} from './styled'

type ProjectExtendedTimelineProps = {
  percentageTimeChange: number
}

export function ProjectExtendedTimeline({
  percentageTimeChange,
}: ProjectExtendedTimelineProps) {
  return percentageTimeChange < 0 ? (
    <AdditionalTimeline>
      <PercentageSavedText>{percentageTimeChange}%</PercentageSavedText>
    </AdditionalTimeline>
  ) : (
    <ExtendedTimeline />
  )
}
