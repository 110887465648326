import { parseCustomFieldInfoFromMaybeDelimitedKey } from '@motion/ui-logic'

import { type CustomFieldDateRow } from '../../../types'
import { DateGroupHeader } from '../../group-headers'

type Props = {
  item: CustomFieldDateRow & {
    key: string
  }
}

export function CustomFieldDateGroupHeader({ item }: Props) {
  const res = parseCustomFieldInfoFromMaybeDelimitedKey(item.type)
  if (res == null) return null
  const { name } = res

  return <DateGroupHeader item={item} fieldName={name} />
}
