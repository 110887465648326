import { showToast } from '@motion/ui/base'
import { logInDev } from '@motion/web-base/logging'

import { useMemo } from 'react'

import { copyToClipboard, type CopyToClipboardArgs } from './utils'

export type ClipboardOptions = {
  notificationText?: string
}

export const useClipboard = () => {
  const api = useMemo(() => {
    const write = (data: CopyToClipboardArgs, options?: ClipboardOptions) => {
      return copyToClipboard(data)
        .then(() => {
          /* eslint promise/always-return: ["warn"] */
          showToast(
            'success',
            options?.notificationText ?? 'Copied successfully'
          )
        })
        .catch((ex) => {
          logInDev(ex)
          throw ex
        })
    }
    return { write }
  }, [])

  return api
}
