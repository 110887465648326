import { CalendarPlusSolid, StatusCompletedSolid } from '@motion/icons'
import {
  Button,
  type ButtonProps,
  IconButton,
  type IconButtonProps,
} from '@motion/ui/base'
import { isUnscheduledSchedulingTask } from '@motion/ui-logic/pm/task'

import { SchedulingEventDropdown } from '~/areas/task-project/components'

import { type CommonButtonProps } from './type'

import { useTasksLoadingState, useUpdateTasksComplete } from '../hooks'

export const CompleteButton = ({
  tasks,
  showAllText,
  type,
}: CommonButtonProps) => {
  const [isLoading, setIsLoading] = useTasksLoadingState(tasks)
  const updateTasksComplete = useUpdateTasksComplete({
    tasks,
    setIsLoading,
    type,
  })

  const areAllTasksScheduled = tasks.every(isUnscheduledSchedulingTask)

  if (areAllTasksScheduled) {
    return (
      <SchedulingEventDropdown taskId={tasks[0]?.id}>
        <Button size='small' sentiment='primary' variant='outlined'>
          <CalendarPlusSolid />
          Schedule
        </Button>
      </SchedulingEventDropdown>
    )
  }

  const commonButtonProps: Partial<ButtonProps & IconButtonProps> = {
    sentiment: 'neutral',
    size: 'small',
    disabled: isLoading,
    onClick: updateTasksComplete,
  }

  if (showAllText && tasks.length > 1) {
    return (
      <Button {...commonButtonProps}>
        <StatusCompletedSolid />
        Complete All
      </Button>
    )
  }

  return (
    <IconButton
      icon={StatusCompletedSolid}
      {...commonButtonProps}
      aria-label='Complete task'
    />
  )
}
