import { type StatusIconProps } from '@motion/ui/pm'
import { isMeetingTask, isSchedulingTask } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

export function getTaskStatusVariant(
  task: TaskSchema
): StatusIconProps['taskVariant'] {
  if (isMeetingTask(task)) {
    return 'meeting'
  }

  if (isSchedulingTask(task)) {
    return 'scheduling'
  }

  return 'default'
}
