import { PopoverTrigger } from '@motion/ui/base'
import { prettyDateDay, type QuickAction } from '@motion/ui-logic'

import { useCalendarStartDay } from '~/areas/calendar/hooks'
import { ContextAwareDateDropdownContent } from '~/areas/project-management/components'
import { DateTime } from 'luxon'
import { type ReactNode, useMemo } from 'react'

type BulkDateDropdownProps = Omit<BulkDateDropdownContentProps, 'close'> & {
  children?: ReactNode
}

export function BulkDateDropdown({ children, ...rest }: BulkDateDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkDateDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type BulkDateDropdownContentProps = {
  close: () => void
  onChange: (date: string | null | undefined) => void
  selectedDate: string | null | undefined
  defaultDate: DateTime
  getQuickActions?: (date: DateTime) => QuickAction[]
  isValidDateOption?: (date: DateTime) => boolean
  disableClearing?: boolean
  showTimeOptions?: boolean
}

function BulkDateDropdownContent({
  close,
  selectedDate,
  onChange,
  defaultDate,
  getQuickActions,
  isValidDateOption,
  showTimeOptions,
  disableClearing,
}: BulkDateDropdownContentProps) {
  const calendarStartDay = useCalendarStartDay()

  const disableDate = (date: DateTime) => {
    if (isValidDateOption && !isValidDateOption(date)) {
      return true
    }

    return false
  }

  const quickActions = useMemo(
    () => getQuickActions?.(DateTime.now()) ?? [],
    [getQuickActions]
  )

  return (
    <ContextAwareDateDropdownContent
      disableClearing={disableClearing}
      calendarStartDay={calendarStartDay}
      disabledDate={disableDate}
      value={selectedDate ?? defaultDate.toISO()}
      onChange={(value) => {
        onChange(value)
        close()
      }}
      quickActions={quickActions}
      showTimeOptions={showTimeOptions}
      formatDateDisplay={(date) => prettyDateDay(date)}
    />
  )
}
