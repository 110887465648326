import { templateStr } from '@motion/ui-logic'

import { type ReactNode } from 'react'

export type FieldsSectionProps = {
  name: string
  children: ReactNode
}

export function FieldsSection({ name, children }: FieldsSectionProps) {
  return (
    <section className='flex gap-3 text-xs items-center'>
      <h4 className='text-semantic-neutral-text-subtle whitespace-nowrap'>
        {templateStr('{{name}}:', { name })}
      </h4>
      <div className='flex flex-wrap gap-1 items-center'>{children}</div>
    </section>
  )
}
