import { type UploadedFileResponseSchema } from '@motion/rpc-types'
import { useShortcut } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useAttachmentActions } from '~/areas/attachments/hooks'

import { AttachmentResult } from './attachment-result'

export interface ConnectedAttachmentResultProps {
  item: UploadedFileResponseSchema
  itemIds: UploadedFileResponseSchema['id'][]
  active: boolean
  onItemClick: () => void
}

export function ConnectedAttachmentResult({
  item,
  itemIds,
  active,
  onItemClick,
}: ConnectedAttachmentResultProps) {
  const modalApi = useModalApi()

  const { copyAttachmentLink } = useAttachmentActions()

  const handleClick = () => {
    modalApi.open('attachment-preview', {
      attachmentId: item.id,
      attachmentIds: itemIds,
    })
    onItemClick()
  }

  const handleCopyLink = () => copyAttachmentLink(item)

  useShortcut('mod+l', handleCopyLink, { enabled: active })

  return (
    <AttachmentResult
      item={item}
      onClick={handleClick}
      onCopyLink={handleCopyLink}
    />
  )
}
