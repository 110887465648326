import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgEmptyDark = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 120 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "emptydark", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { width: 120, height: 120, rx: 60, fill: "#2B2F30" }),
  /* @__PURE__ */ jsxs("g", { filter: "url(#filter0_d_625_20728)", children: [
    /* @__PURE__ */ jsx("rect", { width: 58.1886, height: 70.3894, transform: "translate(27.896 25.0635)", fill: "url(#paint0_linear_625_20728)" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 36.3258, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#8D9698" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 50.4036, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#8D9698" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 64.4814, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#8D9698" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 78.5593, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#8D9698" })
  ] }),
  /* @__PURE__ */ jsx("rect", { x: 63.6404, y: 63.1675, width: 19.1191, height: 7.48139, rx: 3.74069, fill: "#8D9698" }),
  /* @__PURE__ */ jsx("path", { d: "M71.9531 50.6986L76.1569 49.4375C78.2047 48.8231 80.2657 50.3566 80.2657 52.4945C80.2657 54.2573 78.8368 55.6862 77.074 55.6862H72.8318C69.9547 55.6862 69.2492 51.6819 71.9531 50.6986Z", fill: "#8D9698" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M92.2492 73.5979C96.9234 67.3634 96.4254 58.4797 90.7553 52.8097C84.5394 46.5937 74.4613 46.5937 68.2453 52.8097C62.0293 59.0256 62.0293 69.1037 68.2453 75.3197C73.9153 80.9898 82.799 81.4878 89.0335 76.8136L100.403 88.1826C101.291 89.0706 102.73 89.0706 103.618 88.1826C104.506 87.2946 104.506 85.8549 103.618 84.9669L92.2492 73.5979ZM87.5396 56.0254C91.9796 60.4654 91.9796 67.664 87.5396 72.104C83.0996 76.544 75.9009 76.544 71.461 72.104C67.021 67.664 67.021 60.4654 71.461 56.0254C75.9009 51.5854 83.0996 51.5854 87.5396 56.0254Z", fill: "#4E5658" }),
  /* @__PURE__ */ jsx("path", { d: "M75.0194 56.5198C77.5729 55.3282 83.4969 54.2727 86.7653 59.5839", stroke: "white", strokeWidth: 2.04276, strokeLinecap: "round" }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_d_625_20728", x: 15.896, y: 5.06348, width: 82.1886, height: 94.3894, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: -8 }),
      /* @__PURE__ */ jsx("feGaussianBlur", { stdDeviation: 6 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "out" }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.199267 0 0 0 0 0.329924 0 0 0 0 0.427917 0 0 0 0.06 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_625_20728" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_625_20728", result: "shape" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_625_20728", x1: 29.0943, y1: 0, x2: 29.0943, y2: 69.9365, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#434A4B" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#2C3031" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgEmptyDark);
export default ForwardRef;
ForwardRef.displayName = "EmptyDarkIcon";
