import { createStateKey, useSharedState } from '@motion/react-core/shared-state'

import { type ResizeStagesState } from '../types'

const defaultValue: ResizeStagesState = {
  isResizing: false,
}

export const ResizeStagesSharedStateKey = createStateKey<ResizeStagesState>(
  'add-task-state',
  {
    defaultValue,
  }
)

export const useResizeStageState = () =>
  useSharedState(ResizeStagesSharedStateKey)
