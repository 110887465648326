import { type COLOR } from '@motion/shared/common'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import {
  type FolderSchema,
  type ProjectSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useProjectAnalytics } from '~/areas/project/hooks'
import { useLookup } from '~/global/cache'
import { WorkspacesSearchableTree } from '~/global/components/dropdowns'
import { useUpdateProject } from '~/global/rpc/v2'

type ProjectActionMenuProps = {
  onClose(): void
  project: ProjectSchema
}

export const MoveProjectToPopover = ({
  onClose,
  project,
}: ProjectActionMenuProps) => {
  const modalApi = useModalApi()
  const { mutateAsync: updateProject } = useUpdateProject()
  const getProjectAnalyticContext = useProjectAnalytics()
  const lookup = useLookup()

  async function moveProject(
    workspaceId: WorkspaceSchema['id'],
    folderId: FolderSchema['id'] | null = null
  ) {
    if (workspaceId === project.workspaceId && folderId === project.folderId)
      return

    const makeProjectUpdates = async () => {
      if (!project.id) return

      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PROJECT_CONTEXT', {
        ...getProjectAnalyticContext(project.id),
      })

      await updateProject({
        ...project,
        color: project.color as COLOR,
        description: project.description ?? undefined,
        folderId,
        projectId: project.id,
        workspaceId,
      })
    }

    if (workspaceId !== project.workspaceId) {
      const workspace = lookup('workspaces', workspaceId)

      return void modalApi.prompt('confirm', {
        analytics: {
          name: 'move-project-workspace',
        },
        confirmButtonText: 'Move project',
        description:
          'Some statuses, labels and custom fields may be reset if they aren’t available in the new workspace.',
        title: `Are you sure you want to move this project ${project.name} to the workspace ${workspace.name}?`,
        onValue: async (confirmed) => {
          if (confirmed) {
            await makeProjectUpdates()
          }
        },
      })
    }

    await makeProjectUpdates()
  }

  return (
    <WorkspacesSearchableTree
      selectedId={project.folderId}
      workspaceId={project.workspaceId}
      leafNodeType='FOLDER'
      onChange={({ workspaceId, folderId }) => {
        onClose()
        void moveProject(workspaceId, folderId)
      }}
      closePopover={onClose}
      showCreateFolder
    />
  )
}
