import {
  type NormalTaskSchema,
  type RecurringTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

export function validateTask(
  id: string | null | undefined,
  task: TaskSchema | RecurringTaskSchema | null | undefined,
  loading: boolean
): task is NormalTaskSchema {
  if (id != null && !loading && task == null) {
    return false
  }

  if (task != null && task.type !== 'NORMAL') {
    return false
  }

  return true
}
