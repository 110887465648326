import { Tooltip } from '@motion/ui/base'
import { StatusIcon, type StatusIconProps } from '@motion/ui/pm'
import { type StatusSchema } from '@motion/zod/client'

import { useWorkspaceStatusById } from '~/global/hooks'
import { type ComponentRef, forwardRef, type ReactNode } from 'react'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

import { GhostStatusDescription } from '../tooltips'

export type StatusBadgeProps = {
  value: Pick<StatusSchema, 'name' | 'color' | 'type'>
  size?: BadgeSize
  hideTooltip?: boolean
  renderTooltipContent?: () => ReactNode
} & Pick<StatusIconProps, 'iconVariant' | 'taskVariant'>

export const StatusBadge = forwardRef<
  ComponentRef<typeof StatusIcon>,
  StatusBadgeProps
>(function StatusBadge(
  {
    value,
    size,
    hideTooltip = false,
    iconVariant,
    taskVariant,
    renderTooltipContent,
  },
  ref
) {
  const pixelSize = getBadgeSizeInPixels(size)

  const icon = (
    <StatusIcon
      ref={ref}
      item={value}
      width={pixelSize}
      height={pixelSize}
      taskVariant={taskVariant}
      iconVariant={iconVariant}
    />
  )

  const getTooltipContent = () => {
    if (renderTooltipContent != null) {
      return renderTooltipContent()
    }

    if (taskVariant === 'meeting') {
      return 'Meeting'
    }

    if (iconVariant === 'isUnvisitedStage') {
      return <GhostStatusDescription />
    }

    return `Status: ${value.name}`
  }

  if (hideTooltip) return icon

  return (
    <Tooltip asChild renderContent={getTooltipContent}>
      {icon}
    </Tooltip>
  )
})

export type ConnectedStatusBadgeProps = Omit<StatusBadgeProps, 'value'> & {
  id: string
}

export const ConnectedStatusBadge = ({
  id,
  ...rest
}: ConnectedStatusBadgeProps) => {
  const status = useWorkspaceStatusById(id)

  return <StatusBadge value={status} {...rest} />
}
