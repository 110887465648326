import type { AppDispatch, RootState, Store } from './types'

let _store!: Store

export function setStore(store: Store) {
  _store = store
}

export const dispatch: AppDispatch = (action: any) => {
  if (_store == null) {
    throw new Error('dispatch not set')
  }
  return _store.dispatch(action)
}

export const getState = () => {
  if (_store == null) {
    throw new Error('getState not set')
  }
  return _store.getState()
}

export const subscribe = (listener: () => void) => {
  if (_store == null) {
    throw new Error('store is not set')
  }
  return _store.subscribe(listener)
}

export const store = {
  dispatch,
  getState,
  subscribe,
}

export type { AppDispatch, RootState, Store }
