import { FormModal, showToast } from '@motion/ui/base'
import {
  type AllAvailableCustomFieldSchema,
  getCreateCustomFieldErrorMsg,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type WorkspaceSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import {
  type GetCustomFieldsCategoriesResponse,
  useGetAvailableCustomFieldCategories,
} from '~/areas/project-management/hooks'
import { useCreateCustomField } from '~/areas/project-management/hooks/custom-fields/rpc'
import { showErrorToast } from '~/global/toasts'
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from 'react-hook-form'

import { formatCreateFieldArgs } from './utils'

import { DuplicateOptionError } from '../../errors'
import { CustomFieldSettings } from '../components'
import {
  type AddCustomFieldFormFields,
  handleOptionErrors,
} from '../form-utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'add-custom-field': PromptCallbacks<
      AllAvailableCustomFieldSchema | undefined
    > & {
      workspace: Pick<WorkspaceSchema, 'name' | 'id'>
      defaultValues?: Partial<AddCustomFieldFormFields>
    }
  }
}

export const ConnectedAddCustomFieldModal = (
  props: ModalTriggerComponentProps<'add-custom-field'>
) => {
  const categoriesResponse = useGetAvailableCustomFieldCategories()

  return <ConnectedAddCustomFieldFormModal {...props} {...categoriesResponse} />
}

type ConnectedAddCustomFieldFormModalProps =
  ModalTriggerComponentProps<'add-custom-field'> &
    GetCustomFieldsCategoriesResponse

const ConnectedAddCustomFieldFormModal = ({
  close: onClose,
  workspace: { name: workspaceName, id: workspaceId },
  defaultValues,
  ...rest
}: ConnectedAddCustomFieldFormModalProps) => {
  const form = useForm<AddCustomFieldFormFields>({
    defaultValues: {
      name: '',
      type: 'text',
      ...defaultValues,
    },
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })
  const { formState, reset, watch } = form
  const { mutateAsync: createCustomField } = useCreateCustomField()

  const onCloseModal = (newField?: AllAvailableCustomFieldSchema) => {
    onClose(newField)
    reset()
  }

  const type = watch('type')

  const onSubmit: SubmitHandler<AddCustomFieldFormFields> = async (data) => {
    try {
      const args = formatCreateFieldArgs(workspaceId, data)
      if (args == null) {
        throw new Error('Unhandled field type')
      }

      const res = await createCustomField(args, {
        onError(e, variables) {
          showToast('error', getCreateCustomFieldErrorMsg(e, variables.name))
        },
      })
      const newField = res.models.customFields[res.id]

      recordAnalyticsEvent('CUSTOM_FIELD_ADDED', {
        type: data.type,
      })

      onCloseModal(newField as AllAvailableCustomFieldSchema)
      showToast('success', 'Custom field created successfully')
    } catch (error) {
      if (error instanceof DuplicateOptionError) {
        showErrorToast(error)
      }

      throw error
    }
  }

  /**
   * We handle errors for the field array fields here because, unforunately,
   * react-hook-form does not provide reliable error handling for these fields at the hook level.
   * See https://github.com/react-hook-form/react-hook-form/issues/3254.
   */
  const onInvalid: SubmitErrorHandler<AddCustomFieldFormFields> = (errors) =>
    handleOptionErrors(errors, type)

  const disabled = formState.isSubmitting

  return (
    <FormProvider {...form}>
      <FormModal
        title={'Add custom field in ' + workspaceName}
        visible
        onClose={onCloseModal}
        submitAction={{
          onAction: form.handleSubmit(onSubmit, onInvalid),
          disabled,
        }}
      >
        <CustomFieldSettings {...rest} />
      </FormModal>
    </FormProvider>
  )
}
