import { CheckSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { RadioButton, type RadioButtonProps } from '@motion/ui/forms'

import { useResolveTask } from '~/areas/tasks/hooks'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { useState } from 'react'

export type TaskCompleteButtonProps = {
  scheduledTask: ScheduledTaskWithRelation
  openTask: () => void
  onComplete?: () => void
  borderVariant?: RadioButtonProps['borderVariant']
}

export function TaskCompleteButton({
  scheduledTask,
  openTask,
  onComplete,
  borderVariant,
}: TaskCompleteButtonProps) {
  const { task } = scheduledTask
  const [completionInFlight, setCompletionInFlight] = useState(false)
  const { completeTask } = useResolveTask()
  const isTaskComplete = scheduledTask.completed && !completionInFlight

  async function handleMarkComplete() {
    if (!isTaskComplete && task?.id) {
      try {
        onComplete?.()
        setCompletionInFlight(true)
        await completeTask(task.id)
      } finally {
        setCompletionInFlight(false)
      }
    }
  }

  if (isTaskComplete)
    return (
      <button title='Open task' onClick={openTask}>
        <div className='flex items-center justify-center bg-semantic-success-icon-default rounded-full text-semantic-neutral-icon-onDark w-4 h-4'>
          <CheckSolid className='w-[75%] h-[75%]' />
        </div>
      </button>
    )

  return (
    <CompleteButtonContainer isCompleting={completionInFlight}>
      <RadioButton
        checked={isTaskComplete}
        disabled={!task || completionInFlight}
        label={task?.name}
        labelHidden
        onChange={handleMarkComplete}
        variant='checkmark'
        borderVariant={borderVariant}
      />
    </CompleteButtonContainer>
  )
}

const CompleteButtonContainer = classed('div', {
  base: `
    [&_div]:hover:text-semantic-success-icon-subtle
    [&_div]:hover:!border-semantic-success-border-default
    [&_svg]:!w-3 [&_svg]:!h-3 [&_div]:hover:[transition:all_0.2s_ease]
  `,
  variants: {
    isCompleting: {
      true: `
        [&_div]:text-semantic-success-icon-default
        [&_div]:hover:text-semantic-success-icon-default
        cursor-not-allowed
      `,
    },
  },
  defaultVariants: {
    isCompleting: false,
  },
})
