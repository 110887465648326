import { errorInDev } from '@motion/web-base/logging'
import { useCurrentUserOrNull } from '@motion/web-common/auth'
import {
  readLocalStorageValue,
  removeLocalStorageValue,
} from '@motion/web-common/storage'

import { useConferenceSettingsApi } from '~/areas/settings/state'
import { initializeWorker } from '~/chromeApi/webappChromeApiContentScript'
import { LoadingPage } from '~/components/LoadingPage'
import { useMotionLocalStorage } from '~/localServices/storage'
import { type ReactNode, useEffect, useState } from 'react'

import { processZoomAuthObject } from '../state/utils'

type EnsureMotionLocalStorageLoadedProps = {
  children: ReactNode
}
export const EnsureMotionLocalStorageLoaded = (
  props: EnsureMotionLocalStorageLoadedProps
) => {
  const [loaded, setLoaded] = useState(false)
  const { $loaded: localStorageInitialized } = useMotionLocalStorage([
    '$loaded',
  ])
  const user = useCurrentUserOrNull()

  // TODO: Source this from a new mutate-only hook to avoid re-renders
  const { refreshZoomUserData } = useConferenceSettingsApi()

  useEffect(() => {
    if (user?.uid == null) return
    if (!localStorageInitialized || loaded) return
    init(refreshZoomUserData)
      .then(() => setLoaded(true))
      .catch((ex) => errorInDev('error', ex))
  }, [loaded, localStorageInitialized, user?.uid, refreshZoomUserData])

  if (!loaded || !localStorageInitialized) {
    return <LoadingPage id='entry-point' />
  }

  return props.children
}

const VALID_ORIGINS = ['localhost', '.usemotion.com', '.dev-usemotion.com']

const startPageListener = (refreshZoomUserData: () => Promise<void>) => {
  window.addEventListener(
    'message',
    async (event) => {
      const isValidOrigin = VALID_ORIGINS.some((x) => event.origin?.includes(x))
      if (isValidOrigin) {
        switch (event.data?.event) {
          case 'processZoomAuthObject':
            await processZoomAuthObject(refreshZoomUserData)
            break
          default:
            break
        }
      }
    },
    false
  )
  window.addEventListener(
    'storage',
    async (event: any) => {
      const eventKey = event instanceof StorageEvent ? event.key : event.detail
      const value = readLocalStorageValue(eventKey)
      switch (eventKey) {
        case 'motion:zoomAuthentication':
          removeLocalStorageValue('motion:zoomAuthentication')
          if (value?.event === 'processZoomAuthObject') {
            await processZoomAuthObject(refreshZoomUserData)
          }
          break
        default:
          break
      }
    },
    false
  )
}

async function init(refreshZoomUserData: () => Promise<void>) {
  await initializeWorker()
  startPageListener(refreshZoomUserData)
}
