import { useSharedState } from '@motion/react-core/shared-state'
import { Button } from '@motion/ui/base'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'

import {
  DEFAULT_VIEW,
  TeamScheduleActiveViewOptionsKey,
} from '../../pages/pm-revamp/team-schedule/context'
import { TeamSchedulePageContextKey } from '../../pages/pm-revamp/team-schedule/context/page-data-context'
import { useResetView } from '../../pages/pm-revamp/team-schedule/views'
import {
  areViewsEqual,
  toViewDefinition,
} from '../../pages/pm-revamp/team-schedule/views/utils'

export const ConnectedResetFiltersButton = () => {
  const [ctx] = useSharedState(TeamSchedulePageContextKey)
  const [filterState] = useSharedState(ActiveFilterKey)
  const [viewState] = useSharedState(TeamScheduleActiveViewOptionsKey)

  const definition = toViewDefinition(filterState, viewState)

  const resetFilters = useResetView()

  const currentView = ctx.views.byId[viewState.viewId] ?? DEFAULT_VIEW
  if (areViewsEqual(definition, currentView.definition)) {
    return null
  }

  return (
    <Button onClick={() => resetFilters()} size='small' variant='muted'>
      Reset Filters
    </Button>
  )
}
