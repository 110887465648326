import { useModalStatus } from '@motion/web-common/modals'

import { LinkModal } from './LinkModal'

export type OpenLinkModalProps = {
  initialLink?: string
}

export const ConnectedLinkModal = () => {
  const status = useModalStatus('link-modal')

  if (!status.visible) {
    return null
  }

  return (
    <LinkModal
      initialLink={status.props.initialLink}
      onDismiss={() => {
        status.close()
      }}
      onChange={(value) => {
        status.close(value)
      }}
    />
  )
}
