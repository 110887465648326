import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useDeleteProjectDefinition as useDeleteProjectDefinitionCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useDeleteProjectDefinition = () => {
  const modalApi = useModalApi()
  const { mutateAsync: deleteProjectDefinition } =
    useDeleteProjectDefinitionCall()
  const { getWorkspaceProjectDefinitionById } = useWorkspaceFns()

  return useCallback(
    async (id: ProjectDefinitionSchema['id']) => {
      try {
        const projectDefinition = getWorkspaceProjectDefinitionById(id)
        if (projectDefinition == null) {
          throw new Error('Flow template not found')
        }

        const response = await modalApi.prompt('confirm-delete-item', {
          analytics: {
            name: 'delete-project-definition',
          },
          titleValue: 'this project workflow template',
          description:
            // TODO 'There are currently x active projects using this flow.'
            'Any project using this project workflow template will be converted into a regular project without stages.',
          deleteMessage: projectDefinition.name,
          deleteButtonLabelValue: 'template',
        })

        if (response === ModalDismissed) return false

        await deleteProjectDefinition({
          id,
          workspaceId: projectDefinition.workspaceId,
        })

        showToast('success', 'Project definition deleted')

        return true
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'delete-project-definition' },
        })

        showToast('error', 'There was an error deleting the project definition')
      }

      return false
    },
    [deleteProjectDefinition, getWorkspaceProjectDefinitionById, modalApi]
  )
}
