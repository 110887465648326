import { type CustomFieldSchema } from '@motion/shared/custom-fields'

const CUSTOM_FIELD_CELL_SIZES = {
  text: { min: 131, default: 150 },
  number: { min: 90, default: 110 },
  date: { min: 90, default: 110 },
  person: { min: 90, default: 127 },
  select: { min: 90, default: 127 },
  multiSelect: { min: 90, default: 127 },
  multiPerson: { min: 90, default: 127 },
  checkbox: { min: 90, default: 100 },
  relatedTo: { min: 90, default: 100 },
  phone: { min: 90, default: 110 },
  email: { min: 131, default: 150 },
  url: { min: 131, default: 150 },
}

export function sizeOfCustomField(type: CustomFieldSchema['type']) {
  return CUSTOM_FIELD_CELL_SIZES[type]?.default ?? 110
}

export function minSizeOfCustomField(type: CustomFieldSchema['type']) {
  return CUSTOM_FIELD_CELL_SIZES[type]?.min ?? 90
}

const CUSTOM_FIELD_PREFIX = 'custom_'

export function getColumnIdFromCustomFieldId(instanceId: string): string {
  return CUSTOM_FIELD_PREFIX + instanceId // grid area names are derived from this and cannot begin with a number
}

export function getCustomFieldIdFromColumnId(
  columnId: string
): string | undefined {
  return isCustomFieldColumn(columnId)
    ? columnId.replace(CUSTOM_FIELD_PREFIX, '')
    : undefined
}

export function isCustomFieldColumn(columnId: string) {
  return columnId.startsWith(CUSTOM_FIELD_PREFIX)
}
