import { CalendarSolid } from '@motion/icons'
import { FieldButton, type FieldButtonProps, Tooltip } from '@motion/ui/base'

export type FormStartDateDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
>

export const FormStartDateDropdown = ({
  ...buttonProps
}: FormStartDateDropdownProps) => {
  return (
    <FieldButton {...buttonProps} disabled>
      <CalendarSolid />
      <Tooltip content='Start date is set when using the template.'>
        TBD
      </Tooltip>
    </FieldButton>
  )
}
