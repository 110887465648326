import { END_PROJECT_BUFFER } from './constants'

type ShrinkProjectRight = {
  maxStageWidth: number
  stageLeft: number
  maxStageLeft: number
  projectDeltaWidth: number
  projectWidth: number
}

export function shrinkProjectRight({
  stageLeft,
  maxStageWidth,
  maxStageLeft,
  projectWidth,
  projectDeltaWidth,
}: ShrinkProjectRight) {
  // Being shrunk from the right
  // Stage maxWidths should be reduced by the delta amount if it has been 'shrunk' (the right is less than the delta)
  // If the stage left is ALSO passed, the stage left should be set to the maxStageLeft - 1
  let stageRight = stageLeft + maxStageWidth
  let adjustedProjectWidth =
    projectWidth - Math.abs(projectDeltaWidth) - END_PROJECT_BUFFER

  if (stageRight >= adjustedProjectWidth) {
    let amountToReduce = stageRight - adjustedProjectWidth

    maxStageWidth -= amountToReduce

    if (maxStageWidth < 0) {
      maxStageWidth = 0
      stageLeft = adjustedProjectWidth - END_PROJECT_BUFFER
      maxStageLeft = adjustedProjectWidth - END_PROJECT_BUFFER
    }
  }

  if (stageLeft >= maxStageLeft - END_PROJECT_BUFFER) {
    stageLeft = maxStageLeft - END_PROJECT_BUFFER
  }

  return {
    stageLeft,
    maxStageWidth,
    maxStageLeft,
  }
}
