import { type TemplateProjectType } from '@motion/rpc/types'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useSelectProjectTemplate } from '~/areas/project/hooks'
import { useCallback } from 'react'

import { useProjectForm } from './use-project-form'

export const useSelectProjectModalTemplate = () => {
  const { form } = useProjectForm()
  const selectTemplate = useSelectProjectTemplate()

  return useCallback(
    async (
      item: TemplateProjectType | ProjectDefinitionSchema,
      projectId?: string
    ) => {
      try {
        await selectTemplate(item, projectId)
        if (form != null) {
          form.reset()
        }
      } catch (e) {
        throw e
      }
    },
    [form, selectTemplate]
  )
}
