import { Tooltip } from '@motion/ui/base'

import { GhostStatusDescription } from '~/global/components/tooltips'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export type GhostNameProps = {
  taskName: string
  isActive: boolean
  taskLink: string
  onLinkClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
  isCanceled?: boolean
}

export function GhostName({
  taskName,
  isActive,
  taskLink,
  onLinkClick,
  isCanceled,
}: GhostNameProps) {
  return (
    <Link
      className={twMerge(
        'text-xs truncate grow text-semantic-neutral-text-default',
        isActive && 'font-bold',
        isCanceled && 'text-semantic-neutral-text-subtle line-through'
      )}
      onClick={onLinkClick}
      to={taskLink}
    >
      <Tooltip asChild renderContent={() => <GhostStatusDescription />}>
        {taskName}
      </Tooltip>
    </Link>
  )
}
