import { type StageSchema } from '@motion/rpc-types'
import { Button, PopoverTrigger } from '@motion/ui/base'
import {
  type ETADateOptions,
  getEtaDateOptions,
  normalizeStageDeadlineStatus,
} from '@motion/ui-logic'
import { safeParseDate } from '@motion/utils/dates'

import { useUpdateProjectStageDueDate } from '~/areas/project/hooks'
import { useProjectByStageId } from '~/global/hooks'
import { useMemo, useState } from 'react'

import { StageEtaActions } from './eta-actions'
import { StageExtendDeadlineRow } from './extend-deadline-row'
import { type StageEtaPopoverContentProps } from './types'

import { ProjectedDateOptions, ReviewProjectDatesButton } from '../../common'
import { EtaBanner } from '../banner'
import { SHOW_DATE_OPTION_STATUSES } from '../constants'
import { OverdueTasksSection } from '../overdue-tasks'
import { PopoverContainer } from '../styled'
import { type CommonPopoverProps } from '../types'
import { shouldShowReviewDatesButton } from '../utils'

export const StageEtaPopover = ({
  entity,
  children,
}: CommonPopoverProps<StageSchema>) => {
  const project = useProjectByStageId(entity.id)

  if (!project) return null

  return (
    <PopoverTrigger
      renderPopover={({ close }) => {
        const deadlineStatus = normalizeStageDeadlineStatus(entity)
        if (deadlineStatus === 'none') return null

        return (
          <StageEtaPopoverContent
            stage={entity}
            project={project}
            onClose={close}
          />
        )
      }}
    >
      {children}
    </PopoverTrigger>
  )
}

export const StageEtaPopoverContent = (props: StageEtaPopoverContentProps) => {
  const { stage, project, onClose } = props
  const deadlineStatus = normalizeStageDeadlineStatus(stage)
  const updateStageDeadline = useUpdateProjectStageDueDate()
  const [selectedOption, setSelectedOption] =
    useState<ETADateOptions>('projectedDate')

  const projectedDate = stage.estimatedCompletionTime
    ? stage.estimatedCompletionTime
    : null

  const dateOptions = useMemo(() => {
    if (!projectedDate) return null

    return getEtaDateOptions(projectedDate)
  }, [projectedDate])

  const updateDateToSelectedOption = () => {
    if (!dateOptions || !dateOptions[selectedOption]) return

    const optionDate = dateOptions[selectedOption]
    const parsedDate = safeParseDate(optionDate)
    if (!parsedDate) return

    updateStageDeadline(project.id, stage.stageDefinitionId, parsedDate.toISO())
    onClose()
  }

  if (project.deadlineStatus === null) return null

  return (
    <PopoverContainer>
      <EtaBanner entity={stage} type='stage' />
      {stage.estimatedCompletionTime &&
        dateOptions &&
        SHOW_DATE_OPTION_STATUSES.includes(deadlineStatus) && (
          <div className='p-3 flex flex-col gap-3'>
            <StageExtendDeadlineRow {...props} />
            <ProjectedDateOptions
              options={dateOptions}
              selectedOption={selectedOption}
              onChooseOption={setSelectedOption}
            />
            <div className='flex justify-end gap-2'>
              {shouldShowReviewDatesButton(project) && (
                <ReviewProjectDatesButton
                  project={project}
                  sentiment='primary'
                  variant='muted'
                  size='small'
                />
              )}
              <Button
                sentiment='primary'
                variant='solid'
                size='small'
                onClick={updateDateToSelectedOption}
              >
                Extend deadline
              </Button>
            </div>
          </div>
        )}
      {deadlineStatus === 'at-risk' && (
        <OverdueTasksSection
          project={project}
          stage={stage}
          onClose={onClose}
        />
      )}
      <StageEtaActions {...props} />
    </PopoverContainer>
  )
}
