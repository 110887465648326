import { LocationMarkerSolid } from '@motion/icons'
import { TextField, type TextFieldProps } from '@motion/ui/forms'

import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventLocationField = () => {
  const { isLoading, isReadOnly } = useEventModalState()
  const { form, disabledFields } = useEventForm()
  const { control } = form

  const { field } = useController({
    name: 'location',
    control,
  })

  if (isLoading || (isReadOnly && field.value == null)) return null

  const onChange: TextFieldProps['onChange'] = async (value) => {
    field.onChange(value)
  }

  return (
    <TextField
      label='Event Location'
      labelHidden
      placeholder='Location'
      value={field.value ?? ''}
      onChange={onChange}
      prefix={<LocationMarkerSolid />}
      readOnly={isReadOnly}
      disabled={disabledFields.has('location')}
      multiline={isReadOnly}
      size='small'
    />
  )
}
