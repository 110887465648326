import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { selectBookingOnboarded } from '~/state/booking'
import { useAppSelector } from '~/state/hooks'

export const useIsBookingOnboarded = () => {
  const { selectShouldLoadBooking } = useOnboardingApi()
  const schedulerOnboarded = useAppSelector(selectBookingOnboarded)

  return schedulerOnboarded || selectShouldLoadBooking()
}
