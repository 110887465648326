type NameAndEmailLabelProps = {
  name: string
  email?: string
}
export const NameAndEmailLabel = (props: NameAndEmailLabelProps) => {
  const { name, email } = props
  return (
    <>
      <span className='ml-1.5'>{name}</span>
      {email && name !== email && (
        <span className='text-semantic-neutral-bg-strong-hover ml-2 truncate min-w-0'>
          ({email})
        </span>
      )}
    </>
  )
}
