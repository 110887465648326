import { useI18N } from '~/global/contexts'

export type WorkspaceSectionTitleProps = {
  showWorkspace?: boolean
  showProject?: boolean
  showStage?: boolean
}

export const WorkspaceSectionTitle = ({
  showWorkspace = true,
  showProject = false,
  showStage = false,
}: WorkspaceSectionTitleProps) => {
  const { formatList } = useI18N()

  if (!showWorkspace && !showProject && !showStage) {
    return null
  }

  return (
    <h2 className='text-semantic-neutral-text-subtle text-2xs pb-1 px-1'>
      {formatList(
        [
          showWorkspace && 'Workspace',
          showProject && 'Project',
          showStage && 'Stage',
        ].filter(Boolean),
        'conjunction'
      )}
    </h2>
  )
}
