import { createSelector } from '@reduxjs/toolkit'

import { type RootState } from '../store'

export const selectSelectedTeammateContacts = (state: RootState) =>
  state.calendarList.selectedTeammateContacts

/**
 * Helper selector for resolving the correct team search values depending on
 * the feature flag.
 * @param state
 */
export const selectNewTeamSearchValues = createSelector(
  [selectSelectedTeammateContacts],
  (newValue) => {
    return newValue.map((c) => c.email)
  }
)
