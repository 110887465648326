export const maxMeetingsChoices = [...Array(26).keys()]

export const startsInMap: { [day: string]: string } = {
  'day after tomorrow': 'Day after tomorrow',
  'in 2 days': 'In 2 days',
  'in 2 months': 'In 2 months',
  'in 7 days': 'In 7 days',
  'in 14 days': 'In 14 days',
  'in 30 days': 'In 30 days',
  'in 60 days': 'In 60 days',
  'next month': 'Next month',
  'next week': 'Next week',
  today: 'Today',
  tomorrow: 'Tomorrow',
}

export const startsInMapReverse: { [day: string]: string } = {
  'Day after tomorrow': 'day after tomorrow',
  'In 2 days': 'in 2 days',
  'In 2 months': 'in 2 months',
  'In 7 days': 'in 7 days',
  'In 14 days': 'in 14 days',
  'In 30 days': 'in 30 days',
  'In 60 days': 'in 60 days',
  'Next month': 'next month',
  'Next week': 'next week',
  Today: 'today',
  Tomorrow: 'tomorrow',
}

export const reminderEmailMenu = [
  { label: 'Disabled', value: 0 },
  { label: '30 min. before', value: 30 },
  { label: '1 hr. before', value: 60 },
  { label: '2 hrs. before', value: 120 },
  { label: '8 hrs. before', value: 480 },
  { label: '24 hrs. before', value: 1440 },
]
