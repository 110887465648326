import { type EmailAccount } from '@motion/rpc/types'

import { selectEmailAccountsMap } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

export function useHostEmailAccount(
  email: string | undefined
): EmailAccount | undefined {
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)

  return useMemo(
    () => Array.from(emailAccountsMap.values()).find((e) => e.email === email),
    [email, emailAccountsMap]
  )
}
