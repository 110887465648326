import { API, createUseMutation } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'
import { useCleanupCacheOnWorkspaceDelete } from '~/global/hooks'

const cacheKeysToUpdate = [API.workspacesV2.queryKeys.root, MODEL_CACHE_KEY]
const queryFilter = createQueryFilter(cacheKeysToUpdate)

const useCreateWorkspaceMutation = createUseMutation(
  API.workspacesV2.createWorkspace
)
export const useCreateWorkspace = () => {
  const queryClient = useQueryClient()

  return useCreateWorkspaceMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useUpdateWorkspaceMutation = createUseMutation(
  API.workspacesV2.updateWorkspace
)
export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient()

  return useUpdateWorkspaceMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useDeleteWorkspaceMutation = createUseMutation(
  API.workspacesV2.deleteWorkspace
)
export const useDeleteWorkspace = () => {
  const queryClient = useQueryClient()
  const cleanupCacheOnWorkspaceDelete = useCleanupCacheOnWorkspaceDelete()

  return useDeleteWorkspaceMutation({
    onSuccess: (_, { workspaceId }) => {
      MotionCache.delete(queryClient, queryFilter, 'workspaces', workspaceId)
      cleanupCacheOnWorkspaceDelete(workspaceId)
    },
  })
}
