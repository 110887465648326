import { AlertModal } from '@motion/ui/base'

import { PaymentInterval } from '~/components/Team/types/PaymentInterval'
import { type DateTime } from 'luxon'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'pay-team-form-success': {
      chargeAmount: number
      endDate: DateTime
      paymentInterval: PaymentInterval
    }
  }
}

export const PayTeamFormSuccessModal = ({
  chargeAmount,
  endDate,
  paymentInterval,
  close,
}: ModalTriggerComponentProps<'pay-team-form-success'>) => {
  return (
    <AlertModal
      onClose={close}
      actions={[
        {
          label: 'Ok got it',
          onAction: () => close(),
          sentiment: 'neutral',
        },
      ]}
      title='Payment details added successfully'
      visible
    >
      <p className='whitespace-normal break-words text-sm text-semantic-neutral-text-subtle'>
        You will be charged ${chargeAmount} at the end of your trial (
        {endDate.toFormat('LLL dd, yyyy')}) and your subscription will be
        renewed automatically{' '}
        {paymentInterval === PaymentInterval.Annually ? 'annually' : 'monthly'}.
      </p>
    </AlertModal>
  )
}
