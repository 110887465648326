import { type BulkOpsCustomFieldsSchema } from '@motion/shared/custom-fields'
import {
  type BulkFieldUpdateSchema,
  type StageDefinitionSchema,
  type StatusSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { createContext } from 'react'

export type BulkOpsFieldName = keyof BulkOpsFieldsValue['fields']
export type BulkOpsFieldValue<T extends BulkOpsFieldName> =
  BulkOpsFieldsValue['fields'][T]
export type BulkCustomFieldValues = Record<string, BulkOpsCustomFieldsSchema>

export interface BulkOpsFieldsValue {
  fields: {
    statusIds: StatusSchema['id'][]
    isAutoScheduled: TaskSchema['isAutoScheduled'] | undefined
    assigneeUserId: TaskSchema['assigneeUserId'] | undefined
    priorityLevel: TaskSchema['priorityLevel'] | undefined
    labels: BulkFieldUpdateSchema['labels']
    duration: TaskSchema['duration'] | undefined
    dueDate: TaskSchema['dueDate'] | undefined
    startDate: TaskSchema['startDate'] | undefined
    stageDefinitionIds: StageDefinitionSchema['id'][]
    customFieldValues: BulkCustomFieldValues | undefined
  }
  setFieldValue: <T extends BulkOpsFieldName>(
    field: T,
    value: BulkOpsFieldValue<T>
  ) => void

  resetAllFields: () => void
}

export const defaultValue: BulkOpsFieldsValue = {
  fields: {
    statusIds: [],
    isAutoScheduled: undefined,
    assigneeUserId: undefined,
    priorityLevel: undefined,
    labels: undefined,
    duration: undefined,
    dueDate: undefined,
    startDate: undefined,
    stageDefinitionIds: [],
    customFieldValues: undefined,
  },
  setFieldValue: () => {},
  resetAllFields: () => {},
}

export const BulkOpsFieldsContext =
  createContext<BulkOpsFieldsValue>(defaultValue)
