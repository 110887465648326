import { ChevronRightSolid } from '@motion/icons'
import { type PMProjectTypeWithItemType } from '@motion/rpc/types'
import { FieldButton } from '@motion/ui/base'

import { useTemplateTaskForm } from '../hooks'

export const FormWorkspaceProjectDropdown = () => {
  const { options, hiddenFields } = useTemplateTaskForm()

  const hideProjectsFromDropdown = hiddenFields.has('projectId')

  const { selectedWorkspace, savedTemplateProject } = options

  const selectedProject = savedTemplateProject?.project as
    | PMProjectTypeWithItemType
    | undefined

  if (selectedWorkspace == null) return null

  return (
    <FieldButton size='small' variant='muted' disabled>
      {hideProjectsFromDropdown ? (
        <span className='truncate'>{selectedWorkspace?.name}</span>
      ) : (
        <>
          <span className='truncate max-w-[100px]'>
            {selectedWorkspace?.name}
          </span>
          <ChevronRightSolid className='w-3 h-3 text-semantic-neutral-icon-default' />
          <span className='truncate max-w-[250px]'>
            {selectedProject?.name ?? 'No project'}
          </span>
        </>
      )}
    </FieldButton>
  )
}
