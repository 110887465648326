import {
  type AllModelsSchema,
  type RecurringTaskSchema,
  type ScheduledEntitySchema,
  type TaskSchema,
} from '@motion/zod/client'

export function getTaskModels(
  task: TaskSchema | RecurringTaskSchema,
  scheduledEntity?: ScheduledEntitySchema
): Partial<
  Pick<AllModelsSchema, 'tasks' | 'recurringTasks' | 'scheduledEntities'>
> {
  if (task.type === 'RECURRING_TASK') {
    return { recurringTasks: { [task.id]: task } }
  }

  const model = { tasks: { [task.id]: task } }

  if (scheduledEntity) {
    return {
      ...model,
      scheduledEntities: {
        [scheduledEntity.id]: scheduledEntity,
      },
    }
  }

  return model
}
