import { useSharedState } from '@motion/react-core/shared-state'
import { type COLOR } from '@motion/shared/common'
import { Portal } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  DndContext,
  type DragEndEvent,
  type DragMoveEvent,
  DragOverlay,
  type DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { useShiftProjectDates } from '~/areas/project/hooks'
import { type ProjectWithRelations } from '~/global/proxies'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'

import { useShiftToContainer } from './overlay-modifier'

import { usePlannerProps } from '../../context'
import { ScrollDeltaKey } from '../../context/context-keys'
import {
  DRAGGABLE_PROJECT_DISTANCE_THRESHOLD,
  ZIndexMap,
} from '../../shared-constants'
import { getProjectStartAndEnd, pixelToDate } from '../../utils'
import {
  ProjectCardContent,
  ProjectItem,
  ProjectItemTooltip,
} from '../resizeable-project-item'

type DragRowContainerProps = {
  project: ProjectWithRelations
  children: React.ReactNode
}

export const DragRowContainer = (props: DragRowContainerProps) => {
  const [activeId, setActiveId] = useState<string | null>(null)
  const [plannerProps, setPlannerProps] = usePlannerProps()
  const [scrollDelta] = useSharedState(ScrollDeltaKey)
  const [currentDelta, setCurrentDelta] = useState(0)
  const confirmShiftProject = useShiftProjectDates()
  const shiftToContainer = useShiftToContainer()

  let activeProject = props.project.id === activeId ? props.project : null

  const getAdjustedStartDate = useCallback(
    (delta: number) => {
      if (!activeProject) {
        return
      }

      const { start } = getProjectStartAndEnd(activeProject, plannerProps.dayPx)
      const newX = Math.floor(start + delta + scrollDelta)

      return pixelToDate(newX, plannerProps.dayPx)
    },
    [plannerProps.dayPx, activeProject, scrollDelta]
  )

  const getAdjustedEndDate = useCallback(
    (delta: number) => {
      if (!activeProject) {
        return
      }

      let { start, end } = getProjectStartAndEnd(
        activeProject,
        plannerProps.dayPx
      )
      const newX = Math.floor(start + delta + scrollDelta)

      end = newX + Math.abs(start - end)
      return pixelToDate(end, plannerProps.dayPx)
    },
    [plannerProps.dayPx, activeProject, scrollDelta]
  )

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_DRAG_START', {
        stacked: false,
      })
      setActiveId(event.active.id.toString())
      setPlannerProps((prev) => ({
        ...prev,
        resizingId: event.active.id.toString(),
      }))
    },
    [setPlannerProps]
  )

  const handleDragMove = useCallback((event: DragMoveEvent) => {
    setCurrentDelta(event.delta.x)
  }, [])

  const handleDragCancel = useCallback(() => {
    setActiveId(null)
    setCurrentDelta(0)
    setPlannerProps((prev) => ({
      ...prev,
      resizingId: null,
    }))
  }, [setPlannerProps])

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (!activeProject) {
        return
      }
      // Get the delta
      const { x: xDelta } = event.delta
      // Interpolate the new start and end dates
      const newStartDate = getAdjustedStartDate(xDelta)
      const newEndDate = getAdjustedEndDate(xDelta)

      if (!newStartDate || !newEndDate) {
        handleDragCancel()
        return
      }

      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_DRAG_END', {
        stacked: false,
      })

      let numDaysToShift: number = 0

      if (activeProject.startDate) {
        numDaysToShift = newStartDate.diff(
          DateTime.fromISO(activeProject.startDate),
          'days'
        ).days
      } else if (activeProject.dueDate) {
        numDaysToShift = newEndDate.diff(
          DateTime.fromISO(activeProject.dueDate),
          'days'
        ).days
      }

      if (numDaysToShift !== 0) {
        await confirmShiftProject({
          projectId: activeProject.id,
          numDays: numDaysToShift,
        })
      }

      handleDragCancel()
    },
    [
      activeProject,
      getAdjustedStartDate,
      getAdjustedEndDate,
      handleDragCancel,
      confirmShiftProject,
    ]
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: DRAGGABLE_PROJECT_DISTANCE_THRESHOLD },
    })
  )

  const projectStart = getAdjustedStartDate(currentDelta)
  const projectEnd = getAdjustedEndDate(currentDelta)
  const projectColor: COLOR = activeProject?.color ?? 'gray'

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToHorizontalAxis]}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      autoScroll={{
        enabled: false,
        layoutShiftCompensation: false,
      }}
    >
      {props.children}
      {plannerProps.containerNode && (
        <Portal container={plannerProps.containerNode}>
          <DragOverlay
            dropAnimation={null}
            zIndex={ZIndexMap.projectItem}
            modifiers={[shiftToContainer]}
          >
            {activeProject && (
              <ProjectPalette color={projectColor}>
                <ProjectItemTooltip
                  container={plannerProps.containerNode?.parentElement ?? null}
                  placement='top-end'
                  open
                  renderContent={(close) => (
                    <ProjectCardContent
                      projectStart={
                        activeProject.startDate ? projectStart : undefined
                      }
                      projectEnd={
                        activeProject.dueDate
                          ? projectEnd?.minus({ days: 1 })
                          : undefined
                      }
                      project={activeProject}
                      close={close}
                      isDragging
                    />
                  )}
                >
                  <div className='relative w-full h-full'>
                    <ProjectItem
                      project={activeProject}
                      isDragging
                      ignoreStageResizing
                    />
                  </div>
                </ProjectItemTooltip>
              </ProjectPalette>
            )}
          </DragOverlay>
        </Portal>
      )}
    </DndContext>
  )
}
