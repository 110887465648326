import { ProjectPalette } from '@motion/ui/project'
import { useHasTreatment } from '@motion/web-common/flags'

import { type ProjectWithRelations } from '~/global/proxies'
import { memo, useState } from 'react'

import { usePlannerProps } from '../../../context'
import { useScreenValues } from '../../../hooks'
import { PROJECT_ITEM_GAP } from '../../../shared-constants'
import { getProjectStartAndEnd } from '../../../utils'
import {
  ProjectItem,
  ResizeableProjectItem,
} from '../../resizeable-project-item'
import { DragRowContainer } from '../drag-row-container'

type ProjectContainerProps = {
  project: ProjectWithRelations
}

export const ProjectContainer = memo(function ProjectContainer({
  project,
}: ProjectContainerProps) {
  const hasOptimizedGantt = useHasTreatment('optimize-gantt')
  const [active, setActive] = useState(false)

  return (
    <div
      className='w-full h-12 flex flex-row items-center relative'
      onPointerEnter={() => setActive(true)}
    >
      {!hasOptimizedGantt || active ? (
        <DragRowContainer project={project}>
          <ProjectPalette color={project.color}>
            <ResizeableProjectItem project={project} />
          </ProjectPalette>
        </DragRowContainer>
      ) : (
        <ProjectItemPlaceholder project={project} />
      )}
    </div>
  )
})

const ProjectItemPlaceholder = (props: ProjectContainerProps) => {
  const [plannerProps] = usePlannerProps()
  const { positionOffset } = useScreenValues()
  const { project } = props
  const { start, end } = getProjectStartAndEnd(project, plannerProps.dayPx)
  const projectLeft = start + positionOffset
  const projectWidth = end - start - PROJECT_ITEM_GAP
  return (
    <div
      className='absolute h-10 truncate'
      style={{ left: projectLeft, width: projectWidth }}
    >
      <ProjectPalette color={project.color}>
        <ProjectItem
          project={project}
          left={projectLeft}
          width={projectWidth}
          fadedLeft={!project.startDate}
          fadedRight={!project.dueDate}
          isPlaceholder
        />
      </ProjectPalette>
    </div>
  )
}
