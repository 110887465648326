import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { type TaskSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'
import { useCallback } from 'react'

export function useProjectDefinitionForTask() {
  const projectDefinitionsById = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.projectDefinitions.byId
  )
  const projectsById = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.projects.byId
  )

  return useCallback(
    (task: TaskSchema) => {
      if (task.projectId == null) return null

      const project = projectsById[task.projectId]

      if (project == null || project.projectDefinitionId == null) return null

      const projectDefinition =
        projectDefinitionsById[project.projectDefinitionId]

      if (projectDefinition == null) return null

      return projectDefinition
    },
    [projectDefinitionsById, projectsById]
  )
}
