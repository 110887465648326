import { isNoneId } from '@motion/shared/identifiers'

import { BlockedByBlockerDropdown } from '~/areas/tasks/components/dropdowns'
import { PrettyLabels } from '~/global/components/labels'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction } from './components'

type TaskBlockedByCellProps = {
  task: TaskWithRelations
}

export const TaskBlockedByCell = ({ task }: TaskBlockedByCellProps) => {
  const isFake = isNoneId(task.id)

  if (isFake || task.type !== 'NORMAL' || task.projectId == null) {
    return (
      <CellAction disabled isFake={isFake}>
        <span className='text-semantic-neutral-text-subtle'>None</span>
      </CellAction>
    )
  }

  const values = task.blockedByTasks.filter(Boolean).map((blocker) => ({
    id: blocker.id,
    name: blocker.name,
    color: 'grey',
  }))

  return (
    <BlockedByBlockerDropdown task={task}>
      <CellAction disabled={task.archivedTime != null}>
        {task.blockedByTaskIds.length === 0 ? (
          <span className='text-semantic-neutral-text-subtle'>None</span>
        ) : (
          <PrettyLabels values={values} />
        )}
      </CellAction>
    </BlockedByBlockerDropdown>
  )
}
