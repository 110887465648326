import { PaperClipSolid } from '@motion/icons'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useProjectDefinition } from '~/global/hooks'

import { useFlowTemplateForm } from '../hooks'

export const ControlledAttachmentsField = () => {
  const modalApi = useModalApi()
  const { form } = useFlowTemplateForm()
  const { watch } = form

  const projectDefinitionId = watch('id')
  const workspaceId = watch('workspaceId')
  const projectDefinition = useProjectDefinition(projectDefinitionId)
  if (!projectDefinitionId || !workspaceId || !projectDefinition) {
    return null
  }

  const openAttachmentsModal = () => {
    recordAnalyticsEvent('FLOW_TEMPLATE_ATTACHMENTS_MODAL_OPEN')
    modalApi.open('project-definition-attachments-modal', {
      projectDefinitionId,
      workspaceId,
    })
  }
  const attachmentIds = projectDefinition.uploadedFileIds
  const attachmentsCount = attachmentIds?.length ?? 0

  return (
    <ModalFieldButton label='Attachments' onClick={openAttachmentsModal}>
      <PaperClipSolid />
      {attachmentsCount === 0 ? 'None' : attachmentsCount}
    </ModalFieldButton>
  )
}
