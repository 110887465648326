import { classed } from '@motion/theme'
import {
  formatToShortTaskDuration,
  formatToTaskDuration,
} from '@motion/ui-logic'

import {
  DurationBadge,
  type DurationBadgeProps,
} from '~/global/components/badges'

type DurationLabelProps = {
  value: number | null
  size?: DurationBadgeProps['size']
  longMode?: boolean
}

export const DurationLabel = ({
  value,
  size,
  longMode,
}: DurationLabelProps) => {
  const formattedDuration = longMode
    ? formatToTaskDuration(value)
    : formatToShortTaskDuration(value)

  return (
    <DurationContainer>
      <DurationBadge value={value} size={size} hideTooltip />
      <DurationText size={size === 'xsmall' ? 'xsmall' : 'small'}>
        {formattedDuration}
      </DurationText>
    </DurationContainer>
  )
}

const DurationContainer = classed('div', {
  base: `
    flex flex-row items-center overflow-hidden leading-4 gap-1
  `,
})

const DurationText = classed('span', {
  base: '',
  variants: {
    size: {
      small: 'text-xs',
      xsmall: 'text-[10px]',
    },
  },
  defaultVariants: {
    size: 'small',
  },
})
