import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { StageTags } from '~/global/components'
import { WorkspaceBadge } from '~/global/components/badges'
import { useWorkspaceById } from '~/global/hooks'

export type TemplateSearchRowProps = {
  label: React.ReactNode
  workspaceId: string
  onClick: (() => Promise<void>) | undefined
  url: string | undefined
  stages: StageDefinitionSchema[] | undefined
}

export function TemplateSearchRow(props: TemplateSearchRowProps) {
  const workspace = useWorkspaceById(props.workspaceId)

  return (
    <RowContainer>
      <Row>
        <div className='flex flex-row items-center flex-grow gap-2'>
          <div className='text-semantic-neutral-text-default font-semibold text-sm'>
            {props.label}
          </div>
        </div>

        <div className='flex flex-row items-center gap-4 text-2xs'>
          <div className='flex flex-row items-center gap-1 text-semantic-neutral-text-subtle'>
            <WorkspaceBadge />
            {workspace?.name}
          </div>
          <Button
            onClick={props.onClick ?? undefined}
            url={props.url ?? undefined}
            size='small'
          >
            Use template
          </Button>
        </div>
      </Row>
      {props.stages && <StageTags stages={props.stages} />}
    </RowContainer>
  )
}

const RowContainer = classed('div', {
  base: `
    flex flex-col gap-3
    py-4
    text-semantic-neutral-text-default
  `,
})

const Row = classed('div', {
  base: `
    flex flex-row items-center justify-between
  `,
})
