import { type PMTaskType } from '@motion/rpc/types'
import { type ProjectSchema } from '@motion/zod/client'

import { useLegacyProjectTemplateById, useProject } from '~/global/hooks'
import { createContext, useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { type TemplateProjectFormFields } from './types'

export type TemplateProjectFormOptions = {
  savedProject: ProjectSchema | null
  tasks: PMTaskType[]
}

const defaultValue: TemplateProjectFormOptions = {
  savedProject: null,
  tasks: [],
}

const TemplateProjectFormOptionsContext = createContext(defaultValue)

type TemplateProjectFormOptionsProviderProps = {
  children: React.ReactNode
}

export function TemplateProjectFormOptions({
  children,
}: TemplateProjectFormOptionsProviderProps) {
  const options = useOptionsData()

  return (
    <TemplateProjectFormOptionsContext.Provider value={options}>
      {children}
    </TemplateProjectFormOptionsContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export function useTemplateProjectFormOptions() {
  return useContext(TemplateProjectFormOptionsContext)
}

function useOptionsData() {
  const form = useFormContext<TemplateProjectFormFields>()
  const projectId = form.watch('id')
  const templateProjectId = form.watch('templateId')

  const templateProject = useLegacyProjectTemplateById(templateProjectId)

  const projectTasks = useMemo(
    () =>
      (templateProject?.project.tasks.map((t) => ({
        ...t,
        labels: t.labelIds.map((id) => ({
          labelId: id,
        })),
        blockingTasks: t.blockingTaskIds.map((id) => ({
          blockedId: id,
        })),
        blockedTasks: t.blockedByTaskIds.map((id) => ({ blockedId: id })),
        schedule: t.scheduleId ?? undefined,
        templateProjectId: templateProject?.id,
      })) ?? []) as unknown as PMTaskType[],
    [templateProject?.id, templateProject?.project.tasks]
  )

  const savedProject = useProject(projectId)

  return useMemo(() => {
    return {
      savedProject,
      tasks: projectTasks,
    }
  }, [savedProject, projectTasks])
}
