import { PlusSolid, XSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { formatDurationTime } from '@motion/ui-logic'

import { Combobox } from '~/components/Common'
import { linkDurationChoicesMin } from '~/storageConstants'
import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const EventDurationRow = () => {
  const {
    template: {
      state: { durationChoices },
      setters: { setDurationChoices },
      onDurationChange,
    },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow
      title='Duration'
      subtitle='You can add more than 1 option to avoid having multiple templates for different durations. Your guest will be able to choose how much time they need'
    >
      <div className='space-y-2'>
        {durationChoices.map((choice, idx) => (
          <div key={choice} className='flex w-full items-center gap-2'>
            <Combobox
              className='max-w-[250px]'
              value={durationChoices[idx]}
              onChange={(value: number) => onDurationChange(idx, value)}
              autoComplete
              options={linkDurationChoicesMin.map((time) => ({
                label: formatDurationTime(time),
                value: time,
              }))}
            />

            {durationChoices.length > 1 && (
              <Button
                size='small'
                variant='muted'
                sentiment='neutral'
                onClick={() =>
                  setDurationChoices((prev) => {
                    const prevCopy = [...prev]
                    prevCopy.splice(idx, 1)
                    return prevCopy
                  })
                }
                iconOnly
              >
                <XSolid className='size-2' />
              </Button>
            )}
          </div>
        ))}

        {durationChoices.length < linkDurationChoicesMin.length && (
          <Button
            size='small'
            variant='muted'
            sentiment='neutral'
            onClick={() =>
              setDurationChoices((prev) => {
                const availableChoices = linkDurationChoicesMin.filter(
                  (choice) => !prev.includes(choice)
                )

                if (availableChoices.length) {
                  return [...prev, availableChoices[0]].sort((a, b) => a - b)
                }

                return [...prev]
              })
            }
          >
            <PlusSolid /> Add another duration option
          </Button>
        )}
      </div>
    </SectionRow>
  )
}
