import { type Schedule } from '@motion/rpc-types'
import { logInDev } from '@motion/web-base/logging'

import { useCallback } from 'react'

import api from '../../chromeApi/chromeApiContentScript'
import { dispatch, store } from '../../state/proxy'
import { setSchedules, updateSchedules } from '../../state/settingsSlice'
import { validateSchedules } from '../../utils/settingsUtils'

export const useUpdateSchedules = () => {
  return useCallback(async (schedules: Record<string, Schedule>) => {
    logInDev('updateSchedules', schedules)

    try {
      validateSchedules(schedules)
    } catch (e) {
      logInDev('Error validating schedules', e)
      throw new Error(
        'There was an error updating your schedules, please try again'
      )
    }

    await api.storage.local.set({ schedules })
    await dispatch(updateSchedules(schedules))
    store.dispatch(setSchedules(schedules))
  }, [])
}
