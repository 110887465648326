import { ClockSolid } from '@motion/icons'
import {
  FieldButton,
  type FieldButtonProps,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { formatDurationToShort, NO_DURATION } from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useController } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { useTemplateTaskForm } from '../hooks'

export type FormDurationDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
> & {
  forceHideMinChunksControl?: boolean
}

export const FormDurationDropdown = ({
  forceHideMinChunksControl = false,
  ...buttonProps
}: FormDurationDropdownProps) => {
  const { form, options, disabledFields, hiddenFields } = useTemplateTaskForm()

  const { control, watch } = form
  const { durations } = options

  const { field: durationField } = useController({
    name: 'duration',
    control,
  })

  const selectedDurationValue = watch('duration')
  const selectedDuration = durations.find(
    (c) => c.value === selectedDurationValue
  )

  const durationLabel =
    selectedDuration?.label ?? formatDurationToShort(selectedDurationValue ?? 0)

  return (
    <DurationDropdown
      value={selectedDurationValue}
      choices={durations}
      onChange={(value) => durationField.onChange(value)}
      renderPopoverFooter={() => {
        if (forceHideMinChunksControl || hiddenFields.has('minChunkDuration'))
          return

        return (
          <div className='flex flex-col border-t border-dropdown-border mt-1 p-3'>
            <div className='text-xs text-semantic-neutral-text-subtle mb-2'>
              Minimum chunk duration
            </div>
            <MinChunkDurationPopover />
          </div>
        )
      }}
    >
      <FieldButton
        {...buttonProps}
        disabled={disabledFields.has('duration')}
        onClear={
          buttonProps.fullWidth && selectedDurationValue !== NO_DURATION
            ? () => durationField.onChange(NO_DURATION)
            : undefined
        }
        clearTooltipContent='Remove duration'
      >
        <ClockSolid />
        <span
          className={twMerge(
            'truncate',
            buttonProps.size === 'small' && 'max-w-[70px]'
          )}
        >
          {durationLabel}
        </span>
      </FieldButton>
    </DurationDropdown>
  )
}

function MinChunkDurationPopover() {
  const { form, options } = useTemplateTaskForm()

  const { control, watch } = form
  const { chunkDurations } = options

  const { field: minChunkDurationField } = useController({
    name: 'minChunkDuration',
    control,
  })

  const selectedMinChunkDuration = watch('minChunkDuration')
  const selectedMinChunk = chunkDurations.find(
    (c) => c.value === selectedMinChunkDuration
  )

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={chunkDurations}
          computeKey={(item) => String(item.value)}
          computeSelected={(item) => item.value === selectedMinChunkDuration}
          onSelect={(item) => {
            minChunkDurationField.onChange(item.value)
            close()
          }}
          renderItem={(item) => item.label}
        />
      )}
    >
      <PopoverButton>{selectedMinChunk?.label}</PopoverButton>
    </PopoverTrigger>
  )
}
