import { type UploadedFileSchema } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'

import { useEmptyResultsIcon } from '~/global/hooks'

import { AttachmentUploadButton } from '../../../attachment-upload-button'

export type AttachmentGroupsEmptyStateProps = {
  query: string
  onClearQuery: () => void
  targetId?: UploadedFileSchema['targetId']
  targetType?: UploadedFileSchema['targetType']
  workspaceId?: UploadedFileSchema['workspaceId']
}

export function AttachmentGroupsEmptyState({
  query,
  onClearQuery,
  targetId,
  targetType,
  workspaceId,
}: AttachmentGroupsEmptyStateProps) {
  const Illustration = useEmptyResultsIcon()

  return (
    <div className='flex flex-col justify-center items-center w-full h-full gap-4 py-2'>
      {query ? (
        <>
          <Illustration className='size-[120px]' />
          <span className='text-sm font-semibold text-semantic-neutral-text-default'>
            No results for &quot;{query}&quot;
          </span>
          <Button variant='outlined' sentiment='neutral' onClick={onClearQuery}>
            Remove search query
          </Button>
        </>
      ) : (
        <>
          <span className='text-sm font-semibold text-semantic-neutral-text-default'>
            No attachments yet
          </span>
          {targetId != null && targetType != null && workspaceId != null && (
            <AttachmentUploadButton
              targetId={targetId}
              targetType={targetType}
              workspaceId={workspaceId}
              variant='outlined'
            />
          )}
        </>
      )}
    </div>
  )
}
