type GrowProjectRightArgs = {
  // Whether the stage we're calculating is the first stage
  isLastStage: boolean
  delta: number
  maxStageWidth: number
  stageLeft: number
  maxStageLeft: number
}

export function growProjectRight({
  delta,
  isLastStage = false,
  maxStageWidth,
  stageLeft,
  maxStageLeft,
}: GrowProjectRightArgs) {
  if (isLastStage) {
    return {
      maxStageWidth: maxStageWidth + delta,
      stageLeft,
      maxStageLeft: maxStageLeft + delta,
    }
  }

  return {
    stageLeft,
    maxStageWidth,
    maxStageLeft,
  }
}
