import { useModalApi } from '@motion/web-common/modals'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useCallback } from 'react'

import { useTaskDefinitionForm } from '../use-task-definition-form'

export const useShowNotSavedModalIfDirtyFn = (
  confirmModalOptions?: Partial<ModalTriggerComponentProps<'confirm'>>
) => {
  const { form } = useTaskDefinitionForm()
  const {
    formState: { isDirty },
  } = form

  const modalApi = useModalApi()

  return useCallback(async () => {
    if (!isDirty) {
      return true
    }

    return modalApi.prompt('confirm', {
      analytics: {
        name: 'pending-changes',
      },
      title: `Your changes aren't saved`,
      closeButtonText: 'Go back',
      confirmButtonText: 'Close without saving',
      ...confirmModalOptions,
    })
  }, [isDirty, modalApi, confirmModalOptions])
}
