import {
  getSetupProjectChangedFields,
  isSetupProjectChangedFieldName,
  type SetupProjectFormFields,
} from '@motion/ui-logic/pm/project'
import { isEqual } from '@motion/utils/core'
import { keys } from '@motion/utils/object'

import { type ReactNode, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useInitialFormData, useSetupProjectForm } from './hooks'
import { useSyncCustomFields } from './hooks/use-sync-custom-fields'

export type SetupProjectFormProps = {
  children: ReactNode
}

export const SetupProjectForm = ({ children }: SetupProjectFormProps) => {
  const initialFormData = useInitialFormData()

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  useSyncCustomFields(form, initialFormData.customFieldValuesFieldArray)

  const { isDirty } = form.formState

  /**
   * Reset the form to the initial values
   * when the form is not dirty and the initial form data changes
   */
  useEffect(() => {
    if (!isDirty && !isEqual(initialFormData, form.getValues())) {
      form.reset(initialFormData, {
        keepDefaultValues: true,
      })
    }
  }, [form, initialFormData, isDirty])

  return (
    <FormProvider {...form}>
      <SetupProjectFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

const SetupProjectFormValuesUpdater = () => {
  const { form } = useSetupProjectForm()
  const prevFormValues = useRef<SetupProjectFormFields>(form.getValues())

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((formValuesArg, { name }) => {
      if (name == null) return

      if (isSetupProjectChangedFieldName(name)) {
        // Type is wrong; this isn't partial
        const formValues = formValuesArg as SetupProjectFormFields

        const changedFields = getSetupProjectChangedFields(
          formValues,
          prevFormValues.current,
          name
        )

        keys(changedFields).forEach((formFieldKey) => {
          if (!(formFieldKey in formValues)) {
            throw new Error(
              `cannot set value of unknown key "${formFieldKey}" in form`
            )
          }

          const newValue = changedFields[formFieldKey] ?? null
          form.setValue(formFieldKey, newValue, {
            shouldDirty: true,
          })
        })
      }

      prevFormValues.current = formValuesArg as SetupProjectFormFields
    })
    return () => subscription.unsubscribe()
  }, [form])

  return null
}
