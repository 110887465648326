import {
  convertDataFieldsToUpdateSchema,
  type EventFormFields,
} from '@motion/ui-logic'
import { keys } from '@motion/utils/object'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useUpdateEvent } from '~/areas/event/hooks'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useEventForm } from './use-event-form'

export function useFormUpdateEvent() {
  const { form } = useEventForm()
  const updateEvent = useUpdateEvent()

  const eventId = form.watch('id')

  return useCallback(
    async (
      fields: EventFormFields
    ): Promise<CalendarEventSchemaV2 | undefined> => {
      const { dirtyFields } = form.formState

      if (eventId == null) {
        throw new Error(`Can't save an event without an id`)
      }

      const dirtyFieldsKeys = keys(dirtyFields)

      const dataToUpdate = keys(dirtyFields).reduce<Partial<EventFormFields>>(
        (acc, field) => ({ ...acc, [field]: fields[field] }),
        {}
      )

      try {
        const updates = {
          ...convertDataFieldsToUpdateSchema(dataToUpdate),

          // When only updating the color, we don't send any updates
          sendUpdates:
            dirtyFieldsKeys.length === 1 && dirtyFieldsKeys.includes('colorId')
              ? false
              : undefined,
        }

        return updateEvent(eventId, updates)
      } catch (e) {
        showErrorToast(e)
      }
    },
    [eventId, form.formState, updateEvent]
  )
}
