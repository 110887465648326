import { redirect } from 'react-router'

import { route as revampRoute } from './pages/pm-revamp-routes'
import { generatePMRevampRedirectUrl } from './utils/pm-revamp-redirect'

import { type MotionRoute } from '../../routing/api'

export const projectManagementRoutes: MotionRoute[] = [
  /**
   * Support old project management routes.
   * Leave here for a long time until we're sure there are no more old route links in the wild.
   */
  {
    path: 'projectManagement',
    loader: (args) => {
      if (args.request.method !== 'GET') return {}
      const newUrl = generatePMRevampRedirectUrl(args.request.url)
      return redirect(newUrl)
    },
  },
  revampRoute,
]
