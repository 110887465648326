import { useClosure } from '@motion/react-core/hooks'
import { useSharedStateContext } from '@motion/react-core/shared-state'
import { createPendingStatus, createUnknownStage } from '@motion/rpc-cache'
import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'
import { isNoneId, isObjectNoneId } from '@motion/shared/identifiers'
import { type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { createNoneUser } from '@motion/ui-logic/pm/data'
import { byProperty, byValue, Compare } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import {
  type LabelSchema,
  type ProjectSchema,
  type StatusSchema,
  type TaskSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useLookup } from '~/global/cache'

import {
  type WorkspaceLabelsOptions,
  type WorkspaceProjectsOptions,
} from './types'

import {
  AppWorkspaceContext,
  type WorkspaceStageDefinition,
} from '../../contexts'

/**
 * Provide a suite of stable functions to read a workspace, or data within a workspace (statuses, members, labels, ...)
 * Do not use these for rendering. Only use this for data you need in handlers/callbacks
 */
export function useWorkspaceFns() {
  const lookup = useLookup()
  const sharedStateApi = useSharedStateContext()

  const getContext = useClosure(() => {
    return sharedStateApi.get(AppWorkspaceContext)
  })

  const getAllWorkspaces = useClosure((): WorkspaceSchema[] => {
    return getContext().workspaces.all
  })

  const getMyTasksWorkspace = useClosure((): WorkspaceSchema | undefined => {
    return getContext().workspaces.all.find((w) => w.isPersonalWorkspace)
  })

  const getWorkspaceById = useClosure(
    (workspaceId: string): WorkspaceSchema | undefined => {
      return getContext().workspaces.byId[workspaceId]
    }
  )

  const getWorkspaceProjects = useClosure(
    (
      workspaceId: string,
      options: Partial<WorkspaceProjectsOptions> = {}
    ): ProjectSchema[] => {
      const { includeNoProject = false } = options

      return getContext().projects.all.filter((p) => {
        const shouldIncludeNoProject = includeNoProject ? true : !isNoneId(p.id)
        return p.workspaceId === workspaceId && shouldIncludeNoProject
      })
    }
  )

  const getWorkspaceProjectById = useClosure(
    (
      projectId: ProjectSchema['id'] | null | undefined
    ): ProjectSchema | undefined => {
      if (projectId == null) return

      return getContext().projects.byId[projectId]
    }
  )

  const getWorkspaceProjectByTaskId = useClosure(
    (taskId: TaskSchema['id'] | null | undefined) => {
      if (taskId == null) return null

      const task = lookup('tasks', taskId)
      if (task == null) return null

      return getWorkspaceProjectById(task.projectId)
    }
  )

  const getWorkspaceStatuses = useClosure(
    (workspaceId: string): StatusSchema[] => {
      return getContext()
        .statuses.all.filter(
          (status) => status.workspaceId === workspaceId && !status.deleted
        )
        .sort(byProperty('sortPosition', Compare.string))
    }
  )

  const getStatusById = useClosure(
    (statusId: StatusSchema['id']): StatusSchema => {
      return lookup('statuses', statusId)
    }
  )

  const getWorkspaceStatusByName = useClosure(
    (workspaceId: string, name: string): StatusSchema | undefined => {
      const lowerName = name.toLowerCase()

      return getContext().statuses.all.find(
        (status) =>
          status.workspaceId === workspaceId &&
          status.name.toLowerCase() === lowerName
      )
    }
  )

  const getWorkspaceCompletedStatus = useClosure(
    (workspaceId: string): StatusSchema => {
      return (
        getWorkspaceStatuses(workspaceId).find(isCompletedStatus) ??
        createPendingStatus(workspaceId)
      )
    }
  )

  const getWorkspaceCanceledStatus = useClosure(
    (workspaceId: string): StatusSchema => {
      return (
        getWorkspaceStatuses(workspaceId).find(isCanceledStatus) ??
        createPendingStatus(workspaceId)
      )
    }
  )

  const getWorkspaceLabels = useClosure(
    (
      workspaceId: string,
      options: Partial<WorkspaceLabelsOptions> = {}
    ): LabelSchema[] => {
      const { includeNoLabels = false, includeDeleted = false } = options

      return getContext().labels.all.filter((s) => {
        const shouldIncludeNoLabels = includeNoLabels ? true : !isNoneId(s.id)
        const shouldIncludeDeleted = includeDeleted ? true : !s.deleted
        const shouldInclude = shouldIncludeNoLabels && shouldIncludeDeleted

        return s.workspaceId === workspaceId && shouldInclude
      })
    }
  )

  const getWorkspaceLabelByName = useClosure(
    (workspaceId: string, name: string): LabelSchema | undefined => {
      const lowerName = name.toLowerCase()

      return getContext().labels.all.find(
        (label) =>
          label.workspaceId === workspaceId &&
          label.name.toLowerCase() === lowerName
      )
    }
  )

  const getWorkspaceMembers = useClosure(
    (workspaceId: string): WorkspaceMemberWithUser[] => {
      const workspace = getWorkspaceById(workspaceId)
      return (
        workspace?.members.map((m) => {
          const user =
            getContext().users.byId[m.userId] ?? createNoneUser(workspaceId)
          if (isObjectNoneId(user)) {
            Sentry.captureMessage('No user found for member', {
              extra: {
                userId: m.userId,
                workspaceId,
              },
            })
          }

          return {
            ...m,
            workspaceId,
            user,
          }
        }) ?? []
      )
    }
  )
  const getWorkspaceMemberById = useClosure(
    (
      workspaceId: string,
      userId: string | null | undefined
    ): WorkspaceMemberWithUser | undefined => {
      const workspace = getWorkspaceById(workspaceId)
      const member =
        workspace?.members.find((m) => m.userId === userId) ?? undefined

      if (member == null) return

      const user =
        getContext().users.byId[member.userId] ?? createNoneUser(workspaceId)
      if (isObjectNoneId(user)) {
        Sentry.captureMessage('No user found for member', {
          extra: {
            userId: member.userId,
            workspaceId,
          },
        })
      }

      return {
        ...member,
        workspaceId,
        user,
      }
    }
  )

  const getWorkspaceActiveMembers = useClosure(
    (workspaceId: string): WorkspaceMemberWithUser[] => {
      const workspace = getWorkspaceById(workspaceId)
      return (
        workspace?.members
          .map((m) => {
            const user =
              getContext().users.byId[m.userId] ?? createNoneUser(workspaceId)
            if (isObjectNoneId(user)) {
              Sentry.captureMessage('No user found for member', {
                extra: {
                  userId: m.userId,
                  workspaceId,
                },
              })
            }

            return {
              ...m,
              workspaceId,
              user,
            }
          })
          .sort(byValue((member) => member.user.name, Compare.caseSensitive)) ??
        []
      )
    }
  )

  const getWorkspaceProjectDefinitions = useClosure((workspaceId: string) => {
    return getContext().projectDefinitions.all.filter(
      (definition) => definition.workspaceId === workspaceId
    )
  })

  const getWorkspaceProjectDefinitionById = useClosure(
    (definitionId: string | null | undefined) => {
      if (definitionId == null) return

      return getContext().projectDefinitions.byId[definitionId]
    }
  )

  const getStageDefinition = useClosure(
    (definitionId: string | null | undefined): WorkspaceStageDefinition => {
      const stageDefinition =
        definitionId != null
          ? (getContext().stageDefinitions.byId[definitionId] ??
            createUnknownStage(definitionId))
          : createUnknownStage(definitionId)

      if (definitionId != null && isObjectNoneId(stageDefinition)) {
        Sentry.captureMessage('No stage definition found', {
          extra: {
            id: definitionId,
          },
        })
      }

      return stageDefinition
    }
  )

  const getWorkspaceLegacyProjectTemplates = useClosure(
    (workspaceId: string) => {
      return getContext().legacyProjectTemplates.all.filter(
        (template) => template.workspaceId === workspaceId
      )
    }
  )

  const getWorkspaceLegacyProjectTemplateById = useClosure(
    (templateId: string) => {
      return getContext().legacyProjectTemplates.byId[templateId]
    }
  )

  return {
    getAllWorkspaces,
    getMyTasksWorkspace,
    getWorkspaceById,
    getWorkspaceProjects,
    getWorkspaceProjectById,
    getWorkspaceProjectByTaskId,
    getWorkspaceStatuses,
    getStatusById,
    getWorkspaceStatusByName,
    getWorkspaceCompletedStatus,
    getWorkspaceCanceledStatus,
    getWorkspaceLabels,
    getWorkspaceLabelByName,
    getWorkspaceMembers,
    getWorkspaceMemberById,
    getWorkspaceActiveMembers,
    getWorkspaceProjectDefinitions,
    getWorkspaceProjectDefinitionById,
    getStageDefinition,
    getWorkspaceLegacyProjectTemplates,
    getWorkspaceLegacyProjectTemplateById,
  }
}
