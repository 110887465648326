import { type ProjectSchema } from '@motion/rpc-types'
import { useHasTreatment } from '@motion/web-common/flags'

import {
  type AttachmentGroup,
  AttachmentGroups,
} from '~/areas/attachments/components'
import { useGetProjectAttachmentGroups } from '~/areas/attachments/hooks/use-get-project-all-attachments'
import { sortAttachmentIds } from '~/areas/attachments/utils'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useProjectTasks } from '~/areas/task-project/hooks'
import { useProject } from '~/global/hooks'
import { type Dispatch, type SetStateAction, useMemo } from 'react'

import { AttachmentsModalContent } from './attachments-modal-content'

export type AttachmentsModalProjectContentProps = Pick<
  ModalTriggerComponentProps<'attachments'>,
  'close'
> & {
  projectId: ProjectSchema['id']
  searchQuery: string
  setSearchQuery: Dispatch<SetStateAction<string>>
}

export function AttachmentsModalProjectContent({
  close,
  projectId,
  searchQuery,
  setSearchQuery,
}: AttachmentsModalProjectContentProps) {
  const project = useProject(projectId)

  const { data: tasks, isLoading: isLoadingTasks } = useProjectTasks({
    projectId,
    workspaceId: project?.workspaceId,
  })
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')

  const groupsWithDefinitionAttachments = useGetProjectAttachmentGroups({
    projectId,
  })
  const totalAttachmentCount = groupsWithDefinitionAttachments.reduce(
    (acc, group) => acc + group.uploadedFileIds.length,
    0
  )

  const groups = useMemo(() => {
    if (!project || !tasks || hasFlowsAttachments) return []

    return [
      {
        targetId: project.id,
        targetType: 'PROJECT',
        workspaceId: project.workspaceId,
        uploadedFileIds: sortAttachmentIds(project.uploadedFileIds),
      },
      ...tasks.map((task) => ({
        targetId: task.id,
        targetType: 'TEAM_TASK' as const,
        workspaceId: task.workspaceId,
        uploadedFileIds: sortAttachmentIds(task.uploadedFileIds ?? []),
      })),
    ] satisfies AttachmentGroup[]
  }, [project, tasks, hasFlowsAttachments])

  let attachmentCount = useMemo(() => {
    let count = project?.uploadedFileIds?.length ?? 0

    if (tasks) {
      count += tasks.reduce(
        (acc, task) => acc + (task.uploadedFileIds?.length ?? 0),
        0
      )
    }

    return count
  }, [project, tasks])

  if (!project || !tasks || isLoadingTasks) return null

  return (
    <AttachmentsModalContent
      close={close}
      targetId={projectId}
      targetType='PROJECT'
      workspaceId={project.workspaceId}
      attachmentCount={
        hasFlowsAttachments ? totalAttachmentCount : attachmentCount
      }
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    >
      <AttachmentGroups
        groups={hasFlowsAttachments ? groupsWithDefinitionAttachments : groups}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </AttachmentsModalContent>
  )
}
