import { ChevronRightSolid } from '@motion/icons'
import { wrapVariableInDelimiters } from '@motion/shared/flows'
import { type ActionSection } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useClipboard } from '~/localServices/clipboard'

import { AddVariableToStageTasksPopoverContent } from './add-variable-to-stage-tasks-popover-content'
import { type TextVariablePopoverContentProps } from './copy-variable-popover-content'
import { useClearTaskNameVariable } from './use-clear-task-name-variable'
import { useTaskNameVariableUpdater } from './use-task-name-variable-updater'

export function useTextVariableActionSections({
  name,
  variableKey,
}: Pick<
  TextVariablePopoverContentProps,
  'name' | 'variableKey'
>): ActionSection[] {
  const clipboard = useClipboard()
  const modalApi = useModalApi()
  const wrappedKey = wrapVariableInDelimiters(variableKey)

  const clearTextVariable = useClearTaskNameVariable()
  const updater = useTaskNameVariableUpdater()

  return [
    {
      items: [
        {
          content: 'Copy variable',
          onAction: () => {
            if (variableKey.length === 0 || name.length === 0) return

            clipboard.write(
              { text: wrappedKey },
              { notificationText: `Copied ${name}` }
            )
          },
        },
      ],
    },
    {
      items: [
        {
          content: 'Prepend to all tasks',
          onAction: () => updater({ wrappedKey, operation: 'prepend' }),
        },
        {
          content: 'Append to all tasks',
          onAction: () => updater({ wrappedKey, operation: 'append' }),
        },
      ],
    },
    {
      items: [
        {
          content: 'Prepend to stage tasks',
          suffix: <ChevronRightSolid />,
          renderPopover: ({ close }) => (
            <AddVariableToStageTasksPopoverContent
              type='prepend'
              wrappedKey={wrappedKey}
              close={close}
              updater={updater}
            />
          ),
        },
        {
          content: 'Append to stage tasks',
          suffix: <ChevronRightSolid />,
          renderPopover: ({ close }) => (
            <AddVariableToStageTasksPopoverContent
              type='append'
              wrappedKey={wrappedKey}
              close={close}
              updater={updater}
            />
          ),
        },
      ],
    },
    {
      items: [
        {
          content: 'Clear all',
          onAction: async () => {
            const res = await modalApi.prompt('confirm', {
              analytics: {
                name: 'variable-clear-all',
              },
              title: 'Are you sure you want to clear all?',
              description: `This text variable will be removed anywhere it's used.`,
              confirmButtonText: 'Clear all',
              closeButtonText: 'Cancel',
            })

            if (res === ModalDismissed) return

            clearTextVariable(wrappedKey)
          },
        },
      ],
    },
  ]
}
