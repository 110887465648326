import { FilledChevronRightSolid, type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton } from '@motion/ui/base'
import { ConditionalWrapper } from '@motion/ui/utils'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type SectionHeaderProps = {
  title: ReactNode
  isExpanded: boolean
  onToggleExpand: (newState: boolean) => void
  actionButton?: {
    icon: SvgIcon
    onClick?: () => void
    // If you need to wrap the button, say with an action list, you can do so
    wrapper?: (button: ReactNode) => ReactNode
  }
}

export const SectionHeader = ({
  title,
  isExpanded,
  onToggleExpand,
  actionButton,
}: SectionHeaderProps) => {
  return (
    <div className='text-sidebar-title'>
      <div className='flex flex-row items-center justify-between gap-2'>
        <Button
          alignment='left'
          sentiment='neutral'
          variant='muted'
          size='small'
          fullWidth
          onClick={() => {
            onToggleExpand(!isExpanded)
          }}
        >
          {title}

          <FilledChevronRightSolid
            className={twMerge(
              'transition-transform',
              isExpanded && 'rotate-90'
            )}
          />
        </Button>

        {actionButton && (
          <ConditionalWrapper
            condition={!!actionButton.wrapper}
            wrapper={actionButton.wrapper ?? ((children) => children)}
          >
            <ActionButton
              size='small'
              onClick={actionButton.onClick}
              sentiment='neutral'
              variant='outlined'
              icon={actionButton.icon}
            />
          </ConditionalWrapper>
        )}
      </div>
    </div>
  )
}

const ActionButton = classed(IconButton, `[&_[data-icon]]:size-3 mr-2`)
