import { TrashSolid } from '@motion/icons'
import { MIN_NUM_STAGES, wrapVariableInDelimiters } from '@motion/shared/flows'
import { Button, IconButton, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { StageBadge } from '~/global/components/badges'
import { ColorDropdown } from '~/global/components/dropdowns'
import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { useRegisterFieldArray } from '~/hooks'

import { useFlowTemplateForm } from '../hooks'
import { findFirstDuplicateName } from '../utils'

type Props = {
  stagePath: `stages.${number}`
  onRemove: () => void
}

export const StageTitleField = ({ stagePath, onRemove }: Props) => {
  const {
    form: { register, watch, getValues },
  } = useFlowTemplateForm()
  const modalApi = useModalApi()

  const namePath = `${stagePath}.name` as const
  const colorPath = `${stagePath}.color` as const

  const registerProps = useRegisterFieldArray(namePath, register, {
    validate: () => {
      const stages = getValues('stages')
      const value = getValues(namePath)

      const errorMessage = checkTextRuleErrors(
        value,
        getTextFieldRules('Stage title')
      )
      if (errorMessage) {
        return errorMessage
      }

      const duplicateName = findFirstDuplicateName(stages)
      if (duplicateName) {
        return `More than one stage named "${duplicateName.name}"`
      }
    },
  })

  const { onChange: onColorChange } = useRegisterFieldArray(colorPath, register)
  const color = watch(colorPath)
  const numStages = watch('stages').length
  const hasTasks = watch(`${stagePath}.tasks`)?.length > 0

  const variables = watch('textVariables')
  const wrappedVariableKeys = variables.map((v) =>
    wrapVariableInDelimiters(v.key)
  )

  // Don't allow variable keys in stage names for M1
  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const text = e.clipboardData?.getData('text')
    if (text) {
      const matchesKey = wrappedVariableKeys.find((key) => key === text)
      if (matchesKey) {
        e.preventDefault()
      }
    }
  }

  const handleDelete = async () => {
    const res = await modalApi.prompt('confirm', {
      analytics: {
        name: 'template-delete-stage',
      },
      title: 'Are you sure you want to delete this stage?',
      description: `This stage will be removed from any projects that haven’t visited this stage.`,
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete stage',
    })

    if (res === ModalDismissed) return

    onRemove()
    recordAnalyticsEvent('FLOW_TEMPLATE_STAGE_MODIFIED', {
      type: 'delete',
    })
  }

  return (
    <div className='flex gap-2'>
      <ColorDropdown
        selectedColor={color}
        onChange={(colorOption) => onColorChange(colorOption)}
      >
        <Button variant='outlined' sentiment='neutral' iconOnly>
          <StageBadge value={{ color }} hideTooltip />
        </Button>
      </ColorDropdown>

      <TextField
        placeholder='Stage title'
        label='Stage title'
        labelHidden
        size='small'
        variant='default'
        fullWidth
        onPaste={onPaste}
        {...registerProps}
      />

      {numStages > MIN_NUM_STAGES && (
        <Tooltip
          content={
            hasTasks
              ? 'This stage can’t be deleted because it has tasks. Move the tasks or delete them.'
              : undefined
          }
        >
          <IconButton
            icon={TrashSolid}
            variant='muted'
            sentiment='neutral'
            onClick={handleDelete}
            disabled={hasTasks}
          />
        </Tooltip>
      )}
    </div>
  )
}
