import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, ButtonGroup, IconButton } from '@motion/ui/base'

import { type ComponentProps, type ReactNode } from 'react'

export type TaskProjectModalContentProps = {
  onClose(): void
  onSubmit: ComponentProps<'form'>['onSubmit']
  isSubmitting?: boolean
  disableSubmit?: boolean

  actionButtonLabel: string

  renderLeftHeader: () => ReactNode
  renderRightHeader?: () => ReactNode
  renderLeftFooter?: () => ReactNode
  children: ReactNode
}

export const TaskProjectModalContent = (
  props: TaskProjectModalContentProps
) => {
  const {
    onClose,
    onSubmit,
    isSubmitting = false,
    disableSubmit = false,
    actionButtonLabel,
    renderLeftHeader,
    renderRightHeader,
    renderLeftFooter,
    children,
  } = props

  return (
    <form className='h-full flex flex-col' onSubmit={onSubmit}>
      <Header>
        <div className='grow'>{renderLeftHeader()}</div>

        <div className='shrink-0'>
          <ButtonGroup size='small'>
            {renderRightHeader?.()}

            <IconButton
              icon={XSolid}
              variant='muted'
              sentiment='neutral'
              size='small'
              onClick={() => onClose()}
            />
          </ButtonGroup>
        </div>
      </Header>
      <Content>{children}</Content>
      <Footer>
        {/* The fallback div allows the footer actions to always be aligned on the right side */}
        {renderLeftFooter?.() || <div />}
        <ButtonGroup>
          <Button
            variant='muted'
            sentiment='neutral'
            onClick={onClose}
            shortcut='escape'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={disableSubmit}
            loading={isSubmitting}
            shortcut='mod+s'
          >
            {actionButtonLabel}
          </Button>
        </ButtonGroup>
      </Footer>
    </form>
  )
}

const Content = classed('div', {
  // We need to set a height to fix the scrolling issue.
  // h-full doesn't work as expected. Any other value seems to work
  // because the `grow` from flex makes it full height anyway
  base: `flex grow min-h-0 h-1`,
})

const Header = classed('header', {
  base: 'flex gap-2 py-3 px-4 bg-semantic-neutral-bg-disabled electron-drag border-b border-modal-border',
})

const Footer = classed('footer', {
  base: `
    flex justify-between items-center
    p-3
    border-t border-modal-border
    bg-semantic-primary-bg-default
  `,
})
