import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { useHasTreatment } from '@motion/web-common/flags'

import { LoadingPage } from '~/areas/project-management/pages/pm-revamp/common/loading-page'
import { TaskContextProvider } from '~/areas/project-management/pages/pm-revamp/team-schedule/context/task-context'
import {
  ConnectedTaskLoader,
  type ConnectedTaskLoaderProps,
} from '~/areas/project-management/pages/pm-v3/components'
import { ConnectedProjectKanban } from '~/areas/project-management/pages/pm-v3/pages/connected-project-kanban'
import { ConnectedProjectList } from '~/areas/project-management/pages/pm-v3/pages/connected-project-list'
import { ConnectedProjectPlanner } from '~/areas/project-management/pages/pm-v3/pages/connected-project-planner'
import { ConnectedTaskKanban } from '~/areas/project-management/pages/pm-v3/pages/connected-task-kanban'
import { ConnectedTaskList } from '~/areas/project-management/pages/pm-v3/pages/connected-task-list'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes/hooks/use-v3-page-data'
import { type PageParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { useViewState } from '~/areas/project-management/pages/pm-v3/view-state/view-state'
import { useEffect, useMemo, useState } from 'react'

import { DashboardPage } from '../dashboard/page'

export const SelectContents = () => {
  const route = usePageData()

  const queryOverrides = useMemo<
    ConnectedTaskLoaderProps['queryOverrides']
  >(() => {
    if (route.variant !== 'archive') {
      return
    }
    return {
      completed: 'include',
      archived: 'only',
    }
  }, [route.variant])

  if (route.type === 'tasks' || route.type === 'projects') {
    return (
      <>
        <ConnectedTaskLoader
          overrides={route.overrides}
          queryOverrides={queryOverrides}
        />
        <TaskContextProvider>
          <DefaultPageContents route={route} key={route.stateKey} />
        </TaskContextProvider>
      </>
    )
  }

  return <div>unknown page</div>
}

type DefaultPageContentsProps = {
  route: PageParams
}

export const DefaultPageContents = ({ route }: DefaultPageContentsProps) => {
  const [state] = useViewState()
  const [filterState] = useActiveFilter()
  const variant = state.page
  const type = filterState.target
  const hasDashboard = useHasTreatment('charts-preview')

  // Delays the loading of the page a slight amount
  const [delayed, setDelayed] = useState(true)
  useEffect(() => {
    setDelayed(false)
  }, [])

  if (delayed) {
    return <LoadingPage />
  }

  // @ts-expect-error - dashboard is currently internal only
  if (hasDashboard && variant === 'dashboard') {
    return <DashboardPage />
  }

  if (type === 'tasks') {
    if (variant === 'list') {
      return <ConnectedTaskList />
    }

    if (variant === 'kanban') {
      return <ConnectedTaskKanban />
    }
  } else if (type === 'projects') {
    if (variant === 'list') {
      return <ConnectedProjectList />
    }
    if (variant === 'kanban') {
      return <ConnectedProjectKanban />
    }
    if (variant === 'gantt') {
      return <ConnectedProjectPlanner />
    }
  }

  return (
    <div>
      unknown {type} / {variant}
    </div>
  )
}
