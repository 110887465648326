import { CheckSolid } from '@motion/icons'
import { Button, FormModal, Modal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { firebase } from '@motion/web-common/firebase'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useUpdatePassword } from '~/global/rpc/user'
import { useAppSelector } from '~/state/hooks'
import { selectUser, type User } from '~/state/userSlice'
import { type Auth, signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'

export function ConnectedChangePasswordModal({
  close,
}: ModalTriggerComponentProps<'change-password'>) {
  const auth = firebase.auth()
  const user = useAppSelector(selectUser)

  const { mutateAsync: onChangePassword } = useUpdatePassword()
  return (
    <ChangePasswordModal
      auth={auth}
      close={close}
      onChangePassword={onChangePassword}
      user={user}
    />
  )
}

type PasswordValidationError =
  | 'no-old-password'
  | 'no-password'
  | 'passwords-dont-match'
  | 'password-too-short'
  | 'incorrect-password'
const passwordValidationErrorMap: Record<PasswordValidationError, string> = {
  'no-old-password': 'Please enter your current password.',
  'no-password': 'Please enter a new password.',
  'passwords-dont-match': 'Passwords don’t match. Please try again.',
  'password-too-short': 'Password must be at least 8 characters long.',
  'incorrect-password': 'Your current password is incorrect. Please try again.',
}

type ChangePasswordModalProps =
  ModalTriggerComponentProps<'change-password'> & {
    auth: Auth
    onChangePassword: ({
      password,
      oldPassword,
    }: {
      password: string
      oldPassword: string
    }) => Promise<void>
    user: User
  }
function ChangePasswordModal({
  auth,
  close,
  onChangePassword,
  user,
}: ChangePasswordModalProps) {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validationError, setValidationError] =
    useState<null | PasswordValidationError>(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  if (showSuccessModal) {
    return <ChangeSuccessModal onClick={close} />
  }

  return (
    <FormModal
      onClose={close}
      submitAction={{
        text: 'Change password',
        onAction: async () => {
          if (oldPassword.length < 8) {
            setValidationError('no-old-password')
            return
          }
          if (newPassword.length === 0) {
            setValidationError('no-password')
            return
          }
          if (newPassword !== confirmPassword) {
            setValidationError('passwords-dont-match')
            return
          }
          if (newPassword.length < 8 || confirmPassword.length < 8) {
            setValidationError('password-too-short')
            return
          }

          try {
            await signInWithEmailAndPassword(auth, user.email, oldPassword)
          } catch (err) {
            setValidationError('incorrect-password')
            return
          }

          setValidationError(null)

          recordAnalyticsEvent('ACCOUNT_SETTINGS_CHANGE_PASSWORD')
          await onChangePassword({
            password: newPassword,
            oldPassword: oldPassword,
          })
          await signInWithEmailAndPassword(auth, user.email, newPassword)

          setShowSuccessModal(true)
        },
      }}
      title='Change password'
      visible
    >
      <div className='flex flex-col items-start gap-4 self-stretch w-[568px]'>
        <TextField
          fullWidth
          type='password'
          label='Enter your current password'
          onChange={setOldPassword}
          value={oldPassword}
        />
        <TextField
          fullWidth
          type='password'
          label='Enter a new password'
          onChange={setNewPassword}
          value={newPassword}
        />
        <TextField
          fullWidth
          type='password'
          label='Confirm your new password'
          onChange={setConfirmPassword}
          value={confirmPassword}
        />
        {validationError && (
          <span className='text-[12px] text-semantic-error-text-default'>
            {passwordValidationErrorMap[validationError]}
          </span>
        )}
      </div>
    </FormModal>
  )
}

function ChangeSuccessModal({ onClick }: { onClick: () => void }) {
  return (
    <Modal onClose={onClick} visible>
      <div className='flex flex-col gap-2 p-6 items-center'>
        <CheckSolid className='text-semantic-neutral-icon-default' />
        <span className='text-semantic-neutral-text-default text-center text-[16px] font-semibold'>
          Password changed successfully
        </span>
        <span className='text-semantic-neutral-text-subtle text-center text-sm '>
          From now on this password will be used to log in to your account.
        </span>
        <div className='mt-2'>
          <Button onClick={onClick}>OK</Button>
        </div>
      </div>
    </Modal>
  )
}
