import { SearchSolid } from '@motion/icons'
import { type Contact } from '@motion/rpc/types'

import { useContacts } from '~/areas/event/hooks'
import { selectHasContactLessEmails } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

import {
  ContactsAutocompleteCombobox,
  type ContactsAutocompleteComboboxProps,
} from './contacts-autocomplete-combobox'

type ContactsAutocompleteProps = {
  contactsSource?: 'team' | 'general' | 'custom'
  hideIcon?: boolean
  queryHandler?: (query: string) => void
  teamSelectHandler: (values: string[], options?: Contact[]) => void
  /* Array of emails */
  teamValues: string[]
} & Pick<
  ContactsAutocompleteComboboxProps,
  | 'allowSelectQuery'
  | 'className'
  | 'dropdownAlign'
  | 'dropdownContainerClassName'
  | 'inputClassName'
  | 'multiple'
  | 'muted'
  | 'placeholder'
  | 'onFocus'
  | 'allowClearSelection'
>

export const ContactsAutocomplete = ({
  contactsSource = 'team',
  hideIcon = false,
  placeholder = 'Search teammates & select calendars',
  queryHandler = () => void 0,
  teamSelectHandler = () => void 0,
  teamValues = [],
  ...props
}: ContactsAutocompleteProps) => {
  const {
    sortedContacts,
    loadingState,
    onSearch,
    contacts,
    onContactSelectedAnalytics,
  } = useContacts({
    contactsSource,
  })

  const showPermissionWarning = useAppSelector(selectHasContactLessEmails)

  return (
    <ContactsAutocompleteCombobox
      options={sortedContacts}
      values={teamValues}
      onChange={(values) => {
        teamSelectHandler(
          values,
          values.map((value) => contacts[value]).filter(Boolean)
        )
      }}
      onQuery={(query) => {
        queryHandler(query)
        onSearch(query)
      }}
      onContactSelected={onContactSelectedAnalytics}
      placeholder={placeholder}
      icon={hideIcon ? undefined : SearchSolid}
      isLoadingOptions={loadingState === 'loading'}
      showPermissionWarning={showPermissionWarning}
      {...props}
    />
  )
}
