import { isRecurringTaskParent } from '@motion/ui-logic'

import { useTaskByIdV2 } from '~/global/rpc/v2'

import { ActivityFeed } from './activity-feed'

export type TaskActivityProps = {
  taskId: string
  onCommentChange?: (value: string) => void
}

export function TaskActivity({ taskId, onCommentChange }: TaskActivityProps) {
  const { data: task } = useTaskByIdV2({ id: taskId })

  // Don't throw an error if the task is not found,
  // It could be that the task is deleted, or not yet loaded
  if (!task || isRecurringTaskParent(task)) {
    return null
  }

  return (
    <div className='pb-2'>
      <ActivityFeed
        type='task'
        target={task}
        workspaceId={task.workspaceId}
        onCommentChange={onCommentChange}
      />
    </div>
  )
}
