import { type ReactNode } from 'react'

import { type GridTileLayoutProps } from './types'

export type GridTileProps = GridTileLayoutProps & {
  title: string
  children: ReactNode
}

export const GridTile = (props: GridTileProps) => {
  return (
    <div
      className='grid relative text-semantic-neutral-text-default h-full grid-rows-[auto,minmax(0,1fr)] '
      style={{
        gridColumnEnd: `span ${props.width ?? 2}`,
        gridRowEnd: `span ${props.height ?? 2}`,
        gridColumnStart: props.x,
        gridRowStart: props.y,
      }}
    >
      <div className='font-bold'>{props.title}</div>
      {props.children}
    </div>
  )
}
