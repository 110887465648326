import { classed } from '@motion/theme'
import { BaseToast, type BaseToastProps, ToastClose } from '@motion/ui/base'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type TaskSchema } from '@motion/zod/client'

import { useTaskModalUrl } from '~/global/navigation'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { type PropsWithChildren } from 'react'

import { TaskToastBody } from './task-toast-body'

export type TaskToastProps = Omit<BaseToastProps, 'children'> & {
  taskId: TaskSchema['id']
}

export const TaskToast = ({
  visible,
  taskId,
  duration,
  onClose,
}: TaskToastProps) => {
  const { uid: currentUserId } = useAuthenticatedUser()

  const buildTaskModalUrl = useTaskModalUrl()

  const { data: task } = useTaskByIdV2({ id: taskId })

  if (!task) {
    return null
  }

  return (
    <BaseToast visible={visible} duration={duration} onClose={onClose}>
      <TaskToastContentWrapper>
        <TaskToastBody
          task={task}
          onHide={() => onClose()}
          taskUrl={buildTaskModalUrl({ task: task.id })}
          currentUserId={currentUserId}
        />
      </TaskToastContentWrapper>
    </BaseToast>
  )
}

export const TaskToastContentWrapper = ({ children }: PropsWithChildren) => {
  return (
    <ToastWrapper>
      <div className='flex flex-col w-full'>
        <ToastHeader>
          <div className='flex flex-row items-center gap-2'>
            <p className='font-medium'>Task created</p>
          </div>
          <ToastClose />
        </ToastHeader>
        {children}
      </div>
    </ToastWrapper>
  )
}

const ToastWrapper = classed('div', {
  base: 'bg-dropdown-bg w-full justify-between flex flex-row items-start rounded-lg border-dropdown-border border overflow-hidden',
})

const ToastHeader = classed('div', {
  base: 'flex flex-row items-center justify-between align-middle w-full px-2 pt-1.5 pb-1 bg-semantic-neutral-bg-subtle text-semantic-neutral-text-default',
  variants: {
    sentiment: {
      ai: 'bg-semantic-purple-bg-default text-semantic-purple-text-default',
    },
  },
})
