import { DuplicateOutline } from '@motion/icons'
import { Button, type ButtonProps } from '@motion/ui/base'

import { type TaskWithRelations } from '~/global/proxies'
import { type ReactNode } from 'react'

import { useCopyTaskToClipboard } from '../../copy-tasks'

export interface CopyTasksButtonProps {
  list: TaskWithRelations[]
  columns: string[]
  onClick?: ButtonProps['onClick']
  size: ButtonProps['size']
  children: ReactNode
}

export const CopyTasksButton = ({
  list,
  columns,
  onClick,
  size,
  children,
}: CopyTasksButtonProps) => {
  const copyTasks = useCopyTaskToClipboard()

  if (list.length === 0) return

  return (
    <Button
      sentiment='neutral'
      variant='muted'
      size={size}
      onClick={(e) => {
        onClick?.(e)

        copyTasks(list, columns)
      }}
    >
      <DuplicateOutline />
      {children}
    </Button>
  )
}
