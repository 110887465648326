import { HttpConnectionError } from '@motion/rpc'
import { showToast } from '@motion/ui/base'

import { isValidElement, type ReactNode } from 'react'

import { NetworkIssueToast } from './network-issue-toast'

export function showErrorToast(error: unknown, fallback?: ReactNode) {
  let content: ReactNode = fallback ?? 'An unknown error occurred'

  if (error instanceof Error) {
    const override = getErrorToastOverride(error)
    content = override ?? error.message ?? fallback
  } else if (
    typeof error === 'string' ||
    (typeof error === 'object' && isValidElement(error))
  ) {
    content = error
  }

  showToast('error', content)
}

function getErrorToastOverride(error: Error): ReactNode | undefined {
  if (error instanceof HttpConnectionError) {
    return <NetworkIssueToast error={error} />
  }
}
