import {
  TemplateProjectModal,
  TemplateTaskModal,
} from '~/areas/project-management/modals'
import {
  WorkspaceLabelSettings,
  WorkspaceOverviewSettings,
  WorkspaceStatusSettings,
  WorkspaceTaskTemplateSettings,
} from '~/areas/project-management/settings'
import { WorkspaceCustomFieldSettings } from '~/areas/project-management/settings/custom-fields'
import { Redirect } from '~/components/Settings/Components/routing'
import { type MotionRoute } from '~/routing/api'

import { ProjectTemplatesSettingsPage } from './pages'
import { WorkspaceSettingsPage } from './workspace-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    'workspace-settings': {
      workspaceId: string
    }
    'workspace-template-settings': {
      workspaceId: string
    }
  }
}

export const workspaceRoutes: MotionRoute[] = [
  {
    id: 'workspace-settings',
    path: 'workspace/:workspaceId',
    element: <WorkspaceSettingsPage />,
    children: [
      {
        path: 'overview?',
        element: <WorkspaceOverviewSettings />,
        metric: 'PROJECT_MANAGEMENT_OVERVIEW_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'statuses',
        element: <WorkspaceStatusSettings />,
        metric: 'PROJECT_MANAGEMENT_STATUS_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'custom-fields',
        element: <WorkspaceCustomFieldSettings />,
        metric: 'PROJECT_MANAGEMENT_CUSTOM_FIELD_SETTINGS_PAGE_VIEWED',
      },
      {
        id: 'workspace-template-settings',
        path: 'project-templates',
        element: <ProjectTemplatesSettingsPage />,
        children: [
          {
            path: 'project/:templateProjectId',
            element: <TemplateProjectModal />,
          },
          {
            path: 'project/:templateProjectId/tasks/:templateTaskId',
            element: <TemplateTaskModal />,
          },
        ],
        metric: 'PROJECT_MANAGEMENT_FLOW_PROJECT_TEMPLATE_SETTINGS_PAGE_VIEWED',
      },
      {
        path: 'task-templates',
        element: <WorkspaceTaskTemplateSettings />,
        children: [
          {
            path: 'task/:templateTaskId',
            element: <TemplateTaskModal />,
          },
        ],
        metric: 'PROJECT_MANAGEMENT_TASK_TEMPLATE_SETTINGS_PAGE_VIEWED',
      },
      {
        metric: 'PROJECT_MANAGEMENT_LABEL_SETTINGS_PAGE_VIEWED',
        path: 'labels',
        element: <WorkspaceLabelSettings />,
      },
      { path: '*', element: <Redirect to='overview' /> },
    ],
  },
]
