/* eslint react-refresh/only-export-components: ["warn"] */
import { useSharedStateContext } from '@motion/react-core/shared-state'
import { useTaskFromCacheFn } from '@motion/rpc-cache'
import { type UpdateTeamTaskDto } from '@motion/rpc-types'
import { AutoScheduleSetting } from '@motion/shared/common'
import { createNoneId } from '@motion/shared/identifiers'
import { userComparator } from '@motion/ui-logic'
import { byProperty, cascade, Compare, ordered } from '@motion/utils/array'
import {
  type LabelSchema,
  type ProjectSchema,
  type StatusSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { createContext, type ReactNode, useContext, useMemo } from 'react'

import { TeamSchedulePageContextKey } from './page-data-context'

import { type TaskSchema } from '../types'

export type TaskData = {
  id: string
  task: TaskSchema
  project: ProjectSchema | null
  assignee: UserInfoSchema | null
  status: StatusSchema
  labels: LabelSchema[]
}

export type ScheduleContextType = {
  getWorkspace(id: string): WorkspaceSchema | null
  getAvailableStatuses(task: TaskSchema): StatusSchema[]
  getAvailableAssignees(task: TaskSchema): UserInfoSchema[]
  getAvailableProjects(task: TaskSchema): ProjectSchema[]

  getTaskData(task: TaskSchema): TaskData
  getTaskData(id: string): TaskData | null
  updateTask(task: TaskSchema, data: Partial<TaskSchema>): Promise<void>
}

const NOT_IMPLEMENTED = () => {
  throw new Error('TaskApiContext not found')
}
export const TaskApiContext = createContext<ScheduleContextType>({
  updateTask: NOT_IMPLEMENTED,
  getTaskData: NOT_IMPLEMENTED,

  getWorkspace: NOT_IMPLEMENTED,
  getAvailableStatuses: NOT_IMPLEMENTED,
  getAvailableAssignees: NOT_IMPLEMENTED,
  getAvailableProjects: NOT_IMPLEMENTED,
})

const UNKNOWN_STATUS: StatusSchema = {
  id: '',
  name: 'Unknown',
  workspaceId: '',
  color: 'gray',
  sortPosition: '',
  isResolvedStatus: false,
  isDefaultStatus: false,
  isSystemStatus: true,
  autoScheduleEnabled: false,
  deleted: true,
  type: null,
  autoScheduleSetting: AutoScheduleSetting.DISABLED,
}

type TaskContextProviderProps = {
  children: ReactNode
}

export const TaskContextProvider = (props: TaskContextProviderProps) => {
  const ctx = useSharedStateContext()

  const updateTask = useTaskUpdater()
  const getTaskFromCache = useTaskFromCacheFn()

  const api = useMemo(() => {
    function getWorkspace(id: string): WorkspaceSchema | null {
      const workspace = ctx.get(TeamSchedulePageContextKey).workspaces.byId[id]
      return workspace ?? null
    }

    function getAvailableStatuses(task: TaskSchema): StatusSchema[] {
      return ctx
        .get(TeamSchedulePageContextKey)
        .statuses.all.filter((x) => x.workspaceId === task.workspaceId)
    }

    function getAvailableAssignees(task: TaskSchema): UserInfoSchema[] {
      const data = ctx.get(TeamSchedulePageContextKey)
      const workspace = data.workspaces.byId[task.workspaceId]
      if (workspace == null) return []
      return workspace.members
        .filter((m) => m.status === 'ACTIVE')
        .map((m) => data.users.byId[m.userId])
        .filter((u) => u && !u.deleted)
        .sort(userComparator())
    }

    function getAvailableProjects(task: TaskSchema): ProjectSchema[] {
      const data = ctx.get(TeamSchedulePageContextKey)
      return data.projects.all
        .filter((x) => x.workspaceId === task.workspaceId)
        .sort(
          cascade(
            byProperty('id', ordered([createNoneId(task.workspaceId)])),
            byProperty('name', Compare.caseInsensitive)
          )
        )
    }

    function getTaskData(task: TaskSchema): TaskData
    function getTaskData(id: string): TaskData | null
    function getTaskData(taskOrId: string | TaskSchema): TaskData | null {
      const data = ctx.get(TeamSchedulePageContextKey)
      const task =
        typeof taskOrId === 'string' ? getTaskFromCache(taskOrId) : taskOrId
      if (task == null) return null

      return {
        id: task.id,
        project: task.projectId
          ? (data.projects.byId[task.projectId] ?? null)
          : null,
        assignee: task.assigneeUserId
          ? (data.users.byId[task.assigneeUserId] ?? null)
          : null,
        // todo create a fallback status
        status: data.statuses.byId[task.statusId] ?? {
          ...UNKNOWN_STATUS,
          id: task.statusId,
        },
        task,
        labels:
          'labelIds' in task
            ? (
                task.labelIds?.map((labelId) => data.labels.byId[labelId]) ?? []
              ).filter(Boolean)
            : [],
      }
    }

    function getTaskIdToUpdate(
      task: TaskSchema
    ): { id: string; workspaceId: string; type: 'task' | 'recurring' } | null {
      if (task.type === 'NORMAL') {
        return { id: task.id, workspaceId: task.workspaceId, type: 'task' }
      }

      if (task.type === 'RECURRING_INSTANCE') {
        return {
          id: task.parentRecurringTaskId,
          workspaceId: task.workspaceId,
          type: 'recurring',
        }
      }

      const parent = getTaskFromCache(task.parentChunkTaskId)

      if (parent == null) return null
      if (parent.type === 'NORMAL')
        return { id: parent.id, workspaceId: parent.workspaceId, type: 'task' }
      if (parent.type === 'RECURRING_INSTANCE') {
        return {
          id: parent.parentRecurringTaskId,
          workspaceId: parent.workspaceId,
          type: 'recurring',
        }
      }
      return null
    }

    type RecurringTaskFields = {
      assigneeUserId?: string
      deadLine?: string | null
      priority?: TaskSchema['deadlineType']
    }
    async function internalUpdateTask(
      task: TaskSchema,
      data: UpdateTeamTaskDto & RecurringTaskFields
    ): Promise<void> {
      const taskToUpdate = getTaskIdToUpdate(task)
      if (taskToUpdate == null) {
        throw new Error('Unknown task')
      }

      await updateTask(taskToUpdate.id, data)
    }

    return {
      getWorkspace,
      getAvailableStatuses,
      getAvailableAssignees,
      getAvailableProjects,
      getTaskData,
      updateTask: internalUpdateTask,
    }
  }, [ctx, getTaskFromCache, updateTask])

  return (
    <TaskApiContext.Provider value={api}>
      {props.children}
    </TaskApiContext.Provider>
  )
}

export const useTaskContextApi = () => {
  return useContext(TaskApiContext)
}
