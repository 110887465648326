import {
  type CustomFieldValuesRecord,
  type PMTaskType,
  type ProjectTemplateTask,
  type RecurringTask,
} from '@motion/rpc/types'
import { type TeamTaskWithRelationsDto } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

import { useUpdateTemplateProjectTask as useUpdateTemplateProjectTaskRPC } from '../../../global/rpc'
import { handleTaskUpdateErrors } from '../../../tasks/utils'

interface UpdateFns {
  (
    task: PMTaskType,
    updates: Partial<PMTaskType>
  ): Promise<TeamTaskWithRelationsDto>
  (
    task: ProjectTemplateTask,
    updates: Partial<PMTaskType>
  ): Promise<Record<string, unknown> & { id: string }>
}

const toFromUpdateKeys = ['isAutoScheduled', 'priorityLevel', 'duration']

function getAmplitudeChangedFields(
  task: PMTaskType | ProjectTemplateTask,
  updates: Partial<PMTaskType>
) {
  const fromTo: Record<
    string,
    { from: string | undefined; to?: string | undefined }
  > = {}
  for (const key in updates) {
    const prev = task[key as keyof typeof task]
    const next = updates[key as keyof typeof updates]

    if (next === undefined || prev === next) {
      continue
    }
    if (key === 'customFieldValues') {
      for (const value of Object.values(next as CustomFieldValuesRecord)) {
        const customFieldKey = `customField/${value?.type}`
        fromTo[customFieldKey] = {
          from: String(prev),
          to: String(next),
        }
      }
    } else {
      fromTo[key] = {
        from: String(prev),
        to: String(next),
      }
    }
  }
  return {
    fields: Object.keys(fromTo),
    fromTo: Object.fromEntries(
      Object.entries(fromTo).filter(([key]) => toFromUpdateKeys.includes(key))
    ),
  }
}

/**
 * @deprecated use `useTaskUpdater` to update tasks now
 * For template tasks, keep using this hook though
 */
export function useTemplateTaskUpdate() {
  const updateTemplateProjectTask = useUpdateTemplateProjectTask()
  const { getCustomFields } = useCustomFieldsFns()

  return useCallback<UpdateFns>(
    // @ts-expect-error-next-line - externally typed
    async (task, updates) => {
      const args = { task, updates }

      try {
        if (isTemplateProjectTask(args)) {
          const { task, updates } = args

          return await updateTemplateProjectTask(task, updates)
        }

        throw new Error('Unknown task type')
      } catch (err) {
        if (err instanceof Error) {
          handleTaskUpdateErrors(err, getCustomFields(task.workspaceId))
        }
        throw err
      }
    },
    [updateTemplateProjectTask, getCustomFields]
  )
}

export function useUpdateTemplateProjectTask() {
  const { mutateAsync: updateTemplateProjectTask } =
    useUpdateTemplateProjectTaskRPC()
  const context = useRouteAnalyticsMetadata()

  const { getWorkspaceMembers } = useWorkspaceFns()

  return useCallback(
    async (task: ProjectTemplateTask, updates: Partial<PMTaskType>) => {
      const members = getWorkspaceMembers(task.workspaceId)

      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_TASK', {
        type: 'template',
        autoScheduled: updates.isAutoScheduled ?? task.isAutoScheduled,
        workspaceSize: members.length,
        taskDuration: (updates.duration || task.duration) ?? null,
        ...context,
        ...getAmplitudeChangedFields(task, updates),
      })

      const response = await updateTemplateProjectTask({
        templateProjectId: task.templateProjectId,
        workspaceId: task.workspaceId,
        task: {
          id: task.id ?? '',
          workspaceId: task.workspaceId,
          ...updates,
        },
      })

      showToast('success', 'Template task saved')
      return response
    },
    [context, getWorkspaceMembers, updateTemplateProjectTask]
  )
}

interface UpdateArgs<T, K = T> {
  task: T
  updates: Partial<K>
}

function isTemplateProjectTask(
  args: UpdateArgs<PMTaskType | RecurringTask | ProjectTemplateTask>
): args is UpdateArgs<ProjectTemplateTask, PMTaskType> {
  return 'templateProjectId' in args.task
}
