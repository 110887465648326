import { type UniqueIdentifier } from '@dnd-kit/core'
import { useMemo } from 'react'

import { type GroupedNode, type Tree } from '../../../grouping'

export type ActiveItem = {
  type: 'tab' | 'pill' | 'container' | 'item'
  item: GroupedNode | Tree<GroupedNode>
} | null

export const useActiveItem = <T extends GroupedNode>({
  activeId,
  laneItems,
  activeTabs,
}: {
  activeId: UniqueIdentifier | null
  laneItems: Tree<T>
  activeTabs: Tree<T>[]
}) => {
  return useMemo((): ActiveItem => {
    if (!activeId) return null

    const tab = laneItems.children.find(
      (group) => group.qualifiedKey === activeId
    )

    if (tab && laneItems.groups.length > 1) {
      return {
        type: 'tab',
        item: tab,
      }
    }

    const pill = activeTabs[0]?.children.find(
      (group) => group.qualifiedKey === activeId
    )

    if (pill && laneItems.groups.length > 2) {
      return {
        type: 'pill',
        item: pill,
      }
    }

    const groups = laneItems.children.filter(
      (group) =>
        group.qualifiedKey === activeId ||
        group.values.some((i) => i.qualifiedKey === activeId)
    )

    if (!groups || !groups.length) return null

    if (groups[0]?.qualifiedKey === activeId) {
      return {
        type: 'container',
        item: groups[0],
      }
    }

    const item = groups[0].values.find(
      (i) => i.qualifiedKey === activeId
    ) as GroupedNode

    if (!item) return null

    return {
      type: 'item',
      item,
    }
  }, [activeId, activeTabs, laneItems.children, laneItems.groups.length])
}
