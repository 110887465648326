import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

type DuplicateFlowCheckboxesProps = {
  copyMissingCustomFields: boolean
  inviteMissingMembers: boolean
  copyMissingLabels: boolean
  inviteMembersDisabled: boolean
  toggleCopyMissingCustomFields: () => void
  toggleInviteMissingMembers: () => void
  toggleCopyMissingLabels: () => void
}
export function DuplicateFlowCheckboxes({
  copyMissingCustomFields,
  inviteMissingMembers,
  copyMissingLabels,
  inviteMembersDisabled,
  toggleCopyMissingCustomFields,
  toggleInviteMissingMembers,
  toggleCopyMissingLabels,
}: DuplicateFlowCheckboxesProps) {
  return (
    <>
      <Checkbox
        checked={copyMissingCustomFields}
        onChange={toggleCopyMissingCustomFields}
      >
        <CheckboxDescription>
          <span className='text-form-text-default text-sm font-normal'>
            Copy Custom Fields
          </span>
          <span className='text-semantic-neutral-text-subtle text-xs'>
            Motion will try to match any existing custom fields and values in
            the destination workspace. If missing, Motion will create them.
          </span>
        </CheckboxDescription>
      </Checkbox>

      <Tooltip
        asChild
        content={
          inviteMembersDisabled
            ? 'Cannot invite assignees to your personal workspace'
            : undefined
        }
      >
        <Checkbox
          checked={inviteMembersDisabled ? false : inviteMissingMembers}
          disabled={inviteMembersDisabled}
          onChange={toggleInviteMissingMembers}
        >
          <CheckboxDescription>
            <span className='text-form-text-default text-sm font-normal'>
              Invite Assignees
            </span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              Motion will automatically invite any missing assignees to the
              destination workspace. If this is unchecked, then those tasks will
              be assigned to Unassigned.
            </span>
          </CheckboxDescription>
        </Checkbox>
      </Tooltip>

      <Checkbox checked={copyMissingLabels} onChange={toggleCopyMissingLabels}>
        <CheckboxDescription>
          <span className='text-form-text-default text-sm font-normal'>
            Copy Missing Labels
          </span>
          <span className='text-semantic-neutral-text-subtle text-xs'>
            Motion will automatically create any missing labels in the
            destination workspace.
          </span>
        </CheckboxDescription>
      </Checkbox>
    </>
  )
}

const CheckboxDescription = classed('span', {
  base: `flex flex-col items-start gap-1 whitespace-normal`,
})
