import {
  BlockersField,
  type BlockersFieldProps,
} from '~/areas/task-project/fields'

import { useTaskForm } from '../hooks'

export type ControlledBlockersFieldProps = {
  type: BlockersFieldProps['type']
}

export const ControlledBlockersField = ({
  type,
}: ControlledBlockersFieldProps) => {
  const {
    form: { watch },
    disabledFields,
  } = useTaskForm()
  const taskId = watch('id')
  const blockerIds = watch(
    type === 'blocks' ? 'blockingTaskIds' : 'blockedByTaskIds'
  )

  const disabled =
    disabledFields.has('blockedByTaskIds') ||
    disabledFields.has('blockingTaskIds')

  return (
    <BlockersField
      type={type}
      taskId={taskId}
      blockerIds={blockerIds}
      disabled={disabled}
    />
  )
}
