import {
  type EndRelativeDateOption,
  type StartRelativeDateOption,
} from '@motion/shared/common'

import { ModalFieldButton } from '~/areas/task-project/components'
import { forwardRef, type ReactNode } from 'react'

import { UserTaskSettingRelativeDateLabels } from './utils'

type RelativeDateTriggerButtonProps = {
  icon: ReactNode
  value: StartRelativeDateOption | EndRelativeDateOption
  label: string
  endButton?: ReactNode
  disabled?: boolean
}

export const RelativeDateTriggerButton = forwardRef<
  HTMLLabelElement,
  RelativeDateTriggerButtonProps
>(function RelativeDateTriggerButton(props, ref) {
  const { icon, value, label, endButton, disabled, ...rest } = props

  return (
    <ModalFieldButton ref={ref} label={label} disabled={disabled} {...rest}>
      {icon}
      {UserTaskSettingRelativeDateLabels[value]}
      {endButton}
    </ModalFieldButton>
  )
})
