import {
  AddToProjectSolid,
  DotsHorizontalSolid,
  ExternalLinkSolid,
  RemoveFromProjectSolid,
  TrashSolid,
} from '@motion/icons'
import {
  ActionDropdown,
  IconButton,
  MODAL_MOUNT_DURATION,
  showToast,
} from '@motion/ui/base'
import {
  calendarProviderTypeToName,
  canAddToProject,
  canRemoveFromProject,
} from '@motion/ui-logic'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useDeleteEvent, useRemoveEventFromProject } from '~/areas/event/hooks'
import { WorkspaceProjectDropdownContent } from '~/areas/project-management/components'

import { useEventModalState } from '../contexts'
import {
  useCloseMeetingModal,
  useEventForm,
  useFormAddEventToProject,
} from '../hooks'

export type ActionDotsMenuProps = {
  eventId: CalendarEventSchemaV2['id']
}

export const ActionDotsMenu = ({ eventId }: ActionDotsMenuProps) => {
  const { isLoading, hostCalendar, initialEvent } = useEventModalState()

  const { form } = useEventForm()
  const addEventToProject = useFormAddEventToProject()
  const removeEventFromProject = useRemoveEventFromProject()
  const deleteEvent = useDeleteEvent()
  const modalApi = useModalApi()
  const closeMeetingModal = useCloseMeetingModal()

  if (isLoading) return null

  const canDelete = hostCalendar?.accessRole !== 'VIEWER'

  const handleDelete = () => {
    deleteEvent(eventId, {
      onConfirmation: async () => {
        modalApi.dismiss('event-modal')
        closeMeetingModal()
        // Sleeping to let the modal close before completing the deletion
        await sleep(MODAL_MOUNT_DURATION)
      },
    })
  }

  const handleRemoveFromProject = async () => {
    if (form.formState.isDirty) {
      showToast('error', 'Save the event before removing it from a project')
      return
    }

    removeEventFromProject(initialEvent, {
      onConfirmation: () => {
        closeMeetingModal()
        modalApi.open('event-modal', { eventId: initialEvent?.id })
      },
    })
  }

  return (
    <ActionDropdown
      placement='bottom-end'
      sections={[
        {
          items: [
            canAddToProject(initialEvent) && {
              content: 'Add to project',
              prefix: <AddToProjectSolid />,
              renderPopover: ({ close }) => (
                <WorkspaceProjectDropdownContent
                  close={close}
                  onSelect={addEventToProject}
                  selectedProjectId={null}
                  hideNoProject
                  disallowSelectWorkspace
                />
              ),
            },
            initialEvent?.url != null &&
              hostCalendar != null && {
                content: `See event in ${calendarProviderTypeToName[hostCalendar.providerType]}`,
                prefix: <ExternalLinkSolid />,
                url: initialEvent.url,
                onAction: () => {
                  recordAnalyticsEvent('SEE_EVENT_IN_EXTERNAL_PROVIDER', {
                    provider:
                      calendarProviderTypeToName[hostCalendar.providerType],
                  })
                },
              },
          ],
        },
        {
          items: [
            canRemoveFromProject(initialEvent) && {
              content: 'Remove from project',
              prefix: <RemoveFromProjectSolid />,
              onAction: () => handleRemoveFromProject(),
            },
            canDelete && {
              content: 'Delete',
              destructive: true,
              prefix: <TrashSolid />,
              onAction: () => handleDelete(),
            },
          ],
        },
      ]}
    >
      <IconButton
        size='small'
        variant='muted'
        sentiment='neutral'
        icon={DotsHorizontalSolid}
      />
    </ActionDropdown>
  )
}
