import { ExclamationCircleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

export const DesktopUserMustUpdate = () => {
  const desktopDownloadSite = 'https://www.usemotion.com/desktop-app'

  return (
    <div className='electron-drag flex flex-col items-center justify-center h-full w-full *:bg-semantic-neutral-surface-bg-subtlest'>
      <div className='flex flex-col gap-6 items-center'>
        <ExclamationCircleSolid className='w-6 h-6 text-semantic-neutral-icon-default' />
        <div className='flex flex-col gap-3 items-center'>
          <p className='text-base font-bold text-semantic-neutral-text-default'>
            You are using an old version of Motion
          </p>
          <p className='text-sm font-base text-semantic-neutral-text-subtle'>
            Download our latest version to get the best experience
          </p>
        </div>
        <Button url={desktopDownloadSite} sentiment='primary'>
          Download
        </Button>
      </div>
    </div>
  )
}
