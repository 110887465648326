import { priorityLevels } from '@motion/rpc/types'
import { getChunkDurations, getDurations } from '@motion/ui-logic'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import {
  useAllWorkspaces,
  useLegacyProjectTemplateById,
  useWorkspaceById,
  useWorkspaceMembers,
} from '~/global/hooks'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTemplateTask } from './hooks'
import { TemplateTaskFormOptionsContext } from './template-task-form-options-context'
import {
  type TemplateTaskFormFields,
  type TemplateTaskFormOptions,
} from './types'

import { useAppSelector } from '../../../../state/hooks'
import { selectSchedules } from '../../../../state/settingsSlice'
import { sortSchedules } from '../../../../utils/settingsUtils'

type TemplateTaskFormOptionsProviderProps = {
  children: React.ReactNode
}

export function TemplateTaskFormOptions({
  children,
}: TemplateTaskFormOptionsProviderProps) {
  const options = useOptionsData()

  return (
    <TemplateTaskFormOptionsContext.Provider value={options}>
      {children}
    </TemplateTaskFormOptionsContext.Provider>
  )
}

function useOptionsData() {
  const form = useFormContext<TemplateTaskFormFields>()

  const templateTaskId = form.watch('templateId')
  const templateProjectId = form.watch('templateProjectId')

  const workspaceId = form.watch('workspaceId')
  const duration = form.watch('duration')

  const workspaces = useAllWorkspaces()
  const selectedWorkspace = useWorkspaceById(workspaceId)
  const workspaceMembers = useWorkspaceMembers(workspaceId)

  const { uid: currentUserId } = useAuthenticatedUser()
  const userSchedules = useAppSelector(selectSchedules)

  const templateProject = useLegacyProjectTemplateById(templateProjectId)

  const { templateTask } = useTemplateTask(
    {
      id: templateTaskId,
      templateProjectId: templateProjectId,
      workspaceId,
    },
    { enabled: !!workspaceId && !!templateTaskId }
  )

  return useMemo(() => {
    const priorityOptions = priorityLevels

    const schedules = sortSchedules(userSchedules).map(({ id, schedule }) => ({
      id,
      name: schedule.title,
    }))

    return {
      savedTemplateTask: templateTask,
      savedTemplateProject: templateProject,
      currentUserId,
      workspaces,
      selectedWorkspace,
      members: workspaceMembers,
      activeMembers: workspaceMembers.filter((m) => !m.user.deleted),
      priorities: priorityOptions,
      durations: getDurations({ includeNone: true }),
      chunkDurations: getChunkDurations(duration),
      schedules,
    }
  }, [
    userSchedules,
    templateTask,
    templateProject,
    currentUserId,
    workspaces,
    selectedWorkspace,
    workspaceMembers,
    duration,
  ])
}
