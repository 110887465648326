import { request } from './apiUtils'

export abstract class BackendApiService {
  constructor() {}

  protected request(
    path: string,
    method: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE',
    body?: Record<string, any> | string,
    stringify = true
  ) {
    return request(path, method, __BACKEND_HOST__, body, stringify)
  }
}
