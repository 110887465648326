import { Button } from '@motion/ui/base'

import { useProjectUpdater } from '~/areas/project/hooks'
import { AssigneeDropdown } from '~/areas/project-management/components'
import { UserBadge } from '~/global/components/badges'
import { type ProjectWithRelations } from '~/global/proxies'

export const ItemAssignee = ({
  project,
}: {
  project: ProjectWithRelations
}) => {
  const updateProject = useProjectUpdater()
  const selectedUserId = project.managerId
  const workspaceId = project.workspaceId
  const onChange = (userId: string | null) => {
    void updateProject(project, { managerId: userId })
  }

  return (
    <AssigneeDropdown
      selectedUserId={selectedUserId}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <Button sentiment='neutral' variant='muted' iconOnly size='xsmall'>
        <UserBadge value={project.manager} />
      </Button>
    </AssigneeDropdown>
  )
}
