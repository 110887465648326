import { type DateLike, parseDate } from '@motion/utils/dates'

import { TimeField } from './time-field'

import {
  useScheduleAssistantCalendarContext,
  useUpdateSelectedCalendarEvent,
} from '../../../hooks'

export function EndTimeField() {
  const [{ selectedCalendarEvent }] = useScheduleAssistantCalendarContext()
  const updateSelectedEvent = useUpdateSelectedCalendarEvent()

  const startDateTime = selectedCalendarEvent?.new?.start
  const endDateTime = selectedCalendarEvent?.new?.end

  if (endDateTime == null || startDateTime == null) return null

  const timeFieldStartTime = parseDate(startDateTime)

  const onChange = (value: DateLike) => {
    const newEndDateTime = parseDate(value).toISO()

    updateSelectedEvent({
      new: {
        end: newEndDateTime,
      },
    })
  }

  return (
    <TimeField
      value={endDateTime}
      startDateTime={timeFieldStartTime}
      onChange={onChange}
      ariaLabel='End Time'
      showDuration
    />
  )
}
