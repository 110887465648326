import { DotsHorizontalSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { UnstyledCollapsableContainer } from '@motion/ui/base'

import { type PropsWithChildren, type ReactNode, useEffect } from 'react'

import { SectionHeader, type SectionHeaderProps } from './section-header'

import { SidebarExpandablePanel } from '../../components/sidebar-expandable-panel'

type ExpandableSectionBaseProps = PropsWithChildren<{
  title: string
  isExpanded: NonNullable<SectionHeaderProps['isExpanded']>
  onToggleExpand: NonNullable<SectionHeaderProps['onToggleExpand']>
  actionButton?: SectionHeaderProps['actionButton']
}>

export type ExpandableSectionWithPanelProps = ExpandableSectionBaseProps & {
  shouldRenderPanel: boolean
  onToggleRenderPanel: (shouldRender: boolean) => void
  renderPanelContent: (() => ReactNode) | false
}

export type ExpandableSectionWithoutPanelProps = ExpandableSectionBaseProps & {
  shouldRenderPanel?: never
  onToggleRenderPanel?: never
  renderPanelContent?: never
}

export type ExpandableSectionProps =
  | ExpandableSectionWithoutPanelProps
  | ExpandableSectionWithPanelProps

export const ExpandableSection = ({
  title,
  isExpanded,
  onToggleExpand,
  actionButton,
  shouldRenderPanel = false,
  onToggleRenderPanel,
  renderPanelContent,
  children,
}: ExpandableSectionProps) => {
  const hasPanel = !!renderPanelContent

  useEffect(() => {
    if (!isExpanded && shouldRenderPanel) {
      onToggleRenderPanel?.(false)
    }
  }, [isExpanded, onToggleRenderPanel, shouldRenderPanel])

  return (
    <>
      <UnstyledCollapsableContainer
        expanded={isExpanded}
        toggle={() => void onToggleExpand(!isExpanded)}
        renderHeader={() => (
          <div className='px-2'>
            <SectionHeader
              title={title}
              isExpanded={isExpanded}
              onToggleExpand={onToggleExpand}
              actionButton={actionButton}
            />
          </div>
        )}
      >
        <div>
          {children}

          {hasPanel && (
            <MoreButtonWrapper>
              <MoreButton
                active={shouldRenderPanel}
                onClick={() => void onToggleRenderPanel?.(!shouldRenderPanel)}
              >
                <DotsHorizontalSolid className='size-4' />
                <span>More</span>
              </MoreButton>
            </MoreButtonWrapper>
          )}
        </div>
      </UnstyledCollapsableContainer>

      {hasPanel && (
        <SidebarExpandablePanel
          open={shouldRenderPanel}
          onClose={() => void onToggleRenderPanel?.(false)}
        >
          {renderPanelContent && renderPanelContent()}
        </SidebarExpandablePanel>
      )}
    </>
  )
}

const MoreButtonWrapper = classed('div', 'mt-px ml-[11px] mr-[13px]')

const MoreButton = classed('button', {
  base: `
    inline-flex flex-row gap-1.5
    px-1.5 py-2 w-full box-border
    rounded
    text-left text-sm font-medium text-sidebar-item-text-default 
    hover:bg-sidebar-item-bg-hover hover:text-sidebar-item-text-selected
    [&_[data-icon]]:text-semantic-neutral-icon-default
  `,
  variants: {
    active: {
      true: 'text-sidebar-item-text-selected bg-sidebar-item-bg-selected',
    },
  },
  defaultVariants: {
    active: false,
  },
})
