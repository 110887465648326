import { DragSolid } from '@motion/icons'

import { ErrorBoundary } from '~/global/components'
import { forwardRef, type HtmlHTMLAttributes, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type GridTileProps = {
  children?: ReactNode
  renderContent: () => ReactNode
} & HtmlHTMLAttributes<HTMLDivElement>

export const GridTile = forwardRef<HTMLDivElement, GridTileProps>(
  ({ title, children, renderContent, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={twMerge(
          'grid grid-rows-[auto,minmax(0,1fr)] !bg-semantic-neutral-bg-default p-3 rounded-lg shadow-[0_0px_10px_2px_rgba(0,0,0,0.04)]',
          props.className
        )}
      >
        <div className='flex items-center gap-1 -ml-2'>
          <div className='drag-handle text-semantic-neutral-icon-default cursor-grab duration-200'>
            <DragSolid className='size-4' />
          </div>

          <h2 className='font-bold self-start'>{title}</h2>
        </div>

        <div className='grid [&>*]:min-w-0'>
          <ErrorBoundary errorMessage='Error rendering the tile content'>
            {renderContent()}
          </ErrorBoundary>
        </div>

        {children}
      </div>
    )
  }
)

GridTile.displayName = 'GridTile'
