import { ClientValidationError } from '@motion/rpc'
import { type ProjectSchema } from '@motion/zod/client'

import { checkTextRuleErrors, NAME_FIELD_RULE } from '~/global/rules'
import { useCallback } from 'react'

import { useProjectUpdater } from './use-project-updater'

export function useProjectNameUpdater() {
  const updateProject = useProjectUpdater()
  return useCallback(
    async (
      projectOrId: ProjectSchema | ProjectSchema['id'],
      name: ProjectSchema['name']
    ) => {
      const trimName = name.trim()
      const errorMessage = checkTextRuleErrors(trimName, NAME_FIELD_RULE)
      if (errorMessage) {
        throw new ClientValidationError(errorMessage)
      }

      await updateProject(projectOrId, {
        name: trimName,
      })
      return true
    },
    [updateProject]
  )
}
