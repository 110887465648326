import { LoadingSpinner, Tag } from '@motion/ui/base'

export type AttachmentUploadingTagProps = {
  fileName: string
  progress: number
}

export function AttachmentUploadingTag({
  fileName,
  progress,
}: AttachmentUploadingTagProps) {
  return (
    <Tag color='grey' variant='subtle' size='normal'>
      <div className='flex items-center gap-1'>
        {fileName}
        <span>{`${progress}%`}</span>
        <LoadingSpinner size={12} />
      </div>
    </Tag>
  )
}
