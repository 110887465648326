import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgEmptyLight = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 120 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "emptylight", ref, ...props, children: [
  /* @__PURE__ */ jsx("rect", { width: 120, height: 120, rx: 60, fill: "#F0F6FA" }),
  /* @__PURE__ */ jsxs("g", { filter: "url(#filter0_d_1068_21033)", children: [
    /* @__PURE__ */ jsx("rect", { width: 58.1886, height: 70.3894, transform: "translate(27.896 25.0634)", fill: "url(#paint0_linear_1068_21033)" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 36.3257, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#B8CDDD" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 50.4034, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#B8CDDD" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 64.4813, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#B8CDDD" }),
    /* @__PURE__ */ jsx("rect", { x: 36.3428, y: 78.5591, width: 41.2951, height: 4.69263, rx: 2.34631, fill: "#B8CDDD" })
  ] }),
  /* @__PURE__ */ jsx("rect", { x: 63.6404, y: 63.1674, width: 19.1191, height: 7.48139, rx: 3.74069, fill: "#B8CDDD" }),
  /* @__PURE__ */ jsx("path", { d: "M71.9531 50.6985L76.1569 49.4374C78.2047 48.823 80.2657 50.3564 80.2657 52.4944C80.2657 54.2571 78.8368 55.6861 77.074 55.6861H72.8318C69.9547 55.6861 69.2492 51.6817 71.9531 50.6985Z", fill: "#B8CDDD" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M92.2492 73.5978C96.9234 67.3633 96.4254 58.4797 90.7553 52.8096C84.5394 46.5936 74.4613 46.5936 68.2453 52.8096C62.0293 59.0256 62.0293 69.1037 68.2453 75.3197C73.9153 80.9898 82.799 81.4877 89.0335 76.8136L100.403 88.1826C101.291 89.0706 102.73 89.0706 103.618 88.1826C104.506 87.2946 104.506 85.8548 103.618 84.9668L92.2492 73.5978ZM87.5396 56.0253C91.9796 60.4653 91.9796 67.6639 87.5396 72.1039C83.0996 76.5439 75.9009 76.5439 71.461 72.1039C67.021 67.6639 67.021 60.4653 71.461 56.0253C75.9009 51.5853 83.0996 51.5853 87.5396 56.0253Z", fill: "#718B9F" }),
  /* @__PURE__ */ jsx("circle", { cx: 79.5003, cy: 64.0646, r: 11.3693, transform: "rotate(-45 79.5003 64.0646)", fill: "#7B8B97", fillOpacity: 0.17 }),
  /* @__PURE__ */ jsx("path", { d: "M75.0194 56.5197C77.5729 55.328 83.4969 54.2726 86.7653 59.5838", stroke: "white", strokeWidth: 2.04276, strokeLinecap: "round" }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_d_1068_21033", x: 15.896, y: 5.06335, width: 82.1886, height: 94.3894, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: -8 }),
      /* @__PURE__ */ jsx("feGaussianBlur", { stdDeviation: 6 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "out" }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.199267 0 0 0 0 0.329924 0 0 0 0 0.427917 0 0 0 0.06 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1068_21033" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1068_21033", result: "shape" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_1068_21033", x1: 29.0943, y1: 0, x2: 29.0943, y2: 69.9365, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "white" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#F0F6FA" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgEmptyLight);
export default ForwardRef;
ForwardRef.displayName = "EmptyLightIcon";
