import { type CalendarEventSchemaV2 } from '@motion/zod/client'

/**
 * Returns a unique key string for an event
 * event.iCalUid isn't unique enough in some cases, so we need to append a few other information
 *
 * // Context: https://dev-app.usemotion.com/web/projectManagement?workspace=nNglttVeggUOXR83bzHix&task=_qTqqfVfdvl_o1QjCxdpX
 * // Context for event status being added: https://dev-app.usemotion.com/web/pm/workspaces/u7_D-Iy8jdmn3IAvEkdfR?task=c-Y3VlkkijbHM_aOTykc0
 * @param event
 * @returns string - unique event key
 */
export function getEventUniqueKey(event: CalendarEventSchemaV2): string {
  return (
    event.iCalUid + (event.organizer?.email || '') + event.start + event.status
  )
}
