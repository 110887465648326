import { type UploadedFileSchema } from '@motion/rpc-types'

import { AttachmentUploadButton } from '~/areas/attachments'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { useTaskForm } from '~/areas/tasks/modals/task-modal/hooks'
import { useFieldArray } from 'react-hook-form'

import { AttachmentTag } from './components/attachment-tag'
import { AttachmentUploadingTag } from './components/attachment-uploading-tag'

export const TaskAttachmentsUploader = () => {
  const {
    form: { watch, control },
  } = useTaskForm()

  const { append, remove } = useFieldArray({
    control: control,
    name: 'uploadedFiles',
  })

  const workspaceId = watch('workspaceId')
  const uploadedFiles = watch('uploadedFiles')

  const { activeFileUploads } = useFileUploadState()

  const handleUploadSettled = (uploadedFile: UploadedFileSchema) =>
    append({ id: uploadedFile.id })

  const handleAttachmentDelete = (id: UploadedFileSchema['id']) =>
    remove(uploadedFiles.findIndex((uploadedFile) => uploadedFile.id === id))

  const filteredActiveFileUploads = activeFileUploads.filter(
    (upload) => upload.targetId === null && upload.targetType === 'TEAM_TASK'
  )

  const attachmentsCount =
    uploadedFiles.length + filteredActiveFileUploads.length

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center justify-between'>
        <span className='text-sm text-semantic-neutral-text-default font-semibold'>
          Attachments
          {attachmentsCount > 0 && ` (${attachmentsCount})`}
        </span>
        <AttachmentUploadButton
          targetType='TEAM_TASK'
          workspaceId={workspaceId}
          onUploadSettled={handleUploadSettled}
        />
      </div>
      <div className='flex flex-wrap justify-start gap-2'>
        {uploadedFiles.map((uploadedFile) => (
          <AttachmentTag
            key={uploadedFile.id}
            attachmentId={uploadedFile.id}
            onDelete={handleAttachmentDelete}
          />
        ))}

        {filteredActiveFileUploads.map((upload) => (
          <AttachmentUploadingTag
            key={upload.fileName}
            fileName={upload.fileName}
            progress={upload.progress}
          />
        ))}
      </div>
    </div>
  )
}
