import { Button, Modal } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

import { useEffect, useState } from 'react'

export type AvailabilityGuestConfirmationModalProps = {
  /**
   * Fires the callback with the checked/selected guests, or null if the
   * user does not want to invite any (additional) guests
   * @param selectedGuests
   * @returns
   */
  onConfirm: (selectedGuests: string[] | null) => void
  potentialGuests: string[]
}

export const AvailabilityGuestConfirmationModal = (
  props: AvailabilityGuestConfirmationModalProps
) => {
  const { potentialGuests } = props

  const [checkedGuests, setCheckedGuests] = useState<{
    [guest: string]: boolean
  }>({})

  useEffect(function checkAllGuests() {
    const checked: { [guest: string]: boolean } = {}
    potentialGuests.forEach((guest) => {
      checked[guest] = true
    })
    setCheckedGuests(checked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onConfirm = (skip?: boolean) => {
    if (skip) {
      props.onConfirm(null)
      return
    }

    const selectedGuests: string[] = []
    for (const [guest, checked] of Object.entries(checkedGuests)) {
      if (checked) {
        selectedGuests.push(guest)
      }
    }

    props.onConfirm(selectedGuests)
  }

  return (
    <Modal onClose={() => null} visible>
      <div className='flex flex-col gap-3 p-5'>
        <div className='max-h-[200px] overflow-y-auto flex flex-col gap-3 pt-2'>
          <p className='text-semantic-neutral-text-default'>
            Would you also like to invite these people to the event after your
            invitee books a time?
          </p>

          <div>
            {potentialGuests.map((guest) => (
              <div className='calendar-list-row' key={`confirm-${guest}`}>
                <Checkbox
                  onChange={(checked) => {
                    setCheckedGuests((prev) => {
                      prev[guest] = !!checked
                      return { ...prev }
                    })
                  }}
                  checked={checkedGuests[guest]}
                  label={<p className='max-w-[300px] text-ellipsis'>{guest}</p>}
                />
              </div>
            ))}
          </div>
        </div>

        <div className='flex w-full flex-col gap-2'>
          <Button sentiment='primary' onClick={() => onConfirm(false)}>
            Yes, invite them to the event
          </Button>
          <Button
            sentiment='neutral'
            size='normal'
            variant='muted'
            onClick={() => onConfirm(true)}
          >
            No, thanks
          </Button>
        </div>
      </div>
    </Modal>
  )
}
