import { ClockSolid } from '@motion/icons'
import { formatToTaskDuration } from '@motion/ui-logic'
import { type TaskResponseSchema } from '@motion/zod/client'

import { GenericField } from '../../shared/fields'

export interface TaskDurationFieldProps {
  duration: TaskResponseSchema['duration']
}

export const TaskDurationField = (props: TaskDurationFieldProps) => {
  const { duration } = props

  return (
    <GenericField icon={ClockSolid}>
      {formatToTaskDuration(duration)}
    </GenericField>
  )
}
