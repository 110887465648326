import { type AvailableCustomFieldTypes } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'
import { type WorkspaceSchema } from '@motion/zod/client'

import { CustomField } from '~/areas/project-management/custom-fields'
import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { type ReactNode } from 'react'

import { BulkMultiPersonDropdown } from './bulk-multi-person-dropdown'
import { BulkMultiSelectDropdown } from './bulk-multi-select-dropdown'
import { type BulkCustomFieldFieldArrayValue } from './types'

export type BulkCustomFieldDropdownSwitchProps = {
  onChange: (...args: unknown[]) => void
  workspaceId: WorkspaceSchema['id']
  customField: BulkCustomFieldFieldArrayValue
  children: ReactNode
}

export function BulkCustomFieldDropdownSwitch(
  props: BulkCustomFieldDropdownSwitchProps
) {
  const fn = customFieldLookup[props.customField.type]

  if (!fn) {
    const e = new Error(`Unknown custom field type`)
    Sentry.captureException(e, {
      extra: { workspaceId: props.workspaceId, customField: props.customField },
    })
    throw e
  }

  return fn(props)
}

const customFieldLookup: Record<
  AvailableCustomFieldTypes,
  (props: BulkCustomFieldDropdownSwitchProps) => ReactNode
> = {
  text: ({ children, customField, onChange }) => children,
  date: ({ children, customField, onChange }) => {
    const cf = customField as Extract<
      BulkCustomFieldDropdownSwitchProps['customField'],
      { type: 'date' }
    >

    return (
      <SimpleDateDropdown
        disabledDate={() => false}
        value={cf.value ?? null}
        onChange={onChange}
        subActions={[
          {
            content: 'Clear date',
            onAction: () => onChange(null),
          },
        ]}
      >
        {children}
      </SimpleDateDropdown>
    )
  },
  number: ({ children, customField, onChange }) => children,
  url: ({ children, customField, onChange }) => children,
  select: ({ children, workspaceId, customField, onChange }) => {
    const cf = customField as Extract<
      BulkCustomFieldDropdownSwitchProps['customField'],
      { type: 'select' }
    >

    return (
      <CustomField.Select
        workspaceId={workspaceId}
        selectedItemId={cf.value ?? null}
        customFieldId={cf.instanceId}
        onChange={onChange}
        hideBottomActionsSection
      >
        {children}
      </CustomField.Select>
    )
  },
  multiSelect: ({ children, workspaceId, customField, onChange }) => {
    const cf = customField as Extract<
      BulkCustomFieldDropdownSwitchProps['customField'],
      { type: 'multiSelect' }
    >

    return (
      <BulkMultiSelectDropdown
        workspaceId={workspaceId}
        customField={cf}
        onChange={onChange}
      >
        {children}
      </BulkMultiSelectDropdown>
    )
  },
  person: ({ children, workspaceId, customField, onChange }) => {
    const cf = customField as Extract<
      BulkCustomFieldDropdownSwitchProps['customField'],
      { type: 'person' }
    >

    return (
      <CustomField.Person
        workspaceId={workspaceId}
        selectedUserId={cf.value}
        onChange={onChange}
        hideBottomActionsSection
      >
        {children}
      </CustomField.Person>
    )
  },
  multiPerson: ({ children, workspaceId, customField, onChange }) => {
    const cf = customField as Extract<
      BulkCustomFieldDropdownSwitchProps['customField'],
      { type: 'multiPerson' }
    >

    return (
      <BulkMultiPersonDropdown
        workspaceId={workspaceId}
        customField={cf}
        onChange={onChange}
      >
        {children}
      </BulkMultiPersonDropdown>
    )
  },
}
