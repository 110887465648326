import { useHasTreatment } from '@motion/web-common/flags'

import { useFlowsModalState } from '../contexts'

export function useTaskDefinitionDirtyState(taskId: string) {
  const { dirtyTasksMap } = useFlowsModalState()

  const isFullTaskDefinitionModalEnabled = useHasTreatment(
    'flows-task-definition-modal'
  )

  // Return false if flag disabled
  if (!isFullTaskDefinitionModalEnabled) {
    return false
  }

  return dirtyTasksMap[taskId] ?? false
}
