import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import {
  convertStageDefinitionToFormStage,
  type FlowTemplateFormFields,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { showErrorToast } from '~/global/toasts'

import { useFlowTemplateForm } from './use-flow-template-form'
import { useRedirectToFlowTemplate } from './use-redirect-to-flow-template'
import { useSaveFlow } from './use-save-flow'

import { useFlowsModalState } from '../contexts'

export function useSubmitTemplateForm() {
  const saveFlow = useSaveFlow()
  const redirectToFlowTemplate = useRedirectToFlowTemplate()
  const { form } = useFlowTemplateForm()
  const modalState = useFlowsModalState()
  const modalApi = useModalApi()

  const workspaceId = form.watch('workspaceId')
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  return async (data: FlowTemplateFormFields) => {
    try {
      const savedFlowTemplate = await saveFlow(data)
      const currentFormValues = form.getValues()

      const updatedFormValues = {
        ...currentFormValues,
        id: savedFlowTemplate.id,
        stages: updateStagesWithNewTasks(
          currentFormValues.stages,
          savedFlowTemplate.stages,
          workspaceCustomFields
        ),
      }

      // Reset form with updated values
      form.reset(updatedFormValues)

      /// Dismiss current flows template modal and AI modal
      if (modalState.mode === 'ai-generation') {
        modalApi.dismiss('flows-template-modal')
        modalApi.dismiss('flows-ai-modal')
      }

      redirectToFlowTemplate(savedFlowTemplate.id)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitTemplateForm',
        },
      })

      showErrorToast(e)
    }
  }
}

/**
 * Required to replace task placeholder ids
 */
function updateStagesWithNewTasks(
  currentStages: FlowTemplateStage[],
  updatedStages: StageDefinitionSchema[],
  workspaceCustomFields: AllAvailableCustomFieldSchema[]
): FlowTemplateStage[] {
  return currentStages.map((stage) => {
    const updatedStage = updatedStages.find((s) => s.id === stage.id)

    const updatedTasks = updatedStage
      ? convertStageDefinitionToFormStage({
          stage: updatedStage,
          workspaceCustomFields,
        }).tasks
      : stage.tasks

    return {
      ...stage,
      tasks: updatedTasks,
    }
  })
}
