import { type CalendarStartDay } from '@motion/ui-logic/calendar'

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

import { getCalendars } from './calendarThunks'

import { type EventType } from '../../components/Calendar/types/EventType'
import { type ScheduledTask } from '../../taskTypes'
import {
  type Calendar,
  type CalendarListEntry,
} from '../../types/settingsTypes'
import { getProxy } from '../backgroundProxy'
import { type LoadingState } from '../projectManagementSlice'
import type { RootState } from '../types'

const calendarService = getProxy('CalendarService')

export type CalendarListPickerSectionType = 'mine' | 'frequent'
interface CalendarListPickerModalType {
  visible: boolean
  section?: CalendarListPickerSectionType
}

export type CalendarPanelMenuSectionType = 'main' | 'mine' | 'frequent' | 'all'

export interface ScheduledTaskSection {
  id: string
  tasks: ScheduledTask[]
}

export type SectionedScheduledTasks = ScheduledTaskSection[]

export type NewEventTimesType = {
  start: string
  end: string
  pending: boolean
}

export interface CalendarState {
  calendars?: Calendar[]
  myCalendars: CalendarListEntry[]
  frequentCalendars: CalendarListEntry[]
  calendarEvents: Readonly<EventType[]>
  isWeekly: boolean
  calendlyMode: boolean
  teamFocused: boolean
  sidebarMode: 'task' | 'calendar'
  /* ISOString */
  baseDate: string
  miniCalendarVisible: boolean
  /* ISOString */
  miniCalendarDate: string
  calendarTeamShow: boolean
  dragTimeslotsVisible: boolean
  calendarStartDay: CalendarStartDay
  rescheduleMode: boolean
  tasksLoading: boolean
  calendarListPickerModal: CalendarListPickerModalType
  popoverVisible: boolean
  scheduledTaskSections: SectionedScheduledTasks
  calendarEventStay: boolean
  calendarEventVisible: boolean
  calendarEventReminder: boolean
  calendarEditing: boolean
  calendarVisible: boolean
  zoomUpdated: boolean
  calendarModalEvent: EventType
  newEventTimes: NewEventTimesType
  initialViewingDate: string | null
  /**
   * Email list of selected teammates in the team search combobox
   */
  teamSearchLoadingState: Record<string, LoadingState>
  teamSearchValues: string[]
}

export const initialCalendarListPickerModalState: CalendarListPickerModalType =
  {
    section: 'mine',
    visible: false,
  }

export const initialCalendarState: CalendarState = {
  dragTimeslotsVisible: false,
  baseDate: DateTime.now().toISO(),
  calendarEditing: false,
  calendarEventReminder: false,
  calendarEventStay: false,
  calendarEventVisible: false,
  calendarEvents: [],
  calendarListPickerModal: initialCalendarListPickerModalState,
  calendarModalEvent: {} as EventType,
  calendarStartDay: 'sunday',
  calendarTeamShow: false,
  calendarVisible: false,
  calendlyMode: false,
  frequentCalendars: [],
  initialViewingDate: null,
  isWeekly: true,
  miniCalendarDate: DateTime.now().toISO(),
  miniCalendarVisible: false,
  myCalendars: [],
  newEventTimes: {
    end: '',
    pending: false,
    start: '',
  },
  popoverVisible: false,
  rescheduleMode: false,
  scheduledTaskSections: [],
  sidebarMode: 'task',
  tasksLoading: false,
  teamFocused: false,
  teamSearchLoadingState: {},
  teamSearchValues: [],
  zoomUpdated: false,
}

export const detachCalendars = createAsyncThunk(
  'calendar/detachCalendars',
  (): Promise<{ success?: boolean; error?: string }> => {
    return calendarService.detachCalendars()
  }
)

export const mainSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getCalendars.fulfilled, (state, action) => {
      state.calendars = action.payload
    })
  },
  initialState: initialCalendarState,
  name: 'calendar',
  reducers: {
    reset: () => initialCalendarState,
    setDragTimeslotsVisible: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.dragTimeslotsVisible = action.payload
    },
    setBaseDate: (state: CalendarState, action: PayloadAction<string>) => {
      state.baseDate = action.payload
    },
    setCalendarEditing: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.calendarEditing = action.payload
    },
    setCalendarEventReminder: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.calendarEventReminder = action.payload
    },
    setCalendarEventStay: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.calendarEventStay = action.payload
    },
    setCalendarEventVisible: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.calendarEventVisible = action.payload
    },
    setCalendarEvents: (
      state: CalendarState,
      action: PayloadAction<EventType[]>
    ) => {
      state.calendarEvents = action.payload
    },
    setCalendarListPickerModal: (
      state: CalendarState,
      action: PayloadAction<CalendarListPickerModalType>
    ) => {
      state.calendarListPickerModal = {
        ...state.calendarListPickerModal,
        ...action.payload,
      }
    },
    setCalendarModalEvent: (
      state: CalendarState,
      action: PayloadAction<EventType>
    ) => {
      state.calendarModalEvent = action.payload
    },
    setCalendarStartDay: (
      state: CalendarState,
      action: PayloadAction<'sunday' | 'monday'>
    ) => {
      state.calendarStartDay = action.payload
    },
    setCalendarTeamShow: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.calendarTeamShow = action.payload
    },
    setCalendarVisible: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.calendarVisible = action.payload
    },
    setCalendlyMode: (state: CalendarState, action: PayloadAction<boolean>) => {
      state.calendlyMode = action.payload
    },
    setInitialViewingDate: (
      state: CalendarState,
      action: PayloadAction<string | null>
    ) => {
      state.initialViewingDate = action.payload
    },
    setMiniCalendarDate: (
      state: CalendarState,
      action: PayloadAction<string>
    ) => {
      state.miniCalendarDate = action.payload
    },
    setMiniCalendarVisible: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.miniCalendarVisible = action.payload
    },
    setNewEventTimes: (
      state: CalendarState,
      action: PayloadAction<NewEventTimesType>
    ) => {
      state.newEventTimes = action.payload
    },
    setPopoverVisible: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.popoverVisible = action.payload
    },
    setRescheduleMode: (
      state: CalendarState,
      action: PayloadAction<boolean>
    ) => {
      state.rescheduleMode = action.payload
    },
    setScheduledTaskSections: (
      state: CalendarState,
      action: PayloadAction<SectionedScheduledTasks>
    ) => {
      state.scheduledTaskSections = action.payload
    },
    setSidebarMode: (
      state: CalendarState,
      action: PayloadAction<'task' | 'calendar'>
    ) => {
      state.sidebarMode = action.payload
    },
    setTasksLoading: (state: CalendarState, action: PayloadAction<boolean>) => {
      state.tasksLoading = action.payload
    },
    setTeamFocused: (state: CalendarState, action: PayloadAction<boolean>) => {
      state.teamFocused = action.payload
    },

    setWeekly: (state: CalendarState, action: PayloadAction<boolean>) => {
      state.isWeekly = action.payload
    },
    setZoomUpdated: (state: CalendarState, action: PayloadAction<boolean>) => {
      state.zoomUpdated = action.payload
    },
  },
})

export const {
  setCalendarEvents,
  setBaseDate,
  setDragTimeslotsVisible,
  setCalendarStartDay,
  setCalendarVisible,
  setZoomUpdated,
  setInitialViewingDate,
  reset,
} = mainSlice.actions

export const selectBaseDate = (state: RootState) => state.calendar.baseDate
export const selectDragTimeslotsVisible = (state: RootState) =>
  state.calendar.dragTimeslotsVisible
export const selectCalendarStartDay = (state: RootState) =>
  state.calendar.calendarStartDay

export const selectZoomUpdated = (state: RootState) =>
  state.calendar.zoomUpdated

export const selectCalendars = (state: RootState) => state.calendar.calendars

export const calendarReducer = mainSlice.reducer
