import { getBaseHeaders, HttpConnectionError, HttpError } from '@motion/rpc'
import { MOTION_CLIENT_HEADER } from '@motion/shared/common'
import { parseDate } from '@motion/utils/dates'
import { safeJsonParse } from '@motion/utils/object'
import { sleep } from '@motion/utils/promise'
import { getMotionClient } from '@motion/web-base/env'
import { errorInDev, logInDev, makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { firebase } from '@motion/web-common/firebase'
import { stats } from '@motion/web-common/performance'

import { getCurrentUserToken } from '~/utils/auth'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'

import api from '../../chromeApi/chromeApiBackground'
import { type StripeSubscription } from '../../state/userSlice'

export type SubscriptionType =
  | 'legacy_free_tier'
  | 'legacy_nocc_referral'
  | 'legacy_whitelist_team'
  | 'individual_plan'
  | 'team_plan'
  | 'team_plan_trial'

interface UserSubscriptionResponse {
  id: string
  customer?: {
    id: string
    balance: number
    currency: string
  }
  /**
   * The user's last active date. Note that this field (`lastActive` on the user
   * table) is updated when the `/subscriptions` call is made.
   */
  lastActiveDate?: string
  subscription: StripeSubscription | null
  userId: string
  status: string
  // MM/DD/YY
  endDate: string | null
  savingsPercent: number | null
  savingsAmount: number | null
  type: SubscriptionType
  isExpired: boolean
}

type ErrorResponse = {
  message: string
  statusCode: number
}

type NoSubscriptionResponse = Record<string, never>

type SubscriptionEndpointResponse =
  | UserSubscriptionResponse
  | ErrorResponse
  | NoSubscriptionResponse

const makeRequest = async (
  host: string,
  path: string,
  method: string,
  body?: Record<string, any>
) => {
  const url = `${host}/${path}`

  const authToken = await getCurrentUserToken()
  if (authToken == null) {
    throw new Error(`No auth token was found`)
  }

  const reqId = uuidv4()
  try {
    const stringedBody = body ? JSON.stringify(body) : undefined
    return await fetch(url, {
      body: stringedBody,
      headers: {
        ...getBaseHeaders({ token: authToken, contentType: 'json', reqId }),
        'x-motion-web-version': __SENTRY_RELEASE__,
        [MOTION_CLIENT_HEADER]: getMotionClient(),
      },
      method,
      credentials: 'include',
    }).then(async (res) => {
      if (res.ok) return res

      // failed to get a valid response
      throw new HttpError({
        status: res.status,
        body: maybeJson(await res.text()),
        reqId,
      })
    })
  } catch (ex) {
    if (ex instanceof HttpError) {
      throw ex
    }
    throw new HttpConnectionError({ uri: path, method, cause: ex, reqId })
  }
}

function maybeJson(text: string) {
  return safeJsonParse(text) ?? text
}

const request = async (
  path: string,
  method: string,
  body?: Record<string, any>
) => {
  const response = await makeRequest(__BACKEND_HOST__, path, method, body)
  return await response.json()
}

const wipeSubscriptionData = async () => {
  await api.storage.local.set({
    savings: null,
    stripeCustomer: null,
    stripeSubscription: null,
    subscriptionType: null,
    subscriptFetchError: null,
  })
}

export const upgradeToAnnualPlan = async () => {
  return await request('users/upgradeAnnual', 'POST')
}

const log = makeLog('loaded-background.subscription')
export const get = () =>
  log.time('get', async () => {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      logInDev('no user to check subscription')
      Sentry.addBreadcrumb({
        message: 'no user to check sub',
        data: { position: 'userSubscriptionService.get' },
      })
      await wipeSubscriptionData()
      return false
    }

    let res: SubscriptionEndpointResponse | Error | null = null
    let i = 0
    while (i < 3) {
      log(`attempt: ${i + 1}`)
      try {
        // eslint-disable-next-line no-await-in-loop
        res = (await request(
          'subscriptions',
          'POST'
        )) as SubscriptionEndpointResponse

        break
      } catch (error) {
        log.error('failed', error)
        errorInDev('Could not fetch subscription', error)
        Sentry.addBreadcrumb({
          message: 'could not fetch sub.catch',
          data: {
            err: (error as Error).message ?? error,
            position: 'userSubscriptionService.get',
          },
        })
        res =
          error instanceof Error
            ? error
            : new Error('Unable to fetch', { cause: error })
      }
      i += 1
      // eslint-disable-next-line no-await-in-loop
      await sleep(1_000)
    }

    if (res instanceof Error || res == null) {
      errorInDev('Could not fetch subscription', res)
      Sentry.addBreadcrumb({
        message: 'could not fetch sub - after retry',
        data: {
          position: 'userSubscriptionService.get',
        },
      })
      stats.increment('errors.fetch', 1)

      await wipeSubscriptionData()
      await api.storage.local.set({
        subscriptionFetchError: 'serverError',
      })

      return false
    }

    if (res && 'statusCode' in res) {
      errorInDev('Could not fetch subscription', res)
      Sentry.addBreadcrumb({
        message: 'sub - status',
        data: {
          status: res.statusCode,
          position: 'userSubscriptionService.get',
        },
      })

      const ex = new Error('Could not fetch subscription', { cause: res })

      Sentry.captureException(ex, { tags: { position: 'getSubscription' } })
      if (res.statusCode >= 400) {
        await wipeSubscriptionData()
        await api.storage.local.set({
          subscriptionFetchError: 'serverError',
        })
      }
      return false
    }

    const userHasNoSubscription = Boolean(res && Object.keys(res).length === 0)
    if (userHasNoSubscription) {
      await wipeSubscriptionData()
      return false
    }

    if (!res || i >= 3) {
      errorInDev('Could not fetch subscription', res)
      await wipeSubscriptionData()
      await api.storage.local.set({
        subscriptionFetchError: 'serverUnavailable',
      })
      return false
    }

    if (
      res.endDate &&
      parseDate(res.endDate).plus({ day: 1 }) >= DateTime.now()
    ) {
      await api.storage.local.set({
        lastActiveDate: res.lastActiveDate,
        savings: res.savingsPercent
          ? { savingsPct: res.savingsPercent, stripeAmount: res.savingsAmount }
          : null,
        stripeCustomer: res.customer,
        stripeSubscription: res.subscription,
        subscriptionType: res.type,
        subscriptionFetchError: null,
      })
      return true
    }

    await api.storage.local.set({
      savings: null,
      stripeCustomer: res.customer,
      stripeSubscription: res.subscription,
      subscriptionType: res.type,
      subscriptionFetchError: null,
    })
    return true
  })
