import { type TemplateTaskType } from '@motion/rpc/types'
import { getTemplateFormData } from '@motion/ui-logic/pm/task'
import { entries } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCallback } from 'react'

import { useTaskForm } from './use-task-form'

export const useSelectTemplate = () => {
  const { form } = useTaskForm()

  return useCallback(
    (template: TemplateTaskType) => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_USE_TEMPLATE_TASK')

      const formData = getTemplateFormData(template, {
        recurring: form.watch('type') === 'RECURRING_TASK',
      })

      entries(formData).forEach(([key, value]) => {
        form.setValue(key, value, { shouldDirty: true })
      })
    },
    [form]
  )
}
