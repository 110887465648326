import { DateTime } from 'luxon'

import { type FullCalendarEvent } from '../../../../areas/calendar/utils'

export const MIN_EVENT_DURATION = 15
export const TIME_FORMAT = 'h:mm'

export function createTravelTimeBackgroundEvent(
  ev: FullCalendarEvent
): FullCalendarEvent {
  return {
    ...ev,
    ...effectiveRange(ev),
    classNames: [...(ev.classNames ?? []), 'fc-travel-time'],
    display: 'background',
    editable: false,
    id: `${ev.id}|travel`,
    overlap: true,
    title: 'Travel',
  }
}

export const addMinutes = (from: string, offsetMinutes: number) => {
  return DateTime.fromISO(from).plus({ minutes: offsetMinutes }).toISO()
}

export const getDurationInMinutes = (startIso: string, endIso: string) => {
  const start = DateTime.fromISO(startIso)
  const end = DateTime.fromISO(endIso)
  return end.diff(start).as('minute')
}

const effectiveRange = (ev: FullCalendarEvent) => {
  const duration = DateTime.fromISO(ev.end)
    .diff(DateTime.fromISO(ev.start))
    .as('minute')
  const effectiveDuration = Math.max(MIN_EVENT_DURATION, duration)

  const beforeDuration = ev.travelTimeBefore
    ? Math.max(MIN_EVENT_DURATION, ev.travelTimeBefore)
    : 0
  const afterDuration = ev.travelTimeAfter
    ? Math.max(MIN_EVENT_DURATION, ev.travelTimeAfter)
    : 0
  return {
    end: addMinutes(addMinutes(ev.start, effectiveDuration), afterDuration),
    start: addMinutes(ev.start, -beforeDuration),
  }
}

export const toEventModel = (calendarEvent: FullCalendarEvent) => {
  if (!calendarEvent.travelTimeBefore && !calendarEvent.travelTimeAfter) {
    return calendarEvent
  }
  return [
    { ...calendarEvent, groupId: calendarEvent.id },
    createTravelTimeBackgroundEvent(calendarEvent),
  ]
}

export const showAvailabilityTimezone = (
  dragTimeslotsVisible: boolean,
  availabilityTimezone: string,
  currentTimezone: string
) => {
  return dragTimeslotsVisible && availabilityTimezone !== currentTimezone
}
