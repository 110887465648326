import { QuestionMarkCircleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  EnterpriseBillingPlanCard,
  IndividualBillingPlanCard,
  TeamBillingPlanCard,
} from '@motion/web-billing'

import { LoadingSvg } from '~/components/Common/Icons/LoadingSvg'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useState } from 'react'

import { Header, SubHeader, SubParagraph } from '../../../components/Common'
import { HorizontalDivider } from '../../../components/Common/Divider/HorizontalDivider'
import { DetachCalendarModal } from '../../../components/Settings/Modals/DetachCalendarModal/DetachCalendarModal'
import { useToggleIntercom } from '../../../utils/toggleIntercom'

interface TeamSubscriptionExpiredProps {
  email: string
  teamSubscribe: () => void
  individualSubscribe: () => void
  signOut: () => void
}

export const TeamSubscriptionExpired = ({
  email,
  teamSubscribe,
  individualSubscribe,
  signOut,
}: TeamSubscriptionExpiredProps) => {
  const [showDetachCalendarModal, setShowDetachCalendarModal] = useState(false)
  const toggleIntercom = useToggleIntercom()

  const { data: teamPrices } = useGetTeamPrices()

  if (!teamPrices) {
    return (
      <div className='flex h-screen w-screen items-center justify-center'>
        <LoadingSvg />
      </div>
    )
  }

  return (
    <div className='h-screen w-screen overflow-y-auto'>
      <div className='bg-semantic-neutral-bg-subtle relative flex items-center justify-center py-20'>
        <div className='absolute top-12 right-12'>
          <Button onClick={() => toggleIntercom()} sentiment='neutral'>
            <QuestionMarkCircleSolid className='mr-2.5 h-4 w-4' />
            Support
          </Button>
        </div>

        <div className='flex w-full flex-col justify-center'>
          <Header className='mb-5 text-center'>
            Your team&apos;s subscription has ended
          </Header>
          <SubHeader className='mb-1 text-center'>
            Want to continue using Motion?
          </SubHeader>
          <p className='mb-2.5 text-center font-semibold text-semantic-neutral-text-subtle'>
            You won't be charged until you verify your payment information on
            the next page.
          </p>
          <div className='mb-8 flex flex-row items-center justify-center gap-x-3 mx-8'>
            <div className='flex justify-center gap-4 items-stretch flex-wrap'>
              <IndividualBillingPlanCard
                onClick={individualSubscribe}
                buttonText='Subscribe to Individual Plan'
                isSecondary
              />
              <TeamBillingPlanCard
                onClick={teamSubscribe}
                price={teamPrices.annualPricePerMonth}
                buttonText='Re-subscribe to Team Plan'
                savings={teamPrices.individualToTeamSavingsPercent}
              />
              <EnterpriseBillingPlanCard
                location='teamSubscriptionExpired'
                email={email}
              />
            </div>
          </div>

          <div className='flex flex-col justify-center items-center'>
            <p className='max-w-[500px] mb-3 text-center font-medium text-semantic-neutral-text-subtle'>
              {templateStr(
                "If you think you have an active trial or subscription, it seems you might be using the wrong email with {{email}}. Please try logging in with the right one or reach out to our friendly support team at {{link}}. We're here to help!",
                {
                  email: <span className='underline'>{email}</span>,
                  link: (
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://help.usemotion.com'
                    >
                      help.usemotion.com
                    </a>
                  ),
                }
              )}
            </p>

            <Button onClick={signOut} sentiment='neutral'>
              Sign in with another account
            </Button>
          </div>

          <HorizontalDivider />

          <div className='flex flex-col justify-center'>
            <SubParagraph className='text-center'>
              Not using Motion anymore?
            </SubParagraph>
            <div className='flex flex-row items-center justify-center mt-4'>
              <Button
                size='small'
                sentiment='error'
                onClick={() => setShowDetachCalendarModal(true)}
              >
                Delete Motion Tasks
              </Button>
            </div>
          </div>
        </div>
        {showDetachCalendarModal && (
          <DetachCalendarModal
            visible={showDetachCalendarModal}
            close={() => {
              setShowDetachCalendarModal(false)
            }}
          />
        )}
      </div>
    </div>
  )
}
