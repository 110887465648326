import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { isAutoScheduledStatus } from '@motion/shared/common'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type StatusSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'
import { type ReactNode } from 'react'

import { RestrictWidth } from '../../../components'
import { useBulkFilteredData } from '../../hooks'
import { BulkStatusLabel } from '../bulk-status-label'
import { DropdownContentTopSection } from '../dropdown-content-top-section'
import { DropdownContainer } from '../styled'

export type StatusDropdownProps = Omit<
  BulkStatusDropdownContentProps,
  'close'
> & {
  children?: ReactNode
}

export const BulkStatusDropdown = ({
  children,
  ...rest
}: StatusDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkStatusDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type BulkStatusDropdownContentProps = {
  close: () => void
  onChange: (statusIds: StatusSchema['id'][]) => void
  selectedStatusIds: StatusSchema['id'][]
  disableNonAutoschedulableStatus?: boolean
}
const BulkStatusDropdownContent = ({
  close,
  onChange,
  selectedStatusIds,
  disableNonAutoschedulableStatus = false,
}: BulkStatusDropdownContentProps) => {
  const statuses = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.statuses.all
  )
  const filteredStatuses = useBulkFilteredData(statuses, (s) => s.name)

  return (
    <DropdownContainer>
      <DropdownContentTopSection>
        Only showing statuses shared between all tasks you selected
      </DropdownContentTopSection>
      <SearchableList
        searchable
        items={filteredStatuses}
        computeKey={(item) => item[0].name}
        computeSearchValue={(item) => item[0].name}
        computeSelected={(item) => {
          return selectedStatusIds.some((id) => item.some((s) => s.id === id))
        }}
        computeDisabled={(item) => {
          if (!disableNonAutoschedulableStatus) return false
          return item.some((s) => !isAutoScheduledStatus(s))
        }}
        onSelect={(item) => {
          close()
          onChange(item.map((status) => status.id))
        }}
        renderItem={(item) => (
          <RestrictWidth>
            <BulkStatusLabel value={item} />
          </RestrictWidth>
        )}
        inputProps={{ placeholder: 'Choose status...' }}
      />
    </DropdownContainer>
  )
}
