import { type CustomFieldSchemaByType } from '@motion/ui-logic'

import { useTaskCustomFieldUpdater } from '~/areas/tasks/hooks'
import {
  type CustomFieldWithValue,
  type NormalTaskWithRelations,
} from '~/global/proxies'
import { type FC } from 'react'

import { type ControlledCustomFieldProps } from '../types'

export const ConnectedTaskCell = <T extends CustomFieldWithValue>({
  task,
  customField,
  component: Component,
  disabled,
}: {
  task: NormalTaskWithRelations
  customField: T
  component: FC<
    ControlledCustomFieldProps<CustomFieldSchemaByType<typeof customField.type>>
  >
  disabled: boolean
}) => {
  const updateTaskCustomField = useTaskCustomFieldUpdater()
  const { value } = customField

  const onSubmit = async (newValue: typeof value) => {
    await updateTaskCustomField(task, customField.definition, newValue)
  }

  return (
    <Component
      customField={customField}
      value={value ?? null}
      onSubmit={onSubmit}
      disabled={disabled}
    />
  )
}
