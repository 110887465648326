import { PlusSolid } from '@motion/icons'
import { API } from '@motion/rpc'
import {
  type TeamMemberSerializer,
  type UserSerializer,
} from '@motion/rpc-types'
import {
  ActionList,
  Button,
  IconButton,
  PopoverButton,
  PopoverTrigger,
  Table,
  Tag,
  Tooltip,
  UserAvatar,
} from '@motion/ui/base'
import { type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { HasExperiment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useQueryClient } from '@tanstack/react-query'
import { ConnectedInvitePlaceholderTeammateButton } from '~/areas/project-management/components/invite-placeholder-teammate-button'
import {
  useAllWorkspaces,
  useCreateWorkspaceMember,
  useDeleteWorkspaceMember,
  useWorkspaceFns,
} from '~/global/hooks'
import { useTeamMembers } from '~/global/hooks/team'
import { useCurrentTeam } from '~/global/rpc/team'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../../state/hooks'
import {
  removeTeamMember,
  updateTeamMember,
} from '../../../../../state/projectManagement/teamThunks'
import { selectEmail } from '../../../../../state/userSlice'
import { UserTypeType } from '../../../../../types/User'
import { Paragraph, SubParagraph } from '../../../../Common'
import {
  IndividualOrTeam,
  TeamMemberRole,
  UserFriendlyRole,
} from '../../../../ProjectManagement/types'
import { TeamMembersPageV2 } from '../team-members-page-v2/team-members-page-v2'

export type SimpleWorkspace = { label: string; value: string }

export const DeprecatedTeamMembersPage = () => {
  const dispatch = useAppDispatch()

  const teamMembers = useTeamMembers()
  const pmWorkspaces = useAllWorkspaces()
  const { getWorkspaceMembers, getWorkspaceMemberById } = useWorkspaceFns()
  const { data: team } = useCurrentTeam()
  const userEmail = useAppSelector(selectEmail)
  const totalAdmins = teamMembers.filter(
    (teamMember) => teamMember.role === 'ADMIN'
  ).length

  const modalApi = useModalApi()
  const queryClient = useQueryClient()

  const authenticatedUser = useAuthenticatedUser()

  const { createWorkspaceMember } = useCreateWorkspaceMember()
  const { deleteWorkspaceMember } = useDeleteWorkspaceMember()

  const isAdmin =
    teamMembers.filter((member) => member.user.email === userEmail)[0]?.role ===
    'ADMIN'

  const onMemberRemove = useCallback(
    async (user: UserSerializer) => {
      const isSelf = authenticatedUser.uid === user.id
      const confirmed = isSelf
        ? await modalApi.prompt('confirm', {
            analytics: {
              name: 'remove-self-from-team',
            },
            title: `Are you sure you want to remove yourself from the team?`,
            description:
              "You will lose access to Motion immediately. All other users will retain access and the team's subscription will not be cancelled.",
            destructive: true,
            confirmButtonText: 'Remove',
          })
        : await modalApi.prompt('confirm', {
            analytics: {
              name: 'remove-user-from-team',
            },
            title: `Are you sure you want to remove ${user.name} from the team?`,
            destructive: true,
            confirmButtonText: 'Remove',
          })
      if (confirmed !== true) return
      await dispatch(removeTeamMember(user.id))
      // Remove uses redux, also invalidate the query key
      void queryClient.invalidateQueries(API.teams.queryKeys.currentTeam)
      if (isSelf) {
        void queryClient.invalidateQueries(
          API.subscriptions.queryKeys.individualAndTeam
        )
      }
      void recordAnalyticsEvent('TEAM_BILLING_REMOVE', {
        userId: user.id,
        email: user.email,
      })
    },
    [dispatch, modalApi, queryClient, authenticatedUser.uid]
  )

  const onMemberWorkspaceRemove = useCallback(
    async (
      user: UserSerializer,
      workspace: SimpleWorkspace,
      workspaceMembers: WorkspaceMemberWithUser[]
    ) => {
      const confirmed = await modalApi.prompt('confirm', {
        analytics: {
          name: 'remove-user-from-workspace',
        },
        title: `Are you sure you would like to remove ${user.name} from ${workspace.label}?`,
        destructive: true,
        confirmButtonText: 'Remove',
      })
      if (confirmed !== true) return
      const workspaceMember = workspaceMembers.find(
        (member) => member.user.id === user.id
      )
      if (!workspaceMember) return

      await deleteWorkspaceMember({
        workspaceId: workspace.value,
        memberId: workspaceMember.id,
      })
    },
    [deleteWorkspaceMember, modalApi]
  )

  if (!team) {
    return null
  }

  const displayedTeamMembers = teamMembers.map((teamMember) => ({
    dateAdded: parseDate(teamMember.createdTime).toLocaleString(
      DateTime.DATE_MED
    ),
    invited:
      team?.invites?.some((invite) => invite.email === teamMember.user.email) ||
      teamMember.user.type === UserTypeType.PENDING_INVITED,
    key: teamMember.userId,
    teamMember,
    workspaces: pmWorkspaces
      .filter((workspace) => {
        const workspaceMember = getWorkspaceMemberById(
          workspace.id,
          teamMember.userId
        )
        return (
          !!workspaceMember &&
          !workspaceMember.user.deleted &&
          workspaceMember.status === 'ACTIVE'
        )
      })
      .map((workspace) => ({
        label: workspace.name,
        value: workspace.id,
      })),
  }))

  displayedTeamMembers.sort((a, b) => {
    if (a.invited === b.invited) {
      // this is defined as a Date is prisma. Now that the types are seeping through,
      // it thinks that createdTime is a Date.
      // however it gets turned into a string over the wire
      return (a.teamMember.createdTime as unknown as string).localeCompare(
        b.teamMember.createdTime as unknown as string
      )
    }
    // Show invited members on bottom
    if (a.invited) {
      return 1
    }
    return -1
  })

  const teamMembersTableColumns = [
    {
      dataIndex: 'user',
      key: 'user',
      render: ({ teamMember }: { teamMember: TeamMemberSerializer }) => {
        return (
          <div className='flex flex-row items-center gap-2'>
            <UserAvatar
              id={teamMember.userId}
              name={teamMember.user.name}
              profileUrl={teamMember.user.picture}
              size={20}
            />
            <div className='ml-2 flex flex-col truncate'>
              <Paragraph className='truncate p-0'>
                {teamMember.user.name}
              </Paragraph>
              <SubParagraph className='truncate p-0'>
                {teamMember.user.email}
              </SubParagraph>
            </div>
            {teamMember.user.email !== userEmail &&
              (teamMember.user.isPlaceholder ? (
                <Tag size='small' color='red' variant='subtle'>
                  Not Invited
                </Tag>
              ) : teamMember.user.onboardingComplete !== true ? (
                <Tag size='small' color='yellow' variant='subtle'>
                  Not Accepted
                </Tag>
              ) : null)}
          </div>
        )
      },
      title: 'User',
    },
    {
      className: 'w-[200px]',
      dataIndex: 'role',
      key: 'role',
      render: ({ teamMember }: { teamMember: TeamMemberSerializer }) => {
        const role = teamMember.role
        let userFriendlyRole
        if (role === TeamMemberRole.ADMIN) {
          userFriendlyRole = UserFriendlyRole.ADMIN
        } else if (role === TeamMemberRole.MEMBER) {
          userFriendlyRole = UserFriendlyRole.MEMBER
        } else {
          userFriendlyRole = UserFriendlyRole.GUEST
        }

        return isAdmin && userFriendlyRole === UserFriendlyRole.MEMBER ? (
          <PopoverTrigger
            placement='bottom-start'
            renderPopover={() => (
              <ActionList
                items={[UserFriendlyRole.ADMIN, UserFriendlyRole.MEMBER].map(
                  (role) => ({
                    content: role,
                    onAction: () => {
                      void dispatch(
                        updateTeamMember({
                          teamMember: {
                            ...teamMember,
                            role:
                              role === UserFriendlyRole.ADMIN
                                ? TeamMemberRole.ADMIN
                                : TeamMemberRole.MEMBER,
                          },
                          teamId: team.id,
                        })
                      )
                    },
                  })
                )}
              />
            )}
          >
            <PopoverButton>{userFriendlyRole}</PopoverButton>
          </PopoverTrigger>
        ) : (
          <Paragraph>{userFriendlyRole}</Paragraph>
        )
      },
      title: 'Role',
    },
    {
      className: 'w-[110px]',
      dataIndex: 'dateAdded',
      key: 'dateAdded',
      title: 'Date Added',
    },
    {
      dataIndex: 'workspaces',
      key: 'workspaces',
      render: ({
        workspaces,
        teamMember,
        key,
      }: {
        workspaces: SimpleWorkspace[]
        teamMember: TeamMemberSerializer
        key: string
      }) => {
        const workspaceIdsJoined = workspaces.map((ws) => ws.value)
        const remainingWorkspaces = pmWorkspaces.filter(
          (workspace) => !workspaceIdsJoined.includes(workspace.id)
        )
        return (
          <div className='flex flex-wrap gap-1 items-center'>
            {workspaces.map((workspace) => {
              const foundWorkspace = pmWorkspaces.find(
                (ws) => ws.id === workspace.value
              )

              if (!foundWorkspace) return null

              return (
                <Tag
                  variant='subtle'
                  size='small'
                  key={workspace.value}
                  onRemove={
                    foundWorkspace.type === IndividualOrTeam.TEAM
                      ? async () =>
                          await onMemberWorkspaceRemove(
                            teamMember.user,
                            workspace,
                            getWorkspaceMembers(foundWorkspace.id)
                          )
                      : undefined
                  }
                >
                  <p className='max-w-[150px] truncate'>{workspace.label}</p>
                </Tag>
              )
            })}
            {remainingWorkspaces.length > 0 && (
              <PopoverTrigger
                renderPopover={({ close }) => (
                  <ActionList
                    items={remainingWorkspaces.map((workspace) => ({
                      disabled: workspace.type === IndividualOrTeam.INDIVIDUAL,
                      content: (
                        <Tooltip
                          content={
                            workspace.type === IndividualOrTeam.INDIVIDUAL
                              ? 'Team members can’t be added to a personal workspace'
                              : undefined
                          }
                        >
                          {workspace.name}
                        </Tooltip>
                      ),
                      onAction: async () => {
                        await createWorkspaceMember({
                          workspaceId: workspace.id,
                          userId: teamMember.user.id,
                        })
                        close()
                      },
                    }))}
                  />
                )}
              >
                <IconButton
                  icon={PlusSolid}
                  sentiment='neutral'
                  key={`${key}_addWorkspace`}
                  size='small'
                  aria-label='Add team member to workspace'
                />
              </PopoverTrigger>
            )}
          </div>
        )
      },
      title: 'Workspaces',
    },
    {
      className: 'w-[60px]',
      key: 'invite',
      render: ({ teamMember }: { teamMember: TeamMemberSerializer }) => {
        if (!teamMember.user.isPlaceholder) {
          return null
        }
        return (
          <ConnectedInvitePlaceholderTeammateButton
            userId={teamMember.user.id}
          />
        )
      },
    },
    {
      className: 'w-[90px]',
      key: 'remove',
      render: ({ teamMember }: { teamMember: TeamMemberSerializer }) => {
        const hideRemove =
          !isAdmin || (teamMember.role === 'ADMIN' && totalAdmins === 1)
        if (hideRemove) {
          return null
        }
        return (
          <Button
            variant='muted'
            sentiment='neutral'
            onClick={async () => {
              await onMemberRemove(teamMember.user)
            }}
          >
            Remove
          </Button>
        )
      },
    },
  ]

  return (
    <div className='flex h-full w-full flex-col gap-2 pb-20'>
      <div className='flex items-center justify-between'></div>
      <Table
        columns={teamMembersTableColumns}
        dataSource={displayedTeamMembers}
      />
    </div>
  )
}

export const TeamMembersPage = () => {
  return (
    <HasExperiment
      name='team-members-v2'
      fallback={<DeprecatedTeamMembersPage />}
    >
      <TeamMembersPageV2 />
    </HasExperiment>
  )
}
