import { LabelSolid } from '@motion/icons'
import { Button, ButtonGroup, type ButtonProps } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { labelIconColors, legacyColorToNewColor } from '@motion/ui-logic'

import { StatusBadge, type StatusBadgeProps } from '~/global/components/badges'
import { type ReactNode, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const UneditableStatus = ({
  item,
}: {
  item: StatusBadgeProps['value']
}) => {
  return (
    <div className='flex px-[7px] py-[5px] justify-center items-center'>
      <StatusBadge value={item} size='large' hideTooltip />
    </div>
  )
}

export const LabelColoredIcon = ({
  color: initialColor,
  size,
}: {
  color: string
  size?: ButtonProps['size']
}) => {
  const color = legacyColorToNewColor[initialColor] ?? initialColor

  const colorClass = labelIconColors[color]
  const legacyColor = colorClass ? undefined : color
  // TODO: fix later
  const colorClassToVar = colorClass?.split('-').slice(2).join('-')

  return (
    <LabelSolid
      className={twMerge(size === 'small' ? 'w-4 h-4' : 'w-5 h-5')}
      style={{
        color: colorClass
          ? `var(--motion-label-${colorClassToVar}`
          : legacyColor,
      }}
    />
  )
}

export const EditableWorkspaceRow = ({
  icon,
  name: itemName,
  onChange,
  type,
}: {
  icon?: ReactNode
  name: string
  onChange(name: string): void
  type: 'label' | 'status'
}) => {
  const [name, setName] = useState(itemName)

  return (
    <div className='flex gap-3'>
      {icon}
      <div className='flex-grow'>
        <TextField
          onBlur={() => onChange(name)}
          onChange={(value) => setName(value)}
          placeholder={`Enter ${type} name`}
          size='normal'
          value={name}
        />
      </div>
    </div>
  )
}

type AddWorkspaceItemRowProps = {
  icon: React.ReactNode
  onCancel(): void
  onSave(name: string): void
  placeholder?: string
}
export const AddWorkspaceItemRow = ({
  icon,
  onCancel,
  onSave,
  placeholder = 'Enter name',
}: AddWorkspaceItemRowProps) => {
  const [name, setName] = useState('')

  return (
    <div className='flex gap-3'>
      {icon}
      <div className='flex-grow'>
        <TextField
          autoFocus
          onChange={setName}
          size='normal'
          placeholder={placeholder}
          value={name}
        />
      </div>
      <ButtonGroup>
        <Button
          onClick={onCancel}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Cancel
        </Button>
        <Button onClick={() => onSave(name)} size='small'>
          Save
        </Button>
      </ButtonGroup>
    </div>
  )
}
