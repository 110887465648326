import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { isObjectNoneId } from '@motion/shared/identifiers'
import { type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { createNoneUser } from '@motion/ui-logic/pm/data'
import { byValue, Compare } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import {
  type WorkspaceMemberSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import {
  AppWorkspaceContext,
  type AppWorkspaceDataContext,
} from '~/global/contexts'

export const useAllWorkspaceActiveMembers = (): WorkspaceMemberWithUser[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.workspaces.all.flatMap(
      (workspace) =>
        workspace.members
          .filter((m) => m.status === 'ACTIVE')
          .map((m) => convertWorkspaceMember(m, workspace.id, state))
          .sort(byValue((member) => member.user.name, Compare.string)) ?? []
    )
  )
}

export const useMembers = (): WorkspaceMemberWithUser[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.workspaces.all.flatMap(
      (workspace) =>
        workspace.members
          .map((m) => convertWorkspaceMember(m, workspace.id, state))
          .sort(byValue((member) => member.user.name, Compare.string)) ?? []
    )
  )
}

export const useWorkspaceActiveMembers = (
  workspaceId: string
): WorkspaceMemberWithUser[] => {
  return useSharedStateQuery(
    AppWorkspaceContext,
    (state) =>
      state.workspaces.byId[workspaceId]?.members
        .filter((m) => m.status === 'ACTIVE')
        .map((m) => convertWorkspaceMember(m, workspaceId, state))
        .sort(byValue((member) => member.user.name, Compare.string)) ?? []
  )
}

export const useWorkspaceMember = (
  workspaceId: string,
  memberId: string | null | undefined
): WorkspaceMemberWithUser | undefined => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (!memberId) return undefined

    const member = state.workspaces.byId[workspaceId]?.members.find(
      (m) => m.userId === memberId
    )

    if (!member) return undefined

    return convertWorkspaceMember(member, workspaceId, state)
  })
}

export const useMember = (
  memberId: string | null | undefined
): WorkspaceMemberWithUser | undefined => {
  return useMembers().find((m) => m.userId === memberId)
}

function convertWorkspaceMember(
  member: WorkspaceMemberSchema,
  workspaceId: WorkspaceSchema['id'],
  ctx: AppWorkspaceDataContext
): WorkspaceMemberWithUser {
  const user = ctx.users.byId[member.userId] ?? createNoneUser(workspaceId)

  if (isObjectNoneId(user)) {
    Sentry.captureMessage('No user found for member', {
      extra: {
        userId: member.userId,
        workspaceId,
      },
    })
  }

  return {
    ...member,
    workspaceId,
    user,
  }
}
