import { type AvailableCustomFieldTypes } from '@motion/ui-logic'

import {
  ControlledDate,
  ControlledMultiPerson,
  ControlledMultiSelect,
  ControlledNumber,
  ControlledPerson,
  ControlledSelect,
  ControlledText,
  ControlledUrl,
} from './controlled'

export const CONTROLLED_CUSTOM_FIELD_KANBAN_LOOKUP = {
  text: ControlledText,
  date: ControlledDate,
  multiSelect: ControlledMultiSelect,
  number: ControlledNumber,
  select: ControlledSelect,
  url: ControlledUrl,
  multiPerson: ControlledMultiPerson,
  person: ControlledPerson,
} as const satisfies {
  [T in AvailableCustomFieldTypes]: React.ComponentType<any>
}
