import { useProjectUpdater } from '~/areas/project/hooks'
import {
  AssigneeField,
  type AssigneeFieldProps,
} from '~/areas/task-project/fields'
import { useProject, useWorkspaceMember } from '~/global/hooks'

type ProjectAssigneeFieldProps = {
  projectId: string
  onChange?: AssigneeFieldProps['onChange']
}

export function ProjectAssigneeField({
  projectId,
  onChange: onChangeFromProps,
}: ProjectAssigneeFieldProps) {
  const updateProject = useProjectUpdater()

  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project is required')
  }

  const selectedAssignee = useWorkspaceMember(
    project.workspaceId,
    project.managerId
  )

  const onChange: AssigneeFieldProps['onChange'] = async (assigneeId) => {
    await updateProject(projectId, { managerId: assigneeId })
    onChangeFromProps?.(assigneeId)
  }

  return (
    <AssigneeField
      value={selectedAssignee?.userId ?? null}
      onChange={onChange}
      workspaceId={project.workspaceId}
    />
  )
}
