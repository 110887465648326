import { type WorkspaceSchema } from '@motion/rpc-types'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { ModalFieldButton } from '~/areas/task-project/components'
import { WorkspaceBadge } from '~/global/components/badges'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { useWorkspaceById } from '~/global/hooks'

type WorkspaceFieldProps = {
  value: string
  onChange: (workspaceId: string) => void
}

export function WorkspaceField({ value, onChange }: WorkspaceFieldProps) {
  const workspace = useWorkspaceById(value)
  const modalApi = useModalApi()

  const onUpdateWorkspace = async (workspace: WorkspaceSchema) => {
    const res = await modalApi.prompt('confirm', {
      analytics: {
        name: 'update-task-defaults-workspace',
      },
      title: 'Are you sure you want to change the default workspace?',
      description:
        'The default project, assignee, status, labels, and custom fields will be reset.',
      closeButtonText: 'Cancel',
      confirmButtonText: 'Continue',
    })

    if (res === ModalDismissed) return

    onChange(workspace.id)
  }

  return (
    <WorkspaceDropdown selectedWorkspaceId={value} onChange={onUpdateWorkspace}>
      <ModalFieldButton label='Workspace' icon={<WorkspaceBadge />}>
        {workspace?.name ?? 'None'}
      </ModalFieldButton>
    </WorkspaceDropdown>
  )
}
