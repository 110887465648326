import {
  type InternalModalApi,
  type ModalOnDismissFn,
  type ModalOptions,
  useModalApi,
} from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'

import { useContext, useMemo } from 'react'

import { CurrentModalContext } from './current-modal-context'

export function useCurrentModal<TName extends keyof ModalDefinitions>() {
  const name = useContext(CurrentModalContext)
  const modalApi = useModalApi() as InternalModalApi

  return useMemo(
    () => ({
      status: (name: TName): ModalOptions<ModalDefinitions[TName]> => {
        return modalApi.status(name)
      },
      dismiss: () => {
        if (name == null) return
        modalApi.dismiss(name)
      },
      updateProps: (props: Partial<ModalDefinitions[TName]>) => {
        if (name == null) return
        modalApi.updateProps(name, props)
      },
      updateOnDismiss: (callback: ModalOnDismissFn) => {
        if (name == null) return
        modalApi.updateOnDismiss(name, callback)
      },
    }),
    [modalApi, name]
  )
}
