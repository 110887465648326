import { Button, Tooltip } from '@motion/ui/base'
import { getEtaLabel, normalizeTaskDeadlineStatus } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { type BadgeSize, EtaBadge } from '~/global/components/badges'
import { type TaskWithRelations } from '~/global/proxies'

import { BadgeTooltip } from './badge-tooltip'

import { useExtendedNoneEta } from '../../hooks'
import { TaskEtaPopover } from '../popover'

type ConnectedTaskEtaBadgeProps = {
  task: TaskWithRelations
  size?: BadgeSize
  hideTooltip?: boolean
}

export const ConnectedTaskEtaBadge = ({
  task,
  size = 'small',
  hideTooltip = false,
}: ConnectedTaskEtaBadgeProps) => {
  const hasPastDueM2 = useHasTreatment('past-due-m2')
  const deadlineStatus = normalizeTaskDeadlineStatus(task)
  const extendedDeadlineStatus = useExtendedNoneEta({
    entity: task,
    type: 'task',
  })

  if (!hasPastDueM2) return null

  return (
    <TaskEtaPopover entity={task}>
      <Tooltip
        renderContent={() =>
          hideTooltip ? undefined : <BadgeTooltip entity={task} type='task' />
        }
      >
        <Button
          aria-label={getEtaLabel(extendedDeadlineStatus, 'task')}
          sentiment='neutral'
          variant='muted'
          disabled={deadlineStatus === 'none'}
          size='xsmall'
          iconOnly
        >
          <EtaBadge value={extendedDeadlineStatus} size={size} />
        </Button>
      </Tooltip>
    </TaskEtaPopover>
  )
}
