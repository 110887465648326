import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { useScreenValues } from './use-screen-values'

import { usePlannerProps, useScrollPosition } from '../context'
import { getDaysFromWeekStart, isoDateToPixel, pixelToDate } from '../utils'

type TimelineValue = {
  date: DateTime
  position: number
}

// Retrieve the array of weeks and month columns for the timeline bar
export const useTimelineValues = () => {
  const [scrollPosition] = useScrollPosition()
  const [plannerProps] = usePlannerProps()
  const currentDate = DateTime.now().startOf('day')
  const { screenSize, positionOffset } = useScreenValues()

  const scrollInterval =
    Math.floor(Math.floor(scrollPosition / screenSize) * screenSize) * -1

  const { daysFromWeekStart } = getDaysFromWeekStart(currentDate)

  const weekPx = plannerProps.dayPx * 7

  const limitLeft = Math.floor(
    (scrollInterval - screenSize - positionOffset) / weekPx
  )
  const limitRight = Math.floor(
    (scrollInterval + screenSize + positionOffset) / weekPx
  )

  return useMemo(() => {
    const weekCols: TimelineValue[] = []
    const monthCols: TimelineValue[] = []

    const monthMap: Map<string, DateTime> = new Map()
    const weekPx = plannerProps.dayPx * 7
    for (let i = limitLeft; i <= limitRight; i++) {
      const position = i * weekPx + daysFromWeekStart * plannerProps.dayPx
      const positionDate = pixelToDate(position, plannerProps.dayPx)

      const startOfMonth = positionDate.startOf('month')
      monthMap.set(startOfMonth.toISODate(), startOfMonth)

      weekCols.push({
        date: positionDate,
        position,
      })
    }

    for (const [key, value] of monthMap) {
      monthCols.push({
        date: value,
        position: isoDateToPixel(key, plannerProps.dayPx),
      })
    }
    return {
      weekCols,
      monthCols,
    }
  }, [daysFromWeekStart, limitLeft, limitRight, plannerProps.dayPx])
}
