import { type SocketEventType } from '@motion/shared/websockets'
import { makeLog } from '@motion/web-base/logging'
import { websocketsEventSubscriber } from '@motion/web-common/websockets'

const eventsToSubscribe: SocketEventType[] = [
  'team.memberInvited',
  'onboarding.reset',
]

const log = makeLog('[OnboardingWebsocketSubscribers]')
let isRegistered = false

const handleMessage = (event: string) => {
  log(`Received websockets message: ${event}`)
  window.location.reload()
}

export const registerOnboardingWebsocketSubscribers = () => {
  if (isRegistered) {
    return
  }

  isRegistered = true
  log('Registering onboarding websocket events')

  const subscriptions = eventsToSubscribe.map((event) =>
    websocketsEventSubscriber.on(event, () => {
      handleMessage(event)
    })
  )
  return () => {
    subscriptions.forEach((sub) => sub())
    isRegistered = false
  }
}
