import { TagSolid } from '@motion/icons'

import { Label } from '~/global/components/labels'
import { useAppWorkspaceContext } from '~/global/contexts'

import { ConnectedGroupedItemDropdown } from './connected-grouped-item-dropdown'
import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import {
  WorkspaceItem,
  WorkspaceItemGroupHeader,
} from '../../buttons/workspace-item'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedLabelFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(props.target, 'labelIds')
  const [ctx] = useAppWorkspaceContext()

  return (
    <FilterItem
      label='Label'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...value, inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedGroupedItemDropdown
          applyTo={props.target}
          field='labelIds'
          getAllItems={() => ctx.labels.all}
          placeholder='Choose labels...'
          renderItem={(item) => (
            <WorkspaceItem item={item}>
              <Label value={item} />
            </WorkspaceItem>
          )}
          renderHeader={(group) => (
            <WorkspaceItemGroupHeader group={group}>
              <Label value={group.items[0]} />
            </WorkspaceItemGroupHeader>
          )}
          close={close}
        />
      )}
    >
      <MultiSelectFilterValue
        Icon={TagSolid}
        type='labels'
        ids={value?.value ?? null}
      />
    </FilterItem>
  )
}
