import { useMemo } from 'react'

import { useIndividualAndTeamSubscription } from '../../rpc'

export const useIsEnterpriseSubscription = () => {
  const query = useIndividualAndTeamSubscription()

  return useMemo(() => {
    if (!query.data) {
      return { ...query, isEnterprise: false }
    }

    return {
      ...query,
      isEnterprise:
        query.data?.team?.details?.customer?.metadata?.CustomerType ===
        'enterprise',
    }
  }, [query])
}
