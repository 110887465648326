import { type LoginProvider } from '@motion/rpc/types'
import { type StripeSubscriptionSchema } from '@motion/zod/client'

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'
import type { RootState } from './types'

/**
 *  @deprecated - Use @see {@link StripeSubscriptionSchema} from `@motion/zod/client` instead
 */
export type StripeSubscription = StripeSubscriptionSchema

export interface User {
  id: string
  displayName: string
  email: string
  lastActiveDate?: string
  loginProvider?: LoginProvider
  picture?: string
  dateCreated?: string
  stripeSubscription?: StripeSubscription
}

export const initialUserState: Readonly<User> = {
  displayName: '',
  email: '',
  id: '',
}

export const userSlice = createSlice({
  initialState: initialUserState,
  name: 'user',
  reducers: {
    reset: () => initialUserState,
    setStripeSubscription: (
      state: User,
      action: PayloadAction<StripeSubscription | undefined>
    ) => {
      state.stripeSubscription = action.payload
    },
    setUser: (state: User, action: PayloadAction<User | null | undefined>) => {
      state.displayName = action.payload?.displayName || ''
      state.loginProvider = action.payload?.loginProvider || state.loginProvider
      state.email = action.payload?.email || ''
      state.id = action.payload?.id || ''
      state.picture = action.payload?.picture || state.picture
    },
    setLastActiveDate: (state: User, action: PayloadAction<string>) => {
      state.lastActiveDate = action.payload
    },
  },
})

const userServiceHandler = getProxy('UserService')

export const getSubscription = createAsyncThunk<boolean | undefined, void>(
  'users/getSubscription',
  async () => {
    return await userServiceHandler.getSubscription()
  }
)

type UpgradeToAnnualPlanResult =
  | { error: string }
  | { subscription: StripeSubscription }

export const upgradeToAnnualPlan = createAsyncThunk<
  UpgradeToAnnualPlanResult,
  void
>('users/upgradeToAnnualPlan', async () => {
  return await userServiceHandler.upgradeToAnnualPlan()
})

export const { setLastActiveDate, setStripeSubscription, setUser, reset } =
  userSlice.actions

export const selectDisplayName = (state: RootState) => state.user.displayName
export const selectEmail = (state: RootState) => state.user.email
export const selectStripeSubscription = (state: RootState) =>
  state.user.stripeSubscription

/**
 * @deprecated use `useAuthenticatedUser` instead
 */
export const selectUser = (state: RootState) => state.user

export const userReducer = userSlice.reducer
