import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { type NumberCustomField } from '@motion/ui-logic'

import {
  CustomField,
  type CustomFieldInputProps,
} from '~/areas/project-management/custom-fields'
import { type ControlledCustomFieldProps } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { type CustomFieldWithValue } from '~/global/proxies'
import { type FC } from 'react'

export const ControlledNumber: FC<
  ControlledCustomFieldProps<NumberCustomField>
> = ({ value, onSubmit: submitHandler, customField, disabled }) => {
  const [internalValue, setInternalValue] = useDependantState<number | null>(
    () => value,
    [value]
  )

  const onChange = (v: number | null) => {
    setInternalValue(v)
  }

  const onSubmit = useDebouncedCallback(async () => {
    if (internalValue === value) {
      return
    }

    try {
      await submitHandler(internalValue)
    } catch (error) {
      setInternalValue(value)
    }
  }, 600)

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key } = evt

    if (key === 'Enter') {
      return onSubmit()
    }
  }

  return (
    <CustomField.Number
      name={customField.definition.name}
      format={
        (customField as Extract<CustomFieldWithValue, { type: 'number' }>)
          .definition.metadata.format
      }
      value={internalValue}
      onChange={onChange}
      onBlur={onSubmit}
      disabled={disabled}
      onKeyDown={handleKeyDown}
      size='xsmall'
    />
  )
}
