import { classed } from '@motion/theme'
import { formatToReadableWeekDayMonth } from '@motion/ui-logic'

import { DateTime } from 'luxon'

import { useScrollPosition } from '../context'
import { useScreenValues } from '../hooks'
import { ZIndexMap } from '../shared-constants'

export const TodayIndicator = () => {
  const [scrollPosition] = useScrollPosition()
  const { positionOffset } = useScreenValues()
  const todayXValue = positionOffset + scrollPosition

  const cursorRelativePosY = 6

  const cursorStyles = {
    transform: `translate3D(${todayXValue}px,${cursorRelativePosY}px,0)`,
    zIndex: ZIndexMap.dayIndicator + 1,
  }

  return (
    <>
      <Container
        className='text-semantic-primary-text-onDark text-xs whitespace-nowrap flex flex-col w-auto'
        style={cursorStyles}
      >
        <div className='-translate-x-1/2 -translate-y-1/4 text-2xs bg-semantic-primary-bg-strong-default px-0.5 flex items-center justify-center rounded'>
          {formatToReadableWeekDayMonth(DateTime.now())}
        </div>
        <div className='w-px h-1 bg-semantic-primary-border-default -translate-x-1/2 -translate-y-1' />
      </Container>
    </>
  )
}

const Container = classed(
  'div',
  'absolute top-4 pointer-events-none transition-opacity -translate-x-1/2'
)
