import type { RecursiveFolderItemSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { LexoRank } from 'lexorank'
import { useCallback } from 'react'

import { useFindFolderItem } from './use-find-folder-item'

export const useMoveProjectInFolder = () => {
  const updateProject = useProjectUpdater()

  return useCallback(
    (projectId: string, folderId: string, folderItemOrder?: string) =>
      updateProject(projectId, {
        folderId,
        folderItemOrder,
      }),
    [updateProject]
  )
}

export const useMoveFolderItemInDirection = () => {
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()
  const moveProjectInFolder = useMoveProjectInFolder()
  const findFolderItem = useFindFolderItem()

  const moveItem = useCallback(
    (item: RecursiveFolderItemSchema, lexorank: LexoRank) => {
      const order = lexorank.toString()

      if (item.itemType === 'FOLDER') {
        return updateItemInFolder({
          itemId: item.id,
          order,
        })
      }

      if (item.itemType === 'PROJECT') {
        if (!item.folderId) return

        return moveProjectInFolder(item.itemId, item.folderId, order)
      }
    },
    [moveProjectInFolder, updateItemInFolder]
  )

  return useCallback(
    (itemId: string, moveBy: -1 | 1) => {
      const searchResult = findFolderItem((item) => item.id === itemId)

      if (!searchResult) return

      const [item, parent] = searchResult

      const lastIndex = parent.items.length - 1
      const index = parent.items.findIndex(({ id }) => item.id === id)
      const newIndex = index + moveBy

      if (index === -1) return

      if (newIndex < 0) {
        throw new Error('Cannot move top-most item up')
      }

      if (newIndex > lastIndex) {
        throw new Error('Cannot move bottom-most item down')
      }

      const projectedSibling = parent.items[newIndex]
      const projectedFollowingSibling = parent.items[newIndex + moveBy]

      if (!!projectedSibling && !projectedFollowingSibling) {
        // Moving to the top or bottom
        const currentOrder = LexoRank.parse(projectedSibling.order)

        let newOrder!: LexoRank

        if (moveBy === 1) {
          newOrder = currentOrder.genNext()
        } else {
          newOrder = currentOrder.genPrev()
        }

        return moveItem(item, newOrder)
      }

      if (!!projectedFollowingSibling && !!projectedSibling) {
        // Placed in-between two existing items
        const prevOrder = LexoRank.parse(projectedFollowingSibling.order)
        const nextOrder = LexoRank.parse(projectedSibling.order)

        const newOrder = prevOrder.between(nextOrder)

        return moveItem(item, newOrder)
      }
    },
    [findFolderItem, moveItem]
  )
}
