import { type ProjectSchema } from '@motion/rpc-types'
import { byProperty, cascade, Compare } from '@motion/utils/array'
import { cloneDeep } from '@motion/utils/core'

import { DateTime } from 'luxon'

import { projectHasDates } from './date-array-utils'
import { DEFAULT_PROJECT_LENGTH_IN_WEEKS } from './project'

import { type ProjectWithDates } from '../row/resizeable-project-item/stages-bar/types'

/**
 * @deprecated
 * Given a list of projects, we need to stack them based on the start and end date
 * We return an array of arrays, where each array is a row of projects
 * A row of projects is a list of projects that do not overlap with each other
 * When a project overlaps with another project, we create a new row
 *
 * @param rowProjects - An array of projects
 * @returns - Array of arrays of projects (where each array is a row of non-overlapping projects)
 */

export function organizeProjectsIntoRowsLegacy<T extends ProjectSchema>(
  rowProjects: T[]
): T[][] {
  // Add project map (so you can always place the original project in the row)
  const projectsMap = new Map<string, T>()
  rowProjects.forEach((project) => {
    projectsMap.set(project.id, project)
  })

  // Sort the projects by start date, filter out items with no start and end date
  const cleanedProjects: ProjectWithDates[] = rowProjects
    .filter((project) => project.startDate || project.dueDate)
    .map((p) => {
      const project = cloneDeep(p)

      // If it has no start date, set it to -DEFAULT_PROJECT_LENGTH_IN_WEEKS week from the end date
      if (project.startDate == null && project.dueDate != null) {
        project.startDate = DateTime.fromISO(project.dueDate)
          .minus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
          .toISO()
      }

      // If it has no end date, set it to +DEFAULT_PROJECT_LENGTH_IN_WEEKS week from the start date
      if (project.dueDate == null && project.startDate != null) {
        project.dueDate = DateTime.fromISO(project.startDate)
          .plus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
          .toISO()
      }

      if (projectHasDates(project)) {
        return project
      }

      return null
    })
    .filter(Boolean)
    .sort(
      cascade(
        byProperty('startDate', Compare.string),
        byProperty('dueDate', Compare.string)
      )
    )

  const rows: ProjectWithDates[][] = []

  for (const item of cleanedProjects) {
    let placed = false
    for (const row of rows) {
      // Check for collision in the current row
      const hasCollision = row.some(
        (rowItem) =>
          DateTime.fromISO(item.startDate).endOf('day').toISO() <
            DateTime.fromISO(rowItem.dueDate)
              .plus({ days: 1 })
              .startOf('day')
              .toISO() &&
          DateTime.fromISO(item.dueDate).startOf('day').toISO() >
            DateTime.fromISO(rowItem.startDate)
              .minus({ days: 1 })
              .endOf('day')
              .toISO()
      )

      if (!hasCollision && item) {
        row.push(item)
        placed = true
        break
      }
    }

    // If item could not be placed in any existing row, create a new row
    if (!placed && item) {
      rows.push([item])
    }
  }

  // Go through the rows and replace the projects with the original projects
  return rows.map((row) =>
    row.map((project) => projectsMap.get(project.id) as T)
  )
}

export function organizeProjectsIntoRows<T extends ProjectSchema>(
  rowProjects: T[]
): T[][] {
  const projects = [...rowProjects].sort((a, b) => {
    const startA =
      a.startDate ??
      (a.dueDate &&
        DateTime.fromISO(a.dueDate)
          .minus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
          .toISODate())

    const startB =
      b.startDate ??
      (b.dueDate &&
        DateTime.fromISO(b.dueDate)
          .minus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
          .toISODate())

    if (!startA && !startB) return 0

    if (!startA) return 1

    if (!startB) return -1

    return startA > startB ? 1 : -1
  })

  const rows: (string | null)[] = []
  const stacked: T[][] = []

  for (const project of projects) {
    let start = project.startDate
    let end = project.dueDate
    if (!start && !end) continue
    if (!start) {
      // End is for sure defined if we get here but TS doesn't see that.
      start = DateTime.fromISO(end as string)
        .minus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
        .toISODate()
    }
    if (!end) {
      end = DateTime.fromISO(start)
        .plus({ weeks: DEFAULT_PROJECT_LENGTH_IN_WEEKS })
        .toISODate()
    }

    let placed = false
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]

      // Have to do it this way because in JS null < "string" is true.
      placed = row == null ? true : row < start

      if (placed) {
        rows[i] = end
        stacked[i].push(project)
        break
      }
    }

    if (!placed) {
      rows[rows.length] = end
      stacked.push([project])
    }
  }

  return stacked
}
