import { isFlowProject } from '@motion/ui-logic/pm/project'
import { shallowMatch } from '@motion/utils/object'
import {
  type FolderSchema,
  type ProjectSchema,
  type VersionedViewV2,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { getAllIdsOfTypeInFolder } from '~/areas/folders/utils'
import {
  type AppWorkspaceDataContext,
  FilteredWorkspaceContext,
  type FilteredWorkspaceContextProps,
} from '~/global/contexts'
import { type ReactNode } from 'react'

import { usePageData } from '../routes/hooks'
import { type PageParams } from '../routes/types'
import { getDefaultView } from '../views/defaults'

type ConnectedPageContextProps = {
  children: ReactNode
  name?: string
}

export const ConnectedPageContext = (props: ConnectedPageContextProps) => {
  const route = usePageData()

  return (
    <FilteredWorkspaceContext
      name={props.name}
      refreshKey={route.stateKey}
      workspaces={selectWorkspaces(route)}
      stageDefinitions={selectStageDefinitions(route)}
      projects={selectProjects(route)}
      views={selectViews(route)}
      folders={selectFolders(route)}
    >
      {props.children}
    </FilteredWorkspaceContext>
  )
}

const selectWorkspaces =
  (data: PageParams) => (workspaces: WorkspaceSchema[]) => {
    if (data.lock.workspaceId) {
      return workspaces.filter((x) => x.id === data.lock.workspaceId)
    }
    return workspaces
  }

const selectStageDefinitions =
  (data: PageParams): FilteredWorkspaceContextProps['stageDefinitions'] =>
  (stageDefinitions, ctx) => {
    if (data.lock.projectId) {
      const project = ctx.projects.all.find((x) => x.id === data.lock.projectId)
      if (project == null || project.projectDefinitionId == null) {
        return []
      }

      return stageDefinitions.filter(
        (s) => s.projectDefinitionId === project.projectDefinitionId
      )
    }
    return stageDefinitions
  }

const selectViews =
  (data: PageParams) =>
  (views: VersionedViewV2[], ctx: AppWorkspaceDataContext) => {
    const filter: (v: VersionedViewV2) => boolean = (v) => {
      if (data.view.type === 'unknown') return false
      return shallowMatch(v, data.view as VersionedViewV2)
    }

    const defaultView = getDefaultView(data.page, {
      isFlowProject:
        data.page === 'project' &&
        data.lock.projectId != null &&
        isFlowProject(ctx.projects.byId[data.lock.projectId]),
    })

    return [defaultView as VersionedViewV2].concat(...views.filter(filter))
  }

const selectProjects =
  (data: PageParams) =>
  (projects: ProjectSchema[], ctx: AppWorkspaceDataContext) => {
    if (data.lock.projectId) {
      return projects.filter((x) => x.id === data.lock.projectId)
    }
    if (data.lock.folderId && ctx.systemFolders.byId['workspaces']) {
      const folderIds = getAllIdsOfTypeInFolder(
        ctx.systemFolders.byId['workspaces'].items,
        data.lock.folderId,
        'FOLDER'
      )
      return projects.filter((x) =>
        x.folderId ? folderIds.includes(x.folderId) : false
      )
    }
    return projects
  }

const selectFolders =
  (data: PageParams) =>
  (folders: FolderSchema[], ctx: AppWorkspaceDataContext) => {
    if (data.lock.folderId && ctx.systemFolders.byId['workspaces']) {
      const folderIds = getAllIdsOfTypeInFolder(
        ctx.systemFolders.byId['workspaces'].items,
        data.lock.folderId,
        'FOLDER'
      )
      return folders.filter((x) => folderIds.includes(x.id))
    }
    return folders
  }
