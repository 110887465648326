/* eslint-disable react-refresh/only-export-components */
import { useInterval } from '@motion/react-core/hooks'
import { makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { stats } from '@motion/web-common/performance'

import { DateTime } from 'luxon'
import React, { type ReactNode, useState } from 'react'

const log = makeLog('upgrade-check')

const MS_PER_MIN = 60 * 1000

const VERSION_CHECK_INTERVAL = 60 * MS_PER_MIN

export type VersionCheckResult =
  | {
      upgradeAvailable: true
      daysBehind: number
    }
  | {
      upgradeAvailable: false
    }

export const VersionCheckContext = React.createContext<VersionCheckResult>({
  upgradeAvailable: false,
})

const daysBehindBucket = (value: number) => {
  if (value <= 1) return 'day'
  if (value <= 7) return 'week'
  if (value <= 30) return 'month'
  return 'over_one_month'
}

export const VersionCheckProvider = ({ children }: { children: ReactNode }) => {
  const [needsUpdate, setNeedsUpdate] = useState<VersionCheckResult>({
    upgradeAvailable: false,
  })

  useInterval(async () => {
    if (needsUpdate) return
    const requiresUpdate = await checkIfUpgradeRequired()
    if (requiresUpdate == null) return

    stats.increment(
      'ota_check',
      1,
      [
        `upgrade_available:${requiresUpdate.upgradeAvailable}`,
        ...(requiresUpdate.upgradeAvailable
          ? [`days_behind:${daysBehindBucket(requiresUpdate.daysBehind)}`]
          : []),
      ].filter(Boolean)
    )
    setNeedsUpdate(requiresUpdate)
  }, VERSION_CHECK_INTERVAL)

  return (
    <VersionCheckContext.Provider value={needsUpdate}>
      {children}
    </VersionCheckContext.Provider>
  )
}

async function checkIfUpgradeRequired(): Promise<VersionCheckResult | null> {
  log('poll')

  // OTA refresh check
  const res = await fetch('/web/ota.json').catch((err) => {
    log.error('failed to check ota', err)
    return null
  })
  // Failed to fetch
  if (res == null) return null

  if (!res.ok) {
    log.error('failed to check ota', res.status, res.statusText)
    stats.increment('ota_check.failed')
    Sentry.captureException(new Error('Failed to retrieve ota.json'), {
      tags: {
        position: 'ota',
      },
      extra: {
        status: res.status,
      },
      fingerprint: ['ota', 'request_failed'],
    })
    return null
  }

  try {
    const raw = await res.text()
    if (raw[0] !== '{') {
      stats.increment('ota_check.failed', 1, ['error:non_json'])
      Sentry.captureException(new Error('Invalid ota.json'), {
        tags: {
          position: 'ota',
        },
        extra: {
          length: raw.length,
          contentType: res.headers.get('Content-Type'),
        },
        fingerprint: ['ota', 'non_json'],
      })
      return null
    }

    const { version } = JSON.parse(raw) as {
      timestamp: number
      version: string
    }

    if (!version || version === __SENTRY_RELEASE__) {
      return { upgradeAvailable: false }
    }

    log('ota version mismatch', {
      current: __SENTRY_RELEASE__,
      latest: version,
    })

    const dayVersion = version.split('_')[1]
    const newVersionDay = DateTime.fromFormat(dayVersion, 'yyyyMMdd')
    const currentVersionDay = DateTime.fromFormat(
      __SENTRY_RELEASE__.split('_')[1],
      'yyyyMMdd'
    )

    const daysDiff = newVersionDay.diff(currentVersionDay, 'days').days
    return { daysBehind: Math.max(0, daysDiff), upgradeAvailable: true }
  } catch (ex) {
    stats.increment('ota_check.failed', 1, ['error:unhandled'])
    Sentry.captureException(
      new Error('Failed to parse ota.json', { cause: ex }),
      {
        tags: {
          position: 'ota',
        },
        extra: {
          contentType: res.headers.get('Content-Type'),
        },
        fingerprint: ['ota', 'unknown'],
      }
    )
  }
  return null
}
