import { HttpValidationError } from '@motion/rpc'
import { showToast } from '@motion/ui/base'

import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { showErrorToast } from '~/global/toasts'

import { getCustomFieldValidationErrorMessage } from '../../project-management/custom-fields/errors'

export function handleTaskUpdateErrors(
  err: Error,
  customFields: WorkspaceCustomField[]
) {
  if (err instanceof HttpValidationError) {
    const errorMessages = err.body.errors.reduce<string[]>(
      (acc, validationError) => {
        const customFieldErrorMsg = getCustomFieldValidationErrorMessage(
          validationError,
          customFields
        )

        if (customFieldErrorMsg) {
          return [...acc, customFieldErrorMsg]
        }
        return acc
      },
      []
    )

    if (errorMessages.length === 0) {
      return showErrorToast(err, 'There was an error in updating your task.')
    }

    const ToastErrorBody = (
      <div className='flex flex-col gap-2'>
        <strong>Fix the following errors to save:</strong>
        <ol className='list-inside list-decimal'>
          {errorMessages.map((m) => (
            <li key={m}>{m}</li>
          ))}
        </ol>
      </div>
    )

    return showToast('error', ToastErrorBody)
  }

  showErrorToast(err, 'There was an error in updating your task.')
}
