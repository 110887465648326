import { type ProjectSchema } from '@motion/zod/client'

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { clearProjectForParams } from '../modals/project-modal/utils'
import { clearSetupProjectParams } from '../modals/setup-project-modal/utils'

export const useRedirectToProject = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useCallback(
    (projectId: ProjectSchema['id']) => {
      if (searchParams.size === 1 && searchParams.get('project') === projectId)
        return

      setSearchParams((prev) => {
        clearSetupProjectParams(prev)
        clearProjectForParams(prev)
        prev.delete('flowTemplateId')
        prev.delete('activeTab')

        if (prev.get('project') !== projectId) {
          prev.set('project', projectId)
        }
        return prev
      })
    },
    [searchParams, setSearchParams]
  )
}
