import { API } from '@motion/rpc'
import { type Calendar, type Contact } from '@motion/rpc/types'
import { client } from '@motion/web-common/rpc'

import {
  createEntityAdapter,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import {
  createCalendar,
  updateCalendar,
  updateSelectedTeammateContacts,
} from './calendar-list-thunks'
import { type CalendarListPickerModalProps } from './calendar-list-types'

import { deleteEmailAccount } from '../email-accounts/email-accounts-thunks'
import { type LoadingState } from '../projectManagementSlice'
import { type RootState } from '../store'

export const calendarsAdapter = createEntityAdapter<Calendar>({
  selectId: (c) => c.id,
})

export const initialCalendarListPickerModalState: CalendarListPickerModalProps =
  {
    section: 'mine',
    visible: false,
  }

export type CalendarListState = {
  /**
   * Tracks the loading state of createCalendar calls by the calendar provider
   * ID
   */
  createCalendarLoadingState: Record<string, LoadingState>
  /**
   * Controls the visibility of the calendar list picker modal
   */
  calendarListPickerModal: CalendarListPickerModalProps

  /**
   * The selected teammate contacts that were selected on the "Meet with
   * teammates" search box, above the calendar. This currently can control
   * which calendars are visible, as well as a shortcut for adding guests to
   * an event.
   */
  selectedTeammateContacts: Contact[]
}

export const initialCalendarListState: CalendarListState = {
  calendarListPickerModal: initialCalendarListPickerModalState,
  createCalendarLoadingState: {},
  selectedTeammateContacts: [],
}

export const calendarListSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      updateSelectedTeammateContacts.fulfilled,
      (state, action) => {
        client.invalidateQueries({
          queryKey: API.calendars.queryKeys.root,
          refetchType: 'all',
        })
      }
    )

    builder.addCase(updateCalendar.fulfilled, (state, action) => {
      client.invalidateQueries({
        queryKey: API.calendars.queryKeys.root,
        refetchType: 'all',
      })
    })

    builder.addCase(deleteEmailAccount.fulfilled, (state, action) => {
      client.invalidateQueries({
        queryKey: API.calendars.queryKeys.root,
        refetchType: 'all',
      })
    })

    builder.addCase(createCalendar.pending, (state, action) => {
      const key = action.meta.arg.providerId
      state.createCalendarLoadingState[key] = 'loading'
    })

    builder.addCase(createCalendar.fulfilled, (state, action) => {
      const key = action.meta.arg.providerId
      state.createCalendarLoadingState[key] = 'loaded'

      client.invalidateQueries({
        queryKey: API.calendars.queryKeys.root,
        refetchType: 'all',
      })
    })

    builder.addCase(createCalendar.rejected, (state, action) => {
      const key = action.meta.arg.providerId
      state.createCalendarLoadingState[key] = 'error'
    })
  },
  initialState: initialCalendarListState,
  name: 'calendarList',
  reducers: {
    /**
     * Toggle visibility of the Calendar List Picker Modal
     * @param state
     * @param action
     */
    setCalendarListPickerModal: (
      state: CalendarListState,
      action: PayloadAction<CalendarListPickerModalProps>
    ) => {
      state.calendarListPickerModal = {
        ...state.calendarListPickerModal,
        ...action.payload,
      }
    },
    reset: () => initialCalendarListState,
    setSelectedTeammateContacts: (
      state: CalendarListState,
      action: PayloadAction<Contact[]>
    ) => {
      state.selectedTeammateContacts = action.payload
    },
    removeTeammateContact: (
      state: CalendarListState,
      action: PayloadAction<string>
    ) => {
      state.selectedTeammateContacts = state.selectedTeammateContacts.filter(
        (contact) => contact.email !== action.payload
      )
    },
  },
})

export const {
  reset,
  setCalendarListPickerModal,
  setSelectedTeammateContacts,
  removeTeammateContact,
} = calendarListSlice.actions

export const selectCalendarListPickerModal = (state: RootState) =>
  state.calendarList.calendarListPickerModal

export const calendarListReducer = calendarListSlice.reducer
