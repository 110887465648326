import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { type LegacyProjectTemplateSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'

export const useLegacyProjectTemplateById = (
  projectTemplateId: LegacyProjectTemplateSchema['id'] | null | undefined
): LegacyProjectTemplateSchema | null => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    projectTemplateId == null
      ? null
      : state.legacyProjectTemplates.byId[projectTemplateId]
  )
}
