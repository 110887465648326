import { type CommentSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import {
  type ActivityEntryViewSchema,
  type ActivityRenderConfig,
} from '../types'

export type GroupActivityItem =
  | {
      schema: CommentSchema
      config: null
    }
  | {
      schema: ActivityEntryViewSchema
      config: ActivityRenderConfig | null
    }

/**
 * Group activity items if they happen within X minutes of each other.
 * The heuristic is that if the time difference between the first and last item in a group is less than X minutes, they are grouped together.
 *
 * Conditions:
 * 1. Comment items are always in a new group.
 * 2. Activity made by a different user is always in a new group.
 * @param activityItems
 * @param minutes The time frame in minutes
 * @returns A 2D array of grouped activity items
 */
export function groupActivity(activityItems: GroupActivityItem[], minutes = 5) {
  return activityItems.reduce<GroupActivityItem[][]>((result, item) => {
    // Comment items are always in a new group
    if (item.schema.type === 'COMMENT') {
      result.push([item])
      return result
    }

    const lastGroup = result[result.length - 1]

    // If there is no last group, create a new group
    if (!lastGroup) {
      result.push([item])
      return result
    }

    const firstItem = lastGroup[0]
    const lastItem = lastGroup[lastGroup.length - 1]

    // If last item is a comment or is made by a different user, create a new group
    if (
      lastItem.schema.type === 'COMMENT' ||
      lastItem.schema.sourceId !== item.schema.sourceId
    ) {
      result.push([item])
      return result
    }

    const currentTime = DateTime.fromISO(item.schema.createdTime)
    const firstItemTime = DateTime.fromISO(firstItem.schema.createdTime)

    // Check if the first item is within the time frame
    if (
      Math.abs(firstItemTime.diff(currentTime, 'minutes').minutes) < minutes
    ) {
      lastGroup.push(item)
    } else {
      result.push([item])
    }

    return result
  }, [])
}
