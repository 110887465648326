import { classed } from '@motion/theme'

export const Container = classed(
  'div',
  'flex flex-col gap-6 pt-3 pb-1 text-semantic-neutral-text-default'
)

export const BlockerRow = classed('div', 'flex justify-between items-center')

export const BlockersInfoContainer = classed(
  'div',
  'flex flex-col gap-3 pb-1 max-h-[150px] overflow-y-auto'
)

export const QuestionContainer = classed('div', 'flex flex-col gap-4')

export const Question = classed('p', 'text-sm')

export const LabelContainer = classed(
  'div',
  'flex flex-col gap-1 text-semantic-neutral-text-default text-sm'
)
