import { usePrevious } from '@motion/react-core/hooks'
import { type NormalTaskSchema, type ProjectSchema } from '@motion/rpc-types'

import { UncontrolledCollapsableContainer } from '~/areas/task-project/components/collapsable-container'
import { useEffect, useState } from 'react'

import { AttachmentsGroupsTitle } from './attachment-groups-title'

export type AttachmentGroupsCollapsableWrapperProps = {
  task?: NormalTaskSchema
  project?: ProjectSchema
  attachmentCount: number
  activeUploadCount?: number
  searchQuery?: string
  children: React.ReactNode
}

export function AttachmentGroupsCollapsableWrapper({
  task,
  project,
  attachmentCount,
  activeUploadCount = 0,
  searchQuery,
  children,
}: AttachmentGroupsCollapsableWrapperProps) {
  const [expanded, setExpanded] = useState(
    attachmentCount > 0 || activeUploadCount > 0
  )

  const prevSearchQuery = usePrevious(searchQuery)

  useEffect(() => {
    // Expand the collapsable list when the search query changes
    // and there are matching attachments
    if (searchQuery !== prevSearchQuery && attachmentCount > 0) {
      setExpanded(true)
    }
  }, [searchQuery, prevSearchQuery, attachmentCount])

  useEffect(() => {
    if (activeUploadCount && activeUploadCount > 0) {
      setExpanded(true)
    }
  }, [activeUploadCount])

  const handleToggle = () => setExpanded((prev) => !prev)

  return (
    <UncontrolledCollapsableContainer
      title={
        <AttachmentsGroupsTitle
          task={task}
          project={project}
          attachmentCount={attachmentCount}
        />
      }
      chevronPosition='right'
      expanded={expanded}
      toggle={handleToggle}
    >
      <div className='pl-3 pb-3'>{children}</div>
    </UncontrolledCollapsableContainer>
  )
}
