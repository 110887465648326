import { classed } from '@motion/theme'
import { templateStr } from '@motion/ui-logic'
import {
  type CalendarStartDay,
  type CalendarView,
} from '@motion/ui-logic/calendar'

import { DateTime } from 'luxon'

interface DateTitleProps {
  baseDate: DateTime
  timezone: string
  view: CalendarView
  calendarStartDay: CalendarStartDay
}

export const DateTitle = (props: DateTitleProps) => {
  const { baseDate, timezone, view, calendarStartDay } = props

  return (
    <span className='text-semantic-neutral-text-default text-[20px] leading-6'>
      {view === 'daily'
        ? baseDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        : getWeekLabel(baseDate, calendarStartDay, timezone)}
    </span>
  )
}

const getWeekLabel = (
  date: DateTime,
  calendarStartDay: CalendarStartDay,
  timezone: string
) => {
  const dateTz = date.setZone(timezone)
  // Luxon start of week always start with Monday
  const startOfWeek = dateTz
    .startOf('week')
    .minus({ day: calendarStartDay === 'sunday' ? 1 : 0 })
  const endOfWeek = dateTz
    .endOf('week')
    .minus({ day: calendarStartDay === 'sunday' ? 1 : 0 })

  const startMonth = startOfWeek.get('month')
  const startYear = startOfWeek.get('year')
  const endMonth = endOfWeek.get('month')
  const endYear = endOfWeek.get('year')

  if (startMonth === endMonth) {
    return templateStr('{{month}} {{year}}', {
      month: <Month>{dateTz.toFormat('LLL')}</Month>,
      year: dateTz.toFormat('yyyy'),
    })
  }
  if (startYear === endYear) {
    return templateStr('{{startMonth}} - {{endMonth}} {{year}}', {
      startMonth: <Month>{startOfWeek.toFormat('LLL')}</Month>,
      endMonth: <Month>{endOfWeek.toFormat('LLL')}</Month>,
      year: endOfWeek.toFormat('yyyy'),
    })
  }

  return templateStr(
    '{{startMonth}} {{startYear}} - {{endMonth}} {{endYear}}',
    {
      startMonth: <Month>{startOfWeek.toFormat('LLL')}</Month>,
      startYear: startOfWeek.toFormat('yyyy'),
      endMonth: <Month>{endOfWeek.toFormat('LLL')}</Month>,
      endYear: endOfWeek.toFormat('yyyy'),
    }
  )
}

const Month = classed('span', 'font-bold')
