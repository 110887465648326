import {
  ArrowLeftSolid,
  ArrowRightSolid,
  DotsHorizontalSolid,
  EyeOffSolid,
} from '@motion/icons'
import { ActionList, IconButton, PopoverTrigger } from '@motion/ui/base'

export type DotsMenuProps = {
  moveToLeft?: () => void
  moveToRight?: () => void
  hide?: () => void
}

export const DotsMenu = ({ moveToLeft, moveToRight, hide }: DotsMenuProps) => {
  if (moveToLeft == null && moveToRight == null && hide == null) return null

  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <ActionList
          onActionAnyItem={close}
          items={[
            {
              prefix: <ArrowLeftSolid />,
              content: 'Move left',
              onAction: () => moveToLeft?.(),
              disabled: moveToLeft == null,
            },
            {
              prefix: <ArrowRightSolid />,
              content: 'Move right',
              onAction: () => moveToRight?.(),
              disabled: moveToRight == null,
            },
            {
              prefix: <EyeOffSolid />,
              content: 'Hide column',
              onAction: () => hide?.(),
              disabled: hide == null,
            },
          ]}
        />
      )}
    >
      <div className='invisible group-hover/headercell:visible'>
        <IconButton
          icon={DotsHorizontalSolid}
          size='xsmall'
          variant='muted'
          sentiment='neutral'
          onPointerDown={(e) => {
            e.stopPropagation()
          }}
        />
      </div>
    </PopoverTrigger>
  )
}
