import { getDateButtonText } from '@motion/ui-logic/pm/task'

import { forwardRef, type ReactNode } from 'react'

import {
  ModalFieldButton,
  type ModalFieldButtonProps,
} from './modal-field-button'

type DateTriggerButtonProps = {
  icon: ReactNode
  label: string
  date: string | null
  disabled?: boolean
  placeholder: string
  prefix?: string
  endButton?: ReactNode
} & Pick<ModalFieldButtonProps, 'onClear' | 'clearTooltipContent' | 'sentiment'>

export const DateTriggerButton = forwardRef<
  HTMLLabelElement,
  DateTriggerButtonProps
>((props, ref) => {
  const { icon, date, placeholder, prefix, endButton, ...rest } = props

  const dateButtonString = getDateButtonText(date, { prefix, placeholder })

  return (
    <ModalFieldButton ref={ref} {...rest}>
      {icon}
      {dateButtonString}
      {endButton}
    </ModalFieldButton>
  )
})
DateTriggerButton.displayName = 'DateTriggerButton'
