import { QuestionMarkCircleSolid, UsersSolid } from '@motion/icons'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import logo from '../../../../assets/logo_1024.webp'
import { useToggleIntercom } from '../../../../utils/toggleIntercom'

type OnboardingHeaderProps = {
  rightEl?: React.ReactElement
}

export function OnboardingHeader({ rightEl }: OnboardingHeaderProps) {
  const toggleIntercom = useToggleIntercom()
  const user = useAuthenticatedUser()

  return (
    <div className='bg-light-100 dark:bg-dark-1200 dark:border-dark-1000 flex items-center justify-between border-b px-6 py-4'>
      <img src={logo} alt='Motion Logo' className='h-9 w-9' />

      <div>
        <div className='flex items-center gap-2'>
          <div>{rightEl}</div>
          <button
            className='flex items-center gap-2 dark:text-dark-500 dark:hover:text-dark-100 hover:border-primary-400 focus:dark:text-dark-100 border-transparent'
            onClick={() => toggleIntercom()}
          >
            <QuestionMarkCircleSolid className='size-4' />
            <p className='text-light-1100 font-semibold'>Support</p>
          </button>

          <div className='w-2' />

          {!!user && (
            <a
              className='flex items-center gap-2 dark:text-dark-500 dark:hover:text-dark-100 hover:border-primary-400 focus:dark:text-dark-100 border-transparent'
              href={`${__FRONTEND_HOST__}/account?source=onboarding`}
            >
              <UsersSolid className='size-4' />
              <p className='text-light-1100 font-semibold'>Account</p>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
