import {
  type RelativeDateFilterSchema,
  type ViewDateFilterSchema,
} from '@motion/zod/client'

import { DateTime, Duration } from 'luxon'

const SINGLE_OPS = ['equals', 'lt', 'gte']
const MULTIPLE_OPS = ['range']

export const changeOperation = (
  value: ViewDateFilterSchema,
  op: string
): ViewDateFilterSchema | null => {
  const prevOp = value.operator
  if (prevOp === op) return value
  if (value.operator === 'defined' || value.operator === 'empty') return value

  // TODO: Implement relative ranges
  if (value.operator === 'relative' || value.operator === 'defined-relative')
    return value

  if (
    includesBoth(SINGLE_OPS, prevOp, op) ||
    includesBoth(MULTIPLE_OPS, prevOp, op)
  ) {
    return { ...value, operator: op } as ViewDateFilterSchema
  }

  // single -> range
  if (SINGLE_OPS.includes(prevOp) && typeof value.value === 'string') {
    const date = DateTime.fromISO(value.value)
    return {
      ...value,
      operator: op,
      value: {
        from: date.startOf('day').toISO(),
        to: date.endOf('day').plus({ day: 1 }).toISO(),
      },
    } as ViewDateFilterSchema
  }
  // range -> single
  if (MULTIPLE_OPS.includes(prevOp) && typeof value.value === 'object') {
    return {
      ...value,
      operator: op,
      value: value.value.from,
    } as ViewDateFilterSchema
  }
  return null
}

function includesBoth<T>(arr: T[], first: T, second: T) {
  return arr.includes(first) && arr.includes(second)
}

export type RelativeDateInfo = {
  direction: 'next' | 'last'
  unit: 'days' | 'weeks' | 'months'
  value: string
}
export function parseRelativeDate(
  value: RelativeDateFilterSchema
): RelativeDateInfo {
  const duration = Duration.fromISO(value.duration)
  const direction = duration.as('second') >= 0 ? 'next' : 'last'
  const unit = findDurationUnit(duration)
  return {
    direction,
    unit,
    value: String(Math.abs(duration.as(unit))),
  }
}

function findDurationUnit(duration: Duration) {
  if (duration.months > 0) return 'months'
  if (duration.weeks > 0) return 'weeks'
  return 'days'
}
