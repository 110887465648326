import { useFolders } from '~/global/rpc/folders'
import { useCallback } from 'react'

import {
  findFolderItem,
  type FindFolderItemCallback,
  type FindFolderItemReturn,
} from '../utils'

export const useFindFolderItem = () => {
  const { data: folders } = useFolders()

  return useCallback(
    (cb: FindFolderItemCallback): FindFolderItemReturn | null => {
      if (!folders?.models.systemFolders.workspaces) return null

      return findFolderItem(folders.models.systemFolders.workspaces, cb)
    },
    [folders]
  )
}
