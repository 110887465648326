import { ControlledRoleAssigneeField } from './fields'

import { useSetupProjectForm } from '../../hooks'

export const RolesStepContent = () => {
  const { form } = useSetupProjectForm()
  const roles = form.watch('roleAssignees')

  return (
    <div className='flex flex-col gap-5'>
      {roles.map((role, i) => (
        <ControlledRoleAssigneeField
          key={role.variableId}
          rolePath={`roleAssignees.${i}`}
        />
      ))}
    </div>
  )
}
