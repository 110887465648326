import { useCallback, useState } from 'react'

type Setter<T> = (prev: T) => T
type ValueOrFn<T> = T | Setter<T>

interface UseObjectSet<T> {
  /**
   * Set a static value
   * @param key the property to set
   * @param value the value to set
   */
  <K extends keyof T>(key: K, value: T[K]): void

  /**
   * Sets the value of the property given a function with the previous value
   * @param key the property to set
   * @param fn a function that is provided the previous value
   */
  <K extends keyof T>(key: K, fn: Setter<T[K]>): void

  /**
   * Replaces the current state
   * @param value the new state value to use
   */
  (value: T): void

  /**
   * Sets the value to the result of the function
   * @param fn function called with the previous value. The return value replaces the current value
   */
  (fn: Setter<T>): void

  /**
   * Creates a lens for the property
   * @param key the property to lens
   * @returns a function that takes either the new value, or a function that accepts the previous value and returns the new value
   */
  <K extends keyof T>(key: K): (value: ValueOrFn<T[K]>) => void

  // create set lens that takes fn
  <K extends keyof T>(key: K): (fn: Setter<T[K]>) => void
}

export type UseObjectResult<T extends object> = [T, UseObjectSet<T>]

/**
 * Create a variant of useState that is designed around manipulating objects
 * @param initialValue the initial value
 * @returns the current value and the setter function
 */
export const useStateObject = <T extends object>(
  initialValue: T
): UseObjectResult<T> => {
  const [value, setValue] = useState(initialValue)
  const [cache] = useState(
    () =>
      new Map<
        keyof T,
        <K extends keyof T>(key: K, valueOrFn: ValueOrFn<T[K]>) => void
      >()
  )

  const setWithValueOrFn = useCallback(
    <K extends keyof T>(key: K, valueOrFn: ValueOrFn<T[K]>) => {
      setValue((prev) => {
        const value = isFn<T[K]>(valueOrFn) ? valueOrFn(prev[key]) : valueOrFn
        return {
          ...prev,
          [key]: value,
        }
      })
    },
    []
  )

  const updateObjectWithValueOrFn = useCallback((valueOrFn: ValueOrFn<T>) => {
    setValue((prev: T) => {
      return isFn<T>(valueOrFn) ? valueOrFn(prev) : valueOrFn
    })
  }, [])

  const getSetter = useCallback(
    <K extends keyof T>(key: K) => {
      if (cache.has(key)) {
        return cache.get(key)
      }
      const fn = (valueOrFn: any) => setWithValueOrFn(key, valueOrFn)
      cache.set(key, fn)
      return fn
    },
    [cache, setWithValueOrFn]
  )

  const setFn = useCallback(
    function (
      keyOrPartial: keyof T | ValueOrFn<T>,
      value: ValueOrFn<T[keyof T]>
    ) {
      if (isKeyOf<T>(keyOrPartial)) {
        if (arguments.length === 1) {
          return getSetter(keyOrPartial)
        }
        return setWithValueOrFn<typeof keyOrPartial>(keyOrPartial, value)
      }
      updateObjectWithValueOrFn(keyOrPartial)
    },
    [getSetter, setWithValueOrFn, updateObjectWithValueOrFn]
  )

  // @ts-expect-error - heavily overloaded
  return [value, setFn]
}

function isFn<T>(valueOrFn: unknown): valueOrFn is (prev: T) => T {
  return typeof valueOrFn === 'function'
}

function isKeyOf<T extends object>(key: unknown): key is keyof T {
  return typeof key === 'string'
}
