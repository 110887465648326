import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { SimpleDateDropdown } from '~/global/components/dropdowns'

type PlannerDatePickerProps = {
  isoDate: string
  onChange: (isoDate: string | null) => void
}

export const PlannerDatePicker = (props: PlannerDatePickerProps) => {
  const { onChange, isoDate } = props

  return (
    <SimpleDateDropdown
      value={isoDate}
      onChange={(date) => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_JUMP_TO_DATE', {
          type: 'date-picker',
        })
        onChange(date)
      }}
      disabledDate={() => false}
    >
      <Button size='small' sentiment='neutral' variant='outlined'>
        Jump to date
      </Button>
    </SimpleDateDropdown>
  )
}
