import { type StageWithDates } from '@motion/ui-logic/pm/project'

import { twMerge } from 'tailwind-merge'

import { StageDatesRow } from './stage-dates-row'

export const AdjustDatesDescription = ({
  updatedStages,
  sideChanged,
}: {
  updatedStages: {
    changed: StageWithDates[]
    unchanged: StageWithDates[]
  }
  sideChanged: 'start' | 'due'
}) => {
  return (
    <div
      className={twMerge(
        'flex',
        sideChanged === 'start' ? 'flex-col-reverse' : 'flex-col'
      )}
    >
      {updatedStages.unchanged.length > 0 && (
        <div
          className={twMerge(
            'flex flex-col gap-2 text-semantic-neutral-text-subtle border-semantic-neutral-border-subtle py-2',
            sideChanged === 'start' ? 'border-b' : 'border-t'
          )}
        >
          <span>No changes:</span>
          {updatedStages.unchanged.map((stageWithDates, index) => (
            <StageDatesRow key={index} stageWithDates={stageWithDates} />
          ))}
        </div>
      )}
      {updatedStages.changed.length > 0 && (
        <div className='flex flex-col gap-2 font-bold text-semantic-neutral-text-default py-2'>
          <span>Updated deadline & durations:</span>
          {updatedStages.changed.map((stageWithDates, index) => (
            <StageDatesRow key={index} stageWithDates={stageWithDates} />
          ))}
        </div>
      )}
    </div>
  )
}
