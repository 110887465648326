import { logInDev } from '@motion/web-base/logging'

export type CopyToClipboardArgs = {
  text: string
  html?: string
}
export const copyToClipboard = ({ text, html }: CopyToClipboardArgs) => {
  if (navigator.clipboard) {
    // Firefox doesn't support it by default
    // https://caniuse.com/mdn-api_clipboarditem
    if (typeof ClipboardItem !== 'undefined' && html) {
      const items: Record<string, Blob> = {
        'text/plain': new Blob([text], { type: 'text/plain' }),
      }
      if (html) {
        items['text/html'] = new Blob([html], { type: 'text/html' })
      }

      return navigator.clipboard
        .write([new ClipboardItem(items)])
        .catch((ex) => {
          logInDev(ex)
          throw ex
        })
    }

    return navigator.clipboard.writeText(text).catch((ex) => {
      logInDev(ex)
      throw ex
    })
  }

  return manualCopy(text)
}

async function manualCopy(text: string) {
  const textarea = document.createElement('textarea')
  textarea.textContent = text
  textarea.style.display = 'none'
  textarea.style.position = 'fixed'
  textarea.style.top = '-1000px'
  textarea.style.left = '-1000px'
  try {
    document.body.appendChild(textarea)
    textarea.select()

    if (textarea.textContent == null) {
      return
    }

    // In case above did not work
    await navigator?.clipboard?.writeText(textarea.textContent)

    document.execCommand('copy') // Security exception may be thrown by some browsers.
  } catch (e) {
    logInDev(e)
  } finally {
    document.body.removeChild(textarea)
  }
}
