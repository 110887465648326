import {
  FilledChevronDownSolid,
  FilledChevronRightSolid,
  type SvgIcon,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, ButtonGroup, IconButton } from '@motion/ui/base'
import { ConditionalWrapper } from '@motion/ui/utils'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type CalendarListHeaderProps = {
  title: ReactNode
  isExpanded?: boolean
  isAccount?: boolean
  onToggleExpand?: (newState: boolean) => void
  actionButton?: {
    icon: SvgIcon
    onClick?: () => void
    // If you need to wrap the button, say with an action list, you can do so
    wrapper?: (button: ReactNode) => ReactNode
  }
}

export const CalendarListHeader = ({
  title,
  isExpanded,
  isAccount = false,
  onToggleExpand,
  actionButton,
}: CalendarListHeaderProps) => {
  const allowExpand = isExpanded !== undefined && !!onToggleExpand

  const expandIcon = allowExpand ? (
    isExpanded ? (
      <FilledChevronDownSolid className={twMerge(isAccount && '-ml-1.5')} />
    ) : (
      <FilledChevronRightSolid className={twMerge(isAccount && '-ml-1.5')} />
    )
  ) : null

  return (
    <div className='group/cal-list-header text-sidebar-title'>
      <ButtonGroup size='small' segmented stretch nowrap>
        <Button
          alignment='left'
          sentiment='neutral'
          variant={isAccount ? 'mutedBg' : 'muted'}
          size='small'
          fullWidth
          onClick={() => {
            if (!allowExpand) return

            onToggleExpand(!isExpanded)
          }}
        >
          {!isAccount && title}
          {expandIcon}
          {isAccount && title}
        </Button>

        {actionButton && (
          <ConditionalWrapper
            condition={!!actionButton.wrapper}
            wrapper={actionButton.wrapper ?? ((children) => children)}
          >
            <ActionButton
              size='small'
              onClick={actionButton.onClick}
              sentiment='neutral'
              variant='muted'
              icon={actionButton.icon}
            />
          </ConditionalWrapper>
        )}
      </ButtonGroup>
    </div>
  )
}

const ActionButton = classed(
  IconButton,
  `invisible group-hover/cal-list-header:visible`
)
