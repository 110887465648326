import { useSharedState } from '@motion/react-core/shared-state'

import { useProjects } from '~/areas/project-management/pages/pm-v3/pages/hooks'
import { PageTasksContextKey } from '~/areas/tasks/context'
import { memo, useMemo } from 'react'

import { ConnectedProjectTasksPage } from './connected-project-task-page'
import { EmptyState } from './empty-state'
import { TaskTreeList } from './task-list'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { usePageData } from '../routes'
import { useTaskGroupBy, useViewState } from '../view-state'

const ConnectedTaskListInternal = ({
  isArchivePage,
}: {
  isArchivePage: boolean
}) => {
  const [data] = useSharedState(PageTasksContextKey)
  const groupDefinitions = useTaskGroupBy()
  const [viewState] = useViewState()
  const pageData = usePageData()
  const projects = useProjects()

  const filteredData = useMemo(() => {
    if (!data) return []
    if (!viewState.search) return data
    return data.filter((x) =>
      x.name.toLowerCase().includes(viewState.search.toLowerCase())
    )
  }, [data, viewState.search])

  if (data == null) {
    return <LoadingPage />
  }

  if (
    filteredData.length < 1 &&
    ((groupDefinitions.length > 0 && viewState.groupBy.hideEmpty) ||
      isArchivePage)
  ) {
    // The folder page's empty state depends on whether there are projects
    // if there is at least one project, we show the empty state for tasks
    // otherwise, we show the empty state for projects
    if (pageData.page === 'folder') {
      return projects.length === 0 ? (
        <EmptyState target='projects' mainTextOverride='Folder is empty' />
      ) : (
        <EmptyState
          target='tasks'
          isArchivePage={isArchivePage}
          addTaskForProjectParam={projects[0].id}
        />
      )
    }

    return <EmptyState target='tasks' isArchivePage={isArchivePage} />
  }

  return (
    <TaskTreeList
      tasks={filteredData}
      groupBy={groupDefinitions}
      hideEmptyGroups={viewState.groupBy.hideEmpty}
      hideInlineAddRow={isArchivePage}
    />
  )
}

const MemoizedConnectedTaskList = memo(ConnectedTaskListInternal)

export const ConnectedTaskList = () => {
  const pageData = usePageData()

  const isArchivePage = useMemo(
    () => pageData.variant === 'archive',
    [pageData.variant]
  )

  return (
    <ConnectedProjectTasksPage>
      <MemoizedConnectedTaskList isArchivePage={isArchivePage} />
    </ConnectedProjectTasksPage>
  )
}
