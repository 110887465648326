import { StatusSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'

import { StatusLabel } from '~/global/components/labels'
import { AppWorkspaceContext } from '~/global/contexts'

import { ConnectedGroupedItemDropdown } from './connected-grouped-item-dropdown'
import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import {
  WorkspaceItem,
  WorkspaceItemGroupHeader,
} from '../../buttons/workspace-item'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedStatusFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(props.target, 'statusIds')
  const ids = value?.value ?? null
  const [ctx] = useSharedState(AppWorkspaceContext)
  const getAllItems = () => ctx.statuses.all

  return (
    <FilterItem
      label='Status'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...(value ?? {}), inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedGroupedItemDropdown
          applyTo={props.target}
          field='statusIds'
          getAllItems={getAllItems}
          placeholder='Choose statuses...'
          renderItem={(item) => (
            <WorkspaceItem item={item}>
              <StatusLabel value={item} />
            </WorkspaceItem>
          )}
          renderHeader={(group) => (
            <WorkspaceItemGroupHeader group={group}>
              <StatusLabel value={group.items[0]} />
            </WorkspaceItemGroupHeader>
          )}
          close={close}
        />
      )}
    >
      <MultiSelectFilterValue Icon={StatusSolid} type='statuses' ids={ids} />
    </FilterItem>
  )
}
