import { type SidebarState } from '@motion/rpc-types'

export const DefaultStatusFilterValue = Symbol('DefaultStatusVisibility')

interface SidebarStateParsed {
  workspaceOrderIds: string[]
  projectOrderMap: Record<string, string[]>
  expandedMap: Record<string, boolean>
  filteredStatusIdMap: Record<
    string,
    string[] | typeof DefaultStatusFilterValue
  >
}

export const parseSidebarState = (
  sidebarState?: SidebarState
): SidebarStateParsed => {
  const projectOrderMap: SidebarStateParsed['projectOrderMap'] = {}
  const filteredStatusIdMap: SidebarStateParsed['filteredStatusIdMap'] = {}
  const expandedMap: SidebarStateParsed['expandedMap'] = {}
  const workspaceOrderIds: SidebarStateParsed['workspaceOrderIds'] = []

  if (
    sidebarState &&
    Array.isArray(sidebarState?.workspacesAndProjectsOrdered)
  ) {
    for (const workspace of sidebarState.workspacesAndProjectsOrdered) {
      if (!workspace.workspaceId) continue

      workspaceOrderIds.push(workspace.workspaceId)
      projectOrderMap[workspace.workspaceId] = workspace.projectIdsOrdered
      expandedMap[workspace.workspaceId] = Boolean(workspace.workspaceExpanded)
      filteredStatusIdMap[workspace.workspaceId] =
        workspace.filteredStatusIds ?? DefaultStatusFilterValue
    }
  }

  return {
    workspaceOrderIds,
    projectOrderMap,
    expandedMap,
    filteredStatusIdMap,
  }
}
