import { createNoneId } from '@motion/shared/identifiers'
import { type ProjectFormFields } from '@motion/ui-logic/pm/project'
import { reduceCustomFieldsValuesFieldArrayToRecord } from '@motion/ui-logic/pm/project'
import { pick } from '@motion/utils/object'
import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

export function convertFormFieldsToProject(
  fields: ProjectFormFields
): ProjectSchema {
  return {
    ...pick(fields, [
      'description',
      'dueDate',
      'labelIds',
      'managerId',
      'name',
      'priorityLevel',
      'startDate',
      'statusId',
      'workspaceId',
      'activeStageDefinitionId',
      'projectDefinitionId',
    ]),
    id: fields.id ?? createNoneId(fields.workspaceId),
    type: 'NORMAL',
    createdByUserId: 'unknown',
    customFieldValues: reduceCustomFieldsValuesFieldArrayToRecord(
      fields.customFieldValuesFieldArray
    ),
    createdTime: DateTime.now().toISO(),
    updatedTime: null,

    stages: [],
    variableInstances: [],

    color: 'gray',
    completedDuration: 0,
    completedTaskCount: 0,
    canceledDuration: 0,
    canceledTaskCount: 0,
    duration: 0,
    taskCount: 0,
    completion: 0,
    uploadedFileIds: [],
    folderId: null,
    deadlineStatus: 'none',
    scheduledStatus: null,
    estimatedCompletionTime: null,

    // deprecated
    flowTemplateId: null,
  }
}
