import { RichTextImageBubbleMenu } from '@motion/ui/rte'

import { type Editor, EditorContent } from '@tiptap/react'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { TaskStyledField } from './form-name-field'

import { useTemplateTaskForm } from '../hooks'

export type FormDescriptionFieldProps = {
  editor: Editor | null
}

export const FormDescriptionField = (props: FormDescriptionFieldProps) => {
  const { editor } = props

  const { disabledFields } = useTemplateTaskForm()

  const isDisabled = disabledFields.has('description')

  return <StyledDescriptionField editor={editor} disabled={isDisabled} />
}

type StyledDescriptionFieldProps = {
  editor: Editor | null
  disabled?: boolean
}
export const StyledDescriptionField = (props: StyledDescriptionFieldProps) => {
  const { editor, disabled } = props

  useEffect(() => {
    editor?.setOptions({ editable: !disabled })
  }, [editor, disabled])

  return (
    <div className='grow min-h-[60px]'>
      <RichTextImageBubbleMenu editor={editor} />
      <TaskStyledField disabled={disabled} className='h-full'>
        <EditorContent
          editor={editor}
          onFocus={() => {
            if (disabled) {
              editor?.commands.blur()
            }
          }}
          className={twMerge(
            `outline-0
            bg-transparent
            w-full h-full
            overflow-x-hidden overflow-y-scroll
            cursor-text
            text-field-text-default
            placeholder:text-field-text-placeholder
            [&>[contenteditable='false']]:text-field-text-disabled`,
            '[&>.ProseMirror]:p-0',

            '[&_h1]:text-field-text-default [&_h1]:my-[0.5em]',
            '[&_h2]:text-field-text-default [&_h2]:my-[0.5em]',
            '[&_h3]:text-field-text-default [&_h3]:my-[0.5em]',
            '[&_h4]:text-field-text-default [&_h4]:my-[0.5em]',
            '[&_h5]:text-field-text-default [&_h5]:my-[0.5em]',
            '[&_h6]:text-field-text-default [&_h6]:my-[0.5em]',
            '[&_p]:my-[0.5em] [&_li_p]:my-0 [&_li]:my-[0.2em]',
            '[&_ol]:my-[0.5em] [&_ul]:my-[0.5em]',

            '[&_code]:text-xs',
            '[&_code]:p-0.5 [&_code]:rounded [&_code]:box-decoration-clone',
            'dark:[&_code]:text-warning-500 [&_code]:text-alert-400',
            'dark:[&_code]:bg-dark-100/5 [&_code]:bg-light-1200/[0.08]'
          )}
        />
      </TaskStyledField>
    </div>
  )
}
