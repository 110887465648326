import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useMemo } from 'react'

import { BULK_OPS_TARGET_CONFIG, type BulkOpsConfigBase } from './constants'

import { supportedCustomFields } from '../../constants'
import { useBulkOpsGroups, useBulkOpsState } from '../../contexts'

export type BulkOpsConfig = BulkOpsConfigBase & {
  showCoreFields: boolean
  showCustomFields: boolean
}

export function useBulkOpsConfig(): BulkOpsConfig {
  const { target } = useBulkOpsState()

  const { workspaceGroups } = useBulkOpsGroups()

  const workspaceCustomFields = useCustomFieldsByWorkspaceId(
    workspaceGroups[0]?.key
  )

  const validCustomFields = useMemo(() => {
    if (workspaceGroups.length !== 1) return []
    return workspaceCustomFields.filter((cf) =>
      supportedCustomFields.includes(cf.type)
    )
  }, [workspaceGroups.length, workspaceCustomFields])

  const config = BULK_OPS_TARGET_CONFIG[target]

  return {
    ...config,
    showCoreFields: [
      config.showStatusField,
      config.showPriorityField,
      config.showDurationField,
      config.showAssigneeField,
      config.showStartDateField,
      config.showDueDateField,
      config.showStageField,
      config.showAutoscheduleField,
      config.showLabelsField,
      config.showMoveField,
      config.showArchiveField,
      config.showDeleteField,
    ].some(Boolean),
    showCustomFields: config.showCustomFields && validCustomFields.length > 0,
  }
}
