import {
  Button,
  DatePicker,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'
import { type DATE_FILTER_TYPES } from '@motion/zod/client'

import { DateTime } from 'luxon'
import { useState } from 'react'

export type EXTENDED_DATE_FILTER_TYPES = DATE_FILTER_TYPES | 'between-dates'

type DateFilterDropdownProps = {
  calendarStartDay?: CalendarStartDay
  onSave: (values: {
    [key in DATE_FILTER_TYPES]?: string
  }) => void
  onClear: () => void
  filterDates?: string[]
  filterType?: EXTENDED_DATE_FILTER_TYPES
  openOnMount?: boolean
  onBlur?: () => void
  children: React.ReactNode
}

type DateOption = {
  label: string
  value: EXTENDED_DATE_FILTER_TYPES
}

const DATE_OPTIONS: DateOption[] = [
  {
    label: 'Choose date',
    value: 'on-date',
  },
  {
    label: 'Choose date range',
    value: 'between-dates',
  },
  {
    label: 'Before date',
    value: 'before-date',
  },
  {
    label: 'After date',
    value: 'after-date',
  },
]

export function DateFilterDropdown(props: DateFilterDropdownProps) {
  const { calendarStartDay, onSave, onClear, filterDates, filterType } = props

  const [selectedOption, setSelectedOption] =
    useState<EXTENDED_DATE_FILTER_TYPES>(filterType ?? DATE_OPTIONS[0].value)
  const [selectedDates, setSelectedDates] = useState<string[]>(
    filterDates ?? [DateTime.now().toISODate()]
  )

  const formatDate = (date: string) => {
    if (!date) return DateTime.now().toLocaleString()
    return DateTime.fromISO(date).toLocaleString()
  }

  const handleSave = () => {
    if (selectedOption === 'between-dates') {
      onSave({
        'before-date': selectedDates[1],
        'after-date': selectedDates[0],
      })
    } else {
      onSave({
        [selectedOption]: selectedDates[0],
      })
    }
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      openOnMount={props.openOnMount}
      onClose={props.onBlur}
      renderPopover={({ close }) => (
        <div className='flex flex-col gap-2 max-w-[264px]'>
          <div className='p-2 flex flex-col gap-2'>
            <PopoverTrigger
              placement='bottom-start'
              renderPopover={({ close: searchableClose }) => (
                <SearchableList
                  items={DATE_OPTIONS}
                  renderItem={(item) => item.label}
                  computeKey={(item) => item.value}
                  computeSelected={(item) => item.value === selectedOption}
                  itemType='select'
                  onSelect={(item) => {
                    setSelectedOption(item.value)
                    searchableClose()
                  }}
                  searchable={false}
                />
              )}
            >
              <PopoverButton>
                {DATE_OPTIONS.find((option) => option.value === selectedOption)
                  ?.label ?? 'Choose date'}
              </PopoverButton>
            </PopoverTrigger>
            <div className='flex flex-row items-center w-full gap-2'>
              {selectedOption === 'between-dates' ? (
                <>
                  <TextField value={formatDate(selectedDates[0])} />
                  <div className='text-semantic-neutral-text-subtle'>-</div>
                  <TextField value={formatDate(selectedDates[1])} />
                </>
              ) : (
                <div className='w-full'>
                  <TextField value={formatDate(selectedDates[0])} />
                </div>
              )}
            </div>
            <DatePicker
              weekStartDay={calendarStartDay}
              value={selectedDates}
              onChange={(val) => {
                if (typeof val === 'string') {
                  setSelectedDates([val])
                } else if (val?.length === 2) {
                  setSelectedDates([val[0], val[1]])
                }
              }}
              mode={selectedOption === 'between-dates' ? 'range' : 'single'}
            />
          </div>
          <div className='flex p-2 border-t border-modal-border justify-between'>
            <Button
              sentiment='neutral'
              variant='outlined'
              size='small'
              onClick={() => {
                onClear()
                close()
              }}
            >
              Clear
            </Button>
            <Button
              sentiment='primary'
              size='small'
              onClick={() => {
                handleSave()
                close()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    >
      {props.children}
    </PopoverTrigger>
  )
}
