import { type CreateTeamDto } from '@motion/rpc-types'

import { teamsService } from './teamsService'

export class TeamsServiceHandler {
  static id = 'TeamsServiceHandler' as const

  async cancelSubscription(teamId: string, cancellationReason: string) {
    return await teamsService.cancelSubscription(teamId, cancellationReason)
  }

  async checkTeamEligibility(email: string) {
    return await teamsService.checkTeamEligibility(email)
  }

  async createTeam(dto: CreateTeamDto) {
    return await teamsService.createTeam(dto)
  }

  async createSetupIntent(name: string, forceNewCustomer?: boolean) {
    return await teamsService.createSetupIntent(name, forceNewCustomer)
  }

  // TODO: Consolidate both of the below calls (need to update the backend first)
  /*
  Fetches the PM Team and team members
  */
  async getPmTeam() {
    return await teamsService.getPmTeam()
  }

  /*
  Fetches the FirebaseTeam
  */
  async getTeam() {
    return await teamsService.get()
  }

  async reenableSubscription(teamId: string) {
    return await teamsService.reenableSubscription(teamId)
  }

  async rename(teamId: string, name: string) {
    return await teamsService.rename(teamId, name)
  }

  async reusableSetupIntent(teamId: string) {
    return await teamsService.reusableSetupIntent(teamId)
  }

  async switchBillingCycle(teamId: string) {
    return await teamsService.switchBillingCycle(teamId)
  }
}

export const handler = new TeamsServiceHandler()
