import { type ProjectSchema } from '@motion/rpc-types'
import { Button, Tooltip } from '@motion/ui/base'
import { getEtaLabel, normalizeProjectDeadlineStatus } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { type BadgeSize, EtaBadge } from '~/global/components/badges'

import { BadgeTooltip } from './badge-tooltip'

import { useExtendedNoneEta } from '../../hooks'
import { ProjectEtaPopover } from '../popover'

type ConnectedProjectEtaBadgeProps = {
  project: ProjectSchema
  size?: BadgeSize
  hideTooltip?: boolean
}

export const ConnectedProjectEtaBadge = ({
  project,
  size = 'small',
  hideTooltip = false,
}: ConnectedProjectEtaBadgeProps) => {
  const hasPastDueM2 = useHasTreatment('past-due-m2')
  const deadlineStatus = normalizeProjectDeadlineStatus(project)
  const extendedDeadlineStatus = useExtendedNoneEta({
    entity: project,
    type: 'project',
  })

  if (!hasPastDueM2) return null

  return (
    <ProjectEtaPopover entity={project}>
      <Tooltip
        renderContent={() =>
          hideTooltip ? undefined : (
            <BadgeTooltip entity={project} type='project' />
          )
        }
      >
        <Button
          aria-label={getEtaLabel(extendedDeadlineStatus, 'project')}
          sentiment='neutral'
          variant='muted'
          disabled={deadlineStatus === 'none'}
          size='xsmall'
          iconOnly
        >
          <EtaBadge value={extendedDeadlineStatus} size={size} />
        </Button>
      </Tooltip>
    </ProjectEtaPopover>
  )
}
