import { TagSolid } from '@motion/icons'
import { createPendingLabel } from '@motion/rpc-cache'
import { FieldButton, type FieldButtonProps } from '@motion/ui/base'
import { type LabelSchema } from '@motion/zod/client'

import { LabelDropdown } from '~/global/components/dropdowns'
import { Label } from '~/global/components/labels'
import { useWorkspaceLabels } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useTemplateTaskForm } from '../hooks'

export type FormLabelDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
> & { showCountIfMoreThan?: number }

export const FormLabelDropdown = ({
  showCountIfMoreThan,
  ...buttonProps
}: FormLabelDropdownProps) => {
  const { form, disabledFields } = useTemplateTaskForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'labelIds',
    control,
  })

  const selectedLabelIds = watch('labelIds')
  const selectedWorkspaceId = watch('workspaceId')

  const labels = useWorkspaceLabels(selectedWorkspaceId)

  const isDisabled = disabledFields.has('labelIds')

  return (
    <LabelDropdown
      selectedLabelIds={selectedLabelIds}
      workspaceId={selectedWorkspaceId}
      onChange={(labelIds) => {
        field.onChange(labelIds)
      }}
    >
      <FieldButton {...buttonProps} disabled={isDisabled} wrap>
        <LabelName
          labels={labels}
          selectedIds={selectedLabelIds}
          showCountIfMoreThan={showCountIfMoreThan}
        />
      </FieldButton>
    </LabelDropdown>
  )
}

type LabelNameProps = {
  selectedIds: string[]
  labels: LabelSchema[]
  showCountIfMoreThan: FormLabelDropdownProps['showCountIfMoreThan']
}
export const LabelName = (props: LabelNameProps) => {
  const { selectedIds, labels, showCountIfMoreThan } = props

  const name = (() => {
    if (selectedIds.length === 0) {
      return (
        <span className='text-xs text-semantic-neutral-text-subtle'>None</span>
      )
    }
    if (showCountIfMoreThan && selectedIds.length > showCountIfMoreThan)
      return selectedIds.length

    return selectedIds.map((id) => {
      const selectedLabel =
        labels.find((l) => l.id === id) ?? createPendingLabel(id)
      if (!selectedLabel) return null
      return <Label key={id} value={selectedLabel} />
    })
  })()

  return (
    <>
      <TagSolid /> {name}
    </>
  )
}
