import { PuzzleSolid } from '@motion/icons'
import { type TemplateProjectType } from '@motion/rpc/types'
import { SearchableList, type SearchableListSelectProps } from '@motion/ui/base'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

export type TemplateSearchableListProps<
  T extends TemplateProjectType | ProjectDefinitionSchema,
> = {
  close: () => void
  selectTemplate: (item: T) => void
} & Pick<SearchableListSelectProps<T>, 'items' | 'searchable'>

export const TemplateSearchableList = <
  T extends TemplateProjectType | ProjectDefinitionSchema,
>({
  items,
  close,
  selectTemplate,
  searchable = false,
}: TemplateSearchableListProps<T>) => (
  <SearchableList
    searchable={searchable}
    items={items}
    computeKey={(item) => item.id}
    computeSearchValue={(item) => item.name}
    computeSelected={() => false}
    inputProps={{
      placeholder: 'Choose template...',
    }}
    onSelect={(item) => {
      close()
      selectTemplate(item)
    }}
    renderItem={(item) => (
      <div className='flex flex-row items-center gap-1.5 max-w-xs'>
        <PuzzleSolid className='w-4 h-4 text-semantic-neutral-icon-default shrink-0' />
        <div className='truncate'>{item.name}</div>
      </div>
    )}
  />
)
