import { findDefaultStatus, StatusType } from '@motion/shared/common'
import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type StatusSchema } from '@motion/zod/client'

import { StatusLabel } from '~/global/components/labels'
import { useDeleteStatus as useDeleteStatusCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useWorkspaceFns } from '../use-workspace-fns'

export const useDeleteStatus = () => {
  const {
    mutateAsync: deleteWorkspaceStatus,
    isLoading,
    isError,
  } = useDeleteStatusCall()
  const modalApi = useModalApi()
  const { getWorkspaceStatuses } = useWorkspaceFns()

  return {
    deleteStatus: useCallback(
      async (status: StatusSchema): Promise<boolean> => {
        try {
          const statuses = getWorkspaceStatuses(status.workspaceId)
          const defaultStatus = findDefaultStatus(statuses)

          const confirmResponse = await modalApi.prompt('confirm-delete-item', {
            analytics: {
              name: 'delete-status',
            },
            titleValue: 'this status',
            description: templateStr(
              'The status will be deleted and all tasks with this status will change to {{ status }}.',
              {
                status: (
                  <div className='inline-block'>
                    <StatusLabel
                      value={{
                        name:
                          defaultStatus?.name ?? 'Default status for workspace',
                        type: StatusType.DEFAULT,
                        color: defaultStatus?.color ?? '',
                      }}
                      size='small'
                    />
                  </div>
                ),
              }
            ),
            deleteMessage: status.name,
            deleteButtonLabelValue: 'status',
          })

          if (confirmResponse === true) {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_WORKSPACE_STATUS')

            await deleteWorkspaceStatus({
              workspaceId: status.workspaceId,
              statusId: status.id,
            })

            showToast('success', 'Status removed')
            return true
          }
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'delete-status',
            },
          })

          showErrorToast(e)
        }
        return false
      },
      [deleteWorkspaceStatus, getWorkspaceStatuses, modalApi]
    ),
    isLoading,
    isError,
  }
}
