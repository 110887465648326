import { type TaskFormFields } from '@motion/ui-logic/pm/task'
import { Sentry } from '@motion/web-base/sentry'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { showErrorToast } from '~/global/toasts'

import { useRedirectToTask } from './use-redirect-to-task'
import { useSaveTask } from './use-save-task'
import { useTaskForm } from './use-task-form'

type SubmitFormOptions = {
  onSave?: (task: TaskSchema | RecurringTaskSchema) => void
}

export function useSubmitForm({ onSave }: SubmitFormOptions = {}) {
  const saveTask = useSaveTask()
  const redirectToTask = useRedirectToTask()
  const { form } = useTaskForm()
  const { watch } = form

  const taskId = watch('id')

  return async (data: TaskFormFields) => {
    try {
      if (!form.formState.isDirty) return

      const task = await saveTask(data)

      // reset dirty state when successful
      if (taskId != null) {
        form.reset({}, { keepValues: true })
      }

      if (onSave) {
        onSave(task)
      } else {
        redirectToTask(task.id)
      }
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitForm',
        },
      })

      showErrorToast(e)
    }
  }
}
