import { useActiveTab } from './use-active-tab'
import { useSetupProjectModalUrl } from './use-setup-project-modal-url'
import { useTabOptions } from './use-tab-options'

export function useNavigateTabs() {
  const activeTab = useActiveTab()
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()

  const tabOptions = useTabOptions()
  const currentIndex = tabOptions.indexOf(activeTab)

  const prev = tabOptions[currentIndex - 1]
  const prevUrl =
    prev != null ? buildSetupProjectModalUrl({ activeTab: prev }) : null

  const next = tabOptions[currentIndex + 1]
  const nextUrl =
    next != null ? buildSetupProjectModalUrl({ activeTab: next }) : null

  return { prevUrl, nextUrl }
}
