import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

import { ActivityTimestamp } from '../activity-timestamp'

export type ActivityItemContainerProps = {
  subject: ReactNode
  timestamp?: string | null
  icon?: ReactNode | null
  children: React.ReactNode
}

export function ActivityItemContainer({
  icon,
  subject,
  children,
  timestamp,
}: ActivityItemContainerProps) {
  return (
    <div className='activity-item grid grid-cols-[auto_1fr] gap-3 w-full'>
      {icon && (
        <div className='opacity-50 w-3 h-3 [&>[data-icon]]:w-3 [&>[data-icon]]:h-3 flex place-items-center mt-[3px]'>
          {icon}
        </div>
      )}

      <Container>
        <BaseSpan>
          {subject}&nbsp;
          {children}&emsp;
        </BaseSpan>
        {timestamp && (
          <BaseSpan className='whitespace-nowrap'>
            <ActivityTimestamp timestamp={timestamp} />
          </BaseSpan>
        )}
      </Container>
    </div>
  )
}

const BaseSpan = classed(
  'span',
  'text-semantic-neutral-text-subtle font-regular text-xs leading-[18px] break-words'
)

const Container = classed('span', {
  base: `
  leading-none min-w-0
  [&_span]:max-w-full
  `,
})
