import { useShortcut } from '@motion/ui/base'
import { getAllMenuListItems } from '@motion/ui/utils'
import { clamp } from '@motion/utils/math'

import { useCallback, useEffect, useRef, useState } from 'react'

import { DATA_SELECTED_ATTR } from '../utils'

interface UseListNavigationProps<T> {
  items: T[]
  getFirstActiveIndex?: (items: T[]) => number
}

export function useListNavigation<T>({
  items,
  getFirstActiveIndex,
}: UseListNavigationProps<T>) {
  const listRef = useRef<HTMLDivElement>()
  const [activeIndex, setActiveIndex] = useState(0)

  const selectNextItem = useCallback(
    (activeIdx: number, i: number) => {
      if (items.length === 0 || !listRef.current) return

      const next = (activeIdx + i + items.length) % items.length

      const allNodeItems = getAllMenuListItems(listRef.current)

      const currentNode = allNodeItems[activeIdx]
      const nextNode = allNodeItems[next]

      // Apply the data selected attribute so the item can be styled properly
      currentNode.removeAttribute(DATA_SELECTED_ATTR)
      nextNode.setAttribute(DATA_SELECTED_ATTR, 'true')
      nextNode.scrollIntoView({
        block: 'nearest',
      })

      setActiveIndex(next)
    },
    [items.length]
  )

  const resetSelectedItem = useCallback((firstActiveIndex: number = 0) => {
    if (!listRef.current) return

    const allNodeItems = getAllMenuListItems(listRef.current)
    allNodeItems.forEach((node) => {
      node.removeAttribute(DATA_SELECTED_ATTR)
    })

    if (allNodeItems.length > 0) {
      const clampedIndex = clamp(firstActiveIndex, 0, allNodeItems.length)
      allNodeItems[clampedIndex].setAttribute(DATA_SELECTED_ATTR, 'true')
      allNodeItems[clampedIndex].scrollIntoView({
        block: 'center',
      })
      setActiveIndex(clampedIndex)
    }
  }, [])

  // Reset the active index when items changes
  useEffect(() => {
    const firstActiveIndex = getFirstActiveIndex?.(items) ?? 0
    resetSelectedItem(firstActiveIndex)
  }, [getFirstActiveIndex, items, resetSelectedItem])

  const shouldSkip = (evt: KeyboardEvent) => {
    if (items.length === 0 || !listRef.current) return true

    const target = evt.target as HTMLElement
    if (
      target instanceof HTMLInputElement ||
      listRef.current?.contains(target)
    ) {
      return false
    }

    return true
  }

  useShortcut('ArrowDown', (evt) => {
    if (shouldSkip(evt)) return

    selectNextItem(activeIndex, 1)
  })

  useShortcut('ArrowUp', (evt) => {
    if (shouldSkip(evt)) return

    selectNextItem(activeIndex, -1)
  })

  useShortcut('Enter', (evt) => {
    if (shouldSkip(evt) || !listRef.current) return

    const allNodeItems = getAllMenuListItems(listRef.current)
    const currentNode = allNodeItems[activeIndex]
    currentNode.click()
  })

  const customRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        listRef.current = node
        resetSelectedItem()
      }
    },
    [resetSelectedItem]
  )

  return { listRef: customRef, activeIndex }
}
