import { useFieldFilter } from '@motion/ui-logic/pm/data'
import { type Inclusion } from '@motion/zod/client'

import { useAppDataContext } from '~/global/contexts'

import { ConditionSelector } from './filter-item/condition-dropdown'
import { FilterItem } from './filter-item/filter-item'
import { type ConditionOption } from './filter-item/types'

import { type DropdownFilterProps } from '../../types'

const CONDITIONS = [
  { label: 'Yes', id: 'only' },
  { label: 'No', id: 'exclude' },
] satisfies ConditionOption<Inclusion>[]

export const ConnectedIsUnvisitedStageFilter = (
  props: DropdownFilterProps<'tasks'>
) => {
  const ctx = useAppDataContext()
  const [value, setValue] = useFieldFilter(ctx, 'tasks', 'isUnvisitedStage')
  const label =
    CONDITIONS.find((c) => c.id === value)?.label ?? CONDITIONS[0].label

  return (
    <FilterItem
      hideInvert
      label='Unvisited Stage'
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConditionSelector
          conditions={CONDITIONS}
          value={value}
          onSelect={(condition: Inclusion) => {
            setValue(condition)
            close()
          }}
        />
      )}
    >
      {label}
    </FilterItem>
  )
}
