import { type TaskSchema } from '@motion/zod/client'

import { useAgendaCurrentTaskActions } from '~/areas/agenda/hooks'

import { CurrentTaskButton } from './current-task-button'

type StopAndChunkButtonProps = {
  task: TaskSchema
}

export function StopAndChunkButton({ task }: StopAndChunkButtonProps) {
  const { stopAndChunk, taskUpdateInFlight } = useAgendaCurrentTaskActions({
    task,
  })

  return (
    <CurrentTaskButton
      onClick={stopAndChunk}
      action='stop-and-chunk'
      loading={taskUpdateInFlight}
    >
      Stop and chunk
    </CurrentTaskButton>
  )
}
