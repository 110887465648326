import { UserSolid } from '@motion/icons'
import { type FlowTemplateRoleAssignee } from '@motion/ui-logic/pm/project'

import { VariableLabelBase } from '~/global/components/labels'

export function RoleLabel({ role }: { role: FlowTemplateRoleAssignee }) {
  return (
    <VariableLabelBase color={role.color} size='xsmall'>
      <UserSolid className='!w-3 !h-3 !text-palette-highlight-default' />
      <span className='truncate'>{role.name}</span>
    </VariableLabelBase>
  )
}
