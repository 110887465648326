import { twMerge } from 'tailwind-merge'

import { type SettingsSidebarItemProps } from './sidebar-item'

export const SettingsSidebarButton = ({
  title,
  icon: Icon,
  onClick,
}: Omit<SettingsSidebarItemProps, 'to' | 'isSelected'> & {
  onClick: () => void
}) => (
  <button
    onClick={onClick}
    className={twMerge(
      'dark:text-dark-100 text-light-1200 hover:bg-light-300 hover:dark:bg-dark-100/5 flex cursor-pointer',
      'border border-transparent',
      'items-center gap-3 rounded-sm px-2.5 py-1.5 min-w-0 w-full pl-2.5 pr-0 max-w-[190px]'
    )}
  >
    <Icon className='h-4 w-4 text-sidebar-item-text-default shrink-0' />
    <span className='text-sm font-medium text-sidebar-item-text-default truncate'>
      {title}
    </span>
  </button>
)
