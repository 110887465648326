import { useMountMeasureOnce } from '@motion/web-common/performance'

import type FullCalendar from '@fullcalendar/react'
import { useSearchParams } from '~/routing'
import { DateTime } from 'luxon'
import { memo, useEffect, useRef } from 'react'

import { useMainCalendarProps } from './hooks'

import { Calendar } from '../components/calendar/calendar'
import { useCalendarState, useSendCalendarState } from '../hooks'
import { microTask } from '../utils/micro-task'

export const MainCalendar = memo(function MainCalendar() {
  const calendarRef = useRef<FullCalendar | null>(null)
  const [calendarState] = useCalendarState()
  const updateCalendarState = useSendCalendarState()
  const { initialDate } = useSearchParams<{ initialDate?: string }>()

  const calendarProps = useMainCalendarProps()

  useEffect(() => {
    if (!calendarState.selectedCalendarEvent) return
    // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
    microTask(() => {
      calendarRef.current?.getApi().unselect()
    })
  }, [calendarState.selectedCalendarEvent])

  useEffect(() => {
    {
      if (initialDate) {
        try {
          const date = DateTime.fromISO(initialDate)
          updateCalendarState({
            selectedDate: date,
          })
        } catch (err) {}
      }
    }
  }, [initialDate, updateCalendarState])

  useEffect(
    function handleBaseDateChange() {
      const baseDate = calendarState.selectedDate.toJSDate()
      // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
      microTask(() => {
        calendarRef.current?.getApi().gotoDate(baseDate)
      })
    },
    [calendarState.selectedDate]
  )

  useEffect(
    function setCalendarView() {
      const calendarApi = calendarRef.current?.getApi()
      const isWeekView = calendarState.calendarView === 'week'
      const view = isWeekView ? 'timeGridWeek' : 'timeGridDay'
      if (calendarApi?.view && view !== calendarApi.view.type) {
        // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
        microTask(() => {
          calendarApi?.changeView(view)
        })
      }
    },
    [calendarState.calendarView]
  )

  useMountMeasureOnce({
    name: 'calendar.initial_load',
    from: 'htmlStart',
    tags: ['calendar:scheduled_entities'],
    when: ({ initialPath }) =>
      initialPath.startsWith('/web/calendar') &&
      Array.isArray(calendarProps.events) &&
      calendarProps.events.length > 0,
  })

  return <Calendar ref={calendarRef} calendarProps={calendarProps} />
})
