import { InformationCircleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { useShowProjectSidebarStateSendOnly } from '~/areas/project-management/states'
import { useMatchedRouteData } from '~/routing'

import { type MatchedV3RouteData } from '../routes/types'

export function ConnectedShowProjectSidebarButton() {
  const setSidebarState = useShowProjectSidebarStateSendOnly()

  const route = useMatchedRouteData<MatchedV3RouteData>()

  const isProjectTasksView = route.data.page === 'project'

  const onClick = () => {
    setSidebarState((state) => ({
      ...state,
      open: !state.open,
    }))
  }

  if (!isProjectTasksView) {
    return null
  }

  return (
    <Button size='small' sentiment='neutral' onClick={onClick}>
      <InformationCircleSolid />
      Project info
    </Button>
  )
}
