import { errorInDev, logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { useEffect } from 'react'
import { useLocation, useRouteError } from 'react-router'
import { Link, useNavigate } from 'react-router-dom'

import {
  locationToString,
  normalizeUri,
  pathForRoute,
} from '../../routing/utils'
import { Header, Paragraph } from '../Common'

const CALENDAR_ROUTE = pathForRoute('/calendar')

type NotFoundProps = {
  path: string
}
export const NotFoundPage = (props: NotFoundProps) => {
  errorInDev('ROUTE ERROR', props.path)
  const navigate = useNavigate()
  const location = useLocation()
  const error = useRouteError()

  errorInDev('route-error', error)

  const currentUrl = locationToString(location)
  const newUrl = normalizeUri(location)
  useEffect(() => {
    if (newUrl !== currentUrl) {
      logInDev('Redirecting', currentUrl, newUrl)
      navigate(newUrl, { replace: true })
    }
  }, [currentUrl, navigate, newUrl])

  useEffect(() => {
    Sentry.captureException(error, { tags: { position: 'RouterError' } })
  }, [error])

  if (newUrl !== currentUrl) {
    return <div className='dark:bg-dark-1100 fill flex bg-white'></div>
  }

  return (
    <div className='fill dark:bg-dark-1100 fixed flex items-center justify-center bg-white'>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            textAlign: 'center',
          }}
        >
          <Header className='text-light-1200 dark:text-white'>
            Oops! Page not found.
          </Header>
          {__IS_DEV__ && !!error && <DisplayDevError error={error} />}
          <LinkToCalendar />
        </div>
      </div>
    </div>
  )
}

const LinkToCalendar = () => (
  <Paragraph className='text-light-1100 dark:text-dark-400'>
    Click{' '}
    <Link to={CALENDAR_ROUTE} style={{ fontWeight: 'bold' }}>
      here
    </Link>{' '}
    to go back to your calendar
  </Paragraph>
)

type DisplayDevErrorProps = {
  error: unknown
}
const DisplayDevError = (props: DisplayDevErrorProps) => {
  const text =
    props.error instanceof Error
      ? props.error.toString()
      : 'Unknown: ' + JSON.stringify(props.error, undefined, 2)

  return (
    <pre
      className='text-light-1100 dark:text-dark-400'
      style={{ whiteSpace: 'unset', textAlign: 'left' }}
    >
      {text}
    </pre>
  )
}
