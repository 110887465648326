import { getFormatter } from '@motion/ui/helpers'

import { useCustomFieldById } from '~/areas/custom-fields/hooks'
import { numberCustomFieldToNumberInputFormat } from '~/areas/project-management/custom-fields/base/number'
import { useMemo } from 'react'

import { ActivityValue } from '../activity-value'

type ActivityNumberProps = {
  value: number | null | undefined
  instanceId: string
}

export function ActivityNumber({ value, instanceId }: ActivityNumberProps) {
  const customField = useCustomFieldById(instanceId, 'number')

  const formattedValue = useMemo(() => {
    if (value == null) {
      return 'None'
    }

    // If custom field is removed, we should still show the value, but without formatting
    if (!customField) {
      return value
    }

    const formatter = getFormatter(
      numberCustomFieldToNumberInputFormat[customField.metadata.format]
    )

    return formatter(value)
  }, [customField, value])

  return <ActivityValue>{formattedValue}</ActivityValue>
}
