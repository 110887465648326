import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CellText } from './components'

type WorkspaceCellProps = {
  item: TaskWithRelations | ProjectWithRelations
}

export const WorkspaceCell = (props: WorkspaceCellProps) => {
  return (
    <CellText>
      <span className='truncate'>{props.item.workspace?.name}</span>
    </CellText>
  )
}
