import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { getMinimumBucket } from '@motion/ui-logic/billing'
import { isEmailValid } from '@motion/utils/string'
import { useModalApi } from '@motion/web-common/modals'

import { unwrapResult } from '@reduxjs/toolkit'
import { useCallback, useState } from 'react'

import { InviteeList } from './InviteeList/InviteeList'
import { Recommendations } from './Recommendations'

import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { checkTeamEligibility } from '../../../../state/teamSlice'
import { type Contact } from '../../../../state/TeamTypes'
import { selectEmail } from '../../../../state/userSlice'
import { Header, SubParagraph } from '../../../Common'
import { type PayTeamFormProps } from '../../types/PayTeamFormProps'

interface EmailInputProps {
  handleAddClick: () => void
  handleEmail: (email: string) => void
  setTeamName: (team: string) => void
  removeEmail: (email: string) => void
  handleEnter: (event: React.KeyboardEvent) => void
  currentEmail: string
  errorMessage: string | null
  emails: string[]
  teamName: string
  hasRecommendations: boolean
  hasTeamNameError: boolean
  seats?: number
}

const EmailInput = ({
  handleAddClick,
  handleEmail,
  handleEnter,
  setTeamName,
  removeEmail,
  currentEmail,
  errorMessage,
  emails,
  teamName,
  hasRecommendations,
  hasTeamNameError,
  seats,
}: EmailInputProps) => {
  const showTeamNameError = hasTeamNameError && !teamName

  return (
    <div
      className={`flex flex-col gap-8 flex-wrap items-start ${
        hasRecommendations && 'pr-6'
      }`}
    >
      <Header className='text-left text-2xl font-bold'>Create team</Header>
      <div className='flex flex-col gap-2.5 w-full'>
        <FormText>What&apos;s your team called?</FormText>
        <TextField
          onChange={setTeamName}
          value={teamName}
          placeholder='Team name'
          fullWidth
          sentiment={showTeamNameError ? 'error' : undefined}
        />
        {showTeamNameError && (
          <SubParagraph className='text-alert-400 dark:text-alert-400'>
            Team name cannot be empty
          </SubParagraph>
        )}
      </div>
      <div className='flex flex-col gap-2.5 w-full'>
        <div className='flex flex-row items-center justify-between'>
          <FormText>Invite your team</FormText>
          {seats && (
            <p className='text-xs text-semantic-neutral-text-subtle'>
              {emails.length + 1}/{seats} seats used
            </p>
          )}
        </div>
        <div className='w-full flex flex-row items-center gap-x-3'>
          <TextField
            onKeyDown={handleEnter}
            onChange={handleEmail}
            value={currentEmail}
            placeholder='Add email'
            fullWidth
          />
          <div className='shrink-0'>
            <Button
              variant='outlined'
              sentiment='neutral'
              onClick={handleAddClick}
            >
              Add email
            </Button>
          </div>
        </div>
        {errorMessage && (
          <SubParagraph className='text-alert-400 dark:text-alert-400'>
            {errorMessage}
          </SubParagraph>
        )}
        <InviteeList
          emails={emails}
          onDelete={removeEmail}
          className='my-4.5 mx-0'
        />
      </div>
    </div>
  )
}

export interface CreateTeamFormProps {
  onClick: (props: PayTeamFormProps) => void
  recommendations: Contact[]
  hasBucketPricing: boolean
  seats?: number
  isAnnual?: boolean
}

export const CreateTeamForm = ({
  onClick,
  recommendations,
  hasBucketPricing,
  seats,
  isAnnual,
}: CreateTeamFormProps) => {
  const userEmail = useAppSelector(selectEmail)
  const dispatch = useAppDispatch()
  const [emails, setEmails] = useState<string[]>([])
  const teamSize = emails.length + 1
  const [currentEmail, setCurrentEmail] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [hasTeamNameError, setHasTeamNameError] = useState<boolean>(false)
  const [remainingRecommendations, setRemainingRecommendations] = useState<
    Contact[]
  >([...recommendations])
  const [teamName, setTeamName] = useState('')
  const [loading, setLoading] = useState(false)

  const minimumBucket = getMinimumBucket(teamSize)

  const modalApi = useModalApi()

  const removeEmail = useCallback(
    (email: string) => {
      setEmails(emails.filter((e) => e !== email))
      const originalRec = recommendations.filter((rec) => rec.email === email)
      if (originalRec.length > 0) {
        setRemainingRecommendations([
          ...remainingRecommendations,
          originalRec[0],
        ])
      }
    },
    [emails, setEmails] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleAddClick = useCallback(() => {
    const trimmedEmail = currentEmail.trim()

    if (
      !isEmailValid(trimmedEmail) ||
      emails.some(
        (email) => email.toLowerCase() === trimmedEmail.toLowerCase()
      ) ||
      trimmedEmail === userEmail
    ) {
      setErrorMessage(`${trimmedEmail} is not a valid email.`)
      return
    }
    setErrorMessage(null)
    void dispatch(checkTeamEligibility(trimmedEmail))
      .then(unwrapResult)
      .then((res) => {
        if (res.isEligible) {
          setErrorMessage(null)
          setEmails([...emails, trimmedEmail])
          setCurrentEmail('')
        } else {
          setErrorMessage(
            'This member can’t be invited because they’re already part of an active team.'
          )
        }
        return
      })
      .catch(() => {
        setErrorMessage(
          'This member can’t be invited because they’re already part of an active team.'
        )
      })
  }, [currentEmail, emails, setEmails, setCurrentEmail]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleEnter = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleAddClick()
      }
    },
    [currentEmail, emails, setEmails, setCurrentEmail] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const addRecommendation = useCallback(
    (contact: Contact) => {
      if (!emails.includes(contact.email)) {
        setEmails([...emails, contact.email])
        setRemainingRecommendations(
          remainingRecommendations.filter((c) => contact.email !== c.email)
        )
      }
    },
    [emails, setEmails] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className='max-w-[840px] flex flex-col gap-8 pl-5 pt-5'>
      <EmailInput
        handleAddClick={handleAddClick}
        handleEmail={setCurrentEmail}
        setTeamName={setTeamName}
        removeEmail={removeEmail}
        handleEnter={handleEnter}
        currentEmail={currentEmail}
        errorMessage={errorMessage}
        emails={emails}
        teamName={teamName}
        hasRecommendations={recommendations.length > 0}
        hasTeamNameError={hasTeamNameError}
        seats={seats}
      />
      {recommendations.length > 0 && (
        <Recommendations
          recommendations={remainingRecommendations}
          onClick={addRecommendation}
        />
      )}
      {(hasBucketPricing || (emails.length > 0 && teamName)) && (
        <Button
          loading={loading}
          onClick={async () => {
            if (!teamName) {
              setHasTeamNameError(true)
              return
            } else if (seats && emails.length + 1 > seats) {
              modalApi.open('manage-team-seats', {
                title: `Switch to ${minimumBucket} seats to invite ${teamSize} or more members`,
                cancelText: 'Go back',
                isAnnual: !!isAnnual,
                onSubmit: async (newSeats) => {
                  await onClick({
                    createTeamProps: { emails, teamName },
                    newSeats,
                  })
                },
                minSeats: minimumBucket,
                initialSelection: minimumBucket,
              })
              return
            }
            setLoading(true)
            await onClick({ createTeamProps: { emails, teamName } })
            setLoading(false)
          }}
        >
          {hasBucketPricing
            ? emails.length
              ? 'Create team and send invites'
              : 'Create team and invite team members later'
            : 'Continue'}
        </Button>
      )}
    </div>
  )
}

const FormText = classed(
  'p',
  'text-sm font-semibold text-semantic-neutral-text-subtle'
)
