import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { type EventContentArg } from '@fullcalendar/core'

import { AvailabilityEvent } from './AvailabilityEvent'
import { ConnectedCalendarEvent } from './CalendarEvent'
import { CalendarEventTravelTime } from './CalendarEventTravelTime'
import { NewCalendarEvent } from './NewCalendarEvent'
import { TaskEvent } from './TaskEvent'

import { parseFullCalendarEventId } from '../../../../../state/calendarEvents/calendarEventsUtils'

/**
 * Component that FullCalendar renders to render an event on the calendar.
 * It currently handles parsing the ID value within the EventContentArg and
 * passes the relevant data onto child components. See the redux selector
 * `selectDisplayableCalendarEvents` for the format of the event object that
 * gets passed into FullCalendar
 *
 * @param arg Event object containing information about the event. Note that
 * this only contains barebones info about the event itself, and metadata on
 * the current event/calendar state. Each child component is responsible for
 * fetching the relevant data from redux
 * @constructor
 */
export const CalendarEventContainer = (arg: EventContentArg) => {
  const { groupId } = arg.event
  if (groupId === 'non-work-hours') {
    return (
      <div className='h-full bg-calendar-bg-non-work-hours opacity-20'></div>
    )
  }

  if (groupId === 'blocking-timeslots') {
    return (
      <div className='h-full bg-event-stripes from-transparent to-semantic-neutral-bg-active-default opacity-30'></div>
    )
  }

  const { type, id, modifier } = parseFullCalendarEventId(arg.event.id)
  if (!type) return null

  if (id == null) {
    if (type === 'new_event') {
      return <NewCalendarEvent event={arg} />
    }

    Sentry.captureException('Calendar event id is missing during parsing', {
      extra: { event: arg.event },
      tags: { position: 'CalendarEventContainer' },
    })
    return null
  }

  switch (type) {
    case 'task':
      return <TaskEvent id={id} event={arg} />
    case 'event':
      return modifier === 'travel' ? (
        <CalendarEventTravelTime id={id} event={arg} />
      ) : (
        <ConnectedCalendarEvent id={id} event={arg} />
      )
    case 'new_event':
      return <NewCalendarEvent event={arg} />
    case 'availability':
      return <AvailabilityEvent event={arg} />
    default:
      logInDev(`Unexpected calendar event type: ${type}`)
      return null
  }
}
