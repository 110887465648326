import {
  FieldButton,
  type FieldButtonProps,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { createDurationsFromText, formatToTaskDuration } from '@motion/ui-logic'

import { type DurationDropdownProps } from '~/areas/project-management/components'
import { ModalFieldButton } from '~/areas/task-project/components'
import { useDurations } from '~/areas/tasks/modals/task-modal/hooks'
import { useController } from 'react-hook-form'

import { useTaskDefinitionForm } from '../use-task-definition-form'

export const DurationField = () => {
  const { disabledFields } = useTaskDefinitionForm()

  const disabled = disabledFields.has('duration')

  return (
    <ModalFieldButton label='Duration' disabled={disabled} htmlFor='duration'>
      <div className='flex items-center gap-[3px]'>
        <DurationPopover variant='muted' size='small' />
      </div>
    </ModalFieldButton>
  )
}

type DurationPopoverProps = Pick<FieldButtonProps, 'variant' | 'size'>

function DurationPopover(buttonProps: DurationPopoverProps) {
  const { form, disabledFields } = useTaskDefinitionForm()
  const { control } = form

  const { field: durationField } = useController({
    name: 'duration',
    control,
  })

  const selectedDurationValue = durationField.value

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => <DurationPopoverContent close={close} />}
    >
      <FieldButton
        {...buttonProps}
        id='duration'
        iconOnly
        disabled={disabledFields.has('duration')}
      >
        <span className='whitespace-nowrap'>
          {formatToTaskDuration(selectedDurationValue)}
        </span>
      </FieldButton>
    </PopoverTrigger>
  )
}

function DurationPopoverContent({ close }: { close: () => void }) {
  const { form } = useTaskDefinitionForm()
  const { control } = form

  const durations = useDurations()

  const { field: completedDurationField } = useController({
    name: 'completedDuration',
    control,
  })
  const { field: durationField } = useController({
    name: 'duration',
    control,
  })

  const selectedCompletionValue = completedDurationField.value
  const selectedDurationValue = durationField.value

  const onChange: DurationDropdownProps['onChange'] = (duration) => {
    durationField.onChange(duration)
    completedDurationField.onChange(
      Math.min(duration ?? 0, selectedCompletionValue ?? 0)
    )
  }

  return (
    <SearchableList
      items={durations}
      computeKey={(item) => String(item.value)}
      computeSearchValue={(item) => item.label}
      computeSelected={(item) => item.value === selectedDurationValue}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
      filter={(search) => {
        if (search) {
          return createDurationsFromText(search)
        }
        return durations
      }}
      inputProps={{ placeholder: 'Choose or type a duration' }}
    />
  )
}
