import { LinkSolid } from '@motion/icons'
import {
  ButtonGroup,
  FileIcon,
  IconButton,
  Shortcut,
  Tooltip,
} from '@motion/ui/base'
import { prettyDateDay, templateStr } from '@motion/ui-logic'
import { type UploadedFileResponseSchema } from '@motion/zod/client'

import {
  BreadcrumbsLabel,
  type BreadWrapperPiece,
} from '~/global/components/labels'

import {
  AssigneeField,
  DateField,
  FieldBar,
  ResultItem,
  type ResultItemProps,
} from '../shared'

export interface AttachmentResultProps {
  item: UploadedFileResponseSchema
  onClick: ResultItemProps['onClick']
  onCopyLink: () => void
}

export function AttachmentResult({
  item,
  onClick,
  onCopyLink,
}: AttachmentResultProps) {
  return (
    <ResultItem
      renderIcon={() => (
        <FileIcon
          mimeType={item.mimeType}
          className='text-semantic-neutral-text-subtle'
        />
      )}
      title={item.fileName}
      onClick={onClick}
      renderControls={() => {
        return (
          <ButtonGroup size='small'>
            <Tooltip
              asChild
              renderContent={() =>
                templateStr('Copy link {{shortcut}}', {
                  shortcut: <Shortcut shortcut='mod+l' />,
                })
              }
            >
              <IconButton
                icon={LinkSolid}
                size='small'
                variant='outlined'
                sentiment='neutral'
                aria-label='Copy attachment link'
                onClick={(evt) => {
                  evt.stopPropagation()
                  onCopyLink()
                }}
              />
            </Tooltip>
          </ButtonGroup>
        )
      }}
    >
      <FieldBar>
        <BreadcrumbsLabel
          crumbs={[
            item.workspaceId &&
              ({
                type: 'workspace',
                value: item.workspaceId,
              } satisfies BreadWrapperPiece),
            item.projectId &&
              ({
                type: 'project',
                value: item.projectId,
              } satisfies BreadWrapperPiece),
            item.taskId &&
              ({
                type: 'text',
                value: item.task.name || '',
              } satisfies BreadWrapperPiece),
          ].filter(Boolean)}
        />
        {item.createdTime && (
          <DateField>{prettyDateDay(item.createdTime)}</DateField>
        )}
        <AssigneeField
          assigneeUserId={item.createdByUserId}
          assigneeName={item.createdByUser.name}
        />
      </FieldBar>
    </ResultItem>
  )
}
