import { Button, FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { labelColors } from '@motion/ui-logic'

import { LegacyColorDropdown } from '~/global/components/dropdowns'
import { useState } from 'react'

import { LabelColoredIcon } from '../../project-management/settings/common'

export type NewOptionModalProps = {
  onSave: (name: string, color: string) => Promise<void>
  onClose: () => void
  title: string
  placeholder?: string
}

export const NewOptionModal = ({
  onSave,
  onClose,
  title,
  placeholder,
}: NewOptionModalProps) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [currentColor, setCurrentColor] = useState<string>(labelColors[0])

  const onSubmit = async () => {
    setLoading(true)
    await onSave(name, currentColor)
    setLoading(false)
  }

  return (
    <FormModal
      visible
      title={title}
      onClose={onClose}
      submitAction={{
        onAction: onSubmit,
        disabled: loading || name.trim().length === 0,
        text: title,
      }}
    >
      <div className='flex items-center gap-2 self-stretch min-w-[400px]'>
        <LegacyColorDropdown
          colorOptions={labelColors}
          renderItem={(color, onClose) => (
            <Button
              iconOnly
              key={color}
              onClick={() => {
                setCurrentColor(color)
                onClose()
              }}
              size='small'
              variant='muted'
            >
              <LabelColoredIcon color={color} />
            </Button>
          )}
          trigger={
            <Button
              iconOnly
              variant='outlined'
              sentiment='neutral'
              size='small'
            >
              <div className='p-1'>
                <LabelColoredIcon color={currentColor} />
              </div>
            </Button>
          }
        />
        <div className='w-full'>
          <TextField
            autoFocus
            placeholder={placeholder}
            size='normal'
            value={name}
            onChange={setName}
          />
        </div>
      </div>
    </FormModal>
  )
}
