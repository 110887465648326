import { isAutoScheduledStatus } from '@motion/shared/common'
import {
  isMeetingTask,
  isUnscheduledSchedulingTask,
} from '@motion/ui-logic/pm/task'
import { type StatusSchema, type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { getTaskStatusVariant } from '../../utils'
import { useTaskStatusUpdater } from '../updaters'

export function useTaskStatusData(task: TaskSchema) {
  const updater = useTaskStatusUpdater()

  const isMeeting = isMeetingTask(task)

  const disabled = task.archivedTime != null || isMeeting

  const statusTaskVariant = getTaskStatusVariant(task)

  const hasValidData = !isMeeting

  const shouldExcludeResolved = isUnscheduledSchedulingTask(task)

  const onChange = useCallback(
    (statusId: TaskSchema['statusId']) => {
      void updater(task, statusId)
    },
    [task, updater]
  )

  const isStatusAllowed = useCallback(
    (status: StatusSchema) => {
      if (task.type !== 'RECURRING_INSTANCE') return true

      // For recurring instances, when the task isn't auto-scheduled, we need to filter out the statuses not allowing auto-scheduled
      // This function is a helper usually used with FilteredWorkspaceContext to auto-filter these available statuses
      if (task.isAutoScheduled && !isAutoScheduledStatus(status)) return false
      return true
    },
    [task]
  )

  return {
    statusTaskVariant,
    onChange,
    disabled,
    isStatusAllowed,
    hasValidData,
    shouldExcludeResolved,
  }
}
