import { classed } from '@motion/theme'

export const StageTooltipContainer = classed('div', {
  base: `
    w-[250px]
    flex flex-col
    pb-1
    bg-dropdown-bg
  `,
  variants: {
    showDragHeader: {
      true: `
        [&>div:nth-child(n+3)]:border-t
        [&>div:nth-child(n+3)]:border-semantic-neutral-border-default
      `,
      false: `
        [&>div:not(:first-child)]:border-t
        [&>div:not(:first-child)]:border-semantic-neutral-border-default
      `,
    },
  },
})

export const HeaderSection = classed('div', {
  base: 'flex flex-col gap-2 p-2',
})

export const MetricsSection = classed('div', {
  base: 'flex flex-col p-2 pt-3 gap-2 [&>div:not(:first-child)]:border-t [&>div:not(:first-child)]:border-semantic-neutral-border-default',
})

export const SpaceBetweenRow = classed('div', {
  base: 'flex justify-between',
})

export const StatusLabel = classed('div', {
  base: 'flex items-center gap-1.5',
})

export const SmallText = classed('span', {
  base: 'text-2xs text-semantic-neutral-text-default',
})

export const SubtleText = classed('span', {
  base: 'text-2xs text-semantic-neutral-text-subtle',
})

export const DragLabelHeader = classed('div', {
  base: `bg-semantic-neutral-surface-overlay-bg-subtlest flex py-1 px-2
    items-center gap-1.5 self-stretch
    text-semantic-neutral-text-subtle
    text-2xs font-normal leading-4
  `,
})
