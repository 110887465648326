import { type ProjectSchema } from '@motion/zod/client'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useProjectCustomFieldUpdater } from '~/areas/project/hooks'
import { type ControlledCustomFieldsSidebarProps } from '~/areas/task-project/components'
import { useProject } from '~/global/hooks'

import { ProjectCustomFieldsSidebar } from './custom-fields-sidebar'

type ProjectCustomFieldsSectionProps = {
  projectId: ProjectSchema['id']
  onChange: ControlledCustomFieldsSidebarProps['onChange']
}

export const CustomFieldsPanelSection = ({
  projectId,
  onChange: onChangeFromProps,
}: ProjectCustomFieldsSectionProps) => {
  const updateProjectCustomFields = useProjectCustomFieldUpdater()
  const { getCustomField } = useCustomFieldsFns()
  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project is required')
  }

  const onChange: ControlledCustomFieldsSidebarProps['onChange'] = async (
    customFieldId,
    valueArg
  ) => {
    const customField = getCustomField(customFieldId)
    if (customField == null) {
      throw new Error('Custom field not found')
    }

    await updateProjectCustomFields(projectId, customField, valueArg)
    onChangeFromProps?.(customFieldId, valueArg)
  }

  return (
    <ProjectCustomFieldsSidebar projectId={projectId} onChange={onChange} />
  )
}
