import { SearchableList } from '@motion/ui/base'
import { isUnassignedUser } from '@motion/ui-logic'
import { useModalApi } from '@motion/web-common/modals'
import { type TaskSchema } from '@motion/zod/client'

import { useWorkspaceById } from '~/global/hooks'

import { AssigneeDropdownBottomSection } from './assignee-dropdown-bottom-section'

import { type SharedListProps } from '../types'

type SingleListContentProps = SharedListProps & {
  onChange: (assigneeId: TaskSchema['assigneeUserId']) => void
}

export function SingleListContent({
  onChange,
  close,
  workspaceId,
  hideBottomActionsSection = false,
  renderContentTopSection,
  disableOptionFn,
  ...rest
}: SingleListContentProps) {
  const modalApi = useModalApi()
  const workspace = useWorkspaceById(workspaceId)

  if (workspace == null) return null

  const isTeamWorkspace = workspace.type === 'TEAM'

  return (
    <>
      {renderContentTopSection?.()}
      <SearchableList
        itemType='select'
        {...rest}
        onSelect={(item) => {
          if (disableOptionFn?.(item)) {
            return
          }

          if (isUnassignedUser(item)) {
            onChange(null)
          } else {
            onChange(item.userId)
          }
          close()
        }}
      />
      {isTeamWorkspace && !hideBottomActionsSection && (
        <AssigneeDropdownBottomSection
          close={close}
          modalApi={modalApi}
          onChange={onChange}
          workspace={workspace}
          workspaceId={workspaceId}
        />
      )}
    </>
  )
}
