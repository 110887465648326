import { CalendarSolid } from '@motion/icons'
import { IconButton, PopoverTrigger } from '@motion/ui/base'

import { ConnectedCalendarsList } from '~/areas/project-management/components/filters'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

export const ConnectedShowCalendarsButton = () => {
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => <ConnectedCalendarsList />}
    >
      <IconButton
        disabled={!mainEmailAccount}
        icon={CalendarSolid}
        size='small'
        variant='outlined'
        sentiment='neutral'
      />
    </PopoverTrigger>
  )
}
