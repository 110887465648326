import { type UploadedFileSchema } from '@motion/rpc-types'
import { Tag } from '@motion/ui/base'

import { useAttachmentActions } from '~/areas/attachments/hooks'
import { useFileById } from '~/global/rpc/v2/files'
import { type MouseEvent, useCallback } from 'react'

export type AttachmentTagProps = {
  attachmentId: UploadedFileSchema['id']
  onDelete?: (attachmentId: UploadedFileSchema['id']) => void
}

export function AttachmentTag({ attachmentId, onDelete }: AttachmentTagProps) {
  const { data: attachment } = useFileById({ id: attachmentId })

  const { deleteAttachment } = useAttachmentActions()

  const handleAttachmentDelete = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      if (!attachment) return

      const { deleted } = await deleteAttachment(attachment)

      if (deleted && onDelete) {
        onDelete(attachment.id)
      }
    },
    [attachment, deleteAttachment, onDelete]
  )

  if (!attachment) {
    return null
  }

  return (
    <Tag
      color='grey'
      variant='subtle'
      size='normal'
      onRemove={handleAttachmentDelete}
    >
      {attachment.fileName}
    </Tag>
  )
}
