import { type DateLike, parseDate } from '@motion/utils/dates'

import { TimeField } from './time-field'

import {
  useScheduleAssistantCalendarContext,
  useUpdateSelectedCalendarEvent,
} from '../../../hooks'

export function StartTimeField() {
  const [{ selectedCalendarEvent }] = useScheduleAssistantCalendarContext()
  const updateSelectedEvent = useUpdateSelectedCalendarEvent()
  const startDateTime = selectedCalendarEvent?.new?.start
  const endDateTime = selectedCalendarEvent?.new?.end

  // Both start and end date should never be null
  if (startDateTime == null || endDateTime == null) return null

  const timeFieldStartTime = parseDate(startDateTime).startOf('day')

  const onChange = (value: DateLike) => {
    const newStartDateTime = parseDate(value)
    let newEndDateTime = parseDate(endDateTime)

    if (newStartDateTime > parseDate(endDateTime)) {
      newEndDateTime = newStartDateTime.plus({ minutes: 30 })
    }

    updateSelectedEvent({
      new: {
        start: newStartDateTime.toISO(),
        end: newEndDateTime.toISO(),
      },
    })
  }

  return (
    <TimeField
      value={startDateTime}
      startDateTime={timeFieldStartTime}
      onChange={onChange}
      ariaLabel='Start Time'
    />
  )
}
