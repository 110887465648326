import { twMerge } from 'tailwind-merge'

interface FullCalendarThemeSelectorProps {
  children: React.ReactNode
  className?: string
}

export const FullCalendarThemeSelector = ({
  children,
  className = '',
}: FullCalendarThemeSelectorProps) => {
  return (
    <div className={twMerge('bg-calendar-bg-default h-full w-full', className)}>
      {children}
    </div>
  )
}
