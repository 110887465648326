import { classed } from '@motion/theme'
import { Button, ButtonGroup, useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useCreateTask } from '~/areas/tasks/hooks'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { FormProvider, useForm } from 'react-hook-form'

import {
  InlineTaskCreationAssigneeField,
  InlineTaskCreationAutoscheduleToggle,
  InlineTaskCreationBlockingTasksField,
  InlineTaskCreationDescriptionField,
  InlineTaskCreationDueDateField,
  InlineTaskCreationDurationField,
  InlineTaskCreationLabelsField,
  InlineTaskCreationNameField,
  InlineTaskCreationPriorityLevelField,
  InlineTaskCreationStatusField,
} from './fields'
import {
  type InlineTaskCreationFields,
  type InlineTaskCreationFormProps,
} from './form-types'
import { useInlineTaskDefaultValues } from './hooks'

import { INLINE_ADD_TASK_HEIGHT } from '../utils'

export const InlineTaskCreationForm = ({
  close,
  workspaceId,
  projectId,
  initialData,
}: InlineTaskCreationFormProps) => {
  const createTask = useCreateTask()
  const context = useRouteAnalyticsMetadata()

  const defaultValues = useInlineTaskDefaultValues({
    workspaceId,
    projectId,
    initialData,
  })

  const form = useForm<InlineTaskCreationFields>({
    defaultValues,
    mode: 'onChange',
  })

  function onClose() {
    close()
    // Reset the form after a delay to avoid a flash of the default values
    setTimeout(() => form.reset(), 200)
  }

  const onSubmit = form.handleSubmit(async (formData) => {
    try {
      recordAnalyticsEvent('TASK_SIDEBAR_INLINE_TASK_CREATE', context)
      await createTask(formData)
      onClose()
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'inline task modal submit',
        },
      })
      throw e
    }
  })

  useShortcut('mod+enter', () => onSubmit(), {
    enabled: form.formState.isValid,
  })

  return (
    <FormProvider {...form}>
      <InlineTaskCreationFormContainer
        style={{
          height: INLINE_ADD_TASK_HEIGHT,
        }}
      >
        <div className='flex gap-1.5'>
          <div className='pt-1'>
            <InlineTaskCreationStatusField workspaceId={workspaceId} />
          </div>
          <div className='flex grow flex-col gap-1'>
            <div className='flex grow items-center pr-1'>
              <InlineTaskCreationNameField />
              <InlineTaskCreationAutoscheduleToggle />
            </div>
            <InlineTaskCreationDescriptionField />
            <div className='flex gap-1 ml-1'>
              <InlineTaskCreationPriorityLevelField />
              <InlineTaskCreationLabelsField workspaceId={workspaceId} />
              <InlineTaskCreationAssigneeField workspaceId={workspaceId} />
              <InlineTaskCreationDurationField />
              <InlineTaskCreationDueDateField />
            </div>
          </div>
        </div>
        <div className='flex justify-between'>
          <div className='ml-4'>
            <InlineTaskCreationBlockingTasksField
              projectId={projectId}
              workspaceId={workspaceId}
              stageDefinitionId={defaultValues.stageDefinitionId}
            />
          </div>

          <ButtonGroup>
            <Button
              sentiment='neutral'
              size='xsmall'
              variant='muted'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size='xsmall'
              onClick={onSubmit}
              disabled={!form.formState.isValid}
              loading={form.formState.isSubmitting}
              shortcut='mod+enter'
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </InlineTaskCreationFormContainer>
    </FormProvider>
  )
}

const InlineTaskCreationFormContainer = classed(
  'div',
  `
  flex flex-col gap-3
  w-full
  px-2 pt-1.5 pb-4
  overflow-y-auto
  z-[2]
  border-t-[0.5px] border-b-[0.5px] border-semantic-neutral-border-default
  bg-semantic-neutral-bg-default
  `
)
