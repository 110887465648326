import { logInDev } from '@motion/web-base/logging'

import { copyToClipboard } from '../localServices/clipboard'

const getHtmlText = (htmlText: string | undefined, keepNewLine: boolean) => {
  if (!htmlText) {
    return ''
  }
  return keepNewLine
    ? htmlText
        .replace(/<br>/g, '\n')
        .replace(/<\/p>/g, '\n')
        .replace(/<[^>]*>?/gm, '')
        .replace(/\u200b/g, '')
        .replace(/&nbsp;/g, '\n')
        .replace(/[ \t]{2,}/g, ' ')
        .trim()
    : htmlText
        .replace(/<[^>]*>?/gm, ' ')
        .replace(/\u200b/g, '')
        .replace('&nbsp;', ' ')
        .replace(/\s+/g, ' ')
        .trim()
}

export const copyToClipboardAsHtml = async (
  text: string | undefined,
  fallback: string,
  gmail: boolean
) => {
  const div = document.createElement('div')
  div.innerHTML = text ?? ''
  div.style.position = 'fixed'
  div.style.backgroundColor = 'white'
  if (gmail) {
    div.style.fontSize = '13px'
    div.style.color = '#222222'
    div.style.fontFamily = 'Arial, Helvetica, sans-serif'
  }
  document.body.appendChild(div)

  try {
    window.getSelection()?.removeAllRanges()
    const range = document.createRange()
    range.selectNode(div)
    window.getSelection()?.addRange(range)
    if (window.getSelection()?.toString()) {
      document.execCommand('copy')
    } else {
      await copyToClipboard({ text: getHtmlText(text, true) })
    }
    window.getSelection()?.removeAllRanges()
  } catch (e) {
    logInDev(e)
  } finally {
    document.body.removeChild(div)
  }
}
