import { byProperty, Compare } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'

import { useLookup } from '~/global/cache'
import { createTaskProxy } from '~/global/proxies'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useMemo } from 'react'

/*
 * Given a parent task id, returns the chunks data with proxied info for that task sorted by scheduled start date.
 */
export const useTaskChunksData = (parentTaskId?: string) => {
  const { data: parentData } = useTaskByIdV2(
    { id: parentTaskId ?? '' },
    // We shouldn't need to recall the parent task if it's already loaded
    { enabled: !!parentTaskId, staleTime: Infinity }
  )

  const lookup = useLookup()

  return useMemo(() => {
    if (!parentData || parentData.type === 'RECURRING_TASK') return []

    const parentProxy = createTaskProxy(parentData, lookup)
    if (
      !parentProxy ||
      (parentProxy.type !== 'NORMAL' &&
        parentProxy.type !== 'RECURRING_INSTANCE')
    )
      return []

    if (parentProxy.chunks.some((x) => x == null)) {
      Sentry.captureException(
        new Error('Chunks in proxy contains undefined elements'),
        {
          tags: { position: 'useTaskChunksData' },
          extra: {
            taskId: parentProxy.id,
            chunkIds: parentProxy.chunkIds,
            chunkArrayIds: parentProxy.chunks.map((chunk) => chunk?.id),
          },
        }
      )
    }

    return parentProxy.chunks
      .filter(Boolean)
      .map((c) => {
        return createTaskProxy(c, lookup)
      })
      .sort(byProperty('scheduledStart', Compare.caseSensitive))
  }, [lookup, parentData])
}
