import { LoadingSpinner } from '@motion/ui/base'
import { logInDev, makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { stats } from '@motion/web-common/performance'

import { useEffect } from 'react'

const log = makeLog('loading-page')

type LoadingPageProps = {
  id: string
  message?: string
  subMessage?: string
  extra?: Record<string, unknown>
}

export const LoadingPage = ({
  message,
  subMessage,
  id,
  extra,
}: LoadingPageProps) => {
  useEffect(() => {
    const startTime = stats.mark(`loading-page-show-${id}`)
    return () => {
      const stat = stats.span({
        start: startTime,
        end: performance.now(),
        name: 'loading-page',
        tags: [`page:${id}`],
      })

      if (stat == null) return
      logInDev(
        `Time showing preparing motion "${id}": ${stat.duration.toFixed(2)}ms`
      )
    }
  }, [id])

  useEffect(() => {
    let didTimeout = false
    const startTime = performance.now()
    const handle = setTimeout(() => {
      didTimeout = true
      Sentry.captureException(new Error(`Long loading screen`), {
        extra: {
          id,
        },
      })
    }, 20_000)
    return () => {
      clearTimeout(handle)
      if (!didTimeout) return
      Sentry.captureException(new Error(`Loading screen time`), {
        extra: {
          id,
          duration: Math.floor(performance.now() - startTime),
        },
      })
    }
  }, [id])

  log('visible', id)

  return (
    <>
      <div className='absolute left-0 top-0 w-full h-8 electron-drag' />
      <div className='static-loading relative' data-id={id}>
        <h2>
          <LoadingSpinner />
          <span>{message || 'Preparing Motion...'}</span>
        </h2>
        {subMessage && <h3>{subMessage}</h3>}
      </div>
    </>
  )
}
