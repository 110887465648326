import { type GroupByTypes } from '../../grouping'

const disabledMeetingTaskField: Extract<
  GroupByTypes,
  'deadline' | 'startDate' | 'status' | 'priority' | 'user'
>[] = ['deadline', 'startDate', 'status', 'priority', 'user']

export function isDisabledMeetingTaskField(key: string | undefined) {
  return key != null && disabledMeetingTaskField.includes(key)
}
