import { type COLOR } from '@motion/shared/common'
import { type AvailableCustomFieldTypes } from '@motion/ui-logic'
import { createLookup } from '@motion/utils/object'
import {
  type FolderSchema,
  type PriorityLevelSchema,
  type ProjectSchema,
} from '@motion/zod/client'

import {
  useProjectDeadlineUpdater,
  useProjectStartDateUpdater,
  useProjectStatusUpdater,
  useProjectUpdater,
} from '~/areas/project/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback, useMemo } from 'react'

import {
  type CustomFieldMoveHandlerParams,
  useCustomFieldMoveHandler,
} from './use-custom-field-move-handler'

import { type GroupedNode, isNoneGroup, type Tree } from '../../grouping'
import { getMovementType } from '../get-movement-type'

type ProjectFieldUpdaterLookupArgs = {
  entity: ProjectSchema
  groupNode: GroupedNode
  sourceGroupNode: GroupedNode
  workspaceId: string
}

/**
 * Lookup field handler to move a project between groups.
 *
 * Returning false indicates the operation was not done (cancelled or errored).
 */
type ProjectFieldUpdaterLookup = {
  status: (args: ProjectFieldUpdaterLookupArgs) => Promise<boolean>
  priority: (args: ProjectFieldUpdaterLookupArgs) => Promise<void>
  label: (args: ProjectFieldUpdaterLookupArgs) => Promise<void>
  user: (args: ProjectFieldUpdaterLookupArgs) => Promise<void>
  startDate: (args: ProjectFieldUpdaterLookupArgs) => Promise<boolean>
  deadline: (args: ProjectFieldUpdaterLookupArgs) => Promise<boolean>
  workspace: (args: ProjectFieldUpdaterLookupArgs) => Promise<void>
  folder: (args: ProjectFieldUpdaterLookupArgs) => Promise<void>
  color: (args: ProjectFieldUpdaterLookupArgs) => Promise<void>
  default: (args: ProjectFieldUpdaterLookupArgs) => Promise<boolean>
} & {
  [key in AvailableCustomFieldTypes]: (
    args: CustomFieldMoveHandlerParams
  ) => Promise<boolean>
}

/*
 * This hook is used to move a project from one group to another.
 * It is used in the Kanban board to move project between columns.
 *
 * @returns A function that moves a project from one group to another.
 */
export const useMoveProject = () => {
  const { getWorkspaceStatusByName, getWorkspaceLabelByName } =
    useWorkspaceFns()

  const updateProject = useProjectUpdater()
  const updateProjectStartDate = useProjectStartDateUpdater()
  const updateProjectDeadline = useProjectDeadlineUpdater()
  const updateProjectStatus = useProjectStatusUpdater()
  const customFieldMoveHandler = useCustomFieldMoveHandler()

  const updateProjectPriority = useCallback(
    (project: ProjectSchema, priority: PriorityLevelSchema) => {
      updateProject(project, { priorityLevel: priority })
    },
    [updateProject]
  )

  const updateProjectLabels = useCallback(
    (project: ProjectSchema, labelIds: string[]) => {
      updateProject(project, { labelIds })
    },
    [updateProject]
  )

  const updateProjectManager = useCallback(
    (project: ProjectSchema, managerId: string | null) => {
      updateProject(project, { managerId })
    },
    [updateProject]
  )

  const updateProjectFolder = useCallback(
    (project: ProjectSchema, folder: FolderSchema) => {
      updateProject(project, {
        folderId: folder.id,
        workspaceId: folder.targetId,
      })
    },
    [updateProject]
  )

  const updateProjectColor = useCallback(
    (project: ProjectSchema, color: COLOR) => {
      updateProject(project, {
        color,
      })
    },
    [updateProject]
  )

  const doMoveProject = useMemo(() => {
    return createLookup<ProjectFieldUpdaterLookup>({
      status: async ({
        entity,
        groupNode,
        workspaceId,
      }: {
        entity: ProjectSchema
        groupNode: GroupedNode
        workspaceId: string
      }) => {
        const statusId = getWorkspaceStatusByName(
          workspaceId,
          groupNode.key
        )?.id

        if (!statusId) {
          return false
        }

        return updateProjectStatus(entity, statusId)
      },
      priority: async ({
        entity,
        groupNode,
      }: {
        entity: ProjectSchema
        groupNode: GroupedNode
      }) => {
        return updateProjectPriority(
          entity,
          groupNode.key as PriorityLevelSchema
        )
      },
      label: async ({
        entity: project,
        groupNode,
        sourceGroupNode,
        workspaceId,
      }) => {
        if (isNoneGroup(groupNode)) {
          return updateProjectLabels(project, [])
        }

        const labelId = getWorkspaceLabelByName(workspaceId, groupNode.key)?.id
        const sourceLabelId = getWorkspaceLabelByName(
          workspaceId,
          sourceGroupNode.key
        )?.id

        if (!labelId || labelId === sourceLabelId || !sourceLabelId) {
          return
        }

        // If the source group is none group, just add the new label
        if (isNoneGroup(sourceGroupNode)) {
          return updateProjectLabels(project, [labelId])
        }

        // If the new label is already in the list, just remove the source label
        if (project.labelIds.includes(labelId)) {
          return updateProjectLabels(
            project,
            project.labelIds.filter((id) => id !== sourceLabelId)
          )
        }

        // Get project label IDs, replace the source label ID with the new label ID
        const newLabelIds = project.labelIds.map((id) => {
          if (id === sourceLabelId) {
            return labelId
          }

          return id
        })

        // Ensure the new label ID is not already in the list

        return updateProjectLabels(project, newLabelIds)
      },
      user: async ({
        entity,
        groupNode,
      }: {
        entity: ProjectSchema
        groupNode: GroupedNode
      }) => {
        if (isNoneGroup(groupNode)) {
          return updateProjectManager(entity, null)
        }

        return updateProjectManager(entity, groupNode.key)
      },
      startDate: async ({
        entity,
        groupNode,
      }: {
        entity: ProjectSchema
        groupNode: GroupedNode
      }) => {
        return updateProjectStartDate(entity, groupNode.value.value)
      },
      deadline: async ({ entity, groupNode }) => {
        return updateProjectDeadline(entity, groupNode.value.value)
      },
      workspace: async () => {},
      folder: async ({ entity, groupNode }) => {
        return updateProjectFolder(entity, groupNode.value.value)
      },
      color: async ({ entity, groupNode }) => {
        return updateProjectColor(entity, groupNode.value.value)
      },
      text: customFieldMoveHandler,
      date: customFieldMoveHandler,
      number: customFieldMoveHandler,
      url: customFieldMoveHandler,
      select: customFieldMoveHandler,
      multiSelect: customFieldMoveHandler,
      person: customFieldMoveHandler,
      multiPerson: customFieldMoveHandler,
      default: async () => false,
    })
  }, [
    customFieldMoveHandler,
    getWorkspaceLabelByName,
    getWorkspaceStatusByName,
    updateProjectColor,
    updateProjectDeadline,
    updateProjectFolder,
    updateProjectLabels,
    updateProjectManager,
    updateProjectPriority,
    updateProjectStartDate,
    updateProjectStatus,
  ])

  return useCallback(
    async <T extends GroupedNode>({
      item,
      group,
      sourceGroup,
    }: {
      item: GroupedNode
      group: Tree<T>
      sourceGroup: Tree<T>
    }) => {
      const groupNode = group.item
      const sourceGroupNode = sourceGroup?.item

      const project = item.value.value
      const workspaceId: string = item.value.value.workspaceId

      if (!groupNode || !sourceGroupNode || !project || !workspaceId) {
        return
      }

      const movementType = getMovementType(groupNode.value.type)

      return doMoveProject(movementType)({
        entity: project,
        groupNode,
        sourceGroupNode,
        workspaceId,
      })
    },
    [doMoveProject]
  )
}
