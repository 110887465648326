import { FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { useHasTreatment } from '@motion/web-common/flags'

import type { Row } from '@tanstack/react-table'
import { twMerge } from 'tailwind-merge'

export function ToggleTasksButton({ row }: { row?: Row<any> }) {
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')
  const expanded = row?.getIsExpanded() ?? false

  return (
    <button
      type='button'
      className={twMerge(
        'w-5 h-5 flex items-center justify-center hover:bg-button-neutral-outlined-bg-hover',
        hasBetterResizeStages && 'size-4'
      )}
      onClick={row?.getToggleExpandedHandler()}
    >
      <ExpandIcon expanded={expanded} />
    </button>
  )
}

const ExpandIcon = classed(FilledChevronRightSolid, {
  base: `
  transition-transform
  size-3
  text-button-neutral-outlined-icon-default`,
  variants: {
    expanded: {
      true: 'rotate-90',
      false: 'rotate-0',
    },
  },
  defaultVariants: {
    expanded: 'true',
  },
})
