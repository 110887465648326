import { useMemo } from 'react'

import { useCalendarState } from './use-calendar-state'

export const useIsCalendarEventSelected = (id: string) => {
  const [calendarState] = useCalendarState()

  return useMemo(() => {
    return Boolean(calendarState.selectedCalendarEvent?.id.endsWith(id))
  }, [calendarState.selectedCalendarEvent?.id, id])
}
