import { maxBy } from '@motion/utils/array'

import { deepCloneTree } from './deep-clone-tree'
import { type GroupDefinition, type GroupedNode } from './types'

export type Tree<T extends GroupedNode = GroupedNode> = {
  key: string
  qualifiedKey: string
  depth: number
  maxDepth: number
  groups: GroupDefinition<any>[]
  count: number

  parent: Tree<T> | null
  children: Tree<T>[]

  item: T | null

  // The direct `GroupedNode` children of this tree
  values: T[]

  // All of the items (tasks / projects) that are within this tree
  allItems: any[]

  /**
   * Performs a deep clone of the tree, keeping proxies.
   *
   * This function recursively clones the tree and its nested properties.
   * It handles circular references and preserves proxy objects without cloning them.
   *
   * @returns A deep clone of this tree.
   */
  clone(): Tree<T>
}

export function findNodeOrFirst<T extends GroupedNode = GroupedNode>(
  tree: Tree<T>,
  key: string
) {
  return tree.children.find((x) => x.key === key) ?? tree.children[0]
}

export function createTree<T extends GroupedNode = GroupedNode>(
  node: T,
  groups: GroupDefinition<any>[]
): Tree<T> {
  if (groups.length === 0) {
    return {
      key: node.key,
      qualifiedKey: node.qualifiedKey,
      depth: -1,
      maxDepth: 0,
      count: node.count,
      groups,
      parent: null,
      values: (node.children ?? []) as T[],
      children: (node.children ?? []) as unknown as Tree<T>[],
      item: node,
      allItems: node.items ?? [],
      clone,
    }
  }
  return createSubTree(node, null, -1, groups) as Tree<T>
}

function createSubTree<T extends GroupedNode = GroupedNode>(
  node: T,
  parent: Tree<T> | null,
  depth: number,
  groups: GroupDefinition<any>[]
) {
  if (depth >= groups.length) return null

  const tree: Tree<T> = {
    key: node.key,
    qualifiedKey: node.qualifiedKey,
    depth,
    maxDepth: -1,
    count: node.count,
    groups,
    parent,
    values: [],
    children: [],
    item: node,
    allItems: node.items,
    clone,
  }

  tree.children = ((node.children ?? []) as T[])
    .map((child) => {
      return createSubTree(child, tree, depth + 1, groups)
    })
    .filter(Boolean)

  tree.values = (node.children ?? []) as T[]

  const populateMaxDepth = (node: Tree): number => {
    const max =
      node.children.length === 0
        ? 0
        : maxBy(node.children, populateMaxDepth) + 1
    node.maxDepth = max
    return max
  }
  populateMaxDepth(tree)

  return tree
}

function clone<T extends GroupedNode = GroupedNode>(this: Tree<T>): Tree<T> {
  return deepCloneTree(this)
}
