import { type FieldOperationSchema } from '@motion/shared/custom-fields'
import { omit } from '@motion/utils/core'
import { entries, stripUndefined } from '@motion/utils/object'
import {
  type BulkFieldUpdateSchema,
  type NormalTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

/**
 * Returns an object with the field updates to optimistically apply on a task
 * based on the bulk updates object and an existing task
 *
 * @param update BulkFieldUpdateSchema
 * @param cachedTask TaskSchema
 * @returns Partial<NormalTaskSchema>
 */
export function getBulkOptimisticTaskUpdates(
  update: BulkFieldUpdateSchema,
  cachedTask: TaskSchema
) {
  let taskUpdates: Partial<NormalTaskSchema> = stripUndefined(
    omit(update, ['type', 'labels', 'customFieldValues'])
  )

  const newLabelIds = getOptimisticBulkOpMultiIds(
    'labelIds' in cachedTask ? cachedTask.labelIds : null,
    update.labels
  )

  if (newLabelIds != null) {
    taskUpdates.labelIds = newLabelIds
  }

  if (update.customFieldValues != null) {
    const existingCustomValues =
      'customFieldValues' in cachedTask ? cachedTask.customFieldValues : null

    const newValues = entries(update.customFieldValues).reduce(
      (acc, [instanceId, value]) => {
        if (Array.isArray(value.value)) {
          acc[instanceId] = {
            type: value.type,
            value:
              getOptimisticBulkOpMultiIds(
                acc[instanceId]?.value ?? ([] as any),
                value.value
              ) ?? (null as any),
          }
        } else {
          acc[instanceId] = value as any
        }

        return acc
      },
      { ...existingCustomValues }
    )

    taskUpdates.customFieldValues = newValues
  }

  return taskUpdates
}

/**
 * Returns a new array of ids based on the existing ids and the provided updates object
 * Used for optimistic update for Bulk operations
 */
function getOptimisticBulkOpMultiIds(
  existingIds: string[] | null,
  updates:
    | {
        id: string
        operation: FieldOperationSchema
      }[]
    | undefined
) {
  if (existingIds == null || updates == null) return

  const idsToAdd = updates
    .filter((l) => l.operation === 'add' && !existingIds.includes(l.id))
    .map((l) => l.id)
  const idsToRemove = updates
    .filter((l) => l.operation === 'remove')
    .map((l) => l.id)

  return [...existingIds.filter((id) => !idsToRemove.includes(id)), ...idsToAdd]
}
