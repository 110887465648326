import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { time } from '@motion/utils/debug'

import { useAddTaskStateValue } from '~/areas/tasks/states'
import { type TaskWithRelations } from '~/global/proxies'
import { useEffect, useMemo } from 'react'

import { useTreeForTaskNavigation } from './hooks'
import { useTreeGroupOverrides } from './hooks/use-tree-group-overrides'

import {
  buildTreeGroup,
  CurrentTreeKey,
  type GroupDefinition,
  type GroupedNode,
} from '../grouping'
import { TreeList } from '../tree-list'
import { type TreeListRowValueType } from '../tree-list'
import { getTaskInferItem } from '../tree-list/cells/hooks/utils'
import { useTaskColumns } from '../tree-list/columns'
import { useViewState } from '../view-state'

export type TaskTreeListProps = {
  tasks: TaskWithRelations[]
  groupBy: GroupDefinition<TaskWithRelations>[]
  hideEmptyGroups: boolean
  hideInlineAddRow: boolean
}

export const TaskTreeList = (props: TaskTreeListProps) => {
  const addTaskState = useAddTaskStateValue()
  const [viewState] = useViewState()

  const overrides = useTreeGroupOverrides('tasks')

  const grouped = useMemo(() => {
    return time('build-tree-group.total', () => {
      return buildTreeGroup(props.groupBy, overrides)
        .add('task', props.tasks)
        .buildTree<GroupedNode<TreeListRowValueType>>({
          hideEmptyGroups: props.hideEmptyGroups,
          sortOrder: viewState.groupBy.order,
          footer(row) {
            return props.hideInlineAddRow
              ? []
              : [
                  {
                    key: 'totals-row',
                    type: 'task-totals' as const,
                    ...getTaskInferItem({ row, addTaskState }),
                  },
                ]
          },
        })
    })
  }, [
    props.groupBy,
    props.tasks,
    props.hideEmptyGroups,
    props.hideInlineAddRow,
    overrides,
    viewState.groupBy.order,
    addTaskState,
  ])

  const setCurrentTree = useSharedStateSendOnly(CurrentTreeKey)
  useEffect(() => setCurrentTree(grouped), [grouped, setCurrentTree])

  const columns = useTaskColumns()
  useTreeForTaskNavigation(grouped)

  return <TreeList tree={grouped} columns={columns} enableSelection />
}
