/**
 * Get the stage and task index from the name
 * @param name - name looks like 'stages.1.tasks.1.assigneeUserId' or 'stages.1.tasks.1.assigneeVariableKey'
 * @returns
 */
export function getIndexFromName(name: string) {
  const stageIndexString = name.split('.')[1]
  const taskIndexString = name.split('.')[3]

  return {
    stageIndex:
      stageIndexString && !isNaN(parseInt(stageIndexString, 10))
        ? parseInt(stageIndexString, 10)
        : null,
    taskIndex:
      taskIndexString && !isNaN(parseInt(taskIndexString, 10))
        ? parseInt(taskIndexString, 10)
        : null,
  }
}
