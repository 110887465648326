import { classed } from '@motion/theme'

export const Text = classed('span', {
  base: `
    truncate text-left

    text-semantic-neutral-text-default
    aria-[disabled]:text-semantic-neutral-text-disabled
  `,
  variants: {
    size: {
      normal: 'text-sm',
      small: 'text-xs',
      xsmall: 'text-2xs',
    },
  },
  defaultVariants: {
    size: 'normal',
  },
})
