import { FilterDateButton } from './filter-date-button'

import { useFieldFilter } from '../../context'
import { type DropdownFilterProps } from '../types'

export const ConnectedUpdatedTimeFilter = (props: DropdownFilterProps) => {
  if (props.target === 'projects') {
    return <ProjectFilter {...props} />
  }

  return <TaskFilter {...props} />
}

const ProjectFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(props.target, 'updatedTime')

  return (
    <FilterDateButton
      label='Updated'
      value={value}
      onChange={setValue}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      target={props.target}
    />
  )
}

const TaskFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(
    props.target as 'tasks',
    'lastInteractedTime'
  )

  return (
    <FilterDateButton
      label='Updated'
      value={value}
      onChange={setValue}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      target={props.target}
    />
  )
}
