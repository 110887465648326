import {
  findStageForTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type ModalApi } from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import type { TaskDefinitionSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useFlowTemplateForm, useTemplateTasksFn } from '../../../hooks'
import { type UseTemplateTaskReturn } from '../../../hooks'
import { stripVariableKeysFromTaskDefinition } from '../../../utils'
import { stripBlockers } from '../utils'

export type UseWarnAndRemoveTemplateBlockersProps = {
  stages: FlowTemplateStage[]
  previousStages: FlowTemplateStage[]
  activeTaskId: string
  replace: (stages: FlowTemplateStage[]) => void
}

export const useWarnAndRemoveTemplateBlockersFn = () => {
  const modalApi = useModalApi()
  const getTemplateTasks = useTemplateTasksFn()
  const {
    form: { watch },
  } = useFlowTemplateForm()
  const variableKeys = watch('textVariables').map((v) => v.key)

  return useCallback(
    async ({
      stages,
      previousStages,
      activeTaskId,
      replace,
    }: UseWarnAndRemoveTemplateBlockersProps) => {
      const templateTasks = getTemplateTasks(stages)

      return warnAndRemoveTemplateBlockers({
        stages,
        previousStages,
        activeTaskId,
        templateTasks,
        modalApi,
        replace,
        variableKeys,
      })
    },
    [getTemplateTasks, modalApi, variableKeys]
  )
}

type WarnAndRemoveTemplateBlockersProps =
  UseWarnAndRemoveTemplateBlockersProps & {
    templateTasks: UseTemplateTaskReturn
    modalApi: ModalApi<ModalDefinitions>
    variableKeys: string[]
  }

async function warnAndRemoveTemplateBlockers({
  stages,
  previousStages,
  activeTaskId,
  templateTasks,
  modalApi,
  replace,
  variableKeys,
}: WarnAndRemoveTemplateBlockersProps): Promise<boolean> {
  const previousAllTasks = previousStages.flatMap((stage) => stage.tasks)

  const startTaskIndex = previousAllTasks.findIndex(
    (task) => task.id === activeTaskId
  )
  const endTaskIndex = templateTasks.all.findIndex(
    (task) => task.id === activeTaskId
  )

  if (startTaskIndex === -1 || endTaskIndex === -1) {
    Sentry.captureException(
      new Error('Task index not found in warnAndRemoveTemplateBlockers'),
      {
        extra: {
          previousAllTasks,
          templateTasks: templateTasks.all,
          activeTaskId,
        },
      }
    )
    return false
  }

  const startStage = findStageForTask(
    previousStages,
    previousAllTasks[startTaskIndex].id
  )
  const endStage = findStageForTask(stages, templateTasks.all[endTaskIndex].id)

  const isDroppedToOriginalIndex =
    startTaskIndex === endTaskIndex && startStage?.id === endStage?.id
  if (isDroppedToOriginalIndex) {
    return false
  }

  const blockedById = templateTasks.blockedById[activeTaskId] as
    | string
    | undefined
  const blockingIds = templateTasks.blockingById[activeTaskId] ?? []

  let blockedByTask: TaskDefinitionSchema | undefined
  let blockingTasks: TaskDefinitionSchema[] = []

  const isChangingStage =
    startStage != null && endStage != null && startStage.id !== endStage.id
  // If the task is being moved to a different stage, we include all blockers
  if (isChangingStage) {
    blockedByTask =
      blockedById != null ? templateTasks.byId[blockedById] : undefined
    blockingTasks = blockingIds.map((id) => templateTasks.byId[id])
  } else {
    // If the task is being moved within the same stage, we only include blockedBy above the target index
    // and blocking below the target index
    const blockedByTaskAboveIndex = previousAllTasks.findIndex(
      (task, index) => task.id === blockedById && index >= endTaskIndex
    )

    const blockingTasksBelowIndexes = blockingIds
      .map((id) =>
        previousAllTasks.findIndex(
          (task, index) => task.id === id && index <= endTaskIndex
        )
      )
      .filter((i) => i !== -1)

    if (blockedByTaskAboveIndex !== -1 || blockingTasksBelowIndexes.length) {
      blockedByTask =
        blockedByTaskAboveIndex !== -1
          ? previousAllTasks[blockedByTaskAboveIndex]
          : undefined

      blockingTasks = blockingTasksBelowIndexes.map(
        (index) => previousAllTasks[index]
      )
    }
  }

  if (blockedByTask != null || blockingTasks.length) {
    const strippedBlockedByTask = stripVariableKeysFromTaskDefinition(
      blockedByTask,
      variableKeys
    )
    const strippedBlockingTasks = blockingTasks
      .map((t) => stripVariableKeysFromTaskDefinition(t, variableKeys))
      .filter((t) => t.name !== '')

    const result = await modalApi.prompt('remove-template-blockers', {
      startStage: isChangingStage ? startStage : undefined,
      endStage: isChangingStage ? endStage : undefined,
      blockedByTask: strippedBlockedByTask,
      blockingTasks: strippedBlockingTasks,
      onValue(value) {
        if (value) {
          const newStages = stripBlockers(
            activeTaskId,
            stages,
            blockedByTask,
            blockingTasks
          )
          replace(newStages)
          return true
        }

        return false
      },
      onDismiss() {
        if (previousStages == null) return

        replace(previousStages)
      },
    })

    return result === true
  }

  return true
}
