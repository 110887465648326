import { LoadingSolid, TrashSolid } from '@motion/icons'
import { useDebouncedCallback } from '@motion/react-core/hooks'
import {
  type EventConferenceType,
  type ZoomLinkType,
} from '@motion/shared/common'
import {
  getConferenceDataForType,
  type LimitedEventConferenceType,
} from '@motion/ui-logic'
import { logEvent } from '@motion/web-base/analytics'

import { timeStringToMin } from '~/utils/time'
import { useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Events } from '../../../../analyticsEvents'
import { useConferenceSettingsApi } from '../../../../areas/settings/state'
import api from '../../../../chromeApi/chromeApiContentScript'
import { useInNoExternalCalendarsMode } from '../../../../hooks/use-in-no-external-calendars-mode'
import { selectCalendarsLoadingState } from '../../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../../state/hooks'
import {
  reminderDurationChoices,
  reminderWarningChoices,
  ZOOM_LINK_REGEX,
} from '../../../../storageConstants'
import { openZoomAuthWindow } from '../../../../utils/auth'
import { isZoomReady } from '../../../../utils/booking'
import {
  Combobox,
  Input,
  Paragraph,
  PrimaryButton,
  SubParagraph,
  TextButton,
} from '../../../Common'
import { inputContainerClasses } from '../../../Common/GeneralComponentStyles'
import { LinkCalendarsCTA } from '../../Components/link-calendars-cta'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

type MaybeMessage = {
  success: boolean
  text: string
} | null

const formatLink = (link: string) => {
  if (link && !/^https?:\/\//.test(link)) {
    return 'https://' + link
  }
  return link
}

export const ConferenceSettings = () => {
  const {
    deleteZoomAccount,
    refreshZoomUserData,
    selectConferenceSettings,
    selectReminderSettings,
    setConferenceSettings,
    setReminderSettings,
  } = useConferenceSettingsApi()
  const conferenceSettings = selectConferenceSettings()
  const reminderSettings = selectReminderSettings()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const emailAccountsLoadingState = useAppSelector(selectCalendarsLoadingState)

  const [zoomManualLinkInput, setZoomManualLinkInput] = useState(
    conferenceSettings?.zoomManualLink ?? ''
  )
  const [zoomManualLinkMessage, setZoomManualLinkMessage] =
    useState<MaybeMessage>(null)
  const [phoneNumberInput, setPhoneNumberInput] = useState(
    conferenceSettings?.phoneNumber ?? ''
  )
  const [phoneNumberMessage, setPhoneNumberMessage] =
    useState<MaybeMessage>(null)
  const [customLocationInput, setCustomLocationInput] = useState(
    conferenceSettings?.customLocation ?? ''
  )
  const [customLocationMessage, setCustomLocationMessage] =
    useState<MaybeMessage>(null)
  const [authlessZoom, setAuthlessZoom] = useState(false)
  const [reminderDuration, setReminderDuration] = useState(
    `${Math.floor(reminderSettings.duration / 60)} min`
  )
  const [reminderWarning, setReminderWarning] = useState(
    reminderSettings.warning < 60
      ? `${reminderSettings.warning} sec`
      : `${Math.floor(reminderSettings.warning / 60)} min`
  )

  useEffect(
    function fetchConferenceSettings() {
      const getInitialData = async () => {
        const { zoomNeedsRefresh } = await api.storage.local.get([
          'zoomNeedsRefresh',
        ])
        setAuthlessZoom(zoomNeedsRefresh)

        if (conferenceSettings?.zoomAccount) {
          // Refresh zoom user data in order to update the personalMeetingLink
          void refreshZoomUserData()
        }
      }
      void getInitialData()
      const storageListener = (changes: any) => {
        if ('zoomNeedsRefresh' in changes) {
          setAuthlessZoom(changes.zoomNeedsRefresh.newValue)
        }
      }
      api.storage.onChanged.addListener(storageListener)
      return () => api.storage.onChanged.removeListener(storageListener)
    },
    [conferenceSettings?.zoomAccount, refreshZoomUserData]
  )

  const onConferenceTypeChange = useCallback(
    async (type: LimitedEventConferenceType) => {
      await setConferenceSettings({ conferenceType: type })
      const { zoomNeedsRefresh } = await api.storage.local.get([
        'zoomNeedsRefresh',
      ])
      if (
        type === 'zoom' &&
        !isZoomReady(conferenceSettings, zoomNeedsRefresh)
      ) {
        // zoom link without auth or manual link
        setZoomManualLinkMessage({
          success: false,
          text: 'Please paste your Zoom link or connect your account.',
        })
      } else {
        setZoomManualLinkMessage(null)
      }
      void void logEvent(Events.CALENDAR_SETTINGS_CONFERENCE_CHANGE)
    },
    [conferenceSettings, setConferenceSettings]
  )

  const debounceCustomLocation = useDebouncedCallback(async () => {
    if (customLocationInput) {
      await setConferenceSettings({ customLocation: customLocationInput })
      setCustomLocationMessage({
        success: true,
        text: 'Your custom location was saved!',
      })
    } else {
      setCustomLocationMessage({
        success: false,
        text: 'No custom location entered.',
      })
    }
    setTimeout(() => setCustomLocationMessage(null), 5000)
  }, 1000)

  const customLocationHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setCustomLocationInput(e.target.value)
      setPhoneNumberMessage(null)
      debounceCustomLocation()
    },
    [debounceCustomLocation]
  )

  const onZoomLinkTypeChange = useCallback(
    async (linkType: ZoomLinkType) => {
      await setConferenceSettings({ zoomLinkType: linkType })
      void logEvent(Events.CALENDAR_SETTINGS_ZOOM_TYPE_CHANGE)
    },
    [setConferenceSettings]
  )

  const onZoomEmailRemove = useCallback(async () => {
    const res = await deleteZoomAccount()
    void setConferenceSettings({ ...res.conferenceSettings })
    void logEvent(Events.CALENDAR_SETTINGS_ZOOM_CLEAR)
  }, [deleteZoomAccount, setConferenceSettings])

  const openZoomAuth = useCallback(async () => {
    void openZoomAuthWindow()
    void logEvent(Events.CALENDAR_SETTINGS_ZOOM_CONNECT)
  }, [])

  const retrieveZoomLink = useCallback((text: string) => {
    const zoomLinks = formatLink(text).match(ZOOM_LINK_REGEX)
    if (zoomLinks) {
      return zoomLinks[0]
    }
    return null
  }, [])

  const debounceZoomManualLink = useDebouncedCallback(async () => {
    const zoomLink = retrieveZoomLink(zoomManualLinkInput)
    if (zoomLink) {
      await setConferenceSettings({ zoomManualLink: zoomLink })

      setZoomManualLinkMessage({
        success: true,
        text: 'Your meeting link was saved!',
      })
    } else {
      setZoomManualLinkMessage({
        success: false,
        text: 'Please enter a valid Zoom personal meeting link.',
      })
    }
    setTimeout(() => setZoomManualLinkMessage(null), 5000)
  }, 1000)

  const zoomManualLinkHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setZoomManualLinkInput(e.target.value)
      setZoomManualLinkMessage(null)
      debounceZoomManualLink()
    },
    [debounceZoomManualLink]
  )

  const debouncePhoneNumber = useDebouncedCallback(async () => {
    if (phoneNumberInput) {
      await setConferenceSettings({ phoneNumber: phoneNumberInput })
      setPhoneNumberMessage({
        success: true,
        text: 'Your phone number was saved!',
      })
    } else {
      setPhoneNumberMessage({
        success: false,
        text: 'No phone number entered.',
      })
    }
    setTimeout(() => setPhoneNumberMessage(null), 5000)
  }, 1000)

  const phoneNumberHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setPhoneNumberInput(e.target.value)
      setPhoneNumberMessage(null)
      debouncePhoneNumber()
    },
    [debouncePhoneNumber]
  )

  const updateReminderSettings = useCallback(async () => {
    const warningArr = reminderWarning.split(' ')
    let warningTime = 15
    if (warningArr[1] === 'sec') {
      warningTime = parseInt(warningArr[0])
    } else if (warningArr[1] === 'min') {
      warningTime = 60 * parseInt(warningArr[0])
    }

    const durationTime = 60 * timeStringToMin(reminderDuration)
    await setReminderSettings({
      duration: durationTime,
      warning: warningTime,
    })
  }, [reminderWarning, reminderDuration, setReminderSettings])

  const debounceUpdateReminderSettings = useDebouncedCallback(async () => {
    await updateReminderSettings()
  }, 1000)

  const onReminderWarningChange = useCallback(
    (timeString: string) => {
      setReminderWarning(timeString)
      debounceUpdateReminderSettings()
      void logEvent(Events.CALENDAR_SETTINGS_WARNING_CHANGE)
    },
    [debounceUpdateReminderSettings]
  )

  const onReminderDurationChange = useCallback(
    (timeString: string) => {
      setReminderDuration(timeString)
      debounceUpdateReminderSettings()
      void void logEvent(Events.CALENDAR_SETTINGS_DISAPPEAR_CHANGE)
    },
    [debounceUpdateReminderSettings]
  )

  if (noExternalCalendarsMode) {
    const isLoading =
      emailAccountsLoadingState === 'preload' ||
      emailAccountsLoadingState === 'loading'
    return (
      <SettingPage title='Conference Settings' className='gap-12'>
        <div className='flex w-full h-full flex-col gap-3 items-center'>
          {isLoading && (
            <div className='flex w-full h-full flex-col gap-3 items-center'>
              <LoadingSolid className='animate-spin text-semantic-neutral-icon-default' />
            </div>
          )}
          {!isLoading && <LinkCalendarsCTA />}
        </div>
      </SettingPage>
    )
  }

  return (
    <SettingPage title='Conference settings' className='gap-8'>
      <div className='flex w-full flex-col gap-2'>
        <SubParagraph className='dark:text-dark-400 font-semibold'>
          Default conference method
        </SubParagraph>
        <Combobox
          autoComplete
          value={conferenceSettings?.conferenceType}
          onChange={onConferenceTypeChange}
          className='max-w-full'
          options={(
            [
              'meet',
              'zoom',
              'teamsForBusiness',
              'phone',
              'customLocation',
            ] as EventConferenceType[]
          ).map((type) => {
            const data = getConferenceDataForType(type)
            return {
              label: data.title,
              labelIcon: (
                <img className='h-4 w-4' src={data.link} role='presentation' />
              ),
              value: type,
            }
          })}
        />
      </div>
      <div className='flex w-full flex-col gap-3'>
        <Paragraph className='text-base font-semibold'>Zoom</Paragraph>
        <div className='flex w-full flex-col gap-4'>
          <div className='flex w-full flex-col gap-2'>
            <SubParagraph className='dark:text-dark-400 font-semibold'>
              Account
            </SubParagraph>
            {conferenceSettings?.zoomAccount && (
              <div
                className={twMerge(
                  'flex h-8 w-full items-center justify-between rounded border px-3',
                  inputContainerClasses
                )}
              >
                <Paragraph>{conferenceSettings?.zoomAccount}</Paragraph>
                <TextButton icon={TrashSolid} onClick={onZoomEmailRemove} />
              </div>
            )}
            {!conferenceSettings?.zoomAccount && (
              <PrimaryButton onClick={openZoomAuth} className='w-max'>
                Connect Zoom
              </PrimaryButton>
            )}
            {conferenceSettings?.zoomAccount && authlessZoom && (
              <div className='flex items-center'>
                <Paragraph className='text-alert-400 dark:text-alert-400'>
                  Your Zoom account was disconnected
                </Paragraph>
                <PrimaryButton
                  size='small'
                  className='w-max'
                  onClick={openZoomAuth}
                >
                  Reconnect Zoom
                </PrimaryButton>
              </div>
            )}
          </div>
          <div className='flex w-full flex-col gap-2'>
            <SubParagraph className='dark:text-dark-400 font-semibold'>
              Meeting link
            </SubParagraph>
            <Combobox
              autoComplete
              className='max-w-full'
              value={conferenceSettings?.zoomLinkType}
              onChange={onZoomLinkTypeChange}
              options={[
                {
                  disabled: !conferenceSettings?.zoomPersonalLink,
                  label: 'Use personal Zoom link',
                  value: 'personal',
                },
                {
                  disabled: !conferenceSettings?.zoomAccount,
                  label: 'Auto-generate link',
                  value: 'auto',
                },
                { label: 'Manually enter Zoom link', value: 'manual' },
              ]}
            />
            {conferenceSettings?.zoomLinkType === 'manual' && (
              <Input
                placeholder='Paste Zoom personal link'
                value={zoomManualLinkInput}
                onChange={zoomManualLinkHandler}
              />
            )}
            {conferenceSettings?.zoomLinkType === 'manual' &&
              zoomManualLinkMessage && (
                <SubParagraph
                  className={twMerge(
                    zoomManualLinkMessage.success
                      ? 'text-success-300 dark:text-success-300'
                      : 'text-alert-400 dark:text-alert-400'
                  )}
                >
                  {zoomManualLinkMessage.text}
                </SubParagraph>
              )}
          </div>
        </div>
      </div>
      <div className='flex w-full flex-col gap-3'>
        <Paragraph className='text-base font-semibold'>Phone number</Paragraph>
        <Input
          placeholder='Enter phone number'
          value={phoneNumberInput}
          onChange={phoneNumberHandler}
        />
        {phoneNumberMessage && (
          <SubParagraph
            className={twMerge(
              phoneNumberMessage.success
                ? 'text-success-300 dark:text-success-300'
                : 'text-alert-400 dark:text-alert-400'
            )}
          >
            {phoneNumberMessage.text}
          </SubParagraph>
        )}
      </div>
      <div className='flex w-full flex-col gap-3'>
        <Paragraph className='text-base font-semibold'>
          Custom location
        </Paragraph>
        <Input
          placeholder='Enter link or address'
          value={customLocationInput}
          onChange={customLocationHandler}
        />
        {customLocationMessage && (
          <SubParagraph
            className={twMerge(
              customLocationMessage.success
                ? 'text-success-300 dark:text-success-300'
                : 'text-alert-400 dark:text-alert-400'
            )}
          >
            {customLocationMessage.text}
          </SubParagraph>
        )}
      </div>
      <div className='flex w-full flex-col gap-3'>
        <Paragraph className='text-base font-semibold'>
          Join meeting button
        </Paragraph>
        <div className='flex w-full gap-4'>
          <div className='flex w-full flex-col gap-2'>
            <SubParagraph className='dark:text-dark-400 font-semibold'>
              Show before event
            </SubParagraph>
            <Combobox
              className='max-w-full'
              value={reminderWarning}
              onChange={onReminderWarningChange}
              autoComplete
              options={reminderWarningChoices.map((time) => ({
                label: time,
                value: time,
              }))}
            />
          </div>
          <div className='flex w-full flex-col gap-2'>
            <SubParagraph className='dark:text-dark-400 font-semibold'>
              Disappear after event
            </SubParagraph>
            <Combobox
              className='max-w-full'
              value={reminderDuration}
              onChange={onReminderDurationChange}
              autoComplete
              options={reminderDurationChoices.map((time) => ({
                label: time,
                value: time,
              }))}
            />
          </div>
        </div>
      </div>
    </SettingPage>
  )
}
