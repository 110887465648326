import { classed } from '@motion/theme'
import { Button, ButtonGroup } from '@motion/ui/base'
import { FieldLabel } from '@motion/ui/forms'

import { type FormEventHandler } from 'react'
import {
  type FieldValues,
  FormProvider,
  type UseFormReturn,
} from 'react-hook-form'

type BulkDropdownFormProps<T extends FieldValues> = {
  onSubmit: FormEventHandler<HTMLFormElement>
  onReset: () => void
  label: string
  children: React.ReactNode
  form: UseFormReturn<T>
}

export function BulkDropdownForm<T extends FieldValues>({
  onSubmit,
  onReset,
  label,
  children,
  form,
}: BulkDropdownFormProps<T>) {
  return (
    <FormProvider {...form}>
      <Form onReset={onReset} onSubmit={onSubmit}>
        <Body>
          <FieldLabel label={label} column>
            {children}
          </FieldLabel>
        </Body>

        <Footer>
          <ButtonGroup>
            <Button
              type='reset'
              sentiment='neutral'
              variant='muted'
              size='small'
            >
              Cancel
            </Button>
            <Button type='submit' size='small'>
              Save
            </Button>
          </ButtonGroup>
        </Footer>
      </Form>
    </FormProvider>
  )
}

const Form = classed('form', 'w-[280px] flex flex-col')
const Body = classed('div', 'p-4')

const Footer = classed(
  'div',
  'flex justify-end py-3 px-4 border-t border-modal-border'
)
