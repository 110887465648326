import { type Group } from '@motion/ui/base'
import {
  type AllFilters,
  type FilterKeys,
  type FilterTarget,
} from '@motion/ui-logic/pm/data'
import { groupInto } from '@motion/utils/array'
import { type IdFilterSchema } from '@motion/zod/client'

import { type ReactNode, useMemo } from 'react'

import { GroupedItemDropdown } from './grouped-item-dropdown'

import { useFieldFilter } from '../../../context'
import { buildIdFilter } from '../../../utils'

type ValueKeys<T extends FilterTarget> = {
  [TK in FilterKeys<T>]: AllFilters[T]['filters'][TK] extends IdFilterSchema | null
    ? TK
    : never
}[FilterKeys<T>]

type ConnectedStatusDropdownProps<
  T,
  TEntity extends FilterTarget,
  TField extends ValueKeys<TEntity>,
> = {
  applyTo: TEntity
  field: TField
  close(): void

  getAllItems: () => T[]

  placeholder: string
  renderItem(item: T): NonNullable<ReactNode>
  renderHeader(group: Group<T>): NonNullable<ReactNode>
}

export const ConnectedGroupedItemDropdown = <
  T extends { id: string; name: string; workspaceId: string },
  TEntity extends FilterTarget,
  TField extends ValueKeys<TEntity>,
>(
  props: ConnectedStatusDropdownProps<T, TEntity, TField>
) => {
  const [filter, setFilter] = useFieldFilter(
    props.applyTo as FilterTarget,
    // @ts-expect-error - its fine
    props.field
  )

  const { getAllItems } = props
  const items = useMemo(() => {
    const items = getAllItems()
    const groups = groupInto(items, (p) => p.name).map((g) => ({
      key: g.key,
      items: g.items,
    }))

    return {
      all: items,
      groups,
    }
  }, [getAllItems])

  const selected =
    filter == null
      ? []
      : (filter as IdFilterSchema).value
          .map((id) => items.all.find((u) => u.id === id))
          .filter(Boolean)

  const onSelect = (values: T[] | null) => {
    setFilter(buildIdFilter(values, filter as IdFilterSchema))
  }

  return (
    <GroupedItemDropdown
      items={items}
      selected={selected}
      onSelect={onSelect}
      close={props.close}
      placeholder={props.placeholder}
      renderItem={props.renderItem}
      renderHeader={props.renderHeader}
    />
  )
}
