import { TrashSolid } from '@motion/icons'

import { FieldButton } from '../components'
import { useBulkDeleteTasks } from '../hooks'

export function DeleteField() {
  const bulkDelete = useBulkDeleteTasks()

  return (
    <FieldButton
      analyticsName='delete'
      onClick={() => {
        bulkDelete()
      }}
    >
      <TrashSolid />
      Delete
    </FieldButton>
  )
}
