import { QuestionMarkCircleSolid } from '@motion/icons'

import { TextButton } from '../../../components/Common'

interface ButtonsProps {
  additionalButtons?: React.ReactChild
}

export const Buttons = ({ additionalButtons }: ButtonsProps) => {
  return (
    <div className='ml-5 mr-5 flex justify-end'>
      {additionalButtons}
      <TextButton
        className='ml-2 mr-2 flex h-[60px] cursor-pointer select-none items-center whitespace-nowrap font-semibold'
        icon={QuestionMarkCircleSolid}
        onClick={() =>
          window.open(
            'https://help.usemotion.com',
            '_blank',
            'noopener noreferrer'
          )
        }
      >
        Support
      </TextButton>
    </div>
  )
}
