import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useCallback } from 'react'

import { ScheduleSettings } from './ScheduleSettings'

export const ConnectedScheduleSettings = () => {
  const modalApi = useModalApi()

  const openScheduleModal = useCallback(
    (scheduleId: string | undefined) => {
      recordAnalyticsEvent('SCHEDULE_MODAL_OPEN', {
        scheduleId,
      })
      void modalApi.prompt('schedule-modal', {
        scheduleId,
      })
    },
    [modalApi]
  )

  return <ScheduleSettings openScheduleModal={openScheduleModal} />
}
