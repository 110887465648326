import { isNoneId } from '@motion/shared/identifiers'

import { GroupHeaderWithFieldName } from './common'

import { type DateRow } from '../..'

export type DateGroupItem = {
  value: DateRow['value'] | null
  key: string
}

export function DateGroupHeader({
  item,
  fieldName,
}: {
  item: DateGroupItem
  fieldName: string
}) {
  const isFake = isNoneId(item.key)

  return (
    <GroupHeaderWithFieldName fieldName={fieldName}>
      <span className='truncate'>
        {isFake ? (item.value as string) : item.key}
      </span>
    </GroupHeaderWithFieldName>
  )
}
