import { CheckSolid } from '@motion/icons'
import { Button, Modal } from '@motion/ui/base'

import { type ReactNode } from 'react'

export type SuccessModalProps = {
  description: ReactNode
  title: ReactNode
  onClose: () => void
  submitAction?: {
    text?: ReactNode
    onAction?(): Promise<void> | void
    disabled?: boolean
    destructive?: boolean
  }
  visible: boolean
}
export function SuccessModal({
  description,
  onClose,
  submitAction,
  title,
  visible,
}: SuccessModalProps) {
  return (
    <Modal onClose={onClose} visible={visible}>
      <div className='flex flex-col gap-2 p-6 items-center'>
        <CheckSolid className='text-semantic-neutral-icon-default' />
        <span className='text-semantic-neutral-text-default text-center text-[16px] font-semibold'>
          {title}
        </span>
        <span className='text-semantic-neutral-text-subtle text-center text-sm '>
          {description}
        </span>
        <div className='mt-2'>
          <Button
            onClick={() => {
              if (submitAction?.onAction) {
                void submitAction.onAction()
                return
              }
              onClose()
            }}
          >
            {submitAction?.text ?? 'OK'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
