import { classed } from '@motion/theme'

export const TimelineOuterContainer = classed('div', {
  base: 'flex flex-col gap-1',
})

export const TimelineInnerContainer = classed('div', {
  base: '',
})

export const Timeline = classed('div', {
  base: `
  flex flex-shrink-0 justify-center items-start h-[22px] z-10 relative
  rounded-full border border-semantic-blue-border-default bg-semantic-blue-bg-default shadow-sm
  `,
})

export const DateTimeContainer = classed('div', {
  base: 'flex flex-row justify-between',
})

export const ProjectDateLabel = classed('span', {
  base: 'text-semantic-neutral-text-subtle text-[9px] font-medium leading-normal',
})

export const StagesContainer = classed('div', {
  base: 'relative h-[22px] -top-[22px] z-20',
})

export const StageItemContainer = classed('div', {
  base: 'flex flex-col gap-2 items-center absolute top-[3px]',
})

export const StageDateLabel = classed('span', {
  base: 'text-[8px] font-medium leading-2 text-semantic-neutral-text-disabled',
  variants: {
    rotated: {
      true: 'rotate-45 -mr-3',
      false: '',
    },
  },
  defaultVariants: {
    rotated: false,
  },
})

export const AdditionalTimeline = classed('div', {
  base: 'flex rounded-full relative h-[22px] w-full -top-[44px] border-dashed border border-semantic-blue-border-default justify-end items-center',
})

export const ExtendedTimeline = classed('div', {
  base: 'flex rounded-full relative h-[22px] w-full -top-[44px] z-0 border-dashed border border-semantic-warning-bg-strong-hover bg-semantic-warning-bg-disabled',
})

export const PercentageSavedText = classed('span', {
  base: 'text-[9px] font-bold leading-normal text-semantic-blue-text-default mr-2',
})
