import { classed } from '@motion/theme'

import { BUTTON_ROW_WIDTH_THRESHOLD } from '~/areas/agenda/components/constants'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { useRef } from 'react'

import {
  CurrentTaskActionButtons,
  CurrentTaskActionsDropdown,
  CurrentTaskProgress,
} from './components'

import { useStartedAgendaTask } from '../../hooks'
import { TaskItem } from '../task-item'

export function CurrentTaskSection() {
  const currentTask = useStartedAgendaTask()
  const containerRef = useRef<HTMLDivElement>(null)

  if (currentTask == null) {
    return null
  }

  const renderTaskItemActionButtons = ({
    scheduledTask,
  }: {
    scheduledTask: ScheduledTaskWithRelation
  }) => {
    if (scheduledTask.task == null) {
      return null
    }

    const containerWidth =
      containerRef.current?.clientWidth ?? BUTTON_ROW_WIDTH_THRESHOLD

    return containerWidth >= BUTTON_ROW_WIDTH_THRESHOLD ? (
      <CurrentTaskActionButtons task={scheduledTask.task} />
    ) : (
      <CurrentTaskActionsDropdown task={scheduledTask.task} />
    )
  }

  return (
    <CurrentTaskSectionContainer ref={containerRef}>
      <CurrentTaskSectionCard>
        <TaskItem
          scheduledTask={currentTask}
          alwaysActive
          showCompleteButton={false}
          renderTaskItemActionButtons={renderTaskItemActionButtons}
        />
        <CurrentTaskProgress scheduledTask={currentTask} />
      </CurrentTaskSectionCard>
    </CurrentTaskSectionContainer>
  )
}

const CurrentTaskSectionContainer = classed('div', {
  base: 'pl-4 pr-10',
})

const CurrentTaskSectionCard = classed('div', {
  base: 'flex flex-col items-start w-full pl-8 pr-2 pb-4 pt-2 gap-2 border rounded-lg shadow',
})
