import { API } from '@motion/rpc'
import { logEvent } from '@motion/web-base/analytics'
import { errorInDev } from '@motion/web-base/logging'

import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useQueryClient } from '@tanstack/react-query'
import { useUtilizedSubscription } from '~/global/rpc/subscriptions'
import { useGetTeamPrices } from '~/global/rpc/team'
import { createTeam } from '~/state/projectManagement/teamThunks'
import { DateTime, Duration } from 'luxon'
import { useState } from 'react'

import { PaymentSectionWithSeats } from './payment-section-with-seats'

import { Events } from '../../../../analyticsEvents'
import { useAppDispatch } from '../../../../state/hooks'
import { getSubscription } from '../../../../state/userSlice'
import { type PayTeamFormProps } from '../../types/PayTeamFormProps'
import { type ProcessPaymentProps } from '../../types/process-payment-props'

export const PayTeamForm = ({
  onRerender,
  setError,
  goToNextScreen,
  shouldCreateNewTeamCustomer,
}: PayTeamFormProps & {
  goToNextScreen: () => void
  shouldCreateNewTeamCustomer: boolean
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useAppDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const { data: teamPrices } = useGetTeamPrices()
  const existingSubscription = useUtilizedSubscription()

  const [bucket, setBucket] = useState(5)
  const [isAnnual, setIsAnnual] = useState(true)

  const queryClient = useQueryClient()

  const processPayment = async ({
    isAnnual,
    selectedEmails,
    bucketSeats,
    useExistingCard,
  }: ProcessPaymentProps) => {
    if (useExistingCard) {
      setIsSubmitting(true)
      await dispatch(
        createTeam({
          name: 'My team',
          invited: [],
          seats: bucket,
          isMonthly: !isAnnual,
        })
      )
      setIsSubmitting(false)
      return
    }

    if (!stripe || !elements) return // Haven't finished loading stripe yet
    setIsSubmitting(true)
    const stripeResult = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })
    if (stripeResult.error) {
      errorInDev(stripeResult.error)
      if (setError) {
        setError(stripeResult.error.message ?? '')
      }
      setIsSubmitting(false)
      return
    }

    if (!bucketSeats && !selectedEmails) {
      if (setError) {
        setError('Invalid team size, please try again or contact support')
      }
      setIsSubmitting(false)
      return
    }

    const setupIntent = stripeResult.setupIntent
    let createTeamResult

    createTeamResult = await dispatch(
      createTeam({
        invited: [],
        isMonthly: !isAnnual,
        name: 'My team',
        setupIntentId: setupIntent.id,
        seats: bucket,
      })
    ).unwrap()

    if (
      'error' in createTeamResult &&
      typeof createTeamResult.error === 'string'
    ) {
      errorInDev(createTeamResult.error)
      if (setError) {
        setError(createTeamResult.error)
      }
      setIsSubmitting(false)
      if ('clientSecret' in createTeamResult && createTeamResult.clientSecret) {
        if (onRerender) {
          onRerender(`${createTeamResult.clientSecret}`)
        }
      }
      return
    }
    await dispatch(getSubscription())
    void queryClient.invalidateQueries({
      queryKey: API.subscriptions.queryKeys.root,
    })
    try {
      logEvent(Events.TEAM_BILLING_SUBSCRIBE, {
        count: selectedEmails?.length || bucket,
        interval: isAnnual ? 'year' : 'month',
      })
    } catch (e) {
      errorInDev(e)
    }
  }

  if (!stripe || !elements || !teamPrices) {
    // Haven't finished loading stripe yet
    return null
  }

  return (
    <PaymentSectionWithSeats
      isAnnual={isAnnual}
      setIsAnnual={setIsAnnual}
      bucket={bucket}
      setBucket={setBucket}
      isSubmitting={isSubmitting}
      processPayment={processPayment}
      goToNextScreen={goToNextScreen}
      teamPrices={teamPrices}
      stripeCustomerId={existingSubscription?.customer}
      shouldCreateNewTeamCustomer={shouldCreateNewTeamCustomer}
      trialDays={
        shouldCreateNewTeamCustomer
          ? existingSubscription && existingSubscription.status === 'trialing'
            ? Math.round(
                Duration.fromMillis(
                  (existingSubscription.current_period_end -
                    DateTime.now().toSeconds()) *
                    1_000
                ).as('days')
              )
            : 7
          : undefined
      }
    />
  )
}
