import { ClientValidationError } from '@motion/rpc'
import { type COLOR } from '@motion/shared/common'
import {
  convertProjectFormFieldsForUpdate,
  type ProjectFormFields,
} from '@motion/ui-logic/pm/project'
import { reduceCustomFieldsValuesFieldArrayToRecord } from '@motion/ui-logic/pm/project'
import { pick } from '@motion/utils/object'
import { type ProjectsV2CreateRequestSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { checkTextRuleErrors, NAME_FIELD_RULE } from '~/global/rules'
import { useCallback } from 'react'

import { useProjectForm } from './use-project-form'

import { useCreateProject } from '../../../hooks/actions/use-create-project'
import { useProjectUpdater } from '../../../hooks/updaters/use-project-updater'

export function useSaveProject() {
  const { form } = useProjectForm()
  const updateProject = useProjectUpdater()
  const createProject = useCreateProject()
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const { dirtyFields } = form.formState

  const projectId = form.watch('id')

  return useCallback(
    async (fields: ProjectFormFields) => {
      if (projectId != null) {
        throw new Error("Modal is autosaving. Manual save shouldn't be called")
      }

      const trimName = fields.name.trim()
      const errorMessage = checkTextRuleErrors(trimName, NAME_FIELD_RULE)
      if (errorMessage) {
        throw new ClientValidationError(errorMessage)
      }

      if (projectId != null) {
        const project = getWorkspaceProjectById(projectId)
        if (project == null) {
          throw new Error('Project not found')
        }

        const updates = convertProjectFormFieldsForUpdate(fields, dirtyFields)
        return updateProject(project, updates)
      }

      const createPayload = convertFieldsForCreate(fields)
      return createProject(createPayload)
    },
    [
      createProject,
      dirtyFields,
      getWorkspaceProjectById,
      projectId,
      updateProject,
    ]
  )
}

function convertFieldsForCreate(
  fields: ProjectFormFields
): ProjectsV2CreateRequestSchema {
  return {
    ...pick(fields, [
      'workspaceId',
      'managerId',
      'name',
      'description',
      'startDate',
      'dueDate',
      'statusId',
      'priorityLevel',
      'labelIds',
      'folderId',
    ]),
    customFieldValues: reduceCustomFieldsValuesFieldArrayToRecord(
      fields.customFieldValuesFieldArray.filter((field) => field.value != null)
    ),
    color: fields.color as COLOR,
  }
}
