import { Button } from '@motion/ui/base'
import { useSetting } from '@motion/web-common/settings'

import {
  CalendarArrows,
  DateTitle,
} from '~/areas/calendar/components/calendar-header/components'

import { useScheduleAssistantCalendarContext } from '../../hooks'
import { useScheduleAssistantCalendarDateNavigation } from '../../hooks/use-calendar-date-navigation'

export function ScheduleAssistantCalendarHeader() {
  const { back, forward, today } = useScheduleAssistantCalendarDateNavigation()
  const [state] = useScheduleAssistantCalendarContext()

  const [calendarStartDay] = useSetting('calendarStartDay', 'sunday')

  const baseDateTime = state.selectedDate

  return (
    <div className='flex items-center w-full gap-2 p-4 justify-start'>
      <Button
        variant='outlined'
        sentiment='neutral'
        size='small'
        onClick={today}
      >
        Today
      </Button>

      <CalendarArrows forward={forward} back={back} />

      <DateTitle
        baseDate={baseDateTime}
        timezone={baseDateTime.zoneName}
        calendarStartDay={calendarStartDay}
        view='weekly'
      />
    </div>
  )
}
