import { classed } from '@motion/theme'

import {
  SharedFormShell,
  SharedGridShell,
} from '~/areas/task-project/components/styled'

export const FormShell = classed('form', SharedFormShell, {
  base: `
    h-[var(--modal-height)]
    w-[var(--min-modal-width)]
  `,
  variants: {
    columns: {
      2: ``,
    },
  },
  defaultVariants: {
    columns: 2,
  },
  dataAttributes: ['columns'],
})

export const GridShell = classed('div', SharedGridShell, {
  base: `
    [[data-columns="2"]_&]:[grid-template-areas:'aside_main''aside_main''aside_main']
    grid-cols-[var(--aside-width,240px)_1fr]
  `,
})

export const ShellVars = classed('div', {
  base: `
    contents

    setvar-[aside-width=240px]
    modal-md:setvar-[aside-width=240px]
    modal-lg:setvar-[aside-width=240px]

    setvar-[modal-height=600px]
    modal-md:setvar-[modal-height=688px]

    setvar-[min-modal-width=700px]
    modal-md:setvar-[min-modal-width=894px]
  `,
})

export const LeftPanel = classed('div', {
  base: `
    [grid-area:aside]
    py-6 px-4 h-full bg-semantic-neutral-surface-bg-subtlest flex flex-col gap-3 rounded-l
  `,
})

export const MainContent = classed('div', {
  base: `
    [grid-area:main]
    flex flex-col
    relative
    overflow-hidden
    modal-lg:pb-6
    `,
})

export const MainContentScrollable = classed('div', {
  base: `
    gap-4 overflow-y-auto overflow-x-hidden
    flex-1
    px-6 pt-6

    after:absolute
    after:h-[20px]
    after:bg-gradient-to-b after:from-transparent after:to-modal-bg
    after:right-0 after:left-0 after:bottom-17
  `,
})

export const MainContentFooter = classed('div', {
  base: `
    flex flex-row items-center justify-between
    px-6
    h-17
   `,
})

export const StepHeader = classed('h2', {
  base: `
    font-semibold text-lg leading-[24px] text-semantic-neutral-text-default
  `,
})
