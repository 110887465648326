import { useMemoSyncExternalStore } from '@motion/react-core/hooks'
import { Button, Toggle } from '@motion/ui/base'
import {
  type AmplitudeExperimentName,
  featureFlags,
} from '@motion/web-common/flags'

import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'

import { BackLabel } from './common-labels'
import {
  type DevToolsListItems,
  type DevToolsPages,
  type ItemsHookReturn,
} from './types'

import {
  AmplitudeExperimentNames,
  type AmplitudeExperiments,
} from '../../../services/amplitudeFeatureFlagsService'

export const useFeatureFlagItems = (
  setCurrentPage: (page: DevToolsPages) => void
): ItemsHookReturn => {
  const flags = useMemoSyncExternalStore(
    'flags',
    featureFlags.changed.subscribe,
    () => featureFlags.all()
  )

  const toggleFlagValue = (value?: string) => {
    if (value === 'on') {
      return 'off'
    } else if (value === 'treatment') {
      return 'control'
    } else if (value === 'control') {
      return 'treatment'
    }
    return 'on'
  }

  const { mutate: resetFlags } = useMutation({
    mutationFn: () => {
      featureFlags.clearOverrides()
      return featureFlags.refresh()
    },
  })

  const performChaosMonkey = useCallback(() => {
    AmplitudeExperimentNames.forEach((name) => {
      // special case: `new_maintenance_mode` isn't helpful to be randomized
      if (name === 'new_maintenance_mode') {
        return
      }
      const value = Math.random() > 0.5 ? 'on' : 'off'
      featureFlags.override(name, value)
    })
  }, [])

  const listItems: DevToolsListItems = Object.keys(flags)
    .sort()
    .map((item) => ({
      onSelect: () => {
        featureFlags.override(
          item,
          toggleFlagValue(flags[item as AmplitudeExperimentName].value)
        )
      },
      label: (
        <>
          <Toggle
            checked={
              flags[item as keyof AmplitudeExperiments].value === 'on' ||
              flags[item as keyof AmplitudeExperiments].value === 'treatment'
            }
            onChange={() => null}
          >
            {item}
          </Toggle>
          {featureFlags.hasOverride(item as keyof AmplitudeExperiments) && (
            <Button
              size='small'
              sentiment='error'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                featureFlags.override(item, null)
              }}
            >
              clear
            </Button>
          )}
        </>
      ),
      key: item,
    }))

  listItems.unshift({
    onSelect: () => performChaosMonkey(),
    label: 'Chaos monkey (Randomize flags)',
    key: 'Chaos monkey (Randomize flags)',
  })
  listItems.unshift({
    onSelect: () => resetFlags(),
    label: 'Reset flags',
    key: 'Reset flags',
  })
  listItems.unshift({
    onSelect: () => {
      setCurrentPage('main')
    },
    label: <BackLabel />,
    key: 'Back',
    clearSearch: true,
  })
  return [listItems]
}
