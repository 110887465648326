import { logEvent } from '@motion/web-base/analytics'

import { createDesktopDeepLink } from '~/components/MotionTab'

import {
  OpenInDesktopApp,
  type OpenInDesktopAppResult,
} from './OpenInDesktopApp'

import { Events } from '../../../analyticsEvents'
import { persistSettings } from '../../../localServices/firebase/web'
import { selectDesktopSettings } from '../../../state/desktopSlice'
import { useAppSelector } from '../../../state/hooks'

interface WrapperProps {
  setIsVisible: (visible: boolean) => void
  isVisible: boolean
}
export const OpenInDesktopAppAlertWrapper = ({
  setIsVisible,
  isVisible,
}: WrapperProps) => {
  const desktopSettings = useAppSelector(selectDesktopSettings)

  const dismissAlert = (rememberChoice: boolean) => {
    if (rememberChoice) {
      void persistSettings({
        desktop: {
          ...desktopSettings,
          deepLinks: {
            ...desktopSettings?.deepLinks,
            alwaysOpenInApp: false,
            hasDismissedDeepLinkPopup: true,
          },
        },
      })

      void logEvent(Events.DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_DISMISS_ALWAYS)
    } else {
      void logEvent(Events.DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_DISMISS_ONCE)
    }

    setIsVisible(false)
  }

  const openDesktopApp = (rememberChoice: boolean) => {
    const url = createDesktopDeepLink(window.location)

    window.open(url, '_self')

    if (rememberChoice) {
      void persistSettings({
        desktop: {
          ...desktopSettings,
          deepLinks: {
            ...desktopSettings?.deepLinks,
            alwaysOpenInApp: true,
            hasDismissedDeepLinkPopup: true,
          },
        },
      })
      void logEvent(Events.DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_ALWAYS)
    } else {
      void logEvent(Events.DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_ONCE)
    }

    setIsVisible(false)
  }

  const handleResult = ({
    openInDesktopApp,
    persist,
  }: OpenInDesktopAppResult) => {
    if (openInDesktopApp) {
      openDesktopApp(persist)
    } else {
      dismissAlert(persist)
    }
  }

  if (!isVisible || desktopSettings?.deepLinks?.hasDismissedDeepLinkPopup)
    return null

  return (
    <div className='absolute bottom-2 left-2 !z-[2147483648]'>
      <OpenInDesktopApp onResult={handleResult} />
    </div>
  )
}
