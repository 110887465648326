import { type ProjectSchema } from '@motion/zod/client'

import { useProjectStageByProjectAndDefinitionId } from '~/areas/project/hooks/data'

import { ProgressSummary } from './progress-summary'

import { type GroupedNode } from '../../../grouping'
import { CellText } from '../components'
import { useProjectInTree } from '../hooks'

export type StageCompletedDurationCellProps = {
  row: GroupedNode
  stageDefinitionId: string
}

export const StageCompletedDurationCell = ({
  row,
  stageDefinitionId,
}: StageCompletedDurationCellProps) => {
  const project = useProjectInTree(row)
  if (!project) return null

  return (
    <InnerStageCompletedDurationCell
      project={project}
      stageDefinitionId={stageDefinitionId}
    />
  )
}

type InnerStageCompletedDurationCellProps = {
  project: ProjectSchema
  stageDefinitionId: string
}

const InnerStageCompletedDurationCell = ({
  project,
  stageDefinitionId,
}: InnerStageCompletedDurationCellProps) => {
  const stage = useProjectStageByProjectAndDefinitionId(
    project,
    stageDefinitionId
  )

  if (!stage) return null

  return (
    <CellText className='flex-nowrap gap-2'>
      <ProgressSummary
        percentageComplete={stage.completion}
        completedDuration={stage.completedDuration}
      />
    </CellText>
  )
}
