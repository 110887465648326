import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'
import { type LabelSchema } from '@motion/zod/client'

import { useDeleteLabel as useDeleteLabelCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useDeleteLabel = () => {
  const {
    mutateAsync: deleteWorkspaceLabel,
    isLoading,
    isError,
  } = useDeleteLabelCall()
  const modalApi = useModalApi()

  return {
    deleteLabel: useCallback(
      async (label: LabelSchema): Promise<boolean> => {
        try {
          const confirmResponse = await modalApi.prompt('confirm-delete-item', {
            analytics: {
              name: 'delete-label',
            },
            titleValue: 'this label',
            description:
              'The label will be deleted and all tasks with this label will have it removed.',
            deleteMessage: label.name,
            deleteButtonLabelValue: 'label',
          })

          if (confirmResponse === true) {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_WORKSPACE_LABEL')

            await deleteWorkspaceLabel({
              workspaceId: label.workspaceId,
              labelId: label.id,
            })

            showToast('success', 'Label removed')
            return true
          }
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'delete-label',
            },
          })

          showErrorToast(e)
        }
        return false
      },
      [deleteWorkspaceLabel, modalApi]
    ),
    isLoading,
    isError,
  }
}
