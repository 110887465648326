import { Button } from '@motion/ui/base'
import { prettyDateDay } from '@motion/ui-logic'

import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationDueDateField = () => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'dueDate',
    control: formContext.control,
  })
  const projectId = formContext.watch('projectId')
  const stageDefinitionId = formContext.watch('stageDefinitionId')

  return (
    <TaskDeadlineDropdown
      value={field.value ?? null}
      contextProps={{
        projectId,
        stageDefinitionId,
      }}
      onChange={(value) => {
        field.onChange(value)
      }}
    >
      <Button sentiment='neutral' variant='outlined' size='xsmall'>
        <span className='truncate text-[10px] font-normal'>
          {field.value
            ? `Due ${prettyDateDay(field.value, { lowercase: true })}`
            : 'No due date'}
        </span>
      </Button>
    </TaskDeadlineDropdown>
  )
}
