import { useAllCalendars } from '~/areas/calendar/hooks'
import { useMemo } from 'react'

import { useViewState } from '../../view-state'

export function useValidProviderIds() {
  const [viewState] = useViewState()

  const { calendars: allCalendars } = useAllCalendars()
  const { providerIds = [] } = viewState

  return useMemo(
    () =>
      providerIds.filter((providerId) => {
        return allCalendars.some(
          (calendar) => calendar.providerId === providerId
        )
      }),
    [allCalendars, providerIds]
  )
}
