import {
  ExtendedDeadlineStatus,
  getExtendedTaskDeadlineStatus,
} from '@motion/ui-logic'
import {
  sortByDueDate,
  sortByEstimatedCompletionTime,
  sortByPriority,
  sortByStartDate,
} from '@motion/ui-logic/pm/data'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import {
  byProperty,
  byValue,
  Compare,
  type CompareFn,
  ordered,
} from '@motion/utils/array'

import { type TaskWithRelations } from '~/global/proxies'

import { DEFAULT_STRING_SORT } from './sorting'
import { type FieldDefinition } from './types'

export type TaskFieldId = keyof typeof TASK_FIELDS
export const TASK_FIELDS = {
  name: {
    id: 'name',
    name: 'Name',
    size: { min: 300, default: 650 },
    sortFn: byProperty('name', DEFAULT_STRING_SORT),
    disableReordering: true,
    disableHiding: true,
    enablePinning: true,
  },
  deadlineStatus: {
    id: 'deadlineStatus',
    name: 'ETA',
    size: { min: 90, default: 90, max: 90 },
    sortFn: byValue(
      (task) => getExtendedTaskDeadlineStatus(task, [task.status]),
      ordered(ExtendedDeadlineStatus)
    ),
  },
  deadline: {
    id: 'deadline',
    name: 'Deadline',
    size: { min: 100, default: 130 },
    sortFn: sortByDueDate,
  },
  project: {
    id: 'project',
    name: 'Project',
    size: { min: 100, default: 150 },
    sortFn: byValue((task) => task.project?.name, DEFAULT_STRING_SORT),
  },
  stage: {
    id: 'stage',
    name: 'Stage',
    size: { min: 100, default: 115 },
    sortFn: byValue(
      (task) => (task.type === 'NORMAL' ? task.stageDefinition?.name : null),
      DEFAULT_STRING_SORT
    ),
  },
  status: {
    id: 'status',
    name: 'Status',
    size: { min: 100, default: 115 },
    sortFn: byValue((task) => task.status.name, DEFAULT_STRING_SORT),
  },
  priority: {
    id: 'priority',
    name: 'Priority',
    size: { min: 100, default: 110 },
    sortFn: sortByPriority,
  },
  completedDuration: {
    id: 'completedDuration',
    name: 'Completed Duration',
    size: { min: 70, default: 95 },
    sortFn: byValue((task) => {
      if (task.type === 'CHUNK') return undefined
      return task.completedDuration
    }, Compare.numeric),
  },
  duration: {
    id: 'duration',
    name: 'Duration',
    size: { min: 82, default: 95 },
    sortFn: byProperty('duration', Compare.numeric),
  },
  blockedBy: {
    id: 'blockedBy',
    name: 'Blocked by',
    size: { min: 100, default: 120 },
  },
  blocking: {
    id: 'blocking',
    name: 'Blocking',
    size: { min: 100, default: 120 },
  },
  workspace: {
    id: 'workspace',
    name: 'Workspace',
    size: { min: 100, default: 150 },
    sortFn: byValue((task) => task.workspace?.name, DEFAULT_STRING_SORT),
  },
  labels: {
    id: 'labels',
    name: 'Labels',
    size: { min: 100, default: 110 },
  },
  assignee: {
    id: 'assignee',
    name: 'Assignee',
    size: { min: 100, default: 175 },
    sortFn: byValue((task) => task.assignee?.name, DEFAULT_STRING_SORT),
  },
  guests: {
    id: 'guests',
    name: 'Guests',
    size: { min: 100, default: 175 },
    sortFn: byValue((task) => {
      if (!isMeetingTask(task)) return null
      const meeting = task.meetingEvent

      if (meeting == null) return null

      return meeting.attendees.length
    }, Compare.numeric),
  },
  startDate: {
    id: 'startDate',
    name: 'Start date',
    size: { min: 100, default: 130 },
    sortFn: sortByStartDate,
  },
  estimatedCompletionTime: {
    id: 'estimatedCompletionTime',
    name: 'Scheduled on',
    size: { min: 100, default: 130 },
    sortFn: sortByEstimatedCompletionTime,
  },
  schedule: {
    id: 'schedule',
    name: 'Schedule',
    size: { min: 100, default: 120 },
  },
  createdAt: {
    id: 'createdAt',
    name: 'Created at',
    size: { min: 100, default: 130 },
    sortFn: byProperty('createdTime', Compare.string),
  },
  updatedAt: {
    id: 'updatedAt',
    name: 'Updated at',
    size: { min: 100, default: 130 },
    sortFn: byProperty('lastInteractedTime', Compare.string),
  },
  completedAt: {
    id: 'completedAt',
    name: 'Completed at',
    size: { min: 100, default: 130 },
    sortFn: byProperty('completedTime', Compare.string),
  },
  createdBy: {
    id: 'createdBy',
    name: 'Created by',
    size: { min: 100, default: 125 },
    sortFn: byValue((task) => task.createdByUser.name, Compare.string),
  },
  folder: {
    id: 'folder',
    name: 'Folder',
    size: { min: 100, default: 200 },
    sortFn: byValue((task) => task.project?.folderId, DEFAULT_STRING_SORT),
  },
  attachments: {
    id: 'attachments',
    name: 'Attachments',
    size: { min: 100, default: 200 },
    sortFn: byValue(
      (task) => (task.type === 'NORMAL' && task?.uploadedFileIds?.length) || 0,
      Compare.numeric
    ),
  },
} satisfies Record<string, FieldDefinition<TaskWithRelations>>

export const TASK_FIELD_LIST = Object.values(
  TASK_FIELDS
) as FieldDefinition<TaskWithRelations>[]

type FieldsWithSortFn<T> = {
  [K in keyof T]: T[K] extends { sortFn: CompareFn<any> } ? K : never
}[keyof T]

export type SortField = FieldsWithSortFn<typeof TASK_FIELDS>
