import { SearchableList } from '@motion/ui/base'

import { useState } from 'react'

import { type DevToolsPages } from './types'
import { useAlertsTriggerItems } from './use-alerts-trigger-items'
import { useFeatureFlagItems } from './use-feature-flag-items'
import { useFirebaseSettingsItems } from './use-firebase-setttings-items'
import { useMainDevToolsItems } from './use-main-dev-tools-items'
import { useUserAgentItems } from './use-user-agent-items'

export const DevToolsSearchableList = () => {
  const [currentPage, setCurrentPage] = useState<DevToolsPages>('main')
  const [ffItems] = useFeatureFlagItems(setCurrentPage)
  const [mainItems] = useMainDevToolsItems(setCurrentPage)
  const [alertTriggerItems, extraComponent] =
    useAlertsTriggerItems(setCurrentPage)
  const [firebaseSettingsItems, firebaseExtraComponent] =
    useFirebaseSettingsItems(setCurrentPage)
  const [userAgentItems] = useUserAgentItems(setCurrentPage)
  let pageItems = mainItems
  switch (currentPage) {
    case 'firebase-settings':
      pageItems = firebaseSettingsItems
      break
    case 'alerts-trigger':
      pageItems = alertTriggerItems
      break
    case 'feature-flags':
      pageItems = ffItems
      break
    case 'user-agent':
      pageItems = userAgentItems
      break
    default:
      pageItems = mainItems
  }

  return (
    <div className='[&_input]:!max-w-full [&_input]:!py-2 [&_input]:!h-12 mx-1'>
      <SearchableList
        items={pageItems}
        computeKey={(item) => item.key}
        computeSelected={() => false}
        inputProps={{ placeholder: 'Search...' }}
        renderItem={(item) => (
          <div
            className='flex w-full justify-between'
            onClick={(e) => e.preventDefault()}
          >
            {item.label}
          </div>
        )}
        onSelect={(item, clearSearch) => {
          item.onSelect()
          if (item.clearSearch) {
            clearSearch()
          }
        }}
      />
      {extraComponent}
      {firebaseExtraComponent}
    </div>
  )
}
