import { UsersSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { useTheme } from '@motion/theme/dom'
import { GradientButton } from '@motion/ui/base'

import { useNavigate } from 'react-router'

import peopleSrc from './people.png'
import sampleGridDark from './sample-grid-dark.png'
import sampleGridLight from './sample-grid-light.png'

import { SidebarOpenButton, TitleBarHeader } from '../../../common/title-bar'

export const UpsellPage = () => {
  const theme = useTheme()

  const image = theme.theme === 'dark' ? sampleGridDark : sampleGridLight
  const navigate = useNavigate()

  const onCreateTeam = () => {
    navigate('/web/settings/team/create')
  }

  return (
    <Container>
      <HeaderBar>
        <TitleBarContainer>
          <SidebarOpenButton />
          <TitleBarHeader>Team Schedule</TitleBarHeader>
        </TitleBarContainer>
      </HeaderBar>

      <Content>
        <img src={image} />
        <CreateTeamBanner>
          <img src={peopleSrc} className='w-[200px]' />
          <span>
            Invite your team to help them unlock even more productivity
          </span>
          <span>
            Stay up to date with your team&apos;s tasks and availability!
          </span>
          <GradientButton sentiment='promotion' onClick={onCreateTeam}>
            <UsersSolid />
            Create Team
          </GradientButton>
        </CreateTeamBanner>
      </Content>
    </Container>
  )
}

const Container = classed('div', {
  base: `
  flex flex-col
  border  border-semantic-neutral-border-subtle
  rounded-t overflow-hidden mr-3
  bg-calendar-bg-default
  `,
})

export const TitleBarContainer = classed('div', {
  base: `
    flex items-center gap-2
    py-3
  `,
})
const HeaderBar = classed('div', {
  base: `
  flex flex-col
  border-b border-semantic-neutral-border-subtle
  pl-6 pr-4
  `,
})

const Content = classed('div', {
  base: `
    flex flex-col
    max-w-7xl    
  `,
})
const CreateTeamBanner = classed('div', {
  base: `
    flex flex-col items-center gap-4 p-6
  `,
})
