import { ExclamationTriangleSolid } from '@motion/icons'
import { templateStr } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'

import React, { type ErrorInfo, type ReactNode } from 'react'

import { MotionLink } from './motion-link'

type ErrorBoundaryProps = {
  children: ReactNode
  onError?: (error: Error, errorInfo: ErrorInfo) => void
} & (
  | {
      renderFallback?: never
      errorMessage?: string
    }
  | {
      renderFallback: (info: { error: Error; reset: () => void }) => ReactNode
      errorMessage?: never
    }
)

type ErrorBoundaryState = {
  error: Error | null
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
    this.onReset = this.onReset.bind(this)
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack,
      },
    })
  }

  onReset() {
    this.setState({ error: null })
  }

  render(): ReactNode {
    if (this.state.error == null) {
      return this.props.children
    }

    return (
      this.props.renderFallback?.({
        error: this.state.error,
        reset: this.onReset,
      }) ?? (
        <p className='text-sm text-semantic-warning-text-default'>
          {templateStr(
            '{{icon}} {{message}}. Please try refreshing or {{contact}}.',
            {
              icon: (
                <ExclamationTriangleSolid
                  height={16}
                  width={16}
                  className='text-semantic-warning-icon-default inline mr-2'
                />
              ),
              message: this.props.errorMessage ?? 'Oops something went wrong',
              contact: (
                <MotionLink
                  url='https://help.usemotion.com'
                  external
                  className='underline'
                >
                  contacting support
                </MotionLink>
              ),
            }
          )}
        </p>
      )
    )
  }
}
