import { type Editor } from '@tiptap/react'

import { StyledDescriptionField } from '../../template-task-modal/components/form-description-field'

export type FormDescriptionFieldProps = {
  editor: Editor | null
}

export const FormDescriptionField = (props: FormDescriptionFieldProps) => {
  const { editor } = props

  return <StyledDescriptionField editor={editor} />
}
