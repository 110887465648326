import { ExternalLinkSolid, InformationCircleSolid } from '@motion/icons'
import { IconButton, LoadingSpinner, Tooltip } from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { type AnalyticEvents } from '@motion/web-base/analytics/events'
import { useHasTreatment } from '@motion/web-common/flags'

import { useProjectTasks } from '~/areas/task-project/hooks'
import { useProject } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useUriByRouteId } from '~/routing'
import { excludeFalsy } from '~/utils/arrayUtils'

import {
  BaseProjectPanel,
  type ProjectPanelTab as BaseProjectPanelTab,
} from './components/base-project-panel'
import {
  ActivityTab,
  AttachmentsTab,
  DescriptionTab,
  FieldsTab,
  TaskTab,
} from './tabs'

import { SidebarTasksProvider, useSidebarTaskContext } from '../context'

type ProjectPanelProps = {
  projectId: string
  workspaceId: string
  enableInlineAdd: boolean
  currentTaskId?: string
  isProjectModal?: boolean
}

export const ProjectPanel = ({
  projectId,
  workspaceId,
  currentTaskId,
  enableInlineAdd,
  isProjectModal,
}: ProjectPanelProps) => {
  return (
    <SidebarTasksProvider
      enableInlineAdd={enableInlineAdd}
      currentTaskId={currentTaskId}
      projectId={projectId}
      workspaceId={workspaceId}
      isProjectModal={isProjectModal}
    >
      <InternalTaskPanel />
    </SidebarTasksProvider>
  )
}

function InternalTaskPanel() {
  const { projectId, workspaceId, isProjectModal } = useSidebarTaskContext()
  const getRouteUri = useUriByRouteId()

  const buildProjectModalUrl = useProjectModalUrl()
  const project = useProject(projectId)

  const { data: tasks, isLoading } = useProjectTasks({
    workspaceId,
    projectId,
  })

  const routingV4 = useHasTreatment('pm-view-based-routing')

  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_PANEL_VIEW')

  if (project == null) return null

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-full'>
        <LoadingSpinner />
      </div>
    )
  }

  const onChangeAnyField = (fieldName: string) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_PANEL_VALUE_CHANGE', {
      fieldName,
    })
  }

  const onOpenButtonClick = (
    operation: AnalyticEvents['PROJECT_MANAGEMENT_PROJECT_PANEL_HEADER_BUTTON']['operation']
  ) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_PANEL_HEADER_BUTTON', {
      operation,
    })
  }

  const onTabChange = (tab: TabValue) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PROJECT_PANEL_TAB_CHANGE', {
      tab,
    })
  }

  const tabs: ProjectPanelTab[] = excludeFalsy([
    {
      title: 'Tasks',
      value: 'tasks',
      content: (
        <TaskTab
          tasks={tasks}
          projectDefinitionId={project.projectDefinitionId}
        />
      ),
    },
    {
      title: 'Description',
      value: 'description',
      content: <DescriptionTab projectId={projectId} />,
    },
    {
      title: 'Activity',
      value: 'activity',
      content: <ActivityTab projectId={projectId} workspaceId={workspaceId} />,
    },
    {
      title: 'Fields',
      value: 'fields',
      content: (
        <FieldsTab
          projectId={projectId}
          showHeader
          onChangeAnyField={onChangeAnyField}
        />
      ),
    },
    {
      title: 'Attachments',
      value: 'attachments',
      content: <AttachmentsTab project={project} tasks={tasks} />,
    },
  ])

  return (
    <BaseProjectPanel
      projectId={project.id}
      header={
        <>
          <Tooltip content='Open project' asChild>
            <IconButton
              icon={InformationCircleSolid}
              url={buildProjectModalUrl({
                project: project.id,
              })}
              sentiment='neutral'
              variant='muted'
              size='small'
              onClick={() => onOpenButtonClick('open-project')}
            />
          </Tooltip>

          <Tooltip content='Open task list view' asChild>
            <IconButton
              icon={ExternalLinkSolid}
              url={
                routingV4
                  ? getRouteUri('project-tasks', {
                      workspaceId,
                      projectId,
                    })
                  : getRouteUri('workspace-project', {
                      workspaceId,
                      projectId,
                      variant: 'list',
                    })
              }
              sentiment='neutral'
              variant='muted'
              size='small'
              onClick={() => onOpenButtonClick('open-task-list-view')}
            />
          </Tooltip>
        </>
      }
      tabs={!isProjectModal ? tabs : undefined}
      onTabChange={onTabChange}
    >
      <TaskTab
        tasks={tasks}
        projectDefinitionId={project.projectDefinitionId}
      />
    </BaseProjectPanel>
  )
}

// eslint-disable-next-line unused-imports/no-unused-vars
const tabValues = [
  'tasks',
  'description',
  'activity',
  'fields',
  'attachments',
] as const

type TabValue = (typeof tabValues)[number]

type ProjectPanelTab = Omit<BaseProjectPanelTab, 'value'> & {
  value: TabValue
}
