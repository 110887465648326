import { Tooltip } from '@motion/ui/base'
import { formatDurationToShort } from '@motion/ui-logic'

import { type WithTaskProps } from './types'

import { useTaskContextApi } from '../../../context/task-context'

export const DurationButton = (props: WithTaskProps) => {
  if (!props.task.duration) return null
  return (
    <Tooltip
      renderContent={() => <DurationTooltip task={props.task} />}
      asChild
    >
      <span className='text-semantic-neutral-text-subtle'>
        {formatDurationToShort(props.task.duration)}
      </span>
    </Tooltip>
  )
}

const DurationTooltip = (props: WithTaskProps) => {
  const api = useTaskContextApi()

  if (props.task.type !== 'CHUNK') {
    return `Duration: ${formatDurationToShort(props.task.duration)}`
  }

  const parent = api.getTaskData(props.task.parentChunkTaskId)?.task
  if (parent == null) {
    return `Duration: ${formatDurationToShort(props.task.duration)}`
  }

  const dayTotal = props.task.duration
  const totalDuration = parent.duration

  if (dayTotal === totalDuration) {
    return `Duration: ${formatDurationToShort(props.task.duration)}`
  }

  return (
    <div className='grid grid-cols-[1fr,auto] place-items-start gap-x-2'>
      <span>Today&apos;s Duration</span>
      <span className='place-self-end'>
        {formatDurationToShort(props.task.duration)}
      </span>
      <span>Total Duration</span>
      <span className='place-self-end'>
        {formatDurationToShort(parent.duration)}
      </span>
    </div>
  )
}
