import { Sentry } from '@motion/web-base/sentry'

import YouTube from 'react-youtube'

import { muteAndPlay } from './mute-and-play'

interface YoutubeVideoProps {
  videoId: string
  onPlay?: () => void
  onEnd?: () => void
  skipAutoPlay?: boolean
  muteOnStart?: boolean
  hideCc?: boolean
}

export const YoutubeVideo = ({
  videoId,
  onPlay = () => {},
  onEnd = () => {},
  skipAutoPlay = false,
  muteOnStart = true,
  hideCc = false,
}: YoutubeVideoProps) => {
  return (
    <YouTube
      videoId={videoId}
      opts={{
        playerVars: {
          autoplay: 0,
          cc_load_policy: hideCc ? undefined : 1,
          rel: 0,
        },
      }}
      className='flex aspect-video w-full max-w-full lg:max-w-[1100px]'
      iframeClassName='aspect-video w-full h-full'
      onReady={async ({ target: player }) => {
        if (!skipAutoPlay) {
          try {
            void player.playVideo()
          } catch (e) {
            Sentry.captureException(e, { tags: { position: 'youtubePlay' } })
          }
        }

        if (muteOnStart) {
          await muteAndPlay(player, muteOnStart)
        }

        if (skipAutoPlay) {
          setTimeout(() => {
            void player.pauseVideo()
          }, 500)
        }
      }}
      onPlay={onPlay}
      onEnd={onEnd}
    />
  )
}
