import {
  type ChartQueryRequestSchema,
  type ChartQueryResponseSchema,
} from '@motion/zod/client'

import { type ReactNode } from 'react'

import { ChartLoadingSpinner } from './styled'

import { useChartQuery } from '../rpc'
import { ChartQueryError } from '../utils'

type ChartQueryDataProviderProps = {
  chartQuery: ChartQueryRequestSchema
  children: (chartQueryResponse: ChartQueryResponseSchema) => ReactNode
}

export function ChartQueryDataProvider({
  chartQuery,
  children,
}: ChartQueryDataProviderProps) {
  const { data: chartQueryResponse, isLoading } = useChartQuery(chartQuery)

  if (isLoading) {
    return <ChartLoadingSpinner />
  }

  if (chartQueryResponse == null) {
    throw new ChartQueryError('chartQueryResponse is null', chartQuery)
  }

  return children(chartQueryResponse)
}
