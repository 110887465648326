import { memo } from 'react'

import { LineContainer } from './common'

import { usePlannerProps, useScrollPosition } from '../context'
import { useCursorPosition, useScreenValues, useSidebarSize } from '../hooks'
import { ZIndexMap } from '../shared-constants'

export const DayLines = memo(function DayLines() {
  return (
    <div className='absolute inset-0 pointer-events-none'>
      <TodayLine />
      <CursorLine />
    </div>
  )
})

const TodayLine = () => {
  const [scrollPosition] = useScrollPosition()
  const { positionOffset } = useScreenValues()
  const sidebarSize = useSidebarSize()

  const todayXValue = positionOffset + scrollPosition

  // If day is over the sidebar, we don't want to show the line
  let shouldShowLine = todayXValue > sidebarSize
  return (
    <LineContainer
      style={{ zIndex: shouldShowLine ? ZIndexMap.dayIndicator : 0 }}
    >
      <div
        style={{
          transform: `translate3D(${todayXValue - 0.5}px,24px,0)`,
        }}
        className='w-px h-[calc(100%-24px)] absolute bg-semantic-primary-border-active'
      />
    </LineContainer>
  )
}

const CursorLine = () => {
  const [plannerProps] = usePlannerProps()
  const { x: rawCursorXPosition } = useCursorPosition()
  const cursorRelativePosX = Math.floor(
    (rawCursorXPosition ?? 0) - (plannerProps.containerRect?.left || 0)
  )

  const cursorRelativePosY = 6
  if (rawCursorXPosition == null) return null
  return (
    <LineContainer
      style={{
        transform: `translate3D(${cursorRelativePosX - 0.5}px,${cursorRelativePosY}px,0)`,
      }}
    >
      <div className='w-px h-full bg-semantic-neutral-border-strong' />
    </LineContainer>
  )
}
