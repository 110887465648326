import { type MultiPersonCustomField } from '@motion/ui-logic'

import {
  AssigneesDisplay,
  CustomField,
} from '~/areas/project-management/custom-fields'
import { type FC } from 'react'

import { CellAction } from '../../components'
import { type ControlledCustomFieldProps } from '../types'

export const ControlledMultiPersonCell: FC<
  ControlledCustomFieldProps<MultiPersonCustomField>
> = ({ value, onSubmit, customField, disabled }) => {
  return (
    <CustomField.MultiPerson
      selectedUserIds={value?.filter(Boolean) ?? []}
      onChange={(v) => onSubmit(v.filter(Boolean))}
      workspaceId={customField.definition.workspaceId}
    >
      <CellAction isFake={disabled} disabled={disabled}>
        <AssigneesDisplay
          userIds={value}
          type='multiPerson'
          hideIconWhenValue
        />
      </CellAction>
    </CustomField.MultiPerson>
  )
}
