import { Button, type ButtonProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type AnalyticEvents } from '@motion/web-base/analytics/events'

import { forwardRef } from 'react'

type CurrentTaskButtonProps = {
  children: React.ReactNode | string
  action: AnalyticEvents['AGENDA_CURRENT_TASK_BUTTON_CLICKED']['action']
  onClick?: (e: React.MouseEvent) => void
} & ButtonProps

export const CurrentTaskButton = forwardRef<
  HTMLButtonElement,
  CurrentTaskButtonProps
>(({ children, onClick, action, ...buttonProps }, ref) => {
  return (
    <Button
      variant='outlined'
      size='small'
      sentiment='neutral'
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        recordAnalyticsEvent('AGENDA_CURRENT_TASK_BUTTON_CLICKED', { action })
        onClick?.(e)
      }}
      ref={ref}
      {...buttonProps}
    >
      {children}
    </Button>
  )
})

CurrentTaskButton.displayName = 'CurrentTaskButton'
