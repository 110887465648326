import { type DefinedRelativeQueryName } from '@motion/zod/client'

export const LogicalFormats = {
  lt: 'before {{value}}',
  lte: 'on or before {{value}}',
  equals: '{{value}}',
  gte: 'on or after {{value}}',
  gt: 'after {{value}}',
}

export const RelativeFormats = {
  next: '@Next {{value}} {{unit}} ({{from}} - {{to}})',
  last: '@Last {{value}} {{unit}} ({{from}} - {{to}})',
}

export const RelativeQueryFormats = {
  today: '@Today ({{from}})',
  yesterday: '@Yesterday ({{from}})',
  tomorrow: '@Tomorrow ({{from}})',

  'next-7-days': '@Next 7 days ({{from}} - {{to}}',
  'next-14-days': '@Next 14 days ({{from}} - {{to}}',
  'last-7-days': '@Last 7 days ({{from}} - {{to}}',
  'last-14-days': '@Last 14 days ({{from}} - {{to}}',

  'this-week': '@This week ({{from}} - {{to}})',
  'next-week': '@Next week ({{from}} - {{to}})',
  'last-week': '@Last week ({{from}} - {{to}})',

  'this-month': '@This month ({{from}} - {{to}})',
  'next-month': '@Next month ({{from}} - {{to}})',
  'next-30-days': '@Next 30 days ({{from}} - {{to}})',
  'last-month': '@Last month ({{from}} - {{to}})',
} satisfies Record<DefinedRelativeQueryName, string>
