import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import {
  FlowVariableSearchableList,
  type FlowVariableSearchableListProps,
} from '~/areas/flows/components'
import { useFlowTemplateForm } from '~/areas/flows/flow-template-modal/hooks'
import { createNewTextVariable } from '~/areas/flows/flow-template-modal/utils'
import { useFieldArray } from 'react-hook-form'

export type ConnectedFlowVariableSearchableListProps<
  T extends FlowTemplateFormFields['textVariables'][number],
> = Omit<FlowVariableSearchableListProps<T>, 'children'> & {
  close?: () => void
}

export function ConnectedFlowVariableSearchableList<
  T extends FlowTemplateFormFields['textVariables'][number],
>(props: ConnectedFlowVariableSearchableListProps<T>) {
  const {
    form: { control },
  } = useFlowTemplateForm()

  const { append } = useFieldArray({
    control,
    name: 'textVariables',
  })

  const handleAddNew = (name: string) => {
    const newTextVariable = createNewTextVariable(name)
    append(newTextVariable)
    props.onChange(newTextVariable as T)
  }

  return <FlowVariableSearchableList {...props} onAddNew={handleAddNew} />
}
