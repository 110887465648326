import { type Editor } from '@tiptap/react'
import { useEffect } from 'react'

import { type FlowVariableProps } from '../flow-variable-extension'
import { type SuggestionItem } from '../suggestion'

export function useSetTiptapStorage(
  editor: Editor | null,
  variableArgs: FlowVariableProps | undefined
) {
  useEffect(() => {
    if (!variableArgs) return

    const options: SuggestionItem[] = variableArgs.textVariables
      .filter((v) => v.name)
      .map((v) => ({
        label: v.name,
        key: v.key,
      }))

    if (editor) {
      // These must be put in the editor storage to be reactive
      editor.storage.flowVariable.suggestions = options
      editor.storage.flowVariable.stages = variableArgs.stages
      editor.storage.flowVariable.textVariables = variableArgs.textVariables
    }
  }, [variableArgs, editor])
}
