import { findDefaultStatus } from '@motion/shared/common'
import { DEFAULT_DURATION } from '@motion/ui-logic'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { getTaskDefaultDatesWithErrorLogging } from '~/areas/tasks/utils'
import { useProject, useWorkspaceStatuses } from '~/global/hooks'
import { useMemo } from 'react'

import type {
  InlineTaskCreationFields,
  InlineTaskCreationFormProps,
} from '../form-types'

export function useInlineTaskDefaultValues({
  workspaceId,
  projectId,
  initialData,
}: Omit<InlineTaskCreationFormProps, 'close'>): InlineTaskCreationFields {
  const { uid: currentUserId } = useAuthenticatedUser()

  const project = useProject(projectId)
  const statuses = useWorkspaceStatuses(workspaceId)
  const defaultStatus = findDefaultStatus(statuses)

  const { startDate, dueDate } = useMemo(
    () =>
      getTaskDefaultDatesWithErrorLogging({
        project,
        stageDefinitionId: initialData?.stageDefinitionId,
      }),
    [project, initialData?.stageDefinitionId]
  )

  return useMemo(
    () => ({
      name: '',
      description: '',
      isAutoScheduled: true,
      priorityLevel: 'MEDIUM' as const,
      assigneeUserId: currentUserId,
      duration: DEFAULT_DURATION,
      minimumDuration: null,
      startDate,
      dueDate,
      statusId: defaultStatus?.id ?? '',
      workspaceId,
      projectId,
      type: 'NORMAL',
      ...initialData,
      // If the stageDefinitionId is not provided, use the active stageDefinitionId of the project
      stageDefinitionId:
        initialData?.stageDefinitionId ??
        project?.activeStageDefinitionId ??
        null,
    }),
    [
      currentUserId,
      startDate,
      dueDate,
      defaultStatus?.id,
      workspaceId,
      projectId,
      initialData,
      project?.activeStageDefinitionId,
    ]
  )
}
