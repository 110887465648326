import { type OOOEventSchema } from '@motion/zod/client'

import { type Row } from '@tanstack/react-table'
import { useCallback } from 'react'

import { useTeamMateOOOEvents } from './use-teammate-ooo-events'

import { showOOOEventForRow } from '../utils'

export function useOOOEventsForRowFn() {
  const { showOOOEvents, teammateOOOEvents } = useTeamMateOOOEvents()

  return useCallback(
    (row: Row<any>): OOOEventSchema[] => {
      if (!row?.original?.value) {
        return []
      }
      const userId = showOOOEventForRow(row) ? row.original.value.value?.id : ''
      return showOOOEvents ? (teammateOOOEvents![userId] ?? []) : []
    },
    [showOOOEvents, teammateOOOEvents]
  )
}
