import { classed } from '@motion/theme'
import { Tab, type TabProps } from '@motion/ui/base'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'

import { useIsAdmin } from '~/global/hooks'
import { useIsTeamExpired, useTeamMembers } from '~/global/hooks/team'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { DetailsPage } from './components/Pages/details-page'
import { GuestPage } from './components/Pages/GuestPage/GuestPage'
import { ManageTeamButtons } from './components/Pages/TeamMembersPage/manage-team-buttons'
import { TeamMembersPage } from './components/Pages/TeamMembersPage/TeamMembersPage'

import { useAppSelector } from '../../state/hooks'
import { selectTeam } from '../../state/team'
import { selectEmail } from '../../state/userSlice'
import { TeamMemberRole } from '../ProjectManagement/types'
import { TabbedSettingsPage } from '../Settings/Components/SettingPage'

enum SidebarTabs {
  Members,
  Billings,
  Details,
}

export const TeamPageWithTabs = () => {
  const teamMembers = useTeamMembers()
  const team = useAppSelector(selectTeam)
  const { uid: userId } = useAuthenticatedUser()
  const userEmail = useAppSelector(selectEmail)
  const isExpiredTeam = useIsTeamExpired()
  const isAdmin = useIsAdmin()
  const navigate = useNavigate()
  const modalApi = useModalApi()
  const { data: teamPrices } = useGetTeamPrices()

  useEffect(
    function showPayTeamFormModal() {
      if (isExpiredTeam && team) {
        modalApi.open('team-trial-ended', {
          doNotShowAgain: () => {
            navigate('/web/settings/calendar')
          },
          onStartTeamPlan: () => {
            modalApi.open('pay-team-form', {
              userEmail,
              team,
              onSuccess: () => {
                window.location.reload()
              },
            })
          },
        })
      }
    },
    [isExpiredTeam, team, modalApi, userEmail, navigate]
  )

  if (!userId || !team || !teamPrices) {
    return null
  }

  const loggedInTeamMember = teamMembers.find(
    (teamMember) => teamMember.userId === userId
  )

  if (loggedInTeamMember?.role === TeamMemberRole.GUEST) {
    return <GuestPage teamName={team.name} />
  }

  // TODO: refactor this to store state in the route
  const tabs: TabProps[] = [
    {
      value: `${SidebarTabs.Members}`,
      name: 'Members',
      children: <TeamMembersPage />,
    },
    {
      value: `${SidebarTabs.Details}`,
      name: 'Details',
      children: <DetailsPage team={team} isAdmin={isAdmin} />,
    },
  ]

  return (
    <Container>
      <TabbedSettingsPage
        title={
          <div className='flex justify-between w-full items-center'>
            <span>Team Settings</span>
            <ManageTeamButtons />
          </div>
        }
      >
        {team &&
          tabs.map((tab) => (
            <Tab key={tab.value} {...tab}>
              <div className='flex flex-col h-full'>
                <div className='px-12 py-6 overflow-y-auto'>
                  <div className='max-w-5xl'>{tab.children}</div>
                </div>
              </div>
            </Tab>
          ))}
      </TabbedSettingsPage>
    </Container>
  )
}

const Container = classed('div', {
  base: 'bg-light-100 dark:bg-dark-1100 flex h-full w-full min-h-0 overflow-x-auto',
})
