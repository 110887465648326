import { classed } from '@motion/theme'

export const BlockersDropdownContainer = classed('div', 'w-[300px]')

export const Header = classed(
  'div',
  'whitespace-nowrap w-full flex items-center justify-between bg-semantic-neutral-bg-subtle text-semantic-neutral-text-subtle',
  'px-3 py-2 gap-1',
  'text-xs font-semibold'
)
