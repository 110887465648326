import {
  CheckCircleGradientSolid,
  CheckSolid,
  ChevronDownSolid,
  PlaySolid,
} from '@motion/icons'
import { useOnValueChange } from '@motion/react-core/hooks'
import {
  ControlledUnstyledCollapsableContainer,
  ProgressCircle,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { markExposure } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { twMerge } from 'tailwind-merge'

import { useTutorialSidebarState } from '../hooks'

export const TutorialsCollapsable = () => {
  const {
    showTutorialSidebar,
    firstSectionCompletedPercent,
    completedLessonIds,
    firstSection,
  } = useTutorialSidebarState()
  const modalApi = useModalApi()

  // We intentionally only want to mark exposure if the user
  // would see the sidebar.
  useOnValueChange(showTutorialSidebar, () => {
    if (showTutorialSidebar) {
      markExposure('show-tutorials-cta')
    }
  })

  if (!showTutorialSidebar || !firstSection) {
    return null
  }

  return (
    <div className='bg-purple-gradient bg-opacity-10 p-3 w-full'>
      <ControlledUnstyledCollapsableContainer
        initialExpanded={firstSectionCompletedPercent !== 100}
        renderHeader={({ expanded, toggle }) => {
          return (
            <button className='flex items-center gap-2' onClick={toggle}>
              <h3 className='text-semantic-gradient-purple font-medium mr-auto'>
                Get Started
              </h3>
              <div className='flex items-center gap-1'>
                {firstSectionCompletedPercent === 100 ? (
                  <CheckCircleGradientSolid className='size-4' />
                ) : (
                  <ProgressCircle
                    size='xsmall'
                    labelHidden
                    value={firstSectionCompletedPercent}
                    sentiment='purple'
                  />
                )}
                <p className='text-xs text-semantic-gradient-purple'>
                  {firstSectionCompletedPercent}%
                </p>
              </div>

              <ChevronDownSolid
                className={twMerge(
                  'transition-transform text-semantic-neutral-icon-default size-4',
                  expanded && 'rotate-180'
                )}
              />
            </button>
          )
        }}
      >
        <div className='flex flex-col gap-3 pt-3 p-1'>
          {firstSection.lessons.map((lesson) => {
            const isCompleted = completedLessonIds.includes(lesson.id)

            return (
              <button
                key={lesson.id}
                className='flex items-center gap-2'
                onClick={() => {
                  modalApi.open('tutorial-modal', {
                    selectedLessonId: lesson.id,
                  })

                  recordAnalyticsEvent('SIDEBAR_TUTORIAL_CLICK')
                }}
              >
                {isCompleted ? (
                  <CheckSolid className='text-semantic-neutral-icon-subtle size-4' />
                ) : (
                  <PlaySolid className='text-semantic-gradient-purple size-4' />
                )}
                <p
                  className={twMerge(
                    'text-xs',
                    isCompleted
                      ? 'text-semantic-neutral-text-subtle line-through'
                      : 'text-semantic-neutral-text-default'
                  )}
                >
                  {lesson.title}
                </p>
              </button>
            )
          })}
        </div>
      </ControlledUnstyledCollapsableContainer>
    </div>
  )
}
