import { useOnValueChange } from '@motion/react-core/hooks'
import { type TrackedEntityTypes } from '@motion/zod/client'

import { useTrackEntity } from '~/global/rpc'

export function useTrackEntityOpen(options: {
  id?: string
  type?: TrackedEntityTypes
}) {
  const { mutateAsync: trackEntityOpen } = useTrackEntity()

  useOnValueChange(
    options,
    () => {
      const { id, type } = options
      if (!id || !type) return

      void trackEntityOpen({ id, type })
    },
    { triggerOnFirstRender: true }
  )
}
