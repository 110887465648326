import { CalendarSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

export const LinkCalendarsCTA = () => {
  const modalApi = useModalApi()

  return (
    <div className='flex flex-col justify-center'>
      <div className='flex flex-col'>
        <div className='w-11 h-11 flex flex-col justify-center items-center bg-semantic-primary-bg-default rounded'>
          <CalendarSolid className='h-6 w-6 text-semantic-primary-icon-default' />
        </div>
        <p className='text-semantic-neutral-text-default font-semibold pt-6 pb-4 text-[24px]'>
          Link a calendar to see your events in Motion
        </p>
        <p className='text-semantic-neutral-text-default text-[16px]'>
          You can easily link your Google, Outlook/Microsoft, and iCloud
          calendars with your Motion account.
        </p>
        <br />
        <p className='text-semantic-neutral-text-default text-[16px] pb-8'>
          Motion will automatically use them to help schedule your tasks
          seamlessly. Happy scheduling! 😊
        </p>
        <Button
          fullWidth
          onClick={() => {
            modalApi.open('link-calendars')
            recordAnalyticsEvent('LINK_CALENDARS_MODAL_OPEN', {
              source: 'settings',
            })
          }}
          sentiment='primary'
        >
          Link a calendar
        </Button>
      </div>
    </div>
  )
}
