import {
  DotsHorizontalSolid,
  TrashSolid,
  UserOutline,
  UserSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import {
  ActionList,
  IconButton,
  PopoverTrigger,
  UserCard,
} from '@motion/ui/base'

import { useController } from 'react-hook-form'

import { AttendeeBadge } from '../../../components'
import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventGuestsListField = () => {
  const modalState = useEventModalState()
  const { form } = useEventForm()
  const { control } = form

  const { field } = useController({
    name: 'attendees',
    control,
  })

  if (modalState.isLoading) return null

  const removeAttendee = (email: string) => {
    const newGuestsValue = field.value.filter((a) => a.email !== email)
    field.onChange(newGuestsValue)
  }

  const markOptional = (email: string, optional: boolean) => {
    const newGuestsValue = field.value.map((a) => {
      if (a.email !== email) return a
      return { ...a, isOptional: optional }
    })
    field.onChange(newGuestsValue)
  }

  return (
    <ul className='overflow-auto'>
      {field.value.map((attendee) => {
        const name = attendee.displayName

        return (
          <ListItem key={attendee.email}>
            <UserCard
              name={name != null ? name : attendee.email}
              email={name != null ? attendee.email : null}
              icon={<AttendeeBadge value={attendee} />}
              tag={
                attendee.isOrganizer
                  ? 'Host'
                  : attendee.isOptional
                    ? 'Optional'
                    : null
              }
            />

            {!modalState.isReadOnly && (
              <EditIconContainer>
                <PopoverTrigger
                  renderPopover={({ close }) => (
                    <ActionList
                      onActionAnyItem={close}
                      items={[
                        !attendee.isOptional && {
                          prefix: <UserOutline />,
                          content: 'Mark optional',
                          onAction: () => {
                            markOptional(attendee.email, true)
                          },
                        },
                        attendee.isOptional && {
                          prefix: <UserSolid />,
                          content: 'Mark required',
                          onAction: () => {
                            markOptional(attendee.email, false)
                          },
                        },
                        {
                          prefix: <TrashSolid />,
                          content: 'Delete',
                          destructive: true,
                          onAction: () => {
                            removeAttendee(attendee.email)
                          },
                        },
                      ]}
                    />
                  )}
                >
                  <IconButton
                    sentiment='neutral'
                    variant='muted'
                    size='xsmall'
                    aria-label='Edit Attendee'
                    icon={DotsHorizontalSolid}
                  />
                </PopoverTrigger>
              </EditIconContainer>
            )}
          </ListItem>
        )
      })}
    </ul>
  )
}

const ListItem = classed('li', {
  base: `
  group/item
  flex flex-row justify-between items-start
  hover:bg-button-neutral-muted-bg-hover
  p-1
  `,
})

const EditIconContainer = classed('div', {
  base: `
  invisible
  group-hover/item:visible
  `,
})
