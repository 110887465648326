import { classed } from '@motion/theme'

export const Wrapper = classed('section', {
  base: `
    border-t border-semantic-neutral-border-default
    bg-semantic-neutral-surface-overlay-bg-subtlest
    shadow-[0px_-2px_4px_-2px_rgba(0,0,0,0.10),0px_-4px_12px_-1px_rgba(0,0,0,0.02)]
  `,
})

export const Header = classed('div', {
  base: `
    bg-semantic-neutral-surface-overlay-bg-subtle
    px-3 py-2
    flex justify-between items-center
  `,
})

export const Content = classed('div', {
  base: `
  p-3
  flex flex-col gap-3
  `,
})

export const NumSelected = classed('span', {
  base: `
    tabular-nums
    rounded-[4px]
    bg-semantic-primary-bg-strong-default
    text-semantic-neutral-text-onDark
    font-semibold
    px-1
    inline-flex items-center justify-center
    min-w-5
    min-h-5
  `,
})

export const DropdownContainer = classed('div', {
  base: `
    max-w-xs
  `,
})
