import { MenuSolid } from '@motion/icons'
import { IconButton } from '@motion/ui/base'
import { getWindowData } from '@motion/web-base/env'

import { useSidebarState } from '~/global/hooks'
import { twMerge } from 'tailwind-merge'

export function OpenLeftSidebarButton({ className }: { className?: string }) {
  const [leftSidebarState, , toggleLeftSidebar] = useSidebarState('global')
  const { isElectron } = getWindowData()

  if (isElectron) return null

  const showButton = !leftSidebarState.open

  return (
    <div
      // Animate the width of the container so the transition to closed sidebar is smoother.
      style={{ width: !showButton ? 0 : 24, height: 24 }}
      className={twMerge(
        'overflow-hidden transition-[width] shrink-0',
        showButton && className
      )}
    >
      <IconButton
        icon={MenuSolid}
        onClick={toggleLeftSidebar}
        sentiment='neutral'
        size='small'
        variant='muted'
      />
    </div>
  )
}
