import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { type WorkspaceCustomField } from '~/areas/custom-fields/types'

import { OldCustomFieldRowButtons } from './old-custom-field-row-buttons'

type CustomFieldRowProps = {
  field: WorkspaceCustomField
}

export const OldCustomFieldRow = ({ field }: CustomFieldRowProps) => {
  return (
    <div className='flex flex-1 justify-between items-center'>
      <div className='flex gap-2 items-center'>
        <CustomFieldBadge type={field.type} />
        {field.name}
      </div>

      <div className='flex gap-3 items-center'>
        <OldCustomFieldRowButtons field={field} />
      </div>
    </div>
  )
}
