import {
  ExternalLinkSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { type ProjectSchema } from '@motion/rpc-types'
import { type ActionItem } from '@motion/ui/base'

import { useCancelProject, useCompleteProject } from '~/areas/project/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useMemo } from 'react'

import { type ActionOptions } from './types'

export const useProjectActions = (
  project: ProjectSchema,
  actionList: ActionOptions[] = [],
  onClose: () => void
) => {
  const completeProject = useCompleteProject()
  const cancelProject = useCancelProject()
  const { getWorkspaceCanceledStatus } = useWorkspaceFns()
  const navigateToModal = useNavigateByGlobalModalId()

  return useMemo<ActionItem[]>(() => {
    return actionList
      .map((action) => {
        switch (action) {
          case 'complete':
            return {
              content: 'Complete project',
              prefix: <StatusCompletedSolid />,
              onAction: () => {
                completeProject(project.id)
                onClose()
              },
            }
          case 'cancel':
            return {
              content: 'Cancel project',
              prefix: (
                <StatusCanceledSolid className='text-dropdown-item-icon-default' />
              ),
              onAction: () => {
                const canceledStatus = getWorkspaceCanceledStatus(
                  project.workspaceId
                )
                if (!canceledStatus) {
                  throw new Error('Canceled status not found')
                }

                cancelProject(project.id, canceledStatus.id)
                onClose()
              },
            }
          case 'edit':
            return {
              content: 'Go to project',
              prefix: <ExternalLinkSolid />,
              onAction: () => {
                navigateToModal('project', { project: project.id })
                onClose()
              },
            }
          default:
            return null
        }
      })
      .filter(Boolean) as ActionItem[]
  }, [
    actionList,
    cancelProject,
    completeProject,
    getWorkspaceCanceledStatus,
    navigateToModal,
    onClose,
    project.id,
    project.workspaceId,
  ])
}
