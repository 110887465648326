import { Button } from '@motion/ui/base'
import { ModalApiProvider } from '@motion/web-common/modals'

import React from 'react'
import { useNavigate } from 'react-router'

import { useAuth } from '../../../localServices/auth'
import { LoadingPage } from '../../LoadingPage'

let LazyPage = React.lazy(() => Promise.resolve({ default: () => <div></div> }))

if (__QA_ENABLED__) {
  LazyPage = React.lazy(() =>
    import('./support-page').then((mod) => ({
      default: mod.LogInAsAnotherUserPage,
    }))
  )
}

export const ConnectedSupportPage = () => {
  const navigate = useNavigate()
  const auth = useAuth()

  if (!__QA_ENABLED__) {
    navigate('/web/calendar')
    return null
  }

  if (auth.status === 'authenticating' || auth.status === 'signing-out') {
    return <LoadingPage id='support-page' />
  }

  if (auth.status === 'authenticated') {
    return (
      <div className='static-loading'>
        <h2>Log out to access this page.</h2>
        <Button onClick={() => auth.logout()}>Log out</Button>
      </div>
    )
  }

  return (
    <ModalApiProvider>
      <React.Suspense fallback={<LoadingPage id='support-page-suspense' />}>
        <LazyPage />
      </React.Suspense>
    </ModalApiProvider>
  )
}
