import { Checkbox } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  type CellContext,
  isRowSelected,
  type Row,
  type RowSelectionState,
} from '@tanstack/react-table'
import { twMerge } from 'tailwind-merge'

import { type GroupedNode } from '../../../grouping'
import { usePivotTableState, usePivotTableStateSendOnly } from '../../hooks'
import { type TreeListRowValueType } from '../../types'

export function SelectRowCheckbox({
  info,
}: {
  info: CellContext<GroupedNode<TreeListRowValueType>, unknown>
}) {
  const { row, table } = info
  const { rowSelection } = table.getState()

  const setSelectedRowId = usePivotTableStateSendOnly('lastSelectedRowId')
  const lastSelectedRowId = usePivotTableState('lastSelectedRowId')

  const checked = getRowCheckedValue(row, rowSelection)

  const onChangeSelection = (value: boolean) => {
    recordAnalyticsEvent('BULK_OPS_CHECKBOX_CLICK', {
      value,
      target: row.getCanExpand() ? 'group' : 'leaf',
    })

    row.toggleSelected(value, { selectChildren: true })
  }

  const isSelectable = row.getCanSelect()

  return (
    <div
      className={twMerge(
        'z-[1] invisible',
        isSelectable &&
          row.original.count > 0 &&
          'group-hover/table-row:visible',
        isSelectable && checked && 'visible'
      )}
    >
      <Checkbox
        label='Select row'
        labelHidden
        variant={row.getCanExpand() ? 'outlined' : 'solid'}
        checked={checked}
        onChange={onChangeSelection}
        onClick={(evt) => {
          if (evt.shiftKey && lastSelectedRowId != null) {
            const { rows, rowsById } = info.table.getRowModel()
            const rowsToToggle = getRowRange(
              rows,
              lastSelectedRowId,
              info.row.id
            )
            const isLastSelected = rowsById[lastSelectedRowId].getIsSelected()

            recordAnalyticsEvent('BULK_OPS_CHECKBOX_RANGE_CLICK', {
              value: isLastSelected,
              rangeSize: rowsToToggle.length,
            })

            rowsToToggle.forEach((row) => row.toggleSelected(isLastSelected))
          } else {
            setSelectedRowId(info.row.id)
          }
        }}
      />
    </div>
  )
}

function getRowCheckedValue(
  row: Row<GroupedNode<TreeListRowValueType>>,
  rowSelection: RowSelectionState
) {
  if (row.original.count === 0) return false

  if (row.getIsSelected()) {
    return true
  }

  if (row.getCanExpand()) {
    const someSelected = isSomeSubRowsSelected(row, rowSelection)
    return someSelected ? 'indeterminate' : false
  }

  return false
}

function getRowRange<T>(rows: Row<T>[], from: string, to: string) {
  const range: Array<Row<T>> = []
  let foundStart = false
  let foundEnd = false

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]

    if (row.id === from || row.id === to) {
      if (foundStart) {
        foundEnd = true
      }
      if (!foundStart) {
        foundStart = true
      }
    }

    // Don't keep the group rows in the range or the total rows
    if (foundStart && !row.getCanExpand() && row.getCanSelect()) {
      range.push(row)
    }

    if (foundEnd) {
      break
    }
  }

  return range
}

// Custom logic because row.getIsSomeSelected is kinda broken
// https://usemotion.slack.com/archives/C07LNCK9CH1/p1731598702843069?thread_ts=1731598610.693519&cid=C07LNCK9CH1
// Issue: https://github.com/TanStack/table/issues/5173
// Potential other fix: https://github.com/TanStack/table/pull/5790
function isSomeSubRowsSelected<T>(
  row: Row<T>,
  rowSelection: RowSelectionState
) {
  if (row.subRows.length === 0) return false

  let allChildrenSelected = true
  let someSelected = false
  row.subRows.forEach((subRow) => {
    if (someSelected && !allChildrenSelected) {
      return
    }
    if (subRow.getCanSelect()) {
      if (isRowSelected(subRow, rowSelection)) {
        someSelected = true
      } else {
        allChildrenSelected = false
      }
    } else {
      // This else case is the only difference with upstream
      allChildrenSelected = false
    }

    if (subRow.subRows && subRow.subRows.length) {
      const subRowChildrenSelected = isSomeSubRowsSelected(subRow, rowSelection)
      if (subRowChildrenSelected === 'all') {
        someSelected = true
      } else if (subRowChildrenSelected === 'some') {
        someSelected = true
        allChildrenSelected = false
      } else {
        allChildrenSelected = false
      }
    }
  })
  return allChildrenSelected ? 'all' : someSelected ? 'some' : false
}
