import { classed } from '@motion/theme'

import { type RenderSortableItemProps } from '~/areas/project-management/components'

import { DragHandle } from './drag-handle'

type DraggableStageTaskCardProps = Partial<RenderSortableItemProps> & {
  isDragging?: boolean
  children: React.ReactNode
}

export function DraggableStageTaskWrapper({
  listeners,
  setNodeRef,
  setActivatorNodeRef,
  isDragging,
  children,
}: DraggableStageTaskCardProps) {
  // No listeners or ref needed when dragging
  if (isDragging) {
    return (
      <DraggableCard isDragging>
        {/* @ts-expect-error - doesn't need listeners */}
        <DragHandle />
        {children}
      </DraggableCard>
    )
  }

  return (
    <DraggableCard ref={setNodeRef}>
      <DragHandle
        listeners={listeners}
        setActivatorNodeRef={setActivatorNodeRef}
      />
      {children}
    </DraggableCard>
  )
}

const DraggableCard = classed('div', {
  base: `
      relative group/card
      `,
  variants: {
    isDragging: {
      true: 'rotate-[-2deg]',
    },
  },
})
