import { isMobileExperience } from '@motion/web-base/env'
import { useSubscriptionState } from '@motion/web-common/subscriptions'

import { navigateToMobileSplashScreen } from '~/routing/utils'
import { type ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router'

type EnsureWebProps = {
  children: ReactNode
}
export const EnsureWeb = (props: EnsureWebProps) => {
  if (isMobileExperience()) {
    return <RedirectToMobileSplash />
  }

  return props.children
}

const RedirectToMobileSplash = () => {
  const navigate = useNavigate()

  const sub = useSubscriptionState()

  useEffect(() => {
    if (sub.state === 'unset') return

    if (sub.state === 'active') {
      navigateToMobileSplashScreen()
      return
    }
    navigate('/checkout')
  }, [navigate, sub.state])

  return null
}
