import { type ProjectWithRelations } from '~/global/proxies'
import { memo } from 'react'

import { ProjectsNoDatesDropdown } from './projects-no-dates-dropdown'

import { TabHeader } from '../../../../../components'
import { type GroupedNode } from '../../../../../grouping'
import { ZIndexMap } from '../../../../shared-constants'
import { type PlannerRowProps } from '../../../common'

export const LegacyLaneName = memo(function LaneName({
  row,
}: PlannerRowProps<any>) {
  if (!row) return null

  const projects = row.original.children?.map(
    (project: GroupedNode) => project.value.value
  ) as ProjectWithRelations[]

  const projectsWithoutDates = projects.filter(
    (project) => !project.startDate && !project.dueDate && project.id
  )

  return (
    <div
      style={{ zIndex: ZIndexMap.rowName }}
      className='h-full absolute left-0 flex items-center shrink-0 bg-semantic-neutral-surface-bg-default first:pl-[var(--indent)]'
    >
      <div className='flex items-start h-full w-[200px] max-w-[200px] cursor-pointer bg-semantic-neutral-surface-bg-default pt-3 pb-1 px-4 flex-col gap-0.5'>
        <div className='truncate max-w-full'>
          <TabHeader item={{ ...row.original, item: row.original }} active />
        </div>
        <ProjectsNoDatesDropdown projects={projectsWithoutDates} />
      </div>
    </div>
  )
})
