import { type BulkOpsTarget } from '../../contexts'

export type BulkOpsConfigBase = {
  showStatusField: boolean
  showPriorityField: boolean
  showDurationField: boolean
  showAssigneeField: boolean
  showStartDateField: boolean
  showDueDateField: boolean
  showStageField: boolean
  showAutoscheduleField: boolean
  showLabelsField: boolean
  showMoveField: boolean
  showArchiveField: boolean
  showDeleteField: boolean
  showCustomFields: boolean
}

type BulkOpsTargetConfig = Record<BulkOpsTarget, BulkOpsConfigBase>

export const BULK_OPS_TARGET_CONFIG: BulkOpsTargetConfig = {
  tasks: {
    showStatusField: true,
    showPriorityField: true,
    showDurationField: true,
    showAssigneeField: true,
    showStartDateField: true,
    showDueDateField: true,
    showStageField: true,
    showAutoscheduleField: true,
    showLabelsField: true,
    showMoveField: true,
    showArchiveField: true,
    showDeleteField: true,
    showCustomFields: true,
  },
  projects: {
    showStatusField: false,
    showPriorityField: false,
    showDurationField: false,
    showAssigneeField: false,
    showStartDateField: false,
    showDueDateField: false,
    showStageField: false,
    showAutoscheduleField: false,
    showLabelsField: false,
    showMoveField: true,
    showArchiveField: false,
    showDeleteField: false,
    showCustomFields: false,
  },
}
