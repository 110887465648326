import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { Events } from '../../analyticsEvents'

const ZOOM_CLIENT_ID = '20NSmx_qQqGJVpw9eqXU1g'

type ZoomAuthState = {
  desktopApp?: boolean
}

/**
 * Opens a new window to the Zoom OAuth page.
 **/
export const openZoomAuthWindow = () => {
  let redirectUri = 'https://www.inmotion.app/zoom-oauth'
  const isDesktopApp =
    navigator?.userAgent?.toLowerCase()?.indexOf(' electron/') > -1

  // State params for Zoom OAuth callback
  const extraParams: ZoomAuthState = {}

  switch (__ENV__) {
    case 'local':
      // This is a webflow redirect since Zoom does not allow redirects
      // to localhost:3000.
      redirectUri = 'https://inmotion.app/zoom-oauth-dev'
      break
    default:
      redirectUri = `${__FRONTEND_HOST__}/zoom-oauth`
      break
  }

  if (isDesktopApp) {
    extraParams.desktopApp = true
  }

  let zoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${redirectUri}`

  if (Object.entries(extraParams).length) {
    zoomUrl += `&state=${encodeURIComponent(JSON.stringify(extraParams))}`
  }

  window.open(zoomUrl, '_blank', 'height=600,width=800')

  recordAnalyticsEvent(Events.CALENDAR_ZOOM_AUTH_OPEN)
}
