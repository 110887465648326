import { QuestionMarkCircleSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

const DEFAULT_TOOLTIP_CONTENT =
  'If a task has a hard deadline, Motion will prioritize the task and make sure it is scheduled before its deadline; if needed, Motion may schedule it outside of work hours (or another selected schedule) in order to meet deadline.'

type HardDeadlineTooltipIconProps = {
  tooltipContent?: string
  size?: number
}

export function HardDeadlineTooltipIcon({
  tooltipContent = DEFAULT_TOOLTIP_CONTENT,
  size = 16,
}: HardDeadlineTooltipIconProps) {
  return (
    <Tooltip content={tooltipContent}>
      <QuestionMarkCircleSolid
        className='text-semantic-neutral-icon-subtle'
        width={size}
        height={size}
      />
    </Tooltip>
  )
}
