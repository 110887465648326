import { ArrowDownSolid, ArrowUpSolid } from '@motion/icons'
import { IconButton, Tooltip, useShortcut } from '@motion/ui/base'
import { sleep } from '@motion/utils/promise'
import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useNavigate } from 'react-router'

export interface PreviousNextModalButtonsProps {
  previousId: string | null
  nextId: string | null
  buildUrl: (id: string) => string
  renderTooltip: (
    id: TaskSchema['id'] | ProjectSchema['id'],
    shortcut: string,
    isNext?: boolean
  ) => React.ReactNode
}

export const PreviousNextModalButtons = ({
  previousId,
  nextId,
  buildUrl,
  renderTooltip,
}: PreviousNextModalButtonsProps) => {
  if (previousId == null && nextId == null) return null

  return (
    <InnerArrowButtons
      previousId={previousId}
      nextId={nextId}
      buildUrl={buildUrl}
      renderTooltip={renderTooltip}
    />
  )
}

const InnerArrowButtons = ({
  previousId,
  nextId,
  buildUrl,
  renderTooltip,
}: PreviousNextModalButtonsProps) => {
  const navigate = useNavigate()

  const canGoPrev = previousId != null
  const canGoNext = nextId != null

  const prevUrl = canGoPrev ? buildUrl(previousId) : undefined
  const nextUrl = canGoNext ? buildUrl(nextId) : undefined

  async function navigateTo(url: string | undefined) {
    if (url == null) return

    // Because modals can have autosave on blur,
    // we should make sure to blur elements before navigating to properly save the current item
    const focusedElement = document.activeElement as HTMLElement | null
    focusedElement?.blur()
    await sleep(1)
    navigate(url)
    focusedElement?.focus()
  }

  useShortcut('mod+arrowup', () => navigateTo(prevUrl), {
    enabled: canGoPrev,
  })
  useShortcut('mod+arrowdown', () => navigateTo(nextUrl), {
    enabled: canGoNext,
  })

  return (
    <div className='flex flex-col gap-1'>
      <Tooltip
        asChild
        renderContent={
          previousId && canGoPrev
            ? () => renderTooltip(previousId, 'mod+arrowup')
            : undefined
        }
      >
        <IconButton
          icon={ArrowUpSolid}
          sentiment='onDark'
          size='small'
          variant='muted'
          disabled={!canGoPrev}
          url={prevUrl}
          aria-label='Go to previous'
        />
      </Tooltip>
      <Tooltip
        asChild
        renderContent={
          nextId && canGoNext
            ? () => renderTooltip(nextId, 'mod+arrowdown', true)
            : undefined
        }
      >
        <IconButton
          icon={ArrowDownSolid}
          sentiment='onDark'
          size='small'
          variant='muted'
          disabled={!canGoNext}
          url={nextUrl}
          aria-label='Go to next'
        />
      </Tooltip>
    </div>
  )
}
