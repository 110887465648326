import { classed } from '@motion/theme'

import { UserLabel } from '~/global/components/labels'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { type suggestion } from './rte-extension'

import { type MentionItem } from '../../types'

type MentionListProps = {
  items: MentionItem[]
  command: ({ id, label }: { id: string; label: string }) => void
}

// For convenience, provide typing for the ref object which is used both here in MentionList.tsx and suggestion.ts
export type SuggestionListRef = {
  onKeyDown: NonNullable<
    ReturnType<
      NonNullable<ReturnType<typeof suggestion>['render']>
    >['onKeyDown']
  >
}

export const MentionList = forwardRef<SuggestionListRef, MentionListProps>(
  ({ items = [], command }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = (index: number) => {
      const item = items[index]

      if (item) {
        command({ id: item.value, label: item.label })
      }
    }

    const upHandler = () => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length)
    }

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % items.length)
    }

    const enterHandler = () => {
      selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          downHandler()
          return true
        }

        if (event.key === 'Enter' || event.key === 'Tab') {
          enterHandler()
          return true
        }

        return false
      },
    }))

    return (
      <MentionListContainer>
        {items.map((item, index) => (
          <MentionListItem
            key={index}
            data-active={index === selectedIndex || undefined}
            onClick={() => selectItem(index)}
            onPointerEnter={() => setSelectedIndex(index)}
          >
            <UserLabel value={item.user} />
          </MentionListItem>
        ))}
        {!items.length && <p className='px-4 py-1 text-left'>No result</p>}
      </MentionListContainer>
    )
  }
)
MentionList.displayName = 'MentionList'

const MentionListContainer = classed('div', {
  base: `
    flex flex-col items-start min-w-[175px] p-1
    shadow-lg rounded overflow-hidden
    border border-dropdown-item-bg-hover
    text-dropdown-item-text-default bg-dropdown-bg
  `,
})

const MentionListItem = classed('button', {
  base: `
    flex gap-1 items-center
    w-full px-2 h-[32px]
    rounded
    data-[active]:bg-dropdown-item-bg-hover
  `,
})
