export const renderSelection = <T extends { id: string; name: string }>(
  selected: T[],
  items: T[]
) => {
  return selected.length === items.length
    ? 'All'
    : selected.length === 1
      ? selected[0].name
      : selected.length > 1
        ? `${selected.length} selected`
        : 'All' // an empty list is the same as no filter
}
