import { ProjectPalette } from '@motion/ui/project'

import { type ProjectWithRelations } from '~/global/proxies'

import { ProjectRowName } from './project-row-name'
import { SingleItemLane } from './single-item-lane'

import { DragRowContainer } from '../drag-row-container'

type ProjectRowProps = {
  project?: ProjectWithRelations
}

export const ProjectRow = ({ project }: ProjectRowProps) => {
  if (!project) return null

  return (
    <div className='w-full h-12 flex flex-row items-center relative'>
      <ProjectPalette color={project.color}>
        <ProjectRowName project={project} />
        <DragRowContainer project={project}>
          <SingleItemLane project={project} />
        </DragRowContainer>
      </ProjectPalette>
    </div>
  )
}
