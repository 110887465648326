import { CogSolid } from '@motion/icons'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { type ProjectSchema } from '@motion/zod/client'

import { WorkspaceBadge } from '~/global/components/badges'
import { type OrderedWorkspaceProjectList } from '~/global/hooks'
import { useUriByRouteId } from '~/routing'
import { useMemo } from 'react'

import {
  ProjectActionMenu,
  WorkspaceActionMenu,
  WorkspaceFilterButton,
} from '../treeviews/workspace-treeview/components'
import {
  type WorkspaceTreeItem,
  type WorkspaceTreeItems,
} from '../treeviews/workspace-treeview/workspace-treeview.types'

export const useWorkspaceTree = (
  workspaces: OrderedWorkspaceProjectList,
  searchQuery: string
) => {
  const getRouteUri = useUriByRouteId({ noDefaults: true })

  return useMemo(() => {
    const workspaceItems: WorkspaceTreeItems = workspaces.map(
      (
        { item: w, expanded, filteredStatusIds, filteredStatusIdsIsDefault },
        wIndex
      ) => {
        const projects = w.projects.map((project, index) =>
          projectToTreeItem(
            project,
            getRouteUri('workspace-project', {
              workspaceId: w.id,
              projectId: project.id,
            }),
            {
              up: !!searchQuery || index === 0,
              down: !!searchQuery || index === w.projects.length - 1,
            }
          )
        )

        return {
          id: w.id,
          label: w.name,
          value: w.id,
          isContainer: true,
          renderActions: (hovering) => {
            const filtersButton = !searchQuery ? (
              <WorkspaceFilterButton
                key='filters'
                workspace={w}
                filteredStatusIds={filteredStatusIds}
                isDefaultValue={filteredStatusIdsIsDefault}
              />
            ) : null

            return (
              <ButtonGroup size='small' nowrap>
                {hovering && (
                  <>
                    <IconButton
                      icon={CogSolid}
                      size='xsmall'
                      sentiment='neutral'
                      variant='muted'
                      url={getRouteUri('workspace-settings', {
                        workspaceId: w.id,
                      })}
                    />

                    {filteredStatusIdsIsDefault && filtersButton}

                    <WorkspaceActionMenu
                      workspaceId={w.id}
                      moveActionDisabled={{
                        up: !!searchQuery || wIndex === 0,
                        down: !!searchQuery || wIndex === workspaces.length - 1,
                      }}
                    />
                  </>
                )}

                {/* Always last */}
                {!filteredStatusIdsIsDefault && filtersButton}
              </ButtonGroup>
            )
          },
          expanded,
          filteredStatusIds,
          filteredStatusIdsIsDefault,
          children: projects,
          url: getRouteUri('workspace-detail', { workspaceId: w.id }),
          icon: (props: any) => (
            <div className='size-4 ml-0.5'>
              <div {...props}>
                <WorkspaceBadge />
              </div>
            </div>
          ),
        }
      }
    )
    return workspaceItems
  }, [getRouteUri, searchQuery, workspaces])
}

const projectToTreeItem = (
  project: ProjectSchema,
  url: string,
  moveActionDisabled: { up: boolean; down: boolean }
): WorkspaceTreeItem => {
  return {
    id: project.id,
    parentId: project.workspaceId,
    label: project.name,
    value: project.id,
    children: [],
    expanded: false,
    filteredStatusIds: [],
    filteredStatusIdsIsDefault: false,
    project,
    url,
    disabledMoveActions: moveActionDisabled,
    renderActions: (hovering) =>
      hovering && (
        <ProjectActionMenu
          project={project}
          moveActionDisabled={moveActionDisabled}
        />
      ),
  }
}
