import { Popover } from '@motion/ui/base'

type PeekModalPopoverProps = {
  children: React.ReactNode
  anchor: HTMLElement
  onClose?: (event: Event | undefined) => void
}
export const PeekModalPopover = ({
  children,
  anchor,
  onClose,
}: PeekModalPopoverProps) => {
  return (
    <Popover
      showArrow
      enableOutsideInteractions
      offset={14}
      placement='left'
      triggerRef={{ current: anchor }}
      onClose={(event) => {
        if (event instanceof KeyboardEvent && event.key === 'Escape') {
          onClose?.(event)
        }
      }}
    >
      <div
        className='overflow-hidden flex text-semantic-neutral-text-default'
        style={{ maxHeight: `calc(100vh - 64px)` }}
      >
        {children}
      </div>
    </Popover>
  )
}
