import { BackendApiService } from './BackendApiService'

export class SubscriptionService extends BackendApiService {
  static id = 'SubscriptionService' as const

  async autoRefund(): Promise<{ success?: string; error?: string }> {
    try {
      const res = await this.request('subscriptions/auto-refund', 'POST')
      if (res.success) return res
      return { error: res.error }
    } catch (err: any) {
      return { error: err.message }
    }
  }
}

export const handler = new SubscriptionService()
