import { NEW_EVENT_ID } from './constants'
import { type FullCalendarMotionNewEvent, type NewEventType } from './types'

export function createNewFullCalendarMotionEvent(
  selectedEvent: NewEventType
): FullCalendarMotionNewEvent {
  return {
    durationEditable: true,
    end: selectedEvent.end,
    id: NEW_EVENT_ID,
    resourceEditable: true,
    resourceId: 'mine',
    start: selectedEvent.start,
    startEditable: true,
    title: '',
    allDay: selectedEvent.allDay,
  }
}
