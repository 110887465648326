import { WorkspaceSettings } from '~/areas/project-management/pages/workspace-settings'
import { SelectedWorkspaceIdsContext } from '~/global/contexts'
import { useSearchParams } from '~/routing'
import { useParams } from 'react-router-dom'

export const WorkspaceSettingsPage = () => {
  const searchParams = useSearchParams<{ workspaceId?: string }>()
  const params = useParams<{ workspaceId?: string }>()

  const workspaceId = params.workspaceId ?? searchParams.workspaceId

  if (!workspaceId) {
    return null
  }

  return (
    <SelectedWorkspaceIdsContext
      key={workspaceId}
      name='workspace-settings'
      selectedWorkspaceIds={[workspaceId]}
    >
      <WorkspaceSettings workspaceId={workspaceId} />
    </SelectedWorkspaceIdsContext>
  )
}
