import { AnalyticsMetadata } from '@motion/web-base/analytics'

import { type ReactNode } from 'react'
import { useMatches, useSearchParams } from 'react-router-dom'

import { type RouteAnalyticsMetadataParams } from './types'

type RouteAnalyticsMetadataProps = {
  children: ReactNode
}
export const RouteAnalyticsMetadata = (props: RouteAnalyticsMetadataProps) => {
  const matches = useMatches()
  const [params] = useSearchParams()

  const tags = reduceTags(matches)

  return (
    <AnalyticsMetadata
      data={{
        page: 'N/A',
        ...tags,
        modal: getCurrentModal(params),
        section: 'none',
      }}
    >
      {props.children}
    </AnalyticsMetadata>
  )
}

function reduceTags(routes: { handle: unknown }[]) {
  return routes.reduce(
    (acc, route) => {
      if (route.handle == null) return acc
      if (typeof route.handle !== 'object') return acc
      if (!('tags' in route.handle)) return acc
      if (typeof route.handle.tags !== 'object') return acc

      return { ...acc, ...route.handle.tags }
    },
    {} as Record<string, string>
  )
}

function getCurrentModal(
  params: URLSearchParams
): RouteAnalyticsMetadataParams['modal'] {
  const task = params.get('task')
  const project = params.get('project')
  const flowTemplateId = params.get('flowTemplateId')
  const template = params.get('template')
  const templateId = params.get('templateId')
  const meetingTaskId = params.get('mTask')

  if (meetingTaskId) return 'meeting'

  if (task) return 'task'

  if (project) {
    if (project === 'create') {
      if (flowTemplateId) return 'setup-project'
      return 'create-project'
    }
    return 'project'
  }

  if (template === 'create') return 'create-template'
  if (template === 'new') {
    if (templateId) return 'setup-template-from'
    return 'setup-template'
  }

  return 'none'
}
