import { ModalFieldButton } from '~/areas/task-project/components'
import { ColorDropdown } from '~/global/components/dropdowns'
import { ColorLabel } from '~/global/components/labels'
import { useController } from 'react-hook-form'

import { useFlowTemplateForm } from '../hooks'

export const ControlledColorField = () => {
  const { form } = useFlowTemplateForm()
  const { control } = form

  const { field } = useController({
    name: 'color',
    control,
  })

  return (
    <ModalFieldButton label='Color'>
      <ColorDropdown selectedColor={field.value} onChange={field.onChange}>
        <button className='flex gap-1 items-center'>
          <ColorLabel color={field.value} />
        </button>
      </ColorDropdown>
    </ModalFieldButton>
  )
}
