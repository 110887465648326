import { classed } from '@motion/theme'

import { twMerge } from 'tailwind-merge'

import { Header } from '../../../Common'

interface SettingPageProps {
  title?: React.ReactNode
  children: React.ReactNode
  className?: string
  fullWidth?: boolean
  banner?: React.ReactNode
  tabGroup?: React.ReactNode
}

export const SettingPage = ({
  title,
  children,
  className,
  fullWidth,
  banner,
  tabGroup,
}: SettingPageProps) => {
  return (
    <Container>
      {title && (
        <div className='flex w-full items-center py-3.5 px-12 bg-calendar-bg-default'>
          <Header className='mb-0 text-lg w-full'>{title}</Header>
        </div>
      )}
      {tabGroup}
      {banner}
      <div className='px-12 py-6 overflow-auto h-full w-full'>
        <div
          className={twMerge(
            'flex h-full w-full flex-col gap-4 self-start',
            !fullWidth && 'max-w-4xl',
            className
          )}
        >
          {children}
        </div>
      </div>
    </Container>
  )
}

const Container = classed('div', {
  base: `
    dark:divide-dark-1000 divide-light-500 
    flex h-full w-full flex-col tems-start divide-y overflow-x-scroll
    bg-calendar-bg-default overflow-auto max-w-full
  `,
})
