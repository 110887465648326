import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { truncateLink } from '~/areas/activity-feed/utils'
import { MotionLink } from '~/global/components'

import { ActivityValue } from '../activity-value'

type ActivityUrlProps = {
  url: string | null | undefined
}

/**
 * Component to help shrink long urls in the activity feed
 */
export function ActivityUrl({ url }: ActivityUrlProps) {
  if (!url) return <ActivityValue>None</ActivityValue>

  const onClick = () => {
    recordAnalyticsEvent('ACTIVITY_FEED_LINK_CLICK', {
      url,
      type: 'custom-field',
    })
  }

  return (
    <MotionLink
      external
      className='text-semantic-primary-text-default'
      url={url}
      onClick={onClick}
    >
      {truncateLink(url)}
    </MotionLink>
  )
}
