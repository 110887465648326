import { priorityLevels } from '@motion/rpc/types'
import { priorityLabels } from '@motion/ui-logic'

import { PriorityLabel } from '~/global/components/labels'

import { FilterButton } from './filter-button'

import { useFieldFilter } from '../../context'
import { buildIdFilter } from '../../utils'
import { type DropdownFilterProps } from '../types'

const priorityItems = priorityLevels.map((p) => ({
  id: p,
  name: priorityLabels.get(p) ?? p,
}))

export const ConnectedPriorityFilter = (props: DropdownFilterProps) => {
  const [filter, setFilter] = useFieldFilter(props.target, 'priorities')

  return (
    <FilterButton
      itemType='checkbox'
      items={priorityItems}
      searchPlaceholder='Choose priority ...'
      computeSelected={(item) => filter?.value.includes(item.id) ?? false}
      onSelect={(values) => {
        setFilter(buildIdFilter(values, filter) as any)
      }}
      label='Priority'
      renderItem={(priority) => <PriorityLabel value={priority.id} />}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
    />
  )
}
