import { useDependantState } from '@motion/react-core/hooks'
import { MAX_FOLDER_DEPTH } from '@motion/shared/common'
import { Portal } from '@motion/ui/base'
import { type Nullable } from '@motion/utils/types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import {
  type CollisionDetection,
  DndContext,
  type DragEndEvent,
  type DragMoveEvent,
  type DragOverEvent,
  DragOverlay,
  type DragStartEvent,
  type DroppableContainer,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useMoveProjectInFolder } from '~/areas/folders/hooks'
import { useUpdateItemInFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'
import { pastVerticalCenter } from '~/utils/pm-revamp/collision-detection-utils'
import { useCallback, useMemo, useRef, useState } from 'react'

import { ConnectedSortableTreeviewItem, ProjectPlaceholder } from './components'
import {
  adjustTranslate,
  dropAnimationConfig,
  indentationWidth,
  itemHeight,
  measuringConfiguration,
} from './constants'
import { useDragProjection } from './hooks'
import {
  type FlattenedSidebarWorkspacesTreeItem,
  type ItemIdentifier,
  type SortableItemData,
} from './types'
import { flattenTree } from './utils'

import { type SidebarWorkspacesTreeItem } from '../../types'

type FolderTreeviewProps = {
  items: SidebarWorkspacesTreeItem[]
}

export const FolderTreeview = ({ items }: FolderTreeviewProps) => {
  const treeviewRef = useRef<HTMLDivElement>(null)
  const moveProjectInFolder = useMoveProjectInFolder()
  const { mutateAsync: updateItemInFolder } = useUpdateItemInFolder()

  const [activeId, setActiveId] = useState<ItemIdentifier | null>(null)
  const [overId, setOverId] = useState<ItemIdentifier | null>(null)
  const [dragLevel, setDragLevel] = useState(0)

  const [flattenedItems, setOptimisticItems] = useDependantState(
    () => flattenTree(items),
    [items]
  )

  const renderableItems = useMemo(() => {
    if (!activeId) return flattenedItems

    const activeItemIdx = flattenedItems.findIndex(({ id }) => activeId === id)
    const activeItem = flattenedItems[activeItemIdx]

    if (activeItemIdx === -1 || !activeItem) return flattenedItems

    // Collapse all workspaces when dragging a workspace
    if (activeItem.type === 'WORKSPACE') {
      return flattenedItems.filter(({ level }) => level === 0)
    }

    const childCount = flattenedItems
      .slice(activeItemIdx)
      .findIndex((_item, i, arr): boolean => {
        const nextItem = arr[i + 1]

        if (!nextItem) return true

        return nextItem.level <= activeItem.level
      })

    if (childCount <= 0) return flattenedItems

    return flattenedItems.toSpliced(activeItemIdx + 1, childCount)
  }, [flattenedItems, activeId])

  const projected = useDragProjection(
    flattenedItems,
    activeId,
    overId,
    dragLevel
  )

  const sensors = useSensors(useSensor(PointerSensor))

  const activeItem: Nullable<FlattenedSidebarWorkspacesTreeItem> =
    (activeId
      ? renderableItems.find(({ id }) => id === activeId)
      : undefined) ?? null

  const collisionDetection: CollisionDetection = useCallback(
    (args) => {
      if (!activeItem) return []

      const activeItemType = activeItem.type
      const activeItemWorkspaceId = activeItem.item.workspaceId

      const filterContainers = (container: DroppableContainer) => {
        const containerData = container.data.current as SortableItemData

        if (activeItemType === 'WORKSPACE') {
          // allowed in root only and the end of a workspace only
          return containerData.type === 'WORKSPACE'
        }

        // We do not support moving folders/projects between workspaces at this time
        if (containerData.item.workspaceId !== activeItemWorkspaceId) {
          return false
        }

        if (activeItemType === 'FOLDER') {
          return (
            containerData.type !== 'WORKSPACE' &&
            containerData.level <= MAX_FOLDER_DEPTH
          )
        }

        if (activeItemType === 'PROJECT') {
          return containerData.type !== 'WORKSPACE'
        }

        return false
      }

      return pastVerticalCenter({
        ...args,
        droppableContainers: args.droppableContainers.filter(filterContainers),
      })
    },
    [activeItem]
  )

  const getItemKey = useCallback(
    (index: number) => renderableItems[index].id,
    [renderableItems]
  )

  const getScrollElement = useCallback(
    () =>
      // Returns the parent <ScrollArea> component
      treeviewRef.current?.closest('[data-radix-scroll-area-viewport]') ?? null,
    []
  )

  const rowVirtualizer = useVirtualizer({
    count: renderableItems.length,
    estimateSize: () => itemHeight,
    gap: 4, // matches space-y-1
    getItemKey,
    getScrollElement,
    overscan: 15,
    scrollMargin: treeviewRef.current?.offsetTop ?? 0,
  })

  const virtualItems = rowVirtualizer.getVirtualItems()

  return (
    <div ref={treeviewRef}>
      <DndContext
        collisionDetection={collisionDetection}
        measuring={measuringConfiguration}
        onDragCancel={resetState}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <div
          className='relative w-full'
          style={{ minHeight: `${rowVirtualizer.getTotalSize()}px` }}
        >
          <SortableContext
            items={renderableItems}
            strategy={verticalListSortingStrategy}
          >
            <div
              className='absolute top-0 left-0 w-full space-y-1'
              style={{
                transform: `translateY(${(virtualItems[0]?.start ?? 0) - rowVirtualizer.options.scrollMargin}px)`,
              }}
            >
              {virtualItems.map(({ key, index }) => {
                const item = renderableItems[index]
                const level =
                  item.id === activeId && projected
                    ? projected.level
                    : item.level

                return (
                  <div
                    key={key}
                    ref={rowVirtualizer.measureElement}
                    data-index={index}
                  >
                    {item.type === 'PLACEHOLDER' ? (
                      <ProjectPlaceholder item={item} />
                    ) : (
                      <ConnectedSortableTreeviewItem
                        item={item}
                        level={level}
                        projection={projected}
                      />
                    )}
                  </div>
                )
              })}
            </div>

            <Portal container={document.body}>
              <DragOverlay
                modifiers={[adjustTranslate]}
                dropAnimation={dropAnimationConfig}
              >
                {activeItem ? (
                  <ConnectedSortableTreeviewItem
                    item={activeItem}
                    level={activeItem.level}
                    projection={projected}
                    disableDrag
                    isGhost
                  />
                ) : null}
              </DragOverlay>
            </Portal>
          </SortableContext>
        </div>
      </DndContext>
    </div>
  )

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    document.body.style.setProperty('cursor', 'grabbing')

    if (!treeviewRef.current) return

    // Prevent the tree from jumping when dragging and the parent container size
    // changes, like when moving a folder or workspace
    treeviewRef.current.style.setProperty(
      'min-height',
      `${treeviewRef.current.scrollHeight}px`
    )
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    const newOffsetLeft = Math.round(delta.x / indentationWidth)

    if (dragLevel !== newOffsetLeft) {
      setDragLevel(newOffsetLeft)
    }
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  async function handleDragEnd({ active }: DragEndEvent) {
    resetState()

    const activeItemData = active.data.current as SortableItemData | undefined

    if (!activeItemData) {
      return void Sentry.captureException(
        new Error('handleDragEnd active item data is undefined'),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    if (!projected) {
      return void Sentry.captureException(
        new Error('handleDragEnd projected state is undefined'),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    if (!projected.hasMoved) return

    setOptimisticItems(projected.newItems)

    const itemType = activeItemData.type

    if (!projected.parentId) {
      // Folders and projects must have a parent ID to continue
      return void Sentry.captureException(
        new Error(
          'Parent folder ID was not set when attempting to move folder/project'
        ),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    const { order, parentId } = projected

    if (itemType === 'WORKSPACE') {
      const parentFolderId = projected.parentId.toString()
      const itemId = activeItemData.item.id

      recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

      try {
        await updateItemInFolder({
          parentFolderId,
          itemId,
          order,
        })
      } catch (e) {
        Sentry.captureException(
          new Error('Failed to move workspace', { cause: e }),
          {
            extra: {
              parentFolderId,
              itemId,
              order,
            },
            tags: {
              position: 'FolderTreeview',
            },
          }
        )
      }

      return
    }

    const parentFolder = projected.newItems.find(
      ({ item }) => item.id === parentId.toString()
    )

    if (!parentFolder) {
      return void Sentry.captureException(
        new Error('Parent folder could not be found in flattened folder tree'),
        {
          extra: {
            activeId,
            overId,
            projected,
          },
          tags: {
            position: 'FolderTreeview',
          },
        }
      )
    }

    const parentFolderId = parentFolder.item.itemId

    if (itemType === 'FOLDER') {
      const itemId = activeItemData.item.id

      recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

      try {
        await updateItemInFolder({
          parentFolderId,
          itemId,
          order,
        })
      } catch (e) {
        if (e instanceof Error && e.message === 'Folder depth limit reached') {
          return void showErrorToast(
            `You cannot exceed a folder depth of ${MAX_FOLDER_DEPTH}`
          )
        }

        Sentry.captureException(
          new Error('Failed to update item in folder', { cause: e }),
          {
            extra: {
              parentFolderId,
              itemId,
              order,
            },
            tags: {
              position: 'FolderTreeview',
            },
          }
        )

        showErrorToast(e, 'Failed to move folder')
      }

      return
    }

    if (itemType === 'PROJECT') {
      const projectId = activeItemData.item.itemId

      recordAnalyticsEvent('FOLDERS_DRAGGED_SIDEBAR_ITEM', { itemType })

      try {
        await moveProjectInFolder(projectId, parentFolderId, order)
      } catch (e) {
        Sentry.captureException(
          new Error('Failed to update project in folder', { cause: e }),
          {
            extra: {
              projectId,
              parentFolderId,
              order,
            },
            tags: {
              position: 'FolderTreeview',
            },
          }
        )
      }
    }
  }

  function resetState() {
    setActiveId(null)
    setDragLevel(0)
    setOverId(null)

    document.body.style.setProperty('cursor', '')

    treeviewRef.current?.style.removeProperty('min-height')
  }
}
