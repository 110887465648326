import { safeJsonParse } from '@motion/utils/object'

import { useMemo } from 'react'
import {
  useLocation as useWindowLocation,
  useSearchParams as useRouterSearchParams,
} from 'react-router-dom'

import { routeFrom } from '../utils'

/**
 * read url hash, response to url hash change
 * https://github.com/streamich/react-use/blob/master/src/useHash.ts
 */
/* eslint-disable react-hooks/rules-of-hooks */
export const useHash = () => {
  const location = useWindowLocation()
  const override = routeFrom(location)
  return [override]
}
/* eslint-enable react-hooks/rules-of-hooks */

/**
 *
 * @returns The param values
 */
export const useSearchParams = <T extends object>(): T => {
  const [searchParams] = useRouterSearchParams()

  return useMemo(() => {
    let searchParamsObject = Object.fromEntries(searchParams.entries()) as T

    if ('forCustomField' in searchParamsObject) {
      // remove string value
      delete (searchParamsObject as any).forCustomField

      for (const [key, value] of searchParams.entries()) {
        if (key === 'forCustomField') {
          const parsedValue = safeJsonParse(value, {})

          searchParamsObject = {
            ...searchParamsObject,
            forCustomField: {
              ...(searchParamsObject as any).forCustomField,
              ...parsedValue,
            },
          }
          continue
        }
      }
    }

    return searchParamsObject
  }, [searchParams])
}

/**
 * Get the current location.
 * This assumes hash based routing and will return the has as if it was the real path
 * @returns the current location
 */
export const useLocation = () => {
  const [hash] = useHash()
  return new URL(hash.slice(1), window.location.origin)
}

const PAGE_NAME_RE = /^\/([^/]+)/
export const usePage = () => {
  const { pathname } = useLocation()
  const match = PAGE_NAME_RE.exec(pathname)
  return match ? match[1] : ''
}
