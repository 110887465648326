import { classed } from '@motion/theme'
import { StyledField, TextField } from '@motion/ui/forms'

import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useTemplateTaskForm } from '../hooks'

export const FormNameField = () => {
  const { form, disabledFields } = useTemplateTaskForm()

  const { control, watch } = form

  const {
    field,
    formState: { errors },
  } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })

  const name = watch('name')

  return (
    <div className='border-b border-semantic-neutral-border-subtle pb-3 flex flex-row items-center gap-3 items-start'>
      <div className='grow -mx-2 -my-1.5'>
        <TextField
          multiline
          autoFocus
          noResize
          sentiment={errors.name ? 'error' : undefined}
          placeholder='Task name'
          label='Task name'
          labelHidden
          size='large'
          disabled={disabledFields.has('name')}
          value={name}
          onChange={(value) => field.onChange(value)}
          autoSize={{ minRows: 1, maxRows: 5 }}
          variant='muted'
        />
      </div>
    </div>
  )
}

// Override some base styles from StyledField to avoid re-creating the entire logic
export const TaskStyledField = classed(StyledField, {
  base: `
      bg-transparent p-0
      border-transparent
      hover:border-transparent

      focus-within:border-transparent
      hover:focus-within:border-transparent
  `,
  variants: {
    disabled: {
      true: `
        bg-transparent
        border-transparent
        hover:border-transparent
        active:border-transparent
        `,
    },
  },
})
