import {
  formatToReadableWeekDayMonth,
  getAutoscheduleInfo,
} from '@motion/ui-logic'
import { type TaskSchema } from '@motion/zod/client'

import { ConnectedUserBadge, StatusBadge } from '~/global/components/badges'
import { useWorkspaceFns } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { Link } from 'react-router-dom'

import { DueDateIcon } from './due-date-icon'

type CalendarBlockerTaskItemProps = {
  task: TaskSchema
}
export const CalendarBlockerTaskItem = (
  props: CalendarBlockerTaskItemProps
) => {
  const { task } = props
  const { getWorkspaceStatuses } = useWorkspaceFns()
  const buildTaskModalUrl = useTaskModalUrl()

  const { formattedScheduledDate, type, labelVariant } = getAutoscheduleInfo(
    task,
    task.isAutoScheduled,
    Boolean(task.completedTime)
  )

  const statuses = getWorkspaceStatuses(task.workspaceId)
  const status = statuses.find((status) => status.id === task.statusId)
  return (
    <Link
      to={buildTaskModalUrl({ task: task.id })}
      className=' grid grid-cols-[auto_1fr_auto] rounded hover:bg-semantic-neutral-bg-hover py-1 px-1.5 text-left gap-1 w-full text-semantic-neutral-text-default'
    >
      {status && (
        <div className='flex items-center'>
          <StatusBadge size='small' value={status} />
        </div>
      )}
      <div className='grow flex items-center'>
        <div className='truncate max-w-[240px] text-xs'>{task.name}</div>
      </div>
      <div className='h-full flex gap-1 justify-center items-center px-1'>
        <DueDateIcon
          labelVariant={labelVariant}
          type={type}
          formattedScheduledDate={formattedScheduledDate}
          formattedDueDate={
            task.dueDate && formatToReadableWeekDayMonth(task.dueDate)
          }
          formattedCompletedDate={
            task.completedTime &&
            formatToReadableWeekDayMonth(task.completedTime)
          }
        />
        <ConnectedUserBadge userId={task.assigneeUserId} size='small' />
      </div>
    </Link>
  )
}
