import { ModalErrorState } from '~/areas/task-project/components'

import { NoCalendarErrorState } from './no-calendar-state'

import { NoMainCalendarError } from '../errors'

export type ErrorStateProps = {
  close: () => void
  error: Error
}

export function ErrorState({ close, error }: ErrorStateProps) {
  if (error instanceof NoMainCalendarError) {
    return <NoCalendarErrorState close={() => close()} />
  }

  return (
    <ModalErrorState close={() => close()}>
      This event cannot be loaded
    </ModalErrorState>
  )
}
