import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { DatePickerPopover } from './shared/DatePickerPopover'
import { DurationOptionsDropdown } from './shared/DurationOptionsDropdown'
import { WorkspaceTable } from './shared/WorkspaceTable'
import { type Task, type Workspace } from './types'

import { PrimaryButton, TextButton } from '../../../components/Common'
import { inputFocusClasses } from '../../../components/Common/GeneralComponentStyles'
import { CheckmarkIcon } from '../../../components/Common/Icons/CheckmarkIcon'

type CreateTasksProps = {
  workspace: Workspace
  onUpdateTask: (task: Task) => void
  onBack: () => void
  onComplete: () => Promise<any>
}

export function CreateTasks({
  workspace,
  onUpdateTask,
  onBack,
  onComplete,
}: CreateTasksProps) {
  const { tasks, projectName } = workspace
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const inputElement = useRef<null | HTMLInputElement>(null)

  useEffect(function autoFocus() {
    if (inputElement.current) {
      inputElement.current.focus()
    }
  }, [])

  function onSubmit() {
    setLoading(true)

    onComplete().catch((error) => {
      Sentry.captureException(error, {
        tags: { position: 'onboardingCreateTasks' },
      })
      setError(error.message)
      setLoading(false)
    })
  }

  return (
    <div className='lg:mt-12 h-full w-full'>
      <div className='flex h-full p-6 lg:p-0'>
        <div className='flex flex-1 lg:-translate-y-16 flex-col items-center lg:justify-center'>
          <div className='w-full max-w-[450px]'>
            <h1 className='text-semantic-neutral-text-default text-lg lg:text-[28px] lg:leading-normal font-semibold select-none'>
              Create 3 tasks
            </h1>

            <p className='text-semantic-neutral-text-subtle mt-4 select-none text-base'>
              Add a few tasks to {projectName} that you have to get done.
            </p>

            <div className='mt-4'>
              {tasks.map((task, index) => {
                return (
                  <div
                    className='border-light-500 my-2 flex w-full items-center gap-2 rounded border bg-white px-2 py-2'
                    key={task.id}
                  >
                    <span className='text-light-900 border-light-900 flex items-center  justify-center rounded-full border p-1'>
                      <CheckmarkIcon />
                    </span>

                    <div className='relative w-full'>
                      <input
                        ref={index === 0 ? inputElement : null}
                        className={twMerge(
                          'text-light-1200 block w-full break-words rounded px-2 py-1 focus:outline-none text-base lg:text-sm min-w-[136px]',
                          inputFocusClasses
                        )}
                        onChange={(e) => {
                          onUpdateTask({
                            ...task,
                            description: e.target.value,
                          })
                        }}
                      />

                      {!task.description && (
                        <span className='text-light-1100 pointer-events-none absolute inset-0 flex items-center px-2 py-1 text-base lg:text-sm'>
                          Enter task name
                        </span>
                      )}
                    </div>

                    <div className='border-light-400 rounded border'>
                      <DurationOptionsDropdown
                        value={task.durationMinutes}
                        onChange={(value) => {
                          onUpdateTask({
                            ...task,
                            durationMinutes: value,
                          })
                        }}
                        className='text-base lg:text-sm'
                      />
                    </div>

                    <div className='hidden lg:flex'>
                      <DatePickerPopover
                        task={task}
                        onSelectDeadline={(task, deadline) => {
                          onUpdateTask({ ...task, deadlineISO: deadline })
                        }}
                      >
                        <div className='border-light-400 rounded border py-1 px-2'>
                          <span className='block w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm text-black'>
                            {parseDate(task.deadlineISO).toFormat(
                              'LLL dd, yyyy'
                            )}
                          </span>
                        </div>
                      </DatePickerPopover>
                    </div>
                  </div>
                )
              })}

              {error && (
                <div className='break-words px-2 py-2 pr-20'>
                  <p className='text-sm text-red-600'>{error}</p>
                </div>
              )}

              <div className='mt-4 flex flex-col-reverse lg:flex-row justify-between gap-3 lg:gap-0'>
                <TextButton onClick={onBack}>Back</TextButton>
                <PrimaryButton onClick={onSubmit} loading={loading}>
                  Next
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>

        <div className='h-full flex-[1.2_1.2_0%] hidden lg:flex'>
          <WorkspaceTable workspace={workspace} step='tasks' />
        </div>
      </div>
    </div>
  )
}
