import { useConferenceSettingsApi } from '~/areas/settings/state'
import { useCallback, useState } from 'react'

import { Input } from '../../Common'
import { ActionDialog } from '../../Common/Dialog/ActionDialog/ActionDialog'

interface PhoneNumberModalProps {
  visibleHandler: (visible: boolean) => void
  onConfirmRef: any
}

export const PhoneNumberModal = ({
  visibleHandler,
  onConfirmRef,
}: PhoneNumberModalProps) => {
  const [phoneNumberInput, setPhoneNumberInput] = useState('')

  const phoneNumberHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setPhoneNumberInput(e.target.value)
    },
    []
  )

  const { setConferenceSettings } = useConferenceSettingsApi()

  const saveHandler = useCallback(async () => {
    await setConferenceSettings({
      phoneNumber: phoneNumberInput,
    })
  }, [phoneNumberInput, setConferenceSettings])

  const doneHandler = () => {
    if (onConfirmRef.current) {
      void saveHandler()
      onConfirmRef.current(phoneNumberInput)
      onConfirmRef.current = null
    }
    visibleHandler(false)
  }

  return (
    <ActionDialog
      title='Enter your phone number'
      onClose={() => visibleHandler(false)}
      isOpen
      actionButtonText='Done'
      actionButtonHandler={doneHandler}
      cancelButtonHandler={() => {
        if (onConfirmRef.current) {
          onConfirmRef.current('')
          onConfirmRef.current = null
        }
        visibleHandler(false)
      }}
    >
      <div className='w-[350px] py-2'>
        <Input
          placeholder='Phone number'
          value={phoneNumberInput}
          onChange={phoneNumberHandler}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              doneHandler()
            }
          }}
        />
      </div>
    </ActionDialog>
  )
}
