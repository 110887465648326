import { HttpConnectionError, HttpError } from '@motion/rpc'
import { useAuth } from '@motion/web-common/auth'

import { LoadingPage } from '~/components/LoadingPage'
import { LoginPage } from '~/components/LoginSubstates/LoginPage/LoginPage'
import { type ReactNode } from 'react'

import { MaintenanceModePageV2 } from './maintenance-mode'

type EnsureAuthenticatedProps = {
  children: ReactNode
}

// This should eventually move to webapp
export const EnsureAuthenticated = (props: EnsureAuthenticatedProps) => {
  const { auth } = useAuth()

  if (auth.state === 'signing-out') {
    return (
      <LoadingPage message='Signing Out...' id='webapp-wrapper-signing-out' />
    )
  }

  if (auth.state === 'authenticating') {
    return <LoadingPage id='webapp-wrapper-signing-in' />
  }

  if (auth.state === 'error') {
    if (auth.error instanceof HttpConnectionError) {
      return <MaintenanceModePageV2 title='Unable to connect to Motion' />
    }

    if (auth.error instanceof HttpError) {
      // most likely gateway errors
      if (auth.error.status > 500) {
        return <MaintenanceModePageV2 title='Unable to connect to Motion' />
      }
    }

    return <MaintenanceModePageV2 title='Something broke' />
  }

  if (auth.state === 'unauthenticated') {
    return <LoginPage />
  }

  return props.children
}
