import { TextField } from '@motion/ui/forms'
import {
  endConstraints,
  handleTimeEndChange,
  handleTimeStartChange,
  startConstraints,
} from '@motion/ui-logic/pm/task'

import { TimeDropdown } from '~/areas/project-management/components'

type TimeValues = {
  start: string
  end: string
  ideal: string | null
}

export type CustomSchedulePanelProps = {
  startTime: string
  endTime: string
  idealTime: string | null
  onChange(values: TimeValues): void
}

export function CustomSchedulePanel(props: CustomSchedulePanelProps) {
  const { startTime, endTime, idealTime, onChange } = props

  const onStartTimeChange = (value: string) => {
    const changes = handleTimeStartChange(value, {
      timeEnd: endTime,
      idealTime,
    })
    onChange({
      start: changes.timeStart,
      end: changes.timeEnd,
      ideal: changes.idealTime,
    })
  }

  const onEndTimeChange = (value: string) => {
    const changes = handleTimeEndChange(value, {
      timeStart: startTime,
      idealTime,
    })
    onChange({
      start: changes.timeStart,
      end: changes.timeEnd,
      ideal: changes.idealTime,
    })
  }

  const onIdealTimeChange = (value: string | null) => {
    onChange({
      start: startTime,
      end: endTime,
      ideal: value,
    })
  }

  return (
    <div className='flex flex-col gap-2 w-[180px] text-semantic-neutral-text-default'>
      <div className='text-sm'>Custom schedule</div>
      <div className='flex flex-row items-center gap-1'>
        <TimeDropdown
          value={startTime}
          onChange={onStartTimeChange}
          constraints={startConstraints}
        >
          <TextField
            readOnly
            label='Start time'
            labelHidden
            value={startTime}
            placeholder='None'
          />
        </TimeDropdown>
        {' - '}
        <TimeDropdown
          value={endTime}
          onChange={onEndTimeChange}
          constraints={endConstraints}
        >
          <TextField
            readOnly
            label='End time'
            labelHidden
            value={endTime}
            placeholder='None'
          />
        </TimeDropdown>
      </div>

      <TimeDropdown
        allowEmpty
        value={idealTime}
        onChange={onIdealTimeChange}
        constraints={{ start: startTime, end: endTime }}
      >
        <TextField
          readOnly
          label='Ideal start time'
          value={idealTime ?? ''}
          placeholder='None'
        />
      </TimeDropdown>
    </div>
  )
}
