import { useBatch } from '@motion/react-core/hooks'
import { type RpcContextType, useRpcContext } from '@motion/rpc'
import { groupInto } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { bus } from '@motion/web-common/event-bus'
import { stats } from '@motion/web-common/performance'

import { useQueryClient } from '@tanstack/react-query'
import { useI18N } from '~/global/contexts'
import { useEffect, useRef } from 'react'

import * as handlers from './handlers'
import { mergeMutations, mergeMutationsWithWorkspace } from './handlers/utils'
import {
  handleCalendarWebsocketEvent,
  handlePmWebsocketEvent,
} from './listeners'
import { log } from './log'
import {
  type BatchOperation,
  type BulkTaskOperations,
  type FeedUpdates,
  type FolderBatchOperations,
  type ProjectBatchOperations,
  type ProjectDefinitionBatchOperations,
  type ScheduledEntitiesBatchOperations,
  type StatusBatchOperations,
  type TaskBatchOperations,
} from './types'

const METRIC_NAME = 'websocket.handler'
function tag(name: string) {
  return `handler:${name}`
}

function createErrorHandler(name: string) {
  return (ex: unknown) => {
    Sentry.captureException(
      new Error('Failed to handle websocket message', { cause: ex }),
      {
        tags: {
          position: 'websockets',
        },
        extra: {
          handler: name,
        },
      }
    )
  }
}

export const HandleWebsocketEvents = () => {
  const client = useQueryClient()
  const { pluralize } = useI18N()
  const { uid: userId } = useAuthenticatedUser()

  const rpcContext = useRpcContext()
  const rpcContextRef = useRef<RpcContextType>(rpcContext)
  rpcContextRef.current = rpcContext

  const batch = useBatch<BatchOperation>((ops) => {
    const grouped = groupInto(ops, (op) => op.type)
    const ctx = { client, rpcContext: rpcContextRef.current, userId }

    grouped.forEach((b) => {
      log.info(`executing "${b.key}" for ${b.items.length} events`)
      if (b.key === 'refresh-tasks') {
        return stats
          .time(METRIC_NAME, () => {
            const items = b.items as Array<
              TaskBatchOperations & { type: typeof b.key }
            >

            return handlers.refreshTasks(
              ctx,
              mergeMutations(items.map((x) => x.data))
            )
          }, [tag('tasks')])
          .catch(createErrorHandler(b.key))
      }

      if (b.key === 'refresh-projects') {
        return stats.time(METRIC_NAME, () => {
          const items = b.items as Array<
            ProjectBatchOperations & { type: typeof b.key }
          >

          return handlers
            .refreshProjects(ctx, mergeMutations(items.map((x) => x.data)))
            .catch(createErrorHandler(b.key))
        }, [tag('projects')])
      }

      if (b.key === 'refresh-folders') {
        return stats
          .time(METRIC_NAME, () => {
            const items = b.items as Array<
              FolderBatchOperations & { type: typeof b.key }
            >
            return handlers.refreshFolders(
              ctx,
              mergeMutations(items.map((x) => x.data))
            )
          }, [tag('folders')])
          .catch(createErrorHandler(b.key))
      }
      if (b.key === 'refresh-statuses') {
        return stats
          .time(METRIC_NAME, () => {
            const items = b.items as Array<
              StatusBatchOperations & { type: typeof b.key }
            >
            return handlers.refreshWorkspaceStatuses(
              ctx,
              mergeMutationsWithWorkspace(items.map((x) => x.data))
            )
          }, [tag('statuses')])
          .catch(createErrorHandler(b.key))
      }
      if (b.key === 'refresh-feed') {
        return stats
          .time(METRIC_NAME, () => {
            const tasksToRefresh: string[] = []
            const projectsToRefresh: string[] = []
            b.items.forEach((x) => {
              const feed = x as FeedUpdates
              if (feed.data.tasks) {
                tasksToRefresh.push(...feed.data.tasks)
              }
              if (feed.data.projects) {
                projectsToRefresh.push(...feed.data.projects)
              }
            })

            return handlers.refreshFeed(ctx, {
              tasks: tasksToRefresh,
              projects: projectsToRefresh,
            })
          }, [tag('feeds')])
          .catch(createErrorHandler(b.key))
      }

      if (b.key === 'refresh-scheduled-entities') {
        return stats
          .time(METRIC_NAME, () => {
            let entityUpdate = b.items[0] as ScheduledEntitiesBatchOperations

            return handlers.refreshScheduledEntities(
              ctx,
              entityUpdate.data.workspaceId
                ? {
                    workspaceId: entityUpdate.data.workspaceId,
                  }
                : undefined
            )
          }, [tag('scheduled_entities')])
          .catch(createErrorHandler(b.key))
      }

      if (b.key === 'refresh-past-due-tasks') {
        return stats
          .time(METRIC_NAME, () => {
            return handlers.refreshPastDueTasks(ctx)
          }, [tag('past_due')])
          .catch(createErrorHandler(b.key))
      }

      if (b.key === 'refresh-project-definition') {
        return stats
          .time(METRIC_NAME, () => {
            const items = b.items[0] as ProjectDefinitionBatchOperations

            return handlers.refreshProjectDefinition(ctx, items.data)
          }, [tag('project_definition')])
          .catch(createErrorHandler(b.key))
      }

      if (b.key === 'bulk-task-operations') {
        return stats
          .time(METRIC_NAME, () => {
            const items = b.items as Array<BulkTaskOperations>

            return handlers.bulkTaskOperations(userId, items, pluralize)
          }, [tag('bulk_task')])
          .catch(createErrorHandler(b.key))
      }

      if (b.key === 'refresh-calendars') {
        return stats
          .time(METRIC_NAME, () => {
            return handlers.refreshCalendars(ctx.client)
          }, [tag('calendars')])
          .catch(createErrorHandler(b.key))
      }

      Sentry.captureMessage(`Socket group key not handled: ${b.key}`)
    })
  }, 2_000)

  useEffect(() => {
    const ctx = { client, batch }
    return bus.on('legacy:pm:websockets', (e) => handlePmWebsocketEvent(ctx, e))
  }, [batch, client])

  useEffect(() => {
    const ctx = { client, batch }
    return bus.on('legacy:calendar:websockets', (e) =>
      handleCalendarWebsocketEvent(ctx, e)
    )
  }, [batch, client])

  return null
}

if (__IS_DEV__) {
  // @ts-expect-error - it will exist now
  window.bus = bus
}
