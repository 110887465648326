import { classed } from '@motion/theme'
import { type DateCustomField } from '@motion/ui-logic'

import { CustomField } from '~/areas/project-management/custom-fields'
import { type ControlledCustomFieldProps } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { type FC } from 'react'

export const ControlledDate: FC<
  ControlledCustomFieldProps<DateCustomField>
> = ({ value, onSubmit, customField, disabled }) => {
  const hasValue = value != null

  return (
    <CustomField.Date
      name={customField.definition.name}
      value={value}
      onChange={onSubmit}
      disabled={disabled}
      triggerComponent={Trigger}
      size='xsmall'
      hideIcon={hasValue}
    />
  )
}

const Trigger = classed('button', {
  base: `
    flex items-center w-full
    h-5
    gap-2
    text-2xs
  `,
})
