import { FormModal, PopoverButton, showToast } from '@motion/ui/base'
import { formatTime } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { SearchableTimeDropdown } from '~/areas/project-management/components'
import { type DateTime } from 'luxon'
import { useState } from 'react'

import { Paragraph } from '../../../../components/Common'
import { useCreateBookingTimeslot } from '../../../global/rpc'

export type ConnectedStartTasksLaterModalProps = {
  initialTime: DateTime
}

export const StartTasksLaterModal = ({
  close,
  initialTime,
}: ModalTriggerComponentProps<'start-tasks-later'>) => {
  const [value, setValue] = useState(initialTime.toISO())

  const { mutateAsync: createBookingTimeslot } = useCreateBookingTimeslot()

  const onAction = async () => {
    recordAnalyticsEvent('FLEXIBLE_HOURS_START_TASKS_LATER')
    try {
      await createBookingTimeslot({
        startTime: initialTime.startOf('day').toISO(),
        endTime: value,
      })

      showToast(
        'success',
        'Your hours have changed. Tasks will be rescheduled!'
      )

      close()
    } catch (e) {
      showToast('error', 'Error blocking out time')
    }
  }

  const formatted = formatTime(value)

  return (
    <FormModal
      onClose={close}
      submitAction={{
        text: 'Apply',
        onAction,
      }}
      title='Start tasks later'
      visible
    >
      <div className='flex flex-row items-center gap-2 w-[400px]'>
        <Paragraph>Start tasks at</Paragraph>
        <SearchableTimeDropdown
          startDateTime={initialTime.startOf('day')}
          value={formatted}
          onChange={(val) => {
            setValue(val.toISO())
          }}
        >
          <div>
            <PopoverButton>{formatted}</PopoverButton>
          </div>
        </SearchableTimeDropdown>
      </div>
    </FormModal>
  )
}
