import { byProperty, Compare, uniqueBy } from '@motion/utils/array'

import type { MentionOptions } from '@tiptap/extension-mention'
import { ReactRenderer } from '@tiptap/react'
import { type MentionItem } from '~/areas/activity-feed/types'
import { type MutableRefObject } from 'react'
import tippy, { type Instance as TippyInstance } from 'tippy.js'

import { MentionList, type SuggestionListRef } from '../mention-list'

/**
 * Workaround for the current typing incompatibility between Tippy.js and Tiptap
 * Suggestion utility.
 *
 * @see https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
 *
 * Adopted from
 * https://github.com/Doist/typist/blob/a1726a6be089e3e1452def641dfcfc622ac3e942/stories/typist-editor/constants/suggestions.ts#L169-L186
 */
const DOM_RECT_FALLBACK: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON() {
    return {}
  },
}

export const suggestion = ({
  optionsRef,
  commentBoxRef,
}: {
  optionsRef: MutableRefObject<MentionItem[]>
  commentBoxRef: MutableRefObject<HTMLElement | null>
}): MentionOptions['suggestion'] => ({
  items: ({ query }: { query: string }) => {
    return uniqueBy(
      optionsRef.current.filter((item) =>
        item.label.toLowerCase().startsWith(query.toLowerCase())
      ),
      (x) => x.value
    )
      .sort(byProperty('label', Compare.string))
      .slice(0, 5)
  },

  render: () => {
    let component: ReactRenderer<SuggestionListRef> | undefined
    let popup: TippyInstance | undefined

    return {
      onExit() {
        popup?.destroy()
        component?.destroy()
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.hide()
          return true
        }

        if (!component?.ref) return false

        return component.ref.onKeyDown(props)
      },

      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          editor: props.editor,
          props,
        })
        popup = tippy('body', {
          appendTo: () => commentBoxRef.current as Element,
          content: component.element,
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
          interactive: true,
          placement: 'bottom-start',
          showOnCreate: true,
          trigger: 'manual',
        })[0]
      },

      onUpdate(props) {
        component?.updateProps(props)
        popup?.setProps({
          getReferenceClientRect: () =>
            props.clientRect?.() ?? DOM_RECT_FALLBACK,
        })
      },
    }
  },
})
