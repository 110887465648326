import { type DateTime } from 'luxon'

const MIN_PERCENT_DIFF_THRESHOLD = 3.5

export function getStagesWithTimelinePercentage<
  T extends { positionDate: DateTime },
>(startDate: DateTime, endDate: DateTime, positionDates: T[]) {
  const totalDays = endDate.diff(startDate, 'days').days

  positionDates.sort((a, b) => (a.positionDate < b.positionDate ? -1 : 1))

  let prevValue: number | null = null

  return positionDates.map(({ positionDate, ...rest }) => {
    const days = positionDate.diff(startDate, 'days').days
    let currValue = (days / totalDays) * 100
    let showText = true

    if (prevValue == null) {
      prevValue = currValue
    } else {
      const diff = Math.abs(currValue - prevValue)
      if (diff < MIN_PERCENT_DIFF_THRESHOLD) {
        currValue += MIN_PERCENT_DIFF_THRESHOLD - diff
      }

      if (diff === 0) {
        showText = false
      } else {
        prevValue = currValue
      }
    }

    return {
      positionPercentage: Math.min(currValue, 100),
      showText,
      positionDate,
      ...rest,
    }
  })
}

export function shouldRotateDateText(
  stagePercentages: ReturnType<typeof getStagesWithTimelinePercentage>
) {
  return stagePercentages.some((stage, index) =>
    stageDateTextOverlapping(
      stage.positionPercentage,
      stagePercentages[index - 1]?.positionPercentage,
      stagePercentages[index + 1]?.positionPercentage
    )
  )
}

const ROTATE_TEXT_PERCENT_DIFFERENCE_THRESHOLD = 10

export function stageDateTextOverlapping(
  percentage: number,
  previousPercentage?: number,
  nextPercentage?: number
) {
  if (
    previousPercentage != null &&
    percentage - previousPercentage < ROTATE_TEXT_PERCENT_DIFFERENCE_THRESHOLD
  ) {
    return true
  }

  if (
    nextPercentage != null &&
    nextPercentage - percentage < ROTATE_TEXT_PERCENT_DIFFERENCE_THRESHOLD
  ) {
    return true
  }

  return false
}
