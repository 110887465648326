import { useClosure } from '@motion/react-core/hooks'
import { isEqual } from '@motion/utils/core'

import { useEffect, useRef } from 'react'

import {
  type MotionLocalStorageItems,
  type MotionLocalStorageResult,
} from './types'

import api from '../../chromeApi/chromeApiContentScript'
import { type StorageChangeResult } from '../../chromeApi/chromeApiTypes'

export const useOnLocalStorageChange = <
  T extends keyof MotionLocalStorageItems,
>(
  keys: T[],
  cb: (values: MotionLocalStorageResult<T>) => void
) => {
  const stableCallback = useClosure(cb)
  const values = useRef<Partial<MotionLocalStorageItems>>({})

  useEffect(() => {
    function onChange(changes: StorageChangeResult) {
      const requestedChanges = keys.reduce((acc, key) => {
        if (changes[key] == null) return acc
        if (isEqual(changes[key].oldValue, changes[key].newValue)) return acc

        acc[key] = changes[key].newValue

        return acc
      }, {} as MotionLocalStorageResult<T>)

      if (Object.keys(requestedChanges).length === 0) return

      values.current = { ...values.current, ...requestedChanges }
      stableCallback(values.current as MotionLocalStorageResult<T>)
    }

    void api.storage.local.get(keys).then((initialValues) => {
      values.current = initialValues
      stableCallback(initialValues as MotionLocalStorageItems)
      return
    })

    api.storage.onChanged.addListener(onChange)

    return () => api.storage.onChanged.removeListener(onChange)

    // We want to re-trigger the effect when the value of the keys changes, not the keys themselves
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...keys])
}
