import { Button, type ButtonProps } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectDeadlineUpdater } from '~/areas/project/hooks'

type ReviewProjectDatesButtonProps = ButtonProps & { project: ProjectSchema }

export function ReviewProjectDatesButton({
  project,
  ...buttonProps
}: ReviewProjectDatesButtonProps) {
  const modalApi = useModalApi()
  const updateProjectDeadline = useProjectDeadlineUpdater()

  const handleOnClickReviewDates = async () => {
    const response = await modalApi.prompt('review-project-dates', { project })

    if (response === ModalDismissed || response === null) return

    const newDeadline = response as string
    await updateProjectDeadline(project, newDeadline)
  }

  return (
    <Button {...buttonProps} onClick={handleOnClickReviewDates}>
      Review Dates
    </Button>
  )
}
