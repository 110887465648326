import { type StatusSchema, type WorkspaceSchema } from '@motion/zod/client'

import { TaskStatusDropdown } from '~/areas/tasks/components'
import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'

import { ModalFieldButton } from '../components'

export type StatusFieldProps = {
  value: StatusSchema['id']
  onChange: (value: StatusSchema['id']) => void
  disabled?: boolean
  workspaceId: WorkspaceSchema['id']
  isUnvisitedStage?: boolean
  isScheduledTask?: boolean
  shouldExcludeResolved?: boolean
  shouldExcludeCanceled?: boolean
}

export const StatusField = ({
  value,
  onChange,
  disabled,
  workspaceId,
  isUnvisitedStage,
  isScheduledTask,
  shouldExcludeResolved,
  shouldExcludeCanceled,
}: StatusFieldProps) => {
  const selectedStatus = useWorkspaceStatusById(value)
  const taskVariant = isScheduledTask ? 'scheduling' : 'default'

  return (
    <TaskStatusDropdown
      onChange={onChange}
      workspaceId={workspaceId}
      selectedStatusId={value}
      taskVariant={taskVariant}
      excludeResolved={shouldExcludeResolved}
      excludeCanceled={shouldExcludeCanceled}
      isUnvisitedStage={isUnvisitedStage ?? false}
    >
      <ModalFieldButton label='Status' disabled={disabled}>
        <StatusBadge
          value={selectedStatus}
          iconVariant={isUnvisitedStage ? 'isUnvisitedStage' : 'default'}
          taskVariant={taskVariant}
          hideTooltip
        />
        <span className='truncate'>{selectedStatus.name}</span>
      </ModalFieldButton>
    </TaskStatusDropdown>
  )
}
