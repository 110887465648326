import { classed } from '@motion/theme'
import { ActionList, useContextMenu } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type Row } from '@tanstack/react-table'
import { type ComponentProps, type ComponentRef, forwardRef } from 'react'

export const Table = classed('div', {
  base: `
    overflow-auto
    text-sm
    grid
    pb-6
    select-none
  `,
})

type TableRowProps = ComponentProps<typeof StyledTableRow> & {
  row?: Row<any>
  toggleExpandAllRows?: (expand: boolean) => void
}

export const TableRow = forwardRef<
  ComponentRef<typeof StyledTableRow>,
  TableRowProps
>(function TableRow({ row, toggleExpandAllRows, ...props }, ref) {
  return (
    <StyledTableRow
      ref={ref}
      role='row'
      {...props}
      selected={row?.getIsSelected() ?? false}
    />
  )
})

export const ExpandableTableRow = forwardRef<
  ComponentRef<typeof StyledTableRow>,
  TableRowProps
>(function ExpandableTableRow({ row, toggleExpandAllRows, ...props }, ref) {
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_GROUP_RIGHT_CLICK', {
        depth: row?.depth ?? 0,
      })
    },
  })

  const toggleExpandGroups = (expand: boolean) => {
    const parentRow = row?.getParentRow()
    if (parentRow == null) return toggleExpandAllRows?.(expand)

    parentRow?.subRows.forEach((r) => {
      r.toggleExpanded(expand)
    })
  }

  return (
    <>
      <TableRow ref={ref} {...props} onContextMenu={handleContextMenu} />
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ExpandAndCollapseMenu
            close={close}
            onCollapse={() => {
              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_LIST_GROUP_RIGHT_CLICK_ACTION',
                {
                  depth: row?.depth ?? 0,
                  action: 'collapse-all',
                }
              )
              toggleExpandGroups(false)
            }}
            onExpand={() => {
              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_LIST_GROUP_RIGHT_CLICK_ACTION',
                {
                  depth: row?.depth ?? 0,
                  action: 'expand-all',
                }
              )
              toggleExpandGroups(true)
            }}
          />
        )}
      />
    </>
  )
})

type ExpandAndCollapseMenuProps = {
  close: () => void
  onCollapse: () => void
  onExpand: () => void
}
export const ExpandAndCollapseMenu = ({
  close,
  onCollapse,
  onExpand,
}: ExpandAndCollapseMenuProps) => {
  return (
    <ActionList
      items={[
        {
          content: 'Collapse all',
          onAction: onCollapse,
        },
        {
          content: 'Expand all',
          onAction: onExpand,
        },
      ]}
      onActionAnyItem={close}
    />
  )
}

const StyledTableRow = classed('div', {
  base: `
    group/table-row
    grid [grid-template-columns:var(--col-template)]
    col-span-full
    relative
    setvar-[indent=calc(var(--depth,0)*16px)]

    [&[data-has-increased-height="true"]>div]:border-0
    [&[data-has-increased-height="true"]>div:not(:first-child)]:h-[calc(100%-24px)]
    [&[data-has-increased-height="true"]>div:first-child>*]:h-[calc(100%-24px)]
    [&[data-has-increased-height="true"]>div:first-child>*]:place-self-start
    [[data-has-increased-height="true"]+&[data-has-increased-height="false"]]:border-t
    [[data-has-increased-height="true"]+&[data-has-increased-height="false"]]:border-t-pivot-table-header-row-border

    after:pointer-events-none
    after:bg-gradient-to-r
    after:from-calendar-bg-default
    after:from-75%
    after:to-transparent
    after:w-[var(--indent)]
    after:absolute
    after:top-0
    after:bottom-0
    after:left-0

    before:pointer-events-none
    before:bg-semantic-primary-bg-default
    before:absolute
    before:inset-0
    before:opacity-0
    before:setvar-[animate-time=1500ms]
  `,
  variants: {
    expandable: {
      true: `
        bg-pivot-table-header-row-bg
        z-[2] sticky
        shadow-inner

        setvar-[offsetHeader=25px]
        setvar-[offsetGroups=calc(var(--depth)*36px)]
        setvar-[offset=calc(var(--offsetHeader)+var(--offsetGroups))]
        top-[var(--offset)]
      `,
      false: '',
    },
    selected: {
      true: '',
      false: '',
    },
    highlighted: {
      true: ` before:animate-fadeOut`,
    },
  },
  compoundVariants: [
    {
      expandable: false,
      selected: false,
      className:
        'bg-calendar-bg-default hover:bg-pivot-table-item-row-bg-hover',
    },
    {
      expandable: false,
      selected: true,
      className:
        'bg-pivot-table-item-row-bg-selected-default hover:bg-pivot-table-item-row-bg-selected-hover',
    },
  ],
  defaultVariants: {
    expandable: false,
    highlighted: false,
    selected: false,
  },
  dataAttributes: ['selected'],
})

export const PinnableCell = classed('div', {
  base: '',
  variants: {
    isPinned: {
      true: `
        sticky z-[1]
        left-0
        top-0
        !overflow-visible
        after:absolute
        after:w-2
        after:h-full
        after:-right-2
        after:bg-gradient-to-r
        after:from-black/10
        after:to-transparent
      `,
    },
  },
  defaultVariants: {
    isPinned: false,
  },
})

export const TableCell = classed('div', PinnableCell, {
  base: `
    group/table-cell
    flex items-center overflow-hidden
    p-0
    truncate
    relative

    border-b
    border-pivot-table-header-row-border
  `,
  variants: {
    fromExpandableRow: { true: '', false: '' },
  },
  compoundVariants: [
    {
      // @ts-expect-error isPinned comes from PinnableCell
      isPinned: true,
      fromExpandableRow: true,
      className:
        'shadow-inner bg-pivot-table-header-row-bg group-hover/table-row:bg-pivot-table-item-row-bg-hover',
    },
    {
      // @ts-expect-error isPinned comes from PinnableCell
      isPinned: true,
      fromExpandableRow: false,
      className: `
        bg-calendar-bg-default

        [[data-selected="false"]_&]:bg-calendar-bg-default
        [[data-selected="true"]_&]:bg-pivot-table-item-row-bg-selected-default

        [[data-selected="false"]_&]:group-hover/table-row:bg-pivot-table-item-row-bg-hover
        [[data-selected="true"]_&]:group-hover/table-row:bg-semantic-primary-bg-hover
      `,
    },
  ],
})
