import React from 'react'

import { SidebarTasksContext } from './sidebar-tasks-context'

export const useSidebarTaskContext = () => {
  const context = React.useContext(SidebarTasksContext)
  if (!context) {
    throw new Error(
      'useSidebarTaskContext must be used within a SidebarTasksProvider'
    )
  }
  return context
}
