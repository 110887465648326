import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { convertStageDefinitionToFormStage } from '@motion/ui-logic/pm/project'
import { uniqueId } from '@motion/utils/core'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'

import { useDefaultFlowStages } from '../use-default-flow-stages'

export function useInitialStages(
  workspaceId: string | undefined,
  projectDefinition: ProjectDefinitionSchema | null,
  isCreatingFromTemplate: boolean,
  currentUserId: string
) {
  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const defaultStages = useDefaultFlowStages(workspaceId, currentUserId)

  if (projectDefinition == null || projectDefinition.stages.length === 0) {
    return defaultStages
  }

  if (isCreatingFromTemplate) {
    return projectDefinition.stages.map((stage) => ({
      ...convertStageDefinitionToFormStage({
        stage,
        workspaceCustomFields,
        addPlaceholderTaskIds: true,
      }),
      id: createPlaceholderId(uniqueId('stage')),
    }))
  }

  return projectDefinition.stages.map((stage) =>
    convertStageDefinitionToFormStage({
      stage,
      workspaceCustomFields,
    })
  )
}
