import { TaskSolid } from '@motion/icons'
import { FormModal, PopoverButton } from '@motion/ui/base'
import { formatDurationTime, timeDurations } from '@motion/ui-logic'
import { byValue, Compare } from '@motion/utils/array'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { DurationDropdown } from '~/areas/project-management/components'
import { useMemo, useState } from 'react'

import { useCompletedChoices } from '../../../project-management/hooks'

export interface ConnectedStopTaskModalProps {
  name: string
  suggestedAdditionalCompleted: number
  suggestedRemaining: number
}

export type StopTaskModalCallback = {
  completed: number
  remaining: number
}

export const StopTaskModal = ({
  name,
  suggestedAdditionalCompleted,
  suggestedRemaining,
  close,
}: ModalTriggerComponentProps<'stop-task'>) => {
  const totalRemaining = suggestedAdditionalCompleted + suggestedRemaining
  const [additionalCompleted, setAdditionalCompleted] = useState(
    suggestedAdditionalCompleted
  )
  const [remaining, setRemaining] = useState(suggestedRemaining)

  const completedChoices = useCompletedChoices(
    suggestedAdditionalCompleted,
    totalRemaining
  )

  const remainingChoices = useMemo(() => {
    const choices = timeDurations.filter(
      (duration) => duration.value != null && duration.value > 0
    )

    choices.push({
      value: 0,
      label: formatDurationTime(0),
    })

    if (!choices.some((item) => item.value === suggestedRemaining)) {
      choices.push({
        value: suggestedRemaining,
        label: formatDurationTime(suggestedRemaining),
      })
      choices.sort(byValue((item) => item.value, Compare.numeric))
    }
    return choices
  }, [suggestedRemaining])

  return (
    <FormModal
      visible
      onClose={close}
      submitAction={{
        text: 'Stop',
        onAction: () => {
          close({ completed: additionalCompleted, remaining })
        },
      }}
      title='Stop task now'
    >
      <div className='flex flex-col items-start gap-4 self-stretch min-w-[400px]'>
        <div className='text-semantic-neutral-text-subtle flex items-center justify-center gap-1'>
          <TaskSolid className='w-[14px] h-[14px] text-semantic-neutral-icon-subtle' />
          <div className='truncate max-w-[400px] text-xs'>{name}</div>
        </div>
        <div className='text-semantic-neutral-text-default'>
          How long did you work on this task just now?
        </div>
        <div className='w-full'>
          <DurationDropdown
            value={additionalCompleted}
            maxDuration={totalRemaining}
            onChange={(duration) => {
              setAdditionalCompleted(duration ?? 0)
              setRemaining(
                Math.max(0, remaining + additionalCompleted - (duration ?? 0))
              )
            }}
            choices={completedChoices}
          >
            <PopoverButton>
              {formatDurationTime(additionalCompleted)}
            </PopoverButton>
          </DurationDropdown>
        </div>
        <div className='text-semantic-neutral-text-default'>
          How much more time do you need to complete this task?
        </div>
        <div className='w-full'>
          <DurationDropdown
            value={remaining}
            onChange={(duration) => {
              setRemaining(duration ?? 0)
            }}
            choices={remainingChoices}
          >
            <PopoverButton>{formatDurationTime(remaining)}</PopoverButton>
          </DurationDropdown>
        </div>
      </div>
    </FormModal>
  )
}
