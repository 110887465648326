import { Button } from '@motion/ui/base'

import { useProjectModalUrl } from '~/global/navigation'

import { usePageData } from '../routes'

export const ConnectedViewDetailsButton = () => {
  const buildProjectModalUrl = useProjectModalUrl()
  const data = usePageData()

  if (data.page !== 'project') {
    return null
  }

  return (
    <Button
      sentiment='neutral'
      variant='outlined'
      size='small'
      url={buildProjectModalUrl({ project: 'edit' })}
    >
      Open
    </Button>
  )
}
