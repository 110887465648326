import { type ProjectWithRelations } from '~/global/proxies'

import { CellText } from './components'

type ProjectDefinitionCellProps = {
  item: ProjectWithRelations
}

export const ProjectDefinitionCell = ({ item }: ProjectDefinitionCellProps) => {
  const name = item.projectDefinition?.name ?? ''

  return (
    <CellText>
      <span className='truncate'>{name}</span>
    </CellText>
  )
}
