import { type CustomFieldFilters } from '@motion/ui-logic/pm/data'

export function getNarrowedFilter<
  T extends CustomFieldFilters[keyof CustomFieldFilters][string],
  R = T extends Record<string, infer U> ? U : never,
>(filterValue: T | null): R | null {
  if (filterValue == null) {
    return null
  }
  // All filters are the same per filter value
  return Object.values(filterValue)[0]
}
