import { useModalApi } from '@motion/web-common/modals'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useDeleteTask } from '~/areas/tasks/hooks'
import { useLookup } from '~/global/cache'
import { useCallback } from 'react'

export type RemoveEventFromProjectOptions = {
  onConfirmation: () => void
}

export function useRemoveEventFromProject() {
  const modalApi = useModalApi()
  const lookup = useLookup()
  const deleteTask = useDeleteTask()

  return useCallback(
    async (
      event: CalendarEventSchemaV2 | null | undefined,
      options: RemoveEventFromProjectOptions
    ) => {
      const { onConfirmation } = options

      if (event == null) {
        throw new Error('Removing from a project requires an existing event')
      }

      if (event.meetingTaskId == null) {
        throw new Error('Event does not have any meeting task')
      }

      const meetingTask = lookup('tasks', event.meetingTaskId)

      if (meetingTask == null) {
        throw new Error('Removing from a project requires a meeting task')
      }

      const response = await modalApi.prompt('confirm', {
        analytics: {
          name: 'remove-meeting-from-project',
        },
        title: 'Are you sure you want to remove this meeting from the project?',
        description:
          'All project data including custom fields, activity feed and comments will be removed from this event.',
        destructive: true,
        confirmButtonText: 'Remove from project',
      })
      if (response !== true) return

      onConfirmation?.()

      await deleteTask(meetingTask.id, {
        skipPrompt: true,
      })
    },
    [deleteTask, lookup, modalApi]
  )
}
