import { FormModal } from '@motion/ui/base'
import { isSameDay } from '@motion/utils/dates'
import { titleCase } from '@motion/utils/string'
import {
  type AllModelsSchema,
  type RecurrenceUpdateType,
} from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useState } from 'react'

import { RadioGroup } from '../../../../components/Common/RadioGroup/RadioGroup'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'recurring-event-update': PromptCallbacks<RecurrenceUpdateType> & {
      calendarEvent: AllModelsSchema['calendarEvents'][0]
      updates: { start?: string }
      operation: 'update' | 'delete'
    }
  }
}

export const UpdateRecurringEventConfirmationModal = ({
  close,
  calendarEvent,
  updates,
  operation,
}: ModalTriggerComponentProps<'recurring-event-update'>) => {
  const [selected, setSelected] = useState<RecurrenceUpdateType>('this')

  const emailAccounts = useAppSelector(selectEmailAccounts)
  const emailAccount = emailAccounts.find(
    (emailAccount) => emailAccount.email === calendarEvent.email
  )

  let options: { label: string; value: RecurrenceUpdateType }[] = [
    { label: 'This event', value: 'this' },
    { label: 'All events', value: 'all' },
  ]

  // When updating a recurring event, remove the option to apply to "All events"
  // if the provider is Google AND the event is being moved to a different day.
  // Gcal doesn't support applying date changes to all instances of a recurring
  // event - the side effect is that the first instance gets duplicated
  if (emailAccount?.providerType !== 'MICROSOFT') {
    const hasSameStartDay = updates.start
      ? isSameDay(calendarEvent.start, updates.start)
      : true

    if (!hasSameStartDay) {
      options = options.filter((option) => option.value !== 'all')
    }
  }

  return (
    <FormModal
      title={`${titleCase(operation)} recurring event`}
      visible
      onClose={() => close()}
      submitAction={{
        onAction: () => close(selected),
        text: `${titleCase(operation)} event`,
      }}
    >
      <RadioGroup
        className='py-3'
        options={options}
        selectedValue={selected}
        onChange={(value) => {
          setSelected(value as RecurrenceUpdateType)
        }}
        borderless
      />
    </FormModal>
  )
}
