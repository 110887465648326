import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { type COLOR } from '@motion/shared/common'
import { groupInto } from '@motion/utils/array'

import { StageLabel } from '~/global/components/labels'
import {
  AppWorkspaceContext,
  type WorkspaceStageDefinition,
} from '~/global/contexts'
import { useMemo } from 'react'

import { type BulkItem } from '../hooks'

type BulkStageLabelProps = {
  items: BulkItem<WorkspaceStageDefinition>
}

export function BulkStageLabel({ items }: BulkStageLabelProps) {
  const colorGroups = useMemo(() => groupInto(items, (s) => s.color), [items])

  if (items.length === 1 || colorGroups.length === 1) {
    return <StageLabel value={items[0]} />
  }

  const greyStage = {
    ...items[0],
    color: 'gray' as COLOR,
  }

  return <StageLabel value={greyStage} />
}

export function ConnectedBulkStageLabel({ ids }: { ids: string[] }) {
  const stageDefinitions = useSharedStateQuery(AppWorkspaceContext, (state) =>
    ids.map((id) => state.stageDefinitions.byId[id])
  )

  return <BulkStageLabel items={stageDefinitions} />
}
