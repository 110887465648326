import { LoadingSvg } from '~/components/Common/Icons/LoadingSvg'
import React from 'react'

const WebLazyComponent = React.lazy(() =>
  import('./APIOverview').then((mod) => ({ default: mod.APIOverview }))
)

const Loading = () => {
  return (
    <div className='grid h-32 place-content-center'>
      <LoadingSvg />
    </div>
  )
}

export function LazyAPIOverview() {
  return (
    <React.Suspense fallback={<Loading />}>
      <WebLazyComponent />
    </React.Suspense>
  )
}
