import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  CopyTasksButton,
  type CopyTasksButtonProps,
} from '../../../components/copy-tasks'
import { useDataForCopyTasks } from '../../../copy-tasks'
import { type GroupedNode } from '../../../grouping'
import { type TreeListRowValueType } from '../../types'

export interface TreeItemCopyTasksButtonProps {
  item: GroupedNode<TreeListRowValueType>
  onClick: CopyTasksButtonProps['onClick']
  size: CopyTasksButtonProps['size']
  children: CopyTasksButtonProps['children']
}

export const TreeItemCopyTasksButton = ({
  item,
  onClick,
  size,
  children,
}: TreeItemCopyTasksButtonProps) => {
  const { list, columns } = useDataForCopyTasks(item)

  if (item.count === 0 || list.length === 0) return null

  return (
    <CopyTasksButton
      list={list}
      columns={columns}
      size={size}
      onClick={onClick}
    >
      {children}
    </CopyTasksButton>
  )
}

export interface GroupRowCopyTasksButtonProps {
  depth: number
  originalRow: GroupedNode<TreeListRowValueType>
}
export function GroupRowCopyTasksButton({
  depth,
  originalRow,
}: GroupRowCopyTasksButtonProps) {
  return (
    <TreeItemCopyTasksButton
      item={originalRow}
      size='xsmall'
      onClick={() => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_LIST_COPY_TASKS_CLICK', {
          depth,
        })
      }}
    >
      Copy tasks
    </TreeItemCopyTasksButton>
  )
}
