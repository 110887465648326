import { type SvgIcon } from '@motion/icons'
import { Button, type ButtonProps } from '@motion/ui/base'

import { type ReactNode } from 'react'

export interface EmptyContainerProps {
  icon: SvgIcon
  title: ReactNode
  description: ReactNode
  action: Pick<ButtonProps, 'url' | 'onClick' | 'children'> & { icon?: SvgIcon }
}

export const EmptyContainer = (props: EmptyContainerProps) => {
  const { icon: Icon, action, title, description } = props

  const { icon: ActionIcon, ...actionProps } = action

  return (
    <div className='flex gap-3'>
      <Icon className='w-[18px] h-[18px] text-semantic-neutral-icon-subtle' />
      <div className='flex flex-col gap-2'>
        <span className='font-medium text-semantic-neutral-text-default'>
          {title}
        </span>
        <span className='font-normal text-semantic-neutral-text-subtle mb-1'>
          {description}
        </span>
        <div>
          <Button size='small' {...actionProps}>
            {ActionIcon && <ActionIcon />}
            {actionProps.children}
          </Button>
        </div>
      </div>
    </div>
  )
}
