import { LoadingSpinner } from '@motion/ui/base'
import { NumberChart } from '@motion/web-charts/number'

import { useChartQuery } from '~/areas/charts/rpc'

import { GridTile } from '../components/grid-tile'

export const TaskCount = () => {
  const response = useChartQuery({
    $version: 2,
    aggregate: { type: 'count' },
    source: 'tasks',
    groupBy: [],
    filters: [
      {
        archived: 'exclude',
        completed: 'exclude',
      },
    ],
  })

  if (response.isLoading) {
    return (
      <GridTile title='Tasks' width={2} height={1}>
        <LoadingSpinner className='place-self-center' />
      </GridTile>
    )
  }

  return (
    <GridTile title='Tasks' width={2} height={1}>
      <NumberChart
        value={response.data?.data[0]?.value}
        unit='Tasks'
        position='bottom'
      />
    </GridTile>
  )
}
