import { getProjectDateText } from '@motion/ui-logic'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useProjectDeadlineUpdater } from '../updaters'

export function useProjectDeadlineData(project: ProjectSchema) {
  const updateProjectDeadline = useProjectDeadlineUpdater()

  const onChange = useCallback(
    (dueDate: ProjectSchema['dueDate']) => {
      void updateProjectDeadline(project, dueDate)
    },
    [project, updateProjectDeadline]
  )

  const formattedValue = getProjectDateText(project.dueDate)

  return {
    onChange,
    // Flow projects are ISO Date only, so we cannot show the time options
    hideTimeOptions: isFlowProject(project),
    disableClearing: isFlowProject(project),
    formattedValue,
  }
}
