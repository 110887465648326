import { type UploadedFileSchema } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useState } from 'react'

type UseAttachmentsNavigationParams = {
  attachmentIds: UploadedFileSchema['id'][]
  initialAttachmentId: UploadedFileSchema['id']
}

export function useAttachmentsNavigation({
  attachmentIds,
  initialAttachmentId,
}: UseAttachmentsNavigationParams) {
  const [currentAttachmentId, setCurrentAttachmentId] =
    useState(initialAttachmentId)

  const currentAttachmentIndex = attachmentIds.indexOf(currentAttachmentId)

  const hasPrevAttachment = currentAttachmentIndex > 0
  const hasNextAttachment = currentAttachmentIndex < attachmentIds.length - 1

  const goToPrevAttachment = () => {
    if (hasPrevAttachment) {
      const prevAttachmentId = attachmentIds[currentAttachmentIndex - 1]
      setCurrentAttachmentId(prevAttachmentId)
      recordAnalyticsEvent('ATTACHMENT_FILE_VIEW_PREV')
    }
  }

  const goToNextAttachment = () => {
    if (hasNextAttachment) {
      const nextAttachmentId = attachmentIds[currentAttachmentIndex + 1]
      setCurrentAttachmentId(nextAttachmentId)
      recordAnalyticsEvent('ATTACHMENT_FILE_VIEW_NEXT')
    }
  }

  return {
    currentAttachmentId,
    hasPrevAttachment,
    hasNextAttachment,
    goToPrevAttachment,
    goToNextAttachment,
  }
}
