import { BlocksSolid } from '@motion/icons'
import { PopoverTrigger, type PopoverTriggerProps } from '@motion/ui/base'
import { type NormalTaskSchema } from '@motion/zod/client'

import { SidebarTaskList } from '~/areas/task-project/components'
import { useBlockerTasks } from '~/areas/tasks/hooks'
import { useTaskBlockersUpdater } from '~/areas/tasks/hooks/updaters'
import { type ReactNode } from 'react'

import { BlockersDropdown } from './blockers-dropdown'
import { NormalTaskBlockersContent } from './blockers-type-dropdown'
import { BlockersDropdownContainer, Header } from './styled'

export type BlockingTasksDropdownProps = Omit<
  BlockingTasksDropdownContentProps,
  'close'
> & {
  children: ReactNode
  placement?: PopoverTriggerProps['placement']
}

export function BlockingTasksDropdown({
  task,
  placement = 'bottom-start',
  children,
}: BlockingTasksDropdownProps) {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <BlockingTasksDropdownContent close={close} task={task} />
      )}
      placement={placement}
    >
      {children}
    </PopoverTrigger>
  )
}

type BlockingTasksDropdownContentProps = {
  close: () => void
  task: NormalTaskSchema
}
const BlockingTasksDropdownContent = ({
  task,
}: BlockingTasksDropdownContentProps) => {
  const { removeTaskBlocker } = useTaskBlockersUpdater({ type: 'blocks' })

  const { blocking } = useBlockerTasks({
    taskId: task.id,
  })

  const isEmpty = blocking.length === 0

  return (
    <BlockersDropdownContainer>
      {isEmpty ? (
        <NormalTaskBlockersContent
          close={() => null}
          task={task}
          type='blocks'
        />
      ) : (
        <>
          <Header>
            <div className='flex items-center gap-1'>
              <BlocksSolid
                width={14}
                className='text-semantic-warning-icon-default'
              />
              Blocking
            </div>

            <BlockersDropdown type='blocks' sameProject task={task} />
          </Header>

          <div className='px-3 py-4'>
            <SidebarTaskList
              tasks={blocking}
              onRemove={(blocker) => removeTaskBlocker(task, blocker)}
              size='xsmall'
            />
          </div>
        </>
      )}
    </BlockersDropdownContainer>
  )
}
