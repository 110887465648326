import { useShortcut } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TaskResponseSchema } from '@motion/zod/client'

import { useCopyTaskLinkToClipboard } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

import { TaskResult } from './task-result'

import { stripEmTags } from '../../utils'

export interface ConnectedTaskResultProps {
  item: TaskResponseSchema
  active: boolean
  onItemClick: () => void
}

export function ConnectedTaskResult({
  item,
  active,
  onItemClick,
}: ConnectedTaskResultProps) {
  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()
  const copyTaskLink = useCopyTaskLinkToClipboard()

  const handleClick = () => {
    onItemClick()

    // recurring instance task id is stored within the comment, the item id is always the recurring parent id
    const taskId = item.comment?.recurringTaskId ?? item.id

    navigate(
      buildTaskModalUrl({
        task: item.isMeetingTask ? item.id : taskId,
        isMeeting: item.isMeetingTask,
      })
    )
    recordAnalyticsEvent('SEARCH_RESULT_OPENED', {
      type: item.isRecurring ? 'recurring_task' : 'task',
    })
  }

  const handleCopyLink = () => {
    copyTaskLink({
      workspaceId: item.workspaceId,
      taskId: item.id,
      taskName: stripEmTags(item.name),
    })
  }

  useShortcut('mod+l', handleCopyLink, { enabled: active })

  return (
    <TaskResult item={item} onClick={handleClick} onCopyLink={handleCopyLink} />
  )
}
