import { useWorkspaceMember } from '~/global/hooks'

import { ActivityUserName } from './components'

import { useActivityFeedContext } from '../../providers'
import {
  type ActivityEntryViewSchema,
  type ActivityRenderConfig,
} from '../../types'
import { ActivityItemContainer } from '../activity-item-container'

type ActivityItemProps = {
  schema: ActivityEntryViewSchema
  renderConfig: ActivityRenderConfig
  disableTimestamp?: boolean
}

export function ActivityItem({
  schema,
  renderConfig,
  disableTimestamp,
}: ActivityItemProps) {
  const { workspaceId } = useActivityFeedContext()

  const author = useWorkspaceMember(workspaceId, schema.sourceId)

  if (renderConfig == null) {
    return null
  }

  return (
    <ActivityItemContainer
      icon={renderConfig.icon}
      subject={
        <ActivityUserName author={author} sourceType={schema.sourceType} />
      }
      timestamp={disableTimestamp ? null : schema.createdTime}
    >
      {renderConfig.children}
    </ActivityItemContainer>
  )
}
