import { PencilSolid, PuzzleSolid, TrashSolid } from '@motion/icons'
import { type ProjectDefinitionSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { ButtonGroup, IconButton } from '@motion/ui/base'
import { ProjectPalette } from '@motion/ui/project'

import {
  useDeleteProjectDefinition,
  useFlowTemplateModalUrl,
  useUpdateProjectDefinition,
} from '~/areas/flows'
import { ColorDropdown } from '~/global/components/dropdowns'

type FlowTemplateCardProps = {
  flowTemplate: ProjectDefinitionSchema
}
export function FlowTemplateCard({ flowTemplate }: FlowTemplateCardProps) {
  const updateFlowTemplate = useUpdateProjectDefinition()
  const deleteFlowTemplate = useDeleteProjectDefinition()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()

  const editFlowTemplateUrl = buildFlowTemplateModalUrl({
    forWorkspace: flowTemplate.workspaceId,
    template: 'edit',
    templateId: flowTemplate.id,
  })
  return (
    <FlowTemplateContainer>
      <FlowDescriptionContainer>
        <FlowButtonsHeader>
          <ProjectPalette color={flowTemplate.color}>
            <ColorDropdown
              onChange={(color) => {
                void updateFlowTemplate(
                  flowTemplate.id,
                  flowTemplate.workspaceId,
                  {
                    definition: {
                      ...flowTemplate,
                      color: color,
                    },
                  }
                )
              }}
              selectedColor={flowTemplate.color}
            >
              <div className='flex p-1 items-center gap-2 rounded bg-palette-bg-light cursor-pointer hover:bg-palette-bg-hover focus:bg-palette-bg-hover size-5'>
                <PuzzleSolid className='size-3 text-palette-highlight-default' />
              </div>
            </ColorDropdown>
          </ProjectPalette>
          <ButtonGroup>
            <IconButton
              icon={PencilSolid}
              sentiment='neutral'
              size='xsmall'
              url={editFlowTemplateUrl}
              variant='muted'
              aria-label='Edit flow template'
            />
            <IconButton
              icon={TrashSolid}
              onClick={() => void deleteFlowTemplate(flowTemplate.id)}
              sentiment='neutral'
              size='xsmall'
              variant='muted'
              aria-label='Delete flow template'
            />
          </ButtonGroup>
        </FlowButtonsHeader>
        <FlowTitle>{flowTemplate.name}</FlowTitle>
        <FlowDescription>{flowTemplate.description}</FlowDescription>
        <div>
          {/* TODO: update when project count is a named field on flow template */}
          Placeholder for project count
        </div>
      </FlowDescriptionContainer>
      <FlowStagesContainer>
        <div>Placeholder for stages</div>
      </FlowStagesContainer>
    </FlowTemplateContainer>
  )
}

const FlowTemplateContainer = classed('div', {
  base: `
    rounded border border-semantic-neutral-border-light bg-semantic-neutral-surface-raised-bg-subtlest
    flex w-full flex-col items-center
  `,
})

const FlowDescriptionContainer = classed('div', {
  base: `
    flex h-[148px] p-4 flex-col items-start gap-3 self-stretch
    rounded border bg-semantic-neutral-surface-raised-bg-default min-w-0
  `,
})

const FlowButtonsHeader = classed('div', {
  base: `
  flex justify-between items-center self-stretch
  `,
})

const FlowTitle = classed('span', {
  base: `text-semantic-neutral-text-default text-sm font-semibold`,
})

const FlowDescription = classed('span', {
  base: `truncate text-semantic-neutral-text-default text-xs font-normal max-w-full`,
})

const FlowStagesContainer = classed('div', {
  base: `
    rounded-b border border-t-0 border-semantic-neutral-border-light
    bg-semantic-neutral-surface-raised-bg-subtlest flex flex-col items-start self-stretch
    h-[100px] py-2 px-3 gap-1
  `,
})
