import { createContext } from 'react'

import { type useBookingTemplateBySlug } from './hooks/use-booking-template-by-slug'

export const BookingTemplateContext = createContext<
  ReturnType<typeof useBookingTemplateBySlug>
>({
  template: {
    bookingTemplates: {},
    submitHandler: async () => false,
    onDurationChange: () => void 0,
    onHostCalendarChanged: () => ({
      calendar: undefined,
      emailAccount: undefined,
    }),
    showError: () => void 0,
    onHostEmailAccountChanged: () => ({
      emailAccount: undefined,
      primaryCalendar: undefined,
    }),
    onReminderEmailTimeChange: () => void 0,
    onSelectedConflictCalendars: () => void 0,
    setters: {
      setBufferMins: () => void 0,
      setConflictCalendars: () => void 0,
      setCustomScheduleTimezone: () => void 0,
      setDaysSpan: () => void 0,
      setDurationChoices: () => void 0,
      setExternalEventName: () => void 0,
      setGuests: () => void 0,
      setHasBlocking: () => void 0,
      setHasReminderEmail: () => void 0,
      setHostDisplayName: () => void 0,
      setIgnoreInviteTeammates: () => void 0,
      setIsMaxDailyMeetingsDisabled: () => void 0,
      setLinkSlug: () => void 0,
      setLoading: () => void 0,
      setMaxDailyMeetings: () => void 0,
      setName: () => void 0,
      setQuestions: () => void 0,
      setReminderEmailBody: () => void 0,
      setReminderEmailPreBookingMins: () => void 0,
      setReminderEmailSubject: () => void 0,
      setScheduleId: () => void 0,
      setSelectedConflictCalendars: () => void 0,
      setStartsIn: () => void 0,
      setTimepickerDays: () => void 0,
    },
    state: {
      bookingLinkId: null,
      bufferMins: 0,
      conflictCalendars: [],
      createdByUserId: undefined,
      customScheduleTimezone: undefined,
      daysSpan: '',
      durationChoices: [],
      errorMessage: '',
      externalEventName: '',
      guests: [],
      hasBlocking: false,
      hasReminderEmail: false,
      hostDisplayName: '',
      isMaxDailyMeetingsDisabled: false,
      linkSlug: '',
      loading: true,
      maxDailyMeetings: 0,
      name: '',
      questions: [],
      reminderEmailBody: '',
      reminderEmailForbiddenMessage: '',
      reminderEmailPreBookingMins: 0,
      reminderEmailSubject: '',
      scheduleId: '',
      selectedConflictCalendars: [],
      startsIn: '',
      timepickerDays: {
        Friday: [],
        Monday: [],
        Saturday: [],
        Sunday: [],
        Thursday: [],
        Tuesday: [],
        Wednesday: [],
      },
    },
  },
  conference: {
    conferenceType: 'none',
    conferenceOptions: [],
    conferenceTypesLoaded: false,
    getConferenceType: () => ({
      conferenceType: 'none',
      conferenceOptions: [],
    }),
    onConferenceTypeChange: async () => void 0,
    setConferenceType: () => void 0,
  },
  hostCalendar: null,
})
