import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  type CreateProjectDefinitionSchema,
  type ProjectDefinitionSchema,
} from '@motion/zod/client'

import { useMyTasksWorkspace, useProjectDefinition } from '~/global/hooks'
import { useMemo } from 'react'

import { useGetInitialFormData } from './use-get-initial-form-data'
import { usePresetTemplate } from './use-preset-template'
import { useProjectDefinitionFromCreateSchema } from './use-project-definition-from-create-schema'

import { useFlowTemplateModalUrlParams } from '../use-flows-template-modal-url'

type InitialFormFields = {
  /**
   * The initial project definition to use for the form.
   * This is used in AI flows that will generate a project definition.
   */
  initialProjectDefinition?: CreateProjectDefinitionSchema
}

export function useInitialFormData({
  initialProjectDefinition,
}: InitialFormFields): FlowTemplateFormFields {
  const defaultWorkspace = useMyTasksWorkspace()
  const {
    template: templateOperationParam,
    workspaceId: workspaceIdParam,
    templateId,
  } = useFlowTemplateModalUrlParams()

  const { uid: currentUserId } = useAuthenticatedUser()

  const projectDefinition = useFormProjectDefinition({
    initialProjectDefinition,
  })

  const workspaceId =
    projectDefinition?.workspaceId ?? workspaceIdParam ?? defaultWorkspace?.id

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  const getInitialFormData = useGetInitialFormData({
    projectDefinition,
    templateId,
    templateOperationParam,
    currentUserId,
    workspaceId,
  })

  return useMemo(() => {
    return getInitialFormData()
  }, [getInitialFormData])
}

type InitialProjectDefinitionProps = {
  initialProjectDefinition?: CreateProjectDefinitionSchema
}

/**
 * Grab either the preset template or the provided project definition.
 * Project Definition takes precedence over the preset template.
 */
function useFormProjectDefinition({
  initialProjectDefinition,
}: InitialProjectDefinitionProps): ProjectDefinitionSchema | null {
  const defaultWorkspace = useMyTasksWorkspace()
  const {
    fromPresetId,
    template: templateOperationParam,
    workspaceId: workspaceIdParam,
    templateId,
  } = useFlowTemplateModalUrlParams()

  const presetProjectDefinition = usePresetTemplate(
    workspaceIdParam ?? defaultWorkspace?.id,
    templateOperationParam === 'new',
    fromPresetId
  )

  const templateProjectDefinition = useProjectDefinition(templateId ?? '')
  const convertedProjectDefinition = useProjectDefinitionFromCreateSchema(
    initialProjectDefinition ?? presetProjectDefinition
  )

  return useMemo(
    () => convertedProjectDefinition ?? templateProjectDefinition,
    [convertedProjectDefinition, templateProjectDefinition]
  )
}
