import type { CustomFieldValuesRecord } from '@motion/rpc/types'

export const forProjectUrlSearchParams = [
  'forWorkspace',
  'forStatus',
  'forPriority',
  'forLabel',
  'forManager',
  'forStartDate',
  'forDueDate',
  'forProjectId',
  'forTaskId',
  'forCustomField',
  'forFolder',
] as const

export type NormalProjectSearchParams = {
  project?: 'new' | 'edit' | string
  template?: 'new' | 'create'
}

export type ForProjectSearchParams = {
  [K in Exclude<
    (typeof forProjectUrlSearchParams)[number],
    'forCustomField'
  >]?: string
} & {
  forCustomField?: CustomFieldValuesRecord
}

export type ProjectUrlSearchParams = ForProjectSearchParams &
  NormalProjectSearchParams

export type ProjectUrlParams = {
  workspaceId?: string
  projectId?: string
  folderId?: string
}

export const clearProjectForParams = (searchParams: URLSearchParams) => {
  forProjectUrlSearchParams.forEach((param) => searchParams.delete(param))
  return searchParams
}
