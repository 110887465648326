import { GuestItem } from './guest-item'

import {
  useScheduleAssistantCalendarContext,
  useSendScheduleAssistantCalendarContext,
} from '../../../hooks'

type ExternalGuestItemProps = {
  email: string
}

/**
 * Represents an external guest that is not a team member.
 */
export function ExternalGuestItem({ email }: ExternalGuestItemProps) {
  const [calendarState] = useScheduleAssistantCalendarContext()
  const updatedCalendarState = useSendScheduleAssistantCalendarContext()

  if (calendarState.mode !== 'create-new') {
    return null
  }

  const onRemove = () => {
    updatedCalendarState({
      attendees: calendarState.attendees.filter(
        (attendee) => email !== attendee.email
      ),
    })
  }

  return (
    <GuestItem
      name={email}
      email='Calendar not shown (no access)'
      checked={false}
      onRemove={onRemove}
      checkboxDisabled
    />
  )
}
