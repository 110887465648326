import { useIsIapSubscription } from '~/global/hooks'

import { InviteTeammateButton } from './invite-teammate-button'
import { ConnectedUpsellButtons } from './upsell-buttons'

export const SidebarFooter = () => {
  const isIapSubscription = useIsIapSubscription()

  if (isIapSubscription) return null

  return (
    <div className='flex flex-col p-2 items-start gap-2 self-stretch border-t border-semantic-neutral-border-subtle bg-semantic-neutral-surface-bg-subtlest w-full mt-auto'>
      <ConnectedUpsellButtons />
      <InviteTeammateButton />
    </div>
  )
}
