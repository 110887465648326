import { UnstyledModal } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'

import { DevToolsSearchableList } from './dev-tools-components'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'motion-dev-modal': never
  }
}

export function MotionDevModal({
  close,
}: ModalTriggerComponentProps<'motion-dev-modal'>) {
  return (
    <UnstyledModal
      visible
      onClose={() => close()}
      modalClassName='bg-modal-bg w-96 rounded-lg border border-modal-border focus-visible:outline-0 shadow-xl'
      overlayClassName='bg-modal-overlay'
    >
      <div className='overflow-x-hidden'>
        <DevToolsSearchableList />
        {__SENTRY_RELEASE__ && (
          <div className='px-4 py-1 bg-semantic-neutral-bg-subtle'>
            <span className='mt-1 text-right text-semantic-neutral-text-default'>
              Sentry release: {__SENTRY_RELEASE__}
            </span>
          </div>
        )}
      </div>
    </UnstyledModal>
  )
}
