import { AutoRefundPage } from './AutoRefundPage'

import { useMotionLocalStorage } from '../../localServices/storage'

export const ConnectedAutoRefundPage = () => {
  const { subscriptionType } = useMotionLocalStorage(['subscriptionType'])

  if (!subscriptionType) return null

  return <AutoRefundPage subType={subscriptionType} />
}
