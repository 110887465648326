import { type TaskSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { AssigneeDropdown } from '~/areas/project-management/components'
import { useTaskAssigneeData } from '~/areas/tasks/hooks'
import { UserLabel } from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type ReactNode } from 'react'

import { CardButton } from '../components'

export type TaskAssigneeFieldProps = {
  task: TaskWithRelations
}
export const TaskAssigneeField = ({ task }: TaskAssigneeFieldProps) => {
  const { onChange, disabled } = useTaskAssigneeData(task)

  return (
    <AssigneeField
      selectedUserId={task.statusId}
      workspaceId={task.workspaceId}
      disabled={disabled}
      onChange={onChange}
    >
      <UserLabel value={task.assignee} />
    </AssigneeField>
  )
}

export type ProjectAssigneeFieldProps = {
  project: ProjectWithRelations
}
export const ProjectAssigneeField = ({
  project,
}: ProjectAssigneeFieldProps) => {
  const updateProject = useProjectUpdater()

  return (
    <AssigneeField
      selectedUserId={project.managerId}
      workspaceId={project.workspaceId}
      onChange={(userId) => updateProject(project, { managerId: userId })}
    >
      <UserLabel value={project.manager} />
    </AssigneeField>
  )
}

type AssigneeFieldProps = {
  selectedUserId: TaskSchema['assigneeUserId']
  workspaceId: TaskSchema['workspaceId']
  onChange: (userId: TaskSchema['assigneeUserId']) => void
  disabled?: boolean
  children: ReactNode
}
const AssigneeField = ({
  selectedUserId,
  workspaceId,
  onChange,
  disabled,
  children,
}: AssigneeFieldProps) => {
  return (
    <AssigneeDropdown
      selectedUserId={selectedUserId}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <CardButton disabled={disabled}>{children}</CardButton>
    </AssigneeDropdown>
  )
}
