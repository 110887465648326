import {
  API,
  type ApiTypes,
  createUseMutation,
  HttpError,
  useQueryOptionsFactory,
} from '@motion/rpc'
import { getCacheEntry, useStoreModelsFn } from '@motion/rpc-cache'
import { type UploadedFileSchema } from '@motion/rpc-types'

import {
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'
import { useState } from 'react'

const isError404 = (error: unknown) => {
  return error instanceof HttpError && error?.status === 404
}

export type FileByIdApi = ApiTypes<typeof API.files.getFile>
type FileByIdApiArgs = Partial<FileByIdApi['args']>

export const useFileById = (
  args: FileByIdApiArgs,
  opts?: FileByIdApi['UseQueryOptions']
): UseQueryResult<UploadedFileSchema | undefined> => {
  const { id } = args
  const storeModels = useStoreModelsFn()
  const client = useQueryClient()

  const [queryEnabled, setQueryEnabled] = useState(true)

  const fileId = id ?? ''

  const cachedFile = getCacheEntry(client, 'uploadedFiles', fileId)

  const queryOptionsOf = useQueryOptionsFactory(API.files.getFile)
  const queryArgs = queryOptionsOf(
    {
      ...args,
      id: fileId,
    },
    { enabled: Boolean(fileId) && queryEnabled, ...opts }
  )

  const initialData: FileByIdApi['data'] | undefined = cachedFile
    ? {
        id: fileId,
        meta: {
          model: 'uploadedFiles',
        },
        models: {
          uploadedFiles: {
            [fileId]: cachedFile.value,
          },
        },
      }
    : undefined

  const fetchResult = useQuery<
    FileByIdApi['queryFnData'],
    Error,
    UploadedFileSchema | undefined
  >({
    ...queryArgs,
    // @ts-expect-error - typing
    queryFn: async (ctx) => {
      const response = await queryArgs.queryFn(ctx)
      storeModels(response.models)
      return response
    },
    initialData: initialData as unknown as FileByIdApi['queryFnData'],
    initialDataUpdatedAt: initialData ? cachedFile?.updatedAt : undefined,
    enabled: queryArgs.enabled,
    cacheTime: 0,
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    select: (data: FileByIdApi['queryFnData']) => {
      return data.models[data.meta.model][data.id]
    },
    retry: (_: number, error: unknown) => {
      if (isError404(error)) {
        return false
      }

      return true
    },
    onError: (error: unknown) => {
      if (isError404(error)) {
        setQueryEnabled(false)
      }
    },
  })

  return fetchResult
}

export const useDeleteFileMutation = createUseMutation(API.files.deleteFile)

export const useUpdateFileMutation = createUseMutation(API.files.updateFile)
