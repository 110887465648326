import {
  type EndRelativeDateOption,
  isAutoScheduledStatus,
  type StartRelativeDateOption,
} from '@motion/shared/common'
import { showToast } from '@motion/ui/base'
import {
  DEFAULT_DURATION,
  findRelativeDateOptionAfter,
  findRelativeDateOptionBefore,
  getDefaultChunkDuration,
  mapRelativeDateOptionToAbsoluteDate,
  MIN_DURATION_DEFAULT_NO_CHUNKS_UNDER,
  NO_CHUNK_DURATION,
  NO_DURATION,
} from '@motion/ui-logic'
import { type TaskDefaults } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useSaveTaskDefaultSettings } from '@motion/web-common/settings'
import {
  type GlobalUserTaskDefaultSettingsRequestSchema,
  type UserTaskDefaultSettingsRequestSchema,
} from '@motion/zod/client'

import { useMapTaskDefaultAnalytics } from '~/areas/tasks/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'
import { useSendToDesktop } from '~/localServices/desktop'
import { useCallback } from 'react'

/**
 * See MotionTab for where we send the new settings to the desktop on initial load
 */
export const useUpdateTaskDefault = (selectedTaskDefaults: TaskDefaults) => {
  const { mutateAsync: updateTaskDefault } = useSaveTaskDefaultSettings()
  const mapTaskDefaultAnalytics = useMapTaskDefaultAnalytics()
  const sendToDesktop = useSendToDesktop()
  const { uid: currentUserId } = useAuthenticatedUser()
  const workspaceGetters = useWorkspaceFns()

  return useCallback(
    async <T extends keyof UserTaskDefaultSettingsRequestSchema['data']>(
      field: T,
      value: UserTaskDefaultSettingsRequestSchema['data'][T]
    ) => {
      const payload: GlobalUserTaskDefaultSettingsRequestSchema =
        getUpdateTaskDefaultPayload<T>(
          field,
          value,
          selectedTaskDefaults,
          currentUserId,
          workspaceGetters
        )

      try {
        const newSettings = await updateTaskDefault({
          data: payload,
        })
        recordAnalyticsEvent('TASK_DEFAULT_SETTINGS_CHANGED', {
          changed: mapTaskDefaultAnalytics(field, selectedTaskDefaults, value),
        })

        sendToDesktop('updateUserSettings', newSettings)

        showToast('success', 'Task default settings updated')
      } catch (error) {
        showErrorToast(error)
      }
    },
    [
      currentUserId,
      mapTaskDefaultAnalytics,
      selectedTaskDefaults,
      sendToDesktop,
      updateTaskDefault,
      workspaceGetters,
    ]
  )
}

export function getUpdateTaskDefaultPayload<
  T extends keyof UserTaskDefaultSettingsRequestSchema['data'],
>(
  field: T,
  value: UserTaskDefaultSettingsRequestSchema['data'][T],
  selectedTaskDefaults: TaskDefaults,
  currentUserId: string | null,
  workspaceGetters: ReturnType<typeof useWorkspaceFns>
): GlobalUserTaskDefaultSettingsRequestSchema {
  const basePayload: GlobalUserTaskDefaultSettingsRequestSchema = {
    level: 'GLOBAL',
    workspaceId: selectedTaskDefaults.workspaceId,
    [field]: value,
  }

  // If updating workspaceId, we need to wipe all workspace-specific defaults
  if (field === 'workspaceId') {
    return {
      ...basePayload,
      projectId: null,
      assigneeUserId: null,
      statusId: null,
      labelIds: [],
      customFieldValues: null,
    }
  }

  if (field === 'relativeStartOn') {
    const startDate = mapRelativeDateOptionToAbsoluteDate(
      value as StartRelativeDateOption,
      { bound: 'start' }
    )
    const endDate = mapRelativeDateOptionToAbsoluteDate(
      selectedTaskDefaults.relativeDueDate,
      { bound: 'end' }
    )

    if (
      startDate &&
      endDate &&
      startDate.startOf('day') >= endDate.startOf('day')
    ) {
      const newEndOption = findRelativeDateOptionAfter(startDate)
      if (newEndOption !== selectedTaskDefaults.relativeDueDate) {
        return {
          ...basePayload,
          relativeDueDate: newEndOption,
        }
      }
    }
  }

  if (field === 'relativeDueDate') {
    const endDate = mapRelativeDateOptionToAbsoluteDate(
      value as EndRelativeDateOption,
      { bound: 'end' }
    )
    const startDate = mapRelativeDateOptionToAbsoluteDate(
      selectedTaskDefaults.relativeStartOn,
      { bound: 'start' }
    )

    if (
      endDate &&
      startDate &&
      startDate.startOf('day') >= endDate.startOf('day')
    ) {
      const newStartOption = findRelativeDateOptionBefore(endDate)
      if (newStartOption !== selectedTaskDefaults.relativeStartOn) {
        return {
          ...basePayload,
          relativeStartOn: newStartOption,
        }
      }
    }
  }

  if (field === 'duration') {
    const newMinimumDuration = getDefaultChunkDuration(value as number)

    if (newMinimumDuration !== selectedTaskDefaults.minimumDuration) {
      return {
        ...basePayload,
        minimumDuration: newMinimumDuration,
      }
    }
  }

  if (field === 'isAutoScheduled') {
    if (value === false) {
      return {
        ...basePayload,
        scheduleId: null,
      }
    }

    if (selectedTaskDefaults.duration === NO_DURATION) {
      return {
        ...basePayload,
        duration: DEFAULT_DURATION,
      }
    } else if (
      selectedTaskDefaults.minimumDuration === NO_CHUNK_DURATION &&
      selectedTaskDefaults.duration >= MIN_DURATION_DEFAULT_NO_CHUNKS_UNDER
    ) {
      return {
        ...basePayload,
        minimumDuration: getDefaultChunkDuration(selectedTaskDefaults.duration),
      }
    }
  }

  const isOwnTask =
    currentUserId != null &&
    currentUserId === selectedTaskDefaults.assigneeUserId

  if (field === 'assigneeUserId' && !isOwnTask) {
    return {
      ...basePayload,
      scheduleId: null,
    }
  }

  if (field === 'statusId') {
    const status = workspaceGetters.getStatusById(value as string)
    if (
      !isAutoScheduledStatus(status) &&
      selectedTaskDefaults.isAutoScheduled
    ) {
      return {
        ...basePayload,
        isAutoScheduled: false,
      }
    }
  }

  return basePayload
}
