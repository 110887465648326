import {
  type CalendarEventSchemaV2,
  type ProjectSchema,
  type TaskSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useUpdateEvent } from '~/areas/event/hooks'
import { useCallback } from 'react'

export function useAddEventToProject() {
  const updateEvent = useUpdateEvent()

  return useCallback(
    async (
      eventId: CalendarEventSchemaV2['id'] | null | undefined,
      workspaceId: WorkspaceSchema['id'],
      projectId: ProjectSchema['id'] | null,
      schedulingTaskId?: TaskSchema['id']
    ) => {
      if (eventId == null) {
        throw new Error('Adding to a project requires an existing event')
      }

      if (projectId == null) {
        throw new Error('The event must be in a project')
      }

      const updatedEvent = await updateEvent(eventId, {
        sendUpdates: false,
        workspaceData: {
          workspaceId,
          projectId,
          schedulingTaskId,
        },
      })

      return updatedEvent
    },
    [updateEvent]
  )
}
