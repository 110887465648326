import type { PropsWithChildren, ReactNode } from 'react'

type LinkItemProps = PropsWithChildren<{
  title: ReactNode
  hint: string
}>

export const LinkItem = ({ title, hint, children }: LinkItemProps) => (
  <li className='flex items-center justify-between py-1.5 px-2 gap-4'>
    <div>
      <p className='text-sm text-dropdown-item-text-default'>{title}</p>
      <p className='text-[11px] text-dropdown-item-icon-default'>{hint}</p>
    </div>

    <div className='flex gap-1.5 items-center [&>span]:inline-flex'>
      {children}
    </div>
  </li>
)
