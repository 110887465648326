import { memo } from 'react'

import { CalendarDisabledBanner } from './DisabledCalendarBanner'
import { FeatureFlaggedBanner } from './FeatureFlaggedBanner'
import { MainCalendarWarningBanner } from './main-calendar-warning-banner'
import { OutdatedPaymentBanner } from './OutdatedPaymentBanner'
import { TrialEndAddPaymentBanner } from './trial-end-add-payment-banner'

import { useAppSelector } from '../../state/hooks'
import { selectTeam } from '../../state/team'
import { selectStripeSubscription } from '../../state/userSlice'
import { EmailAccountsErrorBanner } from '../email-accounts-error-banner/email-accounts-error-banner'

export const Banners = memo(function Banners() {
  const team = useAppSelector(selectTeam)
  const userStripeSubscription = useAppSelector(selectStripeSubscription)

  return (
    <>
      <EmailAccountsErrorBanner />
      <MainCalendarWarningBanner />
      <CalendarDisabledBanner />
      <FeatureFlaggedBanner />
      <OutdatedPaymentBanner
        pmTeamSubscription={team?.pmTeamSubscription}
        userStripeSubscription={userStripeSubscription}
      />
      <TrialEndAddPaymentBanner />
    </>
  )
})
