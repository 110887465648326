import { DuplicateOutline, PlusSolid, TrashSolid } from '@motion/icons'
import {
  type DayVerbose,
  type Schedule,
  type ScheduleRange,
} from '@motion/rpc-types'
import {
  Button,
  IconButton,
  PopoverTrigger,
  SearchableList,
  Tooltip,
} from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { byValue, Compare } from '@motion/utils/array'
import { logEvent } from '@motion/web-base/analytics'

import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { type Schedules } from './types'

import { Events } from '../../../analyticsEvents'
import { Combobox } from '../../../components/Common'
import { useUpdateSchedules } from '../../../localServices/firebase'
import { quarterHourTimeChoices } from '../../../storageConstants'

const timeStringToDateTime = (timeString: string) => {
  return DateTime.fromFormat(timeString, 'h:mm a')
}

interface ChooseWorkHoursProps {
  onComplete: () => void
  schedules: Schedules
  setSchedules: (schedules: Record<string, Schedule>) => void
  goBack: () => void
}

interface SlotType {
  start: DateTime
  end: DateTime
  invalid?: boolean
}

interface ScheduleDay {
  dayName: DayVerbose
  slots: SlotType[]
  selected: boolean
  hasConflicts?: boolean
}

const DEFAULT_SLOT = {
  start: timeStringToDateTime('9:00 am'),
  end: timeStringToDateTime('5:00 pm'),
}

const defaultSchedule: ScheduleDay[] = [
  {
    dayName: 'Monday',
    slots: [{ ...DEFAULT_SLOT }],
    selected: true,
  },
  {
    dayName: 'Tuesday',
    slots: [{ ...DEFAULT_SLOT }],
    selected: true,
  },
  {
    dayName: 'Wednesday',
    slots: [{ ...DEFAULT_SLOT }],
    selected: true,
  },
  {
    dayName: 'Thursday',
    slots: [{ ...DEFAULT_SLOT }],
    selected: true,
  },
  {
    dayName: 'Friday',
    slots: [{ ...DEFAULT_SLOT }],
    selected: true,
  },
  {
    dayName: 'Saturday',
    slots: [{ ...DEFAULT_SLOT }],
    selected: false,
  },
  {
    dayName: 'Sunday',
    slots: [{ ...DEFAULT_SLOT }],
    selected: false,
  },
]

const timeDropdownOptions = quarterHourTimeChoices.map((choice) => {
  return {
    label: choice,
    value: timeStringToDateTime(choice),
  }
})

const deepCopySchedule = (schedule: ScheduleDay[]): ScheduleDay[] => {
  return schedule.map((day) => ({
    ...day,
    slots: deepCopySlots(day.slots),
  }))
}

const deepCopySlots = (slots: SlotType[]): SlotType[] => {
  return slots.map((slot) => ({ ...slot }))
}

/**
 * Takes an array of slots and a given index, and marks any conflicts that index has
 *
 * @param {SlotType[]} slots - An array of slot objects.
 * @param {number} slotIndex - The index of the slot to validate.
 * @return {SlotType[]} - The updated array of slots with the validation status.
 */
const validateSlot = (slots: SlotType[], slotIndex: number): SlotType[] => {
  const newSlots: SlotType[] = deepCopySlots(slots)
  const slot = newSlots[slotIndex]

  // Reset the current slot to be valid. We'll override if it is invalid
  newSlots[slotIndex].invalid = false

  for (let i = 0; i < newSlots.length; i++) {
    if (i === slotIndex) {
      continue
    }
    const compSlot = newSlots[i]

    const firstSlot = slot.start < compSlot.start ? slot : compSlot
    const secondSlot = slot.start < compSlot.start ? compSlot : slot

    if (
      firstSlot.start === secondSlot.start ||
      firstSlot.end > secondSlot.start
    ) {
      newSlots[i].invalid = true
      newSlots[slotIndex].invalid = true
    } else {
      newSlots[i].invalid = false
    }
  }

  return newSlots
}

const getTimeOptionsForValue = (
  value: DateTime,
  getTimesBefore: boolean
): {
  label: string
  value: DateTime
}[] => {
  return timeDropdownOptions.filter((option) => {
    return getTimesBefore ? option.value < value : option.value > value
  })
}

export function ChooseWorkHours({
  schedules,
  setSchedules,
  onComplete,
  goBack,
}: ChooseWorkHoursProps) {
  const [schedule, setSchedule] = useState(defaultSchedule)
  const updateSchedules = useUpdateSchedules()

  const [copyDays, setCopyDays] = useState<Set<string>>(new Set())

  const toggleSelectedDay = (index: number) => {
    const newSchedule = [...schedule]
    newSchedule[index].selected = !newSchedule[index].selected
    setSchedule(newSchedule)
  }

  const scheduleHasConflicts = useMemo(() => {
    for (const day of schedule) {
      if (day.hasConflicts) {
        return true
      }
    }
    return false
  }, [schedule])

  const orderAndValidateSlots = (
    newIndex: number,
    newSlot: SlotType,
    slots: SlotType[]
  ): { slots: SlotType[]; hasConflicts: boolean } => {
    const newSlots = deepCopySlots(slots)
    newSlots.sort(byValue((slot) => slot.start, Compare.dateTime))
    newIndex = slots.indexOf(newSlot)
    const validatedSlots = validateSlot(newSlots, newIndex)
    return {
      slots: validatedSlots,
      hasConflicts: validatedSlots[newIndex].invalid || false,
    }
  }

  const addSlot = (dayIndex: number) => {
    const newSchedule = deepCopySchedule(schedule)
    const slots = newSchedule[dayIndex].slots

    const nextTime: DateTime = slots[slots.length - 1].end.plus({ hours: 1 })

    const newSlot = {
      start: nextTime,
      end: nextTime.plus({ hours: 1 }),
    }

    slots.push(newSlot)
    const validationResult = orderAndValidateSlots(
      slots.length - 1,
      newSlot,
      slots
    )

    newSchedule[dayIndex].slots = validationResult.slots
    newSchedule[dayIndex].hasConflicts = validationResult.hasConflicts
    setSchedule(newSchedule)
  }

  const deleteSlot = (dayIndex: number, slotIndex: number) => {
    const newSchedule = deepCopySchedule(schedule)
    newSchedule[dayIndex].slots.splice(slotIndex, 1)
    const newDay = newSchedule[dayIndex]
    newDay.hasConflicts = false
    // Need to re-check for conflicts
    newDay.slots.forEach((slot, i) => {
      if (slot.invalid) {
        const slotIsInvalid = validateSlot(newDay.slots, i)[i].invalid
        newDay.slots[i].invalid = slotIsInvalid
        newDay.hasConflicts = newDay.hasConflicts || slotIsInvalid
      }
    })

    newSchedule[dayIndex] = newDay
    setSchedule(newSchedule)
  }

  const handleUpdateSchedule = (
    dayIndex: number,
    slotIndex: number,
    isStart: boolean,
    newValue: DateTime
  ) => {
    const newSchedule = deepCopySchedule(schedule)
    const newSlots = newSchedule[dayIndex].slots
    newSlots[slotIndex][isStart ? 'start' : 'end'] = newValue
    const newSlot = newSlots[slotIndex]
    const validationResult = orderAndValidateSlots(slotIndex, newSlot, newSlots)
    newSchedule[dayIndex].slots = validationResult.slots
    newSchedule[dayIndex].hasConflicts = validationResult.hasConflicts
    setSchedule(newSchedule)
  }

  const saveSchedule = async () => {
    const workSchedule = schedule.reduce(
      (acc, day) => {
        if (day.selected) {
          acc[day.dayName] = day.slots.map((slot) => {
            return {
              range:
                slot.start.toFormat('h:mma').toLowerCase() +
                '-' +
                slot.end.toFormat('h:mma').toLowerCase(),
            }
          })
        } else {
          acc[day.dayName] = []
        }
        return acc
      },
      {} as Record<DayVerbose, ScheduleRange[]>
    )

    const newSchedules = {
      ...schedules,
      work: {
        ...schedules['work'],
        schedule: workSchedule,
      },
    }

    await updateSchedules(newSchedules)
    setSchedules(newSchedules)
    onComplete()
    logEvent(Events.SCHEDULE_SAVE)
  }

  const copyHandler = (day: string) => {
    const newSchedule = deepCopySchedule(schedule)
    const copySchedule = newSchedule.find((x) => x.dayName === day)

    if (!copySchedule) return

    newSchedule.forEach((schedule, i) => {
      if (copyDays.has(schedule.dayName)) {
        newSchedule[i] = {
          ...schedule,
          selected: true,
          slots: deepCopySlots(copySchedule.slots),
        }
      }
    })
    setSchedule(newSchedule)
  }

  return (
    <div className='light flex h-full w-full flex-col items-center'>
      <div className='max-w-[600px] '>
        <div className='flex flex-col gap-y-3 py-8'>
          <h1 className='text-[32px] font-semibold leading-[42px]'>
            Set your weekly work hours
          </h1>
          <p className='text-light-1100'>
            Choose the times when you&apos;d like to schedule tasks. You&apos;ll
            be able to change this later, and add more custom schedules in
            settings.
          </p>
        </div>
        <div className='flex w-full flex-col pb-8'>
          <div className='border-light-500 rounded border bg-white px-6 mb-8 h-full w-full'>
            {schedule.map((day, dayIndex) => (
              <div
                key={dayIndex}
                className={twMerge(
                  'flex flex-row justify-between w-full py-4 items-start min-h-[67px]',
                  dayIndex !== schedule.length - 1 && 'border-b'
                )}
              >
                <div className='flex flex-row items-start'>
                  <div className='flex flex-row items-center pr-4 px-2 pt-2 w-[124px]'>
                    <Checkbox
                      checked={day.selected}
                      onChange={() => toggleSelectedDay(dayIndex)}
                      label={day.dayName}
                    />
                  </div>
                  {day.selected && (
                    <div className='flex flex-col'>
                      {day.slots.map((slot, slotIndex) => {
                        return (
                          <div
                            key={slotIndex}
                            className='flex flex-row items-center w-full'
                          >
                            <Combobox
                              options={getTimeOptionsForValue(slot.end, true)}
                              renderValueToInput
                              value={slot.start
                                .toFormat('h:mm a')
                                .toLowerCase()}
                              autoComplete
                              onChange={(value) => {
                                handleUpdateSchedule(
                                  dayIndex,
                                  slotIndex,
                                  true,
                                  value
                                )
                              }}
                              hasError={slot.invalid}
                              className='w-[93px]'
                              showArrow={false}
                            />
                            <span className='mx-3'>-</span>
                            <Combobox
                              options={getTimeOptionsForValue(
                                slot.start,
                                false
                              )}
                              renderValueToInput
                              value={slot.end.toFormat('h:mm a').toLowerCase()}
                              autoComplete
                              onChange={(value) => {
                                handleUpdateSchedule(
                                  dayIndex,
                                  slotIndex,
                                  false,
                                  value
                                )
                              }}
                              hasError={slot.invalid}
                              className='w-[93px]'
                              showArrow={false}
                            />
                            {slotIndex === 0 ? (
                              <IconButton
                                icon={PlusSolid}
                                variant='muted'
                                onClick={() => addSlot(dayIndex)}
                                sentiment='neutral'
                              />
                            ) : (
                              <IconButton
                                icon={TrashSolid}
                                variant='muted'
                                onClick={() => deleteSlot(dayIndex, slotIndex)}
                                sentiment='neutral'
                              />
                            )}
                          </div>
                        )
                      })}
                      {day.hasConflicts && (
                        <span className='text-red-500'>
                          Make sure times don&apos;t overlap each other
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {day.selected && (
                  <PopoverTrigger
                    onClose={() => {
                      setCopyDays(new Set())
                    }}
                    placement='bottom-end'
                    renderPopover={({ close }) => (
                      <div>
                        <div className='py-2 px-3 bg-semantic-neutral-bg-subtle text-semantic-neutral-text-subtle text-xs'>
                          Copy times to
                        </div>
                        <SearchableList
                          searchable={false}
                          items={schedule}
                          renderItem={(item) => <div>{item.dayName}</div>}
                          computeKey={(item) => item.dayName}
                          computeSelected={(item) =>
                            item.dayName === day.dayName ||
                            copyDays.has(item.dayName)
                          }
                          computeDisabled={(item) =>
                            item.dayName === day.dayName
                          }
                          onSelect={(items) => {
                            const newSet = new Set(items.map((i) => i.dayName))
                            setCopyDays(newSet)
                          }}
                          itemType='checkbox'
                        />
                        <div className='border-t border-dropdown-border flex justify-between p-3'>
                          <Button
                            sentiment='neutral'
                            variant='muted'
                            onClick={close}
                          >
                            Cancel
                          </Button>
                          <Button
                            sentiment='primary'
                            onClick={() => {
                              copyHandler(day.dayName)
                              close()
                            }}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    )}
                  >
                    <Button sentiment='neutral' variant='muted'>
                      <DuplicateOutline />
                      Copy
                    </Button>
                  </PopoverTrigger>
                )}
              </div>
            ))}
          </div>
          <Tooltip
            asChild
            content={scheduleHasConflicts && 'Fix errors above to continue.'}
          >
            <Button
              sentiment='primary'
              onClick={saveSchedule}
              disabled={scheduleHasConflicts}
              fullWidth
            >
              Continue
            </Button>
          </Tooltip>
          <Button
            variant='muted'
            sentiment='neutral'
            fullWidth
            onClick={goBack}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  )
}
