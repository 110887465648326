import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useCreateComment as useCreateCommentMutation } from '~/global/rpc/v2/comments'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { type CommentSubmitPayload } from '../../types'

export type CreateCommentPayload = CommentSubmitPayload & {
  type: 'project' | 'task'
  targetId: string
}

export function useCreateComment() {
  const context = useRouteAnalyticsMetadata()
  const { mutateAsync: createComment } = useCreateCommentMutation()

  return useCallback(
    async ({ body, createdByUserId, type, targetId }: CreateCommentPayload) => {
      try {
        const result = await createComment({
          bodyHtml: body,
          targetId,
          targetType: type === 'project' ? 'PROJECT' : 'TASK',
          createdByUserId,
        })

        if (type === 'project') {
          recordAnalyticsEvent(
            'PROJECT_MANAGEMENT_CREATE_PROJECT_COMMENT',
            context
          )
        } else {
          recordAnalyticsEvent(
            'PROJECT_MANAGEMENT_CREATE_TASK_COMMENT',
            context
          )
        }

        return result
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useCreateComment',
          },
        })

        showErrorToast(e)
      }
    },
    [context, createComment]
  )
}
