import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useHasSeenCta } from '~/global/hooks'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { useCallback, useEffect, useState } from 'react'

import { GdprCookiesAlert } from './gdpr-cookies-alert'

export const GdprCookiesWrapper = () => {
  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()
  const hasSeenCta = useHasSeenCta()
  const [open, setOpen] = useState(false)
  const showCta = hasSeenCta(`GDRP_SETTINGS`)

  const acceptCookies = useCallback(() => {
    recordAnalyticsEvent('GDPR_COOKIES_MODAL_ACCEPTED')
    void updateCtaSettings({
      ctaSettings: {
        GDRP_SETTINGS: true,
      },
    })
  }, [updateCtaSettings])

  const customizeCookies = useCallback(() => {
    recordAnalyticsEvent('GDPR_COOKIES_MODAL_CUSTOMIZE')
    void updateCtaSettings({
      ctaSettings: {
        GDRP_SETTINGS: true,
      },
    })
    setOpen(false)
    // Can't do useNavigate here because we have 2 nested ReactRouters
    window.open('/web/settings/privacy', '_blank')
  }, [updateCtaSettings, setOpen])

  useEffect(() => {
    if (!showCta.loaded) return
    if (showCta.seen) return
    setOpen(true)
  }, [showCta.loaded, showCta.seen, setOpen])

  if (!open) return null

  return (
    <GdprCookiesAlert
      acceptCookies={acceptCookies}
      customizeCookies={customizeCookies}
    />
  )
}
