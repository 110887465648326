import { ArrowLeftSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { type PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

type BookingShellProps = PropsWithChildren<{
  heading: string
  showBackButton?: boolean
  /* Optional function to run when clicking the back button. If the function is
   * undefined, the button will simply call the back function of the router. If
   * your callback returns false, nothing will happen. */
  onBackClick?: () => void | false
}>

export const BookingShell = ({
  heading,
  showBackButton = true,
  onBackClick,
  children,
}: BookingShellProps) => {
  const navigate = useNavigate()

  return (
    <div className='col-span-2 flex h-full flex-col min-h-0'>
      <header className='text-semantic-neutral-text-default border-b-semantic-neutral-border-default bg-semantic-neutral-surface-bg-default flex flex-row items-center gap-3 border-b px-6 py-4 text-base font-semibold'>
        {showBackButton && (
          <Button
            sentiment='neutral'
            size='small'
            variant='muted'
            iconOnly
            onClick={() => {
              if (onBackClick && onBackClick() === false) return

              navigate('/web/calendar')
            }}
          >
            <ArrowLeftSolid />
          </Button>
        )}

        <span>{heading}</span>
      </header>

      {children}
    </div>
  )
}
