import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { type CustomFieldSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'

import { type WorkspaceCustomField } from '../types'
import { getFilteredWorkspaceCustomField } from '../utils'

export function useCustomFieldById(
  customFieldId: string
): WorkspaceCustomField | undefined
export function useCustomFieldById<T extends CustomFieldSchema['type']>(
  customFieldId: string,
  type: T
): Extract<WorkspaceCustomField, { type: T }> | undefined
export function useCustomFieldById(
  customFieldId: string,
  type?: CustomFieldSchema['type']
): WorkspaceCustomField | undefined {
  const field = useSharedStateQuery(AppWorkspaceContext, (state) => {
    const customField = state.customFields.byId[customFieldId]

    if (!customField || (type != null && customField.type !== type)) {
      return undefined
    }

    return getFilteredWorkspaceCustomField(customField)
  })

  return field
}
