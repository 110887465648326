import { PopoverTrigger, type PopoverTriggerProps } from '@motion/ui/base'
import { type QuickAction } from '@motion/ui-logic'

import {
  type DateDropdownTarget,
  type ProjectAwareContextProps,
  ProjectAwareDateDropdownContent,
  type ProjectAwareDateDropdownContentProps,
} from '~/areas/project-management/components'
import { DateTime } from 'luxon'
import { type ReactNode } from 'react'

export type DeadlineDropdownProps = {
  value: string | null
  children: ReactNode
  onChange(value: string | null): void
  isValidDateOption?: (date: DateTime) => boolean
  invalidDateTooltipContent?: ReactNode
  renderContent?: PopoverTriggerProps['renderPopover']
  renderCalendarFooter?: () => ReactNode
  hideTimeOptions?: boolean
  disableClearing?: boolean
  quickActions?: QuickAction[]
  renderHeaderSuffix?: () => ReactNode
  dropdownTarget: DateDropdownTarget
  contextProps: ProjectAwareContextProps
  additionalQuickActions?: ProjectAwareDateDropdownContentProps['additionalQuickActions']
  overrides?: ProjectAwareDateDropdownContentProps['overrides']
}

/**
 * If for a task, use `TaskDeadlineDropdown`
 */
export const DeadlineDropdown = ({
  children,
  renderContent,
  onChange,
  ...rest
}: DeadlineDropdownProps) => {
  const renderPopover =
    renderContent ??
    (({ close }) => (
      <DeadlineDateDropdownContent
        onChange={(value) => {
          onChange(value)
          close()
        }}
        {...rest}
      />
    ))

  return (
    <PopoverTrigger placement='bottom-start' renderPopover={renderPopover}>
      {children}
    </PopoverTrigger>
  )
}

export type DeadlineDateDropdownContentProps = Omit<
  DeadlineDropdownProps,
  'children'
>

export const DeadlineDateDropdownContent = ({
  isValidDateOption,
  invalidDateTooltipContent,
  hideTimeOptions = false,
  contextProps,
  ...props
}: DeadlineDateDropdownContentProps) => {
  const todayStartOfDay = DateTime.now().startOf('day')
  const isValidOption =
    isValidDateOption ?? ((date: DateTime) => date >= todayStartOfDay)

  return (
    <ProjectAwareDateDropdownContent
      {...props}
      contextProps={contextProps}
      disabledDate={(date) => !isValidOption(date)}
      disabledDateTooltipContent={invalidDateTooltipContent}
      showTimeOptions={!hideTimeOptions}
      dropdownType='deadline'
    />
  )
}
