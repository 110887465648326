import {
  formatDurationTime,
  getDurations,
  parseDurationText,
} from '@motion/ui-logic'
import { uniqueBy } from '@motion/utils/array'

export const NULL_FLAG = 'None/Not Sure'
export const SHORT_NULL_FLAG = 'None'
export const REMINDER = 'Short Task/Reminder'
export const SHORT_REMINDER = 'Reminder'

export type DurationOptions = {
  allowZero: boolean
  emptyText: string
  zeroText: string
}
function normalizeDurationOptions(
  values: Partial<DurationOptions> | undefined
): DurationOptions {
  return {
    allowZero: true,
    emptyText: NULL_FLAG,
    zeroText: REMINDER,
    ...values,
  }
}

/**
 * @deprecated Use `formatToTaskDuration()` from ui-logic
 */
export const toTaskDurationWithReminders = (
  val: string | number | null | undefined,
  options?: Partial<DurationOptions>
) => {
  const opts = normalizeDurationOptions(options)
  if (val == null) {
    return opts.emptyText
  }

  if (opts.allowZero && val === 0) {
    return opts.zeroText
  }

  if (typeof val === 'number') return formatDurationTime(val)
  return opts.emptyText
}

/**
 * @deprecated Use `getDurations()` from ui-logic
 */
export const createDurationChoices = (
  duration: string,
  choices: (number | null)[] = getDurations({ includeNone: true }).map(
    (i) => i.value
  ),
  options?: Partial<DurationOptions>
) => {
  const opts = normalizeDurationOptions(options)
  const minuteChoices = parseDurationText(duration)

  const under5Min = minuteChoices?.length && minuteChoices[0] <= 5
  const maybeReminder = opts.allowZero && under5Min ? 0 : undefined

  const newChoices = minuteChoices?.length
    ? [maybeReminder, ...uniqueBy(minuteChoices, (x) => x)].filter(Boolean)
    : choices
  return newChoices
}
