import { createLookupBy } from '@motion/utils/object'

export type EntityCache<T> = {
  all: T[]
  byId: Record<string, T>
}

export function createEntityCache<T extends { id: string }>(
  items: readonly T[]
): EntityCache<T>
export function createEntityCache<T>(
  items: readonly T[],
  keyAccessor: (item: T) => string
): EntityCache<T>
export function createEntityCache<T>(
  items: readonly T[],
  keyAccessor: (item: any) => string = (item) => item.id
): EntityCache<T> {
  return {
    all: items as T[],
    byId: createLookupBy(items, keyAccessor),
  }
}

export function cacheFromRecord<T extends { id: string }>(
  data: Record<string, T>
): EntityCache<T> {
  return {
    all: Object.values(data),
    byId: data,
  }
}
