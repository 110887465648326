import { DuplicateSolid, TrashSolid } from '@motion/icons'
import { IconButton, PopoverTrigger } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { VariableLabel } from '~/global/components/labels'
import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { useRegisterFieldArray } from '~/hooks'

import { TextVariablePopoverContent } from './copy-variable-popover-content'

import { useFlowTemplateForm } from '../../hooks'
import { DEFAULT_TEXT_VARIABLE_KEYS, findFirstDuplicateName } from '../../utils'

type Props = {
  keyPath: `textVariables.${number}.key`
  namePath: `textVariables.${number}.name`
  name: FlowTemplateFormFields['textVariables'][number]['name']
  onRemove: () => void
  addNewVariable: () => void
}

export const TextVariableField = ({
  namePath,
  keyPath,
  name,
  onRemove,
  addNewVariable,
}: Props) => {
  const {
    form: { register, watch, getValues, clearErrors },
  } = useFlowTemplateForm()

  const { onChange, ...rest } = useRegisterFieldArray(namePath, register, {
    validate: () => {
      clearErrors(namePath)
      const textVariables = getValues('textVariables')
      const value = getValues(namePath)

      const errorMessage = checkTextRuleErrors(
        value,
        getTextFieldRules('Text variable name')
      )
      if (errorMessage) {
        return errorMessage
      }

      const duplicateName = findFirstDuplicateName(textVariables)
      if (duplicateName) {
        return `More than one text variable named "${duplicateName.name}"`
      }
    },
  })

  const key = watch(keyPath)

  const isDefault = DEFAULT_TEXT_VARIABLE_KEYS.includes(key)

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addNewVariable()
      e.preventDefault()
    }
  }

  return (
    <div className='flex flex-row items-center gap-2 justify-between'>
      {isDefault ? (
        <VariableLabel value={{ color: 'blue', name }} />
      ) : (
        <TextField
          placeholder='Enter text'
          label='text textVariables'
          labelHidden
          size='small'
          variant='default'
          fullWidth
          onChange={onChange}
          onKeyDown={onKeyDown}
          {...rest}
        />
      )}

      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => (
          <TextVariablePopoverContent
            close={close}
            name={name}
            variableKey={key}
          />
        )}
      >
        <IconButton
          icon={DuplicateSolid}
          variant='muted'
          sentiment='neutral'
          size='small'
        />
      </PopoverTrigger>

      {!isDefault && (
        <IconButton
          icon={TrashSolid}
          variant='muted'
          sentiment='neutral'
          size='small'
          onClick={onRemove}
        />
      )}
    </div>
  )
}
