import { XSolid } from '@motion/icons'
import { FileIcon, IconButton, Tooltip } from '@motion/ui/base'
import { type UploadedFileSchema } from '@motion/zod/client'

import { useFileById } from '~/global/rpc/v2/files'

type AttachmentItemProps = {
  attachmentId: string
  onDelete?: (attachmentId: UploadedFileSchema['id']) => void
}

export function AttachmentItem({
  attachmentId,
  onDelete,
}: AttachmentItemProps) {
  const { data: attachment, isError: isAttachmentError } = useFileById({
    id: attachmentId,
  })

  if (isAttachmentError || attachment == null) {
    return null
  }

  return (
    <div className='flex items-center gap-2 p-2'>
      <FileIcon mimeType={attachment.mimeType} className='shrink-0' />
      <p className='text-semantic-neutral-text-default text-xs truncate mr-auto'>
        {attachment.fileName}
      </p>

      <Tooltip asChild content='Delete attachment'>
        <IconButton
          size='small'
          variant='muted'
          sentiment='neutral'
          icon={XSolid}
          onClick={() => onDelete?.(attachment.id)}
        />
      </Tooltip>
    </div>
  )
}
