import { HasExperiment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { getCustomFieldIcon } from '~/areas/custom-fields/utils'
import { useParams } from 'react-router-dom'

import { OldCustomFieldRow } from './components'

import { SettingTableV2 } from '../../components'
import { useDeleteCustomField } from '../../hooks'

export type CustomFieldsTableProps = {
  fields: WorkspaceCustomField[]
}

export const CustomFieldsTable = ({ fields }: CustomFieldsTableProps) => {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const modalApi = useModalApi()
  const deleteField = useDeleteCustomField()

  if (!workspaceId) {
    throw new Error('No workspaceId')
  }

  return (
    <HasExperiment
      name='duplicate-custom-field'
      fallback={
        <div className='flex flex-col overflow-y-auto overflow-x-hidden rounded border border-semantic-neutral-border-default [&>*]:border-semantic-neutral-border-default [&>*]:border-b [&>*:last-child]:border-none'>
          {fields.map((field) => (
            <div
              key={field.id}
              className='flex items-center gap-3 pl-3 pr-4 py-3'
            >
              <OldCustomFieldRow field={field} />
            </div>
          ))}
        </div>
      }
    >
      <SettingTableV2
        items={fields.map((field) => ({
          id: field.id,
          label: field.name,
          icon: getCustomFieldIcon(field.type),
          onDuplicate: () => {
            modalApi.open('duplicate-custom-field', {
              workspaceId,
              customField: field,
            })
          },
          onDelete: () =>
            deleteField({
              workspaceId,
              customField: field,
            }),
          onEdit: () =>
            modalApi.open('edit-custom-field', {
              workspaceId,
              customField: field,
            }),
        }))}
      />
    </HasExperiment>
  )
}
