import {
  type CustomFieldFieldArrayValue,
  mapCustomFieldToFieldArrayWithValue,
} from '@motion/ui-logic'
import { reduceCustomFieldsValuesFieldArrayToRecord } from '@motion/ui-logic/pm/project'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { CustomFieldsSidebar } from '~/areas/task-project/components'
import { useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'

import { useTaskDefinitionForm } from '../use-task-definition-form'

type CustomFieldsSidebarSectionProps = {
  workspaceId: string
  disabled?: boolean
}

export const CustomFieldsTaskSidebarSection = ({
  workspaceId,
  disabled,
}: CustomFieldsSidebarSectionProps) => {
  const {
    form: { control, watch },
  } = useTaskDefinitionForm()

  const { fields, update, replace } = useFieldArray({
    control,
    name: 'customFieldValuesFieldArray',
  })

  const taskCustomFields = watch('customFieldValuesFieldArray')

  usePopulateFromWorkspaceCustomFields(taskCustomFields, workspaceId, replace)

  return (
    <CustomFieldsSidebar
      fields={fields}
      workspaceId={workspaceId}
      disabled={disabled}
      onChange={(customField, value, index) => {
        update(index, {
          ...customField,
          value: value as any,
        })
      }}
    />
  )
}

function usePopulateFromWorkspaceCustomFields(
  taskCustomFields: CustomFieldFieldArrayValue[],
  workspaceId: string,
  replace: (value: CustomFieldFieldArrayValue[]) => void
) {
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  useEffect(() => {
    const hasNewCustomFields =
      workspaceCustomFields.length !== taskCustomFields.length

    if (hasNewCustomFields) {
      const customFieldValues = reduceCustomFieldsValuesFieldArrayToRecord(
        taskCustomFields,
        { omitNull: true }
      )

      replace(
        workspaceCustomFields.map((field) =>
          mapCustomFieldToFieldArrayWithValue(field, customFieldValues)
        )
      )
    }
  }, [workspaceCustomFields, taskCustomFields, replace])
}
