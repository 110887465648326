import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type TaskSchema } from '@motion/zod/client'

import { PriorityLabel } from '~/global/components/labels'
import { AppWorkspaceContext } from '~/global/contexts'
import { type ReactNode } from 'react'

import { RestrictWidth } from '../../../components'
import { DropdownContainer } from '../styled'

type BulkPriorityDropdownProps = Omit<
  BulkPriorityDropdownContentProps,
  'close'
> & {
  children?: ReactNode
}

export function BulkPriorityDropdown({
  children,
  ...rest
}: BulkPriorityDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkPriorityDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type BulkPriorityDropdownContentProps = {
  close: () => void
  onChange: (priorityLevel: TaskSchema['priorityLevel']) => void
  selectedPriorityLevel: TaskSchema['priorityLevel'] | undefined
}

function BulkPriorityDropdownContent({
  close,
  onChange,
  selectedPriorityLevel,
}: BulkPriorityDropdownContentProps) {
  const priorityLevels = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.priorities.all
  )

  return (
    <DropdownContainer>
      <SearchableList
        searchable
        items={priorityLevels}
        computeKey={(item) => item}
        computeSearchValue={(item) => item}
        computeSelected={(item) => item === selectedPriorityLevel}
        onSelect={(priorityLevel) => {
          onChange(priorityLevel)
          close()
        }}
        renderItem={(item) => (
          <RestrictWidth>
            <PriorityLabel value={item} />
          </RestrictWidth>
        )}
        inputProps={{ placeholder: 'Choose priority...' }}
      />
    </DropdownContainer>
  )
}
