const BADGE_SIZES = {
  xxxsmall: 8,
  xxsmall: 9,
  xsmall: 11,
  small: 12,
  normal: 16,
  medium: 18,
  large: 20,

  // The following are actually only really used by the user badge
  xlarge: 24,
  xxlarge: 28,
  xxxlarge: 32,
  xxxxlarge: 56,
  xxxxxlarge: 96,
}

const DEFAULT_BADGE_SIZE: BadgeSize = 'normal'

export type BadgeSize = keyof typeof BADGE_SIZES
export const getBadgeSizeInPixels = (size: BadgeSize = DEFAULT_BADGE_SIZE) => {
  return BADGE_SIZES[size]
}
