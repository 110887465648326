import { useProjectCustomFieldUpdater } from '~/areas/project/hooks'
import {
  type CustomFieldWithValue,
  type ProjectWithRelations,
} from '~/global/proxies'

import {
  FieldComponentColumn,
  FieldContainer,
  FieldNameColumn,
} from './components'
import { CONTROLLED_CUSTOM_FIELD_KANBAN_LOOKUP } from './lookup'

export const ProjectCardField = ({
  project,
  fieldId,
}: {
  project: ProjectWithRelations
  fieldId: string
}) => {
  const customField = project.customFields[fieldId]
  if (!customField) return null

  return (
    <FieldContainer>
      <FieldNameColumn>{customField.definition.name}</FieldNameColumn>

      <FieldComponentColumn>
        <ConnectedCardProjectField
          project={project}
          customField={customField}
        />
      </FieldComponentColumn>
    </FieldContainer>
  )
}

const ConnectedCardProjectField = ({
  project,
  customField,
}: {
  project: ProjectWithRelations
  customField: CustomFieldWithValue
}) => {
  const updateProjectCustomField = useProjectCustomFieldUpdater()
  const { value } = customField

  const onSubmit = async (newValue: typeof value) => {
    await updateProjectCustomField(project, customField.definition, newValue)
  }

  const FieldComponent = CONTROLLED_CUSTOM_FIELD_KANBAN_LOOKUP[customField.type]
  if (FieldComponent == null) return null

  return (
    <FieldComponent
      customField={customField}
      // @ts-expect-error - fine
      value={value ?? null}
      onSubmit={onSubmit}
    />
  )
}
