import { wrapVariableInDelimiters } from '@motion/shared/flows'
import { type TaskDefinitionSchema } from '@motion/zod/client'

export function formatPrepend(wrappedKey: string) {
  return `${wrappedKey} - `
}

export function formatAppend(wrappedKey: string) {
  return ` - ${wrappedKey}`
}

export function stripVariableKeyFromText(text: string, wrappedKey: string) {
  return text
    .replaceAll(`${formatPrepend(wrappedKey)}`, '')
    .replaceAll(`${formatAppend(wrappedKey)}`, '')
    .replaceAll(` ${wrappedKey} `, ' ')
    .replaceAll(wrappedKey, '')
    .trim()
}

export function stripVariableKeysFromTaskDefinition<
  T extends TaskDefinitionSchema | undefined,
>(task: T, variableKeys: string[]): T {
  if (task == null) return undefined as T

  let name = task.name

  variableKeys.forEach((key) => {
    name = stripVariableKeyFromText(name, wrapVariableInDelimiters(key))
  })

  return {
    ...task,
    name,
  }
}
