import { type Section } from '@motion/ui/base'
import { byProperty, Compare, ordered } from '@motion/utils/array'

import { useMemo } from 'react'

import { SORT_NONE } from './utils'

import {
  type FieldDefinition,
  PROJECT_FIELD_LIST,
  TASK_FIELD_LIST,
  useCustomFieldBaseDefinitions,
} from '../../fields'
import { usePageData } from '../../routes/hooks'

export function useSortSections(): Section<FieldDefinition<any>>[] {
  const customFieldDefinitions = useCustomFieldBaseDefinitions()

  const route = usePageData()

  const target = route.type

  return useMemo(() => {
    const fieldItems =
      target === 'projects'
        ? [
            SORT_NONE,
            ...PROJECT_FIELD_LIST.filter(
              (f) =>
                route.predicates.projects.fields(f) &&
                (f.id === SORT_NONE.id || f.sortFn != null)
            ),
          ].sort(byProperty('name', ordered(['None'], Compare.string)))
        : [
            SORT_NONE,
            ...TASK_FIELD_LIST.filter(
              (f) =>
                route.predicates.tasks.fields(f) &&
                (f.id === SORT_NONE.id || f.sortFn != null)
            ),
          ].sort(byProperty('name', ordered(['None'], Compare.string)))

    return [
      {
        key: 'fields',
        label: 'Fields',
        initialExpanded: true,
        items: fieldItems,
      },
      {
        key: 'custom-fields',
        label: 'Custom fields',
        initialExpanded: true,
        items: customFieldDefinitions.sort(byProperty('name', Compare.string)),
      },
    ].filter(Boolean)
  }, [
    target,
    customFieldDefinitions,
    route.predicates.projects,
    route.predicates.tasks,
  ])
}
