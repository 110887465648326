import { type UploadedFileSchema } from '@motion/rpc-types'

import { useCallback } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

export function useAttachmentUrl() {
  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const getAttachmentUrl = useCallback(
    (attachmentId: UploadedFileSchema['id']) => {
      searchParams.set('attachment', attachmentId)
      return `${location.pathname}?${searchParams.toString()}`
    },
    [location, searchParams]
  )

  const navigateToAttachment = useCallback(
    (attachmentId: UploadedFileSchema['id']) => {
      setSearchParams((prev) => {
        prev.set('attachment', attachmentId)
        return prev
      })
    },
    [setSearchParams]
  )
  return {
    getAttachmentUrl,
    navigateToAttachment,
  }
}
