import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectSchema,
  type RecurringTaskSchema,
  type ScheduledEntitySchema,
  type ScheduledEventSchema,
  type ScheduledTaskChunkSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { type ScopeContext } from '@sentry/types'
import {
  type AllowableModelCacheKeys,
  type LookupFn,
  type LookupFnT,
} from '~/global/cache'

import {
  type ScheduledEventWithRelation,
  type ScheduledNormalTaskWithRelation,
  type ScheduledTaskChunkWithRelation,
} from './scheduled-entity'
import {
  type ProjectWithRelations,
  type RecurringTaskWithRelations,
  type TaskWithRelations,
} from './types'

type LookupDef<T> = {
  key: AllowableModelCacheKeys
  accessor: (task: T) => string | string[] | null | undefined
}

type CustomProxy<T, R = any> = {
  accessor: (task: T) => any
  get(target: T, value: any, lookup: LookupFn): R
}

export type ProxyDef<T> = LookupDef<T> | CustomProxy<T>

export type ApplyRelations<
  TBase,
  TRelations extends Record<string, ProxyDef<TBase>>,
> = TBase & {
  [TKey in keyof TRelations]: TRelations[TKey] extends LookupDef<any>
    ? LookupFnT<TRelations[TKey]['key']>
    : any
}

const EMPTY = {}

const IS_PROXY = Symbol('is-proxy')
const BASE_VALUE = Symbol('base-value')

export function isProxied(item: ProjectSchema): item is ProjectWithRelations
export function isProxied(item: TaskSchema): item is TaskWithRelations
export function isProxied(
  item: RecurringTaskSchema
): item is RecurringTaskWithRelations

export function isProxied(
  item: ScheduledEntitySchema
): item is ScheduledNormalTaskWithRelation
export function isProxied(
  item: ScheduledTaskChunkSchema
): item is ScheduledTaskChunkWithRelation
export function isProxied(
  item: ScheduledEventSchema
): item is ScheduledEventWithRelation

export function isProxied(item: object): boolean
export function isProxied(item: object): boolean {
  return IS_PROXY in item
}

export function getProxyTarget(item: ProjectWithRelations): ProjectSchema
export function getProxyTarget(item: TaskWithRelations): TaskSchema
export function getProxyTarget(
  item: RecurringTaskWithRelations
): RecurringTaskSchema
export function getProxyTarget(item: object) {
  if (BASE_VALUE in item) return item[BASE_VALUE]
  return item
}

const proxyMap = new WeakMap()

export function getOrCreateLookupProxy<
  TBase extends object,
  TRelations extends Record<string | symbol, ProxyDef<TBase>>,
  TFinal extends TBase = ApplyRelations<TBase, TRelations>,
>(item: TBase, relations: TRelations, lookup: LookupFn) {
  const proxy = proxyMap.get(item)
  if (proxy != null) {
    return proxy
  }

  const createdProxy = createLookupProxy(item, relations, lookup) as TFinal
  proxyMap.set(item, createdProxy)
  return createdProxy
}

export function createLookupProxy<
  TBase extends object,
  TRelations extends Record<string | symbol, ProxyDef<TBase>>,
  TFinal extends TBase = ApplyRelations<TBase, TRelations>,
>(item: TBase, relations: TRelations, lookup: LookupFn) {
  return new Proxy(item, {
    has(target, prop) {
      if (prop === IS_PROXY) return true
      if (prop === BASE_VALUE) return true
      if (prop in relations) return true
      return Reflect.has(target, prop)
    },
    ownKeys(target) {
      const targetKeys = Reflect.ownKeys(target)
      const relationKeys = Reflect.ownKeys(relations)
      const keys = [...targetKeys, ...relationKeys]

      return Array.from(new Set(keys))
    },
    get(target, prop, receiver) {
      if (prop === IS_PROXY) return true
      if (prop === BASE_VALUE) return item

      if (Reflect.has(EMPTY, prop)) {
        return Reflect.get(target, prop, receiver)
      }

      const lookupField = relations[prop]
      if (lookupField == null) {
        return Reflect.get(target, prop, receiver)
      }

      const accessor = lookupField.accessor
      const id = accessor(target)

      if (isSimpleLookup(lookupField)) {
        if (!id) return null
        if (Array.isArray(id)) {
          return filterFalsyAndReport(
            id.map((x) => lookup(lookupField.key, x)),
            {
              data: {
                extra: {
                  ids: id,
                  type: lookupField.key,
                },
              },
            }
          )
        }

        return lookup(lookupField.key, id)
      }

      if ('get' in lookupField) {
        return lookupField.get(target, id, lookup)
      }
      return Reflect.get(target, prop, receiver)
    },
  }) as TFinal
}

function isSimpleLookup<T>(item: ProxyDef<T>): item is LookupDef<T> {
  return 'key' in item && 'accessor' in item
}

export function filterFalsyAndReport<T>(
  values: (T | null | undefined)[],
  logMeta: {
    errorMessage?: string
    data?: Partial<ScopeContext>
  } = {}
): T[] {
  const { errorMessage = 'Undefined value in model cache', data } = logMeta
  const type = data?.extra?.type?.toString() ?? 'unknown'
  const message = type ? `[${type}] ${errorMessage}` : errorMessage

  if (values.some((v) => !v)) {
    Sentry.captureException(message, {
      level: 'warning',
      ...data,
      fingerprint: [
        'create-lookup-proxy',
        'undefined-value-in-model-cache',
        type,
      ],
    })
  }

  return values.filter(Boolean)
}
