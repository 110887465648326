import { usePrevious } from '@motion/react-core/hooks'
import {
  getProjectChangedFields,
  isProjectChangedFieldName,
  type ProjectFormFields,
} from '@motion/ui-logic/pm/project'
import { keys } from '@motion/utils/object'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useAutosaveContext } from '~/areas/task-project/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { useTrackEntityOpen } from '~/global/hooks/use-track-entity-open'
import { useSearchParams } from '~/routing'
import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useInitialFormData, useProjectForm } from './hooks'
import {
  convertFormFieldsToProject,
  type ProjectUrlSearchParams,
} from './utils'

export type ProjectFormProps = {
  children: ReactNode
}

export const ProjectForm = ({ children }: ProjectFormProps) => {
  const initialFormData = useInitialFormData()
  const { project: projectUrlParam } = useSearchParams<ProjectUrlSearchParams>()
  const { resetLastSavedTime } = useAutosaveContext()

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const { isDirty } = form.formState

  useTrackEntityOpen({ id: initialFormData.id, type: 'PROJECT' })

  const previousProjectUrlParam = usePrevious(projectUrlParam)

  useEffect(() => {
    // the url param doesn't exist when closing the modal,
    // so let's keep the previous known state during the full closing animation
    if (projectUrlParam == null) return

    if (!isDirty || previousProjectUrlParam !== projectUrlParam) {
      form.reset(initialFormData)
    }
  }, [form, initialFormData, isDirty, previousProjectUrlParam, projectUrlParam])

  useEffect(() => {
    if (previousProjectUrlParam !== projectUrlParam) {
      resetLastSavedTime()
    }
  }, [previousProjectUrlParam, projectUrlParam, resetLastSavedTime])

  return (
    <FormProvider {...form}>
      <ProjectFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

const ProjectFormValuesUpdater = () => {
  const { form } = useProjectForm()
  const { getWorkspaceStatuses, getWorkspaceActiveMembers } = useWorkspaceFns()
  const { getCustomFields } = useCustomFieldsFns()

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((formValues, { name }) => {
      if (name == null) return

      if (isProjectChangedFieldName(name)) {
        const projectCandidate = convertFormFieldsToProject(
          formValues as ProjectFormFields
        )
        const wid = formValues.workspaceId
        if (wid == null) {
          throw new Error('Workspace id must be defined')
        }
        const changedFields = getProjectChangedFields(projectCandidate, {
          fieldNameBeingUpdated: name,
          statuses: getWorkspaceStatuses(wid),
          members: getWorkspaceActiveMembers(wid),
          customFields: getCustomFields(wid),
        })
        keys(changedFields).forEach((key) => {
          if (!(key in formValues)) {
            throw new Error(`cannot set value of unknown key "${key}" in form`)
          }
          return form.setValue(
            key as keyof ProjectFormFields,
            changedFields[key] as any,
            {
              shouldDirty: true,
            }
          )
        })
      }
    })
    return () => subscription.unsubscribe()
  }, [form, getWorkspaceActiveMembers, getCustomFields, getWorkspaceStatuses])

  return null
}
