import { isEnabledStage } from '@motion/ui-logic/pm/project'

import { usePlannerProps } from '~/areas/project-management/pages/pm-v3/planner/context'
import { useMemo } from 'react'

import {
  type ConnectedResizeableProjectStageItemProps,
  type ResizeStageDetails,
} from '../types'
import { getStageLocation } from '../utils'

export const useStageLocation = (
  args: ConnectedResizeableProjectStageItemProps & {
    stageDetails?: ResizeStageDetails
  }
) => {
  const [plannerProps] = usePlannerProps()

  const parsedProject = useMemo(() => {
    return {
      ...args.project,
      stages: args.project.stages.filter(isEnabledStage),
    }
  }, [args.project])

  return useMemo(() => {
    return getStageLocation({
      ...args,
      project: parsedProject,
      dayPx: plannerProps.dayPx,
      shiftModifier: plannerProps.shiftModifier,
    })
  }, [args, plannerProps.dayPx, plannerProps.shiftModifier, parsedProject])
}
