import {
  RTEBoldSolid,
  RTECheckListSolid,
  RTECodeblockOutline,
  RTEH1Solid,
  RTEH2Solid,
  RTEImageSolid,
  RTEItalicizedSolid,
  RTELinkSolid,
  RTEOrderedListSolid,
  RTEUnderlineSolid,
  RTEUnorderedListSolid,
} from '@motion/icons'
import { ButtonGroup, IconButton, type IconButtonProps } from '@motion/ui/base'

import { type Editor } from '@tiptap/react'
import { useRef } from 'react'

import { useTemplateTaskForm } from '../hooks'
import { type TemplateTaskFormActions } from '../template-task-form-handlers'

export type RichTextToolbarProps = {
  editor: Editor | null
  onFileUpload?: TemplateTaskFormActions['uploadImage']
  onLinkClick: TemplateTaskFormActions['openLinkModalPrompt']
}

export const RichTextToolbar = (props: RichTextToolbarProps) => {
  const { editor, onFileUpload, onLinkClick } = props

  const { disabledFields } = useTemplateTaskForm()

  const isDisabled = disabledFields.has('description')

  const fileInputRef = useRef<HTMLInputElement>(null)

  if (!editor) {
    return null
  }

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files?.[0]

    if (!editor || !onFileUpload || !file) return

    const url = await onFileUpload?.(file)

    if (url != null) {
      editor.chain().focus().setImage({ src: url, title: file.name }).run()
    }
  }

  const handleClickLink = async () => {
    if (!editor) return

    const previousUrl = editor.getAttributes('link').href

    const url = await onLinkClick(previousUrl)

    if (url != null) {
      if (url === '') {
        // Remove link
        editor.chain().focus().extendMarkRange('link').unsetLink().run()
        return
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: !url.includes('://') ? `https://${url}` : url })
        .run()
    }
  }

  return (
    <div className='flex gap-2'>
      <ButtonGroup segmented>
        <ToolbarButton
          icon={RTEBoldSolid}
          pressed={editor.isActive('bold')}
          disabled={isDisabled}
          onClick={() => editor.chain().focus().toggleBold().run()}
        />
        <ToolbarButton
          icon={RTEItalicizedSolid}
          pressed={editor.isActive('italic')}
          disabled={isDisabled}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
        <ToolbarButton
          icon={RTEUnderlineSolid}
          pressed={editor.isActive('underline')}
          disabled={isDisabled}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        />
      </ButtonGroup>
      <ButtonGroup segmented>
        <ToolbarButton
          icon={RTEH1Solid}
          pressed={editor.isActive('heading', { level: 1 })}
          disabled={isDisabled}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        />
        <ToolbarButton
          icon={RTEH2Solid}
          pressed={editor.isActive('heading', { level: 2 })}
          disabled={isDisabled}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        />
      </ButtonGroup>
      <ButtonGroup segmented>
        <ToolbarButton
          icon={RTEUnorderedListSolid}
          pressed={editor.isActive('bulletList')}
          disabled={isDisabled}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
        <ToolbarButton
          icon={RTEOrderedListSolid}
          pressed={editor.isActive('orderedList')}
          disabled={isDisabled}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
        <ToolbarButton
          icon={RTECheckListSolid}
          pressed={editor.isActive('taskList')}
          disabled={isDisabled}
          onClick={() => editor.chain().focus().toggleTaskList().run()}
        />
      </ButtonGroup>
      <ButtonGroup segmented>
        <ToolbarButton
          icon={RTEImageSolid}
          disabled={isDisabled}
          onClick={() => fileInputRef?.current?.click()}
        />
        <ToolbarButton
          icon={RTECodeblockOutline}
          pressed={editor.isActive('code')}
          disabled={isDisabled}
          onClick={() => editor.chain().focus().toggleCode().run()}
        />
        <ToolbarButton
          icon={RTELinkSolid}
          pressed={editor.isActive('link')}
          disabled={isDisabled}
          onClick={handleClickLink}
        />
      </ButtonGroup>

      <input
        className='hidden'
        ref={fileInputRef}
        type='file'
        onChange={handleChangeFile}
      />
    </div>
  )
}

type ToolbarButtonProps = {
  icon: IconButtonProps['icon']
  pressed?: boolean
  disabled?: boolean
  onClick: IconButtonProps['onClick']
}

function ToolbarButton({ icon, ...rest }: ToolbarButtonProps) {
  return (
    <IconButton icon={icon} variant='outlined' sentiment='neutral' {...rest} />
  )
}
