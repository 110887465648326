import { type ProjectFormFields } from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'

import { useRedirectToProject } from '~/areas/project/hooks'
import { showErrorToast } from '~/global/toasts'

import { useProjectForm } from './use-project-form'
import { useSaveProject } from './use-save-project'

export function useSubmitForm() {
  const saveProject = useSaveProject()
  const redirectToProject = useRedirectToProject()
  const { form } = useProjectForm()
  const { watch } = form

  const projectId = watch('id')

  return async (data: ProjectFormFields) => {
    try {
      if (!form.formState.isDirty) return

      const response = await saveProject(data)
      if (response == null) {
        return
      }

      // reset dirty state when successful
      if (projectId != null) {
        form.reset({}, { keepValues: true })
      }

      redirectToProject(response.id)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'useSubmitForm',
        },
      })

      showErrorToast(e)
    }
  }
}
