import { usePrevious } from '@motion/react-core/hooks'
import { type TaskDefinitionFormFields } from '@motion/ui-logic/pm/project'
import {
  convertFormFieldsToTask,
  getTaskFormChangedFields,
  isChangedFieldName,
} from '@motion/ui-logic/pm/task'
import { keys } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { type ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useInitialFormData } from './hooks'
import { useTaskDefinitionForm } from './use-task-definition-form'

export type TaskFormProps = {
  children: ReactNode
}

export const TaskDefinitionForm = ({ children }: TaskFormProps) => {
  const initialFormData = useInitialFormData()

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const { reset } = form

  const taskId = initialFormData.id
  const prevTaskId = usePrevious(taskId)
  useEffect(() => {
    if (prevTaskId && prevTaskId !== taskId) {
      reset(initialFormData)
    }
  }, [initialFormData, prevTaskId, reset, taskId])

  return (
    <FormProvider {...form}>
      <TaskFormValuesUpdater />
      {children}
    </FormProvider>
  )
}

const TaskFormValuesUpdater = () => {
  const { form } = useTaskDefinitionForm()
  const { uid: currentUserId } = useAuthenticatedUser()
  const {
    getWorkspaceStatuses,
    getWorkspaceActiveMembers,
    getWorkspaceProjects,
  } = useWorkspaceFns()
  const { getCustomFields } = useCustomFieldsFns()

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((formValues, { name }) => {
      if (name == null) return

      if (isChangedFieldName(name)) {
        const taskCandidate = convertFormFieldsToTask(
          formValues as TaskDefinitionFormFields
        )
        const wid = formValues.workspaceId
        if (wid == null) {
          throw new Error('Workspace id must be defined')
        }

        const changedFields = getTaskFormChangedFields(taskCandidate, {
          fieldNameBeingUpdated: name,
          currentUserId,
          statuses: getWorkspaceStatuses(wid),
          projects: getWorkspaceProjects(wid),
          members: getWorkspaceActiveMembers(wid),
          customFields: getCustomFields(wid),
        })

        keys(changedFields).forEach((key) => {
          if (!(key in formValues)) {
            throw new Error(`cannot set value of unknown key "${key}" in form`)
          }

          return form.setValue(key, changedFields[key]!, {
            shouldDirty: true,
          })
        })
      }
    })
    return () => subscription.unsubscribe()
  }, [
    currentUserId,
    form,
    getWorkspaceActiveMembers,
    getCustomFields,
    getWorkspaceProjects,
    getWorkspaceStatuses,
  ])

  return null
}
