import { getTimeZoneShortName } from '@motion/utils/dates'
import { time } from '@motion/utils/debug'

import type { RawTimeZone } from '@vvo/tzdb'
import { useMemo, useState } from 'react'

import { favoriteTimezones, favoriteTimezonesList } from './constants'
import { type TimezoneType, type TimezoneWithContinent } from './types'

let timezones: TimezoneType[] | undefined
const EMPTY: TimezoneType[] = []

let timezonePromise: Promise<TimezoneType[]> | undefined

/**
 * Gets the list of timezones using `@vvo/tzdb`.
 *
 * @returns The list of timezones
 */
export const useTimezones = () => {
  // This is done as a useState to allow for subsequent requests to return the cached list
  // without requiring a re-render
  const [zones, setZones] = useState(() => {
    if (timezones) return timezones
    if (timezonePromise) {
      timezonePromise.then((data) => setZones(data)).catch(() => {})
      return EMPTY
    }

    timezonePromise = import('@vvo/tzdb')
      .then((data) => {
        timezones = time('tz.process-timezones', () =>
          data.getTimeZones().map((zone) => formatTimezone(zone))
        )
        return timezones
      })
      .catch((ex) => {
        timezonePromise = undefined
        return (timezones = EMPTY)
      })
    void timezonePromise.then((data) => setZones(data))
    return EMPTY
  })

  return zones
}

function formatTimezone(tz: RawTimeZone): TimezoneType {
  const returnObj = {
    ...tz,
    otherAbbreviations: [] as string[],
    formattedName: tz.name.replace(/_/g, ' '),
    favorite: favoriteTimezonesList.includes(tz.name),
    abbreviation: getTimeZoneShortName(tz.name, Date.now()),
  }

  if (tz.name === 'America/Los_Angeles') {
    returnObj.otherAbbreviations = ['PST', 'PT']
  } else if (tz.name === 'America/New_York') {
    returnObj.otherAbbreviations = ['EST', 'ET']
  } else if (tz.name === 'America/Chicago') {
    returnObj.otherAbbreviations = ['CST', 'CT']
  }

  return returnObj
}

export const useTimezoneDB = () => {
  const timezones = useTimezones()

  return useMemo(() => buildTimezoneDB(timezones), [timezones])
}

export const useFavoriteTimezones = () => {
  const timezones = useTimezones()
  return useMemo(() => {
    return favoriteTimezones.map((group) => ({
      continent: group.continent,
      timezones: group.timezones
        .map((timezone) => timezones.find((tz) => tz.name === timezone))
        .filter(Boolean),
    }))
  }, [timezones])
}

const buildTimezoneDB = (
  allTimezones: TimezoneType[]
): TimezoneWithContinent[] => {
  return [
    'North America',
    'Europe',
    'Asia',
    'South America',
    'Africa',
    'Oceania',
  ].map((continentName) => ({
    continent: continentName,
    timezones: allTimezones
      .filter((tz) => tz.continentName === continentName)
      .sort((a, b) => b.mainCities.length - a.mainCities.length)
      .map((tz) => ({
        ...tz,
        formattedName: tz.name.replace(/_/g, ' '),
      })),
  }))
}
