import { UserAddSolid } from '@motion/icons'

import { type Contact } from '../../../../state/TeamTypes'
import { Paragraph, SecondaryButton, SubParagraph } from '../../../Common'

interface RecommendationProps {
  contact: Contact
  onClick: (contact: Contact) => void
}

const Recommendation = ({ contact, onClick }: RecommendationProps) => {
  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-col justify-center'>
        <Paragraph className='text-sm font-medium'>{contact.name}</Paragraph>
        <SubParagraph>{contact.email}</SubParagraph>
      </div>
      <SecondaryButton
        size='small'
        onClick={() => onClick(contact)}
        icon={UserAddSolid}
      />
    </div>
  )
}

export interface RecommendationsProps {
  recommendations: Contact[]
  onClick: (contact: Contact) => void
}

export const Recommendations = ({
  recommendations,
  onClick,
}: RecommendationsProps) => {
  return (
    <div className='dark:border-l-dark-700 border-l-light-400 flex h-full w-[250px] flex-col gap-4  border-l pl-6'>
      <SubParagraph className='my-3 mx-0 text-xs font-semibold'>
        Recommendations
      </SubParagraph>
      {recommendations.map((rec) => (
        <Recommendation key={rec.email} contact={rec} onClick={onClick} />
      ))}
    </div>
  )
}
