import { useExperiment } from '@motion/web-common/flags'

import { type ReactNode } from 'react'

import { MaintenanceModePageV2 } from './maintenance-mode-page-v2'

type MaintenanceModeGuardProps = {
  children: ReactNode
}

export const MaintenanceModeGuard = (props: MaintenanceModeGuardProps) => {
  const newMaintenanceMode = useExperiment('new_maintenance_mode')

  if (newMaintenanceMode?.value === 'on') {
    return (
      <MaintenanceModePageV2
        title={newMaintenanceMode.payload?.title}
        subtitle={newMaintenanceMode.payload?.subtitle}
      />
    )
  }

  return props.children
}
