import {
  type LoaderFunction,
  type LoaderFunctionArgs,
  redirect as baseRedirect,
} from 'react-router'
import { createPath, parsePath, resolvePath } from 'react-router-dom'

import { log } from '../log'

export const loggedRedirect = (args: LoaderFunctionArgs, targetUri: string) => {
  log.info('redirect', `${args.request.url} -> ${targetUri}`)
  return baseRedirect(targetUri)
}

export const redirectLoader =
  (target: string): LoaderFunction =>
  (args) => {
    const original = parsePath(args.request.url)
    const resolved = resolvePath(target, original.pathname)
    const targetUri = createPath({ ...original, pathname: resolved.pathname })

    return loggedRedirect(args, targetUri)
  }

type RedirectOptions = {
  removeView?: boolean
}
export const relativeRedirect = (
  args: LoaderFunctionArgs,
  target: string,
  opts: RedirectOptions = { removeView: false }
) => {
  const original = new URL(args.request.url)
  const resolved = resolvePath(target, original.pathname)

  const search = original.searchParams
  if (opts.removeView) {
    search.delete('view')
  }

  const targetUri = createPath({
    ...original,
    pathname: resolved.pathname,
    search: search.toString(),
  })

  return loggedRedirect(args, targetUri)
}
