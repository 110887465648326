import { ExclamationCircleSolid, RefreshSolid, TrashSolid } from '@motion/icons'
import { type Calendar } from '@motion/rpc/types'
import { ActionList, PopoverTrigger } from '@motion/ui/base'
import { type CalendarSchema } from '@motion/zod/client'

import { updateCalendarList } from '~/state/calendar/calendarThunks'
import { useAppDispatch } from '~/state/hooks'

import { useCalendarActions } from '../../hooks'

export function CalendarNotFoundWarning({
  calendar,
}: {
  calendar: Calendar | CalendarSchema
}) {
  const dispatch = useAppDispatch()
  const { removeCalendar } = useCalendarActions()

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ActionList
          sections={[
            {
              title: 'Calendar not found',
              items: [
                {
                  content: 'Remove calendar',
                  prefix: <TrashSolid />,
                  onAction: () => {
                    removeCalendar(calendar)
                    close()
                  },
                },
                {
                  content: 'Refresh calendar list',
                  prefix: <RefreshSolid />,
                  onAction: () => {
                    void dispatch(
                      updateCalendarList({ updateFrequentlyMet: true })
                    )
                    close()
                  },
                },
              ],
            },
          ]}
        />
      )}
    >
      <ExclamationCircleSolid className='size-3 text-semantic-error-bg-strong-default cursor-pointer shrink-0' />
    </PopoverTrigger>
  )
}
