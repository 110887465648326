import { isObjectNoneId } from '@motion/shared/identifiers'
import type { ProjectSchema, StageSchema } from '@motion/zod/client'

import { useStageDefinition, useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

/**
 * Because we're grouping by name to display the stage rows,
 * we need to find the correct stage by name for the given stage definition id and project.
 */
export const useProjectStageByProjectAndDefinitionId = (
  project: ProjectSchema | undefined,
  stageDefinitionId: string
): StageSchema | undefined => {
  const { getStageDefinition } = useWorkspaceFns()
  const stageDef = useStageDefinition(stageDefinitionId)
  if (isObjectNoneId(stageDef) || !project) return undefined

  const stageName = stageDef.name
  if (!stageName) return undefined

  const correctStage = project.stages.find(
    (s) => getStageDefinition(s.stageDefinitionId)?.name === stageName
  )

  return correctStage
}

export const useProjectStageByProjectAndDefinitionIdFn = (): ((
  project: ProjectSchema | null,
  stageDefinitionId: string | undefined
) => StageSchema | undefined) => {
  const { getStageDefinition } = useWorkspaceFns()

  return useCallback(
    (project, stageDefinitionId) => {
      if (!stageDefinitionId) return undefined

      const stageName = getStageDefinition(stageDefinitionId).name
      if (!project) return undefined

      const correctStage = project.stages.find(
        (s) => getStageDefinition(s.stageDefinitionId).name === stageName
      )

      return correctStage
    },
    [getStageDefinition]
  )
}
