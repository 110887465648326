import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import { Compare } from '@motion/utils/array'
import { stripEmojis } from '@motion/utils/string'

import {
  isDateValue,
  isMultiSelectField,
  isMultiSelectValue,
  isNumberValue,
  isSelectField,
  isSelectValue,
  isTextValue,
  isUrlValue,
} from '~/areas/project-management/custom-fields'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

export function hasCustomFieldAndValue(
  item: TaskWithRelations | ProjectWithRelations,
  customFields: AllAvailableCustomFieldSchema[]
): 'hasValue' | 'noValue' | 'noField' {
  if (
    item.type !== 'NORMAL' ||
    item.customFieldValues == null ||
    !customFields.some(({ id }) => item.workspace.customFieldIds.includes(id))
  ) {
    return 'noField'
  }

  return customFields.some((field) => {
    const matchingValue = item.customFieldValues?.[field.id] ?? null

    if (matchingValue) {
      if (isMultiSelectValue(matchingValue) && isMultiSelectField(field)) {
        return (
          matchingValue.value != null &&
          matchingValue.value.length > 0 &&
          field.metadata.options.some((option) =>
            matchingValue.value?.includes(option.id)
          )
        )
      }

      return matchingValue.value != null
    }
  })
    ? 'hasValue'
    : 'noValue'
}

export function findFirstMatchingValueInCustomFieldValues(
  item: TaskWithRelations | ProjectWithRelations,
  customFields: AllAvailableCustomFieldSchema[]
): string | null {
  if (item.type !== 'NORMAL' || item.customFieldValues == null) {
    return null
  }

  // Return first matching value that matches the id of the custom field
  for (const field of customFields) {
    const matchingValue = item.customFieldValues[field.id]
    if (matchingValue) {
      if (isMultiSelectField(field) && isMultiSelectValue(matchingValue)) {
        const { value: optionIds } = matchingValue
        const {
          metadata: { options },
        } = field

        // Get all the values, sort them, and return the first one alphabetically
        const matchingOption = optionIds
          ?.map((id) => options.find((option) => option.id === id)?.value)
          .sort(Compare.caseInsensitive)?.[0]

        return matchingOption ? stripEmojis(matchingOption) : null
      }

      if (isSelectField(field) && isSelectValue(matchingValue)) {
        const {
          metadata: { options },
        } = field

        const matchingOption = options.find(
          (option) => option.id === matchingValue.value
        )

        return matchingOption ? matchingOption.value : null
      }

      if (isTextValue(matchingValue) || isUrlValue(matchingValue)) {
        return matchingValue.value ? stripEmojis(matchingValue.value) : null
      }

      if (isDateValue(matchingValue)) {
        return matchingValue.value ? matchingValue.value : null
      }

      if (isNumberValue(matchingValue)) {
        return matchingValue.value != null
          ? matchingValue.value.toString()
          : null
      }
    }
  }

  return null
}
