import { priorityLabels } from '@motion/ui-logic'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { type BadgeSize, PriorityBadge } from '~/global/components/badges'

export type PriorityLabelProps = {
  value: PriorityLevelSchema
  size?: BadgeSize
}

export const PriorityLabel = (props: PriorityLabelProps) => {
  return (
    <div className='flex gap-1 items-center leading-4'>
      <PriorityBadge value={props.value} size={props.size} hideTooltip />
      {priorityLabels.get(props.value)}
    </div>
  )
}
