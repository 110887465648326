import { type RecurringTaskSchema, type TaskSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { canBeArchived } from '@motion/ui-logic/pm/task'
import { Sentry } from '@motion/web-base/sentry'

import { useReadTaskFn } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useTaskUpdater } from '../updaters'

export const useArchiveTask = () => {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (taskOrId: TaskSchema['id'] | TaskSchema | RecurringTaskSchema) => {
      try {
        const task =
          typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

        if (task == null || !canBeArchived(task)) {
          throw new Error('This task cannot be archived', { cause: { task } })
        }

        await updateTask(task, {
          archivedTime: new Date().toISOString(),
        })

        showToast('success', 'Task archived')
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useArchiveTask',
          },
        })
        showErrorToast(e)
      }
    },
    [readTask, updateTask]
  )
}
