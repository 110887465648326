import { RteImage } from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'

import parse, {
  attributesToProps,
  type DOMNode,
  domToReact,
  type Element,
} from 'html-react-parser'
import { type ComponentProps } from 'react'
import { Link } from 'react-router-dom'

// Context: https://usemotion.slack.com/archives/CKP2K3JBS/p1723768422495039?thread_ts=1723768370.681569&cid=CKP2K3JBS
export function parseCommentHtml(comment?: string) {
  if (!comment) return null

  const { isElectron } = getWindowData()

  return parse(comment, {
    replace: (domNode) => {
      const node = domNode as Element

      if (!node.attribs) return

      if (node.tagName === 'img') {
        const { src, alt, ...rest } = node.attribs

        if (!src) return

        if (src.startsWith('https://api.dev.beta.usemotion.com')) {
          node.attribs.src = src.replace(
            'https://api.dev.beta.usemotion.com',
            'https://dev-internal.usemotion.com'
          )
        } else if (src.startsWith('https://api.dev.usemotion.com')) {
          node.attribs.src = src.replace(
            'https://api.dev.usemotion.com',
            'https://dev-internal.usemotion.com'
          )
        } else if (src.startsWith('https://api.beta.usemotion.com')) {
          node.attribs.src = src.replace(
            'https://api.beta.usemotion.com',
            'https://internal.usemotion.com'
          )
        } else if (src.startsWith('https://api.usemotion.com')) {
          node.attribs.src = src.replace(
            'https://api.usemotion.com',
            'https://internal.usemotion.com'
          )
        }

        const { style, ...props } = attributesToProps(rest)
        const typedStyle = style as ComponentProps<'img'>['style']

        return (
          <RteImage
            {...props}
            src={src}
            alt={alt}
            float={typedStyle?.float}
            width={typedStyle?.width}
            onExpand={() => {
              recordAnalyticsEvent('IMAGE_EXPAND_CLICK')
            }}
          />
        )
      }

      if (node.tagName === 'a' && node.attribs.href) {
        const isInternal = node.attribs.href.includes(__FRONTEND_HOST__)

        if (isInternal && isElectron) {
          const { href, test, ...props } = attributesToProps(node.attribs)

          // The use of type assertion: https://www.npmjs.com/package/html-react-parser#migration
          return (
            <Link to={String(href)}>
              <a {...props}>{domToReact(node.children as DOMNode[])}</a>
            </Link>
          )
        }

        return (
          <a {...attributesToProps(node.attribs)} target='_blank'>
            {domToReact(node.children as DOMNode[])}
          </a>
        )
      }

      if (node.tagName === 'p' && node.children.length === 0) {
        return (
          <p {...attributesToProps(node.attribs)}>
            <br />
          </p>
        )
      }
    },
  })
}
