import { Portal } from '@motion/ui/base'

// eslint-disable-next-line no-restricted-imports
import { Popover as PopoverRoot, Transition } from '@headlessui/react'
import React, { type ElementType, type ReactElement, useState } from 'react'
import { type PopperChildrenProps, usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'

import { dropdownContainerColorClasses } from '../GeneralComponentStyles'

type PopoverProps = {
  children: any
  trigger: ReactElement<any, any>
  triggerClasses?: string
  dropdownClasses?: string
  className?: string
  placement?: PopperChildrenProps['placement']
  readOnly?: boolean
  noPortal?: boolean
  noAbsolute?: boolean
  as?: ElementType<any>
}

/**
 * @deprecated use `PopoverTrigger` from Motion UI
 */
export const Popover = ({
  children,
  trigger,
  triggerClasses = '',
  dropdownClasses = '',
  className = '',
  placement = 'bottom-end',
  readOnly = false,
  noPortal = false,
  noAbsolute = false,
  as = 'button',
}: PopoverProps) => {
  const [referenceRef, setReferenceRef] = useState<HTMLButtonElement | null>(
    null
  )
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceRef, popperRef, {
    placement,
    strategy: noPortal && !noAbsolute ? 'absolute' : 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'left'],
        },
      },
    ],
  })

  const popOverElement =
    document.getElementById('motion-newtab') || document.body

  const popperStyle = {
    ...(!noPortal ? styles.popper : {}),
    opacity: popperRef ? 1 : 0,
  }

  const renderPopoverPanel = (
    style: React.CSSProperties,
    attributes: { [key: string]: string } | undefined = {},
    className = ''
  ) => {
    if (referenceRef == null) return null

    if (style.transform == null) {
      style.opacity = 0
    }

    return (
      <PopoverRoot.Panel
        ref={setPopperRef}
        style={style}
        {...attributes}
        className='!z-20'
      >
        {({ close, open }) => {
          const originalChildren =
            typeof children === 'function'
              ? children({ close, open })
              : children
          if (!originalChildren) return <></>
          return (
            <div
              className={twMerge(
                'rounded border border-solid shadow-lg',
                dropdownContainerColorClasses,
                className
              )}
            >
              {React.cloneElement(originalChildren)}
            </div>
          )
        }}
      </PopoverRoot.Panel>
    )
  }
  return (
    <PopoverRoot className={twMerge('relative flex h-full w-full', className)}>
      <PopoverRoot.Button
        as={as}
        // @ts-expect-error disabled is not in props.
        disabled={readOnly}
        ref={setReferenceRef}
        className={twMerge(
          'enabled:focus-visible:ring-dark-300 w-full focus:outline-none enabled:focus-visible:ring-2 enabled:focus-visible:ring-opacity-75 [&>*]:disabled:cursor-not-allowed [&>*]:disabled:outline-none',
          triggerClasses
        )}
      >
        {trigger}
      </PopoverRoot.Button>
      <Transition
        enter='transition-opacity ease-out duration-100'
        enterFrom='opacity-0 relative z-10'
        enterTo='opacity-100 relative z-10'
        leave='transition-opacity ease-in duration-100'
        leaveFrom='opacity-100 relative z-10'
        leaveTo='opacity-0 relative z-10'
      >
        {!noPortal && popOverElement && (
          <Portal container={popOverElement}>
            {renderPopoverPanel(
              popperStyle,
              attributes.popper,
              dropdownClasses
            )}
          </Portal>
        )}
        {noPortal &&
          (noAbsolute
            ? // If noPortal and noAbsolute are both true, then we want to render the popover panel with a fixed strategy
              renderPopoverPanel(
                { ...popperStyle, position: 'fixed', right: 0 },
                {},
                dropdownClasses
              )
            : // If no portal and absolute is allowed, then the popover panel will have an absolute strategy with portal positioning
              renderPopoverPanel(
                { ...popperStyle, ...styles.popper, zIndex: 20 },
                attributes.popper,
                dropdownClasses
              ))}
      </Transition>
    </PopoverRoot>
  )
}
