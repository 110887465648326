import { isCompletedStatus } from '@motion/shared/common'
import { isTaskPastDue as isScheduledPastDue } from '@motion/ui-logic'

import { DateTime } from 'luxon'

import { type TaskData } from '../../../context/task-context'

export function isTaskPastDue(props: TaskData): boolean {
  if (isCompletedStatus(props.status) || props.task.completedTime) {
    return false
  }
  if (!props.task.dueDate) return false

  if (props.task.isAutoScheduled) {
    return isScheduledPastDue(props.task)
  }

  return DateTime.fromISO(props.task.dueDate) < DateTime.now()
}
