import {
  createStateKey,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'

export type ShowProjectSidebarState = {
  open: boolean
}

export const ShowProjectSidebarState = createStateKey<ShowProjectSidebarState>(
  'show-project-sidebar-state',
  {
    defaultValue: {
      open: false,
    },
  }
)

export const useShowProjectSidebarState = () =>
  useSharedState(ShowProjectSidebarState, {
    scope: 'root',
  })

export const useShowProjectSidebarStateSendOnly = () =>
  useSharedStateSendOnly(ShowProjectSidebarState, {
    scope: 'root',
  })
