import { isNoneId } from '@motion/shared/identifiers'

import { BlockingTasksDropdown } from '~/areas/tasks/components/dropdowns'
import { PrettyLabels } from '~/global/components/labels'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction } from './components'

type TaskBlockingCellProps = {
  task: TaskWithRelations
}

export const TaskBlockingCell = ({ task }: TaskBlockingCellProps) => {
  const isFake = isNoneId(task.id)

  if (isFake || task.type !== 'NORMAL' || task.projectId == null) {
    return (
      <CellAction disabled isFake={isFake}>
        <span className='text-semantic-neutral-text-subtle'>None</span>
      </CellAction>
    )
  }

  const values = task.blockingTasks.filter(Boolean).map((blocker) => ({
    id: blocker.id,
    name: blocker.name,
    color: 'grey',
  }))

  return (
    <BlockingTasksDropdown task={task}>
      <CellAction disabled={task.archivedTime != null}>
        {task.blockingTaskIds.length === 0 ? (
          <span className='text-semantic-neutral-text-subtle'>None</span>
        ) : (
          <PrettyLabels values={values} />
        )}
      </CellAction>
    </BlockingTasksDropdown>
  )
}
