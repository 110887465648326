import { RechartsPieChart } from '@motion/web-charts/pie'
import {
  type ChartQueryRequestSchema,
  type ChartQueryResponseSchema,
} from '@motion/zod/client'

import { ChartQueryDataProvider } from './chart-query-data-provider'
import { type ConnectedChartProps } from './types'
import { useChartLookups } from './use-chart-lookups'

export const ConnectedPieChart = ({ chartQuery }: ConnectedChartProps) => {
  return (
    <ChartQueryDataProvider chartQuery={chartQuery}>
      {(chartQueryData) => {
        return <InnerPieChart data={chartQueryData} chartQuery={chartQuery} />
      }}
    </ChartQueryDataProvider>
  )
}

type InnerPieChartProps = {
  data: ChartQueryResponseSchema
  chartQuery: ChartQueryRequestSchema
}

export const InnerPieChart = ({ data, chartQuery }: InnerPieChartProps) => {
  const lookups = useChartLookups()

  return (
    <RechartsPieChart
      categoryKey={chartQuery.groupBy[0].field}
      data={data.data}
      labelAccessor={lookups.getLabel}
      colorAccessor={lookups.getColor}
      donut
    />
  )
}
