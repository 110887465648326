import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { CalendarPalette } from '@motion/ui/calendar'
import { formatDateRange, templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { Link } from 'react-router-dom'

import { useScrollPosition } from '../../../context'
import { useScreenValues } from '../../../hooks'
import { OOO_SUBROW_HEIGHT, ZIndexMap } from '../../../shared-constants'
import { type OOOEvent } from '../../calendar-row'
import { ItemContainer } from '../../common'

type EventWithPlannerData = OOOEvent & {
  eventStart: number
  eventEnd: number
  width: number
}
const GRAY_HUE = 1000

export function OOOEventColumn({
  event,
  isFullScreen,
  eventHeight,
}: {
  event: EventWithPlannerData
  isFullScreen?: boolean
  eventHeight?: number
}) {
  const { eventStart, title, width } = event
  const { positionOffset } = useScreenValues()
  const [scrollPosition] = useScrollPosition()

  return (
    <OOOColumn isFullScreen={isFullScreen}>
      <ItemContainer
        style={{
          left: eventStart,
          transform: `translateX(${scrollPosition + positionOffset}px)`,
          width: width,
          zIndex: ZIndexMap.oooItem,
        }}
      >
        <CalendarPalette colorHue={GRAY_HUE}>
          <div
            className='flex flex-col h-full flex-[1_0_0] self-stretch min-w-0'
            style={
              eventHeight
                ? { height: eventHeight + OOO_SUBROW_HEIGHT }
                : undefined
            }
          >
            <Tooltip
              asChild
              content={templateStr('{{title}} ({{dateRange}})', {
                title: title ?? 'Out of Office',
                dateRange: formatDateRange(event.start, event.end),
              })}
            >
              <OOOTitle
                to={`/web/calendar?initialDate=${event.start}`}
                onClick={() => {
                  recordAnalyticsEvent(
                    'PROJECT_MANAGEMENT_PLANNER_OOO_EVENT_CLICK'
                  )
                }}
              >
                OOO
              </OOOTitle>
            </Tooltip>
            <div className='h-full opacity-10 bg-calendar-palette-highlight-default' />
          </div>
        </CalendarPalette>
      </ItemContainer>
    </OOOColumn>
  )
}

const OOOColumn = classed('div', {
  base: '',
  variants: {
    isFullScreen: {
      true: `absolute inset-0`,
      false: '',
    },
  },
})

const OOOTitle = classed(Link, {
  base: `
    opacity-100 flex h-4 pl-1 flex-col justify-center items-start gap-0.5
    bg-calendar-palette-bg-default text-calendar-palette-text font-medium text-2xs
    rounded-sm truncate flex-shrink-0
  `,
})
