import { classed } from '@motion/theme'

import {
  AddTimeButton,
  StopAndChunkButton,
  StopAndCompleteButton,
} from './buttons'
import { type CurrentTaskActionProps } from './types'

export type CurrentTaskActionButtonsProps = CurrentTaskActionProps

export function CurrentTaskActionButtons({
  task,
}: CurrentTaskActionButtonsProps) {
  return (
    <CurrentTaskActionButtonsContainer>
      <AddTimeButton task={task} />
      <StopAndChunkButton task={task} />
      <StopAndCompleteButton task={task} />
    </CurrentTaskActionButtonsContainer>
  )
}

const CurrentTaskActionButtonsContainer = classed('div', {
  base: 'flex flex-row gap-1.5 flex-shrink-0 items-center justify-center',
})
