import { Banner } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import {
  selectEmailAccounts,
  selectMainEmailAccountId,
} from '~/state/email-accounts/email-accounts-slice'

import { useAppSelector } from '../../state/hooks'

export const MainCalendarWarningBanner = ({}) => {
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const mainEmailId = useAppSelector(selectMainEmailAccountId)
  const modalApi = useModalApi()
  const showBanner = emailAccounts.length > 0 && !mainEmailId

  if (!showBanner) return null

  return (
    <Banner
      actions={[
        {
          label: 'Set Main Calendar',
          onAction: () => {
            modalApi.open('link-calendars')
            recordAnalyticsEvent('LINK_CALENDARS_MODAL_OPEN', {
              source: 'main-calendar-warning-banner',
            })
          },
          sentiment: 'primary',
        },
      ]}
      sentiment='warning'
    >
      <span className='text-banner-warning-strong-text text-center text-sm font-normal'>
        You haven’t chosen a main calendar yet. Any events created in Motion
        will automatically be added to your main calendar. Tasks can also be
        synced to your main calendar.
      </span>
    </Banner>
  )
}
