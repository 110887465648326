import { useDependantState } from '@motion/react-core/hooks'
import { byProperty, ordered } from '@motion/utils/array'
import { createLookupBy, createLookupByKey } from '@motion/utils/object'
import { useHasTreatment } from '@motion/web-common/flags'

import {
  type ColumnDef,
  type ColumnOrderState,
  type ColumnPinningState,
  type ColumnSizingState,
  type Updater,
  type VisibilityState,
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useViewState, type ViewStateColumn } from '../../view-state'
import {
  DEFAULT_VIEW_TASK_COLUMNS,
  isDefaultView as isDefaultViewId,
} from '../../views'

export function useTableViewState<T>(allColumns: ColumnDef<T>[]) {
  const [viewState, setViewState] = useViewState()
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  const isDefaultView = viewState.viewId
    ? isDefaultViewId({ id: viewState.viewId })
    : false

  const definitionLookup = useMemo(
    () => createLookupByKey(allColumns, 'id'),
    [allColumns]
  )

  const columnLookup = useMemo<Record<string, ViewStateColumn | undefined>>(
    () => createLookupByKey(viewState.columns, 'id'),
    [viewState.columns]
  )

  const allColumnIds = useMemo(
    () => allColumns.map((col) => col.id).filter(Boolean),
    [allColumns]
  )

  const FILTERED_DEFAULT_COLUMNS = useMemo(() => {
    if (hasPastDueM2) {
      return DEFAULT_VIEW_TASK_COLUMNS
    }

    return DEFAULT_VIEW_TASK_COLUMNS.filter((x) => x.id !== 'deadlineStatus')
  }, [hasPastDueM2])

  const getColumnVisibility = useCallback(
    (id: string) =>
      columnLookup[id]?.visible ??
      (isDefaultView
        ? FILTERED_DEFAULT_COLUMNS.some((x) => x.id === id)
        : true),
    [FILTERED_DEFAULT_COLUMNS, columnLookup, isDefaultView]
  )

  const columns = useMemo(() => {
    const columnIds =
      viewState.columns.length === 0
        ? allColumns.map((col) => col.id).filter(Boolean)
        : viewState.columns.map((col) => col.id)

    const missingColumnIds = allColumns
      .filter((def) => def.id && !columnLookup[def.id])
      .map((x) => x.id)
      .filter(Boolean)

    // Add the missing columns based on their default indexes
    // That way they don't end up at the end of the view state column order list
    const finalColumns = missingColumnIds.reduce(
      (acc, missingId) => {
        const foundIndex = allColumns.findIndex((c) => c.id === missingId)

        if (foundIndex === -1) {
          acc.push(missingId)
        } else {
          acc.splice(foundIndex, 0, missingId)
        }

        return acc
      },
      [...columnIds]
    )

    return finalColumns.map((id) => {
      const found = columnLookup[id]

      return {
        id: id,
        pinned: found?.pinned ?? false,
        visible: getColumnVisibility(id),
        width: found?.width,
      } satisfies ViewStateColumn
    })
  }, [allColumns, columnLookup, getColumnVisibility, viewState.columns])

  const columnIds = useMemo(() => columns.map((x) => x.id), [columns])

  useEffect(() => {
    if (viewState.columns.length === 0) {
      setViewState((prev) => ({
        ...prev,
        columns: allColumnIds.map<ViewStateColumn>((id) => ({
          id,
          visible: true,
          width: definitionLookup[id].size ?? 100,
          pinned: false,
        })),
      }))
    }
  }, [allColumnIds, definitionLookup, setViewState, viewState.columns.length])

  const [columnOrder, setColumnOrderCore] =
    useDependantState<ColumnOrderState>(() => {
      return [...columnIds]
    }, [columnIds])

  const [columnSizing, setColumnSizingCore] =
    useDependantState<ColumnSizingState>(() => {
      return createLookupBy(
        allColumnIds,
        (id) => id,
        (id) => columnLookup[id]?.width ?? definitionLookup[id].size ?? 100
      )
    }, [allColumnIds, columnLookup, definitionLookup])

  const [columnVisibility, setColumnVisibilityCore] =
    useDependantState<VisibilityState>(() => {
      return createLookupBy(
        allColumnIds,
        (id) => id,
        (id) => getColumnVisibility(id)
      )
    }, [allColumnIds, getColumnVisibility])

  const [columnPinning, setColumnPinningCore] =
    useDependantState<ColumnPinningState>(() => {
      return {
        left: viewState.columns.reduce<string[]>((acc, col) => {
          if (col.pinned) {
            acc.push(col.id)
          }
          return acc
        }, []),
      }
    }, [viewState.columns])

  const ref = useRef({
    columnOrder,
    columnVisibility,
    columnSizing,
    columnPinning,
    columns,
  })

  ref.current = {
    columnOrder,
    columnVisibility,
    columnSizing,
    columnPinning,
    columns,
  }

  const {
    setColumnOrder,
    setColumnVisibility,
    setColumnSizing,
    setColumnPinning,
  } = useMemo(() => {
    function setColumnOrder(setter: Updater<ColumnOrderState>) {
      const value =
        typeof setter === 'function' ? setter(ref.current.columnOrder) : setter

      setColumnOrderCore(value)
      setViewState((prev) => ({
        ...prev,
        columns: [...ref.current.columns].sort(
          byProperty('id', ordered(value))
        ),
      }))
    }

    function setColumnVisibility(setter: Updater<VisibilityState>) {
      const value =
        typeof setter === 'function'
          ? setter(ref.current.columnVisibility)
          : setter

      setColumnVisibilityCore(value)

      setViewState((prev) => {
        const newValue = {
          ...prev,
          columns: ref.current.columns.map((col) => ({
            ...col,
            visible: value[col.id] ?? col.visible,
          })),
        }

        const newlyVisible = Object.keys(value).filter(
          (id) => !newValue.columns.some((x) => x.id === id)
        )
        newValue.columns.push(
          ...newlyVisible.map((id) => ({
            id,
            visible: value[id] ?? false,
            pinned: ref.current.columnPinning.left?.includes(id) ?? false,
            width:
              ref.current.columnSizing[id] ?? definitionLookup[id].size ?? 100,
          }))
        )

        return newValue
      })
    }

    function setColumnSizing(setter: Updater<ColumnSizingState>) {
      const value =
        typeof setter === 'function' ? setter(ref.current.columnSizing) : setter

      setColumnSizingCore(value)
      setViewState((prev) => ({
        ...prev,
        columns: ref.current.columns.map((col) => ({
          ...col,
          width: value[col.id] ?? col.width ?? 100,
        })),
      }))
    }

    function setColumnPinning(setter: Updater<ColumnPinningState>) {
      const value =
        typeof setter === 'function'
          ? setter(ref.current.columnPinning)
          : setter

      setColumnPinningCore(value)
      setViewState((prev) => ({
        ...prev,
        columns: ref.current.columns.map((col) => ({
          ...col,
          pinned: value.left?.includes(col.id) ?? false,
        })),
      }))
    }

    return {
      setColumnOrder,
      setColumnVisibility,
      setColumnSizing,
      setColumnPinning,
    }
  }, [
    definitionLookup,
    setColumnOrderCore,
    setColumnSizingCore,
    setColumnVisibilityCore,
    setColumnPinningCore,
    setViewState,
  ])

  return {
    columnOrder,
    setColumnOrder,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
    columnPinning,
    setColumnPinning,
  }
}
