import { ChevronRightSolid } from '@motion/icons'
import { type EmailAccount } from '@motion/rpc/types'
import { Button, CompanyLogo } from '@motion/ui/base'

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface SelectMainCalendarProps {
  emailAccounts: EmailAccount[]
  onBack: () => void
  onSelectMainCalendar: (emailAccount: EmailAccount) => Promise<void>
  isInModal?: boolean
}

export function SelectMainCalendar({
  emailAccounts,
  onBack,
  onSelectMainCalendar,
  isInModal = false,
}: SelectMainCalendarProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function onSubmit(emailAccount: EmailAccount) {
    setLoading(true)
    setError(null)

    try {
      await onSelectMainCalendar(emailAccount)
    } catch (error) {
      setError((error as Error).message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='h-full w-full p-6 md:p-0 lg:p-0'>
      <div
        className={twMerge(
          'relative mx-auto max-w-[600px]',
          !isInModal && 'lg:pt-12'
        )}
      >
        <h1
          className={twMerge(
            'font-semibold',
            isInModal
              ? 'text-semantic-neutral-text-default pb-4 text-[24px]'
              : 'text-light-1200 mb-3 lg:mb-8 text-lg lg:text-3xl lg:leading-normal'
          )}
        >
          Select main calendar
        </h1>
        {isInModal && (
          <p className='text-semantic-neutral-text-default pb-2'>
            When you set up events in Motion, they'll automatically use your
            main calendar. Plus, any tasks you make in Motion can be
            effortlessly synced with this main calendar. Make the most out of
            your scheduling!
          </p>
        )}
        {!isInModal && (
          <p className='text-light-1100'>
            Your main calendar is used as the default when creating events.
          </p>
        )}
        <div className='my-4'>
          {emailAccounts.map((emailAccount) => (
            <button
              key={emailAccount.id}
              onClick={() => onSubmit(emailAccount)}
              disabled={loading}
              className={twMerge(
                'my-2 flex w-full items-center justify-between rounded border border-semantic-neutral-border-strong hover:bg-semantic-neutral-bg-active-hover hover:border-semantic-neutral-border-hover focus:bg-semantic-neutral-bg-active-hover px-4 py-4',
                !isInModal && 'bg-white'
              )}
            >
              <div className='flex items-center gap-2'>
                <div className='h-5 w-5 flex items-center justify-center'>
                  <CompanyLogo provider={emailAccount.providerType} />
                </div>
                <p
                  className={
                    isInModal
                      ? 'text-semantic-neutral-text-default'
                      : 'text-light-1200'
                  }
                >
                  {emailAccount.email}
                </p>
              </div>
              <ChevronRightSolid
                className={twMerge(
                  'size-6',
                  isInModal
                    ? 'text-semantic-neutral-icon-default'
                    : 'text-light-1000'
                )}
              />
            </button>
          ))}

          {error && (
            <div className='break-words px-2 py-2'>
              <p className='text-sm text-red-600'>{error}</p>
            </div>
          )}
        </div>
        <div className='flex items-center justify-center'>
          <Button onClick={onBack} sentiment='neutral' variant='muted'>
            Back
          </Button>
        </div>
      </div>
    </div>
  )
}
