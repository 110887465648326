import { type SvgIcon } from '@motion/icons'

interface IconWrapperProps {
  icon: SvgIcon
}

export const IconWrapper = ({ icon: Icon }: IconWrapperProps) => {
  return (
    <div className='flex flex-col justify-center items-center w-12 h-12 bg-semantic-neutral-bg-default rounded-lg drop-shadow-sm'>
      <Icon />
    </div>
  )
}
