import { type TaskSchema } from '@motion/zod/client'

import { useAgendaTaskActions } from '~/areas/agenda/hooks'

import { TaskItemActionButton } from './task-item-action-button'

type OpenTaskButtonProps = {
  taskId: TaskSchema['id']
}

export function OpenTaskButton({ taskId }: OpenTaskButtonProps) {
  const { openTask } = useAgendaTaskActions({
    taskId,
  })

  return (
    <TaskItemActionButton action='open-task' onClick={openTask}>
      Open task
    </TaskItemActionButton>
  )
}
