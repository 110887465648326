import { QuestionMarkCircleSolid, RefreshSolid } from '@motion/icons'
import { Tooltip, useContextMenu } from '@motion/ui/base'
import {
  formatTime,
  prettyDateDay,
  scheduleTypeToInfo,
  templateStr,
} from '@motion/ui-logic'
import { isMeetingTask, isReminderTask } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { EventActionList } from '~/areas/event/components'
import { useAutoScheduleRange } from '~/areas/project-management/hooks/experiments'
import {
  TaskActionList,
  TaskChunkBubbleText,
  TaskCompleteButton,
} from '~/areas/tasks/components'
import { useTaskDeadlineBadgeData } from '~/areas/tasks/hooks'
import { DeadlineDelayBadge, PriorityBadge } from '~/global/components/badges'
import { useTaskModalUrl } from '~/global/navigation'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { useNavigate } from 'react-router'
import { twMerge } from 'tailwind-merge'

import { AgendaItemWrapper, EventLabel, EventSubtitle } from './common'

import { TaskDeadlineWarning } from '../../components/sidebar'

export type AgendaTaskProps = {
  entity: ScheduledTaskWithRelation
}

export const AgendaTask = ({ entity }: AgendaTaskProps) => {
  const task = entity.task
  const {
    dayNumberTooltipContent,
    deadlineDelayMinutes,
    deadlineDelayDays,
    isTaskDelayed,
    isTaskPastDue,
    isUnfit,
  } = useTaskDeadlineBadgeData(task)

  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  const { handleContextMenu, ContextMenuPopover } = useContextMenu()
  const autoScheduleRange = useAutoScheduleRange()

  if (!task) {
    return null
  }

  const isRecurring = task.type === 'RECURRING_INSTANCE'

  const openTask = () => {
    recordAnalyticsEvent('AGENDA_SIDEBAR_TASK_CLICK')
    navigate(
      buildTaskModalUrl({
        task: task?.type === 'CHUNK' ? task.parentChunkTaskId : task.id,
      })
    )
  }

  const onCompleteTask = () => {
    recordAnalyticsEvent('AGENDA_SIDEBAR_TASK_COMPLETE_CLICK')
  }

  return (
    <>
      <AgendaItemWrapper
        onContextMenu={(e) => {
          recordAnalyticsEvent('AGENDA_SIDEBAR_TASK_RIGHT_CLICK')
          handleContextMenu(e)
        }}
      >
        <div
          className={twMerge(
            'flex gap-2 truncate items-start leading-4 w-full',
            entity.completed && 'opacity-50'
          )}
        >
          <div className='py-2'>
            <TaskCompleteButton
              scheduledTask={entity}
              openTask={openTask}
              onComplete={onCompleteTask}
            />
          </div>
          <button
            className='flex flex-col py-2 gap-1.5 min-w-0 w-full items-start'
            onClick={openTask}
          >
            <div className='flex gap-1.5 items-center w-full'>
              <div className='flex gap-1.5 min-w-0'>
                <EventLabel>{task.name}</EventLabel>
              </div>
              <div className='flex gap-1 items-center leading-4'>
                {entity.type === 'CHUNK' && entity.chunkTotal > 1 && (
                  <TaskChunkBubbleText entity={entity} />
                )}
                {task && !isUnfit && (
                  <DeadlineDelayBadge
                    dayNumberTooltipContent={dayNumberTooltipContent}
                    deadlineDelayMinutes={deadlineDelayMinutes}
                    deadlineDelayDays={deadlineDelayDays}
                  />
                )}
                <PriorityBadge value={task.priorityLevel} size='small' />
                {isRecurring && (
                  <TaskRecurringIcon
                    task={task}
                    isTaskDelayed={isTaskDelayed}
                  />
                )}
                <TaskDeadlineWarning
                  isPastDue={isTaskPastDue}
                  isUnfit={isUnfit}
                  isScheduleOverridden={task.scheduleOverridden}
                />
              </div>
            </div>
            {!isUnfit && !task.isFutureSchedulable && !isReminderTask(task) && (
              <EventSubtitle>
                {formatTime(entity.schedule.start)} -{' '}
                {formatTime(entity.schedule.end)}
              </EventSubtitle>
            )}
            {task.isFutureSchedulable && !entity.completed && task.dueDate && (
              <EventSubtitle>
                <span className='flex items-center gap-1'>
                  To be scheduled
                  <Tooltip
                    content={templateStr(
                      scheduleTypeToInfo.unfitSchedulable.tooltip ?? '',
                      { autoScheduleRange }
                    )}
                  >
                    <QuestionMarkCircleSolid className='h-3 w-3 text-semantic-neutral-icon-subtle' />
                  </Tooltip>
                </span>
                <span className='ml-auto'>
                  Due {prettyDateDay(task.dueDate)}
                </span>
              </EventSubtitle>
            )}
          </button>
        </div>
      </AgendaItemWrapper>
      <ContextMenuPopover
        renderContent={({ close }) =>
          isMeetingTask(task) ? (
            <EventActionList close={close} eventId={task.meetingEventId} />
          ) : (
            <TaskActionList close={close} task={task} />
          )
        }
      />
    </>
  )
}

const TaskRecurringIcon = ({
  task,
  isTaskDelayed,
}: {
  task: TaskSchema | RecurringTaskSchema
  isTaskDelayed: boolean
}) => {
  return (
    <Tooltip
      content={
        isTaskDelayed ? 'Could not fit' : `Recurring ${task ? 'task' : 'event'}`
      }
    >
      <RefreshSolid
        className={twMerge(
          'rounded-lg h-2.5 w-2.5 p-[1px] bg-semantic-neutral-bg-active-hover text-semantic-neutral-icon-default',
          isTaskDelayed &&
            'bg-semantic-error-bg-strong-default text-semantic-error-icon-onDark'
        )}
      />
    </Tooltip>
  )
}
