import { useMemo } from 'react'

import { useViewState } from '../../view-state'
import {
  BASE_SIDEBAR_WIDTH,
  PADDING_PER_GROUP_LEVEL,
} from '../shared-constants'

export const useSidebarSize = () => {
  // Return the sidebar padding/size based on number of groups and padding
  const [viewState] = useViewState()

  let numGroups = viewState.groupBy.fields.length - 1

  if (numGroups <= 0) {
    numGroups = 1
  }

  return useMemo(() => {
    let groupingPadding = numGroups * PADDING_PER_GROUP_LEVEL
    return BASE_SIDEBAR_WIDTH + groupingPadding
  }, [numGroups])
}
