import { type PMTaskType } from '@motion/rpc/types'
import { getStatusChangedFields } from '@motion/ui-logic/pm/task'
import { type StatusSchema } from '@motion/zod/client'

import { useWorkspaceStatuses } from '~/global/hooks'
import { useCallback } from 'react'

import { useTemplateTaskUpdate } from './use-template-task-update'

export const useTemplateTaskStatusDropdown = (task: PMTaskType) => {
  const updateTemplateTask = useTemplateTaskUpdate()
  const statuses = useWorkspaceStatuses(task.workspaceId)
  const onChange = useCallback(
    async (statusId: StatusSchema['id']) => {
      const updates: Partial<PMTaskType> = {
        statusId,
        ...getStatusChangedFields({ ...task, statusId }, { statuses }),
      }

      await updateTemplateTask(task, updates)
    },
    [updateTemplateTask, task, statuses]
  )

  return {
    dropdownValue: statuses.find((s) => s.id === task.statusId),
    onChange,
    workspaceId: task.workspaceId ?? '',
  }
}
