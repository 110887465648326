import { UsersSolid } from '@motion/icons'
import { type Contact } from '@motion/rpc/types'
import { PopoverButton } from '@motion/ui/base'
import { handleGuestsChange } from '@motion/ui-logic'

import { useConferenceSettingsApi } from '~/areas/settings/state'
import { useController } from 'react-hook-form'

import {
  ContactsDropdown,
  type ContactsDropdownProps,
} from '../../../components'
import { PopoverButtonIcon } from '../components'
import { useEventModalState } from '../contexts'
import { useEventForm, useFormHandleConferenceChange } from '../hooks'

export const ControlledEventGuestsSearchField = () => {
  const modalState = useEventModalState()
  const { form } = useEventForm()
  const handleConferenceChange = useFormHandleConferenceChange()

  const { control } = form

  const conferenceSettings =
    useConferenceSettingsApi().selectConferenceSettings()

  const { field } = useController({
    name: 'attendees',
    control,
  })

  if (modalState.isLoading || modalState.isReadOnly) return null

  const { hostEmailAccount } = modalState

  const onChange: ContactsDropdownProps['onChange'] = (contact: Contact) => {
    if (!hostEmailAccount) return

    const { attendees, conferenceType } = handleGuestsChange(
      {
        contact,
        hostEmailAccount,
        conferenceTypeInSettings: conferenceSettings?.conferenceType,
      },
      {
        attendees: form.getValues('attendees'),
        conferenceType: form.getValues('conferenceType'),
      }
    )

    handleConferenceChange(conferenceType)
    form.setValue('attendees', attendees, { shouldDirty: true })
  }

  const selectedEmails = field.value.map((a) => a.email)

  return (
    <ContactsDropdown selectedEmails={selectedEmails} onChange={onChange}>
      <PopoverButton size='small'>
        <div className='flex items-center gap-2'>
          <PopoverButtonIcon icon={UsersSolid} />
          Add guests...
        </div>
      </PopoverButton>
    </ContactsDropdown>
  )
}
