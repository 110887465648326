import { PopoverTrigger, type PopoverTriggerProps } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { NodeViewWrapper } from '@tiptap/react'
import { VariableLabel } from '~/global/components/labels'

type NodeVariableLabelViewProps = {
  variable: FlowTemplateFormFields['textVariables'][number]
  size?: 'xsmall' | 'small'
  renderPopover: PopoverTriggerProps['renderPopover']
}

export const NodeVariableLabelView = ({
  variable,
  size,
  renderPopover,
}: NodeVariableLabelViewProps) => {
  return (
    <PopoverTrigger placement='bottom-start' renderPopover={renderPopover}>
      <NodeViewWrapper as='button' className='inline-flex align-middle'>
        <VariableLabel value={variable} size={size} />
      </NodeViewWrapper>
    </PopoverTrigger>
  )
}
