import {
  ChevronDoubleDownSolid,
  ChevronDownSolid,
  ChevronUpSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { ButtonGroup, IconButton } from '@motion/ui/base'

import { type PropsWithChildren, type ReactNode } from 'react'

type BladeProps = PropsWithChildren<
  {
    title: string
    scrollable?: boolean
    actions?: ReactNode
  } & (
    | {
        state: BladeState
        onStateChange?: (state: BladeState) => void
        expandedMargin?: number
      }
    | {
        state?: never
        onStateChange?: never
        expandedMargin?: never
      }
  )
>

export type BladeState = 'expanded' | 'peek' | 'collapsed'

export const Blade = ({
  title,
  state,
  onStateChange,
  expandedMargin = 0,
  scrollable,
  children,
  actions = null,
}: BladeProps) => {
  const showButtons = !!onStateChange && !!state

  return (
    <Wrapper
      collapsable={!!state}
      state={state}
      style={{
        maxHeight: expandedMargin
          ? `calc(100% - ${expandedMargin + 40}px)`
          : undefined,
      }}
    >
      <Header
        onClick={() => {
          if (onStateChange && state === 'collapsed') {
            onStateChange('peek')
          }
        }}
      >
        <span className='mr-auto'>{title}</span>

        {showButtons ? (
          <ButtonGroup size='small' nowrap segmented>
            <IconButton
              size='small'
              variant='muted'
              sentiment='neutral'
              icon={state === 'expanded' ? ChevronDownSolid : ChevronUpSolid}
              onClick={() => {
                switch (state) {
                  case 'expanded':
                    return void onStateChange('peek')
                  case 'peek':
                    return void onStateChange('expanded')
                  case 'collapsed':
                    return void onStateChange('peek')
                }
              }}
            />

            {state !== 'collapsed' && (
              <IconButton
                size='small'
                variant='muted'
                sentiment='neutral'
                icon={
                  state === 'expanded'
                    ? ChevronDoubleDownSolid
                    : ChevronDownSolid
                }
                onClick={() => void onStateChange('collapsed')}
              />
            )}
          </ButtonGroup>
        ) : (
          actions
        )}
      </Header>

      <Content scrollable={scrollable}>{children}</Content>
    </Wrapper>
  )
}

const Wrapper = classed('div', {
  base: 'w-full rounded-t-lg transition-all group flex flex-col min-h-0 overflow-hidden',
  variants: {
    collapsable: {
      true: `
        min-h-10 max-h-[calc(100%_-_40px)] -mt-4
        bg-semantic-neutral-surface-raised-bg-default 
        shadow-[0px_-4px_6px_-4px_rgba(0,_0,_0,_0.02),_0px_-10px_15px_-3px_rgba(0,_0,_0,_0.08)] 
      `,
      false: `
        min-h-14
        bg-semantic-neutral-bg-disabled shadow-[0px_4px_24px_0px_rgba(0,_0,_0,_0.04)] 
        basis-[40px] flex-1 pb-4
      `,
    },
    state: {
      expanded: '',
      peek: '',
      collapsed: '',
    },
  },
  dataAttributes: ['collapsable', 'state'],
  compoundVariants: [
    {
      collapsable: true,
      state: 'expanded',
      class: 'h-full',
    },
    {
      collapsable: true,
      state: 'peek',
      class: 'h-[calc(50%_-_30px)]',
    },
    {
      collapsable: true,
      state: 'collapsed',
      class: 'h-0 hover:-translate-y-1',
    },
  ],
})

const Header = classed('div', {
  base: 'flex items-center px-3 pt-2 pb-3 text-sm leading-5 font-semibold group-data-[state=collapsed]:hover:cursor-pointer',
})

const Content = classed('div', {
  base: 'relative h-full min-h-0 flex-1',
  variants: {
    scrollable: {
      true: 'overflow-auto',
      false: 'overflow-hidden',
    },
  },
})
