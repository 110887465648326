import { classed } from '@motion/theme'

import { DONE_FOR_THE_DAY_MESSAGE } from '~/global/utils'
import { DateTime } from 'luxon'

export type EmptyDayItemProps = {
  date: DateTime
  label?: string
}

export function EmptyDayItem({ date, label }: EmptyDayItemProps) {
  return (
    <EmptyDayItemContainer>
      {label ?? getDefaultLabel(date)}
    </EmptyDayItemContainer>
  )
}

const DEFAULT_LABEL = 'No tasks scheduled on this day'

function getDefaultLabel(date: DateTime) {
  if (DateTime.now().hasSame(date, 'day')) {
    return DONE_FOR_THE_DAY_MESSAGE
  }

  return DEFAULT_LABEL
}

const EmptyDayItemContainer = classed('div', {
  base: 'truncate pl-4 text-semantic-neutral-text-subtle min-w-0 text-[13px] leading-4',
})
