import { type ViewState } from '../types'

export const DEFAULT_VIEW_STATE: ViewState = {
  $version: 6,
  groupBy: {
    fields: [],
    order: {},
    hideEmpty: true,
    stackProjects: true,
    dateRange: 'quarter',
  },
  search: '',
  sortBy: null,
  page: 'list',
  columns: [],
  cardFields: [],
  providerIds: [],
  showOOOEventsByAssignee: false,
  viewId: null,
}
