import { API, createUseMutation, createUseQuery } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
  useReplaceModelInCachesFn,
} from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

export const useUpdateMyCtaSettings = createUseMutation(
  API.usersV2.updateCtaSettings
)

export const useUpdateMyTimezonesMutation = createUseMutation(
  API.usersV2.updateTimezoneSettings
)

export const useUpdateMyAutoScheduleSettingsMutation = createUseMutation(
  API.usersV2.updateAutoScheduleSettings
)

const pageViewSettingsQueryFilter = createQueryFilter([
  MODEL_CACHE_KEY,
  API.usersV2.queryKeys.pageViewSettings(),
])

const useAllPageViewSettingsQuery = createUseQuery(
  API.usersV2.getAllPageViewSettings
)
export const useAllPageViewSettings = () => {
  const { data } = useAllPageViewSettingsQuery()
  const replaceModelsInCache = useReplaceModelInCachesFn()

  useEffect(() => {
    if (data == null) return
    replaceModelsInCache(data.models)
  }, [data, replaceModelsInCache])

  return data
}

const useUpsertPageViewsSettingsMutation = createUseMutation(
  API.usersV2.upsertPageViewsSettings
)
export const useUpsertPageViewsSettings = () => {
  const client = useQueryClient()

  return useUpsertPageViewsSettingsMutation({
    onMutate: async (data) => {
      const pageViewSettingId = `${data.type}/${data.id}`

      const cacheUpdates = MotionCache.patch(
        client,
        pageViewSettingsQueryFilter,
        'pageViewSettings',
        {
          [pageViewSettingId]: {
            viewOrder: data.viewOrder,
          },
        }
      )
      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(client, pageViewSettingsQueryFilter, data)
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
  })
}
