import { type TaskWithRelations } from '~/global/proxies'
import { useMemo } from 'react'

import { convertCustomFieldToColumn } from './custom-fields'
import { convertTaskFieldToColumn } from './utils'

import {
  type CustomFieldDefinition,
  isCustomFieldColumn,
  useTaskFields,
} from '../../fields'

export const useTaskColumns = () => {
  const taskFields = useTaskFields()

  return useMemo(() => {
    return taskFields.map((f) => {
      if (isCustomFieldColumn(f.id)) {
        return convertCustomFieldToColumn(
          f as CustomFieldDefinition<TaskWithRelations>
        )
      }
      return convertTaskFieldToColumn(f)
    })
  }, [taskFields])
}
