import { getTaskStartDateChangedFields } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export function useTaskStartDateUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      startDate: TaskSchema['startDate']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the start date of a Chunk task')
      }

      if (task.type === 'RECURRING_TASK') {
        if (startDate == null) {
          throw new Error('Recurring Tasks require a start date')
        }

        await updateTask(task, { startingOn: startDate })
      }

      await updateTask(task, {
        startDate,
        ...getTaskStartDateChangedFields({ ...task, startDate } as TaskSchema),
      })
    },
    [readTask, updateTask]
  )
}
