import { type Contact } from '@motion/rpc/types'
import { type TaskSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { handleGuestsChange } from '@motion/ui-logic'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { Sentry } from '@motion/web-base/sentry'

import { useHostEmailAccount } from '~/areas/calendar/hooks'
import { useUpdateEvent } from '~/areas/event/hooks'
import { useConferenceSettingsApi } from '~/areas/settings/state'
import { useCachedItem } from '~/global/cache'
import { showErrorToast } from '~/global/toasts'

/**
 * Update the "guests" field of a task.
 * The guests field is the attendees of a meeting task.
 */
export function useTaskGuestsData(task: TaskSchema) {
  const updateEvent = useUpdateEvent()
  const meetingEventId = isMeetingTask(task) ? task.meetingEventId : null
  const conferenceSettings =
    useConferenceSettingsApi().selectConferenceSettings()

  const event = useCachedItem('calendarEvents', meetingEventId)
  const hostEmailAccount = useHostEmailAccount(event?.email)

  const onChange = async (contacts: Contact[]) => {
    try {
      if (event == null) {
        throw new Error("Can't update guests for a task without an event")
      }

      if (hostEmailAccount == null) {
        throw new Error("Can't update guests without a host email account")
      }

      const { attendees, conferenceType } = handleGuestsChange(
        {
          contact: contacts,
          hostEmailAccount,
          conferenceTypeInSettings: conferenceSettings?.conferenceType,
        },
        {
          attendees: event.attendees,
          conferenceType: event.conferenceType ?? 'none',
        }
      )

      const result = await updateEvent(event.id, {
        attendees: attendees,
        conferenceType,
      })

      if (result != null) {
        showToast('success', 'Guests updated')
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          task,
        },
      })
      showErrorToast("Unable to update event's guests")
    }
  }

  return {
    onChange,
    disabled: event == null,
  }
}
