import {
  createStateKey,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { createLookupById } from '@motion/utils/object'
import {
  type LabelSchema,
  type ProjectSchema,
  type StatusSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import {
  AppWorkspaceContext,
  type EntityCache,
  useAppWorkspaceContext,
} from '~/global/contexts'
import { useEffect } from 'react'

import { DEFAULT_VIEW } from './view-state'

import { type TeamScheduleView } from '../types'

export type PageDataContext = {
  loaded: boolean
  workspaces: EntityCache<WorkspaceSchema>
  projects: EntityCache<ProjectSchema>
  statuses: EntityCache<StatusSchema>
  users: EntityCache<UserInfoSchema>
  labels: EntityCache<LabelSchema>

  views: EntityCache<TeamScheduleView>
}

export function createEntityCache<T extends { id: string }>(
  items: T[]
): EntityCache<T> {
  return {
    all: items,
    byId: createLookupById(items),
  }
}

const EMPTY = { all: [], byId: {} }
const EMPTY_PAGE_CONTEXT: PageDataContext = {
  loaded: false,
  workspaces: EMPTY,
  projects: EMPTY,
  statuses: EMPTY,
  users: EMPTY,
  labels: EMPTY,
  views: EMPTY,
}

// Eventually this will be defined elsewhere
export const TeamSchedulePageContextKey = createStateKey<PageDataContext>(
  'page-data-context',
  { defaultValue: EMPTY_PAGE_CONTEXT }
)

export const SyncPageDataContext = () => {
  const [ctx] = useAppWorkspaceContext()
  const updateContext = useSharedStateSendOnly(TeamSchedulePageContextKey)

  const [dataState] = useSharedState(AppWorkspaceContext)

  useEffect(() => {
    if (!ctx.loaded) return
    const views = Object.values(ctx.views.all)
    const viewCache = createEntityCache(
      [DEFAULT_VIEW, ...views].filter(
        (x) => x.type === 'team-schedule'
      ) as TeamScheduleView[]
    )
    updateContext({
      ...dataState,
      loaded: true,
      views: viewCache,
    })
  }, [ctx.loaded, ctx.views.all, dataState, updateContext])

  return null
}
