import { Tooltip } from '@motion/ui/base'
import { ConditionalWrapper } from '@motion/ui/utils'

import { maxMeetingsChoices } from '~/components/Booking/components/template-form/template-form.types'
import { Combobox } from '~/components/Common'
import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const AvailabilityLimitMeetingsRow = () => {
  const {
    template: {
      state: { maxDailyMeetings, isMaxDailyMeetingsDisabled },
      setters: { setMaxDailyMeetings },
    },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow
      title='Limit number of meetings per day for this link'
      subtitle='Prevent over-booking'
    >
      <ConditionalWrapper
        condition={isMaxDailyMeetingsDisabled}
        wrapper={(children) => (
          <Tooltip
            asChild
            content='This feature is currently disabled for iCloud host calendars.'
          >
            <span className='flex max-w-full w-full'>{children}</span>
          </Tooltip>
        )}
      >
        <div className='flex w-full items-center gap-3'>
          <span className='text-sm shrink-0'>Up to</span>
          <Combobox
            className='max-w-[150px]'
            autoComplete
            disabled={isMaxDailyMeetingsDisabled}
            value={maxDailyMeetings}
            onChange={setMaxDailyMeetings}
            options={maxMeetingsChoices.map((time) => ({
              label: time ? `${time}` : 'unlimited',
              value: time,
            }))}
          />
          <span className='text-sm shrink-0'>meetings per day</span>
        </div>
      </ConditionalWrapper>
    </SectionRow>
  )
}
