import { uniqueBy } from '@motion/utils/array'

import { useTeamMembers } from '~/global/hooks/team'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

/**
 * Given a list of attendee emails, this hook returns the user IDs of the attendees based on `useTeamMembers`.
 * If the email is not found in the team member list, it will be ignored.
 * @param attendeeEmails
 * @returns
 */
export function useTransformEmailsToUserIds(attendeeEmails: string[]) {
  const members = useTeamMembers()
  const emailAccounts = useAppSelector(selectEmailAccounts)

  return useMemo(() => {
    const result = attendeeEmails.reduce<{
      userIds: string[]
      unmatchedEmails: string[]
    }>(
      (acc, email) => {
        const matchedEmailAccount = emailAccounts.find(
          (acc) => acc.email === email
        )

        // The email belongs to one of the emails of the current logged in user
        if (matchedEmailAccount != null) {
          acc.userIds.push(matchedEmailAccount.userId)

          return acc
        }

        const teamMember = members.find((member) => member.user.email === email)

        if (teamMember != null) {
          acc.userIds.push(teamMember.user.id)
          return acc
        }

        // Not found in the team members, add it to the unmatched emails
        acc.unmatchedEmails.push(email)
        return acc
      },
      {
        userIds: [],
        unmatchedEmails: [],
      }
    )

    return {
      userIds: uniqueBy(result.userIds, (id) => id),
      unmatchedEmails: uniqueBy(result.unmatchedEmails, (email) => email),
    }
  }, [attendeeEmails, emailAccounts, members])
}
