import { type NormalTaskSchema } from '@motion/zod/client'

import { useCachedItem } from '~/global/cache'

import { useTaskWithParent } from '../use-task-with-parent'

type Options = {
  taskId: string | undefined
}

type ParsedData = {
  blockedBy: NormalTaskSchema[]
  blocking: NormalTaskSchema[]
}
type Value = ParsedData & { isLoading: boolean }

export const useBlockerTasks = (opts: Options): Value => {
  const { parentOrDirectTask: task, isLoading } = useTaskWithParent(opts)

  const blockedByTasks = useCachedItem(
    'tasks',
    task?.type === 'NORMAL' ? task.blockedByTaskIds : []
  ) as NormalTaskSchema[]
  const blockingTasks = useCachedItem(
    'tasks',
    task?.type === 'NORMAL' ? task.blockingTaskIds : []
  ) as NormalTaskSchema[]

  return { isLoading, blockedBy: blockedByTasks, blocking: blockingTasks }
}
