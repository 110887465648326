import { useTaskDefaults } from './use-task-defaults'

/**
 * In the future, we'll have workspace-specific task defaults
 */
export const useSelectedTaskDefaults = () => {
  const { global } = useTaskDefaults()

  return global
}
