import { formatDateRange, formatDurationToShort } from '@motion/ui-logic'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { PriorityBadge } from '~/global/components/badges'

import { CellText } from './components'

const nf = new Intl.NumberFormat('en-US')
const pr = new Intl.PluralRules('en-US')

export type TotalCountCellProps = {
  count: number | string
  suffix?:
    | string
    | (Partial<Record<Intl.LDMLPluralRule, string>> & { other: string })
}
export const TotalCountCell = ({ count, suffix }: TotalCountCellProps) => {
  const suffixValue = typeof count === 'number' ? pr.select(count) : 'other'
  const word =
    suffix != null &&
    (typeof suffix === 'string'
      ? suffix
      : (suffix[suffixValue] ?? suffix.other))

  return (
    <CellText className='flex gap-[1ch] text-[11px]'>
      <span className='text-semantic-neutral-text-default'>
        {typeof count === 'number' ? nf.format(count) : count}
      </span>
      {word && (
        <span className='text-semantic-neutral-text-subtle'>{word}</span>
      )}
    </CellText>
  )
}

export interface TotalPrioritiesCellProps {
  totals: { priorityLevel: PriorityLevelSchema; count: number }[]
}
export const TotalPrioritiesCell = ({ totals }: TotalPrioritiesCellProps) => {
  return (
    <CellText className='flex gap-2 text-[11px] text-semantic-neutral-text-default whitespace-nowrap'>
      {totals.map((t) => (
        <div key={t.priorityLevel} className='flex gap-0.5'>
          <PriorityBadge value={t.priorityLevel} size='small' hideTooltip />
          {nf.format(t.count)}
        </div>
      ))}
    </CellText>
  )
}

export interface TotalDateRangeCellProps {
  fromDate: string | undefined
  toDate: string | undefined
}
export const TotalDateRangeCell = ({
  fromDate,
  toDate,
}: TotalDateRangeCellProps) => {
  const realToDate = toDate ?? fromDate

  const cell =
    fromDate != null && realToDate != null
      ? formatDateRange(fromDate, realToDate)
      : ''

  return (
    <CellText className='flex gap-2 text-[11px] text-semantic-neutral-text-default'>
      <span className='truncate' title={cell}>
        {cell}
      </span>
    </CellText>
  )
}

export interface TotalDurationCellProps {
  duration: number | null
  formatter?: (duration: number | null) => string
}
export const TotalDurationCell = ({
  duration,
  formatter = formatDurationToShort,
}: TotalDurationCellProps) => {
  return (
    <CellText className='text-[11px] text-semantic-neutral-text-default whitespace-nowrap'>
      {formatter(duration)}
    </CellText>
  )
}
