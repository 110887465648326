import { createLookup } from '@motion/utils/object'

import type { Row } from '@tanstack/react-table'
import type { SortBy } from '~/areas/task-project/utils'
import type { ReactNode } from 'react'

import { StageHeader } from './stage-header'
import { StatusHeader } from './status-header'

import type { InlineTaskCreationFormProps } from '../inline-create'

export type LookupArgs = {
  groupId: string
  toggle: (initialData?: InlineTaskCreationFormProps['initialData']) => void
  row?: Row<any>
}

export const lookupHeader = createLookup<
  Record<
    Extract<keyof typeof SortBy, 'STATUS' | 'STAGES'>,
    (args: LookupArgs) => ReactNode
  > & {
    default: () => ReactNode
  }
>({
  STATUS: ({ groupId, toggle, row }) => (
    <StatusHeader groupId={groupId} toggle={toggle} row={row} />
  ),
  STAGES: ({ groupId, toggle, row }) => (
    <StageHeader groupId={groupId} toggle={toggle} row={row} />
  ),
  default: () => null,
})
