import { makeLog } from '@motion/web-base/logging'

import { type LocalStorage, type StorageChangeResult } from './chromeApiTypes'

import { defaultSettings } from '../storageConstants'

// Note: This module is shared across both BG and CS, so do not import any
// chromeApi modules

// In-memory storage
const storage: Record<string, any> = { ...defaultSettings }

const log = makeLog('motion-local-storage')

const doGet = async (key: string | string[]): Promise<Record<string, any>> => {
  if (!key) {
    return storage
  }

  const keys = Array.isArray(key) ? key : [key]
  const res: Record<string, any> = {}

  for (const singleKey of keys) {
    res[singleKey] = storage[singleKey]
  }

  return res
}

// eslint-disable-next-line import-x/no-default-export
export default {
  clear: (): Promise<void> => {
    return new Promise((resolve) => {
      for (const [key] of Object.keys(storage)) {
        delete storage[key]
      }
      resolve()
    })
  },
  get: doGet,

  /**
   * Specifically for the webapp - merge initial state with provided data
   * (values must be defined) without diffing
   * @param data
   */
  initialize: (data: Record<string, any>) => {
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === 'undefined') {
        continue
      }
      storage[key] = value
    }
  },

  remove: async (key: string) => delete storage[key],

  /**
   * Important note: data returned by this function is by reference, so any
   * changes to objects will be updated accordingly in storage.
   * @param data
   */
  set: async (data: Record<string, any>) => {
    // Construct change data structure
    const changeData: StorageChangeResult = {}

    let hasChanges = false
    for (const [key, value] of Object.entries(data)) {
      const oldValue = storage[key]

      hasChanges = true
      storage[key] = value
      changeData[key] = {
        newValue: value,
        oldValue: oldValue,
      }
    }
    log('changes', changeData)
    return hasChanges ? changeData : null
  },

  /**
   * Since the in-memory storage is distinct across BG and CS, we should ingest
   * the StorageChangeResult emitted by each thread to synchronize.
   * @param changeData
   */
  syncChanges: (changeData: StorageChangeResult) => {
    for (const [key, value] of Object.entries(changeData)) {
      storage[key] = value.newValue
    }
  },
} as unknown as LocalStorage
