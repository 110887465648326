import { ConditionalWrapper } from '@motion/ui/utils'
import { type WorkspaceSchema } from '@motion/zod/client'

import { type BadgeSize, WorkspaceBadge } from '~/global/components/badges'
import { useWorkspaceById } from '~/global/hooks'
import { useUriByRouteId } from '~/routing'

import { MotionLink } from '../motion-link'

export type WorkspaceLabelProps = {
  value: WorkspaceSchema | null
  size?: BadgeSize
  nameOnly?: boolean
  clickable?: boolean
}

export function WorkspaceLabel({
  size,
  value,
  nameOnly = false,
  clickable = false,
}: WorkspaceLabelProps) {
  const getRouteUri = useUriByRouteId({ noDefaults: true })

  const name = value == null ? 'No workspace' : value.name
  const url =
    value != null
      ? getRouteUri('workspace-detail', { workspaceId: value.id })
      : null

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  return (
    <ConditionalWrapper
      condition={clickable}
      wrapper={(children) =>
        url ? (
          <MotionLink url={url} className='overflow-hidden'>
            {children}
          </MotionLink>
        ) : (
          children
        )
      }
    >
      {nameOnly ? (
        label
      ) : (
        <div className='flex gap-1 items-center overflow-hidden leading-4'>
          <WorkspaceBadge size={size} />
          {label}
        </div>
      )}
    </ConditionalWrapper>
  )
}

export type ConnectedWorkspaceLabelProps = Omit<
  WorkspaceLabelProps,
  'value'
> & {
  id: WorkspaceSchema['id']
}

export function ConnectedWorkspaceLabel({
  id,
  ...rest
}: ConnectedWorkspaceLabelProps) {
  const workspace = useWorkspaceById(id)

  return <WorkspaceLabel value={workspace} {...rest} />
}
