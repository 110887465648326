import { classed } from '@motion/theme'
import { VirtualizedColumns } from '@motion/ui/helpers'

import {
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import { type BadgeSize } from '~/global/components/badges'
import { useMemo } from 'react'

import { SortableItem } from '../../../../components/sortable/sortable-item'
import { TabHeader } from '../../components'
import { type GroupedNode, type Tree } from '../../grouping'

const TAB_WIDTH = 200

export type TabRowProps<T extends GroupedNode> = {
  tree: Tree<T>
  display: 'tab' | 'pill'
  size: BadgeSize
  selected: Tree<T>
  onSelect(group: Tree<T>): void
  activeId?: string
}

export function TabRow<T extends GroupedNode>(props: TabRowProps<T>) {
  const activeIndex = props.tree.children.findIndex(
    (item) => item.qualifiedKey === props.activeId
  )

  const stickyIndexes = useMemo(() => {
    if (activeIndex < 0) return undefined
    return [activeIndex]
  }, [activeIndex])

  return (
    <SortableContext
      items={props.tree.children.map((item) => item.qualifiedKey)}
      strategy={horizontalListSortingStrategy}
    >
      <div className='h-12 w-full overflow-hidden relative pt-1'>
        {props.display === 'tab' && <BottomBorder />}
        <VirtualizedColumns
          containerClassName='px-6'
          items={props.tree.children}
          computeItemKey={(item) => `tab-${item?.key}`}
          estimateWidth={() => TAB_WIDTH}
          stickyIndexes={stickyIndexes}
          renderItem={({ item }) => (
            <SortableItem
              id={item.qualifiedKey}
              data={{
                type: props.display,
                width: TAB_WIDTH,
                contain: 'strict',
              }}
              style={{
                position: 'relative',
              }}
              renderItem={() => {
                return (
                  <Tab
                    display={props.display}
                    active={item === props.selected}
                    onClick={() => props.onSelect(item)}
                  >
                    <TabHeader
                      item={item}
                      active={item === props.selected}
                      size={props.size}
                      clickableLabel={false}
                      shouldShowCountType
                    />
                  </Tab>
                )
              }}
            />
          )}
        />
      </div>
    </SortableContext>
  )
}

const BottomBorder = classed('div', {
  base: `
    w-full h-px absolute top-[35px] left-0 bg-semantic-neutral-border-default
  `,
})

export const Tab = classed('button', {
  base: `
    border border-transparent max-w-[200px]
  `,
  variants: {
    active: {
      true: 'bg-semantic-neutral-bg-default',
      false: '',
    },
    dropable: {
      true: '',
    },
    display: {
      tab: 'px-3 flex items-center h-8',
      pill: 'px-2 py-1.5 rounded-full text-xs',
    },
  },
  compoundVariants: [
    {
      active: true,
      display: 'tab',
      class: `
        rounded-t
        border-semantic-neutral-border-default
        border-b-transparent
      `,
    },
    {
      active: false,
      display: 'pill',
      class: `
      text-semantic-neutral-text-subtle
      `,
    },
    {
      active: true,
      display: 'pill',
      class: `
        bg-semantic-neutral-bg-active-default
        text-semantic-neutral-text-default
      `,
    },
  ],

  defaultVariants: {
    active: true,
    dropable: false,
    display: 'tab',
  },
})
