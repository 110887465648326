import {
  getCacheEntryValue,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'

import { notifyManager, type QueryClient } from '@tanstack/react-query'

import { getCalendarEventsQueryFilters } from './event-query-filters'

import { applyOptimisticTaskDelete } from '../task'

export function applyOptimisticCalendarEventDelete(
  client: QueryClient,
  calendarEventId: string
): OptimisticUpdateValue {
  const cachedEvent = getCacheEntryValue(
    client,
    'calendarEvents',
    calendarEventId
  )

  const cacheUpdates: (
    | ReturnType<(typeof MotionCache)['delete']>
    | ReturnType<typeof applyOptimisticTaskDelete>
  )[] = [
    MotionCache.delete(
      client,
      getCalendarEventsQueryFilters(),
      ['calendarEvents', 'scheduledEntities'],
      calendarEventId
    ),
  ]

  // When deleting an event, we need to also remove the meeting task related to it
  if (cachedEvent?.meetingTaskId != null) {
    cacheUpdates.push(
      applyOptimisticTaskDelete(client, cachedEvent.meetingTaskId)
    )
  }

  function rollback() {
    notifyManager.batch(() => {
      cacheUpdates.forEach((c) => c.rollback())
    })
  }

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
