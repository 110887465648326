import { ChevronLeftSolid, QuestionMarkCircleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { useFlowsAIForm } from '../hooks'

type ErrorScreenProps = {
  onBack: () => void
}

export function ErrorScreen({ onBack }: ErrorScreenProps) {
  const form = useFlowsAIForm()

  const handleOnBack = () => {
    form.clearErrors()
    onBack()
  }

  return (
    <div className='h-full flex flex-col items-center justify-center gap-6 p-20 text-semantic-neutral-text-subtle'>
      <QuestionMarkCircleSolid className='size-14' />
      <h4 className='text-base font-medium text-center'>
        Hmmm, something went wrong while setting up your project workflow
        template. Please try refreshing again or contact support.
      </h4>
      <Button sentiment='primary' variant='solid' onClick={handleOnBack}>
        <ChevronLeftSolid className='size-14' />
        Go back
      </Button>
    </div>
  )
}
