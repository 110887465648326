import { classed } from '@motion/theme'

import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { ProjectCardField } from './project-card-field'
import { TaskCardField } from './task-card-field'

import {
  useFilteredCardItems,
  useKanbanCardFieldViewState,
} from '../../../hooks'

export function CustomFieldsSection({
  task,
  project,
}: {
  task?: TaskWithRelations
  project?: ProjectWithRelations
}) {
  const [items] = useKanbanCardFieldViewState()
  const filteredItems = useFilteredCardItems(
    items,
    task?.workspaceId ?? project?.workspaceId ?? ''
  )

  if (task == null && project == null) return null

  if (task != null) {
    if (task.type !== 'NORMAL') return null

    return (
      <Container>
        {filteredItems
          .map(({ id, visible }) => {
            if (!visible) return null
            return <TaskCardField key={id} task={task} fieldId={id} />
          })
          .filter(Boolean)}
      </Container>
    )
  }

  if (project == null) return null

  return (
    <Container>
      {filteredItems
        .map(({ id, visible }) => {
          if (!visible) return null
          return <ProjectCardField key={id} project={project} fieldId={id} />
        })
        .filter(Boolean)}
    </Container>
  )
}

const Container = classed(
  'div',
  'flex flex-col gap-1 w-full',
  '[&_[data-icon]]:h-3 [&_[data-icon]]:w-3'
)
