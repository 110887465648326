import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import {
  createDurationLabel,
  generateIntervalOptionsFromDate,
  generateTimeOptionsFromString,
} from '@motion/ui-logic/calendar'
import { Sentry } from '@motion/web-base/sentry'

import { type DateTime } from 'luxon'
import { useMemo } from 'react'

type SearchableTimeDropdownProps = {
  value: string
  onChange: (newDate: DateTime) => void
  startDateTime: DateTime
  children: React.ReactNode
  showDuration?: boolean
}

export const SearchableTimeDropdown = (props: SearchableTimeDropdownProps) => {
  const {
    value,
    onChange,
    startDateTime,
    showDuration = false,
    children,
  } = props
  const options = useMemo(
    () =>
      generateIntervalOptionsFromDate({
        startDate: startDateTime,
        endDate: startDateTime.plus({ days: 1 }),
        exclusive: showDuration,
      }),
    [showDuration, startDateTime]
  )
  const handleFilter = (search: string) => {
    if (search)
      return generateTimeOptionsFromString(search, startDateTime, {
        showDuration,
      })
    return options
  }
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          items={options}
          renderItem={(item) => (
            <div>
              {item.label}
              {showDuration && (
                <span className='text-semantic-neutral-text-subtle ml-1'>
                  {createDurationLabel(item.duration)}
                </span>
              )}
            </div>
          )}
          computeKey={(item) => item.label}
          computeSelected={(item) => item.label === value}
          filter={handleFilter}
          onSelect={(item) => {
            try {
              onChange(item.value)
            } catch (e) {
              Sentry.captureException(e, {
                tags: {
                  position: 'SearchableTimeDropdown',
                },
                extra: {
                  item: JSON.stringify(item),
                  options: JSON.stringify(options),
                },
              })
            }
            close()
          }}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
