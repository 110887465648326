import { Button } from '@motion/ui/base'

import { Buttons } from './Buttons'

import logo from '../../../assets/logo_1024.webp'

interface OnboardingPageWrapperProps {
  children: React.ReactChild
  navbarPositionFixed: boolean
}

export const OnboardingPageWrapper = ({
  navbarPositionFixed,
  children,
}: OnboardingPageWrapperProps) => {
  const additionalButtons = (
    <Button sentiment='neutral' variant='muted' url='/web/booking'>
      Go back to Motion
    </Button>
  )
  return (
    <div
      className={`fill flex-center bg-light-300 flex h-[100vh] w-[100vw] dark:bg-[#1d1d1d] ${
        navbarPositionFixed ? '' : 'flex flex-col'
      }`}
    >
      {additionalButtons && (
        <div
          style={{
            background: '#151515',
            height: 60,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 1,
          }}
        >
          <Buttons additionalButtons={additionalButtons} />
        </div>
      )}
      <img
        src={logo}
        className='ob-logo'
        style={{ zIndex: 1 }}
        alt='Motion logo'
      />
      <div
        className={`${
          navbarPositionFixed ? 'fixed top-0' : ''
        } bg-light-300 h-[60px] w-full dark:bg-[#151515]`}
      />
      <div className='fill' style={{ overflow: 'auto', position: 'relative' }}>
        <div
          className={`flex w-full justify-center ${
            navbarPositionFixed ? 'h-full' : ''
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
