import { type COLOR } from '@motion/shared/common'
import { isObjectNoneId } from '@motion/shared/identifiers'
import { ProjectPalette } from '@motion/ui/project'
import { daysBetweenDates, safeParseDate } from '@motion/utils/dates'
import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { StageTooltip } from '~/areas/flows'
import { StageBadge } from '~/global/components/badges'
import { useStageDefinition } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { usePlannerProps } from '../../../context'
import { DEFAULT_PROJECT_LENGTH_IN_WEEKS } from '../../../utils'
import { type Side } from '../resize-handle'

/**
 * @deprecated
 */
export const DeprecatedProjectStageItem = ({
  project,
  currentSide,
  stageIndex,
  projectStage,
  prevStageDone = false,
  stageStart,
  stageEnd,
}: {
  project: ProjectSchema
  stageIndex: number
  prevStageDone?: boolean
  currentSide?: Side
  projectStage: StageSchema
  stageStart: DateTime
  stageEnd: DateTime
}) => {
  const [plannerProps] = usePlannerProps()
  const stageDefinition = useStageDefinition(projectStage.stageDefinitionId)

  // Get the number of days between the start and end date
  const days = Math.abs(daysBetweenDates(stageStart, stageEnd))

  const numProjectDays =
    project.startDate && project.dueDate
      ? Math.abs(
          daysBetweenDates(
            DateTime.fromISO(project.startDate),
            DateTime.fromISO(project.dueDate)
          )
        )
      : DEFAULT_PROJECT_LENGTH_IN_WEEKS

  const daysFromEdge = useMemo(() => {
    // Calculate the number of days from the left edge (project start date)

    const projectStartDate = project.startDate
      ? safeParseDate(project.startDate)
      : null

    if (!projectStartDate) return 0

    let distance = Math.abs(daysBetweenDates(projectStartDate, stageStart))

    return distance && distance > 0 ? distance : 0
  }, [project.startDate, stageStart])

  const widthPixels = days * plannerProps.dayPx

  // Calculate the number of pixels from the edge
  let edgePixels = Math.round(daysFromEdge) * plannerProps.dayPx

  // If from the leftSide (currentSide === right), we should subtract a day to the edge to be inclusive of that day
  if (currentSide === 'left') {
    edgePixels -= plannerProps.dayPx
  }

  // If the prev stage is done, we don't want to overlap any completion with the next stage
  if (prevStageDone) {
    edgePixels += 4
  }

  const projectWidth = numProjectDays * plannerProps.dayPx

  const widthStyles = {
    width: widthPixels,
    minWidth: widthPixels,
    maxWidth: widthPixels,
  }
  const rightSide = projectWidth - edgePixels - widthPixels

  // We add a bit of padding of a single day or so to ensure it doesn't shift
  const leftSide = edgePixels - plannerProps.dayPx * 0.25

  const edgeStyles =
    stageIndex === 0 || daysFromEdge === 0
      ? { left: 0, zIndex: 1 }
      : currentSide === 'left'
        ? {
            right: rightSide >= projectWidth ? projectWidth - 1 : rightSide,
          }
        : {
            left: leftSide >= projectWidth ? projectWidth - 1 : leftSide,
          }

  return (
    <StageTooltip
      stage={projectStage}
      start={stageStart.toISODate()}
      end={stageEnd.toISODate()}
    >
      <div
        style={{
          ...widthStyles,
          ...edgeStyles,
        }}
        className={twMerge(
          'flex items-center justify-start gap-1 justify-self-end h-full absolute'
        )}
      >
        {!isObjectNoneId(stageDefinition) && (
          <ProjectPalette color={stageDefinition.color}>
            <div
              className='bg-white dark:bg-opacity-20 bg-opacity-70 h-full absolute left-0 rounded-none'
              // Add 1 to the width to prevent the bar from being cut off before the next stage
              style={{
                width:
                  widthPixels * (projectStage.completion / 100) +
                  (projectStage.completion > 0 &&
                  stageIndex === project.stages.length - 1
                    ? plannerProps.dayPx
                    : 0),
              }}
            />
            <div
              className={twMerge(
                'absolute left-[3px] hidden group-hover/planner-project-item:flex transition-all',
                project.id === plannerProps.resizingId && 'flex'
              )}
            >
              <StageBadge value={stageDefinition} size='xxsmall' hideTooltip />
            </div>
            <ProjectPalette color={project.color as COLOR}>
              <div
                className={twMerge(
                  'absolute left-1 w-1.5 h-1.5 flex group-hover/planner-project-item:hidden transition-all',
                  project.id === plannerProps.resizingId && 'hidden'
                )}
              >
                <div className='rounded-full bg-palette-text-default opacity-40 w-full h-full' />
              </div>
            </ProjectPalette>
          </ProjectPalette>
        )}
      </div>
    </StageTooltip>
  )
}
