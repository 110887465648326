import { templateStr } from '@motion/ui-logic'

import { useProjectByTaskId } from '~/global/hooks'

import { ActivityLink } from './activity-link'

type ActivityEventCreateProps = {
  meetingTaskId: string
}

export function ActivityEventCreate({
  meetingTaskId,
}: ActivityEventCreateProps) {
  const project = useProjectByTaskId(meetingTaskId)

  if (!project) {
    return null
  }

  return templateStr('added this event to {{link}}', {
    link: (
      <ActivityLink type='project' targetId={project.id}>
        {project.name}
      </ActivityLink>
    ),
  })
}
