import { type TaskSchema } from '@motion/zod/client'

import {
  useResolveTask,
  useStopTask,
  useTaskUpdater,
} from '~/areas/tasks/hooks'
import { useState } from 'react'

export type UseAgendaCurrentTaskActionsProps = {
  task: TaskSchema
}

export type UseAgendaCurrentTaskActionsReturnType = {
  addTime: (duration: number) => void
  stopAndChunk: () => void
  stopAndComplete: () => void
  taskUpdateInFlight: boolean
}

export function useAgendaCurrentTaskActions({
  task,
}: UseAgendaCurrentTaskActionsProps): UseAgendaCurrentTaskActionsReturnType {
  const updateTask = useTaskUpdater()
  const stopTask = useStopTask()
  const { completeTask } = useResolveTask()
  const [taskUpdateInFlight, setTaskUpdateInFlight] = useState(false)

  const stopAndChunk = async () => {
    setTaskUpdateInFlight(true)
    await stopTask(task.id)
    setTaskUpdateInFlight(false)
  }

  const stopAndComplete = async () => {
    setTaskUpdateInFlight(true)
    await completeTask(task.id)
    setTaskUpdateInFlight(false)
  }

  const addTime = async (duration: number) => {
    setTaskUpdateInFlight(true)
    await updateTask(task, {
      duration: (task.duration ?? 0) + duration,
    })
    setTaskUpdateInFlight(false)
  }

  return {
    addTime,
    stopAndChunk,
    stopAndComplete,
    taskUpdateInFlight,
  }
}
