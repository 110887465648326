import { merge } from '@motion/utils/core'
import { type ExperimentValues } from '@motion/web-common/flags'

import { type PMPage } from '~/@types/analytics'
import React from 'react'
import { type LoaderFunction, Outlet, type RouteObject } from 'react-router'

import { RouteWrapper } from './components/route-wrapper'
import { join } from './internal'

import { type Events } from '../analyticsEvents'
import { type TreatmentName } from '../localServices/experiments/types'
import { type AmplitudeExperimentName } from '../services/amplitudeFeatureFlagsService/constants'

export type RouteFeatureFlag = {
  name: AmplitudeExperimentName
  variant?: TreatmentName | TreatmentName[]
  negate?: boolean
}

export type RouteFeatureFlagConfig = AmplitudeExperimentName | RouteFeatureFlag
type WrappedLoaderFunction = (
  args: Parameters<LoaderFunction>[0] & { featureFlags: ExperimentValues }
) => ReturnType<LoaderFunction>

export type MotionRoute = Omit<RouteObject, 'children'> & {
  children?: MotionRoute[]
  featureFlag?: RouteFeatureFlagConfig
  metric?: Events
  tags?: Record<string, string> & { page?: PMPage }

  params?: Record<string, string>

  routing?: MotionRelativeRoute
}

export type MotionRelativeRoute = {
  relative: boolean
  template: string
  defaults: Record<string, string>
}

export type MotionRouteHandle = {
  featureFlag?: RouteFeatureFlag
  metric?: Events
}

export type RouteContext = {
  featureFlags: ExperimentValues
  parentPath: string
}
type InternalRouteContext = RouteContext & {
  parent: RouteObject
  collect(route: RouteObject): void
}

export function lazyRoute<TImport, TComponentExportName extends keyof TImport>(
  fn: () => Promise<TImport>,
  componentName: TComponentExportName
) {
  return async () => {
    const moduleExports = await fn()
    return {
      Component: moduleExports[componentName],
    }
  }
}

type InitializeRoutesReturn = {
  tree: RouteObject[]
  flat: RouteObject[]
}

export const initializeRoutes = (
  ctx: RouteContext,
  routes: MotionRoute[]
): InitializeRoutesReturn => {
  const flat: RouteObject[] = []

  const tree = resolveMany(
    { ...ctx, parent: {}, collect: (route) => flat.push(route) },
    routes
  )
  return { tree, flat }
}

function shouldWrapElement(route: MotionRoute) {
  const shouldWrap = route.metric != null
  return shouldWrap
}

function wrapLazy(ctx: RouteContext, def: MotionRoute) {
  const { lazy } = def
  if (!lazy) return undefined
  if (!shouldWrapElement(def)) return lazy

  return async () => {
    const lazyResult = await lazy()
    const { element, Component, loader, ...otherProps } = lazyResult

    if (Component != null) {
      return {
        ...otherProps,
        ...(loader ? { loader: wrapLoader(ctx, loader) } : {}),
        element: React.createElement(RouteWrapper, {
          metric: def.metric,
          element: React.createElement(
            Component,
            {},
            React.createElement(Outlet)
          ),
        }),
      }
    }

    if (element != null) {
      return {
        ...otherProps,
        ...(loader ? { loader: wrapLoader(ctx, loader) } : {}),
        element: React.createElement(RouteWrapper, {
          metric: def.metric,
          element,
        }),
      }
    }

    return {
      ...otherProps,
      ...(loader ? { loader: wrapLoader(ctx, loader) } : {}),
      element: React.createElement(RouteWrapper, {
        metric: def.metric,
        element: React.createElement(Outlet),
      }),
    }
  }
}

function resolve(
  ctx: InternalRouteContext,
  def: MotionRoute
): RouteObject | null {
  if (evaluateFeatureFlag(ctx, def.featureFlag) === false) {
    return null
  }

  const {
    featureFlag,
    children,
    metric,
    element,
    handle,
    lazy: originalLazy,
    tags,
    loader,
    ...props
  } = def

  const uriTemplate = join(ctx.parentPath, def.path)

  const lazy = wrapLazy(ctx, def)
  const route = {
    ...props,
    ...(loader ? { loader: wrapLoader(ctx, loader) } : {}),
    element:
      def.element && shouldWrapElement(def)
        ? React.createElement(RouteWrapper, { metric, element })
        : element,
    lazy,
    handle: {
      ...handle,
      routing: {
        ...def.routing,
        template: def.routing?.template
          ? join(uriTemplate, def.routing.template)
          : undefined,
      },
      featureFlag: normalizeFeatureFlagConfig(featureFlag),
      metric,
      template: uriTemplate,
      tags,
      params: merge({}, ctx.parent.handle?.params, def.params),
    },
  } as RouteObject
  ctx.collect(route)
  route.children =
    children != null
      ? resolveMany(
          { ...ctx, parent: route, parentPath: uriTemplate },
          children
        )
      : undefined
  return route
}

function normalizeFeatureFlagConfig(
  config: RouteFeatureFlagConfig | undefined
): RouteFeatureFlag | undefined {
  if (typeof config === 'string') {
    return { name: config, variant: 'on' }
  }
  return config
}

function resolveMany(
  ctx: InternalRouteContext,
  routes: MotionRoute[] | undefined
): RouteObject[] {
  if (routes == null) return []
  return routes.map((r) => resolve(ctx, r)).filter(Boolean) as RouteObject[]
}

const DEFAULT_ON_VARIANTS = ['on', 'treatment']
const DEFAULT_OFF_VARIANTS = ['off', 'control']

function evaluateFeatureFlag(
  ctx: InternalRouteContext,
  config: RouteFeatureFlagConfig | undefined
) {
  if (config == null) return true

  if (typeof config === 'string') {
    const flag = ctx.featureFlags[config]
    if (!flag || DEFAULT_OFF_VARIANTS.includes(flag.value)) return false
    return DEFAULT_ON_VARIANTS.includes(flag.value)
  }

  const flagsToCheck =
    config.variant == null
      ? DEFAULT_ON_VARIANTS
      : Array.isArray(config.variant)
        ? config.variant
        : [config.variant]

  const flag = ctx.featureFlags[config.name]

  const flagMatches = flag != null && flagsToCheck.includes(flag.value)
  return config.negate ? !flagMatches : flagMatches
}

function wrapLoader(
  ctx: RouteContext,
  loaderFn: boolean | LoaderFunction | undefined
): WrappedLoaderFunction | undefined {
  if (typeof loaderFn !== 'function') return
  return (args) => {
    return loaderFn({ ...args, featureFlags: ctx.featureFlags })
  }
}
