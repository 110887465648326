import { classed } from '@motion/theme'
import { getDisplayableLink } from '@motion/ui-logic'
import { canParseUrl } from '@motion/utils/string'

export function UrlLabel({ value }: { value: string }) {
  return canParseUrl(value) ? (
    <a
      href={value}
      target='_blank'
      rel='noreferrer'
      className='hover:text-semantic-primary-text-default'
    >
      {getDisplayableLink(value)}
    </a>
  ) : (
    <Truncate>{value}</Truncate>
  )
}

export const Truncate = classed('div', 'truncate text-sm font-medium')
