import { type ForwardedRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { BaseButton } from '../BaseButton'
import { type ButtonProps } from '../ButtonDefaults'

/**
 * @deprecated Use `<Button>` from @motion/ui
 */
export const PrimaryButton = forwardRef(
  (
    props: Omit<ButtonProps & JSX.IntrinsicElements['button'], 'ref'>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { className, ...theirProps } = props

    const baseClasses =
      'bg-primary-400 enabled:focus:outline-none enabled:focus:outline-offset-0 enabled:focus-visible:outline-primary-200 enabled:active:outline-primary-200 enabled:hover:bg-primary-600 text-dark-100'

    return (
      <BaseButton
        ref={ref}
        className={twMerge('border-transparent', baseClasses, className)}
        {...theirProps}
      />
    )
  }
)
