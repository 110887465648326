import { classed } from '@motion/theme'

export const Container = classed(
  'div',
  'w-[450px] flex flex-col gap-4 text-semantic-neutral-text-default'
)

export const Question = classed('p', 'text-sm')

export const LabelContainer = classed(
  'div',
  'flex flex-col gap-1 text-semantic-neutral-text-default text-sm'
)
