import { type Row } from '@tanstack/react-table'
import { type ProjectWithRelations } from '~/global/proxies'
import { memo } from 'react'

import { ProjectsNoDatesDropdown } from './projects-no-dates-dropdown'

import { TabHeader } from '../../../../../components'
import { type GroupedNode } from '../../../../../grouping'

type LaneNameProps = {
  row: Row<any>
  topOffset: number
}

export const LaneName = memo(function LaneName({
  row,
  topOffset,
}: LaneNameProps) {
  if (!row) return null

  const projects = row.original.children?.map(
    (project: GroupedNode) => project.value.value
  ) as ProjectWithRelations[]

  const projectsWithoutDates = projects.filter(
    (project) => !project.startDate && !project.dueDate && project.id
  )

  return (
    <div
      className='absolute flex items-start w-[200px] max-w-[200px] cursor-pointer bg-semantic-neutral-surface-bg-default pt-3 pb-1 px-4 flex-col gap-0.5 h-fit'
      style={{ top: topOffset }}
    >
      <div className='truncate max-w-full shrink-0'>
        <TabHeader item={{ ...row.original, item: row.original }} active />
      </div>
      <ProjectsNoDatesDropdown projects={projectsWithoutDates} />
    </div>
  )
})
