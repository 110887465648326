import { TextField } from '@motion/ui/forms'
import { Sentry } from '@motion/web-base/sentry'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useEffect, useRef, useState } from 'react'

import { PMOnboardingProgressBar } from './shared/pm-onboarding-progress-bar'
import { WorkspaceTable } from './shared/WorkspaceTable'
import { type Workspace } from './types'

import { PrimaryButton, TextButton } from '../../../components/Common'

type CreateProjectProps = {
  workspace: Workspace
  onUpdateProjectName: (projectName: string) => void
  onComplete: () => Promise<any>
  onBack: () => void
}

export function CreateProject({
  workspace,
  onUpdateProjectName = () => {},
  onComplete = async () => {},
  onBack = () => {},
}: CreateProjectProps) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const inputElement = useRef<null | HTMLInputElement>(null)

  const { selectIsOnboarding } = useOnboardingApi()
  const isOnboarding = selectIsOnboarding()

  useEffect(function autoFocus() {
    if (inputElement.current) {
      inputElement.current.focus()
    }
  }, [])

  function onSubmit() {
    setLoading(true)

    onComplete().catch((error) => {
      Sentry.captureException(error, {
        tags: { position: 'onboardingCreateProject' },
      })

      setError(error.message)
      setLoading(false)
    })
  }

  return (
    <div className='lg:mt-12 h-full w-full'>
      <div className='mt-4 lg:mt-0 flex lg:h-full'>
        <div className='flex flex-1 lg:-translate-y-16 flex-col items-center lg:justify-center'>
          <div className='px-6 lg:px-0 w-full lg:w-[400px]'>
            {!isOnboarding && <PMOnboardingProgressBar numFilled={2} />}
            <h1 className='text-lg lg:text-[28px] lg:leading-normal font-semibold select-none text-semantic-neutral-text-default'>
              Create your first project
            </h1>

            <p className='text-semantic-neutral-text-subtle mt-4 max-w-[330px] text-base'>
              Create a project that you and your team are currently working on.
            </p>

            <div className='mt-4'>
              <div>
                <TextField
                  autoFocus
                  placeholder='Enter project title'
                  value={workspace.projectName}
                  onChange={onUpdateProjectName}
                />
                {error && (
                  <div className='break-words px-2 py-2 pr-20'>
                    <p className='text-sm text-red-600'>{error}</p>
                  </div>
                )}
              </div>

              <div className='mt-4 flex justify-between flex-col-reverse lg:flex-row gap-3 lg:gap-0'>
                <TextButton onClick={onBack}>Back</TextButton>
                <PrimaryButton
                  onClick={onSubmit}
                  loading={loading}
                  disabled={!workspace.projectName.trim().length}
                >
                  Next
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>

        <div className='h-full flex-[1.2_1.2_0%] hidden lg:flex'>
          <WorkspaceTable workspace={workspace} step='projectName' />
        </div>
      </div>
    </div>
  )
}
