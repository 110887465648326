import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import {
  type NormalTaskWithRelations,
  type ProjectWithRelations,
} from '~/global/proxies'

import { getCustomFieldValueTotalMeta } from './utils'

import { type CellRenderFn } from '../lookup'

type CellWrapperReturn =
  | ((
      | {
          task: NormalTaskWithRelations | null
        }
      | {
          project: ProjectWithRelations | null
        }
    ) & {
      customFieldId: string
      disabled: boolean
      totalMeta?: {
        count: number | string
        suffix?:
          | string
          | (Partial<Record<Intl.LDMLPluralRule, string>> & { other: string })
          | null
      }
    })
  | null

export const useCustomFieldCellWrapper = ({
  info,
}: {
  info: Parameters<CellRenderFn>[0]
}): CellWrapperReturn => {
  const { value: p } = info.row.original

  const { getCustomField } = useCustomFieldsFns()

  if (
    (p.type === 'task-totals' || p.type === 'project-totals') &&
    p.value.addItemValue == null
  ) {
    const columnId = info.column.id

    const customFieldId = columnId.split('_').pop()
    if (customFieldId == null) return null
    const customField = getCustomField(customFieldId)
    if (customField == null) return null

    if (p.type === 'task-totals') {
      const totalMeta = getCustomFieldValueTotalMeta(
        p.value.items.filter(
          (item) => item.type === 'NORMAL'
        ) as NormalTaskWithRelations[],
        customField,
        info.column.columnDef.meta?.customFields?.workspaceIdToFieldId
      )
      if (totalMeta == null) return null

      return { task: null, customFieldId, disabled: false, totalMeta }
    }

    const totalMeta = getCustomFieldValueTotalMeta(
      p.value.items,
      customField,
      info.column.columnDef.meta?.customFields?.workspaceIdToFieldId
    )
    if (totalMeta == null) return null

    return { project: null, customFieldId, disabled: false, totalMeta }
  }

  if (
    p.type === 'task-totals' &&
    p.value.addItemValue != null &&
    p.value.addItemValue.type === 'NORMAL'
  ) {
    const task = p.value.addItemValue
    const customFieldId = getCustomFieldIdFromMeta(task.workspaceId, info)
    if (customFieldId == null) return null

    return { task, customFieldId, disabled: false }
  }

  if (p.type === 'project-totals' && p.value.addItemValue != null) {
    const project = p.value.addItemValue
    const customFieldId = getCustomFieldIdFromMeta(project.workspaceId, info)
    if (customFieldId == null) return null

    return { project, customFieldId, disabled: false }
  }

  if (p.type === 'task') {
    if (p.value.type !== 'NORMAL') return null

    const task = p.value as NormalTaskWithRelations
    const customFieldId = getCustomFieldIdFromMeta(task.workspaceId, info)
    if (customFieldId == null) return null

    return { task, customFieldId, disabled: task.archivedTime != null }
  }

  if (p.type === 'project') {
    const project = p.value
    if (project == null) return null

    const customFieldId = getCustomFieldIdFromMeta(project.workspaceId, info)
    if (customFieldId == null) return null

    return { project, customFieldId, disabled: false }
  }

  return null
}

const getCustomFieldIdFromMeta = (
  workspaceId: string,
  info: Parameters<CellRenderFn>[0]
) => {
  const workspaceIdToFieldId =
    info.column.columnDef.meta?.customFields?.workspaceIdToFieldId
  if (workspaceIdToFieldId == null) return null

  const customFieldId = workspaceIdToFieldId[workspaceId]

  if (customFieldId == null) return null

  return customFieldId
}
