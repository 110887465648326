import { type ProjectSchema } from '@motion/zod/client'

import { useProject } from '~/global/hooks'
import { useParams } from 'react-router-dom'

import { findInParentGroups } from './utils'

import { type GroupedNode } from '../../../grouping'

export function useProjectInTree(row?: GroupedNode): ProjectSchema | null {
  const groupProject = row ? findInParentGroups('project', row) : null

  const { projectId } = useParams<{
    projectId?: string
  }>()
  const projectFromParams = useProject(projectId)

  return groupProject ?? projectFromParams
}
