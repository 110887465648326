import { systemData } from '@motion/web-base/env'

import { copyToClipboard } from '~/localServices/clipboard'

import { BackLabel } from './common-labels'
import { type DevToolsPages } from './types'

export function useUserAgentItems(
  setCurrentPage: (page: DevToolsPages) => void
) {
  return [
    [
      {
        onSelect: () => {
          setCurrentPage('main')
        },
        label: <BackLabel />,
        key: 'Back',
        clearSearch: true,
      },
      {
        onSelect: () => {
          copyToClipboard({
            text: systemData.experience,
          })
        },
        label: `experience: ${systemData.experience}`,
        key: 'experience',
      },
      {
        onSelect: () => {
          copyToClipboard({
            text: systemData.host,
          })
        },
        label: `host: ${systemData.host}`,
        key: 'host',
      },
      {
        onSelect: () => {
          copyToClipboard({
            text: systemData.os,
          })
        },
        label: `os: ${systemData.os}`,
        key: 'os',
      },
    ],
  ]
}
