import { type PMTaskType } from '@motion/rpc/types'
import { generateIntervalOptionsFromDate } from '@motion/ui-logic/calendar'
import { pick } from '@motion/utils/object'
import {
  type CreateTaskForTemplateSchema,
  type CreateTeamTaskSchema,
  type UpdateTeamTaskSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import { type TemplateTaskFormFields } from './types'

import { TIME_FORMAT } from '../../utils/constants'

export const halfHourTimeChoices = createHalfHourTimeChoices()

/**
 * Returns a list of half hour time choices between start and end
 * @param start start time in format 'h:mm a
 * @param end end time in format 'h:mm a
 * @returns array of strings (eg. ['12:00 am', '12:30 am', ...])
 */
export function createHalfHourTimeChoices(
  start = '12:00 am',
  end = '11:59 pm'
) {
  const startDate = DateTime.fromFormat(start, TIME_FORMAT)
  const deadlineDate = DateTime.fromFormat(end, TIME_FORMAT)

  const options = generateIntervalOptionsFromDate({
    startDate,
    endDate: deadlineDate,
    intervalMinutes: 30,
  })

  const values = options.map((c) => c.label.toLocaleLowerCase())

  return [...values, end]
}

export function convertToUpdateTeamTaskSchema(
  fields: TemplateTaskFormFields
): Omit<UpdateTeamTaskSchema, 'id'> {
  return convertToCreateTeamTaskSchema(fields)
}

export function convertToCreateTeamTaskSchema(
  fields: TemplateTaskFormFields
): CreateTeamTaskSchema {
  return {
    ...pick(fields, [
      'workspaceId',
      'projectId',
      'isAutoScheduled',
      'statusId',
      'name',
      'description',
      'startDate',
      'duration',
      'priorityLevel',
    ]),
    assigneeUserId: fields.assigneeId,
    dueDate: fields.deadlineDate,
    minimumDuration: fields.minChunkDuration,
    schedule: fields.scheduleId,
    labels: fields.labelIds.map((labelId) => ({ labelId })),
  }
}

export function convertTaskToCreateTaskForTemplate(
  task: PMTaskType
): CreateTaskForTemplateSchema {
  return {
    ...pick(task, [
      'id',
      'name',
      'description',
      'assigneeUserId',
      'workspaceId',
      'statusId',
      'priorityLevel',
      'schedule',
      'duration',
      'minimumDuration',
      'labels',
      'createdTime',
      'createdByUserId',
      'isAutoScheduled',
      'blockingTasks',
    ]),
  }
}
