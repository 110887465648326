import { type Row } from '@tanstack/react-table'

export const showOOOEventForRow = (row: Row<any>) => {
  if (!row?.original?.value) return false
  if (row.original.value.key === 'Unassigned') return false
  return (
    row.original.value.type === 'user' ||
    (row.original.value.type.toLowerCase().split('/')[0] ?? '').includes(
      'person'
    )
  )
}
