import type { TaskSchema } from '@motion/rpc-types'
import { LoadingSpinner, Tooltip } from '@motion/ui/base'

import {
  ConnectedAssigneeBadge,
  ConnectedStatusBadge,
} from '~/global/components/badges'

import { BlockerRow, BlockersInfoContainer } from './styled'

import { useBlockerTasks } from '../../hooks'

const showNameTooltip = (name: string) => name.length > 50

export const BlockersInfo = ({ task }: { task: TaskSchema }) => {
  const { isLoading, blockedBy } = useBlockerTasks({
    taskId: task.id,
  })

  if (isLoading) {
    return (
      <div className='flex justify-center'>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <BlockersInfoContainer>
      {blockedBy.map((b) => (
        <BlockerRow key={b.id}>
          <div className='flex items-center gap-1.5 max-w-[350px]'>
            <ConnectedStatusBadge id={b.statusId} />

            <Tooltip
              asChild
              content={showNameTooltip(b.name) ? b.name : undefined}
            >
              <span className='truncate'>{b.name}</span>
            </Tooltip>
          </div>

          <ConnectedAssigneeBadge userId={b.assigneeUserId} size='large' />
        </BlockerRow>
      ))}
    </BlockersInfoContainer>
  )
}
