import { showToast } from '@motion/ui/base'

import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { type FormEvent, useCallback } from 'react'

import { useActiveTab } from './use-active-tab'
import { useSetupProjectForm } from './use-setup-project-form'
import { useSubmitSetupProjectForm } from './use-submit-setup-project-form'

export function useSetupProjectModalSubmitHandler() {
  const { form } = useSetupProjectForm()
  const submitForm = useSubmitSetupProjectForm()
  const activeTab = useActiveTab()

  return useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault()
      if (activeTab === 'name') return

      await form.handleSubmit(
        (values) => {
          const nameError = checkTextRuleErrors(
            values.name,
            getTextFieldRules('Project title')
          )
          if (nameError) {
            showToast('error', nameError)
            return
          }

          return submitForm(values)
        },
        (validationErrors) => {
          const errors = Object.values(validationErrors)
          if (errors.length < 1) return

          const message = errors[0].message
          if (typeof message !== 'string' || !message) return

          showToast('error', message)
        }
      )(e)
    },
    [form, submitForm, activeTab]
  )
}
