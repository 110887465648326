import { twMerge } from 'tailwind-merge'

type PMOnboardingProgressBarProps = {
  numFilled: number
}

export const PMOnboardingProgressBar = ({
  numFilled,
}: PMOnboardingProgressBarProps) => {
  return (
    <div className='flex flex-row items-center gap-2 mb-3'>
      {[...Array(3)].map((_, i) => (
        <div
          className={twMerge(
            'h-[8px] w-[77px] bg-light-1000 rounded-full',
            i < numFilled
              ? 'bg-semantic-neutral-border-focus'
              : 'bg-semantic-neutral-border-strong'
          )}
          key={i}
        />
      ))}
    </div>
  )
}
