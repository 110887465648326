import { PMItemType, type PMTaskType } from '@motion/rpc/types'
import { type UpdateTeamTaskDto } from '@motion/rpc-types'
import { getAssigneeChangedFields } from '@motion/ui-logic/pm/task'

import { useWorkspaceMember } from '~/global/hooks'
import { useCallback } from 'react'

import { useTemplateTaskUpdate } from './use-template-task-update'

export const useTemplateTaskAssigneeDropdown = (task: PMTaskType) => {
  const updateTemplateTask = useTemplateTaskUpdate()

  const onChange = useCallback(
    async (assigneeUserId: UpdateTeamTaskDto['assigneeUserId']) => {
      const updates = {
        assigneeUserId,
        ...getAssigneeChangedFields({ ...task, assigneeUserId }),
      }

      await updateTemplateTask(task, updates)
    },
    [updateTemplateTask, task]
  )

  const isRecurringTask = task?.itemType === PMItemType.recurringTask

  const selectedAssignee = useWorkspaceMember(
    task.workspaceId,
    task.assigneeUserId
  )

  return {
    onChange,
    removeUnassignedOption: isRecurringTask,
    selectedAssignee,
    workspaceId: task.workspaceId,
  }
}
