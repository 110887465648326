import { PlusSolid } from '@motion/icons'
import { ActionList, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'
import {
  type LabelSchema,
  type NormalTaskSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useWorkspaceLabels } from '~/global/hooks'
import { type ReactNode } from 'react'

import { Label } from '../labels'

export type LabelDropdownProps = Omit<LabelDropdownContentProps, 'close'> & {
  children?: ReactNode
}

export const LabelDropdown = ({
  children,
  selectedLabelIds,
  workspaceId,
  onChange,
}: LabelDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <LabelDropdownContent
          close={close}
          selectedLabelIds={selectedLabelIds}
          workspaceId={workspaceId}
          onChange={onChange}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type LabelDropdownContentProps = {
  close: () => void
  workspaceId: WorkspaceSchema['id']
  selectedLabelIds: NormalTaskSchema['labelIds']
  onChange: (labelIds: NormalTaskSchema['labelIds']) => void
}
const LabelDropdownContent = ({
  close,
  workspaceId,
  selectedLabelIds,
  onChange,
}: LabelDropdownContentProps) => {
  const modalApi = useModalApi()
  const labels = useWorkspaceLabels(workspaceId)

  const openAddLabelModal = () => {
    setTimeout(async () => {
      await modalApi.prompt('add-label', {
        workspaceId,
        onValue: (createdLabel) => {
          onChange([...selectedLabelIds, createdLabel.id])
        },
      })
    }, 200)
  }

  const computeSelected = (item: LabelSchema) =>
    selectedLabelIds.includes(item.id)

  return (
    <>
      {labels.length === 0 ? (
        <ActionList
          items={[
            {
              content: 'No labels found',
              onAction: close,
              disabled: true,
            },
          ]}
        />
      ) : (
        <SearchableList
          itemType='checkbox'
          items={labels}
          computeSelected={computeSelected}
          computeKey={(item) => item.id}
          computeSearchValue={(item) => item.name}
          renderItem={(item) => <Label value={item} />}
          onSelect={(items) => {
            onChange(items.map((item) => item.id))
          }}
        />
      )}
      <div className='flex flex-col border-t border-dropdown-border mt-1'>
        <ActionList
          onActionAnyItem={close}
          items={[
            {
              prefix: <PlusSolid />,
              content: 'Add label',
              onAction: openAddLabelModal,
            },
          ]}
        />
      </div>
    </>
  )
}
