import { parse } from '@motion/ui-logic'

import { ViewStateSchema } from './schema'

import { type ViewState } from '../types'

export function deserialize(raw: string): ViewState | undefined {
  return parse(ViewStateSchema, raw, migrateFromPrevious)
}

export function serialize(value: ViewState): string {
  return JSON.stringify(value)
}

const MIGRATIONS = [(obj: any) => obj, toV2, toV3, toV4, toV5, toV6]

function migrateFromPrevious(obj: any): ViewState | undefined {
  if (obj == null) return undefined

  const version = obj.$version ?? 1
  for (let i = version; i < MIGRATIONS.length; i++) {
    obj = MIGRATIONS[i](obj)
    if (obj == null) return undefined
  }

  return obj
}

export function toV2(obj: any) {
  obj.$version = 2
  if (obj.columns.length > 0) {
    // fixes invalid state in v1
    obj.columns = []
  }
  return obj
}

export function toV3(obj: any) {
  obj.$version = 3
  const groupOrder = [...obj.groupBy]
  obj.groupBy = {
    fields: groupOrder.map((g) => g.id),
    order: {},
    hideEmpty: obj.hideEmptyGroups,
    stackProjects: obj.stackProjects ?? true,
  }
  delete obj.hideEmptyGroups
  return obj
}

export function toV4(obj: any) {
  obj.$version = 4
  const fields = [...obj.groupBy.fields].map((f) => f.replace('default/', ''))
  obj.groupBy = {
    ...obj.groupBy,
    fields,
  }
  return obj
}

export function toV5(obj: any): ViewState | undefined {
  obj.$version = 5
  const fields = [...obj.groupBy.fields].map((f) => ({ key: f }))
  obj.groupBy = {
    ...obj.groupBy,
    fields,
  }
  return obj
}

export function toV6(obj: any) {
  obj.$version = 6
  obj.groupBy.dateRange = 'quarter'
  return obj
}
