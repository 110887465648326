import {
  type CustomFieldSchema,
  type CustomFieldValuesSchema,
} from '@motion/shared/custom-fields'
import { type CustomFieldFieldArrayValue } from '@motion/ui-logic'

import { type Control, useFieldArray } from 'react-hook-form'

import { CustomFieldsSidebar } from './custom-fields-sidebar'

export type ControlledCustomFieldsSidebarProps = {
  control: Control<{
    customFieldValuesFieldArray: CustomFieldFieldArrayValue[]
  }>
  onChange?: (
    customFieldId: CustomFieldSchema['id'],
    valueArg: CustomFieldValuesSchema['value']
  ) => void
  workspaceId: string
  disabled?: boolean
}

export function ControlledCustomFieldsSidebar({
  control,
  workspaceId,
  disabled = false,
  onChange,
}: ControlledCustomFieldsSidebarProps) {
  const { fields, update } = useFieldArray({
    control,
    name: 'customFieldValuesFieldArray',
  })

  return (
    <CustomFieldsSidebar
      fields={fields}
      workspaceId={workspaceId}
      disabled={disabled}
      onChange={(customField, value, index) => {
        if (onChange != null) {
          return onChange(
            customField.instanceId,
            value as CustomFieldValuesSchema['value']
          )
        }

        update(index, {
          ...customField,
          value: value as any,
        })
      }}
    />
  )
}
