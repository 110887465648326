import { ActionList, PopoverTrigger, UserAvatar } from '@motion/ui/base'

import { useProfilePictureUrl } from '~/global/hooks'
import { type User } from '~/state/userSlice'
import { useRef, useState } from 'react'

type AddPhotoOverlayProps = {
  onRemovePhoto: () => void
  onUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  user: User
}
export function ProfilePhoto({
  onRemovePhoto,
  onUpload,
  user,
}: AddPhotoOverlayProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [isProfilePhotoHovered, setIsProfilePhotoHovered] = useState(false)
  const profileUrl = useProfilePictureUrl(user)
  const hasProfilePhoto =
    Boolean(profileUrl) && profileUrl?.includes('/profile/picture')

  const Content = (
    <div
      className='relative cursor-pointer rounded-full'
      onMouseEnter={() => setIsProfilePhotoHovered(true)}
      onMouseLeave={() => setIsProfilePhotoHovered(false)}
    >
      <UserAvatar
        id={user.id}
        name={user.displayName.trim() || user.email}
        profileUrl={profileUrl}
        size={56}
      />
      {isProfilePhotoHovered && (
        <span
          className='flex items-center absolute w-full h-full top-0 left-0 text-center rounded-full hover:bg-modal-overlay text-white text-[11px] font-bold'
          onClick={() => {
            if (hasProfilePhoto) {
              return setIsProfilePhotoHovered(false)
            }
            fileInputRef?.current?.click()
          }}
        >
          {hasProfilePhoto ? 'Change photo' : 'Add photo'}
        </span>
      )}
    </div>
  )

  return (
    <>
      {hasProfilePhoto ? (
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => (
            <ActionList
              items={[
                {
                  content: 'Change photo',
                  onAction: () => {
                    close()
                    fileInputRef?.current?.click()
                  },
                },
                {
                  content: 'Remove photo',
                  onAction: () => {
                    close()
                    onRemovePhoto()
                  },
                },
              ]}
            />
          )}
        >
          {Content}
        </PopoverTrigger>
      ) : (
        Content
      )}
      <input
        hidden
        ref={fileInputRef}
        type='file'
        accept='image/*'
        onChange={onUpload}
      />
    </>
  )
}
