import { type ForwardedRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { BaseButton } from '../BaseButton'
import { type ButtonProps } from '../ButtonDefaults'

type TextButtonProps = {
  colorClass?: string
  buttonType?: 'primary' | 'destructive' | 'grey'
}

/**
 * @deprecated Use `<Button>` from @motion/ui
 */
export const TextButton = forwardRef(
  (
    {
      buttonType = 'grey',
      ...props
    }: Omit<
      ButtonProps & JSX.IntrinsicElements['button'] & TextButtonProps,
      'ref'
    >,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { className, isPadded = false, ...theirProps } = props

    let lightClasses = ''
    let darkClasses = ''
    let coloredClasses = ''
    switch (buttonType) {
      case 'grey':
        lightClasses =
          'text-light-1100 hover:text-dark-1200 enabled:focus:outline-none enabled:focus:outline-offset-0 enabled:focus-visible:outline-primary-200 enabled:active:outline-primary-200'
        darkClasses =
          'dark:text-dark-400 dark:enabled:hover:text-dark-300 dark:enabled:focus:outline-none dark:enabled:focus:outline-offset-0 dark:enabled:focus-visible:outline-dark-400 dark:enabled:active:outline-dark-400'
        break

      case 'primary':
        coloredClasses =
          'text-primary-400 hover:text-primary-300 enabled:focus:outline-none enabled:focus:outline-offset-0 enabled:focus-visible:outline-primary-200 enabled:active:outline-primary-200'
        break
      case 'destructive':
        coloredClasses =
          'text-alert-400 hover:text-alert-300 enabled:focus:outline-none enabled:focus:outline-offset-0 enabled:focus-visible:outline-alert-200 enabled:active:outline-alert-200'
        break
    }

    return (
      <BaseButton
        ref={ref}
        isPadded={isPadded}
        className={twMerge(
          'border-transparent',
          lightClasses,
          darkClasses,
          coloredClasses,
          className
        )}
        {...theirProps}
      />
    )
  }
)
TextButton.displayName = 'TextButton'
