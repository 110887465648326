import { useMemo } from 'react'

import { useStateScheduledEntities } from '../../hooks'

const AGENDA_ENTITY_TYPES = ['TASK', 'CHUNK', 'EVENT']

/*
 * Hook for fetching scheduled entities with the filter options from the calendar state
 */
export const useAgendaScheduledEntities = () => {
  const { data } = useStateScheduledEntities()

  return useMemo(() => {
    if (!data) return []

    return data.scheduledEntities.filter((entity) => {
      if (!AGENDA_ENTITY_TYPES.includes(entity.type)) return false

      // Only return entities that are in the users calendar
      if (entity.type === 'EVENT') {
        return entity.calendarIds.some(
          (calendarId) => data.models.calendars[calendarId]?.isInMyCalendars
        )
      }

      return true
    })
  }, [data])
}
