import { PlusSolid } from '@motion/icons'
import { ButtonGroup, GradientButton, Shortcut, Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'

import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'
import { selectDesktopSettings } from '~/state/desktopSlice'
import { useAppSelector } from '~/state/hooks'

const DEFAULT_SHORTCUT = 'alt+space'

export function AddTaskProjectButtons() {
  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const { isElectron } = getWindowData()
  const desktopSettings = useAppSelector(selectDesktopSettings)

  const shouldUseDesktopShortcut =
    isElectron && desktopSettings?.shortcuts?.addTask

  const keysToUse = shouldUseDesktopShortcut
    ? desktopSettings.shortcuts.addTask
    : DEFAULT_SHORTCUT

  return (
    <div className='w-full px-3'>
      <ButtonGroup nowrap stretch>
        <Tooltip
          asChild
          renderContent={() =>
            templateStr('Press {{keys}} to create a task', {
              keys: <Shortcut key={keysToUse} shortcut={keysToUse} />,
            })
          }
        >
          <GradientButton
            size='small'
            fullWidth
            url={buildTaskModalUrl()}
            onClick={() => {
              recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
                type: 'task',
              })
            }}
          >
            <PlusSolid />
            Task
          </GradientButton>
        </Tooltip>

        <GradientButton
          size='small'
          fullWidth
          url={buildProjectModalUrl()}
          onClick={() => {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD', {
              type: 'project',
            })
          }}
        >
          <PlusSolid />
          Project
        </GradientButton>
      </ButtonGroup>
    </div>
  )
}
