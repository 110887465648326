import { isStageCanceled } from '@motion/ui-logic/pm/project'
import type { StageSchema } from '@motion/zod/client'

export function resolveStageProjectedDate(
  stages: StageSchema[],
  stageIndex: number
) {
  const stage = stages[stageIndex]
  const previousStage = stageIndex - 1 >= 0 ? stages[stageIndex - 1] : null

  if (stage.estimatedCompletionTime != null && !isStageCanceled(stage)) {
    return stage.estimatedCompletionTime
  }

  if (previousStage != null && previousStage.estimatedCompletionTime != null) {
    return previousStage.estimatedCompletionTime
  }

  return stage.dueDate
}
