// State file to keep track of the tasks selected, loading, etc.

import {
  createStateKey,
  type SharedStateSetterValue,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { type TaskSchema } from '@motion/rpc-types'

import { type TaskWithRelations } from '~/global/proxies'
import { useCallback, useMemo } from 'react'

export type PastDueState = {
  // Quick lookup for selected tasks
  selectedTasks: TaskWithRelations[]
  // Individual task loading state (separated from the main loading state)
  taskLoadingState: Record<string, boolean>
}

export const PAST_DUE_STATE_DEFAULT_VALUE: PastDueState = {
  selectedTasks: [],
  taskLoadingState: {},
}

export const PastDueStateKey = createStateKey<PastDueState>('pastDueState', {
  defaultValue: PAST_DUE_STATE_DEFAULT_VALUE,
})

export const useSendPastDueState = () => {
  const setState = useSharedStateSendOnly(PastDueStateKey)
  return useCallback(
    (changes: Partial<PastDueState> | SharedStateSetterValue<PastDueState>) => {
      if (typeof changes === 'function') {
        setState(changes)
        return
      }
      setState((prev) => ({ ...prev, ...changes }))
    },
    [setState]
  )
}

export const usePastDueState = () => useSharedState(PastDueStateKey)

export const useSelectedTasksById = () => {
  const [{ selectedTasks }] = usePastDueState()

  return useMemo(() => {
    return selectedTasks.reduce(
      (acc, task) => {
        acc[task.id] = task
        return acc
      },
      {} as Record<string, TaskWithRelations>
    )
  }, [selectedTasks])
}

export const useTasksLoadingState = (tasks: TaskSchema[]) => {
  const [{ taskLoadingState }, setPastDueState] = usePastDueState()

  const loadingState = useMemo(() => {
    return tasks.some((task) => taskLoadingState[task.id])
  }, [taskLoadingState, tasks])

  const updateLoadingState = useCallback(
    (isLoading: boolean) => {
      const taskIds = tasks.map((task) => task.id)

      setPastDueState((prev) => {
        const taskLoadingState = {
          ...prev.taskLoadingState,
          ...Object.fromEntries(taskIds.map((id) => [id, isLoading])),
        }
        return { ...prev, taskLoadingState }
      })
    },
    [setPastDueState, tasks]
  )

  return [loadingState, updateLoadingState] as const
}
