import { type COLOR } from '@motion/shared/common'
import { classed, type VariantProps } from '@motion/theme'
import { HoverRow, useContextMenu } from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import {
  canEditTaskDeadline,
  canEditTaskStartDate,
  isMeetingTask,
} from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { EventActionList } from '~/areas/event/components'
import {
  TaskActionList,
  TaskChunkBubbleText,
  TaskCompleteButton,
} from '~/areas/tasks/components'
import { MotionLink } from '~/global/components'
import { PriorityBadge } from '~/global/components/badges'
import { useProject } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { type ReactNode, useRef } from 'react'
import { useNavigate } from 'react-router'

import {
  TaskDeadlineBadge,
  TaskItemActionButtons,
  TaskItemActionsDropdown,
  TaskRecurringInstanceIcon,
} from './components'
import { type ActionsButtonProps } from './components/types'

import { BUTTON_ROW_WIDTH_THRESHOLD } from '../constants'

export type TaskItemProps = {
  scheduledTask: ScheduledTaskWithRelation
  alwaysActive?: VariantProps<typeof HoverRowWrapper>['alwaysActive']
  clickable?: boolean
  showCompleteButton?: boolean
  renderTaskItemActionButtons?: (props: {
    scheduledTask: ScheduledTaskWithRelation
  }) => React.ReactNode
}

export function TaskItem({
  scheduledTask,
  alwaysActive = false,
  clickable = true,
  showCompleteButton = true,
  renderTaskItemActionButtons,
}: TaskItemProps) {
  const { task } = scheduledTask

  const project = useProject(task?.projectId)
  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  const taskIsGhostTask = isGhostTask(task)
  const taskIsChunk = scheduledTask.type === 'CHUNK'
  const taskIsRecurringInstance = task?.type === 'RECURRING_INSTANCE'
  const showChunkText = taskIsChunk && scheduledTask.chunkTotal > 1

  const containerRef = useRef<HTMLDivElement>(null)

  const { handleContextMenu, ContextMenuPopover } = useContextMenu()

  if (task == null) return null

  const actualTask = task.type === 'CHUNK' ? task.parentChunkTask : task

  const getTaskItemActionButtons = () => {
    let element: ReactNode
    if (renderTaskItemActionButtons != null) {
      element = renderTaskItemActionButtons({
        scheduledTask,
      })
    } else {
      const buttonProps: ActionsButtonProps = {
        task: actualTask,
        project: project,
        showChangeDeadline: canEditTaskDeadline(task),
        showChangeStartDate: canEditTaskStartDate(task),
        showStartTask: !scheduledTask.completed,
      }

      const containerWidth =
        containerRef.current?.clientWidth ?? BUTTON_ROW_WIDTH_THRESHOLD

      element =
        containerWidth >= BUTTON_ROW_WIDTH_THRESHOLD ? (
          <TaskItemActionButtons {...buttonProps} />
        ) : (
          <TaskItemActionsDropdown {...buttonProps} />
        )
    }

    return (
      <TaskItemActionsContainer {...motionLinkProps}>
        {element}
      </TaskItemActionsContainer>
    )
  }

  const openTask = () => {
    navigate(
      buildTaskModalUrl({
        task: actualTask.id,
      })
    )
  }

  const onComplete = () => {
    recordAnalyticsEvent('AGENDA_TASK_COMPLETE_CLICKED')
  }

  const onClickRow = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (clickable) {
      recordAnalyticsEvent('AGENDA_TASK_CLICKED')
    }
  }

  const motionLinkProps = {
    url: clickable ? buildTaskModalUrl({ task: actualTask.id }) : '#',
    external: false,
    clickable,
    onClick: onClickRow,
    role: clickable ? 'link' : 'presentation',
  }

  return (
    <>
      <HoverRowWrapper
        alwaysActive={alwaysActive}
        ref={containerRef}
        onContextMenu={(e) => {
          recordAnalyticsEvent('AGENDA_SIDEBAR_TASK_RIGHT_CLICK')
          handleContextMenu(e)
        }}
      >
        <HoverRow
          actions={getTaskItemActionButtons()}
          override={alwaysActive ? 'show' : 'default'}
          className='size-full'
        >
          <TaskItemContainer>
            {showCompleteButton && (
              <TaskCompleteButtonContainer>
                <TaskCompleteButton
                  scheduledTask={scheduledTask}
                  openTask={openTask}
                  onComplete={onComplete}
                  borderVariant={taskIsGhostTask ? 'dashed' : 'solid'}
                />
              </TaskCompleteButtonContainer>
            )}
            <TaskDetailsContainer
              {...motionLinkProps}
              completed={scheduledTask.completed}
            >
              <TaskRowContainer>
                <TaskLabelContainer
                  completed={scheduledTask.completed}
                  isGhostTask={taskIsGhostTask}
                >
                  <TaskNameLabel>{task.name}</TaskNameLabel>
                  {showChunkText && (
                    <TaskChunkBubbleText entity={scheduledTask} />
                  )}
                  {!scheduledTask.completed && (
                    <TaskDeadlineBadge scheduledTask={scheduledTask} />
                  )}
                  <PriorityBadge value={task.priorityLevel} size='small' />
                  {taskIsRecurringInstance && (
                    <TaskRecurringInstanceIcon scheduledTask={scheduledTask} />
                  )}
                </TaskLabelContainer>
              </TaskRowContainer>
              <TaskProjectLabel
                completed={scheduledTask.completed}
                isGhostTask={taskIsGhostTask}
              >
                <ProjectColoredIcon
                  noProject={project == null}
                  height={12}
                  width={12}
                  color={(project?.color ?? 'gray') as COLOR}
                />
                <ProjectNameLabel>
                  {project?.name ?? 'No project'}
                </ProjectNameLabel>
              </TaskProjectLabel>
            </TaskDetailsContainer>
          </TaskItemContainer>
        </HoverRow>
      </HoverRowWrapper>
      <ContextMenuPopover
        renderContent={({ close }) =>
          isMeetingTask(task) ? (
            <EventActionList close={close} eventId={task.meetingEventId} />
          ) : (
            <TaskActionList close={close} task={task} />
          )
        }
      />
    </>
  )
}

const HoverRowWrapper = classed('div', {
  base: 'flex size-full',
  variants: {
    alwaysActive: {
      true: '',
      false: 'hover:bg-semantic-neutral-surface-bg-subtlest',
    },
  },
})

const TaskItemContainer = classed('div', {
  base: 'group flex flex-row items-start self-stretch gap-3 pl-3 flex-1 size-full min-w-0',
})

const TaskCompleteButtonContainer = classed('div', {
  base: 'flex mt-3 flex-shrink-0',
})

const TaskDetailsContainer = classed(MotionLink, {
  base: 'flex flex-col flex-grow-1 pr-2 flex-1 min-w-0 size-full py-3 justify-start gap-2',
  variants: {
    completed: {
      true: 'opacity-50',
    },
    clickable: {
      true: 'cursor-pointer',
      false: 'cursor-default',
    },
  },
})

const TaskItemActionsContainer = classed(MotionLink, {
  base: 'flex size-full flex-grow-1 items-start pr-2 pt-2',
  variants: {
    clickable: {
      true: 'cursor-pointer',
      false: 'cursor-default',
    },
  },
})

const TaskRowContainer = classed('div', {
  base: 'flex flex-row justify-between gap-2 w-full',
})

const TaskLabelContainer = classed('div', {
  base: 'flex flex-row gap-1.5 items-center min-w-0',
  variants: {
    completed: {
      true: 'opacity-50',
    },
    isGhostTask: {
      true: 'opacity-70',
    },
  },
})

const TaskNameLabel = classed('span', {
  base: 'truncate text-semantic-neutral-text-default min-w-0 text-[15px] leading-[17px]',
})

const TaskProjectLabel = classed('span', {
  base: 'flex flex-row gap-1 self-stretch items-center',
  variants: {
    completed: {
      true: 'opacity-50',
    },
    isGhostTask: {
      true: 'opacity-70',
    },
  },
})

const ProjectNameLabel = classed('span', {
  base: 'text-semantic-neutral-text-subtle text-[13px] leading-3',
})
