import { useSharedState } from '@motion/react-core/shared-state'

import { useCallback } from 'react'

import { useSelectView } from './use-select-view'

import { TeamScheduleActiveViewOptionsKey } from '../../context'

export const useResetView = () => {
  const [viewState] = useSharedState(TeamScheduleActiveViewOptionsKey)
  const selectView = useSelectView()

  return useCallback(() => {
    selectView(viewState.viewId)
  }, [selectView, viewState.viewId])
}
