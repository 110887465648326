import { isGhostTask } from '@motion/ui-logic/pm/project'
import { type TaskSchema } from '@motion/zod/client'

import { useTaskStatusData } from '~/areas/tasks/hooks'
import { getTaskStatusVariant } from '~/areas/tasks/utils'
import { type StatusBadgeProps } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'

export const useStatusDropdown = (task: TaskSchema) => {
  const { onChange, disabled, ...rest } = useTaskStatusData(task)
  const value = useWorkspaceStatusById(task.statusId)

  const iconVariant: StatusBadgeProps['iconVariant'] = isGhostTask(task)
    ? 'isUnvisitedStage'
    : 'default'
  const taskVariant: StatusBadgeProps['taskVariant'] =
    getTaskStatusVariant(task)

  return {
    ...rest,
    value,
    onChange,
    disabled,
    iconVariant,
    taskVariant,
  }
}
