import { useDependantState } from '@motion/react-core/hooks'
import { type StageCardType } from '@motion/ui-logic/pm/project'

import {
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
} from '~/areas/flows/utils'
import { useState } from 'react'

import { useStageTaskField } from './use-stage-task-field'

export function useStageEventField(
  taskBasePath: `stages.${number}.tasks.${number}`
) {
  const [scheduleMeetingWithinDays, onChangeScheduleMeetingWithinDays] =
    useStageTaskField(`${taskBasePath}.scheduleMeetingWithinDays`)
  const [duration, onChangeDuration] = useStageTaskField(
    `${taskBasePath}.duration`
  )

  const [prevEventDuration, setPrevEventDuration] = useState<number | null>(
    null
  )
  const [prevTaskDuration] = useDependantState(() => duration, [duration], {
    freezeDependencyUpdates: scheduleMeetingWithinDays != null,
  })

  const onChange = (type: StageCardType) => {
    if (type === 'event') {
      // We set the number of days to schedule a meeting within
      onChangeScheduleMeetingWithinDays(DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS)
      const newDuration = prevEventDuration ?? DEFAULT_EVENT_DURATION

      onChangeDuration(newDuration)
    } else {
      setPrevEventDuration(duration)

      onChangeScheduleMeetingWithinDays(null)
      onChangeDuration(prevTaskDuration)
    }
  }

  // When the scheduleMeetingWithinDays is not null, it means the stage card type is event
  return [
    scheduleMeetingWithinDays != null ? 'event' : 'task',
    onChange,
  ] satisfies {
    0: StageCardType
    1: (type: StageCardType) => void
  }
}
