/* eslint-disable react-refresh/only-export-components */
import { usePrevious } from '@motion/react-core/hooks'
import { type TaskUrlSearchParams } from '@motion/ui-logic/pm/task'

// eslint-disable-next-line import-x/no-restricted-paths
import { type ProjectUrlSearchParams } from '~/areas/project/modals/project-modal/utils'
import { useSearchParams } from '~/routing'
import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

interface UseModalListReturn {
  getPrevId: () => string | null
  getNextId: () => string | null
  setListIds: (list: string[]) => void
}

const useModalList = (
  currentModalId: string | undefined
): UseModalListReturn => {
  const listIds = useRef<string[]>([])
  const frozenListIds = useRef<string[]>([])

  const [currentIndex, setCurrentIndex] = useState(-1)

  const previousModalId = usePrevious(currentModalId)

  useEffect(() => {
    // Freeze the list of ids only when opening the task modal the first time
    if (currentModalId != null && previousModalId == null) {
      frozenListIds.current = listIds.current
    }
  }, [previousModalId, currentModalId])

  useEffect(() => {
    setCurrentIndex(
      frozenListIds.current.findIndex((id) => id === currentModalId)
    )
  }, [currentModalId])

  const isModalFromListBehind = useMemo(
    () => currentModalId != null && currentIndex >= 0,
    [currentIndex, currentModalId]
  )

  const getPrevId = useCallback(() => {
    if (!isModalFromListBehind || currentIndex <= 0) {
      return null
    }
    return frozenListIds.current[currentIndex - 1]
  }, [currentIndex, isModalFromListBehind])

  const getNextId = useCallback(() => {
    if (
      !isModalFromListBehind ||
      currentIndex >= frozenListIds.current.length - 1
    ) {
      return null
    }
    return frozenListIds.current[currentIndex + 1]
  }, [currentIndex, isModalFromListBehind])

  const setListIds = useCallback((ids: string[]) => {
    listIds.current = ids
    return () => {
      listIds.current = []
    }
  }, [])

  return useMemo(
    () => ({
      getPrevId,
      getNextId,
      setListIds,
    }),
    [getPrevId, getNextId, setListIds]
  )
}

export interface ModalListOrderValue {
  tasks: UseModalListReturn
  projects: UseModalListReturn
}

const noop = () => null

const defaultValue: ModalListOrderValue = {
  tasks: {
    getPrevId: noop,
    getNextId: noop,
    setListIds: noop,
  },
  projects: {
    getPrevId: noop,
    getNextId: noop,
    setListIds: noop,
  },
}

export const ModalListOrderContext =
  createContext<ModalListOrderValue>(defaultValue)

type ModalListOrderProviderProps = {
  children: ReactNode
}

export function ModalListOrderProvider({
  children,
}: ModalListOrderProviderProps) {
  const { task: currentTaskId } = useSearchParams<TaskUrlSearchParams>()
  const { project: currentProjectId } =
    useSearchParams<ProjectUrlSearchParams>()

  const taskModalList = useModalList(currentTaskId)
  const projectModalList = useModalList(currentProjectId)

  const value = useMemo(() => {
    return {
      tasks: taskModalList,
      projects: projectModalList,
    }
  }, [taskModalList, projectModalList])

  return (
    <ModalListOrderContext.Provider value={value}>
      {children}
    </ModalListOrderContext.Provider>
  )
}
