import { classed } from '@motion/theme'

export const RibbonContainer = classed('div', {
  base: 'flex flex-row gap-2 items-center justify-start w-full px-4 py-2',
  variants: {
    sentiment: {
      error: 'bg-semantic-error-bg-default',
      warning: 'bg-semantic-orange-bg-default',
      success: 'bg-semantic-success-bg-disabled',
      info: 'bg-semantic-neutral-bg-active-default',
      completed: 'bg-semantic-success-bg-default',
      cancelled: 'bg-semantic-error-bg-default',
    },
  },
})

export const LabelContainer = classed('div', {
  base: 'flex flex-row justify-between items-center w-full',
})

export const LabelText = classed('span', {
  base: 'text-xs font-medium',
  variants: {
    sentiment: {
      info: 'text-semantic-neutral-text-default',
      completed: 'text-semantic-success-text-default',
      cancelled: 'text-semantic-error-text-default',
      none: 'text-semantic-neutral-text-default',
    },
  },
  defaultVariants: {
    sentiment: 'none',
  },
})
