import { useCallback, useState } from 'react'

import { useConferenceSettingsApi } from '../../../areas/settings/state'
import { Input } from '../../Common'
import { ActionDialog } from '../../Common/Dialog/ActionDialog/ActionDialog'

interface CustomLocationModalProps {
  visibleHandler: (visible: boolean) => void
  onConfirmRef: { current: (message: string) => void }
}

export const CustomLocationModal = ({
  visibleHandler,
  onConfirmRef,
}: CustomLocationModalProps) => {
  const { selectConferenceSettings, setConferenceSettings } =
    useConferenceSettingsApi()
  const customLocation = selectConferenceSettings()?.customLocation
  const [customLocationInput, setCustomLocationInput] = useState(customLocation)

  const customLocationHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setCustomLocationInput(e.target.value)
    },
    []
  )

  const saveHandler = useCallback(async () => {
    await setConferenceSettings({ customLocation: customLocationInput })
  }, [customLocationInput, setConferenceSettings])

  const doneHandler = () => {
    if (onConfirmRef.current && customLocationInput != null) {
      void saveHandler()
      onConfirmRef.current(customLocationInput)
    }
    visibleHandler(false)
  }

  return (
    <ActionDialog
      title='Enter your custom location'
      onClose={() => visibleHandler(false)}
      isOpen
      actionButtonText='Done'
      actionButtonHandler={doneHandler}
    >
      <div className='w-[350px] py-2'>
        <Input
          placeholder='Enter link or address'
          value={customLocationInput}
          onChange={customLocationHandler}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void doneHandler()
            }
          }}
        />
      </div>
    </ActionDialog>
  )
}
