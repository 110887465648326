import { NO_DURATION } from '@motion/ui-logic'
import { getTaskDurationChangedFields } from '@motion/ui-logic/pm/task'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export function useTaskDurationUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema | RecurringTaskSchema | TaskSchema['id'],
      newDuration: TaskSchema['duration']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the duration of a Chunk task')
      }
      if (task.type === 'RECURRING_INSTANCE') {
        throw new Error('Cannot update the duration of a recurring instance')
      }
      if (task.type === 'RECURRING_TASK' && newDuration === NO_DURATION) {
        throw new Error('Recurring tasks cannot have a TBD duration')
      }

      const updates = {
        duration: newDuration,
        ...getTaskDurationChangedFields({
          ...task,
          duration: newDuration,
        } as TaskSchema),
      }

      await updateTask(task, updates)
    },
    [readTask, updateTask]
  )
}
