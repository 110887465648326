import { createStateKey, useSharedState } from '@motion/react-core/shared-state'
import {
  type NewOnboardingPage,
  type OnboardingPage,
  type OnboardingProgressKey,
  type PersistedOnboardingState,
  type PMOnboardingExtraData,
  usePersistedOnboardingState,
} from '@motion/web-common/onboarding'

import api from '~/chromeApi/chromeApiContentScript'
import { getProxy } from '~/state/backgroundProxy'
import { setCalendarVisible } from '~/state/calendar/calendarSlice'
import { useAppDispatch } from '~/state/hooks'
import { useMemo, useRef } from 'react'

import * as reduxApi from './redux/apiCalls'

import { type OnboardingScreen, type OnboardingType } from '../v2/types'

const onboardingService = getProxy('OnboardingService')
const { useCreateGetOnboardingState } = reduxApi

type LocalState = {
  isOnboarding: boolean
  newPage: NewOnboardingPage | null
  page: OnboardingPage | null
  isInLinkCalendarsFlow: boolean
}

const LocalOnboardingStateKey = createStateKey<LocalState>('onboarding-state', {
  defaultValue: {
    isOnboarding: false,
    newPage: null,
    page: null,
    isInLinkCalendarsFlow: false,
  },
})

const DEFAULT_REMOTE_STATE: PersistedOnboardingState = {
  isOnboardingComplete: false,
  didSkipOnboarding: true,
  onboardingProgress: {},
  onboardingLatestScreen: '',
  onboardingType: 'individual',
  onboardingVersion: '2',
  onboardingExtraData: { workspaceId: '', projectId: '' },
  onboardingCompletedSurvey: false,
  lastOnboardTimestamp: 0,
}

export const useOnboardingApi = () => {
  const [remoteState, setRemoteState] = usePersistedOnboardingState()
  const dispatch = useAppDispatch()
  const [localState, setLocalState] = useSharedState(LocalOnboardingStateKey, {
    scope: 'root',
  })
  const createGetOnboardingState = useCreateGetOnboardingState()

  const remoteRef = useRef(remoteState ?? DEFAULT_REMOTE_STATE)
  remoteRef.current = remoteState ?? DEFAULT_REMOTE_STATE
  const localRef = useRef(localState)
  localRef.current = localState

  return useMemo(() => {
    const getOnboardingState = createGetOnboardingState(() =>
      Promise.resolve(remoteRef.current)
    )
    return {
      ...reduxApi,
      getOnboardingState,
      getOnboardingCompletedSurvey() {
        return Promise.resolve(remoteRef.current?.onboardingCompletedSurvey)
      },
      async setOnboardingCompletedSurvey(isCompleted: boolean) {
        await setRemoteState({ onboardingCompletedSurvey: isCompleted })
      },
      async setLatestOnboardingScreen(screen: OnboardingScreen) {
        await setRemoteState({
          onboardingLatestScreen: screen,
          onboardingVersion: '2',
        })
      },
      async setOnboardingType(type: OnboardingType) {
        await setRemoteState({ onboardingType: type, onboardingVersion: '2' })
      },
      async setOnboardingExtraData(data: Partial<PMOnboardingExtraData>) {
        await setRemoteState({
          onboardingExtraData: {
            ...remoteRef.current?.onboardingExtraData,
            ...data,
          } as PersistedOnboardingState['onboardingExtraData'],
        })
      },

      // Redux Thunks
      progressOnboarding(key: OnboardingProgressKey) {
        return setRemoteState({
          onboardingProgress: {
            [key]: true,
          },
        })
      },

      async completeOnboarding() {
        await api.storage.local.set({ forceOnboarding: true })
        const res = await onboardingService.completeOnboarding()
        await await setRemoteState({
          isOnboardingComplete: true,
          lastOnboardTimestamp: Date.now(),
        })
        await setLocalState((prev) => ({
          ...prev,
          isOnboarding: false,
        }))

        dispatch(setCalendarVisible(true))
        return res
      },

      setIsOnboarding(value: boolean) {
        setLocalState((prev) => ({
          ...prev,
          isOnboarding: value,
        }))
      },

      setPage(value: OnboardingPage | null) {
        return setLocalState((prev) => ({
          ...prev,
          page: value,
        }))
      },
      setIsInLinkCalendarsFlow(value: boolean) {
        return setLocalState((prev) => ({
          ...prev,
          isInLinkCalendarsFlow: value,
        }))
      },

      // Redux Selectors
      selectIsOnboardingComplete() {
        return remoteRef.current.isOnboardingComplete ?? false
      },
      selectIsOnboarding() {
        return localRef.current.isOnboarding
      },
      selectPage() {
        return localRef.current.page
      },
      selectOnboardingType() {
        return remoteRef.current.onboardingType
      },
      selectLastOnboardingTimestamp() {
        return remoteRef.current.lastOnboardTimestamp
      },
      selectIsInLinkCalendarsFlow() {
        return localRef.current.isInLinkCalendarsFlow
      },
      // booking
      selectShouldLoadBooking() {
        return !!remoteRef.current.onboardingProgress?.scheduler
      },
    }
  }, [createGetOnboardingState, dispatch, setLocalState, setRemoteState])
}
