import { type CustomFieldValuesSchema } from '@motion/rpc-types'
import {
  type AllAvailableCustomFieldSchema,
  type CustomFieldSchemaByType,
  type CustomFieldValueSchemaByType,
} from '@motion/ui-logic'

/**
 * Custom field type guards
 */
export function isMultiSelectField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'multiSelect'> {
  return field.type === 'multiSelect'
}

export function isSelectField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'select'> {
  return field.type === 'select'
}

export function isTextField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'text'> {
  return field.type === 'text'
}

export function isNumberField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'number'> {
  return field.type === 'number'
}

export function isUrlField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'url'> {
  return field.type === 'url'
}

export function isDateField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'date'> {
  return field.type === 'date'
}

export function isPersonField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'person'> {
  return field.type === 'person'
}

export function isMultiPersonField(
  field: AllAvailableCustomFieldSchema
): field is CustomFieldSchemaByType<'multiPerson'> {
  return field.type === 'multiPerson'
}

export function areMultiSelectFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'multiSelect'>[] {
  return fields.every(isMultiSelectField)
}

export function areSelectFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'select'>[] {
  return fields.every(isSelectField)
}

export function areTextFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'text'>[] {
  return fields.every(isTextField)
}

export function areNumberFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'number'>[] {
  return fields.every(isNumberField)
}

export function areUrlFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'url'>[] {
  return fields.every(isUrlField)
}

export function areDateFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'date'>[] {
  return fields.every(isDateField)
}

export function arePersonFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'person'>[] {
  return fields.every((field) => field.type === 'person')
}

export function areMultiPersonFields(
  fields: AllAvailableCustomFieldSchema[]
): fields is CustomFieldSchemaByType<'multiPerson'>[] {
  return fields.every((field) => field.type === 'multiPerson')
}

/**
 * Custom field value type guards
 */
export function isMultiSelectValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'multiSelect'> {
  return value?.type === 'multiSelect'
}

export function isSelectValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'select'> {
  return value?.type === 'select'
}

export function isTextValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'text'> {
  return value?.type === 'text'
}

export function isNumberValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'number'> {
  return value?.type === 'number'
}

export function isUrlValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'url'> {
  return value?.type === 'url'
}

export function isDateValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'date'> {
  return value?.type === 'date'
}

export function isPersonValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'person'> {
  return value?.type === 'person'
}

export function isMultiPersonValue(
  value: CustomFieldValuesSchema | null
): value is CustomFieldValueSchemaByType<'multiPerson'> {
  return value?.type === 'multiPerson'
}
