import { Button, PopoverTrigger } from '@motion/ui/base'
import { type ColorClassId, colorClassIdToColorId } from '@motion/ui-logic'

import { generalDropdownItemClasses } from '~/components/Common/GeneralComponentStyles'
import { ColorItemBadge } from '~/global/components/badges'
import {
  googleCalendarClasses,
  microsoftCalendarClasses,
} from '~/storageConstants'
import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type CalendarColorDropdownProps = Omit<
  ColorDropdownContentProps,
  'close'
> & {
  children: ReactNode
}

export function CalendarColorDropdown({
  children,
  ...contentProps
}: CalendarColorDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ColorDropdownContent {...contentProps} close={close} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type ColorDropdownContentProps = {
  provider?: 'microsoft' | 'google'
  currentColorClass: ColorClassId
  onChange: (colorClass: ColorClassId) => void
  colorsToHide?: ColorClassId[]
  customOptions?: (
    | {
        text: string
        onClick: () => void
      }
    | boolean
  )[]
  close: () => void
}

function ColorDropdownContent({
  provider = 'google',
  currentColorClass,
  onChange = () => {},
  colorsToHide = [],
  customOptions = [],
  close,
}: ColorDropdownContentProps) {
  return (
    <div className='flex flex-col w-[164px]'>
      {customOptions.map((option) => {
        if (option && typeof option !== 'boolean') {
          return (
            <button
              key={`option-${option.text}`}
              onClick={() => {
                option.onClick()
                close()
              }}
              className={twMerge(
                generalDropdownItemClasses,
                'h-auto min-h-[32px] text-left'
              )}
            >
              {option.text}
            </button>
          )
        }

        return null
      })}
      <div className='flex flex-row items-center flex-wrap p-2'>
        {provider === 'microsoft'
          ? microsoftCalendarClasses
          : googleCalendarClasses
              .flat()
              .filter(
                (color: string) => !colorsToHide.includes(color as ColorClassId)
              )
              .map((colorClass) => (
                <Button
                  key={colorClass}
                  iconOnly
                  onClick={() => {
                    onChange(colorClass as ColorClassId)
                    close()
                  }}
                  size='small'
                  sentiment='neutral'
                  variant='muted'
                >
                  <ColorItemBadge
                    colorId={colorClassIdToColorId[colorClass as ColorClassId]}
                    selected={currentColorClass === colorClass}
                  />
                </Button>
              ))}
      </div>
    </div>
  )
}
