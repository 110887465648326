import { LoadingPage } from '../LoadingPage'

export const MigrationLoadingPage = () => {
  return (
    <LoadingPage
      message='Updating Motion...'
      subMessage='This should only take a moment. Please do not close this page or refresh.'
      id='migration-page'
    />
  )
}
