import { setAnalyticsTeamId } from '@motion/web-base/analytics'

import { type ActionReducerMapBuilder, type Draft } from '@reduxjs/toolkit'

import { fetchTeam } from './teamThunks'

import { type ProjectManagementSlice } from '../projectManagementSlice'

export const teamsExtraReducers = (
  builder: ActionReducerMapBuilder<Draft<ProjectManagementSlice>>
) => {
  builder.addCase(fetchTeam.fulfilled, (state, action) => {
    const team = action.payload.team

    // team probably isnt needed
    if (team) {
      state.teamId = team.id
      setAnalyticsTeamId(team.id, team.name, team.members?.length || 0)
    }

    state.userId = action.payload.userId
    state.teamFetched = true
  })
  builder.addCase(fetchTeam.rejected, (state) => {
    // Regardless of whether the fetch team is done, we should set team fetch to true since we are done with the fetch (and onboarding is not allowed to progress if teamFetched is false)
    state.teamFetched = true
  })
}
