import { useLocalStorage } from '@motion/web-common/storage'

import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router'

export const RootV3 = () => {
  const location = useLocation()
  const [, setLastPmUri] = useLocalStorage('motionLastPmUri', location.pathname)

  useEffect(() => {
    if (location.pathname === '/web/pm') return
    setLastPmUri(location.pathname)
  }, [location.pathname, setLastPmUri])

  return <Outlet />
}
