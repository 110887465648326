import { type CustomFieldValuesSchema } from '@motion/rpc-types'
import {
  type AllAvailableCustomFieldSchema,
  getCustomFieldValue,
} from '@motion/ui-logic'
import { type ProjectSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useProjectUpdater } from './use-project-updater'

export function useProjectCustomFieldUpdater() {
  const updateProject = useProjectUpdater()

  return useCallback(
    async (
      projectOrId: ProjectSchema | ProjectSchema['id'],
      customFieldDefinition: AllAvailableCustomFieldSchema,
      valueArg: CustomFieldValuesSchema['value']
    ) => {
      const value = getCustomFieldValue(valueArg)

      await updateProject(projectOrId, {
        customFieldValues: {
          // value is typed properly in the function signature
          [customFieldDefinition.id]: {
            type: customFieldDefinition.type,
            value: value as any,
          },
        },
      })
    },
    [updateProject]
  )
}
