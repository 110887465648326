import { PlusSolid } from '@motion/icons'
import { type ActionItem, ActionList, showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ModalApi } from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import { type TaskSchema, type WorkspaceSchema } from '@motion/zod/client'

import { TeamMemberDropdown } from '../../../team-member-dropdown'

type AssigneeBottomSectionProps = {
  close: () => void
  modalApi: ModalApi<ModalDefinitions>
  onChange: (assigneeId: TaskSchema['assigneeUserId']) => void
  workspace: WorkspaceSchema
  workspaceId: string
  additionalItems?: ActionItem[]
}

export function AssigneeDropdownBottomSection({
  close,
  modalApi,
  onChange,
  workspace,
  workspaceId,
  additionalItems = [],
}: AssigneeBottomSectionProps) {
  return (
    <div className='flex flex-col border-t border-dropdown-border mt-1'>
      <ActionList
        items={[
          ...additionalItems,
          {
            prefix: <PlusSolid />,
            content: 'Invite new team member',
            onAction: () => {
              close()
              void modalApi.open('invite-teammate', { workspace })
            },
          },
          {
            content: (
              <TeamMemberDropdown
                onChange={(newUserId, newUserName) => {
                  recordAnalyticsEvent(
                    'PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBER',
                    { view: 'inline' }
                  )
                  onChange(newUserId)
                  showToast(
                    'neutral',
                    `${newUserName} added to ${workspace?.name}`
                  )

                  close()
                }}
                workspaceId={workspaceId}
              >
                <div className='flex items-center gap-2 text-dropdown-item-text-default'>
                  <PlusSolid className='w-[18px] h-[18px] text-dropdown-item-icon-default' />
                  Add assignee to workspace
                </div>
              </TeamMemberDropdown>
            ),
          },
        ]}
      />
    </div>
  )
}
