import { Tag } from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

export interface InviteeListProps {
  className: string
  onDelete: (email: string) => void
  emails: string[]
}

export const InviteeList = ({
  emails,
  onDelete,
  className = '',
}: InviteeListProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-row items-center flex-wrap gap-2.5',
        className
      )}
    >
      {emails.map((email) => (
        <Tag key={email} onRemove={() => onDelete(email)}>
          <span className='truncate'>{email}</span>
        </Tag>
      ))}
    </div>
  )
}
