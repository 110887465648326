import { classed } from '@motion/theme'

import {
  ChangeDeadlineButton,
  ChangeStartDateButton,
  OpenProjectButton,
  OpenTaskButton,
  StartTaskButton,
} from './buttons'
import { type ActionsButtonProps } from './types'

export type TaskActionButtonsRowProps = ActionsButtonProps

export function TaskItemActionButtons({
  task,
  project = null,
  showOpenTask = true,
  showOpenProject = project != null,
  showChangeStartDate = true,
  showChangeDeadline = true,
  showStartTask = task.completedTime == null,
}: TaskActionButtonsRowProps) {
  return (
    <TaskItemActionButtonsContainer>
      {showOpenTask && <OpenTaskButton taskId={task.id} />}
      {showOpenProject && project != null && (
        <OpenProjectButton projectId={project.id} />
      )}
      {showChangeStartDate && (
        <ChangeStartDateButton task={task} project={project} />
      )}
      {showChangeDeadline && (
        <ChangeDeadlineButton task={task} project={project} />
      )}
      {showStartTask && <StartTaskButton taskId={task.id} />}
    </TaskItemActionButtonsContainer>
  )
}

const TaskItemActionButtonsContainer = classed('div', {
  base: 'flex flex-row gap-1.5 flex-shrink-0 items-center justify-center',
})
