import { useTheme } from '@motion/theme/dom'

import {
  EmptyResultsIllustrationDark,
  EmptyResultsIllustrationLight,
} from '../illustrations'

export function useEmptyResultsIcon() {
  const theme = useTheme()

  return theme.theme === 'dark'
    ? EmptyResultsIllustrationDark
    : EmptyResultsIllustrationLight
}
