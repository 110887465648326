import { useResizeObserver } from '@motion/react-core/hooks'
import { useSharedStateSendOnly } from '@motion/react-core/shared-state'

import { animate } from 'framer-motion'
import {
  type MouseEvent as ReactMouseEvent,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import { DayLines } from './day-lines'
import { ScrollBar } from './scroll-bar'
import { ScrollingDateIndicator } from './scrolling-date-indicator'
import { TimelineDividers } from './timeline-dividers'
import { TodayIndicator } from './today-indicator'

import { usePlannerProps } from '../context'
import { ScrollDeltaKey, ScrollPositionKey } from '../context/context-keys'
import { useAnimatedValue, useSidebarSize } from '../hooks'
import { calculateMaxSpeed } from '../utils/auto-scroll-utils'

type ScrollingContainerProps = {
  children: ReactNode
}

export const ScrollingContainer = (props: ScrollingContainerProps) => {
  const { children } = props
  const setScrollPosition = useSharedStateSendOnly(ScrollPositionKey)
  const setScrollDelta = useSharedStateSendOnly(ScrollDeltaKey)
  const [plannerProps, setPlannerProps] = usePlannerProps()

  const containerRef = useRef<HTMLDivElement>(null)
  const state = useRef<'idle' | 'auto' | 'manual'>('idle')
  const lastCursorPosition = useRef<number | null>(null)
  const startingScrollPosition = useRef<number | null>(null)
  const currentScrollPosition = useRef<number>(0)
  const animateConstantSpeed = useAnimatedValue()
  const sidebarSize = useSidebarSize()

  const setPosition = useCallback(
    (pos: number) => {
      setScrollPosition(pos)
      currentScrollPosition.current = pos
      if (startingScrollPosition.current != null) {
        setScrollDelta(startingScrollPosition.current - pos)
      }
    },
    [setScrollDelta, setScrollPosition]
  )

  useEffect(() => {
    if (plannerProps.scrollTo !== null) {
      animate(currentScrollPosition.current, plannerProps.scrollTo, {
        onUpdate: setPosition,
        onComplete: () => {
          setPlannerProps((prev) => ({ ...prev, scrollTo: null }))
        },
      })
    }
  }, [plannerProps.scrollTo, setPlannerProps, setPosition])

  useResizeObserver(containerRef, (entries) => {
    for (let entry of entries) {
      setPlannerProps((prev) => ({
        ...prev,
        containerRect: entry.target.getBoundingClientRect(),
        containerNode: entry.target,
      }))
    }
  })

  const handlePointerPosition = (pointerX: number) => {
    if (state.current !== 'auto') return
    const isAnimating = animateConstantSpeed.isAnimating()

    const speed = calculateMaxSpeed(
      plannerProps.containerRect,
      pointerX,
      lastCursorPosition.current ?? pointerX,
      isAnimating,
      sidebarSize
    )
    if (speed) {
      animateConstantSpeed.animate(currentScrollPosition.current, {
        type: 'constantSpeed',
        speed,
        onUpdate: setPosition,
      })
    } else {
      animateConstantSpeed.cancelAnimation()
    }
  }

  const handleMouseMove = (event: MouseEvent) => {
    handlePointerPosition(event.pageX)
  }

  const handleTouchMove = (event: TouchEvent) => {
    handlePointerPosition(event.touches[0].pageX)
  }

  const removeTextSelection = () => {
    document.getSelection()?.removeAllRanges()
  }

  const onMouseUp = () => {
    unbindEvents()
    setScrollDelta(0)
    animateConstantSpeed.cancelAnimation()
    state.current = 'idle'
    startingScrollPosition.current = null
  }

  const bindEvents = () => {
    if (document) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('touchmove', handleTouchMove)
      document.addEventListener('pointerup', onMouseUp)
      document.addEventListener('mouseleave', onMouseUp)
      document.addEventListener('touchend', onMouseUp)
      document.addEventListener('selectionchange', removeTextSelection)
    }
  }

  const unbindEvents = () => {
    if (document) {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('pointerup', onMouseUp)
      document.removeEventListener('mouseleave', onMouseUp)
      document.removeEventListener('touchend', onMouseUp)
      document.removeEventListener('selectionchange', removeTextSelection)
    }
  }

  const handleOnWheel = useCallback(
    (event: WheelEvent) => {
      if (Math.abs(event.deltaY) > Math.abs(event.deltaX)) return
      event.preventDefault()
      setPosition(currentScrollPosition.current - event.deltaX)
    },
    [setPosition]
  )

  useEffect(() => {
    const divElement = containerRef.current

    if (!divElement) return
    divElement.addEventListener('wheel', handleOnWheel, {
      passive: false,
    })
    return () => {
      if (!divElement) return
      divElement.removeEventListener('wheel', handleOnWheel)
    }
  }, [handleOnWheel])

  const handlePointerDown = (event: ReactMouseEvent) => {
    let currentNode = event.target as HTMLElement // Start from the clicked element
    lastCursorPosition.current = event.pageX
    startingScrollPosition.current = currentScrollPosition.current

    state.current = 'manual'

    const draggableNode = currentNode.closest('[data-type=draggable]')

    if (draggableNode) {
      state.current = 'auto'
    }

    bindEvents()
    // Remove text selection while resizing, DnD kit does the same
    removeTextSelection()
  }

  return (
    <div
      ref={containerRef}
      className='relative overflow-hidden w-full flex flex-col overscroll-none group/scrolling-container will-change-transform bg'
      onPointerDown={handlePointerDown}
    >
      <TimelineDividers />
      {children}
      <ScrollingDateIndicator containerNode={containerRef.current} />
      <TodayIndicator />
      <DayLines />
      <ScrollBar setPosition={setPosition} />
    </div>
  )
}
