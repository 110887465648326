import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type CalendarEventSchemaV2,
  type UpdateCalendarEventSchema,
} from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useUpdateCalendarEvent as useUpdateCalendarEventCall } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useUpdateEventPrompts } from './use-update-event-prompts'

export const useUpdateEvent = () => {
  const lookup = useLookup()
  const { mutateAsync: updateCalendarEvent } = useUpdateCalendarEventCall()
  const promptUpdateCalendarEvent = useUpdateEventPrompts()

  return useCallback(
    async (
      id: string,
      updates: UpdateCalendarEventSchema
    ): Promise<CalendarEventSchemaV2 | undefined> => {
      const promptResponse = await promptUpdateCalendarEvent(id, updates)
      if (!promptResponse) return

      const { sendUpdates, recurrenceUpdateType } = promptResponse
      try {
        const existingEvent = lookup('calendarEvents', id)
        recordAnalyticsEvent('CALENDAR_PEEK_MODAL_UPDATE_EVENT', {
          isRecurring: Boolean(
            existingEvent?.recurrence || existingEvent?.recurringParentId
          ),
        })

        const response = await updateCalendarEvent({
          id,
          ...updates,
          sendUpdates,
          recurrenceUpdateType,
        })

        showToast('success', 'Event updated')

        return response.calendarEvent
      } catch (err) {
        showErrorToast('Could not update event')
        return
      }
    },
    [lookup, promptUpdateCalendarEvent, updateCalendarEvent]
  )
}
