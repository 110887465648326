import { logEvent } from '@motion/web-base/analytics'

import { type ReactNode, useEffect } from 'react'

export type RouteWrapperProps = {
  element: ReactNode
  metric?: string | null
}

export function RouteWrapper(props: RouteWrapperProps) {
  useEffect(() => {
    if (props.metric) {
      void logEvent(props.metric)
    }
  }, [props.metric])

  return props.element
}
