import {
  FieldButton,
  type FieldButtonProps,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'

export type MinChunkDropdownButtonProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth' | 'disabled'
>

type MinChunkValue = number | null

type Props = {
  chunkDurations: {
    label: string
    value: MinChunkValue
  }[]
  selectedMinChunkDuration: MinChunkValue
  onChange: (value: MinChunkValue) => void
  icon?: React.ReactNode
} & MinChunkDropdownButtonProps

export const MinChunkDurationDropdown = ({
  selectedMinChunkDuration,
  chunkDurations,
  onChange,
  icon,
  ...buttonProps
}: Props) => {
  const selectedMinChunk = chunkDurations.find(
    (c) => c.value === selectedMinChunkDuration
  )

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={chunkDurations}
          computeKey={(item) => String(item.value)}
          computeSelected={(item) => item.value === selectedMinChunkDuration}
          onSelect={(item) => {
            onChange(item.value)
            close()
          }}
          renderItem={(item) => item.label}
        />
      )}
    >
      <FieldButton {...buttonProps}>
        {icon}
        {selectedMinChunk?.label}
      </FieldButton>
    </PopoverTrigger>
  )
}
