import { BlockedBySolid, BlocksSolid } from '@motion/icons'
import {
  findStageForTask,
  type FlowTemplateFormFields,
  mapTaskDefinitionToTask,
} from '@motion/ui-logic/pm/project'

import {
  ModalFieldButton,
  SidebarTaskList,
} from '~/areas/task-project/components'
import { useI18N } from '~/global/contexts'
import { useController } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { stripVariableKeysFromTaskDefinition } from '../../../utils'
import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const ControlledBlockersField = ({
  type,
}: {
  type: 'blocks' | 'blockedBy'
}) => {
  const { form } = useTaskDefinitionForm()
  const { flowFormData } = useTaskDefinitionModalState()
  const { control } = form
  const { pluralize } = useI18N()

  const { field } = useController({
    name: type === 'blocks' ? 'blockingTaskIds' : 'blockedByTaskIds',
    control,
  })

  if (!flowFormData) {
    return null
  }

  const Icon = type === 'blockedBy' ? BlockedBySolid : BlocksSolid
  const label = type === 'blockedBy' ? 'Blocked By' : 'Blocking'

  const blockerCount = field.value.length
  const blockerList = getBlockerList(flowFormData, field.value)

  return (
    <>
      <ModalFieldButton label={label} disabled>
        <div className='flex items-center gap-1.5'>
          <Icon
            className={
              // These icons stay the same color when field is disabled
              type === 'blockedBy'
                ? '!text-semantic-error-icon-default'
                : '!text-semantic-warning-icon-default'
            }
          />

          <button
            className={twMerge('flex items-center gap-1', 'cursor-not-allowed')}
            disabled
          >
            {blockerCount > 0
              ? pluralize(blockerCount, '{{count}} task', '{{count}} tasks')
              : 'None'}
          </button>
        </div>
      </ModalFieldButton>

      <div className='pl-5'>
        <SidebarTaskList tasks={blockerList} size='xsmall' disabled />
      </div>
    </>
  )
}

function getBlockerList(
  flowFormData: FlowTemplateFormFields,
  blockerIds: string[]
) {
  const blockerTaskDef = flowFormData?.stages
    .flatMap((stage) => stage.tasks)
    .find((task) => blockerIds.includes(task.id))
  if (!blockerTaskDef) {
    return []
  }

  const blockerStageDefinitionId =
    findStageForTask(flowFormData.stages, blockerTaskDef?.id ?? '')?.id ?? null
  if (!blockerStageDefinitionId) {
    return []
  }

  const variableKeys = flowFormData.textVariables.map((v) => v.key)

  const scrubbedTaskDef = stripVariableKeysFromTaskDefinition(
    blockerTaskDef,
    variableKeys
  )
  const blockerTask = mapTaskDefinitionToTask(
    scrubbedTaskDef,
    flowFormData.workspaceId,
    blockerStageDefinitionId
  )

  return [blockerTask]
}
