import { type FieldOperationSchema } from '@motion/shared/custom-fields'
import { ButtonTabs } from '@motion/ui/base'
import { entries } from '@motion/utils/object'

import { operationsLabel } from '../constants'

export type OperationTabsPanelProps = {
  selectedOperation: FieldOperationSchema
  onChange: (operation: FieldOperationSchema) => void
}

export const OperationTabsPanel = ({
  onChange,
  selectedOperation,
}: OperationTabsPanelProps) => {
  return (
    <div className='px-2 py-1 bg-semantic-neutral-surface-overlay-bg-subtlest'>
      <ButtonTabs
        size='xsmall'
        items={entries(operationsLabel).map(([value, label]) => ({
          value,
          content: label,
          onAction: () => onChange(value),
        }))}
        activeValue={selectedOperation}
      />
    </div>
  )
}
