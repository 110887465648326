import { useDebouncedCallback } from '@motion/react-core/hooks'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ReactNode, useCallback, useMemo, useState } from 'react'

import { SidebarSearchContext, type SidebarSearchContextApi } from './hook'

type SidebarSearchContextProviderProps = {
  children: ReactNode
}
export const SidebarSearchContextProvider = ({
  children,
}: SidebarSearchContextProviderProps) => {
  const [searchQuery, setInternalSearchQuery] = useState('')

  const debouncedRecordMetric = useDebouncedCallback(() => {
    recordAnalyticsEvent('SIDEBAR_SEARCH_PROJECT_OR_WORKSPACE')
  }, 1_000)

  const setSearchQuery = useCallback(
    (value: string) => {
      setInternalSearchQuery(value)
      if (value) {
        debouncedRecordMetric()
      }
    },
    [debouncedRecordMetric]
  )

  const hasSearch = searchQuery !== ''

  const contextValue = useMemo<SidebarSearchContextApi>(
    () => ({
      hasSearch,
      searchQuery,
      setSearchQuery,
    }),
    [hasSearch, searchQuery, setSearchQuery]
  )

  return (
    <SidebarSearchContext.Provider value={contextValue}>
      {children}
    </SidebarSearchContext.Provider>
  )
}
