import { isDateBetweenDates, safeParseDate } from '@motion/utils/dates'
import { HasExperiment, useHasTreatment } from '@motion/web-common/flags'
import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'
import { type FC } from 'react'

import { ConnectedStageConnector } from './stage-connector'
import { StageTimelineItem } from './stage-timeline-item'
import { DeprecatedStageConnector } from './styled'
import { TodayMarker } from './today-marker'
import { type StageGroup } from './utils'

type StageTimelineSegmentProps = {
  stageGroup: StageGroup
  project: ProjectSchema
  prevGroup: StageGroup | undefined
  nextGroup: StageGroup | undefined
  isLastGroup: boolean
}

export const StageTimelineSegment: FC<StageTimelineSegmentProps> = ({
  stageGroup,
  project,
  prevGroup,
  nextGroup,
  isLastGroup,
}) => {
  const today = DateTime.now().startOf('day')
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')

  const stageDate = safeParseDate(stageGroup.dueDateKey)
  const prevTimelineDate =
    prevGroup != null
      ? safeParseDate(prevGroup.dueDateKey)
      : safeParseDate(project.startDate)
  const nextStageDate =
    nextGroup != null ? safeParseDate(nextGroup.dueDateKey) : null

  const isTodayDirectlyBeforeThisStage = isDateBetweenDates(
    prevTimelineDate?.plus({ days: 1 }) ?? today,
    stageDate,
    today,
    { inclusive: true }
  )

  const isTodayDirectlyAfterThisStage = isDateBetweenDates(
    stageDate,
    nextStageDate,
    today,
    { inclusive: hasBetterResizeStages ? true : false }
  )

  return (
    <>
      {isTodayDirectlyBeforeThisStage && (
        <TodayMarker stageGroup={stageGroup} />
      )}

      <StageTimelineItem stageGroup={stageGroup} project={project} />

      {!isLastGroup && (
        <HasExperiment
          fallback={
            <DeprecatedStageConnector half={isTodayDirectlyAfterThisStage} />
          }
          name='flows-better-resize-stages'
        >
          <ConnectedStageConnector
            groupedStages={nextGroup}
            type={
              // since this check is now inclusive, do not set this to true if this is the stage after the today
              // marker
              isTodayDirectlyAfterThisStage && !isTodayDirectlyBeforeThisStage
                ? 'firstHalf'
                : 'full'
            }
          />
        </HasExperiment>
      )}
    </>
  )
}
