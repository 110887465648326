import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, type ButtonProps, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type BulkOpsAnalyticsFieldName } from '@motion/web-base/analytics/events'

import { type ComponentRef, forwardRef, type ReactNode } from 'react'

export type FieldProps = {
  active?: boolean
  children: ReactNode
  onClick?: ButtonProps['onClick']
  onRemove?: RemoveFieldIconProps['onClick']
  disabled?: boolean
  analyticsName: BulkOpsAnalyticsFieldName
}

export const FieldButton = forwardRef<ComponentRef<typeof Button>, FieldProps>(
  function FieldButton(
    { active, children, onRemove, onClick, analyticsName, ...rest },
    ref
  ) {
    return (
      <Button
        ref={ref}
        sentiment='neutral'
        variant={active ? 'outlined' : 'muted'}
        size='small'
        onClick={(evt) => {
          recordAnalyticsEvent('BULK_OPS_PANEL_FIELD_CLICK', {
            field: analyticsName,
          })

          onClick?.(evt)
        }}
        {...rest}
        // @ts-expect-error - classname is not public
        className='max-w-[300px]'
      >
        {children}
        {active && onRemove && <RemoveFieldIcon onClick={onRemove} />}
      </Button>
    )
  }
)

export type RemoveFieldIconProps = {
  onClick: () => void
}
export function RemoveFieldIcon({ onClick }: RemoveFieldIconProps) {
  return (
    <Tooltip asChild content='Remove field'>
      <XSolidButtonLike
        role='button'
        aria-label='Remove field'
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      />
    </Tooltip>
  )
}

const XSolidButtonLike = classed(XSolid, {
  base: `
    w-3 h-3
    rounded

    hover:bg-semantic-neutral-bg-active-hover

    focus-visible:outline
    focus-visible:outline-2
    focus-visible:outline-offset-1
    focus-visible:outline-button-primary-solid-border-focus
  `,
})
