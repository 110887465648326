import {
  DuplicateOutline,
  QuestionMarkCircleSolid,
  XOutline,
} from '@motion/icons'
import { BaseModal, Button } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'

import { useClipboard } from '../../../../localServices/clipboard'
import { useToggleIntercom } from '../../../../utils/toggleIntercom'

const MOTION_EMAIL = __IS_PROD__
  ? 'tasks@usemotion.com'
  : 'tasks-dev@usemotion.com'

export const EmailIntegrationModal = ({
  close,
}: ModalTriggerComponentProps<'email-integration'>) => {
  const clipboard = useClipboard()
  const copyToClipboard = () => {
    void clipboard.write(
      { text: MOTION_EMAIL },
      {
        notificationText: 'Link copied successfully',
      }
    )
  }
  const toggleIntercom = useToggleIntercom()

  return (
    <BaseModal visible onClose={close}>
      <div className='max-w-xl bg-semantic-neutral-bg-default'>
        <div className='flex justify-between items-center px-4 py-3 border-b-2 border-semantic-neutral-border-subtle'>
          <h2 className='text-lg font-semibold text-semantic-neutral-text-default'>
            Create tasks from email
          </h2>
          <div className='flex items-center'>
            <Button
              onClick={toggleIntercom}
              variant='muted'
              sentiment='neutral'
            >
              <QuestionMarkCircleSolid />
              Help
            </Button>

            <Button
              variant='muted'
              sentiment='neutral'
              onClick={close}
              iconOnly
            >
              <XOutline />
            </Button>
          </div>
        </div>

        <div className='flex flex-col gap-6 p-4'>
          <div className='flex flex-col gap-4'>
            <p className='text-semantic-neutral-text-default'>
              Turn an email into a task by forwarding the email to Motion!
            </p>
            <p className='text-semantic-neutral-text-default'>
              Emails must be sent from any of your linked email accounts to{' '}
              {MOTION_EMAIL}, with no other recipients.
            </p>
            <div className='flex flex-row items-center align-middle justify-between py-1 pr-1 pl-2 bg-semantic-neutral-bg-active-default text-semantic-neutral-text-default rounded-md'>
              {MOTION_EMAIL}
              <Button
                variant='outlined'
                sentiment='neutral'
                onClick={copyToClipboard}
              >
                <DuplicateOutline />
                Copy
              </Button>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <p className='text-semantic-neutral-text-default'>
              Based on the email content, Motion will automatically try to
              figure out the task&apos;s title, priority, deadline, and
              duration. For greater control, you can specify that information.
            </p>
            <p className='text-semantic-neutral-text-default'>
              Only the subject and text body of the email will be parsed for
              task creation. Attachments, in-line images, etc will not be
              considered.
            </p>
            <div className='flex flex-col gap-2'>
              <span className='text-xs rounded-md text-semantic-neutral-text-default'>
                Example:
              </span>
              <div className='flex justify-between items-center py-1.5 pl-2 bg-semantic-neutral-bg-active-default text-semantic-neutral-text-default rounded-md'>
                <span>
                  draft plan for team, high priority, by next Tue, 30min
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
