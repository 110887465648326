import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useAgendaTaskActions } from '~/areas/agenda/hooks'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'

import { TaskItemActionButton } from './task-item-action-button'

type ChangeDeadlineButtonProps = {
  task: TaskSchema
  project?: ProjectSchema | null
}

export function ChangeDeadlineButton({
  task,
  project = null,
}: ChangeDeadlineButtonProps) {
  const { changeDeadline, updateTaskInFlight } = useAgendaTaskActions({
    taskId: task.id,
    projectId: project?.id,
  })

  return (
    <TaskDeadlineDropdown
      value={task.dueDate}
      onChange={changeDeadline}
      contextProps={{ taskId: task.id }}
    >
      <TaskItemActionButton
        action='change-deadline'
        loading={updateTaskInFlight}
      >
        Change deadline
      </TaskItemActionButton>
    </TaskDeadlineDropdown>
  )
}
