import { SHORT_TASK_DURATION } from '@motion/ui-logic'
import {
  getInitialFormData,
  resolveFormWorkspaceAndProjectId,
  type TaskFormFields,
  type TaskModalLocationState,
  type TaskUrlParams,
  type TaskUrlSearchParams,
} from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import {
  useFirestoreSettings,
  useMyUserSettings,
} from '@motion/web-common/settings'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import {
  useMyTasksWorkspace,
  useProject,
  useWorkspaceById,
  useWorkspaceLabels,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { useMemo } from 'react'
import { type Location, useLocation, useParams } from 'react-router'

import { type InitialTaskData } from './use-initial-task-data'

export function useInitialFormData(
  initialTaskData: InitialTaskData,
  defaultValues?: Partial<TaskFormFields>
): TaskFormFields {
  const location = useLocation() as Location<TaskModalLocationState | undefined>
  const searchParams = useSearchParams<TaskUrlSearchParams>()
  const urlParams = useParams<TaskUrlParams>()

  const defaultWorkspace = useMyTasksWorkspace()

  const { task, parentTask } = initialTaskData

  const { data: userSettings } = useMyUserSettings(undefined, {
    meta: { source: 'useInitialFormData' },
  })
  const { data: firestoreSettings } = useFirestoreSettings()

  const schedules = useMemo(
    () => firestoreSettings?.schedules ?? {},
    [firestoreSettings]
  )

  const userDefinedTaskDefaults = userSettings?.taskDefaultSettings

  let { workspaceId, projectId } = resolveFormWorkspaceAndProjectId({
    searchParams,
    urlParams,
    task,
    defaultWorkspace,
    userDefinedTaskDefaults,
  })

  const { uid: currentUserId } = useAuthenticatedUser()
  let workspace = useWorkspaceById(workspaceId)

  // If the workspace is deleted, reset the workspaceId to the personal workspace
  if (workspaceId != null && workspace == null) {
    workspace = defaultWorkspace ?? null
    workspaceId = defaultWorkspace?.id
  }

  let project = useProject(projectId)
  // If the project does not belong to the workspace, clear it
  if (
    projectId != null &&
    project != null &&
    project.workspaceId !== workspaceId
  ) {
    project = null
  }

  const workspaceStatuses = useWorkspaceStatuses(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const workspaceLabels = useWorkspaceLabels(workspaceId)

  if (workspaceId == null || workspace == null) {
    throw new Error('Workspace not defined', {
      cause: {
        workspaceId,
        workspace,
      },
    })
  }

  return useMemo(() => {
    const data = {
      ...getInitialFormData({
        searchParams,
        workspaceId,
        workspaceStatuses,
        workspaceCustomFields,
        workspaceLabels,
        project,
        task,
        parentTask,
        currentUserId,
        scheduledStart: location.state?.fixedTask
          ? searchParams.forStartDate
          : undefined,
        scheduledEnd: location.state?.fixedTask
          ? searchParams.forDueDate
          : undefined,
        schedules,
        userDefinedTaskDefaults,
      }),
      ...defaultValues,
    }
    return {
      ...data,
      ...(location.state?.reminderTask &&
        searchParams.forStartDate && {
          duration: SHORT_TASK_DURATION,
          startDate: searchParams.forStartDate,
          dueDate: parseDate(searchParams.forStartDate).endOf('day').toISO(),
        }),
    }
  }, [
    searchParams,
    workspaceId,
    workspaceStatuses,
    workspaceCustomFields,
    workspaceLabels,
    project,
    task,
    parentTask,
    currentUserId,
    location.state?.fixedTask,
    location.state?.reminderTask,
    schedules,
    userDefinedTaskDefaults,
    defaultValues,
  ])
}
