import { type RecurringTaskSchema, type TaskSchema } from '@motion/rpc-types'
import { canEditTaskStartDate } from '@motion/ui-logic/pm/task'
import { Sentry } from '@motion/web-base/sentry'

import { useRecordTaskStartDateChangeUFFeedback } from '~/global/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useTaskUpdater } from '../updaters'

export function useChangeTaskStartDate() {
  const recordTaskStartDateChangeUFFeedback =
    useRecordTaskStartDateChangeUFFeedback()
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema['id'] | TaskSchema | RecurringTaskSchema,
      newStartDate: string | null
    ) => {
      try {
        const task =
          typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

        if (task == null || !canEditTaskStartDate(task)) {
          throw new Error(`This task's start date cannot be changed`, {
            cause: { task },
          })
        }

        const updatedTask = await updateTask(task, {
          startDate: newStartDate,
        })

        recordTaskStartDateChangeUFFeedback(task, updatedTask)
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useChangeTaskStartDate',
          },
        })
        showErrorToast(e)
      }
    },
    [readTask, recordTaskStartDateChangeUFFeedback, updateTask]
  )
}
