import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

export type TemplateTaskUrlSearchParams = {
  task: string
}

export function useTemplateTaskModalUrl() {
  const { pathname } = useLocation()

  return useCallback(
    (params: TemplateTaskUrlSearchParams) => {
      const urlParams = new URLSearchParams(params)
      urlParams.set('template', 'new')

      return `${pathname}?${urlParams.toString()}`
    },

    [pathname]
  )
}
