import { daysBetweenDates } from '@motion/utils/dates'
import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { END_PROJECT_BUFFER } from './constants'

import { getProjectStartAndEnd } from '../../../../../utils'
import { type StageWithDates } from '../../types'
import { getStageDistanceFromProjectStart } from '../get-stage-distance-from-project-start'
import { isLastStage } from '../is-last-stage'

type GetInitialStageLocationArgs = {
  dayPx: number
  stageWithDates: StageWithDates
  project: ProjectSchema
}

// Given a stage with dates, return the initial stage location information (with no deltas applied, etc)
export const getInitialStageLocation = ({
  dayPx,
  stageWithDates,
  project,
}: GetInitialStageLocationArgs) => {
  const { stage, start, due } = stageWithDates
  const isLast = isLastStage(stage.id, project.stages)
  const startAndEnd = getProjectStartAndEnd(project, dayPx)
  const initialProjectWidth = startAndEnd.end - startAndEnd.start

  const pixelsFromProjectStart = getStageDistanceFromProjectStart({
    projectStart: project.startDate || start,
    stageDate: start,
    dayPx,
  })

  let maxStageWidth =
    daysBetweenDates(
      DateTime.fromISO(due).endOf('day'),
      DateTime.fromISO(start).startOf('day')
    ) * dayPx

  // Be inclusive of the last day
  if (isLast) {
    maxStageWidth += dayPx - END_PROJECT_BUFFER
  }

  return {
    pixelsFromProjectStart,
    initialProjectWidth,
    maxStageWidth,
    completedDuration: stage.completion,
  }
}
