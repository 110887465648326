import { Button } from '@motion/ui/base'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type CalendarEventAttendeeSchemaV2 } from '@motion/zod/client'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export function SchedulingAssistantButton() {
  const { form } = useEventForm()
  const { displayName } = useAuthenticatedUser()

  const modalApi = useModalApi()
  const modalState = useEventModalState()

  if (modalState.isLoading || modalState.isReadOnly) {
    return null
  }

  const start = form.watch('start')
  const end = form.watch('end')

  const hostEmail = form.watch('email')
  const attendees = form.watch('attendees')

  // Include the host as an attendee if there are no attendees
  const dedupedAttendees: CalendarEventAttendeeSchemaV2[] =
    attendees.length > 0
      ? attendees
      : [
          {
            email: hostEmail,
            isOrganizer: true,
            // We use displayName from `useAuthenticatedUser` because the host at this point is always the authenticated user. If not, you would not be able to see the scheduling button.
            displayName: displayName ?? hostEmail,
            isOptional: false,
          },
        ]

  return (
    <Button
      variant='outlined'
      sentiment='neutral'
      size='small'
      onClick={async () => {
        const result = await modalApi.prompt('schedule-assistant', {
          mode: 'create-new',
          initialData: {
            start,
            end,
            attendees: dedupedAttendees,
            hostEmail,
          },
        })

        if (result !== ModalDismissed) {
          if (result.new != null) {
            form.setValue('start', result.new.start, {
              shouldDirty: true,
            })
            form.setValue('end', result.new.end, {
              shouldDirty: true,
            })
          }

          if (result.attendees != null) {
            form.setValue('attendees', result.attendees, {
              shouldDirty: true,
            })
          }
        }
      }}
    >
      Open scheduling assistant
    </Button>
  )
}
