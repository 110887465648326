import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

export function getParentChunkId(
  task?: TaskSchema | RecurringTaskSchema | null
) {
  if (task?.type !== 'CHUNK') return null
  return task.parentChunkTaskId
}

export function getRecurringTaskId(
  task?: TaskSchema | RecurringTaskSchema | null
) {
  if (task?.type !== 'RECURRING_INSTANCE') return null
  return task.parentRecurringTaskId
}
