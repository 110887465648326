import type { FolderSchema } from '@motion/zod/client'

import { useFolders } from '~/global/rpc/folders'
import { useCallback } from 'react'

type UseFindFolderCallback = (
  folder: FolderSchema,
  index: number,
  array: FolderSchema[]
) => boolean

type UseFindFolderReturnType = (
  cb: UseFindFolderCallback
) => FolderSchema | null

export const useFindFolder = (): UseFindFolderReturnType => {
  const { data: folders } = useFolders()

  return useCallback<UseFindFolderReturnType>(
    (cb) => {
      if (!folders) return null

      return Object.values(folders.models.folders).find(cb) ?? null
    },
    [folders]
  )
}
