import { ConfirmationModal, FormModal } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useState } from 'react'

import { RadioGroup } from '../../../components/Common/RadioGroup/RadioGroup'

export type DeleteRecurringTaskChoice = 'instance' | 'all'

export const DeleteTaskConfirmationModal = ({
  close,
  task,
}: ModalTriggerComponentProps<'task-delete'>) => {
  const [selected, setSelected] =
    useState<DeleteRecurringTaskChoice>('instance')

  if (task.type === 'RECURRING_INSTANCE') {
    return (
      <FormModal
        title='Delete recurring task'
        visible
        onClose={() => close()}
        submitAction={{
          onAction: () =>
            close(
              (selected === 'all' ? task.parentRecurringTaskId : task.id) ?? ''
            ),
          text: 'Delete',
          destructive: true,
        }}
      >
        <div className='w-[450px] text-semantic-neutral-text-subtle'>
          <p>
            {templateStr('The task(s) {{name}} will be gone forever.', {
              name: <span className='font-semibold'>{task.name}</span>,
            })}
          </p>
          <RadioGroup
            className='py-3'
            options={[
              { label: 'This task', value: 'instance' },
              { label: 'All tasks', value: 'all' },
            ]}
            selectedValue={selected}
            onChange={(value: string) =>
              setSelected(value as DeleteRecurringTaskChoice)
            }
            borderless
          />
        </div>
      </FormModal>
    )
  }

  const title = 'Are you sure you want to delete this task?'
  const bodyText = 'The task {{name}} will be gone forever.'

  return (
    <ConfirmationModal
      visible
      destructive
      title={title}
      description={
        <span>
          {templateStr(bodyText, {
            name: <span className='font-semibold'>{task.name}</span>,
          })}
        </span>
      }
      onClose={() => close()}
      action={{
        label: 'Delete task',
        onAction: () => close(task.id),
      }}
    />
  )
}
