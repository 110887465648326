import { SearchSolid } from '@motion/icons'
import { type ProjectSchema } from '@motion/rpc-types'
import { TextField } from '@motion/ui/forms'
import { useHasTreatment } from '@motion/web-common/flags'

import { AttachmentUploadButton } from '~/areas/attachments'
import {
  type AttachmentGroup,
  AttachmentGroups,
} from '~/areas/attachments/components'
import { useGetProjectAttachmentGroups } from '~/areas/attachments/hooks/use-get-project-all-attachments'
import { sortAttachmentIds } from '~/areas/attachments/utils'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useState } from 'react'

type AttachmentsTabProps = {
  project: ProjectSchema
  tasks: NormalTaskWithRelations[]
}

export function AttachmentsTab({ project, tasks }: AttachmentsTabProps) {
  const [searchQuery, setSearchQuery] = useState('')
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')
  const groupsWithDefinitionAttachments = useGetProjectAttachmentGroups({
    projectId: project.id,
  })

  const groups: AttachmentGroup[] = hasFlowsAttachments
    ? groupsWithDefinitionAttachments
    : [
        {
          targetId: project.id,
          targetType: 'PROJECT',
          workspaceId: project.workspaceId,
          uploadedFileIds: sortAttachmentIds(project.uploadedFileIds),
        },
        ...tasks.map(
          (task) =>
            ({
              targetId: task.id,
              targetType: 'TEAM_TASK',
              workspaceId: task.workspaceId,
              uploadedFileIds: sortAttachmentIds(task.uploadedFileIds ?? []),
            }) satisfies AttachmentGroup
        ),
      ]

  return (
    <div className='w-full h-full overflow-auto rounded-b-lg shadow-lg'>
      <div className='flex gap-2 p-3'>
        <TextField
          size='small'
          rounded='default'
          prefix={<SearchSolid />}
          placeholder='Search'
          value={searchQuery}
          onChange={setSearchQuery}
          fullWidth
          showClearButton
        />

        <AttachmentUploadButton
          targetId={project.id}
          targetType='PROJECT'
          workspaceId={project.workspaceId}
          label='Add'
          size='small'
        />
      </div>
      <div className='flex flex-col pl-[6px] pr-3 pb-[6px]'>
        <AttachmentGroups
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          groups={groups}
          variant='compact'
        />
      </div>
    </div>
  )
}
