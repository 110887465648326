import { DotsVerticalSolid } from '@motion/icons'
import { ContextMenuWrapper, PopoverTrigger, Tooltip } from '@motion/ui/base'
import { ConditionalWrapper } from '@motion/ui/utils'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type VersionedViewV2 } from '@motion/zod/client'

import { ViewActionList } from './view-action-list'

import { useEffectiveView, useSelectedView } from '../../../views'

export type ViewItemProps = {
  view: VersionedViewV2
  selected?: boolean
  isViewDirty?: boolean
}

export function ViewItem({
  view,
  selected = false,
  isViewDirty = false,
}: ViewItemProps) {
  const isDirty = selected && isViewDirty

  return (
    <ContextMenuWrapper
      renderContextMenu={(close) => (
        <ViewActionList close={close} view={view} />
      )}
      onMenuOpen={() => {
        recordAnalyticsEvent('VIEW_TAB_RIGHT_CLICK')
      }}
    >
      {({ handleContextMenu }) => (
        <ConditionalWrapper
          condition={selected}
          wrapper={(children) => (
            <PopoverTrigger
              placement='bottom-end'
              renderPopover={({ close }) => (
                <ViewActionList close={close} view={view} />
              )}
            >
              <div className='flex gap-1 items-center'>
                {children}
                {selected && (
                  <DotsVerticalSolid className='size-4 shrink-0 text-semantic-neutral-icon-default' />
                )}
              </div>
            </PopoverTrigger>
          )}
        >
          <Tooltip
            asChild
            content={
              isDirty &&
              'This view has unsaved changes. Click the dot menu to save or discard changes.'
            }
          >
            <span
              className='flex gap-1 flex-nowrap pt-2.5 pb-2'
              onContextMenu={handleContextMenu}
            >
              <span className='max-w-[200px] truncate'>{view.name}</span>
              {isDirty && (
                <span className='text-semantic-primary-text-default'>*</span>
              )}
            </span>
          </Tooltip>
        </ConditionalWrapper>
      )}
    </ContextMenuWrapper>
  )
}

export function ConnectedViewItem({ view }: Pick<ViewItemProps, 'view'>) {
  const [selected] = useSelectedView()
  const info = useEffectiveView()

  return (
    <ViewItem
      view={view}
      selected={selected.id === view.id}
      isViewDirty={info.state !== 'clean'}
    />
  )
}
