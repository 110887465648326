import { type PersonValueSchema } from '@motion/shared/custom-fields'

import {
  AssigneesDisplay,
  CustomField,
} from '~/areas/project-management/custom-fields'
import { ModalFieldButton } from '~/areas/task-project/components'

import { type ModalCustomField } from '../../types'

type Props = {
  value: PersonValueSchema['value']
  customField: Extract<ModalCustomField, { type: 'person' }>
  onChange: (userId: PersonValueSchema['value']) => void
  disabled?: boolean
  size?: 'small' | 'xsmall'
}

export function Person({
  value,
  customField,
  onChange,
  disabled = false,
  size,
}: Props) {
  return (
    <CustomField.Person
      workspaceId={customField.workspaceId}
      selectedUserId={value}
      onChange={onChange}
    >
      <ModalFieldButton
        label={customField.name}
        disabled={disabled}
        size={size}
      >
        <AssigneesDisplay
          userIds={value ? [value] : null}
          type={customField.type}
          hideIcon
          disabled={disabled}
          size={size}
        />
      </ModalFieldButton>
    </CustomField.Person>
  )
}
