import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { BackendApiService } from './BackendApiService'

import { Events as AnalyticsEvents } from '../analyticsEvents'

export interface UploadImageDto {
  file: File
  taskId?: string
  projectId?: string
  recurringTaskId?: string
  workspaceId: string
}

export interface UploadImageResponse {
  url: string
}

export class WorkspacesService extends BackendApiService {
  static id = 'WorkspacesService' as const

  uploadImage(imageProps: UploadImageDto): Promise<UploadImageResponse> {
    const {
      workspaceId,
      file,
      taskId = null,
      projectId = null,
      recurringTaskId = null,
    } = imageProps
    recordAnalyticsEvent(AnalyticsEvents.PROJECT_MANAGEMENT_UPLOAD_IMAGE)

    const data = new FormData()
    data.append('file', file)

    if (taskId) {
      data.append('taskId', taskId)
    }

    if (projectId) {
      data.append('projectId', projectId)
    }

    if (recurringTaskId) {
      data.append('recurringTaskId', recurringTaskId)
    }

    if (taskId) {
      data.append('resourceType', 'TEAM_TASK')
      data.append('resourceId', taskId)
    } else if (projectId) {
      data.append('resourceType', 'PROJECT')
      data.append('resourceId', projectId)
    } else if (recurringTaskId) {
      data.append('resourceType', 'RECURRING_TASK')
      data.append('resourceId', recurringTaskId)
    }

    if (workspaceId) {
      data.append('workspaceId', workspaceId)
    }

    return this.request(`files/uploadImage`, 'POST', data, false)
  }
}

export const instance = new WorkspacesService()
