import { type CalendarKillSwitch } from '@motion/rpc/types'
import { getEditableCalendars, sortCalendars } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { type CalendarSchema } from '@motion/zod/client'

import { useGetCalendars } from '~/global/rpc/v2'
import {
  selectEmailAccounts,
  selectMainEmailAccountId,
} from '~/state/email-accounts/email-accounts-slice'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useHostEmailAccount } from './use-host-email-account'

export function useAllCalendars(opts?: { enabled?: boolean }): {
  calendars: CalendarSchema[]
  isLoading: boolean
} {
  const { data: calendarsData, isInitialLoading } = useGetCalendars(opts)
  const mainEmailAccountId = useSelector(selectMainEmailAccountId)

  return useMemo(() => {
    const calendars = calendarsData?.calendars ?? []

    return {
      isLoading: isInitialLoading,
      calendars: sortCalendars(calendars, mainEmailAccountId),
    }
  }, [calendarsData?.calendars, isInitialLoading, mainEmailAccountId])
}

export function useMyCalendars(opts?: { enabled?: boolean }): CalendarSchema[] {
  const { calendars } = useAllCalendars()

  return useMemo(() => {
    return calendars.filter((c) => c.isInMyCalendars)
  }, [calendars])
}

export function useFrequentlyMetCalendars(): CalendarSchema[] {
  const { calendars } = useAllCalendars()

  return useMemo(() => {
    return calendars
      .filter((c) => c.isInFrequentlyMet)
      .sort(byProperty('title', Compare.string))
  }, [calendars])
}

export function useActiveCalendars(): CalendarSchema[] {
  const { calendars } = useAllCalendars()

  return useMemo(() => {
    return calendars.filter((c) => c.isEnabled || c.isInMyCalendars)
  }, [calendars])
}

export function useEditableCalendars(): CalendarSchema[] {
  const { calendars } = useAllCalendars()

  return useMemo(() => {
    return getEditableCalendars(calendars)
  }, [calendars])
}

export function useHostCalendar(
  email: string,
  calendarId: CalendarSchema['id']
): {
  hostCalendar: CalendarSchema | null
  isLoading: boolean
} {
  const { calendars, isLoading } = useAllCalendars()
  const hostEmailAccount = useHostEmailAccount(email)

  return useMemo(() => {
    const hostCalendar = calendars.find(
      (cal) =>
        cal.emailAccountId === hostEmailAccount?.id && cal.id === calendarId
    )

    return { hostCalendar: hostCalendar ?? null, isLoading }
  }, [calendarId, calendars, hostEmailAccount?.id, isLoading])
}

export function useMainCalendar(): {
  mainCalendar: CalendarSchema | null
  isLoading: boolean
} {
  const { data: calendarsData, isInitialLoading } = useGetCalendars()

  return useMemo(() => {
    return {
      mainCalendar:
        calendarsData?.calendars.find(
          (cal) => cal.id === calendarsData.mainCalendarId
        ) ?? null,
      isLoading: isInitialLoading,
    }
  }, [
    calendarsData?.calendars,
    calendarsData?.mainCalendarId,
    isInitialLoading,
  ])
}

export function useMainCalendarId(): {
  mainCalendarId: CalendarSchema['id'] | null
  isLoading: boolean
} {
  const { isLoading, mainCalendar } = useMainCalendar()

  return useMemo(() => {
    return {
      mainCalendarId: mainCalendar?.id ?? null,
      isLoading,
    }
  }, [mainCalendar, isLoading])
}

export function useCalendarIsDisabled(): CalendarKillSwitch | null {
  const { data: calendarsData } = useGetCalendars()

  return useMemo(() => {
    return calendarsData?.isDisabled ?? null
  }, [calendarsData?.isDisabled])
}

export function useCalendarsByEmailAccountId(emailAccountId: string) {
  const { calendars } = useAllCalendars()

  return useMemo(() => {
    return calendars.filter((c) => c.emailAccountId === emailAccountId)
  }, [calendars, emailAccountId])
}

export function useCalendarByProviderId(providerId: string) {
  const { calendars } = useAllCalendars()

  return useMemo(() => {
    return calendars.find((c) => c.providerId === providerId)
  }, [calendars, providerId])
}

export function useCalendarsByEmail(
  email?: string,
  calendarId?: string
): CalendarSchema[] {
  const { calendars } = useAllCalendars()
  const emailAccounts = useSelector(selectEmailAccounts)

  return useMemo(() => {
    const emailAccount = emailAccounts.find((e) => e.email === email)

    if (!emailAccount) {
      return []
    }

    return calendars.filter(
      (c) => c.emailAccountId === emailAccount.id && c.id === calendarId
    )
  }, [calendarId, calendars, email, emailAccounts])
}
