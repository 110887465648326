import { TaskSolid } from '@motion/icons'
import { type ProjectSchema, type StageSchema } from '@motion/rpc-types'
import { ActionList, Button, PopoverTrigger, Tag } from '@motion/ui/base'

import { OverdueRow } from './row'

import { useBulkTaskActions, useOverdueTasks } from '../../../hooks'
import { SectionContainer } from '../styled'

type OverdueTasksSectionProps = {
  project: ProjectSchema
  stage?: StageSchema
  onClose: () => void
}

export const OverdueTasksSection = (props: OverdueTasksSectionProps) => {
  const { project, stage, onClose } = props
  const stageDefinitionId = stage ? stage.stageDefinitionId : undefined

  const { data, isLoading } = useOverdueTasks(
    project.workspaceId,
    project.id,
    stageDefinitionId
  )

  const topActions = useBulkTaskActions(
    project.workspaceId,
    data ?? [],
    ['doASAP', 'hardDeadline'],
    onClose
  )

  const bottomActions = useBulkTaskActions(
    project.workspaceId,
    data ?? [],
    ['complete', 'cancel'],
    onClose
  )

  // Don't render the section if loading or no overdue tasks
  if (isLoading || !data || data.length === 0) return null

  return (
    <SectionContainer className='flex flex-col gap-1 p-2'>
      <div className='flex justify-between'>
        <div className='flex gap-2 items-center'>
          <TaskSolid className='size-4' />
          <div className='flex gap-2 items-center'>
            <span className='text-semantic-neutral-text-default font-medium'>
              Fix tasks scheduled past deadline
            </span>
            <Tag size='small' variant='subtle' color='grey'>
              {data.length}
            </Tag>
          </div>
        </div>
        {data.length > 1 && (
          <PopoverTrigger
            renderPopover={() => (
              <ActionList
                sections={[
                  {
                    items: topActions,
                  },
                  {
                    items: bottomActions,
                  },
                ]}
              />
            )}
          >
            <Button size='small' variant='outlined' sentiment='neutral'>
              Fix all
            </Button>
          </PopoverTrigger>
        )}
      </div>
      <div className='pl-6 max-h-[160px] overflow-y-auto overflow-x-hidden'>
        {data && data.length > 0 && (
          <div className='flex flex-col gap-3'>
            {data.map((task) => (
              <OverdueRow
                key={task.id}
                task={task}
                parentType={stageDefinitionId ? 'stage' : 'project'}
                projectOrStageDeadline={stage ? stage.dueDate : project.dueDate}
                onClose={onClose}
              />
            ))}
          </div>
        )}
      </div>
    </SectionContainer>
  )
}
