import { parseDate } from '@motion/utils/dates'
import { logInDev } from '@motion/web-base/logging'

import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'

import api from '../chromeApi/chromeApiBackground'
import { type Task, type TaskChunk, type TaskEvent } from '../taskTypes'

/**
 * Generate a temporary calendar event with the bare minimum settings (e.g.
 * title, non-editable, completed task). If a chunk is provided, then a task
 * event will be generated specifically for the chunk.
 * @param task
 * @param chunk
 */
const generateTaskEvent = (
  task: Task,
  chunk: TaskChunk | null = null
): TaskEvent => {
  const classNames = ['fc-6', 'fc-task', 'fc-complete']

  if (
    (task.duration && task.duration < 60) ||
    (chunk &&
      parseDate(chunk.end).diff(parseDate(chunk.start), 'minutes').minutes < 60)
  ) {
    classNames.push('fc-short')
  }
  return {
    calendarEditable: false,
    classNames: classNames,
    editable: false,
    end: chunk ? chunk.end : task.scheduledEnd,
    eventEditable: false,
    id: nanoid(),
    isCompletedTaskEvent: true,
    isCreator: true,
    motionTaskDone: true,
    motionTaskId: task.id,
    onMyCalendar: true,
    primary: true,
    resourceId: 'user',
    start: chunk ? chunk.start : task.scheduledStart,
    title: task.title,
  }
}

/**
 * Return a new array of events which includes the task events provided. This
 * will first filter out previously generated completed task events before
 * appending the new task events. This function will also filter out events that
 * should have been deleted (either by extension or update function), but
 * haven't due to gcal API issues.
 *
 * @param events
 * @param generatedTaskEvents
 * @param tasks
 */
const addTaskEvents = (
  events: TaskEvent[],
  generatedTaskEvents: TaskEvent[],
  tasks: Record<string, Task>
) => {
  return [
    ...events.filter((event: TaskEvent) => {
      // Filter out existing completed task events (as we'll be appending
      // fresh events right after)
      if (event.isCompletedTaskEvent) {
        return false
      }

      // Proactively remove task events for:
      if (
        event.motionTaskId &&
        // Completed tasks
        ((event.motionTaskId in tasks && tasks[event.motionTaskId].done) ||
          // Tasks that have already been deleted
          !(event.motionTaskId in tasks))
      ) {
        return false
      }

      return true
    }),
    ...generatedTaskEvents,
  ]
}

/**
 * Based on the provided flag value, add or remove completed task events into
 * the calendarEvents and currentCalendarEvents storage variables.
 * @param b
 * @param date
 */
export const setShowCompletedTasks = async (
  b: boolean,
  date: string | null
) => {
  const { calendarEvents, currentCalendarEvents } = await api.storage.local.get(
    ['calendarEvents', 'currentCalendarEvents']
  )

  await persistAndAppendCompletedTasks(
    calendarEvents,
    currentCalendarEvents,
    b,
    date
  )
  return b
}

/**
 * Adds completed task events to the events arrays, and persists to local
 * storage. This function is designed in a way to be used internally but also
 * callable from calendar util functions which mutate calendar events
 * @param calendarEvents
 * @param currentCalendarEvents
 * @param showCompletedTasks Set as null to retrieve existing value from storage
 * @param date current date or custom date range of task events to generate, +-
 *   2 weeks
 */
const persistAndAppendCompletedTasks = async (
  calendarEvents: TaskEvent[],
  currentCalendarEvents: TaskEvent[],
  showCompletedTasks: boolean | null = null,
  date: string | null = null
) => {
  // Pull state from local storage if not explicitly set
  if (showCompletedTasks === null) {
    const res = await api.storage.local.get(['showCompletedTasks'])
    showCompletedTasks = !!res.showCompletedTasks
  }

  const res = await api.storage.local.get(['tasks'])
  const tasks = res.tasks as Record<string, Task>

  // If we want to show completed tasks, generate task events
  let generatedTaskEvents: TaskEvent[] = []
  if (showCompletedTasks) {
    generatedTaskEvents = await generateCompletedTaskEvents(
      tasks,
      date ?? undefined
    )
  }

  const dataToPersist: Record<string, unknown> = { showCompletedTasks }

  if (calendarEvents) {
    dataToPersist.calendarEvents = addTaskEvents(
      calendarEvents,
      generatedTaskEvents,
      tasks
    )
  }

  if (currentCalendarEvents) {
    dataToPersist.currentCalendarEvents = addTaskEvents(
      currentCalendarEvents,
      generatedTaskEvents,
      tasks
    )
  }

  await api.storage.local.set(dataToPersist)
  return dataToPersist
}

/**
 * Generate completed task events for all relevant completed tasks within a
 * date range. A date can be provided, which will generate tasks +- 2 weeks, or
 * the function will use the current datetime.
 *
 * @param tasks
 * @param date
 */
const generateCompletedTaskEvents = async (
  tasks: Record<string, Task>,
  date: string | null = null
) => {
  const taskEvents: TaskEvent[] = []

  // Create date range of +- 2 weeks from provided/current date of tasks to
  // generate calendar events for
  const base = date ? DateTime.fromISO(date) : DateTime.now()
  const earlyTime = base.minus({ week: 2 }).toISO()
  const lateTime = base.plus({ week: 2 }).toISO()

  for (const [, task] of Object.entries(tasks)) {
    if (task.allowDivide && task.chunks && task.chunks.length > 0) {
      for (const chunk of task.chunks) {
        if (
          (task.done || chunk.done) &&
          parseDate(chunk.start) >= parseDate(earlyTime) &&
          parseDate(chunk.start) < parseDate(lateTime)
        ) {
          taskEvents.push(generateTaskEvent(task, chunk))
        }
      }
    } else if (
      task.done &&
      task.scheduledStart &&
      parseDate(task.scheduledStart) >= parseDate(earlyTime) &&
      parseDate(task.scheduledStart) < parseDate(lateTime)
    ) {
      taskEvents.push(generateTaskEvent(task))
    }
  }

  logInDev('completed task events', taskEvents)
  return taskEvents
}
