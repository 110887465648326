import { Sentry } from '@motion/web-base/sentry'

import { useMyCalendars } from '~/areas/calendar/hooks'

import { Callout } from './callout'
import { SearchSection } from './search-section'
import { UserCalendarItem } from './user-calendar-item'

import { ModeToLabels } from '../../constants'
import { useScheduleAssistantCalendarContext } from '../../hooks'
import { Sidebar, SidebarSection } from '../styled'

export function SelectExistingSidebar() {
  const [calendarState] = useScheduleAssistantCalendarContext()
  const userCalendars = useMyCalendars({
    enabled: calendarState.mode === 'select-existing',
  })

  if (calendarState.mode !== 'select-existing') {
    Sentry.captureException(
      'SelectExistingSidebar rendered in the wrong mode',
      {
        extra: { calendarState: JSON.stringify(calendarState) },
      }
    )
    return null
  }

  return (
    <Sidebar>
      <SidebarSection hasBottomBorder>
        <Callout label={ModeToLabels[calendarState.mode].calloutLabel} />

        <SearchSection />
      </SidebarSection>

      <SidebarSection gap='sm'>
        <h4 className='text-xs font-semibold text-semantic-neutral-text-subtle'>
          My calendars ({userCalendars.length})
        </h4>

        {userCalendars.map((calendar) => (
          <UserCalendarItem calendar={calendar} key={calendar.id} />
        ))}
      </SidebarSection>
    </Sidebar>
  )
}
