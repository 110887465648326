import {
  PopoverTrigger,
  type PopoverTriggerProps,
  SearchableList,
} from '@motion/ui/base'
import { createDurationsFromText, getDurations } from '@motion/ui-logic'

import { type ReactNode } from 'react'

export type DurationDropdownProps = {
  children: React.ReactElement
  value: number | null
  onChange: (duration: number | null) => void
  placement?: PopoverTriggerProps['placement']
  choices?: {
    label: string
    value: number | null
  }[]
  maxDuration?: number | null
  renderPopoverFooter?: () => ReactNode
}

export const DurationDropdown = ({
  value,
  choices = getDurations({ includeNone: true }),
  placement = 'bottom-start',
  maxDuration,
  renderPopoverFooter,
  onChange,
  children,
}: DurationDropdownProps) => {
  const handleFilter = (search: string) => {
    const searchDurations = search ? createDurationsFromText(search) : []

    const finalDurations =
      searchDurations.length > 0 ? searchDurations : choices

    return maxDuration == null
      ? finalDurations
      : finalDurations.filter(
          (duration) => duration.value != null && duration.value <= maxDuration
        )
  }

  return (
    <PopoverTrigger
      placement={placement}
      renderPopover={({ close }) => {
        const footer = renderPopoverFooter?.()

        return (
          <>
            <SearchableList
              items={choices}
              computeKey={(item) => String(item.value)}
              computeSelected={(item) => item.value === value}
              onSelect={(item) => {
                onChange(item.value)

                if (footer == null) {
                  close()
                }
              }}
              renderItem={(item) => item.label}
              filter={handleFilter}
              inputProps={{
                placeholder: 'Choose or type a duration...',
              }}
            />
            {footer}
          </>
        )
      }}
    >
      {children}
    </PopoverTrigger>
  )
}
