import { getUpdatedStages } from '@motion/ui-logic/pm/project'
import { type ProjectSchema } from '@motion/zod/client'

import { getInitialStageLocation } from './get-initial-stage-location'
import { growProjectRight } from './grow-project-right'
import { shrinkProjectRight } from './shrink-project-right'

import { getProjectStartAndEnd, pixelToDate } from '../../../../../utils'
import { type Side } from '../../../resize-handle'
import { type StageWithDates } from '../../types'
import { isFirstStage } from '../is-first-stage'
import { isLastStage } from '../is-last-stage'

type getStageLocationProjectResize = {
  dayPx: number
  currentSide?: Side
  stageWithDates: StageWithDates
  stageLeft: number
  maxStageLeft: number
  maxStageWidth: number
  projectDeltaWidth: number
  initialProjectWidth: number
  project: ProjectSchema
  shiftModifier?: boolean
}
export const getStageLocationProjectResize = ({
  dayPx,
  currentSide,
  stageWithDates,
  projectDeltaWidth,
  stageLeft,
  maxStageLeft,
  maxStageWidth,
  project,
  initialProjectWidth,
  shiftModifier = false,
}: getStageLocationProjectResize) => {
  const { start: projectStart, end: projectEnd } = getProjectStartAndEnd(
    project,
    dayPx
  )
  const projectLeft =
    currentSide === 'left' ? projectStart - projectDeltaWidth : projectStart
  const projectRight =
    currentSide === 'right'
      ? projectEnd + projectDeltaWidth
      : projectEnd - dayPx
  const projectStartDate = pixelToDate(projectLeft, dayPx).toISODate()
  const projectDueDate = pixelToDate(projectRight, dayPx).toISODate()
  const isShrinking = projectDeltaWidth < 0

  const defaults = {
    maxStageLeft,
    stageLeft,
    maxStageWidth,
  }
  if (projectStartDate > projectDueDate || projectDeltaWidth === 0) {
    return defaults
  }

  // TODO: Extract logic out to a function, general code cleanup
  if (shiftModifier) {
    if (currentSide === 'right') {
      if (isShrinking) {
        return shrinkProjectRight({
          stageLeft,
          maxStageWidth,
          maxStageLeft,
          projectWidth: initialProjectWidth,
          projectDeltaWidth,
        })
      }

      return growProjectRight({
        delta: projectDeltaWidth,
        stageLeft,
        maxStageWidth,
        maxStageLeft,
        isLastStage: isLastStage(stageWithDates.stage.id, project.stages),
      })
    }

    const firstStage = isFirstStage(stageWithDates.stage.id, project.stages)
    if (firstStage) {
      return {
        maxStageLeft,
        stageLeft: stageLeft - projectDeltaWidth,
        maxStageWidth: maxStageWidth + projectDeltaWidth,
      }
    }
    return defaults
  }

  const { stagesWithDates: adjustedStagesWithDates } = getUpdatedStages({
    project,
    startDate: projectStartDate,
    dueDate: projectDueDate,
  })

  const newStageDates = adjustedStagesWithDates.find(
    (stage) =>
      stage.stage.stageDefinitionId === stageWithDates.stage.stageDefinitionId
  )

  if (!newStageDates) {
    return defaults
  }

  const {
    maxStageWidth: adjustedMaxStageWidth,
    pixelsFromProjectStart: adjustedStageLeft,
    initialProjectWidth: adjustedMaxStageLeft,
  } = getInitialStageLocation({
    dayPx,
    stageWithDates: {
      ...newStageDates,
      stage: {
        ...stageWithDates.stage,
        ...newStageDates.stage,
      },
    },
    project: {
      ...project,
      startDate: projectStartDate,
      dueDate: projectDueDate,
    },
  })

  if (currentSide === 'left') {
    const firstStage = isFirstStage(stageWithDates.stage.id, project.stages)

    if (firstStage) {
      return {
        maxStageLeft: adjustedMaxStageLeft,
        stageLeft: stageLeft - projectDeltaWidth,
        maxStageWidth:
          projectDeltaWidth > 0
            ? Math.max(adjustedMaxStageWidth, maxStageWidth)
            : adjustedMaxStageWidth,
      }
    }

    // left side stageLeft date calculation wasn't working so return adjusted dates to use instead
    return {
      maxStageLeft: adjustedMaxStageLeft,
      stageLeft: adjustedStageLeft,
      maxStageWidth: adjustedMaxStageWidth,
      adjustedStartDate: newStageDates.start,
      adjustedEndDate: newStageDates.due,
    }
  }

  return {
    stageLeft: adjustedStageLeft,
    maxStageWidth: adjustedMaxStageWidth,
    maxStageLeft: adjustedMaxStageLeft,
  }
}
