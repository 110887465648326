import { type IdFilterSchema } from '@motion/zod/client'

import { UserFilterItem, type UserFilterItemProps } from './user-filter-item'

import { type UseFieldFilter, useFieldFilter } from '../../../../context'
import { type DropdownFilterProps } from '../../../types'

type UserFilterProps = DropdownFilterProps & {
  label: string
  field: 'assigneeUserIds' | 'managerIds' | 'createdByUserIds'
  hideEmptyUser?: UserFilterItemProps['hideEmptyUser']
}

export const ConnectedUserFilter = (props: UserFilterProps) => {
  const [value, setValue] = useFieldFilter(
    props.target,
    // @ts-expect-error - the field  will be fine
    props.field
  ) as UseFieldFilter<IdFilterSchema | null>

  return (
    <UserFilterItem
      label={props.label}
      filter={value}
      setFilter={setValue}
      target={props.target}
      openOnMount={props.openOnMount}
      onBlur={props.onBlur}
      hideEmptyUser={props.hideEmptyUser}
    />
  )
}
