import { ActionList, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ReactNode } from 'react'

import { type BulkOpsAction, useBulkOpsState } from '../../contexts'

type SelectedMenuDropdownProps = {
  children: ReactNode
}

export function SelectedMenuDropdown({ children }: SelectedMenuDropdownProps) {
  const { triggerAction: ctxTrigger } = useBulkOpsState()

  const triggerAction = (action: BulkOpsAction) => {
    recordAnalyticsEvent('BULK_OPS_PANEL_SELECT_DROPDOWN', {
      action: action,
    })
    ctxTrigger(action)
  }

  return (
    <PopoverTrigger
      placement='top-start'
      renderPopover={({ close }) => (
        <ActionList
          onActionAnyItem={close}
          items={[
            {
              content: 'Unselect all',
              onAction: () => triggerAction('unselect-all'),
            },
            {
              content: 'Select all',
              onAction: () => triggerAction('select-all'),
            },
          ]}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
