import { type DateTime } from 'luxon'
import { createContext, useContext } from 'react'

export type AutoSaveValue = {
  lastSaved: DateTime | undefined
  shouldSupressSavingToast: boolean
  setLastSavedTime: (time: DateTime) => void
  resetLastSavedTime: () => void
}

const defaultValue: AutoSaveValue = {
  lastSaved: undefined,
  shouldSupressSavingToast: false,
  setLastSavedTime: () => {},
  resetLastSavedTime: () => {},
}

export const AutoSaveContext = createContext(defaultValue)

export function useAutosaveContext() {
  return useContext(AutoSaveContext)
}
