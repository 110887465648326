import { AvailabilityEvent as UIAvailabilityEvent } from '@motion/ui/calendar'

import { type EventContentArg } from '@fullcalendar/core'

import { removeAvailabilityEvent } from '../../../../../state/calendarEvents/calendarEventsSlice'
import { useAppDispatch } from '../../../../../state/hooks'

type Props = {
  event: EventContentArg
}

/**
 * Component for rendering a selected availability event onto FullCalendar.
 */
export const AvailabilityEvent = (props: Props) => {
  const { event: eventArg } = props
  const { event } = eventArg
  const { start, end } = event

  const dispatch = useAppDispatch()

  if (!start) return null

  const handleDelete = () => {
    dispatch(removeAvailabilityEvent({ id: event.id }))
  }

  return (
    <UIAvailabilityEvent
      startTime={start}
      endTime={end ?? start}
      onRemove={handleDelete}
    />
  )
}
