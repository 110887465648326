import {
  type COLOR,
  isCanceledStatus,
  isCompletedStatus,
} from '@motion/shared/common'
import { Tooltip } from '@motion/ui/base'
import {
  ProjectColoredIcon,
  type ProjectColoredIconVariant,
} from '@motion/ui/project'
import { type ProjectSchema } from '@motion/zod/client'

import { useProject, useWorkspaceStatusById } from '~/global/hooks'
import { type ComponentRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type ProjectBadgeProps = {
  value: Pick<ProjectSchema, 'color' | 'name' | 'statusId'> | null
  size?: BadgeSize
  hideTooltip?: boolean
  className?: string
}

export const ProjectBadge = forwardRef<
  ComponentRef<typeof ProjectColoredIcon>,
  ProjectBadgeProps
>(function ProjectBadge({ value, size, hideTooltip, className }, ref) {
  const pixels = getBadgeSizeInPixels(size)

  const colorOption = (value?.color ?? 'gray') as COLOR
  const status = useWorkspaceStatusById(value?.statusId ?? '')

  let variant: ProjectColoredIconVariant | undefined

  if (isCompletedStatus(status)) {
    variant = 'COMPLETED'
  } else if (isCanceledStatus(status)) {
    variant = 'CANCELED'
  }

  const icon = (
    <ProjectColoredIcon
      ref={ref}
      className={twMerge('shrink-0', className)}
      width={pixels}
      height={pixels}
      color={colorOption}
      variant={variant}
    />
  )

  if (hideTooltip) return icon

  return (
    <Tooltip asChild content={`Project: ${value?.name ?? 'None'}`}>
      {icon}
    </Tooltip>
  )
})

type ConnectedProjectBadgeProps = Omit<ProjectBadgeProps, 'value'> & {
  id: string | null
}
export const ConnectedProjectBadge = ({
  id,
  ...rest
}: ConnectedProjectBadgeProps) => {
  const project = useProject(id)
  return <ProjectBadge value={project} {...rest} />
}
