import { isNoneId } from '@motion/shared/identifiers'
import { NO_DURATION, SHORT_TASK_DURATION } from '@motion/ui-logic'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { type NormalTaskSchema, type TaskSchema } from '@motion/zod/client'

import { useCompletedChoices } from '~/areas/project-management/hooks'
import { useCallback } from 'react'

import { useTaskCompletedDurationUpdater } from '../updaters'

export function useTaskCompletedDurationData(task: TaskSchema) {
  const updater = useTaskCompletedDurationUpdater()

  const taskCompletedDuration =
    task.type === 'CHUNK' ? 0 : task.completedDuration
  const taskDuration = task.duration ?? 0

  const completedChoices = useCompletedChoices(
    taskCompletedDuration,
    taskDuration
  )

  const isFake = isNoneId(task.id)
  const isMeeting = isMeetingTask(task)

  const disabled =
    isFake ||
    task.archivedTime != null ||
    task.completedTime != null ||
    task.duration === SHORT_TASK_DURATION ||
    task.duration === NO_DURATION ||
    isMeeting

  const hasValidData = !isMeeting

  const onChange = useCallback(
    (completedDuration: NormalTaskSchema['completedDuration']) => {
      if (completedDuration === taskCompletedDuration) return

      void updater(task, completedDuration ?? 0)
    },
    [task, taskCompletedDuration, updater]
  )

  return {
    choices: completedChoices,
    completedDuration: task.type === 'CHUNK' ? 0 : task.completedDuration,
    onChange,
    disabled,
    isFake,
    hasValidData,
  }
}
