import { Sidebar } from '@motion/ui/base'
import { AnalyticsMetadata } from '@motion/web-base/analytics'

import { useShowProjectSidebarState } from '~/areas/project-management/states'
import { ProjectSidePanel } from '~/areas/task-project/components'
import { type ReactNode } from 'react'
import { useParams } from 'react-router-dom'

import { BulkOpsContainer } from '../bulk-ops'

type ConnectedProjectTasksPageProps = {
  children: ReactNode
}

/**
 * This is a wrapper component that adds a sidebar to the project tasks page
 */
export function ConnectedProjectTasksPage({
  children,
}: ConnectedProjectTasksPageProps) {
  const { projectId } = useParams<{ projectId: string }>()
  const [sidebarState] = useShowProjectSidebarState()

  return (
    <div className='flex-1 flex min-w-0 min-h-0'>
      <BulkOpsContainer target='tasks'>{children}</BulkOpsContainer>

      {projectId && (
        <AnalyticsMetadata data={{ section: 'project-sidepanel' }}>
          <Sidebar
            minWidth={300}
            hideCloseButton
            resizeDirection='left'
            closedWidth={-75}
            open={sidebarState.open}
          >
            <ProjectSidePanel projectId={projectId} />
          </Sidebar>
        </AnalyticsMetadata>
      )}
    </div>
  )
}
