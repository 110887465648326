import { MagicWandSolid, XSolid } from '@motion/icons'
import {
  Button,
  GradientButton,
  IconButton,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { values } from '@motion/utils/object'
import { logInDev } from '@motion/web-base/logging'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'

import { Highlight } from '~/analytics/highlight'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { ErrorBoundary } from '~/global/components'
import { showErrorToast } from '~/global/toasts'
import { type FormEvent, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

import {
  Attachments,
  ErrorScreen,
  FieldWrapper,
  Footer,
  FormShell,
  Header,
  LoadingScreen,
  ModalBody,
  Section,
  SectionSubtitle,
  SectionTitle,
} from './components'
import { FlowsAIForm } from './flows-ai-form'
import { useFlowsAIForm, useSubmitFlowsAi } from './hooks'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'flows-ai-modal': {
      workspaceId: string
    }
  }
}

type ConnectedFlowsAIModalProps = ModalTriggerComponentProps<'flows-ai-modal'>

export function ConnectedFlowsAIModal({
  close,
  workspaceId,
}: ConnectedFlowsAIModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { cancelActiveFileUploads } = useFileUploadState()

  const handleClose = () => {
    cancelActiveFileUploads({
      targetId: null,
      targetType: null,
    })
    Highlight.stop()
    close()
  }

  return (
    <UnstyledModal
      overlayClassName='bg-modal-overlay'
      data-testid='flows-ai-modal'
      visible
      onClose={handleClose}
      disableOverlayClick={isLoading}
      disableEscapeKey={isLoading}
      withAnimation
    >
      <ModalBody>
        <FlowsAIForm workspaceId={workspaceId}>
          <ErrorBoundary
            renderFallback={({ reset }) => <ErrorScreen onBack={reset} />}
          >
            <InnerEventModal
              close={handleClose}
              loading={isLoading}
              onLoadingChange={setIsLoading}
            />
          </ErrorBoundary>
        </FlowsAIForm>
      </ModalBody>
    </UnstyledModal>
  )
}

type InnerEventModalProps = Pick<ConnectedFlowsAIModalProps, 'close'> & {
  loading: boolean
  onLoadingChange: (loading: boolean) => void
}

function InnerEventModal({
  close,
  loading,
  onLoadingChange,
}: InnerEventModalProps) {
  const form = useFlowsAIForm()
  const submitForm = useSubmitFlowsAi()
  const modalApi = useModalApi()
  const user = useAuthenticatedUser()

  useShortcut('mod+s', () => {
    handleSubmit()
  })

  useEffect(
    function loadHighlight() {
      try {
        Highlight.identify(user.email, {
          name: user.displayName ?? '',
          uid: user.uid,
        })
        Highlight.track('AI_PWT_MODAL_OPEN')
        Highlight.start()
      } catch (e) {
        logInDev('error starting Highlight', e)
        void e
      }
    },
    [user]
  )

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    await form.handleSubmit(
      async (data) => {
        try {
          onLoadingChange(true)

          const result = await submitForm(data)

          if (result) {
            modalApi.open('flows-template-modal', {
              mode: 'ai-generation',
              initialProjectDefinition: result.pwt,
            })
          }
        } catch (e) {
          if (e instanceof Error) {
            form.setError('root.submitError', {
              message: e.message,
            })
          }
        } finally {
          onLoadingChange(false)
        }
      },
      (validationErrors) => {
        const firstError = values(validationErrors)[0]

        if (!firstError) {
          return
        }

        if (firstError.type === 'required') {
          showErrorToast('Please fill out the required fields.')
        }
      }
    )(e)
  }

  const errors = form.formState.errors

  if (errors.root?.submitError != null) {
    throw new Error(errors.root?.submitError.message)
  }

  if (loading) {
    return (
      <FormShell>
        <LoadingScreen />
      </FormShell>
    )
  }

  return (
    <FormShell onSubmit={handleSubmit}>
      <Header>
        <h2 className='text-base flex items-center gap-2 font-semibold text-semantic-neutral-text-default'>
          <MagicWandSolid className='size-[18px] text-semantic-gradient-purple' />
          Create project workflow with AI (beta)
        </h2>

        <IconButton
          icon={XSolid}
          sentiment='neutral'
          variant='muted'
          onClick={close}
        />
      </Header>

      <Section>
        <SectionTitle>Describe your project</SectionTitle>

        <Controller
          name='message'
          control={form.control}
          render={({ field }) => (
            <FieldWrapper field='message'>
              <TextField
                value={field.value}
                onChange={field.onChange}
                multiline
                noResize
                autoSize={{
                  minRows: 5,
                }}
                label='Project Description'
                labelHidden
                placeholder='This project is for our web design agency to design a website for a client.'
                sentiment={errors.message ? 'error' : 'default'}
              />
            </FieldWrapper>
          )}
        />
      </Section>

      <Section>
        <SectionTitle>Upload up to 3 documents that are relevant </SectionTitle>
        <SectionSubtitle>
          Relevant documents can include PDFs, spreadsheets (.csv, .xlsx), text
          documents (.docx, .txt), or images (.jpeg, .png) that describe your
          Standard Operating Procedures. Or documents with sample projects or
          workflows you currently have in your organization.
        </SectionSubtitle>

        <Attachments />
      </Section>

      <Footer>
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={close}
          shortcut='esc'
        >
          Cancel
        </Button>

        <GradientButton
          disabled={form.formState.isSubmitting}
          type='submit'
          sentiment='purple'
          shortcut='mod+s'
        >
          <MagicWandSolid />
          Generate & Preview
        </GradientButton>
      </Footer>
    </FormShell>
  )
}
