import { TextField } from '@motion/ui/forms'
import { Sentry } from '@motion/web-base/sentry'

import { useParseCancellation, useUtilizedSubscription } from '~/global/rpc'
import { DateTime } from 'luxon'
import { useState } from 'react'

import { PencilIcon } from '../components/icons'
import { ModalFooter } from '../components/modal-footer'
import { ModalMainWrapper } from '../components/modal-main-wrapper'
import { ModalTitle } from '../components/modal-title'
import { type CancelScreenProps } from '../components/types'

export const CancellationReason = ({
  onNextClick,
  onCloseModal,
  setNextScreen,
}: CancelScreenProps) => {
  const stripeSubscription = useUtilizedSubscription()

  const { mutateAsync: parseCancellation } = useParseCancellation()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const openAiResponse = await parseCancellation({
        content: reason,
      })
      const parsedReason = openAiResponse?.reason
      if (
        parsedReason === 'discount' &&
        stripeSubscription &&
        !stripeSubscription.discount &&
        stripeSubscription.plan.interval !== 'year' &&
        stripeSubscription.trial_end &&
        stripeSubscription.trial_end > DateTime.now().toSeconds()
      ) {
        setNextScreen('discount')
      }
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'cancellationInterceptionParseCancellation' },
      })
    }

    onNextClick()
  }

  const [reason, setReason] = useState('')

  return (
    <>
      <ModalMainWrapper>
        <ModalTitle
          text="Can you let us know why you're cancelling?"
          subtext='Your feedback is greatly appreciated!'
          icon={PencilIcon}
        />
        <TextField
          label='Cancellation reason'
          labelHidden
          placeholder='Enter reason for cancelling'
          multiline
          noResize
          autoSize={{ minRows: 11 }}
          value={reason}
          onChange={(value) => setReason(value)}
        />
      </ModalMainWrapper>
      <ModalFooter
        onCloseModal={onCloseModal}
        onNextClick={handleSubmit}
        disableNext={loading || !reason}
      />
    </>
  )
}
