import { useSharedState } from '@motion/react-core/shared-state'
import { Tooltip, useShortcut } from '@motion/ui/base'

import { memo, useEffect, useRef, useState } from 'react'

import { ScrollDeltaKey } from '../../context/context-keys'
import { useResizable } from '../../hooks'

export type Side = 'left' | 'right'
type ResizeHandleProps = {
  onChange: (change: { width: number; left: number }) => void
  onResizeEnd: (side: Side) => void
  onResizeStart: (side: Side) => void
  onResizeCancel: () => void
  tooltipContent?: string
  side: Side
}

export const ResizeHandle = memo(function ResizeHandle(
  props: ResizeHandleProps
) {
  const [scrollDelta] = useSharedState(ScrollDeltaKey)

  const { onChange, onResizeEnd, onResizeStart, onResizeCancel, side } = props
  const [delta, setDelta] = useState(0)
  const isActive = useRef(false)
  const [didCancel, setDidCancel] = useState(false)

  useShortcut(
    'escape',
    () => {
      setDidCancel(true)
      isActive.current = false
      setDelta(0)
      onResizeCancel()
    },
    { enabled: isActive.current }
  )

  const handleResizeStart = () => {
    isActive.current = true
    onResizeStart?.(side)
  }

  const handleResizeEnd = (side?: Side) => {
    if (!didCancel && side) {
      isActive.current = false
      onResizeEnd(side)
      setDelta(0)
    }

    setDidCancel(false)
  }

  useEffect(() => {
    if (!isActive.current) return
    const widthDelta =
      side === 'left' ? -delta - scrollDelta : delta + scrollDelta

    const leftDelta = side === 'left' ? delta + scrollDelta : 0

    onChange({
      width: widthDelta,
      left: leftDelta,
    })
  }, [delta, side, onChange, scrollDelta])

  const [ref] = useResizable({
    side,
    onResize: setDelta,
    onResizeEnd: handleResizeEnd,
    onResizeStart: handleResizeStart,
  })

  return (
    <div
      ref={ref}
      className='absolute'
      style={{
        left: side === 'left' ? -20 : undefined,
        right: side === 'right' ? 0 : undefined,
      }}
    >
      <Tooltip placement='top-end' content={props.tooltipContent}>
        <div
          onClick={(e) => e.stopPropagation()}
          className='absolute cursor-col-resize top-2 bottom-0 w-5 h-6 hidden group-hover/planner-project-item:flex z-[2]'
        >
          <div
            className='absolute top-1 bottom-1 w-0.5 bg-palette-bg-selected rounded-sm'
            style={{
              left: side === 'left' ? 14 : undefined,
              right: side === 'right' ? 14 : undefined,
            }}
          />
        </div>
      </Tooltip>
    </div>
  )
})
