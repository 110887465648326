import type { Modifier } from '@dnd-kit/core'
import { useCallback } from 'react'

import { useValidProviderIds } from '../../hooks'
import { OOO_CALENDAR_ROW_HEIGHT } from '../../shared-constants'

export const useShiftToContainer = (): Modifier => {
  const validProviderIds = useValidProviderIds()
  const providersLength = validProviderIds.length
  return useCallback(
    ({ activeNodeRect, transform, scrollableAncestorRects }) => {
      if (activeNodeRect) {
        const rect = scrollableAncestorRects[0]
        if (!rect) {
          return transform
        }

        return {
          ...transform,
          x: transform.x - rect.left,
          y:
            transform.y -
            rect.top +
            activeNodeRect.height +
            OOO_CALENDAR_ROW_HEIGHT * providersLength,
        }
      }

      return transform
    },
    [providersLength]
  )
}
