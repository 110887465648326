import { DuplicateSolid, QuestionMarkCircleSolid } from '@motion/icons'
import { showToast, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'

import { getTzAbbr } from '~/utils/time'
import * as DOMPurify from 'dompurify'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'

import { copyToClipboard } from '../../../../localServices/clipboard'
import {
  type BookingSettings as BookingSettingsType,
  getSettings,
  selectBookingSettings,
  updateBookingSettings,
  updateUrlPrefix,
} from '../../../../state/booking'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import {
  availabilityMessageVariables,
  defaultAvailabilityMessageTemplate,
  sampleMeetingTimes,
} from '../../../../storageConstants'
import {
  Input,
  Paragraph,
  PrimaryButton,
  SubParagraph,
  TextButton,
} from '../../../Common'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

const populateAvailabilityMessageTemplateText = (
  text: string,
  {
    meetingTimes = sampleMeetingTimes,
    bookingLink = 'usemotion.com/your-booking-link',
    timezone = undefined,
    duration = '30 min',
  }
) => {
  const newTimezone = timezone || getTzAbbr(DateTime.now().zoneName)
  let newText = DOMPurify.default.sanitize(text)
  newText = newText.split('$Meeting times$').join(meetingTimes)
  newText = newText.split('$Booking link$').join(bookingLink)
  newText = newText.split('$Timezone$').join(newTimezone)
  newText = newText.split('$Duration$').join(duration)
  return newText
}

export const BookingSettings = () => {
  const dispatch = useAppDispatch()
  const settings = useAppSelector(selectBookingSettings)

  const [originalUrlPrefix, setOriginalUrlPrefix] = useState('')
  const [urlPrefixInput, setUrlPrefixInput] = useState('')
  const [urlPrefixResult, setUrlPrefixResult] = useState('')
  const [messageTemplateInput, setMessageTemplateInput] = useState('')
  const [populatedMessageTemplate, setPopulatedMessageTemplate] = useState('')
  const [savingAvailability, setSavingAvailability] = useState(false)
  const [availabilityErrorMessage, setAvailabilityErrorMessage] = useState('')

  /**
   * Update react state when redux settings change
   */
  useEffect(
    function onSettingsChange() {
      const target = settings ?? ({} as Partial<BookingSettingsType>)

      setOriginalUrlPrefix(target.urlPrefix ?? '')
      setUrlPrefixInput(target.urlPrefix ?? '')

      const currentTemplate =
        target.customAvailabilityMessageTemplate ??
        defaultAvailabilityMessageTemplate
      setMessageTemplateInput(currentTemplate)
      setPopulatedMessageTemplate(
        populateAvailabilityMessageTemplateText(currentTemplate, {})
      )
    },
    [settings]
  )

  const urlPrefixInputHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setUrlPrefixInput(e.target.value)
    },
    []
  )

  const availabilityMessageHandler = useCallback((text: string) => {
    setMessageTemplateInput(text)
    setPopulatedMessageTemplate(
      populateAvailabilityMessageTemplateText(text, {})
    )
  }, [])

  const clearAvailabilityErrors = useCallback(() => {
    setAvailabilityErrorMessage('')
    setUrlPrefixResult('')
  }, [])

  const saveAvailabilityHandler = useCallback(async () => {
    clearAvailabilityErrors()
    setSavingAvailability(true)
    if (originalUrlPrefix !== urlPrefixInput) {
      try {
        await dispatch(updateUrlPrefix({ urlPrefix: urlPrefixInput })).unwrap()
      } catch (e) {
        logInDev('Issue saving Url prefix', e)
        setUrlPrefixResult('Issue saving your URL')
        setSavingAvailability(false)
        return
      }
    }

    const newSettings = {
      messageTemplate: messageTemplateInput,
    }

    await dispatch(updateBookingSettings(newSettings)).unwrap()

    setSavingAvailability(false)

    // Re-pull so that redux settings are set properly
    void dispatch(getSettings())
    recordAnalyticsEvent('CALENDAR_AVAILABILITY_SETTINGS_SAVE')
  }, [
    originalUrlPrefix,
    urlPrefixInput,
    messageTemplateInput,
    clearAvailabilityErrors,
    dispatch,
  ])

  return (
    <SettingPage title='Booking' className='gap-6'>
      <div className='flex w-full flex-col gap-3.5'>
        <div className='flex items-center gap-2.5'>
          <Paragraph className='font-semibold'>Change your base URL</Paragraph>

          <Tooltip
            asChild
            content='This global identifier will be in each of your permanent links.'
          >
            <TextButton icon={QuestionMarkCircleSolid} />
          </Tooltip>
        </div>

        <SubParagraph className='font-semibold'>
          Note: changing your base URL means that your previously copied{' '}
          <span className='italic'>template</span> links will no longer work and
          need to be updated.
        </SubParagraph>

        <div className='flex items-center gap-3'>
          <Paragraph className='text-sm'>app.usemotion.com/meet/</Paragraph>

          <Input
            value={urlPrefixInput}
            onChange={urlPrefixInputHandler}
            className='w-full'
          />
        </div>

        {urlPrefixResult && (
          <Paragraph className='text-alert-400 dark:text-alert-400'>
            {urlPrefixResult}
          </Paragraph>
        )}

        <SubParagraph>
          Motion will send out invites from https://app.usemotion.com/meet/
          {urlPrefixInput}/[meeting-invite]
        </SubParagraph>
      </div>

      <div className='flex w-full justify-between gap-6'>
        <div className='flex w-full flex-col gap-4'>
          <Paragraph className='font-semibold'>
            Default availability message
          </Paragraph>

          <TextField
            multiline
            autoSize={{ minRows: 10, maxRows: 20 }}
            placeholder='Body'
            value={messageTemplateInput}
            onChange={(value) => {
              availabilityMessageHandler(value)
            }}
          />
        </div>

        <div className='flex w-full flex-col gap-4'>
          <Paragraph className='font-semibold'>Sample</Paragraph>

          <SubParagraph className='w-full whitespace-pre'>
            {populatedMessageTemplate}
          </SubParagraph>

          <div className='flex flex-col gap-3'>
            <Paragraph className='text-xs font-semibold'>Variables</Paragraph>

            <div className='flex flex-col gap-1'>
              {availabilityMessageVariables.map((varString) => (
                <div key={varString} className='flex items-center gap-2.5'>
                  <Paragraph className='text-xs'> {varString}</Paragraph>

                  <TextButton
                    icon={DuplicateSolid}
                    onClick={() => {
                      void copyToClipboard({ text: varString })
                      showToast('success', 'Copied!')
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {availabilityErrorMessage && (
        <Paragraph className='text-alert-400 dark:text-alert-400'>
          {availabilityErrorMessage}
        </Paragraph>
      )}

      <PrimaryButton
        className='max-w-max'
        onClick={saveAvailabilityHandler}
        loading={savingAvailability}
      >
        Save
      </PrimaryButton>
    </SettingPage>
  )
}
