import { type ComponentRef, forwardRef } from 'react'

import { ProjectRow } from './project-row'

import { AddProjectRow } from '../../add-project'
import { type PlannerRowProps, type StyledPlannerRow } from '../../common'
import { StyledPlannerRowContainer } from '../../container'

export const SinglePlannerRow = forwardRef<
  ComponentRef<typeof StyledPlannerRow>,
  PlannerRowProps<any> & { eventHeight?: number }
>(function SinglePlannerRow(
  { row, toggleExpandAllRows, visible, eventHeight, ...props },
  ref
) {
  if (!visible)
    return <StyledPlannerRowContainer ref={ref} role='row' {...props} />
  return (
    <StyledPlannerRowContainer ref={ref} role='row' {...props}>
      {row?.original.value.type === 'add-project-row' ? (
        <AddProjectRow row={row} />
      ) : (
        <ProjectRow project={row?.original.value.value} />
      )}
    </StyledPlannerRowContainer>
  )
})
