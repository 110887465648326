import { type NormalTaskSchema } from '@motion/zod/client'

import { useBlockerTasks, useTaskBlockersUpdater } from '~/areas/tasks/hooks'

import { SidebarTaskList } from '../../components'

export const BlockerTaskList = ({
  type,
  task,
}: {
  type: 'blockedBy' | 'blocks'
  task: NormalTaskSchema
}) => {
  const { blockedBy, blocking } = useBlockerTasks({
    taskId: task.id,
  })
  const { removeTaskBlocker } = useTaskBlockersUpdater({
    type,
  })

  const taskList = type === 'blockedBy' ? blockedBy : blocking

  return (
    <div className='pl-5'>
      <SidebarTaskList
        tasks={taskList}
        onRemove={(blocker) => removeTaskBlocker(task, blocker)}
        size='xsmall'
      />
    </div>
  )
}
