import { useModalApi } from '@motion/web-common/modals'

import { useCalendarsByEmailAccountId } from '~/areas/calendar/hooks'

import {
  selectEmailAccountAddedModal,
  selectEmailAccountById,
  selectMainEmailAccount,
  setEmailAccountAddedModal,
} from '../../state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { EmailAccountAddedModal } from '../Modals/email-account-added-modal/email-account-added-modal'

export const EmailAccountAddedModalHandler = () => {
  const dispatch = useAppDispatch()
  const modalApi = useModalApi()

  const modal = useAppSelector(selectEmailAccountAddedModal)
  const emailAccount = useAppSelector((state) =>
    selectEmailAccountById(state, modal.emailAccountId ?? 'none')
  )
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const calendars = useCalendarsByEmailAccountId(emailAccount?.id ?? 'none')

  const onComplete = () => {
    dispatch(
      setEmailAccountAddedModal({ visible: false, emailAccountId: undefined })
    )

    if (!emailAccount || !mainEmailAccount) {
      // TODO
      return
    }

    modalApi.open('change-main-calendar', {
      emailAccount,
      mainEmailAccount,
    })
  }

  if (!modal.visible || !emailAccount) return null

  return (
    <EmailAccountAddedModal
      calendars={calendars}
      emailAccount={emailAccount}
      onComplete={onComplete}
    />
  )
}
