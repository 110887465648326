import { createAsyncThunk } from '@reduxjs/toolkit'

import { type CalendarListService } from '../../services/calendarListService'
import { getProxy } from '../backgroundProxy'
import type { RootState } from '../types'

const THUNK_PREFIX = 'calendar'

const calendarService = getProxy('CalendarService')
const calendarListService = getProxy('CalendarListService')

/**
 * @deprecated need to figure out a way to trigger this transparently on the
 * backend
 */
export const updateCalendarList = createAsyncThunk<
  void,
  { updateFrequentlyMet: boolean },
  { state: RootState }
>(`${THUNK_PREFIX}/updateCalendarList`, async ({ updateFrequentlyMet }) => {
  await calendarListService.update({ updateFrequentlyMet })
})

/**
 * @deprecated remove when kill firestore - calendar is complete
 */
export const getCalendars = createAsyncThunk<
  Awaited<ReturnType<CalendarListService['get']>>,
  undefined,
  { state: RootState }
>(`${THUNK_PREFIX}/getCalendars`, async () => {
  return await calendarListService.get()
})

/**
 * WARNING: Use sparingly!
 * Endpoint for the frontend to trigger the update function. Should be used sparingly.
 * It's to be used when the calendar list is updated (accounts added/removed),
 * but ideally we should be adding calendar_list CRUD endpoints which would trigger the update function on change
 * @deprecated remove when kill firestore - calendar is complete
 */
export const dangerouslyTriggerUpdateFunction = createAsyncThunk<
  void,
  void,
  { state: RootState }
>(`${THUNK_PREFIX}/dangerouslyTriggerUpdateFunction`, async () => {
  return await calendarService.dangerouslyTriggerUpdateFunction()
})
