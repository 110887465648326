import { twMerge } from 'tailwind-merge'

export type HorizontalDividerProps = {
  className?: string
}

export const HorizontalDivider = ({ className }: HorizontalDividerProps) => {
  return (
    <span
      className={twMerge(
        'dark:bg-dark-900 bg-light-400 my-5 h-[1px] w-full',
        className
      )}
    />
  )
}
