export const BOOKING_ONBOARDING_CAROUSEL_DATA = {
  IMAGES: {
    DARK: [
      'https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/608761771791de3afe41021e_scheduler1.png',
      'https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/6087617880950999dc9f1b31_scheduler2.png',
      'https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/608761781dd3ab1bb582714c_scheduler3.png',
      'https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/608761772d1214e59324372a_scheduler4.png',
    ],
    LIGHT: [
      'https://storage.googleapis.com/external-files.usemotion.com/Light_Schedule_Onboarding_1.png',
      'https://storage.googleapis.com/external-files.usemotion.com/Light_Schedule_Onboarding_2.png',
      'https://storage.googleapis.com/external-files.usemotion.com/Light_Schedule_Onboarding_3.png',
      'https://storage.googleapis.com/external-files.usemotion.com/Light_Schedule_Onboarding_4.png',
    ],
  },
  CAPTIONS: [
    "Set your schedule. We'll use your calendar events to find your availability.",
    'Get a link that your guests can use to book meetings with you.',
    "We'll suggest meeting times that maximize your focus time.",
    'Booking link too impersonal? Copy your availabilities as a text message for a personal touch.',
  ],
}
