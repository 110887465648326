import { MotionLogoSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import {
  type ETADateOptions,
  type ETADateReturn,
  formatToReadableWeekDayMonth,
} from '@motion/ui-logic'
import { keys } from '@motion/utils/object'

type ProjectedDateOptionsProps = {
  options: ETADateReturn
  selectedOption: ETADateOptions
  onChooseOption: (option: ETADateOptions) => void
}

export const ProjectedDateOptions = ({
  options,
  selectedOption = 'projectedDate',
  onChooseOption,
}: ProjectedDateOptionsProps) => {
  return (
    <div className='grid grid-cols-3 grid-rows-1 gap-1'>
      {keys(options).map((option) => {
        const active = option === selectedOption

        return (
          <Button
            key={option}
            sentiment={active ? 'primary' : 'neutral'}
            variant='outlined'
            size='xsmall'
            onClick={() => onChooseOption(option)}
            fullWidth
          >
            <ButtonInnerContainer
              size={option === 'projectedDate' ? 'normal' : 'small'}
            >
              <ButtonTitle active={active}>
                {formatToReadableWeekDayMonth(options[option])}
              </ButtonTitle>
              {option === 'projectedDate' ? (
                <SubtitleContainer active={active}>
                  <ButtonSubtitle active={active}>
                    <div className='flex gap-0.5 items-center'>
                      <LogoContainer active={active}>
                        <MotionLogoSolid width={12} height={12} />
                      </LogoContainer>
                      <span>Projected date</span>
                    </div>
                  </ButtonSubtitle>
                  <ButtonSubtext active={active}>Recommended</ButtonSubtext>
                </SubtitleContainer>
              ) : option === 'projectedEoW' ? (
                <SubtitleContainer active={active}>
                  <span>
                    {options['projectedEoW'].weekNumber ===
                    options['projectedDate'].weekNumber
                      ? 'End of week of'
                      : '1 week after'}
                  </span>
                  <ButtonSubtitle active={active}>
                    <LogoContainer active={active}>
                      <MotionLogoSolid width={8} height={8} />
                    </LogoContainer>
                    <ButtonSubtext active={active}>
                      Projected date
                    </ButtonSubtext>
                  </ButtonSubtitle>
                </SubtitleContainer>
              ) : option === 'projectedEoM' ? (
                <SubtitleContainer active={active}>
                  {options['projectedEoM'].month ===
                  options['projectedDate'].month
                    ? 'End of month of'
                    : '1 month after'}
                  <ButtonSubtitle active={active}>
                    <LogoContainer active={active}>
                      <MotionLogoSolid width={8} height={8} />
                    </LogoContainer>
                    <ButtonSubtext active={active}>
                      Projected date
                    </ButtonSubtext>
                  </ButtonSubtitle>
                </SubtitleContainer>
              ) : null}
            </ButtonInnerContainer>
          </Button>
        )
      })}
    </div>
  )
}

const ButtonInnerContainer = classed('div', {
  base: `
    flex flex-col items-center gap-1 py-3
  `,
  variants: {
    size: {
      small: `[&_[data-icon]]:!size-3`,
      normal: `[&_[data-icon]]:!size-3`,
    },
  },
  defaultVariants: {
    size: 'normal',
  },
})

const SubtitleContainer = classed('div', {
  base: `flex flex-col items-center text-2xs font-normal`,
  variants: {
    active: {
      true: `text-semantic-primary-text-default`,
      false: `text-semantic-neutral-text-subtle`,
    },
  },
  defaultVariants: {
    active: false,
  },
})

const LogoContainer = classed('div', {
  variants: {
    active: {
      true: `text-semantic-primary-text-default`,
      false: `text-semantic-neutral-text-default`,
    },
  },
  defaultVariants: {
    active: false,
  },
})

const ButtonTitle = classed('p', {
  base: `
    font-semibold
    text-2xs
  `,
  variants: {
    active: {
      true: `text-semantic-primary-text-default`,
      false: `text-semantic-neutral-text-default`,
    },
  },
  defaultVariants: {
    active: false,
  },
})

const ButtonSubtitle = classed('div', {
  base: `
    flex-row flex gap-0.5
    items-center
    text-2xs
  `,
  variants: {
    active: {
      true: `text-semantic-primary-text-default`,
      false: `text-semantic-neutral-text-subtle`,
    },
  },
  defaultVariants: {
    active: false,
  },
})

const ButtonSubtext = classed('p', {
  base: `
    text-[10px]
  `,
  variants: {
    active: {
      true: `text-semantic-primary-text-default`,
      false: `text-semantic-neutral-text-disabled`,
    },
  },
  defaultVariants: {
    active: false,
  },
})
