import { UserSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import { ConnectedUserLabel } from '~/global/components/labels'

import { BulkAssigneeDropdown, FieldButton } from '../components'
import { useBulkOpsField } from '../contexts'
import { useBulkFieldValidation, useBulkFilteredUsers } from '../hooks'

export function AssigneeField() {
  const [assigneeUserId, setAssigneeUserId] = useBulkOpsField('assigneeUserId')
  const [isAutoScheduled] = useBulkOpsField('isAutoScheduled')
  const filteredUsers = useBulkFilteredUsers()

  const active = assigneeUserId !== undefined

  const { isValid, errMsg } = useBulkFieldValidation([
    () => ({
      isValid: filteredUsers.length > 0,
      errMsg: 'No assignees are shared between the tasks you selected',
    }),
  ])

  return (
    <Tooltip content={errMsg}>
      <BulkAssigneeDropdown
        onChange={(id) => {
          setAssigneeUserId(id)
        }}
        selectedUserId={assigneeUserId}
        filteredUsers={filteredUsers}
        removeUnassignedOption={isAutoScheduled}
      >
        <FieldButton
          analyticsName='assignee'
          disabled={!isValid}
          active={active}
          onRemove={() => {
            setAssigneeUserId(undefined)
          }}
        >
          {active ? (
            <ConnectedUserLabel userId={assigneeUserId} size='small' />
          ) : (
            <>
              <UserSolid />
              Assignee
            </>
          )}
        </FieldButton>
      </BulkAssigneeDropdown>
    </Tooltip>
  )
}
