import { Checkbox } from '@motion/ui/forms'
import { useActiveFilter } from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  useAllCancelledStatuses,
  useAllCompletedStatuses,
} from '~/global/hooks'

export const ConnectedShowPastDeadlineButton = () => {
  const [filter, setFilter] = useActiveFilter()
  const cancelledStatuses = useAllCancelledStatuses()
  const completedStatuses = useAllCompletedStatuses()
  const resolvedStatusIds = cancelledStatuses
    .concat(completedStatuses)
    .map((status) => status.id)

  if (filter.target !== 'tasks') return null

  const toggle = (checked: boolean) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_SHOW_PAST_DEADLINE', {
      checked,
    })

    setFilter((prev) => {
      // filter out cancelled statuses if there's no current status filter, and remove this filter when toggling off
      const taskFilters = { ...prev.tasks.filters }
      const hasStatusFilters = Boolean(taskFilters.statusIds?.value?.length)
      const shouldFilterStatuses = checked && !hasStatusFilters

      const shouldRemoveResolvedStatusesFilter =
        !checked &&
        taskFilters.statusIds?.inverse &&
        taskFilters.statusIds.value?.every((statusId) =>
          resolvedStatusIds.includes(statusId)
        )

      if (shouldFilterStatuses) {
        taskFilters['statusIds'] = {
          value: resolvedStatusIds,
          operator: 'in',
          inverse: true,
        }
      } else if (shouldRemoveResolvedStatusesFilter) {
        taskFilters['statusIds'] = null
      }

      return {
        ...prev,
        tasks: {
          ...prev.tasks,
          filters: {
            ...taskFilters,
            scheduledStatus: checked
              ? { operator: 'in', value: ['PAST_DUE', 'UNFIT_PAST_DUE'] }
              : null,
          },
        },
      }
    })
  }

  const isChecked = Boolean(
    filter.tasks.filters.scheduledStatus?.value.includes('PAST_DUE') ||
      filter.tasks.filters.scheduledStatus?.value.includes('UNFIT_PAST_DUE')
  )
  return (
    <Checkbox
      checked={isChecked}
      onChange={toggle}
      label='Only show scheduled past deadline'
      size='small'
    />
  )
}
