import { PlusSolid } from '@motion/icons'
import {
  Button,
  SearchableTree,
  type VirtualizedTreeNode,
} from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useWorkspacesDropdownTree } from './hooks'
import { getNodeType } from './utils'
import { type WorkspacesTreeDropdownProps } from './workspaces-tree-dropdown'
import { WorkspacesTreeNodeIcon } from './workspaces-tree-node-label'

export type WorkspacesSearchableTreeProps = Pick<
  WorkspacesTreeDropdownProps,
  | 'selectedId'
  | 'workspaceId'
  | 'leafNodeType'
  | 'onChange'
  | 'computeDisabled'
  | 'hideNoProject'
  | 'showCreateFolder'
> & { closePopover: () => void }

export const WorkspacesSearchableTree = ({
  selectedId,
  leafNodeType = 'PROJECT',
  onChange,
  computeDisabled,
  hideNoProject = false,
  workspaceId,
  closePopover,
  showCreateFolder,
}: WorkspacesSearchableTreeProps) => {
  const noneItemLabel =
    leafNodeType === 'PROJECT'
      ? 'No project'
      : leafNodeType === 'FOLDER'
        ? 'No folder'
        : undefined

  const modalApi = useModalApi()

  const { rootNode, models } = useWorkspacesDropdownTree({
    leafNodeType,
    noneItemLabel,
    computeDisabled,
    hideNoProject,
  })

  const openCreateNewFolderModal = () => {
    modalApi.open('create-new-folder', {
      workspaceId,
    })
  }

  const handleChange = (node: VirtualizedTreeNode) => {
    const selected: {
      projectId: string | null
      folderId: string | null
      workspaceId: string
    } = { workspaceId: '', folderId: null, projectId: null }
    if (node.path) {
      for (const id of node.path) {
        const folder = models.folders[id]
        const project = models.projects[id]
        if (project) {
          selected.projectId = project.id
        }
        if (folder) {
          if (folder.type === 'WORKSPACE') {
            selected.workspaceId = folder.targetId
          } else {
            selected.folderId = folder.id
          }
        }
      }
    }

    onChange(selected)
  }

  return (
    <>
      <SearchableTree
        rootNode={rootNode}
        onSelect={handleChange}
        selectedId={selectedId}
        computeSelectable={(node) => {
          const type = getNodeType(node, models.folders, models.projects)
          return type === leafNodeType || type === 'NONE'
        }}
        renderLabel={(node) => <span className='truncate'>{node.label}</span>}
        renderIcon={(node) => (
          <WorkspacesTreeNodeIcon
            id={node.id}
            nodeType={getNodeType(node, models.folders, models.projects)}
            leafNodeType={leafNodeType}
          />
        )}
      />

      {showCreateFolder && (
        <div className='flex flex-col border-t border-dropdown-border mt-1'>
          <Button
            alignment='left'
            onClick={() => {
              closePopover()
              openCreateNewFolderModal()
            }}
            sentiment='neutral'
            variant='muted'
          >
            <PlusSolid />
            Create folder
          </Button>
        </div>
      )}
    </>
  )
}
