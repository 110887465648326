import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { END_STAGE_RESIZE_BUFFER, RESIZE_INTO_STAGE_BUFFER } from './constants'
import { growProjectRight } from './grow-project-right'
import { shrinkProjectRight } from './shrink-project-right'

import { type ResizeStageDetails } from '../../types'
import { isLastStage } from '../is-last-stage'

type GetStageLocationProjectResizeArgs = {
  stage: StageSchema
  stageLeft: number
  maxStageLeft: number
  maxStageWidth: number
  project: ProjectSchema
  stageDetails?: ResizeStageDetails
  shiftModifier?: boolean
}

type AdjustStageLeftAndWidthArgs = {
  stageLeft: number
  maxStageWidth: number
  maxStageLeft: number
  delta: number
  originalRight: number
}

type HandleResizingLastStageArgs = {
  isDraggingRight: boolean
  delta: number
  stageLeft: number
  maxStageWidth: number
  maxStageLeft: number
}

type HandleResizingNextStageArgs = {
  maxStageLeft: number
  originalRight: number
  stageLeft: number
  maxStageWidth: number
  delta: number
}

function adjustStageLeftAndWidth({
  stageLeft,
  maxStageLeft,
  maxStageWidth,
  delta,
}: AdjustStageLeftAndWidthArgs) {
  if (delta < 0 && maxStageWidth + delta < 0) {
    // The left should be the total reduction amount
    stageLeft -= Math.abs(maxStageWidth + delta)
  }

  // Dragging the stage due date to the left, you reduce the maxStageWidth
  maxStageWidth += delta

  // If maxStageWidth is less than 0, set it to 0
  if (maxStageWidth < 0) {
    maxStageWidth = 0
  }

  if (stageLeft < 0) {
    stageLeft = 0
  }

  return { stageLeft, maxStageWidth, maxStageLeft }
}

// Resizing last stage adjusts the entire project deadline
function handleResizingLastStage({
  isDraggingRight,
  delta,
  stageLeft,
  maxStageWidth,
  maxStageLeft,
}: HandleResizingLastStageArgs) {
  if (isDraggingRight) {
    return growProjectRight({
      delta: delta,
      stageLeft,
      maxStageWidth,
      maxStageLeft,
      isLastStage: true,
    })
  }

  return shrinkProjectRight({
    projectDeltaWidth: -delta,
    projectWidth: maxStageLeft + END_STAGE_RESIZE_BUFFER,
    maxStageWidth,
    stageLeft,
    maxStageLeft,
  })
}

// The effect on the next stage when resizing a stage (it grows)
function handleResizingNextStage({
  maxStageLeft,
  stageLeft,
  originalRight,
  maxStageWidth,
  delta,
}: HandleResizingNextStageArgs) {
  // The right of the original stage (the stage being resized) is the max we can grow the next stage
  return {
    maxStageWidth: maxStageWidth + Math.min(originalRight, Math.abs(delta)),
    stageLeft: stageLeft - Math.min(originalRight, Math.abs(delta)),
    maxStageLeft,
  }
}

function handleResizeIntoStage({
  stageLeft,
  maxStageWidth,
  maxStageLeft,
  delta,
  originalRight,
}: AdjustStageLeftAndWidthArgs) {
  // The amount to reduce is how far into the current stage we've resized
  // We can calculate this by checking how far past the right the delta is from the original right
  let amountToReduce = Math.abs(
    originalRight + delta - (stageLeft + maxStageWidth)
  )

  return {
    stageLeft: maxStageWidth - amountToReduce < 0 ? 0 : stageLeft,
    maxStageWidth: maxStageWidth - amountToReduce,
    maxStageLeft,
  }
}

export function getStageLocationStageResize({
  stage,
  stageLeft,
  maxStageLeft,
  maxStageWidth,
  project,
  stageDetails,
  shiftModifier,
}: GetStageLocationProjectResizeArgs) {
  let {
    delta,
    stageId: resizingStageId,
    projectId,
    originalLeft,
    originalRight,
  } = stageDetails || {
    delta: 0,
    projectId: undefined,
    stageId: undefined,
    originalRight: 0,
    originalLeft: 0,
  }

  const defaults = { stageLeft, maxStageWidth, maxStageLeft }

  if (!delta || !resizingStageId || project.id !== projectId) {
    return defaults
  }

  let isDraggingRight = delta > 0
  let isResizingLastStage = isLastStage(resizingStageId, project.stages)
  let resizingIndex = project.stages.findIndex((s) => s.id === resizingStageId)
  let currentItemIndex = project.stages.findIndex((s) => s.id === stage.id)

  if (isResizingLastStage && resizingStageId === stage.id) {
    return handleResizingLastStage({
      isDraggingRight,
      delta,
      stageLeft,
      maxStageWidth,
      maxStageLeft,
    })
  }

  if (isDraggingRight) {
    // The current item and all items after it should be affected such that their maxStageWidth is increased
    if (currentItemIndex === resizingIndex) {
      return {
        maxStageWidth: maxStageWidth + delta,
        stageLeft,
        maxStageLeft: maxStageLeft + delta,
      }
    } else if (currentItemIndex > resizingIndex) {
      if (shiftModifier) {
        // only eat into the next stage if we're using the shift modifier
        if (currentItemIndex === resizingIndex + 1) {
          return {
            stageLeft: stageLeft + delta,
            maxStageWidth: Math.max(0, maxStageWidth - delta),
            maxStageLeft,
          }
        }
        return { stageLeft, maxStageWidth, maxStageLeft }
      }

      return {
        stageLeft: stageLeft + delta,
        maxStageWidth,
        maxStageLeft: maxStageLeft + delta,
      }
    }

    // Items before the resizing stage should not be affected
    return { maxStageWidth, stageLeft, maxStageLeft }
  }

  // Delta cannot be positive if we're dragging left
  if (!isDraggingRight && resizingStageId === stage.id) {
    return adjustStageLeftAndWidth({
      stageLeft,
      maxStageLeft,
      maxStageWidth,
      delta,
      originalRight,
    })
  }

  if (!shiftModifier) {
    if (currentItemIndex > resizingIndex) {
      return {
        stageLeft: stageLeft + delta,
        maxStageWidth,
        maxStageLeft: maxStageLeft + delta,
      }
    }

    return defaults
  }

  const stageRight = stageLeft + maxStageWidth
  const draggingStagePosition = originalRight + delta

  // Grow the next stage if we're shrinking our current stage
  const nextStageResize = resizingIndex === currentItemIndex - 1 && delta < 0

  if (nextStageResize) {
    return handleResizingNextStage({
      maxStageLeft,
      stageLeft,
      maxStageWidth,
      originalRight,
      delta,
    })
  }

  const hasResizedIntoStage =
    draggingStagePosition > stageLeft && draggingStagePosition < stageRight

  if (hasResizedIntoStage) {
    const resizeIntoStageRes = handleResizeIntoStage({
      stageLeft,
      maxStageWidth,
      maxStageLeft,
      delta,
      originalRight,
    })

    if (isResizingLastStage && resizeIntoStageRes.maxStageWidth > 0) {
      // We need to add a buffer for the end of the project
      return {
        ...resizeIntoStageRes,
        maxStageWidth:
          resizeIntoStageRes.maxStageWidth + RESIZE_INTO_STAGE_BUFFER,
      }
    }

    return resizeIntoStageRes
  }

  const hasStageBoundariesCrossed =
    (draggingStagePosition < stageLeft && originalLeft > stageLeft) ||
    (draggingStagePosition > stageRight && originalLeft < stageRight)

  if (hasStageBoundariesCrossed) {
    return { stageLeft: draggingStagePosition, maxStageWidth: 0, maxStageLeft }
  }

  return { stageLeft, maxStageWidth, maxStageLeft }
}
