import { type COLOR } from '@motion/shared/common'
import {
  getColorClassIdForColor,
  getColorFromColorClassId,
} from '@motion/ui/palette'
import {
  colorClassIdToColorId,
  type ColorId,
  colorIdToColorClassId,
  disabledGoogleEventColorIds,
  orderedColorIds,
} from '@motion/ui-logic'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useHostCalendar } from '~/areas/calendar/hooks'
import { useCachedItem } from '~/global/cache'
import { useProject } from '~/global/hooks'
import { useCallback, useMemo } from 'react'

import { getEventColor } from '../../utils'
import { useUpdateEvent } from '../actions'

export const useEventColorData = (
  event: Pick<
    CalendarEventSchemaV2,
    'id' | 'email' | 'calendarId' | 'meetingTaskId' | 'colorId'
  >
) => {
  const updateEvent = useUpdateEvent()
  const { hostCalendar } = useHostCalendar(event.email, event.calendarId)
  const calendar = useCachedItem('calendars', event.calendarId)
  const meetingTask = useCachedItem('tasks', event.meetingTaskId)
  const project = useProject(meetingTask?.projectId)

  const onChangeColor = useCallback(
    (color: COLOR) => {
      const colorClassId = getColorClassIdForColor(color)
      const colorId =
        colorClassId == null ? null : colorClassIdToColorId[colorClassId]

      updateEvent(event.id, { colorId })
    },
    [event.id, updateEvent]
  )

  const colorOptions = useMemo(() => {
    if (
      // We only support changing specific event colors for google events
      hostCalendar?.providerType !== 'GOOGLE'
    ) {
      // color changes not allowed
      return []
    }

    const options = orderedColorIds
      .filter((c) => !disabledGoogleEventColorIds.includes(c))
      .map((colorId) =>
        getColorFromColorClassId(colorIdToColorClassId[colorId])
      )

    if (project != null && !options.includes(project.color)) {
      options.push(project.color)
    }

    return options
  }, [hostCalendar?.providerType, project])

  const eventColor = useMemo(
    () =>
      getEventColor(event.colorId as ColorId | null, {
        projectColor: project?.color,
        calendarColorId: calendar?.colorId as ColorId | undefined,
      }),
    [calendar?.colorId, event.colorId, project?.color]
  )

  return {
    colorOptions,
    onChangeColor,
    eventColor,
  }
}
