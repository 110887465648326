import { useCallback, useEffect, useState } from 'react'

type CursorPosition = {
  x: number
  y: number
}

export const useCursorPosition = () => {
  const [rawCursorPosition, setRawCursorPosition] = useState<CursorPosition>({
    x: 0,
    y: 0,
  })

  const handlePointerMove = useCallback((event: MouseEvent) => {
    setRawCursorPosition({
      x: Math.floor(event.clientX),
      y: Math.floor(event.clientY),
    })
  }, [])

  useEffect(() => {
    if (!document) return

    document.addEventListener('pointermove', handlePointerMove, {
      passive: false,
    })

    return () => {
      if (!document) return

      document.removeEventListener('pointermove', handlePointerMove)
    }
  }, [handlePointerMove])

  return rawCursorPosition
}
