import { isCustomFieldKey } from '@motion/ui-logic'
import { createLookup } from '@motion/utils/object'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useMemo } from 'react'

import { getCustomFieldIdFromColumnId, type TaskFieldId } from '../../fields'
import { type GroupedNode, type TaskGroupableFieldIds } from '../../grouping'
import { createCustomFieldGroupingName } from '../../grouping/custom-fields'
import {
  type GroupTaskParents,
  type TreeListRowValueType,
} from '../../tree-list'
import { useViewState } from '../../view-state'

export function useDataForCopyTasks(item: GroupedNode<TreeListRowValueType>) {
  const [viewState] = useViewState()
  const { getCustomField } = useCustomFieldsFns()

  const visibleColumns = useMemo(
    () =>
      viewState.columns
        .filter((col) => col.visible == null || col.visible)
        .map((col) => {
          const customFieldId = getCustomFieldIdFromColumnId(col.id)
          if (customFieldId != null) {
            const field = getCustomField(customFieldId)
            if (field != null) {
              return createCustomFieldGroupingName(field)
            }
            return null
          }
          return col.id
        })
        .filter(Boolean),
    [getCustomField, viewState.columns]
  )

  const leafs = useMemo(() => getLeafs(item), [item])
  const list = useMemo(
    () =>
      leafs
        .filter((item) => item.value.type === 'task')
        .map((item) => item.value.value) as Extract<
        TreeListRowValueType,
        { type: 'task' }
      >['value'][],
    [leafs]
  )

  const columns = useMemo(() => {
    const parentGroupColumns =
      leafs.length > 0
        ? ([
            leafs[0].parent?.value,
            leafs[0].parent?.parent?.value,
            leafs[0].parent?.parent?.parent?.value,
          ]
            .filter(Boolean)
            // @ts-expect-error = @@root isn't exposed
            .filter((g) => g.type !== '@@root')
            .map((g) =>
              isCustomFieldKey(g.type) ? g.type : groupToColumn(g.type)
            )
            .reverse() as (keyof GroupTaskParents)[])
        : []

    const columns = [
      ...parentGroupColumns,
      ...visibleColumns.filter((c) => !parentGroupColumns.find((x) => c === x)),
    ]

    if (parentGroupColumns.includes('project')) {
      moveProjectFirst(columns)
    }

    return columns
  }, [leafs, visibleColumns])

  return { list, columns }
}

function getLeafs(
  node: GroupedNode<TreeListRowValueType>
): GroupedNode<TreeListRowValueType>[] {
  if (node.children == null) return [node]

  return node.children.reduce<GroupedNode<TreeListRowValueType>[]>(
    (acc, child) => {
      if (child.key === 'totals-row') return acc
      return acc.concat(getLeafs(child))
    },
    []
  )
}

function moveProjectFirst(items: string[]) {
  let projectIndex = items.indexOf('project')
  if (projectIndex >= 0) {
    const removed = items.splice(projectIndex, 1)
    items.unshift(...removed)
  }
  return items
}

// TODO: Rename the groups to match the column name
const groupToColumn = createLookup<
  Record<Exclude<TaskGroupableFieldIds, 'scheduledDate'>, TaskFieldId> & {
    default: string
  }
>({
  label: 'labels',
  priority: 'priority',
  project: 'project',
  status: 'status',
  stage: 'stage',
  user: 'assignee',
  createdBy: 'createdBy',
  workspace: 'workspace',
  default: 'unknown',
  completedAt: 'completedAt',
  deadline: 'deadline',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  startDate: 'startDate',
  folder: 'folder',
  deadlineStatus: 'deadlineStatus',
})
