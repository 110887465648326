import { PlusSolid } from '@motion/icons'
import { type PMTaskType } from '@motion/rpc/types'
import { Button, Tooltip } from '@motion/ui/base'

import { TemplateBlockersTypeDropdown } from './template-blockers-type-dropdown'

export type TemplateBlockersDropdownProps = {
  task: PMTaskType | undefined
  type: 'blocks' | 'blockedBy'
  sameProject: boolean
  disabled?: boolean
}

export function TemplateBlockersDropdown(props: TemplateBlockersDropdownProps) {
  const { task, type, sameProject, disabled } = props

  const trigger = (
    <Tooltip
      asChild
      content={
        task == null
          ? 'You must create a task before adding blockers'
          : !sameProject
            ? 'You must save the task before adding blockers'
            : undefined
      }
    >
      <Button
        variant='muted'
        sentiment='neutral'
        size='small'
        disabled={disabled}
      >
        <PlusSolid />
        Add task
      </Button>
    </Tooltip>
  )

  if (task == null || task.type !== 'TEMPLATE') {
    return trigger
  }

  return (
    <TemplateBlockersTypeDropdown task={task} type={type}>
      {trigger}
    </TemplateBlockersTypeDropdown>
  )
}
