import { XSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'
import { IconButton, Tooltip, type TooltipProps } from '@motion/ui/base'

import { forwardRef, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type BaseProps = VariantProps<typeof LabelButton> &
  VariantProps<typeof ChildrenContainer> & {
    children: ReactNode
    suffix?: ReactNode
    disabled?: boolean
    clearTooltipContent?: TooltipProps['content']
    onClear?: React.MouseEventHandler<HTMLButtonElement>
    onClick?: React.MouseEventHandler<HTMLLabelElement>
    htmlFor?: HTMLLabelElement['htmlFor']
  }

type PropsWithIcon = BaseProps & {
  icon: ReactNode
}

type PropsWithLabel = BaseProps & {
  label: string
}

export type ModalFieldButtonProps = PropsWithIcon | PropsWithLabel

export const ModalFieldButton = forwardRef<
  HTMLLabelElement,
  ModalFieldButtonProps
>((props, ref) => {
  const {
    children,
    onClear,
    clearTooltipContent,
    onClick,
    disabled = false,
    suffix,
    size,
    variant,
    sentiment,
    wrap,
    htmlFor,
  } = props

  const hasLabel = 'label' in props && props.label != null
  const hasIcon = 'icon' in props
  if (!hasLabel && !hasIcon) {
    throw new Error('ModalFieldButton must have either a label or an icon')
  }

  return (
    <LabelButton
      role='button'
      onClick={(e) => {
        if (disabled) {
          return
        }
        onClick?.(e)
      }}
      onKeyDown={(e) => {
        if (disabled) {
          return
        }
        if (e.key === 'Enter') {
          onClick?.(e as any)
        }
      }}
      ref={ref}
      tabIndex={0}
      aria-disabled={disabled || undefined}
      hasIcon={hasIcon}
      size={size}
      variant={variant}
      sentiment={sentiment}
      htmlFor={htmlFor}
    >
      {hasLabel && (
        <Tooltip
          asChild
          content={props.label.length > 20 ? props.label : undefined}
        >
          <div
            className={twMerge(
              !disabled && 'text-semantic-neutral-text-subtle',
              'flex max-w-[50%]'
            )}
          >
            <span className='truncate'>{props.label}</span>
            <span>:</span>
          </div>
        </Tooltip>
      )}
      {hasIcon && <div className='shrink-0'>{props.icon}</div>}

      <ChildrenContainer
        hasIcon={hasIcon}
        aria-disabled={disabled || undefined}
        size={size}
        wrap={wrap}
      >
        {children}
      </ChildrenContainer>

      {suffix != null && (
        <SuffixWrapper onClick={(evt) => evt.stopPropagation()}>
          {suffix}
        </SuffixWrapper>
      )}

      {onClear != null && !disabled && (
        <ClearButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Tooltip asChild content={clearTooltipContent}>
            <IconButton
              variant='muted'
              sentiment='neutral'
              icon={XSolid}
              onClick={onClear}
              size='xsmall'
            />
          </Tooltip>
        </ClearButtonWrapper>
      )}
    </LabelButton>
  )
})

ModalFieldButton.displayName = 'ModalFieldButton'

const LabelButton = classed('label', {
  base: `
    group/modal-field-button

    relative flex items-center gap-1.5
    px-1

    min-h-[30px] w-full

    rounded

    text-sm
    text-semantic-neutral-text-default

    hover:bg-button-neutral-muted-bg-hover

    aria-[disabled]:cursor-not-allowed
    aria-[disabled]:text-semantic-neutral-text-disabled
    aria-[disabled]:hover:bg-transparent
    aria-[disabled]&_[data-icon]:text-semantic-neutral-icon-disabled

    focus-visible:outline
    focus-visible:outline-1
    focus-visible:-outline-offset-1
    focus-visible:outline-button-primary-solid-border-focus
    focus-visible:bg-button-neutral-outlined-bg-default
  `,
  variants: {
    hasIcon: {
      true: 'gap-2',
    },
    size: {
      xsmall: `
        min-h-[20px]
        [&_[data-icon]]:w-3
        [&_[data-icon]]:h-3
        text-xs
      `,
      small: `
        [&_[data-icon]]:w-3
        [&_[data-icon]]:h-3
      `,
      normal: `
        [&_[data-icon]]:w-4
        [&_[data-icon]]:h-4
      `,
    },
    variant: {
      subtle: `
        [&_[data-icon]]:text-semantic-neutral-icon-subtle
        `,
      normal: `
        [&_[data-icon]]:text-semantic-neutral-icon-default
      `,
    },
    sentiment: {
      neutral: ``,
      ai: `
        text-semantic-purple-text-default
        [&_[data-icon]]:text-semantic-purple-text-default
        aria-[disabled]:text-semantic-purple-text-disabled
        aria-[disabled]&_[data-icon]:text-semantic-purple-icon-disabled
      `,
    },
  },
  defaultVariants: {
    size: 'small',
    variant: 'normal',
    sentiment: 'neutral',
  },
})

const ChildrenContainer = classed('div', {
  base: `
    flex items-center flex-1
    h-full gap-1.5

    [&_span[data-size=small]>input]:text-sm
    lg:[&_span[data-size=small]>input]:text-sm
    [&_span]:text-sm

    aria-[disabled]:text-semantic-neutral-text-disabled
  `,
  variants: {
    hasIcon: {
      true: 'gap-2',
    },
    size: {
      xsmall: `
          [&_span[data-size=xsmall]>input]:text-xs
          lg:[&_span[data-size=xsmall]>input]:text-xs
          [&_span[data-size=small]>input]:text-xs
          [&_span]:text-xs
          [&_span]:p-0
          [&_input]:text-xs
      `,
      small: '',
      normal: '',
    },
    wrap: { true: 'flex-wrap', false: 'truncate' },
  },
  defaultVariants: {
    wrap: false,
  },
})

const SuffixWrapper = classed('div', {
  base: `
    shrink-0
  `,
})

const ClearButtonWrapper = classed(
  'div',
  'shrink-0',
  'invisible group-hover/modal-field-button:visible group-focus-within/modal-field-button:visible'
)
