import { CheckSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, LoadingSpinner, SearchableDropdown } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { TEAM_DEFAULT_BUCKET_SEATS } from '@motion/ui-logic/billing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { useIndividualSubscription } from '~/global/rpc/subscriptions'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useEffect, useState } from 'react'

import { dispatch } from '../../../state/proxy'
import { getSubscription } from '../../../state/userSlice'

const TEAM_PERKS = [
  'Automatically prioritize what each team member should work on',
  'Ensure entire team never misses deadlines',
  'Tells you exactly when every task and project is expected to finish',
  'Shows progress and what each team member is working on',
  'Automatically resolve blockers',
  'Centralized billing',
]

type IndividualTeamInterceptionProps = {
  onSkip: () => void
  goToTeamCreate: () => void
  onCreateBucketTeam: (seats: number, isMonthly: boolean) => void
  individualTeamSavings: number
}

export const IndividualTeamInterception = ({
  onSkip,
  onCreateBucketTeam,
  individualTeamSavings,
}: IndividualTeamInterceptionProps) => {
  const subscription = useIndividualSubscription()
  const isAnnual = subscription?.plan?.interval === 'year'
  const [pageLoading, setPageLoading] = useState(true)

  const { data: teamPrices } = useGetTeamPrices()
  const modalApi = useModalApi()

  const hasDiscount = individualTeamSavings > 0
  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')

  useEffect(() => {
    async function refreshSubscription() {
      await dispatch(getSubscription())
      setPageLoading(false)
    }
    void refreshSubscription()
  }, [])

  const handleSwitchToTeam = () => {
    if (shouldUseNoCcTrial) {
      // We'll default the newly converted b2b trial to default of 5 seats
      const defaultBucketSeats = TEAM_DEFAULT_BUCKET_SEATS
      onCreateBucketTeam(defaultBucketSeats, !isAnnual)
      recordAnalyticsEvent('NEW_ONBOARDING_UPGRADE_INDIVIDUAL_TO_TEAM', {
        num_seats: defaultBucketSeats,
      })
      return
    }

    modalApi.open('manage-team-seats', {
      title: 'Pick your team size',
      isAnnual: isAnnual,
      isTrial: true,
      actionText: 'Switch to Team',
      cancelText: 'Go back',
      onSubmit: async (selectedSeats) => {
        await onCreateBucketTeam(selectedSeats, !isAnnual)
        recordAnalyticsEvent('NEW_ONBOARDING_UPGRADE_INDIVIDUAL_TO_TEAM', {
          num_seats: selectedSeats,
        })
      },
    })
  }

  if (pageLoading || !teamPrices) {
    return (
      <div className='flex flex-row items-center justify-center w-full h-full gap-3 text-semantic-neutral-text-default font-semibold'>
        <LoadingSpinner />
        Loading...
      </div>
    )
  }

  return (
    <div className='h-full w-full flex flex-row items-center justify-center'>
      <div className='bg-semantic-neutral-bg-default flex flex-col p-8 w-[509px] rounded-xl gap-6'>
        <h1 className='text-2xl font-semibold'>
          {templateStr(
            '🦸 Give superpowers to your team. Unlock team features.{{discountStr}}',
            {
              discountStr: hasDiscount
                ? ` Get a ${individualTeamSavings}% discount.`
                : '',
            }
          )}
        </h1>
        <Subtext>
          Assign tasks and collaborate on projects with teammates. All team
          members receive a free trial.
        </Subtext>
        <Subtext>
          {templateStr(
            'Each team member is ${{price}}/mo, billed {{interval}}{{discountStr}}',
            {
              price: isAnnual
                ? teamPrices.annualPricePerMonth
                : teamPrices.monthlyPrice,
              interval: isAnnual ? 'annually' : 'monthly',
              discountStr: hasDiscount
                ? ` (${individualTeamSavings}% cheaper than Individual plan).`
                : '.',
            }
          )}
        </Subtext>
        <div className='flex flex-col gap-2'>
          <Button
            onClick={handleSwitchToTeam}
            data-testid='onboarding-interception-switch'
          >
            Switch to Team
          </Button>
          <SearchableDropdown
            items={[
              {
                id: 'will_try_motion_with_team_in_future',
                label: `I'd like to use Motion with my team,
                  but not right now`,
              },
              {
                id: 'never_use_motion_with_team',
                label: 'I will never want to use Motion with my team',
              },
              {
                id: 'no_team',
                label: `I don't have a team`,
              },
            ]}
            placement='bottom'
            renderItem={(item) => item.label}
            onChange={({ id }) => {
              recordAnalyticsEvent('KYC_CREATE_TEAM_SKIP', {
                reason: id,
              })
              onSkip()
            }}
          >
            <Button fullWidth sentiment='neutral' variant='muted'>
              Continue as Individual
            </Button>
          </SearchableDropdown>
        </div>
      </div>
      <div className='p-8 w-[371px]'>
        <div className='flex flex-col gap-2'>
          {(hasDiscount
            ? [
                ...TEAM_PERKS,
                `${individualTeamSavings}% cheaper than individual plan`,
              ]
            : TEAM_PERKS
          ).map((perk, i) => (
            <div className='flex gap-2 items-start' key={i}>
              <div>
                <CheckSolid className='text-semantic-primary-icon-default w-[18px] h-[18px] ' />
              </div>
              <p className='text-sm text-semantic-neutral-text-default'>
                {perk}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Subtext = classed('p', 'text-semantic-neutral-text-subtle text-base')
