import { type CalendarSchema } from '@motion/zod/client'

import { createContext, type ReactNode, useContext, useMemo } from 'react'

type SearchContextValue = {
  activeCalendars: CalendarSchema[]
}

const defaultValue: SearchContextValue = {
  activeCalendars: [],
}

const SearchContext = createContext(defaultValue)

type SearchContextProviderProps = {
  children: ReactNode
  activeCalendars: CalendarSchema[]
}

export function SearchContextProvider({
  children,
  activeCalendars,
}: SearchContextProviderProps) {
  const value = useMemo<SearchContextValue>(
    () => ({ activeCalendars }),
    [activeCalendars]
  )

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export function useSearchContext() {
  return useContext(SearchContext)
}
