import { useClosure } from '@motion/react-core/hooks'
import { merge } from '@motion/utils/core'

import { useCallback, useMemo } from 'react'
import { type UIMatch, useMatches } from 'react-router'

type MatchedRouteData = {
  params: Record<string, string>
  data: Record<string, unknown>
  handle: Record<string, unknown>
}

export function useMatchedRouteData<
  T extends MatchedRouteData = MatchedRouteData,
>(): T {
  const matches = useMatches()

  return useMemo(() => {
    return buildRouteData(matches)
  }, [matches])
}

export function useMatchedRouteDataFn<
  T extends MatchedRouteData = MatchedRouteData,
>() {
  const getMatches = useMatchesFn()

  return useCallback((): T => {
    return buildRouteData(getMatches())
  }, [getMatches])
}

function buildRouteData<T extends MatchedRouteData = MatchedRouteData>(
  matches: UIMatch[]
) {
  return matches.reduce(
    (acc, cur) => {
      // @ts-expect-error - unknown
      merge(acc.params, cur.handle?.params, cur.params)
      merge(acc.data, cur.data)
      merge(acc.handle, cur.handle)
      return acc
    },
    {
      params: {},
      data: { filter: {}, state: new Map() },
      handle: {},
    } as unknown as T
  )
}

/**
 * Returns the route matches in a stable getter
 * This should only be used within other getters
 */
export const useMatchesFn = () => {
  const matches = useMatches()

  return useClosure(() => matches)
}
