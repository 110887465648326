import { useDependantState } from '@motion/react-core/hooks'
import { getWindowData } from '@motion/web-base/env'

import { useCustomFieldById } from '~/areas/custom-fields/hooks'
import {
  CustomField,
  type CustomFieldInputProps,
  type CustomNumberInputProps,
} from '~/areas/project-management/custom-fields'
import { ModalFieldButton } from '~/areas/task-project/components'
import { forwardRef } from 'react'

export const Number = forwardRef<
  HTMLLabelElement,
  CustomNumberInputProps & {
    customFieldId: string
  }
>(({ customFieldId, size, ...props }, ref) => {
  const { isMac } = getWindowData()

  const customField = useCustomFieldById(customFieldId, 'number')

  const { value, onChange: submitHandler, ...rest } = props

  const [internalValue, setInternalValue] = useDependantState(
    () => value,
    [value]
  )

  const onChange = (v: number | null) => {
    setInternalValue(v)
  }

  const onSubmit = () => {
    if (internalValue === value) {
      return
    }

    try {
      submitHandler(internalValue)
    } catch (error) {
      setInternalValue(value)
    }
  }

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key, metaKey, ctrlKey } = evt

    if (['Enter', 'Escape'].includes(key)) {
      evt.preventDefault()
      return onSubmit()
    }

    if (
      (isMac && metaKey && key === 's') ||
      (!isMac && ctrlKey && key === 's')
    ) {
      evt.preventDefault()
      onSubmit()
    }
  }

  if (customField == null) return null
  const { format } = customField.metadata

  return (
    <ModalFieldButton label={props.name} disabled={props.disabled} size={size}>
      <CustomField.Number
        ref={ref}
        value={internalValue}
        onChange={onChange}
        format={format}
        onBlur={onSubmit}
        onKeyDown={handleKeyDown}
        {...rest}
      />
    </ModalFieldButton>
  )
})

Number.displayName = 'TaskModalCustomFieldNumber'
