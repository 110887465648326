import {
  CustomField,
  SelectTriggerValues,
} from '~/areas/project-management/custom-fields'
import { ModalFieldButton } from '~/areas/task-project/components'

import { type ModalCustomField } from '../../types'

type Props = {
  value: string | null
  customField: Extract<ModalCustomField, { type: 'select' }>
  onChange: (id: string | null) => void
  disabled?: boolean
  size?: 'small' | 'xsmall'
}

export function Select({
  value,
  customField,
  onChange,
  disabled = false,
  size,
}: Props) {
  return (
    <CustomField.Select
      workspaceId={customField.workspaceId}
      customFieldId={customField.instanceId}
      selectedItemId={value}
      onChange={onChange}
      size={size}
    >
      <ModalFieldButton
        label={customField.name}
        disabled={disabled}
        size={size}
      >
        <SelectTriggerValues
          selectedItemIds={value != null ? [value] : []}
          customFieldId={customField.instanceId}
          type={customField.type}
          hideIcon
          disabled={disabled}
          size={size}
        />
      </ModalFieldButton>
    </CustomField.Select>
  )
}
