import { StatusLabel } from '~/global/components/labels'

import {
  BulkStatusDropdown,
  ConnectedBulkStatusLabel,
  FieldButton,
} from '../components'
import { useBulkOpsField } from '../contexts'

export function StatusField() {
  const [statusIds, setStatusIds] = useBulkOpsField('statusIds')
  const [isAutoScheduled] = useBulkOpsField('isAutoScheduled')

  const active = statusIds.length > 0

  return (
    <BulkStatusDropdown
      onChange={(ids) => {
        setStatusIds(ids)
      }}
      selectedStatusIds={statusIds}
      disableNonAutoschedulableStatus={isAutoScheduled}
    >
      <FieldButton
        active={active}
        analyticsName='status'
        onRemove={() => {
          setStatusIds([])
        }}
      >
        {active ? (
          <ConnectedBulkStatusLabel ids={statusIds} size='small' />
        ) : (
          <StatusLabel
            value={{
              name: 'Status',
              color: '#889096',
              type: null,
            }}
            size='small'
          />
        )}
      </FieldButton>
    </BulkStatusDropdown>
  )
}
