import { Sentry } from '@motion/web-base/sentry'

import { type GetEntitiesApi, useGetScheduledEntities } from '~/global/rpc'
import { useMemo } from 'react'

import { useScheduleAssistantCalendarContext } from './use-calendar-state'

/**
 * Grab all the event and its schedule entities for the user's my calendars events that are within the sync session.
 * @returns
 */
export function useSelectExistingScheduleEntities(
  opts?: GetEntitiesApi['UseQueryOptions']
) {
  const [{ syncSessionRange, ...contextState }] =
    useScheduleAssistantCalendarContext()

  const range = useMemo(() => {
    if (syncSessionRange == null) {
      Sentry.captureException('syncSessionRange is null', {
        extra: {
          syncSessionRange,
          contextState,
        },
      })

      return {
        start: null,
        end: null,
      }
    }

    return syncSessionRange
  }, [contextState, syncSessionRange])

  return useGetScheduledEntities(
    {
      include: ['event', 'task'],
      filters: {
        scheduled: {
          from: range.start?.toISO(),
          to: range.end?.toISO(),
        },
        myCalendarsOnly: true,
        completed: 'include',
      },
    },
    opts
  )
}
