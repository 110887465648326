import { type DateAdjustmentStrategy } from '@motion/shared/projects'
import { formatToReadableWeekDayMonth, templateStr } from '@motion/ui-logic'
import {
  getEnabledStagesWithDates,
  getPreviousEnabledStage,
} from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { Sentry } from '@motion/web-base/sentry'

import { useI18N } from '~/global/contexts'
import { useProject } from '~/global/hooks'
import { useState } from 'react'

import {
  type RadioOption,
  UpdateProjectDateModal,
} from './update-project-date-modal'

import { type ModalTriggerComponentProps } from '../../../modals/modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'update-project-date': PromptCallbacks<{
      dateAdjustmentStrategy: DateAdjustmentStrategy
    }> & {
      projectId: string
      newDate: string
      dateType: 'start' | 'due'
    }
  }
}

export function ConnectedUpdateProjectDateModal({
  close,
  projectId,
  newDate,
  dateType,
}: ModalTriggerComponentProps<'update-project-date'>) {
  const project = useProject(projectId)
  const [dateAdjustmentStrategy, setDateAdjustmentStrategy] =
    useState<DateAdjustmentStrategy>(
      dateType === 'start' ? 'SHIFT' : 'DISTRIBUTE'
    )
  const { pluralize } = useI18N()

  if (!project || !project.stages) {
    Sentry.captureException(new Error('Project or stages not found'), {
      tags: {
        position: 'update-project-date-modal',
        projectId,
      },
    })
    close()
    return null
  }

  const stagesWithDates = getEnabledStagesWithDates(project.stages, {
    start: project.startDate,
    due: project.dueDate,
  })

  // Project dates
  const projectStartDate = parseDate(project.startDate ?? '')
  const projectEndDate = parseDate(project.dueDate ?? '')
  const projectLength = Math.round(
    projectEndDate.diff(projectStartDate, 'days').days
  )
  // Stage dates
  const firstStageEndDate = stagesWithDates[0]
    ? parseDate(stagesWithDates[0].due)
    : projectEndDate
  const lastStage = stagesWithDates[stagesWithDates.length - 1]
  const previousStage = lastStage
    ? getPreviousEnabledStage(project, lastStage.stage.stageDefinitionId)
    : null
  const previousStageEndDate = previousStage
    ? parseDate(previousStage.dueDate)
    : null
  const lastStageLength = previousStageEndDate
    ? Math.round(projectEndDate.diff(previousStageEndDate, 'days').days)
    : 0

  // Date change calculations
  const parsedOriginalDate =
    dateType === 'start' ? projectStartDate : projectEndDate
  const parsedNewDate = parseDate(newDate)
  const deadlineDiff = parsedNewDate.diff(parsedOriginalDate, 'days')
  const deadlineDiffDays = Math.round(deadlineDiff.days)

  // Strategy flags
  const disableDistributeStrategy =
    dateType === 'start' && deadlineDiffDays > projectLength
  const disableAbsorbStrategy =
    (dateType === 'start' &&
      deadlineDiffDays >
        firstStageEndDate.diff(projectStartDate, 'days').days) ||
    (dateType === 'due' &&
      deadlineDiffDays < 0 &&
      Math.abs(deadlineDiffDays) > lastStageLength)

  // labels
  const daysChangedCopy = templateStr('{{sign}}{{days}} {{dayLabel}}', {
    sign: deadlineDiffDays > 0 ? '+' : '',
    days: deadlineDiffDays,
    dayLabel: pluralize(deadlineDiffDays, 'day', 'days'),
  })
  const originalDateLabel = formatToReadableWeekDayMonth(parsedOriginalDate)
  const newDateLabel = formatToReadableWeekDayMonth(parsedNewDate)
  const showShiftStrategy = dateType === 'start'
  const dateTypeLabel = dateType === 'start' ? 'start date' : 'deadline'
  const stageToBeUpdated = dateType === 'start' ? 'first' : 'last'

  // shift and distribute work in the opposite direction as the start date change, same for deadline.
  const absorbDistributeDays =
    dateType === 'start' ? deadlineDiffDays * -1 : deadlineDiffDays
  const absorbDistributeDaysCopy = templateStr(
    '{{sign}}{{days}} {{dayLabel}}',
    {
      sign: absorbDistributeDays > 0 ? '+' : '',
      days: absorbDistributeDays,
      dayLabel: pluralize(absorbDistributeDays, 'day', 'days'),
    }
  )
  const shiftStrategy = {
    radioTitle: 'Change start date and move project',
    radioSubtitle: templateStr(
      `Motion will move the project by {{daysChangedCopy}}. The project deadline will move from {{endDateLabel}} to {{newEndDateLabel}}.
        Motion will move all the stage deadlines and the project
        deadline by {{daysChangedCopy}}`,
      {
        endDateLabel: formatToReadableWeekDayMonth(projectEndDate),
        newEndDateLabel: formatToReadableWeekDayMonth(
          projectEndDate.plus({ days: deadlineDiffDays })
        ),
        daysChangedCopy,
      }
    ),
    value: 'SHIFT' as DateAdjustmentStrategy,
  }

  const distributeStrategy = {
    radioTitle: `Change ${dateTypeLabel} and resize stages proportionally`,
    radioSubtitle: `Motion will redistribute the difference (${absorbDistributeDaysCopy}) across all non-completed stages`,
    value: 'DISTRIBUTE' as DateAdjustmentStrategy,
    disabled: disableDistributeStrategy,
    tooltipContent: disableDistributeStrategy
      ? 'Resizing is not possible if the adjustment exceeds the duration of the project'
      : undefined,
  }

  const absorbStrategy = {
    radioTitle: `Change ${dateTypeLabel} and resize the ${stageToBeUpdated} stage`,
    radioSubtitle: `Motion will resize the ${stageToBeUpdated} stage by ${absorbDistributeDaysCopy}`,
    value: 'ABSORB' as DateAdjustmentStrategy,
    disabled: disableAbsorbStrategy,
    tooltipContent: disableAbsorbStrategy
      ? `Resizing is not possible if the adjustment exceeds the duration of the ${stageToBeUpdated} stage in the project`
      : undefined,
  }

  const radioOptions: RadioOption[] = showShiftStrategy
    ? [shiftStrategy, distributeStrategy, absorbStrategy]
    : [distributeStrategy, absorbStrategy]

  const description = templateStr(
    `You are changing the project {{dateTypeLabel}} from {{originalDateLabel}} to {{newDateLabel}} ({{daysChangedCopy}})`,
    {
      dateTypeLabel,
      originalDateLabel,
      newDateLabel,
      daysChangedCopy,
    }
  )

  return (
    <UpdateProjectDateModal
      dateAdjustmentStrategy={dateAdjustmentStrategy}
      radioOptions={radioOptions}
      onClose={close}
      setDateAdjustmentStrategy={setDateAdjustmentStrategy}
      description={description}
    />
  )
}
