import { DotsVerticalSolid } from '@motion/icons'
import {
  ContextMenuWrapper,
  type ContextMenuWrapperProps,
  IconButton,
  PopoverTrigger,
} from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

type ContextMenuWithButtonProps = Omit<
  ContextMenuWrapperProps,
  'onMenuOpen'
> & {
  onMenuOpen?: (trigger: 'contextMenu' | 'button') => void
}

/**
 * Requires a parent to define 'group/calendar-event' class for visibility toggling
 */
export const ContextMenuPopoverWithButton = ({
  onMenuOpen,
  renderContextMenu,
  children,
}: ContextMenuWithButtonProps) => {
  return (
    <>
      <ContextMenuWrapper
        renderContextMenu={renderContextMenu}
        onMenuOpen={() => {
          if (onMenuOpen) {
            onMenuOpen('contextMenu')
          }
        }}
      >
        {({ handleContextMenu }) => (
          <>
            {typeof children === 'function'
              ? children({ handleContextMenu })
              : children}

            <div
              className={twMerge(
                'invisible h-5',
                // 'visible',
                'group-hover/calendar-event:visible group-focus-within/calendar-event:visible',
                'absolute right-[-15px]'
              )}
            >
              <PopoverTrigger
                placement='bottom-start'
                renderPopover={({ close }) => (
                  <div className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden max-h-[400px]'>
                    {renderContextMenu(close)}
                  </div>
                )}
              >
                <IconButton
                  size='xsmall'
                  variant='outlined'
                  sentiment='neutral'
                  icon={DotsVerticalSolid}
                  onMouseDown={(e) => {
                    handleContextMenu(e)
                    if (onMenuOpen) {
                      onMenuOpen('button')
                    }
                  }}
                  // @ts-expect-error Required to style this button to designs
                  className='h-5 w-[15px]'
                />
              </PopoverTrigger>
            </div>
          </>
        )}
      </ContextMenuWrapper>
    </>
  )
}
