import { useHasTreatment } from '@motion/web-common/flags'

import { type FilterDefinition } from '~/areas/project-management/filters'
import {
  ConnectedETAFilter,
  ConnectedIsUnvisitedStageFilter,
} from '~/areas/project-management/filters/components/v2'
import { useMemo } from 'react'

import { useCustomFieldFilterDefinitions } from './custom-fields/use-custom-field-filter-definitions'
import { defaultProjectFilters, defaultTaskFilters } from './definitions'

export const useTaskFilterDefinitions = (): FilterDefinition<'tasks'>[] => {
  const customFieldFilters = useCustomFieldFilterDefinitions<'tasks'>()
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  return useMemo(() => {
    const filters = [...defaultTaskFilters, ...customFieldFilters].concat([
      {
        key: 'isUnvisitedStage',
        label: 'Unvisited Stage',
        renderDropdown: (props) => (
          <ConnectedIsUnvisitedStageFilter {...props} />
        ),
      },
    ])

    return !hasPastDueM2
      ? filters
      : filters.concat([
          {
            key: 'deadlineStatuses',
            label: 'ETA',
            renderDropdown: (props) => <ConnectedETAFilter {...props} />,
          },
        ])
  }, [customFieldFilters, hasPastDueM2])
}

export const useProjectFilterDefinitions =
  (): FilterDefinition<'projects'>[] => {
    const customFieldFilters = useCustomFieldFilterDefinitions<'projects'>()
    const hasPastDueM2 = useHasTreatment('past-due-m2')

    return useMemo(() => {
      const filters = [...defaultProjectFilters, ...customFieldFilters]
      return !hasPastDueM2
        ? filters
        : filters.concat([
            {
              key: 'deadlineStatuses',
              label: 'ETA',
              renderDropdown: (props) => <ConnectedETAFilter {...props} />,
            },
          ])
    }, [customFieldFilters, hasPastDueM2])
  }
