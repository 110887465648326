import { type PMTaskType } from '@motion/rpc/types'
import {
  type CreateTemplateProjectDto,
  type UpdateTemplateProjectDto,
} from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useReadProjectFn } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { TemplateProjectFormHandlersContext } from './hooks/use-project-form-handlers'
import { useTemplateProjectFormOptions } from './template-project-form-options'
import {
  type TemplateProjectFormActions,
  type TemplateProjectFormFields,
} from './types'
import { convertProjectForm } from './utils'

import {
  useCreateTemplateProject,
  useUpdateTemplateProject,
} from '../../../global/rpc'
import { useUploadWorkspaceImage } from '../../hooks/use-upload-workspace-image'
import { convertTaskToCreateTaskForTemplate } from '../template-task-modal/utils'

export type TemplateProjectFormHandlersProps = {
  children: React.ReactNode
}

export function TemplateProjectFormHandlers({
  children,
}: TemplateProjectFormHandlersProps) {
  const value = useHandlersValue()

  return (
    <TemplateProjectFormHandlersContext.Provider value={value}>
      {children}
    </TemplateProjectFormHandlersContext.Provider>
  )
}

function useHandlersValue() {
  const { templateProjectId } = useParams<{ templateProjectId: string }>()
  const [searchParams] = useSearchParams()
  const isEditingProject =
    !!searchParams.get('project') && searchParams.get('project') !== 'new'

  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const readProject = useReadProjectFn()

  const updateProject = useProjectUpdater()
  const { mutateAsync: createTemplateProject } = useCreateTemplateProject()
  const { mutateAsync: updateTemplateProject } = useUpdateTemplateProject()

  const modalApi = useModalApi()

  const options = useTemplateProjectFormOptions()
  const form = useFormContext<TemplateProjectFormFields>()

  const { dirtyFields } = form.formState
  const { savedProject, tasks } = options

  const projectId = form.watch('id')
  const workspaceId = form.watch('workspaceId')

  const uploadWorkspaceImage = useUploadWorkspaceImage(workspaceId, {
    projectId,
  })

  return useMemo<TemplateProjectFormActions>(() => {
    const closeModal: TemplateProjectFormActions['closeModal'] = async () => {
      // Closing the edit template modal
      if (templateProjectId != null) {
        navigate(
          generatePath(
            `/web/settings/workspace/:workspaceId/project-templates`,
            {
              workspaceId: workspaceId,
            }
          )
        )
        return
      }

      // Closing the saving a project as template
      setSearchParams((prev) => {
        prev.delete('template')
        return prev
      })
      return
    }

    return {
      /**
       * Close the modal
       */
      closeModal,

      async openLinkModalPrompt(initialLink = '') {
        const response = await modalApi.prompt('link-modal', {
          initialLink,
        })

        if (response !== ModalDismissed) {
          return response
        }
        return null
      },

      /**
       * Upload an image
       * @param file File object to upload
       * @returns string url of the file
       */
      uploadImage(file) {
        return uploadWorkspaceImage(file)
      },

      async submitTemplate(data) {
        try {
          let projectForTemplate: ProjectSchema | undefined

          // If we're trying to save a template from a project, we might need to update the project first
          if (isEditingProject && savedProject && projectId) {
            const { templateName, ...restDirty } = dirtyFields
            const hasOtherDirtyFields = Object.keys(restDirty).length > 0

            if (hasOtherDirtyFields) {
              const dataProject = convertProjectForm(data)

              const project = await readProject(projectId)
              if (project == null) {
                throw new Error('Project not found')
              }

              const { models } = await updateProject(project, {
                ...dataProject,
              })

              projectForTemplate = models.projects[projectId]
            }
          }

          if (data.templateId) {
            const response = await updateTemplateProject(
              convertToUpdateTemplateProject(data)
            )

            showToast('success', 'Template saved')
            return response
          }

          const response = await createTemplateProject(
            convertToCreateTemplateProject(data, projectForTemplate, tasks)
          )

          showToast('success', 'Template created')
          return response
        } catch (e) {
          showErrorToast(e)
          throw e
        }
      },
    }
  }, [
    createTemplateProject,
    dirtyFields,
    isEditingProject,
    modalApi,
    navigate,
    projectId,
    readProject,
    savedProject,
    setSearchParams,
    tasks,
    templateProjectId,
    updateProject,
    updateTemplateProject,
    uploadWorkspaceImage,
    workspaceId,
  ])
}

function convertToCreateTemplateProject(
  fields: TemplateProjectFormFields,
  project?: ProjectSchema,
  tasks: PMTaskType[] = []
): CreateTemplateProjectDto {
  const finalProject = project ?? convertProjectForm(fields)

  return {
    name: fields.templateName ?? '',
    workspaceId: fields.workspaceId,
    project: {
      name: finalProject.name,
      description: finalProject.description ?? undefined,
      workspaceId: finalProject.workspaceId,
      managerId: finalProject.managerId ?? undefined,
      statusId: finalProject.statusId ?? undefined,
      priorityLevel: finalProject.priorityLevel,
      labels: finalProject.labelIds?.map((labelId) => ({ labelId })) ?? [],
      tasks: tasks.map(convertTaskToCreateTaskForTemplate),
    },
  }
}

function convertToUpdateTemplateProject(
  fields: TemplateProjectFormFields
): UpdateTemplateProjectDto {
  const result = convertToCreateTemplateProject(fields)

  return {
    ...result,
    id: fields.templateId ?? '',
    project: {
      ...result.project,
      id: fields.id ?? '',
    },
  }
}
