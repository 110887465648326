import { type FilterTarget } from '@motion/ui-logic/pm/data'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { byProperty, Compare } from '@motion/utils/array'
import { entries } from '@motion/utils/object'
import { useHasTreatment } from '@motion/web-common/flags'

import { useWorkspaceFns } from '~/global/hooks'

import {
  type GroupableField,
  ProjectGroupableFields,
  TaskGroupableFields,
} from '../../grouping'
import {
  isGroupBySupportedForField,
  useAvailableCustomFieldsGroupedByTypedName,
} from '../../grouping/custom-fields'
import { usePageData } from '../../routes/hooks'

export const useAvailableGroupByFields = (): ((
  type: FilterTarget
) => GroupableField[]) => {
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const pageData = usePageData()
  const availableCustomFieldNamesByTypedName =
    useAvailableCustomFieldsGroupedByTypedName()
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  return (type) => {
    const availableCustomFieldEntries = entries(
      availableCustomFieldNamesByTypedName
    )

    const customFieldGroupableFields = availableCustomFieldEntries
      .map(([key, customField]) =>
        isGroupBySupportedForField(customField[0].type)
          ? {
              id: key,
              label: customField[0].name,
            }
          : null
      )
      .filter(Boolean)
      .sort(byProperty('label', Compare.string))

    const targetTypeGroupableFields: GroupableField[] =
      type === 'projects'
        ? ProjectGroupableFields
        : TaskGroupableFields.filter((f) => {
            if (f.id === 'stage') {
              if (pageData.lock.projectId != null) {
                return isFlowProject(
                  getWorkspaceProjectById(pageData.lock.projectId)
                )
              }
            }
            return true
          })

    return [...targetTypeGroupableFields, ...customFieldGroupableFields]
      .filter(pageData.predicates.groups)
      .filter((field) => {
        if (!hasPastDueM2 && field.id === 'deadlineStatus') return false

        return true
      })
  }
}
