import {
  type BookingConferenceType,
  type EventConferenceType,
} from '@motion/shared/common'

import { customAlphabet } from 'nanoid'

import {
  type BookingLinkTemplate,
  type BookingQuestionType,
} from '../state/booking'
import {
  type AvailabilityQuestionType,
  type FirebaseQuestionType,
} from '../types/bookingTypes'

export const linkIdGenerator = customAlphabet(
  '1234567890bcdfghjklmnpqrstvwxyz',
  7
)

export const generateRandomPermanentLinkId = () => {
  return linkIdGenerator().toLowerCase()
}

/**
 * @deprecated Remove after booking refactor is done since link id's will become
 * random uuid's
 *
 * Gets the firebase template link id from the user/team id and link slug
 */
export const getFirebaseTemplateLinkId = (
  userOrTeamId: string,
  linkSlug: string
) => {
  return `${userOrTeamId}--${linkSlug}`
}

export const questionTypeToFirebase = (
  questionType: BookingQuestionType
): AvailabilityQuestionType['type'] => {
  switch (questionType) {
    case 'MULTIPLE_CHOICE':
      return 'multiple-choice'
    case 'TEXT_ONE_LINE':
      return 'text-single'
    default:
      return 'text-multiple'
  }
}

export const sortBookingLinkTemplates = (
  templates: Record<string, BookingLinkTemplate>
) => {
  return Object.keys(templates)
    .map((key) => ({
      ...templates[key],
    }))
    .sort((a, b) => {
      if (a.order === null) {
        return 1
      } else if (b.order === null) {
        return -1
      }
      return a.order - b.order
    })
}

export const conferenceTypeToFirebase = (
  conferenceType: BookingConferenceType
): EventConferenceType => {
  switch (conferenceType) {
    case 'NONE':
      return 'none'
    case 'ZOOM':
      return 'zoom'
    case 'GOOGLE_MEET':
      return 'meet'
    case 'MICROSOFT_TEAMS':
      return 'teamsForBusiness'
    case 'PHONE':
      return 'phone'
    case 'CUSTOM_LOCATION':
      return 'customLocation'
    default:
      return 'none'
  }
}

export const conferenceTypeToGeneric = (
  conferenceType: string
): BookingConferenceType => {
  switch (conferenceType) {
    case 'none':
      return 'NONE'
    case 'zoom':
      return 'ZOOM'
    case 'meet':
      return 'GOOGLE_MEET'
    case 'teamsForBusiness':
      return 'MICROSOFT_TEAMS'
    case 'phone':
      return 'PHONE'
    case 'customLocation':
      return 'CUSTOM_LOCATION'
    default:
      return 'NONE'
  }
}

export const genericToConferenceType = (
  conferenceType: BookingConferenceType
): string => {
  switch (conferenceType) {
    case 'NONE':
      return 'none'
    case 'ZOOM':
      return 'zoom'
    case 'GOOGLE_MEET':
      return 'meet'
    case 'MICROSOFT_TEAMS':
      return 'teamsForBusiness'
    case 'PHONE':
      return 'phone'
    case 'CUSTOM_LOCATION':
      return 'customLocation'
    default:
      return 'none'
  }
}

export const questionTypeToGeneric = (
  questionType: FirebaseQuestionType
): BookingQuestionType => {
  switch (questionType) {
    case 'multiple-choice':
      return 'MULTIPLE_CHOICE'
    case 'text-single':
      return 'TEXT_ONE_LINE'
    case 'text-multiple':
      return 'TEXT_MULTIPLE_LINES'
  }
}

/**
 * Returns the full url given a urlPrefix and linkSlug.
 */
export const createFullTemplateUrl = (urlPrefix: string, linkSlug: string) => {
  return `${__FRONTEND_HOST__}/meet/${urlPrefix}/${linkSlug}`
}

/**
 * Generates a URL for a template child based on the provided link slug.
 *
 * @param {string} linkSlug - The link slug to use in the URL.
 * @return {string} The URL for the template child.
 */
export const createTemplateChildUrl = (linkSlug: string) => {
  return `${__FRONTEND_HOST__}/meet/${linkSlug}`
}
