import {
  type Calendar,
  type CreateCalendarDto,
  type UpdateCalendarDto,
} from '@motion/rpc/types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { BackendApiService } from './BackendApiService'

import { Events as AnalyticsEvents } from '../analyticsEvents'
import { isCockroachEmailAccountId } from '../utils/calendarUtils'

export class CalendarService extends BackendApiService {
  static id = 'CalendarService' as const

  /**
   * Resolves the URL prefix for calendar-related CRUD ops. We want to use the
   * v2 endpoints if the following is true:
   * - calendar being mutated is a cockroach email account
   *
   * Firebase calendars should continue to use the 'old' endpoints. The new
   * endpoints are designed to work ONLY with cockroach calendars (see
   * `updateMultiple` where we split the changes based on source type).
   *
   * The FF check can be removed once iCloud is fully rolled out. The v2
   * endpoint should become the default controller once Kill Firestore -
   * Calendar is complete and the calendars migrated to Cockroach.
   *
   * @param emailAccountId
   * @returns
   */
  private getUrlPrefix(emailAccountId: string) {
    return isCockroachEmailAccountId(emailAccountId) ? 'v2/' : ''
  }

  async create(data: CreateCalendarDto): Promise<Calendar> {
    const res = await this.request(
      `${this.getUrlPrefix(data.emailAccountId)}calendars`,
      'POST',
      data
    )
    return res.calendar
  }

  async update(data: UpdateCalendarDto): Promise<Calendar> {
    const res = await this.request(
      `${this.getUrlPrefix(data.emailAccountId)}calendars/${encodeURIComponent(
        data.id
      )}`,
      'PATCH',
      data
    )
    return res.calendar
  }

  async detachCalendars(): Promise<{ success?: boolean; error?: string }> {
    try {
      await this.request('detach_calendars', 'POST', {})
      recordAnalyticsEvent(AnalyticsEvents.CALENDARS_DETACH)
      return { success: true }
    } catch (e: any) {
      Sentry.captureException(e, {
        tags: { position: 'settings/detachCalendars' },
      })
      return {
        error: `Fetch failed: ${e.message}, action settings/detachCalendars`,
      }
    }
  }

  /**
   * WARNING: Use sparingly!
   * Endpoint for the frontend to trigger the update function. Should be used sparingly.
   * It's to be used when the calendar list is updated (accounts added/removed),
   * but ideally we should be adding calendar_list CRUD endpoints which would trigger the update function on change
   * TODO: Remove when calendar_list CRUD endpoints are implemented
   * @deprecated you should be calling a backend endpoint that automatically
   * triggers the update function for you
   * @returns
   */
  async dangerouslyTriggerUpdateFunction() {
    return await this.request('update_function', 'POST', {})
  }
}

export const instance = new CalendarService()
