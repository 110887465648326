import { TaskSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { formatDurationToShort } from '@motion/ui-logic'

import { IconLabel } from './icon-label'
import { TaskRow } from './task-row'

import { type TaskSchema } from '../../types'
import { totalTimes } from '../utils'

export type CellProps = {
  gridRow: number
  gridColumn: number
  tasks: TaskSchema[]
  viewBy: 'dueDate' | 'scheduledStart'
  showTotals: boolean
}

export const Cell = (props: CellProps) => {
  const times = totalTimes(props.tasks)

  return (
    <Container style={{ gridRow: props.gridRow, gridColumn: props.gridColumn }}>
      {props.showTotals && (
        <div className='flex gap-2 px-2 text-semantic-neutral-text-subtle'>
          <IconLabel
            Icon={TaskSolid}
            text={formatDurationToShort(times.tasks)}
          />
        </div>
      )}
      <div className='flex flex-col gap-1 overflow-y-auto px-2'>
        {props.tasks.map((task) => (
          <TaskRow key={task.id} task={task} viewBy={props.viewBy} />
        ))}
      </div>
    </Container>
  )
}

const Container = classed('div', {
  base: `
    flex flex-col gap-2
    py-2
    h-[230px]
  `,
})
