import { CalendarSolid } from '@motion/icons'
import { FieldButton, type FieldButtonProps, Tooltip } from '@motion/ui/base'

export type FormDeadlineDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
>

export const FormDeadlineDropdown = ({
  ...buttonProps
}: FormDeadlineDropdownProps) => {
  return (
    <FieldButton {...buttonProps} disabled>
      <CalendarSolid />
      <Tooltip content='Deadline is set when using the template.'>TBD</Tooltip>
    </FieldButton>
  )
}
