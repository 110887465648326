import { useHasTreatment } from '@motion/web-common/flags'

import { useFolders } from '~/global/rpc/folders'

export const useHasWorkspaceFoldersTreatment = () => {
  const hasWorkspaceFolders = useHasTreatment('workspace-folders')
  const { data } = useFolders(undefined, {
    enabled: hasWorkspaceFolders,
  })

  return (
    hasWorkspaceFolders &&
    data &&
    data.models.systemFolders.workspaces != null &&
    data.models.systemFolders.userFavorites != null
  )
}

export const useHasPinnedViewsTreatment = () => {
  const hasWorkspaceFolders = useHasWorkspaceFoldersTreatment()
  const hasPinnedViews = useHasTreatment('pinned-views')

  return hasWorkspaceFolders && hasPinnedViews
}
