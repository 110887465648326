import { API, createFetch } from '@motion/rpc/fetch'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { type TasksV2QueryResponseSchema } from '@motion/zod/client'

import {
  applyOptimisticTaskDelete,
  getScheduledEntityQueryFilters,
} from '~/global/cache'

import { type HandlerContext } from './types'
import { buildQueryContext, type MutationIds } from './utils'

import { log } from '../log'

const TASK_QUERY_KEY = API.tasksV2.queryKeys.query
const TASK_BY_ID_KEY = API.tasksV2.queryKeys.byIdRoot
const PAST_DUE_KEY = API.tasksV2.queryKeys.pastDue()
const SCHEDULED_ENTITIES_QUERY_KEY = API.scheduledEntities.queryKeys.root

const KEYS_TO_UPDATE = [
  MODEL_CACHE_KEY,
  TASK_QUERY_KEY,
  TASK_BY_ID_KEY,
  SCHEDULED_ENTITIES_QUERY_KEY,
]
const KEYS_TO_REFETCH_IF_MUTATED = [PAST_DUE_KEY]

export async function refreshTasks(
  ctx: HandlerContext,
  mutations: MutationIds
) {
  log.info('refresh-tasks', mutations)

  const getTasks = createFetch<typeof API.tasksV2.queryTasks>(
    API.tasksV2.queryTasks,
    buildQueryContext(ctx)
  )
  const idsToFetch = [...mutations.update, ...mutations.create]

  const response =
    idsToFetch.length === 0
      ? null
      : await log.time('get tasks', () =>
          getTasks({
            $version: 2,
            filters: [
              {
                ids: idsToFetch,
                completed: 'include',
                archived: 'include',
                recurring: 'CURRENT',
                type: ['CHUNK', 'NORMAL', 'RECURRING_INSTANCE'],
              },
            ],
            include: API.tasksV2.taskAllIncludes,
          })
        )

  if (mutations.delete.length > 0) {
    log.time('remove.all', () => {
      mutations.delete.forEach((idToDelete) => {
        applyOptimisticTaskDelete(ctx.client, idToDelete)
      })
    })
  }

  if (response != null) {
    // Remove all scheduled entities for tasks not assigned to the main user
    const entitiesToRemove = mutations.update.filter(
      (id) => response.models.tasks?.[id]?.assigneeUserId !== ctx.userId
    )
    if (entitiesToRemove.length > 0) {
      MotionCache.delete(
        ctx.client,
        getScheduledEntityQueryFilters(),
        'scheduledEntities',
        entitiesToRemove
      )
    }

    MotionCache.upsert(ctx.client, createQueryFilter(KEYS_TO_UPDATE), response)
  }

  log.time('check if refetch keys', () => {
    const mutationIds = [
      ...mutations.update,
      ...mutations.create,
      ...mutations.delete,
    ]

    const queriesToRefetch =
      ctx.client.getQueriesData<TasksV2QueryResponseSchema>(
        createQueryFilter(KEYS_TO_REFETCH_IF_MUTATED)
      )

    queriesToRefetch.forEach(([key, data]) => {
      const shouldInvalidate = data?.ids.some((id) => mutationIds.includes(id))

      if (shouldInvalidate) {
        ctx.client.invalidateQueries({
          queryKey: key,
        })
      }
    })
  })
}
