import {
  ChevronDoubleLeftSolid,
  ChevronDoubleRightSolid,
  ChevronLeftSolid,
  ChevronRightSolid,
} from '@motion/icons'
import { Button, ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'
import { parseDate } from '@motion/utils/dates'

import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { DateTime } from 'luxon'

import { ConnectedWeekendsToggle } from './weekends-toggle'

import { useCalendarStartDay } from '../../../../../../calendar/hooks'
import { TeamScheduleActiveViewOptionsKey, useViewOptions } from '../../context'

type DateSelectorProps = {
  value: DateTime
  calendarStartDay?: CalendarStartDay
  onChange(value: DateTime): void
  hideWeekends?: boolean
}

export const DateSelector = (props: DateSelectorProps) => {
  const today = DateTime.now().startOf('day')

  function moveSelection(days: number) {
    let targetDay = props.value.plus({ days })
    if (!props.hideWeekends) {
      return props.onChange(targetDay)
    }

    while (targetDay.weekday > 5) {
      targetDay = targetDay.plus({ days })
    }
    return props.onChange(targetDay)
  }

  function setSelection(date: DateTime) {
    props.onChange(date)
  }

  function filterDate(date: DateTime) {
    if (!props.hideWeekends) return false
    return date.weekday > 5
  }

  return (
    <div className='flex gap-2'>
      <ButtonGroup segmented size='small'>
        <Tooltip content='Back 1 week' asChild>
          <IconButton
            sentiment='neutral'
            variant='outlined'
            size='small'
            icon={ChevronDoubleLeftSolid}
            onClick={() => moveSelection(-7)}
          />
        </Tooltip>

        <Tooltip content='Back 1 day' asChild>
          <IconButton
            sentiment='neutral'
            variant='outlined'
            size='small'
            icon={ChevronLeftSolid}
            onClick={() => moveSelection(-1)}
          />
        </Tooltip>

        <SimpleDateDropdown
          value={props.value.toISO()}
          calendarStartDay={props.calendarStartDay}
          onChange={(x) => props.onChange(parseDate(x ?? today))}
          disabledDate={filterDate}
        >
          <Button
            sentiment='neutral'
            variant='outlined'
            size='small'
            content='hello'
          >
            {props.value.toFormat('LLL dd')}
          </Button>
        </SimpleDateDropdown>

        <Tooltip content='Forward 1 day' asChild>
          <IconButton
            sentiment='neutral'
            variant='outlined'
            size='small'
            icon={ChevronRightSolid}
            onClick={() => moveSelection(1)}
          />
        </Tooltip>
        <Tooltip content='Forward 1 week' asChild>
          <IconButton
            sentiment='neutral'
            variant='outlined'
            size='small'
            icon={ChevronDoubleRightSolid}
            onClick={() => moveSelection(7)}
          />
        </Tooltip>
      </ButtonGroup>
      <Button
        sentiment='neutral'
        variant='outlined'
        size='small'
        onClick={() => setSelection(DateTime.now().startOf('day'))}
      >
        Today
      </Button>
    </div>
  )
}

const Separator = () => {
  return <div className='w-[1px] bg-semantic-neutral-border-default' />
}

export const ConnectedDateSelector = () => {
  const [viewOptions, setViewOptions] = useViewOptions(
    TeamScheduleActiveViewOptionsKey
  )
  const calendarStartDay = useCalendarStartDay()

  return (
    <div className='flex gap-2'>
      <DateSelector
        value={parseDate(viewOptions.column.from)}
        calendarStartDay={calendarStartDay}
        hideWeekends={viewOptions.hideWeekends}
        onChange={(value) =>
          setViewOptions((prev) => ({
            ...prev,
            column: {
              ...prev.column,
              from: value.toISO(),
            },
          }))
        }
      />
      <Separator />
      <ConnectedWeekendsToggle />
    </div>
  )
}
