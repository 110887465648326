import { AlertPositionContainer } from '~/components/Alerts/AlertsContext/AlertContainer'
import { alertList } from '~/components/Alerts/AlertsContext/AlertManager'
import { useState } from 'react'

import { BackLabel } from './common-labels'
import { type DevToolsPages, type ItemsHookReturn } from './types'

export const useAlertsTriggerItems = (
  setCurrentPage: (page: DevToolsPages) => void
): ItemsHookReturn => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const Comp = selectedIndex != null ? alertList[selectedIndex].component : null

  return [
    [
      {
        onSelect: () => {
          setCurrentPage('main')
        },
        label: <BackLabel />,
        key: 'Back',
        clearSearch: true,
      },
      {
        onSelect: () => {
          setSelectedIndex(null)
        },
        label: 'Close all',
        key: 'Close all',
      },
      ...alertList.map((alert, index) => ({
        onSelect: () => {
          setSelectedIndex(index)
        },
        label: alert.id,
        key: alert.id,
      })),
    ],
    Comp && (
      <div
        key='alert-preview'
        className='fixed bottom-0 right-0 pointer-events-none'
      >
        <AlertPositionContainer className='pointer-events-auto'>
          <Comp
            open
            setOpen={(value: boolean) => {
              if (!value) {
                setSelectedIndex(null)
              }
            }}
          />
        </AlertPositionContainer>
      </div>
    ),
  ]
}
