import {
  SuggestionDropdown,
  type SuggestionDropdownProps,
} from '~/areas/flows/components'

import { ConnectedFlowVariableSearchableList } from './connected-flow-variable-searchable-list'

export const ConnectedSuggestionDropdown = (
  props: Omit<SuggestionDropdownProps, 'renderList'>
) => {
  return (
    <SuggestionDropdown
      {...props}
      renderList={({ items, onChange }) => (
        <ConnectedFlowVariableSearchableList
          items={items}
          onChange={onChange}
        />
      )}
    />
  )
}
