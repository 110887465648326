import { classed } from '@motion/theme'
import { PopoverTrigger } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { type DropdownProps } from './types'

export type FilterDropdownProps = {
  children: ReactNode
  renderDropdown(props: DropdownProps): ReactNode

  onBlur?: (() => void) | undefined
  openOnMount?: boolean | undefined

  isInverted?: boolean
}

export const FilterDropdownButton = (props: FilterDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      onClose={props.onBlur}
      openOnMount={props.openOnMount}
      renderPopover={props.renderDropdown}
    >
      <FilterValueButton isInverted={props.isInverted}>
        {props.children}
      </FilterValueButton>
    </PopoverTrigger>
  )
}

export const FilterValueButton = classed('button', {
  base: `
    flex items-center
    hover:bg-filter-button-bg-hover
    last:rounded-r
    max-w-[250px]
    truncate

    px-[6px] py-[2px]
  `,
  variants: {
    isInverted: {
      true: `text-semantic-error-text-default`,
      false: '',
    },
  },
  defaultVariants: {
    isInverted: false,
  },
})
