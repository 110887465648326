import { type UploadedFileSchema } from '@motion/rpc-types'
import { useHasTreatment } from '@motion/web-common/flags'

import { useProjectDefinitionForTask } from '~/areas/flows'
import { useProject, useProjectDefinition } from '~/global/hooks'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useMemo } from 'react'

// get attachments for a project or task, looking up the definition attachments as well
export function useGetProjectTaskAttachments({
  targetId,
  targetType,
}: {
  targetId: string
  targetType: 'PROJECT' | 'TEAM_TASK'
}) {
  const project = useProject(targetId)
  const { data: task } = useTaskByIdV2(
    { id: targetId },
    { enabled: targetType === 'TEAM_TASK', staleTime: Infinity }
  )
  const getProjectDefinitionForTask = useProjectDefinitionForTask()

  const taskProjectDefinition =
    task && 'taskDefinitionId' in task
      ? getProjectDefinitionForTask(task)
      : null
  const projectDefinition = useProjectDefinition(project?.projectDefinitionId)
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')

  return useMemo(() => {
    if (!hasFlowsAttachments) {
      return []
    }

    if (
      targetType === 'TEAM_TASK' &&
      task &&
      (task.type === 'NORMAL' || task.type === 'RECURRING_INSTANCE')
    ) {
      const taskUploadedFileIds = (task.uploadedFileIds ?? []).map((fileId) =>
        getAttachmentInfo(fileId, {
          id: task.id,
          targetType: 'TEAM_TASK',
          workspaceId: task.workspaceId,
        })
      )
      const hasValidTaskDefinition =
        'taskDefinitionId' in task &&
        task.taskDefinitionId != null &&
        taskProjectDefinition

      if (hasValidTaskDefinition) {
        const taskDefinition = taskProjectDefinition.stages
          .flatMap((stage) => stage.tasks)
          .find((t) => t.id === task.taskDefinitionId)
        const parentAttachmentIds =
          taskDefinition?.uploadedFileIds?.map((fileId) =>
            getAttachmentInfo(fileId, {
              id: taskDefinition.id,
              targetType: 'TASK_DEFINITION',
              workspaceId: task.workspaceId,
            })
          ) ?? []
        return [...taskUploadedFileIds, ...parentAttachmentIds]
      }

      return taskUploadedFileIds
    }

    if (targetType === 'PROJECT' && project) {
      const projectUploadedFiles = project.uploadedFileIds.map((fileId) =>
        getAttachmentInfo(fileId, {
          id: project.id,
          targetType: 'PROJECT',
          workspaceId: project.workspaceId,
        })
      )
      if (projectDefinition) {
        const projectDefinitionAttachmentIds =
          projectDefinition.uploadedFileIds?.map((fileId) =>
            getAttachmentInfo(fileId, {
              id: projectDefinition.id,
              targetType: 'PROJECT_DEFINITION',
              workspaceId: projectDefinition.workspaceId,
            })
          ) ?? []
        return [...projectUploadedFiles, ...projectDefinitionAttachmentIds]
      }
      return projectUploadedFiles
    }

    return []
  }, [
    hasFlowsAttachments,
    targetType,
    task,
    project,
    taskProjectDefinition,
    projectDefinition,
  ])
}

function getAttachmentInfo(
  fileId: string,
  parent: {
    id: string
    targetType: UploadedFileSchema['targetType']
    workspaceId: string
  }
) {
  return {
    id: fileId,
    targetType: parent.targetType,
    targetId: parent.id,
    workspaceId: parent.workspaceId,
  }
}
