import { type EmailAccount } from '@motion/rpc/types'
import { sortEmailAccounts } from '@motion/ui-logic'

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityState,
  type PayloadAction,
} from '@reduxjs/toolkit'

import {
  deleteEmailAccount,
  fetchAllEmailAccounts,
} from './email-accounts-thunks'
import { type EmailAccountAddedModalProps } from './email-accounts-types'

import { type LoadingState } from '../projectManagementSlice'
import { type RootState } from '../store'

export const emailAccountsAdapter = createEntityAdapter<EmailAccount>({
  selectId: (c) => c.id,
})

export type EmailAccountsState = {
  emailAccounts: EntityState<EmailAccount>
  emailAccountsLoadingState: LoadingState
  /**
   * The emailAccountId of the main email
   */
  mainEmailAccountId?: string
  /**
   * Controls visibility of the "Email Account Added" modal. This is usually
   * toggled when an `emailAccount.created` websocket event is received
   */
  emailAccountAddedModal: EmailAccountAddedModalProps
}

export const initialEmailAccountsState: EmailAccountsState = {
  emailAccounts: emailAccountsAdapter.getInitialState(),
  emailAccountsLoadingState: 'preload',
  emailAccountAddedModal: {
    visible: false,
  },
}

export const emailAccountsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchAllEmailAccounts.fulfilled, (state, action) => {
      emailAccountsAdapter.removeAll(state.emailAccounts)
      emailAccountsAdapter.upsertMany(
        state.emailAccounts,
        action.payload.emailAccounts
      )
      state.mainEmailAccountId = action.payload.mainEmailAccountId
      state.emailAccountsLoadingState = 'loaded'
    })

    builder.addCase(fetchAllEmailAccounts.pending, (state) => {
      state.emailAccountsLoadingState = 'loading'
    })

    builder.addCase(fetchAllEmailAccounts.rejected, (state) => {
      state.emailAccountsLoadingState = 'error'
    })

    builder.addCase(deleteEmailAccount.fulfilled, (state, action) => {
      emailAccountsAdapter.removeOne(state.emailAccounts, action.meta.arg)
    })
  },
  initialState: initialEmailAccountsState,
  name: 'emailAccounts',
  reducers: {
    reset: () => initialEmailAccountsState,
    setEmailAccountAddedModal: (
      state: EmailAccountsState,
      action: PayloadAction<EmailAccountAddedModalProps>
    ) => {
      state.emailAccountAddedModal = action.payload
    },
  },
})

export const { reset, setEmailAccountAddedModal } = emailAccountsSlice.actions

export const selectCalendarsLoadingState = (state: RootState) =>
  state.emailAccounts.emailAccountsLoadingState

export const emailAccountsSelectors = emailAccountsAdapter.getSelectors(
  (state: RootState) => state.emailAccounts.emailAccounts
)

export const selectMainEmailAccountId = (state: RootState) =>
  state.emailAccounts.mainEmailAccountId

export const selectEmailAccounts = createSelector(
  [emailAccountsSelectors.selectAll, selectMainEmailAccountId],
  (emailAccounts, mainEmailAccountId) => {
    return sortEmailAccounts(
      emailAccounts,
      mainEmailAccountId
    ) as EmailAccount[]
  }
)

export const selectEmailAccountsMap = createSelector(
  [selectEmailAccounts],
  (emailAccounts) =>
    new Map<string, EmailAccount>(emailAccounts.map((e) => [e.id, e]))
)

export const selectEmailAccountById = createSelector(
  [(state: RootState) => state, (_, id: string) => id],
  (state, id) => {
    return emailAccountsSelectors.selectById(state, id)
  }
)

export const selectMainEmailAccount = createSelector(
  [selectEmailAccounts, selectMainEmailAccountId],
  (emailAccounts, mainEmailAccountId) => {
    return emailAccounts.find((e) => e.id === mainEmailAccountId)
  }
)

export const selectEmailAccountAddedModal = (state: RootState) =>
  state.emailAccounts.emailAccountAddedModal

export const emailAccountsReducer = emailAccountsSlice.reducer

// TODO prob better to be calc'd on backend
export const selectHasContactLessEmails = createSelector(
  [selectEmailAccounts],
  (emailAccounts) => {
    return emailAccounts.some((emailAccount) => {
      if (emailAccount.providerType !== 'GOOGLE') {
        return false
      }

      return (
        !emailAccount.scope?.length ||
        !emailAccount.scope.find((s) => s.includes('contacts.other.readonly'))
      )
    })
  }
)
