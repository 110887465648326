import { PlusSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useMyCalendars } from '~/areas/calendar/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

export const CreateEventButton = () => {
  const modalApi = useModalApi()
  const myCalendars = useMyCalendars()
  const buildTaskModalUrl = useTaskModalUrl()
  const navigate = useNavigate()

  const handleCreateEvent = () => {
    recordAnalyticsEvent('CALENDAR_TOOLBAR_CREATE_EVENT_CLICK', {
      hasCalendars: myCalendars.length > 0,
    })

    if (myCalendars.length > 0) {
      modalApi.open('event-modal', {})
    } else {
      navigate(buildTaskModalUrl())
    }
  }

  return (
    <Tooltip
      content={myCalendars.length ? 'Create event' : 'Create task'}
      asChild
    >
      <IconButton
        variant='outlined'
        sentiment='neutral'
        size='small'
        icon={PlusSolid}
        aria-label={myCalendars.length ? 'Create event' : 'Create task'}
        onClick={handleCreateEvent}
      />
    </Tooltip>
  )
}
