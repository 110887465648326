import type { WorkspaceFieldsByTypedName } from '@motion/ui-logic'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useCallback } from 'react'

import { getTypedNameKey } from '../utils'

export const useWorkspaceCustomFieldsByTypedName = () => {
  const { getCustomFields } = useCustomFieldsFns()

  return useCallback(
    (workspaceId: string | null): WorkspaceFieldsByTypedName => {
      const customFields = getCustomFields(workspaceId ?? '')

      const fieldsByTypedName = customFields.reduce<WorkspaceFieldsByTypedName>(
        (acc, field) => ({ ...acc, [getTypedNameKey(field)]: field }),
        {}
      )

      return fieldsByTypedName
    },
    [getCustomFields]
  )
}
