import { AppleLogo, GoogleLogo, MicrosoftLogo } from '@motion/icons'
import { type ProviderType } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import linkCalendarBanner from './link-calendar-banner.png'

import { addAccount } from '../../../../utils/auth'

export const LinkCalendarsIntro = () => {
  const modalApi = useModalApi()

  const onAddAccount = (provider: ProviderType) => {
    addAccount(provider)
    recordAnalyticsEvent('LINK_CALENDARS_MODAL_PROVIDER_CLICK', { provider })
  }

  return (
    <div className='flex flex-col'>
      <img src={linkCalendarBanner} alt='banner' className='w-full' />
      <p className='text-semantic-neutral-text-default font-semibold pt-6 pb-4 text-[24px]'>
        Link all your calendars to Motion
      </p>
      <p className='text-semantic-neutral-text-default text-[16px]'>
        You can easily link your Google, Outlook/Microsoft, and iCloud calendars
        with your Motion account.
      </p>
      <br />
      <p className='text-semantic-neutral-text-default text-[16px]'>
        Motion will automatically use them to help schedule your tasks
        seamlessly. Happy scheduling! 😊
      </p>
      <p className='text-semantic-neutral-text-default font-semibold text-[16px] pt-4 pb-6'>
        Choose a provider to continue
      </p>
      <div className='flex flex-row items-center gap-4'>
        <Button
          fullWidth
          onClick={() => {
            onAddAccount('google')
          }}
          sentiment='neutral'
          variant='outlined'
          size='normal'
        >
          <div className='flex flex-row items-center py-1.5 gap-2 whitespace-nowrap'>
            <GoogleLogo />
            Google Calendar
          </div>
        </Button>
        <Button
          fullWidth
          onClick={() => {
            onAddAccount('microsoft')
          }}
          sentiment='neutral'
          variant='outlined'
        >
          <div className='flex flex-row items-center py-1.5 gap-2 whitespace-nowrap'>
            <MicrosoftLogo />
            Microsoft Calendar
          </div>
        </Button>
        <Button
          fullWidth
          onClick={() => {
            modalApi.open('add-icloud-calendar')
            recordAnalyticsEvent('LINK_CALENDARS_MODAL_PROVIDER_CLICK', {
              provider: 'apple',
            })
          }}
          sentiment='neutral'
          variant='outlined'
        >
          <div className='flex flex-row items-center py-1.5 gap-2 whitespace-nowrap'>
            <AppleLogo />
            Apple Calendar
          </div>
        </Button>
      </div>
    </div>
  )
}
