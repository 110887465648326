import { TitleModal } from '@motion/ui/base'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'

import { type ModalTriggerComponentProps } from '~/areas/modals'

export function TutorialVideoModal({
  close,
}: ModalTriggerComponentProps<'tutorial-video'>) {
  useOnMountAnalyticsEvent('TUTORIAL_VIDEO_POPUP_SHOW')

  const videoSrc =
    'https://www.youtube.com/embed/no2l-Ob1Eq4?si=85e0dcyAO2wt1k5i&start=0&end=1088&autoplay=false&color=1&fs=1&rel=0'

  return (
    <TitleModal
      disableEscapeKey
      disableOverlayClick
      onClose={close}
      title='Learn from a real customer and see how they set up Motion'
      visible
    >
      <div className='flex flex-col gap-4 max-w-[568px]'>
        <span className='text-semantic-neutral-text-default font-normal text-sm'>
          Efficient App, a small business of 5 team members, has been using
          Motion for more than 2 years to manage their team projects and tasks.
        </span>
        <iframe
          allowFullScreen
          className='w-[568px] h-[318px] rounded'
          src={videoSrc}
        />
      </div>
    </TitleModal>
  )
}
