import { UserAddSolid } from '@motion/icons'
import { GradientButton } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useIsTeamExpired } from '~/global/hooks/team'
import { useAppSelector } from '~/state/hooks'
import { selectTeam } from '~/state/team'

type InviteTeammateButtonProps = {
  abbreviated?: boolean
}

export function InviteTeammateButton({
  abbreviated = false,
}: InviteTeammateButtonProps) {
  const modalApi = useModalApi()
  const team = useAppSelector(selectTeam)
  const isExpiredTeam = useIsTeamExpired()

  if (!team || isExpiredTeam) return null

  return (
    <div className='w-full h-full bg-semantic-neutral-bg-disabled'>
      <GradientButton
        size='small'
        fullWidth
        onClick={() => {
          modalApi.open('invite-teammate', {})
        }}
        sentiment='promotion'
      >
        <UserAddSolid />
        Invite {!abbreviated && 'team members'}
      </GradientButton>
    </div>
  )
}
