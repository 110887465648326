export const favoriteTimezonesList = [
  'America/Los_Angeles',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'Europe/London',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Rome',
  'Europe/Moscow',
  'Europe/Istanbul',
  'Asia/Shanghai',
  'Asia/Kolkata',
  'Asia/Tokyo',
  'Asia/Jakarta',
  'Asia/Seoul',
  'America/Sao_Paulo',
  'America/Lima',
  'America/Bogota',
  'America/Santiago',
  'Australia/Sydney',
  'Pacific/Auckland',
  'Australia/Brisbane',
  'Australia/Perth',
  'Africa/Lagos',
  'Africa/Cairo',
  'Africa/Johannesburg',
  'Africa/Nairobi',
  'Africa/Casablanca',
]

export const favoriteTimezones = [
  {
    continent: 'North America',
    timezones: [
      'America/Los_Angeles',
      'America/New_York',
      'America/Chicago',
      'America/Denver',
    ],
  },
  {
    continent: 'Europe',
    timezones: [
      'Europe/London',
      'Europe/Paris',
      'Europe/Berlin',
      'Europe/Rome',
      'Europe/Moscow',
      'Europe/Istanbul',
    ],
  },
  {
    continent: 'Asia',
    timezones: [
      'Asia/Shanghai',
      'Asia/Kolkata',
      'Asia/Tokyo',
      'Asia/Jakarta',
      'Asia/Seoul',
    ],
  },
  {
    continent: 'South America',
    timezones: [
      'America/Sao_Paulo',
      'America/Lima',
      'America/Bogota',
      'America/Santiago',
    ],
  },
  {
    continent: 'Africa',
    timezones: [
      'Africa/Lagos',
      'Africa/Cairo',
      'Africa/Johannesburg',
      'Africa/Nairobi',
      'Africa/Casablanca',
    ],
  },
  {
    continent: 'Oceania',
    timezones: [
      'Australia/Sydney',
      'Pacific/Auckland',
      'Australia/Brisbane',
      'Australia/Perth',
    ],
  },
]
