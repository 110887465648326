import { useProjectUpdater } from '~/areas/project/hooks'
import { FolderField, type FolderFieldProps } from '~/areas/task-project/fields'
import { useProject } from '~/global/hooks'

type ProjectFolderFieldProps = {
  projectId: string
  onChange?: FolderFieldProps['onChange']
}

export function ProjectFolderField({
  projectId,
  onChange: onChangeFromProps,
}: ProjectFolderFieldProps) {
  const updateProject = useProjectUpdater()
  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project is required')
  }

  const onChange: FolderFieldProps['onChange'] = async (changes) => {
    const { folderId, workspaceId } = changes
    await updateProject(project.id, { folderId, workspaceId })
    onChangeFromProps?.(changes)
  }

  return (
    <FolderField
      folderId={project.folderId}
      workspaceId={project.workspaceId}
      onChange={onChange}
    />
  )
}
