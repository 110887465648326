import {
  createStateKey,
  type StateKey,
  useSharedState,
} from '@motion/react-core/shared-state'

import { deserialize, serialize } from './serialization'
import { DEFAULT_VIEW_STATE } from './serialization/defaults'
import { type ViewState, type WorkspaceViewState } from './types'

export const ViewStateKey = createStateKey<ViewState>('view-state', {
  defaultValue: DEFAULT_VIEW_STATE,
  serialize,
  deserialize,
})

export const useViewState = <T extends ViewState = WorkspaceViewState>() =>
  useSharedState<T>(ViewStateKey as unknown as StateKey<T>)
