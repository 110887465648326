import type { UserTaskSettingRelativeDateOption } from '@motion/shared/common'

export const UserTaskSettingRelativeDateLabels = {
  today: 'Today',
  tomorrow: 'Tomorrow',
  'next-7-days': '7 days from now',
  'next-14-days': 'In 2 weeks',
  'this-week': 'This week',
  'next-week': 'Next week',
  'this-month': 'This month',
  'next-30-days': 'In one month',
  'next-month': 'Next month',
} as const satisfies Record<UserTaskSettingRelativeDateOption, string>
