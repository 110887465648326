import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { type SupportedNumberFilterSchema } from '@motion/ui-logic/pm/data'
import { createLookupByKey } from '@motion/utils/object'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'

import { NumberFilterDropdown } from './number-filter-dropdown'
import { getDisplayText } from './utils'

import { type DropdownFilterProps } from '../../../../types'
import { FilterItem } from '../../filter-item'
import { getNarrowedFilter } from '../utils'

type Props = DropdownFilterProps & {
  groupedFields: CustomFieldSchemaByType<'number'>[]
}

export const ConnectedCustomFieldNumberFilter = (props: Props) => {
  const { groupedFields } = props
  const firstField = groupedFields[0]
  const [filters, setFilterValue] = useCustomFieldFilter(
    props.target,
    firstField
  )
  const filter = getNarrowedFilter(filters)

  const applyFilter = (schema: SupportedNumberFilterSchema | null) => {
    if (schema == null) {
      setFilterValue(null)
    } else if (
      (schema.operator !== 'range' &&
        schema.operator !== 'empty' &&
        isNaN(schema.value)) ||
      (schema.operator === 'range' &&
        (isNaN(schema.value.min) || isNaN(schema.value.max)))
    ) {
      return
    } else {
      const record = createLookupByKey(groupedFields, 'id', () => schema)
      setFilterValue(record)
    }
  }

  const displayText = getDisplayText(filters)

  return (
    <FilterItem
      label={firstField.name}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setFilterValue(null)}
      canInvert
      inverted={filter?.inverse}
      onInvertChanged={(invert) => {
        if (filter == null) return
        applyFilter({ ...filter, inverse: invert })
      }}
      renderDropdown={({ close }) => (
        <NumberFilterDropdown
          target={props.target}
          customField={firstField}
          onSubmit={(value) => {
            applyFilter(value)
            close()
          }}
          close={close}
        />
      )}
    >
      {displayText != null ? (
        <div className='flex gap-1 items-center'>{displayText}</div>
      ) : null}
    </FilterItem>
  )
}
