import { ConfirmationModal, showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useDisconnectCalendars } from '~/global/rpc/user'

export const DisconnectCalendarsModal = ({
  close,
}: ModalTriggerComponentProps<'disconnect-calendars'>) => {
  const { mutateAsync: disconnectCalendars, isLoading } =
    useDisconnectCalendars({
      onError: (error) => {
        if (error.message) {
          showToast('error', `Failed to disconnect calendars: ${error.message}`)
          return
        }

        showToast('error', 'Failed to disconnect calendars')
      },
    })

  return (
    <ConfirmationModal
      action={{
        disabled: isLoading,
        label: 'Disconnect external calendars',
        onAction: async () => {
          recordAnalyticsEvent('ACCOUNT_SETTINGS_DISCONNECT_CALENDARS')
          await disconnectCalendars()
          close()
          showToast('success', 'Calendar disconnection initiated.')
        },
      }}
      destructive
      description={
        <span className='w-[398px] block'>
          This will disconnect any external calendar connections and allow you
          to reconnect them. This is a safe way to re-sync any external
          calendars if they are having sync issues.
        </span>
      }
      title={
        <p className='text-[16px] w-[402px] '>
          Are you sure you want to disconnect external calendars?
        </p>
      }
      onClose={close}
      visible
    />
  )
}
