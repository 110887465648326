export const AGENDA_URI = '/web/agenda' as const
export const CALENDAR_URI = '/web/calendar' as const
export const MY_TASKS_URI = '/web/pm/my-tasks' as const
export const ALL_TASKS_URI = '/web/pm/all-tasks' as const
export const ALL_PROJECTS_URI = '/web/pm/all-projects' as const

export const GLOBAL_SIDEBAR_PANEL_CONTAINER_ID =
  'global-sidebar-panel-container'

export const HIGHLIGHTABLE_DATA_ATTRIBUTE = 'data-motion-highlightable-id'
export const HIGHLIGHTABLE_DATASET_ATTRIBUTE = 'motionHighlightableId'
