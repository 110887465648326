import { TagSolid } from '@motion/icons'
import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { groupInto } from '@motion/utils/array'
import { type BulkFieldUpdateSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { BulkLabelsDropdown, BulkLabelsLabel, FieldButton } from '../components'
import { operationsLabel } from '../constants'
import { useBulkOpsField } from '../contexts'
import { useBulkFieldValidation, useBulkFilteredLabels } from '../hooks'

export function LabelsField() {
  const [labelField, seLabelField] = useBulkOpsField('labels')
  const filteredLabels = useBulkFilteredLabels()

  const active = labelField && labelField.length > 0

  const { isValid, errMsg } = useBulkFieldValidation([
    () => ({
      isValid: filteredLabels.length > 0,
      errMsg: 'No labels are shared between the tasks you selected',
    }),
  ])

  return (
    <Tooltip content={errMsg}>
      <BulkLabelsDropdown
        onChange={seLabelField}
        selectedLabelsField={labelField}
        filteredLabels={filteredLabels}
      >
        <FieldButton
          disabled={!isValid}
          active={active}
          analyticsName='labels'
          onRemove={() => seLabelField(undefined)}
        >
          {active ? (
            <LabelListItems values={labelField} />
          ) : (
            <>
              <TagSolid />
              Labels
            </>
          )}
        </FieldButton>
      </BulkLabelsDropdown>
    </Tooltip>
  )
}

function LabelListItems({
  values,
}: {
  values: Required<BulkFieldUpdateSchema>['labels']
}) {
  const labels = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.labels.all
  )

  const labelsGroup = useMemo(() => {
    const matchedLabels = values.flatMap(
      (value) => labels.find((label) => label.id === value.id) ?? []
    )

    return groupInto(matchedLabels, (l) => l.name)
  }, [labels, values])

  let content = <BulkLabelsLabel values={labelsGroup[0].items} />

  // We use fragments here because the parent "FieldButton" component will handle the layout
  if (labelsGroup.length > 1) {
    content = <>{labelsGroup.length} labels</>
  }

  const operation = values[0].operation

  return templateStr('{{icon}} {{operation}} {{content}}', {
    icon: <TagSolid />,
    operation:
      operation === 'remove' ? (
        <span className='text-semantic-error-text-default'>
          {operationsLabel[operation]}
        </span>
      ) : null,
    content,
  })
}
