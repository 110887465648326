import {
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'

import { useCallback } from 'react'

import { useSelectedView } from './use-selected-view'

import { ViewStateKey } from '../../view-state'
import { fromViewDefinition } from '../utils'

export const useResetView = () => {
  const [viewState, setViewState] = useSharedState(ViewStateKey)
  const setFilter = useSharedStateSendOnly(ActiveFilterKey)
  const [currentView, selectView] = useSelectedView()

  return useCallback(() => {
    const segments = fromViewDefinition(currentView)
    setViewState(segments.view)
    setFilter(segments.filter)

    selectView(viewState.viewId)
  }, [currentView, selectView, setFilter, setViewState, viewState.viewId])
}
