import { type UseMutationArgs } from '@motion/rpc'

import { type useCreateCustomField } from '~/areas/project-management/hooks/custom-fields/rpc'

import { validateNoDuplicateOptions } from '../../errors'
import { type AddCustomFieldFormFields } from '../form-utils'

export function formatCreateFieldArgs(
  workspaceId: string,
  data: AddCustomFieldFormFields
): UseMutationArgs<typeof useCreateCustomField> | null {
  const commonArgs = { workspaceId, name: data.name.trim() }
  switch (data.type) {
    case 'date':
    case 'text':
    case 'url':
    case 'person':
    case 'multiPerson':
      return {
        ...commonArgs,
        type: data.type,
      }
    case 'select':
    case 'multiSelect': {
      const options = data.metadata.options.map((option) => {
        return {
          ...option,
          value: option.value.trim(),
        }
      })

      validateNoDuplicateOptions(options)

      return {
        ...commonArgs,
        type: data.type,
        metadata: {
          ...data.metadata,
          options,
        },
      }
    }
    case 'number':
      return {
        ...commonArgs,
        type: data.type,
        metadata: {
          ...data.metadata,
        },
      }
    case 'phone':
    case 'email':
    case 'checkbox':
    case 'relatedTo':
      return null
  }
}
