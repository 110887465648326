import { useDependantState } from '@motion/react-core/hooks'
import { useActiveFilter } from '@motion/ui-logic/pm/data'

import { KanbanDndContainer } from './dnd-container'
import { KanbanNoGroupsState } from './no-groups-state'

import { findNodeOrFirst, type GroupedNode, type Tree } from '../grouping'
import { EmptyState } from '../pages'

export type KanbanBoardProps<T extends GroupedNode> = {
  tree: Tree<T>
}

export const KanbanBoard = <T extends GroupedNode>(
  props: KanbanBoardProps<T>
) => {
  const tree = props.tree
  const depth = tree.maxDepth
  const [filterState] = useActiveFilter()

  // has the qualified keys of the stages
  const [active, setActive] = useDependantState<Tree<T>[]>(
    (prev) => {
      const keys =
        prev == null || prev.length !== tree.maxDepth - 1
          ? Array.from({ length: tree.maxDepth - 1 }, () => '')
          : prev.map((x) => x.key)

      if (keys.length === 0) {
        return []
      }

      const first = findNodeOrFirst(tree, keys[0])
      if (keys.length === 1) {
        return [first]
      }
      const second = findNodeOrFirst(first, keys[1])
      return [first, second]
    },
    [tree]
  )

  const selectTab = (index: number, value: Tree<T>) => {
    setActive((prev) => {
      const clone = [...prev]
      clone[index] = value

      if (index === 0 && depth > 2) {
        clone[1] = value.children[0]
      }

      return clone
    })
  }

  if (tree.groups.length > 0 && tree.maxDepth === 0) {
    return <EmptyState target={filterState.target} />
  }

  if (tree.groups.length === 0) {
    return <KanbanNoGroupsState />
  }

  return (
    <KanbanDndContainer
      tree={tree}
      selectTab={(index, value) => selectTab(index, value)}
      activeTab={active}
    />
  )
}
