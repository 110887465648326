import { useHasTreatment } from '@motion/web-common/flags'

import { useCallback } from 'react'

import { usePersistedConferenceSettingsStateFirestore } from './use-persisted-conference-settings-state-firestore'
import { usePersistedConferenceSettingsStatePostgres } from './use-persisted-conference-settings-state-postgres'

import { type PersistedConferenceSettingsStateUpdate } from '../types'

export const usePersistedConferenceSettingsState = () => {
  const shouldReadPostgres = useHasTreatment(
    'kill-firestore-conference-settings-read'
  )
  const shouldDualWrite = useHasTreatment(
    'kill-firestore-conference-settings-dual-write'
  )

  const [firestoreData, firestoreUpdate] =
    usePersistedConferenceSettingsStateFirestore()
  const [postgresData, postgresUpdate] =
    usePersistedConferenceSettingsStatePostgres({
      read: shouldReadPostgres,
      write: shouldDualWrite,
    })

  const dualUpdate = useCallback(
    async (data: PersistedConferenceSettingsStateUpdate) => {
      // The firestoreUpdate is technically redundant but do it to be sure
      await firestoreUpdate(data)
      await postgresUpdate(data)
    },
    [firestoreUpdate, postgresUpdate]
  )

  return [
    shouldReadPostgres ? postgresData : firestoreData,
    shouldDualWrite ? dualUpdate : firestoreUpdate,
  ] as const
}
