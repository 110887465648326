import { logInDev } from '@motion/web-base/logging'
import { firebase } from '@motion/web-common/firebase'

import { signInWithCustomToken as fb_signInWithCustomToken } from 'firebase/auth'
import diff from 'microdiff'

import api from '../../chromeApi/chromeApiBackground'

const getSyncUserID = () => {
  return firebase.auth().currentUser?.uid
}

/**
 * @deprecated avoid reading from/writing to firestore settings. Use Postgres
 * settings and/or introduce new endpoints
 */
export const persistSettings = async (
  settings: Record<string, any>,
  pushToStorage = false,
  merge = false
) => {
  const syncUserID = getSyncUserID()
  if (!syncUserID) {
    logInDev('persistSettings - no syncUserID, ignoring persist')
    return
  }

  if (pushToStorage) {
    await api.storage.local.set(settings)
  }

  logInDev('persistSettings', settings)
  // @ts-expect-error - legacy
  const oldData = api.storage.local.get(Object.keys(settings))
  const diffResult = diff(oldData, settings)
  if (!diffResult.length) {
    logInDev('persistSettings - no changes, ignoring persist')
    return
  }

  logInDev('change', { diffResult })
  await api.storage.local.set(settings)
  const ref = firebase.firestore().collection('settings').doc(syncUserID)

  if (merge) {
    await ref.set(settings, { merge: true })
  } else {
    await ref.update(settings)
  }
}

export const signInWithCustomToken = async (token: string) => {
  await fb_signInWithCustomToken(firebase.auth(), token)
}
