import { PlusSolid } from '@motion/icons'
import { useSharedStateContext } from '@motion/react-core/shared-state'
import { IconButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { intersection, useActiveFilter } from '@motion/ui-logic/pm/data'

import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import {
  useInferProjectNavigateParams,
  useInferTaskNavigateParams,
} from './hooks'

import { AppWorkspaceContext } from '../../../../../global/contexts'
import { type GroupedNode, type Tree } from '../../grouping'

const workspaceParentId = <T extends GroupedNode>(
  currentGroup: Tree<T>
): string | undefined => {
  while (!!currentGroup.item && !!currentGroup.item.parent) {
    if (currentGroup.item.value.type === 'workspace') {
      return currentGroup.item.value.value.id
    }

    if (!currentGroup.parent) {
      break
    }

    currentGroup = currentGroup.parent
  }

  return undefined
}

const parentGroupWorkspaceIntersection = <T extends GroupedNode>(
  currentGroup: Tree<T>
): string[] => {
  const groupWorkspaceIds = currentGroup.item?.workspaceIds ?? []

  let workspaceIds = groupWorkspaceIds

  while (!!currentGroup.item && !!currentGroup.item.parent) {
    if (currentGroup.item.value.type === 'workspace') {
      break
    }

    if (!currentGroup.parent) {
      break
    }

    currentGroup = currentGroup.parent

    workspaceIds = intersection(
      workspaceIds,
      currentGroup.item?.workspaceIds ?? []
    )
  }

  if (workspaceIds.length > 0) {
    return workspaceIds
  }

  return []
}

type TabsAddItemProps<T extends GroupedNode> = {
  item: Tree<T>
}

export const TabAddItem = <T extends GroupedNode>(
  props: TabsAddItemProps<T>
) => {
  const sharedStateApi = useSharedStateContext()
  const ctx = sharedStateApi.get(AppWorkspaceContext)

  const filterState = useActiveFilter()
  const itemType = filterState[0]?.target ?? 'tasks'

  const taskNavigateParams = useInferTaskNavigateParams()
  const projectNavigateData = useInferProjectNavigateParams()

  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()

  const groupWorkspaceOptions: string[] = useMemo(() => {
    // Group workspace options are determined by the following rules:

    // 1. If we have a workspace parent group, we should use that workspaceId
    const parentWorkspaceGroupId = workspaceParentId(props.item)
    if (parentWorkspaceGroupId) return [parentWorkspaceGroupId]

    // 2. Otherwise if we have a single workspace in the group, we should use that workspaceId
    const groupWorkspaceIds = props.item.item?.workspaceIds ?? []
    if (groupWorkspaceIds.length === 1) {
      return groupWorkspaceIds
    }

    // 3. Otherwise find the intersection of workspaceIds for all parent groups
    const parentGroupWorkspaceIntersections = parentGroupWorkspaceIntersection(
      props.item
    )

    if (parentGroupWorkspaceIntersections.length > 0) {
      return parentGroupWorkspaceIntersections
    }

    // 4. Or scan through tasks/projects to find workspaceIds
    const itemWorkspaceIds = [
      ...new Set(props.item.values.map((item) => item.value.value.workspaceId)),
    ]
    if (itemWorkspaceIds.length > 0) {
      return itemWorkspaceIds
    }

    // 5. Finally use the value workspaceId if it exists
    return props.item.item?.workspaceIds ?? []
  }, [props.item])

  const onAddClick = (workspaceId: string) => {
    if (itemType === 'tasks') {
      navigate(buildTaskModalUrl(taskNavigateParams(props.item, workspaceId)))
    } else if (itemType === 'projects') {
      navigate(
        buildProjectModalUrl(projectNavigateData(props.item, workspaceId))
      )
    }
  }

  // If multiple workspaces, show a selector popover
  if (groupWorkspaceOptions.length > 1) {
    return (
      <PopoverTrigger
        placement='bottom-start'
        renderPopover={({ close }) => {
          const workspaces = groupWorkspaceOptions.map((id) => ({
            id,
            label: ctx.workspaces.byId[id].name,
            value: id,
          }))

          return (
            <SearchableList
              items={workspaces.sort((a, b) => a.label.localeCompare(b.label))}
              itemType='select'
              searchable
              computeKey={(item) => item.label}
              computeSelected={() => false}
              onSelect={(item) => {
                onAddClick(item.value)
                close()
              }}
              renderItem={(item) => <div>{item.label}</div>}
            />
          )
        }}
      >
        <IconButton
          icon={PlusSolid}
          variant='muted'
          size='small'
          sentiment='neutral'
        />
      </PopoverTrigger>
    )
  }

  // If single workspace, add directly
  return (
    <IconButton
      icon={PlusSolid}
      variant='muted'
      size='small'
      sentiment='neutral'
      onClick={() => onAddClick(groupWorkspaceOptions[0])}
    />
  )
}
