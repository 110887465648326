import {
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { useHasTreatment } from '@motion/web-common/flags'
import {
  type VersionedViewV2,
  type WorkspaceVersionedViewV2,
} from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'
import { useNavigateByRouteId } from '~/routing'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { viewIdSlug } from './view-id-slug'

import { usePageData } from '../../routes/hooks'
import { ViewStateKey } from '../../view-state'
import { getDefaultView } from '../defaults'
import { fromViewDefinition } from '../utils'

type SelectViewOptions = {
  updateOnly?: boolean
}

export const useSelectView = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const setViewState = useSharedStateSendOnly(ViewStateKey)
  const setFilterState = useSharedStateSendOnly(ActiveFilterKey)
  const navigate = useNavigateByRouteId()
  const urlNavigate = useNavigate()
  const route = usePageData()
  const routingV4 = useHasTreatment('pm-view-based-routing')
  const hasDashboard = useHasTreatment('charts-preview')

  return useCallback(
    (
      maybeIdOrView: VersionedViewV2 | string | null,
      opts: SelectViewOptions = { updateOnly: false }
    ) => {
      if (routingV4) {
        const viewId =
          maybeIdOrView == null
            ? 'default'
            : typeof maybeIdOrView === 'string'
              ? maybeIdOrView
              : viewIdSlug(maybeIdOrView.id)

        return navigate('parent', {
          viewId: viewIdSlug(viewId),
        })
      }

      const defaultView = getDefaultView(route.page, {
        isFlowProject:
          route.page === 'project' &&
          route.lock.projectId != null &&
          isFlowProject(ctx.projects.byId[route.lock.projectId]),
      })

      const view =
        maybeIdOrView == null
          ? defaultView
          : typeof maybeIdOrView === 'string'
            ? (ctx.views.byId[maybeIdOrView] ?? defaultView)
            : maybeIdOrView

      const local = fromViewDefinition(view as WorkspaceVersionedViewV2)

      setFilterState(local.filter)
      setViewState(local.view)

      if (opts.updateOnly) return

      if (hasDashboard && view.definition.type === 'dashboard') {
        return urlNavigate('./dashboard', { relative: 'route' })
      }

      navigate('parent', {
        type: local.filter.target,
        variant: local.view.page,
      })
    },
    [
      ctx.projects.byId,
      ctx.views.byId,
      hasDashboard,
      navigate,
      route.lock.projectId,
      route.page,
      routingV4,
      setFilterState,
      setViewState,
      urlNavigate,
    ]
  )
}
