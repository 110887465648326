import type { EntityFilterState, FilterTarget } from '@motion/ui-logic/pm/data'
import type {
  IdFilterSchema,
  PriorityFilterSchema,
  PriorityLevelSchema,
} from '@motion/zod/client'

import type {
  AppWorkspaceDataContext,
  WorkspaceStageDefinition,
} from '~/global/contexts'

export function matchesPriority(
  all: PriorityLevelSchema[],
  op: PriorityFilterSchema | null
) {
  if (op == null) return all
  return all.filter((item) => {
    const valueFound = op.value.includes(item)
    return invert(valueFound, op.inverse)
  })
}

export function matches<T extends { id: string }>(
  all: T[],
  op: IdFilterSchema | null
) {
  if (op == null) return all
  return all.filter((item) => {
    const valueFound = op.value.includes(item.id)
    return invert(valueFound, op.inverse)
  })
}

export function matchesStage(
  ctx: AppWorkspaceDataContext,
  type: FilterTarget,
  filter: EntityFilterState
) {
  const stageDefinitionIds = filter[type].filters.stageDefinitionIds
  const projectDefinitionIds = filter.projects.filters.projectDefinitionIds

  if (stageDefinitionIds == null && projectDefinitionIds == null) {
    return ctx.stageDefinitions.all
  }

  return ctx.stageDefinitions.all.filter((item) =>
    filteredStageMatches(item, projectDefinitionIds, stageDefinitionIds)
  )
}

function filteredStageMatches(
  item: WorkspaceStageDefinition,
  projectDefinitionIds: IdFilterSchema | null,
  stageDefinitionIds: IdFilterSchema | null
) {
  if (stageDefinitionIds == null && projectDefinitionIds == null) {
    return true
  }

  if (stageDefinitionIds != null && projectDefinitionIds != null) {
    return (
      invert(
        stageDefinitionIds.value.includes(item.id),
        stageDefinitionIds.inverse
      ) &&
      invert(
        projectDefinitionIds.value.includes(item.projectDefinitionId),
        projectDefinitionIds.inverse
      )
    )
  }

  if (stageDefinitionIds != null) {
    return invert(
      stageDefinitionIds.value.includes(item.id),
      stageDefinitionIds.inverse
    )
  }

  if (projectDefinitionIds != null) {
    return invert(
      projectDefinitionIds.value.includes(item.projectDefinitionId),
      projectDefinitionIds.inverse
    )
  }

  return false
}

function invert(left: boolean | undefined, right: boolean | undefined) {
  return Boolean(right ? !left : left)
}
