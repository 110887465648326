import { useProjectUpdater } from '~/areas/project/hooks'
import {
  AssigneeField,
  type AssigneeFieldProps,
} from '~/areas/task-project/fields'
import { useWorkspaceMember } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledAssigneeField = () => {
  const { form } = useProjectForm()
  const updateProject = useProjectUpdater()

  const { control, watch } = form

  const { field } = useController({
    name: 'managerId',
    control,
  })
  const id = watch('id')
  const workspaceId = watch('workspaceId')
  const selectedAssignee = useWorkspaceMember(workspaceId, field.value)

  const onChange: AssigneeFieldProps['onChange'] = async (assigneeId) => {
    if (id != null) {
      return await updateProject(id, { managerId: assigneeId })
    }

    field.onChange(assigneeId)
  }

  return (
    <AssigneeField
      value={selectedAssignee?.userId ?? null}
      onChange={onChange}
      workspaceId={workspaceId}
    />
  )
}
