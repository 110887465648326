import { showToast } from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { type ProjectsV2ApplyDefinitionRequestSchema } from '@motion/zod/client'

import { useApplyDefinitionToProject as useApplyDefinitionToProjectCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useApplyDefinitionToProject = () => {
  const { mutateAsync: applyDefinitionToProject } =
    useApplyDefinitionToProjectCall()

  return useCallback(
    async (
      data: ProjectsV2ApplyDefinitionRequestSchema & {
        projectId: string
        projectDefinitionId: string
      }
    ) => {
      try {
        const response = await applyDefinitionToProject(data)

        showToast('success', 'Template successfully applied to project')

        return response.models.projects[response.id]
      } catch (error) {
        showToast('error', 'Failed to apply template to project')

        Sentry.captureException(error, {
          extra: {
            data,
          },
        })
        return undefined
      }
    },
    [applyDefinitionToProject]
  )
}
