import { calculateDurationInMinutes } from '@motion/ui-logic'
import { getTaskDurationChangedFields } from '@motion/ui-logic/pm/task'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useRecordCalendarTaskChangeUFFeedback } from '~/global/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { useAppDispatch } from '~/state/hooks'
import { confirmCalendarTurnIntoFixedTimeTask } from '~/state/modalsSlice'
import { useCallback } from 'react'

export const useCalendarTaskUpdater = () => {
  const dispatch = useAppDispatch()
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const recordCalendarTaskChangeUFFeedback =
    useRecordCalendarTaskChangeUFFeedback()

  return useCallback(
    async (id: string, updates: { start: string; end: string }) => {
      const originalTask = await readTask(id)

      if (!originalTask || originalTask.type === 'RECURRING_TASK') return

      const accepted = await dispatch(
        confirmCalendarTurnIntoFixedTimeTask({ task: originalTask })
      ).unwrap()

      if (!accepted) return

      const duration = calculateDurationInMinutes(updates.start, updates.end)
      const updatedTask = await updateTask(originalTask, {
        duration,
        ...getTaskDurationChangedFields({
          ...originalTask,
          duration: duration,
        }),
        isFixedTimeTask: true,
        scheduledStart: updates.start,
        scheduledEnd: updates.end,
      })

      recordCalendarTaskChangeUFFeedback(originalTask, updatedTask)

      return updatedTask
    },
    [dispatch, readTask, recordCalendarTaskChangeUFFeedback, updateTask]
  )
}
