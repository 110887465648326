import { isCompletedStatus } from '@motion/shared/common'
import { createNoneProject } from '@motion/ui-logic/pm/data'

import {
  type useOrderedWorkspaceProjectList,
  useWorkspaceFns,
} from '~/global/hooks'
import { useMemo } from 'react'

type WorkspaceProjectSectionsOptions = {
  hideNoProject: boolean
}

export function useWorkspaceProjectSections(
  workspaces: ReturnType<typeof useOrderedWorkspaceProjectList>['data'],
  options: Partial<WorkspaceProjectSectionsOptions>
) {
  const { hideNoProject = false } = options

  const { getStatusById } = useWorkspaceFns()

  return useMemo(
    () =>
      workspaces.map(({ item: workspace, expanded }) => {
        return {
          key: workspace.id,
          label: workspace.name,
          initialExpanded: expanded,
          items: workspace.projects.reduce(
            (acc, project) => {
              const status = getStatusById(project.statusId)
              if (!isCompletedStatus(status)) {
                acc.push(project)
              }

              return acc
            },
            hideNoProject ? [] : [createNoneProject(workspace.id)]
          ),
        }
      }),
    [getStatusById, hideNoProject, workspaces]
  )
}
