import { CalendarSolid } from '@motion/icons'

import { DateTriggerButton } from '~/areas/task-project/components'

export const ControlledDeadlineField = () => {
  return (
    <DateTriggerButton
      label='Deadline'
      icon={<CalendarSolid />}
      disabled
      date={null}
      placeholder='Stage Deadline'
    />
  )
}
