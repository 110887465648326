import { PopoverButton, PopoverTrigger, SearchableList } from '@motion/ui/base'

import { RestrictWidth } from '~/areas/project-management/pages/pm-v3/components'
import { useEffect } from 'react'

import { useGroupByPopoverContext } from './group-by-popover-context'

const DateGroupOptions = [
  { id: 'day', label: 'By day' },
  { id: 'week', label: 'By week' },
  { id: 'month', label: 'By month' },
  { id: 'quarter', label: 'By quarter' },
  { id: 'half', label: 'By half' },
  { id: 'year', label: 'By year' },
] as const satisfies { id: string; label: string }[]

export function DateGroupOptionsSelect({ id }: { id: string }) {
  const { fields, setFieldOption } = useGroupByPopoverContext()

  const selected = fields.find((field) => field.id === id)!
  const selectedOption = DateGroupOptions.find(
    (option) => option.id === selected.by
  )

  const displayOption = selectedOption ?? DateGroupOptions[0]

  useEffect(() => {
    if (!selectedOption) {
      setFieldOption(id, DateGroupOptions[0].id)
    }
  }, [id, selectedOption, setFieldOption])

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          items={DateGroupOptions}
          itemType='select'
          computeKey={(item) => item.id}
          computeSelected={(item) => item.id === displayOption.id}
          computeSearchValue={(item) => item.label}
          onSelect={(item) => {
            setFieldOption(id, item.id)
            close()
          }}
          renderItem={(item) => <RestrictWidth>{item.label}</RestrictWidth>}
        />
      )}
    >
      <PopoverButton className='ml-1'>{displayOption.label}</PopoverButton>
    </PopoverTrigger>
  )
}
