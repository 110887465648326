import { useSharedStateQuery } from '@motion/react-core/shared-state'

import { AppWorkspaceContext } from '~/global/contexts'

import { useBulkFilteredData } from '../use-bulk-filtered-data'

export function useBulkFilteredLabels() {
  const labels = useSharedStateQuery(
    AppWorkspaceContext,
    (state) => state.labels.all
  )

  const filteredLabels = useBulkFilteredData(labels, (l) => l.name)

  return filteredLabels
}
