import { useAgendaState } from '~/areas/agenda/hooks/use-agenda-state'
import { useMemo } from 'react'

export function useWeekOfSelectedDay() {
  const [agendaState] = useAgendaState()

  return useMemo(() => {
    return {
      start: agendaState.selectedDate.toISO(),
      end: agendaState.selectedDate.plus({ days: 7 }).endOf('day').toISO(),
    }
  }, [agendaState.selectedDate])
}
