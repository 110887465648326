import { XSolid } from '@motion/icons'
import { API } from '@motion/rpc'
import { IconButton, Modal } from '@motion/ui/base'
import { useModalStatus } from '@motion/web-common/modals'

import { useQueryClient } from '@tanstack/react-query'
import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { AddICloudForm, type ICloudForm } from './add-icloud-form'
import { AddICloudInstructions } from './add-icloud-instructions'

import { setEmailAccountAddedModal } from '../../../state/email-accounts/email-accounts-slice'
import { fetchAllEmailAccounts } from '../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch } from '../../../state/hooks'

type STEPS = 'INSTRUCTIONS' | 'FORM'

export const AddICloudCalendarModalContainer = () => {
  const client = useQueryClient()
  const dispatch = useAppDispatch()
  const status = useModalStatus('add-icloud-calendar')
  const [step, setStep] = useState<STEPS>('INSTRUCTIONS')
  const form = useForm<ICloudForm>({
    defaultValues: {
      email: '',
      password: '',
      type: 'APPLE',
    },
    reValidateMode: 'onBlur',
  })
  const { selectIsOnboarding, selectIsInLinkCalendarsFlow } = useOnboardingApi()

  const isOnboarding = selectIsOnboarding()
  const isInLinkCalendarsFlow = selectIsInLinkCalendarsFlow()

  const refetchCalendars = async (
    emailAccountId: string,
    skipConfigurationModals = false
  ) => {
    await dispatch(fetchAllEmailAccounts())
    client.invalidateQueries(API.calendars.queryKeys.root)

    // When onboarding, the step for choosing calendars/main calendar is done
    // after adding all email accounts, so we don't need to show the modal
    if (isOnboarding || isInLinkCalendarsFlow) {
      return
    }

    if (skipConfigurationModals) {
      return
    }

    dispatch(
      setEmailAccountAddedModal({
        visible: true,
        emailAccountId,
      })
    )
    return
  }

  if (!status.visible) return null

  const handleClose = () => {
    setStep('INSTRUCTIONS')
    form.reset()
    status.close()
  }

  const handleSubmit = async (
    emailAccountId: string,
    skipConfigurationModals = false
  ) => {
    await refetchCalendars(emailAccountId, skipConfigurationModals)
    handleClose()
  }

  return (
    <Modal onClose={handleClose} visible>
      <div className='flex flex-col'>
        <div className='dark:text-dark-100 text-light-1200 flex justify-between border-b px-4 py-3'>
          <p className='text-base font-semibold text-inherit dark:text-inherit'>
            Add iCloud Calendar
          </p>
          <IconButton
            icon={XSolid}
            onClick={handleClose}
            sentiment='neutral'
            size='small'
            variant='muted'
            aria-label='Close iCloud calendar setup modal'
          />
        </div>
        <div className='flex flex-col w-full p-4'>
          {step === 'INSTRUCTIONS' && (
            <AddICloudInstructions onNext={() => setStep('FORM')} />
          )}
          {step === 'FORM' && (
            <AddICloudForm
              onBack={() => setStep('INSTRUCTIONS')}
              form={form}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
