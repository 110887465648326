import type { NormalTaskSchema } from '@motion/rpc-types'

export const getAvailableBlockerOptions = (
  projectTasks: NormalTaskSchema[],
  currentBlockers: NormalTaskSchema[],
  task: Partial<Pick<NormalTaskSchema, 'id' | 'stageDefinitionId'>>
) => {
  return projectTasks.filter(
    (t) =>
      t.id !== task.id &&
      !currentBlockers.some((cur) => cur.id === t.id) &&
      (!task.stageDefinitionId ||
        task.stageDefinitionId === t.stageDefinitionId)
  )
}
