import { classed } from '@motion/theme'

export const IconContainer = classed('div', {
  base: 'py-0.5 shrink-0 [&>[data-icon]]:size-4 [&>[data-icon]]:text-dropdown-item-icon-default',
})

export const EndContentContainer = classed(
  'div',
  'flex gap-1 flex-nowrap items-center overflow-hidden'
)
