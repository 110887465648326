import { TrashSolid } from '@motion/icons'
import {
  Button,
  type ButtonProps,
  IconButton,
  type IconButtonProps,
} from '@motion/ui/base'
import { shouldShowPastDueDeleteButton } from '@motion/ui-logic'
import { canTaskBeExtended } from '@motion/ui-logic/pm/task'

import { type CommonButtonProps } from './type'

import { useDeleteTasks, useTasksLoadingState } from '../hooks'

export const DeleteTaskButton = ({
  showAllText,
  tasks,
  type,
}: CommonButtonProps) => {
  const [isLoading, setIsLoading] = useTasksLoadingState(tasks)
  const deleteTasks = useDeleteTasks({ tasks, setIsLoading, type })

  const forceAll = tasks.every((task) =>
    task.type === 'RECURRING_INSTANCE' ? !canTaskBeExtended(task, null) : false
  )

  if (
    !forceAll &&
    tasks.every(
      (task) =>
        task.type === 'RECURRING_INSTANCE' &&
        !shouldShowPastDueDeleteButton(task)
    )
  ) {
    return null
  }

  const commonButtonProps: Partial<ButtonProps & IconButtonProps> = {
    variant: 'outlined',
    sentiment: 'error',
    neutralBase: true,
    size: 'small',
    onClick: deleteTasks,
    disabled: isLoading,
    // @ts-expect-error - will change when we get new button
    className: 'hover:themevar-[b-icon=buttonMuted-error-icon-default]',
  }

  if (showAllText && tasks.length > 1) {
    return (
      <Button {...commonButtonProps}>
        <TrashSolid />
        Delete All
      </Button>
    )
  }

  return (
    <IconButton
      icon={TrashSolid}
      {...commonButtonProps}
      aria-label='Delete task'
    />
  )
}
