import { clearTaskForParams } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useRedirectToTask = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useCallback(
    (taskId: TaskSchema['id']) => {
      if (searchParams.size === 1 && searchParams.get('task') === taskId) return

      setSearchParams((prev) => {
        clearTaskForParams(prev)
        if (prev.get('task') !== taskId) {
          prev.set('task', taskId)
        }
        return prev
      })
    },
    [searchParams, setSearchParams]
  )
}
