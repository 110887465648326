import { BriefcaseSolid } from '@motion/icons'
import { PopoverButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { useController } from 'react-hook-form'

import { PopoverButtonIcon } from '../components'
import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventStatusField = () => {
  const { isLoading, isReadOnly } = useEventModalState()
  const { form } = useEventForm()
  const { control } = form

  const { field } = useController({
    name: 'status',
    control,
  })

  if (isLoading) return null

  const onChange = async (value: CalendarEventSchemaV2['status']) => {
    field.onChange(value)
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={options}
          renderItem={(item) => optionLabels[item]}
          computeKey={(item) => item}
          computeSelected={(item) => item === field.value}
          onSelect={(item) => {
            onChange(item)
            close()
          }}
        />
      )}
    >
      <PopoverButton
        aria-label='Event status'
        readOnly={isReadOnly}
        size='small'
      >
        <div className='flex items-center gap-2 overflow-hidden'>
          <PopoverButtonIcon icon={BriefcaseSolid} />
          <span className='truncate'>{optionLabels[field.value]}</span>
        </div>
      </PopoverButton>
    </PopoverTrigger>
  )
}

const optionLabels: Record<CalendarEventSchemaV2['status'], string> = {
  FREE: 'Free',
  BUSY: 'Busy',
}
const options = keys(optionLabels)
