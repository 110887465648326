import { getCustomFieldValue } from '@motion/ui-logic'
import { keys } from '@motion/utils/object'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { ConnectedCustomFieldLabel } from '~/areas/custom-fields/labels'

import { BulkCustomFieldValueLabel, FieldButton } from '../components'
import {
  BulkCustomFieldDropdownSwitch,
  type BulkCustomFieldFieldArrayValue,
} from '../components/custom-fields'
import { supportedCustomFields } from '../constants'
import {
  type BulkCustomFieldValues,
  useBulkOpsField,
  useBulkOpsGroups,
} from '../contexts'

export function CustomFields() {
  const { workspaceGroups } = useBulkOpsGroups()

  // We only support custom fields currently when all tasks are in the same workspace
  if (workspaceGroups.length !== 1) return null

  return <InnerCustomFields workspaceId={workspaceGroups[0].key} />
}

type InnerCustomFieldsProps = {
  workspaceId: WorkspaceSchema['id']
}

function InnerCustomFields({ workspaceId }: InnerCustomFieldsProps) {
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const [customFieldValues, setCustomFieldValues] =
    useBulkOpsField('customFieldValues')

  if (workspaceCustomFields == null) return null

  return workspaceCustomFields.map((customField) => {
    if (!supportedCustomFields.includes(customField.type)) return null

    const active = customFieldValues?.[customField.id] !== undefined
    const bulkFieldValue = customFieldValues?.[customField.id]?.value

    const customFieldWithValue: BulkCustomFieldFieldArrayValue = {
      instanceId: customField.id,
      name: customField.name,
      type: customField.type,
      value: bulkFieldValue as any,
    }

    const onRemove = () => {
      setCustomFieldValues(
        applyCustomFieldChanges(
          customFieldValues,
          'remove',
          customFieldWithValue
        )
      )
    }

    return (
      <BulkCustomFieldDropdownSwitch
        key={customField.id}
        workspaceId={workspaceId}
        customField={customFieldWithValue}
        onChange={(value: any) => {
          if (value === undefined) {
            return onRemove()
          }

          setCustomFieldValues(
            applyCustomFieldChanges(customFieldValues, 'update', {
              ...customFieldWithValue,
              value,
            })
          )
        }}
      >
        <FieldButton
          active={active}
          analyticsName={`custom_field_${customField.type}`}
          onRemove={onRemove}
        >
          {active ? (
            <BulkCustomFieldValueLabel
              instanceId={customField.id}
              value={customFieldValues[customField.id]}
              size='small'
            />
          ) : (
            <ConnectedCustomFieldLabel instanceId={customField.id} />
          )}
        </FieldButton>
      </BulkCustomFieldDropdownSwitch>
    )
  })
}

function applyCustomFieldChanges(
  values: BulkCustomFieldValues | undefined,
  op: 'update' | 'remove',
  customField: BulkCustomFieldFieldArrayValue
): BulkCustomFieldValues | undefined {
  if (op === 'remove') {
    const copy = { ...values }
    delete copy[customField.instanceId]
    if (keys(copy).length === 0) return undefined
    return copy
  }

  if (op === 'update') {
    return {
      ...values,
      [customField.instanceId]: {
        type: customField.type,
        value: getBulkCustomFieldValue(customField.value) as any,
      },
    }
  }
}

function getBulkCustomFieldValue(
  value: BulkCustomFieldFieldArrayValue['value']
): BulkCustomFieldFieldArrayValue['value'] {
  if (value == null) return null

  if (Array.isArray(value)) {
    return value
  }

  return getCustomFieldValue(value)
}
