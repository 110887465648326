import { isFlowVariableKey } from '@motion/shared/flows'
import type { FlowTemplateRoleAssignee } from '@motion/ui-logic/pm/project'

export function getSelectedRole(
  flowTemplateRoles: FlowTemplateRoleAssignee[],
  maybeAssigneeVariableKey: string | null
) {
  if (!isFlowVariableKey(maybeAssigneeVariableKey)) {
    return null
  }

  const foundRoleIndex = flowTemplateRoles.findIndex(
    (r) => r.key === maybeAssigneeVariableKey
  )
  const foundRole =
    foundRoleIndex !== -1 ? flowTemplateRoles[foundRoleIndex] : null

  if (foundRole != null && foundRole.name === '') {
    return { ...foundRole, name: `Role ${foundRoleIndex + 1}` }
  }

  return foundRole
}
