import { classed } from '@motion/theme'
import { type UserInfoSchema } from '@motion/zod/client'

import {
  type BadgeSize,
  ConnectedUserBadge,
  getBadgeSizeInPixels,
  UserBadge,
} from '~/global/components/badges'
import { useUser } from '~/global/hooks'
import { memo } from 'react'
import { twMerge } from 'tailwind-merge'

import { useCalculatePrettyLabels } from './hooks'
import { RoundNumOverflowBadge } from './round-num-overflow-badge'

export type UserLabelProps = {
  value: UserInfoSchema | null | undefined
  size?: BadgeSize
  nameOnly?: boolean
  disabled?: boolean
}

export const UserLabel = ({
  value,
  size,
  nameOnly,
  disabled,
}: UserLabelProps) => {
  const label = <div className='truncate'>{value?.name ?? 'Unassigned'}</div>

  if (nameOnly) return label

  return (
    <StyledUserLabel disabled={disabled}>
      <UserBadge value={value} size={size} />
      {label}
    </StyledUserLabel>
  )
}

const StyledUserLabel = classed('div', {
  base: 'grid grid-cols-[auto_1fr] gap-1 items-center leading-4',
  variants: {
    disabled: {
      true: 'opacity-50',
    },
  },
})

export type ConnectedUserLabelProps = Omit<UserLabelProps, 'value'> & {
  userId: UserInfoSchema['id'] | null
}

export const ConnectedUserLabel = ({
  userId,
  ...rest
}: ConnectedUserLabelProps) => {
  const user = useUser(userId)

  return <UserLabel value={user} {...rest} />
}

export type UserBadgeSize = 'xsmall' | 'small' | 'normal'

interface PrettyAssigneesProps {
  userIds: string[]
  size: UserLabelProps['size']
  nameOnly?: UserLabelProps['nameOnly']
}

/**
 * This component needs to be rendered in a container with a fixed width.
 * Otherwise, getting the container width in getLabelsRenderingInfo will be buggy.
 *
 * The component is memoized because it reads `getBoundingClientRect` on every render
 */
export const PrettyAssignees = memo(function PrettyAssignees({
  userIds,
  size,
  nameOnly,
}: PrettyAssigneesProps) {
  const labelWidth = getBadgeSizeInPixels(size)

  const { usedLabels, nbOverflow, setContainer } = useCalculatePrettyLabels({
    values: userIds.map((id) => ({ id })),
    labelWidth,
  })

  const isMoreThanOne = userIds.length > 1

  if (!userIds.length) return null

  return (
    <div
      ref={setContainer}
      className={twMerge(
        'flex flex-row items-center gap-1 w-full',
        !isMoreThanOne && `truncate`
      )}
    >
      {isMoreThanOne ? (
        usedLabels.map((value) => (
          <ConnectedUserBadge key={value.id} userId={value.id} size={size} />
        ))
      ) : (
        <ConnectedUserLabel
          userId={userIds[0]}
          size={size}
          nameOnly={nameOnly}
        />
      )}
      {nbOverflow > 0 && (
        <RoundNumOverflowBadge
          style={{ width: labelWidth, height: labelWidth }}
        >
          +{nbOverflow}
        </RoundNumOverflowBadge>
      )}
    </div>
  )
})
