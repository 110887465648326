import { templateStr } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema, type WorkspaceSchema } from '@motion/zod/client'

import { useI18N } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useBulkUpdateSelection } from './use-bulk-update-selection'

import { useBulkOpsState } from '../../contexts'

export function useBulkMoveTasks() {
  const { bulkUpdateTaskSelection } = useBulkUpdateSelection()
  const { pluralize } = useI18N()
  const { selectedIds } = useBulkOpsState()
  const modalApi = useModalApi()
  const { getWorkspaceById, getWorkspaceProjectById } = useWorkspaceFns()

  return useCallback(
    async (
      workspaceId: WorkspaceSchema['id'],
      projectId: ProjectSchema['id'] | null
    ) => {
      try {
        const workspace = getWorkspaceById(workspaceId)
        const project =
          projectId == null ? null : getWorkspaceProjectById(projectId)

        if (workspace == null || (projectId != null && project == null)) {
          throw new Error('Workspace or Project cannot be found')
        }

        const response = await modalApi.prompt('confirm', {
          analytics: {
            name: 'bulk-move-tasks',
          },
          title: templateStr(
            'Are you sure you want to move {{item}} to {{destination}}?',
            {
              item: pluralize(
                selectedIds.length,
                'this {{count}} task',
                'these {{count}} tasks'
              ),
              destination:
                project != null
                  ? templateStr('{{workspaceName}} / {{projectName}}', {
                      workspaceName: workspace.name,
                      projectName: project.name,
                    })
                  : workspace.name,
            }
          ),
          description: (
            <div>
              <p>Here’s what will happen:</p>
              <ol className='list-inside list-decimal'>
                <li>
                  Status will be changed to To do if the existing status doesn’t
                  exist in the new workspace
                </li>
                <li>
                  Assignee will be set to unassigned if the existing assignee
                  isn’t part of the new workspace
                </li>
                <li>
                  Labels will be removed if they don’t exist in the new
                  workspace
                </li>
                <li>
                  Custom fields that don’t exist in the new workspace will be
                  removed from the task
                </li>
                <li>
                  Tasks moving to a project with a different flow will be placed
                  in the current stage
                </li>
                <li>
                  Blockers will be removed from tasks moving to a different
                  project
                </li>
              </ol>
            </div>
          ),
          confirmButtonText: pluralize(
            selectedIds.length,
            'Move {{count}} task',
            'Move {{count}} tasks'
          ),
          closeButtonText: 'Cancel',
        })

        if (response === ModalDismissed) return undefined

        await bulkUpdateTaskSelection({
          type: 'bulk-location-update',
          workspaceId,
          projectId,
        })
      } catch (e) {
        Sentry.captureException(e)

        showErrorToast(e)
      }
    },
    [
      bulkUpdateTaskSelection,
      getWorkspaceById,
      getWorkspaceProjectById,
      modalApi,
      pluralize,
      selectedIds.length,
    ]
  )
}
