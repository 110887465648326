import { ExpandableModal } from '@motion/ui/base'

import { memo } from 'react'

import { ProjectFormContent } from './components'
import { useProjectForm } from './hooks'
import { TemplateProjectForm } from './template-project-form'

export const TemplateProjectModal = () => {
  return (
    <TemplateProjectForm>
      <InnerForm />
    </TemplateProjectForm>
  )
}

const InnerForm = memo(() => {
  const { actions, form } = useProjectForm()
  const { closeModal } = actions
  const {
    formState: { isDirty },
  } = form

  const child = <ProjectFormContent onClose={closeModal} />

  return (
    <ExpandableModal
      visible
      expanded
      onClose={closeModal}
      disableOverlayClick={isDirty}
    >
      {child}
    </ExpandableModal>
  )
})
InnerForm.displayName = 'InnerForm'
