import { QuestionMarkCircleSolid } from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { ActionList, Button, PopoverTrigger, Tooltip } from '@motion/ui/base'
import {
  getAutoscheduleInfo,
  scheduleTypeToInfo,
  templateStr,
} from '@motion/ui-logic'
import { daysBetweenDates } from '@motion/utils/dates'

import { useSingleTaskActions } from '~/areas/eta/hooks'
import { AutoScheduleTooltip } from '~/areas/tasks/tooltips'
import { StatusBadge } from '~/global/components/badges'
import { useI18N } from '~/global/contexts'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { DateTime } from 'luxon'

type OverdueRowProps = {
  task: TaskSchema
  projectOrStageDeadline: string | null
  parentType: 'project' | 'stage'
  onClose: () => void
}

export const OverdueRow = ({
  task,
  projectOrStageDeadline,
  parentType,
  onClose,
}: OverdueRowProps) => {
  const navigateToModal = useNavigateByGlobalModalId()

  const topActions = useSingleTaskActions(task, ['hardDeadline', 'doASAP'])
  const bottomActions = useSingleTaskActions(task, [
    'complete',
    'cancel',
    'edit',
  ])

  const status = useWorkspaceStatusById(task.statusId)

  return (
    <Row
      onClick={() => navigateToModal('task', { task: task.id })}
      role='button'
    >
      <StatusContainer>
        <StatusBadge
          size='small'
          value={status}
          iconVariant={task.isUnvisitedStage ? 'isUnvisitedStage' : undefined}
        />
      </StatusContainer>

      <RowTextContainer>
        <RowName>{task.name}</RowName>
        <OverdueRowDateText
          task={task}
          projectOrStageDeadline={projectOrStageDeadline}
          parentType={parentType}
          onClose={onClose}
        />
      </RowTextContainer>
      <ButtonContainer>
        <PopoverTrigger
          renderPopover={() => (
            <ActionList
              sections={[
                {
                  items: topActions,
                },
                {
                  items: bottomActions,
                },
              ]}
            />
          )}
        >
          <Button size='small' variant='outlined' sentiment='neutral'>
            Fix
          </Button>
        </PopoverTrigger>
      </ButtonContainer>
    </Row>
  )
}

const OverdueRowDateText = ({
  task,
  projectOrStageDeadline,
  parentType,
}: OverdueRowProps) => {
  const { pluralize } = useI18N()

  const taskScheduledDate =
    task.type === 'NORMAL' ? task.estimatedCompletionTime : task.scheduledEnd

  if (!taskScheduledDate) {
    if (
      task.type !== 'CHUNK' &&
      (task.scheduledStatus === 'UNFIT_PAST_DUE' ||
        task.scheduledStatus === 'UNFIT_SCHEDULABLE')
    ) {
      return <UnfitRowText task={task} />
    }

    return null
  }

  const daysOverdue =
    taskScheduledDate && projectOrStageDeadline
      ? daysBetweenDates(
          DateTime.fromISO(taskScheduledDate),
          DateTime.fromISO(projectOrStageDeadline)
        )
      : 0

  // Only check against parent deadline if it's overdue
  if (daysOverdue > 0) {
    return (
      <RowOverdueText>
        {templateStr('{{days}} {{pluralText}} after {{parentType}} deadline', {
          days: daysOverdue,
          pluralText: pluralize(daysOverdue, 'day', 'days'),
          parentType: parentType,
        })}
      </RowOverdueText>
    )
  }

  // otherwise check against its own deadline
  if (task.dueDate) {
    const daysUntilDeadline = Math.abs(
      daysBetweenDates(
        DateTime.fromISO(taskScheduledDate),
        DateTime.fromISO(task.dueDate)
      )
    )

    if (daysUntilDeadline > 0) {
      return (
        <RowOverdueText>
          {templateStr('{{days}} {{pluralText}} past its deadline', {
            days: daysUntilDeadline,
            pluralText: pluralize(daysUntilDeadline, 'day', 'days'),
          })}
        </RowOverdueText>
      )
    }
  }

  return null
}

export const UnfitRowText = ({ task }: { task: TaskSchema }) => {
  const { type } = getAutoscheduleInfo(
    task,
    task.isAutoScheduled,
    !!task.completedTime
  )

  const scheduleDateInfo = scheduleTypeToInfo[type]

  return (
    <Tooltip
      renderContent={() => (
        <AutoScheduleTooltip info={scheduleDateInfo} task={task} />
      )}
    >
      <RowOverdueText>
        <span>Could not fit</span>
        <QuestionMarkCircleSolid className='size-3.5' />
      </RowOverdueText>
    </Tooltip>
  )
}

const Row = classed(
  'div',
  'max-w-full flex p-0.5 gap-1.5 hover:bg-semantic-neutral-surface-overlay-bg-subtle rounded items-center cursor-pointer'
)

const StatusContainer = classed('div', 'flex mt-0.5 self-start w-auto')

const RowTextContainer = classed(
  'div',
  'flex flex-col gap-0.5 items-start w-auto max-w-[80%]'
)

const RowName = classed(
  'p',
  'text-xs text-semantic-neutral-text-default truncate max-w-full'
)

const RowOverdueText = classed(
  'p',
  'text-2xs text-semantic-neutral-text-subtle flex items-center gap-0.5'
)

const ButtonContainer = classed('div', 'ml-auto self-center w-auto')
