import { FieldButton, type FieldButtonProps } from '@motion/ui/base'

import { ConnectedInvitePlaceholderTeammateButton } from '~/areas/project-management/components/invite-placeholder-teammate-button'
import { ConnectedUserBadge } from '~/global/components/badges'
import { useWorkspaceById, useWorkspaceMember } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { AssigneeDropdown } from '../../../components'
import { useProjectForm } from '../hooks'

export type FormAssigneeDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
>

export const FormAssigneeDropdown = ({
  ...buttonProps
}: FormAssigneeDropdownProps) => {
  const { form } = useProjectForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'managerId',
    control,
  })

  const selectedWorkspaceId = watch('workspaceId')
  const selectedAssigneeId = watch('managerId')

  const selectedWorkspace = useWorkspaceById(selectedWorkspaceId)
  const selectedAssignee = useWorkspaceMember(
    selectedWorkspaceId,
    selectedAssigneeId
  )

  const isDisabled = selectedWorkspace?.isPersonalWorkspace ?? false

  return (
    <div className='flex gap-1 items-center'>
      <div className='flex-1'>
        <AssigneeDropdown
          selectedUserId={selectedAssignee?.userId ?? null}
          onChange={(userId) => {
            field.onChange(userId)
          }}
          workspaceId={selectedWorkspaceId}
        >
          <FieldButton {...buttonProps} disabled={isDisabled}>
            <ConnectedUserBadge userId={selectedAssignee?.user.id ?? null} />
            <div className='inline-grid'>
              <div className='truncate'>
                {selectedAssignee?.user.name ?? 'Unassigned'}
              </div>
            </div>
          </FieldButton>
        </AssigneeDropdown>
      </div>
      <ConnectedInvitePlaceholderTeammateButton
        userId={selectedAssignee?.user.id}
      />
    </div>
  )
}
