import {
  isAutoScheduledStatus,
  isCompletedStatus,
  isSystemStatus,
} from '@motion/shared/common'
import { Button } from '@motion/ui/base'
import { statusColorOptions } from '@motion/ui-logic'
import { type StatusSchema } from '@motion/zod/client'

import { StatusBadge } from '~/global/components/badges'
import { LegacyColorDropdown } from '~/global/components/dropdowns'

import { EditableWorkspaceRow, UneditableStatus } from './common'
import { WorkspaceStatusAutoScheduleCheckbox } from './workspace-status-auto-schedule-checkbox'

type WorkspaceStatusItemProps = {
  status: StatusSchema
  updateTaskStatus: (
    field: string,
    value: string | boolean,
    status: StatusSchema
  ) => void
}

export const WorkspaceStatusItem = ({
  status,
  updateTaskStatus,
}: WorkspaceStatusItemProps) => {
  const isEditable = !isSystemStatus(status)

  return (
    <div className='flex items-center gap-3'>
      {isEditable ? (
        <div className='grow'>
          <EditableWorkspaceRow
            icon={
              <LegacyColorDropdown
                colorOptions={statusColorOptions}
                renderItem={(color, onClose) => (
                  <Button
                    iconOnly
                    key={color}
                    onClick={() => {
                      updateTaskStatus('color', color, status)
                      onClose()
                    }}
                    size='small'
                    variant='muted'
                  >
                    <StatusBadge value={{ ...status, color }} hideTooltip />
                  </Button>
                )}
                trigger={
                  <Button iconOnly variant='outlined' sentiment='neutral'>
                    <StatusBadge value={status} size='large' hideTooltip />
                  </Button>
                }
              />
            }
            name={status.name}
            type='status'
            onChange={(name) => updateTaskStatus('name', name, status)}
          />
        </div>
      ) : (
        <div className='flex items-center gap-3 grow'>
          <UneditableStatus item={status} />
          <span>{status.name}</span>
        </div>
      )}

      <WorkspaceStatusAutoScheduleCheckbox
        value={!isAutoScheduledStatus(status)}
        onChange={(checked) => {
          updateTaskStatus('autoScheduleEnabled', !checked, status)
        }}
        statusName={status.name}
        disabled={!isEditable}
        isResolvedStatus={isCompletedStatus(status)}
      />
      {/* Spacer for non editable items. Put it here for now because otherwise it would be a big refactor of the settings table. */}
      {!isEditable && <div className='w-8 shrink-0' />}
    </div>
  )
}
