import { useTaskCustomFieldUpdater } from '~/areas/tasks/hooks'
import {
  type CustomFieldWithValue,
  type NormalTaskWithRelations,
} from '~/global/proxies'

import {
  FieldComponentColumn,
  FieldContainer,
  FieldNameColumn,
} from './components'
import { CONTROLLED_CUSTOM_FIELD_KANBAN_LOOKUP } from './lookup'

export const TaskCardField = ({
  task,
  fieldId,
}: {
  task: NormalTaskWithRelations
  fieldId: string
}) => {
  const customField = task.customFields[fieldId]
  if (!customField) return null

  return (
    <FieldContainer>
      <FieldNameColumn>{customField.definition.name}</FieldNameColumn>

      <FieldComponentColumn>
        <ConnectedCardTaskField task={task} customField={customField} />
      </FieldComponentColumn>
    </FieldContainer>
  )
}

const ConnectedCardTaskField = ({
  task,
  customField,
}: {
  task: NormalTaskWithRelations
  customField: CustomFieldWithValue
}) => {
  const updateTaskCustomField = useTaskCustomFieldUpdater()
  const { value } = customField

  const onSubmit = async (newValue: typeof value) => {
    await updateTaskCustomField(task, customField.definition, newValue)
  }

  const FieldComponent = CONTROLLED_CUSTOM_FIELD_KANBAN_LOOKUP[customField.type]
  if (FieldComponent == null) return null

  return (
    <FieldComponent
      customField={customField}
      // @ts-expect-error - fine
      value={value ?? null}
      onSubmit={onSubmit}
    />
  )
}
