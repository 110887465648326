import { type ReactNode } from 'react'

import { PageParamsContext } from './context'
import { usePageDataInternal } from './use-v3-page-data-internal'

type WaitForParamsProps = {
  children?: ReactNode
}
export const CollectPageData = (props: WaitForParamsProps) => {
  const data = usePageDataInternal()

  return (
    <PageParamsContext.Provider value={data}>
      {props.children}
    </PageParamsContext.Provider>
  )
}
