import { getDurations } from '@motion/ui-logic'

import { useMemo } from 'react'

import { useTaskForm } from './use-task-form'

export function useDurations() {
  const {
    form: { watch },
  } = useTaskForm()

  const type = watch('type')
  const isRecurring = type === 'RECURRING_TASK' || type === 'RECURRING_INSTANCE'
  return useMemo(
    () => getDurations({ includeNone: !isRecurring }),
    [isRecurring]
  )
}
