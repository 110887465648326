import {
  dereferenceToken,
  getBaseHeaders,
  type RpcContextType,
  useRpcContext,
} from '@motion/rpc'

import { parseResponse } from '~/services/apiUtils'
import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

export const XHR_READY_STATE_DONE = 4

export type UploadFileParams = {
  method: string
  uri: string
  data: FormData
  options?: {
    headers?: Headers
    onUpload?: (request: XMLHttpRequest) => void
    onProgress?: (progress: ProgressEvent<XMLHttpRequestEventTarget>) => void
  }
}

async function getHeaders(reqId: string, rpcContext: RpcContextType) {
  const token = await dereferenceToken(rpcContext.token)

  return new Headers({
    ...getBaseHeaders({ token, reqId }),
    ...(rpcContext.headers ?? {}),
  })
}

export function useFileUpload() {
  const rpcContext = useRpcContext()

  const uploadFile = useCallback(
    async <R>({ method, uri, data, options }: UploadFileParams): Promise<R> => {
      const reqId = uuidv4()

      const headers = await getHeaders(reqId, rpcContext)

      const xhr = new XMLHttpRequest()

      const response = await new Promise<Response>(function (resolve, reject) {
        xhr.responseType = 'blob'
        xhr.onreadystatechange = function () {
          // If readyState !== DONE, do nothing
          if (xhr.readyState !== XHR_READY_STATE_DONE) {
            return
          }

          const response = new Response(xhr.response, {
            status: xhr.status,
            statusText: xhr.statusText,
          })

          resolve(response)
        }

        // Add error event listener
        xhr.addEventListener('error', () => {
          reject(new TypeError('Failed to fetch'))
        })

        // Add upload progress event listener if provided
        if (options?.onProgress) {
          xhr.upload.addEventListener('progress', options.onProgress)
        }

        if (options?.onUpload) {
          options.onUpload(xhr)
        }

        xhr.open(method, rpcContext.baseUri + uri, true)

        // Set headers
        for (const [key, value] of headers.entries()) {
          xhr.setRequestHeader(key, value)
        }

        xhr.send(data)
      })

      return parseResponse(response, {
        method,
        host: rpcContext.baseUri,
        path: uri,
      })
    },
    [rpcContext]
  )

  return {
    uploadFile,
  }
}
