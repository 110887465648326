import { classed } from '@motion/theme'
import { type FilterTarget, useActiveFilter } from '@motion/ui-logic/pm/data'

import { Fragment, type ReactNode, useState } from 'react'

import { AddFilterButton } from './add-filter-button'
import { type FilterDefinition } from './types'

type FilterBarProps<T extends FilterTarget> = {
  definitions: FilterDefinition[]
  applyTo: T
  children?: ReactNode
}

export const ConnectedLegacyFilterButtons = <T extends FilterTarget>(
  props: FilterBarProps<T>
) => {
  const [state] = useActiveFilter()
  const [currentlyOpen, setOpen] = useState<FilterDefinition | null>(null)

  function onSelect(def: FilterDefinition) {
    setOpen(def)
  }

  const order = [
    state.workspaces.filters.ids ? 'workspaces' : [],
    state.projects.filters.ids ? 'projects' : [],
    state.tasks.ordered,
  ].flat()

  const currentFilters = order
    .filter((key) => (currentlyOpen?.key ?? '') !== key)
    .map((key) => ({
      key,
      value:
        key === 'workspaces'
          ? state.workspaces.filters.ids
          : key === 'projects'
            ? state.projects.filters.ids
            : // @ts-expect-error - check is fine
              state.tasks.filters[key],
    }))
    .filter((def) => def.value != null)
    .map((def) => props.definitions.find((x) => x.key === def.key))
    .filter(Boolean)

  const available = props.definitions.filter(
    (d) => !currentFilters.some((x) => x.key === d.key)
  )

  return (
    <>
      {currentFilters.map((def) => (
        <Fragment key={def.key}>
          {def.renderDropdown({ target: props.applyTo })}
        </Fragment>
      ))}
      {currentlyOpen ? (
        <Fragment key={currentlyOpen.key}>
          {currentlyOpen.renderDropdown({
            openOnMount: true,
            onBlur: () => setOpen(null),
            target: props.applyTo,
          })}
        </Fragment>
      ) : (
        <AddFilterButton
          definitions={available}
          onSelect={onSelect}
          variant={currentFilters.length === 0 ? 'initial' : 'additional'}
        />
      )}
    </>
  )
}

export const FilterBar = classed('div', {
  base: `
    flex gap-2    
    items-center
    flex-wrap
    relative
  `,
})
