import { ClockSolid } from '@motion/icons'
import { FieldButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import {
  createDurationsFromText,
  formatToTaskDuration,
  getDurations,
} from '@motion/ui-logic/pm'

import { ModalFieldButton } from '~/areas/task-project/components'

export type DurationProps = {
  value: number | null
  onChange: (value: number | null) => void
}

export const DurationField = ({ value, onChange }: DurationProps) => {
  return (
    <ModalFieldButton label='Duration'>
      <div className='flex items-center gap-[3px]'>
        <ClockSolid />
        <DurationPopover value={value} onChange={onChange} />
      </div>
    </ModalFieldButton>
  )
}

function DurationPopover({ value, onChange }: DurationProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <DurationPopoverContent
          value={value}
          onChange={onChange}
          close={close}
        />
      )}
    >
      <FieldButton iconOnly variant='muted' size='small'>
        <span className='whitespace-nowrap'>{formatToTaskDuration(value)}</span>
      </FieldButton>
    </PopoverTrigger>
  )
}

function DurationPopoverContent({
  value,
  onChange,
  close,
}: DurationProps & {
  close: () => void
}) {
  const durations = getDurations()

  return (
    <SearchableList
      items={durations}
      computeKey={(item) => String(item.value)}
      computeSearchValue={(item) => item.label}
      computeSelected={(item) => item.value === value}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
      filter={(search) => {
        if (search) {
          return createDurationsFromText(search)
        }
        return durations
      }}
      inputProps={{ placeholder: 'Choose or type a duration' }}
    />
  )
}
