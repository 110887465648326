import {
  Button,
  ButtonGroup,
  type ButtonProps,
  DatePicker,
  type DatePickerProps,
  PopoverTrigger,
  type PopoverTriggerProps,
} from '@motion/ui/base'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'

import { DateTime } from 'luxon'
import { type ReactNode } from 'react'

export type SimpleDateDropdownProps = {
  value: string | null
  onChange(value: string | null): void
  calendarStartDay?: CalendarStartDay
  disabledDate?: DatePickerProps['disabledDate']
  subActions?: {
    content: ButtonProps['children']
    onAction: ButtonProps['onClick']
  }[]
  placement?: PopoverTriggerProps['placement']
  children?: ReactNode
}

export const SimpleDateDropdown = (props: SimpleDateDropdownProps) => {
  const {
    value,
    onChange,
    calendarStartDay = 'sunday',
    disabledDate = (date) => date < DateTime.now().startOf('day'),
    children,
    subActions = [],
    placement = 'bottom-start',
  } = props

  return (
    <PopoverTrigger
      placement={placement}
      renderPopover={({ close }) => (
        <div className='p-3'>
          <DatePicker
            value={value}
            weekStartDay={calendarStartDay}
            mode='single'
            onChange={(v) => {
              if (!v || Array.isArray(v)) return

              onChange(v)
              close()
            }}
            disabledDate={disabledDate}
          />
          {subActions.length > 0 && (
            <div className='flex justify-center'>
              <ButtonGroup>
                {subActions.map((action, i) => (
                  <Button
                    key={i}
                    sentiment='neutral'
                    variant='muted'
                    onClick={(e) => {
                      action.onAction?.(e)
                      close()
                    }}
                  >
                    {action.content}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          )}
        </div>
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
