import { PlusSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

import { useWorkspacesTreeviewContext } from '../hooks'

type SidebarTitleProps = {
  onAddWorkspace: () => void
  className?: string
}

export const SidebarTitle = ({
  onAddWorkspace,
  className,
}: SidebarTitleProps) => {
  const { items } = useWorkspacesTreeviewContext()

  return (
    <div className={twMerge('flex items-center justify-between', className)}>
      <div className='text-sidebar-title text-xs font-medium'>
        Workspaces ({items.length})
      </div>

      <div>
        <Button
          size='xsmall'
          variant='outlined'
          sentiment='neutral'
          iconOnly
          onClick={onAddWorkspace}
        >
          <PlusSolid />
        </Button>
      </div>
    </div>
  )
}
