import { FilterDateButton } from './filter-date-button'

import { useFieldFilter } from '../../context'
import { type DropdownFilterProps } from '../types'

export const ConnectedCreatedTimeFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(props.target, 'createdTime')

  return (
    <FilterDateButton
      label='Created'
      value={value}
      onChange={setValue}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      target={props.target}
    />
  )
}
