import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useModalStatus } from '@motion/web-common/modals'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useCreateAiView } from '~/global/rpc'
import { useState } from 'react'

declare module '@motion/web-common/modals/definitions' {
  export interface ModalDefinitions {
    'ai-view-generation-modal': {
      selectView(view: VersionedViewV2): void
    }
  }
}

export interface AiViewGenerationModalProps {
  onDismiss: () => void
  selectView(view: VersionedViewV2): void
}

const AiViewGenerationModal = ({
  onDismiss,
  selectView,
}: AiViewGenerationModalProps) => {
  const [modalInput, setModalInput] = useState('')
  const { mutateAsync: createView } = useCreateAiView()

  const onSubmitPrompt = async () => {
    const view = await createView({ message: modalInput })
    selectView(view.models.views[view.id])
    onDismiss()
  }

  return (
    <FormModal
      title='Generate view (beta)'
      visible
      onClose={onDismiss}
      submitAction={{
        onAction: onSubmitPrompt,
        text: 'Generate',
      }}
    >
      <div className='text-semantic-neutral-text-subtle text-sm leading-5 mb-2'>
        Describe the view or report you want to see and Motion will
        automatically generate a view for you.
      </div>
      <p className='text-semantic-neutral-text-subtle text-2xs font-bold mb-2'>
        Prompt
      </p>
      <TextField
        multiline
        placeholder='For example: “Create a timesheet for Rodrigo, Omid and Maddy”, or “Show all past due tasks in the next two weeks”'
        labelHidden
        autoSize={{ minRows: 3 }}
        value={modalInput}
        autoFocus
        onChange={(value) => setModalInput(value)}
      />
    </FormModal>
  )
}

export const ConnectedAiViewGenerationModal = () => {
  const status = useModalStatus('ai-view-generation-modal')

  if (!status.visible) {
    return null
  }

  return (
    <AiViewGenerationModal
      {...status.props}
      onDismiss={() => {
        status.close()
      }}
    />
  )
}
