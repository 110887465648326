import {
  type FlowProject,
  isEnabledStage,
  isStageCanceled,
  isStageCompleted,
} from '@motion/ui-logic/pm/project'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

export function useSkipStagesConfirmModalFn() {
  const modalApi = useModalApi()
  const { getStageDefinition } = useWorkspaceFns()

  return useCallback(
    async (
      project: FlowProject,
      newStageDefinitionId: StageDefinitionSchema['id']
    ) => {
      const { currentStageIndex, newStageIndex, allProjectStages } =
        findStageIndices(project, newStageDefinitionId)

      // No confirmation needed when moving backwards or staying on same stage
      if (currentStageIndex >= newStageIndex) {
        return 'NOOP'
      }

      // Get all stages between current and target stage (inclusive of target)
      const stagesBeingSkipped = allProjectStages.slice(
        currentStageIndex + 1,
        newStageIndex + 1
      )

      // Check if any stages being skipped are unvisited and not completed/canceled
      const hasUncompletedStages = stagesBeingSkipped.some(
        (stage) =>
          !stage.visited && !isStageCanceled(stage) && !isStageCompleted(stage)
      )

      // No confirmation needed if all stages are already visited/completed/canceled
      if (!hasUncompletedStages) {
        return 'NOOP'
      }

      // Check if target stage is the next enabled stage
      const enabledStagesBeingSkipped =
        stagesBeingSkipped.filter(isEnabledStage)
      const isMovingToNextEnabledStage =
        enabledStagesBeingSkipped.length > 0 &&
        enabledStagesBeingSkipped[0].stageDefinitionId === newStageDefinitionId

      const response = await modalApi.prompt('skip-stages-modal', {
        currentStageDefinition: getStageDefinition(
          project.activeStageDefinitionId
        ),
        newStageDefinition: getStageDefinition(newStageDefinitionId),
        isNextEnabledStage: isMovingToNextEnabledStage,
      })

      return response === ModalDismissed ? false : response
    },
    [getStageDefinition, modalApi]
  )
}

// Helper function to find indices of current and target stages
const findStageIndices = (
  project: FlowProject,
  newStageDefinitionId: string
) => {
  const allProjectStages = project.stages
  const currentStageIndex = allProjectStages.findIndex(
    (s) => s.stageDefinitionId === project.activeStageDefinitionId
  )
  const newStageIndex = allProjectStages.findIndex(
    (s) => s.stageDefinitionId === newStageDefinitionId
  )

  if (currentStageIndex < 0 || newStageIndex < 0) {
    throw new Error('Stage definitions not found', {
      cause: {
        allProjectStages: JSON.stringify(allProjectStages, null, 2),
        currentStageId: project.activeStageDefinitionId,
        newStageId: newStageDefinitionId,
      },
    })
  }

  return { currentStageIndex, newStageIndex, allProjectStages }
}
