import { useSetting } from '@motion/web-common/settings'

/**
 * Returns the start day (`monday` or `sunday`) being used for the calendar
 * @returns CalendarStartDay `monday` | `sunday`
 */
export const useCalendarStartDay = () => {
  const [calendarStartDay] = useSetting('calendarStartDay', 'sunday')
  return calendarStartDay
}
