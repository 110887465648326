import { ExternalLinkSolid, FilledChevronDownSolid } from '@motion/icons'
import { TaskType } from '@motion/rpc/types'
import { type COLOR } from '@motion/shared/common'
import { classed } from '@motion/theme'
import { IconButton } from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'

import { ConnectedTaskEtaBadge } from '~/areas/eta'
import {
  ProjectDropdown,
  type ProjectDropdownProps,
} from '~/areas/project-management/components'
import { useTaskProjectUpdater } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'
import { useNavigate } from 'react-router-dom'

type ProjectHeaderProps = {
  task: TaskWithRelations
}

export const ProjectField = (props: ProjectHeaderProps) => {
  const navigate = useNavigate()
  const { task } = props

  const updateTaskProject = useTaskProjectUpdater()
  const onChange: ProjectDropdownProps['onChange'] = (projectId) => {
    updateTaskProject(task, projectId)
  }

  const disabled = task.type === TaskType.RECURRING_INSTANCE

  return (
    <Container>
      <ProjectDropdown
        onChange={onChange}
        selectedProjectId={task.projectId ?? null}
        workspaceId={task.workspaceId}
      >
        <Trigger disabled={disabled}>
          <ProjectColoredIcon
            className='w-3.5 h-3.5'
            color={task.project?.color as COLOR}
            noProject={!task.project}
          />
          <span className='truncate'>
            {task.project ? task.project.name : 'No project'}
          </span>
          {!disabled && (
            <FilledChevronDownSolid className='w-3.5 text-semantic-neutral-icon-subtle opacity-0 group-hover:opacity-100' />
          )}
        </Trigger>
      </ProjectDropdown>

      {task.projectId && (
        <div className='opacity-0 group-hover:opacity-100 flex items-center'>
          <IconButton
            variant='muted'
            sentiment='neutral'
            size='xsmall'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate(
                `/web/pm/workspaces/${task.workspaceId}/projects/${task.projectId}`
              )
            }}
            icon={ExternalLinkSolid}
          />
        </div>
      )}
      <ConnectedTaskEtaBadge task={task} />
    </Container>
  )
}

const Container = classed('div', {
  base: `
    h-6 flex items-center bg-semantic-neutral-bg-disabled
    group px-2
  `,
})

const Trigger = classed('button', {
  base: `
    h-6 min-w-0 flex flex-grow
    items-center text-[11px]
    text-semantic-neutral-text-disabled gap-1
    enabled:hover:text-semantic-neutral-text-subtle`,
})
