import { Checkbox, type CheckboxProps } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type HeaderContext } from '@tanstack/react-table'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { type GroupedNode } from '../../../grouping'
import { type TreeListRowValueType } from '../../types'

type NameHeaderProps = {
  info: HeaderContext<GroupedNode<TreeListRowValueType>, unknown>
}

export function NameHeader({ info }: NameHeaderProps) {
  const { table, column } = info

  const canSomeRowsBeSelected = useMemo(
    () => table.getRowModel().flatRows.some((row) => row.getCanSelect()),
    [table]
  )

  const checked: CheckboxProps['checked'] = table.getIsAllRowsSelected()
  const onChange: CheckboxProps['onChange'] = (value) => {
    recordAnalyticsEvent('BULK_OPS_CHECKBOX_CLICK', {
      value,
      target: 'header',
    })
    table.toggleAllRowsSelected(value)
  }

  return (
    <div className='flex gap-1 items-center pl-2.5'>
      {canSomeRowsBeSelected && (
        <div
          className={twMerge(
            'm-auto invisible group-hover/table-row:visible',
            checked && 'visible'
          )}
        >
          <Checkbox
            label='Select all rows'
            labelHidden
            checked={checked}
            onChange={onChange}
          />
        </div>
      )}
      <span>{column.columnDef.meta?.name ?? 'unknown'}</span>
    </div>
  )
}
