import { classed } from '@motion/theme'
import {
  formatToReadableWeekDayMonth,
  formatToRelativeDay,
  templateStr,
} from '@motion/ui-logic'

import { DateTime } from 'luxon'

export function AgendaDayHeader({ date }: { date: DateTime }) {
  const { relativeDay, fullDate } = getDayHeaderLabels(date)

  return (
    <DayHeaderContainer>
      <DayHeader>
        {templateStr('{{relativeDay}} {{fullDate}}', { relativeDay, fullDate })}
      </DayHeader>
    </DayHeaderContainer>
  )
}

function getDayHeaderLabels(date: DateTime) {
  const isToday = date.hasSame(DateTime.now(), 'day')
  const isTomorrow = date.hasSame(DateTime.now().plus({ days: 1 }), 'day')

  const fullDate =
    isToday || isTomorrow
      ? formatToReadableWeekDayMonth(date)
      : date.toFormat('LLL d')

  const relativeDay = (
    <DayMainText>{formatToRelativeDay(date, true)}</DayMainText>
  )

  return { relativeDay, fullDate }
}

const DayHeaderContainer = classed('div', {
  base: 'flex flex-row w-full items-start self-stretch gap-2 p-2',
})

const DayHeader = classed('div', {
  base: 'text-xl',
})

const DayMainText = classed('span', {
  base: 'font-bold',
})
