import { UnstyledModal } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  EnterpriseBillingPlanCard,
  IndividualBillingPlanCard,
  TeamBillingPlanCard,
} from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalApi } from '@motion/web-common/modals'

import type { ModalTriggerComponentProps } from '~/areas/modals'
import { useCurrentTeam, useGetTeamPrices } from '~/global/rpc/team'

type ChoosePlanModalProps = ModalTriggerComponentProps<'choose-plan-modal'>

export function ChoosePlanModal({}: ChoosePlanModalProps) {
  const modalApi = useModalApi()
  const user = useAuthenticatedUser()
  const { data: team } = useCurrentTeam()
  const { data: teamPrices } = useGetTeamPrices()

  function onSelectPlan(teamSelected: boolean) {
    if (teamSelected) {
      recordAnalyticsEvent('CHOOSE_PLAN_MODAL_SELECT_TEAM')
    } else {
      recordAnalyticsEvent('CHOOSE_PLAN_MODAL_SELECT_INDIVIDUAL')
    }

    return () => {
      modalApi.dismiss('choose-plan-modal')
      modalApi.open('start-billing-modal', { teamSelected })
    }
  }

  return (
    <UnstyledModal
      withAnimation
      overlayClassName='bg-modal-overlay'
      visible
      onClose={() => {
        modalApi.dismiss()
      }}
    >
      <div className='p-4'>
        <div className='flex flex-col bg-modal-bg w-[95vw] max-w-[1364px] max-h-[704px] p-8 rounded-lg'>
          <h1 className='text-2xl text-semantic-neutral-text-default font-semibold pb-8'>
            Choose the plan that best fits your needs
          </h1>
          <div className='flex sm:flex-row m-auto gap-4 items-center sm:items-stretch'>
            {!team && (
              <IndividualBillingPlanCard
                onClick={onSelectPlan(false)}
                hidePrice
                withBorder
                buttonText='Continue as Individual'
              />
            )}
            <TeamBillingPlanCard
              onClick={onSelectPlan(true)}
              hidePrice
              withBorder
              savings={teamPrices?.individualToTeamSavingsPercent}
              buttonText='Continue as Team'
            />
            <EnterpriseBillingPlanCard
              location='choosePlanModal'
              email={user.email}
              displayName={user.displayName ?? undefined}
              withBorder
              hidePrice
            />
          </div>
        </div>
      </div>
    </UnstyledModal>
  )
}
