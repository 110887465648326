import { useTaskPriorityData } from '~/areas/tasks/hooks'
import { PriorityBadge } from '~/global/components/badges'
import { PriorityDropdown } from '~/global/components/dropdowns'

import { RowButton } from './common'
import { type WithTaskProps } from './types'

export const PriorityButton = (props: WithTaskProps) => {
  const {
    onChange: onChangePriority,
    priorities,
    disabled,
    hasValidData,
  } = useTaskPriorityData(props.task)

  if (!hasValidData) return null

  return (
    <PriorityDropdown
      items={priorities}
      selectedItem={props.task.priorityLevel}
      onChange={onChangePriority}
    >
      <RowButton disabled={disabled}>
        <PriorityBadge value={props.task.priorityLevel} size='small' />
      </RowButton>
    </PriorityDropdown>
  )
}
