import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TaskActionListActionName } from '@motion/web-base/analytics/events'

export function wrapWithAnalytics(actionName: TaskActionListActionName) {
  return (fn?: () => void) => () => {
    recordAnalyticsEvent('TASK_ACTION_LIST_CLICKED', {
      action: actionName,
    })

    fn?.()
  }
}
