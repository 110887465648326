import { TrashSolid, UserSolid } from '@motion/icons'
import { Button, IconButton } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { getColorHueValue, PaletteProvider } from '@motion/ui/palette'

import { ColorDropdown } from '~/global/components/dropdowns'
import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { useRegisterFieldArray } from '~/hooks'

import { useFlowTemplateForm } from '../hooks'
import { findFirstDuplicateName } from '../utils'

type Props = {
  namePath: `roles.${number}.name`
  colorPath: `roles.${number}.color`
  onRemove: () => void
  addNewRole: () => void
  index: number
}

export const RoleField = ({
  namePath,
  colorPath,
  onRemove,
  addNewRole,
  index,
}: Props) => {
  const {
    form: { register, watch, getValues, clearErrors },
  } = useFlowTemplateForm()

  const { onChange, ...registerProps } = useRegisterFieldArray(
    namePath,
    register,
    {
      validate: () => {
        clearErrors(namePath)
        const roles = getValues('roles')
        const value = getValues(namePath)

        const errorMessage = checkTextRuleErrors(
          value,
          getTextFieldRules('Role name')
        )

        if (errorMessage) {
          return errorMessage
        }

        const duplicateName = findFirstDuplicateName(roles)
        if (duplicateName) {
          return `More than one role named "${duplicateName.name}"`
        }
      },
    }
  )

  const { onChange: onColorChange } = useRegisterFieldArray(colorPath, register)
  const color = watch(colorPath)

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addNewRole()
      e.preventDefault()
    }
  }

  return (
    <div className='flex flex-row items-center gap-2 grow'>
      <ColorDropdown
        selectedColor={color}
        onChange={(colorOption) => onColorChange(colorOption)}
      >
        <Button variant='outlined' sentiment='neutral' size='small' iconOnly>
          <PaletteProvider colorHue={getColorHueValue(color)}>
            <UserSolid className='!text-palette-highlight-default' />
          </PaletteProvider>
        </Button>
      </ColorDropdown>

      <TextField
        fullWidth
        placeholder={`Role ${index + 1}`}
        label='Roles'
        labelHidden
        size='small'
        variant='default'
        onChange={onChange}
        onKeyDown={onKeyDown}
        {...registerProps}
      />

      <IconButton
        icon={TrashSolid}
        variant='muted'
        sentiment='neutral'
        size='small'
        onClick={onRemove}
      />
    </div>
  )
}
