import { useMemoDeep } from '@motion/react-core/hooks'

import { type Table } from '@tanstack/react-table'
import { useMemo } from 'react'

export const useTableGridTemplateColumns = (table: Table<any>) => {
  const headers = table.getLeafHeaders()

  const sizing = useMemoDeep(
    headers.map((x) => ({ id: x.id, size: x.getSize() }))
  )

  return useMemo(() => {
    if (sizing.length === 0) return ''
    const first = sizing[0]
    const parts = [`[${first.id}-start] ${first.size}px`]

    for (let i = 1; i < sizing.length; i++) {
      const cur = sizing[i]
      const prev = sizing[i - 1]

      const labels = [`${prev.id}-end`]
      if (i === 1) {
        labels.push('data-start')
      }
      labels.push(`${cur.id}-start`)

      parts.push(`[${labels.join(' ')}]`)
      parts.push(`${cur.size}px`)
    }
    const last = sizing[sizing.length - 1]
    parts.push(`[${last.id}-end data-end]`)
    return parts.join(' ')
  }, [sizing])
}
