import {
  SharedStateProvider,
  type StateKey,
  useSharedStateContext,
} from '@motion/react-core/shared-state'
import { showToast } from '@motion/ui/base'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { DB, SharedStatePersister } from '@motion/web-common/storage'

import { ModalTrigger } from '~/areas/modals'
import { LoadingPage } from '~/areas/project-management/pages/pm-revamp/common/loading-page'
import {
  usePageData,
  useValidRoute,
} from '~/areas/project-management/pages/pm-v3/routes'
import { type PageParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { ConnectedPageContext } from '~/areas/project-management/pages/pm-v3/shell/connected-page-context'
import { ViewStateKey } from '~/areas/project-management/pages/pm-v3/view-state'
import {
  ConnectedCreateViewModal,
  ConnectedSaveViewModal,
} from '~/areas/project-management/pages/pm-v3/views/modals'
import { ErrorPage } from '~/global/components/error-page'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'

const PERSIST_KEYS = [ActiveFilterKey, ViewStateKey]

const persistKeysFilter = (key: StateKey<any>) => PERSIST_KEYS.includes(key)

export const ShellWithContext = () => {
  const route = usePageData()
  const routeValidation = useValidRoute()
  const navigate = useNavigate()

  useEffect(() => {
    if (routeValidation != null) {
      showToast('error', 'The route is invalid. Navigating to the right page.')
      navigate(routeValidation, {
        replace: true,
      })
    }
  }, [navigate, routeValidation])

  if (routeValidation != null) {
    // Rendering loading while it navigates
    return <LoadingPage />
  }

  return (
    <ErrorPage>
      <SharedStateProvider
        name='filter'
        initialValues={route.state}
        batchTime={0}
      >
        <SharedStatePersister
          key={route.stateKey}
          prefix={route.stateKey}
          store={DB.state}
          filter={persistKeysFilter}
        />
        <SyncRoute route={route} />

        <ConnectedPageContext name='shell'>
          <Outlet />

          <ModalTrigger
            name='save-view-v2'
            component={ConnectedSaveViewModal}
          />
          <ModalTrigger
            name='create-view-v2'
            component={ConnectedCreateViewModal}
          />
        </ConnectedPageContext>
      </SharedStateProvider>
    </ErrorPage>
  )
}

type SetRouteParamsProps = {
  route: PageParams
}

function SyncRoute(props: SetRouteParamsProps) {
  const ctx = useSharedStateContext()
  useEffect(() => {
    props.route.state.forEach((value, key) => {
      ctx.set(key, value)
    })
    // We only want to reset these values when the route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, props.route.stateKey])
  return null
}
