import { type ClientFirebaseSettingsDto } from '@motion/rpc-types'
import { pick } from '@motion/utils/core'
import { makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { firebase } from '@motion/web-common/firebase'
import {
  KEYS_TO_PUSH_TO_FIREBASE,
  settingsSyncPullKeys,
  useFirestoreSettings,
} from '@motion/web-common/settings'

import { useSyncFirebaseCalendarState } from '~/areas/calendar/hooks'
import api from '~/chromeApi/chromeApiBackground'
import { type StorageChangeResult } from '~/chromeApi/chromeApiTypes'
import { type LocalStorageSetOptions } from '~/chromeApi/shared'
import { type MotionLocalStorageItems } from '~/localServices/storage/types'
import { onReceivedNewSettings } from '~/syncUtils'
import { useEffect } from 'react'

const log = makeLog('firestore-settings-sync')

function shouldSyncKey(key: string): key is keyof ClientFirebaseSettingsDto {
  return KEYS_TO_PUSH_TO_FIREBASE.includes(key)
}

export const SyncMotionLocalStorageToFirebase = () => {
  const { data } = useFirestoreSettings()
  const user = useAuthenticatedUser()
  const updateFirebaseCalendarSettings = useSyncFirebaseCalendarState()

  useEffect(() => {
    if (data == null) return
    log.info('got updated settings from firestore', data)

    const syncData = pick(
      data,
      settingsSyncPullKeys
    ) as unknown as MotionLocalStorageItems
    syncData.$loaded = true
    api.storage.local.set(syncData, { source: 'firestore' })

    onReceivedNewSettings(syncData)
    updateFirebaseCalendarSettings(syncData)
  }, [data, updateFirebaseCalendarSettings])

  useEffect(() => {
    async function onChange(
      changeData: StorageChangeResult,
      options: LocalStorageSetOptions
    ) {
      log('local storage changed', changeData, options)

      if (options.source === 'firestore') {
        log('changes were from firestore. skipping replication')
        return
      }

      const settings: Partial<ClientFirebaseSettingsDto> = {}
      for (const key in changeData) {
        if (shouldSyncKey(key)) {
          settings[key] = changeData[key].newValue
        }
      }
      if (!Object.keys(settings).length) return

      try {
        log('saving settings', settings)
        await firebase
          .firestore()
          .collection<ClientFirebaseSettingsDto>('settings')
          .doc(user.uid)
          .set(settings, { merge: true })
      } catch (error) {
        log('pushSettings error', error, settings)
        Sentry.captureException(error, { tags: { position: 'pushSettings' } })
      }
    }

    return api.storage.onChanged.addListener(onChange)
  }, [user.uid])

  return null
}
