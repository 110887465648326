import type { PropsWithChildren } from 'react'

type SectionRowProps = PropsWithChildren<{
  title: string
  subtitle?: string
}>

export const SectionRow = ({ title, subtitle, children }: SectionRowProps) => {
  return (
    <div className='p-5 grid grid-cols-2 gap-x-8'>
      <div className='space-y-2'>
        <h4 className='text-base font-semibold'>{title}</h4>

        {subtitle && (
          <p className='text-sm text-semantic-neutral-text-subtle'>
            {subtitle}
          </p>
        )}
      </div>

      <div>{children}</div>
    </div>
  )
}
