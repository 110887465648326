import { Checkbox } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type PastDueRow } from '~/@types/analytics'
import { type TaskWithRelations } from '~/global/proxies'
import { useCallback, useMemo } from 'react'

import {
  useSelectedTasksById,
  useSendPastDueState,
  useTasksLoadingState,
} from '../hooks'

type RowCheckboxType = {
  tasks: TaskWithRelations[]
  type?: PastDueRow
}

export const RowCheckbox = (props: RowCheckboxType) => {
  const { tasks, type = 'NORMAL' } = props
  const setPastDueState = useSendPastDueState()
  const selectedTasksById = useSelectedTasksById()
  const [isLoading] = useTasksLoadingState(tasks)

  const areAllTasksSelected = useMemo(() => {
    // Check each task and see if its currently selected
    return tasks.every((task) => selectedTasksById[task.id])
  }, [tasks, selectedTasksById])

  const onCheckboxChange = useCallback(() => {
    if (areAllTasksSelected) {
      recordAnalyticsEvent('PAST_DUE_MODAL_CHECK_ROW', {
        checked: false,
        type,
      })
      // Set the selected tasks to the tasks passed in
      return setPastDueState((state) => ({
        ...state,
        selectedTasks: state.selectedTasks.filter(
          (task) => !tasks.map((t) => t.id).includes(task.id)
        ),
      }))
    }

    recordAnalyticsEvent('PAST_DUE_MODAL_CHECK_ROW', {
      checked: true,
      type,
    })

    setPastDueState((state) => ({
      ...state,
      selectedTasks: [
        ...state.selectedTasks,
        ...tasks.filter((task) => !state.selectedTasks.includes(task)),
      ],
    }))
  }, [areAllTasksSelected, setPastDueState, tasks, type])

  return (
    <div className='-mr-1 -mt-[3px]'>
      <Checkbox
        disabled={isLoading}
        checked={areAllTasksSelected}
        onChange={onCheckboxChange}
      />
    </div>
  )
}
