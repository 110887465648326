import {
  type LabelSchema,
  type NormalTaskSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useTaskLabelsData } from '~/areas/tasks/hooks'
import { LabelDropdown } from '~/global/components/dropdowns'
import { PrettyLabels } from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type ReactNode } from 'react'

import { CellAction } from './components'

export type TaskLabelsCellProps = {
  task: TaskWithRelations
}
export const TaskLabelsCell = ({ task }: TaskLabelsCellProps) => {
  const { onChange, disabled } = useTaskLabelsData(task)

  if (task.type === 'CHUNK') return null

  return (
    <LabelsCell
      value={task.labelIds}
      workspaceId={task.workspaceId}
      onChange={onChange}
      disabled={disabled}
    >
      {task.labels.length === 0 ? (
        <span className='text-semantic-neutral-text-subtle'>None</span>
      ) : (
        <PrettyLabels values={task.labels} />
      )}
    </LabelsCell>
  )
}

export type ProjectLabelsCellProps = {
  project: ProjectWithRelations
}
export const ProjectLabelsCell = ({ project }: ProjectLabelsCellProps) => {
  const updateProject = useProjectUpdater()

  const onChange: LabelsCell['onChange'] = (labelIds) => {
    void updateProject(project, { labelIds })
  }

  return (
    <LabelsCell
      value={project.labelIds}
      workspaceId={project.workspaceId}
      onChange={onChange}
    >
      {project.labelIds.length === 0 ? (
        <span className='text-semantic-neutral-text-subtle'>None</span>
      ) : (
        <PrettyLabels values={project.labels} />
      )}
    </LabelsCell>
  )
}

type LabelsCell = {
  value: LabelSchema['id'][]
  workspaceId: WorkspaceSchema['id']
  onChange: (labelIds: NormalTaskSchema['labelIds']) => void
  disabled?: boolean
  children: ReactNode
}
const LabelsCell = ({
  value,
  workspaceId,
  onChange,
  disabled = false,
  children,
}: LabelsCell) => {
  const button = <CellAction disabled={disabled}>{children}</CellAction>

  if (disabled) return button

  return (
    <LabelDropdown
      selectedLabelIds={value}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      {button}
    </LabelDropdown>
  )
}
