import type {
  AllAvailableCustomFieldSchema,
  CustomFieldSchemaByType,
} from '@motion/ui-logic'
import {
  availableFilterFields,
  type EntityType,
} from '@motion/ui-logic/pm/data'
import { entries } from '@motion/utils/object'

import type {
  DropdownFilterProps,
  FilterDefinition,
} from '~/areas/project-management/filters'
import {
  ConnectedCustomFieldDateFilter,
  ConnectedCustomFieldNumberFilter,
  ConnectedCustomFieldPersonFilter,
  ConnectedCustomFieldSelectFilter,
  ConnectedCustomFieldTextFilter,
  type TextFilterAllowedFields,
} from '~/areas/project-management/filters/components/v2'

import { useAvailableCustomFieldsGroupedByTypedName } from '../../grouping/custom-fields'

export const useCustomFieldFilterDefinitions = <
  TEntity extends Exclude<EntityType, 'workspaces'>,
>(): FilterDefinition<TEntity>[] => {
  const availableCustomFields = useAvailableCustomFieldsGroupedByTypedName()

  return entries(availableCustomFields)
    .map(([typeName, fields]) =>
      availableFilterFields.includes(fields[0].type)
        ? ({
            key: typeName,
            label: fields[0].name,
            renderDropdown: filterDropdownSwitch<TEntity>(fields),
          } satisfies FilterDefinition<TEntity>)
        : null
    )
    .filter(Boolean)
}
const filterDropdownSwitch =
  <
    TEntity extends Exclude<EntityType, 'workspaces'>,
    T extends AllAvailableCustomFieldSchema[] = AllAvailableCustomFieldSchema[],
  >(
    fields: T
  ) =>
  // eslint-disable-next-line react/display-name
  (props: DropdownFilterProps<TEntity>) => {
    switch (fields[0].type) {
      case 'multiSelect':
      case 'select':
        return (
          <ConnectedCustomFieldSelectFilter
            {...props}
            groupedFields={
              fields as CustomFieldSchemaByType<'multiSelect' | 'select'>[]
            }
          />
        )
      case 'url':
      case 'text':
        return (
          <ConnectedCustomFieldTextFilter
            {...props}
            groupedFields={
              fields as CustomFieldSchemaByType<TextFilterAllowedFields>[]
            }
          />
        )
      case 'multiPerson':
      case 'person':
        return (
          <ConnectedCustomFieldPersonFilter
            {...props}
            groupedFields={fields as CustomFieldSchemaByType<'person'>[]}
          />
        )
      case 'number':
        return (
          <ConnectedCustomFieldNumberFilter
            {...props}
            groupedFields={fields as CustomFieldSchemaByType<'number'>[]}
          />
        )
      case 'date':
        return (
          <ConnectedCustomFieldDateFilter
            {...props}
            groupedFields={fields as CustomFieldSchemaByType<'date'>[]}
          />
        )
      default:
        return null
    }
  }
