import { API } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'
import { type FoldersV2ResponseSchema } from '@motion/zod/client'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { type MutationIds } from './utils'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]

const updateFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshFolders(
  ctx: HandlerContext,
  mutations: MutationIds
) {
  notifyManager.batch(async () => {
    await ctx.client.invalidateQueries(API.folders.queryKeys.getAll, {
      predicate: () => true,
    })

    const foldersResponse = ctx.client.getQueryData<FoldersV2ResponseSchema>(
      API.folders.queryKeys.getAll
    )
    if (foldersResponse == null) return
    MotionCache.upsert(ctx.client, updateFilter, foldersResponse)

    if (mutations.delete.length) {
      MotionCache.delete(ctx.client, updateFilter, 'folders', mutations.delete)
    }
  })
}
