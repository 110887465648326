import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'

interface ModalFooterProps {
  onCloseModal: () => void
  onNextClick: () => void
  hideButtons?: boolean
  disableNext?: boolean
}

export const ModalFooter = ({
  onCloseModal,
  onNextClick,
  hideButtons,
  disableNext,
}: ModalFooterProps) => {
  return (
    <StyledModalFooter>
      <div></div>
      <div>
        {!hideButtons && (
          <div className='flex flex-row items-center gap-3'>
            <Button
              sentiment='primary'
              variant='outlined'
              onClick={onCloseModal}
            >
              Don&apos;t cancel
            </Button>
            <Button
              variant='outlined'
              sentiment='neutral'
              onClick={onNextClick}
              disabled={disableNext}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </StyledModalFooter>
  )
}

const StyledModalFooter = classed('div', {
  base: 'h-[56px] w-full flex flex-row items-center justify-between py-3 px-4 border-t border-modal-border',
})
