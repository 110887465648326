import { logEvent } from '@motion/web-base/analytics'
import { getWindowData, isMobileExperience } from '@motion/web-base/env'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { useConferenceSettingsApi } from '~/areas/settings/state'
import { ConnectedEnsureValidSubscription } from '~/areas/subscriptions'
import { useAuth } from '~/localServices/auth'
import { DateTime } from 'luxon'
import React, { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { LoadingPage } from './LoadingPage'
import { MigrationLoadingPage } from './MigrationLandingPage/MigrationLoadingPage'
import { MotionTab } from './MotionTab'

import { Highlight } from '../analytics/highlight'
import { OnboardingTerminated } from '../areas/onboarding/v1/OnboardingTerminated'
import api from '../chromeApi/chromeApiContentScript'
import { terminateWorker } from '../chromeApi/webappChromeApiContentScript'
import { useOnDesktopEvent } from '../localServices/desktop'
import { useAppDispatch, useAppSelector, useTheme } from '../state/hooks'
import * as mainActions from '../state/mainSlice'

/**
 * Wrapper around the MotionTab component primarily used to handle multiple
 * webapp instances during onboarding, and only allow one instance to be active
 * at a time. There are two ways we track this:
 * - Localstorage: For instances within the same browser instance, we write
 *   to a localstorage key (which is shared across all tabs), and will trigger
 *   on other active tabs.
 * - Firestore settings: A key is written with a timestamp that gets emitted
 *   across all webapp instances. This handles onboardings across browsers
 */
export const WebappWrapper = memo(function WebappWrapper() {
  const [terminated, setTerminated] = useState<null | 'onboarding' | 'logout'>(
    null
  )
  const { selectIsOnboarding } = useOnboardingApi()

  const onboarding = selectIsOnboarding()

  const backgroundLoaded = useAppSelector(mainActions.selectBackgroundLoaded)

  const isMigrating = useAppSelector(mainActions.selectIsMigrating)
  const theme = useTheme()
  const { isElectron } = getWindowData()

  const location = useLocation()

  const dispatch = useAppDispatch()

  useEffect(function redirectToHttps() {
    if (
      window.location.protocol === 'http:' &&
      window.location.hostname !== 'localhost'
    ) {
      window.location.href = window.location.href.replace('http:', 'https:')
    }
  }, [])

  const auth = useAuth()

  useEffect(() => {
    if (!onboarding && !isMobileExperience()) {
      // Thunk handles FF check
      void dispatch(mainActions.enableBrowserNotifications())
    }
  }, [backgroundLoaded, onboarding]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function initHighlight() {
    if (__IS_PROD__) {
      Highlight.initialize()
    }
  }, [])

  useOnDesktopEvent('logEvent', (eventName: string, properties: any) => {
    void logEvent(eventName, properties)
  })

  const { refreshZoomUserData } = useConferenceSettingsApi()

  useOnDesktopEvent('zoom-oauth', async () => {
    await refreshZoomUserData()
  })

  useEffect(
    function loadThirdParty() {
      const contentListener = (request: any) => {
        if (request.event === 'identifyUser') {
          try {
            if (__IS_PROD__) {
              Highlight.identify(request.user.email, {
                name: request.user.displayName,
                uid: request.user.uid,
              })
              // Only logging highlight sessions for first month to respect quota
              if (
                DateTime.fromISO(request.user.dateCreated).plus({ weeks: 4 }) >
                DateTime.now()
              ) {
                Highlight.track('WEBAPP_LOAD')
                Highlight.start()
              }
            }
          } catch (e) {
            Sentry.captureException(e, {
              tags: { position: 'initializeHighlight' },
            })
            void e
          }
          // TODO: Move the Intercom into its own component
          if (auth.isPro) {
            try {
              window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: 'onbboe5h',
                created_at: DateTime.fromISO(
                  request.user.dateCreated
                ).toUnixInteger(),
                email: request.user.email,
                hide_default_launcher: true,
                name: request.user.displayName,
              })
            } catch (e) {
              void e
            }
          }
        }
      }
      api.runtime.onMessage.addListener(contentListener)
      return () => api.runtime.onMessage.removeListener(contentListener)
    },
    [auth, auth.isPro]
  )

  /**
   * Storage listener that listens for a specific key that indicates whether
   * a particular window or tab is currently onboarding. If detected, then
   * we'll terminate the web worker (so that storage/firestore listeners are
   * removed) so that snapshots/changes do not affect the currently active
   * onboarding instance.
   */
  useEffect(() => {
    const listener = async (e: StorageEvent) => {
      if (e.newValue === null) {
        return
      }

      switch (e.key) {
        case 'motionOnboarding':
          if (onboarding) {
            logInDev('terminating worker')
            await terminateWorker()
            setTerminated('onboarding')
          }
          break
        case 'motionLogout':
          logInDev('received logout message')
          // Don't need to run through sign out process, as the sender should
          // have gone through that.
          logInDev('terminating worker')
          await terminateWorker()
          setTerminated('logout')
          break
      }
    }

    if (backgroundLoaded && !isElectron) {
      // Potentially fix issue where setting `motionOnboarding` fires this
      // event in the same window (which shouldn't happen - see:
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event)
      // To avoid this, the storage item is set before `backgroundLoaded` is
      // fired, and we bind the listener after.
      window.addEventListener('storage', listener)
    }

    return () => window.removeEventListener('storage', listener)
  }, [onboarding, backgroundLoaded, isElectron])

  useEffect(
    function setDarkModeClass() {
      if (theme === 'light') {
        document.body.classList.remove('dark')
      } else {
        document.body.classList.add('dark')
      }
    },
    [theme]
  )

  // TODO: Move the Intercom into its own component
  useEffect(() => {
    if (!auth.isPro) {
      window.Intercom('update', {
        hide_default_launcher: false,
      })
    }
  }, [auth, auth.isPro])

  if (location.pathname === '/web/support') {
    // navigate('/web/calendar')
    return null
  }

  if (auth.status !== 'authenticated' || auth.user == null) {
    return null
  }

  if (!backgroundLoaded) {
    return <LoadingPage id='webapp-wrapper' />
  }

  if (terminated) {
    return <OnboardingTerminated type={terminated} />
  }

  if (isMigrating) {
    return <MigrationLoadingPage />
  }

  if (!auth.isPro && !onboarding) {
    try {
      window.Intercom('update', {
        hide_default_launcher: false,
      })
    } catch (e) {
      void e
    }
  }

  const SubscriptionWrapper = onboarding
    ? React.Fragment
    : ConnectedEnsureValidSubscription

  return (
    <SubscriptionWrapper>
      <MotionTab />
    </SubscriptionWrapper>
  )
})
