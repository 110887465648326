import { BackendApiService } from './BackendApiService'

export type ApiGetUsageInterval = 'hour' | '4hour' | 'day' | 'week'

export type ApiUsage = {
  totals: {
    success: number
    error: number
  }

  errorSeries: {
    ts: string
    requests: number
  }[]

  successSeries: {
    ts: string
    requests: number
  }[]
}

export class ApiService extends BackendApiService {
  static id = 'ApiService' as const

  private readonly apiPath = 'api'

  getUsage(interval: ApiGetUsageInterval): Promise<ApiUsage> {
    return this.request(
      `${this.apiPath}/usage?interval=${interval}`,
      'GET',
      undefined
    )
  }
}

export const handler = new ApiService()
