import { DotsHorizontalSolid } from '@motion/icons'
import {
  ActionList,
  type ActionSection,
  IconButton,
  PopoverTrigger,
} from '@motion/ui/base'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import {
  useArchiveDeleteSection,
  useDuplicateSection,
  useOpenLinkSection,
  useStartStopActionItems,
} from '~/areas/tasks/components'
import { getParentChunkId } from '~/areas/tasks/utils'
import { useCachedItem } from '~/global/cache'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { useCloseTaskModal } from '../hooks'

export type DotsButtonProps = {
  taskId: TaskSchema['id']
}

export const DotsButton = ({ taskId }: DotsButtonProps) => {
  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <DotsMenuContent close={close} taskId={taskId} />
      )}
    >
      <IconButton
        size='small'
        variant='muted'
        sentiment='neutral'
        icon={DotsHorizontalSolid}
      />
    </PopoverTrigger>
  )
}

type DotsMenuContentProps = {
  close: () => void
  taskId: TaskSchema['id']
}

const DotsMenuContent = ({ close, taskId }: DotsMenuContentProps) => {
  const { data: task } = useTaskByIdV2({ id: taskId })

  if (task == null) {
    return null
  }

  return (
    <div className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden max-h-[400px]'>
      <ContentList close={close} task={task} />
    </div>
  )
}

export type ContentListProps = {
  close: () => void
  task: TaskSchema | RecurringTaskSchema
}
export const ContentList = ({ close, task }: ContentListProps) => {
  const { sections } = useTaskActionItems(task)

  return <ActionList sections={sections} onActionAnyItem={close} />
}

const useTaskActionItems = (
  task: TaskSchema | RecurringTaskSchema
): { sections: ActionSection[] } => {
  const closeModal = useCloseTaskModal()

  const parentChunkTask = useCachedItem('tasks', getParentChunkId(task))
  const parentChunkOrDirectTask = parentChunkTask ?? task

  const getLinkSection = useOpenLinkSection({
    copyShortcutLabel: 'mod+l',
    hideOpenTask: true,
    onNavigateAway: () => {
      closeModal()
    },
  })
  const getStartStopItems = useStartStopActionItems(task.id)
  const getDuplicateSection = useDuplicateSection({
    duplicateShortcutLabel: 'mod+d',
    onNavigateAway: () => {
      closeModal()
    },
  })
  const getArchiveDeleteSection = useArchiveDeleteSection({
    onDeleteConfirmed: () => {
      closeModal()
    },
  })

  const { sections } = useMemo(() => {
    return {
      sections: [
        getLinkSection({ task, parentChunkOrDirectTask }),
        { items: getStartStopItems({ task, parentChunkOrDirectTask }) },
        getDuplicateSection({ task, parentChunkOrDirectTask }),
        getArchiveDeleteSection({ task, parentChunkOrDirectTask }),
      ] as ActionSection[],
    }
  }, [
    getLinkSection,
    task,
    parentChunkOrDirectTask,
    getStartStopItems,
    getDuplicateSection,
    getArchiveDeleteSection,
  ])

  return { sections }
}
