import { ArrowLeftSolid } from '@motion/icons'

export const BackLabel = () => {
  return (
    <div className='flex items-center gap-2'>
      <ArrowLeftSolid className='w-3 h-3' />
      Back
    </div>
  )
}
