import {
  type AllAvailableCustomFieldSchema,
  hasOptions,
} from '@motion/ui-logic'

import { type WorkspaceCustomField } from '../types'

export function getFilteredWorkspaceCustomField(
  customField: AllAvailableCustomFieldSchema
): WorkspaceCustomField {
  if (hasOptions(customField)) {
    const filteredOptions = customField.metadata.options.filter(
      (option) => !option.deletedTime
    )

    return {
      ...customField,
      metadata: {
        ...customField.metadata,
        options: filteredOptions,
      },
    } satisfies typeof customField
  }

  return customField
}
