import { classed } from '@motion/theme'

import React from 'react'

import { CalendarPeekModal } from './components'
import { CalendarHeader, MainCalendar } from './main-calendar'
import { RightSidebar } from './right-sidebar'

export const CalendarView = () => {
  return (
    <>
      <CalendarColumn>
        <CalendarHeader />
        <MainCalendar />
      </CalendarColumn>

      <RightSidebar />

      <CalendarPeekModal />
    </>
  )
}

export const CalendarColumn = classed('div', {
  base: 'flex flex-col bg-calendar-bg-default',
})
