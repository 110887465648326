import { byValue, Compare, ordered } from '@motion/utils/array'

import { type ScheduleCollection } from '../types/settingsTypes'

/*
 * Takes a mapping of schedule names to schedules and returns an a
 * array of schedules sorted by title, but with Work as the first
 * schedule
 */
export const sortSchedules = (schedules: ScheduleCollection) => {
  return Object.entries(schedules)
    .map(([id, schedule]) => ({ id, schedule }))
    .sort(
      byValue(
        (x) => x.schedule.title,
        ordered([schedules.work?.title], Compare.caseInsensitive)
      )
    )
}

// Per update function, valid formats for start and end are 'ha' and 'h:mma'
// We verify the times are in this format separated by a hyphen
const validateRange = (range: string) => {
  const formatToMatch =
    /^(1[012]|[1-9])(:[0-5][0-9])?(am|pm)-([1-9]|1[012])(:[0-5][0-9])?(am|pm)$/
  if (!formatToMatch.test(range)) {
    throw new Error(`Invalid range ${range}`)
  }
}

export const validateSchedules = (schedules: ScheduleCollection) => {
  Object.values(schedules).forEach((schedule) => {
    Object.values(schedule.schedule).forEach((scheduleDay) => {
      scheduleDay.forEach((range) => {
        validateRange(range.range)
      })
    })
  })
}
