import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskDurationUpdater } from '../updaters'

export function useTaskDurationData(task: TaskSchema) {
  const updater = useTaskDurationUpdater()

  const disabled =
    task.type === 'RECURRING_INSTANCE' ||
    task.archivedTime != null ||
    isMeetingTask(task)

  const onChange = useCallback(
    (duration: TaskSchema['duration']) => {
      void updater(task, duration)
    },
    [task, updater]
  )

  return {
    onChange,
    disabled,
  }
}
