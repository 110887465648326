import { type ProjectSchema, type TaskSchema } from '@motion/zod/client'

import { useAgendaTaskActions } from '~/areas/agenda/hooks'
import { StartDateDropdown } from '~/areas/project-management/components'

import { TaskItemActionButton } from './task-item-action-button'

type ChangeStartDateButtonProps = {
  task: TaskSchema
  project?: ProjectSchema | null
}

export function ChangeStartDateButton({
  task,
  project = null,
}: ChangeStartDateButtonProps) {
  const { changeStartDate, updateTaskInFlight } = useAgendaTaskActions({
    taskId: task.id,
    projectId: project?.id,
  })

  return (
    <StartDateDropdown
      value={task.startDate}
      onChange={changeStartDate}
      contextProps={{ taskId: task.id }}
      dropdownTarget='task'
    >
      <TaskItemActionButton
        action='change-start-date'
        loading={updateTaskInFlight}
      >
        Change start date
      </TaskItemActionButton>
    </StartDateDropdown>
  )
}
