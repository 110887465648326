import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { focusFirstParentFocusableNode } from '@motion/ui/utils'
import { type TextCustomField } from '@motion/ui-logic'

import {
  CustomField,
  type CustomFieldInputProps,
} from '~/areas/project-management/custom-fields'
import { type FC, useRef, useState } from 'react'

import { CellText } from '../../components'
import { type ControlledCustomFieldProps } from '../types'

export const ControlledTextCell: FC<
  ControlledCustomFieldProps<TextCustomField> & {
    hideIcon?: boolean
  }
> = ({ value, onSubmit: submitHandler, customField, disabled }) => {
  const ref = useRef<HTMLLabelElement>(null)
  const [isDirty, setIsDirty] = useState(false)
  const [internalValue, setInternalValue] = useDependantState(
    () => value,
    [value],
    { freezeDependencyUpdates: isDirty }
  )

  const onChange = (v: string) => {
    setInternalValue(v)
  }

  const onSubmit = useDebouncedCallback(async () => {
    if (internalValue === value) {
      setIsDirty(false)
      return
    }

    try {
      await submitHandler(internalValue)
      // Focus on the cell if input is still focused
      if (ref.current != null && ref.current.contains(document.activeElement)) {
        focusFirstParentFocusableNode(ref.current)
      }
    } catch (error) {
      setInternalValue(value)
    } finally {
      setIsDirty(false)
    }
  }, 600)

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key } = evt

    if (key === 'Enter') {
      return onSubmit()
    }
  }

  return (
    <CellText withHover isFake={disabled}>
      <CustomField.Text
        ref={ref}
        name={customField.definition.name}
        value={internalValue ?? ''}
        onChange={onChange}
        onFocus={() => setIsDirty(true)}
        onBlur={() => onSubmit()}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        hideIcon
      />
    </CellText>
  )
}
