import { getTaskAutoScheduledChangedFields } from '@motion/ui-logic/pm/task'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export function useTaskAutoscheduleUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const { uid: currentUserId } = useAuthenticatedUser()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      isAutoScheduled: TaskSchema['isAutoScheduled']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }

      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the deadline of a Chunk task')
      }

      const updates = {
        isAutoScheduled,
        ...getTaskAutoScheduledChangedFields(
          { ...task, isAutoScheduled },
          {
            currentUserId,
          }
        ),
      }

      await updateTask(task, updates)
    },
    [currentUserId, readTask, updateTask]
  )
}
