import { useTheme } from '@motion/theme/dom'
import { templateStr } from '@motion/ui-logic'

export function LoadingScreen() {
  const { theme } = useTheme()

  return (
    <div className='flex flex-col gap-6 size-full items-center justify-center p-10 md:p-28'>
      <video
        muted
        autoPlay
        loop
        playsInline
        className='w-full max-w-[350px] h-[132px]'
      >
        <source
          src={templateStr(
            '/images/animations/ai-pwt-animation-{{theme}}.webm',
            {
              theme,
            }
          )}
          type='video/webm'
        />
        {/* Fallback in case browser doesn't support webm */}
        <source
          src={templateStr(
            '/images/animations/ai-pwt-animation-{{theme}}.mp4',
            {
              theme,
            }
          )}
          type='video/mp4'
        />
      </video>

      <h4 className='text-base font-medium text-center text-semantic-neutral-text-subtle'>
        Hang tight! We&apos;re setting up your project workflow template —
        it&apos;ll be ready in around 15 seconds!
      </h4>
    </div>
  )
}
