import { classed } from '@motion/theme'
import { templateStr } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { type DayHeaderContentArg } from '@fullcalendar/core'
import { DateTime } from 'luxon'

type CalendarDayHeaderProps = {
  info: DayHeaderContentArg
}

export function CalendarDayHeader({ info }: CalendarDayHeaderProps) {
  if (!info.date) return

  const date = parseDate(info.date)
  const dateIsoDate = date.toISODate()

  const isToday = dateIsoDate === DateTime.local().toISODate()

  return (
    <div className='relative'>
      <Date isToday={isToday}>
        {templateStr('{{weekday}} {{number}}', {
          weekday: date.toFormat('ccc'),
          number: <DateNumber>{date.toFormat('d')}</DateNumber>,
        })}
      </Date>
    </div>
  )
}

const Date = classed('span', {
  base: `
    text-sm h-10 font-medium inline-flex items-center gap-1 text-semantic-neutral-text-subtle
  `,
  variants: {
    isToday: {
      true: 'font-bold text-semantic-neutral-text-default',
    },
  },
})
const DateNumber = classed('span', 'h-6 p-1 inline-flex items-center')
