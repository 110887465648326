import { PlusOutline } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'

import { useTaskModalUrl } from '~/global/navigation'
import { type DateTime } from 'luxon'
import { useNavigate } from 'react-router'

export type AddTaskRowProps = {
  date: DateTime
}

export function AddTaskRow({ date }: AddTaskRowProps) {
  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  const openCreateTaskModal = () => {
    navigate(
      buildTaskModalUrl({
        forStartDate: date.toISODate(),
        forDueDate: date.endOf('day').toISO(),
      })
    )
  }

  return (
    <AddTaskRowContainer>
      <Button
        onClick={(e) => {
          openCreateTaskModal()
        }}
        variant='muted'
        sentiment='neutral'
        size='xsmall'
      >
        <AddTaskButtonInner>
          <PlusOutline
            height={16}
            width={16}
            className='text-semantic-neutral-icon-default'
          />
          <AddTaskButtonLabel>Add task</AddTaskButtonLabel>
        </AddTaskButtonInner>
      </Button>
    </AddTaskRowContainer>
  )
}

const AddTaskRowContainer = classed('div', {
  base: 'flex flex-row h-9 self-stretch align-start border-t border-semantic-neutral-border-light pt-2',
})

const AddTaskButtonInner = classed('span', {
  base: 'flex flex-row items-center gap-[6px] py-1 px-2',
})

const AddTaskButtonLabel = classed('span', {
  base: 'text-sm font-semibold text-button-neutral-muted-text-default',
})
