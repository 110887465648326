import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { getTaskDeadlineDateChangedFields } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useI18N } from '~/global/contexts'
import { showErrorToast } from '~/global/toasts'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { type ActionArgs } from './types'

// For each given task, update the scheduled date to either the stage deadline (if exists) or the project deadline
export const useUpdateTasksToStageOrProjectDeadline = () => {
  const updateTask = useTaskUpdater()
  const { pluralize } = useI18N()

  return useCallback(
    async (args: ActionArgs) => {
      const { tasks, setIsLoading, type } = args

      recordAnalyticsEvent(
        'PAST_DUE_MODAL_ACTION_EXTEND_TO_STAGE_OR_PROJECT_DEADLINE',
        {
          type,
          numTasks: tasks.length,
        }
      )

      setIsLoading(true)

      const updatedTaskCalls = await Promise.allSettled(
        tasks.map((task) => {
          try {
            if (task.type === 'CHUNK') {
              throw new Error('Cannot update the deadline of a Chunk task')
            }

            if (!task.projectId) {
              throw new Error('Task does not have a project')
            }

            let newDeadline: string | undefined = undefined

            if (task.project?.dueDate) {
              newDeadline = DateTime.fromISO(task.project.dueDate)
                .endOf('day')
                .toISO()
            }

            if (task.type === 'NORMAL' && task.stageDefinitionId) {
              const stage = task.project?.stages.find(
                (stage) => stage.stageDefinitionId === task.stageDefinitionId
              )

              if (stage?.dueDate) {
                newDeadline = DateTime.fromISO(stage.dueDate)
                  .endOf('day')
                  .toISO()
              }
            }

            if (!newDeadline) {
              throw new Error(
                'Task does not have a project or stage with a due date'
              )
            }

            return updateTask(task, {
              dueDate: newDeadline,
              ...getTaskDeadlineDateChangedFields({
                ...task,
                dueDate: newDeadline,
              }),
            })
          } catch (error) {
            return Promise.reject(error)
          }
        })
      )

      const tasksUnableToUpdate = updatedTaskCalls.filter(
        (result) => result.status === 'rejected'
      )

      if (tasksUnableToUpdate.length) {
        showErrorToast(
          templateStr('{{numTasks}} {{tasks}} could not be updated', {
            numTasks: tasksUnableToUpdate.length,
            tasks: pluralize(tasksUnableToUpdate.length, 'task', 'tasks'),
          })
        )
      } else {
        showToast(
          'success',
          templateStr('{{numTasks}} {{tasks}} updated to {{updateType}}', {
            numTasks: tasks.length,
            tasks: pluralize(tasks.length, 'task', 'tasks'),
            updateType: pluralize(
              tasks.length,
              'stage or project deadline',
              'their stage or project deadlines'
            ),
          })
        )
      }

      setIsLoading(false)
    },
    [pluralize, updateTask]
  )
}
