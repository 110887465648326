import { Button } from '@motion/ui/base'

import { AssigneeDropdown } from '~/areas/project-management/components'
import { UserBadge } from '~/global/components/badges'
import { useWorkspaceMember } from '~/global/hooks'
import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationAssigneeField = ({
  workspaceId,
}: {
  workspaceId: string
}) => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'assigneeUserId',
    control: formContext.control,
  })
  const member = useWorkspaceMember(workspaceId, field.value)

  return (
    <AssigneeDropdown
      selectedUserId={field.value ?? null}
      onChange={field.onChange}
      workspaceId={workspaceId}
    >
      <Button sentiment='neutral' variant='outlined' iconOnly size='xsmall'>
        <div className='p-0.5'>
          <UserBadge value={member?.user ?? null} size='small' />
        </div>
      </Button>
    </AssigneeDropdown>
  )
}
