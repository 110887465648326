import { XSolid } from '@motion/icons'

import { twMerge } from 'tailwind-merge'

import { SecondaryButton } from '../../Button/SecondaryButton/SecondaryButton'
import { TextButton } from '../../Button/TextButton/TextButton'
import { Paragraph } from '../../Text'
import { Dialog, type DialogProps } from '../Dialog'
import {
  type CancelDialogProps,
  DialogButton,
  type DialogButtonProps,
} from '../DialogButton/DialogButton'

export const ActionDialog = (
  props: {
    errorMessage?: string
    contentPadding?: boolean
  } & DialogProps &
    CancelDialogProps &
    DialogButtonProps
) => {
  const {
    isOpen,
    title,
    children,
    onClose,
    errorMessage,
    size = 'large',
    className,
    showClose = true,
    contentPadding = true,
    cancelButtonText = 'Cancel',
    navbarClickable,
    cancelButtonHandler,
    cancelButtonDisabled = false,
    ...actionButtonProps
  } = props
  return (
    <Dialog
      isOpen={isOpen}
      padded={false}
      onClose={onClose}
      size={size}
      navbarClickable={navbarClickable}
      className={twMerge(
        'flex max-h-[95vh] flex-col overflow-hidden',
        className
      )}
    >
      <div className='dark:text-dark-100 text-light-1200 flex justify-between px-4 py-3'>
        {title && (
          <Paragraph className='text-base font-semibold text-inherit dark:text-inherit'>
            {title}
          </Paragraph>
        )}
        {showClose && (
          <TextButton className='ml-auto' onClick={onClose} icon={XSolid} />
        )}
      </div>
      <div className='dark:border-dark-900 border-light-500 flex h-full max-h-[75vh] min-h-0 grow flex-col overflow-hidden border-y'>
        <div className='overflow-y-auto'>
          <div
            className={`dark:text-dark-300 text-light-1100 flex-1 ${
              contentPadding ? 'p-4' : ''
            }`}
          >
            {children}
          </div>
        </div>
      </div>
      <div className='flex flex-col px-4 py-3'>
        {errorMessage && (
          <Paragraph className='text-alert-400 dark:text-alert-400'>
            {errorMessage}{' '}
          </Paragraph>
        )}
        <div className='flex w-full items-center justify-end gap-2'>
          <SecondaryButton
            onClick={() => {
              if (cancelButtonHandler) {
                cancelButtonHandler()
              } else {
                onClose && onClose()
              }
            }}
            disabled={cancelButtonDisabled}
          >
            {cancelButtonText}
          </SecondaryButton>
          <DialogButton {...actionButtonProps} />
        </div>
      </div>
    </Dialog>
  )
}
