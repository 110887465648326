import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTemplateProjectFormHandlers } from './use-project-form-handlers'

import { useTemplateProjectFormOptions } from '../template-project-form-options'
import { type TemplateProjectFormFields } from '../types'

export function useProjectForm() {
  const form = useFormContext<TemplateProjectFormFields>()
  const options = useTemplateProjectFormOptions()
  const actions = useTemplateProjectFormHandlers()

  return useMemo(
    () => ({
      form,
      options,
      actions,
    }),
    [form, options, actions]
  )
}
