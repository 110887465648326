import { useSharedState } from '@motion/react-core/shared-state'

import { PageTasksContextKey } from '~/areas/tasks/context/task-loader'

const nf = new Intl.NumberFormat('en-US')

export const TaskCounter = () => {
  const [tasks] = useSharedState(PageTasksContextKey)
  if (tasks == null) return null
  return (
    <div className='flex items-center text-xs text-semantic-neutral-text-subtle uppercase shrink-0'>
      Tasks: {nf.format(tasks.length)}
    </div>
  )
}
