import { TextField } from '@motion/ui/forms'

import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationDescriptionField = () => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'description',
    control: formContext.control,
  })

  return (
    <TextField
      size='xsmall'
      variant='muted'
      onChange={field.onChange}
      value={field.value}
      fullWidth
      multiline
      placeholder='Description'
      noResize
    />
  )
}
