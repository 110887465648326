import {
  CustomField,
  type CustomFieldDateProps,
} from '~/areas/project-management/custom-fields'
import { ModalFieldButton } from '~/areas/task-project/components'
import { forwardRef } from 'react'

export const Date = forwardRef<HTMLButtonElement, CustomFieldDateProps>(
  (props, ref) => {
    return (
      <CustomField.Date
        {...props}
        ref={ref}
        triggerComponent={ModalFieldButton}
      />
    )
  }
)

Date.displayName = 'TaskModalDate'
