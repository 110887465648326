import { isMeetingTask } from '@motion/ui-logic/pm/task'

import { ProjectDropdown } from '~/areas/project-management/components'
import { useTaskProjectUpdater } from '~/areas/tasks/hooks'
import { ConnectedProjectBadge } from '~/global/components/badges'

import { RowButton } from './common'
import { type WithTaskProps } from './types'

export const ProjectButton = (props: WithTaskProps) => {
  const updateProject = useTaskProjectUpdater()

  return (
    <ProjectDropdown
      selectedProjectId={props.task.projectId}
      workspaceId={props.task.workspaceId}
      onChange={(projectId) => {
        updateProject(props.task, projectId)
      }}
      hideNoProject={isMeetingTask(props.task)}
    >
      <RowButton disabled={props.task.type === 'RECURRING_INSTANCE'}>
        <ConnectedProjectBadge size='small' id={props.task.projectId} />
      </RowButton>
    </ProjectDropdown>
  )
}
