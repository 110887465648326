import { PopoverContainer } from '@motion/ui/base'
import { mergeRefs } from '@motion/ui/utils'

import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  type Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { cloneElement, isValidElement, type ReactNode } from 'react'

import { useSidebarSize } from '../../hooks'

export interface ProjectItemTooltipProps {
  children: ReactNode
  renderContent: (close?: () => void) => ReactNode
  placement?: Placement
  open: boolean
  setOpen?: (open: boolean) => void
  container: HTMLElement | null
}

export const ProjectItemTooltip = (props: ProjectItemTooltipProps) => {
  const {
    children,
    renderContent,
    placement = 'left-start',
    open = false,
    setOpen = () => {},
    container,
  } = props
  const sidebarSize = useSidebarSize()
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,

    middleware: [
      offset(2),
      flip({
        fallbackAxisSideDirection: 'end',
        fallbackPlacements: ['top-end', 'top-start'],
      }),
      shift({ padding: { left: sidebarSize + 5, right: 5 } }),
    ],
    whileElementsMounted: (reference, floating, update) =>
      autoUpdate(reference, floating, update, { animationFrame: true }),
  })

  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
    focus,
  ])

  const trigger = isValidElement(children) ? (
    cloneElement(children, {
      ...getReferenceProps(),
      ref: mergeRefs(refs.setReference, (children as any).ref),
    } as React.HTMLAttributes<HTMLElement>)
  ) : (
    <div ref={refs.setReference} {...getReferenceProps()}>
      {children}
    </div>
  )

  const close = () => setOpen(false)

  return (
    <>
      {trigger}
      {container && open && (
        <FloatingPortal root={container}>
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            onClick={(e) => e.stopPropagation()}
            onContextMenu={(e) => e.stopPropagation()}
            {...getFloatingProps()}
          >
            {renderContent(close)}
          </PopoverContainer>
        </FloatingPortal>
      )}
    </>
  )
}
