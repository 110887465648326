import { type TeamMemberSerializer } from '@motion/rpc-types'

import { TeamMemberRole } from '../components/ProjectManagement/types'
import { type Team } from '../state/TeamTypes'

export const getUniqueEmails = (team: Team): Set<string> => {
  const users = team.users
    ? Object.keys(team.users).map((user) => team.users[user].email)
    : []
  const invited = team.invited ? team.invited : []
  return new Set([...users, ...invited])
}

/**
 * Returns a set of email addresses representing the team admins
 *
 * @param {PMTeamMemberType[]} teamMembers - An array of team members
 * @return {Set<string>} - A set of email addresses of team admins
 */
export const getTeamAdmins = (
  teamMembers: TeamMemberSerializer[]
): Set<string> => {
  return new Set(
    teamMembers
      .filter((member) => member.role === TeamMemberRole.ADMIN)
      .map((member) => member.user.email)
  )
}
