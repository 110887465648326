import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { time } from '@motion/utils/debug'

import { useModalListForNavigation } from '~/global/hooks'
import { type ProjectWithRelations } from '~/global/proxies'
import { useEffect, useMemo } from 'react'

import { useTreeGroupOverrides } from './hooks'

import {
  buildTreeGroup,
  getOrderedIdsFromTree,
  type GroupDefinition,
  type GroupedNode,
} from '../grouping'
import { CurrentTreeKey } from '../grouping/use-active-group'
import { KanbanBoard } from '../kanban'
import { type TreeListRowValueType } from '../tree-list'
import { useViewState } from '../view-state'

export type ProjectKanbanBoardProps = {
  projects: ProjectWithRelations[]
  groupBy: GroupDefinition<ProjectWithRelations>[]
  hideEmptyGroups: boolean
}

export const ProjectKanbanBoard = (props: ProjectKanbanBoardProps) => {
  const [viewState] = useViewState()
  const overrides = useTreeGroupOverrides('projects')

  const grouped = useMemo(() => {
    return time('build-tree-group.total', () => {
      return buildTreeGroup(props.groupBy, overrides)
        .add('project', props.projects)
        .buildTree<GroupedNode<TreeListRowValueType>>({
          hideEmptyGroups: props.hideEmptyGroups,
          sortOrder: viewState.groupBy.order,
        })
    })
  }, [
    props.groupBy,
    props.projects,
    props.hideEmptyGroups,
    overrides,
    viewState.groupBy.order,
  ])

  const setCurrentTree = useSharedStateSendOnly(CurrentTreeKey)
  useEffect(() => setCurrentTree(grouped), [grouped, setCurrentTree])

  useModalListForNavigation({ projects: getOrderedIdsFromTree(grouped) })

  return <KanbanBoard tree={grouped} />
}
