import {
  AutoscheduleSolid,
  LoadingSolid,
  QuestionMarkCircleSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, ButtonGroup, Tooltip, useShortcut } from '@motion/ui/base'
import {
  formatTime,
  prettyDateDay,
  scheduleTypeToInfo,
  templateStr,
} from '@motion/ui-logic'
import { getScheduledDate, isReminderTask } from '@motion/ui-logic/pm/task'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

// TODO tleunen - fix this
// Importing the file directly because otherwise it creates some circular dependencies within some redux slices
import { useCopyTaskLinkToClipboard } from '~/global/hooks/use-copy-link-to-clipboard'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAutoScheduleRange } from '../../../../../areas/project-management/hooks/experiments'
import { useSendToDesktop } from '../../../../../localServices/desktop'

type TaskToastBodyProps = {
  task: TaskSchema | RecurringTaskSchema
  onHide: () => void
  taskUrl: string
  currentUserId: string
  desktopOptionSpace?: boolean
}

export const TaskToastBody = (props: TaskToastBodyProps) => {
  const { task, onHide, taskUrl, currentUserId, desktopOptionSpace } = props

  const copyTaskLink = useCopyTaskLinkToClipboard()
  const navigate = useNavigate()
  const sendToDesktop = useSendToDesktop()

  function handleOpenTask(): void {
    if (!desktopOptionSpace) {
      void navigate(taskUrl)
    } else {
      sendToDesktop('openTask', { taskUrl })
    }
    onHide()
  }

  function handleCopyTaskLink(): void {
    copyTaskLink({
      workspaceId: task.workspaceId,
      taskName: task.name,
      taskId: task.id,
    })
  }

  useShortcut('mod+o', () => handleOpenTask())
  useShortcut('mod+l', () => handleCopyTaskLink())

  return (
    <div className='flex flex-col gap-2 py-2 px-3'>
      <div className='text-sm font-semibold text-semantic-neutral-text-default'>
        {task.name}
      </div>
      <ButtonGroup size='small'>
        <Button
          onClick={handleOpenTask}
          sentiment='neutral'
          size='small'
          variant='solid'
          shortcut='mod+o'
        >
          Open task
        </Button>
        <Button
          onClick={handleCopyTaskLink}
          sentiment='neutral'
          size='small'
          variant='solid'
          shortcut='mod+l'
        >
          Copy link
        </Button>
      </ButtonGroup>
      <SchedulingRow
        task={task}
        isAssignedToCurrentUser={currentUserId === task.assigneeUserId}
      />
    </div>
  )
}

type SchedulingRowProps = {
  task: TaskSchema | RecurringTaskSchema
  isAssignedToCurrentUser: boolean
}
const SchedulingRow = ({
  task,
  isAssignedToCurrentUser,
}: SchedulingRowProps) => {
  const [forceSchedule, setForceSchedule] = useState(false)
  const autoScheduleRange = useAutoScheduleRange()

  const isRecurring = task.type === 'RECURRING_TASK'

  useEffect(() => {
    if (isAssignedToCurrentUser) return
    // Force the schedule after 1 second so that task that you assign to other people appear scheduled.
    // We can't fo it for real because users only receive scheduled websocket events for their own tasks.
    const timerId = setTimeout(() => {
      setForceSchedule(true)
    }, 2000)

    return () => clearTimeout(timerId)
  }, [isAssignedToCurrentUser])

  const shouldShowSchedulingRow =
    task.isAutoScheduled && !isRecurring && !isReminderTask(task)

  if (!shouldShowSchedulingRow) {
    return null
  }

  const scheduledDate = getScheduledDate(task)

  if (scheduledDate) {
    return (
      <TaskRow sentiment='ai'>
        <div className='w-5 h-5 bg-semantic-purple-bg-default rounded-full p-1'>
          <AutoscheduleSolid className='w-3 h-3 text-semantic-purple-icon-default' />
        </div>
        {templateStr('Scheduled {{day}} at {{time}}', {
          day: prettyDateDay(scheduledDate, {
            lowercase: true,
          }),
          time: formatTime(scheduledDate, {
            optionalMinutes: true,
          }),
        })}
      </TaskRow>
    )
  }

  if (task.isUnfit && !task.needsReschedule) {
    return (
      <TaskRow>
        <div className='w-5 h-5 bg-semantic-neutral-bg-subtle rounded-full p-1'>
          <AutoscheduleSolid className='w-3 h-3 text-semantic-neutral-icon-default' />
        </div>
        To be scheduled in the future{' '}
        <Tooltip
          content={templateStr(
            scheduleTypeToInfo.unfitSchedulable.tooltip ?? '',
            {
              autoScheduleRange,
            }
          )}
        >
          <QuestionMarkCircleSolid className='w-3 h-3' />
        </Tooltip>
      </TaskRow>
    )
  }

  if (forceSchedule) {
    return (
      <TaskRow sentiment='ai'>
        <div className='w-5 h-5 bg-semantic-purple-bg-default rounded-full p-1'>
          <AutoscheduleSolid className='w-3 h-3 text-semantic-purple-icon-default' />
        </div>
        Scheduled
      </TaskRow>
    )
  }

  return (
    <TaskRow sentiment='ai'>
      <LoadingSolid className='animate-spin w-3 h-3 text-semantic-purple-icon-default' />
      Scheduling...
    </TaskRow>
  )
}

const TaskRow = classed('div', {
  base: 'flex flex-row items-center gap-2 text-xs font-medium text-semantic-neutral-text-subtle mt-2',
  variants: {
    sentiment: {
      ai: 'text-semantic-purple-text-default',
    },
  },
})
