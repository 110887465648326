import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import {
  ColorDropdownContent,
  type ColorDropdownContentProps,
} from '~/global/components/dropdowns'

import { useProjectUpdater } from '../../hooks/updaters'

export type ProjectColorPickerProps = {
  selected: ColorDropdownContentProps['selectedColor']
  project: ProjectSchema | null
  onChange?: (color: string) => void
}

export function ProjectColorPicker(props: ProjectColorPickerProps) {
  const { selected, project, onChange } = props

  const context = useRouteAnalyticsMetadata()

  const updateProject = useProjectUpdater()

  return (
    <ColorDropdownContent
      selectedColor={selected}
      onChange={async (color) => {
        if (project != null) {
          recordAnalyticsEvent(
            'PROJECT_MANAGEMENT_UPDATE_PROJECT_COLOR',
            context
          )
          await updateProject(project, {
            color,
          })
        }

        onChange?.(color)
      }}
    />
  )
}
