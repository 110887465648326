import { classed } from '@motion/theme'
import { templateStr } from '@motion/ui-logic'

import { useUtilizedSubscription } from '~/global/rpc/subscriptions'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { useAppSelector } from '../../../state/hooks'
import { selectUser } from '../../../state/userSlice'
import { formatAndFilterWorkspaceSummary } from '../../Account/utils'
import { CancelModalListItem } from '../components/cancel-modal-list-item'
import { FrownIcon } from '../components/icons'
import { ModalFooter } from '../components/modal-footer'
import { ModalMainWrapper } from '../components/modal-main-wrapper'
import { ModalTitle } from '../components/modal-title'
import { type CancelScreenProps } from '../components/types'

export const BeforeYouGo = ({
  onNextClick,
  onCloseModal,
  workspaceSummary,
}: CancelScreenProps) => {
  const user = useAppSelector(selectUser)
  const userFirstName = user.displayName.split(' ')[0]

  const utilizedSubscription = useUtilizedSubscription()

  const workspaceSummaryItems = useMemo(
    () => formatAndFilterWorkspaceSummary(workspaceSummary),
    [workspaceSummary]
  )

  return (
    <>
      <ModalMainWrapper>
        <ModalTitle
          text={`${userFirstName}, before you go...`}
          subtext="We're sorry you're thinking of leaving us. A few things you
          should know before you continue:"
          icon={FrownIcon}
        />
        <div className='flex flex-col bg-semantic-neutral-bg-disabled p-6 gap-4'>
          <span className='text-xs font-medium text-semantic-neutral-text-subtle'>
            If you cancel...
          </span>
          {utilizedSubscription && (
            <CancelModalText>
              {templateStr(
                "Your subscription will stay active until {{expiration}}. You won't be charged again after that.",
                {
                  expiration: DateTime.fromSeconds(
                    utilizedSubscription.current_period_end
                  ).toFormat('MMMM d, y'),
                }
              )}
            </CancelModalText>
          )}
          <CancelModalText>
            Your account including all tasks and projects will be permanently
            deleted 90 days after subscription ends.
          </CancelModalText>
          <div className='flex flex-col gap-2'>
            <span className='text-xs font-semibold text-semantic-neutral-text-default'>
              You&apos;ll lose access to:
            </span>
            <div className='flex flex-col gap-1'>
              {workspaceSummaryItems.map((text, i) => (
                <CancelModalListItem key={i} text={text} />
              ))}
            </div>
          </div>
        </div>
      </ModalMainWrapper>
      <ModalFooter onNextClick={onNextClick} onCloseModal={onCloseModal} />
    </>
  )
}

const CancelModalText = classed('span', {
  base: 'text-sm font-normal text-semantic-neutral-text-default',
})
