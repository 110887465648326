import { useMemoDeep } from '@motion/react-core/hooks'
import { type ExperimentValues } from '@motion/web-common/flags'

import { memo, useMemo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { RouteContext } from './context'
import { ResetRouter } from './reset-router'

import { initializeRoutes } from '../api'
import { routes } from '../routes'

type AppRouterProps = {
  featureFlags: ExperimentValues
}

export const AppRouter = memo(function AppRouter(props: AppRouterProps) {
  const featureFlags = useMemoDeep(props.featureFlags)

  const info = useMemo(() => {
    const validatedRoutes = initializeRoutes(
      { featureFlags: featureFlags, parentPath: '' },
      routes
    )

    return {
      router: createBrowserRouter(validatedRoutes.tree),
      routes: validatedRoutes.flat,
    }
  }, [featureFlags])

  return (
    <ResetRouter>
      <RouteContext.Provider value={info.routes}>
        <RouterProvider router={info.router} />
      </RouteContext.Provider>
    </ResetRouter>
  )
})
