import { BlockedBySolid } from '@motion/icons'
import { wrapVariableInDelimiters } from '@motion/shared/flows'
import {
  FieldButton,
  type FieldButtonProps,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { type TaskDefinitionSchema } from '@motion/zod/client'

import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { useNonEmptyTasksAbove } from './hooks'

import { stripVariableKeyFromText } from '../../../utils'

type BlockedByFieldProps = {
  stageTasksPath: `stages.${number}.tasks`
  task: TaskDefinitionSchema
  value: TaskDefinitionSchema | null
  onChange: (value: string | null) => void
  textVariables: FlowTemplateFormFields['textVariables']
  size?: FieldButtonProps['size']
}

export function BlockedByField(props: BlockedByFieldProps) {
  const {
    task,
    value,
    onChange,
    textVariables,
    stageTasksPath,
    size = 'small',
  } = props

  const variableKeys = textVariables.map((v) => v.key)

  const name = useMemo(() => {
    if (!value?.name) return null

    let name = value.name

    variableKeys.forEach((key) => {
      name = stripVariableKeyFromText(name, wrapVariableInDelimiters(key))
    })

    return name
  }, [value?.name, variableKeys])

  const trigger = (
    <FieldButton variant='muted' fullWidth size={size}>
      <div className='flex flex-row items-center gap-1 truncate'>
        <BlockedBySolid className='!text-semantic-error-icon-default' />

        <div
          className={twMerge(
            'truncate',
            'cursor-pointer',
            value != null
              ? 'text-semantic-neutral-text-default'
              : 'text-semantic-neutral-text-subtle'
          )}
        >
          {name != null ? `Blocked by ${name}` : 'Blocked by'}
        </div>
      </div>
    </FieldButton>
  )

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BlockedByContent
          close={close}
          stageTasksPath={stageTasksPath}
          task={task}
          value={value}
          onChange={onChange}
        />
      )}
    >
      {trigger}
    </PopoverTrigger>
  )
}

type BlockedByContentProps = Omit<BlockedByFieldProps, 'textVariables'> & {
  close: () => void
}

export const BlockedByContent = ({
  close,
  stageTasksPath,
  task,
  value,
  onChange,
}: BlockedByContentProps) => {
  const nonEmptyTasksAbove = useNonEmptyTasksAbove(stageTasksPath, task)
  const computeSelected = (item: TaskDefinitionSchema) => item.id === value?.id

  return (
    <SearchableList
      searchable
      items={nonEmptyTasksAbove}
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={computeSelected}
      onSelect={(item) => {
        close()
        onChange(computeSelected(item) ? null : item.id)
      }}
      renderItem={(task) => (
        <div className='max-w-xs truncate'>{task.name}</div>
      )}
      inputProps={{ placeholder: 'Search tasks...' }}
    />
  )
}
