import { QuestionMarkCircleSolid } from '@motion/icons'
import { Toggle, Tooltip } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useController } from 'react-hook-form'

import { SubItemIndentMarker } from './styled'

import { useTaskDefinitionForm } from '../use-task-definition-form'

const TOOLTIP_CONTENT =
  'If this is toggled on, Motion will prioritize this task and make sure it schedules before deadline; if needed, Motion may schedule it outside of work hours (or another selected schedule) in order to meet deadline.'

export const ControlledDeadlineTypeToggle = () => {
  const { form, disabledFields, hiddenFields } = useTaskDefinitionForm()

  const { control } = form

  const { field: deadlineTypeField } = useController({
    name: 'deadlineType',
    control,
  })

  const deadlineType = deadlineTypeField.value
  const disabled = disabledFields.has('deadlineType')
  const shouldIndent = !hiddenFields.has('dueDate')

  const hardDeadlineField = (
    <ModalFieldButton
      label='Hard deadline'
      size='normal'
      variant='subtle'
      disabled={disabled}
    >
      <Tooltip content={disabled ? undefined : TOOLTIP_CONTENT}>
        <QuestionMarkCircleSolid />
      </Tooltip>
      <Toggle
        checked={deadlineType === 'HARD'}
        disabled={disabled}
        size='small'
        onChange={() => {
          deadlineTypeField.onChange(deadlineType === 'HARD' ? 'SOFT' : 'HARD')
        }}
      />
    </ModalFieldButton>
  )

  if (!shouldIndent) return hardDeadlineField

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />
      {hardDeadlineField}
    </div>
  )
}
