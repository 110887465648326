import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { ColumnHeaderCell } from './components'
import { type TreeListColumn } from './type'
import { minSizeOf, sizeOf } from './utils'

import { type CustomFieldDefinition } from '../../fields'
import { CONTROLLED_CUSTOM_FIELD_CELL_LOOKUP } from '../cells'
import { CellWrapper } from '../cells/custom-fields/cell-wrapper'

export function convertCustomFieldToColumn(
  f:
    | CustomFieldDefinition<TaskWithRelations>
    | CustomFieldDefinition<ProjectWithRelations>
): TreeListColumn {
  const {
    name,
    id,
    customFieldMeta: { workspaceIdToFieldId, type },
  } = f
  return {
    id,
    header: () => <ColumnHeaderCell>{name}</ColumnHeaderCell>,
    size: sizeOf(f),
    minSize: minSizeOf(f),
    cell: (info) => (
      <CellWrapper
        component={CONTROLLED_CUSTOM_FIELD_CELL_LOOKUP[type] ?? null}
        info={info}
      />
    ),
    enableResizing: true,
    enableSorting: true,
    enablePinning: false,
    accessorFn: (row) => row,
    meta: {
      name: f.name,
      disableReordering: false,
      customFields: {
        workspaceIdToFieldId,
      },
    },
  }
}
