import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { time } from '@motion/utils/debug'
import { useHasTreatment } from '@motion/web-common/flags'

import { useAddProjectStateValue } from '~/areas/project/states'
import { useModalListForNavigation } from '~/global/hooks'
import { type ProjectWithRelations } from '~/global/proxies'
import { useEffect, useMemo } from 'react'

import { useTreeGroupOverrides } from './hooks'

import {
  buildTreeGroup,
  getOrderedIdsFromTree,
  type GroupDefinition,
  type GroupedNode,
} from '../grouping'
import { CurrentTreeKey } from '../grouping/use-active-group'
import { TreeList } from '../tree-list'
import { type TreeListRowValueType } from '../tree-list'
import { getProjectInferItem } from '../tree-list/cells/hooks/utils'
import { useProjectColumns } from '../tree-list/columns'
import { useViewState } from '../view-state'

export type ProjectTreeListProps = {
  projects: ProjectWithRelations[]
  groupBy: GroupDefinition<ProjectWithRelations>[]
  hideEmptyGroups: boolean
}

export const ProjectTreeList = (props: ProjectTreeListProps) => {
  const addProjectState = useAddProjectStateValue()
  const [viewState] = useViewState()
  const overrides = useTreeGroupOverrides('projects')

  const hasWorkspaceFolders = useHasTreatment('workspace-folders')

  const grouped = useMemo(() => {
    return time('build-tree-group.total', () => {
      return buildTreeGroup(props.groupBy, overrides)
        .add('project', props.projects)
        .buildTree<GroupedNode<TreeListRowValueType>>({
          hideEmptyGroups: props.hideEmptyGroups,
          sortOrder: viewState.groupBy.order,
          footer(row) {
            return [
              {
                key: 'totals-row',
                type: 'project-totals' as const,
                ...getProjectInferItem({ row, addProjectState }),
              },
            ]
          },
        })
    })
  }, [
    props.groupBy,
    props.projects,
    props.hideEmptyGroups,
    overrides,
    viewState.groupBy.order,
    addProjectState,
  ])

  const setCurrentTree = useSharedStateSendOnly(CurrentTreeKey)
  useEffect(() => setCurrentTree(grouped), [grouped, setCurrentTree])

  useModalListForNavigation({ projects: getOrderedIdsFromTree(grouped) })

  const columns = useProjectColumns()

  return (
    <TreeList
      tree={grouped}
      columns={columns}
      enableSelection={hasWorkspaceFolders}
    />
  )
}
