import { calculateNextFridayDueDate } from '@motion/ui-logic'

import type { Workspace } from './types'

const nextFriday = calculateNextFridayDueDate()

export const defaultWorkspace: Workspace = {
  projectName: '',
  tasks: [
    {
      deadlineISO: nextFriday,
      description: '',
      durationMinutes: 30,
      id: '1',
    },
    {
      deadlineISO: nextFriday,
      description: '',
      durationMinutes: 30,
      id: '2',
    },
    {
      deadlineISO: nextFriday,
      description: '',
      durationMinutes: 30,
      id: '3',
    },
  ],
  workspaceName: '',
}
