import { logInDev } from '@motion/web-base/logging'

export class BrowserNotificationsHandler {
  static id = 'BrowserNotificationsHandler' as const
  private notificationsEnabled = false

  /**
   * Flag provided by frontend when querying/requesting permission from the user
   * @param enabled
   * @returns
   */
  setEnableNotifications(enabled: boolean) {
    this.notificationsEnabled = enabled
    logInDev(
      `NotificationsHandler.setEnableNotifications: ${enabled ? 'yes' : 'no'}`,
      enabled
    )
    return enabled
  }

  /**
   * Checks whether browser notifications are enabled. This only tracks whether
   * browser notifications are enabled (so we can present the option to enable
   * if disabled), and not whether the feature itself is enabled.
   * @returns
   */
  isNotificationsEnabled() {
    return this.notificationsEnabled
  }

  /**
   * Checks FF to see whether the feature is enabled, and whether browser
   * notifications are enabled by the user
   * @returns
   */
  shouldShowNotifications() {
    return this.isNotificationsEnabled()
  }
}

export const handler = new BrowserNotificationsHandler()
