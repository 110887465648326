import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  useCalendarState,
  useSendCalendarState,
} from '~/areas/calendar/hooks/use-calendar-state'
import { setBaseDate } from '~/state/calendar/calendarSlice'
import { useAppDispatch } from '~/state/hooks'
import { DateTime } from 'luxon'

export const useCalendarDateNavigation = () => {
  const dispatch = useAppDispatch()
  const setCalendarState = useSendCalendarState()
  const calendarView = useCalendarView()
  const selectedDate = useBaseDate()

  const handleDateChange = (op: number) => {
    const days = calendarView === 'week' ? 7 : 1
    const newDate = selectedDate.plus({ days: days * op })

    setCalendarState({ selectedDate: newDate })
    dispatch(setBaseDate(newDate.toISO()))
    recordAnalyticsEvent('CALENDAR_CHANGE_BASE_DATE', {
      direction: op < 0 ? 'back' : 'forward',
      calendarView,
    })
  }

  const handleGoToToday = () => {
    const now = DateTime.now()

    setCalendarState({ selectedDate: now })
    dispatch(setBaseDate(now.toISO()))
    recordAnalyticsEvent('CALENDAR_CHANGE_BASE_DATE', {
      direction: 'today',
      calendarView,
    })
  }

  return {
    back: (num = 1) => handleDateChange(-num),
    today: () => handleGoToToday(),
    forward: (num = 1) => handleDateChange(num),
  }
}

const useCalendarView = () => {
  const [state] = useCalendarState()

  const { calendarView } = state

  return calendarView
}

const useBaseDate = () => {
  const [state] = useCalendarState()

  return state.selectedDate
}
