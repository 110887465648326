import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { createLookupByKey } from '@motion/utils/object'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'

import { type DropdownFilterProps } from '../../../../types'
import { UserFilterItem, type UserFilterValueProps } from '../../user-filter'
import { getNarrowedFilter } from '../utils'

type Props = DropdownFilterProps & {
  groupedFields: CustomFieldSchemaByType<'person'>[]
}

export const ConnectedCustomFieldPersonFilter = (props: Props) => {
  const { groupedFields } = props
  const firstField = groupedFields[0]
  const [value, setValue] = useCustomFieldFilter(props.target, firstField)
  const filter = getNarrowedFilter(value)

  const setFilter = (newFilter: UserFilterValueProps['filter']) => {
    if (newFilter == null || newFilter.value.length === 0) {
      setValue(null)
      return
    }
    const record = createLookupByKey(groupedFields, 'id', () => newFilter)
    setValue(record)
  }

  return (
    <UserFilterItem
      filter={filter}
      setFilter={setFilter}
      label={firstField.name}
      target={props.target}
      openOnMount={props.openOnMount}
      onBlur={props.onBlur}
      // TODO: Remove when we support null users
      hideEmptyUser
    />
  )
}
