import { getStageTense, isStageUnfit } from '@motion/ui-logic/pm/project'
import { type TaskSchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useProject } from '~/global/hooks'

import {
  UnfitBlockerChainTooltip,
  UnfitFallbackTooltip,
  UnfitStageTooltip,
} from './components'

import { useBlockerTasks } from '../../../hooks/helpers/use-blocker-tasks'
import { findUnfitBlockerChain } from '../../../utils'

export type UnfitTaskTooltipProps = {
  task: TaskSchema
}

export function UnfitTaskTooltip({ task }: UnfitTaskTooltipProps) {
  const project = useProject(task?.projectId)
  const lookup = useLookup()

  const { blockedBy: blockedByTasks, isLoading } = useBlockerTasks({
    taskId: task?.id,
  })

  if (isLoading) {
    return undefined
  }

  // Check if blocked on previous stage
  if (
    project != null &&
    task?.type === 'NORMAL' &&
    task.stageDefinitionId != null
  ) {
    const taskStageTense = getStageTense(project, task.stageDefinitionId)
    const isTaskStageInFuture = taskStageTense === 'future'
    const taskStageIndex = project.stages.findIndex(
      (s) => s.stageDefinitionId === task.stageDefinitionId
    )
    const isAnyPreviousStageUnfit =
      taskStageIndex > 0 &&
      project.stages.slice(0, taskStageIndex).some(isStageUnfit)

    if (isTaskStageInFuture && isAnyPreviousStageUnfit) {
      return <UnfitStageTooltip />
    }
  }

  // Check blocker chain tooltip
  const unfitBlockerChain = findUnfitBlockerChain(lookup, blockedByTasks)
  if (unfitBlockerChain.length > 0) {
    return <UnfitBlockerChainTooltip task={task} chain={unfitBlockerChain} />
  }

  return <UnfitFallbackTooltip />
}
