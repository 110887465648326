import { isNoneId } from '@motion/shared/identifiers'
import {
  getProjectDueDateDateChangedFields,
  isFlowProject,
} from '@motion/ui-logic/pm/project'
import { parseDate } from '@motion/utils/dates'
import { useHasTreatment } from '@motion/web-common/flags'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useProjectUpdater } from './use-project-updater'

import { useAdjustProjectDates } from '../actions/use-adjust-project-dates'

export function useProjectDeadlineUpdater() {
  const updateProject = useProjectUpdater()
  const adjustProjectDates = useAdjustProjectDates()
  const modalApi = useModalApi()
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')

  return useCallback(
    async (project: ProjectSchema, newDeadline: ProjectSchema['dueDate']) => {
      const currentProjectDueDate = project.dueDate
      if (currentProjectDueDate === newDeadline) return true
      if (
        currentProjectDueDate &&
        newDeadline &&
        parseDate(currentProjectDueDate).equals(parseDate(newDeadline))
      ) {
        return true
      }

      const updates = {
        dueDate: newDeadline,
        ...getProjectDueDateDateChangedFields({
          ...project,
          dueDate: newDeadline,
        }),
      }

      if (newDeadline == null || isNoneId(project.id)) {
        await updateProject(project, updates)
        return true
      }

      if (isFlowProject(project) && hasBetterResizeStages) {
        const response = await modalApi.prompt('update-project-date', {
          projectId: project.id,
          newDate: newDeadline,
          dateType: 'due',
        })

        if (
          response === ModalDismissed ||
          response.dateAdjustmentStrategy === 'SHIFT'
        ) {
          return false
        }

        await updateProject(project, {
          dueDate: newDeadline,
          ...getProjectDueDateDateChangedFields({
            ...project,
            dueDate: newDeadline,
          }),
          options: {
            dateAdjustmentStrategy: response.dateAdjustmentStrategy,
          },
        })
        return true
      }

      const formattedDeadline = isFlowProject(project)
        ? parseDate(newDeadline).toISODate()
        : parseDate(newDeadline).toISO()

      return adjustProjectDates(project.id, {
        ...(updates.startDate ? { startDate: updates.startDate } : {}),
        dueDate: formattedDeadline,
      })
    },
    [adjustProjectDates, hasBetterResizeStages, modalApi, updateProject]
  )
}
