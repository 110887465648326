import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useDoTaskASAP } from '~/areas/tasks/hooks'
import { useCallback } from 'react'

import { type ActionArgs } from './types'
import { useBulkUpdateRows } from './use-bulk-update-rows'

export const useUpdateTasksAsap = (args: ActionArgs) => {
  const { type, ...rest } = args
  const bulkUpdateRows = useBulkUpdateRows()
  const doTaskAsap = useDoTaskASAP()

  return useCallback(async () => {
    recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_DO_ASAP', {
      type,
      numTasks: rest.tasks.length,
    })

    if (rest.tasks.length === 1) {
      return doTaskAsap(rest.tasks[0].id)
    }

    return bulkUpdateRows({
      ...rest,
      updates: {
        type: 'bulk-field-update',
        priorityLevel: 'ASAP',
      },
    })
  }, [bulkUpdateRows, doTaskAsap, rest, type])
}
