import { useSharedState } from '@motion/react-core/shared-state'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  groupInto,
} from '@motion/utils/array'

import { StatusLabel } from '~/global/components/labels'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { GroupedFilterButton } from './grouped-filter-button'
import { WorkspaceItem, WorkspaceItemGroupHeader } from './workspace-item'

import { useFieldFilter } from '../../context'
import { buildIdFilter, useWorkspaceFilter } from '../../utils'
import { type DropdownFilterProps } from '../types'

export const ConnectedStatusFilter = (props: DropdownFilterProps) => {
  const [context] = useSharedState(AppWorkspaceContext)
  const [filter, setFilter] = useFieldFilter(props.target, 'statusIds')
  const byWorkspace = useWorkspaceFilter()
  const statuses = useMemo(() => {
    const all = context.statuses.all
      .filter(byWorkspace)
      .filter((x) => !x.deleted)
      .sort(
        cascade(
          byProperty('name', Compare.caseInsensitive),
          byValue(
            (item) => context.workspaces.byId[item.workspaceId].name,
            Compare.caseInsensitive
          )
        )
      )

    const groups = groupInto(all, (p) => p.name)
      .map((g) => ({
        key: g.key,
        items: g.items,
      }))
      .sort(byProperty('key', Compare.caseInsensitive))
    return {
      all,
      groups,
    }
  }, [byWorkspace, context.statuses.all, context.workspaces.byId])
  const selected =
    filter?.value.map((id) => context.statuses.byId[id]).filter(Boolean) ?? []

  return (
    <GroupedFilterButton
      groups={statuses.groups}
      selected={selected}
      onSelected={(value) => setFilter(buildIdFilter(value, filter))}
      label='Status'
      renderItem={({ item }) => (
        <WorkspaceItem item={item}>
          <StatusLabel value={item} />
        </WorkspaceItem>
      )}
      renderHeader={({ group }) => (
        <WorkspaceItemGroupHeader group={group}>
          <StatusLabel value={group.items[0]} />
        </WorkspaceItemGroupHeader>
      )}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      searchPlaceholder='Choose statuses ...'
    />
  )
}
