import { formatTime, templateStr } from '@motion/ui-logic'
import { isFlowProject } from '@motion/ui-logic/pm/project'

import { type ActivityFeedType } from '~/areas/activity-feed/types'
import { formatActivityMonthDay } from '~/areas/activity-feed/utils'
import { useProject } from '~/global/hooks'
import { useMemo } from 'react'

import { ActivityValue } from './activity-value'

type ActivityDateProps = {
  type: 'start' | 'due'
  targetId: string
  targetType: ActivityFeedType['type']
  oldTimestamp: string | null
  newTimestamp: string | null
}

export function ActivityDate({ targetType, ...rest }: ActivityDateProps) {
  if (targetType === 'task') {
    // Only keep time for task due dates
    return <InnerActivityDate {...rest} keepTime={rest.type === 'due'} />
  }
  return <ProjectActivityDate {...rest} />
}

function ProjectActivityDate({
  targetId,
  type,
  ...rest
}: Omit<ActivityDateProps, 'targetType'>) {
  const project = useProject(targetId)

  // We only care about the time for non-flows project's (https://usemotion.slack.com/archives/C07G9CKN5HB/p1725030852398199)
  return (
    <InnerActivityDate
      {...rest}
      type={type}
      keepTime={!isFlowProject(project) && type === 'due'}
    />
  )
}

type InnerActivityDateProps = Omit<
  ActivityDateProps,
  'targetId' | 'targetType'
> & {
  keepTime?: boolean
}

function InnerActivityDate({
  type,
  keepTime,
  oldTimestamp,
  newTimestamp,
}: InnerActivityDateProps) {
  const { dateFrom, dateTo } = useMemo(() => {
    let dateFrom = formatActivityMonthDay(oldTimestamp, keepTime)
    let dateTo = formatActivityMonthDay(newTimestamp, keepTime)

    if (
      dateFrom === dateTo &&
      dateFrom !== 'None' &&
      oldTimestamp &&
      newTimestamp
    ) {
      dateFrom += `, ${formatTime(oldTimestamp)}`
      dateTo += `, ${formatTime(newTimestamp)}`
    }

    return {
      dateFrom,
      dateTo,
    }
  }, [keepTime, newTimestamp, oldTimestamp])

  return templateStr(`changed {{type}} from {{dateFrom}} to {{dateTo}}`, {
    type: type === 'start' ? 'start date' : 'due date',
    dateFrom: <ActivityValue>{dateFrom}</ActivityValue>,
    dateTo: <ActivityValue>{dateTo}</ActivityValue>,
  })
}
