import { SearchableList } from '@motion/ui/base'
import { unassignedMember } from '@motion/ui-logic'
import { useModalApi } from '@motion/web-common/modals'
import { type TaskSchema } from '@motion/zod/client'

import { useWorkspaceById } from '~/global/hooks'

import { AssigneeDropdownBottomSection } from './assignee-dropdown-bottom-section'

import { type AssigneeDropdownOption } from '../hooks'
import { type SharedListProps } from '../types'

type MultiListContentProps = SharedListProps & {
  onChange: (assigneeIds: TaskSchema['assigneeUserId'][]) => void
}

export function MultiListContent({
  onChange,
  close,
  workspaceId,
  hideBottomActionsSection = false,
  renderContentTopSection,
  disableOptionFn,
  ...rest
}: MultiListContentProps) {
  const modalApi = useModalApi()
  const workspace = useWorkspaceById(workspaceId)

  if (workspace == null) return null

  const isTeamWorkspace = workspace.type === 'TEAM'

  function mapUsersToIdOrNull(
    items: AssigneeDropdownOption[]
  ): (string | null)[] {
    return items.map((item) =>
      item.userId === unassignedMember.userId ? null : item.userId
    )
  }

  return (
    <>
      {renderContentTopSection?.()}
      <SearchableList
        itemType='checkbox'
        {...rest}
        onSelect={(items) => {
          if (items.some((item) => disableOptionFn?.(item))) {
            return
          }

          onChange(mapUsersToIdOrNull(items))
        }}
      />
      {isTeamWorkspace && !hideBottomActionsSection && (
        <AssigneeDropdownBottomSection
          close={close}
          modalApi={modalApi}
          onChange={(assigneeId) =>
            onChange([...mapUsersToIdOrNull(rest.items), assigneeId])
          }
          workspace={workspace}
          workspaceId={workspaceId}
        />
      )}
    </>
  )
}
