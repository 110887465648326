import { createLookup } from '@motion/utils/object'
import { type DashboardViewChartSchema } from '@motion/zod/client'

import { ConnectedNumberChart, ConnectedPieChart } from '~/areas/charts'
import { ConnectedBarChart } from '~/areas/charts/components/connected-bar-chart'
import { ConnectedLineChart } from '~/areas/charts/components/connected-line-chart'

import { ChartQueryProvider } from '../../charts'

type ChartLookup = Record<DashboardViewChartSchema['type'], any>

export const chartComponentLookup = createLookup<
  ChartLookup & {
    default: null
  }
>({
  pie: ChartQueryProvider(ConnectedPieChart),
  number: ChartQueryProvider(ConnectedNumberChart),
  bar: ChartQueryProvider(ConnectedBarChart),
  line: ChartQueryProvider(ConnectedLineChart),
  default: null,
})
