import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'

import { type EventClickArg, type EventDropArg } from '@fullcalendar/core'
import { type EventResizeDoneArg } from '@fullcalendar/interaction'
import { useUpdateEvent } from '~/areas/event/hooks'
import { useEventModal } from '~/areas/event/modals/hooks'
import { useLookup } from '~/global/cache'
import { updateAvailabilityEvent } from '~/state/calendarEvents/calendarEventsSlice'
import { parseFullCalendarEventId } from '~/state/calendarEvents/calendarEventsUtils'
import { useAppDispatch } from '~/state/hooks'
import { useCallback } from 'react'

import { useSendCalendarState } from './use-calendar-state'
import { useCalendarTaskUpdater } from './use-calendar-task-updater'

export const useCalendarCommonHandlers = () => {
  const dispatch = useAppDispatch()
  const setCalendarState = useSendCalendarState()
  const calendarTaskUpdater = useCalendarTaskUpdater()
  const calendarEventUpdater = useUpdateEvent()

  const lookup = useLookup()
  const openEventModal = useEventModal()

  const onEventClick = useCallback(
    (arg: EventClickArg) => {
      const { type, id } = parseFullCalendarEventId(arg.event.id)
      if (type !== 'event') return

      const event = id ? lookup('calendarEvents', id) : null

      if (event != null) {
        openEventModal(event)
        return
      }
      Sentry.captureException('Calendar event not found', {
        tags: {
          position: 'onEventClick',
        },
        extra: {
          eventId: id,
        },
      })
    },
    [lookup, openEventModal]
  )

  const onDragStart = useCallback(() => {
    setCalendarState({
      isDraggingEvent: true,
    })
  }, [setCalendarState])

  const onDragStop = useCallback(() => {
    setCalendarState({
      isDraggingEvent: false,
    })
  }, [setCalendarState])

  const onEventUpdate = useCallback(
    async (arg: EventDropArg | EventResizeDoneArg) => {
      const { type, id } = parseFullCalendarEventId(arg.event.id)
      switch (type) {
        case 'new_event':
          setCalendarState({
            selectedCalendarEvent: {
              id: arg.event.id,
              new: {
                start: arg.event.startStr,
                end: arg.event.endStr,
                allDay: arg.event.allDay,
              },
            },
          })
          break
        case 'event':
          if (id == null) return
          const updatedEvent = calendarEventUpdater(id, {
            start: arg.event.startStr,
            end: arg.event.endStr,
          })
          if (!updatedEvent) {
            arg.revert()
          }
          break
        case 'task':
          // Don't allow moving tasks to all day section
          if (!id || arg.event.allDay) {
            arg.revert()
            return
          }
          const updatedTask = await calendarTaskUpdater(id, {
            start: arg.event.startStr,
            end: arg.event.endStr,
          })
          if (!updatedTask) {
            arg.revert()
          }
          recordAnalyticsEvent('CALENDAR_DRAGGED_ENTITY_TO_NEW_TIME', {
            type,
            completed: Boolean(updatedTask?.completedTime),
          })
          break
        case 'availability':
          if (!id) {
            break
          }
          dispatch(
            updateAvailabilityEvent({
              changes: {
                end: arg.event.endStr,
                start: arg.event.startStr,
              },
              id,
            })
          )
          break
      }
      if (type !== 'task') {
        recordAnalyticsEvent('CALENDAR_DRAGGED_ENTITY_TO_NEW_TIME', { type })
      }
    },
    [setCalendarState, calendarEventUpdater, calendarTaskUpdater, dispatch]
  )

  return {
    onEventClick,
    onDragStart,
    onDragStop,
    onDrop: onEventUpdate,
    onResize: onEventUpdate,
  } as const
}
