import { PlusSolid, PuzzleSolid } from '@motion/icons'
import { type TemplateProjectType } from '@motion/rpc/types'
import { Button } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useParams } from 'react-router-dom'

import { LoadingSvg } from '../../../../components/Common/Icons/LoadingSvg'
import {
  useDeleteProjectTemplate,
  useTemplatesByWorkspaceId,
} from '../../../global/rpc/templates'
import { SettingTableV2 } from '../../components'
import { EmptyContainer } from '../empty-container'

/**
 *  @deprecated - remove after flows-m5a is at 100% rollout
 */
export function DeprecatedProjectTemplateTable() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const { data: workspaceTemplates, isLoading } = useTemplatesByWorkspaceId(
    {
      workspaceId,
    },
    {
      enabled: !!workspaceId,
    }
  )

  const { mutateAsync: deleteProjectTemplate } = useDeleteProjectTemplate()
  const modalApi = useModalApi()

  const askConfirmation = (item: TemplateProjectType) => {
    return modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-project-template',
      },
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete template',
      destructive: true,
      title: `Are you sure you want to delete this template ${item.name}?`,
    })
  }

  const handleDeleteProjectTemplate = async (item: TemplateProjectType) => {
    const confirmed = await askConfirmation(item)
    if (confirmed !== ModalDismissed) {
      await deleteProjectTemplate({
        id: item.id,
        workspaceId: item.workspaceId,
      })
    }
  }

  return isLoading ? (
    <LoadingSvg />
  ) : (
    <SettingTableV2
      title='Regular Project Templates'
      emptyContainer={
        <EmptyContainer
          title='No regular project templates'
          description='Use templates to easily create common projects within your workspace.'
          icon={PuzzleSolid}
          action={{
            children: 'Create project template',
            icon: PlusSolid,
            url: 'project/new',
          }}
        />
      }
      items={(workspaceTemplates?.templateProjects ?? []).map((template) => ({
        id: template.id,
        label: template.name,
        icon: PuzzleSolid,
        onEditUrl: `project/${template.id}`,
        onDelete: () => handleDeleteProjectTemplate(template),
      }))}
      headerEndContent={
        <Button url='project/new' size='small'>
          <PlusSolid />
          Create regular template
        </Button>
      }
    />
  )
}
