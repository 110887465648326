import { Button, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema, type WorkspaceSchema } from '@motion/zod/client'

import {
  getGhostDisabledTooltipContent,
  WorkspaceSectionContainer,
  WorkspaceSectionTitle,
} from '~/areas/task-project/components'
import {
  StageField,
  type StageFieldProps,
  WorkspaceField,
  WorkspaceProjectField,
} from '~/areas/task-project/fields'
import {
  useMoveTaskToWorkspaceProject,
  useTaskStageUpdater,
} from '~/areas/tasks/hooks'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useProject, useWorkspaceById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventWorkspaceProjectField = () => {
  const context = useRouteAnalyticsMetadata()
  const { isLoading, initialMeetingTask, toggleSidePanel, sidePanelOpen } =
    useEventModalState()
  const { form } = useEventForm()

  const updateTaskStage = useTaskStageUpdater()
  const moveTaskTo = useMoveTaskToWorkspaceProject()

  const { field: workspaceIdField } = useController({
    name: 'workspaceId',
    control: form.control,
  })
  const { field: projectIdField } = useController({
    name: 'projectId',
    control: form.control,
  })
  const { field: stageIdField } = useController({
    name: 'stageDefinitionId',
    control: form.control,
  })

  const selectedWorkspace = useWorkspaceById(workspaceIdField.value)
  const selectedProject = useProject(projectIdField.value)

  if (
    isLoading ||
    workspaceIdField.value == null ||
    selectedWorkspace == null ||
    selectedProject == null
  ) {
    return null
  }

  const isUnvisitedStage = initialMeetingTask?.isUnvisitedStage ?? false

  const updateWorkspaceProject = async (
    newWorkspaceId: WorkspaceSchema['id'],
    newProjectId: ProjectSchema['id'] | null
  ) => {
    if (
      newWorkspaceId === selectedWorkspace.id &&
      newProjectId === selectedProject.id
    ) {
      return
    }

    if (initialMeetingTask != null) {
      return moveTaskTo(initialMeetingTask.id, newWorkspaceId, newProjectId)
    }

    if (newWorkspaceId !== workspaceIdField.value) {
      workspaceIdField.onChange(newWorkspaceId)
    }
    if (newProjectId !== projectIdField.value) {
      projectIdField.onChange(newProjectId)
    }
  }

  const onWorkspaceChange = (newWorkspace: WorkspaceSchema) =>
    updateWorkspaceProject(newWorkspace.id, null)

  const onWorkspaceProjectChange = (
    newWorkspaceId: string,
    newProjectId: string | null
  ) => updateWorkspaceProject(newWorkspaceId, newProjectId)

  const onStageChange: StageFieldProps['onChange'] = (stage) => {
    if (initialMeetingTask != null) {
      return updateTaskStage(initialMeetingTask.id, stage)
    }

    stageIdField.onChange(stage.id)
  }

  return (
    <WorkspaceSectionContainer>
      <WorkspaceSectionTitle
        showProject
        showStage={stageIdField.value != null}
      />

      <Tooltip
        content={
          !isUnvisitedStage ? null : getGhostDisabledTooltipContent('workspace')
        }
      >
        <WorkspaceField
          workspaceId={workspaceIdField.value}
          onChange={onWorkspaceChange}
        />
      </Tooltip>

      <Tooltip
        content={
          !isUnvisitedStage ? null : getGhostDisabledTooltipContent('project')
        }
      >
        <WorkspaceProjectField
          workspaceId={workspaceIdField.value}
          projectId={projectIdField.value}
          onChange={onWorkspaceProjectChange}
          hideNoProject
          disallowSelectWorkspace
          suffix={
            <Button
              size='xsmall'
              variant='outlined'
              sentiment='neutral'
              onClick={() => {
                toggleSidePanel()
                recordAnalyticsEvent(
                  sidePanelOpen ? 'TASK_SIDEBAR_CLOSE' : 'TASK_SIDEBAR_OPEN',
                  context
                )
              }}
            >
              {sidePanelOpen ? 'Close' : 'Open'}
            </Button>
          }
        />
      </Tooltip>

      {stageIdField.value != null && (
        <Tooltip
          content={
            initialMeetingTask == null
              ? 'This meeting event will be created in the project current stage'
              : null
          }
        >
          <StageField
            workspaceId={workspaceIdField.value}
            projectId={projectIdField.value}
            stageDefinitionId={stageIdField.value}
            onChange={onStageChange}
            disabled={initialMeetingTask == null}
          />
        </Tooltip>
      )}
    </WorkspaceSectionContainer>
  )
}
