import { UserLabel } from '~/global/components/labels'
import { type TaskWithRelations } from '~/global/proxies'

import { CellText } from './components'

type CreatedByCellProps = {
  value: TaskWithRelations['createdByUser']
}
export const CreatedByCell = ({ value }: CreatedByCellProps) => {
  return (
    <CellText>
      <UserLabel value={value} />
    </CellText>
  )
}
