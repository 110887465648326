import {
  API,
  type ApiTypes,
  createUseQueryLazy,
  useQueryOptionsFactory,
} from '@motion/rpc'
import { useStoreModelsFn } from '@motion/rpc-cache'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import { Sentry } from '@motion/web-base/sentry'

import { useQuery } from '@tanstack/react-query'
import { createScheduledEntityProxy } from '~/global/proxies'
import { useMemo } from 'react'

import { getAllTaskRelationIds } from './v2'

import { useLookup } from '../cache'

export type GetEntitiesApi = ApiTypes<
  typeof API.scheduledEntities.getScheduledEntities
>

// For search modal purposes
export const useGetScheduledEntitiesLazy = createUseQueryLazy(
  API.scheduledEntities.getScheduledEntities
)

export const useGetScheduledEntities = (
  args: GetEntitiesApi['args'],
  opts?: GetEntitiesApi['UseQueryOptions']
) => {
  const storeModels = useStoreModelsFn()

  const queryOptionsOf = useQueryOptionsFactory(
    API.scheduledEntities.getScheduledEntities
  )
  const queryArgs = queryOptionsOf(args, opts as any)

  return useQuery({
    notifyOnChangeProps: ['error', 'data', 'dataUpdatedAt'],
    ...queryArgs,
    async queryFn(ctx) {
      const value = await queryArgs.queryFn(ctx as any)
      storeModels(value.models)

      // Validate that all tasks received have all relations
      value.ids.forEach((entityId) => {
        const scheduledEntity = value.models.scheduledEntities[entityId]
        if (scheduledEntity.type !== 'TASK' && scheduledEntity.type !== 'CHUNK')
          return

        const task =
          value.models.tasks[scheduledEntity.id] ||
          value.models.recurringTasks[scheduledEntity.id]

        const ids = getAllTaskRelationIds(task)
        const idsMissing = ids.filter(
          (taskId) =>
            !value.models.tasks[taskId] && !value.models.recurringTasks[taskId]
        )

        if (idsMissing.length > 0) {
          Sentry.captureMessage(
            'Some tasks are missing in the schedule entities response',
            {
              extra: {
                baseTaskId: scheduledEntity.id,
                missing: JSON.stringify(idsMissing),
                query: JSON.stringify(ctx.queryKey),
                response: JSON.stringify(value),
              },
            }
          )
        }
      })

      return value
    },
  })
}

export const useGetScheduledEntitiesWithProxies = (
  args: GetEntitiesApi['args'],
  opts?: GetEntitiesApi['UseQueryOptions']
) => {
  const lookup = useLookup()

  const query = useGetScheduledEntities(args, opts)

  return useMemo(() => {
    if (!query.data) {
      return query
    }

    const scheduledEntities = query.data.ids
      .map((id) => {
        const scheduledEntity = query.data.models.scheduledEntities[id]
        return createScheduledEntityProxy(scheduledEntity, lookup)
      })
      .filter(Boolean)

    return {
      ...query,
      data: {
        scheduledEntities,
        ...query.data,
      },
    }
  }, [lookup, query])
}

export const useFilterScheduledEntitiesGhostTasks = (
  result: ReturnType<typeof useGetScheduledEntitiesWithProxies>,
  includeGhostTasks: boolean
) => {
  const lookup = useLookup()

  return useMemo(() => {
    if (!result.data?.scheduledEntities) return []

    return result.data.scheduledEntities.filter((entity) => {
      if (includeGhostTasks) return true

      const task = lookup('tasks', entity.id)

      // Include if cache miss or not an unvisited task
      return !task || !isGhostTask(task)
    })
  }, [result.data, includeGhostTasks, lookup])
}
