import { type TaskSchema } from '@motion/zod/client'

import { useAgendaTaskActions } from '~/areas/agenda/hooks'

import { TaskItemActionButton } from './task-item-action-button'

type StartTaskButtonProps = {
  taskId: TaskSchema['id']
}

export function StartTaskButton({ taskId }: StartTaskButtonProps) {
  const { startTask } = useAgendaTaskActions({
    taskId,
  })

  return (
    <TaskItemActionButton action='start-task' onClick={startTask}>
      Start task
    </TaskItemActionButton>
  )
}
