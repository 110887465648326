import { AutoScheduleToggle } from '@motion/ui/base'
import { getPrioritiesForTask } from '@motion/ui-logic'
import { type TaskDefaults } from '@motion/ui-logic/pm/task'

import { ProjectDropdown } from '~/areas/project-management/components/dropdowns'
import {
  CustomFieldUISwitch,
  ModalFieldButton,
} from '~/areas/task-project/components'
import {
  AssigneeField,
  LabelsField,
  PriorityField,
  StatusField,
} from '~/areas/task-project/fields'
import { Paragraph, SubParagraph } from '~/components/Common/Text'
import { SettingPage } from '~/components/Settings/Components/SettingPage'
import { MotionLink } from '~/global/components'
import { ProjectBadge } from '~/global/components/badges'
import { SelectedWorkspaceIdsContext } from '~/global/contexts'
import { useProject } from '~/global/hooks'
import { type FC } from 'react'

import {
  DueDateField,
  DurationField,
  HardDeadlineField,
  MinChunkDurationField,
  ScheduleField,
  StartDateField,
} from './fields'
import { WorkspaceField } from './fields/workspace-field'
import {
  useHiddenDisabledFields,
  useSelectedTaskDefaults,
  useUpdateTaskDefault,
} from './hooks'

export const TaskDefaultsSettingsPage: FC = () => {
  const selectedTaskDefaults = useSelectedTaskDefaults()

  const { workspaceId: selectedWorkspaceId } = selectedTaskDefaults

  return (
    <SelectedWorkspaceIdsContext
      key={selectedWorkspaceId}
      name='task-defaults'
      selectedWorkspaceIds={[selectedWorkspaceId]}
    >
      <SettingPage title='Task Defaults' className='gap-6'>
        <SubParagraph className='text-sm'>
          These defaults will be used when creating a task.
        </SubParagraph>

        <Fields selectedTaskDefaults={selectedTaskDefaults} />
      </SettingPage>
    </SelectedWorkspaceIdsContext>
  )
}

const Fields: FC<{ selectedTaskDefaults: TaskDefaults }> = ({
  selectedTaskDefaults,
}) => {
  const { workspaceId: selectedWorkspaceId, projectId: selectedProjectId } =
    selectedTaskDefaults
  const { hiddenFields, disabledFields } =
    useHiddenDisabledFields(selectedTaskDefaults)

  const updateTaskDefault = useUpdateTaskDefault(selectedTaskDefaults)

  const project = useProject(selectedProjectId)

  // This is created on user login, so we should always have a global default
  if (!selectedTaskDefaults) return <NotFound />

  const hasCustomFields =
    selectedTaskDefaults.customFieldValuesFieldArray?.length > 0

  return (
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col gap-1'>
        <WorkspaceField
          value={selectedWorkspaceId}
          onChange={(workspaceId) =>
            updateTaskDefault('workspaceId', workspaceId)
          }
        />

        <ProjectDropdown
          workspaceId={selectedWorkspaceId}
          selectedProjectId={selectedProjectId}
          onChange={(value) => updateTaskDefault('projectId', value)}
        >
          <ModalFieldButton
            label='Project'
            icon={<ProjectBadge value={project} />}
          >
            {project?.name ?? 'None'}
          </ModalFieldButton>
        </ProjectDropdown>
      </div>

      <div className='flex flex-col gap-1'>
        <AssigneeField
          onChange={(value) => updateTaskDefault('assigneeUserId', value)}
          value={selectedTaskDefaults.assigneeUserId}
          workspaceId={selectedWorkspaceId}
          removeUnassignedOption
        />

        <StatusField
          onChange={(value) => updateTaskDefault('statusId', value)}
          value={selectedTaskDefaults.statusId}
          workspaceId={selectedWorkspaceId}
          shouldExcludeResolved
          shouldExcludeCanceled
        />

        <PriorityField
          list={getPrioritiesForTask({ type: 'NORMAL' })}
          onChange={(value) => updateTaskDefault('priorityLevel', value)}
          value={selectedTaskDefaults.priorityLevel}
        />

        <LabelsField
          onChange={(value) => updateTaskDefault('labelIds', value)}
          selectedLabelIds={selectedTaskDefaults.labelIds}
          workspaceId={selectedWorkspaceId}
        />
      </div>

      <div className='flex flex-col gap-1'>
        <ModalFieldButton label='Auto-scheduled'>
          <AutoScheduleToggle
            labelHidden
            checked={selectedTaskDefaults.isAutoScheduled}
            disabled={disabledFields.has('isAutoScheduled')}
            onChange={(e) => {
              const { checked } = e.currentTarget
              updateTaskDefault('isAutoScheduled', checked)
            }}
          />
        </ModalFieldButton>

        <DurationField
          onChange={(value) => updateTaskDefault('duration', value)}
          value={selectedTaskDefaults.duration}
        />

        {!hiddenFields.has('minimumDuration') && (
          <MinChunkDurationField
            onChange={(value) => updateTaskDefault('minimumDuration', value)}
            value={selectedTaskDefaults.minimumDuration}
            duration={selectedTaskDefaults.duration}
          />
        )}

        {!hiddenFields.has('startDate') && (
          <StartDateField
            value={selectedTaskDefaults.relativeStartOn}
            onChange={(value) => updateTaskDefault('relativeStartOn', value)}
            disabled={disabledFields.has('startDate')}
          />
        )}

        <DueDateField
          value={selectedTaskDefaults.relativeDueDate}
          onChange={(value) => updateTaskDefault('relativeDueDate', value)}
          warnOnPastDueOnChange={(value) =>
            updateTaskDefault('ignoreWarnOnPastDue', !value)
          }
          selectedTaskDefaults={selectedTaskDefaults}
          disabled={disabledFields.has('dueDate')}
        />

        <HardDeadlineField
          value={selectedTaskDefaults.deadlineType}
          onChange={(value) => updateTaskDefault('deadlineType', value)}
          disabled={disabledFields.has('deadlineType')}
        />

        {!hiddenFields.has('scheduleId') && (
          <ScheduleField
            value={selectedTaskDefaults.scheduleId}
            onChange={(value) => updateTaskDefault('scheduleId', value)}
          />
        )}
      </div>

      {hasCustomFields && (
        <div className='flex flex-col gap-3 mt-3'>
          <h2 className='text-sm font-semibold'>Custom fields</h2>

          <div className='flex flex-col gap-1'>
            {selectedTaskDefaults.customFieldValuesFieldArray?.map(
              (customField) => {
                return (
                  <CustomFieldUISwitch
                    key={customField.instanceId}
                    customField={{
                      ...customField,
                      workspaceId: selectedWorkspaceId,
                    }}
                    value={customField.value}
                    onChange={(value: any) =>
                      updateTaskDefault('customFieldValues', {
                        [customField.instanceId]: {
                          type: customField.type,
                          value,
                        },
                      })
                    }
                  />
                )
              }
            )}
          </div>
        </div>
      )}
    </div>
  )
}

function NotFound() {
  return (
    <Paragraph className='text-sm'>
      No defaults found. Please contact support at{' '}
      <MotionLink url='https://help.usemotion.com' external>
        https://help.usemotion.com
      </MotionLink>
    </Paragraph>
  )
}
