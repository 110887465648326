import { type ScheduledEntitySchema } from '@motion/zod/client'

import { AgendaEvent } from './agenda-event'
import { AgendaTask } from './agenda-task'

type AgendaItemProps = {
  entity: ScheduledEntitySchema
}

export const AgendaItem = ({ entity }: AgendaItemProps) => {
  if (entity.type === 'TASK' || entity.type === 'CHUNK') {
    return <AgendaTask entity={entity} />
  } else if (entity.type === 'EVENT') {
    return <AgendaEvent entity={entity} />
  }

  return null
}
