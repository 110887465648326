import { type CalendarOptions } from '@fullcalendar/core'
import { useMemo } from 'react'

import { useScheduleAssistantCommonProps } from './use-calendar-common-props'
import { useCalendarHandlers } from './use-calendar-handlers'
import { useFullCalendarEvents } from './use-full-calendar-events'

import { ScheduleAssistantCalendarEventContainer } from '../calendar-event-container'

export function useScheduleAssistantCalendarProps(): CalendarOptions {
  const commonProps = useScheduleAssistantCommonProps()
  const events = useFullCalendarEvents()
  const handlers = useCalendarHandlers()

  return useMemo(() => {
    return {
      ...commonProps,
      events,
      eventContent: ScheduleAssistantCalendarEventContainer,
      // Clicking an event trigger eventClick.
      eventClick: handlers.onEventClick,
      // Triggered when an empty calendar slot is selected by dragging.
      select: handlers.onEmptySlotDragEnd,
      // Triggered when an empty calendar slot is clicked.
      dateClick: handlers.onEmptySlotClick,
      eventResize: handlers.onEventUpdate,
      eventDrop: handlers.onEventUpdate,
    }
  }, [
    commonProps,
    events,
    handlers.onEmptySlotClick,
    handlers.onEmptySlotDragEnd,
    handlers.onEventClick,
    handlers.onEventUpdate,
  ])
}
