import React, {
  type DependencyList,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react'

export function useLatestValue(
  value: useResizableProps,
  dependencies: DependencyList = [value]
) {
  const valueRef = useRef<useResizableProps>(value)

  useLayoutEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  return valueRef
}

type useResizableProps = {
  side?: 'left' | 'right'
  onResize: (change: number) => void
  onResizeStart: () => void
  onResizeEnd: (side?: 'left' | 'right') => void
}

export const useResizable = (props: useResizableProps) => {
  const [node, setNode] = React.useState<HTMLDivElement | null>(null)
  const deltaX = useRef<number>(0)
  const latestProps = useLatestValue(props, Object.values(props))

  const ref = React.useCallback((nodeEle: HTMLDivElement) => {
    setNode(nodeEle)
  }, [])

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!node) {
        return
      }
      latestProps.current.onResizeStart()
      const startPos = e.clientX
      const handleMouseMove = (e: MouseEvent) => {
        const dx = e.clientX - startPos

        deltaX.current = dx
        latestProps.current.onResize(deltaX.current)
      }

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
        latestProps.current.onResizeEnd(props.side)
        deltaX.current = 0
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    },
    [latestProps, node, props.side]
  )
  const handleTouchStart = React.useCallback(
    (e: TouchEvent) => {
      if (!node) {
        return
      }
      latestProps.current.onResizeStart()
      const touch = e.touches[0]
      const startPos = touch.clientX

      const handleTouchMove = (e: TouchEvent) => {
        const touch = e.touches[0]
        deltaX.current = touch.clientX - startPos
        latestProps.current.onResize(deltaX.current)
      }

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove)
        document.removeEventListener('touchend', handleTouchEnd)
        latestProps.current.onResizeEnd(props.side)
        deltaX.current = 0
      }

      document.addEventListener('touchmove', handleTouchMove)
      document.addEventListener('touchend', handleTouchEnd)
    },
    [latestProps, node, props.side]
  )
  React.useEffect(() => {
    if (!node) {
      return
    }

    node.addEventListener('mousedown', handleMouseDown)
    node.addEventListener('touchstart', handleTouchStart)
    return () => {
      node.removeEventListener('mousedown', handleMouseDown)
      node.removeEventListener('touchstart', handleTouchStart)
    }
  }, [node, handleMouseDown, handleTouchStart])

  return [ref]
}
