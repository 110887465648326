import { type NormalTaskSchema, type ProjectSchema } from '@motion/rpc-types'
import { ProjectColoredIcon } from '@motion/ui/project'

import { ConnectedStatusBadge } from '~/global/components/badges'

type AttachmentsGroupsTitleProps = {
  task?: NormalTaskSchema
  project?: ProjectSchema
  attachmentCount: number
}

export function AttachmentsGroupsTitle({
  task,
  project,
  attachmentCount,
}: AttachmentsGroupsTitleProps) {
  const name = project?.name ?? task?.name
  return (
    <div className='flex items-center gap-2 [&_[data-icon]]:!size-4'>
      {project && (
        <ProjectColoredIcon color={project.color} className='!size-4' />
      )}
      {task && <ConnectedStatusBadge id={task.statusId} size='normal' />}
      <span className='max-w-[205px] inline-flex'>
        <span className='text-ellipsis whitespace-nowrap overflow-hidden'>
          {name}
        </span>
        {attachmentCount > 0 && <span>&nbsp;({attachmentCount})</span>}
      </span>
    </div>
  )
}
