import * as userNotificationsService from './userService/userNotificationsService'
import * as userSubscriptionService from './userService/userSubscriptionsService'

import { checkStripeSubscriptionThrottled } from '../utils/stripe'

const doCheckStripeSubscription = async (force = false) => {
  if (force) {
    checkStripeSubscriptionThrottled.cancel()
  }
  const res = await checkStripeSubscriptionThrottled()
  return res
}

export class UserServiceHandler {
  static id = 'UserServiceHandler' as const

  getSubscription(force?: boolean) {
    return doCheckStripeSubscription(force)
  }

  async upgradeToAnnualPlan() {
    return await userSubscriptionService.upgradeToAnnualPlan()
  }

  async getNotificationPreferences() {
    return await userNotificationsService.get()
  }

  async updateNotificationPreferences(
    payload: userNotificationsService.UserNotificationsPayload
  ) {
    return await userNotificationsService.update(payload)
  }
}

export const handler = new UserServiceHandler()
