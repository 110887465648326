import { logEvent } from '@motion/web-base/analytics'

import { Events } from '~/analyticsEvents'
import { setZoomUpdated } from '~/state/calendar/calendarSlice'
import { store } from '~/state/proxy'

export const processZoomAuthObject = async (
  refreshZoomUserData: () => Promise<void>
) => {
  await refreshZoomUserData()
  void logEvent(Events.ZOOM_PERMISSIONS_AUTH)
  store.dispatch(setZoomUpdated(true))
}
