import { buildDateFilterQuery, isLogicalFilter } from '@motion/ui-logic/pm/data'
import { createLookupByKey } from '@motion/utils/object'
import {
  type IdFilterSchema,
  type ViewDateFilterSchema,
} from '@motion/zod/client'

import { type AddProjectState } from '~/areas/project/states'
import { type AddTaskState } from '~/areas/tasks/states'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { type GroupedNode } from '../../../grouping'
import {
  type DefaultGroupTaskParents,
  type GroupProjectParents,
  type GroupTaskParents,
} from '../../types'
import { getParentRowValues } from '../../utils'

export type TaskInferItem = {
  value: {
    items: TaskWithRelations[]
    addItemValue: TaskWithRelations | null
  }
  parents: GroupTaskParents
}

export const getTaskInferItem = ({
  row,
  addTaskState,
}: {
  row: GroupedNode
  addTaskState?: AddTaskState
}): TaskInferItem => {
  return {
    parents: createLookupByKey(getParentRowValues(row), 'type') as any,
    value: {
      items: row.children?.map((x) => x.value.value as TaskWithRelations) ?? [],
      addItemValue:
        addTaskState?.parentRowId === row.qualifiedKey
          ? addTaskState.task
          : null,
    },
  }
}

export type ProjectInferItem = {
  value: {
    items: ProjectWithRelations[]
    addItemValue: ProjectWithRelations | null
  }
  parents: GroupProjectParents
}

export const getProjectInferItem = ({
  row,
  addProjectState,
}: {
  row: GroupedNode
  addProjectState?: AddProjectState
}): ProjectInferItem => {
  return {
    parents: createLookupByKey(getParentRowValues(row), 'type') as any,
    value: {
      items:
        row.children?.map((x) => x.value.value as ProjectWithRelations) ?? [],
      addItemValue:
        addProjectState?.parentRowId === row.qualifiedKey
          ? addProjectState.project
          : null,
    },
  }
}

type ValueItem = (TaskInferItem | ProjectInferItem)['value']['items'][number]
type UnionKeys<T> = T extends { [key: string]: any } ? keyof T : never

export function makeIdFilterFunction<
  TFilter extends IdFilterSchema,
  TKey extends UnionKeys<ValueItem>,
  TItem extends Record<TKey, any>,
>(filter: TFilter | null, key: TKey) {
  return (items: TItem[]) => {
    // If filtering by at least one item, returning the first one
    if (
      filter != null &&
      filter.operator === 'in' &&
      !filter.inverse &&
      filter.value.length === 1 &&
      filter.value[0] != null
    ) {
      return filter.value[0]
    }

    // If all items have the same key value, we can use that
    if (items.length > 0 && typeof items[0] === 'object' && key in items[0]) {
      const value = items[0][key]
      if (
        !Array.isArray(value) &&
        items.every((t) => key in t && t[key] === value)
      ) {
        return value
      }
    }

    return null
  }
}

export function makeDateFilterFunction<
  TFilter extends ViewDateFilterSchema,
  TKey extends UnionKeys<ValueItem>,
  TItem extends Record<TKey, any>,
>(filter: TFilter | null, key: TKey) {
  return (items: TItem[]) => {
    if (filter == null) return null

    const normalized = buildDateFilterQuery(filter)
    if (normalized == null) return null

    if (normalized.operator === 'range') {
      return normalized.value.to
    }

    if (isLogicalFilter(normalized)) {
      return normalized.value
    }

    // If all items have the same key value, we can use that
    if (items.length > 0 && typeof items[0] === 'object' && key in items[0]) {
      const value = items[0][key]
      if (
        !Array.isArray(value) &&
        items.every((t) => key in t && t[key] === value)
      ) {
        return value
      }
    }

    return null
  }
}

export function findByName<T extends { name: string }>(
  list: T[],
  name: string | undefined
): T | undefined {
  if (name == null) return
  return list.find((i) => i.name === name)
}

export function findInParentGroups<T extends keyof DefaultGroupTaskParents>(
  type: T,
  groupedRow: GroupedNode
): DefaultGroupTaskParents[T]['value'] | null {
  const outerGroupedRows = getParentRowValues(groupedRow)

  const foundProjectRow = outerGroupedRows.find((row) => row.type === type)

  return (foundProjectRow?.value ?? null) as DefaultGroupTaskParents[T]['value']
}
