import { useModalApi } from '@motion/web-common/modals'

import api from '~/chromeApi/chromeApiContentScript'
import { showAvailabilityTimezone } from '~/components/Calendar/components/Main/utils'
import { useTimezoneSettings } from '~/global/hooks'
import { useUpdateMyTimezonesMutation } from '~/global/rpc/v2'
import { selectDragTimeslotsVisible } from '~/state/calendar/calendarSlice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import {
  selectAvailabilityTimezone,
  selectExtraTimezones,
  setExtraTimezones,
  setSecondaryTimezone,
} from '~/state/timezone-slice'
import { useMemo } from 'react'

export const useCalendarTimezones = (localTimezone: string) => {
  const dispatch = useAppDispatch()
  const modalApi = useModalApi()
  const { defaultTimezone, secondaryTimezone: secondaryTimezoneSelector } =
    useTimezoneSettings({ useDefaults: true })
  const { mutate: updateTimezoneSettings } = useUpdateMyTimezonesMutation()
  const dragTimeslotsVisible = useAppSelector(selectDragTimeslotsVisible)
  const extraTimezoneSelector = useAppSelector(selectExtraTimezones)
  const availabilityTimezoneSelector = useAppSelector(
    selectAvailabilityTimezone
  )

  const promptNewTimezone = async () => {
    return new Promise<string | null>((resolve) => {
      modalApi.prompt('timezone-picker', {
        onTimezoneChange: resolve,
      })
    })
  }

  const [secondaryTimezone, extraTimezones] = useMemo<
    [string | null | undefined, string[]]
  >(() => {
    if (
      showAvailabilityTimezone(
        dragTimeslotsVisible,
        availabilityTimezoneSelector,
        localTimezone
      )
    ) {
      return [availabilityTimezoneSelector, []]
    }

    const secondaryTimezone =
      secondaryTimezoneSelector === localTimezone
        ? null
        : secondaryTimezoneSelector

    return [secondaryTimezone, extraTimezoneSelector]
  }, [
    availabilityTimezoneSelector,
    dragTimeslotsVisible,
    extraTimezoneSelector,
    localTimezone,
    secondaryTimezoneSelector,
  ])

  const handleAddTimezone = async (): Promise<void> => {
    const newTimezone = await promptNewTimezone()
    if (!newTimezone || newTimezone === localTimezone) return

    if (!secondaryTimezone) {
      dispatch(setSecondaryTimezone(newTimezone))
      updateTimezoneSettings({
        secondaryTimezone: newTimezone,
      })
      return void api.storage.local.set({ calendarTimezone: newTimezone })
    }

    dispatch(setExtraTimezones([...extraTimezones, newTimezone]))
  }

  const handleRemoveTimezone = async (index: number) => {
    if (index === 0) {
      let newTimezone = ''
      // replace secondary timezone with first instance of extraTimezone
      if (extraTimezones.length > 0) {
        newTimezone = extraTimezones[0]
        // We'll remove the extra timezone later
      }

      // Save new timezone
      dispatch(setSecondaryTimezone(newTimezone))
      updateTimezoneSettings({
        secondaryTimezone: newTimezone,
      })
      void api.storage.local.set({ calendarTimezone: newTimezone })
    }

    const newExtraTimezones = [...extraTimezones]
    newExtraTimezones.splice(Math.max(0, index - 1), 1)
    dispatch(setExtraTimezones(newExtraTimezones))
  }

  const handleEditTimezone = async (index: number): Promise<void> => {
    const newTimezone = await promptNewTimezone()
    if (newTimezone === null) return

    if (index === 0) {
      if (newTimezone === defaultTimezone) {
        return void handleRemoveTimezone(index)
      }

      dispatch(setSecondaryTimezone(newTimezone))
      updateTimezoneSettings({
        secondaryTimezone: newTimezone,
      })

      return void api.storage.local.set({ calendarTimezone: newTimezone })
    }

    const newExtraTimezones = [...extraTimezones]
    newExtraTimezones[index] = newTimezone
    dispatch(setExtraTimezones(newExtraTimezones))
  }

  return {
    addTimezone: handleAddTimezone,
    editTimezone: handleEditTimezone,
    removeTimezone: handleRemoveTimezone,
    secondaryTimezone,
    extraTimezones,
  }
}
