import { type Row } from '@tanstack/react-table'
import { type CSSProperties, memo } from 'react'

import { type GroupedNode } from '../../grouping'
import { useViewState } from '../../view-state'
import { HeaderRow, SinglePlannerRow } from '../row'
import { OOOEventsRow } from '../row/planner-row/ooo-event-column/ooo-events-row'
import { LaneName } from '../row/planner-row/stacked-planner-row/lane-name'
import { MultiProjectLane } from '../row/planner-row/stacked-planner-row/multi-project-lane'
import {
  HEADER_ROW_HEIGHT,
  MULTI_PROJECT_ROW_HEIGHT,
  OOO_ROW_TYPE,
  STACKED_PROJECTS_ROW_TYPE,
  ZIndexMap,
} from '../shared-constants'

type RowContainerProps<T> = {
  row: Row<T>
  maxDepth: number
  toggleExpandAllRows?: (expand: boolean) => void
  rowAbove?: Row<T>
}

export const StackedRowContainer = memo(
  <T extends GroupedNode>(props: RowContainerProps<T>) => {
    const { row, rowAbove, maxDepth, toggleExpandAllRows } = props
    const depth = getRowDepth(row)
    const canExpand = row.getCanExpand()

    if (canExpand) {
      return (
        <HeaderLane
          row={row}
          rowAbove={rowAbove}
          maxDepth={maxDepth}
          toggleExpandAllRows={toggleExpandAllRows}
        />
      )
    }

    if (row.original.value.type === OOO_ROW_TYPE) {
      return <OOOEventsLane row={row} maxDepth={maxDepth} />
    }

    if (row.original.value.type === STACKED_PROJECTS_ROW_TYPE) {
      return <MultiProjectLane row={row} projects={row.original.items} />
    }

    return (
      <SinglePlannerRow
        row={row}
        visible
        style={
          {
            '--depth': depth,
          } as CSSProperties
        }
      />
    )
  }
)

StackedRowContainer.displayName = 'StackedRowContainer'

const HeaderLane = <T extends GroupedNode>(props: RowContainerProps<T>) => {
  const { row, maxDepth, rowAbove, toggleExpandAllRows = () => null } = props

  const [viewState] = useViewState()
  const stackProjects = viewState.groupBy.stackProjects

  const depth = getRowDepth(row)
  if (stackProjects && depth === maxDepth - 1) {
    const rowAboveType = rowAbove ? rowAbove.original.value.type : 'none'
    const height = rowAboveType === STACKED_PROJECTS_ROW_TYPE ? 8 : 1
    return (
      <div
        className='w-full relative flex bg-semantic-neutral-surface-overlay-bg-subtle'
        style={{
          height,
          background: height === 1 ? 'transparent' : undefined,
          zIndex: ZIndexMap.gapRow,
        }}
      >
        <LaneName row={row} topOffset={height} />
      </div>
    )
  }

  return (
    <HeaderRow
      row={row}
      toggleExpandAllRows={toggleExpandAllRows}
      style={
        {
          height: HEADER_ROW_HEIGHT,
          '--depth': depth,
        } as CSSProperties
      }
    />
  )
}

const OOOEventsLane = <T extends GroupedNode>(props: RowContainerProps<T>) => {
  const { row, maxDepth } = props
  const [viewState] = useViewState()
  const { stackProjects } = viewState.groupBy

  let eventHeight = 0
  for (const leaf of row.getParentRow()?.getLeafRows() || []) {
    if (leaf.original.value.type === STACKED_PROJECTS_ROW_TYPE) {
      eventHeight += MULTI_PROJECT_ROW_HEIGHT
    }
    if (leaf.getCanExpand()) {
      eventHeight += leaf.depth === maxDepth - 1 ? 8 : 36
    }
  }

  if (stackProjects) {
    eventHeight = Math.max(0, eventHeight - 8)
  }

  return (
    <div className='h-4'>
      <OOOEventsRow events={row.original.items} eventHeight={eventHeight} />
    </div>
  )
}

function getRowDepth<T extends GroupedNode>(row: Row<T>) {
  return row.getCanExpand() ? row.depth : Math.max(row.depth - 1, 0)
}
