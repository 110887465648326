import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { IntegrationBlock } from './integration-block'
import mail from './mail.svg'
import ZapierLogo from './zapier_logo.svg?url'

import { SettingPage } from '../../Components/SettingPage/SettingPage'

export const IntegrationSettings = () => {
  const modalApi = useModalApi()

  return (
    <SettingPage title='Integrations' className='gap-8'>
      <IntegrationBlock
        title='Zapier'
        bodyText='Use Zapier to integrate Motion with other apps.'
        logo={ZapierLogo}
        integrationButton={
          <Button
            external
            onClick={() => recordAnalyticsEvent('ZAPIER_INTEGRATION_CLICK')}
            url='https://zapier.com/apps/motion/integrations'
          >
            Connect
          </Button>
        }
        isBeta={false}
      />
      <IntegrationBlock
        title='Email'
        bodyText='Turn an email into a task'
        logo={mail}
        integrationButton={
          <Button
            onClick={() => {
              recordAnalyticsEvent('EMAIL_INTEGRATION_CLICK')
              void modalApi.open('email-integration')
            }}
          >
            See How
          </Button>
        }
        isBeta={false}
      />
    </SettingPage>
  )
}
