import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { RadioButton, type RadioButtonProps } from '@motion/ui/forms'

import { type ReactNode } from 'react'

type ModalRadioButtonProps = RadioButtonProps & {
  tooltipContent?: string
  radioTitle: string
  radioSubtitle?: string | ReactNode[]
}

export function ModalRadioButton({
  tooltipContent,
  radioTitle,
  radioSubtitle,
  checked,
  disabled,
  onChange,
  ...props
}: ModalRadioButtonProps) {
  return (
    <RadioButton
      align='start'
      checked={checked}
      disabled={disabled}
      label={
        <Tooltip asChild content={tooltipContent}>
          <RadioBody>
            <RadioTitle disabled={disabled}>{radioTitle}</RadioTitle>
            {radioSubtitle && (
              <RadioSubtitle disabled={disabled}>{radioSubtitle}</RadioSubtitle>
            )}
          </RadioBody>
        </Tooltip>
      }
      onChange={onChange}
      {...props}
    />
  )
}

const RadioBody = classed('div', {
  base: `
   flex flex-col justify-center items-start gap-1 flex-[1_0_0]
  `,
})

const RadioTitle = classed('span', {
  base: `
  text-form-text-default text-sm font-medium
  `,
  variants: {
    disabled: {
      true: `text-form-text-disabled`,
    },
  },
})

const RadioSubtitle = classed('span', {
  base: `
  text-semantic-neutral-text-subtle text-xs font-normal
  `,
  variants: {
    disabled: {
      true: `text-form-text-disabled`,
    },
  },
})
