// When adding a class here, add it to the settings.json file as well for tailwind intellisense

// Input container includes border, focus, active
export const inputContainerClasses =
  'bg-field-bg-default text-field-text-default border border-solid border-field-border-default hover:border-field-border-hover'
export const inputMutedContainerClasses =
  'border-transparent bg-transparent dark:border-transparent dark:bg-transparent hover:bg-light-100 dark:hover:bg-dark-1200 hover:border-light-900 dark:hover:border-dark-700'
// Both of the below are the same classes but focusEnabled relies on a disabled prop being applied to the same element
export const inputFocusClasses = `
  focus-visible:outline-0
  focus-visible:ring-2 focus-visible:ring-offset-1
  focus-visible:ring-offset-field-bg-default
  focus-visible:ring-field-border-focus`

export const inputTextClasses = 'text-light-1200 dark:text-dark-100'
export const inputUnselectedClasses = 'dark:text-dark-400 text-light-1100'
export const inputPlaceholderClasses =
  'dark:placeholder:text-dark-400 placeholder:text-light-1100'

export const chevronClasses = 'text-field-icon-selectChevron w-[18px] h-[18px]'
export const inputIconSizeClasses = 'h-4 w-4'
export const inputIconColorClasses = 'dark:text-dark-500 text-light-1100'

export const dropdownContainerClasses =
  'max-h-60 mt-1 w-full absolute z-10 overflow-auto shadow-lg border rounded text-sm'
export const dropdownContainerColorClasses =
  'dark:border-dark-700 dark:bg-dark-800 bg-light-100 border-light-500'
export const generalDropdownItemClasses =
  'h-8 relative cursor-pointer items-center px-2 py-1.5 text-sm dark:hover:bg-dark-100/5 hover:bg-light-300 focus:outline-primary-200 dark:text-dark-100 text-light-1200'
export const selectedDropdownItemClasses = 'dark:bg-dark-100/5 bg-light-300'

export const inputErrorClasses = 'border-alert-400 dark:border-alert-400'
export const inputContainerDisabledClasses =
  '[&>*]:opacity-50 cursor-not-allowed focus:outline-none active:outline-none hover:outline-none border-field-border-disabled hover:border-field-border-disabled bg-field-bg-disabled'
export const inputDisabledClasses =
  'disabled:cursor-not-allowed disabled:opacity-40'
