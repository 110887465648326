import { useOnValueChange } from '@motion/react-core/hooks'
import { Modal } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { TutorialList, TutorialViewer } from '~/areas/tutorials/components'
import {
  useActiveTutorialData,
  useCompletedTutorialPercentage,
} from '~/areas/tutorials/hooks'
import {
  useGetTutorialPayload,
  useGetUserTutorialCompletion,
  useUpdateTutorialCompletion,
} from '~/components/Settings/Pages/tutorials/rpc-hooks'
import { useMemo, useState } from 'react'

export type TutorialModalProps = {
  selectedLessonId?: string
}

export const TutorialModal = ({ selectedLessonId }: TutorialModalProps) => {
  const modalApi = useModalApi()
  const { data: tutorialData } = useGetTutorialPayload()
  const { mutateAsync: updateTutorialCompletion } =
    useUpdateTutorialCompletion()
  const { data: userCompletionData } = useGetUserTutorialCompletion()

  const completedLessonIds = userCompletionData?.completedTutorialIds ?? []
  const flatLessons =
    useMemo(() => {
      return tutorialData?.sections.flatMap((section) => section.lessons)
    }, [tutorialData?.sections]) ?? []
  const completedPercent = useCompletedTutorialPercentage()

  const [activeLessonId, setActiveLessonId] = useState(
    selectedLessonId ??
      (flatLessons.find((lesson) => !completedLessonIds.includes(lesson.id))
        ?.id ||
        flatLessons[0]?.id ||
        '')
  )

  useOnValueChange(completedPercent, () => {
    if (completedPercent === 100) {
      recordAnalyticsEvent('TUTORIALS_ALL_COMPLETED')
    }
  })

  const {
    activeLesson,
    activeSessionTitle,
    activeSectionIndex,
    activeLessonIndex,
  } = useActiveTutorialData(tutorialData, activeLessonId)

  function onNextLesson() {
    const currLessonIdx = flatLessons.findIndex(
      (lesson) => lesson.id === activeLessonId
    )
    if (currLessonIdx > -1 && currLessonIdx + 1 < flatLessons.length) {
      setActiveLessonId(flatLessons[currLessonIdx + 1].id)
    }
  }

  function onPrevLesson() {
    const currLessonIdx = flatLessons.findIndex(
      (lesson) => lesson.id === activeLessonId
    )
    if (currLessonIdx > 0) {
      setActiveLessonId(flatLessons[currLessonIdx - 1].id)
    }
  }

  async function onCompleteLesson() {
    await updateTutorialCompletion({ tutorialId: activeLessonId ?? '' })
    recordAnalyticsEvent('TUTORIALS_LESSON_COMPLETED', {
      lessonId: activeLessonId ?? '',
    })
  }

  function onSelectLesson(lessonId: string) {
    setActiveLessonId(lessonId)
  }

  if (!tutorialData?.sections.length) {
    return
  }

  return (
    <Modal visible onClose={() => modalApi.dismiss()}>
      <div className='grid grid-cols-[320px_minmax(0,600px)] w-full items-start gap-8 p-4 pt-6 pr-6 mx-auto'>
        <TutorialList
          sections={tutorialData.sections}
          completedLessonIds={completedLessonIds}
          activeSectionIndex={activeSectionIndex}
          activeLessonIndex={activeLessonIndex}
          onSelectLesson={onSelectLesson}
        />
        <TutorialViewer
          lesson={activeLesson}
          sectionTitle={activeSessionTitle}
          completed={completedLessonIds.includes(activeLessonId)}
          onNextLesson={onNextLesson}
          onPrevLesson={onPrevLesson}
          onCompleteLesson={onCompleteLesson}
        />
      </div>
    </Modal>
  )
}
