import { ScheduleAssistantCalendar } from './calendar'
import { ScheduleAssistantCalendarHeader } from './calendar-header'

export function ScheduleAssistantCalendarView() {
  return (
    <div className='flex flex-col'>
      <ScheduleAssistantCalendarHeader />
      <ScheduleAssistantCalendar />
    </div>
  )
}
