import { type PMTaskType, TaskType } from '@motion/rpc/types'
import {
  getDeadlineDateChangedFields,
  getDeadlineText,
} from '@motion/ui-logic/pm/task'

import { useCallback } from 'react'

import { useTemplateTaskUpdate } from './use-template-task-update'

export const useTemplateTaskDeadlinePicker = (task: PMTaskType) => {
  const updateTemplateTask = useTemplateTaskUpdate()

  const isASAPPriority = task?.priorityLevel === 'ASAP'

  const onChange = useCallback(
    (dueDate: string | null) => {
      const updates = {
        dueDate,
        ...getDeadlineDateChangedFields({ ...task, dueDate }),
      }

      void updateTemplateTask(task, updates)
    },
    [updateTemplateTask, task]
  )

  const deadlineStr = getDeadlineText(task)
  const disabled = task.type === TaskType.RECURRING_INSTANCE || isASAPPriority

  return {
    value: task.dueDate ?? null,
    onChange,
    deadlineStr,
    disabled,
  }
}
