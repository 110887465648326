import {
  type DashboardViewCellSchema,
  type DashboardViewDefinitionV2,
} from '@motion/zod/client'

import { useMemo } from 'react'
import { type Layout, type Layouts } from 'react-grid-layout'

import { type Breakpoints, chartDefaultLayoutSettings } from '../utils'

/**
 * Convert a dashboard view to a set of Layout for react-grid-layout.
 * `Layouts` is composed of arrays of `Layout` with different breakpoints.
 */
export function useViewToLayouts(view: DashboardViewDefinitionV2): Layouts {
  return useMemo(() => {
    const layout = cellsToLayout(view.cells)

    return {
      sm: convertLayoutToColumn(layout),
      md: layout,
      lg: layout,
    } satisfies Record<Breakpoints, Layout[]>
  }, [view])
}

function cellsToLayout(cells: DashboardViewCellSchema[]) {
  return cells.map((cell) => {
    const defaults = chartDefaultLayoutSettings[cell.chart.type]

    return {
      ...defaults,
      i: cell.id,
      h: cell.height,
      w: cell.width,
      x: cell.x,
      y: cell.y,
      cell,
    }
  })
}

function convertLayoutToColumn(layouts: Layout[], column = 1) {
  // TODO: Implement the conversion
  return layouts
}
