import { PlusSolid } from '@motion/icons'
import { type PMTaskStatusType } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type StatusIconProps } from '@motion/ui/pm'
import { useModalApi } from '@motion/web-common/modals'
import { type StatusSchema, type WorkspaceSchema } from '@motion/zod/client'

import {
  BaseMultiDropdown,
  type BaseMultiDropdownProps,
} from '~/global/components'
import { StatusLabel } from '~/global/components/labels'
import { useWorkspaceStatuses } from '~/global/hooks'
import { type ReactNode } from 'react'

type StatusType = PMTaskStatusType | StatusSchema

export type StatusDropdownProps = Omit<StatusDropdownContentProps, 'close'> & {
  children?: ReactNode
}

export const StatusDropdown = ({ children, ...rest }: StatusDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <StatusDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type StatusDropdownContentProps = {
  close: () => void
  onChange: (statusId: StatusSchema['id']) => void
  workspaceId: WorkspaceSchema['id']
  selectedStatusId: StatusSchema['id']
  showResolvedStatusFirst?: boolean
  excludeResolved?: boolean
  excludeCanceled?: boolean
  renderContentTopSection?: () => ReactNode
  iconVariant?: StatusIconProps['iconVariant']
  taskVariant?: StatusIconProps['taskVariant']
}
const StatusDropdownContent = ({
  close,
  onChange,
  workspaceId,
  selectedStatusId,
  showResolvedStatusFirst = false,
  excludeResolved = false,
  excludeCanceled = false,
  renderContentTopSection,
  iconVariant,
  taskVariant,
}: StatusDropdownContentProps) => {
  const modalApi = useModalApi()
  const statuses = useWorkspaceStatuses(workspaceId, {
    resolvedFirst: showResolvedStatusFirst,
    excludeResolved,
    excludeCanceled,
  })

  const openAddStatusModal = () => {
    setTimeout(async () => {
      await modalApi.prompt('add-status', {
        workspaceId,
        onValue: (createdStatus) => {
          onChange(createdStatus.id)
        },
      })
    }, 200)
  }

  return (
    <>
      {renderContentTopSection != null && (
        <StatusDropdownTopSection>
          {renderContentTopSection()}
        </StatusDropdownTopSection>
      )}
      <SearchableList
        searchable
        items={statuses}
        computeKey={(item) => item.id}
        computeSearchValue={(item) => item.name}
        computeSelected={(item) => item.id === selectedStatusId}
        onSelect={(item) => {
          close()
          onChange(item.id)
        }}
        renderItem={(item) => (
          <StatusLabel
            value={item}
            iconVariant={iconVariant}
            taskVariant={taskVariant}
          />
        )}
        inputProps={{ placeholder: 'Choose status...' }}
      />
      <div className='flex flex-col border-t border-dropdown-border mt-1'>
        <Button
          alignment='left'
          onClick={() => {
            close()
            openAddStatusModal()
          }}
          sentiment='neutral'
          variant='muted'
        >
          <PlusSolid />
          Add status
        </Button>
      </div>
    </>
  )
}

export type MultiStatusDropdownProps<T extends StatusType = PMTaskStatusType> =
  {
    items: T[]
    computeSelected: BaseMultiDropdownProps<T>['computeSelected']
    onChange: (items: T[]) => void
    children?: ReactNode
    footerActions?: BaseMultiDropdownProps<T>['footerActions']
  }

export const MultiStatusDropdown = <T extends StatusType = PMTaskStatusType>(
  props: MultiStatusDropdownProps<T>
) => {
  const { items, computeSelected, onChange, footerActions, children } = props

  return (
    <BaseMultiDropdown
      itemType='checkbox'
      searchable
      searchPlaceholder='Choose status...'
      items={items}
      computeKey={(item) => item.id}
      computeSelected={computeSelected}
      computeSearchValue={(item) => item.name}
      onSelect={onChange}
      renderItem={(item) => (
        <StatusLabel
          value={item as Pick<StatusSchema, 'name' | 'color' | 'type'>}
        />
      )}
      footerActions={footerActions}
    >
      {children}
    </BaseMultiDropdown>
  )
}

const StatusDropdownTopSection = classed('div', {
  base: 'flex flex-row items-center gap-1.5 text-2xs leading-4 text-semantic-neutral-text-subtle bg-semantic-neutral-surface-overlay-bg-subtle py-1.5 px-2',
})
