import { AgendaShell } from '~/areas/agenda'
import { AgendaView } from '~/areas/agenda/view'
import {
  BookingCreateLinkPage,
  BookingTutorialPage,
  CalendarShell,
  CalendarView,
} from '~/areas/calendar'
import { BookingEditLinkPage } from '~/areas/calendar/booking/pages/edit-link-page'
import { SidebarShell } from '~/areas/shell/sidebar-shell'
import { GlobalSidebar } from '~/areas/sidebar'
import { AttachmentPreviewPage } from '~/components/attachment-preview-page'
import { ConnectedAutoRefundPage } from '~/components/Settings/ConnectedAutoRefundPage'
import { ConnectedSettingsSidebar } from '~/components/Settings/Sidebar'
import { settingsRoutes } from '~/pages/settings/routes'
import { tutorialRoutes } from '~/pages/tutorials/routes'
import { redirect } from 'react-router'

import { type MotionRoute } from './api'

import { projectManagementRoutes } from '../areas/project-management/routes'
import { DesktopOnlyContainer } from '../components/DesktopOnly/DesktopOnly'
import { NotFoundPage } from '../components/MotionTab/NotFoundPage'
import { MotionTabShell } from '../components/MotionTab/Shell'
import { ResetPassword } from '../components/reset-password'
import { ConnectedTeamLandingPage } from '../components/Team'

export const routes: MotionRoute[] = [
  {
    path: 'web',
    element: <MotionTabShell />,
    loader: (args) => {
      if (
        args.request.url.endsWith('/web') ||
        args.request.url.endsWith('/web/')
      ) {
        return redirect('/web/calendar')
      }
      return {}
    },
    children: [
      // Routes with global left sidebar
      {
        element: <SidebarShell sidebar={<GlobalSidebar />} />,
        children: [
          {
            path: 'agenda',
            element: <AgendaShell />,
            tags: { page: 'agenda' },
            children: [
              {
                index: true,
                element: <AgendaView />,
              },
            ],
          },
          {
            path: 'calendar',
            element: <CalendarShell />,
            tags: { page: 'calendar' },
            children: [
              {
                index: true,
                element: <CalendarView />,
              },
              {
                path: 'booking/create-link',
                element: <BookingCreateLinkPage />,
              },
              {
                path: 'booking/new',
                element: <BookingEditLinkPage />,
              },
              {
                path: 'booking/:slug',
                element: <BookingEditLinkPage />,
              },
            ],
          },
          ...projectManagementRoutes,
          {
            path: 'booking',
            loader: () => {
              return redirect('/web/calendar?booking')
            },
          },
          ...tutorialRoutes,
          {
            path: 'booking_tutorial',
            element: <BookingTutorialPage />,
          },
          {
            path: 'scheduler_tutorial',
            loader: () => {
              return redirect('/web/booking_tutorial')
            },
          },
          { path: 'autorefund', element: <ConnectedAutoRefundPage /> },
          { path: 'reset_password', element: <ResetPassword /> },
          { path: 'team', element: <ConnectedTeamLandingPage /> },

          {
            id: 'not-found-shell',
            path: '*',
            element: <NotFoundPage path='/web/*' />,
          },
        ],
      },

      // Routes with settings sidebar
      {
        element: <SidebarShell sidebar={<ConnectedSettingsSidebar />} />,
        children: settingsRoutes,
      },

      // Routes without a global left sidebar
      {
        children: [
          {
            id: 'attachment-preview',
            path: 'attachments/:attachmentId',
            element: <AttachmentPreviewPage />,
          },
        ],
      },

      { path: 'desktop', element: <DesktopOnlyContainer /> },
    ],
    errorElement: <NotFoundPage path='/web/error' />,
  },

  {
    id: 'not-found',
    path: '*',
    element: <NotFoundPage path='/*' />,
    errorElement: <NotFoundPage path='/*/error' />,
  },
]
