import {
  EyeSolid,
  MenuAlt2Solid,
  PencilSolid,
  PlusSolid,
  TrashSolid,
  ViewBoardsSolid,
} from '@motion/icons'
import {
  ActionDropdown,
  type ActionItem,
  Button,
  type ButtonProps,
} from '@motion/ui/base'
import { byProperty, Compare, ordered } from '@motion/utils/array'

import { DEFAULT_VIEW_NAME, isDefaultView } from '../utils'

type ActionItemWithName = ActionItem & {
  name: string
}

type MinimalView = {
  id: string
  name: string
  isPrivate: boolean
} & (
  | { type: 'team-schedule' }
  | {
      type:
        | 'workspace'
        | 'all-tasks'
        | 'my-tasks'
        | 'task-instructions'
        | 'project-instructions'
      definition: { layout: string }
    }
)

type ProjectManagementViewDropdownProps<T extends MinimalView> = {
  views: T[]
  selected: T
  onSelect(view: T): void
  onDelete(view: T): void
  onEdit?(view: T): void
  onCreateNew(): void
  variant?: ButtonProps['variant']
}

export const ProjectManagementViewDropdown = <T extends MinimalView>(
  props: ProjectManagementViewDropdownProps<T>
) => {
  const {
    views,
    selected,
    onSelect,
    onDelete,
    onEdit,
    onCreateNew,
    variant = 'outlined',
  } = props
  const saveViewActionItem = {
    content: 'Create view',
    prefix: <PlusSolid />,
    onAction: onCreateNew,
  }

  function getViewActionItem(view: T): ActionItemWithName {
    return {
      name: view.name,
      content: view.name,
      onAction: () => onSelect(view),
      prefix: getIcon(view),
      suffix: <RowActions view={view} onDelete={onDelete} onEdit={onEdit} />,
    }
  }

  const { privateViews, workspaceViews } = views.reduce(
    (
      acc: {
        privateViews: ActionItemWithName[]
        workspaceViews: ActionItemWithName[]
      },
      view
    ) => {
      if (isDefaultView(view) || !view.isPrivate) {
        acc.workspaceViews.push(getViewActionItem(view))
      } else if (view.isPrivate) {
        acc.privateViews.push(getViewActionItem(view))
      }

      return acc
    },
    { privateViews: [], workspaceViews: [] }
  )

  const sections = [
    {
      items: workspaceViews.sort(
        byProperty(
          'name',
          ordered([DEFAULT_VIEW_NAME], Compare.caseInsensitive)
        )
      ),
    },
    {
      items: privateViews.sort(byProperty('name', Compare.caseInsensitive)),
      title: 'Personal views',
    },
    { items: [saveViewActionItem] },
  ]

  return (
    <ActionDropdown sections={sections}>
      <Button variant={variant} sentiment='neutral' size='small'>
        <EyeSolid />
        <div className='whitespace-nowrap'>{selected.name}</div>
      </Button>
    </ActionDropdown>
  )
}

function getIcon<T extends MinimalView>(view: T) {
  if (view.type === 'team-schedule') {
    return <EyeSolid />
  }

  const Icon =
    view.definition.layout === 'list' ? MenuAlt2Solid : ViewBoardsSolid
  return <Icon />
}

type RowActionsProps<T extends MinimalView> = {
  view: T
  onDelete(view: T): void
  onEdit?(view: T): void
}
function RowActions<T extends MinimalView>(props: RowActionsProps<T>) {
  const { view, onDelete, onEdit } = props
  if (isDefaultView(view)) return null

  return (
    <div>
      {onEdit && (
        <Button
          iconOnly
          onClick={(evt) => {
            evt.preventDefault()
            onEdit(view)
          }}
          size='small'
          sentiment='neutral'
          variant='muted'
        >
          <PencilSolid />
        </Button>
      )}
      <Button
        iconOnly
        onClick={(evt) => {
          evt.preventDefault()
          onDelete(view)
        }}
        size='small'
        sentiment='neutral'
        variant='muted'
      >
        <TrashSolid />
      </Button>
    </div>
  )
}
