import { classed } from '@motion/theme'

export const FieldInfoText = classed(
  'p',
  'max-w-[400px] text-xs text-semantic-neutral-text-subtle'
)

export const CustomFieldDisplay = classed(
  'div',
  `
  grid grid-cols-[16px_1fr]
  gap-3 items-center
  text-nowrap
  text-sm
  bg-semantic-neutral-surface-raised-bg-subtlest
  rounded-lg
  px-3 py-2
`
)

export const CustomFieldNameAndType = classed(
  'div',
  'grid grid-cols-[min-content_min-content] gap-1'
)
