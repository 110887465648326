import { TrashSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { forwardRef } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'

export type OptionRowProps = {
  icon: React.ReactNode
  onCancel(): void
  defaultValue?: string
} & UseFormRegisterReturn<`metadata.options.${number}.value`>

export const OptionRow = forwardRef<HTMLLabelElement, OptionRowProps>(
  function OptionRow({ icon, onCancel, defaultValue, ...props }, ref) {
    return (
      <div className='flex gap-3'>
        {icon}
        <div className='flex-grow flex [&>label]:flex-1'>
          <TextField
            placeholder='Option'
            size='normal'
            required
            {...props}
            ref={ref}
            defaultValue={defaultValue}
            onChange={(value) => {
              return props.onChange({ target: { value, name: props.name } })
            }}
          />
        </div>

        <Button onClick={onCancel} sentiment='neutral' variant='muted' iconOnly>
          <TrashSolid />
        </Button>
      </div>
    )
  }
)
