import { type NormalTaskSchema, type TaskSchema } from '@motion/rpc-types'
import { ConfirmationModal } from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import { templateStr } from '@motion/ui-logic'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useI18N } from '~/global/contexts'
import { useState } from 'react'

import { BlockersInfo } from './blockers-info'
import {
  Container,
  LabelContainer,
  Question,
  QuestionContainer,
} from './styled'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'complete-task-with-blockers': PromptCallbacks<Action> & {
      task: NormalTaskSchema
    }
  }
}

type Action = 'complete' | 'remove' | 'noop'

export const CompleteTaskWithBlockersModal = ({
  close,
  task,
}: ModalTriggerComponentProps<'complete-task-with-blockers'>) => {
  const [value, setValue] = useState<Action | null>(null)
  const { pluralize } = useI18N()

  return (
    <ConfirmationModal
      visible
      closeLabel='Cancel'
      description={
        <CompleteTaskWithBlockersModalBody
          value={value}
          setValue={setValue}
          task={task}
        />
      }
      action={{
        disabled: !value,
        onAction: () => value !== null && close(value),
        label: 'Continue',
      }}
      onClose={close}
      title={
        <div className='font-normal'>
          {templateStr('{{taskName}} has{{article}} uncompleted {{blocker}}:', {
            taskName: <span className='font-semibold'>{task.name}</span>,
            article: pluralize(task.blockingTaskIds.length, ' an', ''),
            blocker: pluralize(
              task.blockingTaskIds.length,
              'blocker',
              'blockers'
            ),
          })}
        </div>
      }
    />
  )
}

type CompleteTaskWithBlockersModalBodyProps = {
  value: Action | null
  setValue: (tasksAction: Action) => void
  task: TaskSchema
}

const CompleteTaskWithBlockersModalBody = ({
  value,
  setValue,
  task,
}: CompleteTaskWithBlockersModalBodyProps) => {
  return (
    <Container>
      <BlockersInfo task={task} />

      <QuestionContainer>
        <Question>
          What do you want to do for these uncompleted blockers?
        </Question>

        <RadioButton
          checked={value === 'noop'}
          onChange={() => setValue('noop')}
          align='start'
          renderLabel={() => (
            <LabelContainer>
              <span className='leading-3'>Do nothing</span>
              <span className='text-semantic-neutral-text-subtle text-xs'>
                Complete this task and leave blockers unaffected
              </span>
            </LabelContainer>
          )}
        />

        <RadioButton
          checked={value === 'complete'}
          onChange={() => setValue('complete')}
          align='start'
          renderLabel={() => (
            <LabelContainer>
              <span className='leading-3'>Complete all blockers</span>
              <span className='text-semantic-neutral-text-subtle text-xs'>
                Complete this task and all tasks that are blocking it
              </span>
            </LabelContainer>
          )}
        />

        <RadioButton
          checked={value === 'remove'}
          onChange={() => setValue('remove')}
          align='start'
          renderLabel={() => (
            <LabelContainer>
              <span className='leading-3'>Remove all blockers</span>
              <span className='text-semantic-neutral-text-subtle text-xs'>
                Complete this task and remove all blockers. Blockers&apos;
                statuses will remain unaffected
              </span>
            </LabelContainer>
          )}
        />
      </QuestionContainer>
    </Container>
  )
}
