export interface FakeWorker {
  onmessage: ((ev: MessageEvent<any>) => void) | null
  postMessage(message: any): void
  dispose?(): Promise<void>
}

export const webSide: FakeWorker = {
  onmessage: null,
  postMessage(message: any) {
    const ev = new MessageEvent('message', { data: message })
    workerSide.onmessage?.(ev)
  },
}

export const workerSide: FakeWorker = {
  onmessage: null,
  postMessage(message: any) {
    const ev = new MessageEvent('message', { data: message })
    webSide.onmessage?.(ev)
  },
}
