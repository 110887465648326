import { Button } from '@motion/ui/base'
import { byProperty, Compare } from '@motion/utils/array'

import { BaseMultiDropdown } from '~/global/components'
import { WorkspaceBadge } from '~/global/components/badges'
import { useAppWorkspaceContext } from '~/global/contexts'

import { useFieldFilter } from '../../../context'
import { buildIdFilter as buildIdFilter } from '../../../utils'

export const ConnectedWorkspaceFilter = () => {
  const [filter, setFilter] = useFieldFilter('workspaces', 'ids')

  const [shellContext] = useAppWorkspaceContext({ scope: 'shell' })

  const all = shellContext.workspaces.all.sort(
    byProperty('name', Compare.string)
  )

  const ids = filter?.value ?? null
  const text =
    ids == null || ids.length === 0 || ids.length === all.length
      ? 'All'
      : ids.length === 1
        ? (shellContext.workspaces.byId[ids[0]]?.name ?? '<No Access>')
        : `${ids.length} workspaces`

  const sentiment = text === 'All' ? 'neutral' : 'primary'

  return (
    <BaseMultiDropdown
      searchable
      computeSearchValue={(value) => value.name}
      computeSelected={(x) => (ids == null ? false : ids.includes(x.id))}
      computeKey={(x) => x.id}
      renderItem={(x) => <div>{x.name}</div>}
      onSelect={(items) => setFilter(buildIdFilter(items, filter))}
      searchPlaceholder='Search workspaces...'
      items={all}
      itemType='checkbox'
    >
      <Button sentiment={sentiment} variant='outlined' size='small'>
        <WorkspaceBadge />
        Workspace: {text}
      </Button>
    </BaseMultiDropdown>
  )
}
