import { useDebouncedCallback } from '@motion/react-core/hooks'
import type { Contact } from '@motion/rpc/types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useCalendarChanges } from '~/components/Calendar/components/Sidebar/SidebarMode/sidebar-calendars/use-calendar-changes'
import { selectNewTeamSearchValues } from '~/state/calendar-list/calendar-list-selectors'
import { updateSelectedTeammateContacts } from '~/state/calendar-list/calendar-list-thunks'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { useEffect } from 'react'

import { useAllCalendars } from './use-calendars'

export const useSelectTeammates = () => {
  const dispatch = useAppDispatch()

  const { calendars } = useAllCalendars()
  const { setCalendarChanges } = useCalendarChanges()
  const selectedTeammates = useAppSelector(selectNewTeamSearchValues)

  const handleSelectedTeammates = useDebouncedCallback(
    async (_: string[], options: Contact[] | undefined) => {
      const result = await dispatch(
        updateSelectedTeammateContacts({
          contacts: options ?? [],
          calendars,
        })
      ).unwrap()

      if (result != null) {
        result.forEach((updateDto) => {
          setCalendarChanges(updateDto)
        })
      }

      if (selectedTeammates.length < (options ?? []).length) {
        void recordAnalyticsEvent('CALENDAR_TEAMMATE_SEARCH_SELECT')
      }
    },
    1_000,
    { leading: true, maxWait: 1_000 }
  )

  // TODO: remove this once teammate contacts is removed from redux
  useEffect(() => {
    return () => {
      void dispatch(
        updateSelectedTeammateContacts({
          contacts: [],
          calendars,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return [selectedTeammates, handleSelectedTeammates] as const
}
