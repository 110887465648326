import { FormModal, showToast } from '@motion/ui/base'
import { type TaskSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import {
  type BulkDuplicateTaskType,
  useBulkDuplicateTask,
} from '~/areas/tasks/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { DuplicationConfigForm } from './components/duplication-config-form'
import { TypeSelectionStepForm } from './components/type-selection-step'

export type DuplicateFormSteps = 'type-selection' | 'duplication-config'

export interface TaskBulkDuplicateFormValues {
  title: string
  numberOfCopies: number
  selectedAssigneeIds: TaskSchema['assigneeUserId'][]
  type: BulkDuplicateTaskType
}

export const TaskBulkDuplicateModal = ({
  close,
  task,
}: ModalTriggerComponentProps<'task-bulk-duplicate'>) => {
  const [step, setStep] = useState<DuplicateFormSteps>('type-selection')

  const navigate = useNavigate()

  const form = useForm<TaskBulkDuplicateFormValues>({
    defaultValues: {
      title: `${task.name} (Duplicate)`,
      numberOfCopies: 2,
      selectedAssigneeIds: [],
    },
  })

  const type = form.watch('type')
  const selectedAssigneeIds = form.watch('selectedAssigneeIds')

  const bulkDuplicateTask = useBulkDuplicateTask(task)
  const buildTaskUrl = useTaskModalUrl()

  const handleFormAction = async (form: TaskBulkDuplicateFormValues) => {
    if (step === 'type-selection') {
      setStep('duplication-config')
    } else if (step === 'duplication-config') {
      const assignees =
        type === 'assignees'
          ? form.selectedAssigneeIds
          : Array.from({ length: form.numberOfCopies }, () => null)

      const result = await bulkDuplicateTask(assignees, {
        title: form.title,
        type: form.type,
      })

      if (result.status === 'success') {
        showToast(
          'neutral',
          <>
            Created {assignees.length} duplicates of:{' '}
            <span className='underline'>
              <Link to={buildTaskUrl({ task: task.id })}>{task.name}</Link>
            </span>
          </>
        )

        close()

        navigate(buildTaskUrl({ task: result.taskIds[0] }))
      } else if (result.failedAssigneeIds.length === assignees.length) {
        showToast('error', 'Failed to create duplicates')
      } else {
        showToast(
          'error',
          `Failed to create ${result.failedAssigneeIds.length} of ${assignees.length} duplicates`
        )
      }
    }
  }

  return (
    <FormProvider {...form}>
      <FormModal
        title='Bulk duplicate tasks'
        visible
        onClose={() => close()}
        submitAction={{
          onAction: form.handleSubmit(handleFormAction),
          disabled:
            (step === 'type-selection' && !type) ||
            (step === 'duplication-config' &&
              type === 'assignees' &&
              !selectedAssigneeIds.length),
          text: 'Continue',
        }}
      >
        {step === 'type-selection' ? (
          <TypeSelectionStepForm />
        ) : step === 'duplication-config' ? (
          <DuplicationConfigForm task={task} />
        ) : null}
      </FormModal>
    </FormProvider>
  )
}
