import { SearchOutline } from '@motion/icons'
import { Modal } from '@motion/ui/base'
import { logEvent } from '@motion/web-base/analytics'

import type { ModalTriggerComponentProps } from '~/areas/modals'
import { Input } from '~/components/Common'
import { useTimezoneSettings } from '~/global/hooks'
import { getTzAbbr } from '~/utils/time'
import { DateTime } from 'luxon'
import { createRef, useCallback, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useFavoriteTimezones, useTimezoneDB } from './use-timezones'

import { Events } from '../../../analyticsEvents'

type TimezonePickerPromptCallback = string | null

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'timezone-picker': PromptCallbacks<TimezonePickerPromptCallback> & {
      clearableTimezoneValue?: string | null
      onTimezoneChange?: (timezone: string | null) => void
      showDefault?: boolean
    }
  }
}

interface TimezoneType {
  otherAbbreviations: string[]
  favorite: boolean
  name: string
  alternativeName: string
  group: string[]
  continentCode: string
  continentName: string
  countryName: string
  countryCode: string
  mainCities: string[]
  rawOffsetInMinutes: number
  abbreviation: string
  rawFormat: string
  formattedName: string
}

interface TimezoneWithContent {
  continent: string
  timezones: TimezoneType[]
}

export const TimezonePickerModal = ({
  close,
  clearableTimezoneValue,
  onTimezoneChange = () => void 0,
  showDefault,
}: ModalTriggerComponentProps<'timezone-picker'>) => {
  const { defaultTimezone } = useTimezoneSettings({ useDefaults: true })
  const [searchString, setSearchString] = useState('')

  const timezoneDB = useTimezoneDB()
  const favoriteTimezonesData = useFavoriteTimezones()
  const [timezoneList, setTimezoneList] = useState(favoriteTimezonesData)

  const [selectedIdx, setSelectedIdx] = useState(0)
  const [timezoneRefs, setTimezoneRefs] = useState<any[]>([])
  const firstMouseEnteredRef = useRef(false)

  const handleClose = useCallback(() => {
    close()
  }, [close])

  useEffect(() => {
    void logEvent(Events.CALENDAR_TIMEZONE_SHOW)
  }, [])

  useEffect(() => {
    const s = searchString.trim().toLowerCase()
    if (!s || s.length === 1) {
      setTimezoneList(favoriteTimezonesData as TimezoneWithContent[])
      setSelectedIdx(0)
    } else {
      const filteredTimezones = timezoneDB
        .map((continentGroup) => ({
          continent: continentGroup.continent,
          timezones: continentGroup.timezones
            .filter(
              (tz) =>
                tz.formattedName.toLowerCase().includes(s) ||
                tz.countryName.toLowerCase().includes(s) ||
                tz.alternativeName.toLowerCase().includes(s) ||
                tz.continentName.includes(s) ||
                tz.mainCities.some((city) => city.toLowerCase().includes(s)) ||
                tz.abbreviation.toLowerCase().includes(s) ||
                tz.otherAbbreviations.some((abbr) =>
                  abbr.toLowerCase().includes(s)
                )
            )
            .sort((a, b) => (a === b ? 0 : a ? -1 : 1)),
        }))
        .filter((continent) => continent.timezones.length)
      setTimezoneList(filteredTimezones)
      const count = filteredTimezones.reduce(
        (acc, cur) => acc + cur.timezones.length,
        0
      )
      setSelectedIdx((prev) => (prev >= count ? 0 : prev))
    }
  }, [favoriteTimezonesData, searchString, timezoneDB])

  useEffect(() => {
    setTimezoneRefs((prev) =>
      [
        ...Array(
          timezoneList.reduce((acc, cur) => acc + cur.timezones.length, 0)
        ),
      ].map((_, i) => prev[i] || createRef())
    )
  }, [timezoneList])

  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent) => {
      if (timezoneList.length) {
        switch (e.key) {
          case 'ArrowUp': {
            e.preventDefault()
            const newIdx =
              selectedIdx === 0
                ? timezoneRefs.length - 1
                : (selectedIdx - 1) % timezoneRefs.length
            setSelectedIdx(newIdx)
            firstMouseEnteredRef.current = false
            timezoneRefs[newIdx].current &&
              timezoneRefs[newIdx].current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              })
            break
          }
          case 'ArrowDown': {
            e.preventDefault()
            const newIdx = (selectedIdx + 1) % timezoneRefs.length
            setSelectedIdx(newIdx)
            firstMouseEnteredRef.current = false
            timezoneRefs[newIdx].current &&
              timezoneRefs[newIdx].current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
              })
            break
          }
          case 'Enter': {
            e.preventDefault()
            if (timezoneRefs.length > selectedIdx) {
              timezoneRefs[selectedIdx].current &&
                timezoneRefs[selectedIdx].current.click()
              handleClose()
            }
            break
          }
          default:
            break
        }
      }
    },
    [timezoneList.length, handleClose, selectedIdx, timezoneRefs]
  )

  return (
    <Modal onClose={close} visible>
      <div
        className='divide-light-400 flex flex-col gap-2 divide-y dark:divide-transparent w-[600px]'
        onKeyDown={keyDownHandler}
      >
        <div className='p-3'>
          <Input
            autoFocus
            value={searchString}
            placeholder='Search location or time zone'
            onChange={(e) => setSearchString(e.target.value)}
            icon={SearchOutline}
            iconAlign='left'
            className='text-lg hover:border-transparent hover:outline-none focus:border-transparent focus:outline-none dark:hover:border-transparent dark:focus:border-transparent'
            inputClassName='text-lg hover:border-transparent hover:outline-none focus:border-transparent focus:outline-none dark:hover:border-transparent dark:focus:border-transparent !ml-3'
            iconClassName='h-5 w-5 mr-4'
            muted
          />
        </div>
        <div className='grid h-[500px] overflow-y-auto'>
          {timezoneList.length ? (
            <div className='dark:bg-dark-1000 flex w-full flex-col gap-2 p-3'>
              {clearableTimezoneValue && (
                <div
                  className='dark:hover:bg-dark-900 hover:bg-light-300 flex cursor-pointer justify-between p-3'
                  onClick={() => {
                    if (onTimezoneChange) {
                      onTimezoneChange(null)
                    }
                    handleClose()
                  }}
                >
                  <div className='text-base'>
                    Clear ({getTzAbbr(clearableTimezoneValue)})
                  </div>
                </div>
              )}
              {showDefault && defaultTimezone && (
                <div
                  className='dark:hover:bg-dark-900 hover:bg-light-300 flex cursor-pointer justify-between p-3'
                  onClick={() => {
                    if (onTimezoneChange) {
                      onTimezoneChange('default')
                    }
                    handleClose()
                  }}
                >
                  <div className='text-base'>
                    Default ({getTzAbbr(defaultTimezone)})
                  </div>
                </div>
              )}
              {timezoneList.map((group, groupIdx) => (
                <div
                  className='flex flex-col gap-1'
                  key={`continent-${group.continent}`}
                >
                  <div className='dark:text-dark-400 px-3 text-sm font-semibold'>
                    {group.continent}
                  </div>
                  {group.timezones.map((timezone, timezoneIdx) => {
                    const itemIdx =
                      timezoneIdx +
                      timezoneList
                        .slice(0, groupIdx)
                        .reduce((acc, cur) => acc + cur.timezones.length, 0)
                    return (
                      <div
                        key={`timezone-${group.continent}-${timezone.name}`}
                        className={twMerge(
                          'dark:hover:bg-dark-900 dark:focus:bg-dark-900 hover:bg-light-300 focus:bg-light-300 flex cursor-pointer items-center justify-between rounded p-3',
                          selectedIdx === itemIdx &&
                            'dark:bg-dark-900 bg-light-300'
                        )}
                        ref={timezoneRefs[itemIdx]}
                        onMouseEnter={() => {
                          if (firstMouseEnteredRef.current) {
                            setSelectedIdx(itemIdx)
                          } else {
                            firstMouseEnteredRef.current = true
                          }
                        }}
                        onClick={() => {
                          if (onTimezoneChange) {
                            onTimezoneChange(timezone.name)
                          }
                          handleClose()
                        }}
                      >
                        <div className='flex items-center gap-3'>
                          <div className='text-semantic-neutral-text-default text-base font-semibold'>
                            {timezone.abbreviation}
                          </div>
                          <div className='flex items-center gap-1 min-w-0 truncate max-w-md'>
                            {searchString.trim() && (
                              <div
                                className={twMerge(
                                  'text-semantic-neutral-text-disabled',
                                  searchString.trim() === '' &&
                                    'max-w-full truncate'
                                )}
                              >
                                {timezone.countryName} /
                              </div>
                            )}
                            <div
                              className={twMerge(
                                'text-semantic-neutral-text-subtle',
                                searchString.trim() && 'truncate font-bold'
                              )}
                            >
                              {timezone.mainCities.join(', ')}
                            </div>
                          </div>
                        </div>
                        <div className='shrink-0 text-semantic-neutral-text-subtle'>
                          {DateTime.now()
                            .setZone(timezone.name)
                            .toFormat('hh:mm a')}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          ) : (
            <div className='w-full pt-8 text-center text-semantic-neutral-text-subtle'>
              No results
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
