import { FlagSolid } from '@motion/icons'

import { PriorityLabel } from '~/global/components/labels'

import { BulkPriorityDropdown, FieldButton } from '../components'
import { useBulkOpsField } from '../contexts'

export function PriorityField() {
  const [priority, setPriority] = useBulkOpsField('priorityLevel')

  const active = priority != null

  return (
    <BulkPriorityDropdown
      onChange={setPriority}
      selectedPriorityLevel={priority}
    >
      <FieldButton
        active={active}
        analyticsName='priority'
        onRemove={() => setPriority(undefined)}
      >
        {active ? (
          <PriorityLabel value={priority} />
        ) : (
          <>
            <FlagSolid />
            Priority
          </>
        )}
      </FieldButton>
    </BulkPriorityDropdown>
  )
}
