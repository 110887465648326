import { classed } from '@motion/theme'

export const EventContainer = classed('div', {
  base: 'flex h-auto w-full items-center justify-between gap-2 pl-3 pr-2 py-1 rounded border-l-[4px]',
  variants: {
    eventType: {
      task: `bg-semantic-neutral-surface-overlay-bg-subtlest border-calendar-palette-highlight-default`,
      calendar: `bg-calendar-palette-bg-default border-calendar-palette-border-default`,
      none: 'border-l-0 bg-semantic-neutral-surface-overlay-bg-subtlest py-1.5',
    },
  },
})

export const EventContentContainer = classed(
  'button',
  'flex flex-col gap-1 items-start overflow-hidden min-w-0 leading-3 flex-1'
)

export const EventHeader = classed('span', {
  base: 'max-w-full truncate font-semibold text-xs text-semantic-neutral-text-default',
  variants: {
    eventType: {
      calendar: 'text-calendar-palette-text-default',
    },
  },
})

export const EventMessage = classed('span', {
  base: 'max-w-full truncate text-[10px] text-semantic-neutral-text-subtle',
  variants: {
    eventType: {
      calendar: 'text-calendar-palette-text-default',
      none: 'text-wrap text-xs text-center',
    },
  },
})

export const EventOptionsContainer = classed(
  'div',
  'flex items-center gap-1 py-[5px]'
)

export const EventConferenceContainer = classed('div', {
  base: 'inline-flex',
  variants: {
    callType: {
      meet:
        '[&_a]:border-semantic-meet-bg-default [&_a]:bg-semantic-meet-bg-default ' +
        '[&_a]:hover:bg-semantic-meet-bg-hover [&_a]:hover:border-semantic-meet-bg-hover',
      hangoutsMeet:
        '[&_a]:border-semantic-meet-bg-default [&_a]:bg-semantic-meet-bg-default ' +
        '[&_a]:hover:bg-semantic-meet-bg-hover [&_a]:hover:border-semantic-meet-bg-hover',
      teamsForBusiness:
        '[&_a]:border-semantic-teams-bg-default [&_a]:bg-semantic-teams-bg-default ' +
        '[&_a]:hover:bg-semantic-teams-bg-hover [&_a]:hover:border-semantic-teams-bg-hover',
      none: '',
    },
  },
  defaultVariants: {
    callType: 'none',
  },
})
