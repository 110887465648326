import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { AvailabilityCalendarListPickerModal } from '~/components/Booking/components/Modals/availability-calendar-list-picker-modal/availability-calendar-list-picker-modal'
import { DefaultSelectTrigger } from '~/components/Common/Select/Select'
import { useContext, useState } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const AvailabilityAttendanceRequirementsRow = () => {
  const {
    template: {
      state: { conflictCalendars, selectedConflictCalendars, guests },
      onSelectedConflictCalendars,
    },
  } = useContext(BookingTemplateContext)
  const [calListPickerVisible, setCalListPickerVisible] = useState(false)

  const showCalendarListPicker = () => {
    setCalListPickerVisible(true)
    recordAnalyticsEvent('CALENDAR_AVAILABILITIES_TEMPLATE_CALENDARS_EDIT')
  }

  const conflictCalendarTitles = conflictCalendars
    .map(({ email, title }) =>
      title !== email ? `${title} (${email})` : title
    )
    .join(', ')

  return (
    <>
      <SectionRow
        title='Attendance requirements'
        subtitle="We'll check for conflicts from all selected calendars"
      >
        <div>
          <DefaultSelectTrigger
            selectedLabel={conflictCalendarTitles}
            placeholder='Select calendars to check for conflicts'
            onClick={showCalendarListPicker}
          />
        </div>
      </SectionRow>

      {calListPickerVisible && (
        <AvailabilityCalendarListPickerModal
          onClose={() => setCalListPickerVisible(false)}
          onSelectedCalendars={onSelectedConflictCalendars}
          selectedCalendars={selectedConflictCalendars}
          selectedGuests={guests}
        />
      )}
    </>
  )
}
