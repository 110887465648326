import { LabelsField, type LabelsFieldProps } from '~/areas/task-project/fields'
import { useTaskLabelsUpdater } from '~/areas/tasks/hooks'
import { useController } from 'react-hook-form'

import { useTaskForm } from '../hooks'

export const TaskLabelsField = () => {
  const { form, disabledFields } = useTaskForm()
  const updateTaskLabels = useTaskLabelsUpdater()

  const { control, watch } = form

  const { field } = useController({
    name: 'labelIds',
    control,
  })

  const taskId = watch('id')
  const selectedLabelIds = watch('labelIds')
  const workspaceId = watch('workspaceId')

  const disabled = disabledFields.has('labelIds')

  const onChange: LabelsFieldProps['onChange'] = (labelIds) => {
    if (taskId != null) {
      return updateTaskLabels(taskId, labelIds)
    }

    field.onChange(labelIds)
  }

  return (
    <LabelsField
      selectedLabelIds={selectedLabelIds}
      workspaceId={workspaceId}
      onChange={onChange}
      disabled={disabled}
    />
  )
}
