import { isObjectNoneId } from '@motion/shared/identifiers'
import {
  getQuickActionsForDeadline,
  getQuickActionsForStartDate,
  prettyDateDay,
  type QuickAction,
} from '@motion/ui-logic'
import {
  type NormalTaskSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { useCalendarStartDay } from '~/areas/calendar/hooks'
import { type StageDateRangeColorData } from '~/areas/flows'
import {
  ContextAwareDateDropdownContent,
  type ContextAwareDateDropdownContentProps,
} from '~/areas/project-management/components'
import {
  type ProjectDateQuickActionInput,
  useProjectDateQuickActions,
  useProjectDatesContextData,
} from '~/areas/project-management/hooks'
import { useTaskWithParent } from '~/areas/tasks/hooks'
import { StageBadge } from '~/global/components/badges'
import { useProject, useStageDefinition } from '~/global/hooks'
import { DateTime } from 'luxon'

export type ProjectAwareTaskContextProps = {
  taskId: TaskSchema['id'] | null | undefined
}

export type ProjectAwareProjectContextProps = {
  projectId: ProjectSchema['id'] | null | undefined
  stageDefinitionId?: StageDefinitionSchema['id'] | null | undefined
}

export type ProjectAwareContextProps =
  | ProjectAwareTaskContextProps
  | ProjectAwareProjectContextProps

export type DateDropdownTarget = 'task' | 'project' | 'stage'
export type DateDropdownType = 'start-date' | 'deadline'

export type ProjectAwareDateDropdownContentProps =
  ContextAwareDateDropdownContentProps & {
    contextProps: ProjectAwareContextProps
    dropdownTarget?: DateDropdownTarget
    dropdownType: DateDropdownType
    additionalQuickActions?: QuickAction[]
    overrides?: {
      stageDateRangeColors?: StageDateRangeColorData[]
    }
  }

export function ProjectAwareDateDropdownContent({
  contextProps,
  dropdownTarget,
  dropdownType,
  onChange,
  additionalQuickActions,
  overrides,
  ...props
}: ProjectAwareDateDropdownContentProps) {
  const { project, stageDefinition } =
    useProjectAwareDateContextValues(contextProps)

  const quickActions = useProjectAwareQuickActions({
    project,
    stageDefinition,
    dropdownTarget,
    dropdownType,
    additionalQuickActions,
  })

  const calendarStartDay = useCalendarStartDay()

  const { stageDateRangeColors } = useProjectDatesContextData(project)

  const finalDateRangeColors =
    overrides?.stageDateRangeColors ?? stageDateRangeColors

  return (
    <ContextAwareDateDropdownContent
      {...props}
      onChange={(value) => onChange(value)}
      calendarStartDay={calendarStartDay}
      formatDateDisplay={(date) => prettyDateDay(date)}
      dateRangeColors={finalDateRangeColors}
      renderHeaderSuffix={
        stageDefinition != null && !isObjectNoneId(stageDefinition)
          ? () => <StageBadge value={stageDefinition} />
          : undefined
      }
      quickActions={quickActions}
    />
  )
}

type ProjectAwareDateContextValues = {
  task?: TaskSchema | null
  project?: ProjectSchema | null
  stageDefinition?: StageDefinitionSchema | null
}

function useProjectAwareDateContextValues(
  contextProps: ProjectAwareContextProps
): ProjectAwareDateContextValues {
  const taskId = 'taskId' in contextProps ? contextProps.taskId : null

  const { task, parentTask } = useTaskWithParent({ taskId })
  const taskToUse: NormalTaskSchema | null =
    task?.type === 'NORMAL'
      ? task
      : parentTask?.type === 'NORMAL'
        ? parentTask
        : null

  const projectIdToUse =
    'projectId' in contextProps ? contextProps.projectId : taskToUse?.projectId

  const stageDefinitionIdToUse =
    'stageDefinitionId' in contextProps
      ? contextProps.stageDefinitionId
      : taskToUse?.stageDefinitionId

  const project = useProject(projectIdToUse)
  const stageDefinition = useStageDefinition(stageDefinitionIdToUse)

  return {
    task: taskToUse,
    project,
    stageDefinition,
  }
}

type UseProjectAwareQuickActionsProps = {
  project?: ProjectDateQuickActionInput | null
  stageDefinition?: StageDefinitionSchema | null
  dropdownTarget?: DateDropdownTarget
  dropdownType: DateDropdownType
  additionalQuickActions?: QuickAction[]
}

function useProjectAwareQuickActions({
  project,
  stageDefinition,
  dropdownTarget,
  dropdownType,
  additionalQuickActions = [],
}: UseProjectAwareQuickActionsProps) {
  const { projectQuickActions, stageQuickActionsByStageDefinitionId } =
    useProjectDateQuickActions(project)
  const todayStartOfDay = DateTime.now().startOf('day')

  const addProjectActions = (quickActions: QuickAction[]) => {
    const additionalAction =
      dropdownType === 'deadline'
        ? projectQuickActions.deadline
        : projectQuickActions.start

    if (additionalAction == null) return

    quickActions.push(additionalAction)
  }

  const addTaskActions = (quickActions: QuickAction[]) => {
    const stageQuickActions =
      stageDefinition?.id != null
        ? stageQuickActionsByStageDefinitionId?.[stageDefinition?.id]
        : undefined

    if (stageQuickActions == null) return

    const additionalAction =
      dropdownType === 'deadline'
        ? stageQuickActions.deadline
        : stageQuickActions.start
    quickActions.push(additionalAction)
  }

  switch (dropdownTarget) {
    case 'project':
      addProjectActions(additionalQuickActions)
      break
    case 'stage':
      addProjectActions(additionalQuickActions)
      break
    case 'task':
      addProjectActions(additionalQuickActions)
      addTaskActions(additionalQuickActions)
      break
    default:
      break
  }

  return dropdownType === 'deadline'
    ? getQuickActionsForDeadline(todayStartOfDay, additionalQuickActions)
    : getQuickActionsForStartDate(todayStartOfDay, additionalQuickActions)
}
