import { getSelectedRole } from '~/areas/flows/flow-template-modal/utils'
import { useEffect, useMemo } from 'react'

import { useStageTaskField } from './use-stage-task-field'

import { useFlowTemplateForm } from '../../../../hooks'
import { roleToFlowTemplateAssignee } from '../../utils'

export function useFlowTemplateRoles(
  taskBasePath: `stages.${number}.tasks.${number}`
) {
  const {
    form: { watch, setValue },
  } = useFlowTemplateForm()
  const [assigneeVariableKey] = useStageTaskField(
    `${taskBasePath}.assigneeVariableKey`
  )

  const roles = watch('roles')
  const flowTemplateRoles = roles.map(roleToFlowTemplateAssignee)
  const selectedRole = useMemo(
    () => getSelectedRole(flowTemplateRoles, assigneeVariableKey),
    [flowTemplateRoles, assigneeVariableKey]
  )

  useEffect(() => {
    if (selectedRole == null) {
      setValue(`${taskBasePath}.assigneeVariableKey`, null)
    }
  }, [selectedRole, setValue, taskBasePath])

  return { flowTemplateRoles, selectedRole }
}
