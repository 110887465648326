import {
  type Calendar,
  type TemporaryCalendar,
  type UpdateCalendarDto,
} from '@motion/rpc/types'
import { FormModal } from '@motion/ui/base'
import { logEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { type CalendarSchema } from '@motion/zod/client'

import { useUpdateCalendars } from '~/global/rpc/v2'
import { useCallback, useEffect, useState } from 'react'

import { useAddContactCalendar } from './hooks/use-add-contact-calendar'
import { useCalendarList } from './hooks/use-calendar-list'

import { Events } from '../../../../../analyticsEvents'
import { useDeleteAccount } from '../../../../../hooks/use-delete-account'
import { useStateObject } from '../../../../../hooks/use-state-object'
import {
  selectCalendarListPickerModal,
  setCalendarListPickerModal,
} from '../../../../../state/calendar-list/calendar-list-slice'
import { type CalendarListPickerSectionType } from '../../../../../state/calendar-list/calendar-list-types'
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks'
import { addAccount } from '../../../../../utils/auth'
import { FlatCalendarListPicker } from '../../../../calendar-list-pickers/flat-calendar-list-picker/flat-calendar-list-picker'
import { GroupedCalendarListPicker } from '../../../../calendar-list-pickers/grouped-calendar-list-picker/grouped-calendar-list-picker'
import { Paragraph, SubParagraph } from '../../../../Common'
import { ContactsAutocomplete } from '../../../../contacts-autocomplete'

const resolveCheckedCalendarField = (
  section?: CalendarListPickerSectionType
): 'isInMyCalendars' | 'isInFrequentlyMet' => {
  switch (section) {
    case 'mine':
      return 'isInMyCalendars'
    default:
      return 'isInFrequentlyMet'
  }
}

export const CalendarListPickerModal = () => {
  const dispatch = useAppDispatch()
  const { mutateAsync: updateCalendars } = useUpdateCalendars()

  const {
    calendars,
    emailAccounts,
    emailAccountsMap,
    mainEmailAccountId,
    onCreateNewCalendars,
  } = useCalendarList()

  const { section, visible: calListPickerModalVisible } = useAppSelector(
    selectCalendarListPickerModal
  )

  // The field on a calendar that determines whether the calendar is selected
  const checkedCalendarField = resolveCheckedCalendarField(section)

  // Tracks calendars that have been checked by the user. This allows us
  // to batch apply the changes to the backend
  const [checkedItems, setCheckedItems] = useStateObject<
    Record<string, boolean>
  >({})
  const getCalendarKey = useCallback(
    (calendar: Calendar | CalendarSchema) =>
      `${calendar.emailAccountId}${calendar.id}`,
    []
  )
  const setCalendarChecked = useCallback(
    (calendar: Calendar | CalendarSchema, checked: boolean) => {
      setCheckedItems(getCalendarKey(calendar), checked)
    },
    [setCheckedItems, getCalendarKey]
  )

  // Tracks saving state of the modal to prevent double saves
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const {
    isCheckingCalendarAccess,
    onContactSearched,
    permissionWarnings,
    resetState: resetAddContactCalendarState,
    selectedContacts,
    temporaryCalendars,
  } = useAddContactCalendar({
    calendars,
    createNewCalendars: true,
    emailAccounts,
    onCreateNewCalendars,
    section,
    setCalendarChecked,
  })

  useEffect(
    // temporary fix until this is moved off redux. we need to wait until calendars are created
    // before we can check them
    function checkNewContacts() {
      selectedContacts.forEach((contact) => {
        const calendar = calendars.find((c) => c.providerId === contact)
        if (calendar) {
          setCalendarChecked(calendar, true)
        }
      })
    },
    [selectedContacts, calendars, setCalendarChecked]
  )

  const allCalendars = [...calendars, ...temporaryCalendars] as Calendar[]

  const visibleHandler = (visible: boolean) => {
    dispatch(setCalendarListPickerModal({ visible }))
    if (!visible) {
      resetAddContactCalendarState()
      setCheckedItems({})
      setIsSaving(false)
    }
  }

  const reconnectAccount = () => {
    void logEvent(Events.CALENDAR_AUTHLESS_ACCOUNT_SIGNIN)
    addAccount()
  }

  const save = () => {
    if (isSaving) {
      return
    }

    const changes: UpdateCalendarDto[] = []
    const calendarsMap = new Map<string, TemporaryCalendar>(
      allCalendars.map((c) => [getCalendarKey(c), c])
    )

    // Iterate through all checked items, and generate a changes DTO containing
    // all the calendars that were changed, including temporary calendars
    // added via contacts search.
    for (const [key, checked] of Object.entries(checkedItems)) {
      const calendar = calendarsMap.get(key)
      if (!calendar) {
        continue
      }

      if (calendar[checkedCalendarField] === checked) {
        continue
      }

      changes.push({
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        [checkedCalendarField]: checked,
        isNew: calendar.isTemporary ? true : undefined,
        title: calendar.isTemporary ? calendar.title : undefined,
      })
    }

    logInDev(changes)

    setIsSaving(true)
    void updateCalendars({ calendars: changes })
      .then(() => {
        void logEvent(Events.CALENDAR_GROUP_MODAL_SAVE, { section })
        visibleHandler(false)
        return true
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const removeEmailAccount = useDeleteAccount({
    event: Events.CALENDAR_REMOVE_ACCOUNT_CLICK,
  })

  const resolveCheckedState = (calendar: Calendar | CalendarSchema) => {
    // Primary main calendar CANNOT be unchecked
    if (calendar.emailAccountId === mainEmailAccountId && calendar.isPrimary) {
      return true
    }

    // Return user-set value
    const key = getCalendarKey(calendar)
    if (key in checkedItems) {
      return checkedItems[key]
    }

    return calendar[checkedCalendarField]
  }

  // Buffer checked status of calendar in react state
  const onCalendarChecked = (
    calendar: Calendar | CalendarSchema,
    checked: boolean
  ) => {
    setCalendarChecked(calendar, checked)
  }

  // If the calendars in redux get updated, update the react state to match
  // the latest values
  useEffect(
    function updateCheckedItemsState() {
      for (const calendar of calendars) {
        const key = getCalendarKey(calendar)
        if (!(key in checkedItems)) {
          continue
        }

        const actualValue = calendar[checkedCalendarField]
        if (checkedItems[key] !== actualValue) {
          setCalendarChecked(calendar, actualValue)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendars, section, setCalendarChecked]
  )

  const calendarsInSection = allCalendars.filter((c) => {
    // Always render calendars that are currently in "My Calendars" or
    // "Frequently Met With"
    if (c[checkedCalendarField]) {
      return true
    }

    // Additionally show calendars that have been selected from the Grouped
    // Calendar List Picker
    const key = getCalendarKey(c)
    return key in checkedItems && checkedItems[key]
  })

  const title = section === 'mine' ? 'My Calendars' : 'Frequently Met With'

  return (
    <FormModal
      visible={calListPickerModalVisible}
      onClose={() => visibleHandler(false)}
      submitAction={{
        text: 'Save',
        onAction: save,
        disabled:
          isSaving || !!permissionWarnings.length || isCheckingCalendarAccess,
      }}
      title={`Edit ${title}`}
    >
      <div className='flex h-[400px] min-w-[400px] w-auto flex-col overflow-auto'>
        <ContactsAutocomplete
          teamValues={selectedContacts}
          contactsSource='team'
          teamSelectHandler={onContactSearched}
          placeholder='Search calendars'
          className='mb-2 [&_input]:w-full'
        />
        {permissionWarnings.length > 0 && (
          <SubParagraph className='mb-2 !text-semantic-error-text-default'>
            {permissionWarnings.join('\n')}
          </SubParagraph>
        )}
        <Paragraph className='pb-2 font-medium'>{title}</Paragraph>
        <div className='mb-2 flex w-full flex-col'>
          <FlatCalendarListPicker
            checkboxMode='normal'
            items={calendarsInSection}
            emailAccountsMap={emailAccountsMap}
            mainEmailAccountId={mainEmailAccountId ?? 'none'}
            onCalendarChecked={onCalendarChecked}
            onCalendarUpdated={() => undefined}
            resolveCheckedState={resolveCheckedState}
            showOptions={false}
          />
        </div>
        <div className='border-light-400 dark:border-dark-800 flex w-full flex-col gap-1 border-t py-2'>
          <GroupedCalendarListPicker
            calendars={allCalendars}
            emailAccounts={emailAccounts}
            isInModal
            mainEmailAccountId={mainEmailAccountId ?? emailAccounts[0]?.id}
            onCalendarChecked={onCalendarChecked}
            onCalendarUpdated={() => undefined}
            onRemoveEmailAccount={removeEmailAccount}
            onStartEmailAuth={reconnectAccount}
            resolveCheckedState={resolveCheckedState}
          />
        </div>
      </div>
    </FormModal>
  )
}
