import {
  CheckCircleSolid,
  FastForwardSolid,
  RewindSolid,
  StatusCanceledSolid,
} from '@motion/icons'
import { type ActionSection } from '@motion/ui/base'
import { getStageTense, getStageVariant } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import {
  useCancelStage,
  useCompleteStage,
  useMoveProjectToStage,
} from '~/areas/project/hooks'
import { useRouteAnalyticsMetadata } from '~/global/analytics'

import { IconContainer } from './styled'

export function useStageHeaderContextMenuSections(
  project: ProjectSchema,
  projectStage: StageSchema,
  stageDefinition: StageDefinitionSchema
) {
  const context = useRouteAnalyticsMetadata()
  const moveProjectToStage = useMoveProjectToStage()
  const completeProjectStage = useCompleteStage()
  const cancelProjectStage = useCancelStage()
  const stageTense = getStageTense(project, projectStage.stageDefinitionId)
  const stageVariant = getStageVariant(projectStage)

  const GoToStageIcon = stageTense === 'past' ? RewindSolid : FastForwardSolid
  const goToStageText =
    stageTense === 'past' ? 'Go back to stage' : 'Advance to this stage'

  const sections: ActionSection[] = []

  if (stageTense !== 'current') {
    sections.push({
      items: [
        {
          content: goToStageText,
          prefix: <GoToStageIcon />,
          onAction: () => {
            recordAnalyticsEvent('TASK_SIDEBAR_MOVE_STAGE', {
              ...context,
              stageTense,
            })
            moveProjectToStage(project, stageDefinition.id)
          },
        },
      ],
    })
  }

  if (stageVariant === 'completed' || stageVariant === 'skipped') {
    return sections
  }

  sections.push({
    items: [
      {
        content: 'Complete all tasks in stage',
        description: 'This will complete all incomplete tasks in this stage',

        prefix: (
          <IconContainer>
            <CheckCircleSolid className='!text-semantic-success-bg-strong-default' />
          </IconContainer>
        ),
        onAction: () => {
          completeProjectStage(project, stageDefinition.id)
        },
      },
      {
        content: 'Cancel tasks in stage',
        description:
          'This will set the status of all incomplete tasks in this stage to be Canceled',

        prefix: (
          <IconContainer>
            <StatusCanceledSolid />
          </IconContainer>
        ),
        onAction: () => {
          cancelProjectStage(project, stageDefinition.id)
        },
      },
    ],
  })

  return sections
}
