import { type CustomFieldSchema } from '@motion/shared/custom-fields'
import { showToast } from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useDeleteCustomFieldMutation } from './rpc'

export const useDeleteCustomField = () => {
  const modalApi = useModalApi()
  const { mutateAsync: deleteCustomField } = useDeleteCustomFieldMutation()

  return useCallback(
    async ({
      workspaceId,
      customField,
    }: {
      workspaceId: string
      customField: Pick<CustomFieldSchema, 'id' | 'name' | 'type'>
    }) => {
      try {
        const truncatedName = truncateAtSpace(customField.name, 20)

        const response = await modalApi.prompt('confirm-delete-item', {
          analytics: {
            name: 'delete-custom-fields',
          },
          titleValue: truncatedName,
          description: (
            <div className='flex flex-col gap-4'>
              <p>This field will be removed from all tasks in the workspace.</p>
              <p>
                <ul className='list-disc list-inside indent-2'>
                  <li>
                    List Views will have the <strong>{customField.name}</strong>{' '}
                    column automatically removed.
                  </li>
                  <li>
                    Kanban Board Cards will not display{' '}
                    <strong>{customField.name}</strong>.
                  </li>
                  <li>
                    If <strong>{customField.name}</strong> is being used as a
                    filter in any Saved View, it will no longer be filtering by{' '}
                    <strong>{customField.name}</strong>.
                  </li>
                  <li>
                    If <strong>{customField.name}</strong> is being used as a
                    sort in any Saved View, it will now be sorted by None.
                  </li>
                  <li>
                    If <strong>{customField.name}</strong> is being used to
                    group by in any Saved View, it will now be grouped by None.
                  </li>
                </ul>
              </p>
            </div>
          ),
          deleteMessage: 'DELETE',
          deleteButtonLabelValue: 'field',
          alignment: 'left',
        })

        if (response === ModalDismissed) return false

        await deleteCustomField({
          customFieldId: customField.id,
          workspaceId,
        })
        showToast('success', 'Custom field deleted')
        recordAnalyticsEvent('CUSTOM_FIELD_DELETED', {
          type: customField.type,
        })
      } catch (e) {
        Sentry.captureException(e, {
          tags: { position: 'delete-custom-field' },
        })

        showErrorToast(e)
        throw e
      }
    },
    [deleteCustomField, modalApi]
  )
}
