import {
  type FolderSchema,
  type ProjectSchema,
  type WorkspaceSchema,
} from '@motion/rpc-types'

import { WorkspacesTreeDropdown } from '~/global/components/dropdowns'
import { type PropsWithChildren, useMemo } from 'react'

import { useBulkOpsGroups } from '../../contexts'
import { DropdownContentTopSection } from '../dropdown-content-top-section'

export type BulkFolderDropdownProps = PropsWithChildren<{
  onSelect: (selected: {
    projectId: ProjectSchema['id'] | null
    folderId: FolderSchema['id'] | null
    workspaceId: WorkspaceSchema['id']
  }) => void
}>

export const BulkFolderDropdown = ({
  children,
  onSelect,
}: BulkFolderDropdownProps) => {
  const { workspaceGroups } = useBulkOpsGroups()

  const workspaceIds = useMemo(
    () => workspaceGroups.map((g) => g.key),
    [workspaceGroups]
  )

  return (
    <WorkspacesTreeDropdown
      selectedId={null}
      onChange={onSelect}
      leafNodeType='FOLDER'
      computeDisabled={({ workspace }) =>
        !workspaceIds.every((id) => id === workspace.id)
      }
      renderHeader={() => (
        <div className='w-80'>
          <DropdownContentTopSection>
            Bulk operations for projects are only available for projects within
            the same workspace
          </DropdownContentTopSection>
        </div>
      )}
    >
      {children}
    </WorkspacesTreeDropdown>
  )
}
