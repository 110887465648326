import { CustomFieldDateGroupHeader } from './date'
import { CustomFieldSelectGroupHeader } from './multi-select'
import { CustomFieldPersonGroupHeader } from './person'
import { CustomFieldTextGroupHeader } from './text'
import { CustomFieldUrlGroupHeader } from './url'

import {
  type CustomFieldRow,
  type CustomFieldRowFieldType,
  type TreeListRowValueType,
} from '../../../types'

export function CustomFieldGroupHeader({
  item,
}: {
  item: TreeListRowValueType & { key: string }
}) {
  if (
    isCustomFieldGroupHeader(item, 'text') ||
    isCustomFieldGroupHeader(item, 'number')
  ) {
    return <CustomFieldTextGroupHeader item={item} />
  }

  if (isCustomFieldGroupHeader(item, 'url')) {
    return <CustomFieldUrlGroupHeader item={item} />
  }

  if (isCustomFieldGroupHeader(item, 'date')) {
    return <CustomFieldDateGroupHeader item={item} />
  }

  if (
    isCustomFieldGroupHeader(item, 'multiSelect') ||
    isCustomFieldGroupHeader(item, 'select')
  ) {
    return <CustomFieldSelectGroupHeader item={item} />
  }

  if (
    isCustomFieldGroupHeader(item, 'multiPerson') ||
    isCustomFieldGroupHeader(item, 'person')
  ) {
    return <CustomFieldPersonGroupHeader item={item} />
  }

  return null
}

function isCustomFieldGroupHeader<T extends CustomFieldRowFieldType>(
  item: TreeListRowValueType,
  prefix: T
): item is Extract<CustomFieldRow, { type: `${T}/${string}` }> {
  return item.type.startsWith(prefix)
}
