import { LocationMarkerSolid } from '@motion/icons'

import { GenericField } from '../../shared/fields'

export interface LocationFieldProps {
  loc: string
}

export const LocationField = (props: LocationFieldProps) => {
  const { loc } = props

  return (
    <GenericField icon={LocationMarkerSolid}>
      <span className='truncate max-w-[150px]'>{loc}</span>
    </GenericField>
  )
}
