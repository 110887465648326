import { Tooltip } from '@motion/ui/base'
import { formatDateTimeDetailed, formatToRelativeTime } from '@motion/ui-logic'

type ActivityTimestampProps = {
  timestamp: string
}

export function ActivityTimestamp({ timestamp }: ActivityTimestampProps) {
  return (
    <Tooltip asChild content={formatDateTimeDetailed(timestamp)}>
      {formatToRelativeTime(timestamp)}
    </Tooltip>
  )
}
