import { API } from '@motion/rpc'
import { client } from '@motion/web-common/rpc'

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'
import {
  createTeam,
  fetchTeam,
  inviteTeamMembers,
  removeTeamMember,
  updateTeam,
  updateTeamMember,
} from './projectManagement/teamThunks'
import { dispatch } from './proxy'
import { type Team, type TeamUser } from './TeamTypes'

import { type PMTeamSubscriptionType } from '../components/ProjectManagement/types'
import {
  type CheckTeamEligibilityResponse,
  type MutationResponse,
  type SetupIntentResult,
} from '../services/teamsService'

export interface TeamState {
  team: Readonly<Team | null>
  invitedTeams: Readonly<Readonly<Team>>[]
}

export const initialTeamsState: Readonly<TeamState> = {
  invitedTeams: [],
  team: null,
}

const teamServiceHandler = getProxy('TeamService')

export const getTeams = createAsyncThunk('team/getTeams', async () => {
  return await teamServiceHandler.getTeam()
})

export const checkTeamEligibility = createAsyncThunk<
  CheckTeamEligibilityResponse,
  string
>('team/checkTeamEligibility', async (email) => {
  return await teamServiceHandler.checkTeamEligibility(email)
})

export const cancelSubscription = createAsyncThunk<
  PMTeamSubscriptionType,
  { teamId: string; cancellationReason: string }
>('team/cancelSubscription', async ({ teamId, cancellationReason }) => {
  return await teamServiceHandler.cancelSubscription(teamId, cancellationReason)
})

export const createSetupIntent = createAsyncThunk<
  SetupIntentResult,
  { name: string; forceNewCustomer?: boolean }
>('team/setupIntent', async ({ name, forceNewCustomer }) => {
  return await teamServiceHandler.createSetupIntent(name, forceNewCustomer)
})

export const reenableSubscription = createAsyncThunk<
  PMTeamSubscriptionType,
  string
>('team/reenableSubscription', async (teamId: string) => {
  return await teamServiceHandler.reenableSubscription(teamId)
})

export const reusableSetupIntent = createAsyncThunk<SetupIntentResult, string>(
  'team/reusableSetupIntent',
  async (teamId: string) => {
    return await teamServiceHandler.reusableSetupIntent(teamId)
  }
)

export const switchBillingCycle = createAsyncThunk<MutationResponse, string>(
  'team/switchBillingCycle',
  async (teamId: string) => {
    const result = await teamServiceHandler.switchBillingCycle(teamId)
    void dispatch(fetchTeam())
    return result
  }
)

export const teamSlice = createSlice({
  extraReducers: (builder) => {
    // Optimistically update team name
    builder
      .addCase(fetchTeam.fulfilled, (state, action) => {
        const users: { [key: string]: TeamUser } = {}
        if (action.payload.team) {
          for (const member of action.payload.team?.members || []) {
            users[member.id] = {
              email: member.user.email,
              joined: true,
              name: member.user.name,
              uid: member.userId,
            }
          }
          const newTeam: Team = {
            ...action.payload.team,
            invited:
              action.payload.team.invites?.map((invite) => invite.email) || [],
            pmTeamSubscription: action.payload.team.teamSubscription,
            users,
          }
          state.team = newTeam
        }

        // Invalidating `useCurrentTeam` to get new data
        client.invalidateQueries(API.teams.queryKeys.currentTeam)
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        if (!state.team?.pmTeamSubscription) {
          throw new Error(
            `pmTeamSubscription is not defined in state.team: ${JSON.stringify(
              state.team
            )}`
          )
        }
        state.team.pmTeamSubscription.subscription = action.payload
      })
      .addCase(reenableSubscription.fulfilled, (state, action) => {
        if (!state.team?.pmTeamSubscription) {
          throw new Error(
            `pmTeamSubscription is not defined in state.team: ${JSON.stringify(
              state.team
            )}`
          )
        }
        state.team.pmTeamSubscription.subscription = action.payload
      })

    // Actions that provide an updated team entity which we'll push into state
    const actions = [
      createTeam.fulfilled,
      inviteTeamMembers.fulfilled,
      removeTeamMember.fulfilled,
    ]
    actions.forEach((action) =>
      builder.addCase(action, (state, action) => {
        if (action.payload && 'joinedTeam' in action.payload) {
          state.team = action.payload.joinedTeam as Team
          return
        }
        state.team = action.payload as Team

        // Invalidating `useCurrentTeam` to get new data
        client.invalidateQueries(API.teams.queryKeys.currentTeam)
      })
    )

    const actions2 = [updateTeam.fulfilled, updateTeamMember.fulfilled]
    actions2.forEach((action) =>
      builder.addCase(action, () => {
        // Invalidating `useCurrentTeam` to get new data
        client.invalidateQueries(API.teams.queryKeys.currentTeam)
      })
    )
  },
  initialState: initialTeamsState,
  name: 'team',
  reducers: {
    reset: () => ({ ...initialTeamsState }),
    setInvitedTeams: (state: TeamState, action: PayloadAction<Team[]>) => {
      state.invitedTeams = action.payload
    },
    setTeam: (state: TeamState, action: PayloadAction<Team>) => {
      // pmTeamSubscription is sometimes not present in the action.payload
      state.team = {
        ...state.team,
        ...action.payload,
      }
    },
  },
})

export const { setTeam, setInvitedTeams, reset } = teamSlice.actions

export const teamReducer = teamSlice.reducer
