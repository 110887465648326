import { type UploadedFileSchema } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { AttachmentItem, AttachmentItemPlaceholder } from './components'
import { useFileUploadState } from './contexts/file-upload-context'

const FALLBACK_EMPTY_MESSAGE = 'No attachments have been added.'

export type AttachmentsProps = {
  attachmentIds: UploadedFileSchema['id'][]
  childAttachmentCount?: number
  targetId: UploadedFileSchema['targetId']
  targetType: UploadedFileSchema['targetType']
  variant?: 'default' | 'compact'
  emptyMessage?: string
  limit?: number
  onAttachmentClick?: (id: UploadedFileSchema['id']) => void
}

export function Attachments({
  attachmentIds,
  childAttachmentCount = 0,
  targetId,
  targetType,
  variant = 'default',
  emptyMessage = FALLBACK_EMPTY_MESSAGE,
  limit,
  onAttachmentClick,
}: AttachmentsProps) {
  const modalApi = useModalApi()

  const { activeFileUploads } = useFileUploadState()

  const filteredActiveFileUploads = activeFileUploads
    .filter(
      (activeFileUpload) =>
        activeFileUpload.targetId === targetId &&
        activeFileUpload.targetType === targetType
    )
    .filter((_, index) => !limit || index < limit)

  const adjustedLimit = limit ? limit - filteredActiveFileUploads.length : limit

  const filteredAttachmentIds = attachmentIds.filter(
    (_, index) => !limit || (adjustedLimit && index < adjustedLimit)
  )

  const hiddenCount =
    activeFileUploads.length -
    filteredActiveFileUploads.length +
    (attachmentIds.length - filteredAttachmentIds.length)

  const handleShowMoreClick = () => {
    if (!targetId || !targetType) return

    modalApi.open('attachments', {
      targetId,
      targetType,
    })
  }

  const totalCount = attachmentIds.length + childAttachmentCount

  return (
    <>
      {!attachmentIds.length && !filteredActiveFileUploads.length ? (
        <div className='text-sm text-semantic-neutral-text-subtle pl-2'>
          {emptyMessage}
        </div>
      ) : (
        <>
          {filteredActiveFileUploads.map(({ tempId, fileName, progress }) => (
            <AttachmentItemPlaceholder
              key={tempId}
              fileName={fileName}
              variant={variant}
              progress={progress}
            />
          ))}
          {filteredAttachmentIds.map((attachmentId) => (
            <AttachmentItem
              key={attachmentId}
              attachmentId={attachmentId}
              variant={variant}
              onClick={onAttachmentClick}
            />
          ))}
        </>
      )}
      {(hiddenCount > 0 || childAttachmentCount > 0) && (
        <div className='mt-2'>
          <Button
            variant='muted'
            sentiment='primary'
            size='small'
            onClick={handleShowMoreClick}
          >
            {targetType === 'TEAM_TASK'
              ? `Show ${hiddenCount} more`
              : `See all project attachments (${totalCount})`}
          </Button>
        </div>
      )}
    </>
  )
}
