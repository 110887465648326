import { useModalApi } from '@motion/web-common/modals'

import { CalendarSettings } from './calendar-settings'

export const ConnectedCalendarSettings = () => {
  const modalApi = useModalApi()

  return (
    <CalendarSettings
      onShowDetachCalendarModal={() => modalApi.open('detach-calendar')}
    />
  )
}
