import { twMerge } from 'tailwind-merge'

import { FormAutoScheduleToggle } from './form-autoschedule-toggle'

type FormAutoSchedulePanelProps = {
  onChange?: (checked: boolean) => void
}
export const FormAutoSchedulePanel = (props: FormAutoSchedulePanelProps) => {
  return (
    <div className={twMerge('p-4 h-[60px] flex flex-row items-center gap-1')}>
      <FormAutoScheduleToggle showDate onChange={props.onChange} />
    </div>
  )
}
