import { type MultiPersonValueSchema } from '@motion/shared/custom-fields'

import {
  AssigneesDisplay,
  CustomField,
} from '~/areas/project-management/custom-fields'
import { ModalFieldButton } from '~/areas/task-project/components'

import { type ModalCustomField } from '../../types'

type Props = {
  value: MultiPersonValueSchema['value']
  customField: Extract<ModalCustomField, { type: 'multiPerson' }>
  onChange: (userIds: (string | null)[]) => void
  disabled?: boolean
  size?: 'small' | 'xsmall'
}

export function MultiPerson({
  value,
  customField,
  onChange,
  disabled = false,
  size,
}: Props) {
  return (
    <CustomField.MultiPerson
      workspaceId={customField.workspaceId}
      selectedUserIds={value ?? []}
      onChange={onChange}
    >
      <ModalFieldButton
        label={customField.name}
        disabled={disabled}
        size={size}
      >
        <AssigneesDisplay
          userIds={value}
          type={customField.type}
          hideIcon
          disabled={disabled}
          size={size}
        />
      </ModalFieldButton>
    </CustomField.MultiPerson>
  )
}
