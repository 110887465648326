import { formatToReadableWeekDayMonth } from '@motion/ui-logic'
import { getStartDateText, isMeetingTask } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskStartDateUpdater } from '../updaters'

export function useTaskStartDateData(task: TaskSchema) {
  const updateTaskStartDate = useTaskStartDateUpdater()

  const onChange = useCallback(
    (startDate: TaskSchema['startDate']) => {
      void updateTaskStartDate(task, startDate)
    },
    [task, updateTaskStartDate]
  )

  const isMeeting = isMeetingTask(task)
  const isRecurringInstance = task.type === 'RECURRING_INSTANCE'

  const disabled =
    isRecurringInstance ||
    task.priorityLevel === 'ASAP' ||
    task.archivedTime != null ||
    isMeeting

  const disableClearingValue = isRecurringInstance || task.isAutoScheduled

  const formattedValue =
    isMeeting && task.scheduledStart
      ? formatToReadableWeekDayMonth(task.scheduledStart)
      : getStartDateText(task)

  return {
    onChange,
    disabled,
    formattedValue,
    disableClearingValue,
  }
}
