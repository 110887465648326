import { ScheduleSolid } from '@motion/icons'
import { FieldButton, type FieldButtonProps } from '@motion/ui/base'

import { ScheduleDropdown } from '~/global/components/dropdowns'
import { useController } from 'react-hook-form'

import { useTemplateTaskForm } from '../hooks'

export type FormScheduleDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
> & {
  showFullLabel?: boolean
}

export const FormScheduleDropdown = ({
  showFullLabel = false,
  ...buttonProps
}: FormScheduleDropdownProps) => {
  const { form, options, disabledFields } = useTemplateTaskForm()

  const { watch, control } = form
  const { schedules } = options

  const { field } = useController({
    name: 'scheduleId',
    control,
    rules: {
      required: true,
    },
  })

  const selectedScheduleId = watch('scheduleId')
  const selectedSchedule = schedules.find((s) => s.id === selectedScheduleId)

  return (
    <ScheduleDropdown
      selectedScheduleId={selectedScheduleId}
      onChange={field.onChange}
    >
      <FieldButton
        {...buttonProps}
        disabled={disabledFields.has('scheduleId')}
        wrap
      >
        <ScheduleSolid /> {selectedSchedule?.name}
      </FieldButton>
    </ScheduleDropdown>
  )
}
