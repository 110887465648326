import { useProjectUpdater } from '~/areas/project/hooks'
import { LabelsField, type LabelsFieldProps } from '~/areas/task-project/fields'
import { useProject } from '~/global/hooks'

type ProjectLabelsFieldProps = {
  projectId: string
  onChange?: LabelsFieldProps['onChange']
}

export function ProjectLabelsField({
  projectId,
  onChange: onChangeFromProps,
}: ProjectLabelsFieldProps) {
  const updateProject = useProjectUpdater()
  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project is required')
  }

  const onChange: LabelsFieldProps['onChange'] = async (labelIds) => {
    await updateProject(projectId, { labelIds })
    onChangeFromProps?.(labelIds)
  }

  return (
    <LabelsField
      selectedLabelIds={project.labelIds}
      workspaceId={project.workspaceId}
      onChange={onChange}
    />
  )
}
