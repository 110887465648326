import { type ProjectUrlSearchParams } from '~/areas/project/modals/project-modal/utils'

import { type InferProjectDataHandler } from '../../../tree-list/cells/hooks'

export const parseProjectValuesToSearchParams = (
  opts: ReturnType<InferProjectDataHandler>
): ProjectUrlSearchParams => {
  const results: ProjectUrlSearchParams = {
    forStatus: opts.statusId,
  }

  if (opts.workspaceId) {
    results.forWorkspace = opts.workspaceId
  }

  if (opts.managerId) {
    results.forManager = opts.managerId
  }

  if (opts.labelId) {
    results.forLabel = opts.labelId
  }

  if (opts.priorityLevel) {
    results.forPriority = opts.priorityLevel
  }

  if (opts.customFieldValues) {
    results.forCustomField = opts.customFieldValues
  }

  return results
}
