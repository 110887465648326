import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useProjectUpdater } from '~/areas/project/hooks'
import { ModalFieldButton } from '~/areas/task-project/components'
import { WorkspaceBadge } from '~/global/components/badges'
import {
  WorkspaceDropdown,
  type WorkspaceDropdownProps,
} from '~/global/components/dropdowns'
import { useI18N } from '~/global/contexts'
import { useProject, useWorkspaceById } from '~/global/hooks'

import { ProjectActiveStageField } from './active-stage-field'

type ProjectWorkspaceFieldProps = {
  projectId: string
  onChange?: WorkspaceDropdownProps['onChange']
}

export function ProjectWorkspaceField({
  projectId,
  onChange,
}: ProjectWorkspaceFieldProps) {
  const { formatList } = useI18N()
  const modalApi = useModalApi()
  const updateProject = useProjectUpdater()
  const project = useProject(projectId)
  const isFlow = isFlowProject(project)

  const selectedWorkspace = useWorkspaceById(project?.workspaceId)

  if (project == null) {
    throw new Error('Project is required')
  }

  if (selectedWorkspace == null) return null

  const onWorkspaceSelect: WorkspaceDropdownProps['onChange'] = async (
    item
  ) => {
    if (project.workspaceId === item.id) return
    // When updating an existing project, we're triggering the update right away

    // Sleeping a bit to make sure the dropdown gets closed before prompting the modal to keep the focus within the modal
    await sleep(1)

    const confirmResponse = await modalApi.prompt('confirm', {
      analytics: {
        name: 'move-project-workspace',
      },
      title: templateStr(
        'Are you sure you want to move this project from {{ oldWorkspace }} to {{ newWorkspace }}?',
        {
          oldWorkspace: selectedWorkspace.name,
          newWorkspace: item.name,
        }
      ),
      description:
        'Assignees, labels and statuses may be reset if they are not available in the other workspace.',
      confirmButtonText: 'Move project',
      closeButtonText: 'Cancel',
    })

    if (confirmResponse === ModalDismissed) return

    await updateProject(project, {
      workspaceId: item.id,
    })
    onChange?.(item)
  }

  return (
    <Container>
      <label className='text-semantic-neutral-text-subtle text-2xs pb-1 px-1'>
        {formatList(
          [
            'Workspace',
            project.activeStageDefinitionId != null ? 'Stage' : null,
          ].filter(Boolean),
          'conjunction'
        )}
      </label>

      <Tooltip
        content={
          isFlow
            ? 'Workspace can’t be changed for projects using a workflow'
            : undefined
        }
      >
        <WorkspaceDropdown
          selectedWorkspaceId={project.workspaceId}
          onChange={onWorkspaceSelect}
        >
          <ModalFieldButton
            size='normal'
            icon={<WorkspaceBadge />}
            disabled={isFlow}
          >
            {selectedWorkspace.name}
          </ModalFieldButton>
        </WorkspaceDropdown>
      </Tooltip>

      <ProjectActiveStageField projectId={projectId} />
    </Container>
  )
}

const Container = classed('div', {
  base: `
   flex flex-col gap-0
   modal-lg:gap-1.5
  `,
})
