import { classed, type ComponentProps } from '@motion/theme'

import { type Row } from '@tanstack/react-table'

import { type GroupedNode } from '../../grouping'
import { ZIndexMap } from '../shared-constants'

export type PlannerRowProps<T extends GroupedNode> = ComponentProps<
  typeof StyledPlannerRow
> & {
  row?: Row<T>
  toggleExpandAllRows?: (expand: boolean) => void
  visible?: boolean
}

export const StyledPlannerRow = classed('div', {
  base: `
    flex
    items-center
    setvar-[indent=calc(calc((var(--depth,1)))*16px)]
  `,
  variants: {
    expandable: {
      true: `
        bg-pivot-table-header-row-bg
        hover:bg-pivot-table-item-row-bg-hover
        sticky
        shadow-inner

        setvar-[offsetHeader=0px]
        setvar-[offsetGroups=calc(var(--depth)*36px)]
        setvar-[offset=calc(var(--offsetHeader)+var(--offsetGroups))]
        top-[var(--offset)]
        pl-[var(--indent)]
        
        before:pointer-events-none
        before:bg-gradient-to-r
        before:from-calendar-bg-default
        before:from-75%
        before:to-transparent
        before:w-[var(--indent)]
        before:absolute
        before:top-0
        before:bottom-0
        before:left-0

      `,
      false: ``,
    },
    visible: {
      true: '',
      false: '',
    },
  },
  defaultVariants: {
    expandable: false,
    visible: true,
  },
})

export const RowNameContainer = (
  props: ComponentProps<typeof RowNameClass> & {
    children: React.ReactNode
  }
) => {
  return (
    <div
      style={{ zIndex: ZIndexMap.rowName }}
      className='h-full flex items-center shrink-0 bg-semantic-neutral-surface-bg-default first:pl-[var(--indent)]'
    >
      <RowNameClass {...props}>{props.children}</RowNameClass>
    </div>
  )
}

export const RowNameClass = classed('div', {
  base: `
    flex items-center
    h-full w-[217px]
    cursor-pointer
    bg-semantic-neutral-surface-bg-default
    hover:bg-semantic-neutral-surface-bg-subtle
    py-3 px-4
  `,
})

export const ItemContainer = classed('div', {
  base: `
    h-full
    absolute
    flex
    items-center
  `,
})
