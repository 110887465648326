import {
  type Data,
  type DraggableAttributes,
  type DraggableSyntheticListeners,
  type UniqueIdentifier,
} from '@dnd-kit/core'
import {
  type AnimateLayoutChanges,
  type NewIndexGetter,
  useSortable,
} from '@dnd-kit/sortable'
import type { Transform } from '@dnd-kit/utilities'
import React from 'react'

export interface RenderSortableItemProps {
  dragOverlay: boolean
  dragging: boolean
  sorting: boolean
  fadeIn: boolean
  listeners: DraggableSyntheticListeners
  setActivatorNodeRef: (node: HTMLElement | null) => void
  setNodeRef: (node: HTMLElement | null) => void
  attributes: DraggableAttributes
}

export interface SortableItemProps {
  animateLayoutChanges?: AnimateLayoutChanges
  disabled?: boolean
  getNewIndex?: NewIndexGetter
  id: UniqueIdentifier
  useDragOverlay?: boolean
  onRemove?(id: UniqueIdentifier): void
  renderItem(args: RenderSortableItemProps): React.ReactElement
  data?: Data
  style?: React.CSSProperties
  className?: string
  hasDragHandle?: boolean
}

export function SortableItem({
  disabled,
  animateLayoutChanges,
  getNewIndex,
  id,
  renderItem,
  useDragOverlay,
  data,
  style,
  className,
  hasDragHandle = false,
}: SortableItemProps) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
    getNewIndex,
    data,
  })

  return (
    <Item
      setNodeRef={setNodeRef}
      setActivatorNodeRef={setActivatorNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      renderItem={renderItem}
      listeners={listeners}
      attributes={attributes}
      data-id={id}
      dragOverlay={!useDragOverlay && isDragging}
      style={style}
      className={className}
      hasDragHandle={hasDragHandle}
      {...parseTransform(transform, transition)}
    />
  )
}

export interface Props {
  dragOverlay?: boolean
  dragging?: boolean
  index?: number
  fadeIn?: boolean
  transform?: string
  listeners?: DraggableSyntheticListeners
  sorting?: boolean
  transition?: string
  value: React.ReactNode
  setNodeRef: (node: HTMLElement | null) => void
  setActivatorNodeRef: (node: HTMLElement | null) => void
  renderItem(args: RenderSortableItemProps): React.ReactElement
  attributes: DraggableAttributes
  style?: React.CSSProperties
  className?: string
  hasDragHandle: boolean
}

export const Item = React.memo(function Item({
  dragOverlay,
  dragging,
  fadeIn,
  listeners,
  renderItem,
  sorting,
  transition,
  transform,
  setActivatorNodeRef,
  attributes,
  setNodeRef,
  style,
  className,
  hasDragHandle,
}: Props) {
  return (
    <div
      ref={setNodeRef}
      {...(hasDragHandle ? undefined : listeners)}
      className={className}
      style={{
        transform,
        transition,
        position: 'absolute',
        opacity: dragging ? 0.4 : 1.0,
        ...style,
      }}
    >
      {renderItem({
        dragOverlay: Boolean(dragOverlay),
        dragging: Boolean(dragging),
        sorting: Boolean(sorting),
        fadeIn: Boolean(fadeIn),
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        attributes,
      })}
    </div>
  )
})

function parseTransform(
  transform: Transform | null,
  transition?: string | null
) {
  return transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: transition
          ? 'transform 200ms cubic-bezier(0.2, 0, 0, 1)'
          : undefined,
      }
    : undefined
}
