import { Button, type ButtonProps } from '@motion/ui/base'

export interface CancelDialogProps {
  cancelButtonText?: string
  cancelButtonHandler?: () => void
  cancelButtonDisabled?: boolean
}

type DialogButtonType = 'primary' | 'secondary' | 'destructive'

export type DialogButtonProps = {
  actionButtonText: string
  actionButtonHandler: () => void
  actionButtonLoading?: boolean
  actionButtonDisabled?: boolean
  actionButtonType?: DialogButtonType
  actionButtonKeyboardShortcuts?: string
}

/* Render an action button for the dialog based on the type of button given */
export const DialogButton = ({
  actionButtonText,
  actionButtonHandler,
  actionButtonLoading,
  actionButtonDisabled,
  actionButtonType = 'primary',
  actionButtonKeyboardShortcuts,
}: DialogButtonProps) => {
  const props: Partial<ButtonProps> = {
    onClick: actionButtonHandler,
    loading: actionButtonLoading,
    disabled: actionButtonDisabled,
    shortcut: actionButtonKeyboardShortcuts,
  }

  return (
    <Button
      sentiment={
        actionButtonType === 'destructive'
          ? 'error'
          : actionButtonType === 'secondary'
            ? 'neutral'
            : 'primary'
      }
      {...props}
    >
      {actionButtonText}
    </Button>
  )
}
