import { getEventTitleFromSchedulingTaskTitle } from '@motion/shared/meetings'
import { getInitialEventFormData } from '@motion/ui-logic'
import { type EventFormFields } from '@motion/ui-logic'
import {
  type CalendarEventSchemaV2,
  type NormalTaskSchema,
} from '@motion/zod/client'

import { useMainCalendarId } from '~/areas/calendar/hooks'
import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useConferenceSettingsApi } from '~/areas/settings/state'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

import { NoMainCalendarError } from '../errors'

export type InitialValues = Partial<EventFormFields>

export type InitialFormOptions = {
  isLoading?: boolean
  event: CalendarEventSchemaV2 | null | undefined
  eventRecurringParent: CalendarEventSchemaV2 | null | undefined
  meetingTask: NormalTaskSchema | null | undefined
  schedulingTask: NormalTaskSchema | null | undefined
}

export function useInitialFormData(
  defaultValues: InitialValues = {},
  options: InitialFormOptions
): EventFormFields {
  const {
    isLoading,
    event,
    eventRecurringParent,
    meetingTask,
    schedulingTask,
  } = options

  const finalTask = meetingTask ?? schedulingTask

  const workspaceCustomFields = useCustomFieldsByWorkspaceId(
    finalTask?.workspaceId
  )

  const { mainCalendarId, isLoading: isLoadingCalendarId } = useMainCalendarId()
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const conferenceSettings =
    useConferenceSettingsApi().selectConferenceSettings()

  if (!isLoadingCalendarId && mainCalendarId == null) {
    throw new NoMainCalendarError()
  }

  return useMemo(() => {
    return getInitialEventFormData({
      mainCalendarId,
      mainEmailAccount,
      eventRecurringParent,
      settingConferenceType: conferenceSettings.conferenceType,
      workspaceCustomFields,
      defaultValues: {
        title: schedulingTask
          ? getEventTitleFromSchedulingTaskTitle(schedulingTask.name)
          : undefined,
        ...defaultValues,
      },
      options: {
        task: finalTask,
        event,
        isLoading: isLoading || isLoadingCalendarId,
      },
    })
  }, [
    mainCalendarId,
    mainEmailAccount,
    eventRecurringParent,
    conferenceSettings.conferenceType,
    workspaceCustomFields,
    schedulingTask,
    defaultValues,
    finalTask,
    event,
    isLoading,
    isLoadingCalendarId,
  ])
}
