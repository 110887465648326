import {
  fetchBookingData,
  selectBookingPageLoadingState,
} from '~/state/booking'
import { selectCalendarsLoadingState } from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import type { LoadingState } from '~/state/projectManagementSlice'
import { type PropsWithChildren, useEffect } from 'react'

export const useBookingLoadingState = () => {
  const dispatch = useAppDispatch()
  const calendarsLoadingState = useAppSelector(selectCalendarsLoadingState)
  const bookingPageLoadingState = useAppSelector(selectBookingPageLoadingState)

  useEffect(() => {
    dispatch(fetchBookingData())
  }, [dispatch])

  return isLoaded(calendarsLoadingState) && isLoaded(bookingPageLoadingState)
}

const isLoaded = (state: LoadingState) =>
  !(['preload', 'loading'] satisfies LoadingState[]).includes(state)

export const EnsureBookingDataLoaded = ({ children }: PropsWithChildren) => {
  useBookingLoadingState()

  return children
}
