import { isCanceledStatus } from '@motion/shared/common'
import { PopoverTrigger } from '@motion/ui/base'
import { isUnscheduledSchedulingTask } from '@motion/ui-logic/pm/task'

import { useWorkspaceStatusById } from '~/global/hooks'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { type ReactNode } from 'react'

import {
  SchedulingEventDropdownContent,
  type SchedulingEventDropdownContentProps,
} from './scheduling-event-dropdown-content'

type SchedulingEventDropdownProps = Omit<
  SchedulingEventDropdownContentProps,
  'close'
> & {
  children: ReactNode
}

export function SchedulingEventDropdown(props: SchedulingEventDropdownProps) {
  const { data: task } = useTaskByIdV2({
    id: props.taskId,
  })

  const status = useWorkspaceStatusById(task?.statusId)

  if (!isUnscheduledSchedulingTask(task) || isCanceledStatus(status)) {
    return null
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SchedulingEventDropdownContent close={close} {...props} />
      )}
    >
      {props.children}
    </PopoverTrigger>
  )
}
