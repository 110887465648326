import { NoTemplatesFound } from '~/areas/task-project/components'
import {
  useProjectDefinitions,
  useWorkspaceLegacyProjectTemplates,
} from '~/global/hooks'

import { TemplateSearchableList } from './template-searchable-list'

import { useProjectForm, useSelectProjectModalTemplate } from '../../hooks'

export type TemplateDropdownContentProps = {
  close: () => void
  shouldUseFlowTemplates?: boolean
  searchable?: boolean
  projectId?: string
}
export const TemplateDropdownContent = ({
  close,
  shouldUseFlowTemplates,
  searchable = false,
  projectId,
}: TemplateDropdownContentProps) => {
  const selectTemplate = useSelectProjectModalTemplate()
  const { form } = useProjectForm()
  const { watch } = form

  const workspaceId = watch('workspaceId')

  const legacyTemplates = useWorkspaceLegacyProjectTemplates(workspaceId)
  const flowTemplates = useProjectDefinitions(workspaceId)

  const templates = shouldUseFlowTemplates ? flowTemplates : legacyTemplates

  if (templates == null) {
    // TODO Render error?
    return null
  }

  if (templates.length === 0) {
    return <NoTemplatesFound />
  }

  return (
    <TemplateSearchableList
      items={templates as any}
      close={close}
      selectTemplate={(item) => selectTemplate(item, projectId)}
      searchable={searchable}
    />
  )
}
