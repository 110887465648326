import { classed } from '@motion/theme'
import { FieldButton } from '@motion/ui/base'
import { safeParseDate } from '@motion/utils/dates'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { DateTime } from 'luxon'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { FieldIconContainer } from '../common/field-icon-container'
import { type CustomFieldDateProps } from '../types'

const NONE_VALUE = 'None'

export const Date = forwardRef<HTMLButtonElement, CustomFieldDateProps>(
  (props, ref) => {
    let convertedDate = props.value ? safeParseDate(props.value) : null

    const defaultValue = props.value ? props.value : NONE_VALUE
    const parsedDate =
      convertedDate != null
        ? convertedDate.toLocaleString(DateTime.DATE_MED)
        : defaultValue

    const TriggerButtonComponent = props.triggerComponent ?? FieldButton

    return (
      <SimpleDateDropdown
        disabledDate={() => false}
        value={convertedDate != null ? props.value : null}
        onChange={props.onChange}
        subActions={[
          {
            content: 'Clear date',
            onAction: () => props.onChange(null),
          },
        ]}
      >
        <TriggerButtonComponent
          ref={ref}
          // @ts-expect-error - fine
          style={{ gap: 8 }}
          className={twMerge(
            props.noHoverStyle &&
              'enabled:group-hover:bg-transparent enabled:group-hover:border-transparent'
          )}
          fullWidth
          size={props.size ?? 'small'}
          variant='muted'
          disabled={props.disabled}
          label={props.name}
        >
          {!props.hideIcon && (
            <FieldIconContainer size={props.size}>
              <CustomFieldBadge type='date' size={props.size} />
            </FieldIconContainer>
          )}
          <DateLabel
            isPlaceholder={parsedDate === NONE_VALUE}
            disabled={props.disabled}
            size={props.size}
          >
            {parsedDate}
          </DateLabel>
        </TriggerButtonComponent>
      </SimpleDateDropdown>
    )
  }
)

Date.displayName = 'CustomFieldDate'

const DateLabel = classed('span', {
  base: '',
  variants: {
    isPlaceholder: {
      true: 'text-semantic-neutral-text-default',
    },
    disabled: {
      true: 'text-semantic-neutral-text-disabled',
    },
    size: {
      xsmall: 'text-xs',
      small: '',
    },
  },
})
