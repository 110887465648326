import { XSolid } from '@motion/icons'
import {
  ActionList,
  Button,
  ButtonGroup,
  type Group,
  IconButton,
  PopoverTrigger,
  type RenderGroupContext,
  type RenderItemContext,
  SearchableGroupedCheckboxList,
} from '@motion/ui/base'

import { type FooterAction } from '~/global/components'
import { type ReactNode, useMemo } from 'react'

const DEFAULT_RENDER_SELECTED = <T extends { id: string; name: string }>(
  selected: T[],
  groups: Group<T>[],
  items: T[]
) => {
  return selected.length === items.length
    ? 'All'
    : selected.length === 1
      ? selected[0].name
      : selected.length > 1
        ? `${selected.length} selected`
        : 'All' // an empty list is the same as no filter
}

export type GroupedFilterButtonProps<T> = {
  openOnMount?: boolean
  onBlur?: () => void
  emptyLabel?: string
  footerActions?: FooterAction[]

  label: ReactNode

  searchPlaceholder?: string
  groups: Group<T>[]
  selected: T[]
  onSelected(items: T[] | null): void

  renderHeader(ctx: RenderGroupContext<T>): NonNullable<ReactNode>
  renderItem(ctx: RenderItemContext<T>): NonNullable<ReactNode>
  renderSelected?(
    selected: T[],
    groups: Group<T>[],
    items: T[]
  ): NonNullable<ReactNode>
}

export const GroupedFilterButton = <T extends { id: string; name: string }>(
  props: GroupedFilterButtonProps<T>
) => {
  const {
    groups,
    selected,
    onSelected,
    renderHeader,
    renderItem,
    renderSelected = DEFAULT_RENDER_SELECTED,
    emptyLabel,
    searchPlaceholder = 'Search ...',
    label,
  } = props

  const allItems = useMemo(() => groups.flatMap((g) => g.items), [groups])

  const finalFooterActions = props.footerActions ?? [
    {
      label: 'Clear',
      onAction: () => {
        onSelected([])
      },
    },
    {
      label: 'Select all',
      onAction: () => {
        onSelected(groups.flatMap((g) => g.items))
      },
    },
  ]

  const selectedValue = useMemo(() => {
    return renderSelected(selected, groups, allItems)
  }, [allItems, groups, renderSelected, selected])

  return (
    <PopoverTrigger
      placement='bottom-start'
      openOnMount={props.openOnMount}
      onClose={props.onBlur}
      renderPopover={({ close }) =>
        groups.length === 0 ? (
          <ActionList
            items={[
              {
                content: emptyLabel ?? 'No items found',
                onAction: close,
                disabled: true,
              },
            ]}
          />
        ) : (
          <>
            <SearchableGroupedCheckboxList
              groups={groups}
              selected={selected}
              onSelected={onSelected}
              renderItem={renderItem}
              renderHeader={renderHeader}
              placeholder={searchPlaceholder}
            />
            {finalFooterActions.length > 0 && (
              <div className='border-t border-dropdown-border py-1.5 px-2 flex justify-between'>
                {finalFooterActions.map((action) => {
                  return (
                    <Button
                      key={String(action.label)}
                      onClick={() => {
                        action.onAction()
                        if (action.closeOnAction) {
                          close()
                        }
                      }}
                      variant='outlined'
                      sentiment='neutral'
                      size='small'
                    >
                      {action.label}
                    </Button>
                  )
                })}
              </div>
            )}
          </>
        )
      }
    >
      <ButtonGroup segmented nowrap>
        <Button sentiment='neutral' variant='outlined' size='small'>
          {label}: <div className='font-medium'>{selectedValue}</div>
        </Button>
        <IconButton
          sentiment='neutral'
          variant='outlined'
          size='small'
          icon={XSolid}
          onClick={(e) => {
            e.stopPropagation()
            onSelected(null)
          }}
        />
      </ButtonGroup>
    </PopoverTrigger>
  )
}
