import { DuplicateOutline } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import { getMeetingTaskStaticURL } from '@motion/ui-logic'

import { useCopyHtmlLinkToClipboard } from '~/global/hooks'

import { useEventModalState } from '../contexts'

export function CopyMeetingLinkButton() {
  const { initialMeetingTask } = useEventModalState()
  const copyLink = useCopyHtmlLinkToClipboard()

  if (initialMeetingTask == null) return null

  const handleClick = () => {
    const url = getMeetingTaskStaticURL({
      workspaceId: initialMeetingTask.workspaceId,
      taskId: initialMeetingTask.id,
    })

    copyLink(url, initialMeetingTask.name)
  }

  return (
    <Tooltip asChild content='Copy meeting link'>
      <IconButton
        icon={DuplicateOutline}
        onClick={handleClick}
        variant='muted'
        sentiment='neutral'
        size='small'
      />
    </Tooltip>
  )
}
