import { priorityLevels } from '@motion/rpc/types'
import { Button } from '@motion/ui/base'

import { PriorityBadge } from '~/global/components/badges'
import { PriorityDropdown } from '~/global/components/dropdowns'
import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationPriorityLevelField = () => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'priorityLevel',
    control: formContext.control,
  })

  return (
    <PriorityDropdown
      items={priorityLevels}
      selectedItem={field.value}
      onChange={field.onChange}
    >
      <Button sentiment='neutral' variant='outlined' iconOnly size='xsmall'>
        <div className='p-0.5'>
          <PriorityBadge value={field.value} />
        </div>
      </Button>
    </PriorityDropdown>
  )
}
