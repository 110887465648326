import { COLORS } from '@motion/shared/common'
import { createNoneId } from '@motion/shared/identifiers'
import {
  DATE_FORMATTERS_BY_UNIT,
  ExtendedDeadlineStatus,
  getExtendedProjectDeadlineStatus,
  getExtendedTaskDeadlineStatus,
  isProject,
} from '@motion/ui-logic'
import {
  createNoneFolder,
  createNoneLabel,
  createNoneProject,
  createNoneProjectDefinition,
  createNoneStageDefinition,
  createNoneUser,
  type EntityFilterState,
} from '@motion/ui-logic/pm/data'
import { getScheduledDate, isMeetingTask } from '@motion/ui-logic/pm/task'
import {
  byProperty,
  byValue,
  Compare,
  groupInto,
  ordered,
  orderedAtEnd,
} from '@motion/utils/array'
import { safeParseDate } from '@motion/utils/dates'
import {
  type FolderSchema,
  type LabelSchema,
  PriorityLevelSchema,
  type ProjectDefinitionSchema,
  type ProjectSchema,
  type StageDefinitionSchema,
  type StatusSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { type ProxyLookupFn } from '~/global/cache'
import { type AppWorkspaceDataContext } from '~/global/contexts'
import {
  type NormalTaskWithRelations,
  type ProjectWithRelations,
  type RecurringInstanceWithRelations,
  type UnchunkedTaskWithRelations,
} from '~/global/proxies'

import { getNonMeetingProperty } from './utils'
import { type GroupDefinition } from './utils/multi-group'

export type GroupArgs = {
  ctx: AppWorkspaceDataContext
  filter: EntityFilterState
  lookup: ProxyLookupFn
}

const GROUPING_STRING_SORT = Compare.string.with({
  null: 'at-end',
  empty: 'at-end',
  trim: true,
})

export const groupByWorkspace = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, WorkspaceSchema> => {
  const activeWorkspaces = ctx.workspaces.all

  return {
    type: 'workspace',
    name: 'Workspace',
    groupType: 'select',
    isBounded: true,
    initialValues: activeWorkspaces.map((p) => ({
      key: p.id,
      value: p,
      workspaceIds: [p.id],
    })),
    accessor: (item) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lookup('workspaces', item.workspaceId)!,
    keyOf: (item: WorkspaceSchema) => item.id,
    sortBy: byValue((x) => x.value.name, GROUPING_STRING_SORT),
  }
}

export const groupByProject = ({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<
  UnchunkedTaskWithRelations,
  ProjectSchema | null
> => {
  const noneProject = createNoneProject('null')

  return {
    type: 'project',
    name: 'Project',
    groupType: 'select',
    isBounded: true,
    initialValues: [
      {
        key: noneProject.id,
        value: noneProject,
      },
      ...ctx.projects.all.map((p) => ({
        key: p.id,
        value: p,
        workspaceIds: [p.workspaceId],
      })),
    ],
    accessor: (item) =>
      item.projectId
        ? (lookup('projects', item.projectId) ?? noneProject)
        : noneProject,
    keyOf: (item) => String(item?.id),
    sortBy: byValue(
      (x) => x.value?.name ?? noneProject.name,
      orderedAtEnd([noneProject.name], GROUPING_STRING_SORT)
    ),
  }
}

export const groupByStatus = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, StatusSchema> => {
  return {
    type: 'status',
    name: 'Status',
    groupType: 'select',
    isBounded: true,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    accessor: (item) => lookup('statuses', item.statusId)!,
    keyOf: (status: StatusSchema) => status.name,
    initialValues: groupInto(ctx.statuses.all, (x) => x.name).map((x) => ({
      key: x.key,
      value: x.items[0],
      workspaceIds: x.items.map((x) => x.workspaceId),
    })),
    sortBy: byValue((x) => x.key, GROUPING_STRING_SORT),
  }
}

export const groupByStage = <TItem extends NormalTaskWithRelations>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, StageDefinitionSchema | null> => {
  const noneStageDef = createNoneStageDefinition()

  return {
    type: 'stage',
    name: 'Stage',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => item.stageDefinition,
    keyOf: (stage: StageDefinitionSchema | null) =>
      stage?.name ?? noneStageDef.name,
    initialValues: [
      ...groupInto(ctx.stageDefinitions.all, (x) => x.name).map((g) => ({
        key: g.key,
        value: g.items[0],
        workspaceIds: g.items.map((x) => x.workspaceId),
      })),
      {
        key: noneStageDef.name,
        value: noneStageDef,
      },
    ],
    // Keep default sorting from the initial values
    // it's already sorted based on the order in the project definition
    sortBy: () => 0,
  }
}

export const groupByActiveStage = <TItem extends ProjectWithRelations>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, StageDefinitionSchema | null> => {
  const noneStageDef = createNoneStageDefinition()

  return {
    type: 'stage',
    name: 'Stage',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => item.activeStageDefinition,
    keyOf: (stage: StageDefinitionSchema | null) =>
      stage?.name ?? noneStageDef.name,
    initialValues: [
      ...groupInto(ctx.stageDefinitions.all, (x) => x.name).map((g) => ({
        key: g.key,
        value: g.items[0],
        workspaceIds: g.items.map((x) => x.workspaceId),
      })),
      {
        key: noneStageDef.name,
        value: noneStageDef,
      },
    ],
    // Keep default sorting from the initial values
    // it's already sorted based on the order in the project definition
    sortBy: () => 0,
  }
}

export const groupByPriority = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>({
  ctx,
}: GroupArgs): GroupDefinition<TItem, PriorityLevelSchema | null> => {
  const workspaceIds = ctx.workspaces.all.map((w) => w.id)

  return {
    type: 'priority',
    name: 'Priority',
    groupType: 'select',
    isBounded: true,
    initialValues: [
      {
        key: 'ASAP',
        value: 'ASAP',
        workspaceIds,
      },
      {
        key: 'HIGH',
        value: 'HIGH',
        workspaceIds,
      },
      {
        key: 'MEDIUM',
        value: 'MEDIUM',
        workspaceIds,
      },
      {
        key: 'LOW',
        value: 'LOW',
        workspaceIds,
      },
    ],
    accessor: (item) => getNonMeetingProperty(item, 'priorityLevel'),
    keyOf: (item) => (item == null ? 'No Value' : String(item)),
    sortBy: byProperty(
      'key',
      ordered(PriorityLevelSchema as readonly string[])
    ),
  }
}

export const groupByAssignee = <TItem extends UnchunkedTaskWithRelations>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, UserInfoSchema> => {
  return {
    type: 'user',
    name: 'Assignee',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => {
      return item.assigneeUserId
        ? (lookup('users', item.assigneeUserId) ??
            createNoneUser(item.workspaceId))
        : createNoneUser(item.workspaceId)
    },
    keyOf: (user) => user.id,
    initialValues: [
      ...ctx.workspaces.all.map((w) => ({
        key: createNoneId(w.id),
        value: createNoneUser(w.id),
        workspaceIds: [w.id],
      })),
      ...groupInto(ctx.members.all, (x) => x.userId).map((g) => ({
        key: g.key,
        value: g.items[0].user,
        workspaceIds: g.items.map((x) => x.workspaceId),
      })),
    ],
    sortBy: byValue(
      (item) => item.value?.name ?? 'Unassigned',
      orderedAtEnd(['Unassigned'], GROUPING_STRING_SORT)
    ),
  }
}

export const groupByManager = <TItem extends ProjectSchema>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, UserInfoSchema> => {
  return {
    type: 'user',
    name: 'Assignee',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => {
      return item.managerId
        ? (lookup('users', item.managerId) ?? createNoneUser(item.workspaceId))
        : createNoneUser(item.workspaceId)
    },
    keyOf: (user) => user.id,
    initialValues: [
      ...ctx.workspaces.all.map((w) => ({
        key: createNoneId(w.id),
        value: createNoneUser(w.id),
        workspaceIds: [w.id],
      })),
      ...groupInto(ctx.members.all, (x) => x.userId).map((g) => ({
        key: g.key,
        value: g.items[0].user,
        workspaceIds: g.items.map((x) => x.workspaceId),
      })),
    ],
    sortBy: byValue(
      (item) => item.value?.name ?? 'Unassigned',
      orderedAtEnd(['Unassigned'], GROUPING_STRING_SORT)
    ),
  }
}

export const groupByCreatedBy = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, UserInfoSchema | null> => {
  return {
    type: 'createdBy',
    name: 'Created By',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => {
      return (
        lookup('users', item.createdByUserId) ??
        createNoneUser(item.workspaceId)
      )
    },
    keyOf: (user) => String(user?.id),
    initialValues: groupInto(ctx.members.all, (x) => x.userId).map((g) => ({
      key: g.key,
      value: g.items[0].user,
      workspaceIds: g.items.map((x) => x.workspaceId),
    })),
    sortBy: byValue(
      (item) => item.value?.name ?? 'Unknown',
      orderedAtEnd(['Unknown'], GROUPING_STRING_SORT)
    ),
  }
}

export const groupByLabel = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, LabelSchema> => {
  return {
    type: 'label',
    name: 'Label',
    groupType: 'multiSelect',
    isBounded: true,
    accessor: (item) => {
      if (item.labelIds.length === 0) {
        return [createNoneLabel('null')]
      }
      return item.labelIds.map((id) => lookup('labels', id)).filter(Boolean)
    },
    keyOf: (label: LabelSchema) => label.name,
    initialValues: groupInto(ctx.labels.all, (x) => x.name).map((x) => ({
      key: x.key,
      value: x.items[0],
      workspaceIds: x.items.map((x) => x.workspaceId),
    })),

    sortBy: byValue(
      (x) => x.key,
      orderedAtEnd(['No Label'], GROUPING_STRING_SORT)
    ),
  }
}

const GROUPING_DATE_SORT = Compare.date.with({
  null: 'at-end',
})

export const groupByDeadline = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>(
  _args: GroupArgs
): GroupDefinition<TItem, string | null> => {
  return {
    type: 'deadline',
    name: 'Deadline',
    groupType: 'date',
    isBounded: false,
    accessor: (item) =>
      // In case of meeting tasks, we use the scheduled start date as the start date
      !isProject(item) && isMeetingTask(item) && item.scheduledEnd
        ? item.scheduledEnd
        : item.dueDate,
    keyOf: (item) =>
      item == null ? 'No Value' : DATE_FORMATTERS_BY_UNIT.day(item),
    initialValues: [
      {
        key: 'No Value',
        value: null,
      },
    ],
    sortBy: byValue((x) => safeParseDate(x.value ?? ''), GROUPING_DATE_SORT),
  }
}

export const groupByCompletedAt = <TItem extends UnchunkedTaskWithRelations>(
  _args: GroupArgs
): GroupDefinition<TItem, string | null> => {
  return {
    type: 'completedAt',
    name: 'Completed At',
    groupType: 'date',
    isBounded: false,
    accessor: (item) => item.completedTime,
    keyOf: (item) =>
      item == null ? 'No Value' : DATE_FORMATTERS_BY_UNIT.day(item),
    initialValues: [
      {
        key: 'No Value',
        value: 'Not Completed',
      },
    ],
    sortBy: byValue((x) => safeParseDate(x.value ?? ''), GROUPING_DATE_SORT),
  }
}

export const groupByCreatedAt = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>(
  _args: GroupArgs
): GroupDefinition<TItem, string> => {
  return {
    type: 'createdAt',
    name: 'Created At',
    groupType: 'date',
    isBounded: false,
    accessor: (item) => item.createdTime,
    keyOf: (item) => DATE_FORMATTERS_BY_UNIT.day(item),
    initialValues: [],
    sortBy: byValue((x) => safeParseDate(x.value), GROUPING_DATE_SORT),
  }
}

export const groupByUpdatedAt = <
  TItem extends
    | NormalTaskWithRelations
    | RecurringInstanceWithRelations
    | ProjectSchema,
>(
  _args: GroupArgs
): GroupDefinition<TItem, string | null> => {
  return {
    type: 'updatedAt',
    name: 'Updated At',
    groupType: 'date',
    isBounded: false,
    accessor: (item) =>
      'lastInteractedTime' in item && item.lastInteractedTime != null
        ? item.lastInteractedTime
        : item.updatedTime,
    keyOf: (item) =>
      item == null ? 'No Value' : DATE_FORMATTERS_BY_UNIT.day(item),
    initialValues: [
      {
        key: 'No Value',
        value: 'Not Updated',
      },
    ],
    sortBy: byValue((x) => safeParseDate(x.value ?? ''), GROUPING_DATE_SORT),
  }
}

export const groupByStartDate = <
  TItem extends UnchunkedTaskWithRelations | ProjectWithRelations,
>(
  _args: GroupArgs
): GroupDefinition<TItem, string | null> => {
  return {
    type: 'startDate',
    name: 'Start Date',
    groupType: 'date',
    isBounded: false,
    accessor: (item) =>
      // In case of meeting tasks, we use the scheduled start date as the start date
      !isProject(item) && isMeetingTask(item) && item.scheduledStart
        ? item.scheduledStart
        : item.startDate,
    keyOf: (item) =>
      item == null ? 'No Value' : DATE_FORMATTERS_BY_UNIT.day(item),
    initialValues: [
      {
        key: 'No Value',
        value: 'No Start Date',
      },
    ],
    sortBy: byValue((x) => safeParseDate(x.value ?? ''), GROUPING_DATE_SORT),
  }
}

export const groupByScheduledDate = <TItem extends UnchunkedTaskWithRelations>(
  _args: GroupArgs
): GroupDefinition<TItem, string | null> => {
  return {
    type: 'scheduledDate',
    name: 'Scheduled Date',
    groupType: 'date',
    isBounded: false,
    accessor: (item) => getScheduledDate(item)?.toISO() ?? null,
    keyOf: (item) =>
      item == null ? 'No Value' : DATE_FORMATTERS_BY_UNIT.day(item),
    initialValues: [
      {
        key: 'No Value',
        value: 'No Scheduled Date',
      },
    ],
    sortBy: byValue((x) => safeParseDate(x.value ?? ''), GROUPING_DATE_SORT),
  }
}

export const groupByProjectDefinition = <TItem extends ProjectWithRelations>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, ProjectDefinitionSchema | null> => {
  const noneProjectDef = createNoneProjectDefinition('null')

  return {
    type: 'projectDefinition',
    name: 'Template',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => item.projectDefinition,
    keyOf: (projectDefinition) =>
      projectDefinition?.name ?? noneProjectDef.name,
    initialValues: [
      {
        key: noneProjectDef.name,
        value: noneProjectDef,
      },
      ...groupInto(ctx.projectDefinitions.all, (x) => x.name).map((g) => ({
        key: g.key,
        value: g.items[0],
        workspaceIds: g.items.map((x) => x.workspaceId),
      })),
    ],
    sortBy: byProperty(
      'key',
      orderedAtEnd([noneProjectDef.name], GROUPING_STRING_SORT)
    ),
  }
}

export const groupByFolder = <
  TItem extends UnchunkedTaskWithRelations | ProjectSchema,
>({
  ctx,
  lookup,
}: GroupArgs): GroupDefinition<TItem, FolderSchema> => {
  return {
    type: 'folder',
    name: 'Folder',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => {
      const folderId =
        'project' in item ? item.project?.folderId : item.folderId
      return folderId
        ? (lookup('folders', folderId) ?? createNoneFolder(item.workspaceId))
        : createNoneFolder(item.workspaceId)
    },
    keyOf: (folder) => folder.id,
    initialValues: groupInto(ctx.folders.all, (x) => x.id).map((x) => ({
      key: x.key,
      value: x.items[0],
    })),
    sortBy: byValue(
      (item) => item.value?.name ?? 'No folder',
      orderedAtEnd(['No folder'], GROUPING_STRING_SORT)
    ),
  }
}

export const groupByEta = <
  TItem extends UnchunkedTaskWithRelations | ProjectSchema,
>({
  ctx,
}: GroupArgs): GroupDefinition<TItem, string | null> => {
  return {
    type: 'deadlineStatus',
    name: 'ETA',
    groupType: 'select',
    isBounded: true,
    accessor: (item) =>
      isProject(item)
        ? getExtendedProjectDeadlineStatus(item, ctx.statuses.all)
        : getExtendedTaskDeadlineStatus(item, ctx.statuses.all),
    keyOf: (item) => (item == null ? 'none' : item),
    initialValues: [
      {
        key: 'none',
        value: 'none',
      },
    ],
    sortBy: byValue(
      (x) => x.value ?? 'none',
      ordered(ExtendedDeadlineStatus as readonly string[])
    ),
  }
}

export const groupByColor = <TItem extends ProjectSchema>(
  _args: GroupArgs
): GroupDefinition<TItem, string | null> => {
  return {
    type: 'color',
    name: 'Color',
    groupType: 'select',
    isBounded: true,
    accessor: (item) => (item.color ? item.color : 'none'),
    keyOf: (item) => (item == null ? 'none' : item),
    initialValues: [
      {
        key: 'none',
        value: 'none',
      },
    ],
    sortBy: byValue(
      (x) => x.value ?? 'none',
      ordered(COLORS as readonly string[])
    ),
  }
}
