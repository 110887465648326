import { ChevronLeftOutline, ChevronRightOutline } from '@motion/icons'
import { ButtonGroup, IconButton } from '@motion/ui/base'

export type CalendarArrowsProps = {
  back: () => void
  forward: () => void
}

export const CalendarArrows = ({ back, forward }: CalendarArrowsProps) => {
  return (
    <ButtonGroup size='small' segmented>
      <IconButton
        sentiment='neutral'
        variant='muted'
        size='small'
        icon={ChevronLeftOutline}
        onClick={() => void back()}
      />

      <IconButton
        sentiment='neutral'
        variant='muted'
        size='small'
        icon={ChevronRightOutline}
        onClick={() => void forward()}
      />
    </ButtonGroup>
  )
}
