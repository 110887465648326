import { useSharedState } from '@motion/react-core/shared-state'
import { isNoneId } from '@motion/shared/identifiers'
import {
  ActiveFilterKey,
  buildProjectFilterFrom,
} from '@motion/ui-logic/pm/data'
import { byProperty, Compare } from '@motion/utils/array'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useLookup } from '~/global/cache'
import { useAppDataContext } from '~/global/contexts'
import { createProjectProxy, type ProjectWithRelations } from '~/global/proxies'
import { useMemo } from 'react'

import { getSortFn, useProjectFields } from '../../fields'
import { useViewState } from '../../view-state'

export const useProjects = () => {
  const ctx = useAppDataContext()
  const lookup = useLookup()
  const [viewState] = useViewState()
  const [filter] = useSharedState(ActiveFilterKey)
  const projectFields = useProjectFields()
  const user = useAuthenticatedUser()
  return useMemo<ProjectWithRelations[]>(() => {
    let data = ctx.projects
      .all()
      .filter((project) => !isNoneId(project.id))
      .filter(buildProjectFilterFrom(ctx, filter, undefined, user.uid))

    if (viewState.search) {
      data = data.filter((x) =>
        x.name.toLowerCase().includes(viewState.search.toLowerCase())
      )
    }

    const sortBy =
      getSortFn(viewState.sortBy, projectFields) ??
      byProperty('name', Compare.string.with({ numeric: true }))

    const proxies = data.map((p) => createProjectProxy(p, lookup)).sort(sortBy)

    return proxies
  }, [
    ctx,
    filter,
    lookup,
    projectFields,
    user.uid,
    viewState.search,
    viewState.sortBy,
  ])
}
