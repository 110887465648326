import { useSearchParams } from 'react-router-dom'

import { type SetupProjectTabOption, useTabOptions } from './use-tab-options'

export function useActiveTab() {
  const [searchParams] = useSearchParams()
  const activeTabSearchParam = searchParams.get('activeTab') ?? ''
  const tabOptions = useTabOptions()

  const activeTab = tabOptions.includes(activeTabSearchParam)
    ? activeTabSearchParam
    : tabOptions[0]

  return activeTab as SetupProjectTabOption
}
