import { StartDateSolid } from '@motion/icons'
import { StartRelativeDateOption } from '@motion/shared/common'

import { RelativeDateDropdown } from './relative-date-dropdown'
import { RelativeDateTriggerButton } from './relative-date-trigger-button'

type StartDateFieldProps = {
  value: StartRelativeDateOption
  onChange: (item: StartRelativeDateOption) => void
  disabled?: boolean
}

export const StartDateField = ({
  value,
  onChange,
  disabled,
}: StartDateFieldProps) => {
  return (
    <RelativeDateDropdown options={StartRelativeDateOption} onChange={onChange}>
      <RelativeDateTriggerButton
        label='Start date'
        icon={<StartDateSolid />}
        value={value}
        disabled={disabled}
      />
    </RelativeDateDropdown>
  )
}
