import { BackendApiService } from './BackendApiService'

import { type Calendar } from '../types/settingsTypes'

type CalendarListDto = {
  updateFrequentlyMet: boolean
}

/**
 * Service for updating the calendar lists (frequently met with calendars)
 * @deprecated
 */
export class CalendarListService extends BackendApiService {
  static id = 'CalendarListService' as const
  async update({
    updateFrequentlyMet = false,
  }: CalendarListDto): Promise<void> {
    await this.request('calendar_list', 'POST', {
      updateFrequentlyMet,
    })
  }

  async get(): Promise<Calendar[]> {
    const res = await this.request('calendar_list/calendars', 'GET')
    return res.calendars as Calendar[]
  }
}

export const instance = new CalendarListService()
