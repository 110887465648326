import { priorityLevels } from '@motion/rpc/types'

import { useProjectUpdater } from '~/areas/project/hooks'
import {
  PriorityField,
  type PriorityFieldProps,
} from '~/areas/task-project/fields'
import { useProject } from '~/global/hooks'

type ProjectPriorityFieldProps = {
  projectId: string
  onChange?: PriorityFieldProps['onChange']
}

export function ProjectPriorityField({
  projectId,
  onChange: onChangeFromProps,
}: ProjectPriorityFieldProps) {
  const updateProject = useProjectUpdater()
  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project is required')
  }

  const onChange: PriorityFieldProps['onChange'] = async (priorityLevel) => {
    await updateProject(project.id, { priorityLevel })
    onChangeFromProps?.(priorityLevel)
  }

  return (
    <PriorityField
      list={priorityLevels}
      value={project?.priorityLevel}
      onChange={onChange}
    />
  )
}
