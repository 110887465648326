import { Button } from '@motion/ui/base'

import { useEffectiveView, useResetView } from '../../views'

export const ConnectedResetViewButton = () => {
  const reset = useResetView()
  const info = useEffectiveView()

  if (info.state === 'clean') return null
  return (
    <Button onClick={reset} size='small' variant='muted'>
      Reset
    </Button>
  )
}
