import { logInDev } from '@motion/web-base/logging'

import api from './chromeApi/chromeApiBackground'
import { setShowEventsInAgenda } from './state/mainSlice'
import { store } from './state/proxy'
import { setShowCompletedTasks } from './utils/taskUtils'

/**
 * Fires when the firestore settings collection is updated. This returns
 * a filtered subset of settings values, which will eventually be persisted to
 * storage. This allows execute certain logic only changes were made on a
 * different extension/webapp instance.
 *
 * @param newSettings
 */
export const onReceivedNewSettings = async (
  newSettings: Record<string, any>
) => {
  logInDev('onReceivedNewSettings')

  if ('showCompletedTasks' in newSettings) {
    await setShowCompletedTasks(newSettings.showCompletedTasks, null)
    // The prior function already sets storage
    delete newSettings.showCompletedTasks
  }

  if ('showAgendaEvents' in newSettings) {
    store.dispatch(setShowEventsInAgenda(newSettings.showAgendaEvents))
    delete newSettings.showAgendaEvents
  }

  await api.storage.local.set(newSettings)
}
