import { useSharedState } from '@motion/react-core/shared-state'
import { createNoneId } from '@motion/shared/identifiers'
import {
  byProperty,
  cascade,
  Compare,
  groupInto,
  ordered,
} from '@motion/utils/array'

import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { FilterButton } from './filter-button'

import { useFieldFilter } from '../../context'
import { buildIdFilter, useWorkspaceFilter } from '../../utils'
import { type DropdownFilterProps } from '../types'

export const ConnectedProjectFilter = (props: DropdownFilterProps) => {
  const [context] = useSharedState(AppWorkspaceContext)
  const [filter, setFilter] = useFieldFilter('projects', 'ids')

  const byWorkspace = useWorkspaceFilter()

  const projects = useMemo(() => {
    const all = context.projects.all.filter(byWorkspace)

    const sections = groupInto(all, (p) => p.workspaceId)
      .map((g) => ({
        key: g.key,
        label: context.workspaces.byId[g.key]?.name ?? 'unknown',
        items: g.items.sort(
          cascade(
            byProperty('id', ordered([createNoneId(g.key)])),
            byProperty('name', Compare.caseInsensitive)
          )
        ),
      }))
      .sort(byProperty('label', Compare.caseInsensitive))
    return {
      all,
      sections,
    }
  }, [byWorkspace, context.projects.all, context.workspaces.byId])

  return (
    <FilterButton
      itemType='sectioned-checkbox'
      sections={projects.sections}
      renderEmpty={NoProjects}
      computeSelected={(item) => filter?.value.includes(item.id) ?? false}
      onSelect={(values) => {
        setFilter(buildIdFilter(values, filter))
      }}
      label='Project'
      searchPlaceholder='Choose Projects...'
      renderItem={(item) => (
        <span className='max-w-xs truncate'>{item.name}</span>
      )}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
    />
  )
}
const NoProjects = () => 'No Projects'
