import { TagSolid } from '@motion/icons'
import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { type CustomFieldFilters } from '@motion/ui-logic/pm/data'
import { entries } from '@motion/utils/object'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'
import { Label } from '~/global/components/labels'

import { ConnectedGroupedFieldDropdown } from './grouped-field-dropdown'
import { type AllowedFields, type ExtendedMatchingFieldOption } from './types'

import {
  WorkspaceItem,
  WorkspaceItemGroupHeader,
} from '../../../../buttons/workspace-item'
import { MultiSelectFilterValue } from '../../../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../../../types'
import { FilterItem } from '../../filter-item'

type Props = DropdownFilterProps & {
  groupedFields: CustomFieldSchemaByType<AllowedFields>[]
}

export const ConnectedCustomFieldSelectFilter = (props: Props) => {
  const { groupedFields } = props
  const firstField = groupedFields[0]
  const [filter, setFilter] = useCustomFieldFilter(props.target, firstField)

  const getAllItems = (): ExtendedMatchingFieldOption[] =>
    groupedFields.flatMap((f) =>
      f.metadata.options.map((option) => ({
        ...option,
        customFieldId: f.id,
        workspaceId: f.workspaceId,
        name: option.value,
      }))
    )

  const filterList = filter != null ? Object.values(filter) : []
  const ids =
    filter != null ? filterList.flatMap((filter) => filter.value) : null

  const handleInvert = (invert: boolean) => {
    if (filter == null) return
    const inverted = entries(filter).reduce<
      CustomFieldFilters['select'][string]
    >(
      (acc, [key, value]) => ({
        ...acc,
        [key]: { ...value, inverse: invert },
      }),
      {}
    )
    setFilter(inverted)
  }

  return (
    <FilterItem
      label={firstField.name}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setFilter(null)}
      canInvert
      inverted={filterList[0]?.inverse}
      onInvertChanged={handleInvert}
      renderDropdown={({ close }) => (
        <ConnectedGroupedFieldDropdown
          target={props.target}
          customField={firstField}
          getAllItems={getAllItems}
          placeholder='Select options ...'
          renderItem={(item) => (
            <WorkspaceItem item={item}>
              <Label value={item} />
            </WorkspaceItem>
          )}
          renderHeader={(group) => (
            <WorkspaceItemGroupHeader group={group}>
              <Label value={group.items[0]} />
            </WorkspaceItemGroupHeader>
          )}
          close={close}
        />
      )}
    >
      <MultiSelectFilterValue
        Icon={TagSolid}
        type={firstField.type}
        ids={ids}
        getAllItems={getAllItems}
      />
    </FilterItem>
  )
}
