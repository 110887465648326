import { XOutline } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  useAgendaState,
  useSchedulingFeedbackSurveyUrl,
} from '~/areas/agenda/hooks'
import { PeekModalPopover } from '~/areas/calendar/components/calendar-peek-modal/peek-modal-popover'
import { MotionLink } from '~/global/components'

export function MoveTaskPopover() {
  const [agendaState, setAgendaState] = useAgendaState()
  const { attemptedMoveTaskEventId } = agendaState
  const surveyUrl = useSchedulingFeedbackSurveyUrl()

  if (attemptedMoveTaskEventId == null) return null

  let element: HTMLElement | null =
    document.querySelector(`[data-event-id="${attemptedMoveTaskEventId}"]`) ??
    null

  if (element == null) {
    element = document.querySelector(`[id=agenda-right-sidebar]`)
  }

  if (element == null) {
    return null
  }

  const closePopover = () => {
    setAgendaState((prev) => ({
      ...prev,
      attemptedMoveTaskEventId: null,
    }))
  }

  const onClickXButton = () => {
    recordAnalyticsEvent('AGENDA_MOVE_TASK_POPOVER_CLOSED')
    closePopover()
  }

  const onClickPrimaryButton = () => {
    recordAnalyticsEvent('AGENDA_OPENED_FEEDBACK_FORM', {
      from_element: 'sidebar-popover',
    })
    closePopover()
  }

  return (
    <PeekModalPopover anchor={element}>
      <ModalContainer>
        <ContentContainer>
          <MainContentContainer>
            <Header>
              You can drag tasks on the calendar in any order you want!
            </Header>
            <Paragraph>
              If you would like to drag tasks directly on the agenda, we’d like
              to know more. Click the button below and our Product Team will
              reach out to you to learn more about your scenario
            </Paragraph>
          </MainContentContainer>
          <ButtonContainer>
            <MotionLink external url={surveyUrl}>
              <Button
                variant='solid'
                sentiment='primary'
                alignment='left'
                size='small'
                onClick={onClickPrimaryButton}
              >
                <ButtonLabel>I want to drag tasks on the agenda</ButtonLabel>
              </Button>
            </MotionLink>
          </ButtonContainer>
        </ContentContainer>
        <XButtonContainer>
          <button onClick={onClickXButton}>
            <XOutline height={16} width={16} />
          </button>
        </XButtonContainer>
      </ModalContainer>
    </PeekModalPopover>
  )
}

const ModalContainer = classed(
  'div',
  'flex flex-row gap-4 max-w-[290px] min-h-[180px] p-3'
)
const ContentContainer = classed('div', 'flex flex-col flex-start gap-3')
const MainContentContainer = classed('div', 'flex flex-col flex-start gap-1')
const Header = classed('h1', 'font-bold text-sm')
const Paragraph = classed('p', 'font-normal text-xs')

const ButtonContainer = classed('div', 'flex')
const ButtonLabel = classed('span', 'font-semibold text-xs')
const XButtonContainer = classed('div', 'items-start')
