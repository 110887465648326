import { classed } from '@motion/theme'
import { type OOOEventSchema } from '@motion/zod/client'

import { ZIndexMap } from '../../shared-constants'
import { StyledPlannerRow } from '../common'
import { OOOEventsRow } from '../planner-row/ooo-event-column/ooo-events-row'

export function OOOHeaderRow({
  events,
  eventHeight,
}: {
  events: OOOEventSchema[]
  eventHeight?: number
}) {
  return (
    <StyledPlannerRow className='w-full left-0 flex'>
      <div className='relative h-full w-full max-h-full flex flex-row items-center'>
        <TitleContainer style={{ zIndex: ZIndexMap.rowName }}>
          <span className='text-semantic-neutral-text-subtle text-xs font-medium truncate'>
            Out of Office
          </span>
        </TitleContainer>
        <OOOEventsRow events={events} eventHeight={eventHeight} />
      </div>
    </StyledPlannerRow>
  )
}

const TitleContainer = classed('div', {
  base: `
    flex h-7 py-1 px-3 items-center gap-2 shrink-0 self-stretch min-w-0 w-[217px]
    border-b border-semantic-neutral-border-light bg-semantic-neutral-surface-bg-default
  `,
})
