import { ClockSolid } from '@motion/icons'
import { formatToTaskDuration } from '@motion/ui-logic'

import { BulkDurationDropdown, FieldButton } from '../components'
import { useBulkOpsField } from '../contexts'

export function DurationField() {
  const [durationField, setDurationField] = useBulkOpsField('duration')

  // We're checking for `undefined` because durationField can be null (when it's set to "To be determined")
  const active = durationField !== undefined

  return (
    <BulkDurationDropdown
      onChange={setDurationField}
      selectedDuration={durationField}
    >
      <FieldButton
        active={active}
        analyticsName='duration'
        onRemove={() => setDurationField(undefined)}
      >
        <ClockSolid />
        {active ? formatToTaskDuration(durationField) : 'Duration'}
      </FieldButton>
    </BulkDurationDropdown>
  )
}
