import { useAutoScheduleSettings } from '~/global/hooks'
import { type SettingPageOptions } from '~/pages/settings/types'

import { PublicTaskNamesSettings } from './PublicTaskNamesSettings/PublicTaskNamesSettings'
import { TaskBreakSettings } from './TaskBreakSettings/TaskBreakSettings'
import { TimeBlockSettings } from './TimeBlockSettings/TimeBlockSettings'

import { useInNoExternalCalendarsMode } from '../../../../hooks/use-in-no-external-calendars-mode'
import { SubParagraph, TextButton } from '../../../Common'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

interface TimeManagerSettingsProps {
  setSettingsPage: (page: SettingPageOptions) => void
}

export const TaskManagerSettings = ({
  setSettingsPage,
}: TimeManagerSettingsProps) => {
  const selectedTaskTimeBlockMode = useAutoScheduleSettings().taskTimeBlockMode
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()

  const onCalendarLinkClick = () => {
    setSettingsPage('calendar')
  }

  return (
    <SettingPage title='Auto-scheduling' className='gap-6'>
      <SubParagraph className='text-sm'>
        The task manager checks for conflicts from your &quot;My Calendars&quot;
        group. To modify this group, go to the
        <TextButton
          onClick={onCalendarLinkClick}
          className='mx-1 text-inherit underline dark:text-inherit'
        >
          Calendars
        </TextButton>
        page in settings.
      </SubParagraph>
      <TimeBlockSettings />
      {selectedTaskTimeBlockMode !== 'no-events' &&
        !noExternalCalendarsMode && <PublicTaskNamesSettings />}
      <TaskBreakSettings />
    </SettingPage>
  )
}
