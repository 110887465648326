import { useCallback } from 'react'

import { useAppDispatch } from '../../../state/hooks'
import { uploadWorkspaceImage } from '../../../state/projectManagement/workspaceThunks'

type UploadWorkspaceImageOptions = {
  projectId?: string
  taskId?: string
  isRecurringTask?: boolean
}

export function useUploadWorkspaceImage(
  workspaceId: string,
  options: UploadWorkspaceImageOptions
) {
  const dispatch = useAppDispatch()
  const { projectId, taskId, isRecurringTask } = options

  return useCallback(
    async (file: File) => {
      const res = await dispatch(
        uploadWorkspaceImage({
          file,
          projectId,
          taskId: !isRecurringTask ? taskId : undefined,
          recurringTaskId: isRecurringTask ? taskId : undefined,
          workspaceId: workspaceId,
        })
      ).unwrap()

      if (res?.url) {
        return res.url
      }
    },
    [dispatch, workspaceId, taskId, isRecurringTask, projectId]
  )
}
