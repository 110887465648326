import { type CreateZoomMeetingDto } from '@motion/rpc-types'
import { EventConferenceType } from '@motion/shared/common'
import {
  descriptionAddConference,
  generateCustomLocationMeetingMessage,
  generatePhoneMeetingMessage,
  generateZoomMeetingMessage,
  generateZoomPersonalMeetingMessage,
  stripDescriptionConference,
} from '@motion/ui-logic/calendar'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useConferenceSettingsApi } from '~/areas/settings/state'
import { useAppDispatch } from '~/state/hooks'
import { showPromptModal } from '~/state/modalsSlice'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

export const useGenerateConferencingData = () => {
  const dispatch = useAppDispatch()
  const { selectConferenceSettings, createZoomMeeting } =
    useConferenceSettingsApi()
  const conferenceSettings = selectConferenceSettings()

  const getPhoneNumber = useCallback(async () => {
    return (await dispatch(
      showPromptModal({ type: 'phoneNumber' })
    ).unwrap()) as string
  }, [dispatch])

  const getCustomLocation = useCallback(async () => {
    return (await dispatch(
      showPromptModal({ type: 'customLocation' })
    ).unwrap()) as string
  }, [dispatch])

  return async (
    conferenceType: EventConferenceType,
    currentDescription: string,
    zoomData: Pick<
      CreateZoomMeetingDto,
      'topic' | 'startTime' | 'recurringRule'
    > & { endTime: string }
  ): Promise<{
    location: string
    description: string
  }> => {
    const newDescription = stripDescriptionConference(currentDescription)

    if (conferenceType === EventConferenceType.zoom) {
      let location = ''
      recordAnalyticsEvent('CALENDAR_ZOOM_CLICK')
      if (
        conferenceSettings?.zoomLinkType === 'personal' &&
        conferenceSettings?.zoomAccount &&
        conferenceSettings?.zoomPersonalLink
      ) {
        location = conferenceSettings?.zoomPersonalLink
      } else if (
        conferenceSettings?.zoomLinkType === 'manual' &&
        conferenceSettings?.zoomManualLink
      ) {
        location = conferenceSettings?.zoomManualLink
      }

      if (location) {
        // setLocation(linkMessage)
        const inviteMessage = generateZoomPersonalMeetingMessage(location)
        const description = descriptionAddConference(
          newDescription,
          inviteMessage
        )
        return { location, description }
      }

      const dataForCreate: CreateZoomMeetingDto = {
        topic: zoomData.topic,
        recurringRule: zoomData.recurringRule,
        startTime: zoomData.startTime,
        duration: DateTime.fromISO(zoomData.endTime).diff(
          DateTime.fromISO(zoomData.startTime),
          'minutes'
        ).minutes,
      }

      const res = await createZoomMeeting(dataForCreate)

      if (res.completed && res.data) {
        const inviteMessage = generateZoomMeetingMessage(res.data)
        const description = descriptionAddConference(
          newDescription,
          inviteMessage
        )
        location = res.data.joinUrl
        return { location, description }
      }
      throw new Error('Zoom failed')
    } else if (conferenceType === EventConferenceType.phone) {
      recordAnalyticsEvent('CALENDAR_PHONE_MODAL_OPEN')
      let phoneNumber = conferenceSettings?.phoneNumber
      if (!phoneNumber) {
        phoneNumber = await getPhoneNumber()
      }
      if (phoneNumber) {
        const inviteMessage = generatePhoneMeetingMessage(phoneNumber)
        const description = descriptionAddConference(
          newDescription,
          inviteMessage
        )
        return { location: phoneNumber, description }
      }
    } else if (conferenceType === EventConferenceType.customLocation) {
      recordAnalyticsEvent('CALENDAR_CUSTOM_LOCATION_OPEN')
      let customLocation = conferenceSettings?.customLocation
      if (!customLocation) {
        customLocation = await getCustomLocation()
      }
      if (customLocation) {
        const inviteMessage =
          generateCustomLocationMeetingMessage(customLocation)
        const description = descriptionAddConference(
          newDescription,
          inviteMessage
        )
        return { location: customLocation, description }
      }
    }

    return { location: '', description: newDescription }
  }
}
