import { useSharedStateContext } from '@motion/react-core/shared-state'

import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { getFilteredWorkspaceCustomField } from '~/areas/custom-fields/utils'
import { AppWorkspaceContext } from '~/global/contexts'
import { useCallback } from 'react'

/**
 * Provide a suite of stable functions to read custom fields
 * Do not use these for rendering. Only use this for data you need in handlers/callbacks
 */
export function useCustomFieldsFns() {
  const sharedStateApi = useSharedStateContext()
  const ctx = sharedStateApi.get(AppWorkspaceContext)

  const getCustomFields = useCallback(
    (workspaceId: string): WorkspaceCustomField[] => {
      return ctx.customFields.all
        .filter((s) => s.workspaceId === workspaceId)
        .map((field) => getFilteredWorkspaceCustomField(field))
    },
    [ctx.customFields.all]
  )

  const getCustomField = useCallback(
    (customFieldId: string): WorkspaceCustomField | undefined => {
      const customField = ctx.customFields.byId[customFieldId]
      if (customField == null) return

      return getFilteredWorkspaceCustomField(customField)
    },
    [ctx.customFields.byId]
  )

  return {
    getCustomFields,
    getCustomField,
  }
}
