import { isNoneId } from '@motion/shared/identifiers'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type TaskSchema, type WorkspaceSchema } from '@motion/zod/client'

import { ProjectLabel } from '~/global/components/labels'
import { useProjects } from '~/global/hooks'
import { type ReactNode } from 'react'

export type ProjectDropdownProps = Omit<
  ProjectDropdownContentProps,
  'close'
> & {
  children?: ReactNode
}

export const ProjectDropdown = ({
  selectedProjectId,
  workspaceId,
  onChange,
  children,
  hideNoProject,
}: ProjectDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ProjectDropdownContent
          workspaceId={workspaceId}
          selectedProjectId={selectedProjectId}
          close={close}
          onChange={onChange}
          hideNoProject={hideNoProject}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type ProjectDropdownContentProps = {
  close: () => void
  selectedProjectId: TaskSchema['projectId']
  workspaceId: WorkspaceSchema['id']
  onChange(projectId: TaskSchema['projectId']): void
  hideNoProject?: boolean
}
const ProjectDropdownContent = ({
  close,
  onChange,
  workspaceId,
  selectedProjectId,
  hideNoProject = false,
}: ProjectDropdownContentProps) => {
  const projects = useProjects(workspaceId, {
    includeNoProject: !hideNoProject,
  })

  return (
    <SearchableList
      searchable
      items={projects}
      computeKey={(p) => p.id}
      computeSearchValue={(p) => p.name}
      computeSelected={(p) =>
        p.id === selectedProjectId ||
        (isNoneId(p.id) && selectedProjectId == null)
      }
      onSelect={(p) => {
        close()
        onChange(isNoneId(p.id) ? null : p.id)
      }}
      renderItem={(p) => (
        <div className='max-w-xs'>
          <ProjectLabel value={p} />
        </div>
      )}
      inputProps={{ placeholder: 'Choose project...' }}
    />
  )
}
