import { API, createUseMutation } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'

const cacheKeysToUpdate = [API.workspacesV2.queryKeys.root, MODEL_CACHE_KEY]
const queryFilter = createQueryFilter(cacheKeysToUpdate)

const useCreateStatusMutation = createUseMutation(API.workspacesV2.createStatus)
export const useCreateStatus = () => {
  const queryClient = useQueryClient()

  return useCreateStatusMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useUpdateStatusMutation = createUseMutation(API.workspacesV2.updateStatus)
export const useUpdateStatus = () => {
  const queryClient = useQueryClient()

  return useUpdateStatusMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useDeleteStatusMutation = createUseMutation(API.workspacesV2.deleteStatus)
export const useDeleteStatus = () => {
  const queryClient = useQueryClient()

  return useDeleteStatusMutation({
    onSuccess: (_, { statusId }) => {
      MotionCache.delete(queryClient, queryFilter, 'statuses', statusId)
    },
  })
}
