import { CheckCircleOutline } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { DaysOfWeekSchema } from '@motion/zod/client'

import { useState } from 'react'

import { type OnboardingRecurringTask } from './types'

type CreateRecurringTasksProps = {
  onSkip: () => void
  onCreateRecurringTasks: (tasks: OnboardingRecurringTask[]) => Promise<void>
}

const weekdays = DaysOfWeekSchema.filter((d) => d !== 'SA' && d !== 'SU')

const defaultRecurringTasks: OnboardingRecurringTask[] = [
  {
    days: weekdays,
    deadlineType: 'SOFT',
    duration: 15,
    frequency: 'DAILY',
    name: 'Meditate',
    timeEnd: '11:00 am',
    timeStart: '8:00 am',
  },
  {
    days: weekdays,
    deadlineType: 'SOFT',
    duration: 60,
    frequency: 'DAILY',
    name: 'Check emails',
    timeEnd: '5:00 pm',
    timeStart: '9:00 am',
  },
  {
    days: weekdays,
    deadlineType: 'SOFT',
    duration: 30,
    frequency: 'DAILY',
    name: 'Lunch',
    timeEnd: '1:00 pm',
    timeStart: '11:00 am',
  },
  {
    days: weekdays,
    deadlineType: 'SOFT',
    duration: 30,
    frequency: 'DAILY',
    name: 'Exercise',
    timeEnd: '5:00 pm',
    timeStart: '2:00 pm',
  },
]

export function CreateRecurringTasks({
  onSkip,
  onCreateRecurringTasks,
}: CreateRecurringTasksProps) {
  const [selectedTasks, setSelectedTasks] = useState<OnboardingRecurringTask[]>(
    []
  )

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  function toggleSelectedTask(task: OnboardingRecurringTask) {
    setSelectedTasks((tasks) => {
      const taskNames = tasks.map((task) => task.name)
      return taskNames.includes(task.name)
        ? tasks.filter((t) => t.name !== task.name)
        : [...tasks, task]
    })
  }

  async function onSubmit() {
    setLoading(true)
    setError('')

    await onCreateRecurringTasks(selectedTasks).catch((error) => {
      setLoading(false)
      setError(error.message)
    })
  }

  const taskNames = selectedTasks.map((task) => task.name)

  return (
    <div className='bg-light-300 h-full w-full'>
      <div className='flex h-full'>
        <div className='m-8 flex lg:min-w-[500px] flex-[1.5_1.5_0%] justify-center'>
          <div className='mx-auto max-w-[550px]'>
            <div className='lg:my-4'>
              <h1 className='text-lg lg:text-[32px] font-semibold lg:leading-[42px]'>
                Recurring Tasks
              </h1>

              <p className='text-light-1100 lg:mt-4'>
                Use recurring tasks to create tasks that repeat periodically.
                Choose some recurring tasks that you want to block time for.
              </p>
            </div>

            <div className='border-light-500 rounded border bg-white p-3 lg:py-6 lg:px-6 mt-4 lg:mt-0'>
              <div>
                <h3 className='text-sm lg:text-base font-semibold'>
                  Which recurring tasks would you like to schedule on your
                  calendar?
                </h3>
                <div className='my-4 lg:my-6'>
                  {defaultRecurringTasks.map((task) => {
                    const isSelected = taskNames.includes(task.name)
                    return (
                      <div
                        key={task.name}
                        className='my-1 flex items-center gap-2'
                      >
                        <Checkbox
                          onChange={() => toggleSelectedTask(task)}
                          checked={isSelected}
                          label={task.name}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>

              <p className='text-light-1100 text-xs'>
                Don&apos;t worry - you&apos;ll be able to change these settings
                later
              </p>
            </div>

            {error && (
              <div className='break-words px-2 py-2 pr-20'>
                <p className='text-sm text-red-600'>{error}</p>
              </div>
            )}

            <div className='mt-6 flex flex-col gap-4'>
              <Button
                fullWidth
                onClick={onSubmit}
                loading={loading}
                disabled={selectedTasks.length === 0}
              >
                Create recurring tasks
              </Button>

              <Button sentiment='neutral' variant='muted' onClick={onSkip}>
                Skip
              </Button>
            </div>
          </div>
        </div>

        <div className='h-full min-w-[600px] flex-1 bg-white px-8 hidden lg:flex'>
          <div className='flex w-full h-full items-center justify-center'>
            <div className='flex w-full gap-2'>
              <div className='flex-1'>
                <div className='border-b-light-500 my-1 border-b py-0.5'>
                  <span className='semibold text-light-1000 text-xs uppercase'>
                    Mon
                  </span>
                </div>

                <div className='mt-0.5 h-12 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/2 rounded-md bg-gray-400'></div>
                </div>
                <div className='mt-0.5 h-6 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/3 rounded-md bg-gray-400'></div>
                </div>

                <div className='bg-primary-700 mt-0.5 h-12 rounded-lg p-2'>
                  <div className=''>
                    <div className='flex items-center gap-1'>
                      <CheckCircleOutline className='text-white w-3 h-3' />
                      <span className='text-[10px] font-semibold text-white'>
                        Lunch
                      </span>
                    </div>
                  </div>
                </div>

                <div className='mt-0.5 h-8' />

                <div className='mt-0.5 h-16 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/3 rounded-md bg-gray-400'></div>
                </div>
              </div>

              <div className='flex-1'>
                <div className='border-b-light-500 my-1 border-b py-0.5'>
                  <span className='semibold text-light-1000 text-xs uppercase'>
                    Tue
                  </span>
                </div>

                <div className='mt-0.5 h-12 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/2 rounded-md bg-gray-400'></div>
                </div>
                <div className='bg-primary-700 mt-0.5 h-12 rounded-lg p-2'>
                  <div className='flex items-center gap-1'>
                    <CheckCircleOutline className='text-white w-3 h-3' />
                    <span className='text-[10px] font-semibold text-white'>
                      Lunch
                    </span>
                  </div>
                </div>
                <div className='mt-0.5 h-12 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/3 rounded-md bg-gray-400'></div>
                </div>

                <div className='mt-0.5 h-8 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/3 rounded-md bg-gray-400'></div>
                </div>

                <div className='mt-0.5 h-6' />

                <div className='mt-0.5 h-8 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/3 rounded-md bg-gray-400'></div>
                </div>
              </div>
              <div className='flex-1'>
                <div className='border-b-light-500 my-1 border-b py-0.5'>
                  <span className='semibold text-light-1000 text-xs uppercase'>
                    Wed
                  </span>
                </div>

                <div className='mt-0.5 h-12 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/2 rounded-md bg-gray-400'></div>
                </div>
                <div className='mt-0.5 h-8 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/3 rounded-md bg-gray-400'></div>
                </div>

                <div className='h-8' />

                <div className='bg-primary-700 mt-0.5 h-12 rounded-lg p-2'>
                  <div className='flex items-center gap-1'>
                    <CheckCircleOutline className='text-white w-3 h-3' />
                    <span className='text-[10px] font-semibold text-white'>
                      Lunch
                    </span>
                  </div>
                </div>

                <div className='mt-0.5 h-8 rounded-lg bg-gray-200 p-2'>
                  <div className='h-2.5 w-1/3 rounded-md bg-gray-400'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
