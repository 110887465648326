import { useOnValueChange } from '@motion/react-core/hooks'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import {
  type OpenedModalOptions,
  useModalStatus,
} from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'

import {
  type RouteAnalyticsMetadataParams,
  useRouteAnalyticsMetadata,
} from '~/global/analytics'
import { type FunctionComponent } from 'react'

import { CurrentModalContext } from './current-modal-context'

export type ModalTriggerComponentProps<
  TModalName extends string & keyof ModalDefinitions,
> = Pick<OpenedModalOptions<ModalDefinitions[TModalName]>, 'close'> &
  (OpenedModalOptions<ModalDefinitions[TModalName]>['props'] extends never
    ? unknown
    : OpenedModalOptions<ModalDefinitions[TModalName]>['props'])

export function ModalTrigger<
  TModalName extends string & keyof ModalDefinitions,
  TComponent extends FunctionComponent<ModalTriggerComponentProps<TModalName>>,
>(props: { name: TModalName; component: TComponent }) {
  const { name, component } = props
  const modal = useModalStatus(name)

  if (!modal.visible) {
    return null
  }

  return (
    <ModalTriggerWithAnalytics
      name={name}
      open
      component={component}
      modalProps={modal.props}
      modalClose={modal.close}
    />
  )
}

export type AnimatedModalTriggerComponentProps<
  TModalName extends string & keyof ModalDefinitions,
> = Pick<OpenedModalOptions<ModalDefinitions[TModalName]>, 'close'> &
  (OpenedModalOptions<ModalDefinitions[TModalName]>['props'] extends never
    ? unknown
    : OpenedModalOptions<ModalDefinitions[TModalName]>['props']) & {
    open: boolean
  }

/**
 * AnimatedModalTrigger is a specific component to manage a modal visibility from the ModalAPI
 * The AnimatedModalTrigger is always rendering the component received as props
 * and provide an `open` prop which defines the real open state of the modal.
 * This is allowing the modal to do open/close animation
 *
 * When no animation is needed, please use `ModalTrigger` instead which doesn't render the component until it's visible
 */
export function AnimatedModalTrigger<
  TModalName extends string & keyof ModalDefinitions,
  TComponent extends FunctionComponent<
    AnimatedModalTriggerComponentProps<TModalName>
  >,
>(props: { name: TModalName; component: TComponent }) {
  const { name, component } = props
  const modal = useModalStatus(name)

  const modalProps = modal.visible ? modal.props : {}
  const modalClose = modal.visible ? modal.close : () => {}

  return (
    <ModalTriggerWithAnalytics
      name={name}
      open={modal.visible}
      component={component as any}
      modalProps={modalProps}
      modalClose={modalClose}
    />
  )
}

declare module '@motion/web-base/analytics/events' {
  type ModalData = RouteAnalyticsMetadataParams & {
    name: string
  }

  interface AnalyticEvents {
    MODAL_SHOWN: ModalData
    MODAL_ACTION: ModalData
    MODAL_CLOSE: ModalData
  }
}

function ModalTriggerWithAnalytics<
  TModalName extends string & keyof ModalDefinitions,
  TComponent extends FunctionComponent<ModalTriggerComponentProps<TModalName>>,
>(props: {
  name: TModalName
  open: boolean
  component: TComponent
  modalProps: Partial<ModalDefinitions[TModalName]>
  modalClose: OpenedModalOptions<ModalDefinitions[TModalName]>['close']
}) {
  const {
    name,
    open = false,
    component: Component,
    modalProps,
    modalClose,
  } = props

  const context = useRouteAnalyticsMetadata()

  // Very generic modals might define `{analytics: name}` to provide a better analytics name than the modal name
  const analyticsName =
    modalProps != null && 'analytics' in modalProps
      ? ((modalProps.analytics as any)?.name ?? name)
      : name

  useOnValueChange(
    open,
    (newValue) => {
      if (!newValue) return

      recordAnalyticsEvent('MODAL_SHOWN', {
        name: analyticsName,
        ...context,
      })
    },
    { triggerOnFirstRender: true }
  )

  const close = (value: any) => {
    if (value === undefined) {
      recordAnalyticsEvent('MODAL_CLOSE', {
        name: analyticsName,
        ...context,
      })
      modalClose()
      return
    }

    // Only prompt modals would come here
    // because only prompts close the modal with a specific value
    recordAnalyticsEvent('MODAL_ACTION', {
      name: analyticsName,
      ...context,
    })

    modalClose(value)
  }

  return (
    <CurrentModalContext.Provider value={name}>
      {/* @ts-expect-error - wrapped type */}
      <Component open={open} {...modalProps} close={close} />
    </CurrentModalContext.Provider>
  )
}
