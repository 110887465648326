import { ChartThemeProvider } from '@motion/web-charts/theme'
import { useHasTreatment } from '@motion/web-common/flags'

import { GridLayout } from './components/grid-layout'
import { DashboardGridLayout } from './grid-layout'
import { CompletedTimeByAssigneeChart } from './task-charts/completed-time-by-assignee-chart'
import { ConnectedScheduledStatusPieChart } from './task-charts/scheduled-status-pie-chart'
import { TaskCount } from './task-charts/task-count'
import { TasksByAssigneeAndStatusChart } from './task-charts/tasks-by-assignee-status'

export const DashboardPage = () => {
  const enableNewDashboardLayout = useHasTreatment('dashboard-grid-layout')

  return (
    <ChartThemeProvider>
      <div className='border-t border-semantic-neutral-border-subtle m-4 overflow-y-scroll overflow-x-hidden'>
        {enableNewDashboardLayout ? (
          <DashboardGridLayout />
        ) : (
          <GridLayout>
            <TasksByAssigneeAndStatusChart width={6} height={4} />
            <CompletedTimeByAssigneeChart width={6} height={4} />

            <TaskCount />

            <ConnectedScheduledStatusPieChart />
          </GridLayout>
        )}
      </div>
    </ChartThemeProvider>
  )
}
