import { useDependantState } from '@motion/react-core/hooks'

import { type ReactNode, useMemo } from 'react'

import {
  BulkOpsFieldsContext,
  type BulkOpsFieldsValue,
  defaultValue,
} from './bulk-ops-fields-context'

import { useBulkOpsState } from '../bulk-ops'

type BulkOpsFieldsProviderProps = {
  children: ReactNode
}

export function BulkOpsFieldsProvider({
  children,
}: BulkOpsFieldsProviderProps) {
  const { selectedIds } = useBulkOpsState()
  const [fields, setFields] = useDependantState(
    () => defaultValue.fields,
    // We want to reset the fields when the selection change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds]
  )

  const value = useMemo<BulkOpsFieldsValue>(() => {
    return {
      fields,
      setFieldValue: (field, value) => {
        setFields((prev) => ({ ...prev, [field]: value }))
      },
      resetAllFields: () => setFields(defaultValue.fields),
    }
  }, [fields, setFields])

  return (
    <BulkOpsFieldsContext.Provider value={value}>
      {children}
    </BulkOpsFieldsContext.Provider>
  )
}
