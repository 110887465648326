import { Shortcut } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { useTaskByIdV2 } from '~/global/rpc/v2'

export interface TaskPrevNextTooltipContent {
  id: string
  shortcut: string
  isNext?: boolean
}

export function TaskPrevNextTooltipContent({
  id,
  shortcut,
  isNext = false,
}: TaskPrevNextTooltipContent) {
  const { data: task } = useTaskByIdV2({ id })

  if (task == null) return null

  return (
    <div className='flex flex-col gap-1 items-center'>
      <p>
        {templateStr('{{previousNext}} task: {{name}}', {
          previousNext: isNext ? 'Next' : 'Previous',
          name: task.name,
        })}
      </p>
      <Shortcut shortcut={shortcut} size='small' />
    </div>
  )
}
