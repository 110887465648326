import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useAllWorkspaces } from '~/global/hooks'
import { type ReactNode } from 'react'

export type WorkspaceDropdownProps = Omit<
  WorkspaceDropdownContentProps,
  'close'
> & {
  withAllWorkspacesOption?: boolean
  children?: ReactNode
}

export const WorkspaceDropdown = (props: WorkspaceDropdownProps) => {
  const { children, ...rest } = props

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <WorkspaceDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export const ALL_WORKSPACES_ID = 'all'

type WorkspaceDropdownContentProps = {
  close: () => void
  onChange: (workspace: WorkspaceSchema) => void
  selectedWorkspaceId: WorkspaceSchema['id'] | null
  withAllWorkspacesOption?: boolean
}
export const WorkspaceDropdownContent = ({
  close,
  onChange,
  selectedWorkspaceId,
  withAllWorkspacesOption = false,
}: WorkspaceDropdownContentProps) => {
  const workspaces = useAllWorkspaces({ scope: 'root' })

  const items = withAllWorkspacesOption
    ? [
        { id: ALL_WORKSPACES_ID, name: 'All workspaces' } as WorkspaceSchema,
        ...workspaces,
      ]
    : workspaces

  return (
    <SearchableList
      itemType='select'
      items={items}
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={(item) => item.id === selectedWorkspaceId}
      onSelect={(item) => {
        onChange(item)
        close()
      }}
      renderItem={(item) => (
        <span className='max-w-xs truncate'>{item.name}</span>
      )}
      inputProps={{ placeholder: 'Choose workspace...' }}
    />
  )
}
