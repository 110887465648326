import { useSharedStateContext } from '@motion/react-core/shared-state'

import { useEffect } from 'react'

import { TeamSchedulePageContextKey } from './page-data-context'
import { TeamScheduleActiveViewOptionsKey } from './view-state'

import { useEntityFilterState } from '../../../../filters'
import { isDefaultViewId } from '../../../../utils'
import { getSortedUsers } from '../hooks/use-sorted-users'

/**
 * Ensures that the `viewOptions.rowOrder` stays in sync with the filters
 */
export const ValidateViewOptions = () => {
  const [filter] = useEntityFilterState('tasks')

  const api = useSharedStateContext()

  useEffect(() => {
    const viewState = api.get(TeamScheduleActiveViewOptionsKey)
    const orderedUsers = viewState.rowOrder

    // Keeps the state 'clean' when on the default view without reordering
    if (
      isDefaultViewId(viewState.viewId) &&
      !viewState.rowOrder?.length &&
      !filter.filters.assigneeUserIds?.value.length
    ) {
      return
    }

    const ordered = getSortedUsers({
      users: api.get(TeamSchedulePageContextKey).users,
      selectedUsers: filter.filters.assigneeUserIds?.value ?? null,
      order: orderedUsers,
    })

    api.set(TeamScheduleActiveViewOptionsKey, {
      ...viewState,
      rowOrder: ordered.map((x) => x.id),
    })
  }, [api, filter.filters.assigneeUserIds])

  return null
}
