import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type CreateCalendarEventSchema } from '@motion/zod/client'

import { useCreateCalendarEvent as useCreateCalendarEventCall } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useCreateEvent = () => {
  const { mutateAsync: createCalendarEvent } = useCreateCalendarEventCall()

  return useCallback(
    async (data: CreateCalendarEventSchema & { calendarId: string }) => {
      try {
        recordAnalyticsEvent('CALENDAR_PEEK_MODAL_CREATE_EVENT', {
          isRecurring: Boolean(data.recurrence),
        })

        const response = await createCalendarEvent(data)

        showToast('success', 'Event created')

        return response
      } catch (e) {
        Sentry.captureException(e)
        showErrorToast(e)
      }
    },
    [createCalendarEvent]
  )
}
