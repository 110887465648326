import { Toggle } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useEffect } from 'react'

import { type DesktopShortcutKeys, desktopShortcutKeys } from './shortcut-keys'

import { persistSettings } from '../../../../localServices/firebase/web'
import { selectDesktopSettings } from '../../../../state/desktopSlice'
import { useAppSelector } from '../../../../state/hooks'
import { Paragraph, PrimaryButton } from '../../../Common'
import { DesktopShortcutRow } from '../../Components/DesktopShortcutRow/DesktopShortcutRow'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

interface DesktopSettingsProps {
  isElectron: boolean
}

export const DesktopSettings = ({
  isElectron = false,
}: DesktopSettingsProps) => {
  const desktopSettings = useAppSelector(selectDesktopSettings)

  useEffect(function onDesktopSettingsLoad() {
    recordAnalyticsEvent('DESKTOP_SETTINGS_VISIT')
  }, [])

  useEffect(
    function setInitialDesktopShortcuts() {
      if (!desktopSettings?.shortcuts) {
        void persistSettings({
          desktop: {
            shortcuts: {
              addTask: 'Alt+Space',
              openCalendar: 'Alt+C',
              openProjectManager: 'Alt+P',
              openScheduler: 'Alt+A',
            },
          },
        })
      }
    },
    [desktopSettings?.shortcuts]
  )

  return (
    <SettingPage title='Desktop app' className='gap-4'>
      {!isElectron && (
        <div className='flex flex-col gap-2'>
          <Paragraph className='text-base font-semibold'>
            Don&apos;t have the desktop app yet?
          </Paragraph>
          <PrimaryButton
            className='min-w-[max-content] max-w-max'
            onClick={() => {
              recordAnalyticsEvent('DESKTOP_SETTINGS_DOWNLOAD')
              window.open('https://www.usemotion.com/desktop-app', '_blank')
            }}
          >
            Download desktop app
          </PrimaryButton>
        </div>
      )}

      <Toggle
        onChange={(e) => {
          const checked = e.target.checked
          recordAnalyticsEvent('DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_ALWAYS', {
            checked,
          })
          void persistSettings({
            desktop: {
              ...desktopSettings,
              deepLinks: {
                ...desktopSettings?.deepLinks,
                alwaysOpenInApp: checked,
              },
            },
          })
        }}
        checked={!!desktopSettings?.deepLinks?.alwaysOpenInApp}
      >
        Open links in desktop app when possible
      </Toggle>

      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-4'>
          <Paragraph className='text-base font-semibold'>
            Keyboard shortcuts
          </Paragraph>
          {desktopShortcutKeys.map((shortcutKey: DesktopShortcutKeys) => (
            <DesktopShortcutRow
              key={shortcutKey}
              disabled={!isElectron}
              shortcutKey={shortcutKey}
              currentShortcut={desktopSettings?.shortcuts?.[shortcutKey]}
              onShortcutChange={(shortcut) => {
                recordAnalyticsEvent('DESKTOP_SETTINGS_SHORTCUT_CHANGE', {
                  shortcut,
                  shortcutKey,
                })
                void persistSettings({
                  desktop: {
                    ...desktopSettings,
                    shortcuts: {
                      ...desktopSettings?.shortcuts,
                      [shortcutKey]: shortcut,
                    },
                  },
                })
              }}
            />
          ))}
        </div>
      </div>
    </SettingPage>
  )
}
