import {
  MagicWandGradientSolid,
  MagicWandSolid,
  PlusSolid,
  XSolid,
} from '@motion/icons'
import {
  Button,
  GradientButton,
  IconButton,
  UnstyledModal,
} from '@motion/ui/base'
import { Sentry } from '@motion/web-base/sentry'
import { HasExperiment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { SharedFormShell, ShellVars } from '~/areas/task-project/components'
import { useAllProjectDefinitions, useProjectDefinitions } from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'

import { FlowTemplateCardLink } from './components'
import {
  useCloseFlowTemplateModal,
  useFlowTemplateModalUrl,
  useFlowTemplateModalUrlParams,
} from './hooks'
import { FLOW_TEMPLATE_PRESETS } from './presets'

type CreateFlowTemplateModalProps = {
  open: boolean
}

export function ConnectedCreateFlowTemplateModal({
  open,
}: CreateFlowTemplateModalProps) {
  const closeModal = useCloseFlowTemplateModal()

  return (
    <UnstyledModal
      data-testid='create-flow-template-modal'
      type='page'
      visible={open}
      onClose={closeModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <CreateFlowTemplateModalContent onClose={closeModal} />
    </UnstyledModal>
  )
}

function CreateFlowTemplateModalContent({ onClose }: { onClose: () => void }) {
  const { workspaceId } = useFlowTemplateModalUrlParams()
  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()

  const workspaceProjectDefinitions = useProjectDefinitions(workspaceId)
  const allProjectDefinitions = useAllProjectDefinitions()

  const projectDefinitions = workspaceId
    ? workspaceProjectDefinitions
    : allProjectDefinitions

  return (
    <ShellVars>
      <SharedFormShell className='p-6'>
        <div className='gap-6 flex flex-col h-full'>
          <div className='flex justify-between items-center'>
            <h1 className='font-bold text-lg text-semantic-neutral-text-default'>
              Create project workflow template
            </h1>
            <div className='flex gap-2'>
              <Button
                sentiment='neutral'
                variant='outlined'
                url={buildFlowTemplateModalUrl({ template: 'new' })}
              >
                <PlusSolid />
                Create from scratch
              </Button>

              <IconButton
                icon={XSolid}
                sentiment='neutral'
                variant='muted'
                onClick={onClose}
              />
            </div>
          </div>

          <div className='gap-6 flex flex-col overflow-auto'>
            <HasExperiment name='flows-ai'>
              <FlowsAICard />
            </HasExperiment>

            <div className='gap-3 flex flex-col'>
              <h2 className='font-bold text-sm text-semantic-neutral-text-default'>
                Create from existing template
              </h2>
              <div className='flex gap-3 flex-wrap'>
                {projectDefinitions.map((definition) => {
                  return (
                    <FlowTemplateCardLink
                      key={definition.id}
                      name={definition.name}
                      stages={definition.stages}
                      url={buildFlowTemplateModalUrl({
                        template: 'new',
                        templateId: definition.id,
                      })}
                    />
                  )
                })}
              </div>
            </div>

            <div className='gap-3 flex flex-col'>
              <h2 className='font-bold text-sm text-semantic-neutral-text-default'>
                Create from Motion predefined templates
              </h2>
              <div className='flex gap-3 flex-wrap'>
                {FLOW_TEMPLATE_PRESETS.map((p) => {
                  return (
                    <FlowTemplateCardLink
                      key={p.id}
                      name={p.name}
                      stages={p.template().stages}
                      url={buildFlowTemplateModalUrl({
                        template: 'new',
                        fromPresetId: p.id,
                      })}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </SharedFormShell>
    </ShellVars>
  )
}

function FlowsAICard() {
  const { workspaceId } = useFlowTemplateModalUrlParams()
  const modalApi = useModalApi()
  const closeModal = useCloseFlowTemplateModal()

  const openModal = () => {
    if (!workspaceId) {
      Sentry.captureException("No workspace ID found. Can't open AI modal.", {
        tags: {
          position: 'CreateFlowTemplateModalContent',
        },
      })

      showErrorToast("No workspace ID found. Can't open AI modal.")
      return
    }

    closeModal()

    // NOTE: Can't open this modal immediately after closing, looks like it will cause the following modal to not be registered
    setTimeout(() => {
      modalApi.open('flows-ai-modal', {
        workspaceId,
      })
    }, 0)
  }

  return (
    <div className='py-5 px-4 flex flex-col items-center gap-3 border border-semantic-purple-border-default rounded-lg shadow-[0_0_30px_-20px_rgba(0,0,0,0.3)] shadow-semantic-pink-border-active'>
      <div className='flex items-center gap-2'>
        <MagicWandGradientSolid className='size-4' />

        <h4 className='font-bold bg-purple-gradient text-gradient'>
          Create project workflow with AI{' '}
          <span className='font-normal'>(beta)</span>
        </h4>
      </div>

      <p className='text-sm text-semantic-neutral-text-default mb-1'>
        Paste or upload your SOP or project guidelines. Motion will
        automatically create the stages and tasks required for the project.
      </p>

      <GradientButton sentiment='purple' onClick={openModal}>
        <MagicWandSolid />
        Create with AI
      </GradientButton>
    </div>
  )
}
