import { type ProjectSchema } from '@motion/rpc-types'
import { groupBy } from '@motion/utils/array'

import { useMemo } from 'react'

/*
 * Given a project, check if its at-risk, if so find the first stage that matches the criteria
 */
export const useProjectStageToShow = (project: ProjectSchema | null) => {
  return useMemo(() => {
    if (project && project.deadlineStatus === 'at-risk') {
      const entityStageMap = groupBy(
        project.stages,
        (stage) => stage.deadlineStatus
      )
      const missedDeadlineStages = entityStageMap['missed-deadline']
      if (missedDeadlineStages && missedDeadlineStages.length > 0) {
        return missedDeadlineStages[0]
      }

      const scheduledPastDeadlineStages =
        entityStageMap['scheduled-past-deadline']
      if (
        scheduledPastDeadlineStages &&
        scheduledPastDeadlineStages.length > 0
      ) {
        return scheduledPastDeadlineStages[0]
      }
    }

    return undefined
  }, [project])
}
