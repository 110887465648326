import { XSolid } from '@motion/icons'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/rpc-types'
import {
  Button,
  IconButton,
  LoadingSpinner,
  showToast,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import { isMeetingTask, isTaskSchema } from '@motion/ui-logic/pm/task'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalTitle } from '@motion/web-common/html'

import { useFileUploadState } from '~/areas/attachments/contexts'
import {
  ModalErrorState,
  PreviousNextModalButtons,
  type PreviousNextModalButtonsProps,
  ProjectPanel,
  SharedFormShell,
  ShellActions,
  ShellVars,
  SidebarContent,
  SidebarSection,
  SidebarShell,
  SidebarWorkspaceSection,
  SidePanel,
  TaskAttachmentsCollapsable,
  TaskAttachmentsUploader,
} from '~/areas/task-project/components'
import { AutoSaveProvider } from '~/areas/task-project/contexts'
import { TutorialButtonLink } from '~/global/components'
import { useModalListOrder } from '~/global/contexts'
import { useTrackEntityOpen } from '~/global/hooks/use-track-entity-open'
import { useSubcribeToFeed } from '~/global/hooks/websockets'
import {
  useNavigateByGlobalModalId,
  useRouteConfirmationPromptBeforeLeaving,
  useTaskModalUrl,
  useUnsavedChangesPrompt,
  useUriByGlobalModalId,
} from '~/global/navigation'
import { type FormEvent, memo, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ArchiveBanner,
  GridBanner,
  GridShell,
  MainHeader,
  MainScrollableContent,
  RecurringTaskBannerText,
  ShellMainFooter,
  SidebarFooter,
  SidebarRecurringTaskBanner,
  TaskActivityCollapsable,
  TaskDebugInfo,
  TaskEtaRibbon,
} from './components'
import { TaskModalStateProvider, useTaskModalState } from './contexts'
import {
  AutoScheduleToggle,
  ControlledAssigneeField,
  ControlledBlockersField,
  ControlledDescriptionField,
  ControlledNameField,
  ControlledPriorityField,
  ControlledStageField,
  ControlledStatusField,
  ControlledWorkspaceFolderProjectField,
  ControlledWorkspaceProjectField,
  CustomFieldsTaskSidebarSection,
  DeadlineField,
  DeadlineToggle,
  DurationField,
  MinChunkField,
  RecurringField,
  ScheduleField,
  StartDateField,
  TaskHeader,
  TaskLabelsField,
  WarnOnField,
} from './fields'
import {
  useCloseTaskModal,
  useInitialTaskData,
  useSubmitForm,
  useTaskForm,
} from './hooks'
import { TaskForm } from './task-form'

import { TaskPrevNextTooltipContent } from '../../components/task-prev-next-tooltip-content'

type ConnectedTaskModalProps = {
  open: boolean
}

export function ConnectedTaskModal({ open }: ConnectedTaskModalProps) {
  const closeModal = useCloseTaskModal()

  const { cancelActiveFileUploads } = useFileUploadState()

  const handleCloseModal = () => {
    cancelActiveFileUploads({ targetId: null, targetType: 'TEAM_TASK' })
    closeModal()
  }

  return (
    <UnstyledModal
      data-testid='task-modal'
      type='page'
      visible={open}
      onClose={handleCloseModal}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <TaskModalBody close={handleCloseModal} />
    </UnstyledModal>
  )
}

export type TaskModalBodyProps = {
  close: () => void
  onSave?: (task: TaskSchema | RecurringTaskSchema) => void
  fromDesktopOptionSpace?: boolean
}
export function TaskModalBody({
  close,
  onSave,
  fromDesktopOptionSpace = false,
}: TaskModalBodyProps) {
  const initialTaskData = useInitialTaskData()

  const navigateToModal = useNavigateByGlobalModalId()

  const isMeeting = isMeetingTask(initialTaskData.task)

  useEffect(() => {
    if (!isMeeting || initialTaskData.task?.id == null) {
      return
    }

    navigateToModal('meeting', { mTask: initialTaskData.task.id })
  }, [isMeeting, initialTaskData.task?.id, navigateToModal])

  if (!initialTaskData.isLoading && isMeeting) {
    return null
  }

  return (
    <AutoSaveProvider suppressSaveToast>
      <TaskForm initialTaskData={initialTaskData}>
        <TaskModalStateProvider initialTaskData={initialTaskData}>
          <TaskModalContentWrapper
            close={close}
            onSave={onSave}
            fromDesktopOptionSpace={fromDesktopOptionSpace}
          />
        </TaskModalStateProvider>
      </TaskForm>
    </AutoSaveProvider>
  )
}

const TaskModalContentWrapper = memo(function TaskModalContentWrapper({
  close,
  onSave,
  fromDesktopOptionSpace = false,
}: TaskModalBodyProps) {
  if (fromDesktopOptionSpace) {
    return <OptionSpaceTaskModalContent close={close} onSave={onSave} />
  }

  return <InAppTaskModalContent close={close} onSave={onSave} />
})

const OptionSpaceTaskModalContent = ({
  close: parentClose,
  onSave,
}: TaskModalContentProps) => {
  const { form } = useTaskForm()
  const promptUnsavedChanges = useUnsavedChangesPrompt()
  const {
    formState: { isDirty, isSubmitting },
  } = form

  const hasDirtyFormFields = isDirty && !isSubmitting

  const close = async () => {
    const proceed = hasDirtyFormFields ? await promptUnsavedChanges() : true

    if (proceed) {
      parentClose()
    }
  }

  useShortcut('escape', () => close())

  return (
    <div className='contents [&_form]:max-w-none'>
      <TaskModalContent close={close} onSave={onSave} />
    </div>
  )
}

const InAppTaskModalContent = ({ close, onSave }: TaskModalContentProps) => {
  const { form } = useTaskForm()
  const { hasPendingComment } = useTaskModalState()
  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const taskId = watch('id')
  const taskName = watch('name')

  useOnMountAnalyticsEvent('TASK_OPEN')
  useSubcribeToFeed('task', taskId)
  useModalTitle(taskId ? taskName : 'New task')

  const hasDirtyFormFields = isDirty && !isSubmitting
  const isNotAutoSaved = taskId == null

  useRouteConfirmationPromptBeforeLeaving({
    when: (hasDirtyFormFields && isNotAutoSaved) || hasPendingComment,
    message:
      hasPendingComment && !hasDirtyFormFields
        ? 'Your comment has not been saved'
        : undefined,
  })

  return <TaskModalContent close={close} onSave={onSave} />
}

type TaskModalContentProps = Omit<TaskModalBodyProps, 'fromDesktopOptionSpace'>
const TaskModalContent = ({ close, onSave }: TaskModalContentProps) => {
  const { form, hiddenFields } = useTaskForm()
  const { sidePanelOpen, initialTaskData } = useTaskModalState()
  const { task: initialTask, parentTask, isLoading, hasError } = initialTaskData

  const submitForm = useSubmitForm({ onSave })

  const hasWorkspaceFolders = useHasTreatment('workspace-folders')

  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const taskId = watch('id')
  const taskName = watch('name')
  const taskType = watch('type')
  const isArchived = isTaskSchema(initialTask)
    ? initialTask.archivedTime != null
    : undefined
  const workspaceId = watch('workspaceId')
  const projectId = watch('projectId')

  const isRecurringTaskInstance = taskType === 'RECURRING_INSTANCE'
  const isRecurringTask = taskType === 'RECURRING_TASK'

  const { activeFileUploads } = useFileUploadState()

  const hasActiveFileUploads = activeFileUploads.some(
    (upload) => upload.targetId == null && upload.targetType === 'TEAM_TASK'
  )

  const buildRecurringParentUrl = useTaskModalUrl()

  const hasFormFooterButtons = taskId == null

  useTrackEntityOpen({ id: taskId, type: 'TASK' })

  useOnMountAnalyticsEvent('PROJECT_MANAGEMENT_VIEW_TASK', {
    enabled: taskId != null,
  })

  useShortcut('mod+s', () => onSubmit(), {
    enabled: hasFormFooterButtons,
  })

  const {
    tasks: { getPrevId: getPrevTaskId, getNextId: getNextTaskId },
  } = useModalListOrder()

  const buildModalUrl = useUriByGlobalModalId()

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (!isDirty) return

    await form.handleSubmit(submitForm, (validationErrors) => {
      const errors = Object.values(validationErrors)
      if (errors.length < 1) return

      const message = errors[0].message
      if (typeof message !== 'string' || !message) return

      showToast('error', message)
    })(e)
  }

  const renderPrevNextButtonTooltip: PreviousNextModalButtonsProps['renderTooltip'] =
    (id, shortcut, isNext) => (
      <TaskPrevNextTooltipContent id={id} shortcut={shortcut} isNext={isNext} />
    )

  return (
    <ShellVars columns={2}>
      <SharedFormShell
        columns={2}
        onSubmit={onSubmit}
        className={twMerge(
          'transition-transform',
          sidePanelOpen && 'setvar-[modal-offset=292px] -translate-x-[150px]'
        )}
      >
        <ShellActions
          className={twMerge(
            'transition-transform',
            sidePanelOpen && 'translate-x-[292px]' // 300px sidePanel - 8px to cover the modal a tiny bit
          )}
        >
          <IconButton
            icon={XSolid}
            sentiment='onDark'
            size='small'
            variant='muted'
            onClick={close}
          />
          {!isLoading && !hasError && !sidePanelOpen && taskId && (
            <PreviousNextModalButtons
              previousId={getPrevTaskId()}
              nextId={getNextTaskId()}
              buildUrl={(id) => buildModalUrl('task', { task: id })}
              renderTooltip={renderPrevNextButtonTooltip}
            />
          )}
        </ShellActions>
        <GridShell
          layout={isArchived ? 'archived' : 'normal'}
          withFooter={hasFormFooterButtons}
        >
          {isArchived && (
            <GridBanner>
              <ArchiveBanner />
            </GridBanner>
          )}
          {isLoading ? (
            <div className='col-span-2 row-span-4 w-full h-full grid place-items-center'>
              <LoadingSpinner />
            </div>
          ) : hasError ? (
            <ModalErrorState close={close} className='col-span-2 row-span-4'>
              This task doesn&apos;t exist
            </ModalErrorState>
          ) : (
            <>
              <MainHeader>
                <TaskHeader />
                <ControlledNameField />
              </MainHeader>

              <SidebarShell>
                {initialTask != null &&
                  initialTask.type !== 'RECURRING_TASK' && (
                    <TaskEtaRibbon task={initialTask} />
                  )}
                <SidebarContent>
                  {isRecurringTaskInstance && (
                    <SidebarRecurringTaskBanner>
                      <RecurringTaskBannerText>
                        This is an instance of a recurring task
                      </RecurringTaskBannerText>
                      <Button
                        size='xsmall'
                        variant='outlined'
                        sentiment='neutral'
                        url={buildRecurringParentUrl({
                          task: parentTask?.id,
                        })}
                      >
                        Edit
                      </Button>
                    </SidebarRecurringTaskBanner>
                  )}

                  <SidebarWorkspaceSection>
                    {hasWorkspaceFolders ? (
                      <ControlledWorkspaceFolderProjectField />
                    ) : (
                      <ControlledWorkspaceProjectField />
                    )}
                  </SidebarWorkspaceSection>

                  <AutoScheduleToggle />

                  <SidebarSection className='pt-3 modal-lg:pt-5'>
                    {hasWorkspaceFolders && <ControlledStageField />}
                    <ControlledAssigneeField />
                    <ControlledStatusField />
                    <ControlledPriorityField />
                  </SidebarSection>

                  <SidebarSection>
                    {!hiddenFields.has('recurrenceMeta') && <RecurringField />}
                    <DurationField />
                    {!hiddenFields.has('minimumDuration') && <MinChunkField />}
                    {!hiddenFields.has('startDate') && <StartDateField />}

                    {/* For recurring parents only, warn field is top-level. */}
                    {/* For others, it's nested in DeadlineField. */}
                    {isRecurringTask && <WarnOnField />}

                    {!hiddenFields.has('dueDate') && <DeadlineField />}
                    {!hiddenFields.has('deadlineType') && <DeadlineToggle />}
                    {!hiddenFields.has('scheduleId') && <ScheduleField />}
                  </SidebarSection>

                  <SidebarSection>
                    <TaskLabelsField />

                    {taskType === 'NORMAL' && (
                      <CustomFieldsTaskSidebarSection
                        workspaceId={workspaceId}
                        taskId={taskId}
                        disabled={isArchived}
                      />
                    )}
                  </SidebarSection>

                  <SidebarSection>
                    {!hiddenFields.has('blockedByTaskIds') &&
                      !hiddenFields.has('blockingTaskIds') && (
                        <>
                          <ControlledBlockersField type='blockedBy' />
                          <ControlledBlockersField type='blocks' />
                        </>
                      )}
                  </SidebarSection>

                  {!__IS_PROD__ && <TaskDebugInfo />}
                </SidebarContent>
              </SidebarShell>

              <MainScrollableContent>
                <ControlledDescriptionField />
                {taskId ? (
                  <TaskAttachmentsCollapsable taskId={taskId} />
                ) : (
                  <TaskAttachmentsUploader />
                )}
                {taskId && taskType !== 'RECURRING_TASK' && (
                  <TaskActivityCollapsable taskId={taskId} />
                )}
              </MainScrollableContent>

              <ShellMainFooter>
                <TutorialButtonLink
                  lesson='managing-tasks'
                  rounded
                  onClick={() => {
                    recordAnalyticsEvent('PROJECT_MANAGEMENT_TUTORIAL_CLICK', {
                      target: 'v3-tutorial',
                      source: 'task-modal',
                    })
                  }}
                />
              </ShellMainFooter>

              {hasFormFooterButtons && (
                <SidebarFooter>
                  <Button
                    sentiment='neutral'
                    variant='muted'
                    shortcut='esc'
                    onClick={close}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    sentiment='primary'
                    variant='solid'
                    shortcut='mod+s'
                    disabled={
                      !isDirty ||
                      taskName.trim().length < 1 ||
                      hasActiveFileUploads
                    }
                    loading={isSubmitting}
                  >
                    Save task
                  </Button>
                </SidebarFooter>
              )}
            </>
          )}
        </GridShell>
      </SharedFormShell>
      <SidePanel open={sidePanelOpen}>
        {projectId != null ? (
          <ProjectPanel
            projectId={projectId}
            workspaceId={workspaceId}
            currentTaskId={taskId}
            enableInlineAdd={taskId != null}
          />
        ) : (
          <div className='flex items-center justify-center h-full'>
            <LoadingSpinner />
          </div>
        )}
      </SidePanel>
    </ShellVars>
  )
}
