import { BillingSection, BillingSectionTitle } from './billing-section'

export const NonAdminSection = () => {
  return (
    <BillingSection className='flex flex-col gap-2 p-6'>
      <BillingSectionTitle>Plan</BillingSectionTitle>
      <p className='text-semantic-neutral-text-default'>
        Your subscription is managed by your team admin.
      </p>
    </BillingSection>
  )
}
