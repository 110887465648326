import { DateTime } from 'luxon'
import { useCallback, useEffect } from 'react'

import { useAgendaCalendarState } from './use-agenda-calendar-state'
import { useAgendaState } from './use-agenda-state'

const DAY_CHANGE_CHECK_INTERVAL = 60 * 1000 // 1 minute

export function useUpdateAgendaOnDayChange() {
  const [agendaState, setAgendaState] = useAgendaState()
  const [agendaCalState, setAgendaCalState] = useAgendaCalendarState()

  const ensureSelectedDateIsToday = useCallback(() => {
    const today = DateTime.now()
    const agendaDayIsToday = agendaState.selectedDate.hasSame(today, 'day')
    if (!agendaDayIsToday && !agendaState.selectedDateManuallyChanged) {
      setAgendaState((prev) => ({
        ...prev,
        selectedDate: today,
      }))
    }

    const calendarDayIsToday = agendaCalState.selectedDate.hasSame(today, 'day')
    if (!calendarDayIsToday && !agendaCalState.selectedDateManuallyChanged) {
      setAgendaCalState((prev) => ({
        ...prev,
        selectedDate: today,
      }))
    }
  }, [
    agendaState.selectedDate,
    agendaState.selectedDateManuallyChanged,
    agendaCalState.selectedDate,
    agendaCalState.selectedDateManuallyChanged,
    setAgendaState,
    setAgendaCalState,
  ])

  // Ensure selected date is today on mount
  useEffect(() => {
    ensureSelectedDateIsToday()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const intervalId = setInterval(
      ensureSelectedDateIsToday,
      DAY_CHANGE_CHECK_INTERVAL
    )

    return () => clearInterval(intervalId)
  }, [ensureSelectedDateIsToday])
}
