import { cubicBezier } from 'framer-motion/dom'

const easeIn = cubicBezier(0.42, 0, 1, 1)
const MAX_SPEED = 4
const THRESHOLD_PERCENTAGE = 0.15

const POINTER_MIN_DISTANCE = 8
export const calculateMaxSpeed = (
  container: DOMRect | null,
  cursorX: number,
  lastCursorPosition: number,
  isAnimating: boolean,
  sidebarWidth: number
) => {
  if (!container) return

  const delta = cursorX - lastCursorPosition
  const threshold = container.width * THRESHOLD_PERCENTAGE

  const distanceFromLeftEdge = Math.max(
    cursorX - container.left - sidebarWidth,
    0
  )
  const distanceFromRightEdge = Math.max(container.right - cursorX, 0)

  if (distanceFromLeftEdge <= threshold) {
    if (!isAnimating && delta >= -POINTER_MIN_DISTANCE) return 0
    const percentage = (threshold - distanceFromLeftEdge) / threshold
    return easeIn(percentage) * MAX_SPEED
  } else if (distanceFromRightEdge <= threshold) {
    if (!isAnimating && delta <= POINTER_MIN_DISTANCE) return 0
    const percentage = (threshold - distanceFromRightEdge) / threshold
    return easeIn(percentage) * -MAX_SPEED
  }
  return 0
}
