import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type FolderSchema, type WorkspaceSchema } from '@motion/zod/client'

import {
  BaseEditFolderModal,
  type SaveHandlerData,
} from '~/areas/modals/folders/components/base-edit-folder-modal'
import { useCreateFolder } from '~/global/rpc/folders'
import { showErrorToast } from '~/global/toasts'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'create-new-folder': {
      workspaceId?: WorkspaceSchema['id']
      folderId?: FolderSchema['id']
    }
  }
}

export const NewFolderModal = ({
  workspaceId,
  folderId,
  close,
}: ModalTriggerComponentProps<'create-new-folder'>) => {
  const { mutateAsync: createFolder } = useCreateFolder()

  const handleOnSave = async ({
    parentFolderId,
    folderName,
    selectedColor,
  }: SaveHandlerData) => {
    recordAnalyticsEvent('FOLDERS_CREATED_FOLDER', {
      color: selectedColor, // Determine if users are setting colors or not
    })

    try {
      await createFolder({
        name: folderName,
        color: selectedColor,
        parentFolderId,
      })

      showToast('success', 'Folder created')

      close()
    } catch (e) {
      Sentry.captureException(
        new Error('Could not create workspace folder', {
          cause: e,
        }),
        {
          extra: {
            workspaceId,
          },
          tags: {
            position: 'NewFolderModal',
          },
        }
      )

      showErrorToast(e, 'Could not create folder')
    }
  }

  return (
    <BaseEditFolderModal
      close={close}
      initialSelectedParentFolderId={folderId}
      initialSelectedWorkspaceId={workspaceId}
      isNewFolder
      onSave={handleOnSave}
    />
  )
}
