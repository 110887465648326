import { type UniqueIdentifier } from '@dnd-kit/core'

import { type GroupedNode, type Tree } from '../../grouping'
import { TabRow } from '../tab'

export const BoardHeaders = <T extends GroupedNode>({
  tree,
  activeTabs,
  selectTab,
  activeId,
}: {
  tree: Tree<T>
  activeTabs: Tree<T>[]
  selectTab: (index: number, value: Tree<T>) => void
  activeId: UniqueIdentifier | null
}) => {
  return (
    <>
      {activeTabs.length > 0 && (
        <TabRow
          display='tab'
          tree={tree}
          selected={activeTabs[0]}
          onSelect={(value) => selectTab(0, value)}
          size='normal'
          activeId={activeId?.toString()}
        />
      )}
      {activeTabs.length > 1 && (
        <TabRow
          display='pill'
          tree={activeTabs[0]}
          selected={activeTabs[1]}
          onSelect={(value) => selectTab(1, value)}
          size='small'
          activeId={activeId?.toString()}
        />
      )}
    </>
  )
}
