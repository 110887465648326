import { ActionList, type ActionListProps } from '@motion/ui/base'
import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import { sleep } from '@motion/utils/promise'
import { useModalApi } from '@motion/web-common/modals'
import { type WorkspaceSchema } from '@motion/zod/client'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { type FC } from 'react'

import { useGetAvailableCustomFieldCategories } from '../hooks/custom-fields/use-get-available-custom-field-categories'

export const AddCustomFieldCategoriesPopover: FC<{
  workspace: Pick<WorkspaceSchema, 'name' | 'id'>
  close: () => void
  onValue?: (value: AllAvailableCustomFieldSchema | undefined) => void
}> = ({ close, onValue, workspace }) => {
  const modalApi = useModalApi()
  const categories = useGetAvailableCustomFieldCategories()

  const customFieldCategoryItems: ActionListProps['items'] =
    categories.categoriesConfigList.map(({ id: type, name }) => {
      return {
        prefix: <CustomFieldBadge type={type} />,
        content: name,
        onAction: async () => {
          // Make sure the dropdown closes before opening the modal to get the focus within the modal
          await sleep(1)
          modalApi.prompt('add-custom-field', {
            workspace,
            defaultValues: {
              type,
            },
            onValue,
          })
        },
      }
    })

  return <ActionList onActionAnyItem={close} items={customFieldCategoryItems} />
}
