import { type DateAdjustmentStrategy } from '@motion/shared/projects'
import { ConfirmationModal } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { type ModalTriggerComponentProps } from '../../../modals/modal-trigger'
import { ModalRadioButton } from '../components/modal-radio-button'

export type RadioOption = {
  radioTitle: string
  radioSubtitle: string | ReactNode[]
  value: DateAdjustmentStrategy
  disabled?: boolean
  tooltipContent?: string
}

type UpdateProjectDateModalProps = {
  dateAdjustmentStrategy: DateAdjustmentStrategy
  radioOptions: RadioOption[]
  onClose: ModalTriggerComponentProps<'update-project-date'>['close']
  setDateAdjustmentStrategy: (
    dateAdjustmentStrategy: DateAdjustmentStrategy
  ) => void
  description: string | ReactNode[]
}
export function UpdateProjectDateModal({
  onClose,
  description,
  dateAdjustmentStrategy,
  setDateAdjustmentStrategy,
  radioOptions,
}: UpdateProjectDateModalProps) {
  return (
    <ConfirmationModal
      action={{
        label: 'Continue',
        onAction: () => {
          onClose({ dateAdjustmentStrategy })
        },
      }}
      closeLabel='Cancel'
      description={
        <div className='gap-3 flex flex-col'>
          <div className='text-semantic-neutral-text-default text-xs font-normal inline-flex flex-wrap gap-2 flex-row'>
            <span>{description}</span>
          </div>
          <div className='flex flex-col items-start gap-4 self-stretch'>
            {radioOptions.map((radioOption) => (
              <ModalRadioButton
                key={radioOption.value}
                radioTitle={radioOption.radioTitle}
                radioSubtitle={radioOption.radioSubtitle}
                checked={dateAdjustmentStrategy === radioOption.value}
                onChange={() => setDateAdjustmentStrategy(radioOption.value)}
                disabled={radioOption.disabled}
                tooltipContent={radioOption.tooltipContent}
              />
            ))}
          </div>
        </div>
      }
      onClose={onClose}
      title='How should Motion treat the stage deadlines?'
      visible
    />
  )
}
