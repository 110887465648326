import { classed } from '@motion/theme'

export const BarOverlay = classed('div', {
  base: `
    absolute top-0 left-0
    w-full h-full
    bg-semantic-neutral-text-default opacity-5 dark:opacity-10
    rounded-b-md
  `,
})
