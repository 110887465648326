import { type Calendar } from '@motion/rpc/types'

import { validate as isUuid } from 'uuid'

/**
 * @deprecated - This function is deprecated. Read `calendar.id` directly instead
 *
 * Calendars that are stored in cockroach should return their internal UUID.
 * Firebase calendars should return their external provider ID.
 * @param c
 * @returns
 */
export const resolveCalendarId = (c: Pick<Calendar, 'id' | 'providerId'>) => {
  return isUuid(c.id) ? c.id : c.providerId
}

export const isCockroachEmailAccountId = (id: string) => isUuid(id)

export const formatCalendarTitle = (title: string, email?: string) =>
  `${title}${title.includes('@') ? '' : ` (${email})`}`
