import {
  type FlowTemplateFormTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'

import { type UniqueIdentifier } from '@dnd-kit/core'
import { useMemo, useState } from 'react'

import { type StageTasksProps } from '../../stage-tasks'

export const useTemplateActiveTask = (
  stages: FlowTemplateStage[],
  allTasks: FlowTemplateFormTask[]
) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const activeTask = useMemo(
    () => allTasks.find((task) => task.id === activeId),
    [activeId, allTasks]
  )
  let activeStageIndex: number | null = null
  let stageTasksPath: StageTasksProps['tasksPath'] | null = null
  let activeTaskIndex: number | null = null

  if (activeId != null) {
    activeStageIndex = stages.findIndex((stage) =>
      stage.tasks.find((task) => task.id === activeId)
    )
    stageTasksPath = `stages.${activeStageIndex}.tasks` as const
    activeTaskIndex = stages[activeStageIndex]?.tasks.findIndex(
      (task) => task.id === activeId
    )
  }

  return {
    activeTask,
    setActiveId,
    stageTasksPath,
    activeTaskIndex,
  }
}
