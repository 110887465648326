import { classed } from '@motion/theme'
import { AutoScheduleToggle, Tooltip } from '@motion/ui/base'
import {
  getAutoscheduleInfo,
  scheduleTypeToInfo,
  templateStr,
} from '@motion/ui-logic'
import { isAutoScheduledToggleEnabled } from '@motion/ui-logic/pm/task'

// eslint-disable-next-line import-x/no-restricted-paths
import { AutoScheduleTooltip } from '~/areas/tasks/tooltips'
import { type TaskWithRelations } from '~/global/proxies'
import { twMerge } from 'tailwind-merge'

export type AutoScheduleLabelProps = {
  task: TaskWithRelations
  onChange: (checked: boolean) => void
  position?: 'toggle-right' | 'toggle-left'
  isUnvisitedStage?: boolean
  toggleOnly?: boolean
}

export const AutoScheduleLabel = ({
  task,
  onChange,
  position = 'toggle-left',
  isUnvisitedStage,
  toggleOnly = false,
}: AutoScheduleLabelProps) => {
  const { disabled, tooltipText } = isAutoScheduledToggleEnabled(task)
  const isAutoScheduled = task.isAutoScheduled
  const isCompleted = task.completedTime != null

  const { formattedScheduledDate, type, labelVariant } = getAutoscheduleInfo(
    task,
    isAutoScheduled,
    isCompleted
  )

  const info = scheduleTypeToInfo[type]

  return (
    <div
      className={twMerge(
        'shrink-0 flex flex-row items-center gap-1.5',
        position === 'toggle-right' && 'flex-row-reverse'
      )}
    >
      <Tooltip asChild content={tooltipText}>
        <AutoScheduleToggle
          checked={isAutoScheduled}
          labelHidden
          onChange={(e) => onChange(e.currentTarget.checked)}
          disabled={disabled}
          isUnvisitedStage={isUnvisitedStage}
        />
      </Tooltip>
      {!toggleOnly && (
        <Tooltip
          renderContent={() => <AutoScheduleTooltip info={info} task={task} />}
          asChild
        >
          <AutoScheduleDate className='truncate' variant={labelVariant}>
            {info.shortText
              ? templateStr(info.shortText, { date: formattedScheduledDate })
              : 'None'}
          </AutoScheduleDate>
        </Tooltip>
      )}
    </div>
  )
}

export const AutoScheduleDate = classed('span', {
  variants: {
    disabled: { true: 'opacity-50' },
    variant: {
      on: 'text-semantic-purple-bg-strong-default',
      off: 'text-semantic-neutral-text-disabled',
      error: 'text-semantic-error-bg-strong-default',
    },
  },
  defaultVariants: {
    variant: 'off',
  },
})
