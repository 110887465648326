import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { ProjectActivity } from '~/areas/activity-feed/components'
import { CollapsableContainer } from '~/areas/task-project/components'

import { useProjectModalState } from '../../contexts'

export const ProjectActivityCollapsable = ({
  projectId,
}: {
  projectId: ProjectSchema['id']
}) => {
  const { setHasPendingComment } = useProjectModalState()

  return (
    <CollapsableContainer
      title='Activity'
      onToggle={(expanded) => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_COLLAPSE_ACTIVITY', {
          collapsed: !expanded,
          type: 'project',
        })
      }}
    >
      <ProjectActivity
        projectId={projectId}
        onCommentChange={(value) => {
          setHasPendingComment(value.length > 0 && value !== '<p></p>')
        }}
      />
    </CollapsableContainer>
  )
}
