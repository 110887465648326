import { classed } from '@motion/theme'

import { useMemo } from 'react'

import { OOOEventColumn } from './ooo-event-column'

import { usePlannerProps, useScrollPosition } from '../../../context'
import { useScreenValues } from '../../../hooks'
import { LEFT_PROJECT_OFFSET } from '../../../shared-constants'
import { isoDateToPixel } from '../../../utils'
import { type OOOEvent } from '../../calendar-row'

export function OOOEventsRow({
  events,
  isFullScreen = false,
  eventHeight,
}: {
  events: OOOEvent[]
  isFullScreen?: boolean
  eventHeight?: number
}) {
  const [plannerProps] = usePlannerProps()
  const { positionOffset, screenSize } = useScreenValues()
  const [scrollPosition] = useScrollPosition()

  const visibleEvents = useMemo(() => {
    const visibleEvents = []
    for (const event of events) {
      const eventStart = isoDateToPixel(event.start, plannerProps.dayPx)
      const eventEnd = isoDateToPixel(event.end, plannerProps.dayPx)
      const width = eventEnd - eventStart

      const result = {
        ...event,
        eventStart,
        eventEnd,
        width,
      }
      const leftEdge = -scrollPosition - positionOffset + LEFT_PROJECT_OFFSET
      const rightEdge = -scrollPosition + screenSize - positionOffset - 60

      if (eventEnd < leftEdge || eventStart > rightEdge) {
        continue
      } else {
        visibleEvents.push(result)
      }
    }

    return visibleEvents
  }, [events, plannerProps.dayPx, positionOffset, screenSize, scrollPosition])

  return (
    <OOORow isFullScreen={isFullScreen}>
      {visibleEvents.map((event, index) => {
        return (
          <OOOEventColumn
            isFullScreen={isFullScreen}
            key={`${event.start}-${event.end}-${index}`}
            event={event}
            eventHeight={eventHeight}
          />
        )
      })}
    </OOORow>
  )
}

const OOORow = classed('div', {
  base: 'w-full h-full absolute top-0',
  variants: {
    isFullScreen: {
      true: `h-[100vh]`,
      false: '',
    },
  },
})
