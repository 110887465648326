import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'
/**
 * 'blockedBy' means that the handler 'task' is blocked by another task
 * 'blocks' means that the handler 'task' is blocking another task
 */
export type BlockerType = 'blockedBy' | 'blocks'

export type TaskBlockersUpdaterProps = {
  type: BlockerType
}
export function useTaskBlockersUpdater({ type }: TaskBlockersUpdaterProps) {
  const updateTask = useTaskUpdater()

  const addTaskBlocker = useCallback(
    async (task: TaskSchema, blocker: TaskSchema) => {
      if (task.type !== 'NORMAL' || blocker.type !== 'NORMAL') {
        // Blockers can only exist on normal tasks
        return
      }

      const taskToUpdate = type === 'blockedBy' ? task : blocker
      const blockerToAdd = type === 'blockedBy' ? blocker : task

      const newBlockedByTaskIds = [
        ...taskToUpdate.blockedByTaskIds,
        blockerToAdd.id,
      ]

      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_BLOCKERS')

      await updateTask(taskToUpdate, {
        blockedByTaskIds: newBlockedByTaskIds,
      })
    },
    [type, updateTask]
  )

  const removeTaskBlocker = useCallback(
    async (task: TaskSchema, blocker: TaskSchema) => {
      if (task.type !== 'NORMAL' || blocker.type !== 'NORMAL') {
        // Blockers can only exist on normal tasks
        return
      }

      const taskToUpdate = type === 'blockedBy' ? task : blocker
      const blockerToRemove = type === 'blockedBy' ? blocker : task

      const newBlockedByTaskIds = taskToUpdate.blockedByTaskIds.filter(
        (id) => id !== blockerToRemove.id
      )

      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_BLOCKERS')

      await updateTask(taskToUpdate, {
        blockedByTaskIds: newBlockedByTaskIds,
      })
    },
    [type, updateTask]
  )

  return {
    addTaskBlocker,
    removeTaskBlocker,
  }
}
