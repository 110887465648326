import { type ContactRecord } from '@motion/rpc/types'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { getProxy } from '../backgroundProxy'
import type { RootState } from '../types'

const contactsService = getProxy('ContactsService')

const THUNK_PREFIX = 'contacts'

export const searchContacts = createAsyncThunk<
  ContactRecord,
  { search: string; teamOnly?: boolean; uniqueId?: string },
  { state: RootState }
>(`${THUNK_PREFIX}/search`, async (opts) => {
  return contactsService.search(opts)
})
