import { PuzzleSolid } from '@motion/icons'
import { TextField } from '@motion/ui/forms'

type TemplateNameFieldProps = {
  value: string
  onChange: (value: string) => void
  hasErrors?: boolean
}
export const TemplateNameField = ({
  value,
  onChange,
  hasErrors = false,
}: TemplateNameFieldProps) => {
  return (
    <div className='flex flex-row items-center gap-3'>
      <PuzzleSolid className='w-[18px] h-[18px] text-dropdown-item-icon-default' />
      <TextField
        autoFocus
        sentiment={hasErrors ? 'error' : undefined}
        placeholder='Template name'
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
