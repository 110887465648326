import { type StripePaymentMethodSchema } from '@motion/rpc-types'
import { Button, Tag } from '@motion/ui/base'
import { formattedCurrencyAmount, templateStr } from '@motion/ui-logic'
import {
  type BillingPrices,
  INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT,
  makeTeamTrialOnIndividualBillingTermsStr,
} from '@motion/ui-logic/billing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useShouldShowSalesTaxMessage } from '@motion/web-billing'
import { useModalApi } from '@motion/web-common/modals'

import { useOpenPortalLink } from '~/components/Account/hooks'
import { useUncancelSubscriptionAndUpdate } from '~/components/cancel-account-modal/rpc-hooks'
import { useIsNoCardTrialing } from '~/global/hooks'
import { useCustomerCreditBalance } from '~/global/hooks/use-customer-credit-balance'
import { useGetWorkspaceSummary } from '~/global/rpc'
import { useGetTeamPrices } from '~/global/rpc/team'
import { fetchTeam } from '~/state/projectManagement/teamThunks'
import { dispatch } from '~/state/proxy'
import { reenableSubscription } from '~/state/teamSlice'
import { type Team } from '~/state/TeamTypes'
import { type StripeSubscription } from '~/state/userSlice'
import { DateTime } from 'luxon'

import { BillingSection, BillingSectionTitle } from './billing-section'

import { ManagePlanDropdown } from '../manage-plan-dropdown'

export type PlanOverviewSectionProps = {
  stripeSubscription: StripeSubscription
  team: Readonly<Team | null>
  paymentMethod?: StripePaymentMethodSchema
  isTeam?: boolean
  teamPrices?: BillingPrices
  isSecondarySubscription?: boolean
}

type ConnectedPlanOverviewSectionProps = PlanOverviewSectionProps &
  (
    | { isTeam?: false; teamPrices?: BillingPrices }
    | { isTeam: true; teamPrices: BillingPrices; team: Readonly<Team> }
  )

const PlanOverviewSection = ({
  stripeSubscription,
  paymentMethod,
  isTeam,
  team,
  teamPrices,
  isSecondarySubscription,
}: ConnectedPlanOverviewSectionProps) => {
  const teamSubscription = team?.pmTeamSubscription || null

  const modalApi = useModalApi()

  const discount = stripeSubscription.discount?.coupon?.percent_off
  const pricePerSeat = stripeSubscription.plan.amount / 100
  const subscriptionMultiplier = stripeSubscription.quantity
  const isTrial = stripeSubscription.status === 'trialing'
  const isAnnual = stripeSubscription.plan.interval === 'year'
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()
  const isNoCardTrialing = useIsNoCardTrialing()
  const customerBalance = useCustomerCreditBalance()

  const { data: workspaceSummary } = useGetWorkspaceSummary()

  const { isPortalLoading, openPortalLink } = useOpenPortalLink({
    stripeCustomerId: stripeSubscription.customer,
  })

  const { doUncancelSubscription } = useUncancelSubscriptionAndUpdate()

  const formattedEndDate = stripeSubscription.current_period_end
    ? DateTime.fromSeconds(stripeSubscription.current_period_end).toFormat(
        'LLL d, yyyy'
      )
    : ''

  const trialDaysRemaining = isTrial
    ? (DateTime.fromSeconds(stripeSubscription.current_period_end)
        .endOf('day')
        .diff(DateTime.local().endOf('day'), 'days')
        .toObject().days ?? -1)
    : undefined

  const isCanceled =
    !!stripeSubscription.cancel_at_period_end ||
    stripeSubscription.status === 'canceled'

  const handleSubscribe = async () => {
    if (!isCanceled) return
    if (isTrial) {
      if (isNoCardTrialing) {
        modalApi.open('choose-plan-modal')
        return
      }
      if (isTeam) {
        recordAnalyticsEvent('TEAM_BILLING_REACTIVATE')
      } else {
        recordAnalyticsEvent('INDIVIDUAL_BILLING_REACTIVATE')
      }
      await doUncancelSubscription()
    }
  }

  const onResubscribe = async () => {
    if (isTeam) {
      recordAnalyticsEvent('TEAM_BILLING_REACTIVATE')
      const hasDefaultSource =
        !!teamSubscription?.customer.default_source ||
        !!teamSubscription?.customer.invoice_settings.default_payment_method ||
        paymentMethod

      if (hasDefaultSource) {
        await dispatch(reenableSubscription(team.id))
        await dispatch(fetchTeam())
      } else {
        modalApi.open('change-payment-method', {
          hasPaymentMethod: false,
          activeTeamId: team.id,
        })
      }
      return
    }
    recordAnalyticsEvent('INDIVIDUAL_BILLING_REACTIVATE')
    await doUncancelSubscription()
  }

  return (
    <BillingSection>
      {!isAnnual && !isNoCardTrialing && (
        <div className='bg-semantic-success-bg-default w-full py-2 flex flex-row items-center justify-center gap-2'>
          <h2 className='text-semantic-neutral-text-default font-medium'>
            Switch to annual billing to save{' '}
            {isTeam
              ? teamPrices.annualSavingsPercentInteger
              : INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT}
            %
          </h2>
          <Button
            size='small'
            sentiment='primary'
            onClick={() =>
              modalApi.open('upgrade-panel', {
                subscription: stripeSubscription,
              })
            }
          >
            Switch to annual billing
          </Button>
        </div>
      )}
      <div className='flex flex-col gap-8 p-6'>
        <div className='flex justify-between items-start'>
          <div className='flex flex-col gap-2'>
            <BillingSectionTitle>Plan</BillingSectionTitle>
            <div className='flex flex-row items-center gap-2'>
              <h1 className='text-2xl text-semantic-neutral-text-default font-semibold'>
                {isTeam ? 'Team' : 'Individual'}
              </h1>
              {isTrial && (
                <Tag color='yellow' variant='subtle' size='small'>
                  <span className='font-semibold'>
                    {templateStr('Trial ends {{remainingStr}}{{plural}}', {
                      remainingStr:
                        trialDaysRemaining === 0
                          ? 'today'
                          : `in ${trialDaysRemaining} day`,
                      plural: (trialDaysRemaining ?? 0) > 1 ? 's' : '',
                    })}
                  </span>
                </Tag>
              )}
              {isCanceled && !isTrial && (
                <Tag color='grey' variant='subtle' size='small'>
                  <span className='font-semibold'>Auto-renewal cancelled</span>
                </Tag>
              )}
            </div>
            <p className='text-sm text-semantic-neutral-text-default font-medium'>
              {templateStr(
                '${{pricePerSeat}}{{seatStr}} per {{interval}}{{applicableTax}}',
                {
                  pricePerSeat,
                  seatStr: isTeam ? '/seat' : '',
                  interval: isAnnual ? 'year' : 'month',
                  applicableTax: shouldShowSalesTaxMessage
                    ? ' (plus applicable taxes)'
                    : '',
                }
              )}
            </p>
          </div>
          <div className='flex flex-row items-center gap-2'>
            {!isNoCardTrialing && (
              <Button
                sentiment='neutral'
                loading={isPortalLoading}
                onClick={openPortalLink}
              >
                Invoices
              </Button>
            )}
            <ManagePlanDropdown
              annualSavings={
                isTeam
                  ? teamPrices.annualSavingsPercentInteger
                  : INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT
              }
              isAnnual={isAnnual}
              isCanceled={isCanceled}
              isTrial={isTrial}
              onCancel={async () => {
                if (!isTeam) {
                  modalApi.open('cancel-account-modal', { workspaceSummary })
                  return
                }
                modalApi.open('cancel-team-plan', { team })
                recordAnalyticsEvent('TEAM_CANCELLATION_CLICK')
              }}
              onResubscribe={onResubscribe}
              onSubscribe={handleSubscribe}
              onSwitchPlan={async () => {
                recordAnalyticsEvent('CLICK_TEAM_BILLING_CONVERT_TO_ANNUAL')
                modalApi.open('upgrade-panel', {
                  subscription: stripeSubscription,
                })
              }}
            />
          </div>
        </div>
        <div>
          {!isCanceled ? (
            <div className='flex flex-row items-center gap-2'>
              {isSecondarySubscription ? (
                <p className='text-sm text-semantic-neutral-text-subtle font-normal'>
                  {makeTeamTrialOnIndividualBillingTermsStr(
                    pricePerSeat * subscriptionMultiplier,
                    isAnnual,
                    formattedEndDate,
                    trialDaysRemaining || 0
                  )}
                </p>
              ) : (
                <div className='flex flex-col'>
                  <p>
                    {templateStr('{{nextPayment}} {{nextPaymentStr}}', {
                      nextPayment: (
                        <span className='text-semantic-neutral-text-subtle'>
                          Next payment:
                        </span>
                      ),
                      nextPaymentStr: makeNextPaymentAmountStr(
                        pricePerSeat * subscriptionMultiplier,
                        formattedEndDate,
                        discount
                      ),
                    })}
                  </p>
                  {customerBalance && (
                    <p>
                      {templateStr('{{accountCreditStr}} {{customerBalance}}', {
                        accountCreditStr: (
                          <span className='text-semantic-neutral-text-subtle'>
                            Account credit:
                          </span>
                        ),
                        customerBalance,
                      })}
                    </p>
                  )}
                </div>
              )}
              {discount && (
                <Tag color='green' variant='subtle' size='small'>
                  <span className='font-semibold'>
                    {discount}% discount applied
                  </span>
                </Tag>
              )}
            </div>
          ) : (
            <div className='flex flex-col gap-2'>
              <p className='text-semantic-neutral-text-subtle text-sm'>
                {templateStr(
                  'Your {{planOrTrial}} will end on {{formattedEndDate}}.{{chargeStr}}',
                  {
                    planOrTrial: isTrial ? 'trial' : 'plan',
                    formattedEndDate,
                    chargeStr: isTrial ? '' : ' You won’t be charged again.',
                  }
                )}
              </p>
              <p className='text-semantic-error-text-default font-semibold text-sm'>
                All tasks, projects and workspaces will be permanently deleted
                90 days after your plan ends.
              </p>
            </div>
          )}
        </div>
      </div>
    </BillingSection>
  )
}

export const ConnectedPlanOverviewSection = (
  props: Omit<PlanOverviewSectionProps, 'isTeam' | 'teamPrices'>
) => {
  const isTeam = !!(
    props.team?.id &&
    props.team?.pmTeamSubscription?.subscription?.id ===
      props.stripeSubscription.id
  )

  const { data: teamPrices } = useGetTeamPrices()

  if (isTeam) {
    if (!teamPrices || !props.team) return null
    return (
      <PlanOverviewSection
        {...props}
        team={props.team}
        isTeam={isTeam}
        teamPrices={teamPrices}
      />
    )
  }

  return <PlanOverviewSection {...props} />
}

const makeNextPaymentAmountStr = (
  amount: number,
  date: string,
  discount?: number | null
) => {
  if (!discount) {
    return templateStr('{{baseAmount}} on {{nextPaymentDate}}', {
      baseAmount: formattedCurrencyAmount(amount, 'USD', true),
      nextPaymentDate: date,
    })
  }

  return (
    <>
      <span className='line-through'>
        {formattedCurrencyAmount(amount, 'USD', true)}
      </span>
      <span className='text-semantic-success-text-default'>
        {templateStr(' {{discountAmount}} on {{nextPaymentDate}}', {
          discountAmount: formattedCurrencyAmount(
            amount - amount * (discount / 100),
            'USD',
            true
          ),
          nextPaymentDate: date,
        })}
      </span>
    </>
  )
}
