import { LinkSolid, ProjectCubeSolid } from '@motion/icons'
import { isCanceledStatus } from '@motion/shared/common'
import { ButtonGroup, IconButton, Shortcut, Tooltip } from '@motion/ui/base'
import { prettyDateDay, templateStr } from '@motion/ui-logic'
import { type ProjectResponseSchema } from '@motion/zod/client'

import { PriorityLabel, StatusLabel } from '~/global/components/labels'

import { highlightEmText } from '../../utils'
import {
  AssigneeField,
  CommentField,
  DateField,
  DescCommentContainer,
  FieldBar,
  PMResultTitle,
  ResultItem,
  type ResultItemProps,
  WorkspaceProjectField,
} from '../shared'

export interface ProjectResultProps {
  item: ProjectResponseSchema
  onClick: ResultItemProps['onClick']
  onCopyLink: () => void
}

export function ProjectResult({
  item,
  onClick,
  onCopyLink,
}: ProjectResultProps) {
  return (
    <ResultItem
      renderIcon={() => (
        <ProjectCubeSolid className='shrink-0 text-semantic-neutral-icon-default w-4 h-4' />
      )}
      title={
        <PMResultTitle canceled={isCanceledStatus(item.status)}>
          {highlightEmText(item.name)}
        </PMResultTitle>
      }
      onClick={onClick}
      renderControls={() => {
        return (
          <ButtonGroup size='small'>
            <Tooltip
              asChild
              renderContent={() =>
                templateStr('Copy link {{shortcut}}', {
                  shortcut: <Shortcut shortcut='mod+l' />,
                })
              }
            >
              <IconButton
                icon={LinkSolid}
                size='small'
                variant='outlined'
                sentiment='neutral'
                aria-label='Copy project link'
                onClick={(evt) => {
                  evt.stopPropagation()
                  onCopyLink()
                }}
              />
            </Tooltip>
          </ButtonGroup>
        )
      }}
    >
      <FieldBar>
        <WorkspaceProjectField workspaceName={item.workspace.name} />
        {item.dueDate && <DateField>{prettyDateDay(item.dueDate)}</DateField>}
        <StatusLabel size='small' value={item.status} />
        <PriorityLabel size='small' value={item.priorityLevel} />
        <AssigneeField
          assigneeUserId={item.managerUserId}
          assigneeName={item.manager.name}
        />
      </FieldBar>
      <DescCommentContainer>
        {item.description && <div>{highlightEmText(item.description)}</div>}
        {item.comment && <CommentField comment={item.comment} />}
      </DescCommentContainer>
    </ResultItem>
  )
}
