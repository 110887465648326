import { type WorkspaceMemberWithUser } from '@motion/ui-logic'
import { type ActivitySchema } from '@motion/zod/client'

import { useActivityFeedContext } from '~/areas/activity-feed/providers'
import { type ReactNode } from 'react'

type ActivityUserNameProps = {
  sourceType: ActivitySchema['sourceType']
  author?: WorkspaceMemberWithUser
}

export function ActivityUserName({
  sourceType,
  author,
}: ActivityUserNameProps) {
  const { type } = useActivityFeedContext()

  let authorName: ReactNode

  switch (sourceType) {
    case 'USER': {
      // User might not exist if they were deleted
      authorName = author?.user.name ?? (
        <span className='line-through'>Deleted user</span>
      )
      break
    }
    case 'SYSTEM':
      authorName = type === 'event' ? 'Event sync' : 'Motion'
      break
  }

  return authorName
}
