import { parseCustomFieldInfoFromMaybeDelimitedKey } from '@motion/ui-logic'

import { Label } from '~/global/components/labels'

import {
  type CustomFieldMultiSelectRow,
  type CustomFieldSelectRow,
} from '../../../types'
import { GroupHeaderWithFieldName } from '../../group-headers'

export function CustomFieldSelectGroupHeader({
  item,
}: {
  item: (CustomFieldMultiSelectRow | CustomFieldSelectRow) & { key: string }
}) {
  const res = parseCustomFieldInfoFromMaybeDelimitedKey(item.type)
  if (res == null) return null
  const { name } = res

  return (
    <GroupHeaderWithFieldName fieldName={name}>
      <Label
        value={{
          id: item.value.id,
          name: item.value.value,
          color: item.value.color,
        }}
      />
    </GroupHeaderWithFieldName>
  )
}
