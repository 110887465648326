import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type AlertsStateItem = {
  id: string
  priority: number
}

type AlertsState = Record<string, AlertsStateItem>

const AlertsApiContext = createContext<{
  addAlert: (options: AlertsStateItem) => void
  all: () => AlertsState
  removeAlert: (id: string) => void
}>({
  addAlert: () => {},
  all: () => ({}),
  removeAlert: () => {},
})

export const AlertsProvider = ({ children }: { children: ReactNode }) => {
  const [alertState, setAlertState] = useState<AlertsState>({})

  const api = useMemo(
    () => ({
      addAlert: (options: AlertsStateItem) =>
        setAlertState((prevState) => ({ ...prevState, [options.id]: options })),
      all: () => alertState,
      removeAlert: (id: string) =>
        setAlertState((prevState) => {
          const newState = { ...prevState }
          delete newState[id]
          return newState
        }),
    }),
    [alertState]
  )

  return (
    <AlertsApiContext.Provider value={api}>
      {children}
    </AlertsApiContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export const useAlertContext = ({
  id,
  priority,
}: {
  id: string
  priority: number
}) => {
  const api = useContext(AlertsApiContext)

  if (!api) {
    throw new Error('useAlertContext must be used within the AlertsProvider')
  }

  const setOpen = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        api.addAlert({ id, priority })
        return
      }
      api.removeAlert(id)
    },
    // We only want it to take effect on the first render so we don't add the api to the deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, priority]
  )

  // Find the highest priority alert.
  let highestPriorityAlert = { id: 'none', priority: -1 }
  for (const value of Object.values(api.all())) {
    if (value.priority > highestPriorityAlert.priority) {
      highestPriorityAlert = value
    }
  }
  const open = highestPriorityAlert.id === id
  return { open, setOpen } as const
}
