import type { ScheduleInfo } from '@motion/ui-logic'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { type ReactNode } from 'react'

import { useAutoScheduleTooltip } from '../hooks'

export type AutoScheduleTooltipProps = {
  info: ScheduleInfo
  task: TaskSchema | RecurringTaskSchema | undefined
}

export const AutoScheduleTooltip = ({
  info,
  task,
}: AutoScheduleTooltipProps): ReactNode => {
  const toggleTooltipMessage = useAutoScheduleTooltip(info, task)

  return toggleTooltipMessage
}
