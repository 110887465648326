import { Button } from '@motion/ui/base'
import { getIsTeamTrialSetToCancel } from '@motion/ui-logic'
import { INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT } from '@motion/ui-logic/billing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { TrialDaysLeftBadge } from '~/components/Alerts/TrialDaysLeftBadge/TrialDaysLeftBadge'
import {
  useIsAdmin,
  useIsEnterpriseSubscription,
  useUpsellInfo,
} from '~/global/hooks'
import { useIsTeamExpired } from '~/global/hooks/team'
import { useGetTeamPrices } from '~/global/rpc/team'
import { useAppSelector } from '~/state/hooks'
import { selectStripeSubscription } from '~/state/userSlice'
import { DateTime } from 'luxon'

export function ConnectedUpsellButtons() {
  const modalApi = useModalApi()
  const { data: teamPrices } = useGetTeamPrices()
  const isAdmin = useIsAdmin()
  const isExpiredTeam = useIsTeamExpired()
  const userStripeSubscription = useAppSelector(selectStripeSubscription)
  const { isEnterprise, isLoading: isEnterpriseLoading } =
    useIsEnterpriseSubscription()

  const {
    isOnTeamMonthly,
    isIndividualNoCardTrial,
    showTeamAnnualUpgrade,
    showIndividualAnnualUpgrade,
    stripeSubscription,
    team,
  } = useUpsellInfo()

  const showAnnualUpgrade =
    showIndividualAnnualUpgrade ||
    (isOnTeamMonthly && isAdmin && !isIndividualNoCardTrial)

  const showCreateTeam = showTeamAnnualUpgrade && !isOnTeamMonthly

  const isTeamTrial = getIsTeamTrialSetToCancel(team?.pmTeamSubscription)

  const showTrialDaysLeftUpsell =
    (isTeamTrial && isAdmin) || isIndividualNoCardTrial

  if (!teamPrices || isEnterprise || isEnterpriseLoading) return null

  return (
    <>
      {showTrialDaysLeftUpsell && (
        <TrialDaysLeftBadge
          trialExpiryISO={
            isTeamTrial
              ? (team?.pmTeamSubscription?.endDate ?? '')
              : userStripeSubscription?.trial_end
                ? DateTime.fromSeconds(
                    userStripeSubscription?.trial_end
                  ).toISO()
                : ''
          }
        />
      )}

      {showCreateTeam && (
        <Button
          onClick={() => {
            recordAnalyticsEvent('NAVBAR_CTA_CREATE_TEAM', {
              version: 'v2',
            })
          }}
          fullWidth
          sentiment='success'
          size='small'
          url={
            isExpiredTeam && isAdmin
              ? '/web/settings/team'
              : '/web/settings/team/create'
          }
          variant='outlined'
        >
          💪 Save {teamPrices.individualToTeamSavingsPercent}% with team plan
        </Button>
      )}

      {showAnnualUpgrade && (
        <Button
          onClick={() => {
            recordAnalyticsEvent('NAVBAR_CTA_ACCOUNT_UPGRADE_ANNUAL', {
              isTeam: isOnTeamMonthly,
              version: 'v2',
            })
            modalApi.open('upgrade-panel', {
              subscription:
                team?.pmTeamSubscription?.subscription ?? stripeSubscription,
            })
          }}
          fullWidth
          sentiment='success'
          size='small'
          variant='outlined'
        >
          🎉 Switch to annual to save{' '}
          {isOnTeamMonthly
            ? teamPrices.annualSavingsPercentInteger
            : INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT}
          %
        </Button>
      )}
    </>
  )
}
