import { type FieldTypeSchema } from '@motion/shared/custom-fields'
import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import {
  type AllAvailableCustomFieldSchema,
  type AvailableCustomFieldTypes,
  type CustomFieldSchemaByType,
  type CustomFieldValueSchemaByType,
  type TypedNameKey,
  type WorkspaceFieldsByTypedName,
} from '@motion/ui-logic'
import { parseCustomFieldInfoFromMaybeDelimitedKey } from '@motion/ui-logic'
import { createNoneUser } from '@motion/ui-logic/pm/data'
import { groupInto, uniqueBy } from '@motion/utils/array'
import { type NormalTaskSchema, type UserInfoSchema } from '@motion/zod/client'

import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { type AppWorkspaceDataContext } from '~/global/contexts'

import {
  type CustomFieldRow,
  type CustomFieldRowValue,
  type GroupTaskParents,
} from '../../tree-list/types'
import { type GroupValue } from '../utils'

export const CUSTOM_FIELD_NO_VALUE = 'No Value'
export const CUSTOM_FIELD_NONE_ID = createNoneId(CUSTOM_FIELD_NO_VALUE)

/**
 * Filter options by unique value.
 * Returns only the last option with any duplicated value.
 *
 * @example
 * Passed list of ids: ['1', '2']
 * and options: [{ id: '1', value: 'Option 1', color: 'red' }, { id: '2', value: 'Option 1', color: 'blue' }, { id: '3', value: 'Option 3', color: 'green' }]
 * Returns: [{ id: '2', value: 'Option 1', color: 'blue' } ]
 *
 */
export function getUniqueSelectOptionsByIds(
  options: CustomFieldSchemaByType<'multiSelect'>['metadata']['options'],
  value: CustomFieldValueSchemaByType<'multiSelect'>['value']
) {
  const valueOptions = options.filter((o) => value?.some((v) => o.id === v))
  return uniqueBy(valueOptions, (o) => o.value)
}

export function getTypedNameKey(
  field: Pick<AllAvailableCustomFieldSchema, 'type' | 'name'>
): TypedNameKey {
  return `${field.type}/${field.name}`
}

export function getCustomFieldValuesFromGroupParents(
  parents: GroupTaskParents,
  workspaceCustomFieldsByTypedName: WorkspaceFieldsByTypedName
): NormalTaskSchema['customFieldValues'] {
  return Object.values(parents).reduce<NormalTaskSchema['customFieldValues']>(
    (acc, row) => {
      if (row == null) return acc
      const res = parseCustomFieldInfoFromMaybeDelimitedKey(row.type)
      if (res === null) {
        return acc
      }
      const { customFieldType, name } = res
      const typedNameKey = getTypedNameKey({ type: customFieldType, name })
      const customFieldId = workspaceCustomFieldsByTypedName[typedNameKey]?.id
      if (customFieldId == null) {
        return acc
      }
      const valueToAdd = getCustomFieldValueFromCustomFieldRow(
        row as CustomFieldRow
      )
      if (valueToAdd == null || valueToAdd.value == null) {
        return acc
      }

      return {
        ...acc,
        [customFieldId]: valueToAdd,
      }
    },
    {}
  )
}

function getCustomFieldValueFromCustomFieldRow(row: CustomFieldRow) {
  const res = parseCustomFieldInfoFromMaybeDelimitedKey(row.type)
  if (res === null) {
    return null
  }
  const { customFieldType } = res
  switch (customFieldType) {
    case 'text':
    case 'url':
    case 'date': {
      const value = row.value as CustomFieldRowValue<typeof customFieldType>
      return {
        type: customFieldType,
        value: value === CUSTOM_FIELD_NO_VALUE ? null : value,
      }
    }

    case 'number': {
      const value = row.value as CustomFieldRowValue<typeof customFieldType>
      return { type: customFieldType, value: parseFloat(value ?? '') ?? null }
    }

    case 'select':
    case 'person': {
      const value = row.value as CustomFieldRowValue<typeof customFieldType>
      return {
        type: customFieldType,
        value:
          value != null && value.id && !isNoneId(value.id) ? value.id : null,
      }
    }

    case 'multiPerson':
    case 'multiSelect': {
      const value = row.value as CustomFieldRowValue<typeof customFieldType>
      return {
        type: customFieldType,
        value:
          value != null && value.id && !isNoneId(value.id) ? [value.id] : null,
      }
    }

    default:
      return null
  }
}

export function isGroupBySupportedForField(type: AvailableCustomFieldTypes) {
  const types: FieldTypeSchema[] = [
    'text',
    'url',
    'number',
    'date',
    'select',
    'multiSelect',
    'person',
    'multiPerson',
  ]
  return types.includes(type)
}

export function createCustomFieldGroupingName(field: WorkspaceCustomField) {
  return `${getTypedNameKey(field)}`
}

export function getPersonInitialValues(
  ctx: AppWorkspaceDataContext
): GroupValue<UserInfoSchema>[] {
  const firstWorkspace = ctx.workspaces.all[0]
  const noneUser = createNoneUser(firstWorkspace.id)

  return [
    {
      key: noneUser.name,
      value: noneUser,
      workspaceIds: [firstWorkspace.id],
    },
    ...groupInto(ctx.members.all, (x) => x.id).reduce<
      GroupValue<UserInfoSchema>[]
    >(
      (acc, x) =>
        // Keyed by name to avoid duplicates
        acc.some((v) => v.value.name === x.items[0].user.name)
          ? acc
          : acc.concat({
              key: x.items[0].user.name,
              value: x.items[0].user,
              workspaceIds: x.items.map((w) => w.workspaceId),
            }),
      []
    ),
  ]
}
