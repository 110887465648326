import { WorkspaceSolid } from '@motion/icons'
import { ProjectPalette } from '@motion/ui/project'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type WorkspaceBadgeProps = {
  size?: BadgeSize
}
export function WorkspaceBadge({ size = 'normal' }: WorkspaceBadgeProps) {
  const pixelSize = getBadgeSizeInPixels(size)

  return (
    <ProjectPalette color='gray'>
      <WorkspaceSolid
        className='text-palette-highlight-default shrink-0'
        height={pixelSize}
        width={pixelSize}
      />
    </ProjectPalette>
  )
}
