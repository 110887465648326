import { type TaskUrlSearchParams } from '@motion/ui-logic/pm/task'
import { entries } from '@motion/utils/object'

import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { useMeetingModalUrl } from './use-meeting-modal-url'

export type TaskUrlParams = {
  workspaceId?: string
  projectId?: string
}

export type TaskModalParams = TaskUrlSearchParams & {
  isMeeting?: boolean
}

export function useTaskModalUrl() {
  const { workspaceId: workspaceIdParam, projectId: projectIdParam } =
    useParams<TaskUrlParams>()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const buildMeetingModalUrl = useMeetingModalUrl()

  return useCallback(
    ({ isMeeting = false, ...params }: TaskModalParams = {}) => {
      if (isMeeting && params.task != null && params.task !== 'new') {
        return buildMeetingModalUrl({ mTask: params.task })
      }

      const urlParams = new URLSearchParams(searchParams)

      const newParams: TaskUrlSearchParams = {
        ...params,
        // replace params based on the current route params
        forWorkspace:
          params.forWorkspace === workspaceIdParam
            ? undefined
            : params.forWorkspace,
        forProject:
          params.forProject === projectIdParam ? undefined : params.forProject,
        task: params.task ?? 'new',
        forCustomField: params.forCustomField ?? undefined,
      }

      for (const [key, value] of entries(newParams)) {
        if (key === 'forCustomField') {
          if (value == null || typeof value !== 'object') {
            urlParams.delete(key)
            continue
          }

          urlParams.set(key, JSON.stringify(value))
          continue
        }

        if (value) {
          urlParams.set(key, value as string)
        }
      }

      // Remove meeting task param because it's not compatible with task. We cannot stack them
      urlParams.delete('mTask')

      return `${pathname}?${urlParams.toString()}`
    },

    [
      buildMeetingModalUrl,
      pathname,
      projectIdParam,
      searchParams,
      workspaceIdParam,
    ]
  )
}
