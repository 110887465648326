import { classed, type ComponentProps } from '@motion/theme'

import { type ComponentRef, forwardRef } from 'react'

export const Card = classed('div', {
  base: `
      w-full
      flex flex-col
      rounded-md
      cursor-pointer
      text-semantic-neutral-text-default
      text-[11px]
      leading-4

      border overflow-hidden
      bg-kanban-card-bg-default border-kanban-card-border-default
      hover:border-kanban-card-border-hover

      select-none
  `,
  variants: {
    dragging: { true: '-rotate-2', false: '' },
    padded: { true: 'p-2 gap-2', false: '' },
    isGhost: {
      true: 'border-semantic-neutral-border-light bg-semantic-neutral-surface-bg-subtlest',
      false: '',
    },
  },
  defaultVariants: {
    dragging: false,
    padded: true,
    isGhost: false,
  },
})

export const CardInfoRow = classed('div', {
  base: `flex flex-row items-center`,
  variants: {
    spaceBetween: { true: 'justify-between' },
    reverse: {
      true: 'flex-row-reverse justify-end',
      false: '',
    },
  },
  defaultVariants: {
    spaceBetween: false,
    reverse: false,
  },
})

export const CardTitle = classed('div', {
  base: `
    text-semantic-neutral-text-default
    text-sm leading-5 font-medium
    line-clamp-2 h-10
  `,

  variants: {
    canceled: {
      true: 'line-through text-semantic-neutral-text-subtle',
    },
  },
  defaultVariants: {
    canceled: false,
  },
})

export const CardButton = forwardRef<
  ComponentRef<typeof StyledCardButton>,
  ComponentProps<typeof StyledCardButton>
>(function CardButton(props, ref) {
  return <StyledCardButton ref={ref} {...props} />
})

const StyledCardButton = classed('button', {
  base: `

    rounded px-1 py-px

    disabled:cursor-not-allowed
    enabled:hover:bg-semantic-neutral-bg-active-hover
  `,
  variants: {
    iconOnly: {
      true: 'p-px shrink-0',
      false: 'px-1 py-px',
    },
    noOverflow: {
      true: '',
      false: 'overflow-hidden',
    },
    fullWidth: {
      true: 'w-full',
    },
  },
  defaultVariants: {
    iconOnly: false,
    noOverflow: false,
    fullWidth: false,
  },
})
