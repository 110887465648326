import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { TaskActivity } from '~/areas/activity-feed/components'
import { CollapsableContainer } from '~/areas/task-project/components'

import { useTaskModalState } from '../../contexts'

export const TaskActivityCollapsable = ({ taskId }: { taskId: string }) => {
  const { setHasPendingComment } = useTaskModalState()
  return (
    <CollapsableContainer
      title='Activity'
      onToggle={(expanded) => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_COLLAPSE_ACTIVITY', {
          collapsed: !expanded,
          type: 'task',
        })
      }}
    >
      <TaskActivity
        taskId={taskId}
        onCommentChange={(value) => {
          setHasPendingComment(value.length > 0 && value !== '<p></p>')
        }}
      />
    </CollapsableContainer>
  )
}
