import { useProject } from '~/global/hooks'

import { ActivityFeed } from './activity-feed'

export type ProjectActivityProps = {
  projectId: string
  onCommentChange?: (value: string) => void
}

export function ProjectActivity({
  projectId,
  onCommentChange,
}: ProjectActivityProps) {
  const project = useProject(projectId)

  // Don't throw an error if the project is not found,
  // It could be that the project is deleted
  if (!project) {
    return null
  }

  return (
    <div className='pb-2'>
      <ActivityFeed
        type='project'
        target={project}
        workspaceId={project.workspaceId}
        onCommentChange={onCommentChange}
      />
    </div>
  )
}
