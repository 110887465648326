import { PopoverTrigger } from '@motion/ui/base'
import { canAddTime } from '@motion/ui-logic/pm/task'
import type { TaskSchema } from '@motion/zod/client'

import { AddTaskDurationActionList } from '~/areas/tasks/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useState } from 'react'

import { CurrentTaskButton } from './current-task-button'

type AddTimeButtonProps = {
  task: TaskSchema
}

export function AddTimeButton({ task }: AddTimeButtonProps) {
  const updateTask = useTaskUpdater()
  const [addTimeInFlight, setAddTimeInFlight] = useState(false)

  const onSelectTime = async (task: TaskSchema, duration: number) => {
    setAddTimeInFlight(true)
    await updateTask(task, {
      duration: (task.duration ?? 0) + duration,
    })
    setAddTimeInFlight(false)
  }

  if (!canAddTime(task)) return null

  return (
    <PopoverTrigger
      placement='left-start'
      renderPopover={({ close }) => (
        <AddTaskDurationActionList
          close={close}
          currentDuration={task.duration}
          onSelect={(duration) => onSelectTime(task, duration)}
        />
      )}
    >
      <CurrentTaskButton action='add-time' loading={addTimeInFlight}>
        Add time
      </CurrentTaskButton>
    </PopoverTrigger>
  )
}
