import { type COLOR } from '@motion/shared/common'
import { FolderColoredIcon } from '@motion/ui/project'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

type FolderBadgeProps = {
  size?: BadgeSize
  color?: COLOR
}

export function FolderBadge({
  size = 'normal',
  color = 'gray',
}: FolderBadgeProps) {
  const pixelSize = getBadgeSizeInPixels(size)

  return (
    <FolderColoredIcon color={color} height={pixelSize} width={pixelSize} />
  )
}
