import {
  getStageChangeText,
  type StageWithDates,
} from '@motion/ui-logic/pm/project'

import { ConnectedStageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'

export const StageDatesRow = ({
  stageWithDates,
}: {
  stageWithDates: StageWithDates
}) => {
  const { stage } = stageWithDates

  const { pluralize } = useI18N()
  const stageChangeText = getStageChangeText({ stageWithDates, pluralize })

  return (
    <span className='flex gap-1 items-center text-sm flex-wrap break-words'>
      {stage.stageDefinitionId && (
        <ConnectedStageLabel id={stage.stageDefinitionId} size='normal' />
      )}
      <span>{stageChangeText}</span>
    </span>
  )
}
