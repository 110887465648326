import { StatusType } from '@motion/shared/common'
import { getStageVariant, type StageVariant } from '@motion/ui-logic/pm/project'
import { type ProjectSchema, type StatusSchema } from '@motion/zod/client'

import { useProjectStatusUpdater } from '~/areas/project/hooks'
import { useProjectStageByProjectAndDefinitionId } from '~/areas/project/hooks/data'
import { StatusDropdown } from '~/areas/project-management/components'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { useTaskStatusData } from '~/areas/tasks/hooks'
import { StatusLabel } from '~/global/components/labels'
import { FilteredWorkspaceContext } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CellAction, CellText } from './components'
import { useProjectInTree } from './hooks'

import { type GroupedNode } from '../../grouping'

type TaskStatusCellProps = {
  task: TaskWithRelations
}
export const TaskStatusCell = ({ task }: TaskStatusCellProps) => {
  const { onChange, isStatusAllowed, disabled, hasValidData } =
    useTaskStatusData(task)
  const { isUnvisitedStage } = task

  if (!hasValidData) {
    return <CellText>-</CellText>
  }

  return (
    <FilteredWorkspaceContext statuses={isStatusAllowed}>
      <TaskStatusDropdown
        isUnvisitedStage={isUnvisitedStage}
        onChange={onChange}
        selectedStatusId={task.statusId}
        workspaceId={task.workspaceId}
      >
        <CellAction disabled={disabled}>
          <StatusLabel
            value={task.status}
            iconVariant={isUnvisitedStage ? 'isUnvisitedStage' : undefined}
          />
        </CellAction>
      </TaskStatusDropdown>
    </FilteredWorkspaceContext>
  )
}

type ProjectStatusCellProps = {
  project: ProjectWithRelations
}
export const ProjectStatusCell = ({ project }: ProjectStatusCellProps) => {
  const updateProjectStatus = useProjectStatusUpdater()

  return (
    <StatusDropdown
      onChange={(statusId) => updateProjectStatus(project, statusId)}
      selectedStatusId={project.statusId}
      workspaceId={project.workspaceId}
    >
      <CellAction>
        <StatusLabel value={project.status} />
      </CellAction>
    </StatusDropdown>
  )
}

type StageStatusCellProps = {
  row: GroupedNode
  stageDefinitionId: string
}
export const StageStatusCell = ({
  row,
  stageDefinitionId,
}: StageStatusCellProps) => {
  const project = useProjectInTree(row)
  if (!project) return null

  return (
    <CellAction disabled>
      <InnerStageStatusCell
        project={project}
        stageDefinitionId={stageDefinitionId}
      />
    </CellAction>
  )
}

type InnerStageStatusCellProps = {
  project: ProjectSchema
  stageDefinitionId: string
}

const InnerStageStatusCell = ({
  project,
  stageDefinitionId,
}: InnerStageStatusCellProps) => {
  const stage = useProjectStageByProjectAndDefinitionId(
    project,
    stageDefinitionId
  )
  const { getWorkspaceStatusByName } = useWorkspaceFns()

  if (!stage) return null

  const stageVariant = getStageVariant(stage)

  const statusName = getStageStatusName(stageVariant, stage.visited)

  // Create a fake status object
  const value: Pick<StatusSchema, 'name' | 'color' | 'type'> = {
    name: statusName,
    color:
      getWorkspaceStatusByName(project.workspaceId, statusName)?.color ??
      'grey',
    type: getStageStatusType(stageVariant),
  }

  return <StatusLabel value={value} />
}

const getStageStatusName = (
  stageVariant: StageVariant,
  isVisitedStage: boolean
) => {
  return stageVariant === 'completed'
    ? 'Completed'
    : stageVariant === 'skipped'
      ? 'Canceled'
      : isVisitedStage
        ? 'In Progress'
        : 'Todo'
}

const getStageStatusType = (stageVariant: StageVariant): StatusType => {
  return stageVariant === 'completed'
    ? StatusType.COMPLETED
    : stageVariant === 'skipped'
      ? StatusType.CANCELED
      : StatusType.DEFAULT
}
