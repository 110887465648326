import type { TaskSchema } from '@motion/rpc-types'
import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'

import { useWorkspaceFns } from '~/global/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useBlockerTasksFn } from './use-blocker-tasks-fn'

type UseHasUnfinishedBlockersFnOptions = {
  only?: 'blockedBy' | 'blocking'
}

export const useHasUnfinishedBlockersFn = ({
  only,
}: UseHasUnfinishedBlockersFnOptions = {}) => {
  const readTask = useReadTaskFn()
  const getBlockerTasks = useBlockerTasksFn()
  const { getStatusById } = useWorkspaceFns()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      inStageDefinitionId: string | null
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }

      const { blockedBy, blocking } = await getBlockerTasks(task)

      const blockerTasks =
        only === 'blockedBy'
          ? blockedBy
          : only === 'blocking'
            ? blocking
            : [...blockedBy, ...blocking]

      return blockerTasks.some((blocker) => {
        if (blocker.type !== 'NORMAL') {
          return false
        }

        if (
          inStageDefinitionId != null &&
          blocker.stageDefinitionId != null &&
          blocker.stageDefinitionId !== inStageDefinitionId
        ) {
          return false
        }

        const status = getStatusById(blocker.statusId)

        return !(isCompletedStatus(status) || isCanceledStatus(status))
      })
    },
    [getBlockerTasks, getStatusById, only, readTask]
  )
}
