import { FilledChevronDownSolid, type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'
import { type ContextMenuPopoverProps, useContextMenu } from '@motion/ui/base'

import type { DraggableAttributes } from '@dnd-kit/core'
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { useDebugMode } from '~/global/hooks/use-debug-mode'
import { forwardRef, type ReactNode } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { DragHandle } from './drag-handle'

import { indentationWidth, itemHeight } from '../constants'

export type TreeviewItemProps = {
  // --------- Required ---------
  label: ReactNode
  level: number
  order: string
  url: string
  // --------- Optional ---------
  attributes?: Record<string, unknown>
  className?: string
  disableDrag?: boolean
  handleListeners?: SyntheticListenerMap
  handleRef?: (node: HTMLElement | null) => void
  icon?: SvgIcon
  isActive?: boolean
  isContainer?: boolean
  isContainerExpanded?: boolean
  isDragging?: boolean
  isGhost?: boolean
  isHighlighted?: boolean
  isInside?: boolean
  isSorting?: boolean
  onToggleExpand?: (newState: boolean) => void
  renderButtons?: () => ReactNode
  renderContextMenuContent?: ContextMenuPopoverProps['renderContent']
  renderFixedButtons?: () => ReactNode
  style?: React.CSSProperties
  wrapperRef?: (node: HTMLDivElement) => void
  // ------------------------------
  handleAttributes?: DraggableAttributes
}

export const TreeviewItem = forwardRef<HTMLDivElement, TreeviewItemProps>(
  function TreeviewItem(
    {
      attributes,
      className,
      disableDrag = false,
      handleListeners,
      handleRef,
      icon: Icon,
      isActive = false,
      isContainer = false,
      isContainerExpanded = true,
      isDragging,
      isGhost = false,
      isHighlighted,
      isInside,
      isSorting,
      label,
      level,
      onToggleExpand = () => void 0,
      order,
      renderButtons,
      renderFixedButtons,
      renderContextMenuContent,
      style,
      url,
      wrapperRef,
    },
    ref
  ) {
    const navigate = useNavigate()
    const { handleContextMenu, ContextMenuPopover } = useContextMenu()
    const isDebugMode = useDebugMode()

    return (
      <>
        <div
          ref={wrapperRef}
          style={style}
          className={twMerge(
            'relative group',
            isDragging && 'z-10',
            isSorting && 'pointer-events-none'
          )}
          data-level={level}
          data-order={order}
          {...attributes}
        >
          <div
            ref={ref}
            className={twMerge(
              'flex items-center mr-3',
              isDragging &&
                'bg-semantic-primary-bg-strong-default h-0.5 overflow-hidden rounded-full mr-4',
              className
            )}
            style={{
              marginLeft: level * indentationWidth + (isDragging ? 20 : 0),
            }}
            onContextMenu={handleContextMenu}
          >
            <div
              className={twMerge(
                'items-center grid grid-cols-[min-content_1fr] w-full',
                isDragging && 'hidden'
              )}
              style={{ height: itemHeight }}
            >
              <span className='opacity-0 group-hover:opacity-100 transition-all'>
                <DragHandle
                  isDragging={isDragging}
                  listeners={handleListeners}
                  setActivatorNodeRef={handleRef}
                  disabled={disableDrag}
                />
              </span>

              <ItemShell
                active={isActive}
                droppable={isInside}
                ghost={isGhost}
                highlighted={isHighlighted}
              >
                {Icon && (
                  <div
                    className='p-1.5 size-4 box-content relative shrink-0 cursor-pointer'
                    onClick={() => {
                      if (!isContainer) {
                        return void navigate(url)
                      }

                      onToggleExpand(!isContainerExpanded)
                    }}
                  >
                    {isContainer && (
                      <FilledChevronDownSolid
                        className={twMerge(
                          'absolute top-1.5 left-1.5 size-4 opacity-0 group-hover:opacity-100 transition-all',
                          !isContainerExpanded && '-rotate-90'
                        )}
                      />
                    )}

                    <Icon
                      className={twMerge(
                        'absolute top-1.5 left-1.5 size-4 transition-all',
                        isContainer && 'opacity-100 group-hover:opacity-0'
                      )}
                    />
                  </div>
                )}
                <Link to={url} className='contents'>
                  <div className='h-full text-sm truncate w-full leading-7'>
                    {label}
                  </div>
                </Link>
                {!isGhost && (renderButtons || renderFixedButtons) && (
                  <div className='px-1'>
                    <div className='relative flex items-center justify-center h-full gap-1'>
                      {renderButtons && (
                        <div className='absolute invisible group-hover:relative group-hover:visible right-0'>
                          {renderButtons()}
                        </div>
                      )}

                      {renderFixedButtons && (
                        <div className='contents'>{renderFixedButtons()}</div>
                      )}
                    </div>
                  </div>
                )}
                {isDebugMode && (isSorting || isGhost) && (
                  <DebugModeLabel>{order}</DebugModeLabel>
                )}
              </ItemShell>
            </div>
          </div>
        </div>
        {renderContextMenuContent && (
          <ContextMenuPopover renderContent={renderContextMenuContent} />
        )}
      </>
    )
  }
)

const ItemShell = classed('div', {
  base: `
    relative flex items-center text-sm select-none
    h-full pl-0.5 ml-0.5 gap-0.5
    text-sidebar-item-text-default
    hover:bg-sidebar-item-bg-hover rounded overflow-hidden
  `,
  variants: {
    active: {
      true: 'bg-sidebar-item-bg-selected text-sidebar-item-text-selected',
      false: '',
    },
    droppable: {
      true: 'bg-semantic-primary-bg-hover text-semantic-primary-text-default',
      false: '',
    },
    ghost: {
      true: 'opacity-50 bg-semantic-neutral-bg-hover shadow border border-semantic-neutral-border-strong',
      false: '',
    },
    highlighted: {
      true: 'bg-sidebar-item-bg-hover',
      false: '',
    },
  },
  defaultVariants: {
    active: false,
    droppable: false,
    ghost: false,
    highlighted: false,
  },
})

const DebugModeLabel = classed(
  'div',
  'text-semantic-warning-text-default border-dashed border-semantic-warning-border-default p-0.5 border text-[8px] leading-[8px] font-mono'
)
