import { FieldButton, type FieldButtonProps } from '@motion/ui/base'

import { ConnectedInvitePlaceholderTeammateButton } from '~/areas/project-management/components/invite-placeholder-teammate-button'
import { ConnectedUserBadge } from '~/global/components/badges'
import { useController } from 'react-hook-form'

import { AssigneeDropdown } from '../../../components'
import { useTemplateTaskForm } from '../hooks'

export type FormAssigneeDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
> & {
  iconOnly?: boolean
}

export const FormAssigneeDropdown = ({
  iconOnly = false,
  ...buttonProps
}: FormAssigneeDropdownProps) => {
  const { form, options, disabledFields } = useTemplateTaskForm()

  const { control, watch } = form
  const { members, workspaces } = options

  const { field } = useController({
    name: 'assigneeId',
    control,
  })

  const selectedWorkspaceId = watch('workspaceId')
  const selectedWorkspace = workspaces.find((w) => w.id === selectedWorkspaceId)

  const selectedAssigneeId = watch('assigneeId')
  const selectedAssignee = members.find((m) => m.user.id === selectedAssigneeId)

  const isDisabled =
    selectedWorkspace?.isPersonalWorkspace || disabledFields.has('assigneeId')

  return (
    <div className='flex gap-1 items-center'>
      <div className='flex-1'>
        <AssigneeDropdown
          onChange={(userId) => {
            field.onChange(userId)
          }}
          selectedUserId={selectedAssignee?.userId ?? null}
          workspaceId={selectedWorkspaceId}
        >
          <FieldButton
            {...buttonProps}
            iconOnly={iconOnly}
            disabled={isDisabled}
          >
            <ConnectedUserBadge userId={selectedAssignee?.user.id ?? null} />
            {!iconOnly && (
              <div className='inline-grid'>
                <div className='truncate'>
                  {selectedAssignee?.user.name ?? 'Unassigned'}
                </div>
              </div>
            )}
          </FieldButton>
        </AssigneeDropdown>
      </div>
      <ConnectedInvitePlaceholderTeammateButton
        userId={selectedAssignee?.user.id}
      />
    </div>
  )
}
