import { createLookup } from '@motion/utils/object'

import { type HeaderContext } from '@tanstack/react-table'
import { type ReactNode } from 'react'

import { ColumnHeaderCell, NameHeader } from './components'

import { type ProjectFieldId, type TaskFieldId } from '../../fields'
import { type GroupedNode } from '../../grouping'
import { type TreeListRowValueType } from '../types'

export type CellRenderFn = (
  info: HeaderContext<GroupedNode<TreeListRowValueType>, unknown>
) => ReactNode

type FieldId = TaskFieldId | ProjectFieldId

export const getHeaderColumn = createLookup<
  Record<Extract<FieldId, 'name'>, CellRenderFn> & {
    default: CellRenderFn
  }
>({
  name: (info) => <NameHeader info={info} />,
  default: (info) => {
    return (
      <ColumnHeaderCell>
        {info.column.columnDef.meta?.name ?? 'unknown'}
      </ColumnHeaderCell>
    )
  },
})
