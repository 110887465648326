import { cssVar } from '@motion/theme/dom'
import { type ScheduledStatusSchema } from '@motion/zod/client'

export function labelOfScheduledStatus(
  name: ScheduledStatusSchema | 'UNKNOWN'
) {
  switch (name) {
    case 'ON_TRACK':
      return 'On track'
    case 'UNFIT_SCHEDULABLE':
      return 'Scheduled past deadline'
    case 'PAST_DUE':
      return 'Past deadline'
    case 'UNFIT_PAST_DUE':
      return 'Unfit past due'
    default:
      return 'Not scheduled'
  }
}

export function colorOfScheduledStatus(
  name: ScheduledStatusSchema | 'UNKNOWN'
) {
  switch (name) {
    case 'ON_TRACK':
      return cssVar('palette-green-border-strong')
    case 'PAST_DUE':
      return cssVar('palette-tangerine-border-strong')
    case 'UNFIT_SCHEDULABLE':
      return cssVar('palette-yellow-border-strong')
    case 'UNFIT_PAST_DUE':
      return cssVar('palette-red-border-strong')
    default:
      return cssVar('palette-gray-border-strong')
  }
}
