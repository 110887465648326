import { type VariableDefinitionSchema } from '@motion/zod/client'

export function isTextVariable(
  field: VariableDefinitionSchema
): field is Omit<VariableDefinitionSchema, 'type'> & { type: 'text' } {
  return field.type === 'text'
}

export function isRoleVariable(
  field: VariableDefinitionSchema
): field is Omit<VariableDefinitionSchema, 'type'> & { type: 'person' } {
  return field.type === 'person'
}
