import { DesktopComputerSolid } from '@motion/icons'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'

import { useHasSeenCta } from '~/global/hooks'
import { useUpdateMyCtaSettings } from '~/global/rpc/v2'
import { useCallback, useEffect } from 'react'

import {
  Paragraph,
  PrimaryButton,
  SubParagraph,
  TextButton,
} from '../../Common'

type DownloadDesktopPopupProps = {
  open?: string
  setOpen?: (open: boolean) => void
}
interface DownloadDesktopPopupContentProps {
  onDismiss?: () => void
}

export const DownloadDesktopPopup = ({
  open,
  setOpen = () => {},
}: DownloadDesktopPopupProps) => {
  const { isElectron } = getWindowData()
  const { mutate: updateCtaSettings } = useUpdateMyCtaSettings()

  const hasSeenCta = useHasSeenCta()
  const showCta = hasSeenCta(`DISMISSED_DESKTOP_APP_DOWNLOAD_PROMPT`)

  const updateSettings = useCallback(() => {
    void updateCtaSettings({
      ctaSettings: {
        DISMISSED_DESKTOP_APP_DOWNLOAD_PROMPT: true,
      },
    })
    setOpen(false)
  }, [updateCtaSettings, setOpen])

  useEffect(
    function checkShouldShowDownloadDesktop() {
      if (!showCta.loaded) return
      if (showCta.seen) return

      if (isElectron) {
        recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_AUTO_DISMISSED')
        updateSettings()
        return
      }

      setOpen(true)
    },
    [updateSettings, isElectron, setOpen, showCta.loaded, showCta.seen]
  )

  if (!open) return null
  return (
    <DownloadDesktopPopupContent
      onDismiss={() => {
        updateSettings()
      }}
    />
  )
}

const DownloadDesktopPopupContent = ({
  onDismiss = () => {},
}: DownloadDesktopPopupContentProps) => {
  useEffect(() => {
    recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_SHOWN')
  }, [])

  return (
    <div className='dark:bg-dark-1000 bg-light-100 dark:border-dark-900 border-light-500 flex w-[323px] flex-col gap-4 rounded-lg border p-4 drop-shadow'>
      <div className='bg-light-300 dark:bg-dark-900 h-9 w-9 rounded-lg p-2'>
        <DesktopComputerSolid className='dark:text-dark-300 text-light-1100 text-base h-5 w-5' />
      </div>
      <div className='flex flex-col gap-2'>
        <Paragraph className='font-semibold'>
          Download our desktop app
        </Paragraph>
        <SubParagraph className='text-base'>
          Manage your calendar, projects and tasks without needing to open the
          browser.
        </SubParagraph>
      </div>
      <div className='flex w-full gap-2'>
        <PrimaryButton
          className='min-w-[max-content]'
          data-testid='desktop-download-cta'
          onClick={() => {
            recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_CLICKED', {
              view: 'download_popup',
            })
            onDismiss()
            window.open('https://www.usemotion.com/desktop-app', '_blank')
          }}
        >
          Download desktop app
        </PrimaryButton>
        <TextButton
          className='w-full'
          data-testid='desktop-download-close'
          onClick={() => {
            recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_DISMISSED')
            onDismiss()
          }}
        >
          Not now
        </TextButton>
      </div>
    </div>
  )
}
