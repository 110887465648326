import { useSharedState } from '@motion/react-core/shared-state'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { useHasTreatment } from '@motion/web-common/flags'

import { useCachedItem } from '~/global/cache'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { useSelectView } from './use-select-view'

import { usePageData } from '../../routes/hooks'
import { ViewStateKey } from '../../view-state'
import { getDefaultView } from '../defaults'

export const useSelectedView = () => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const [viewState] = useSharedState(ViewStateKey)
  const route = usePageData()
  const routingV4 = useHasTreatment('pm-view-based-routing')

  const update = useSelectView()

  const viewId = routingV4 ? route.lock.viewId : viewState.viewId
  const cachedView = useCachedItem('views', viewId)

  const currentView = useMemo(() => {
    if (cachedView != null) return cachedView

    const defaultView = getDefaultView(route.page, {
      isFlowProject:
        route.page === 'project' &&
        route.lock.projectId != null &&
        isFlowProject(ctx.projects.byId[route.lock.projectId]),
    })
    return defaultView
  }, [cachedView, ctx.projects.byId, route.lock.projectId, route.page])

  return [currentView, update] as const
}
