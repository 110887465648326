import type { RecursiveFolderItemSchema } from '@motion/zod/client'

export function getAllIdsOfTypeInFolder(
  children: RecursiveFolderItemSchema[],
  targetFolderId: string,
  itemType: RecursiveFolderItemSchema['itemType'],
  matchedParent: boolean = false
): string[] {
  const matchedIds: string[] = []

  for (const child of children) {
    const matched = matchedParent || child.itemId === targetFolderId
    if (child.itemType === 'FOLDER') {
      const nestedMatchedIds = getAllIdsOfTypeInFolder(
        child.items,
        targetFolderId,
        itemType,
        matched
      )
      matchedIds.push(...nestedMatchedIds)
    }
    if (matched && child.itemType === itemType) {
      matchedIds.push(child.itemId)
    }
  }

  return matchedIds
}
