import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import {
  type CustomFieldFilters,
  type FilterTarget,
} from '@motion/ui-logic/pm/data'

import { useFieldFilter } from './filter-context'

export const useCustomFieldFilter = <
  R extends AllAvailableCustomFieldSchema,
  T extends keyof CustomFieldFilters = R['type'],
>(
  target: FilterTarget,
  customField: R
) => {
  const filterKey = `${customField.type as T}/${customField.name}` as const
  return useFieldFilter(target, customField.type as T, filterKey)
}
