import { formatMonthDay } from '@motion/ui-logic'

/**
 * Format a timestamp to a month and day string (e.g. 'Jan 1')
 * @param timestamp
 * @param keepTime - If false, ignore the time part of the `timestamp`
 */
export function formatActivityMonthDay(
  timestamp: string | null,
  keepTime?: boolean
) {
  const parsed = keepTime ? timestamp : timestamp?.split('T')[0]
  return parsed ? formatMonthDay(parsed) : 'None'
}
