import { DotsVerticalSolid } from '@motion/icons'
import { type EmailAccount } from '@motion/rpc/types'
import {
  CompanyLogo,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
  UserAvatar,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { type CalendarSchema } from '@motion/zod/client'

import { useCalendarsByEmailAccountId } from '~/areas/calendar/hooks'
import { useUpdatePrimaryCalendar } from '~/global/rpc/v2'
import { useCallback, useState } from 'react'

import { Events } from '../../../../analyticsEvents'
import { useDeleteAccount } from '../../../../hooks'
import { selectMainEmailAccountId } from '../../../../state/email-accounts/email-accounts-slice'
import {
  fetchAllEmailAccounts,
  setMainCalendar,
} from '../../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { DropdownMenu, Paragraph, TextButton } from '../../../Common'
import { LabelTag } from '../../../Common/LabelTag/LabelTag'

type Props = {
  emailAccount: EmailAccount
}

export const EmailAccountRow = (props: Props) => {
  const { emailAccount } = props
  const dispatch = useAppDispatch()

  const user = useAuthenticatedUser()
  const mainEmailAccountId = useAppSelector(selectMainEmailAccountId)
  const { mutateAsync: updatePrimaryCalendar, isLoading } =
    useUpdatePrimaryCalendar()
  const calendars = useCalendarsByEmailAccountId(emailAccount?.id ?? 'none')

  const primaryCalendar = calendars.find((c) => c.isPrimary)

  const [markAsMainErrorAccount, setMarkAsMainErrorAccount] = useState<
    string | null
  >(null)

  const markMainCalendar = useCallback(
    async (emailAccount: EmailAccount) => {
      recordAnalyticsEvent('CALENDAR_MARK_MAIN_CLICK')

      const res = await dispatch(setMainCalendar(emailAccount.id)).unwrap()
      if ('assigned' in res && !res.assigned) {
        setMarkAsMainErrorAccount(emailAccount.email)
      }
    },
    [dispatch]
  )

  const removeEmailAccount = useDeleteAccount({
    event: Events.CALENDAR_REMOVE_ACCOUNT_CLICK,
  })

  const onChangePrimaryCalendar = async (calendar: CalendarSchema) => {
    // Update an iCal calendar to be the primary calendar
    try {
      recordAnalyticsEvent('CALENDAR_UPDATE_PRIMARY', {
        view: 'calendar_settings',
      })

      await updatePrimaryCalendar({
        id: calendar.id,
      })

      await dispatch(fetchAllEmailAccounts())
    } catch (e) {}
  }

  return (
    <div key={`row-${emailAccount.email}`} className='flex justify-between p-4'>
      <div className='flex items-center gap-2'>
        <UserAvatar
          id={user.uid}
          name={user.displayName}
          size={24}
          // Only show the avatar if this is the main email account
          profileUrl={
            emailAccount.id === mainEmailAccountId ? user.photoURL : undefined
          }
          bottomRight={<CompanyLogo provider={emailAccount.providerType} />}
        />
        <Paragraph className='max-w-full gap-1 truncate'>
          <span className='text-inherit dark:text-inherit'>
            {emailAccount.email}
          </span>
          {markAsMainErrorAccount === emailAccount.email && (
            <span className='text-alert-400 dark:text-alert-400'>
              &nbsp;Error: This is already the main calendar for an existing
              account. Please contact support.
            </span>
          )}
        </Paragraph>
      </div>
      <div className='flex items-center gap-2'>
        {emailAccount.providerType === 'APPLE' && (
          <div className='flex items-center gap-3'>
            <p className='text-semantic-neutral-text-subtle whitespace-nowrap text-xs'>
              Create events/tasks on:
            </p>
            <PopoverTrigger
              placement='bottom-start'
              renderPopover={({ close }) => (
                <SearchableList
                  items={calendars}
                  computeKey={(item) => item.id}
                  computeSearchValue={(item) => item.title}
                  computeSelected={(item) => !!item.isPrimary}
                  computeDisabled={(item) => !!item.isPrimary}
                  onSelect={async (item) => {
                    await onChangePrimaryCalendar(item)
                    close()
                  }}
                  renderItem={(item) => item.title}
                />
              )}
            >
              <PopoverButton disabled={isLoading}>
                {primaryCalendar ? primaryCalendar?.title : 'Select a calendar'}
              </PopoverButton>
            </PopoverTrigger>
          </div>
        )}
        {emailAccount.id === mainEmailAccountId && (
          <LabelTag muted text='Main Calendar' />
        )}
        {emailAccount.id !== mainEmailAccountId && (
          <DropdownMenu
            trigger={<TextButton icon={DotsVerticalSolid} />}
            options={[
              {
                label: 'Mark as main calendar',
                onSelect: () => markMainCalendar(emailAccount),
              },
              {
                className: 'text-alert-400 dark:text-alert-400',
                label: 'Remove account',
                onSelect: () => removeEmailAccount(emailAccount),
              },
            ]}
          />
        )}
      </div>
    </div>
  )
}
