import { CalendarSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { formatMonthDay, getQuickActionsForDeadline } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { FieldButton } from '../components'
import { BulkDateDropdown } from '../components/dropdowns/bulk-date-dropdown'
import { useBulkOpsField } from '../contexts'
import { useBulkFieldValidation } from '../hooks'

export function DueDateField() {
  const [startDate] = useBulkOpsField('startDate')
  const [dueDate, setDueDate] = useBulkOpsField('dueDate')
  const [priority] = useBulkOpsField('priorityLevel')

  const active = dueDate !== undefined

  const isValidDateOption = (date: DateTime) =>
    date >= DateTime.now().startOf('day') &&
    (!startDate || DateTime.fromISO(startDate) <= date)

  // The default date should be the start date if it exists, otherwise it should be the current date
  const defaultDate = (startDate ? parseDate(startDate) : DateTime.now()).endOf(
    'day'
  )

  const { isValid: canUpdateDueDate, errMsg } = useBulkFieldValidation([
    () => ({
      isValid: priority !== 'ASAP',
      errMsg: "Can't update the start date of tasks with ASAP priority",
    }),
  ])

  return (
    <Tooltip content={errMsg}>
      <BulkDateDropdown
        defaultDate={defaultDate}
        getQuickActions={getQuickActionsForDeadline}
        selectedDate={dueDate}
        onChange={setDueDate}
        isValidDateOption={isValidDateOption}
        showTimeOptions
      >
        <FieldButton
          active={active}
          disabled={!canUpdateDueDate}
          analyticsName='due-date'
          onRemove={() => setDueDate(undefined)}
        >
          <CalendarSolid />
          {active ? getDeadlineLabel(dueDate) : 'Deadline'}
        </FieldButton>
      </BulkDateDropdown>
    </Tooltip>
  )
}

function getDeadlineLabel(dueDate: string | null | undefined) {
  if (dueDate == null) return 'Deadline: None'

  return formatMonthDay(dueDate)
}
