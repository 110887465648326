import { useGetTeamPrices } from '~/global/rpc/team'

export const AnnualSavings = () => {
  const { data: teamPrices } = useGetTeamPrices()

  if (!teamPrices) return null

  return (
    <div className='bg-success-500 flex items-center justify-center rounded-full px-2.5 py-1'>
      <p className='text-dark-100 m-0 p-0 text-xs font-semibold'>
        Save {teamPrices.annualSavingsPercentInteger}%
      </p>
    </div>
  )
}
