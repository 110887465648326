export const flowTemplateUrlSearchParams = [
  'forDuplicate',
  'forWorkspace',
  'fromPresetId',
  'template',
  'templateId',
] as const

export type FlowTemplateUrlSearchParams = {
  [K in (typeof flowTemplateUrlSearchParams)[number]]?: string
} & { template?: 'new' | 'create' | 'edit'; pathName?: string }

export type FlowTemplateModalUrlProps = {
  workspaceId?: string
  templateId?: string
}

export const clearFlowTemplateSearchParams = (
  searchParams: URLSearchParams
) => {
  flowTemplateUrlSearchParams.forEach((param) => searchParams.delete(param))
  return searchParams
}
