import { isGhostTask } from '@motion/ui-logic/pm/project'

import { TaskStatusDropdown } from '~/areas/tasks/components'
import { useTaskStatusData } from '~/areas/tasks/hooks'
import { ConnectedStatusBadge } from '~/global/components/badges'

import { RowButton } from './common'
import { type WithTaskProps } from './types'

export const TaskStatus = ({ task }: WithTaskProps) => {
  const { statusTaskVariant, onChange, disabled, shouldExcludeResolved } =
    useTaskStatusData(task)

  const isGhost = isGhostTask(task)

  return (
    <TaskStatusDropdown
      isUnvisitedStage={task.isUnvisitedStage}
      selectedStatusId={task.statusId}
      onChange={onChange}
      workspaceId={task.workspaceId}
      taskVariant={statusTaskVariant}
      excludeResolved={shouldExcludeResolved}
    >
      <RowButton disabled={disabled}>
        <ConnectedStatusBadge
          id={task.statusId}
          size='xsmall'
          iconVariant={isGhost ? 'isUnvisitedStage' : undefined}
          taskVariant={statusTaskVariant}
        />
      </RowButton>
    </TaskStatusDropdown>
  )
}
