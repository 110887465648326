import { MenuOutline } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { useGlobalSidebarState } from '~/areas/sidebar/hooks'

export const SidebarOpenButton = () => {
  const { sidebarState, setSidebarState } = useGlobalSidebarState()

  return (
    <div
      // Animate the width of the container so the transition to closed sidebar is smoother.
      style={{ width: sidebarState?.open ? 0 : 28, height: 24 }}
      className='overflow-hidden transition-[width] shrink-0'
    >
      {!sidebarState?.open && (
        <Button
          sentiment='neutral'
          variant='muted'
          onClick={() =>
            setSidebarState({
              open: true,
              width: sidebarState?.width ?? 240,
            })
          }
          iconOnly
          size='small'
        >
          <MenuOutline />
        </Button>
      )}
    </div>
  )
}
