import { PlayTriangleSolid } from '@motion/icons'
import { formatMonthDay } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'

import { ConnectedStageConnector } from './stage-connector'
import {
  DeprecatedStageConnector,
  Dot,
  StageDate,
  TodayMarkerContainer,
  TodayText,
} from './styled'
import { type StageGroup } from './utils'

type TodayMarkerProps = {
  stageGroup?: StageGroup
}
export function TodayMarker({ stageGroup }: TodayMarkerProps) {
  const today = new Date()

  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')

  if (!hasBetterResizeStages) {
    return (
      <>
        <TodayMarkerContainer>
          <TodayText>TODAY</TodayText>
          <Dot color='red' />
          <StageDate className='relative left-[-8px]'>
            {formatMonthDay(today, { numeric: true })}
          </StageDate>
        </TodayMarkerContainer>
        <DeprecatedStageConnector half />
      </>
    )
  }
  return (
    <ConnectedStageConnector type='secondHalf' groupedStages={stageGroup}>
      <TodayMarkerContainer className='absolute'>
        <TodayText className='absolute top-[-4px]'>TODAY</TodayText>

        <PlayTriangleSolid className='text-semantic-error-bg-strong-default rotate-90 size-[10px] top-[5px] absolute' />

        <StageDate className='absolute left-[-8px] bottom-[2px]'>
          {formatMonthDay(today, { numeric: true })}
        </StageDate>
      </TodayMarkerContainer>
    </ConnectedStageConnector>
  )
}
