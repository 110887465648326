import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'

import { useProjectTasks } from '~/areas/task-project/hooks'
import { getAvailableBlockerOptions } from '~/areas/tasks/utils'
import { useI18N } from '~/global/contexts'
import { useController } from 'react-hook-form'
import { type ControllerRenderProps, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationBlockingTasksField = ({
  projectId,
  workspaceId,
  stageDefinitionId,
}: {
  projectId: string
  workspaceId: string
  stageDefinitionId?: string | null
}) => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'blockedByTaskIds',
    control: formContext.control,
  })

  return (
    <InnerInlineTaskCreationBlockingTasksField
      field={field}
      projectId={projectId}
      workspaceId={workspaceId}
      stageDefinitionId={stageDefinitionId}
    />
  )
}

const InnerInlineTaskCreationBlockingTasksField = ({
  field,
  projectId,
  workspaceId,
  stageDefinitionId,
}: {
  field: ControllerRenderProps<InlineTaskCreationFields, 'blockedByTaskIds'>
  projectId: string
  workspaceId: string
  stageDefinitionId?: string | null
}) => {
  const { pluralize } = useI18N()
  const { data: projectTasks } = useProjectTasks({
    projectId,
    workspaceId,
  })

  if (!projectTasks) {
    return null
  }

  const availableBlockerOptions = getAvailableBlockerOptions(projectTasks, [], {
    stageDefinitionId,
  })

  return (
    <PopoverTrigger
      renderPopover={() => (
        <SearchableList
          searchable
          inputProps={{ placeholder: 'Choose blockers...' }}
          computeSearchValue={(item) => item.name}
          items={availableBlockerOptions}
          onSelect={(items) => {
            field.onChange(items.map((item) => item.id))
          }}
          renderItem={(task) => (
            <div className='max-w-xs truncate'>{task.name}</div>
          )}
          computeKey={(task) => task.id}
          computeSelected={(task) =>
            field.value?.some((id) => id === task.id) ?? false
          }
          itemType='checkbox'
        />
      )}
    >
      <Button sentiment='neutral' variant='outlined' size='xsmall'>
        <span className='truncate text-[10px] font-normal'>
          {pluralize(
            field.value?.length ?? 0,
            'Blocked by 1 task',
            'Blocked by {{count}} tasks'
          )}
        </span>
      </Button>
    </PopoverTrigger>
  )
}
