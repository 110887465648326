import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useState } from 'react'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'add-flow-template-text-variable-modal': PromptCallbacks<{
      name: string
    }>
  }
}

type AddFlowTemplateTextVariableModalProps =
  ModalTriggerComponentProps<'add-flow-template-text-variable-modal'>

export const AddFlowTemplateTextVariableModal = ({
  close,
}: AddFlowTemplateTextVariableModalProps) => {
  const [rawTextVariableName, setRawTextVariableName] = useState('')

  const name = rawTextVariableName.trim()
  const disabled = name === ''

  function handleSubmit() {
    close({ name })
  }

  return (
    <FormModal
      title='Add text variable'
      visible
      onClose={close}
      submitAction={{
        onAction: handleSubmit,
        disabled,
        text: 'Add text variable',
      }}
    >
      <div className='flex flex-row items-center gap-3'>
        <TextField
          value={rawTextVariableName}
          onChange={setRawTextVariableName}
          autoFocus
          fullWidth
          placeholder='Enter text'
        />
      </div>
    </FormModal>
  )
}
