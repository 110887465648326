import { type DayVerbose } from '@motion/rpc-types'
import { getDatesBetween } from '@motion/ui-logic'
import { type Group } from '@motion/utils/array'
import { useSetting } from '@motion/web-common/settings'

import { useWeekOfSelectedDay } from '~/areas/agenda/hooks/use-week-of-selected-day'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { useAgendaEntities } from './use-agenda-entities'

export function useAgendaTasksByDate() {
  const { data, isLoading } = useAgendaEntities()
  const weekOfSelectedDay = useWeekOfSelectedDay()
  const [schedules] = useSetting('schedules', {})

  const dates = getDatesBetween(
    DateTime.fromISO(weekOfSelectedDay.start),
    DateTime.fromISO(weekOfSelectedDay.end)
  )

  const workDays = dates.filter((date) => {
    const day = date.weekdayLong as DayVerbose
    return (schedules?.work?.schedule?.[day]?.length ?? 0) > 0
  })

  return useMemo(() => {
    const scheduledEntities = data?.scheduledEntities ?? []

    const entities = scheduledEntities?.filter(
      (entity) => entity.type === 'TASK' || entity.type === 'CHUNK'
    )

    const buckets = new Map<string, Group<ScheduledTaskWithRelation, string>>()
    for (const date of workDays) {
      buckets.set(date.toISODate(), { key: date.toISODate(), items: [] })
    }

    for (const entity of entities) {
      const key = DateTime.fromISO(entity.schedule.start).toISODate()

      let bucket = buckets.get(key)

      if (!bucket) {
        bucket = { key, items: [] }
        buckets.set(key, bucket)
      }

      bucket?.items.push(entity)
    }

    return {
      tasksByDate: Array.from(buckets.values()),
      isLoading,
    }
  }, [data?.scheduledEntities, isLoading, workDays])
}
