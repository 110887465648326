import { FilledChevronDownSolid } from '@motion/icons'
import { classed } from '@motion/theme'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type CollapsableButtonProps = {
  expanded: boolean
  onClick: () => void
  children: ReactNode
  disabled?: boolean
}

export function CollapsableButton(props: CollapsableButtonProps) {
  const { expanded, children, ...rest } = props

  return (
    <StyledButton type='button' {...rest}>
      {children}
      <FilledChevronDownSolid
        className={twMerge(
          'transition-transform text-semantic-neutral-icon-default',
          expanded && 'rotate-180'
        )}
        height={12}
        width={12}
      />
    </StyledButton>
  )
}

const StyledButton = classed('button', {
  base: `
  inline-flex items-center gap-1 px-1
  text-xs text-semantic-neutral-text-default leading-[18px]
  rounded-[4px]

  focus-visible:outline
  focus-visible:outline-1
  focus-visible:outline-offset-1
  focus-visible:outline-button-primary-solid-border-focus

  hover:bg-button-neutral-muted-bg-hover
  focus:bg-button-neutral-muted-bg-default

  active:bg-button-neutral-muted-bg-pressed

  disabled:cursor-not-allowed
  disabled:text-semantic-neutral-text-disabled
  disabled:bg-button-neutral-muted-bg-default
  `,
})
