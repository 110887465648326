import type { RecurringTaskSchema, TaskSchema } from '@motion/rpc-types'
import { canHaveBlockers } from '@motion/ui-logic/pm/task'

import { useLookup } from '~/global/cache'
import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useBlockerTasksFn = () => {
  const readTask = useReadTaskFn()
  const lookup = useLookup()

  return useCallback(
    async (taskOrId: TaskSchema | TaskSchema['id'] | RecurringTaskSchema) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (!canHaveBlockers(task)) {
        return {
          blockedBy: [],
          blocking: [],
        }
      }

      return {
        blockedBy: task.blockedByTaskIds
          .map((id) => lookup('tasks', id))
          .filter(Boolean),
        blocking: task.blockingTaskIds
          .map((id) => lookup('tasks', id))
          .filter(Boolean),
      }
    },
    [lookup, readTask]
  )
}
