import { PopoverTrigger } from '@motion/ui/base'

import type { ReactNode } from 'react'

export const RelativeDateDropdown = ({ children }: { children: ReactNode }) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <RelativeDateDropdownContent close={close} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

const RelativeDateDropdownContent = ({ close }: { close: () => void }) => {
  return <div>RelativeDateDropdownContent</div>
}
