import { showToast } from '@motion/ui/base'
import { formatToReadableWeekDayMonth, templateStr } from '@motion/ui-logic'
import { isFlowProject } from '@motion/ui-logic/pm/project'
import { useHasTreatment } from '@motion/web-common/flags'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useWorkspaceFns } from '~/global/hooks'
import { useShiftProject as useShiftProjectCall } from '~/global/rpc/v2'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

type ShiftProjectModalProps = {
  projectId: string
  numDays: number
}

/**
 * @returns a function that prompts the user to shift the tasks and stages of a project by a specified number of days
 */
export const useShiftProjectDates = () => {
  const modalApi = useModalApi()
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const { mutateAsync: shiftProjectDates, isLoading } = useShiftProjectCall()

  return useCallback(
    async ({ projectId, numDays }: ShiftProjectModalProps) => {
      const project = getWorkspaceProjectById(projectId)
      if (project == null) {
        throw new Error('Project not found')
      }

      const newStartDate = project.startDate
        ? DateTime.fromISO(project.startDate).plus({
            days: numDays,
          })
        : null

      const projectDueDate = project.dueDate
        ? DateTime.fromISO(project.dueDate)
        : null
      const newDeadline =
        projectDueDate != null
          ? projectDueDate.plus({
              days: numDays,
            })
          : projectDueDate

      // If the shift is 0 days, don't show the modal
      if (numDays === 0) {
        return true
      }

      if (!hasBetterResizeStages) {
        const response = await modalApi.prompt('confirm', {
          analytics: {
            name: 'shift-project-dates',
          },
          title: templateStr(
            'Are you sure you want to change the project start date and deadline? ({{start}} - {{end}})',
            {
              start: newStartDate
                ? formatToReadableWeekDayMonth(newStartDate)
                : 'None',
              end: newDeadline
                ? formatToReadableWeekDayMonth(newDeadline)
                : 'None',
            }
          ),
          description: (
            <div className='flex flex-col gap-4'>
              {templateStr(
                '{{items}} start dates/deadlines inside this project will also be updated.',
                {
                  items: isFlowProject(project) ? 'Stage and task' : 'Task',
                }
              )}
              <p>Completed and fixed time tasks won’t be updated.</p>
            </div>
          ),
          confirmButtonText: 'Confirm',
          closeButtonText: 'Cancel',
          loading: isLoading,
        })

        if (response === ModalDismissed) {
          return false
        }
      }

      try {
        await shiftProjectDates({ projectId, numDays: Math.round(numDays) })
      } catch {
        showToast('error', 'Moving project failed, please try again')
        return false
      }

      showToast('success', 'Project moved successfully')
      return true
    },
    [
      getWorkspaceProjectById,
      hasBetterResizeStages,
      modalApi,
      isLoading,
      shiftProjectDates,
    ]
  )
}
