import { type ScheduledEntitySchema } from '@motion/zod/client'

import { type LookupFn } from '~/global/cache'

import { type ScheduledEntityWithRelations } from './types'

import {
  getOrCreateLookupProxy,
  isProxied,
  type ProxyDef,
} from '../create-proxy'
import { createTaskProxy } from '../create-task-proxy'

export const SCHEDULED_ENTITY_WITH_RELATIONS = {
  task: {
    accessor: (scheduledEntity) => scheduledEntity.id,
    get: (scheduledEntity, _prop, lookup) => {
      const task = lookup('tasks', scheduledEntity.id)
      if (!task) return null
      return createTaskProxy(task, lookup)
    },
  },
  event: {
    key: 'calendarEvents',
    accessor: (scheduledEntity) => scheduledEntity.id,
  },
} satisfies Record<string | symbol, ProxyDef<ScheduledEntityWithRelations>>

export function createScheduledEntityProxy(
  scheduledEntity: ScheduledEntitySchema,
  lookup: LookupFn
): ScheduledEntityWithRelations {
  if (isProxied(scheduledEntity)) return scheduledEntity
  return getOrCreateLookupProxy(
    scheduledEntity,
    SCHEDULED_ENTITY_WITH_RELATIONS,
    lookup
  )
}
