import { AutoScheduleToggle } from '@motion/ui/base'

import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationAutoscheduleToggle = () => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { field } = useController({
    name: 'isAutoScheduled',
    control: formContext.control,
  })

  return (
    <AutoScheduleToggle
      checked={field.value}
      onChange={field.onChange}
      labelHidden
    />
  )
}
