import { daysBetweenDates } from '@motion/utils/dates'

import { DateTime } from 'luxon'

type GetStageDistanceFromProjectStartArgs = {
  projectStart?: string
  // Start or end date of the stage
  stageDate: string
  dayPx: number
}

export function getStageDistanceFromProjectStart({
  projectStart,
  stageDate,
  dayPx,
}: GetStageDistanceFromProjectStartArgs) {
  if (!projectStart) return 0
  const distance = Math.abs(
    daysBetweenDates(
      DateTime.fromISO(projectStart),
      DateTime.fromISO(stageDate)
    )
  )

  if (distance && distance > 0) {
    return distance * dayPx
  }

  return 0
}
