import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

export type TaskDeadlineStatus = 'due-today' | 'due-tomorrow'

export function useTaskDeadlineStatus(
  scheduledTask: ScheduledTaskWithRelation
): TaskDeadlineStatus | null {
  return useMemo(() => {
    const task = scheduledTask.task

    if (
      task == null ||
      task?.deadlineType === 'NONE' ||
      task?.dueDate == null
    ) {
      return null
    }

    const deadline = DateTime.fromISO(task.dueDate).startOf('day')
    const today = DateTime.now().startOf('day')

    if (deadline.equals(today)) {
      return 'due-today'
    }

    if (deadline.equals(today.plus({ days: 1 }))) {
      return 'due-tomorrow'
    }

    return null
  }, [scheduledTask])
}
