import {
  type AppDataContext,
  createQuery,
  toDataFilter,
} from '@motion/ui-logic/pm/data'
import {
  type ChartQueryAggregateSchema,
  ChartQueryAggregateSumFieldSchema,
  type ChartQueryRequestSchema,
  type DashboardViewAggregate,
  type DashboardViewCellSchema,
  type DashboardViewChartSchema,
  type DashboardViewGroupBySchema,
  type GroupableFieldSchema,
  type ViewDefinitionFiltersSchema,
} from '@motion/zod/client'

import { fromViewDefinitionFiltersToFilterState } from '~/areas/project-management/pages/pm-v3/views/utils'

// TODO: Add tests for these functions
export function mapCellSchemaToChartQuery(
  ctx: AppDataContext,
  cell: DashboardViewCellSchema
): ChartQueryRequestSchema {
  const { chart } = cell

  const filters = mapFilters(ctx, chart.filters, chart.item)

  return {
    $version: 1,
    source: chart.item as 'tasks',
    aggregate: mapAggregate(chart.aggregate),
    groupBy: mapGroupBy('groupBy' in chart ? chart.groupBy : []),
    filters: filters,
  }
}

function mapAggregate(
  aggregate: DashboardViewAggregate
): ChartQueryAggregateSchema {
  switch (aggregate.type) {
    case 'count':
      return { type: 'count' }

    case 'sum':
      // Validate that the field is one of the allowed sum fields
      const sumField = ChartQueryAggregateSumFieldSchema.find(
        (x) => x === aggregate.field
      )

      if (!sumField) {
        throw new Error(`Invalid sum field: ${aggregate.field}`)
      }

      return {
        type: 'sum',
        field: sumField,
      }
  }
}

type HasByField<T extends GroupableFieldSchema> = T extends { by: any }
  ? T
  : never

type WithoutByField<T extends GroupableFieldSchema> = T extends { by: any }
  ? never
  : T

function mapGroupBy(
  groupBy: DashboardViewGroupBySchema[]
): GroupableFieldSchema[] {
  return groupBy.map((groupBy) => {
    if ('by' in groupBy) {
      // It's a date grouping
      return {
        field: groupBy.field,
        by: groupBy.by,
      } as HasByField<GroupableFieldSchema>
    }

    // It's a primitive grouping
    return {
      field: groupBy.field,
    } as WithoutByField<GroupableFieldSchema>
  })
}

function mapFilters(
  ctx: AppDataContext,
  filters: ViewDefinitionFiltersSchema,
  type: DashboardViewChartSchema['item']
) {
  const filterState = fromViewDefinitionFiltersToFilterState('tasks', filters)
  const dataFilter = toDataFilter(filterState)

  const query = createQuery(ctx, dataFilter)

  return query?.filters ?? []
}
