import { useModalStatus } from '@motion/web-common/modals'

import { DoTaskASAPModal } from './DoTaskASAPModal'

export type DoTaskASAPCallback = {
  isChunkedTask: boolean
  minimumDuration?: number | null
}

export const ConnectedDoTaskASAPModal = () => {
  const status = useModalStatus('do-task-asap')

  if (!status.visible) {
    return null
  }

  return (
    <DoTaskASAPModal
      task={status.props.task}
      onClose={status.close}
      onConfirm={(isChunkedTask, minimumDuration) =>
        status.close({ isChunkedTask, minimumDuration })
      }
    />
  )
}
