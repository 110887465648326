import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { useDeleteProject as useDeleteProjectCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useProjectAnalytics } from '../use-project-analytics'

export const useDeleteProject = () => {
  const modalApi = useModalApi()
  const { mutateAsync: deleteProject } = useDeleteProjectCall()
  const getProjectAnalyticContext = useProjectAnalytics()

  return useCallback(
    async (
      project: Pick<ProjectSchema, 'id' | 'taskCount' | 'name'>,
      onConfirmed?: () => void
    ) => {
      try {
        const response = await modalApi.prompt('confirm-delete-item', {
          analytics: {
            name: 'delete-project',
          },
          titleValue: 'this project',
          description: templateStr(
            `This project and {{taskCount}} tasks will be deleted permanently.`,
            {
              taskCount: <span className='font-bold'>{project.taskCount}</span>,
            }
          ),
          deleteMessage: project.name,
          deleteButtonLabelValue: 'project',
        })

        if (response === ModalDismissed) return false

        onConfirmed?.()
        // Sucks but let's sleep 200ms to make sure the project modal closes when it's open
        // So we don't temporarily show a "project not found" screen
        await sleep(200)

        recordAnalyticsEvent('PROJECT_MANAGEMENT_DELETE_PROJECT', {
          project_type: getProjectAnalyticContext(project.id).project_type,
        })

        await deleteProject({ projectId: project.id })

        showToast('success', 'Project deleted')

        return true
      } catch (e) {
        Sentry.captureException(e, { tags: { position: 'delete-project' } })

        showToast('error', 'There was an error deleting the project')
      }

      return false
    },
    [deleteProject, getProjectAnalyticContext, modalApi]
  )
}
