import { ProjectColoredIcon } from '@motion/ui/project'

import { useHasWorkspaceFoldersTreatment } from '~/areas/sidebar/hooks'
import { AutoSaveProvider } from '~/areas/task-project/contexts'
import { useProject } from '~/global/hooks'

import {
  CustomFieldsPanelSection,
  ProjectAssigneeField,
  ProjectDeadlineField,
  ProjectFolderField,
  ProjectLabelsField,
  ProjectPriorityField,
  ProjectStartDateField,
  ProjectStatusField,
  ProjectWorkspaceField,
} from '../components/fields'

type FieldsTabProps = {
  projectId: string
  showHeader?: boolean
  onChangeAnyField?: (fieldName: string) => void
}

export function FieldsTab({
  projectId,
  showHeader = true,
  onChangeAnyField,
}: FieldsTabProps) {
  const hasFolders = useHasWorkspaceFoldersTreatment()

  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project not found when rendering FieldsTab')
  }

  return (
    <AutoSaveProvider suppressSaveToast>
      <div className='max-w-[300px] h-full overflow-auto bg-modal-bg rounded-b-lg shadow-lg'>
        {showHeader && (
          <div className='flex flex-row items-center gap-1 w-full px-4 py-2 bg-semantic-neutral-bg-subtle'>
            <ProjectColoredIcon className='h-4 w-4' />
            <span className='font-semibold text-semantic-neutral-text-subtle text-xs'>
              Project fields
            </span>
          </div>
        )}
        <div className='bg-semantic-neutral-surface-overlay-bg-default'>
          <div className='flex flex-col overflow-hidden'>
            <div className='p-3 pt-4 flex flex-col gap-0.5'>
              <ProjectWorkspaceField
                projectId={projectId}
                onChange={(value) => {
                  onChangeAnyField?.('workspaceId')
                }}
              />
              {hasFolders && (
                <ProjectFolderField
                  projectId={projectId}
                  onChange={(value) => {
                    onChangeAnyField?.('folderId')
                  }}
                />
              )}
            </div>
            <div className='pt-3 w-full flex flex-col gap-0.5 px-3 py-2.5'>
              <ProjectAssigneeField
                projectId={projectId}
                onChange={(value) => {
                  onChangeAnyField?.('managerId')
                }}
              />
              <ProjectStatusField
                projectId={projectId}
                onChange={(value) => {
                  onChangeAnyField?.('statusId')
                }}
              />
              <ProjectStartDateField
                projectId={projectId}
                onChange={(value) => {
                  onChangeAnyField?.('startDate')
                }}
              />
              <ProjectDeadlineField
                projectId={projectId}
                onChange={(value) => {
                  onChangeAnyField?.('dueDate')
                }}
              />
              <ProjectPriorityField projectId={projectId} />
            </div>
            <div className='w-full flex flex-col gap-0.5 px-3 py-2.5'>
              <ProjectLabelsField
                projectId={projectId}
                onChange={(value) => {
                  onChangeAnyField?.('labelIds')
                }}
              />
              <CustomFieldsPanelSection
                projectId={projectId}
                onChange={(value) => {
                  onChangeAnyField?.('customFields')
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </AutoSaveProvider>
  )
}
