import { useCallback } from 'react'
import {
  type FieldValues,
  type Path,
  type PathValue,
  type RegisterOptions,
  type UseFormRegister,
} from 'react-hook-form'

export const useRegisterFieldArray = <
  T extends FieldValues,
  N extends Path<T>,
  TType extends PathValue<T, N>,
>(
  name: N,
  register: UseFormRegister<T>,
  options: RegisterOptions<T> = {}
) => {
  const { onChange, ...rest } = register(name, options)
  const stableOnChange = useCallback(
    (s: TType) =>
      onChange({
        target: { value: s, name },
      }),
    [onChange, name]
  )

  return {
    onChange: stableOnChange,
    ...rest,
  }
}
