import { ProgressCircle } from '@motion/ui/base'
import { formatDurationToShort } from '@motion/ui-logic'

export type ProgressSummaryProps = {
  percentageComplete: number
  completedDuration: number
}

export function ProgressSummary({
  percentageComplete,
  completedDuration,
}: ProgressSummaryProps) {
  return (
    <>
      <ProgressCircle value={percentageComplete} labelHidden size='small' />
      <span className='text-semantic-neutral-text-subtle leading-5 truncate'>
        {formatDurationToShort(completedDuration)}
      </span>
    </>
  )
}
