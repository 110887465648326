import { useModalListForNavigation } from '~/global/hooks'
import { useMemo } from 'react'

import {
  getOrderedIdsFromTree,
  type GroupedNode,
  type Tree,
} from '../../grouping'

export function useTreeForTaskNavigation<T extends GroupedNode = GroupedNode>(
  tree: Tree<T>
) {
  const orderedTaskIds = useMemo(() => getOrderedIdsFromTree(tree), [tree])
  useModalListForNavigation({ tasks: orderedTaskIds })
}
