import { useShowProjectedEntities } from '~/global/hooks'
import { DateTime } from 'luxon'

import {
  useFilterScheduledEntitiesGhostTasks,
  useGetScheduledEntitiesWithProxies,
} from '../../rpc'

/*
 * @returns the list of scheduled entities that are happening today from the cache
 */
export const useTodaysScheduledEntities = () => {
  const today = DateTime.now().startOf('day').toISO()
  const tomorrow = DateTime.now().plus({ days: 1 }).endOf('day').toISO()

  const result = useGetScheduledEntitiesWithProxies({
    filters: {
      scheduled: {
        from: today,
        to: tomorrow,
      },
      myCalendarsOnly: true,
      completed: 'exclude',
    },
    include: ['event', 'task'],
    hydrate: ['calendarEvents', 'tasks', 'recurringTasks'],
  })

  const [showProjectedEntities] = useShowProjectedEntities()

  const postProcessedScheduledEntities = useFilterScheduledEntitiesGhostTasks(
    result,
    showProjectedEntities
  )

  return postProcessedScheduledEntities ?? []
}
