import {
  FlowVariableSearchableList,
  SuggestionDropdown,
  type SuggestionDropdownProps,
} from '~/areas/flows/components'

export const ConnectedSuggestionDropdown = (
  props: Omit<SuggestionDropdownProps, 'renderList'>
) => {
  return (
    <SuggestionDropdown
      {...props}
      renderList={({ items, onChange }) => (
        <FlowVariableSearchableList
          items={items}
          onChange={onChange}
          hideAddNew
        />
      )}
    />
  )
}
