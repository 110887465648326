import { BaseModal, Button, LoadingSpinner } from '@motion/ui/base'
import {
  type BillingPrices,
  computeMonthlyPrice,
  INDIVIDUAL_PRICES,
} from '@motion/ui-logic/billing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useExperiment } from '@motion/web-common/flags'
import { isTeamSubscription } from '@motion/web-common/subscriptions'

import { useUtilizedSubscription } from '~/global/rpc/subscriptions'
import { useState } from 'react'

import { discountPriceString } from '../../Account/utils'
import { default as Confetti } from '../components/confetti.svg?url'
import { ExtraDiscount } from '../components/extra-discount'
import { PresentIcon } from '../components/icons'
import { ModalFooter } from '../components/modal-footer'
import { ModalMainWrapper } from '../components/modal-main-wrapper'
import { ModalTitle } from '../components/modal-title'
import { type CancelScreenProps } from '../components/types'
import {
  useApplyCouponAndUpdate,
  useGetCoupon,
  useGetCouponLazy,
} from '../rpc-hooks'

export const Discount = ({
  setNextScreen,
  onNextClick,
  onCloseModal,
  teamPrices,
}: CancelScreenProps & { teamPrices: BillingPrices }) => {
  const [showExtraDiscount, setShowExtraDiscount] = useState(false)

  const coupons = useExperiment('cancellation-interception-discount').payload
  const { data: couponData, isLoading: isLoadingCoupon } = useGetCoupon({
    couponId: coupons.couponId,
  })
  const discount: number | undefined = couponData?.percent_off
  const discountStr = discount?.toFixed()

  const {
    execute: executeGetFallbackCoupon,
    fetchStatus: fallbackCouponStatus,
    data: fallbackCouponData,
  } = useGetCouponLazy()

  const stripeSubscription = useUtilizedSubscription()
  const isAnnual = stripeSubscription?.plan.interval === 'year'
  const isTeam = stripeSubscription && isTeamSubscription(stripeSubscription)

  const monthlyPrice = computeMonthlyPrice(
    isAnnual,
    !!isTeam,
    teamPrices,
    INDIVIDUAL_PRICES
  )

  const { isApplyingCoupon, doApplyCoupon } = useApplyCouponAndUpdate()

  const handleClaimDiscount = async (
    couponId: string,
    subscriptionId: string,
    percent_off: number
  ) => {
    await doApplyCoupon(couponId, subscriptionId)
    setNextScreen('discount_success')
    void recordAnalyticsEvent('CANCELLATION_INTERCEPTION_DISCOUNT_CLAIMED', {
      percent_off,
    })
    onNextClick()
  }

  const handleDeclinedCoupon = async () => {
    if (coupons.fallbackCouponId) {
      const gotCoupon = await executeGetFallbackCoupon({
        couponId: coupons.fallbackCouponId,
      })
      if (gotCoupon) {
        setShowExtraDiscount(true)
        return
      }
    }
    onNextClick()
  }

  if (isLoadingCoupon) {
    return (
      <div className='flex flex-row items-center justify-center w-full h-full gap-3 text-semantic-success-text-default font-semibold'>
        <LoadingSpinner />
        Loading...
      </div>
    )
  } else if (!coupons || !discount) {
    // If we're unable to fetch the coupons, show the cancellation interception page
    onNextClick()
  } else {
    return (
      <>
        <ModalMainWrapper>
          {fallbackCouponData && (
            <BaseModal
              visible={showExtraDiscount}
              onClose={() => setShowExtraDiscount(false)}
            >
              <ExtraDiscount
                handleClaimDiscount={() =>
                  fallbackCouponData.id &&
                  handleClaimDiscount(
                    fallbackCouponData.id,
                    stripeSubscription?.id || '',
                    fallbackCouponData.percent_off
                  )
                }
                onNextClick={onNextClick}
                discount={fallbackCouponData.percent_off}
                couponId={fallbackCouponData.id}
                isAnnual={isAnnual}
                loading={isApplyingCoupon}
                monthlyPrice={monthlyPrice}
              />
            </BaseModal>
          )}
          <ModalTitle
            text={`${discountStr}% Discount on us!`}
            subtext={`We want to make this work. Keep using Motion for ${discountStr}% off!`}
            icon={PresentIcon}
            size='large'
          />
          <div className='bg-semantic-neutral-bg-disabled h-[300px]'>
            <div
              className='flex flex-col gap-6 items-center justify-center h-full w-full py-6 px-4 '
              style={{
                background: `url(${Confetti})`,
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className='flex flex-col gap-1 text-center text-semantic-neutral-text-default z-40'>
                <div className='text-semantic-success-text-default font-semibold'>
                  <span className='text-[32px]'>
                    {discountPriceString(monthlyPrice, discount)}
                  </span>
                  <span className='text-sm'>/month</span>
                </div>
                <span className='line-through	'>${monthlyPrice}/month</span>
                {isAnnual && (
                  <span className='text-xs font-semibold text-semantic-neutral-text-subtle'>
                    (billed annually)
                  </span>
                )}
              </div>
              <div className='flex flex-col gap-2 items-center'>
                <div>
                  <Button
                    onClick={() =>
                      handleClaimDiscount(
                        couponData.id,
                        stripeSubscription?.id || '',
                        discount
                      )
                    }
                    loading={!showExtraDiscount && isApplyingCoupon}
                  >
                    Claim discount
                  </Button>
                </div>
                <div>
                  <Button
                    variant='muted'
                    sentiment='neutral'
                    onClick={handleDeclinedCoupon}
                    loading={fallbackCouponStatus === 'fetching'}
                  >
                    No thanks
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </ModalMainWrapper>
        <ModalFooter
          onCloseModal={onCloseModal}
          onNextClick={handleDeclinedCoupon}
        />
      </>
    )
  }
}
