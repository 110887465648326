import { RefreshSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useRefreshTasks } from '../../hooks/use-refresh-tasks'

export const RefreshTasksButton = () => {
  const [isRefreshingTasks, refreshTasks] = useRefreshTasks()

  const handleRefreshTasksClick = () => {
    recordAnalyticsEvent('CALENDAR_REFRESH_TASKS_CLICK')
    void refreshTasks()
  }

  return (
    <Button
      variant='outlined'
      sentiment='neutral'
      size='small'
      onClick={handleRefreshTasksClick}
      loading={isRefreshingTasks}
    >
      <RefreshSolid />
      <span className='hidden xl:inline'>Refresh all tasks</span>
    </Button>
  )
}
