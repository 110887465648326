import { useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useSetupProjectFormNavigationContext } from '../../hooks/ctx'
import { useSetupProjectForm } from '../../hooks/use-setup-project-form'

export const NameStepContent = () => {
  const {
    form: { control },
  } = useSetupProjectForm()

  const { field } = useController({
    control,
    name: 'name',
    defaultValue: '',
    rules: NAME_FIELD_RULE,
  })

  const { goForward } = useSetupProjectFormNavigationContext()

  useShortcut('Enter', (e) => {
    if (field.value.trim().length > 0) {
      goForward(e)
    }
  })

  return <TextField autoFocus {...field} placeholder='Enter project title' />
}
