import {
  createStateKey,
  type SharedStateSetterValue,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'

import {
  CALENDAR_STATE_DEFAULT_VALUE,
  type CalendarState,
} from '~/areas/calendar/hooks'
import { useCallback } from 'react'

export type AgendaCalendarState = CalendarState & {
  selectedDateManuallyChanged: boolean
}

export const AgendaCalendarsStateKey = createStateKey<AgendaCalendarState>(
  'agendaCalendarState',
  {
    defaultValue: {
      ...CALENDAR_STATE_DEFAULT_VALUE,
      selectedDateManuallyChanged: false,
    },
  }
)

export function useAgendaCalendarState() {
  return useSharedState(AgendaCalendarsStateKey)
}

export function useSendAgendaCalendarState() {
  const setState = useSharedStateSendOnly(AgendaCalendarsStateKey)
  return useCallback(
    (
      changes:
        | Partial<AgendaCalendarState>
        | SharedStateSetterValue<AgendaCalendarState>
    ) => {
      if (typeof changes === 'function') {
        setState(changes)
        return
      }
      setState((prev) => ({ ...prev, ...changes }))
    },
    [setState]
  )
}
