import { API } from '@motion/rpc'

import { useWorkspaceFns } from '~/global/hooks'
import { useTasksV2 } from '~/global/rpc/v2'

/*
 * Given a projectId (and an optional stageId) this hook returns a list of overdue tasks.
 * by querying the tasks by that project/stage from the backend
 */
export const useOverdueTasks = (
  workspaceId: string,
  projectId: string,
  stageDefinitionId?: string
) => {
  const { getWorkspaceCanceledStatus } = useWorkspaceFns()

  return useTasksV2(
    {
      $version: 2,
      filters: [
        {
          type: ['NORMAL'],
          projectIds: { operator: 'in', value: [projectId] },
          stageDefinitionIds: stageDefinitionId
            ? { operator: 'in', value: [stageDefinitionId] }
            : undefined,
          completed: 'exclude',
          scheduledStatus: {
            operator: 'in',
            value: ['PAST_DUE', 'UNFIT_PAST_DUE'],
          },
          statusIds: {
            operator: 'in',
            value: [getWorkspaceCanceledStatus(workspaceId).id],
            inverse: true,
          },
        },
      ],
      include: API.tasksV2.taskAllIncludes,
    },
    { enabled: !!projectId }
  )
}
