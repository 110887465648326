import { type COLOR, COLORS } from '@motion/shared/common'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import { getColorName } from '@motion/ui/palette'
import { capitalize } from '@motion/utils/string'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useProjectUpdater } from '~/areas/project/hooks'
import { ModalFieldButton } from '~/areas/task-project/components'
import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { ColorItemBadge } from '~/global/components/badges'
import { ColorLabel } from '~/global/components/labels'
import { useProject } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledColorField = () => {
  const { form } = useProjectForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'color',
    control,
  })

  const context = useRouteAnalyticsMetadata()

  const projectId = watch('id')

  const project = useProject(projectId)

  const updateProject = useProjectUpdater()

  const updateProjectColor = async (color: COLOR) => {
    if (project != null) {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PROJECT_COLOR', context)
      await updateProject(project, {
        color,
      })
    }
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          items={[...COLORS]}
          renderItem={(item) => <ColorLabel color={item} />}
          computeKey={(item) => item}
          computeSearchValue={(item) => getColorName(item)}
          computeSelected={(item) => item === field.value}
          onSelect={(item) => {
            field.onChange(item)
            updateProjectColor(item)

            close()
          }}
        />
      )}
    >
      <ModalFieldButton label='Color'>
        <ColorItemBadge color={field.value} />
        {capitalize(field.value)}
      </ModalFieldButton>
    </PopoverTrigger>
  )
}
