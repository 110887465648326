import { showToast } from '@motion/ui/base'
import { type CreateProjectDefinitionRequestSchema } from '@motion/zod/client'

import { useCreateProjectDefinition as useCreateProjectDefinitionCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

export const useCreateProjectDefinition = () => {
  const { mutateAsync: createProjectDefinition } =
    useCreateProjectDefinitionCall()

  return useCallback(
    async (data: CreateProjectDefinitionRequestSchema) => {
      const response = await createProjectDefinition({
        workspaceId: data.definition.workspaceId,
        ...data,
      })

      showToast('success', 'Project definition created')

      return response.models[response.meta.model][response.id]
    },
    [createProjectDefinition]
  )
}
