import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  type BillingPrices,
  makeTeamTrialOnIndividualBillingTermsStr,
} from '@motion/ui-logic/billing'
import {
  BillingCardHoldWarning,
  BillingFooter,
  BillingPlanSelector,
  SafeCheckoutGuarantee,
} from '@motion/web-billing'

import { PaymentElement } from '@stripe/react-stripe-js'
import { type StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import { useOpenPortalLink } from '~/components/Account/hooks'
import { useIsNoCardTrialing } from '~/global/hooks'
import { useIndividualSubscription } from '~/global/rpc/subscriptions'
import { getSubscription } from '~/state/userSlice'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { dispatch } from '../../../../state/proxy'
import { type ProcessPaymentProps } from '../../types/process-payment-props'

type PaymentSectionWithSeatsProps = {
  isAnnual: boolean
  setIsAnnual: (isAnnual: boolean) => void
  bucket: number
  setBucket: (bucket: number) => void
  isSubmitting: boolean
  processPayment: (props: ProcessPaymentProps) => Promise<void>
  goToNextScreen: () => void
  teamPrices: BillingPrices
  stripeCustomerId?: string
  shouldCreateNewTeamCustomer: boolean
  trialDays?: number
}

export const PaymentSectionWithSeats = ({
  isAnnual,
  setIsAnnual,
  bucket,
  setBucket,
  isSubmitting,
  processPayment,
  goToNextScreen,
  teamPrices,
  stripeCustomerId,
  shouldCreateNewTeamCustomer,
  trialDays,
}: PaymentSectionWithSeatsProps) => {
  const [hasSelectedCard, setHasSelectedCard] = useState(true)
  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)
  const [useDefaultPaymentMethod, setUseDefaultPaymentMethod] = useState(
    !shouldCreateNewTeamCustomer
  )
  const isNoCardTrialing = useIsNoCardTrialing()

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setHasSelectedCard(event.value.type === 'card')
    setIsPaymentFilledOut(event.complete)
  }

  const existingSubscription = useIndividualSubscription()

  const { openPortalLink } = useOpenPortalLink({
    stripeCustomerId,
  })

  return (
    <div className='max-w-[840px] flex flex-col gap-10 pl-5 pt-5'>
      <BillingPlanSelector
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        savingsPercent={teamPrices.annualSavingsPercentInteger}
        title={
          shouldCreateNewTeamCustomer && trialDays
            ? `Start free ${trialDays} day trial (Team Plan)`
            : 'Switch to Team Plan'
        }
        text='Team Plan'
        subText={isAnnual ? 'Billed yearly' : 'Billed monthly'}
        price={
          isAnnual ? teamPrices.annualPricePerMonth : teamPrices.monthlyPrice
        }
        trialLength={0}
        isTeam
        setBucket={setBucket}
        bucket={bucket}
      />
      {!isNoCardTrialing && (
        <div className='flex flex-col gap-4'>
          <h1 className='text-xl font-bold'>Payment information</h1>
          {!shouldCreateNewTeamCustomer && (
            <div
              className={twMerge(
                'flex flex-row items-center',
                useDefaultPaymentMethod ? 'justify-between' : 'justify-start'
              )}
            >
              {useDefaultPaymentMethod && (
                <div className='text-sm text-semantic-neutral-text-default'>
                  {templateStr(
                    'The {{linkToCard}} will be charged ${{price}} billed {{interval}}{{prorationText}}',
                    {
                      linkToCard: (
                        <a
                          onClick={openPortalLink}
                          className='text-semantic-primary-text-default underline'
                        >
                          card on file
                        </a>
                      ),
                      price:
                        (isAnnual
                          ? teamPrices.annualPrice
                          : teamPrices.monthlyPrice) * bucket,
                      interval: isAnnual ? 'annually' : 'monthly',
                      prorationText:
                        existingSubscription?.status === 'trialing'
                          ? ' after your trial ends.'
                          : `. The first ${
                              isAnnual ? 'year' : 'month'
                            } amount will be pro-rated starting today.`,
                    }
                  )}
                </div>
              )}
              <Button
                onClick={() => setUseDefaultPaymentMethod((prev) => !prev)}
                sentiment='neutral'
                size='small'
              >
                {useDefaultPaymentMethod
                  ? 'Change Payment Method'
                  : 'Use Existing Payment Method'}
              </Button>
            </div>
          )}
          {!useDefaultPaymentMethod && (
            <>
              <PaymentElement onChange={handlePaymentElementChange} />
              <BillingCardHoldWarning visible={!hasSelectedCard} />
              <SafeCheckoutGuarantee />
            </>
          )}
        </div>
      )}
      {shouldCreateNewTeamCustomer && (
        <div>
          <p>
            {makeTeamTrialOnIndividualBillingTermsStr(
              (isAnnual ? teamPrices.annualPrice : teamPrices.monthlyPrice) *
                bucket,
              isAnnual,
              DateTime.now().plus({ days: trialDays }).toFormat('MMM d, yyyy'),
              trialDays || 0
            )}
          </p>
        </div>
      )}
      <div className='flex flex-col gap-4 items-center'>
        <Button
          disabled={!useDefaultPaymentMethod && !isPaymentFilledOut}
          loading={isSubmitting}
          onClick={async () => {
            await processPayment({
              isAnnual,
              bucketSeats: bucket,
              useExistingCard: useDefaultPaymentMethod,
            })
            await dispatch(getSubscription())
            goToNextScreen()
          }}
          fullWidth
        >
          {shouldCreateNewTeamCustomer && trialDays
            ? `Start ${trialDays} day free team trial`
            : ' Start team plan'}
        </Button>
        {(!shouldCreateNewTeamCustomer || !trialDays) && (
          <BillingFooter
            isAnnual={isAnnual}
            price={
              isAnnual
                ? teamPrices.annualPricePerMonth
                : teamPrices.monthlyPrice
            }
            bucket={bucket}
            isTeam
          />
        )}
      </div>
    </div>
  )
}
