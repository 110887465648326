import { StartDateSolid } from '@motion/icons'
import { sleep } from '@motion/utils/promise'

import { useProjectStartDateUpdater } from '~/areas/project/hooks'
import { StartDateDropdown } from '~/areas/project-management/components'
import { DateTriggerButton } from '~/areas/task-project/components'
import { useProject } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledStartDateField = () => {
  const { form } = useProjectForm()
  const updateProjectStartDate = useProjectStartDateUpdater()

  const { control, watch } = form

  const projectId = watch('id')
  const project = useProject(projectId)

  const { field } = useController({
    name: 'startDate',
    control,
  })

  const today = DateTime.now().toISODate()

  const onChange = async (startDate: string | null) => {
    if (project == null) {
      field.onChange(startDate)
      return
    }

    // Sleeping a bit to make sure the dropdown gets closed before prompting the modal to keep the focus within the modal
    await sleep(1)

    await updateProjectStartDate(project, startDate)
  }

  return (
    <StartDateDropdown
      value={field.value}
      onChange={(value) => {
        onChange(value)
      }}
      contextProps={{ projectId }}
      dropdownTarget='project'
    >
      <DateTriggerButton
        label='Start date'
        icon={<StartDateSolid />}
        onClear={field.value === today ? undefined : () => onChange(today)}
        date={field.value}
        placeholder='None'
        clearTooltipContent='Reset start date to today'
      />
    </StartDateDropdown>
  )
}
