import { type ModalTriggerComponentProps } from '~/areas/modals'
import { type ReactNode, useMemo, useState } from 'react'

import { FlowsModalStateContext } from './flows-modal-context'

export type FlowsModalStateProviderProps = {
  children: ReactNode
  mode?: ModalTriggerComponentProps<'flows-template-modal'>['mode']
}

export function FlowsModalStateProvider({
  children,
  mode,
}: FlowsModalStateProviderProps) {
  const [dirtyTasksMap, setDirtyTasksMap] = useState<Record<string, boolean>>(
    {}
  )

  const value = useMemo(() => {
    return {
      mode,
      dirtyTasksMap,
      setDirtyTasksMap,
    }
  }, [dirtyTasksMap, mode])

  return (
    <FlowsModalStateContext.Provider value={value}>
      {children}
    </FlowsModalStateContext.Provider>
  )
}
