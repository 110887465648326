import { Button } from '@motion/ui/base'
import { formatDurationToShort, getDurations } from '@motion/ui-logic'
import { getTaskDurationChangedFields } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { DurationDropdown } from '~/areas/project-management/components'
import { useController, useFormContext } from 'react-hook-form'

import { type InlineTaskCreationFields } from '../form-types'

export const InlineTaskCreationDurationField = () => {
  const formContext = useFormContext<InlineTaskCreationFields>()
  const { getValues, setValue } = formContext
  const { field } = useController({
    name: 'duration',
    control: formContext.control,
  })

  const durations = getDurations({ includeNone: true })

  const selectedDuration = durations.find((c) => c.value === field.value)
  const durationLabel =
    selectedDuration?.label ?? formatDurationToShort(field.value ?? 0)

  const onChange = (duration: number | null) => {
    field.onChange(duration)
    const updates = getTaskDurationChangedFields({
      ...getValues(),
      duration,
    } as TaskSchema)
    if (updates.minimumDuration !== undefined) {
      setValue('minimumDuration', updates.minimumDuration)
    }
  }

  return (
    <DurationDropdown
      value={field.value}
      choices={durations}
      onChange={onChange}
    >
      <Button sentiment='neutral' variant='outlined' size='xsmall'>
        <span className='truncate text-[10px] font-normal'>
          {durationLabel}
        </span>
      </Button>
    </DurationDropdown>
  )
}
