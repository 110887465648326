import type { OnDragStartArgs } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'

import { useRecordTriedDraggingAgendaItemUFFeedback } from '~/global/hooks'
import { serializeFullCalendarEventId } from '~/state/calendarEvents/calendarEventsUtils'
import { DateTime } from 'luxon'
import { useCallback, useEffect } from 'react'

import { useSendAgendaState } from './use-agenda-state'
import { useNearestAgendaTask } from './use-nearest-agenda-task'

import type { AgendaTreeItem } from '../components/agenda-treeview/types'

export function useFakeDragForSurvey() {
  const setAgendaState = useSendAgendaState()
  const nextTask = useNearestAgendaTask({ ignoreUpcomingEvents: false })
  const useAgendaTasksDraggable = useHasTreatment('agenda-tasks-draggable')
  const recordTriedDraggingUFFeedback =
    useRecordTriedDraggingAgendaItemUFFeedback()
  const disableDrag = !useAgendaTasksDraggable

  useEffect(() => {
    if (disableDrag) return

    // Reset the attempted move task event id when the component mounts
    setAgendaState((prev) => ({
      ...prev,
      attemptedMoveTaskEventId: null,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDragStart = useCallback(
    ({ activeItem }: OnDragStartArgs<AgendaTreeItem>) => {
      if (disableDrag || activeItem == null) return

      const taskScheduledDate =
        activeItem.task?.schedule.start != null
          ? DateTime.fromISO(activeItem.task?.schedule.start).startOf('day')
          : null

      const task = taskScheduledDate?.hasSame(DateTime.now(), 'day')
        ? activeItem?.task
        : nextTask

      const attemptedMoveTaskEventId = serializeFullCalendarEventId({
        type: 'task',
        id: task?.task?.id ?? '',
      })

      recordTriedDraggingUFFeedback()

      setAgendaState((prev) => ({
        ...prev,
        attemptedMoveTaskEventId,
      }))
    },
    [disableDrag, nextTask, recordTriedDraggingUFFeedback, setAgendaState]
  )

  return { onDragStart, disableDrag }
}
