import { entries } from '@motion/utils/object'

import { type ProjectUrlSearchParams } from '~/areas/project/modals/project-modal/utils'
import { useCallback } from 'react'

import { type GroupedNode, type Tree } from '../../../grouping'
import {
  type InferProjectDataHandler,
  useInferProjectDataHandler,
} from '../../../tree-list/cells/hooks'
import { getProjectInferItem } from '../../../tree-list/cells/hooks/utils'

const groupProjectParamKeyLookup = {
  workspaceId: 'forWorkspace',
  managerId: 'forManager',
  statusId: 'forStatus',
  priorityLevel: 'forPriority',
  labelId: 'forLabel',
  folderId: 'forFolder',
  customFieldValues: 'forCustomField',
} as const satisfies {
  [K in keyof ReturnType<InferProjectDataHandler>]: string
}

export const useInferProjectNavigateParams = () => {
  const inferProjectData = useInferProjectDataHandler()

  const getNodeData = useCallback(
    <T extends GroupedNode>(
      group: Tree<T>,
      workspaceId: string
    ): ProjectUrlSearchParams => {
      // Check the node item type and return the appropriate data
      const node = group.item
      if (node == null) {
        return {}
      }

      const inferItem = getProjectInferItem({
        row: node,
      })
      const data = inferProjectData(inferItem, workspaceId)
      const params = entries(data).reduce<ProjectUrlSearchParams>(
        (acc, [key, value]) => ({
          ...acc,
          [groupProjectParamKeyLookup[
            key as keyof typeof groupProjectParamKeyLookup
          ]]: value,
        }),
        {}
      )

      return params
    },
    [inferProjectData]
  )

  return useCallback(
    <T extends GroupedNode>(
      group: Tree<T>,
      workspaceId: string
    ): ProjectUrlSearchParams => {
      const navigateData = getNodeData(group, workspaceId)

      return {
        ...navigateData,
        forWorkspace: workspaceId,
      }
    },
    [getNodeData]
  )
}
