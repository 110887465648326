import { InformationCircleSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

type WorkspaceStatusAutoScheduleCheckboxProps = {
  value: boolean
  onChange: (checked: boolean) => void
  statusName: string
  isResolvedStatus?: boolean
  disabled?: boolean
}

export const WorkspaceStatusAutoScheduleCheckbox = (
  props: WorkspaceStatusAutoScheduleCheckboxProps
) => {
  const { value, onChange, statusName, disabled, isResolvedStatus } = props
  return (
    <div className='shrink-0 flex items-center gap-1'>
      {!isResolvedStatus && (
        <Checkbox
          label='Disable Auto-Scheduling'
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
      )}
      <Tooltip
        content={
          isResolvedStatus
            ? 'Completed tasks cannot be auto-scheduled'
            : disabled
              ? "This setting can't be changed for the disabled toggles"
              : `When this is checked, tasks in the ${statusName} status will not be allowed to be auto-scheduled`
        }
      >
        <InformationCircleSolid className='w-4 text-semantic-neutral-icon-default' />
      </Tooltip>
    </div>
  )
}
