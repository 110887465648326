import { useCurrentModal } from '~/areas/modals'
import { useUnsavedChangesPrompt } from '~/global/navigation'
import { useEffect } from 'react'

interface PromptOptions {
  when: boolean
  message?: string
}

export function useModalPromptBeforeDismiss({ when, message }: PromptOptions) {
  const currentModal = useCurrentModal()
  const showUnsavedPrompt = useUnsavedChangesPrompt()

  useEffect(() => {
    currentModal.updateOnDismiss(async () => {
      return when ? await showUnsavedPrompt(message) : true
    })

    return () => {
      currentModal.updateOnDismiss(undefined)
    }
  }, [currentModal, message, showUnsavedPrompt, when])
}
