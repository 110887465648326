import { time } from '@motion/utils/debug'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { errorInDev, logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { bus } from '@motion/web-common/event-bus'
import { websocketsEventSubscriber } from '@motion/web-common/websockets'

import { EventEmitter } from 'events'

import {
  type BackgroundLoadedMessage,
  type CreateWindow,
  type CreateWindowMessage,
  type LocalStorageSetResponse,
  type MessageListener,
  type PromiseTracker,
  type PushStorageResponse,
  type WorkerEvent,
  type WorkerEvents,
} from './chromeApiTypes'
import { storage } from './shared'
import { type FakeWorker, webSide } from './shared/workers'
import localStorage from './webAppLocalStorage'

import { setBackgroundLoaded } from '../state/mainSlice'
import { store } from '../state/proxy'

// Helps us to manage adding/removing listeners
const ee = new EventEmitter()

// Worker instance generated by the content script
let worker: FakeWorker

let backgroundScriptLoaded = false

let bufferedStorageChanges = {}

export const terminateWorker = async () => {
  if (!worker) {
    logInDev('No web worker to terminate')
    return
  }

  await sendWorkerMessage({}, 'terminate', false, true)

  await worker.dispose?.()

  // @ts-expect-error null cannot be assigned to worker. Ignored to turn on TS strict mode.
  worker = null
}

export const initializeWorker = async () => {
  if (worker) {
    return
  }

  const localWorker: FakeWorker = webSide

  // TODO use worker-loader
  await setWorker(localWorker)

  const mod = await time(
    'loading background',
    () => import('../loadedBackground.entry')
  )
  await mod.initializeSession()
  localWorker.dispose = mod.terminateSession

  window.onunload = () => {
    void terminateWorker()
  }
}

/**
 * Important: This should be called after the "pushStorage" message is received
 * from the BG. If not, then this function will set incorrect state, since it
 * expects its local storage to be up to date
 * @param message
 */
const handleBackgroundLoaded = async (message: BackgroundLoadedMessage) => {
  backgroundScriptLoaded = true
  logInDev('--- Background script loaded ---')
  Sentry.addBreadcrumb({
    message: 'handleBackgroundLoaded',
    data: { ...message, bufferedStorageChanges },
  })
  if (bufferedStorageChanges) {
    logInDev('Pushing buffered storage changes')
    ee.listeners('storage.onChanged').forEach((listener) =>
      listener(bufferedStorageChanges)
    )
    // @ts-expect-error null cannot be assigned to bufferedStorageChanges. Ignored to turn on TS strict mode.
    bufferedStorageChanges = null
  }

  store.dispatch(setBackgroundLoaded(true))
}

/**
 * Set the worker for the current page/tab. This expects to be set once for
 * the lifetime of the page/tab.
 * TODO need to investigate whether a worker instance can be shared across
 * multiple tabs.
 * @param _worker
 */
export const setWorker = async (_worker: FakeWorker) => {
  worker = _worker
  worker.onmessage = async (e) => {
    if (
      !e.data ||
      !e.data.source ||
      !e.data.source.startsWith('motion-backend')
    ) {
      return
    }

    const event = e.data as WorkerEvent
    switch (event.source) {
      case 'motion-backend':
        if (!event.eventType) {
          return
        }
        switch (event.eventType) {
          case 'sentry-event':
          case 'sentry-breadcrumb': {
            return window.postMessage(event)
          }
          case 'refresh':
            window.location.reload()
            return
          case 'initBackground':
            store.dispatch(setBackgroundLoaded(false))
            sendWorkerMessageResponse(null, event.promiseIndex)
            return
          case 'pushStorage':
            const { data } = event.message as PushStorageResponse
            logInDev('Initializing storage from BG', data)
            void localStorage.initialize(data)
            sendWorkerMessageResponse(null, event.promiseIndex)
            return
          case 'localStorageSet':
            const { changeData } = event.message as LocalStorageSetResponse
            if (backgroundScriptLoaded) {
              ee.listeners('storage.onChanged').forEach((listener) =>
                listener(changeData)
              )
            } else {
              Object.assign(bufferedStorageChanges, changeData)
            }
            localStorage.syncChanges(changeData)
            return
          case 'createWindow':
            await tabs.create(event.message as CreateWindowMessage)
            break

          case 'teamWebsocketMessage':
            bus.emit('legacy:pm:websockets', {
              type: event.message.type,
              data: event.message,
            })

            break
          case 'websocketsMessage':
            websocketsEventSubscriber.handle(event.message.type, event.message)
            break
          case 'runtimeMessage':
            switch (event.message.event) {
              case 'backgroundLoaded':
                Sentry.addBreadcrumb({ message: 'recieved backgroundLoaded' })
                await handleBackgroundLoaded(
                  event.message as BackgroundLoadedMessage
                )
                sendWorkerMessageResponse(null, event.promiseIndex)
                return
            }

            ee.listeners('onMessage').forEach((listener) =>
              listener(event.message)
            )
            break
          default:
            errorInDev('Unexpected event', event.message, event)
        }
        return
      case 'motion-backend-promise-response':
        // Responding to a prior worker message event, which requires a response
        const message = e.data as WorkerEvent
        workerMessagePromises[message.promiseIndex]?.resolve(e.data.message)
        delete workerMessagePromises[message.promiseIndex]
    }
  }
}

let promiseIndex = 0
const workerMessagePromises: PromiseTracker = {}

/**
 * Send a message to the worker that will be processed by the background
 * script's onMessage listeners.
 *
 * @param message
 * @param eventType
 * @param createPromise
 * @param internal
 */
const sendWorkerMessage = async (
  message: any,
  eventType?: WorkerEvents,
  createPromise = true,
  internal = false
) => {
  const event: WorkerEvent = {
    eventType,
    message,
    // @ts-expect-error promiseIdenx should not be set to undefined. Ignored to turn on TS strict mode.
    promiseIndex: createPromise ? promiseIndex : undefined,
    source: internal ? 'motion-frontend' : 'motion-frontend-onMessage',
  }

  if (createPromise) {
    return await new Promise((resolve, reject) => {
      if (worker == null) {
        logInDev(
          `Unable to send the event to the worker. Worker is not initialized.`,
          message
        )
        return reject(new Error('The worker thread is not initialized'))
      }
      workerMessagePromises[promiseIndex] = { reject, resolve }
      worker.postMessage(event)
      promiseIndex++
    })
  }
  worker.postMessage(event)
  return await Promise.resolve()
}

/**
 * Respond to a worker event which has an associated promise that needs to
 * be resolved.
 * @param message
 * @param promiseIndex
 */

const sendWorkerMessageResponse = (message: any, promiseIndex: number) => {
  const workerMessage: WorkerEvent = {
    message,
    promiseIndex: promiseIndex,
    source: 'motion-frontend-promise-response',
  }

  if (!worker) {
    // Sometimes worker is not initialized when the response is sent
    // keeping this in amplitude for event tracking in case it ever spikes
    recordAnalyticsEvent('WORKER_NOT_INITIALIZED')
    return
  }
  worker.postMessage(workerMessage)
}

export const runtime = {
  getManifest: () => {
    return {
      update_url:
        window.location.href.includes('app.usemotion.com') ||
        window.location.href.includes(
          'dashboard-qa-dot-light-relic-254202.appspot.com'
        )
          ? 'update_url'
          : null,
    }
  },
  getURL: (path: string) => {
    if (path.startsWith('/')) {
      return `/web${path}`
    }
    return path
  },
  onConnect: {
    addListener: (listener: MessageListener) => {
      ee.addListener('onConnect', listener)
    },
  },
  onMessage: {
    addListener: (listener: MessageListener) => {
      ee.addListener('onMessage', listener)
    },
    removeListener: (listener: MessageListener) => {
      ee.removeListener('onMessage', listener)
    },
  },
  sendMessage: async (message: Record<string, any>): Promise<any> => {
    return await sendWorkerMessage(message)
  },
  setUninstallURL: (url: string) => {
    logInDev('runtime.setUninstallURL stub', url)
  },
}

export { storage }

export const tabs = {
  async create(data: CreateWindow) {
    if (data.type === 'popup') {
      const popup = window.open(
        data.url,
        '_blank',
        `width=${data.width},height=${data.height}`
      )
      if (!popup) {
        window.alert(
          'The popup window has been blocked. Please open the popup in the URL bar.'
        )
      }
    } else {
      window.location.href = data.url
    }

    return await Promise.resolve()
  },
  async query() {
    return Promise.resolve([])
  },
}

/**
 * Triggers an event on other motion tabs (for the same environment) that
 * terminates each tab's service worker. The function works by writing data
 * into local storage, which will fire a `StorageEvent` handled within
 * `WebappWrapper`. This event fires on every tab except the current tab.
 */
export const terminateWorkersOnOtherTabs = () => {
  window.localStorage.setItem('motionLogout', `${Date.now()}`)
}
