export function getNumberFieldRules(fieldName: string) {
  return {
    required: {
      value: true,
      message: `${fieldName} must be a number`,
    },
    minValue: {
      value: 0,
      message: `${fieldName} cannot be less than 0`,
    },
    isInteger: {
      value: true,
      message: `${fieldName} must be an integer`,
    },
    isPositive: {
      value: true,
      message: `${fieldName} must be a positive number`,
    },
  } as const
}
