/**
 * Truncate the URL to a certain length while keeping it readable
 * by first trying to truncate the path.
 * @param url
 * @param estimateMaxLength
 * @returns
 */
export function truncateLink(url: string, estimateMaxLength: number = 50) {
  // Remove protocol (http:// or https://)
  let cleanUrl = url.replace(/^https?:\/\//, '')

  // If the clean URL is shorter than maxLength, return it
  if (cleanUrl.length <= estimateMaxLength) {
    return cleanUrl
  }

  // Split the URL into parts
  const parts = cleanUrl.split('/')

  // If the URL has no path, return the clean URL
  if (parts.length === 1) {
    return cleanUrl
  }

  // If the URL has only one path, return the clean URL
  if (parts.length === 2) {
    return cleanUrl
  }

  // Get the domain and the last part
  const domain = parts[0]
  const lastPart = parts[parts.length - 1]

  // Check if the last part is empty (URL ends with '/')
  const isTrailingSlash = lastPart === ''

  // Attempt to only truncate the paths
  let truncated = `${domain}/…/${isTrailingSlash ? parts[parts.length - 2] + '/' : lastPart}`

  if (truncated.length > estimateMaxLength) {
    const lastPath = isTrailingSlash ? parts[parts.length - 2] : lastPart
    // If the truncated URL is still longer than the estimateMaxLength,
    // Truncate the last part again
    truncated = `${domain}/…${lastPath.slice(-(estimateMaxLength - 4 - domain.length))}`
  }

  return truncated
}
