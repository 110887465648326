import { type WorkspaceSchema } from '@motion/zod/client'

import { WorkspaceBadge } from '~/global/components/badges'
import { WorkspaceDropdown } from '~/global/components/dropdowns'
import { useWorkspaceById } from '~/global/hooks'

import { ModalFieldButton } from '../components'

export type WorkspaceFieldProps = {
  workspaceId: WorkspaceSchema['id']
  onChange: (workspace: WorkspaceSchema) => void
  disabled?: boolean
}
export const WorkspaceField = ({
  workspaceId,
  onChange,
  disabled,
}: WorkspaceFieldProps) => {
  const selectedWorkspace = useWorkspaceById(workspaceId)

  if (selectedWorkspace == null) return null

  return (
    <WorkspaceDropdown selectedWorkspaceId={workspaceId} onChange={onChange}>
      <ModalFieldButton
        size='normal'
        icon={<WorkspaceBadge />}
        disabled={disabled}
      >
        {selectedWorkspace.name}
      </ModalFieldButton>
    </WorkspaceDropdown>
  )
}
