import { byProperty, ordered } from '@motion/utils/array'
import { type ProjectSchema } from '@motion/zod/client'

import { type FC } from 'react'

import { StageTimelineBadge } from './stage-timeline-badge'
import { type StageGroup } from './utils'

type StageTimelineBadgesProps = {
  stageGroup: StageGroup
  project: ProjectSchema
}

export const StageTimelineBadges: FC<StageTimelineBadgesProps> = ({
  stageGroup,
  project,
}) => {
  // Sort stages to ensure active stage is first (will be rendered on top)
  const sortedStages = [...stageGroup.stages].toSorted(
    byProperty('stageDefinitionId', ordered([project.activeStageDefinitionId]))
  )

  return sortedStages.map((stage, index) => {
    return (
      <StageTimelineBadge
        key={stage.id}
        project={project}
        projectStage={stage}
        isStacked
        stackIndex={index}
      />
    )
  })
}
