import { parseDate } from '@motion/utils/dates'
import { keys } from '@motion/utils/object'

import { isProxied } from '~/global/proxies/create-proxy'
import { DateTime } from 'luxon'

/**
 * Performs a deep clone of the tree, keeping proxies.
 *
 * This function recursively clones the tree and its nested properties.
 * It handles circular references and preserves proxy objects without cloning them.
 *
 * @param tree - The tree to be cloned.
 * @param visited - A WeakMap to keep track of already cloned objects (used internally for handling circular references).
 * @returns A deep clone of the input tree.
 *
 * @remarks
 * - Primitive values are returned as-is.
 * - Arrays are cloned as new arrays with their elements deeply cloned.
 * - Objects detected as proxies (using isProxied function) are returned as-is without cloning.
 * - Other objects are cloned as new objects with their properties deeply cloned.
 * - Circular references are handled correctly to avoid infinite recursion.
 */
export function deepCloneTree<T extends object | DateTime>(
  tree: T,
  visited = new WeakMap<object, any>()
): T {
  if (typeof tree !== 'object' || tree === null) {
    return tree
  }

  if (visited.has(tree)) {
    return visited.get(tree)
  }

  if (!Array.isArray(tree) && isProxied(tree)) {
    return tree
  }

  /**
   * Simplying copying an object instance will discard its prototype chain,
   * losing the fact in this case that it is a DateTime instance.
   * This will return a new instance of the date.
   */
  if (tree instanceof DateTime) {
    return parseDate(tree.toISO()) as T
  }

  const clone: any = Array.isArray(tree) ? [] : {}
  visited.set(tree, clone)

  keys(tree).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(tree, key)) {
      clone[key] = deepCloneTree(tree[key] as any, visited)
    }
  })

  return clone
}
