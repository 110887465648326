import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { getHeaderColumn } from './lookup-headers'
import { type TreeListColumn } from './type'

import {
  type FieldDefinition,
  type ProjectFieldId,
  type TaskFieldId,
} from '../../fields'
import { CELLS } from '../cells'

export function sizeOf(field: FieldDefinition<any>) {
  if (typeof field.size === 'number') return field.size
  return field.size.default
}

const SMALLEST_SUPPORTED_SIZE = 40

export function minSizeOf(field: FieldDefinition<any>) {
  if (typeof field.size === 'number')
    return Math.max(field.size, SMALLEST_SUPPORTED_SIZE)
  return Math.max(field.size.min, SMALLEST_SUPPORTED_SIZE)
}

export function maxSizeOf(field: FieldDefinition<any>) {
  if (typeof field.size === 'number') return field.size
  return field.size.max
}

export function convertTaskFieldToColumn(
  f: FieldDefinition<TaskWithRelations>
): TreeListColumn {
  return {
    id: f.id,
    header: getHeaderColumn(f.id),
    size: sizeOf(f),
    minSize: minSizeOf(f),
    maxSize: maxSizeOf(f),
    cell: CELLS[f.id as TaskFieldId],
    enableResizing: !f.disableResizing,
    enableSorting: f.sortFn != null,
    enableHiding: !f.disableHiding,
    enablePinning: f.enablePinning ?? false,
    accessorFn: (row) => row,

    meta: {
      name: f.name,
      disableReordering: f.disableReordering ?? false,
      customFields: undefined,
    },
  }
}

export function convertProjectFieldToColumn(
  f: FieldDefinition<ProjectWithRelations>
): TreeListColumn {
  return {
    id: f.id,
    header: getHeaderColumn(f.id),
    size: sizeOf(f),
    minSize: minSizeOf(f),
    cell: CELLS[f.id as ProjectFieldId],
    enableResizing: !f.disableResizing,
    enableSorting: f.sortFn != null,
    enableHiding: !f.disableHiding,
    enablePinning: f.enablePinning ?? false,
    accessorFn: (row) => row,

    meta: {
      name: f.name,
      disableReordering: f.disableReordering ?? false,
      customFields: undefined,
    },
  }
}
