import { type SupportedStringFilterSchema } from '@motion/ui-logic/pm/data'

import { ALLOWED_STRING_OPERATORS } from './types'

import { getNarrowedFilter } from '../utils'

export function getDisplayText(
  filters: Record<string, SupportedStringFilterSchema> | null
) {
  const valueText = formatValue(filters)
  const filter = getNarrowedFilter(filters)
  const prefix = ALLOWED_STRING_OPERATORS.find(
    (x) => x.id === filter?.operator
  )?.label
  return [prefix, valueText].filter(Boolean).join(' ')
}

function formatValue(
  filters: Record<string, SupportedStringFilterSchema> | null
): string | null {
  const filter = getNarrowedFilter(filters)
  if (filter == null) {
    return 'none'
  }

  if (filter.operator === 'empty') {
    return null
  }

  if (filter.value == null || filter.value === '') {
    return 'none'
  }

  return `"${filter.value}"`
}

export const changeOperation = (
  filter: SupportedStringFilterSchema | null,
  op: SupportedStringFilterSchema['operator']
): SupportedStringFilterSchema | null => {
  if (filter == null) return null

  const prevOp = filter.operator
  if (prevOp === op) return filter

  if (op === 'empty') {
    return {
      inverse: filter.inverse,
      operator: op,
    }
  }

  return {
    ...filter,
    value: 'value' in filter ? filter.value : '',
    operator: op,
  }
}
