import { classed } from '@motion/theme'

import React from 'react'
import { Outlet } from 'react-router'

import { RightSidebar } from './right-sidebar'

import { CalendarPeekModal } from '../calendar/components'

export function AgendaShell() {
  return (
    <ShellContainer>
      <ShellMainContent>
        <Outlet />
        <RightSidebar />
        <CalendarPeekModal />
      </ShellMainContent>
    </ShellContainer>
  )
}

const ShellContainer = classed('div', {
  base: 'flex flex-col size-full',
})

const ShellMainContent = classed('div', {
  base: 'grid size-full grid-cols-[1fr_auto]',
})
