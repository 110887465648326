import { type StageWithDefinition } from '~/areas/project/hooks/data'
import { type DateTime } from 'luxon'

import { DateTimeRow, StageItem } from './common'
import {
  StagesContainer,
  Timeline,
  TimelineInnerContainer,
  TimelineOuterContainer,
} from './styled'
import { getStagesWithTimelinePercentage, shouldRotateDateText } from './utils'

type ProjectStagesTimelineProps = {
  startDate: DateTime
  endDate: DateTime
  stagesWithPositions: (StageWithDefinition & { positionDate: DateTime })[]
  innerTimelineWidthPercent?: number
}

export function ProjectStagesTimeline({
  startDate,
  endDate,
  stagesWithPositions,
  innerTimelineWidthPercent = 100,
}: ProjectStagesTimelineProps) {
  const stagePercentages = getStagesWithTimelinePercentage(
    startDate,
    endDate,
    stagesWithPositions
  )

  const rotateStageDateText = shouldRotateDateText(stagePercentages)

  return (
    <TimelineOuterContainer>
      <DateTimeRow startDate={startDate} endDate={endDate} />
      <TimelineInnerContainer>
        <Timeline style={{ width: `${innerTimelineWidthPercent}%` }} />
        <StagesContainer>
          {stagePercentages.map((stage) => (
            <StageItem
              key={stage.id}
              positionPercentage={stage.positionPercentage}
              date={stage.positionDate}
              stageDefinition={stage.stageDefinition}
              rotatedText={rotateStageDateText}
              showText={stage.showText}
            />
          ))}
        </StagesContainer>
      </TimelineInnerContainer>
    </TimelineOuterContainer>
  )
}
