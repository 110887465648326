import { classed } from '@motion/theme'

export const FieldBar = classed(
  'div',
  'flex gap-3 text-semantic-neutral-text-subtle text-[11px] leading-3'
)

export const DescCommentContainer = classed(
  'div',
  'flex flex-col gap-1.5 text-semantic-neutral-text-disabled text-[11px] leading-3'
)

export const PMResultTitle = classed('div', {
  base: '',
  variants: {
    canceled: {
      true: 'line-through text-semantic-neutral-text-subtle',
    },
  },
})
