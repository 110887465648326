import { getLocalTaskById } from '@motion/rpc-cache'

import { type QueryClient, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { type TaskSchema } from '../types'

export const useTaskIsRecurringFn = () => {
  const client = useQueryClient()
  return useCallback(
    (task: TaskSchema) => {
      return isRecurring(client, task)
    },
    [client]
  )
}

function isRecurring(client: QueryClient, task: TaskSchema) {
  if (task.type === 'CHUNK') {
    const parent = getLocalTaskById(client, task.parentChunkTaskId)
    return parent != null && parent.type === 'RECURRING_INSTANCE'
  }
  return task.type === 'RECURRING_INSTANCE'
}
