import { type ProjectSchema } from '@motion/zod/client'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useProjectCustomFieldUpdater } from '~/areas/project/hooks'
import {
  ControlledCustomFieldsSidebar,
  type ControlledCustomFieldsSidebarProps,
} from '~/areas/task-project/components'

import { useProjectForm } from '../hooks'

type CustomFieldsSidebarSectionProps = {
  workspaceId: string
  projectId: ProjectSchema['id'] | null | undefined
}

export const CustomFieldsProjectSidebarSection = ({
  workspaceId,
  projectId,
}: CustomFieldsSidebarSectionProps) => {
  const {
    form: { control },
  } = useProjectForm()
  const updateProjectCustomFields = useProjectCustomFieldUpdater()
  const { getCustomField } = useCustomFieldsFns()

  const shouldTriggerAutoSave = projectId != null

  const onChange: ControlledCustomFieldsSidebarProps['onChange'] = async (
    customFieldId,
    valueArg
  ) => {
    if (shouldTriggerAutoSave) {
      const customField = getCustomField(customFieldId)
      if (customField == null) {
        throw new Error('Custom field not found')
      }

      return await updateProjectCustomFields(projectId, customField, valueArg)
    }
  }

  return (
    <ControlledCustomFieldsSidebar
      control={control as any}
      workspaceId={workspaceId}
      onChange={shouldTriggerAutoSave ? onChange : undefined}
    />
  )
}
