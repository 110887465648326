import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router'

const NavigationContext = createContext<{
  lastNonSettingsPage: string
}>({
  lastNonSettingsPage: '/web/calendar',
})

export const NavigationContextProvider = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation()

  // We don't need this to persist if the user reloads, so state is fine
  const [lastNonSettingsPage, setLastNonSettingsPage] = useState(
    pathname.startsWith('/web/settings') ? '/web/calendar' : pathname
  )

  useEffect(() => {
    if (!pathname.startsWith('/web/settings')) {
      setLastNonSettingsPage(pathname)
    }
  }, [pathname])

  const value = useMemo(
    () => ({
      lastNonSettingsPage,
    }),
    [lastNonSettingsPage]
  )

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export const useLastNonSettingsPage = () => {
  return useContext(NavigationContext).lastNonSettingsPage
}
