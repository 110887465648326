import {
  useSharedStateContext,
  useSharedStateQuery,
} from '@motion/react-core/shared-state'
import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import {
  ActiveFilterKey,
  buildProjectFilterFrom,
  createNoneProject,
  createNoneUser,
  type FilterTarget,
} from '@motion/ui-logic/pm/data'
import { groupInto } from '@motion/utils/array'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useLookup } from '~/global/cache'
import { useAppDataContext } from '~/global/contexts'
import {
  AppWorkspaceContext,
  useAppWorkspaceContext,
} from '~/global/contexts/app-workspace-context'
import { createProjectProxy } from '~/global/proxies'
import { useMemo } from 'react'

import { matches, matchesPriority, matchesStage } from './match-utils'
import { type InitialValue, type SelectedItems } from './types'

export const useTreeGroupInitialValues = (type: FilterTarget): InitialValue => {
  const items = useSelectedFilterItems(type)
  const [ctx] = useAppWorkspaceContext()
  const lookup = useLookup()

  const hasAnyNoneProjects = items.project.some((x) => isNoneId(x.id))

  return useMemo(() => {
    if (!items) return {}

    const allProjects =
      type === 'projects'
        ? undefined
        : [
            ...(hasAnyNoneProjects
              ? [
                  {
                    key: createNoneId('null'),
                    value: createNoneProject('null'),
                  },
                ]
              : []),
            ...items.project
              .filter((p) => !isNoneId(p.id))
              .map((p) => ({
                key: p.id,
                value: createProjectProxy(p, lookup),
                workspaceIds: [p.workspaceId],
              })),
          ]

    const selectedUserIds = items.users.map((u) => u.id)
    return {
      ...(allProjects == null ? undefined : { project: allProjects }),
      status: groupInto(items.status, (x) => x.name).map((g) => ({
        key: g.key,
        value: g.items[0],
        workspaceIds: g.items.map((w) => w.workspaceId),
      })),
      priority: items.priority.map((g) => ({
        key: g,
        value: g,
        workspaceIds: items.workspace.map((x) => x.id),
      })),
      user: [
        ...items.workspace.map((w) => ({
          key: createNoneId(w.id),
          value: createNoneUser(w.id),
          workspaceIds: [w.id],
        })),
        ...groupInto(ctx.members.all, (x) => x.userId).map((g) => ({
          key: g.key,
          value: g.items[0].user,
          workspaceIds: g.items.map((x) => x.workspaceId),
        })),
      ].filter((x) => selectedUserIds.includes(x.key)),
      stage: groupInto(items.stage, (x) => x.name).map((g) => ({
        key: g.key,
        value: g.items[0],
        workspaceIds: g.items.map((x) => x.workspaceId),
      })),
      projectDefinition: groupInto(items.projectDefinition, (x) => x.name).map(
        (g) => ({
          key: g.key,
          value: g.items[0],
          workspaceIds: g.items.map((x) => x.workspaceId),
        })
      ),
    } satisfies InitialValue
  }, [ctx.members.all, hasAnyNoneProjects, items, lookup, type])
}

const useSelectedFilterItems = (type: FilterTarget) => {
  const api = useSharedStateContext()
  const lookup = useLookup()
  const appDataContext = useAppDataContext()
  const filter = useSharedStateQuery(ActiveFilterKey, (state) => state)
  const user = useAuthenticatedUser()

  return useMemo(() => {
    const ctx = api.get(AppWorkspaceContext)

    const predicate = buildProjectFilterFrom(
      appDataContext,
      filter,
      undefined,
      user.uid
    )
    const projects = ctx.projects.all
      .filter(predicate)
      .map((x) => createProjectProxy(x, lookup))

    return {
      status: matches(ctx.statuses.all, filter[type].filters.statusIds),
      project: projects,
      workspace: ctx.workspaces.all,
      priority: matchesPriority(
        ctx.priorities.all,
        filter[type].filters.priorities
      ),
      users: matches(
        ctx.users.all,
        type === 'tasks'
          ? filter.tasks.filters.assigneeUserIds
          : filter.projects.filters.managerIds
      ),
      labels: matches(ctx.labels.all, filter[type].filters.labelIds),
      stage: matchesStage(ctx, type, filter),
      projectDefinition:
        type === 'tasks'
          ? ctx.projectDefinitions.all
          : matches(
              ctx.projectDefinitions.all,
              filter.projects.filters.projectDefinitionIds
            ),
    } satisfies SelectedItems
  }, [api, appDataContext, filter, user.uid, type, lookup])
}
