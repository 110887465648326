import { classed } from '@motion/theme'
import { PopoverButton, SearchableDropdown } from '@motion/ui/base'
import { type FormatOptions, templateStr } from '@motion/ui-logic'

import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { type CustomFieldFormFields } from '../form-utils'

type FormatOptionFieldProps = {
  formatOptions: FormatOptions
}

export const FormatOptionField = ({
  formatOptions,
}: FormatOptionFieldProps) => {
  const { setValue, watch } = useFormContext<CustomFieldFormFields>()

  const fieldFormat = watch('metadata.format')

  useEffect(() => {
    if (!fieldFormat) {
      setValue('metadata.format', formatOptions[0].id)
    }
  }, [fieldFormat, formatOptions, setValue])

  const selectedItem = formatOptions.find((option) => option.id === fieldFormat)

  return (
    <div className='flex flex-col'>
      <LabelText>Format</LabelText>
      <SearchableDropdown
        selectedItem={selectedItem}
        onChange={(value) => setValue('metadata.format', value.id)}
        items={formatOptions}
        renderItem={(item) => (
          <span className='flex gap-2'>
            <span>{item.label}</span>
            <span className='italic text-semantic-neutral-text-subtle'>
              {templateStr('Ex: {{ exampleText }}', {
                exampleText: item.exampleText,
              })}
            </span>
          </span>
        )}
      >
        <PopoverButton>{selectedItem?.label}</PopoverButton>
      </SearchableDropdown>
    </div>
  )
}

const LabelText = classed('span', {
  base: `
    text-semantic-neutral-text-subtle text-xs
    mb-1.5
  `,
})
