import { classed } from '@motion/theme'

export const PopoverContainer = classed('div', {
  base: `
    w-[350px] 
    border-dropdown-border border rounded-sm
    bg-dropdown-background 
  `,
})

export const PopoverExtendDeadlineContainer = classed('div', {
  base: 'flex items-center gap-2 text-semantic-neutral-icon-default',
})

export const PopoverExtendDeadlineText = classed('div', {
  base: 'text-semantic-neutral-text-default text-xs font-medium flex items-center gap-0.5',
})

export const SectionContainer = classed('div', {
  base: 'w-full border-t border-semantic-neutral-border-light',
})
