import {
  ConfirmDeleteModal,
  type ConfirmDeleteModalProps,
} from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { useModalStatus } from '@motion/web-common/modals'

import { type ReactNode } from 'react'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'confirm-delete-item': PromptCallbacks<boolean> & {
      titleValue: ReactNode
      description?: ConfirmDeleteModalProps['description']
      deleteMessage: ConfirmDeleteModalProps['deleteMessage']
      deleteButtonLabelValue: ReactNode
      alignment?: ConfirmDeleteModalProps['alignment']
      analytics: {
        name: string
      }
    }
  }
}

export const ConnectedConfirmDeleteModal = () => {
  const status = useModalStatus('confirm-delete-item')

  if (!status.visible) return null

  const onClose = status.close
  const {
    titleValue,
    deleteButtonLabelValue,
    description,
    deleteMessage,
    alignment,
  } = status.props

  return (
    <ConfirmDeleteModal
      title={templateStr('Are you sure you want to delete {{ value }}?', {
        value: titleValue,
      })}
      description={description}
      deleteMessage={deleteMessage}
      deleteButtonLabel={templateStr('Delete {{ value }}', {
        value: deleteButtonLabelValue,
      })}
      onClose={() => onClose()}
      onConfirm={() => onClose(true)}
      alignment={alignment}
    />
  )
}
