import { getProjectDateText } from '@motion/ui-logic'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectStartDateUpdater } from '~/areas/project/hooks'
import { StartDateDropdown } from '~/areas/project-management/components'
import { useTaskStartDateData } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction } from './components'

type TaskStartDateCellProps = {
  task: TaskWithRelations
}
export const TaskStartDateCell = ({ task }: TaskStartDateCellProps) => {
  const { onChange, disabled, formattedValue, disableClearingValue } =
    useTaskStartDateData(task)

  return (
    <StartDateDropdown
      value={task.startDate}
      onChange={onChange}
      disableClearing={disableClearingValue}
      contextProps={{ taskId: task.id }}
      dropdownTarget='task'
    >
      <CellAction disabled={disabled}>
        <span className='truncate'>{formattedValue}</span>
      </CellAction>
    </StartDateDropdown>
  )
}

type ProjectStartDateCellProps = {
  project: ProjectSchema
}
export const ProjectStartDateCell = ({
  project,
}: ProjectStartDateCellProps) => {
  const updateProjectStartDate = useProjectStartDateUpdater()
  const formattedValue = getProjectDateText(project.startDate)

  return (
    <StartDateDropdown
      value={project.startDate}
      onChange={(startDate) => {
        void updateProjectStartDate(project, startDate)
      }}
      contextProps={{ projectId: project.id }}
      dropdownTarget='project'
    >
      <CellAction>
        <span className='truncate'>{formattedValue}</span>
      </CellAction>
    </StartDateDropdown>
  )
}
