import {
  AdjustmentsOutline,
  ChevronDoubleLeftSolid,
  ChevronDoubleRightSolid,
  ChevronDownSolid,
  LinkOutline,
} from '@motion/icons'
import {
  ActionDropdown,
  Button,
  PopoverTrigger,
  Tooltip,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { useSetting } from '@motion/web-common/settings'

import { OpenLeftSidebarButton } from '~/areas/sidebar/components'
import { useInNoExternalCalendarsMode } from '~/hooks'
import { forwardRef, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { CreateEventButton } from './create-event-button'
import { RefreshTasksButton } from './refresh-tasks-button'

import { BookingLinksDropdown } from '../../booking/components/booking-links-dropdown'
import {
  CalendarArrows,
  CalendarSettings,
  DateTitle,
} from '../../components/calendar-header/components'
import { TimezoneGroup } from '../../components/calendar-header/timezone-group/timezone-group'
import {
  useCalendarDateNavigation,
  useCalendarSidebarState,
  useCalendarState,
  useSendCalendarState,
} from '../../hooks'

export const CalendarHeader = () => {
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const modalApi = useModalApi()
  const [state] = useCalendarState()
  const setCalendarState = useSendCalendarState()
  const [calendarStartDay] = useSetting('calendarStartDay', 'sunday')
  const { sidebarState, toggleSidebar } = useCalendarSidebarState()
  const calendarNavigation = useCalendarDateNavigation()

  const baseDateTime = state.selectedDate
  const isWeekly = state.calendarView === 'week'
  const isSidebarOpen = sidebarState.open

  const calendarViewHandler = useCallback(
    (value: string) => {
      setCalendarState({
        calendarView: value === 'weekly' ? 'week' : 'day',
      })

      recordAnalyticsEvent('CALENDAR_WEEKLY_TOGGLE', {
        location: 'toolbar',
      })
    },
    [setCalendarState]
  )

  return (
    <div className='flex flex-row items-center w-full'>
      <div className='grow relative py-3 px-4 flex flex-row items-center justify-between gap-2'>
        <TimezoneGroup localTimezone={baseDateTime.zoneName} />

        <div className='shrink-0 flex gap-2'>
          <OpenLeftSidebarButton />

          <Button
            variant='outlined'
            sentiment='neutral'
            size='small'
            onClick={() => void calendarNavigation.today()}
          >
            Today
          </Button>

          <CalendarArrows
            back={calendarNavigation.back}
            forward={calendarNavigation.forward}
          />

          <DateTitle
            baseDate={baseDateTime}
            timezone={baseDateTime.zoneName}
            calendarStartDay={calendarStartDay}
            view={isWeekly ? 'weekly' : 'daily'}
          />
        </div>

        <div className='shrink-0 flex gap-1.5'>
          {noExternalCalendarsMode ? (
            <BookingButton
              onClick={() => {
                modalApi.open('link-calendars')
                recordAnalyticsEvent('LINK_CALENDARS_MODAL_OPEN', {
                  source: 'booking-calendar-header',
                })
              }}
            />
          ) : (
            <BookingLinksDropdown>
              <BookingButton />
            </BookingLinksDropdown>
          )}

          <PopoverTrigger
            placement='bottom-end'
            renderPopover={() => <CalendarSettings />}
          >
            <Tooltip
              content='Set week start day and task display preferences'
              asChild
            >
              <Button variant='outlined' sentiment='neutral' size='small'>
                <AdjustmentsOutline />
                <span className='hidden xl:inline'>Display options</span>
              </Button>
            </Tooltip>
          </PopoverTrigger>

          <RefreshTasksButton />

          <CreateEventButton />

          <ActionDropdown
            items={[
              {
                content: 'Week',
                onAction: () => {
                  calendarViewHandler('weekly')
                },
              },
              {
                content: 'Day',
                onAction: () => {
                  calendarViewHandler('daily')
                },
              },
            ]}
          >
            <Button variant='outlined' sentiment='neutral' size='small'>
              {isWeekly ? 'Week' : 'Day'}
              <ChevronDownSolid />
            </Button>
          </ActionDropdown>

          <Button
            onClick={() => void toggleSidebar()}
            sentiment='neutral'
            size='small'
            variant='muted'
          >
            <div
              className={twMerge(
                'flex flex-row items-center gap-1.5',
                !isSidebarOpen && 'flex-row-reverse'
              )}
            >
              <span>{isSidebarOpen ? 'Close' : 'Open'}</span>

              {isSidebarOpen ? (
                <ChevronDoubleRightSolid />
              ) : (
                <ChevronDoubleLeftSolid />
              )}
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

type BookingButtonProps = { onClick?: () => void }

const BookingButton = forwardRef<HTMLButtonElement, BookingButtonProps>(
  function BookingButton(props, ref) {
    return (
      <Button
        ref={ref}
        variant='outlined'
        sentiment='neutral'
        size='small'
        onClick={props.onClick}
      >
        <LinkOutline />
        <span className='hidden xl:inline'>Booking links</span>
      </Button>
    )
  }
)
