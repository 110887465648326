import { MotionCache } from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'
import { getFileQueryFilters } from '~/global/cache'
import { useUpdateFileMutation } from '~/global/rpc/v2/files'

export const useUpdateAttachment = () => {
  const queryClient = useQueryClient()

  return useUpdateFileMutation({
    onSuccess: (response, { id }) => {
      // Update the cache to reflect the changes
      MotionCache.patch(
        queryClient,
        getFileQueryFilters(id),
        'uploadedFiles',
        response.models.uploadedFiles
      )
    },
  })
}
