import { useShortcut } from '@motion/ui/base'

import { ControlledTextReplacementField } from './fields'

import {
  useSetupProjectForm,
  useSetupProjectModalSubmitHandler,
} from '../../hooks'

export const TextReplacementsStepContent = () => {
  const { form } = useSetupProjectForm()
  const textReplacements = form.watch('textReplacements')

  const submit = useSetupProjectModalSubmitHandler()
  useShortcut('Enter', (e) => {
    submit(e as any)
  })

  return (
    <div className='flex flex-col gap-5'>
      {textReplacements.map((field, i) => (
        <ControlledTextReplacementField
          key={field.variableId}
          textReplacementPath={`textReplacements.${i}`}
        />
      ))}
    </div>
  )
}
