import { DotsVerticalSolid, PencilSolid } from '@motion/icons'
import { type Calendar } from '@motion/rpc/types'
import { IconButton, Tooltip } from '@motion/ui/base'
import { CalendarCheckbox, CalendarTitle } from '@motion/ui/calendar'
import { ConditionalWrapper } from '@motion/ui/utils'
import {
  colorClassIdToColorId,
  getColorClassForColorId,
} from '@motion/ui-logic'
import { type CalendarSchema } from '@motion/zod/client'

import { CalendarListHeader } from '~/areas/calendar/right-sidebar/components/calendar-list-header'
import { selectEmailAccountsMap } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

import { CalendarAvatar } from './calendar-avatar'
import { renderCalendarIcons } from './utils'

import { CalendarColorDropdown } from '../../components'

type CalendarListProps = {
  title: React.ReactNode
  icon?: React.ReactNode
  calendars: CalendarSchema[]
  isExpanded: boolean
  isMyCalendarList?: boolean
  menuCustomOptions?: (calendar: CalendarSchema) => {
    text: string
    onClick: () => void
  }[]
  onCalendarChecked?: (calendar: CalendarSchema, isEnabled: boolean) => void
  onUpdateCalendar: (
    calendar: CalendarSchema,
    changes: Partial<Calendar>
  ) => void
  onEditCalendars?: () => void
  onToggleExpand: (expanded: boolean) => void
  isAccount?: boolean
}

export function CalendarList({
  title,
  icon = null,
  calendars,
  isExpanded,
  isMyCalendarList = false,
  menuCustomOptions = () => [],
  onCalendarChecked,
  onUpdateCalendar,
  onEditCalendars,
  onToggleExpand,
  isAccount = false,
}: CalendarListProps) {
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)

  return (
    <div className='flex gap-3 flex-col'>
      <CalendarListHeader
        title={
          <>
            {icon && <span>{icon}</span>}
            <span className='truncate'>
              {title} {!isAccount && <span>({calendars.length})</span>}
            </span>
          </>
        }
        isExpanded={calendars.length ? isExpanded : undefined}
        isAccount={isAccount}
        onToggleExpand={onToggleExpand}
        actionButton={
          onEditCalendars
            ? {
                icon: PencilSolid,
                onClick: onEditCalendars,
              }
            : undefined
        }
      />

      {isExpanded && !!calendars.length && (
        <div className='flex gap-5 flex-col mx-2'>
          {calendars.map((calendar) => {
            const colorId = getColorClassForColorId(calendar.colorId)
            const isInMyCalendars = isMyCalendarList || calendar.isInMyCalendars

            return (
              <div
                className='flex items-center [&_label]:min-w-0 [&_span]:truncate text-sm font-medium justify-between group/cal gap-1'
                key={calendar.id}
              >
                {isInMyCalendars ? (
                  <div className='flex gap-2 items-center min-w-0'>
                    <ConditionalWrapper
                      condition={!isMyCalendarList}
                      wrapper={(children) => (
                        <Tooltip
                          asChild
                          content='To hide this calendar, remove it from My Calendars.'
                        >
                          {children}
                        </Tooltip>
                      )}
                    >
                      <CalendarAvatar colorId={colorId} />
                    </ConditionalWrapper>

                    {/* TODO find a way to change this text color to sidebar-item-text-default */}
                    <CalendarTitle
                      calendar={calendar}
                      emailAccountsMap={emailAccountsMap}
                      hideEmail={isAccount}
                      isMyCalendarList={isMyCalendarList}
                      renderIcons={renderCalendarIcons}
                      size='small'
                    />
                  </div>
                ) : (
                  <CalendarCheckbox
                    colorId={colorId}
                    checked={calendar.isEnabled}
                    label={
                      <CalendarTitle
                        calendar={calendar}
                        emailAccountsMap={emailAccountsMap}
                        hideEmail={isAccount}
                        renderIcons={renderCalendarIcons}
                        size='small'
                      />
                    }
                    onChange={(checked) =>
                      onCalendarChecked?.(calendar, checked)
                    }
                  />
                )}

                {/* Setting width to 0px to hide the dots button instead of `hidden` class because the dropdown requires the position data  */}
                <div className='w-0 h-4 shrink-0 overflow-hidden group-hover/cal:w-4'>
                  <CalendarColorDropdown
                    customOptions={menuCustomOptions(calendar)}
                    currentColorClass={colorId}
                    onChange={(colorClass) =>
                      onUpdateCalendar(calendar, {
                        colorId: colorClassIdToColorId[colorClass],
                      })
                    }
                  >
                    <IconButton
                      icon={DotsVerticalSolid}
                      sentiment='neutral'
                      size='xsmall'
                      variant='muted'
                      aria-label='More calendar options'
                    />
                  </CalendarColorDropdown>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
