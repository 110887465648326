import {
  EnterpriseBillingPlanCard,
  TeamBillingPlanCard,
} from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useGetTeamPrices } from '~/global/rpc/team'

export interface CreateTeamLandingProps {
  onClick: () => void
}

export function CreateTeamLanding({ onClick }: CreateTeamLandingProps) {
  const { data } = useGetTeamPrices()
  const user = useAuthenticatedUser()

  return (
    <div className='flex h-full w-full pt-20 justify-center bg-semantic-neutral'>
      <div className='w-full px-6'>
        <h1 className='text-semantic-1200 mb-2 lg:mb-6 text-center text-2xl lg:text-[32px] font-bold leading-[42px] select-none'>
          Create a team
        </h1>
        <div className='flex flex-col sm:flex-row justify-center gap-4 items-center sm:items-stretch'>
          <>
            <TeamBillingPlanCard
              onClick={onClick}
              price={data?.annualPricePerMonth}
              withBorder
            />
            <EnterpriseBillingPlanCard
              withBorder
              location='createTeamLanding'
              email={user.email}
              displayName={user.displayName ?? undefined}
            />
          </>
        </div>
      </div>
    </div>
  )
}
