import { templateStr } from '@motion/ui-logic'

import { StageLabel } from '~/global/components/labels'
import type { AppWorkspaceDataContext } from '~/global/contexts'
import { type ReactNode } from 'react'

export function getProjectNameWithActiveStage(
  ctx: AppWorkspaceDataContext,
  projectId: string
): ReactNode | undefined {
  const project = ctx.projects.byId[projectId]
  const stage =
    project?.activeStageDefinitionId != null
      ? ctx.stageDefinitions.byId[project.activeStageDefinitionId]
      : null

  return stage
    ? templateStr('{{project}} {{stage}}', {
        project: project.name,
        stage: <StageLabel value={stage} />,
      })
    : project?.name
}
