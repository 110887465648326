import { type CalendarEventAttendeeStatus } from '@motion/rpc-types'
import { type CalendarEventProps } from '@motion/ui/calendar'

export function getRsvpUIEvent(
  rsvp?: CalendarEventAttendeeStatus
): CalendarEventProps['rsvp'] {
  if (rsvp === 'declined') return 'no'
  if (rsvp === 'tentative') return 'maybe'
  if (rsvp === 'needsAction') return 'default'

  return undefined
}
