import { PlusSolid, PuzzleSolid } from '@motion/icons'
import { Button, ButtonGroup } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useDeleteProjectDefinition } from '~/areas/flows'
import {
  FlowTutorialButton,
  useFlowTemplateModalUrl,
} from '~/areas/flows/flow-template-modal'
import { StageTags } from '~/global/components'
import { useProjectDefinitions } from '~/global/hooks'
import { useParams } from 'react-router-dom'

import { SettingTableV2 } from '../../components'
import { EmptyContainer } from '../empty-container'

/**
 *  @deprecated - remove after flows-m5a is at 100% rollout
 */
export function DeprecatedFlowTemplateTable() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const flowProjectTemplates = useProjectDefinitions(workspaceId)
  const modalApi = useModalApi()

  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const newFlowTemplateUrl = buildFlowTemplateModalUrl({
    forWorkspace: workspaceId,
    template: 'create',
  })

  const handleOpenFlowModal = (isNew = true) => {
    recordAnalyticsEvent('FLOW_TEMPLATE_MODAL_OPENED', { new: isNew })
  }

  const deleteProjectDefinition = useDeleteProjectDefinition()

  return (
    <SettingTableV2
      title='Project Workflow Templates'
      items={flowProjectTemplates.map((template) => ({
        id: template.id,
        label: template.name,
        icon: PuzzleSolid,
        onDuplicate: () => {
          modalApi.open('duplicate-flow', {
            flowId: template.id,
            workspaceId,
          })
        },
        onEdit: () => handleOpenFlowModal(false),
        onEditUrl: buildFlowTemplateModalUrl({
          forWorkspace: workspaceId,
          template: 'edit',
          templateId: template.id,
        }),
        onDelete: () => deleteProjectDefinition(template.id),
        bottomSection: <StageTags stages={template.stages} />,
      }))}
      emptyContainer={
        <EmptyContainer
          title='No project workflow templates'
          description='Use project workflow templates to easily create common projects within your workspace.'
          icon={PuzzleSolid}
          action={{
            children: 'Create project workflow template',
            icon: PlusSolid,
            url: newFlowTemplateUrl,
            onClick: () => handleOpenFlowModal(),
          }}
        />
      }
      headerEndContent={
        <ButtonGroup>
          <FlowTutorialButton rounded={false} />
          <Button
            onClick={() => handleOpenFlowModal()}
            url={newFlowTemplateUrl}
            size='small'
          >
            <PlusSolid />
            Create project workflow template
          </Button>
        </ButtonGroup>
      }
    />
  )
}
