import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type DateSelectArg } from '@fullcalendar/core'
import { type DateClickArg } from '@fullcalendar/interaction'
import { DateTime } from 'luxon'
import { useCallback, useRef } from 'react'

import { useSendCalendarState } from '../../hooks'
import { NEW_EVENT_ID } from '../../utils'

export const useMainCalendarHandlers = () => {
  const setCalendarState = useSendCalendarState()
  const hasDragged = useRef(false)

  const onEmptySlotDragEnd = useCallback(
    (arg: DateSelectArg) => {
      hasDragged.current = true
      setCalendarState({
        selectedCalendarEvent: {
          id: NEW_EVENT_ID,
          new: { allDay: arg.allDay, start: arg.startStr, end: arg.endStr },
        },
      })
      recordAnalyticsEvent('CALENDAR_EMPTY_SLOT_SELECTED', {
        dragged: true,
        allDay: arg.allDay,
      })
    },
    [setCalendarState]
  )

  const onEmptySlotClick = useCallback(
    (arg: DateClickArg) => {
      if (hasDragged.current === true) {
        hasDragged.current = false
        return
      }

      const startDate = DateTime.fromJSDate(arg.date)
      const endStr = arg.allDay
        ? startDate.plus({ days: 1 }).toISO()
        : startDate.plus({ minutes: 30 }).toISO()

      setCalendarState((prev) => {
        if (new Date().getTime() - prev.unselectCalendarEventTimeStamp < 300)
          return prev

        recordAnalyticsEvent('CALENDAR_EMPTY_SLOT_SELECTED', {
          dragged: false,
          allDay: arg.allDay,
        })
        return {
          ...prev,
          selectedCalendarEvent: {
            id: NEW_EVENT_ID,
            new: { allDay: arg.allDay, start: arg.dateStr, end: endStr },
          },
        }
      })
    },
    [setCalendarState]
  )

  return {
    onEmptySlotDragEnd,
    onEmptySlotClick,
  } as const
}
