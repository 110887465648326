import { classed } from '@motion/theme'
import { templateStr } from '@motion/ui-logic'

import { useI18N } from '~/global/contexts'

export type CompletedTasksItemProps = {
  allCompleteLabel?: string
  numTasks: number
  numCompletedTasks: number
}

export function CompletedTasksItem({
  allCompleteLabel,
  numTasks,
  numCompletedTasks,
}: CompletedTasksItemProps) {
  const { pluralize } = useI18N()

  return (
    <OuterContainer>
      {numCompletedTasks === numTasks && (
        <MainTextContainer>
          {allCompleteLabel ?? DEFAULT_LABEL}
        </MainTextContainer>
      )}
      <SubtextContainer>
        {templateStr(
          '({{numCompletedTasks}} completed tasks. To show {{pronoun}} here go to {{settingsPath}})',
          {
            numCompletedTasks,
            pronoun: pluralize(numCompletedTasks, 'it', 'them'),
            settingsPath: SettingsPath,
          }
        )}
      </SubtextContainer>
    </OuterContainer>
  )
}

const SettingsPath = (
  <span className='font-semibold'>{`Calendar > Display options > Show completed tasks`}</span>
)

const DEFAULT_LABEL = 'All tasks complete! Enjoy your time off.'

const OuterContainer = classed('div', {
  base: 'flex flex-col gap-2 pl-10',
})

const MainTextContainer = classed('span', {
  base: 'text-semantic-neutral-text-subtle min-w-0 text-[13px] leading-4',
})

const SubtextContainer = classed('span', {
  base: 'text-semantic-neutral-text-subtle text-2xs leading-3',
})
