import { type StatusIconProps } from '@motion/ui/pm'
import { type StatusSchema } from '@motion/zod/client'

import { type BadgeSize, StatusBadge } from '~/global/components/badges'

export type StatusLabelProps = {
  value: Pick<StatusSchema, 'name' | 'color' | 'type'>
  size?: BadgeSize
  iconVariant?: StatusIconProps['iconVariant']
  taskVariant?: StatusIconProps['taskVariant']
}

export const StatusLabel = ({
  value,
  size,
  iconVariant,
  taskVariant,
}: StatusLabelProps) => {
  if (!value) return null

  return (
    <div className='flex gap-1 items-center overflow-hidden leading-4'>
      <StatusBadge
        value={value}
        size={size}
        iconVariant={iconVariant}
        taskVariant={taskVariant}
        hideTooltip
      />
      <span className='truncate'>{value.name}</span>
    </div>
  )
}
