import { getWindowData } from '@motion/web-base/env'

const { isMac } = getWindowData()

export const parseShortcutKey = (key: string) => {
  let currentKey = key.toLocaleUpperCase()

  if (key === 'Alt') {
    if (isMac) {
      currentKey = '⌥'
    } else {
      currentKey = 'alt'
    }
  }

  if (key === 'Meta' || key === 'Command' || key === 'CommandOrControl') {
    currentKey = '⌘'
  }

  if (key === 'Control') {
    currentKey = 'ctrl'
  }

  if (key.length === 1) {
    return currentKey.toUpperCase()
  }

  return currentKey.toLowerCase()
}
