import { Toggle } from '@motion/ui/base'

import { useAutoScheduleSettings } from '~/global/hooks'
import { useUpdateMyAutoScheduleSettingsMutation } from '~/global/rpc/v2'

import { Combobox, Paragraph } from '../../../../Common'

const durationOptions = [...Array(4).keys()].map((num) => num * 15 + 15)
const intervalOptions = [...Array(8).keys()].map((num) => num + 1)

export const TaskBreakSettings = () => {
  const autoScheduleSettings = useAutoScheduleSettings()
  const { mutate: updateAutoScheduleSettings } =
    useUpdateMyAutoScheduleSettingsMutation()

  if (!autoScheduleSettings) {
    return null
  }

  const onTaskBreakToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    void updateAutoScheduleSettings({ taskBreaksEnabled: e.target.checked })
  }

  const onTaskBreakDurationChange = async (value: string) => {
    void updateAutoScheduleSettings({ taskBreakDuration: parseInt(value) })
  }

  const onTaskBreakIntervalChange = async (value: string) => {
    void updateAutoScheduleSettings({ taskBreakIntervalHours: parseInt(value) })
  }

  return (
    <div className='flex flex-col gap-2'>
      <Paragraph className='pb-1 text-base font-semibold'>
        Break between tasks
      </Paragraph>
      <div className='flex items-center gap-3'>
        <Toggle
          checked={autoScheduleSettings.taskBreaksEnabled}
          onChange={onTaskBreakToggle}
        />
        {autoScheduleSettings.taskBreaksEnabled && (
          <div className='flex items-center gap-3'>
            <Paragraph>Schedule a</Paragraph>
            <Combobox
              autoComplete
              className='w-20 max-w-[80px]'
              value={autoScheduleSettings.taskBreakDuration}
              onChange={onTaskBreakDurationChange}
              options={durationOptions.map((num) => ({
                label: num.toString(),
                value: num,
              }))}
            />
            <Paragraph>min break every</Paragraph>
            <Combobox
              autoComplete
              className='w-20 max-w-[80px]'
              value={autoScheduleSettings.taskBreakIntervalHours}
              onChange={onTaskBreakIntervalChange}
              options={intervalOptions.map((num) => ({
                label: num.toString(),
                value: num,
              }))}
            />
            <Paragraph>hour(s)</Paragraph>
          </div>
        )}
      </div>
    </div>
  )
}
