import { type ProjectSchema, type WorkspaceSchema } from '@motion/rpc-types'
import { showToast } from '@motion/ui/base'

import { useAddEventToProject } from '~/areas/event/hooks'
import { useCallback } from 'react'

import { useEventForm } from './use-event-form'
import { useNavigateFromEventToEvent } from './use-navigate-from-to'

import { useEventModalState } from '../contexts'

export function useFormAddEventToProject() {
  const { form } = useEventForm()
  const { initialEvent } = useEventModalState()
  const addEventToProject = useAddEventToProject()
  const navigateToEvent = useNavigateFromEventToEvent()

  return useCallback(
    async (
      workspaceId: WorkspaceSchema['id'],
      projectId: ProjectSchema['id'] | null
    ) => {
      if (form.formState.isDirty || initialEvent == null) {
        showToast('error', 'Save the event before adding to a project')
        return
      }

      if (projectId == null) {
        showToast('error', 'The event must be within a project')
        return
      }

      const updatedEvent = await addEventToProject(
        initialEvent.id,
        workspaceId,
        projectId
      )

      if (updatedEvent == null || updatedEvent.meetingTaskId == null) return

      navigateToEvent(initialEvent, updatedEvent)
    },
    [addEventToProject, form.formState.isDirty, initialEvent, navigateToEvent]
  )
}
