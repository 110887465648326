import { type FlowTemplateRoleAssignee } from '@motion/ui-logic/pm/project'

import { type AssigneeDropdownOption } from '~/areas/project-management/components'

export function isFlowTemplateRoleAssignee(
  item: AssigneeDropdownOption
): item is FlowTemplateRoleAssignee {
  return item.type === 'person'
}

export function getTaskPathFromStageTaskFieldPath(
  path: `stages.${number}.tasks.${number}.${string}`
) {
  const pathSplit = path.split('.')

  return pathSplit
    .slice(0, pathSplit.length - 1)
    .join('.') as `stages.${number}.tasks.${number}`
}
