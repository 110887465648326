import { useSharedStateQuery } from '@motion/react-core/shared-state'

import { AppWorkspaceContext } from '~/global/contexts'

import { type WorkspaceCustomField } from '../types'
import { getFilteredWorkspaceCustomField } from '../utils'

export const useCustomFieldsRecord = (
  workspaceId: string
): Record<string, WorkspaceCustomField> => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.customFields.all
      .filter((s) => s.workspaceId === workspaceId)
      .reduce((acc, field) => {
        return {
          ...acc,
          [field.id]: getFilteredWorkspaceCustomField(field),
        }
      }, {})
  )
}
