import { type ExtendedDeadlineStatus, getEtaLabel } from '@motion/ui-logic'

import { type BadgeSize, EtaBadge } from '~/global/components/badges'

export type EtaLabelProps = {
  value: ExtendedDeadlineStatus | null
  size?: BadgeSize
  nameOnly?: boolean
}

export function EtaLabel({ size, value, nameOnly = false }: EtaLabelProps) {
  const name = getEtaLabel(value ?? 'none')

  const label = (
    <span title={name} className='truncate'>
      {name}
    </span>
  )

  if (nameOnly) {
    return label
  }

  return (
    <div className='flex gap-1 items-center overflow-hidden leading-4'>
      <EtaBadge size={size} value={value ?? 'none'} />
      {label}
    </div>
  )
}
