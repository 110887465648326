import { isCustomFieldKey } from '@motion/ui-logic'
import { makeLog } from '@motion/web-base/logging'

import { useWorkspaceFns } from '~/global/hooks'
import { type TaskWithRelations } from '~/global/proxies'
import { useClipboard } from '~/localServices/clipboard'
import { useAppSelector } from '~/state/hooks'
import { selectSchedules } from '~/state/settingsSlice'
import { useCallback } from 'react'

import {
  customFieldLookup,
  generateHTMLTable,
  generateMarkdownTable,
  getColumnName,
  taskFieldLookup,
} from '../utils'

const log = makeLog('copy tasks')

export function useCopyTaskToClipboard() {
  const clipboard = useClipboard()
  const { getWorkspaceMembers } = useWorkspaceFns()
  const userSchedules = useAppSelector(selectSchedules)

  return useCallback(
    (list: TaskWithRelations[], columns: string[]) => {
      const valueLookup = (
        item: TaskWithRelations,
        key: string,
        opts?: { asHtml?: boolean }
      ) => {
        if (isCustomFieldKey(key) && item.type === 'NORMAL') {
          const field = item.customFields[key]
          if (field == null) return 'unknown'

          return customFieldLookup(field.type)(
            // @ts-expect-error - type is ok
            field,
            {
              definition: field.definition,
              users: getWorkspaceMembers(item.workspaceId),
            }
          )
        }
        return taskFieldLookup(key)(item, {
          schedules: userSchedules,
          asHtml: opts?.asHtml,
        })
      }

      const markdownTable = log.time('generate markdown table', () => {
        return generateMarkdownTable(
          list,
          columns,
          (key) => getColumnName(key),
          (item: TaskWithRelations, key: string) => valueLookup(item, key)
        )
      })

      const htmlTable = log.time('generate html table', () => {
        return generateHTMLTable(
          list,
          columns,
          (key) => getColumnName(key),
          (item: TaskWithRelations, key: string) =>
            valueLookup(item, key, { asHtml: true })
        )
      })

      void clipboard.write(
        { html: htmlTable, text: markdownTable },
        {
          notificationText: 'Tasks copied successfully',
        }
      )
    },
    [clipboard, getWorkspaceMembers, userSchedules]
  )
}
