import { API, createUseMutation, createUseQuery } from '@motion/rpc'
import {
  computeSavingsPercent,
  INDIVIDUAL_MONTHLY_PRICE,
  makeBillingPrices,
} from '@motion/ui-logic/billing'
import { setAnalyticsTeamId } from '@motion/web-base/analytics'

export const useCurrentTeam = createUseQuery(API.teams.getCurrentTeam, {
  select: (data) => {
    if (data?.id) {
      setAnalyticsTeamId(
        data.id,
        data.name,
        data.teamSubscription?.bucketSeats ??
          data.teamSubscription?.numSeats ??
          0
      )
    }
    return data
  },
})
export const useRenameTeam = createUseMutation(API.teams.renameTeam)
export const useInviteTeamMembers = createUseMutation(
  API.teams.inviteTeamMembers
)
export const useUpdateBucketSeats = createUseMutation(
  API.teams.updateBucketSeats
)
export const useResubscribeTeam = createUseMutation(API.teams.resubscribe)

export const useGetTeamPrices = createUseQuery(
  API.subscriptions.getTeamPrices,
  {
    select: (data) => {
      const teamPrices = makeBillingPrices(data.monthlyPrice, data.annualPrice)
      return {
        ...teamPrices,
        individualToTeamSavingsPercent: computeSavingsPercent(
          INDIVIDUAL_MONTHLY_PRICE,
          teamPrices.monthlyPrice,
          0
        ),
      }
    },
  }
)

export const useUpdateTeamMemberRoles = createUseMutation(
  API.teams.updateTeamMemberRoles
)
