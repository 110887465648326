import {
  isCustomFieldKey,
  parseCustomFieldInfoFromMaybeDelimitedKey,
} from '@motion/ui-logic'

export function getMovementType(type: string) {
  if (isCustomFieldKey(type)) {
    const res = parseCustomFieldInfoFromMaybeDelimitedKey(type)
    if (res == null) {
      return 'default'
    }
    return res.customFieldType
  }
  return type
}
