import { PlusSolid } from '@motion/icons'
import { type LabelSchema, type NormalTaskSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useTaskLabelsData } from '~/areas/tasks/hooks'
import { LabelDropdown } from '~/global/components/dropdowns'
import { PrettyLabels } from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type ReactNode } from 'react'

import { CardButton } from '../components'

export type TaskLabelsFieldProps = {
  task: TaskWithRelations
}
export const TaskLabelsField = ({ task }: TaskLabelsFieldProps) => {
  const { onChange, disabled } = useTaskLabelsData(task)

  if (task.type === 'CHUNK') return null

  return (
    <LabelsField
      selectedLabelIds={task.labelIds}
      workspaceId={task.workspaceId}
      onChange={onChange}
      disabled={disabled}
    >
      <InlineLabels labels={task.labels} />
    </LabelsField>
  )
}

export type ProjectLabelsFieldProps = {
  project: ProjectWithRelations
}
export const ProjectLabelsField = ({ project }: ProjectLabelsFieldProps) => {
  const updateProject = useProjectUpdater()

  return (
    <LabelsField
      selectedLabelIds={project.labelIds}
      workspaceId={project.workspaceId}
      onChange={(labelIds) => updateProject(project, { labelIds })}
    >
      <InlineLabels labels={project.labels} />
    </LabelsField>
  )
}

type LabelsFieldProps = {
  selectedLabelIds: NormalTaskSchema['labelIds']
  workspaceId: NormalTaskSchema['workspaceId']
  onChange: (labelIds: NormalTaskSchema['labelIds']) => void
  disabled?: boolean
  children: ReactNode
}
const LabelsField = ({
  selectedLabelIds,
  workspaceId,
  onChange,
  disabled,
  children,
}: LabelsFieldProps) => {
  return (
    <LabelDropdown
      selectedLabelIds={selectedLabelIds}
      workspaceId={workspaceId}
      onChange={onChange}
    >
      <CardButton fullWidth={selectedLabelIds.length > 0} disabled={disabled}>
        {children}
      </CardButton>
    </LabelDropdown>
  )
}

type InlineLabelsProps = {
  labels: LabelSchema[]
}
const InlineLabels = ({ labels }: InlineLabelsProps) => {
  return labels.length === 0 ? (
    <div className='flex flex-row items-center gap-1 text-semantic-neutral-text-disabled'>
      <PlusSolid className='w-3.5 h-3.5 text-semantic-neutral-icon-subtle' />
      Add label
    </div>
  ) : (
    <PrettyLabels values={labels} />
  )
}
