import {
  type NormalTaskSchema,
  type UploadedFileSchema,
} from '@motion/rpc-types'
import { useHasTreatment } from '@motion/web-common/flags'

import { Attachments, type AttachmentsProps } from '~/areas/attachments'
import { useGetProjectTaskAttachments } from '~/areas/attachments/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useTaskByIdV2 } from '~/global/rpc/v2'

import { AttachmentGroupsCollapsableWrapper } from './attachment-groups-collapsable-wrapper'

type AttachmentGroupsTaskGroupProps = Omit<
  AttachmentGroupsTaskGroupContentProps,
  'task'
> & {
  taskId: NormalTaskWithRelations['id']
}

export function AttachmentGroupsTaskGroup({
  taskId,
  ...rest
}: AttachmentGroupsTaskGroupProps) {
  const { data: task } = useTaskByIdV2({ id: taskId })

  if (!task || task.type !== 'NORMAL') return null

  return <AttachmentGroupsTaskGroupContent task={task} {...rest} />
}

export type AttachmentGroupsTaskGroupContentProps = {
  task: NormalTaskSchema
  uploadedFileIds: UploadedFileSchema['id'][]
  searchQuery?: string
  variant: AttachmentsProps['variant']
  withWrapper?: boolean
  onAttachmentClick?: (attachmentId: UploadedFileSchema['id']) => void
}

function AttachmentGroupsTaskGroupContent({
  task,
  uploadedFileIds,
  searchQuery,
  variant,
  withWrapper = true,
  onAttachmentClick,
}: AttachmentGroupsTaskGroupContentProps) {
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')

  const attachmentsWithDefinition = useGetProjectTaskAttachments({
    targetId: task.id,
    targetType: 'TEAM_TASK',
  })
  const attachmentIds = hasFlowsAttachments
    ? attachmentsWithDefinition.map((attachment) => attachment.id)
    : (task.uploadedFileIds ?? [])
  const attachmentCount = attachmentIds.length

  if (!task || !uploadedFileIds.length) return null

  const attachments = (
    <Attachments
      targetId={task.id}
      targetType='TEAM_TASK'
      attachmentIds={uploadedFileIds}
      variant={variant}
      emptyMessage={
        searchQuery != null && searchQuery.length > 0 && attachmentIds.length
          ? 'No matching attachments found.'
          : undefined
      }
      onAttachmentClick={onAttachmentClick}
    />
  )

  if (!withWrapper) return attachments

  return (
    <AttachmentGroupsCollapsableWrapper
      task={task}
      attachmentCount={attachmentCount}
      searchQuery={searchQuery}
    >
      {attachments}
    </AttachmentGroupsCollapsableWrapper>
  )
}
