/* eslint-disable react-refresh/only-export-components */
import { createContext, type ReactNode, useMemo, useState } from 'react'

import { type InitialTaskData } from '../hooks'

export type TaskModalState = {
  sidePanelOpen: boolean
  toggleSidePanel: () => void
  hasPendingComment: boolean
  setHasPendingComment: (value: boolean) => void
  initialTaskData: InitialTaskData
}

const defaultValue: TaskModalState = {
  sidePanelOpen: false,
  toggleSidePanel: () => {},
  hasPendingComment: false,
  setHasPendingComment: () => {},
  initialTaskData: {
    isLoading: false,
    hasError: false,
    task: undefined,
    parentTask: undefined,
  },
}

export const TaskModalStateContext = createContext(defaultValue)

export type TaskModalStateProviderProps = {
  children: ReactNode
  initialTaskData: InitialTaskData
}

export function TaskModalStateProvider({
  children,
  initialTaskData,
}: TaskModalStateProviderProps) {
  const [sidePanelOpen, setSidePanelOpen] = useState(false)
  const [hasPendingComment, setHasPendingComment] = useState(false)

  const value = useMemo(() => {
    return {
      sidePanelOpen,
      toggleSidePanel: () => setSidePanelOpen((prev) => !prev),
      hasPendingComment,
      setHasPendingComment,
      initialTaskData,
    }
  }, [sidePanelOpen, hasPendingComment, initialTaskData])

  return (
    <TaskModalStateContext.Provider value={value}>
      {children}
    </TaskModalStateContext.Provider>
  )
}
