import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { clearFlowTemplateSearchParams } from '../utils'

export const useRedirectToFlowTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useCallback(
    (flowTemplateId: ProjectDefinitionSchema['id']) => {
      if (
        searchParams.size === 1 &&
        searchParams.get('templateId') === flowTemplateId
      )
        return

      setSearchParams((prev) => {
        clearFlowTemplateSearchParams(prev)
        if (prev.get('templateId') !== flowTemplateId) {
          prev.set('templateId', flowTemplateId)
          prev.set('template', 'edit')
        }
        return prev
      })
    },
    [searchParams, setSearchParams]
  )
}
