import { type CalendarEvent } from '@motion/rpc/types'
import { type CalendarEventSchemaV2 } from '@motion/rpc-types'
import { type AttendeeStatus } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useCallback } from 'react'

import { useUpdateEvent } from './use-update-event'

export const useUpdateEventRSVP = () => {
  const lookup = useLookup()
  const updateCalendarEvent = useUpdateEvent()

  return useCallback(
    (eventId: CalendarEventSchemaV2['id'], status: AttendeeStatus) => {
      const event = lookup('calendarEvents', eventId)
      if (!event) {
        throw new Error('Event cannot be found')
      }

      const nextAttendees = event.attendees.map((attendee) => {
        if (attendee.email === event.email) {
          return { ...attendee, status }
        }

        return attendee
      }) as CalendarEvent['attendees']

      return updateCalendarEvent(eventId, {
        attendees: nextAttendees,
        sendUpdates: true,
      })
    },
    [lookup, updateCalendarEvent]
  )
}
