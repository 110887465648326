import { PopoverButton } from '@motion/ui/base'
import { formatToTaskDuration } from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useController } from 'react-hook-form'

export function BulkDurationFormFields() {
  const { field: valueField } = useController({
    name: 'value',
  })

  return (
    <div className='flex gap-2'>
      <DurationDropdown
        onChange={(type) => valueField.onChange(type)}
        value={valueField.value}
        placement='bottom-start'
      >
        <PopoverButton>{formatToTaskDuration(valueField.value)}</PopoverButton>
      </DurationDropdown>
    </div>
  )
}
