import { type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'
import { Tag, Tooltip } from '@motion/ui/base'
import { formatNumberValue } from '@motion/ui-logic'

import { type ReactNode } from 'react'

type EntityCountTagProps = {
  count: number
  icon: SvgIcon
  tooltipText: ReactNode
}

export function EntityCountTag({
  icon: Icon,
  count,
  tooltipText,
}: EntityCountTagProps) {
  if (count === 0) return null

  return (
    <Tooltip
      placement='bottom-start'
      renderContent={() => <TooltipContent>{tooltipText}</TooltipContent>}
      noPadding
    >
      <Tag variant='subtle' size='small'>
        <div className='flex flex-row gap-1 items-center justify-center'>
          <Icon className='size-3' />
          {formatNumberValue(count, 'formatted')}
        </div>
      </Tag>
    </Tooltip>
  )
}

const TooltipContent = classed('div', {
  base: 'flex px-2 py-1.5 items-center self-stretch text-semantic-neutral-text-inverse text-xs font-semibold',
})
