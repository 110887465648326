import { selectDesktopVersion } from '~/state/desktopSlice'
import { useAppSelector } from '~/state/hooks'

import { useDesktopState } from './use-desktop-state'

export function useDesktopVersion() {
  const [{ desktopVersion }] = useDesktopState()
  const selectorVersion = useAppSelector(selectDesktopVersion)

  return desktopVersion || selectorVersion || '0.23.0'
}
