import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { clearProjectForParams } from '../utils'

export type CloseProjectModalOptions = {
  force?: boolean
}

export const useCloseProjectModal = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return useCallback(
    ({ force = false }: CloseProjectModalOptions = {}) => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('project')
      searchParams.delete('forTaskId')
      clearProjectForParams(searchParams)

      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        {
          state: {
            // This will force navigating without showing the unsaved changes prompt
            force,
          },
        }
      )
    },
    [location.pathname, location.search, navigate]
  )
}
