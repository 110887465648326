import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import {
  isUnassignedUser,
  unassignedMember,
  type WorkspaceMemberWithUser,
} from '@motion/ui-logic'
import { type TaskSchema } from '@motion/zod/client'

import { type AssigneeDropdownContentProps } from '~/areas/project-management/components/dropdowns/assignee-dropdown/types'
import { UserLabel } from '~/global/components/labels'
import { type ReactNode, useMemo } from 'react'

import { RestrictWidth } from '../../../components'
import { DropdownContentTopSection } from '../dropdown-content-top-section'
import { DropdownContainer } from '../styled'

export type BulkAssigneeDropdownProps = Omit<
  BulkAssigneeDropdownContentProps,
  'close'
> & {
  children?: ReactNode
}

export const BulkAssigneeDropdown = ({
  children,
  ...rest
}: BulkAssigneeDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkAssigneeDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type BulkAssigneeDropdownContentProps = {
  close: () => void
  onChange: (id: TaskSchema['assigneeUserId']) => void
  selectedUserId: TaskSchema['assigneeUserId'] | undefined
  filteredUsers: WorkspaceMemberWithUser[][]
  removeUnassignedOption?: AssigneeDropdownContentProps['removeUnassignedOption']
}
const BulkAssigneeDropdownContent = ({
  close,
  onChange,
  selectedUserId,
  filteredUsers,
  removeUnassignedOption = false,
}: BulkAssigneeDropdownContentProps) => {
  const items = useMemo(() => {
    if (removeUnassignedOption) return filteredUsers
    return [[unassignedMember], ...filteredUsers]
  }, [removeUnassignedOption, filteredUsers])

  return (
    <DropdownContainer>
      <DropdownContentTopSection>
        Only showing assignees shared between all tasks you selected
      </DropdownContentTopSection>
      <SearchableList
        searchable
        items={items}
        computeKey={(item) => item[0].userId}
        computeSearchValue={(item) => item[0].user.name}
        computeSelected={(item) =>
          (selectedUserId === null && isUnassignedUser(item[0])) ||
          item[0].userId === selectedUserId
        }
        onSelect={(item) => {
          close()
          onChange(isUnassignedUser(item[0]) ? null : item[0].userId)
        }}
        renderItem={(item) => (
          <RestrictWidth>
            <UserLabel value={item[0].user} />
          </RestrictWidth>
        )}
        inputProps={{ placeholder: 'Choose assignee...' }}
      />
    </DropdownContainer>
  )
}
