import { Sentry } from '@motion/web-base/sentry'
import { type ProjectSchema } from '@motion/zod/client'

import {
  type RouteAnalyticsMetadataParams,
  useRouteAnalyticsMetadata,
} from '~/global/analytics'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

type ProjectAnalytics = RouteAnalyticsMetadataParams & {
  project_type: 'regular' | 'flow' | 'template'
}

export const useProjectAnalytics = () => {
  const analyticsMetadata = useRouteAnalyticsMetadata()
  const { getWorkspaceProjectById, getWorkspaceProjectDefinitionById } =
    useWorkspaceFns()

  return useCallback(
    (id: ProjectSchema['id']): ProjectAnalytics => {
      const project = getWorkspaceProjectById(id)
      if (project == null) {
        const e = new Error('Project not found')
        Sentry.captureException(e, {
          extra: {
            projectId: id,
          },
        })
        throw e
      }

      const projectDefinition = getWorkspaceProjectDefinitionById(
        project.projectDefinitionId
      )
      const projectType =
        projectDefinition == null
          ? 'regular'
          : projectDefinition.stages.length > 1
            ? 'flow'
            : 'template'

      return {
        project_type: projectType,
        ...analyticsMetadata,
      }
    },
    [
      analyticsMetadata,
      getWorkspaceProjectById,
      getWorkspaceProjectDefinitionById,
    ]
  )
}
