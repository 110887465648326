import { type SupportedCustomFieldFilterStringOperators } from '@motion/ui-logic/pm/data'

import { type ConditionOption } from '../../filter-item'

export const ALLOWED_STRING_OPERATORS = [
  { id: 'contains', label: 'including' },
  { id: 'beginsWith', label: 'beginning with' },
  { id: 'endsWith', label: 'ending with' },
  { id: 'empty', label: 'none' },
] as const satisfies ConditionOption<SupportedCustomFieldFilterStringOperators>[]

export type TextFilterAllowedFields = 'text' | 'url'
