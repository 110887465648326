import { useEffect, useState } from 'react'

import type { MotionLocalStorageItems, MotionLocalStorageResult } from './types'

import api from '../../chromeApi/chromeApiContentScript'
import { type StorageChangeResult } from '../../chromeApi/chromeApiTypes'

export const useMotionLocalStorage = <T extends keyof MotionLocalStorageItems>(
  keys: readonly T[]
): MotionLocalStorageResult<T> => {
  const [values, setValues] = useState<MotionLocalStorageResult<T>>(
    {} as MotionLocalStorageResult<T>
  )

  useEffect(() => {
    function refreshData() {
      void api.storage.local
        .get(keys)
        .then((storageValues) =>
          setValues(storageValues as MotionLocalStorageResult<T>)
        )
    }

    function onChange(changes: StorageChangeResult) {
      setValues((prev) => {
        const requestedChanges = keys.reduce((acc, key) => {
          if (changes[key]?.oldValue === changes[key]?.newValue) return acc
          if (key in changes) {
            acc[key] = changes[key].newValue
          }
          return acc
        }, {} as MotionLocalStorageResult<T>)

        if (Object.keys(requestedChanges).length === 0) return prev

        return { ...prev, ...requestedChanges }
      })
    }

    refreshData()

    return api.storage.onChanged.addListener(onChange)

    // We want to re-trigger the effect when the value of the keys changes, not the keys themselves
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...keys])

  return values as MotionLocalStorageResult<T>
}
