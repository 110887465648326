import { classed } from '@motion/theme'
import {
  RichTextContent,
  RichTextImageBubbleMenu,
  RichTextToolbar,
} from '@motion/ui/rte'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { useDescriptionEditor, type UseDescriptionEditorProps } from '../hooks'

export type DescriptionEditorProps = Omit<
  UseDescriptionEditorProps,
  'editorContainerRef'
> & {
  variant?: 'default' | 'sidepanel'
}

export const DescriptionEditor = ({
  variant = 'default',
  ...editorProps
}: DescriptionEditorProps) => {
  const editorContainerRef = useRef<HTMLDivElement>(null)
  const modalApi = useModalApi()

  const { editor, uploadWorkspaceImage } = useDescriptionEditor({
    ...editorProps,
    editorContainerRef,
  })

  const { disabled, disableImageUpload } = editorProps

  const openLinkModalPrompt = async (initialLink: string = '') => {
    const response = await modalApi.prompt('link-modal', {
      initialLink,
    })

    if (response !== ModalDismissed) {
      return response
    }
    return null
  }

  return (
    <EditorContainer ref={editorContainerRef}>
      {!disableImageUpload && <RichTextImageBubbleMenu editor={editor} />}
      <div
        className={twMerge(
          'py-1 sticky top-0 z-[1]',
          variant === 'default'
            ? 'bg-modal-bg'
            : 'bg-semantic-neutral-surface-overlay-bg-default'
        )}
      >
        <RichTextToolbar
          editor={editor}
          disabled={disabled}
          disableImageUpload={disableImageUpload}
          onFileUpload={!disableImageUpload ? uploadWorkspaceImage : undefined}
          onLinkClick={openLinkModalPrompt}
          size={variant === 'sidepanel' ? 'small' : 'normal'}
        />
      </div>
      <StyledRichTextContent editor={editor} disabled={disabled} />
    </EditorContainer>
  )
}

const EditorContainer = classed('div', {
  base: `
    relative
    gap-3
    flex-1
    flex flex-col
  `,
})

// min-height prevents the editor from collapsing when empty
const StyledRichTextContent = classed(RichTextContent, {
  base: `
    outline-0
    bg-transparent
    px-0.5
    w-full
    [&>.ProseMirror]:min-h-[300px]
    flex flex-col
    flex-grow shrink-0
  `,
})
