import { type CalendarOptions } from '@fullcalendar/core'
import { AgendaCalendarHeader } from '~/areas/agenda/right-sidebar/components/agenda-calendar-header'
import { useCalendarCommonProps } from '~/areas/calendar/hooks/use-calendar-common-props'
import { useMainCalendarHandlers } from '~/areas/calendar/main-calendar/hooks'
import { CalendarEventContainer } from '~/components/Calendar/components/Main/CalendarEvents/CalendarEventContainer'
import { calendarAvailabilitiesSelectors } from '~/state/calendarEvents/calendarEventsSlice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

import { useAgendaFcEvents } from './use-agenda-fc-events'

export const useAgendaSidebarCalendarProps = (): CalendarOptions => {
  const commonProps = useCalendarCommonProps({
    shouldShowSecondaryTimezone: false,
  })
  const calendarEvents = useAgendaFcEvents()
  const handlers = useMainCalendarHandlers()

  const availabilityEvents = useAppSelector((state) =>
    calendarAvailabilitiesSelectors.selectAll(state)
  )

  const events = useMemo(() => {
    return [...availabilityEvents, ...calendarEvents]
  }, [availabilityEvents, calendarEvents])

  return useMemo(() => {
    return {
      ...commonProps,
      initialView: 'timeGridDay',
      // Triggered when an empty calendar slot is selected by dragging. Clicking an event trigger eventClick.
      select: handlers.onEmptySlotDragEnd,
      // Triggered when an empty calendar slot is clicked.
      dateClick: handlers.onEmptySlotClick,
      events,
      eventContent: (event) => {
        return <CalendarEventContainer {...event} />
      },
      dayHeaderContent: () => <AgendaCalendarHeader />,
      dayHeaderClassNames: 'h-16',
    }
  }, [
    commonProps,
    events,
    handlers.onEmptySlotClick,
    handlers.onEmptySlotDragEnd,
  ])
}
