import { PopoverTrigger } from '@motion/ui/base'
import { DEFAULT_DURATION } from '@motion/ui-logic'

import { type ReactNode } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { type BulkOpsFieldsValue } from '../../contexts'
import { BulkDropdownForm, BulkDurationFormFields } from '../forms'

type BulkDurationDropdownProps = {
  onChange: (duration: BulkOpsFieldsValue['fields']['duration']) => void
  selectedDuration: BulkOpsFieldsValue['fields']['duration']
  children?: ReactNode
}

type BulkDurationFormFieldsValues = {
  value: BulkOpsFieldsValue['fields']['duration']
}

export function BulkDurationDropdown({
  children,
  ...rest
}: BulkDurationDropdownProps) {
  const form = useForm<BulkDurationFormFieldsValues>({
    defaultValues: {
      value: rest.selectedDuration ?? DEFAULT_DURATION,
    },
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const onSubmit: SubmitHandler<BulkDurationFormFieldsValues> = async (
    fieldValues
  ) => {
    rest.onChange(fieldValues.value)
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkDropdownForm
          form={form}
          onSubmit={(e) => {
            form.handleSubmit(onSubmit)(e)
            close()
          }}
          onReset={close}
          label='Duration'
        >
          <BulkDurationFormFields />
        </BulkDropdownForm>
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
