import { classed } from '@motion/theme'

import { UserBadge } from '~/global/components/badges'

import { type UserInfo } from '../../types'

type VerticalUserProps = {
  user: UserInfo
}

export const VerticalUser = ({ user }: VerticalUserProps) => {
  return (
    <Container>
      <UserBadge
        value={{
          id: user.id,
          name: user.name,
          email: user.email,
          picture: user.picture,
          deleted: false,
          onboardingComplete: true,
          isPlaceholder: false,
          hasActiveSubscription: true,
        }}
        size='large'
      />
      <div
        className='pointer-events-none origin-center overflow-hidden text-ellipsis whitespace-nowrap'
        style={{
          writingMode: 'vertical-lr',
          transform: 'rotate(180deg)',
          lineHeight: '24px',
        }}
      >
        {user.name}
      </div>
    </Container>
  )
}

const Container = classed('div', {
  base: `
    flex flex-col gap-2 flex-1
    relative overflow-hidden
  `,
})
