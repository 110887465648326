import { CalendarSolid } from '@motion/icons'
import { FieldButton, type FieldButtonProps, Tooltip } from '@motion/ui/base'
import { getDateButtonText } from '@motion/ui-logic/pm/task'

import { forwardRef, type ReactNode } from 'react'

import { useTemplateTaskForm } from '../hooks'

export type RenderOptions = {
  icon: ReactNode
  date: string | null
  disabled: boolean
  placeholder: string
  prefix?: string
  tooltip?: string
} & Pick<FieldButtonProps, 'onClear' | 'clearTooltipContent'>

type FormStartDateDropdownProps = {
  children: (opts: RenderOptions) => ReactNode
}

export const FormStartDateDropdown = ({
  children,
}: FormStartDateDropdownProps) => {
  const { disabledFields } = useTemplateTaskForm()

  const isDisabled = disabledFields.has('startDate')

  return children({
    icon: <CalendarSolid />,
    date: null,
    disabled: isDisabled,
    placeholder: 'TBD',
    prefix: '',
    tooltip: 'Start date is set when using the template.',
  })
}

export type StartDateButtonProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth' | 'sentiment'
> &
  RenderOptions

export const DateDropdownButton = forwardRef<
  HTMLButtonElement,
  StartDateButtonProps
>((props, ref) => {
  const { icon, date, placeholder, prefix, tooltip, ...rest } = props

  const dateButtonString = getDateButtonText(date, { prefix, placeholder })

  return (
    <FieldButton ref={ref} {...rest}>
      {icon}

      <Tooltip content={tooltip}>
        <span className='tabular-nums'>{dateButtonString}</span>
      </Tooltip>
    </FieldButton>
  )
})
DateDropdownButton.displayName = 'DateDropdownButton'
