import { ButtonTabs } from '@motion/ui/base'
import {
  type AttendeeStatus,
  type CalendarEventSchemaV2,
} from '@motion/zod/client'

import { useEventRSVPData } from '~/areas/event/hooks'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventRSVPField = () => {
  const { initialEvent, isLoading } = useEventModalState()

  if (isLoading || initialEvent == null) return null

  return <InternalEventRSVPField event={initialEvent} />
}

const InternalEventRSVPField = ({
  event: initialEvent,
}: {
  event: CalendarEventSchemaV2
}) => {
  const { form } = useEventForm()

  const { canRSVP, userRSVP, onChangeRSVP } = useEventRSVPData(initialEvent)

  if (!canRSVP) {
    return null
  }

  const onChange = async (value: AttendeeStatus) => {
    const updatedEvent = await onChangeRSVP(value)
    if (updatedEvent != null) {
      form.setValue('attendees', updatedEvent.attendees)
    }
  }

  return (
    <div className='flex flex-row justify-between items-center'>
      <span className='text-semantic-neutral-text-subtle text-2xs leading-4'>
        Going?
      </span>
      <ButtonTabs
        size='xsmall'
        items={options.map(({ value, label }) => ({
          value,
          content: label,
          onAction: () => onChange(value),
        }))}
        activeValue={userRSVP}
      />
    </div>
  )
}

const options = [
  {
    value: 'accepted' as const,
    label: 'Yes',
  },
  {
    value: 'declined' as const,
    label: 'No',
  },
  {
    value: 'tentative' as const,
    label: 'Maybe',
  },
]
