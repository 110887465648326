import { useContextMenu } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { TabHeader } from '../../../components'
import { ExpandButton } from '../../../tree-list/cells/components'
import { ExpandAndCollapseMenu } from '../../../tree-list/components'
import { ZIndexMap } from '../../shared-constants'
import { type PlannerRowProps } from '../common'
import { StyledPlannerRowContainer } from '../container'

type HeaderRowProps = PlannerRowProps<any> & {
  toggleExpandAllRows: (expand: boolean) => void
}
export const HeaderRow = ({
  row,
  toggleExpandAllRows,
  ...props
}: HeaderRowProps) => {
  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_GROUP_RIGHT_CLICK', {
        depth: row?.depth ?? 0,
      })
    },
  })

  if (!row) return null

  return (
    <>
      <StyledPlannerRowContainer
        role='header'
        {...props}
        expandable
        onContextMenu={handleContextMenu}
        style={{ zIndex: ZIndexMap.headerRow, ...props.style }}
      >
        <div className='overflow-hidden flex gap-3 items-center relative px-2.5'>
          <ExpandButton row={row} />
          <TabHeader item={{ ...row.original, item: row.original }} active />
        </div>
      </StyledPlannerRowContainer>
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ExpandAndCollapseMenu
            close={close}
            onCollapse={() => {
              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_PLANNER_GROUP_RIGHT_CLICK_ACTION',
                {
                  depth: row?.depth ?? 0,
                  action: 'collapse-all',
                }
              )
              toggleExpandAllRows(false)
            }}
            onExpand={() => {
              recordAnalyticsEvent(
                'PROJECT_MANAGEMENT_PLANNER_GROUP_RIGHT_CLICK_ACTION',
                {
                  depth: row?.depth ?? 0,
                  action: 'expand-all',
                }
              )
              toggleExpandAllRows(true)
            }}
          />
        )}
      />
    </>
  )
}
