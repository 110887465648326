import { FilledChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, Tooltip } from '@motion/ui/base'

import { type Row } from '@tanstack/react-table'

type ExpandButtonProps = {
  row: Row<any>
}

export const ExpandButton = ({ row }: ExpandButtonProps) => {
  const expanded = row.getIsExpanded()
  return (
    <Tooltip asChild content='Right click to expand/collapse all'>
      <Button
        iconOnly
        size='xsmall'
        sentiment='neutral'
        variant='muted'
        onClick={row.getToggleExpandedHandler()}
      >
        <ExpandIcon expanded={expanded} />
      </Button>
    </Tooltip>
  )
}

const ExpandIcon = classed(FilledChevronRightSolid, {
  base: `transition-transform`,
  variants: {
    expanded: {
      true: 'rotate-90',
      false: '',
    },
  },
  defaultVariants: {
    expanded: 'false',
  },
})
