import { classed } from '@motion/theme'
import { truncateAtSpace } from '@motion/ui-logic'

import { type FC, type PropsWithChildren } from 'react'

export const GroupContainer = classed('div', 'inline-flex gap-1 items-center')

export const GroupHint = classed(
  'span',
  'text-semantic-neutral-text-subtle truncate leading-4'
)

export const GroupHeaderWithFieldName: FC<
  PropsWithChildren<{ fieldName: string }>
> = ({ children, fieldName }) => (
  <GroupContainer>
    {children}

    <GroupHint>{`(${truncateAtSpace(fieldName)})`}</GroupHint>
  </GroupContainer>
)
