import { createStateKey } from '@motion/react-core/shared-state'

export const ScrollPositionKey = createStateKey<number>(
  'plannerScrollPosition',
  { defaultValue: 0 }
)
export const ScrollDeltaKey = createStateKey<number>('plannerScrollDelta', {
  defaultValue: 0,
})

export const PlannerPropsKey = createStateKey<{
  resizingId: string | null
  containerRect: DOMRect | null
  containerNode: Element | null
  dayPx: number
  scrollTo: number | null
  shiftModifier: boolean
}>('plannerProps', {
  defaultValue: {
    resizingId: null,
    containerRect: null,
    containerNode: null,
    dayPx: 15,
    scrollTo: null,
    shiftModifier: false,
  },
})
