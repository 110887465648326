import { formatDurationToShort } from '@motion/ui-logic'
import { type ProjectSchema } from '@motion/zod/client'

import { useProjectStageByProjectAndDefinitionId } from '~/areas/project/hooks/data'

import { type GroupedNode } from '../../../grouping'
import { CellText } from '../components'
import { useProjectInTree } from '../hooks'

export type StageTotalDurationCellProps = {
  row: GroupedNode
  stageDefinitionId: string
}

export const StageTotalDurationCell = ({
  row,
  stageDefinitionId,
}: StageTotalDurationCellProps) => {
  const project = useProjectInTree(row)
  if (!project) return null

  return (
    <InnerStageTotalDurationCell
      project={project}
      stageDefinitionId={stageDefinitionId}
    />
  )
}

type InnerStageTotalDurationCellProps = {
  project: ProjectSchema
  stageDefinitionId: string
}

const InnerStageTotalDurationCell = ({
  project,
  stageDefinitionId,
}: InnerStageTotalDurationCellProps) => {
  const stage = useProjectStageByProjectAndDefinitionId(
    project,
    stageDefinitionId
  )
  if (!stage) return null

  return (
    <CellText className='flex-nowrap gap-2'>
      <span className='text-semantic-neutral-text-subtle leading-5 truncate'>
        {formatDurationToShort(stage.duration - stage.canceledDuration)}
      </span>
    </CellText>
  )
}
