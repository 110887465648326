import { FormModal, showToast } from '@motion/ui/base'
import {
  type AllAvailableCustomFieldSchema,
  truncateAtSpace,
} from '@motion/ui-logic'
import { isEqual } from '@motion/utils/core'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type WorkspaceSchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import {
  type GetCustomFieldsCategoriesResponse,
  useGetAvailableCustomFieldCategories,
} from '~/areas/project-management/hooks'
import { useUpdateCustomField } from '~/areas/project-management/hooks/custom-fields/rpc'
import { useWorkspaceById } from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from 'react-hook-form'

import { formatEditFieldArgs } from './utils'

import { DuplicateOptionError } from '../../errors'
import { CustomFieldSettings } from '../components'
import {
  type EditCustomFieldFormFields,
  handleOptionErrors,
} from '../form-utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'edit-custom-field': {
      workspaceId: WorkspaceSchema['id']
      customField: AllAvailableCustomFieldSchema
    }
  }
}

export const ConnectedEditCustomFieldModal = (
  props: ModalTriggerComponentProps<'edit-custom-field'>
) => {
  const categoriesResponse = useGetAvailableCustomFieldCategories()

  return (
    <ConnectedEditCustomFieldFormModal {...props} {...categoriesResponse} />
  )
}

const getDefaultValues = (
  customField: AllAvailableCustomFieldSchema
): EditCustomFieldFormFields => {
  switch (customField.type) {
    case 'date':
    case 'text':
    case 'number':
    case 'url':
    case 'person':
    case 'multiPerson':
      return customField
    case 'select':
    case 'multiSelect':
      const filteredOptions = customField.metadata.options.filter(
        (option) => !option.deletedTime
      )
      const newField = customField
      newField.metadata.options = filteredOptions
      return newField
    default:
      throw new Error('Unhandled field type')
  }
}

type ConnectedEditCustomFieldFormModalProps =
  ModalTriggerComponentProps<'edit-custom-field'> &
    GetCustomFieldsCategoriesResponse

const ConnectedEditCustomFieldFormModal = ({
  close: onClose,
  workspaceId,
  customField,
  ...rest
}: ConnectedEditCustomFieldFormModalProps) => {
  const form = useForm<EditCustomFieldFormFields>({
    defaultValues: getDefaultValues(customField),
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const { formState, reset, watch } = form
  const { mutateAsync: updateCustomField } = useUpdateCustomField()
  const workspace = useWorkspaceById(workspaceId)
  if (workspace == null) {
    throw new Error('Workspace not found')
  }
  const { name: workspaceName } = workspace

  const onCloseModal = () => {
    onClose()
    reset()
  }

  const type = watch('type')

  const onSubmit: SubmitHandler<EditCustomFieldFormFields> = async (data) => {
    try {
      const args = formatEditFieldArgs(workspaceId, customField.id, data)
      if (args == null) {
        throw new Error('Unhandled field type')
      }

      await updateCustomField(args, {
        onError(e) {
          showErrorToast(e)
        },
      })

      recordAnalyticsEvent('CUSTOM_FIELD_EDITED', {
        type: data.type,
      })

      onCloseModal()
      showToast('success', 'Custom field updated successfully')
    } catch (error) {
      if (error instanceof DuplicateOptionError) {
        showErrorToast(error)
      }

      throw error
    }
  }

  /**
   * We handle errors for the field array fields here because, unforunately,
   * react-hook-form does not provide reliable error handling for these fields at the hook level.
   * See https://github.com/react-hook-form/react-hook-form/issues/3254.
   */
  const onInvalid: SubmitErrorHandler<EditCustomFieldFormFields> = (errors) =>
    handleOptionErrors(errors, type)

  const disabled = formState.isSubmitting || isEqual(customField, watch())

  return (
    <FormProvider {...form}>
      <FormModal
        title={`${truncateAtSpace(customField.name)} in ${workspaceName}`}
        visible
        onClose={onCloseModal}
        submitAction={{
          onAction: form.handleSubmit(onSubmit, onInvalid),
          disabled,
        }}
      >
        <CustomFieldSettings isEditCustomField {...rest} />
      </FormModal>
    </FormProvider>
  )
}
