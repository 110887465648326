import { classed } from '@motion/theme'
import { Modal } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type WorkspaceSummarySchema } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useGetTeamPrices } from '~/global/rpc/team'
import { type ReactNode, useState } from 'react'

import { useCancellationAnalytics } from './cancellation-analytics'
import { type CancelScreenProps } from './components/types'
import { BeforeYouGo } from './pages/before-you-go'
import { CancellationConfirmation } from './pages/cancellation-confirmation'
import { CancellationReason } from './pages/cancellation-reason'
import { Discount } from './pages/discount'
import { DiscountSuccess } from './pages/discount-success'
import { FinalInterception } from './pages/final-interception'
import { type CancelFlowScreen } from './types'

const baseFlow: CancelFlowScreen[] = ['before_you_go', 'cancellation_reason']

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'cancel-account-modal': CancelAccountModalProps
  }
}

interface CancelAccountModalProps {
  workspaceSummary?: WorkspaceSummarySchema
}

export const CancelAccountModal = ({
  workspaceSummary,
  close,
}: ModalTriggerComponentProps<'cancel-account-modal'>) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [branchScreen, setBranchScreen] = useState<CancelFlowScreen | null>(
    null
  )
  const [finalScreen, setFinalScreen] =
    useState<CancelFlowScreen>('final_interception')

  const screens = branchScreen
    ? [...baseFlow, branchScreen, finalScreen]
    : [...baseFlow, finalScreen]
  const activeScreen = screens[activeIndex]
  useCancellationAnalytics(activeScreen)

  const { data: teamPrices } = useGetTeamPrices()

  const onCloseModal = () => {
    setActiveIndex(0)
    close()
    setBranchScreen(null)
    setFinalScreen('final_interception')
    recordAnalyticsEvent('CANCELLATION_INTERCEPTION_CLOSE_MODAL')
  }

  const onNextClick = () => {
    setActiveIndex(activeIndex + 1)
  }

  return (
    <Modal visible onClose={onCloseModal}>
      <div>
        {renderScreenMap[activeScreen]({
          onNextClick,
          setNextScreen:
            activeScreen === 'cancellation_reason'
              ? setBranchScreen
              : setFinalScreen,
          onCloseModal,
          workspaceSummary,
          teamPrices,
        })}
      </div>
    </Modal>
  )
}

const renderScreenMap: Record<
  CancelFlowScreen,
  (props: CancelScreenProps) => ReactNode
> = {
  before_you_go: (props) => (
    <ModalMain gradient='warning'>
      <BeforeYouGo {...props} />
    </ModalMain>
  ),
  cancellation_reason: (props) => (
    <ModalMain gradient='primary'>
      <CancellationReason {...props} />
    </ModalMain>
  ),
  discount: (props) => {
    if (!props.teamPrices) return null
    return (
      <ModalMain gradient='success'>
        <Discount {...props} teamPrices={props.teamPrices} />
      </ModalMain>
    )
  },
  discount_success: (props) => (
    <ModalMain gradient='success' short>
      <DiscountSuccess {...props} />
    </ModalMain>
  ),
  final_interception: (props) => <FinalInterception {...props} />,
  cancellation_confirmation: (props) => <CancellationConfirmation {...props} />,
}

const ModalMain = classed('div', {
  base: 'flex flex-col justify-between h-[561px] w-[519px] bg-gradient-to-b to-[120px]',
  variants: {
    gradient: {
      warning: 'from-semantic-warning-bg-default',
      primary: 'from-semantic-primary-bg-default',
      success: 'from-semantic-success-bg-default',
    },
    short: {
      true: 'h-[367px] p-0 via-40%',
    },
  },
})
