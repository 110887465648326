import { usePrevious } from '@motion/react-core/hooks'
import { type ProjectSchema, type UploadedFileSchema } from '@motion/rpc-types'
import { useHasTreatment } from '@motion/web-common/flags'

import { Attachments, type AttachmentsProps } from '~/areas/attachments'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { useGetProjectAttachmentsCount } from '~/areas/attachments/hooks'
import { useProject } from '~/global/hooks'
import { useMemo } from 'react'

import { AttachmentGroupsCollapsableWrapper } from './attachment-groups-collapsable-wrapper'

type AttachmentGroupsProjectGroupProps = Omit<
  AttachmentGroupsProjectGroupContentProps,
  'project'
> & {
  projectId: ProjectSchema['id']
}

export function AttachmentGroupsProjectGroup({
  projectId,
  ...rest
}: AttachmentGroupsProjectGroupProps) {
  const project = useProject(projectId)

  if (!project) return null

  return <AttachmentGroupsProjectGroupContent project={project} {...rest} />
}

export type AttachmentGroupsProjectGroupContentProps = {
  project: ProjectSchema
  uploadedFileIds: UploadedFileSchema['id'][]
  searchQuery?: string
  variant: AttachmentsProps['variant']
  withWrapper?: boolean
  onAttachmentClick?: (attachmentId: UploadedFileSchema['id']) => void
}

function AttachmentGroupsProjectGroupContent({
  project,
  uploadedFileIds,
  searchQuery,
  variant,
  withWrapper = true,
  onAttachmentClick,
}: AttachmentGroupsProjectGroupContentProps) {
  const hasFlowsAttachments = useHasTreatment('flows-pwt-attachments')
  const { projectAttachmentCount } = useGetProjectAttachmentsCount({
    projectId: project.id,
  })
  const unfilteredAttachmentCount = hasFlowsAttachments
    ? projectAttachmentCount
    : project.uploadedFileIds.length

  const { activeFileUploads } = useFileUploadState()

  const filteredActiveFileUploads = useMemo(
    () =>
      activeFileUploads.filter(
        (activeFileUpload) =>
          activeFileUpload.targetId === project.id &&
          activeFileUpload.targetType === 'PROJECT'
      ),
    [activeFileUploads, project.id]
  )

  const prevFilteredActiveFileUploads = usePrevious(filteredActiveFileUploads)

  if (
    !uploadedFileIds.length &&
    !filteredActiveFileUploads.length &&
    !prevFilteredActiveFileUploads?.length
  )
    return null

  const attachments = (
    <Attachments
      targetId={project.id}
      targetType='PROJECT'
      attachmentIds={uploadedFileIds}
      variant={variant}
      emptyMessage={
        searchQuery != null &&
        searchQuery.length > 0 &&
        unfilteredAttachmentCount > 0
          ? 'No matching attachments found.'
          : undefined
      }
      onAttachmentClick={onAttachmentClick}
    />
  )

  if (!withWrapper) return attachments

  return (
    <AttachmentGroupsCollapsableWrapper
      project={project}
      attachmentCount={unfilteredAttachmentCount}
      activeUploadCount={filteredActiveFileUploads.length}
      searchQuery={searchQuery}
    >
      <div className='pl-3 pb-3'>{attachments}</div>
    </AttachmentGroupsCollapsableWrapper>
  )
}
