import { useActiveFilter } from '@motion/ui-logic/pm/data'

import { useParams } from 'react-router'

import { type GroupedNode } from '../../grouping'
import { type TreeListRowValueType } from '../../tree-list'

export function useOptionsVisibility(
  treeItem: GroupedNode<TreeListRowValueType> | null
) {
  const { workspaceId, projectId } = useParams<{
    workspaceId: string
    projectId: string
  }>()
  const [filter] = useActiveFilter()

  const showWorkspaceOptions =
    treeItem != null && projectId == null && workspaceId != null
  const showProjectOptions =
    treeItem != null && projectId != null && workspaceId != null
  const showCopyTasks =
    treeItem != null && filter.target === 'tasks' && treeItem.count !== 0

  return {
    hasAnyOptionVisible:
      showWorkspaceOptions || showProjectOptions || showCopyTasks,

    showWorkspaceOptions,
    showProjectOptions,
    showCopyTasks,
  }
}
