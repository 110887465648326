import { mapCustomFieldToFieldArrayWithValue } from '@motion/ui-logic'
import { type FlowTemplateStage } from '@motion/ui-logic/pm/project'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useWorkspaceStatuses } from '~/global/hooks'

import { getNewStageData } from '../utils'

export const useDefaultFlowStages = (
  workspaceId: string,
  currentUserId: string
): FlowTemplateStage[] => {
  const workspaceStatuses = useWorkspaceStatuses(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
    mapCustomFieldToFieldArrayWithValue(field, {})
  )

  return [
    getNewStageData({
      currentUserId,
      workspaceStatuses,
      name: 'Design',
      color: 'yellow',
      customFieldValuesFieldArray,
    }),
    getNewStageData({
      currentUserId,
      workspaceStatuses,
      name: 'Development',
      color: 'red',
      customFieldValuesFieldArray,
    }),
  ]
}
