import { type TaskType } from '@motion/rpc-types'
import {
  CustomEventHandlers,
  KeyboardShortcuts,
  type KeyboardShortcutsOptions,
  useRichTextEditor,
} from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  FlowVariable,
  type FlowVariableProps,
  useSetTiptapStorage,
} from '~/areas/flows/tiptap-extensions'
import { useUploadWorkspaceImage } from '~/areas/project-management/hooks'
import { startTransition, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

export type UseDescriptionEditorProps = {
  workspaceId: string
  taskId?: string
  projectId?: string
  taskType?: TaskType
  value: string | null
  onChange: (value: string) => void
  onBlur?: (value: string, event: FocusEvent) => void
  autoFocus?: boolean
  disabled?: boolean
  editorClassName?: string
  disableImageUpload?: boolean
  shortcuts?: KeyboardShortcutsOptions['shortcuts']
  variableArgs?: FlowVariableProps
  editorContainerRef: React.RefObject<HTMLDivElement>
}

export const useDescriptionEditor = ({
  workspaceId,
  taskId,
  projectId,
  taskType,
  value,
  onChange,
  onBlur,
  autoFocus,
  disabled,
  editorClassName,
  disableImageUpload,
  shortcuts,
  variableArgs,
  editorContainerRef,
}: UseDescriptionEditorProps) => {
  const isUsingVariables = variableArgs != null

  const uploadWorkspaceImage = useUploadWorkspaceImage(workspaceId, {
    taskId,
    projectId,
    isRecurringTask: taskType === 'RECURRING_TASK',
  })

  const editor = useRichTextEditor({
    className: twMerge('h-full overflow-y-hidden', editorClassName),
    placeholder: isUsingVariables
      ? 'Enter description (type "/" to use text variables)'
      : 'Description',
    value: value ?? '',
    disabled,
    disableImageUpload,
    onChange(value) {
      startTransition(() => {
        onChange(value)
      })
    },
    onBlur: onBlur
      ? (v, event) => {
          if (editorContainerRef.current?.contains(event.relatedTarget as Node))
            return

          onBlur(v, event)
        }
      : undefined,
    onImgExpandClick: () => {
      recordAnalyticsEvent('IMAGE_EXPAND_CLICK')
    },
    extensions: [
      isUsingVariables ? FlowVariable(variableArgs) : null,
      !disableImageUpload && uploadWorkspaceImage
        ? CustomEventHandlers(uploadWorkspaceImage)
        : null,
      shortcuts != null ? KeyboardShortcuts.configure({ shortcuts }) : null,
    ].filter(Boolean),
  })

  useSetTiptapStorage(editor, variableArgs)

  useEffect(() => {
    editor?.setOptions({ editable: !disabled })
  }, [editor, disabled])

  useEffect(() => {
    if (disabled || !autoFocus) return

    editor?.commands.focus()
  }, [editor, disabled, autoFocus])

  return { editor, uploadWorkspaceImage }
}
