import { classed } from '@motion/theme'

import { TaskNameRichInput } from '~/areas/flows/components'
import { checkTextRuleErrors, getTextFieldRules } from '~/global/rules'
import { useMemo } from 'react'

import { ConnectedNodeVariableLabel } from './connected-node-variable-label'
import { ConnectedSuggestionDropdown } from './connected-suggestion-dropdown'

import { useFlowTemplateForm } from '../../../../hooks'
import { useStageEventField, useStageTaskField } from '../hooks'

type StageTaskNameFieldProps = {
  taskBasePath: `stages.${number}.tasks.${number}`
}

export const StageTaskNameField = ({
  taskBasePath,
}: StageTaskNameFieldProps) => {
  const {
    form: { getFieldState, formState, unregister, watch, getValues },
  } = useFlowTemplateForm()

  const [stageCardType] = useStageEventField(taskBasePath)
  const [name, onNameChange] = useStageTaskField(`${taskBasePath}.name`, {
    validate: () => {
      const value = getValues(`${taskBasePath}.name`)
      if (value == null) {
        unregister(`${taskBasePath}.name`)
        return
      }
      const errorMessage = checkTextRuleErrors(
        value,
        getTextFieldRules('Task name')
      )
      if (errorMessage) {
        return errorMessage
      }
    },
  })

  const { error: nameError } = getFieldState(`${taskBasePath}.name`, formState)

  const stages = watch('stages')
  const textVariables = watch('textVariables')
  const taskId = watch(taskBasePath).id

  const textVariableNames = textVariables.map((v) => v.name) // intentionally not memoized

  const variableArgs = useMemo(
    () => ({
      stages,
      textVariables,
      taskId,
      type: 'name' as const,
      as: ConnectedNodeVariableLabel,
      dropdownComponent: ConnectedSuggestionDropdown,
    }),
    // textVariables contents can change, but the array reference does not
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stages, textVariables, textVariableNames, taskId]
  )

  return (
    <StageTaskNameFieldContainer>
      <TaskNameRichInput
        value={name}
        onChange={onNameChange}
        isError={nameError != null}
        variableArgs={variableArgs}
        stageCardType={stageCardType}
      />
    </StageTaskNameFieldContainer>
  )
}

const StageTaskNameFieldContainer = classed('div', {
  base: 'mx-2',
})
