import { ArrowLeftSolid, ArrowRightSolid } from '@motion/icons'
import { IconButton } from '@motion/ui/base'

import { BOOKING_ONBOARDING_CAROUSEL_DATA } from '~/areas/calendar/booking/constants'
import { useTheme } from '~/state/hooks'
import { useEffect, useState } from 'react'

const carouselSlideCount = BOOKING_ONBOARDING_CAROUSEL_DATA.CAPTIONS.length

export const OnboardingGraphicCarousel = () => {
  const theme = useTheme()
  const [graphicsIdx, setGraphicsIdx] = useState(0)

  const bookingGraphicsSources =
    theme === 'light'
      ? BOOKING_ONBOARDING_CAROUSEL_DATA.IMAGES.LIGHT
      : BOOKING_ONBOARDING_CAROUSEL_DATA.IMAGES.DARK

  useEffect(() => {
    const timeout = setTimeout(
      () => setGraphicsIdx((graphicsIdx + 1) % carouselSlideCount),
      10_000
    )

    return () => void clearTimeout(timeout)
  }, [graphicsIdx])

  return (
    <div className='flex flex-col items-center gap-8'>
      <div className='flex h-[224px] w-full items-center justify-between gap-4'>
        <span className='[&_button:disabled]:invisible'>
          <IconButton
            icon={ArrowLeftSolid}
            variant='muted'
            sentiment='neutral'
            disabled={graphicsIdx === 0}
            onClick={() =>
              setGraphicsIdx(
                (prev) => (prev + (carouselSlideCount - 1)) % carouselSlideCount
              )
            }
          />
        </span>

        <div className='flex w-auto items-center justify-center h-full'>
          {[0, 1, 2, 3].map((idx) => (
            <img
              src={bookingGraphicsSources[idx]}
              key={idx}
              alt='graphic'
              className='w-full h-full object-center object-contain'
              style={{ display: graphicsIdx === idx ? 'initial' : 'none' }}
            />
          ))}
        </div>

        <span className='[&_button:disabled]:invisible'>
          <IconButton
            icon={ArrowRightSolid}
            variant='muted'
            sentiment='neutral'
            disabled={graphicsIdx >= carouselSlideCount - 1}
            onClick={() =>
              setGraphicsIdx((prev) => (prev + 1) % carouselSlideCount)
            }
          />
        </span>
      </div>

      <p className='break-words text-center text-xs text-semantic-neutral-text-subtle'>
        {BOOKING_ONBOARDING_CAROUSEL_DATA.CAPTIONS[graphicsIdx]}
      </p>
    </div>
  )
}
