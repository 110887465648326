import { classed } from '@motion/theme'

import { Link } from 'react-router-dom'

export interface SettingsSidebarItemProps {
  title: string | React.ReactNode
  icon: React.ElementType
  isSelected: boolean
  to: string
  dataTestId?: string
}

export const SettingsSidebarItem = ({
  dataTestId,
  title,
  icon,
  isSelected = false,
  to,
}: SettingsSidebarItemProps) => {
  const Icon = icon

  return (
    <SidebarItem
      data-testid={dataTestId}
      isSelected={isSelected}
      to={`/web/settings/${to}`}
    >
      <Icon className='h-4 w-4 shrink-0 text-sidebar-item-text-default' />
      <span className='text-sm font-medium truncate'>{title}</span>
    </SidebarItem>
  )
}

const SidebarItem = classed(Link, {
  base: `text-sidebar-item-text-default hover:bg-light-300 hover:dark:bg-dark-100/5 flex cursor-pointer
  items-center gap-3 rounded-sm px-2.5 py-1.5 min-w-0 w-full pl-2.5 pr-0 max-w-[190px]
  `,
  variants: {
    isSelected: {
      true: `bg-sidebar-item-bg-selected text-sidebar-item-text-selected`,
    },
  },
})
