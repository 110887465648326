import { API, createUseMutation } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'

const cacheKeysToUpdate = [API.workspacesV2.queryKeys.root, MODEL_CACHE_KEY]
const queryFilter = createQueryFilter(cacheKeysToUpdate)

const useCreateWorkspaceMemberMutation = createUseMutation(
  API.workspacesV2.createWorkspaceMember
)
export const useCreateWorkspaceMember = () => {
  const queryClient = useQueryClient()

  return useCreateWorkspaceMemberMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useCreateWorkspaceMembersMutation = createUseMutation(
  API.workspacesV2.createWorkspaceMembers
)
export const useCreateWorkspaceMembers = () => {
  const queryClient = useQueryClient()

  return useCreateWorkspaceMembersMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useUpdateWorkspaceMemberMutation = createUseMutation(
  API.workspacesV2.updateWorkspaceMember
)
export const useUpdateWorkspaceMember = () => {
  const queryClient = useQueryClient()

  return useUpdateWorkspaceMemberMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useDeleteWorkspaceMemberMutation = createUseMutation(
  API.workspacesV2.deleteWorkspaceMember
)
export const useDeleteWorkspaceMember = () => {
  const queryClient = useQueryClient()

  return useDeleteWorkspaceMemberMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useDeleteWorkspaceMembersMutation = createUseMutation(
  API.workspacesV2.deleteWorkspaceMembers
)
export const useDeleteWorkspaceMembers = () => {
  const queryClient = useQueryClient()

  return useDeleteWorkspaceMembersMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}
