export type GroupableField = {
  id: `${string}/${string}` | string
  label: string
}

export const TaskGroupableFields = [
  { id: 'status', label: 'Status' },
  { id: 'stage', label: 'Stage' },
  { id: 'project', label: 'Project' },
  { id: 'priority', label: 'Priority' },
  { id: 'user', label: 'Assignee' },
  { id: 'workspace', label: 'Workspace' },
  { id: 'label', label: 'Label' },
  { id: 'deadline', label: 'Deadline' },
  { id: 'createdBy', label: 'Created by' },
  { id: 'createdAt', label: 'Created at' },
  { id: 'completedAt', label: 'Completed at' },
  { id: 'updatedAt', label: 'Updated at' },
  { id: 'startDate', label: 'Start date' },
  { id: 'scheduledDate', label: 'Scheduled date' },
  { id: 'folder', label: 'Folder' },
  { id: 'deadlineStatus', label: 'ETA' },
] as const satisfies GroupableField[]

export type TaskGroupableFieldIds = (typeof TaskGroupableFields)[number]['id']

export const ProjectGroupableFields = [
  { id: 'status', label: 'Status' },
  { id: 'stage', label: 'Stage' },
  { id: 'priority', label: 'Priority' },
  { id: 'user', label: 'Assignee' },
  { id: 'workspace', label: 'Workspace' },
  { id: 'label', label: 'Label' },
  { id: 'deadline', label: 'Deadline' },
  { id: 'createdBy', label: 'Created by' },
  { id: 'createdAt', label: 'Created at' },
  { id: 'updatedAt', label: 'Updated at' },
  { id: 'startDate', label: 'Start date' },
  { id: 'projectDefinition', label: 'Template' },
  { id: 'folder', label: 'Folder' },
  { id: 'deadlineStatus', label: 'ETA' },
  { id: 'color', label: 'Color' },
] as const satisfies GroupableField[]

export type ProjectGroupableFieldIds =
  (typeof ProjectGroupableFields)[number]['id']

export const WorkspacePageGroupExclusions = [
  'workspace',
] as const satisfies TaskGroupableFieldIds[]

export const ProjectPageGroupExclusions = [
  'workspace',
  'folder',
] as const satisfies TaskGroupableFieldIds[]

export const FolderPageGroupExclusions = [
  'workspace',
] as const satisfies ProjectGroupableFieldIds[]

// TODO: remove when workspace-folders is at 100%
export const FolderGroupExclusions = [
  'folder',
] as const satisfies ProjectGroupableFieldIds[]
