import { DescriptionField } from '~/areas/task-project/fields'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { ConnectedNodeVariableLabel } from './task-def-name/connected-node-variable-label'
import { ConnectedSuggestionDropdown } from './task-def-name/connected-suggestion-dropdown'

import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const ControlledDescriptionField = () => {
  const { form, disabledFields } = useTaskDefinitionForm()
  const { control, watch } = form

  const { flowFormData } = useTaskDefinitionModalState()
  if (!flowFormData) {
    throw new Error('flowFormData is required')
  }

  const { field } = useController({
    name: 'description',
    control,
  })

  const workspaceId = watch('workspaceId')

  const taskId = watch('id')
  const { stages, textVariables } = flowFormData

  const variableArgs = useMemo(
    () => ({
      stages,
      textVariables,
      taskId,
      labelSize: 'small' as const,
      type: 'description' as const,
      as: ConnectedNodeVariableLabel,
      dropdownComponent: ConnectedSuggestionDropdown,
    }),
    [stages, textVariables, taskId]
  )

  return (
    <DescriptionField
      workspaceId={workspaceId}
      value={field.value}
      onChange={field.onChange}
      disabled={disabledFields.has('description')}
      variableArgs={variableArgs}
    />
  )
}
