import { throttle } from '@motion/utils/core'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { firebase } from '@motion/web-common/firebase'

import { loadStripe } from '@stripe/stripe-js/pure'
import { get as getStripeSubscription } from '~/services/userService/userSubscriptionsService'

import api from '../chromeApi/chromeApiBackground'

export const getStripe = async () => {
  const stripeKey = __IS_PROD__
    ? 'pk_live_bXkyvmoKFB9KZsMI4NNJH36E00bksv6Yuq'
    : 'pk_test_ZDQQFkW9TeMexOR6rF2weB5Q00k51CIQZm'
  return await loadStripe(stripeKey)
}

export const checkStripeSubscription = async () => {
  logInDev('checkStripeSubscription')
  try {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) return false

    const hasSubscription = await getStripeSubscription()
    if (hasSubscription) {
      await api.storage.local.get('stripeSubscription')
    }
    return hasSubscription
  } catch (e) {
    logInDev(e)
    Sentry.captureException(e, {
      tags: { position: 'checkStripeSubscription' },
    })
  }
  return false
}

/**
 * Checks the user's stripe subscription (which is throttled to reduce stripe
 * load)
 * @param force Force stripe subscription to be recalculated from server
 * @returns {Promise<*>}
 */
export const doCheckStripeSubscription = async (force = false) => {
  if (force) {
    checkStripeSubscriptionThrottled.cancel()
  }
  const res = await checkStripeSubscriptionThrottled()
  return res
}

/**
 * Important: do not call directly unless you know what you are doing. Instead,
 * call doCheckStripeSubscription() in loadedBackground
 */
export const checkStripeSubscriptionThrottled = throttle(
  checkStripeSubscription,
  10000,
  { leading: true, trailing: false }
)
