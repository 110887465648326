import { type TaskSchema } from '../types'

export function formatDuration(value: number | null) {
  if (value == null) return ''

  const hours = value / 60
  const formatted =
    hours - Math.floor(hours) !== 0 ? hours.toFixed(1) : String(hours)

  return `${formatted}h`
}

export function totalTimes(tasks: TaskSchema[]) {
  const totalTaskTime = tasks.reduce(
    (sum, task) => sum + (task.duration ?? 0),
    0
  )
  return {
    tasks: totalTaskTime,
  }
}
