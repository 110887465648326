import { CalendarSolid, RefreshSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { formatTime } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { EventActionList } from '~/areas/event/components'
import { type ScheduledEventWithRelation } from '~/global/proxies'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'

import { AgendaItemWrapper, EventLabel, EventSubtitle } from './common'

import { ContextMenuPopoverWithButton } from '../../components'

export const AgendaEvent = ({
  entity,
}: {
  entity: ScheduledEventWithRelation
}) => {
  const calendarEvent = entity.event
  const modalApi = useModalApi()

  if (!calendarEvent) {
    return null
  }

  const handleEventClick = () => {
    recordAnalyticsEvent('AGENDA_SIDEBAR_EVENT_CLICK')

    if (entity.event) {
      modalApi.open('event-modal', { eventId: entity.id })
    }
  }

  const isRecurring = !!calendarEvent.recurringEventId

  const isEventFinished =
    DateTime.fromISO(entity.schedule.end).toISO() < DateTime.now().toISO()

  if (entity.schedule.timeless) {
    return (
      <AgendaItemWrapper>
        <button
          className='flex gap-2 min-w-0 py-2 items-center w-full'
          onClick={handleEventClick}
        >
          <div className='flex items-center text-semantic-neutral-icon-subtle rounded-3xl py-2'>
            <CalendarSolid className='w-4 h-4' />
          </div>
          <EventLabel>{calendarEvent.title}</EventLabel>
        </button>
      </AgendaItemWrapper>
    )
  }

  return (
    <ContextMenuPopoverWithButton
      renderContextMenu={(close) => (
        <EventActionList close={close} eventId={entity.id} />
      )}
      onMenuOpen={(trigger) => {
        recordAnalyticsEvent('AGENDA_SIDEBAR_EVENT_RIGHT_CLICK')
      }}
    >
      {({ handleContextMenu }) => (
        <AgendaItemWrapper onContextMenu={handleContextMenu}>
          <button
            title='View event'
            onClick={handleEventClick}
            className={twMerge(
              'flex gap-2 truncate items-start leading-4 w-full',
              isEventFinished && 'opacity-50'
            )}
          >
            <div className='flex items-center text-semantic-neutral-icon-subtle rounded-3xl py-2'>
              <CalendarSolid className='w-4 h-4' />
            </div>
            <div className='flex flex-col py-2 gap-1.5 min-w-0 w-full items-start'>
              <div className='flex gap-1.5 items-center w-full'>
                <div className='flex gap-1.5 min-w-0'>
                  <EventLabel>{calendarEvent.title}</EventLabel>
                </div>
                <div className='flex gap-1 items-center leading-4'>
                  {isRecurring && (
                    <Tooltip content='Recurring event'>
                      <RefreshSolid className='rounded-lg h-2.5 w-2.5 p-[1px] bg-semantic-neutral-bg-active-hover text-semantic-neutral-icon-default' />
                    </Tooltip>
                  )}
                </div>
              </div>
              <EventSubtitle>
                {formatTime(entity.schedule.start)} -{' '}
                {formatTime(entity.schedule.end)}
              </EventSubtitle>
            </div>
          </button>
        </AgendaItemWrapper>
      )}
    </ContextMenuPopoverWithButton>
  )
}
