import { isMeetingTask } from '@motion/ui-logic/pm/task'

import { type TreeListRowValueType } from './types'

import { type GroupedNode } from '../grouping'
import { type ViewStateGroupByField } from '../view-state'

export function canRowSelect(node: GroupedNode<TreeListRowValueType>) {
  const { type, value } = node.value

  if (node.qualifiedKey.endsWith('/totals-row')) return false
  if (type === 'task' && value.type === 'RECURRING_INSTANCE') return false
  if (type === 'task' && isMeetingTask(value)) return false

  if (node.children != null) {
    const canSelectChildren = node.children.some(canRowSelect)
    if (!canSelectChildren) return false
  }

  return true
}

export function getParentRowValues(row: GroupedNode): ({
  type: string
  value: any
} & ViewStateGroupByField)[] {
  return [row.value, row.parent?.value, row.parent?.parent?.value].filter(
    Boolean
  )
}
