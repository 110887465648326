import {
  type CustomFieldSchema,
  type CustomFieldValuesSchema,
} from '@motion/shared/custom-fields'
import { mapCustomFieldToFieldArrayWithValue } from '@motion/ui-logic'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { AddCustomFieldButton } from '~/areas/project-management/custom-fields/add-custom-field-button'
import { CustomFieldUISwitch } from '~/areas/task-project/components'
import { useProject } from '~/global/hooks'

export type ProjectCustomFieldsSidebarProps = {
  projectId: string
  onChange?: (
    customFieldId: CustomFieldSchema['id'],
    valueArg: CustomFieldValuesSchema['value']
  ) => void
  disabled?: boolean
}

export function ProjectCustomFieldsSidebar({
  projectId,
  onChange,
  disabled = false,
}: ProjectCustomFieldsSidebarProps) {
  const project = useProject(projectId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(
    project?.workspaceId
  )

  if (project == null) {
    throw new Error('Project is required')
  }

  if (workspaceCustomFields == null) return null

  const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
    mapCustomFieldToFieldArrayWithValue(field, project.customFieldValues)
  )

  return (
    <>
      {customFieldValuesFieldArray.map((customField, index) => {
        return (
          <CustomFieldUISwitch
            key={customField.instanceId}
            customField={{ ...customField, workspaceId: project.workspaceId }}
            disabled={disabled}
            value={customField.value}
            onChange={(value) => {
              if (onChange != null) {
                return onChange(
                  customField.instanceId,
                  value as CustomFieldValuesSchema['value']
                )
              }
            }}
          />
        )
      })}

      {!disabled && (
        <div className='pt-1'>
          <AddCustomFieldButton
            workspaceId={project.workspaceId}
            sentiment='neutral'
            size='small'
            usePopover
          />
        </div>
      )}
    </>
  )
}
