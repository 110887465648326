import { DotsHorizontalSolid } from '@motion/icons'
import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'
import { classed, type ComponentProps } from '@motion/theme'
import { IconButton, PopoverTrigger } from '@motion/ui/base'
import { StatusIcon } from '@motion/ui/pm'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { type StatusSchema } from '@motion/zod/client'

import { ProjectActionList } from '~/areas/project/components/project-action-list'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { type ProjectWithRelations } from '~/global/proxies'
import { type DateTime } from 'luxon'
import { useNavigate } from 'react-router'
import { twMerge } from 'tailwind-merge'

import { ProjectItemInfo } from './project-item-info'
import { type Side } from './resize-handle'
import { StagesBar } from './stages-bar'

import { useScrollPosition } from '../../context'
import { useSidebarSize } from '../../hooks'
import { ZIndexMap } from '../../shared-constants'

type ProjectItemsProps = {
  listeners?: Record<string, unknown> | undefined
  setActivatorNodeRef?: (node: HTMLElement | null) => void
  project: ProjectWithRelations
  currentSide?: Side
  left?: number
  width?: number
  deltaWidth?: number
  ignoreStageResizing?: boolean
  isPlaceholder?: boolean
}

type ProjectItemProps = ComponentProps<typeof Item> & ProjectItemsProps

const OFFSCREEN_NAME_PADDING = 8

export const ProjectItem = (props: ProjectItemProps) => {
  const {
    project,
    left,
    width,
    currentSide,
    deltaWidth,
    ignoreStageResizing,
    isPlaceholder = false,
    ...rest
  } = props
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()

  const status = useWorkspaceStatusById(project.statusId)
  const isProjectCompleted = isCompletedStatus(status)
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  return (
    <Item
      onClick={() => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_OPEN_PROJECT')
        navigate(buildProjectModalUrl({ project: project.id }))
      }}
      {...rest}
      isCompleted={isProjectCompleted}
      style={{ zIndex: ZIndexMap.projectItem }}
      projectState={
        hasPastDueM2 && project.deadlineStatus === 'missed-deadline'
          ? 'missed-deadline'
          : 'none'
      }
    >
      <ProjectName
        listeners={rest.listeners}
        setActivatorNodeRef={rest.setActivatorNodeRef}
        project={project}
        width={width}
        left={left}
        status={status}
      />

      {project?.stages.length > 0 && (
        <StagesBar
          deltaWidth={deltaWidth}
          project={project}
          currentSide={currentSide}
          ignoreStageResizing={ignoreStageResizing}
          isPlaceholder={isPlaceholder}
        />
      )}
      <div
        className={twMerge(
          'absolute top-1 right-1 max-w-full',
          width && width < 40 && 'hover:min-w-[40px]'
        )}
      >
        <ProjectItemInfo project={project} />
      </div>
    </Item>
  )
}

type ProjectNameProps = ProjectItemsProps & {
  status: StatusSchema
}

const ProjectName = ({
  project,
  width,
  left,
  status,
  setActivatorNodeRef,
  listeners,
}: ProjectNameProps) => {
  const sidebarSize = useSidebarSize()
  const [scrollPosition] = useScrollPosition()

  const relativeOffScreen: number =
    !left || !width ? 0 : left + scrollPosition - sidebarSize

  const relativeOffScreenLeft: number =
    Math.abs(relativeOffScreen) + OFFSCREEN_NAME_PADDING

  const shouldStick = !!width && relativeOffScreen < 0
  const maxWidth =
    width && width - relativeOffScreenLeft > 0
      ? width - relativeOffScreenLeft
      : 0

  const isCanceled = isCanceledStatus(status)
  const isCompleted = isCompletedStatus(status)

  return (
    <div
      className='max-w-auto flex justify-self-center px-2.5 h-full items-center'
      title={project.name}
      ref={setActivatorNodeRef}
      {...listeners}
    >
      <div
        className='w-full flex gap-1 relative items-center text-sm h-full'
        style={
          // If the left value is off the screen, we want the name of the project to be sticky to the left
          shouldStick && maxWidth > 0
            ? {
                position: 'absolute',
                maxWidth: maxWidth,
                // Relative distance from the edge of the screen
                left: relativeOffScreenLeft,
              }
            : {}
        }
      >
        {isCanceled || isCompleted ? <StatusIcon item={status} /> : null}
        <div
          className={twMerge(
            'max-w-full truncate',
            isCanceled && 'line-through text-semantic-neutral-text-subtle'
          )}
        >
          {project.name}
        </div>
      </div>
    </div>
  )
}

type ProjectCardContentProps = ComponentProps<typeof CardContent> & {
  projectStart?: DateTime
  projectEnd?: DateTime
  project: ProjectWithRelations
  close?: () => void
}

export const ProjectCardContent = (props: ProjectCardContentProps) => {
  const {
    close: closeParent,
    project,
    projectStart,
    projectEnd,
    ...rest
  } = props

  const startLabel = projectStart?.toFormat('ccc LLL dd, yyyy') ?? 'None'
  const endLabel = projectEnd?.toFormat('ccc LLL dd, yyyy') ?? 'None'
  const projectDuration =
    projectStart && projectEnd
      ? Math.ceil(projectEnd.diff(projectStart).as('days'))
      : null

  return (
    <CardContent {...rest}>
      <div className='flex pl-0.5 items-center gap-1 relative'>
        <span>
          {startLabel} - {endLabel}{' '}
          {projectDuration !== null && `(${projectDuration} days)`}
        </span>
        {rest.isDragging ? null : (
          <PopoverTrigger
            placement='bottom-end'
            onClose={() => {
              closeParent?.()
            }}
            renderPopover={({ close }) => (
              <ProjectActionList
                close={() => {
                  close()
                }}
                options={{ showColors: true, allowProjectResolution: true }}
                project={project}
              />
            )}
          >
            <IconButton
              variant='muted'
              sentiment='neutral'
              size='xsmall'
              icon={DotsHorizontalSolid}
            />
          </PopoverTrigger>
        )}
      </div>
    </CardContent>
  )
}

const Item = classed('div', {
  base: `
    flex flex-col
    relative
    h-full w-full
    rounded
    py-0

    cursor-grab select-none

    text-palette-text-default
  `,
  variants: {
    fadedLeft: {
      true: `
        bg-gradient-to-r from-10% from-transparent to-40% to-palette-bg-default
      `,
    },
    fadedRight: {
      true: `
        bg-gradient-to-l from-10% from-transparent to-40% to-palette-bg-default
      `,
    },
    isCompleted: {
      true: `opacity-60`,
    },
    isDragging: {
      // Put the dragging item on top of the other items in the lane
      true: `
        z-10
        truncate
      `,
      false: `
        hover:to-palette-bg-hover
        hover:from-0%
        hover:to-0%
        whitespace-nowrap
      `,
    },
    projectState: {
      'missed-deadline': 'border border-semantic-error-border-active',
      none: '',
    },
  },
  compoundVariants: [
    {
      fadedLeft: false,
      fadedRight: false,
      isDragging: false,
      className: `
        bg-palette-bg-default
        hover:bg-palette-bg-hover
      `,
    },
    {
      fadedLeft: false,
      fadedRight: false,
      isDragging: true,
      className: `
        bg-palette-bg-default
      `,
    },
  ],
  defaultVariants: {
    fadedLeft: false,
    fadedRight: false,
    isDragging: false,
    projectState: 'none',
  },
})

const CardContent = classed('div', {
  base: `
    flex justify-end items-center
    rounded-sm  p-0.5 text-2xs
    whitespace-nowrap
  `,
  variants: {
    isDragging: {
      true: `
        bg-semantic-primary-bg-strong-default text-semantic-primary-text-onDark
      `,
      false: `
        bg-semantic-neutral-surface-bg-default text-semantic-neutral-text-subtle
      `,
    },
  },
  defaultVariants: {
    isDragging: false,
  },
})
