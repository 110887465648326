import {
  createPendingStatus,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import {
  type RecurringTaskSchema,
  type TaskSchema,
  type TasksV2CreateSchema,
  type TasksV2NormalCreateSchema,
} from '@motion/rpc-types'
import { createOptimisticScheduledEntityFromTask } from '@motion/ui-logic'
import { createTemporaryTask } from '@motion/ui-logic/pm/task'

import { type QueryClient } from '@tanstack/react-query'

import { getTaskQueryFilters } from './task-query-filters'
import { getTaskModels } from './utils'

export function applyOptimisticTaskCreate(
  client: QueryClient,
  currentUserId: string,
  taskCreate: TasksV2CreateSchema['data']
): OptimisticUpdateValue {
  const {
    isAutoScheduled,
    assigneeUserId,
    workspaceId,
    statusId,
    priorityLevel,
    labelIds,
    name,
  } = taskCreate

  const {
    startDate,
    dueDate,
    stageDefinitionId,
    customFieldValues,
    projectId,
    scheduledStart,
  } =
    taskCreate.type === 'NORMAL'
      ? getNormalTaskTempValues(taskCreate)
      : ({} as TasksV2NormalCreateSchema)

  const tempTask = createTemporaryTask({
    isAutoScheduled: isAutoScheduled ?? false,
    assigneeUserId: assigneeUserId ?? null,
    createdByUserId: currentUserId,
    workspaceId: workspaceId,
    projectId: projectId ?? null,
    statusId: statusId ?? createPendingStatus('unknown').id,
    priorityLevel: priorityLevel,
    labelIds: labelIds ?? [],
    customFieldValues: customFieldValues ?? {},
    name: name ?? '',
    type: taskCreate.type,
    duration: taskCreate.duration,
    scheduledStart,
    startDate,
    dueDate,
    stageDefinitionId,
  })

  const tempScheduledEntity = createOptimisticScheduledEntityFromTask(
    tempTask,
    currentUserId
  )
  const partialTasksModel = getTaskModels(tempTask, tempScheduledEntity)

  const { rollback } = MotionCache.upsert(
    client,
    getTaskQueryFilters(tempTask.id),
    {
      models: partialTasksModel,
    }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}

const getNormalTaskTempValues = (taskCreate: TasksV2NormalCreateSchema) => {
  return {
    startDate: taskCreate.startDate,
    dueDate: taskCreate.dueDate,
    stageDefinitionId: taskCreate.stageDefinitionId,
    customFieldValues: taskCreate.customFieldValues,
    projectId: taskCreate.projectId,
    scheduledStart: taskCreate.scheduledStart,
  }
}

export function applyTaskCreateToCaches(
  client: QueryClient,
  task: TaskSchema | RecurringTaskSchema,
  currentUserId: string
) {
  const scheduledEntity = createOptimisticScheduledEntityFromTask(
    task,
    currentUserId
  )
  const tasksModels = getTaskModels(task, scheduledEntity)

  MotionCache.upsert(client, getTaskQueryFilters(task.id), {
    models: tasksModels,
  })
}
