import { PopoverTrigger } from '@motion/ui/base'
import { prettyDateDay, type QuickAction } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { type ReactNode } from 'react'

import { type ContextAwareDateDropdownContentProps } from './context-aware-date-dropdown-content'
import {
  type DateDropdownTarget,
  type ProjectAwareContextProps,
  ProjectAwareDateDropdownContent,
  type ProjectAwareDateDropdownContentProps,
} from './project-aware-date-dropdown-content'

export type StartDateDropdownProps = {
  value: string | null
  children: ReactNode
  onChange(value: string | null): void
  disableClearing?: boolean
  disabledDate?: ContextAwareDateDropdownContentProps['disabledDate']
  quickActions?: QuickAction[]
  dropdownTarget: DateDropdownTarget
  contextProps: ProjectAwareContextProps
  additionalQuickActions?: ProjectAwareDateDropdownContentProps['additionalQuickActions']
  overrides?: ProjectAwareDateDropdownContentProps['overrides']
}

export const StartDateDropdown = ({
  children,
  disableClearing,
  onChange,
  ...rest
}: StartDateDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <StartDateDropdownContent
          disableClearing={disableClearing}
          onChange={(value) => {
            onChange(value)
            close()
          }}
          {...rest}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type StartDateDropdownContentProps = Omit<StartDateDropdownProps, 'children'>

export const StartDateDropdownContent = ({
  onChange,
  contextProps,
  ...props
}: StartDateDropdownContentProps) => {
  return (
    <ProjectAwareDateDropdownContent
      {...props}
      contextProps={contextProps}
      onChange={(value) =>
        onChange(value ? parseDate(value).toISODate() : null)
      }
      formatDateDisplay={(date) => prettyDateDay(date)}
      dropdownType='start-date'
    />
  )
}
