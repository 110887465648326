import { ActionList, Button, PopoverTrigger } from '@motion/ui/base'
import { canAddTime } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useAgendaCurrentTaskActions } from '~/areas/agenda/hooks'
import { AddTaskDurationActionList } from '~/areas/tasks/components'

import { type CurrentTaskActionProps } from './types'

export type CurrentTaskActionsDropdownProps = CurrentTaskActionProps

export function CurrentTaskActionsDropdown({
  task,
}: CurrentTaskActionsDropdownProps) {
  return (
    <PopoverTrigger
      placement='left-start'
      renderPopover={({ close }) => (
        <CurrentTaskActionList task={task} closePopover={close} />
      )}
    >
      <Button
        variant='outlined'
        size='small'
        sentiment='neutral'
        onClick={() => {
          recordAnalyticsEvent('AGENDA_TASK_TASK_ACTIONS_DROPDOWN_OPENED')
        }}
      >
        Task actions
      </Button>
    </PopoverTrigger>
  )
}

type CurrentTaskActionListProps = CurrentTaskActionsDropdownProps & {
  closePopover: () => void
}

function CurrentTaskActionList({
  task,
  closePopover,
}: CurrentTaskActionListProps) {
  const { addTime, stopAndChunk, stopAndComplete } =
    useAgendaCurrentTaskActions({
      task,
    })

  return (
    <ActionList
      onActionAnyItem={closePopover}
      items={[
        canAddTime(task) && {
          content: 'Add time',
          onAction: () => {},
          renderPopover: ({ close }) => (
            <AddTaskDurationActionList
              close={close}
              currentDuration={task.duration}
              onSelect={addTime}
            />
          ),
        },
        {
          content: 'Stop and chunk',
          onAction: stopAndChunk,
        },
        {
          content: 'Stop and complete',
          onAction: stopAndComplete,
        },
      ]}
    />
  )
}
