import { createContext, useContext } from 'react'

export type SidebarSearchContextApi = {
  hasSearch: boolean
  searchQuery: string
  setSearchQuery: (value: string) => void
}
export const SidebarSearchContext = createContext<SidebarSearchContextApi>({
  hasSearch: false,
  searchQuery: '',
  setSearchQuery: () => null,
})

export const useSidebarSearchContext = () => useContext(SidebarSearchContext)
