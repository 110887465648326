import { LabelsField } from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useTaskDefinitionForm } from '../use-task-definition-form'

export const ControlledLabelsField = () => {
  const { form } = useTaskDefinitionForm()
  const { control, watch } = form

  const workspaceId = watch('workspaceId')

  const { field } = useController({
    name: 'labelIds',
    control,
  })

  return (
    <LabelsField
      selectedLabelIds={field.value}
      workspaceId={workspaceId}
      onChange={field.onChange}
    />
  )
}
