import { CustomFieldTextSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { classed } from '@motion/theme'
import { Label, type LabelProps } from '@motion/ui/pm'
import { ProjectPalette } from '@motion/ui/project'
import { type VariableDefinitionSchema } from '@motion/zod/client'

export type VariableLabelProps = {
  value: Pick<VariableDefinitionSchema, 'name' | 'color'>
  size?: 'xsmall' | 'small'
}

export const VariableLabel = ({
  value,
  size = 'xsmall',
}: VariableLabelProps) => {
  return (
    <VariableLabelBase color={value.color} size={size}>
      <CustomFieldTextSolid className='w-3 h-3 text-semantic-blue-icon-default shrink-0' />
      <span className='text-semantic-blue-text-strong truncate font-medium'>
        {value.name}
      </span>
    </VariableLabelBase>
  )
}

export type VariableLabelBaseProps = Omit<LabelProps, 'color'> & {
  color: COLOR
}

export const VariableLabelBase = ({
  children,
  color,
  ...rest
}: VariableLabelBaseProps) => {
  return (
    <ProjectPalette color={color}>
      {/* @ts-expect-error - fine */}
      <StyledLabel {...rest}>{children}</StyledLabel>
    </ProjectPalette>
  )
}

const StyledLabel = classed(Label, {
  base: `
    h-4
    inline-flex flex-row items-center
    gap-1 px-1.5
    rounded-full
    !text-palette-text-default !bg-palette-bg-default
  `,
})
