import { type TaskDeadlineType } from '@motion/rpc-types'
import { Toggle } from '@motion/ui/base'

import { HardDeadlineTooltipIcon } from './hard-deadline-tooltip-icon'

export type DeadlineToggleProps = {
  deadlineType: TaskDeadlineType
  disabled?: boolean
  onChange: (deadlineType: TaskDeadlineType) => void
}

export const DeadlineToggle = (props: DeadlineToggleProps) => {
  const { deadlineType, disabled = false, onChange } = props
  return (
    <div className='flex items-center gap-1'>
      <Toggle
        checked={deadlineType === 'HARD'}
        disabled={disabled}
        size='small'
        onChange={(e) => onChange(e.currentTarget.checked ? 'HARD' : 'SOFT')}
      >
        Hard Deadline
      </Toggle>
      <HardDeadlineTooltipIcon />
    </div>
  )
}
