import { Button, showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isPasswordValid } from '@motion/ui-logic'
import { useChangePassword } from '@motion/web-common/auth'
import { firebase } from '@motion/web-common/firebase'

import { signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import { useAuth } from '../../localServices/auth'

export const ResetPassword = () => {
  const navigate = useNavigate()
  const passwordChangeMutation = useChangePassword()
  const { logout, user } = useAuth()
  const auth = firebase.auth()

  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)

  const changePassword = async () => {
    try {
      await passwordChangeMutation.mutateAsync({ password })

      if (user) {
        await signInWithEmailAndPassword(auth, user.email, password)
        setPassword('')
        showToast('success', 'Password changed')
        return
      }

      await logout()
    } catch (error) {
      const err = error as Error
      if (err.message.startsWith('Validation Error')) {
        setError('Password must be at least 8 characters long.')
      } else {
        setError('Could not change password. Please contact support.')
      }
    }
  }

  return (
    <div className='w-full h-full bg-semantic-neutral-bg-default flex justify-center py-9'>
      <div className='w-full max-w-[600px] flex flex-col gap-y-9'>
        <p className='font-bold text-[32px] text-semantic-neutral-text-default'>
          Change Password
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            void changePassword()
          }}
        >
          <div className='w-full flex flex-col gap-y-4'>
            <TextField
              type='password'
              label='New password'
              value={password}
              placeholder='Enter at least 8 characters, with at least 1 number'
              onChange={(newPassword) => setPassword(newPassword)}
            />

            <div className='w-full flex flex-col gap-y-2'>
              {error && (
                <p className='text-lg text-semantic-error-text-default'>
                  {error}
                </p>
              )}
              <Button
                loading={passwordChangeMutation.isLoading}
                disabled={!isPasswordValid(password)}
                type='submit'
              >
                Reset password
              </Button>
              <Button
                onClick={() => navigate('/web/account')}
                variant='muted'
                sentiment='neutral'
              >
                Back
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
