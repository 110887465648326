import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

export interface ResultItemProps {
  renderIcon: () => ReactNode
  title: ReactNode
  onClick(): void
  children: ReactNode
  renderControls?: () => ReactNode
  variant?: 'subtle' | 'default'
}

export function ResultItem(props: ResultItemProps) {
  const {
    renderIcon,
    title,
    onClick,
    renderControls,
    children,
    variant = 'default',
  } = props

  const controls = renderControls?.()

  return (
    <Wrapper role='listitem' tabIndex={0} onClick={onClick}>
      {renderIcon()}
      <div className='flex flex-col gap-1'>
        <TitleContainer variant={variant}>{title}</TitleContainer>
        <div className='flex flex-col gap-1.5'>{children}</div>
      </div>
      {!!controls && <ControlsContainer>{controls}</ControlsContainer>}
    </Wrapper>
  )
}

const Wrapper = classed('div', {
  base: `
    relative group
    p-1.5 rounded
    flex gap-2.5

    hover:bg-semantic-neutral-bg-active-default
    focus:bg-semantic-neutral-bg-active-default
    [&[data-selected]]:bg-semantic-neutral-bg-active-default
    focus:outline-none

    cursor-pointer
  `,
})

const TitleContainer = classed('div', {
  base: `text-sm font-semibold pr-7`,
  variants: {
    variant: {
      subtle: `text-semantic-neutral-text-subtle`,
      default: `text-semantic-neutral-text-default`,
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const ControlsContainer = classed('div', {
  base: `
    absolute top-1.5 right-1.5
    hidden group-hover:block
  `,
})
