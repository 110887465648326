import { ArrowDownSolid, ArrowUpSolid } from '@motion/icons'
import {
  Button,
  ButtonGroup,
  IconButton,
  PopoverTrigger,
  SearchableList,
  type SearchableListSectionedSelectProps,
  Tooltip,
} from '@motion/ui/base'
import { type FilterTarget } from '@motion/ui-logic/pm/data'

import { type TaskWithRelations } from '~/global/proxies'

import { RestrictWidth } from '../components/restrict-width'
import { type FieldDefinition, type SortField } from '../fields'

export type SortButtonGroupProps = {
  value: SortField | ''
  sections: SearchableListSectionedSelectProps<
    FieldDefinition<TaskWithRelations>
  >['sections']
  onChange(value: SortField): void
  onChangeDirection(): void
  direction?: 'asc' | 'desc'
  filterTarget: FilterTarget
  disabled?: boolean
  disabledTooltip?: string
}

export const SortButtonGroup = ({
  value,
  sections,
  onChange,
  onChangeDirection,
  direction,
  filterTarget,
  disabled = false,
  disabledTooltip,
}: SortButtonGroupProps) => {
  const items = sections.flatMap((s) => s.items)
  const matched = items.find((x) => x.id === value)
  const isActive = value.length > 0 && matched != null

  const filterTargetName = filterTarget === 'tasks' ? 'Task' : 'Project'

  return (
    <Tooltip content={disabledTooltip ?? undefined} asChild>
      <ButtonGroup segmented size='small'>
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => (
            <SearchableList
              itemType='sectioned'
              sections={sections}
              onSelect={(item) => {
                onChange(item.id as SortField)
                close()
              }}
              computeKey={(item) => item.id}
              computeSelected={(item) => item.id === value}
              computeSearchValue={(item) => item.name}
              renderItem={(item) => <RestrictWidth>{item.name}</RestrictWidth>}
              inputProps={{ placeholder: 'Search...' }}
            />
          )}
        >
          <Button
            variant='outlined'
            sentiment={isActive ? 'primary' : 'neutral'}
            size='small'
            disabled={disabled}
          >
            <div className='font-medium whitespace-nowrap'>
              {isActive
                ? `${filterTargetName} Sort: ${matched.name}`
                : `Sort ${filterTargetName}s`}
            </div>
          </Button>
        </PopoverTrigger>
        {isActive && direction != null && (
          <IconButton
            icon={direction === 'asc' ? ArrowUpSolid : ArrowDownSolid}
            variant='outlined'
            sentiment='primary'
            size='small'
            onClick={onChangeDirection}
          />
        )}
      </ButtonGroup>
    </Tooltip>
  )
}
