import { classed } from '@motion/theme'

type SidePanelProps = {
  children: React.ReactNode
  open: boolean
}

export const SidePanel = ({ children, open }: SidePanelProps) => {
  return (
    <Wrapper open={open}>
      {open && <StyledPanel>{children}</StyledPanel>}
    </Wrapper>
  )
}

const Wrapper = classed('aside', {
  base: `
    absolute
    right-0

    -top-4
    modal-lg:-top-6
    modal-xl:-top-8

    transition-[transform,scale]
    ease-[cubic-bezier(0,0,.5,1.24)]
  `,
  variants: {
    open: {
      false: 'scale-95',
      true: 'scale-100 translate-x-[142px]', // 150px translation - 8px to cover the modal a tiny bit
    },
  },
})

const StyledPanel = classed('div', {
  base: `
    rounded-[8px]
    border-t-[0.5px]
    border-b-[0.5px]
    border-semantic-neutral-border-default
    bg-semantic-neutral-surface-overlay-bg-default
    shadow-md
    overflow-hidden

    w-[300px]
    h-[719px]
    modal-md:h-[801px]
    modal-lg:h-[867px]
    modal-xl:h-[calc(80vh+64px)] modal-xl:max-h-[1479px]
`,
})
