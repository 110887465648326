import { BellSolid, BellXSolid, CalendarSolid } from '@motion/icons'
import { EndRelativeDateOption } from '@motion/shared/common'
import { FieldButton, PopoverTrigger } from '@motion/ui/base'

import { WarnOnToggle } from '~/areas/project-management/components/warn-on-toggle'

import { RelativeDateDropdown } from './relative-date-dropdown'
import { RelativeDateTriggerButton } from './relative-date-trigger-button'

import { type useSelectedTaskDefaults } from '../hooks'

type DueDateFieldProps = {
  value: EndRelativeDateOption
  onChange: (item: EndRelativeDateOption) => void
  warnOnPastDueOnChange: (value: boolean) => void
  selectedTaskDefaults: ReturnType<typeof useSelectedTaskDefaults>
  disabled?: boolean
}

export const DueDateField = ({
  value,
  onChange,
  warnOnPastDueOnChange,
  selectedTaskDefaults,
  disabled,
}: DueDateFieldProps) => {
  return (
    <RelativeDateDropdown options={EndRelativeDateOption} onChange={onChange}>
      <RelativeDateTriggerButton
        label='Deadline'
        icon={<CalendarSolid />}
        value={value}
        disabled={disabled}
        endButton={
          <DeadlineWarnButton
            selectedTaskDefaults={selectedTaskDefaults}
            onChange={warnOnPastDueOnChange}
            disabled={disabled}
          />
        }
      />
    </RelativeDateDropdown>
  )
}

type DeadlineWarnButtonProps = {
  selectedTaskDefaults: ReturnType<typeof useSelectedTaskDefaults>
  onChange: (value: boolean) => void
  disabled?: boolean
}

function DeadlineWarnButton({
  selectedTaskDefaults,
  onChange,
  disabled,
}: DeadlineWarnButtonProps) {
  const { isAutoScheduled, ignoreWarnOnPastDue } = selectedTaskDefaults

  return (
    <PopoverTrigger
      renderPopover={() => (
        <div className='p-2'>
          <WarnOnToggle
            checked={!ignoreWarnOnPastDue}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      )}
    >
      <FieldButton
        size='xsmall'
        variant='outlined'
        sentiment={isAutoScheduled ? 'ai' : 'neutral'}
        iconOnly
        disabled={disabled}
      >
        <div className='p-0.5'>
          {ignoreWarnOnPastDue ? <BellXSolid /> : <BellSolid />}
        </div>
      </FieldButton>
    </PopoverTrigger>
  )
}
