import { Compare, type CompareFn, descending } from '@motion/utils/array'

import { type FieldDefinition } from './types'

export const DEFAULT_STRING_SORT = Compare.string.with({
  null: 'at-end',
  empty: 'at-end',
  trim: true,
  numeric: true,
})

export function getSortFn<T>(
  def: { field: string; direction: 'asc' | 'desc' } | null,
  fields: FieldDefinition<T>[]
): CompareFn<T> | undefined {
  if (def == null) return undefined

  const field = fields.find((f) => f.id === def.field)

  if (field == null || field.sortFn == null) return

  return def.direction === 'desc' ? descending(field.sortFn) : field.sortFn
}
