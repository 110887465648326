import { isCompletedStatus } from '@motion/shared/common'
import { formatTimeRange, prettyDateDay, templateStr } from '@motion/ui-logic'
import { type ChunkTaskSchema } from '@motion/zod/client'

import { StatusBadge } from '~/global/components/badges'
import { type ChunkTaskWithRelations } from '~/global/proxies'
import { twMerge } from 'tailwind-merge'

import { useTaskChunksData } from '../hooks/data/use-task-chunks-data'

export type ChunkInfoTooltipContentProps = {
  currentTaskId: string
  parentChunkTaskId?: string
}

export function ChunkInfoTooltipContent({
  currentTaskId,
  parentChunkTaskId,
}: ChunkInfoTooltipContentProps) {
  const chunks = useTaskChunksData(parentChunkTaskId)

  if (chunks.length < 1) return null

  return (
    <div>
      <p>
        {templateStr('This task is divided into {{count}} chunks:', {
          count: chunks.length,
        })}
      </p>
      <ul className='flex flex-col gap-1'>
        {chunks.map((chunk) => (
          <ChunkTooltipContentItem
            key={chunk.id}
            chunk={chunk}
            currentTaskId={currentTaskId}
          />
        ))}
      </ul>
    </div>
  )
}

export type ChunkTooltipContentItemProps = {
  chunk: ChunkTaskWithRelations
  currentTaskId: ChunkTaskSchema['id']
}

export function ChunkTooltipContentItem({
  chunk,
  currentTaskId,
}: ChunkTooltipContentItemProps) {
  const date = chunk.scheduledStart ?? chunk.dueDate
  if (!date) return

  const chunkLabel = templateStr('{{completedIcon}} {{date}} ({{timeRange}})', {
    completedIcon: (
      <StatusBadge
        size='xsmall'
        value={
          isCompletedStatus(chunk.status)
            ? chunk.status
            : {
                name: '',
                type: null,
                color: '#889096',
              }
        }
        hideTooltip
      />
    ),
    date: prettyDateDay(date),
    timeRange:
      !chunk.scheduledStart || !chunk.scheduledEnd
        ? 'Unfit'
        : formatTimeRange(chunk.scheduledStart, chunk.scheduledEnd),
  })

  return (
    <li
      key={chunk.id}
      className={twMerge(
        'inline-flex items-center gap-1',
        chunk.id === currentTaskId && 'font-bold'
      )}
    >
      {chunkLabel}
    </li>
  )
}
