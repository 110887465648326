import { Elements } from '@stripe/react-stripe-js'
import { type Appearance, type Stripe } from '@stripe/stripe-js'

import { useTheme } from '../../../state/hooks'

const colors = {
  light: {
    300: '#F3F3F4',
    1100: '#747485',
    1200: '#1A1A20',
  },
  dark: {
    100: '#fff',
    400: '#A1A1A1',
    1100: '#262627',
  },
}

interface ElementsWrapperProps {
  stripe: Stripe | null
  clientSecret: string
  children: React.ReactChild
}

export const LightModeElementsWrapper = ({
  children,
  clientSecret,
  stripe,
}: ElementsWrapperProps) => {
  const appearance: Appearance = {
    variables: {
      colorPrimary: '#0570de',
      colorBackground: colors.dark[100],
      colorText: '#1d1d1d',
      colorTextPlaceholder: '#9CA3AF',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      spacingGridRow: '12px',
      spacingGridColumn: '12px',
      borderRadius: '4px',
    },
    rules: {
      '.Tab': {
        border: '1px solid #E0E6EB',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
      },

      '.Tab:hover': {
        color: 'var(--colorText)',
      },

      '.Tab--selected': {
        borderColor: '#E0E6EB',
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
      },

      '.Input--invalid': {
        boxShadow:
          '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
      },
    },
  }

  return (
    <Elements options={{ clientSecret, appearance }} stripe={stripe}>
      {children}
    </Elements>
  )
}

export const ElementsWrapper = ({
  children,
  clientSecret,
  stripe,
}: ElementsWrapperProps) => {
  const theme = useTheme()

  const appearance: Appearance = {
    variables: {
      colorPrimary: '#0570de',
      colorBackground:
        theme === 'light' ? colors.light[300] : colors.dark[1100],
      colorText: theme === 'light' ? colors.light[1200] : colors.dark[100],
      colorTextPlaceholder:
        theme === 'light' ? colors.light[1100] : colors.dark[400],
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      spacingGridRow: '12px',
      spacingGridColumn: '12px',
      borderRadius: '4px',
    },
  }

  return (
    <Elements
      key={clientSecret}
      options={{ clientSecret, appearance }}
      stripe={stripe}
    >
      {children}
    </Elements>
  )
}
