import { ConnectedPriorityFilter, ConnectedProjectFilter } from './components'
import {
  ConnectedCompletedTimeFilter,
  ConnectedCreatedTimeFilter,
  ConnectedDueDateFilter,
  ConnectedLabelFilter,
  ConnectedStatusFilter,
  ConnectedUpdatedTimeFilter,
  ConnectedUserFilter,
  ConnectedWorkspaceFilter,
} from './components/buttons'
import { type FilterDefinition } from './components/types'

export const ALL_FILTER_DEFINITIONS: FilterDefinition[] = [
  {
    key: 'assigneeUserIds',
    label: 'Assignees',
    renderDropdown: (props) => <ConnectedUserFilter {...props} />,
  },
  {
    key: 'managerIds',
    label: 'Assignees',
    renderDropdown: (props) => <ConnectedUserFilter {...props} />,
  },
  {
    key: 'statusIds',
    label: 'Statuses',
    renderDropdown: (props) => <ConnectedStatusFilter {...props} />,
  },
  {
    key: 'workspaces',
    label: 'Workspaces',
    renderDropdown: (props) => <ConnectedWorkspaceFilter {...props} />,
  },
  {
    key: 'projects',
    label: 'Projects',
    renderDropdown: (props) => <ConnectedProjectFilter {...props} />,
  },
  {
    key: 'labelIds',
    label: 'Labels',
    renderDropdown: (props) => <ConnectedLabelFilter {...props} />,
  },
  {
    key: 'priorities',
    label: 'Priority',
    renderDropdown: (props) => <ConnectedPriorityFilter {...props} />,
  },
  {
    key: 'createdTime',
    label: 'Created On',
    renderDropdown: (props) => <ConnectedCreatedTimeFilter {...props} />,
  },
  {
    key: 'updatedTime',
    label: 'Updated On',
    renderDropdown: (props) => <ConnectedUpdatedTimeFilter {...props} />,
  },
  {
    key: 'completedTime',
    label: 'Completed On',
    renderDropdown: (props) => <ConnectedCompletedTimeFilter {...props} />,
  },
  {
    key: 'dueDate',
    label: 'Deadline',
    renderDropdown: (props) => <ConnectedDueDateFilter {...props} />,
  },
]
