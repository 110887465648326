import { useDeleteCalendarEventFn } from '~/global/rpc'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useUpdateEventPrompts } from './use-update-event-prompts'

export type DeleteEventOptions = {
  onConfirmation: () => Promise<void>
}

export const useDeleteEvent = () => {
  const { mutateAsync: deleteCalendarEvent } = useDeleteCalendarEventFn()
  const promptUpdateCalendarEvent = useUpdateEventPrompts()

  return useCallback(
    async (id: string, options: Partial<DeleteEventOptions> = {}) => {
      const { onConfirmation } = options
      const promptResponse = await promptUpdateCalendarEvent(id, {}, 'delete')
      if (!promptResponse) return

      const { sendUpdates, recurrenceUpdateType } = promptResponse

      await onConfirmation?.()

      await deleteCalendarEvent({
        id,
        sendUpdates,
        recurrenceUpdateType,
        timezone: DateTime.now().zoneName,
      })
    },
    [deleteCalendarEvent, promptUpdateCalendarEvent]
  )
}
