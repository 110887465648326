import { RefreshOutline } from '@motion/icons'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'

import type { ScheduledTaskWithRelation } from '~/global/proxies'

type TaskRecurringInstanceIconProps = {
  scheduledTask: ScheduledTaskWithRelation
}

export function TaskRecurringInstanceIcon({
  scheduledTask,
}: TaskRecurringInstanceIconProps) {
  return (
    <Tooltip
      content={
        scheduledTask.unfit
          ? 'This recurring task could not fit'
          : 'Recurring task'
      }
    >
      <RefreshOutlineIcon unfit={scheduledTask.unfit} />
    </Tooltip>
  )
}

const RefreshOutlineIcon = classed(RefreshOutline, {
  base: `
    rounded-lg h-2.5 w-2.5 p-px bg-semantic-neutral-bg-active-hover text-semantic-neutral-icon-default
  `,
  variants: {
    unfit: {
      true: 'bg-semantic-error-bg-strong-default text-semantic-error-icon-onDark',
      false: '',
    },
  },
  defaultVariants: {
    unfit: false,
  },
})
