import { Button, FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { statusColorOptions } from '@motion/ui-logic'

import { StatusBadge } from '~/global/components/badges'
import { LegacyColorDropdown } from '~/global/components/dropdowns'
import { useState } from 'react'

export type NewStatusModalProps = {
  onSave: (name: string, color: string) => Promise<void>
  onClose: () => void
}

export const NewStatusModal = (props: NewStatusModalProps) => {
  const { onSave, onClose } = props
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [currentColor, setCurrentColor] = useState<string>(
    statusColorOptions[0]
  )

  const onSubmit = async () => {
    setLoading(true)
    await onSave(name, currentColor)
    setLoading(false)
  }

  return (
    <FormModal
      visible
      title='Add status'
      onClose={onClose}
      submitAction={{
        onAction: onSubmit,
        disabled: !name || loading,
        text: 'Add status',
      }}
    >
      <div className='flex items-center gap-2 self-stretch min-w-[400px]'>
        <LegacyColorDropdown
          colorOptions={statusColorOptions}
          renderItem={(color, onClose) => (
            <Button
              iconOnly
              key={color}
              onClick={() => {
                setCurrentColor(color)
                onClose()
              }}
              size='small'
              variant='muted'
            >
              <StatusBadge
                value={{
                  color,
                  name,
                  type: null,
                }}
                hideTooltip
              />
            </Button>
          )}
          trigger={
            <Button
              iconOnly
              variant='outlined'
              sentiment='neutral'
              size='small'
            >
              <div className='p-1'>
                <StatusBadge
                  value={{
                    color: currentColor,
                    name,
                    type: null,
                  }}
                  hideTooltip
                />
              </div>
            </Button>
          }
        />
        <div className='w-full'>
          <TextField
            autoFocus
            placeholder='Enter status name'
            size='normal'
            value={name}
            onChange={setName}
          />
        </div>
      </div>
    </FormModal>
  )
}
