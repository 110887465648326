import { NumberField } from '@motion/ui/forms'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { forwardRef } from 'react'

import { numberCustomFieldToNumberInputFormat } from './mapping'

import { FieldIconContainer } from '../common/field-icon-container'
import { type CustomNumberInputProps } from '../types'

export const Number = forwardRef<HTMLLabelElement, CustomNumberInputProps>(
  ({ hideIcon, format = 'plain', ...props }: CustomNumberInputProps, ref) => {
    return (
      <NumberField
        ref={ref}
        labelHidden
        label={props.name}
        size='small'
        variant='muted'
        {...props}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onKeyDown={(e) => {
          props.onKeyDown?.(e)
        }}
        onChange={(val) => {
          props.onChange(isNaN(val) ? null : val)
        }}
        formatOptions={numberCustomFieldToNumberInputFormat[format]}
        placeholder='None'
        prefix={
          !hideIcon && (
            <FieldIconContainer size={props.size}>
              <CustomFieldBadge type='number' />
            </FieldIconContainer>
          )
        }
        value={props.value}
      />
    )
  }
)

Number.displayName = 'CustomFieldNumber'
