import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { type WorkspaceSchema } from '@motion/zod/client'

import { AppWorkspaceContext } from '~/global/contexts'

import { type WorkspaceCustomField } from '../types'
import { getFilteredWorkspaceCustomField } from '../utils'

export const useCustomFieldsByWorkspaceId = (
  workspaceId: WorkspaceSchema['id'] | undefined | null
): WorkspaceCustomField[] => {
  const fields = useSharedStateQuery(AppWorkspaceContext, (state) =>
    workspaceId == null
      ? []
      : state.customFields.all
          .filter((s) => s.workspaceId === workspaceId)
          .map((field) => getFilteredWorkspaceCustomField(field))
  )

  return fields
}
