import { LinkSolid } from '@motion/icons'
import { isCanceledStatus } from '@motion/shared/common'
import {
  ButtonGroup,
  IconButton,
  Shortcut,
  Tag,
  Tooltip,
} from '@motion/ui/base'
import { type StatusIconProps } from '@motion/ui/pm'
import { prettyDateDay, templateStr } from '@motion/ui-logic'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import { type TaskResponseSchema } from '@motion/zod/client'

import { StatusBadge } from '~/global/components/badges'
import { PriorityLabel, StatusLabel } from '~/global/components/labels'

import { TaskDurationField } from './fields'

import { highlightEmText } from '../../utils'
import {
  AssigneeField,
  CommentField,
  DateField,
  DescCommentContainer,
  FieldBar,
  PMResultTitle,
  ResultItem,
  type ResultItemProps,
  WorkspaceProjectField,
} from '../shared'

export interface TaskResultProps {
  item: TaskResponseSchema
  onClick: ResultItemProps['onClick']
  onCopyLink: () => void
}

export function TaskResult({ item, onClick, onCopyLink }: TaskResultProps) {
  const title = (
    <div className='inline-flex gap-1.5 items-center'>
      <PMResultTitle canceled={isCanceledStatus(item.status)}>
        {highlightEmText(item.name)}
      </PMResultTitle>
      {item.isRecurring && (
        <Tag color='grey' size='small' variant='subtle'>
          Recurring
        </Tag>
      )}
    </div>
  )

  const taskVariant: StatusIconProps['taskVariant'] = item.isMeetingTask
    ? 'meeting'
    : item.isSchedulingTask
      ? 'scheduling'
      : 'default'
  const isGhost = isGhostTask(item)

  return (
    <ResultItem
      renderIcon={() => (
        <StatusBadge
          value={item.status}
          iconVariant={isGhost ? 'isUnvisitedStage' : undefined}
          taskVariant={taskVariant}
        />
      )}
      variant={isGhost ? 'subtle' : 'default'}
      title={title}
      onClick={onClick}
      renderControls={() => {
        return (
          <ButtonGroup size='small'>
            <Tooltip
              asChild
              renderContent={() =>
                templateStr('Copy link {{shortcut}}', {
                  shortcut: <Shortcut shortcut='mod+l' />,
                })
              }
            >
              <IconButton
                icon={LinkSolid}
                size='small'
                variant='outlined'
                sentiment='neutral'
                aria-label='Copy task link'
                onClick={(evt) => {
                  evt.stopPropagation()
                  onCopyLink()
                }}
              />
            </Tooltip>
          </ButtonGroup>
        )
      }}
    >
      <FieldBar>
        <WorkspaceProjectField
          workspaceName={item.workspace.name}
          projectName={item.project.name}
        />
        {item.dueDate && <DateField>{prettyDateDay(item.dueDate)}</DateField>}
        <TaskDurationField duration={item.duration} />
        <StatusLabel size='small' value={item.status} />
        <PriorityLabel size='small' value={item.priorityLevel} />
        <AssigneeField
          assigneeUserId={item.assigneeUserId}
          assigneeName={item.assignee.name}
        />
      </FieldBar>
      <DescCommentContainer>
        {item.description && <div>{highlightEmText(item.description)}</div>}
        {item.comment && <CommentField comment={item.comment} />}
      </DescCommentContainer>
    </ResultItem>
  )
}
