import { FormModal, showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'

import type { ModalTriggerComponentProps } from '~/areas/modals'
import { copyToClipboard } from '~/localServices/clipboard'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'embed-booking': {
      link: string
    }
  }
}

export const EmbedBookingModal = ({
  link,
  close,
}: ModalTriggerComponentProps<'embed-booking'>) => {
  useOnMountAnalyticsEvent('EMBED_BOOKING_SHOW')

  const code = `<!-- Motion embed begin -->\n<iframe src="${link}" title="Motion Booking Page" width="100%" height="840px" frameborder="0"></iframe>\n<!-- Motion embed end -->`

  return (
    <FormModal
      visible
      onClose={close}
      cancelAction={{ text: 'Close' }}
      title='Embed on website'
      submitAction={{
        text: 'Copy code',
        onAction: () => {
          void copyToClipboard({ text: code })
          showToast('neutral', 'Code copied to clipboard!')
          recordAnalyticsEvent('EMBED_BOOKING_COPY')
          close()
        },
      }}
    >
      <div className='flex flex-col gap-2 w-[450px]'>
        <p>
          Place this code in your page&apos;s HTML where you want your booking
          page to appear.
        </p>

        <TextField
          multiline
          label='Website embed code'
          labelHidden
          value={code}
          onChange={() => null}
          onFocus={(e) => {
            e?.target.select()
          }}
        />
      </div>
    </FormModal>
  )
}
