import { CalendarSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { Tag } from '@motion/ui/base'
import {
  type ColorId,
  formatDateTimeRange,
  getColorClassForColorId,
  getConferenceTypeFromConferenceLink,
} from '@motion/ui-logic'
import { type CalendarSchema } from '@motion/zod/client'

import {
  CalendarField,
  ConferenceField,
  LocationField,
  RecurrenceField,
} from './fields'

import { type CalendarEventSearchSchema, highlightEmText } from '../../utils'
import {
  DateField,
  FieldBar,
  ResultItem,
  type ResultItemProps,
  WorkspaceProjectField,
} from '../shared'

export interface EventResultProps {
  activeCalendars: CalendarSchema[]
  item: CalendarEventSearchSchema
  onClick: ResultItemProps['onClick']
}

export function EventResult({
  activeCalendars,
  item,
  onClick,
}: EventResultProps) {
  const calendarColorId = (activeCalendars.find(
    (cal) => cal.id === item.calendarId
  )?.colorId ?? '0') as ColorId

  const colorId = getColorClassForColorId(calendarColorId, '0')

  const isRecurring = item.recurringEventId != null

  const title = (
    <div className='inline-flex gap-1.5 items-center'>
      <span>{highlightEmText(item.title)}</span>
      {isRecurring && (
        <Tag color='grey' size='small' variant='subtle'>
          Recurring
        </Tag>
      )}
    </div>
  )

  return (
    <ResultItem
      renderIcon={() => (
        <CalendarSolid className='shrink-0 text-semantic-neutral-icon-default w-4 h-4' />
      )}
      title={title}
      onClick={onClick}
    >
      <FieldBar>
        {item.meetingTask?.workspace?.name != null && (
          <WorkspaceProjectField
            workspaceName={item.meetingTask.workspace.name}
            projectName={item.meetingTask.project?.name}
            projectColor={item.meetingTask.project?.color as COLOR | undefined}
          />
        )}
        <DateField>{formatDateTimeRange(item.start, item.end)}</DateField>
        {item.recurrence && <RecurrenceField recurrence={item.recurrence} />}
        <CalendarField colorId={colorId} email={item.email} />
        {!item.conferenceLink && item.location && (
          <LocationField loc={item.location} />
        )}
        {item.conferenceLink && (
          <ConferenceField
            conferenceName={getConferenceTypeFromConferenceLink({
              conferenceLink: item.conferenceLink,
              readable: true,
            })}
          />
        )}
      </FieldBar>
    </ResultItem>
  )
}
