import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

export type GridLayoutProps = {
  children: ReactNode
}

export const GridLayout = (props: GridLayoutProps) => {
  return <GridLayoutContainer>{props.children}</GridLayoutContainer>
}

// borders - https://geary.co/internal-borders-css-grid/
const GridLayoutContainer = classed('div', {
  base: `
    grid
    gap-12
    
    grid-cols-[repeat(12,minmax(70px,1fr))]
    [grid-auto-rows:95px]
    
    

    [&>*]:relative

    /* column */
    [&>*]:before:absolute
    [&>*]:before:bg-semantic-neutral-border-light
    [&>*]:before:[inline-size:1px]
    [&>*]:before:[block-size:100%]    
    [&>*]:before:[inset-inline-start:calc(100%+24px)]

    /* row */
    [&>*]:after:absolute
    [&>*]:after:bg-semantic-neutral-border-light
    [&>*]:after:[inline-size:calc(100%+48px)]
    [&>*]:after:[block-size:1px]
    [&>*]:after:[inset-inline-start:-24px]
    [&>*]:after:[inset-block-start:calc(100%+24px)]    

  `,
  variants: {
    debug: {
      true: `
        [&>*]:before:bg-white
        [&>*]:after:bg-white
      `,
      false: '',
    },
  },
  defaultVariants: {
    debug: false,
  },
})
