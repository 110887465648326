import {
  API,
  type ApiUseMutationOptions,
  createUseMutation,
  createUseQuery,
} from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'
import { applyOptimisticProjectDefinitionUpdates } from '~/global/cache'

const cacheKeysToUpdate = [API.workspacesV2.queryKeys.root, MODEL_CACHE_KEY]
const getCacheKeysWithTemplateId = (templateId: string) => [
  ...cacheKeysToUpdate,
  API.projectsDefinitions.queryKeys.query({ id: templateId }),
]

export const useGetProjectDefinitionById = () => {
  const queryClient = useQueryClient()
  return createUseQuery(API.projectsDefinitions.getById, {
    select: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(getCacheKeysWithTemplateId(data.id)),
        data
      )
      return data
    },
  })
}

export const useCreateProjectDefinition = () => {
  const queryClient = useQueryClient()
  return createUseMutation(API.projectsDefinitions.create)({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(cacheKeysToUpdate),
        data
      )
    },
  })
}
const useUpdateProjectDefinitionMutation = createUseMutation(
  API.projectsDefinitions.update
)
export const useUpdateProjectDefinition = (
  opts?: ApiUseMutationOptions<typeof API.projectsDefinitions.update>
) => {
  const queryClient = useQueryClient()

  return useUpdateProjectDefinitionMutation({
    onMutate: async (updatedProjectDefinition) => {
      const cacheUpdates = applyOptimisticProjectDefinitionUpdates(
        queryClient,
        updatedProjectDefinition.id,
        {
          id: updatedProjectDefinition.id,
          ...updatedProjectDefinition.definition,
        }
      )

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(cacheKeysToUpdate),
        data
      )
    },
    onError: (err, _, context) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },

    ...opts,
  })
}

const useDeleteProjectDefinitionMutation = createUseMutation(
  API.projectsDefinitions.deleteProjectDefinition
)
export const useDeleteProjectDefinition = () => {
  const queryClient = useQueryClient()

  return useDeleteProjectDefinitionMutation({
    onSuccess: (_, { id }) => {
      MotionCache.delete(
        queryClient,
        createQueryFilter(cacheKeysToUpdate),
        'projectDefinitions',
        id
      )
    },
  })
}

const useCopyProjectDefinitionMutation = createUseMutation(
  API.projectsDefinitions.copyProjectDefinition
)
export const useCopyProjectDefinition = () => {
  const queryClient = useQueryClient()

  return useCopyProjectDefinitionMutation({
    onSuccess: (data) => {
      MotionCache.upsert(
        queryClient,
        createQueryFilter(cacheKeysToUpdate),
        data
      )
    },
  })
}
