import { adjustDateToEndOfDay } from '@motion/ui-logic/pm/task'
import type { NormalTaskSchema, TaskSchema } from '@motion/zod/client'

import {
  DeadlineDateDropdownContent,
  DeadlineDropdown,
  type DeadlineDropdownProps,
} from '~/areas/project-management/components'

export type TaskDeadlineDropdownProps = Omit<
  DeadlineDropdownProps,
  'dropdownTarget'
> & {
  projectId?: TaskSchema['projectId']
  stageDefinitionId?: NormalTaskSchema['stageDefinitionId']
}

/**
 * Stage-aware task deadline dropdown.
 *
 * If a valid projectId and stageDefinitionId are provided,
 * and the stage deadline is a valid selection,
 * the dropdown will include a "Stage deadline" option.
 */
export const TaskDeadlineDropdown = ({
  children,
  onChange,
  value,
  ...rest
}: TaskDeadlineDropdownProps) => {
  return (
    <DeadlineDropdown
      renderContent={({ close }) => (
        <DeadlineDateDropdownContent
          onChange={(updateValue) => {
            // If going from no value to a value, set the end of the day
            const endOfDayDeadline =
              value == null ? adjustDateToEndOfDay(updateValue) : updateValue

            onChange(endOfDayDeadline)
            close()
          }}
          value={value}
          {...rest}
          dropdownTarget='task'
        />
      )}
      onChange={onChange}
      value={value}
      {...rest}
      dropdownTarget='task'
    >
      {children}
    </DeadlineDropdown>
  )
}
