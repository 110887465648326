import {
  type AvailableCustomFieldTypes,
  type CustomFieldValueSchemaByType,
} from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'

import { forwardRef, type ReactElement, type Ref } from 'react'

import { TaskModalCustomField } from './categories'

import { type ModalCustomField } from '../types'

export type CustomFieldUISwitchProps = {
  value: CustomFieldValueSchemaByType<AvailableCustomFieldTypes>['value']
  onChange: (...args: unknown[]) => void
  customField: ModalCustomField
  disabled?: boolean
  size?: 'small' | 'xsmall'
}

export const CustomFieldUISwitch = forwardRef<
  unknown,
  CustomFieldUISwitchProps
>((props, ref) => {
  const lookup = makeFieldLookup(props, ref)

  const fn = lookup[props.customField.type]
  if (!fn) {
    const e = new Error(`Unknown custom field type`)
    Sentry.captureException(e, { extra: { customField: props.customField } })
    throw e
  }

  return fn()
})

CustomFieldUISwitch.displayName = 'CustomFieldUISwitch'

function makeFieldLookup(
  { value, onChange, customField, disabled, size }: CustomFieldUISwitchProps,
  ref: React.ForwardedRef<unknown>
): Record<
  | 'number'
  | 'text'
  | 'url'
  | 'date'
  | 'select'
  | 'multiSelect'
  | 'person'
  | 'multiPerson',
  () => ReactElement
> {
  const name = customField.name
  return {
    text: () => (
      <TaskModalCustomField.Text
        ref={ref as Ref<HTMLLabelElement>}
        name={name}
        value={(value as CustomFieldValueSchemaByType<'text'>['value']) ?? ''}
        onChange={onChange}
        disabled={disabled}
        hideIcon
        size={size}
        variant='minimal'
      />
    ),
    date: () => (
      <TaskModalCustomField.Date
        ref={ref as Ref<HTMLButtonElement>}
        name={name}
        value={(value as CustomFieldValueSchemaByType<'date'>['value']) ?? ''}
        onChange={onChange}
        disabled={disabled}
        size={size}
        hideIcon
      />
    ),
    number: () => (
      <TaskModalCustomField.Number
        ref={ref as Ref<HTMLLabelElement>}
        name={name}
        value={value as CustomFieldValueSchemaByType<'number'>['value']}
        customFieldId={customField.instanceId}
        onChange={onChange}
        disabled={disabled}
        size={size}
        hideIcon
        variant='minimal'
      />
    ),
    url: () => (
      <TaskModalCustomField.Url
        ref={ref as Ref<HTMLLabelElement | HTMLDivElement>}
        name={name}
        value={(value as CustomFieldValueSchemaByType<'url'>['value']) ?? ''}
        onChange={onChange}
        disabled={disabled}
        size={size}
        hideIcon
        variant='minimal'
      />
    ),
    select: () => (
      <TaskModalCustomField.Select
        value={value as CustomFieldValueSchemaByType<'select'>['value']}
        customField={
          customField as Extract<ModalCustomField, { type: 'select' }>
        }
        onChange={onChange}
        size={size}
        disabled={disabled}
      />
    ),
    multiSelect: () => (
      <TaskModalCustomField.MultiSelect
        value={value as CustomFieldValueSchemaByType<'multiSelect'>['value']}
        customField={
          customField as Extract<ModalCustomField, { type: 'multiSelect' }>
        }
        onChange={onChange}
        size={size}
        disabled={disabled}
      />
    ),
    person: () => (
      <TaskModalCustomField.Person
        customField={
          customField as Extract<ModalCustomField, { type: 'person' }>
        }
        value={value as CustomFieldValueSchemaByType<'person'>['value']}
        onChange={onChange}
        size={size}
        disabled={disabled}
      />
    ),
    multiPerson: () => (
      <TaskModalCustomField.MultiPerson
        customField={
          customField as Extract<ModalCustomField, { type: 'multiPerson' }>
        }
        value={value as CustomFieldValueSchemaByType<'multiPerson'>['value']}
        onChange={onChange}
        size={size}
        disabled={disabled}
      />
    ),
  }
}
