import {
  DatePicker,
  type DatePickerProps,
  type DateRangeColorData,
} from '@motion/ui/base'
import type { CalendarStartDay, QuickAction } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { DateActionSection } from '~/areas/project-management/components/dropdowns/date-action-section'
import { DateDropdownHeader } from '~/areas/project-management/components/dropdowns/date-dropdown-header'
import type { DateTime } from 'luxon'
import { type ReactNode, useMemo, useRef } from 'react'

import { mergeDateWithTime } from '../../utils'

export type ContextAwareDateDropdownContentProps = {
  value: string | null
  disabledDate?: DatePickerProps['disabledDate']
  disableClearing?: boolean
  quickActions?: QuickAction[]
  showTimeOptions?: boolean
  renderCalendarFooter?: () => ReactNode
  onChange(value: string | null): void
  formatDateDisplay?: (date: DateTime) => string
  dateRangeColors?: DateRangeColorData[]
  renderHeaderSuffix?: () => ReactNode
  disabledDateTooltipContent?: DatePickerProps['disabledDateTooltipContent']
  calendarStartDay?: CalendarStartDay
}

export function ContextAwareDateDropdownContent({
  value,
  disabledDate,
  disabledDateTooltipContent,
  disableClearing = false,
  calendarStartDay,
  quickActions = [],
  showTimeOptions = false,
  onChange,
  renderCalendarFooter,
  formatDateDisplay = (date) => date.toFormat('MMMM d'),
  dateRangeColors = [],
  renderHeaderSuffix,
}: ContextAwareDateDropdownContentProps) {
  const actionListContainerRef = useRef<HTMLDivElement>(null)

  const valueConverted = useMemo(
    () => (value ? parseDate(value) : null),
    [value]
  )

  const dateActions = disabledDate
    ? quickActions.filter((action) => {
        return !disabledDate(action.value)
      })
    : quickActions

  return (
    <div className='grid grid-cols-[auto_auto]'>
      <div className='flex flex-col border-r border-dropdown-border'>
        <div className='py-2'>
          <DateDropdownHeader
            value={valueConverted}
            formatDateDisplay={formatDateDisplay}
            disableClearing={disableClearing}
            onChange={(v) => onChange(v)}
            showTimeOptions={showTimeOptions}
            renderSuffix={renderHeaderSuffix}
          />

          <div className='pt-3 px-1'>
            <DatePicker
              weekStartDay={calendarStartDay}
              value={value}
              onChange={(v) => {
                if (!v || Array.isArray(v)) return

                const vDate = parseDate(v)

                onChange(mergeDateWithTime(vDate, valueConverted).toISO())
              }}
              disabledDate={disabledDate}
              disabledDateTooltipContent={disabledDateTooltipContent}
              dateRangeColors={dateRangeColors}
              showDayNumberSubtext
              showDayNumberSupertext
            />
          </div>

          {!!renderCalendarFooter && (
            <div className='pt-3 px-3'>{renderCalendarFooter()}</div>
          )}
        </div>
      </div>
      {dateActions.length > 0 && (
        <DateActionSection
          contentRef={actionListContainerRef}
          currentValue={valueConverted}
          dateActions={dateActions}
          onChange={onChange}
        />
      )}
    </div>
  )
}
