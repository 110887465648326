import { type BulkFieldUpdateSchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback } from 'react'

import { useBulkUpdateSelection } from './use-bulk-update-selection'

import { useBulkOpsFields, useBulkOpsState } from '../../contexts'

/**
 * This hook allows the user to update multiple fields at the same time
 * by reading all changes made to the BulkOps Fields context.
 */
export function useBulkUpdateFields() {
  const lookup = useLookup()
  const { setSelectedIds } = useBulkOpsState()
  const fieldCtx = useBulkOpsFields()
  const { bulkUpdateTaskSelection } = useBulkUpdateSelection()
  const { getStageDefinition } = useWorkspaceFns()

  return useCallback(async () => {
    const statuses = fieldCtx.fields.statusIds.map((statusId) =>
      lookup('statuses', statusId)
    )

    const labels =
      fieldCtx.fields.labels?.map((field) => ({
        ...field,
        workspaceId: lookup('labels', field.id).workspaceId,
      })) ?? []

    const stages = fieldCtx.fields.stageDefinitionIds.map((stageId) =>
      getStageDefinition(stageId)
    )

    await bulkUpdateTaskSelection((group) => {
      return {
        type: 'bulk-field-update' as const,
        statusId:
          statuses.length > 0
            ? statuses.find((s) => s.workspaceId === group.key)?.id
            : undefined,
        isAutoScheduled: fieldCtx.fields.isAutoScheduled,
        assigneeUserId: fieldCtx.fields.assigneeUserId,
        priorityLevel: fieldCtx.fields.priorityLevel,
        duration: fieldCtx.fields.duration,
        startDate: fieldCtx.fields.startDate,
        dueDate: fieldCtx.fields.dueDate,
        stageDefinitionId:
          stages.length > 0
            ? stages.find((stage) => stage?.workspaceId === group.key)?.id
            : undefined,
        labels:
          labels.length > 0
            ? labels.filter((s) => s.workspaceId === group.key)
            : undefined,
        customFieldValues: fieldCtx.fields.customFieldValues,
      } satisfies BulkFieldUpdateSchema
    })

    // Reset the selected ids and fields
    setSelectedIds([])
    fieldCtx.resetAllFields()
  }, [
    bulkUpdateTaskSelection,
    fieldCtx,
    getStageDefinition,
    lookup,
    setSelectedIds,
  ])
}
