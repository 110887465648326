import { useAppDispatch, useAppSelector } from '../../state/hooks'
import {
  selectCalendarTurnIntoFixedTimeTaskState,
  setCalendarTurnIntoFixedTimeTaskState,
} from '../../state/modalsSlice'
import { CalendarTurnIntoFixedTimeTaskModal } from '../Modals/CalendarTurnIntoFixedTimeTaskModal/CalendarTurnIntoFixedTimeTaskModal'

const CalendarTurnIntoFixedTimeTaskHandler = () => {
  const dispatch = useAppDispatch()
  const visible = useAppSelector(selectCalendarTurnIntoFixedTimeTaskState)

  const handleCancel = () => {
    dispatch(setCalendarTurnIntoFixedTimeTaskState('closed'))
  }

  const handleConfirm = () => {
    dispatch(setCalendarTurnIntoFixedTimeTaskState('confirmed'))
  }

  if (visible !== 'open') return null
  return (
    <CalendarTurnIntoFixedTimeTaskModal
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  )
}

export { CalendarTurnIntoFixedTimeTaskHandler }
