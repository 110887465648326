import { Portal, Tooltip } from '@motion/ui/base'

import * as Menu from '@radix-ui/react-dropdown-menu'
import React, { Fragment, type ReactElement, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { DropdownSubMenu } from './DropdownSubMenu'
import { type DropdownMenuOption } from './types'

import { dropdownContainerColorClasses } from '../GeneralComponentStyles'
import { MenuItem } from '../MenuItem/MenuItem'

export interface DropdownMenuProps {
  trigger: ReactElement
  options: (DropdownMenuOption | boolean)[]
  align?: 'end' | 'start' | 'center'
  onOpenChange?: (open: boolean) => void
  className?: string
  containerClassName?: string
  // See radix ui documentation on this prop: https://www.radix-ui.com/docs/primitives/components/dropdown-menu#api-reference
  modal?: boolean
}

/**
 * @deprecated Use a `PopoverTrigger` with an `ActionList` instead
 */
export const DropdownMenu = ({
  trigger,
  options = [],
  align = 'end',
  onOpenChange = () => null,
  className = '',
  containerClassName = '',
  modal = true,
}: DropdownMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const triggerRef = useRef<HTMLDivElement>(null)

  const handleSelect = (event: Event, selected: DropdownMenuOption) => {
    event.preventDefault()
    event.stopPropagation()

    if (!selected.onSelect) {
      return
    }

    selected.onSelect(event, selected)
  }

  const handleCheck = (checked: boolean, selected: DropdownMenuOption) => {
    if (!selected.onCheck) {
      return
    }
    selected.onCheck(checked, selected)
  }

  const handleClick = () => {
    onOpenChange(true)
    setIsOpen(true)
  }

  const clone = React.cloneElement(trigger, {
    onClick: handleClick,
    ref: triggerRef,
  })

  const renderOption = (option: DropdownMenuOption) => {
    return (
      <Fragment key={`dropdown-menu-${option.label}`}>
        {option.checkable && (
          <Menu.CheckboxItem
            checked={option.isChecked}
            onSelect={() => {
              handleCheck(!option.isChecked, option)
              setIsOpen(false)
            }}
            disabled={option.disabled}
          >
            <MenuItem {...option} openHandler={setIsOpen} />
          </Menu.CheckboxItem>
        )}
        {!option.checkable && !option.children && (
          <Menu.Item
            asChild
            onSelect={(e) => {
              handleSelect(e, option)
              setIsOpen(false)
            }}
            disabled={option.disabled || option.isTitle}
          >
            <MenuItem {...option} openHandler={setIsOpen} />
          </Menu.Item>
        )}
        {!option.checkable && option.children && (
          <DropdownSubMenu
            trigger={<MenuItem {...option} />}
            onSelect={() => setIsOpen(false)}
            options={option.children}
          />
        )}
      </Fragment>
    )
  }

  return (
    <div className='relative inline-flex'>
      {clone}
      {isOpen && (
        <Menu.Root
          modal={modal}
          onOpenChange={(open) => {
            onOpenChange(open)
            setIsOpen(open)
            triggerRef.current?.focus()
          }}
          defaultOpen
        >
          <Menu.Trigger asChild>
            <div
              style={{
                pointerEvents: 'none',
                inset: 0,
                position: 'absolute',
              }}
            />
          </Menu.Trigger>
          {isOpen && (
            <Portal>
              <Menu.Content
                asChild
                align={align}
                avoidCollisions
                className={twMerge(
                  'z-20',
                  'radix-state-open:animate-fadein transition-opacity duration-1000 ease-out',
                  containerClassName
                )}
              >
                <div
                  className={twMerge(
                    'mt-1 max-h-60 overflow-y-auto rounded border shadow-lg',
                    dropdownContainerColorClasses,
                    className
                  )}
                >
                  {options
                    .filter(
                      (option) =>
                        option && typeof option !== 'boolean' && !!option.label
                    )
                    .map((option) => {
                      // To allow evaluating of options that are conditional
                      if (typeof option === 'boolean') return

                      const optionComponent = renderOption(option)
                      return option.tooltipText ? (
                        <Tooltip content={option.tooltipText}>
                          {optionComponent}
                        </Tooltip>
                      ) : (
                        optionComponent
                      )
                    })}
                </div>
              </Menu.Content>
            </Portal>
          )}
        </Menu.Root>
      )}
    </div>
  )
}
