import { FieldButton } from '@motion/ui/base'

import { useWorkspaceById } from '~/global/hooks'

import { useProjectForm } from '../hooks'

export const FormWorkspaceDropdown = () => {
  const { form } = useProjectForm()

  const workspaceId = form.watch('workspaceId')

  const selectedWorkspace = useWorkspaceById(workspaceId)

  return (
    <FieldButton size='small' variant='muted' disabled>
      {selectedWorkspace?.name}
    </FieldButton>
  )
}
