import { StageSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { classed } from '@motion/theme'
import { type Group, Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { StageLabel } from '~/global/components/labels'
import {
  AppWorkspaceContext,
  type WorkspaceStageDefinition,
} from '~/global/contexts'
import { useProjectDefinition, useWorkspaceById } from '~/global/hooks'
import { type PropsWithChildren } from 'react'

import { ConnectedGroupedItemDropdown } from './connected-grouped-item-dropdown'
import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedStageFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter(props.target, 'stageDefinitionIds')
  const ids = value?.value ?? null
  const [ctx] = useSharedState(AppWorkspaceContext)

  const getAllItems = () => ctx.stageDefinitions.all

  return (
    <FilterItem
      label='Stages'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...(value ?? {}), inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedGroupedItemDropdown
          applyTo={props.target}
          field='stageDefinitionIds'
          getAllItems={getAllItems}
          placeholder='Choose stages...'
          renderItem={(item) => (
            <StageItem item={item}>
              <StageLabel value={item} />
            </StageItem>
          )}
          renderHeader={(group) => (
            <StageItemGroup group={group}>
              <StageLabel value={group.items[0]} />
            </StageItemGroup>
          )}
          close={close}
        />
      )}
    >
      <MultiSelectFilterValue
        Icon={StageSolid}
        type='stageDefinitions'
        ids={ids}
        getAllItems={getAllItems}
        labelSize='small'
      />
    </FilterItem>
  )
}

const StageItem = ({
  item,
  children,
}: PropsWithChildren<{ item: WorkspaceStageDefinition }>) => {
  return (
    <Container>
      {children}
      <ConnectedItemSubTitle item={item} />
    </Container>
  )
}

const StageItemGroup = ({
  group,
  children,
}: PropsWithChildren<{ group: Group<WorkspaceStageDefinition> }>) => {
  const hasSingleItem = group.items.length === 1

  return (
    <Container>
      {children}
      {hasSingleItem ? (
        <ConnectedItemSubTitle item={group.items[0]} />
      ) : (
        <Subtitle>
          {templateStr('({{count}} templates)', { count: group.items.length })}
        </Subtitle>
      )}
    </Container>
  )
}

const ConnectedItemSubTitle = ({
  item,
}: {
  item: WorkspaceStageDefinition
}) => {
  const workspace = useWorkspaceById(item.workspaceId)
  const projectDefinition = useProjectDefinition(item.projectDefinitionId)

  const projectDefName = projectDefinition?.name ?? 'unknown'
  const workspaceName = workspace?.name ?? 'unknown'

  return (
    <Tooltip asChild content={[projectDefName, workspaceName].join(', ')}>
      <Subtitle>({[projectDefName, workspaceName].join(', ')})</Subtitle>
    </Tooltip>
  )
}

const Container = classed(
  'div',
  'grid grid-cols-[auto,minmax(0,auto)] overflow-hidden gap-x-1 items-center max-w-xs'
)

const Subtitle = classed(
  'span',
  'text-xs text-dropdown-item-text-disabled text-nowrap text-ellipsis overflow-hidden'
)
