import {
  CustomFieldCheckboxSolid,
  CustomFieldDateSolid,
  CustomFieldMultiPersonSolid,
  CustomFieldMultiSelectSolid,
  CustomFieldNumberSolid,
  CustomFieldPersonSolid,
  CustomFieldSelectSolid,
  CustomFieldTextSolid,
  CustomFieldUrlSolid,
  MailSolid,
  PhoneSolid,
  SearchSolid,
  type SvgIcon,
} from '@motion/icons'
import { type FieldTypeSchema } from '@motion/zod/client'

const CUSTOM_FIELD_CATEGORY_ICON_MAP: Record<FieldTypeSchema, SvgIcon> = {
  text: CustomFieldTextSolid,
  number: CustomFieldNumberSolid,
  url: CustomFieldUrlSolid,
  date: CustomFieldDateSolid,
  person: CustomFieldPersonSolid,
  multiPerson: CustomFieldMultiPersonSolid,
  select: CustomFieldSelectSolid,
  multiSelect: CustomFieldMultiSelectSolid,
  checkbox: CustomFieldCheckboxSolid,
  // Placeholders
  email: MailSolid,
  phone: PhoneSolid,
  relatedTo: SearchSolid,
}

export function getCustomFieldIcon(type: FieldTypeSchema): SvgIcon {
  return (
    CUSTOM_FIELD_CATEGORY_ICON_MAP[type] ?? CUSTOM_FIELD_CATEGORY_ICON_MAP.text
  )
}
