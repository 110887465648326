import {
  mergeContactsWithEventGuests,
  parseAndCountCalendarEventAttendees,
} from '@motion/ui-logic'

import { createSelector } from '@reduxjs/toolkit'

import { selectSearchContacts } from '../accountsSlice'

/**
 * Merge the event contacts with the search results preserving the rank information.
 */
export const selectRankedSearchContacts = createSelector(
  [selectSearchContacts, (_, calendarEvents) => calendarEvents],
  (searchContacts, calendarEvents) => {
    const eventContacts = parseAndCountCalendarEventAttendees(calendarEvents)
    return mergeContactsWithEventGuests(searchContacts, eventContacts)
  }
)
