export class ActivityFeedMetadataError extends Error {
  readonly metadata: unknown

  constructor(metadata?: unknown) {
    super('Metadata has invalid data')
    this.name = this.constructor.name

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }

    // Add metadata
    this.metadata = metadata
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      metadata: this.metadata,
    }
  }
}
