import { BlockedBySolid } from '@motion/icons'
import { PopoverTrigger, type PopoverTriggerProps } from '@motion/ui/base'
import { type NormalTaskSchema } from '@motion/zod/client'

import { SidebarTaskList } from '~/areas/task-project/components'
import { useBlockerTasks, useTaskBlockersUpdater } from '~/areas/tasks/hooks'
import { type ReactNode } from 'react'

import { BlockersDropdown } from './blockers-dropdown'
import { NormalTaskBlockersContent } from './blockers-type-dropdown'
import { BlockersDropdownContainer, Header } from './styled'

export type BlockedByBlockerDropdownProps = Omit<
  BlockedByBlockerDropdownContentProps,
  'close'
> & {
  children: ReactNode
  placement?: PopoverTriggerProps['placement']
}

export function BlockedByBlockerDropdown({
  task,
  placement = 'bottom-start',
  children,
}: BlockedByBlockerDropdownProps) {
  return (
    <PopoverTrigger
      renderPopover={({ close }) => (
        <BlockedByBlockerDropdownContent close={close} task={task} />
      )}
      placement={placement}
    >
      {children}
    </PopoverTrigger>
  )
}

type BlockedByBlockerDropdownContentProps = {
  close: () => void
  task: NormalTaskSchema
}
const BlockedByBlockerDropdownContent = ({
  task,
}: BlockedByBlockerDropdownContentProps) => {
  const { removeTaskBlocker } = useTaskBlockersUpdater({ type: 'blockedBy' })

  const { blockedBy } = useBlockerTasks({
    taskId: task.id,
  })

  const isEmpty = blockedBy.length === 0

  return (
    <BlockersDropdownContainer>
      {isEmpty ? (
        <NormalTaskBlockersContent
          close={() => null}
          task={task}
          type='blockedBy'
        />
      ) : (
        <>
          <Header>
            <div className='flex items-center gap-1'>
              <BlockedBySolid
                width={14}
                className='text-semantic-error-icon-default'
              />
              Blocked By
            </div>

            <BlockersDropdown type='blockedBy' sameProject task={task} />
          </Header>

          <div className='px-3 py-4'>
            <SidebarTaskList
              tasks={blockedBy}
              onRemove={(blocker) => removeTaskBlocker(task, blocker)}
              size='xsmall'
            />
          </div>
        </>
      )}
    </BlockersDropdownContainer>
  )
}
