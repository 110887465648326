import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type AnalyticEvents } from '@motion/web-base/analytics/events'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useCallback } from 'react'

import { SORT_NONE } from '../components/sort-button/utils'
import {
  getCustomFieldIdFromColumnId,
  isCustomFieldColumn,
  type SortField,
} from '../fields'

export function useRecordAnalyticsSortEvent() {
  const { getCustomField } = useCustomFieldsFns()

  return useCallback(
    (value: string | undefined, direction: 'asc' | 'desc' | 'unset') => {
      const getAnalyticsSortValue = (
        value: string
      ): AnalyticEvents['PROJECT_MANAGEMENT_UPDATE_SORT']['sort'] => {
        if (isCustomFieldColumn(value)) {
          const customFieldId = getCustomFieldIdFromColumnId(value)
          if (customFieldId != null) {
            const field = getCustomField(customFieldId)
            return `customField_${field?.type}`
          }
        }
        return value === SORT_NONE.id ? 'none' : (value as SortField)
      }

      if (value != null) {
        const analyticsValue = getAnalyticsSortValue(value)

        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_SORT', {
          sort: analyticsValue,
        })
      }

      if (value !== SORT_NONE.id) {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_SORT_DIRECTION', {
          direction:
            direction === 'unset'
              ? 'none'
              : direction === 'asc'
                ? 'ASC'
                : 'DESC',
        })
      }
    },
    [getCustomField]
  )
}
