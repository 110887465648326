import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'
import { type RootState } from './store'

import { type ApiGetUsageInterval, type ApiUsage } from '../services/apiService'

const apiServiceHandler = getProxy('ApiService')

export const getApiUsage = createAsyncThunk<ApiUsage, ApiGetUsageInterval>(
  'api/usage/get',
  async (interval) => {
    return apiServiceHandler.getUsage(interval)
  }
)

export interface APIState {
  usage?: ApiUsage
}

export const initialApiState: APIState = {
  usage: undefined,
}

export const apiSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getApiUsage.fulfilled, (state, action) => {
      state.usage = action.payload
    })
  },
  initialState: initialApiState,
  name: 'api',
  reducers: {
    reset: () => initialApiState,
  },
})

export const { reset } = apiSlice.actions

export const selectApiUsage = (state: RootState) => state.api.usage

export const apiReducer = apiSlice.reducer
