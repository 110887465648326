import { type MotionLocalStorageItems } from '~/localServices/storage/types'
import { useEffect, useState } from 'react'

import api from '../../../../chromeApi/chromeApiContentScript'

/**
 * Utility hook to keep in sync w/ `api.storage.local`
 * Returns a mutable "local" copy that can be updated (e.g for optimistic UI)
 *
 * Usage:
 * `const [contacts, setContacts] = useApiStorage('contacts', [])`
 *
 * @param key
 * @param initialState
 * @returns [data, setData]
 */
export function useApiStorage<T extends keyof MotionLocalStorageItems>(
  key: T,
  initialState: MotionLocalStorageItems[T]
): [MotionLocalStorageItems[T], (update: MotionLocalStorageItems[T]) => void] {
  const [data, setData] = useState<MotionLocalStorageItems[T] | null>(
    initialState
  )

  useEffect(() => {
    const getFromStorage = async () => {
      const result = await api.storage.local.get([key])

      const data = result[key]
      setData(data)
    }

    void getFromStorage()

    const listener = (changes: any) => {
      if (key in changes) {
        setData(changes[key].newValue)
      }
    }

    api.storage.onChanged.addListener(listener)
    return () => api.storage.onChanged.removeListener(listener)
  }, [key])

  return [data as MotionLocalStorageItems[T], setData]
}
