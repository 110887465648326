import { type TaskResponseSchema } from '@motion/zod/client'

import { ConnectedUserBadge } from '~/global/components/badges'

import { highlightEmText } from '../../../utils'

interface CommentFieldProps {
  comment: NonNullable<TaskResponseSchema['comment']>
}

export const CommentField = (props: CommentFieldProps) => {
  const { comment } = props

  return (
    <div className='inline-flex gap-1.5'>
      <ConnectedUserBadge userId={comment.createdByUserId} />
      <span>{highlightEmText(comment.text)}</span>
    </div>
  )
}
