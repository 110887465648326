import { type RouteData } from '~/areas/project-management/pages/pm-v3/routes/types'
import { lazyRoute, type MotionRoute } from '~/routing/api'

declare module '@motion/web-common/flags/definitions' {
  interface AmplitudeExperiments {
    'charts-preview': FeatureFlag
  }
}

export const dashboardRoute: MotionRoute = {
  path: 'dashboard',
  lazy: lazyRoute(() => import('./index'), 'DashboardPage'),
  featureFlag: 'charts-preview',
  loader: () => ({ variant: 'dashboard' }) satisfies Partial<RouteData>,
}
