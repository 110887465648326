import { logEvent } from '@motion/web-base/analytics'

import { useEffect, useState } from 'react'

import { Highlight } from '../analytics/highlight'
import { Events } from '../analyticsEvents'

export function useToggleIntercom() {
  const [intercomVisible, setIntercomVisible] = useState(false)

  useEffect(function handleIntercom() {
    try {
      window.Intercom('onHide', () => {
        window.Intercom('update', {
          hide_default_launcher: true,
        })
        setIntercomVisible(false)
      })
      window.Intercom('onShow', () => {
        window.Intercom('update', {
          hide_default_launcher: false,
        })
        setIntercomVisible(true)
      })
    } catch (e) {
      void e
    }
  }, [])

  function toggleIntercom() {
    if (intercomVisible) {
      window.Intercom('hide')
      window.Intercom('update', {
        hide_default_launcher: true,
      })
    } else {
      window.Intercom('update', {
        hide_default_launcher: false,
      })
      window.Intercom('show')
      setTimeout(() => {
        const messenger = document.querySelector('#intercom-container')
        if (!messenger) {
          window.open(
            'https://help.usemotion.com',
            '_blank',
            'noopener noreferrer'
          )
        }
      }, 2000)
    }
    logEvent(Events.CLICK_HELP)
    Highlight.track(Events.CLICK_HELP)
  }

  return toggleIntercom
}
