import { type ProjectUrlSearchParams } from './project-url-params'

export function getProjectUrlParams(
  searchParams: ProjectUrlSearchParams,
  workspaceIdParam: string | undefined,
  projectIdParam: string | undefined
): ProjectUrlSearchParams {
  const project: ProjectUrlSearchParams['project'] =
    searchParams.project ?? 'create'

  return {
    ...searchParams,
    // replace params based on the current route params
    forWorkspace:
      searchParams.forWorkspace === workspaceIdParam
        ? undefined
        : searchParams.forWorkspace,
    project:
      project === 'new' || project === 'edit'
        ? project
        : project === projectIdParam
          ? 'edit'
          : project,
  }
}
