import { FilterDateButton } from './filter-date-button'

import { useFieldFilter } from '../../context'
import { type DropdownFilterProps } from '../types'

export const ConnectedCompletedTimeFilter = (props: DropdownFilterProps) => {
  const [value, setValue] = useFieldFilter('tasks', 'completedTime')

  return (
    <FilterDateButton
      label='Completed'
      value={value}
      onChange={(value) => setValue(value)}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      target={props.target}
    />
  )
}
