import { XSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { isEmailValid } from '@motion/utils/string'

import { unwrapResult } from '@reduxjs/toolkit'
import { dispatch } from '~/state/proxy'
import { checkTeamEligibility } from '~/state/teamSlice'
import { useCallback, useState } from 'react'

import { Dialog, Input, Paragraph, TextButton } from '../../../../Common'
import { type AddMembersModalProps } from '../../../types/AddMembersModalProps'
import { InviteeList } from '../../CreateTeam/InviteeList/InviteeList'

export const AddMembersModal = ({
  addMembers,
  closeModal,
}: AddMembersModalProps) => {
  const [emails, setEmails] = useState<string[]>([])
  const [currentEmail, setCurrentEmail] = useState('')
  const [ineligibleMember, setIneligibleMember] = useState(false)

  const addEmails = async () => {
    const trimmedEmail = currentEmail.trim()
    if (
      isEmailValid(trimmedEmail) &&
      !emails.some(
        (email) => trimmedEmail.toLowerCase() === email.toLowerCase()
      )
    ) {
      return dispatch(checkTeamEligibility(trimmedEmail))
        .then(unwrapResult)
        .then((res) => {
          if (res.isEligible) {
            setIneligibleMember(false)
            const newEmails = [...emails, trimmedEmail]
            setEmails(newEmails)
            setCurrentEmail('')
            return newEmails
          }
          setIneligibleMember(true)
          return emails
        })
    }
    return emails
  }

  const handleEnter = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        void addEmails()
      }
    },
    [currentEmail, emails, setEmails, setCurrentEmail] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setCurrentEmail(event.target.value)
    },
    [setCurrentEmail]
  )

  const onDelete = useCallback(
    (email: string) => {
      setEmails(emails.filter((e) => e !== email))
    },
    [emails, setEmails]
  )

  return (
    <Dialog isOpen onClose={closeModal}>
      <div className='flex flex-col'>
        <div className='flex items-center justify-between pb-4'>
          <Paragraph className='m-0 text-base font-medium'>
            Invite member
          </Paragraph>
          <TextButton onClick={() => closeModal()} icon={XSolid} />
        </div>
        <p className='mb-1.5 text-semantic-neutral-text-subtle text-xs font-medium'>
          Email addresses
        </p>
        <Input
          containerClassName='mb-4'
          onKeyDown={handleEnter}
          onChange={onChange}
          value={currentEmail}
          placeholder='Enter email'
        />
        {ineligibleMember && (
          <p className='mb-2.5 text-semantic-error-text-default text-xs'>
            This member can’t be invited because they’re already part of an
            active team.
          </p>
        )}
        {emails.length > 0 && (
          <InviteeList
            emails={emails}
            onDelete={onDelete}
            className='m-0 mb-2.5'
          />
        )}
        <div className='w-full flex flex-row items-center justify-end'>
          <Button
            onClick={async () => {
              const membersToAdd = await addEmails()
              addMembers(membersToAdd)
            }}
            disabled={emails.length === 0}
          >
            Add members
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
