import { type MultiSelectCustomField } from '@motion/ui-logic'

import {
  CustomField,
  SelectTriggerValues,
} from '~/areas/project-management/custom-fields'
import { type FC } from 'react'

import { CellAction } from '../../components'
import { type ControlledCustomFieldProps } from '../types'

export const ControlledMultiSelectCell: FC<
  ControlledCustomFieldProps<MultiSelectCustomField>
> = ({ value, onSubmit, customField, disabled }) => {
  return (
    <CustomField.MultiSelect
      selectedItemIds={value ?? []}
      onChange={onSubmit}
      workspaceId={customField.definition.workspaceId}
      customFieldId={customField.definition.id}
      disabled={disabled}
    >
      <CellAction isFake={disabled} disabled={disabled}>
        <SelectTriggerValues
          selectedItemIds={value ?? []}
          customFieldId={customField.definition.id}
        />
      </CellAction>
    </CustomField.MultiSelect>
  )
}
