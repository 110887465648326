import { LoadingSolid } from '@motion/icons'
import { ExpandableModal } from '@motion/ui/base'

import { memo } from 'react'

import { TaskFormContent } from './components'
import { useTemplateTaskForm } from './hooks'
import { TemplateTaskForm } from './template-task-form'

export const TemplateTaskModal = () => {
  return (
    <TemplateTaskForm>
      <InnerForm />
    </TemplateTaskForm>
  )
}

const InnerForm = memo(() => {
  const { actions, form } = useTemplateTaskForm()
  const { closeModal } = actions
  const {
    formState: { isDirty },
  } = form

  const loading = form.watch('isLoading')

  const child = loading ? (
    <div className='grow gap-3 p-3 w-full h-full grid place-items-center'>
      <LoadingSolid className='animate-spin text-semantic-neutral-icon-default' />
    </div>
  ) : (
    <TaskFormContent onClose={closeModal} />
  )

  return (
    <ExpandableModal
      visible
      expanded
      onClose={closeModal}
      disableOverlayClick={isDirty}
    >
      {child}
    </ExpandableModal>
  )
})
InnerForm.displayName = 'InnerForm'
