import { type Contact } from '@motion/rpc/types'
import { LoadingSpinner, SearchableList } from '@motion/ui/base'
import { fuzzySearch } from '@motion/ui-logic'
import { isEmailValid } from '@motion/utils/string'

import { useRef } from 'react'

import { useContacts } from '../../../hooks'
import { ContactLabel } from '../../contact-label'
import { MissingContactsPermissionsWarning } from '../../missing-contacts-permissions'

export type ContactsDropdownContentProps = {
  close: () => void
  onChange: (contact: Contact) => void
  selectedEmails: string[]
}
type ContactWithHideAvatar = Contact & { hideAvatar?: boolean }

export function ContactsDropdownContent({
  close,
  onChange,
  selectedEmails,
}: ContactsDropdownContentProps) {
  const { sortedContacts, loadingState, onSearch } = useContacts({
    contactsSource: 'general',
  })
  const previousSearch = useRef('')

  const handleFilter = (search: string): ContactWithHideAvatar[] => {
    if (search !== previousSearch.current) {
      previousSearch.current = search
      onSearch(search)
    }

    const filtered = fuzzySearch({
      query: search,
      items: sortedContacts,
      keys: ['email', 'displayName'],
      // Boost the score for team contacts to make them show up higher
      scoreFn: (r) => r.score * (r.obj.teamDomain ? 2 : 1),
    })

    if (filtered.some((item) => item.email === search)) return filtered
    return [
      ...filtered,
      { email: search, displayName: '', teamDomain: false, hideAvatar: true },
    ]
  }

  return (
    <div className='relative'>
      <div className='absolute top-0 right-3'>
        {loadingState === 'loading' && (
          <LoadingSpinner className='w-4 text-semantic-neutral-text-muted' />
        )}
      </div>
      <SearchableList
        items={sortedContacts as ContactWithHideAvatar[]}
        renderItem={(item) => (
          <ContactLabel item={item} hideAvatar={Boolean(item.hideAvatar)} />
        )}
        computeKey={(item) => item.email + item.displayName}
        filter={handleFilter}
        computeSelected={(item) => selectedEmails.includes(item.email)}
        onSelect={(item, clearSearch) => {
          if (!isEmailValid(item.email)) return
          onChange(item)
          clearSearch()
        }}
        inputProps={{
          placeholder: 'Choose or type a contact...',
        }}
      />
      <MissingContactsPermissionsWarning />
    </div>
  )
}
