import { type SvgIcon } from '@motion/icons'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export type IconLabelProps = {
  Icon: SvgIcon
  text: ReactNode
  thin?: boolean
  subtle?: boolean
  size?: 'normal' | 'small'
}

export const IconLabel = (props: IconLabelProps) => {
  const { subtle = true, size = 'small' } = props
  return (
    <div
      className={twMerge(
        'flex text-xs items-center justify-between',
        size === 'normal' ? 'gap-2' : 'gap-1'
      )}
    >
      <props.Icon
        width={size === 'small' ? 12 : 16}
        height={size === 'small' ? 12 : 16}
        className={twMerge(
          subtle
            ? 'text-semantic-neutral-icon-subtle'
            : 'text-semantic-neutral-icon-default'
        )}
      />
      <span className={props.thin ? 'font-normal' : 'font-semibold'}>
        {props.text}
      </span>
    </div>
  )
}
