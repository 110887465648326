import { useDependantState } from '@motion/react-core/hooks'
import {
  Button,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { RadioButton, TextField } from '@motion/ui/forms'
import {
  type CalendarStartDay,
  type CustomRecurrenceForm,
  type DayChoice,
  getVisualDaysOptions,
  parseInitialCustomRecurrenceValue,
  recurrenceFormToRecurrenceString,
} from '@motion/ui-logic/calendar'
import { parseDate } from '@motion/utils/dates'
import { safeParseNumber } from '@motion/utils/number'

import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { useI18N } from '~/global/contexts'

export type CustomRecurrencePanelProps = {
  refDate: string
  onChange: (ruleString: string) => void
  initialValue: string
  calendarStartDay: CalendarStartDay
}

export const CustomRecurrencePanel = ({
  refDate,
  onChange,
  initialValue,
  calendarStartDay,
}: CustomRecurrencePanelProps) => {
  const form = parseInitialCustomRecurrenceValue(refDate, initialValue)

  const handleUpdate = <K extends keyof CustomRecurrenceForm>(
    key: K,
    value: CustomRecurrenceForm[K]
  ) => {
    const newForm = { ...form, [key]: value }
    if (key === 'freq' && value === 'WEEKLY') {
      newForm.endsAfter = 13
      newForm.endsOn = parseDate(refDate).plus({ weeks: 13 }).toISODate()
    } else if (key === 'freq' && value === 'MONTHLY') {
      newForm.endsAfter = 12
      newForm.endsOn = parseDate(refDate).plus({ months: 12 }).toISODate()
    } else if (key === 'freq' && value === 'DAILY') {
      newForm.endsAfter = 30
      newForm.endsOn = parseDate(refDate).plus({ days: 30 }).toISODate()
    }

    onChange(recurrenceFormToRecurrenceString(refDate, newForm))
  }

  const renderWeekOptions = () => {
    return (
      <div className='flex justify-between'>
        {getVisualDaysOptions(calendarStartDay).map((option) => {
          const selected = form.dayChoices.includes(option.value)

          return (
            <Button
              key={option.value}
              size='small'
              variant={selected ? 'solid' : 'outlined'}
              sentiment={selected ? 'primary' : 'neutral'}
              // @ts-expect-error We want these buttons to be rounded
              className='h-7 w-7 rounded-full p-1'
              onClick={() => {
                let prev = [...form.dayChoices]
                if (prev.includes(option.value)) {
                  prev = prev.filter((x) => x !== option.value)
                } else {
                  prev.push(option.value as DayChoice)
                }
                handleUpdate('dayChoices', prev)
              }}
            >
              {option.label}
            </Button>
          )
        })}
      </div>
    )
  }

  const [inputValue, setInputValue] = useDependantState<string>(
    () => form.interval.toString(),
    [form.interval]
  )

  const onChangeInterval = (value: string) => {
    setInputValue(value)
    const num = safeParseNumber(value)
    num !== null && handleUpdate('interval', num)
  }

  return (
    <div className='flex flex-col gap-4 p-3 border-l border-dropdown-border'>
      <div className='flex flex-row items-center gap-1'>
        <p>Repeat every</p>
        <div className='w-[75px]'>
          <TextField
            label='repeat every'
            labelHidden
            type='number'
            min={1}
            max={500}
            value={inputValue}
            onChange={onChangeInterval}
          />
        </div>
        <div className='w-[100px]'>
          <FrequencyDropdown
            value={form.freq}
            interval={form.interval}
            onChange={(freq) => handleUpdate('freq', freq)}
          />
        </div>
      </div>
      {form.freq === 'WEEKLY' ? renderWeekOptions() : null}
      {form.freq === 'MONTHLY' && (
        <FrequencyMonthOptionsDropdown
          value={form.monthChoice}
          weekNameStr={form.weekNameStr}
          refDate={refDate}
          onChange={(choice) => handleUpdate('monthChoice', choice)}
        />
      )}
      <div className='flex flex-col gap-1'>
        <p>Ends</p>
        <RadioButton
          label='Never'
          checked={form.endsChoice === 'never'}
          onChange={() => handleUpdate('endsChoice', 'never')}
        />
        <RadioButton
          label={
            <div className='flex items-center gap-2'>
              <p>On</p>
              <SimpleDateDropdown
                calendarStartDay={calendarStartDay}
                value={parseDate(form.endsOn).toISO()}
                onChange={(date) => {
                  if (!date) return
                  handleUpdate('endsOn', parseDate(date).toISODate())
                }}
              >
                <PopoverButton disabled={form.endsChoice !== 'on'}>
                  {form.endsOn}
                </PopoverButton>
              </SimpleDateDropdown>
            </div>
          }
          checked={form.endsChoice === 'on'}
          onChange={() => handleUpdate('endsChoice', 'on')}
        />
        <RadioButton
          label={
            <div className='flex items-center gap-2'>
              <p>After</p>
              <div className='w-[50px]'>
                <TextField
                  label='After'
                  labelHidden
                  type='number'
                  disabled={form.endsChoice !== 'after'}
                  min={1}
                  max={500}
                  value={form.endsAfter}
                  onChange={(value) =>
                    handleUpdate('endsAfter', parseInt(value))
                  }
                />
              </div>
              <p>times</p>
            </div>
          }
          checked={form.endsChoice === 'after'}
          onChange={() => handleUpdate('endsChoice', 'after')}
        />
      </div>
    </div>
  )
}

type FrequencyDropdownProps = {
  onChange: (frequency: CustomRecurrenceForm['freq']) => void
  value: CustomRecurrenceForm['freq']
  interval: number
}

function FrequencyDropdown({
  value,
  interval,
  onChange,
}: FrequencyDropdownProps) {
  const { pluralize } = useI18N()

  const options = [
    { label: pluralize(interval, 'Day', 'Days'), value: 'DAILY' as const },
    { label: pluralize(interval, 'Week', 'Weeks'), value: 'WEEKLY' as const },
    {
      label: pluralize(interval, 'Month', 'Months'),
      value: 'MONTHLY' as const,
    },
  ]

  const displayLabel =
    options.find((opt) => opt.value === value)?.label ?? 'Unknown'

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={options}
          renderItem={(item) => item.label}
          computeKey={(item) => item.value}
          computeSelected={(item) => item.value === value}
          onSelect={(item) => {
            onChange(item.value)
            close()
          }}
        />
      )}
    >
      <PopoverButton>{displayLabel}</PopoverButton>
    </PopoverTrigger>
  )
}

type FrequencyMonthOptionsDropdownProps = {
  onChange: (monthChoice: CustomRecurrenceForm['monthChoice']) => void
  value: CustomRecurrenceForm['monthChoice']
  refDate: string
  weekNameStr: string
}

function FrequencyMonthOptionsDropdown({
  value,
  onChange,
  refDate,
  weekNameStr,
}: FrequencyMonthOptionsDropdownProps) {
  const options = [
    {
      label: `Monthly on day ${parseDate(refDate).toFormat('d')}`,
      value: 'day' as const,
    },
    {
      label: `Monthly on ${weekNameStr} ${parseDate(refDate).toFormat('EEEE')}`,
      value: 'nth' as const,
    },
  ]

  const displayLabel =
    options.find((opt) => opt.value === value)?.label ?? 'Unknown'

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          items={options}
          renderItem={(item) => item.label}
          computeKey={(item) => item.value}
          computeSelected={(item) => item.value === value}
          onSelect={(item) => {
            onChange(item.value)
            close()
          }}
        />
      )}
    >
      <PopoverButton>{displayLabel}</PopoverButton>
    </PopoverTrigger>
  )
}
