import {
  EyeSolid,
  MenuAlt2Outline,
  PlannerSolid,
  TemplateSolid,
  ViewBoardsOutline,
} from '@motion/icons'
import { type VersionedViewV2 } from '@motion/zod/client'

export function ViewIcon({ view }: { view: VersionedViewV2 }) {
  let Icon

  if (view.type === 'team-schedule') {
    Icon = EyeSolid
  } else if (view.definition.type === 'dashboard') {
    Icon = TemplateSolid
  } else {
    switch (view.definition.layout) {
      case 'kanban':
        Icon = ViewBoardsOutline
        break
      case 'gantt':
      case 'planner':
        Icon = PlannerSolid
        break
      default:
        Icon = MenuAlt2Outline
    }
  }

  return <Icon className='shrink-0 size-4 text-semantic-neutral-icon-default' />
}
