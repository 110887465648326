import { createStateKey } from '@motion/react-core/shared-state'

import { type GroupedNode, type Tree } from './utils'

import { type TreeListRowValueType } from '../tree-list'

type ItemTree = Tree<GroupedNode<TreeListRowValueType>>

export const CurrentTreeKey = createStateKey<ItemTree>('current-tree', {
  equals: (l, r) => l === r,
})
