import { useSharedState } from '@motion/react-core/shared-state'
import { useActiveFilter } from '@motion/ui-logic/pm/data'

import { CurrentTreeKey } from '~/areas/project-management/pages/pm-v3/grouping'

const nf = new Intl.NumberFormat('en-US')

export const ItemCounter = () => {
  const [tree] = useSharedState(CurrentTreeKey)
  const [filter] = useActiveFilter()

  const count = tree == null ? 0 : tree.allItems.length

  if (tree == null) return null
  const text = filter.target === 'tasks' ? 'Tasks' : 'Projects'

  return (
    <div className='flex items-center text-xs text-semantic-neutral-text-subtle uppercase shrink-0'>
      {text}: {nf.format(count)}
    </div>
  )
}
