// eslint-disable-next-line no-restricted-imports
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { PrimaryButton, TextButton } from '../../../../components/Common'

interface SkipTeamDoubleConfirmationModalProps {
  isOpen: boolean
  onSkip: () => void
  onClose: () => void
}

export function SkipTeamDoubleConfirmationModal({
  isOpen,
  onSkip,
  onClose,
}: SkipTeamDoubleConfirmationModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='max-w-[450px] transform overflow-hidden rounded bg-white px-6 py-5 text-left align-middle shadow-xl transition-all'>
                <Dialog.Title
                  as='h3'
                  className='text-light-1200 text-lg font-medium leading-6'
                >
                  Are you sure you don&apos;t want to create a team?
                </Dialog.Title>

                <p className='text-light-1100 my-4 text-sm'>
                  If you don&apos;t want to create a team at this time you can
                  start a trial for our Individual Plan.
                </p>

                <PrimaryButton
                  className='my-1 mt-6 flex w-full items-center justify-center py-2'
                  onClick={onClose}
                >
                  Nevermind, I want to create a team
                </PrimaryButton>
                <TextButton
                  className='flex w-full items-center justify-center py-2'
                  onClick={onSkip}
                >
                  Yes - start trial for individual plan
                </TextButton>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
