import { ClockSolid } from '@motion/icons'
import {
  ActionDropdown,
  type ActionDropdownProps,
  IconButton,
  showToast,
  Tooltip,
} from '@motion/ui/base'
import { roundTime } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { Paragraph } from '~/components/Common'
import {
  useCreateBookingTimeslot,
  useDeleteBookingTimeslots,
} from '~/global/rpc'
import { DateTime } from 'luxon'

type BlockingTimeslotDropdownProps = {
  date: DateTime
  timeslotIds: string[]
  disabled?: boolean
}

export const BlockingTimeslotDropdown = ({
  date,
  timeslotIds = [],
  disabled = false,
}: BlockingTimeslotDropdownProps) => {
  const modalApi = useModalApi()

  const isToday = date.toISODate() === DateTime.local().toISODate()

  const { mutateAsync: createBookingTimeslot } = useCreateBookingTimeslot()
  const { mutateAsync: deleteBookingTimeslots } = useDeleteBookingTimeslots()

  const blockWholeDay = async () => {
    recordAnalyticsEvent('FLEXIBLE_HOURS_BLOCK_OUT_WHOLE_DAY')
    try {
      await createBookingTimeslot({
        startTime: date.startOf('day').toISO(),
        endTime: date.endOf('day').toISO(),
      })

      showToast(
        'success',
        'Your hours have changed. Tasks will be rescheduled!'
      )
    } catch (e) {
      showToast('error', 'Error blocking out time')
    }
  }

  const resetHours = async () => {
    recordAnalyticsEvent('FLEXIBLE_HOURS_RESET_HOURS')
    try {
      if (timeslotIds.length) {
        await deleteBookingTimeslots({
          ids: timeslotIds,
        })
      }

      showToast(
        'success',
        'Your hours have changed. Tasks will be rescheduled!'
      )
    } catch (e) {
      showToast('error', 'Error resetting blocking hours')
    }
  }

  const items: ActionDropdownProps['items'] = [
    {
      content: <Paragraph>Start tasks later</Paragraph>,
      description: "Move tasks to when you're ready.",
      onAction: () => {
        void modalApi.prompt('start-tasks-later', {
          initialTime: isToday
            ? roundTime(DateTime.now(), 15)
            : date.set({ hour: 9 }),
        })
      },
    },
    {
      content: <Paragraph>Stop tasks early</Paragraph>,
      description: "Reschedule today's tasks for later.",
      onAction: () => {
        void modalApi.prompt('stop-tasks-early', {
          initialTime: isToday
            ? roundTime(DateTime.now(), 15)
            : date.set({ hour: 12 }),
        })
      },
    },
    {
      content: <Paragraph>Block out hours</Paragraph>,
      description: 'Block specific hours.',
      onAction: () => {
        void modalApi.prompt('block-out-hours', {
          initialTime: isToday
            ? roundTime(DateTime.now(), 15)
            : date.set({ hour: 9 }),
        })
      },
    },
    {
      content: <Paragraph>Block out whole day</Paragraph>,
      description: 'Blocks whole day.',
      onAction: () => blockWholeDay(),
    },
    timeslotIds.length > 0 && {
      content: <Paragraph>Reset hours</Paragraph>,
      description: 'All hours will be unblocked.',
      onAction: () => resetHours(),
    },
  ]

  const button = (
    <Tooltip content={!disabled && 'Change task hours'} asChild>
      <IconButton
        sentiment='neutral'
        size='small'
        variant='muted'
        icon={ClockSolid}
      />
    </Tooltip>
  )

  if (disabled) {
    return button
  }

  return <ActionDropdown items={items}>{button}</ActionDropdown>
}
