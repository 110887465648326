import { Toggle } from '@motion/ui/base'
import { NumberField } from '@motion/ui/forms'

import { checkNumberRuleErrors } from '~/global/rules'
import { useRegisterFieldArray } from '~/hooks'

import { ExpectedDurationUnitSelector } from './expected-duration-unit-selector'

import { useFlowTemplateForm } from '../../hooks'
import { getNumberFieldRules } from '../../utils'

type StageDetailsProps = {
  deadlineIntervalPath: `stages.${number}.deadlineInterval`
  automaticallyMoveToNextStagePath: `stages.${number}.automaticallyMoveToNextStage`
}

export const StageDetails = ({
  deadlineIntervalPath,
  automaticallyMoveToNextStagePath,
}: StageDetailsProps) => {
  const {
    form: { register, watch, getValues, formState, getFieldState },
  } = useFlowTemplateForm()
  const deadlineIntervalValuePath = `${deadlineIntervalPath}.value` as const
  const { error } = getFieldState(deadlineIntervalValuePath, formState)

  const registerProps = useRegisterFieldArray(
    deadlineIntervalValuePath,
    register,
    {
      validate: () => {
        const value = getValues(deadlineIntervalValuePath)

        const errorMessage = checkNumberRuleErrors(
          value,
          getNumberFieldRules('Stage deadline')
        )

        if (errorMessage) {
          return errorMessage
        }
      },
    }
  )
  const { onChange: onAutomaticallyMoveToNextStageChange } =
    useRegisterFieldArray(automaticallyMoveToNextStagePath, register)

  const deadlineIntervalValue = watch(deadlineIntervalValuePath)
  const automaticallyMoveToNextStage = watch(automaticallyMoveToNextStagePath)

  return (
    <div className='flex flex-col gap-2'>
      <span className='font-medium text-2xs text-semantic-neutral-text-default'>
        Tasks will be created when this stage begins
      </span>

      <div>
        <label className='text-xs text-semantic-neutral-text-subtle flex flex-row items-center gap-1.5 whitespace-nowrap'>
          Expected duration:
          <NumberField
            value={deadlineIntervalValue}
            label='Deadline interval'
            labelHidden
            size='small'
            // @ts-expect-error - fine
            className='w-4'
            sentiment={error ? 'error' : 'default'}
            {...registerProps}
          />
          <ExpectedDurationUnitSelector
            deadlineIntervalPath={deadlineIntervalPath}
          />
        </label>
      </div>

      <Toggle
        size='small'
        checked={automaticallyMoveToNextStage}
        onChange={(e) => {
          const value = e.target.checked
          onAutomaticallyMoveToNextStageChange(value)
        }}
      >
        Auto-advance after all tasks done
      </Toggle>
    </div>
  )
}
