import { AppleLogo, GoogleLogo, MicrosoftLogo } from '@motion/icons'
import { type ProviderType } from '@motion/rpc-types'
import { ActionDropdown, type PopoverTriggerProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

type AddAccountDropdownProps = {
  onAddAccount: (provider: ProviderType) => void
  children?: React.ReactNode
  placement?: PopoverTriggerProps['placement']
}

export const AddAccountDropdown = (props: AddAccountDropdownProps) => {
  const { placement = 'bottom-start' } = props
  const modalApi = useModalApi()

  return (
    <ActionDropdown
      placement={placement}
      items={[
        {
          prefix: <GoogleLogo />,
          content: 'Add Google Calendar',
          onAction: () => {
            props.onAddAccount('google')
          },
        },
        {
          prefix: <MicrosoftLogo />,
          content: 'Add Outlook Calendar',
          onAction: () => {
            props.onAddAccount('microsoft')
          },
        },
        {
          prefix: <AppleLogo />,
          content: 'Add iCloud Calendar',
          onAction: () => {
            recordAnalyticsEvent('ICLOUD_CALENDAR_ADD_ACCOUNT_CLICK')
            modalApi.open('add-icloud-calendar')
          },
        },
      ]}
    >
      {props.children}
    </ActionDropdown>
  )
}
