import { PencilSolid } from '@motion/icons'
import { Button, Tooltip } from '@motion/ui/base'
import { ConditionalWrapper } from '@motion/ui/utils'

import { ReminderEmailEditorModal } from '~/components/Booking/components/Modals/ReminderEmailEditorModal/ReminderEmailEditorModal'
import { reminderEmailMenu } from '~/components/Booking/components/template-form/template-form.types'
import { Combobox } from '~/components/Common'
import { useContext, useState } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const BookingReminderRow = () => {
  const [emailEditorVisible, setEmailEditorVisible] = useState(false)

  const {
    template: {
      state: {
        hasReminderEmail,
        reminderEmailPreBookingMins,
        reminderEmailForbiddenMessage,
        reminderEmailSubject,
        reminderEmailBody,
      },
      setters: { setReminderEmailSubject, setReminderEmailBody },
      onReminderEmailTimeChange,
    },
  } = useContext(BookingTemplateContext)

  return (
    <>
      <SectionRow
        title='Send reminder emails before meeting'
        subtitle='Improves no-show rates'
      >
        <div className='flex w-full items-center gap-2'>
          <ConditionalWrapper
            condition={!!reminderEmailForbiddenMessage}
            wrapper={(children) => (
              <Tooltip asChild content={reminderEmailForbiddenMessage}>
                <span className='flex max-w-full w-full'>{children}</span>
              </Tooltip>
            )}
          >
            <Combobox
              autoComplete
              className='max-w-full'
              value={hasReminderEmail ? reminderEmailPreBookingMins : 0}
              onChange={onReminderEmailTimeChange}
              disabled={!!reminderEmailForbiddenMessage}
              options={reminderEmailMenu}
            />
          </ConditionalWrapper>

          {hasReminderEmail && (
            <Button
              variant='muted'
              sentiment='neutral'
              size='small'
              onClick={() => setEmailEditorVisible(true)}
            >
              <span className='whitespace-nowrap inline-flex items-center gap-x-2'>
                <PencilSolid /> Edit message
              </span>
            </Button>
          )}
        </div>
      </SectionRow>

      {emailEditorVisible && (
        <ReminderEmailEditorModal
          visible={emailEditorVisible}
          reminderEmailSubject={reminderEmailSubject}
          reminderEmailBody={reminderEmailBody}
          reminderEmailSubjectHandler={setReminderEmailSubject}
          reminderEmailBodyHandler={setReminderEmailBody}
          reminderEmailTime={reminderEmailPreBookingMins}
          visibleHandler={setEmailEditorVisible}
        />
      )}
    </>
  )
}
