import { CheckSolid } from '@motion/icons'

// eslint-disable-next-line no-restricted-imports
import { RadioGroup as Base } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

export interface RadioGroupOption {
  children?: React.ReactNode
  label?: React.ReactNode
  description?: string
  value: string
}

export interface RadioGroupProps {
  options: RadioGroupOption[]
  selectedValue?: string
  className?: string
  optionClassName?: string
  onChange: (selected: string) => void
  borderless?: boolean
}

/**
 * @deprecated Use `RadioButton` from Motion UI
 */
export const RadioGroup = ({
  options,
  selectedValue,
  className = '',
  optionClassName,
  onChange,
  borderless = false,
}: RadioGroupProps) => {
  return (
    <Base value={selectedValue} onChange={onChange} className={className}>
      {options.map((option) => {
        return (
          <Item
            key={option.value}
            option={option}
            className={optionClassName}
            borderless={borderless}
          />
        )
      })}
    </Base>
  )
}

interface ItemProps {
  option: RadioGroupOption
  className?: string
  borderless?: boolean
}

const Item = ({ option, className = '', borderless = false }: ItemProps) => {
  return (
    <Base.Option
      value={option.value}
      className={twMerge(
        'flex w-full cursor-pointer space-y-2',
        borderless
          ? 'px-3 py-1'
          : 'border-light-500 dark:border-dark-1000 border p-3',
        className
      )}
    >
      {({ checked }) => (
        <div className='flex w-full items-center space-x-2'>
          <div className='h-full flex items-center'>
            {checked && (
              <div className='bg-primary-400 rounded-full'>
                <CheckSolid className='h-5 w-5 p-1 text-white' />
              </div>
            )}
            {!checked && (
              <div className='dark:bg-dark-1100 dark:border-dark-800 hover:dark:bg-dark-1000 border-light-500 hover:bg-light-300 rounded-full border bg-white'>
                <CheckSolid className='invisible h-[18px] w-[18px] p-1' />
              </div>
            )}
          </div>
          <div>
            {option.label && (
              <Base.Label className='dark:text-dark-100 text-light-1200 cursor-pointer text-sm'>
                {option.label}
              </Base.Label>
            )}
            {option.children && (
              <Base.Label className='dark:text-dark-100 text-light-1200 cursor-pointer text-sm'>
                {option.children}
              </Base.Label>
            )}
            {option.description && (
              <Base.Description className='dark:text-dark-400 text-light-1100 m-0 text-xs'>
                {option.description}
              </Base.Description>
            )}
          </div>
        </div>
      )}
    </Base.Option>
  )
}
