import { type SettingPageOptions } from '~/pages/settings/types'
import { useNavigate } from 'react-router'

import { TaskManagerSettings } from './TaskManagerSettings'

export const ConnectedTaskManagerSettings = () => {
  const navigate = useNavigate()

  const navigateToSettingsPage = (page: SettingPageOptions) =>
    navigate(`/web/settings/${page}`)

  return <TaskManagerSettings setSettingsPage={navigateToSettingsPage} />
}
