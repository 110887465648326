import { ExternalLinkSolid, PlusSolid } from '@motion/icons'
import { useDelayedUnmount } from '@motion/react-core/hooks'
import {
  Button,
  IconButton,
  Tooltip,
  UnstyledCollapsableContainer,
} from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useSortedGroupedTasks } from '~/areas/task-project/hooks'
import { type SortBy } from '~/areas/task-project/utils'
import { useProject } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'
import { useUriByRouteId } from '~/routing'
import { useState } from 'react'

import { useSidebarTaskContext } from '../../context'
import { InlineTaskCreationForm } from '../../inline-create'
import { SidebarTasks } from '../../sidebar-tasks'

type TaskTabProps = {
  tasks: NormalTaskWithRelations[]
  projectDefinitionId: ProjectDefinitionSchema['id'] | null
}

export function TaskTab({ tasks, projectDefinitionId }: TaskTabProps) {
  const { enableInlineAdd, projectId, workspaceId, isProjectModal } =
    useSidebarTaskContext()

  const sort = projectDefinitionId ? 'STAGES' : 'STATUS'

  const groupedTasks = useSortedGroupedTasks({
    tasks,
    sort,
    projectDefinitionId,
  })

  return (
    <div className='flex flex-col h-full'>
      <TaskTabHeader
        workspaceId={workspaceId}
        projectId={projectId}
        sort={sort}
        enableInlineAdd={enableInlineAdd}
        isEmpty={tasks.length === 0}
        isProjectModal={isProjectModal}
      />
      <SidebarTasks
        groupedTasks={groupedTasks}
        sort={sort}
        projectId={projectId}
      />
    </div>
  )
}

export type TaskTabHeaderProps = {
  workspaceId: string
  projectId: string
  sort: keyof typeof SortBy
  enableInlineAdd: boolean
  isEmpty: boolean
  isProjectModal?: boolean
}

function TaskTabHeader({
  workspaceId,
  projectId,
  sort,
  enableInlineAdd,
  isEmpty,
  isProjectModal,
}: TaskTabHeaderProps) {
  const getRouteUri = useUriByRouteId()

  const [expanded, setExpanded] = useState(false)
  const toggle = () => setExpanded((prev) => !prev)
  const project = useProject(projectId)

  const mounted = useDelayedUnmount(expanded, 200)
  const routingV4 = useHasTreatment('pm-view-based-routing')

  // sort can only 'STAGES' if the project is created via a project template, in which case it will ghost tasks.
  const displayAddTaskButton =
    enableInlineAdd &&
    isEmpty &&
    sort !== 'STAGES' &&
    sort !== 'STATUS' &&
    !expanded

  if (!project) {
    throw new Error('Project not found')
  }

  return (
    <>
      <UnstyledCollapsableContainer
        expanded={expanded}
        toggle={toggle}
        renderHeader={() => (
          <div className='min-w-0 p-3 flex flex-row items-center justify-between'>
            <div className='flex gap-2'>
              <h3 className='font-bold text-[15px] text-semantic-neutral-text-default'>
                Tasks
              </h3>

              {isProjectModal && (
                <Tooltip content='Open task list view' asChild>
                  <IconButton
                    icon={ExternalLinkSolid}
                    variant='muted'
                    sentiment='neutral'
                    size='small'
                    url={
                      routingV4
                        ? getRouteUri('project-tasks', {
                            workspaceId,
                            projectId,
                          })
                        : getRouteUri('workspace-project', {
                            workspaceId,
                            projectId,
                            variant: 'list',
                          })
                    }
                    aria-label='Open task list view'
                  />
                </Tooltip>
              )}
            </div>

            <div className='flex gap-2'>
              {enableInlineAdd && (
                <Tooltip content='Add new task' asChild>
                  <Button
                    variant='muted'
                    sentiment='neutral'
                    size='small'
                    onClick={toggle}
                    iconOnly
                  >
                    <PlusSolid />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      >
        {mounted && enableInlineAdd && (
          <InlineTaskCreationForm
            close={() => setExpanded(false)}
            workspaceId={workspaceId}
            projectId={projectId}
          />
        )}
      </UnstyledCollapsableContainer>
      {displayAddTaskButton && (
        <div className='pl-4'>
          <Button
            variant='outlined'
            sentiment='neutral'
            size='small'
            onClick={() => setExpanded(true)}
          >
            <PlusSolid />
            <span>Add a task</span>
          </Button>
        </div>
      )}
    </>
  )
}
