import {
  createStateKey,
  type SharedStateSetterValue,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'

import { DateTime } from 'luxon'
import { useCallback } from 'react'

type AgendaState = {
  selectedDate: DateTime
  selectedDateManuallyChanged: boolean
  settings: {
    // Whether to show completed tasks
    includeEvents: boolean
  }
  // most recent task attempted to be moved
  attemptedMoveTaskEventId: string | null
}
export const AgendaStateKey = createStateKey<AgendaState>('agendaState', {
  defaultValue: {
    selectedDate: DateTime.now().startOf('day'),
    selectedDateManuallyChanged: false,
    settings: {
      includeEvents: false,
    },
    attemptedMoveTaskEventId: null,
  },
})

export const useSendAgendaState = () => {
  const setState = useSharedStateSendOnly(AgendaStateKey)
  return useCallback(
    (changes: Partial<AgendaState> | SharedStateSetterValue<AgendaState>) => {
      if (typeof changes === 'function') {
        setState(changes)
        return
      }
      setState((prev) => ({ ...prev, ...changes }))
    },
    [setState]
  )
}

export const useAgendaState = () => useSharedState(AgendaStateKey)
