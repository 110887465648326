import { StageSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import { useProjectDefinitionForTask } from '~/areas/flows'

import {
  BulkStageDropdown,
  ConnectedBulkStageLabel,
  FieldButton,
} from '../components'
import { isBulkOpsTaskGroups, useBulkOpsField } from '../contexts'
import {
  useBulkFieldValidation,
  useBulkFilteredStageDefinitions,
} from '../hooks'

export function StageField() {
  const [stageDefinitionIds, setStageDefinitionIds] =
    useBulkOpsField('stageDefinitionIds')

  const getTaskProjectDefinition = useProjectDefinitionForTask()
  const filteredStageDefinitions = useBulkFilteredStageDefinitions()

  const active = stageDefinitionIds.length > 0

  const { isValid, errMsg } = useBulkFieldValidation([
    ({ target, groups }) => ({
      isValid: isBulkOpsTaskGroups(groups, target)
        ? groups.every((group) =>
            group.items.every((item) => getTaskProjectDefinition(item) != null)
          )
        : true,
      errMsg:
        "Stages are only available for tasks that are in a project's workflow",
    }),
    () => ({
      isValid: filteredStageDefinitions.length > 0,
      errMsg: 'No stages are shared between the tasks you selected',
    }),
  ])

  return (
    <Tooltip content={errMsg}>
      <BulkStageDropdown
        selectedStageDefinitionIds={stageDefinitionIds}
        onChange={setStageDefinitionIds}
        filteredStageDefinitions={filteredStageDefinitions}
      >
        <FieldButton
          analyticsName='stage'
          disabled={!isValid}
          active={active}
          onRemove={() => setStageDefinitionIds([])}
        >
          {active ? (
            <ConnectedBulkStageLabel ids={stageDefinitionIds} />
          ) : (
            <>
              <StageSolid /> Stage
            </>
          )}
        </FieldButton>
      </BulkStageDropdown>
    </Tooltip>
  )
}
