import { FormModal } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useState } from 'react'

import { RadioGroup } from '../Common/RadioGroup/RadioGroup'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'complete-chunked-task': PromptCallbacks<CompleteChunkedTaskChoice>
  }
}

type CompleteChunkedTaskChoice =
  // Complete only this chunk
  | 'this'
  // Complete all task chunks
  | 'all'

export const CompleteChunkedTaskModal = ({
  close,
}: ModalTriggerComponentProps<'complete-chunked-task'>) => {
  const [selected, setSelected] = useState<CompleteChunkedTaskChoice>('this')

  return (
    <FormModal
      title='Mark Task Complete'
      visible
      onClose={() => close()}
      submitAction={{
        onAction: () => close(selected),
        text: 'Complete',
      }}
    >
      <div className='text-semantic-neutral-text-subtle'>
        <RadioGroup
          className='py-3'
          options={[
            { label: 'This chunk', value: 'this' },
            { label: 'All chunks for this task', value: 'all' },
          ]}
          selectedValue={selected}
          onChange={(value: string) =>
            setSelected(value as CompleteChunkedTaskChoice)
          }
          borderless
        />
      </div>
    </FormModal>
  )
}
