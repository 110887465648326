import {
  CogSolid,
  DotsHorizontalSolid,
  DuplicateSolid,
  LinkSolid,
} from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import { ActionList, IconButton, PopoverTrigger } from '@motion/ui/base'

import {
  CurrentTreeKey,
  type GroupedNode,
} from '~/areas/project-management/pages/pm-v3/grouping'
import {
  useCopyProjectLinkToClipboard,
  useCopyWorkspaceLinkToClipboard,
  useWorkspaceFns,
} from '~/global/hooks'
import { useNavigateByRouteId } from '~/routing'
import { useParams } from 'react-router'

import { useOptionsVisibility } from './use-options-visibility'

import { useCopyTaskToClipboard, useDataForCopyTasks } from '../../copy-tasks'
import { type TreeListRowValueType } from '../../tree-list'

export const ConnectedHeaderDotsButton = () => {
  const [tree] = useSharedState(CurrentTreeKey)

  const options = useOptionsVisibility(tree?.item)

  if (tree == null || tree.item == null || !options.hasAnyOptionVisible)
    return null

  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) =>
        tree.item != null && (
          <DotsMenuContent
            close={close}
            treeItem={tree.item}
            optionsVisibility={options}
          />
        )
      }
    >
      <IconButton
        icon={DotsHorizontalSolid}
        size='small'
        variant='muted'
        sentiment='neutral'
      />
    </PopoverTrigger>
  )
}

function DotsMenuContent({
  close,
  treeItem,
  optionsVisibility,
}: {
  close: () => void
  treeItem: GroupedNode<TreeListRowValueType>
  optionsVisibility: ReturnType<typeof useOptionsVisibility>
}) {
  const navigate = useNavigateByRouteId()
  const { getWorkspaceById, getWorkspaceProjectById } = useWorkspaceFns()
  const copyWorkspaceLink = useCopyWorkspaceLinkToClipboard()
  const copyProjectLink = useCopyProjectLinkToClipboard()
  const { list, columns } = useDataForCopyTasks(treeItem)
  const copyTasks = useCopyTaskToClipboard()
  const { workspaceId, projectId } = useParams<{
    workspaceId: string
    projectId: string
  }>()

  const { showWorkspaceOptions, showProjectOptions, showCopyTasks } =
    optionsVisibility

  return (
    <ActionList
      onActionAnyItem={close}
      items={[
        showCopyTasks && {
          prefix: <DuplicateSolid />,
          content: 'Copy tasks as table',
          onAction: () => copyTasks(list, columns),
        },
        showProjectOptions &&
          workspaceId != null &&
          projectId != null && {
            prefix: <LinkSolid />,
            content: 'Copy project link',
            onAction: () => {
              const project = getWorkspaceProjectById(projectId)
              if (project == null) return

              copyProjectLink({
                workspaceId,
                projectId,
                projectName: project.name,
              })
            },
          },
        showWorkspaceOptions &&
          workspaceId != null && {
            prefix: <LinkSolid />,
            content: 'Copy workspace link',
            onAction: () => {
              const workspace = getWorkspaceById(workspaceId)
              if (workspace == null) return

              copyWorkspaceLink({ workspaceId, workspaceName: workspace.name })
            },
          },
        showWorkspaceOptions &&
          workspaceId != null && {
            prefix: <CogSolid />,
            content: 'Workspace Settings',
            onAction: () => {
              navigate('workspace-settings', {
                workspaceId,
              })
            },
          },
      ]}
    />
  )
}
