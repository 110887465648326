import { ArrowRightSolid, CalendarSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'
import { templateStr } from '@motion/ui-logic'
import { daysBetweenDates } from '@motion/utils/dates'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useProjectStagesWithDefinitions } from '~/areas/project/hooks/data'
import { StageLabel } from '~/global/components/labels'
import { DateTime } from 'luxon'

import { resolveStageProjectedDate } from '../../utils'

type ProjectStageUpdateDetailsProps = {
  project: Pick<
    ProjectSchema,
    'stages' | 'deadlineStatus' | 'dueDate' | 'estimatedCompletionTime'
  >
  projectedEndDateOverride?: DateTime | null
}

export function ProjectStageUpdateDetails({
  project,
  projectedEndDateOverride,
}: ProjectStageUpdateDetailsProps) {
  const stagesWithDefinitions = useProjectStagesWithDefinitions(project, {
    includeCancelledStages: false,
  })

  const stagesWithProjectedDates = stagesWithDefinitions.map(
    ({ stageDefinition, ...stage }, index) => {
      const projectedDate = resolveStageProjectedDate(
        stagesWithDefinitions,
        index
      )
      return {
        ...stage,
        stageDefinition,
        projectedDate,
      }
    }
  )

  return (
    <ProjectStageUpdateDetailsContainer>
      {project.stages != null && project.stages.length > 0 && (
        <>
          <SectionTitle>Details:</SectionTitle>
          <StagesContainer>
            {stagesWithProjectedDates.map(
              ({ stageDefinition, ...stage }, index) => (
                <StageUpdateDetails
                  key={stageDefinition.id}
                  currentDueDate={DateTime.fromISO(stage.dueDate)}
                  projectedDate={DateTime.fromISO(stage.projectedDate)}
                  stageDefinition={stageDefinition}
                />
              )
            )}
          </StagesContainer>
          <Divider />
        </>
      )}
      <ProjectUpdateDetails
        project={project}
        projectedEndDateOverride={projectedEndDateOverride}
      />
    </ProjectStageUpdateDetailsContainer>
  )
}

type StageUpdateDetailsProps = {
  currentDueDate: DateTime
  projectedDate: DateTime
  stageDefinition: StageDefinitionSchema
}

function StageUpdateDetails({
  currentDueDate,
  projectedDate,
  stageDefinition,
}: StageUpdateDetailsProps) {
  const { label, sentiment } = getUpdateLabelAndSentiment({
    currentDueDate,
    newDueDate: projectedDate,
    entityType: 'stage',
  })

  return (
    <div className='flex flex-col gap-1 items-start self-stretch'>
      <div className='flex'>
        <StageLabel value={stageDefinition} size='small' />
      </div>
      <DateChangeRow
        currentDueDate={currentDueDate}
        newDueDate={projectedDate}
        label={label}
        sentiment={sentiment}
      />
    </div>
  )
}

type ProjectUpdateDetailsProps = {
  project: Pick<ProjectSchema, 'dueDate' | 'estimatedCompletionTime'>
  projectedEndDateOverride?: DateTime | null
}

function ProjectUpdateDetails({
  project,
  projectedEndDateOverride,
}: ProjectUpdateDetailsProps) {
  const currentDueDate =
    project.dueDate != null ? DateTime.fromISO(project.dueDate) : null
  const newDueDate =
    projectedEndDateOverride ??
    (project.estimatedCompletionTime != null
      ? DateTime.fromISO(project.estimatedCompletionTime)
      : null)

  const { label, sentiment } = getUpdateLabelAndSentiment({
    newDueDate,
    currentDueDate,
    entityType: 'project',
  })

  return (
    <div className='flex flex-col gap-2 items-start self-stretch'>
      <div className='flex flex-row gap-1 items-center'>
        <CalendarSolid height={14} width={14} />
        <span className='text-xs leading-3 font-semibold capitalize'>
          Project Deadline
        </span>
      </div>
      <DateChangeRow
        currentDueDate={currentDueDate}
        newDueDate={newDueDate}
        label={label}
        sentiment={sentiment}
      />
    </div>
  )
}

function DateChangeRow({
  currentDueDate,
  newDueDate,
  label,
  sentiment,
}: {
  currentDueDate: DateTime | null
  newDueDate: DateTime | null
  label: string
  sentiment: VariantProps<typeof UpdateLabelText>['sentiment']
}) {
  return (
    <DateRowContainer>
      <DateText date={currentDueDate} />
      <ArrowRightSolid className='size-3' />
      <DateText date={newDueDate} />
      <UpdateLabelText sentiment={sentiment}>{label}</UpdateLabelText>
    </DateRowContainer>
  )
}

function DateText({ date }: { date: DateTime | null }) {
  return (
    <span className='text-xs text-semantic-neutral-text-subtle'>
      {date == null ? 'None' : date.toFormat('EEE MMM d')}
    </span>
  )
}

const DateRowContainer = classed('div', {
  base: 'flex flex-row gap-2 items-center',
})

const UpdateLabelText = classed('span', {
  base: 'text-xs',
  variants: {
    sentiment: {
      positive: 'text-tag-green-subtle-text',
      negative: 'text-semantic-warning-text-default',
      neutral: 'text-semantic-neutral-text-subtle',
    },
  },
  defaultVariants: {
    sentiment: 'neutral',
  },
})

const ProjectStageUpdateDetailsContainer = classed('div', {
  base: 'flex flex-col items-start gap-3 self-stretch p-4 w-96 bg-semantic-neutral-bg-disabled h-full',
})

const SectionTitle = classed('div', {
  base: 'text-semantic-neutral-text-default text-[13px] font-semibold',
})

const StagesContainer = classed('div', {
  base: 'flex flex-col gap-4',
})

const Divider = classed('div', {
  base: 'border-t border-semantic-neutral-border-default h-0 self-stretch',
})

function getUpdateLabelAndSentiment({
  currentDueDate,
  newDueDate,
  entityType,
}: {
  currentDueDate: DateTime | null
  newDueDate: DateTime | null
  entityType: 'stage' | 'project'
}): {
  sentiment: VariantProps<typeof UpdateLabelText>['sentiment']
  label: string
} {
  if (newDueDate == null || currentDueDate == null) {
    return {
      label: entityType === 'stage' ? '(no new due date)' : '(no change)',
      sentiment: 'neutral',
    }
  }

  const daysBetween = daysBetweenDates(newDueDate, currentDueDate)
  if (daysBetween < 0) {
    const absDaysBetween = Math.abs(daysBetween)
    const label =
      entityType === 'stage'
        ? templateStr('(stage shrunk by {{days}}d)', {
            days: absDaysBetween,
          })
        : templateStr('(saved {{days}} days)', {
            days: absDaysBetween,
          })

    return {
      label,
      sentiment: 'positive',
    }
  }

  if (daysBetween > 0) {
    const label =
      entityType === 'stage'
        ? templateStr('(stage increased by {{days}}d)', {
            days: daysBetween,
          })
        : templateStr('(increased {{days}} days)', {
            days: daysBetween,
          })

    return {
      label,
      sentiment: 'negative',
    }
  }

  const label =
    entityType === 'stage' ? '(stage length unchanged)' : '(no change)'

  return {
    label,
    sentiment: 'neutral',
  }
}
