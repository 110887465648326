import { isMobileExperience } from '@motion/web-base/env'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useRequiresOnboarding } from '@motion/web-common/onboarding'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { LoadingPage } from '~/components/LoadingPage'
import { ModalContainer } from '~/components/ModalHandlers/ModalContainer'
import { ErrorPage } from '~/global/components/error-page'
import { useAuth } from '~/localServices/auth'
import { useMotionLocalStorage } from '~/localServices/storage'
import { navigateToMobileSplashScreen } from '~/routing/utils'
import { websocketsService } from '~/services/websockets-service'
import { registerCalendarWebsocketSubscribers } from '~/state/calendar-list/calendar-list-websocket-subscribers'
import { registerWebsocketSubscribers } from '~/state/calendarEvents/calendarEventsWebsocketEvents'
import { useEmailAccountWebSocketSubscription } from '~/state/email-accounts/email-accounts-websocket-subscribers'
import { registerOnboardingWebsocketSubscribers } from '~/state/onboarding/onboardingWebsocketEvents'
import { type ReactNode, useEffect, useState } from 'react'

import { Onboarding } from './v2/Onboarding'

import { handler as userHandler } from '../../services/UserServiceHandler'

declare module 'local-storage' {
  interface MotionLocalStorageItems {
    forceOnboarding?: boolean
  }
}

type EnsureOnboardedProps = {
  children: ReactNode
}
export const EnsureOnboarded = (props: EnsureOnboardedProps) => {
  const isOnboarding = useRequiresOnboarding()

  const { forceOnboarding } = useMotionLocalStorage(['forceOnboarding'])

  const auth = useAuth()
  if (isOnboarding == null || auth.status !== 'authenticated') {
    return <LoadingPage id='ensure-onboarded' />
  }

  if (isOnboarding || forceOnboarding) {
    return <ConnectedOnboarding logout={auth.logout} />
  }

  return props.children
}

type ConnectedOnboardingProps = {
  logout: () => void
}

const ConnectedOnboarding = (props: ConnectedOnboardingProps) => {
  const user = useAuthenticatedUser()
  const { setIsOnboarding } = useOnboardingApi()

  useEffect(() => {
    setIsOnboarding(true)
  }, [setIsOnboarding])

  useEffect(function initSubscribers() {
    void websocketsService.connect()
    const subscriptions = [
      registerWebsocketSubscribers(),
      registerCalendarWebsocketSubscribers(),
      registerOnboardingWebsocketSubscribers(),
    ]
    return () => {
      subscriptions.forEach((unsub) => unsub?.())
    }
  }, [])
  useEmailAccountWebSocketSubscription()

  const [loaded, setLoaded] = useState(false)
  const [hasSubscription, setHasSubscription] = useState(false)
  useEffect(() => {
    void userHandler
      .getSubscription(true)
      .then((v) => setHasSubscription(v ?? false))
      .finally(() => setLoaded(true))
  }, [])

  if (!loaded) {
    return <LoadingPage id='onboarding-subscription' />
  }

  if (hasSubscription && isMobileExperience()) {
    navigateToMobileSplashScreen()
    return null
  }

  return (
    <ErrorPage>
      <ModalContainer />
      <div className='no-print h-full w-full' data-theme='light'>
        <Onboarding userEmail={user.email} onLogout={props.logout} />
      </div>
    </ErrorPage>
  )
}
