import { ExclamationCircleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { selectHasContactLessEmails } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

export const MissingContactsPermissionsWarning = () => {
  const modal = useModalApi()

  const showPermissionWarning = useAppSelector(selectHasContactLessEmails)
  if (!showPermissionWarning) return null
  return (
    <div className='px-1 pb-1'>
      <Button
        size='small'
        fullWidth
        sentiment='error'
        variant='outlined'
        onClick={(e: React.MouseEvent) => {
          e.preventDefault()
          e.stopPropagation()
          modal.open('contact-permissions-modal')
        }}
      >
        <ExclamationCircleSolid className='size-4' />
        Update contacts permission to load all contacts
      </Button>
    </div>
  )
}
