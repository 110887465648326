import { useTaskByIdV2 } from '~/global/rpc/v2'

import { ActivityLink } from './activity-link'

export function ActivityTaskLink({ taskId }: { taskId: string }) {
  const { data: task } = useTaskByIdV2({
    id: taskId,
  })

  if (!task) {
    return <span className='line-through'>Deleted task</span>
  }

  return (
    <ActivityLink type='task' targetId={task.id}>
      {task.name}
    </ActivityLink>
  )
}
