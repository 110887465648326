import { useModalApi } from '@motion/web-common/modals'

import { useTimezoneSettings } from '~/global/hooks'

import { ScheduleModal, type ScheduleModalProps } from './ScheduleModal'

import { useAppSelector } from '../../../state/hooks'
import { selectSchedules, setSchedules } from '../../../state/settingsSlice'

type Optional<T> = {
  [TKey in keyof T]?: T[TKey]
}
type OptionalProps<TObj, TProps extends keyof TObj> = Optional<
  Pick<TObj, TProps>
>

type OptionalComponentProps = Optional<
  Pick<ScheduleModalProps, 'schedules' | 'scheduleId' | 'onChange'>
>
export type ConnectedScheduleModalProps = Omit<
  ScheduleModalProps,
  'onClose' | keyof OptionalComponentProps
>

export type OpenScheduleModalProps = Pick<ScheduleModalProps, 'scheduleId'> &
  OptionalProps<ScheduleModalProps, 'onChange' | 'schedules'>

export const ConnectedScheduleModal = (props: ConnectedScheduleModalProps) => {
  const modalApi = useModalApi()
  const schedules = useAppSelector(selectSchedules)
  const { defaultTimezone } = useTimezoneSettings({ useDefaults: true })

  const status = modalApi.status('schedule-modal')
  if (!status.visible) return null

  return (
    <ScheduleModal
      schedules={schedules}
      defaultTimezone={defaultTimezone}
      onChange={setSchedules}
      onClose={(scheduleId) =>
        scheduleId ? status.close(scheduleId) : status.close()
      }
      openTimezoneModal={(modalProps) => {
        modalApi.prompt('timezone-picker', {
          onTimezoneChange: modalProps.changeHandler,
          showDefault: modalProps.showDefault,
          clearableTimezoneValue: modalProps.clearableValue,
        })
      }}
      {...props}
      {...status.props}
    />
  )
}
