import { useClosure } from '@motion/react-core/hooks'
import { isPending } from '@motion/rpc-cache'
import { isNoneId } from '@motion/shared/identifiers'
import { LoadingSpinner } from '@motion/ui/base'
import {
  Label as PMLabel,
  type LabelProps as PMLabelProps,
} from '@motion/ui/pm'
import { isNewLabelColor, legacyColorToNewColor } from '@motion/ui-logic'
import { type LabelSchema } from '@motion/zod/client'

import { forwardRef, memo, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { LabelWidthCache, useCalculatePrettyLabels } from './hooks'

export type LabelProps = {
  value: {
    id: LabelSchema['id']
    name: LabelSchema['name']
    color: LabelSchema['color']
  }
  size?: 'xsmall' | 'small'
  noTruncate?: PMLabelProps['noTruncate']
}

export const Label = forwardRef<HTMLSpanElement, LabelProps>(function Label(
  { value, size, noTruncate },
  ref
) {
  if (value.id && isNoneId(value.id)) {
    return (
      <span
        ref={ref}
        className={twMerge('text-xs truncate', size === 'xsmall' && 'text-2xs')}
      >
        {value.name}
      </span>
    )
  }

  if (isPending(value)) {
    return (
      <span
        ref={ref}
        className={twMerge(
          'text-xs truncate inline-flex gap-1 items-center shrink-0',
          size === 'xsmall' && 'text-2xs'
        )}
      >
        <LoadingSpinner size={12} />
        {value.name}
      </span>
    )
  }

  const color =
    legacyColorToNewColor[value.color] ??
    (isNewLabelColor(value.color) ? value.color : 'grey')

  return (
    <PMLabel ref={ref} color={color} size={size} noTruncate={noTruncate}>
      {value.name}
    </PMLabel>
  )
})

export interface PrettyLabelsProps {
  values: LabelProps['value'][]
  size?: LabelProps['size']
}

/**
 * This component needs to be rendered in a container with a fixed width.
 * Otherwise, getting the container width in getLabelsRenderingInfo will be buggy.
 *
 * The component is memoized because it reads `getBoundingClientRect` on every render
 */
export const PrettyLabels = memo(function PrettyLabels({
  values,
  size,
}: PrettyLabelsProps) {
  const { refreshLabels, usedLabels, nbOverflow, setContainer } =
    useCalculatePrettyLabels({
      values,
    })

  return (
    <div
      ref={(ref) => setContainer(ref)}
      className='flex flex-row items-center gap-1 w-full'
    >
      {usedLabels.map((value) => (
        <AutoSizedLabel
          key={value.id}
          value={value}
          size={size}
          onSizeComplete={
            LabelWidthCache.has(value.name) ? undefined : refreshLabels
          }
          disableTruncation={usedLabels.length > 1}
        />
      ))}
      {nbOverflow > 0 && (
        <span className='flex items-center shrink-0'>
          <Label
            value={{ id: 'overflow', name: `+${nbOverflow}`, color: 'grey' }}
            size={size}
          />
        </span>
      )}
    </div>
  )
})

type AutoSizedLabelProps = LabelProps & {
  onSizeComplete?: (width: number) => void
  disableTruncation?: boolean
}

function AutoSizedLabel({
  onSizeComplete = () => {},
  disableTruncation = false,
  ...rest
}: AutoSizedLabelProps) {
  const [container, setContainer] = useState<HTMLSpanElement | null>(null)
  const onSize = useClosure(onSizeComplete)

  useEffect(() => {
    let width = LabelWidthCache.get(rest.value.name)

    if (width == null && container != null) {
      width = container.getBoundingClientRect().width
      LabelWidthCache.set(rest.value.name, width)
    }

    if (width != null) {
      onSize(width)
    }
  }, [container, onSize, rest.value.name])

  return (
    <Label
      ref={(node) => setContainer(node)}
      noTruncate={disableTruncation}
      {...rest}
    />
  )
}
