import { Sentry } from '@motion/web-base/sentry'

import { type CustomFieldWithValue } from '~/global/proxies'
import { isProxied } from '~/global/proxies/create-proxy'
import { type FC } from 'react'

import { ConnectedProjectCell } from './connected-project-cell'
import { ConnectedTaskCell } from './connected-task-cell'
import { NoCustomField } from './helpers'

import { type CellRenderFn } from '../../lookup'
import { TotalCountCell } from '../../total-cell'
import { useCustomFieldCellWrapper } from '../hooks'

type Props = {
  info: Parameters<CellRenderFn>[0]
  component: FC<any> | null
}

export function CellWrapper({ component, info }: Props) {
  const res = useCustomFieldCellWrapper({ info })

  const p = info.row.original.value

  if (p.type === 'task-totals' || p.type === 'project-totals') {
    if (p.value.addItemValue != null || res == null) {
      return <NoCustomField />
    }
    const { totalMeta } = res
    if (totalMeta == null) return <NoCustomField />
    const { count, suffix } = totalMeta
    if (count == null) return <NoCustomField />

    return <TotalCountCell count={count} suffix={suffix ?? undefined} />
  }

  if (p.type !== 'task' && p.type !== 'project') {
    return null
  }

  if (res == null || component == null) return <NoCustomField />

  const { customFieldId, disabled } = res

  if ('project' in res) {
    const { project } = res
    if (project == null) return <NoCustomField />

    const customField = project.customFields?.[customFieldId] ?? null
    if (customField == null) return <NoCustomField />

    return (
      <ConnectedProjectCell
        project={project}
        customField={customField}
        component={component}
        disabled={disabled}
      />
    )
  }

  const { task } = res
  if (task == null) return <NoCustomField />

  let customField: CustomFieldWithValue | null
  try {
    customField = task.customFields?.[customFieldId] ?? null
  } catch (ex) {
    Sentry.captureException(ex, {
      tags: {
        position: 'CustomField',
      },
      extra: {
        taskIsProxy: isProxied(task),
      },
    })
    return <NoCustomField />
  }

  if (customField == null) return <NoCustomField />

  return (
    <ConnectedTaskCell
      task={task}
      customField={customField}
      component={component}
      disabled={disabled}
    />
  )
}
