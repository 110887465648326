import { useProjectDeadlineData } from '~/areas/project/hooks/data'
import {
  DeadlineDropdown,
  type DeadlineDropdownProps,
  DeadlineToggle,
} from '~/areas/project-management/components'
import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskDeadlineData } from '~/areas/tasks/hooks'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { type DateTime } from 'luxon'

import { CardButton } from '../components'

export type TaskDeadlineFieldProps = {
  task: TaskWithRelations
}
export const TaskDeadlineField = ({ task }: TaskDeadlineFieldProps) => {
  const {
    onChange,
    onChangeDeadlineType,
    formattedValue,
    disabled,
    isValidDateOption,
  } = useTaskDeadlineData(task)

  return (
    <TaskDeadlineDropdown
      contextProps={{ taskId: task.id }}
      value={task.dueDate}
      onChange={onChange}
      isValidDateOption={isValidDateOption}
      renderCalendarFooter={() => (
        <DeadlineToggle
          onChange={onChangeDeadlineType}
          deadlineType={task.deadlineType}
        />
      )}
    >
      <CardButton disabled={disabled}>
        <div className='truncate'>{formattedValue}</div>
      </CardButton>
    </TaskDeadlineDropdown>
  )
}

export type ProjectDeadlineFieldProps = {
  disabled?: boolean
  isValidDateOption?: (date: DateTime) => boolean
  renderDropdownContentFooter?: DeadlineDropdownProps['renderCalendarFooter']
  project: ProjectWithRelations
}
export const ProjectDeadlineField = ({
  project,
  disabled,
  ...props
}: ProjectDeadlineFieldProps) => {
  const { onChange, hideTimeOptions, formattedValue } =
    useProjectDeadlineData(project)

  return (
    <DeadlineDropdown
      {...props}
      value={project.dueDate}
      onChange={onChange}
      hideTimeOptions={hideTimeOptions}
      contextProps={{ projectId: project.id }}
      dropdownTarget='project'
    >
      <CardButton disabled={disabled}>
        <div className='truncate'>{formattedValue}</div>
      </CardButton>
    </DeadlineDropdown>
  )
}
