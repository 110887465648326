import {
  ActionList,
  Button,
  PopoverTrigger,
  showToast,
  Tooltip,
} from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { Events } from '~/analyticsEvents'
import {
  type BookingLinkTemplate,
  copyAvailabilitiesManual,
  selectBookingSettings,
} from '~/state/booking'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { createFullTemplateUrl } from '~/utils/booking-utils'
import { useCallback } from 'react'

type CopyLinkButtonProps = Pick<
  BookingLinkTemplate,
  'linkSlug' | 'durationChoices'
> & {
  disabled: boolean
}

export const CopyLinkButton = ({
  linkSlug,
  durationChoices,
  disabled,
}: CopyLinkButtonProps) => {
  const dispatch = useAppDispatch()
  const { urlPrefix } = useAppSelector(selectBookingSettings)

  const handleCopyLink = useCallback<
    (durationChoice?: string | number) => void
  >(
    (durationChoice = '') => {
      const link =
        createFullTemplateUrl(urlPrefix ?? '', linkSlug) +
        (durationChoice ? `?d=${durationChoice}` : '')

      dispatch(copyAvailabilitiesManual(link))
      showToast('success', 'Link copied successfully')
      recordAnalyticsEvent(Events.CALENDAR_AVAILABILITIES_TEMPLATE_COPY_LINK)
    },
    [dispatch, linkSlug, urlPrefix]
  )

  return (
    <Tooltip
      renderContent={() => (
        <div className='space-y-1'>
          <p>Copy permanent link</p>
          <p className='text-xs'>A general link you can send to anyone.</p>
        </div>
      )}
    >
      {durationChoices.length > 1 ? (
        <PopoverTrigger
          placement='bottom-end'
          renderPopover={({ close }) => (
            <ActionList
              items={[
                {
                  content: 'Let guest choose duration',
                  onAction: () => void handleCopyLink(),
                },
                ...durationChoices.map((choice) => ({
                  content: `${choice} min`,
                  onAction: () => void handleCopyLink(choice),
                })),
              ]}
              onActionAnyItem={close}
            />
          )}
        >
          <Button
            size='xsmall'
            sentiment='neutral'
            variant='outlined'
            disabled={disabled}
          >
            Copy link
          </Button>
        </PopoverTrigger>
      ) : (
        <Button
          size='xsmall'
          sentiment='neutral'
          variant='outlined'
          onClick={() => void handleCopyLink()}
          disabled={disabled}
        >
          Copy link
        </Button>
      )}
    </Tooltip>
  )
}
