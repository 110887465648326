import { API } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import {
  type ProjectSchema,
  type ProjectsV2UpdateRequestSchema,
} from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'
import { applyOptimisticFolderItemUpdates } from '~/global/cache'

export function getProjectQueryFilters(projectId: ProjectSchema['id']) {
  return createQueryFilter([
    API.workspacesV2.queryKeys.root,
    MODEL_CACHE_KEY,
    API.projectsV2.queryKeys.byId(projectId),
  ])
}

export async function applyOptimisticProjectUpdates(
  client: QueryClient,
  projectId: string,
  updates: Partial<ProjectSchema> | Partial<ProjectsV2UpdateRequestSchema>
) {
  const { rollback: rollbackProject } = MotionCache.patch(
    client,
    getProjectQueryFilters(projectId),
    'projects',
    {
      [projectId]: updates,
    }
  )

  let rollbackFolders: (() => void) | undefined

  if (
    ('folderId' in updates && updates.folderId) ||
    ('folderItemOrder' in updates && updates.folderItemOrder)
  ) {
    ;({ rollback: rollbackFolders } = await applyOptimisticFolderItemUpdates(
      client,
      projectId,
      {
        parentFolderId: updates.folderId ?? undefined,
        order:
          (updates as ProjectsV2UpdateRequestSchema).folderItemOrder ??
          undefined,
      },
      'PROJECT'
    ))
  }

  const rollback = () => {
    rollbackProject?.()
    rollbackFolders?.()
  }

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}

export function applyOptimisticProjectDelete(
  client: QueryClient,
  projectId: ProjectSchema['id']
): OptimisticUpdateValue {
  const { rollback } = MotionCache.delete(
    client,
    getProjectQueryFilters(projectId),
    'projects',
    projectId
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
