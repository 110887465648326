import { TextField } from '@motion/ui/forms'

import { isTextVariable } from '~/areas/flows'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export const ControlledTextReplacementField = ({
  textReplacementPath,
}: {
  textReplacementPath: `textReplacements.${number}`
}) => {
  const { form } = useSetupProjectForm()

  const { control, watch } = form

  const { field: textReplacementField } = useController({
    name: `${textReplacementPath}.value`,
    control,
    rules: NAME_FIELD_RULE,
  })

  const textReplacementVariableId = watch(`${textReplacementPath}.variableId`)
  const textReplacements = watch('projectDefinition.variables').filter(
    isTextVariable
  )
  const selectedTextReplacement = textReplacements.find(
    (textReplacement) => textReplacement.id === textReplacementVariableId
  )

  if (selectedTextReplacement == null) {
    return null
  }

  return (
    <div className='w-[300px]'>
      <TextField
        label={selectedTextReplacement.name}
        value={textReplacementField.value ?? ''}
        onChange={textReplacementField.onChange}
      />
    </div>
  )
}
