import { classed } from '@motion/theme'

import {
  ConnectedProjectEtaBadge,
  ConnectedStageEtaBadge,
  ConnectedTaskEtaBadge,
} from '~/areas/eta'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CellText } from './components'
import { useProjectInTree } from './hooks'

import { type GroupedNode } from '../../grouping'

type ETACellProps =
  | {
      item: TaskWithRelations
      type: 'task'
    }
  | {
      item: ProjectWithRelations
      type: 'project'
    }

type ETAStageCellProps = {
  row: GroupedNode
  stageDefinitionId: string
}

export const ETACell = (props: ETACellProps) => {
  return (
    <ETACellContainer>
      {props.type === 'task' ? (
        <ConnectedTaskEtaBadge task={props.item} size='normal' />
      ) : props.type === 'project' ? (
        <ConnectedProjectEtaBadge project={props.item} size='normal' />
      ) : null}
    </ETACellContainer>
  )
}

export const ETAStageCell = ({ row, stageDefinitionId }: ETAStageCellProps) => {
  const project = useProjectInTree(row)
  if (!project) return null

  const stage = project.stages.find(
    (stage) => stage.stageDefinitionId === stageDefinitionId
  )

  if (!stage) {
    return null
  }

  return (
    <ETACellContainer>
      <ConnectedStageEtaBadge stage={stage} size='normal' />
    </ETACellContainer>
  )
}

const ETACellContainer = classed(CellText, 'flex items-center justify-start')
