import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { formatToCompactReadableWeekDayMonth } from '@motion/ui-logic'
import { type StageSchema } from '@motion/zod/client'

import { useDebugMode } from '~/global/hooks/use-debug-mode'

export function StageHeaderDebugInfo({
  projectStage,
}: {
  projectStage: StageSchema
}) {
  const shouldShowDebugInfo = useDebugMode()

  if (!shouldShowDebugInfo) return null

  return (
    <Button size='xsmall' variant='muted' sentiment='neutral'>
      <DebugModeLabel>
        <span className='whitespace-nowrap'>
          {formatToCompactReadableWeekDayMonth(projectStage.dueDate)}
        </span>
      </DebugModeLabel>
    </Button>
  )
}

const DebugModeLabel = classed(
  'div',
  'text-semantic-warning-text-default border-dashed border-semantic-warning-border-default p-0.5 border text-[8px] leading-[8px] font-mono'
)
