import { type CustomFieldValuesSchema } from '@motion/shared/custom-fields'
import {
  type AllAvailableCustomFieldSchema,
  getCustomFieldValue,
} from '@motion/ui-logic'
import { type NormalTaskSchema } from '@motion/zod/client'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

export function useTaskCustomFieldUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: NormalTaskSchema | NormalTaskSchema['id'],
      customFieldDefinition: AllAvailableCustomFieldSchema,
      valueArg: CustomFieldValuesSchema['value']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type !== 'NORMAL') {
        throw new Error('Cannot update custom fields on this task')
      }

      const value = getCustomFieldValue(valueArg)

      await updateTask(task, {
        customFieldValues: {
          // value is typed properly in the function signature
          [customFieldDefinition.id]: {
            type: customFieldDefinition.type,
            value: value as any,
          },
        },
      })
    },
    [readTask, updateTask]
  )
}
