import {
  CalendarSolid,
  PlusSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { Button, ButtonGroup, IconButton, Tooltip } from '@motion/ui/base'
import {
  formatToCompactReadableWeekDayMonthNoYear,
  formatToReadableWeekDayMonth,
} from '@motion/ui-logic'
import {
  getPreviousEnabledStage,
  getStageStartDate,
  getStageTense,
  type StageVariant,
} from '@motion/ui-logic/pm/project'
import { safeParseDate } from '@motion/utils/dates'
import { useHasTreatment } from '@motion/web-common/flags'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { ConnectedStageEtaBadge } from '~/areas/eta'
import {
  useCancelStage,
  useCompleteStage,
  useUpdateProjectStageDueDate,
} from '~/areas/project/hooks'
import { DeadlineDropdown } from '~/areas/project-management/components'

import { GoToStageButton } from './go-to-stage-button'
import { type LookupArgs } from './lookup-header'
import { StageHeaderContextMenu } from './stage-header-context-menu'
import { StageHeaderDebugInfo } from './stage-header-debug-info'
import { EndContentContainer } from './styled'

import { useSidebarTaskContext } from '../context'

export function StageHeaderEndContent({
  toggle,
  projectStage,
  project,
  stageDefinition,
  stageVariant,
  isActive,
}: Pick<LookupArgs, 'toggle'> & {
  projectStage: StageSchema
  project: ProjectSchema
  stageDefinition: StageDefinitionSchema
  stageVariant: StageVariant
  isActive: boolean
}) {
  const { enableInlineAdd } = useSidebarTaskContext()
  const updateStageDueDate = useUpdateProjectStageDueDate()

  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')
  const hasPastDueM2 = useHasTreatment('past-due-m2')
  const previousStage = getPreviousEnabledStage(project, stageDefinition.id)
  const projectStartDate = safeParseDate(project.startDate ?? '')?.plus({
    days: 1,
  })
  const stageStartDate = projectStartDate
    ? getStageStartDate(projectStartDate, previousStage?.dueDate)
    : null
  const completeProjectStage = useCompleteStage()
  const cancelProjectStage = useCancelStage()
  const isStageResolved =
    projectStage.canceledTime != null || projectStage.completedTime != null

  const showGoToStageButton = !isActive
  const showBetterResizeGoToStage =
    hasBetterResizeStages &&
    getStageTense(project, stageDefinition.id) === 'future'

  const shouldShowEtaBadge =
    hasPastDueM2 &&
    (projectStage.deadlineStatus === 'missed-deadline' ||
      projectStage.deadlineStatus === 'scheduled-past-deadline')

  if (stageVariant === 'skipped') {
    return (
      <EndContentContainer>
        {!__IS_PROD__ && <StageHeaderDebugInfo projectStage={projectStage} />}
        <em className='text-semantic-neutral-text-subtle text-2xs'>Canceled</em>
      </EndContentContainer>
    )
  }

  if (hasBetterResizeStages) {
    return (
      <EndContentContainer>
        {shouldShowEtaBadge && (
          <ConnectedStageEtaBadge size='xxsmall' stage={projectStage} />
        )}
        <div className='-mt-0.5'>
          {stageStartDate && (
            <span className='whitespace-nowrap text-button-neutral-muted-text-disabled text-[10px] leading-4 pl-0.5'>
              {formatToCompactReadableWeekDayMonthNoYear(stageStartDate)} -
            </span>
          )}
          <DeadlineDropdown
            hideTimeOptions
            disableClearing
            value={projectStage.dueDate}
            onChange={(dueDate) => {
              if (dueDate == null) return
              updateStageDueDate(project.id, stageDefinition.id, dueDate)
            }}
            isValidDateOption={() => true}
            contextProps={{
              projectId: project.id,
              stageDefinitionId: projectStage.id,
            }}
            dropdownTarget='stage'
          >
            <Button size='xsmall' variant='muted' sentiment='neutral'>
              <span className='whitespace-nowrap'>
                {formatToCompactReadableWeekDayMonthNoYear(
                  projectStage.dueDate
                )}
              </span>
            </Button>
          </DeadlineDropdown>
        </div>

        <ButtonGroup size='small'>
          {showBetterResizeGoToStage && (
            <GoToStageButton
              project={project}
              stageDefinition={stageDefinition}
            />
          )}
          {!isStageResolved && (
            <>
              <Tooltip asChild content='Complete stage'>
                <IconButton
                  icon={StatusCompletedSolid}
                  onClick={() =>
                    completeProjectStage(project, stageDefinition.id)
                  }
                  size='xsmall'
                  variant='muted'
                  sentiment='neutral'
                  aria-label='Complete stage'
                />
              </Tooltip>

              <Tooltip asChild content='Cancel stage'>
                <IconButton
                  icon={StatusCanceledSolid}
                  size='xsmall'
                  variant='muted'
                  sentiment='neutral'
                  onClick={() =>
                    cancelProjectStage(project, stageDefinition.id)
                  }
                  aria-label='Cancel stage'
                />
              </Tooltip>
            </>
          )}
        </ButtonGroup>
      </EndContentContainer>
    )
  }

  return (
    <EndContentContainer>
      {shouldShowEtaBadge && <ConnectedStageEtaBadge stage={projectStage} />}
      <DeadlineDropdown
        hideTimeOptions
        disableClearing
        value={projectStage.dueDate}
        onChange={(dueDate) => {
          if (dueDate == null) return
          updateStageDueDate(project.id, stageDefinition.id, dueDate)
        }}
        isValidDateOption={() => true}
        contextProps={{
          projectId: project.id,
          stageDefinitionId: projectStage.id,
        }}
        dropdownTarget='stage'
      >
        <Button size='xsmall' variant='muted' sentiment='neutral'>
          {!shouldShowEtaBadge && <CalendarSolid />}
          <span className='whitespace-nowrap'>
            {formatToReadableWeekDayMonth(projectStage.dueDate)}
          </span>
        </Button>
      </DeadlineDropdown>

      {showGoToStageButton && (
        <GoToStageButton project={project} stageDefinition={stageDefinition} />
      )}

      {enableInlineAdd && (
        <IconButton
          icon={PlusSolid}
          size='xsmall'
          variant='muted'
          sentiment='neutral'
          onClick={() => toggle({ stageDefinitionId: stageDefinition.id })}
          aria-label='Add new task'
        />
      )}

      <StageHeaderContextMenu
        project={project}
        projectStage={projectStage}
        stageDefinition={stageDefinition}
      />
    </EndContentContainer>
  )
}
