import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type TaskSchema } from '@motion/zod/client'

import { getParentChunkId } from '~/areas/tasks/utils'
import { useReadTaskFn, useStopTaskMutation } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

interface Options {
  source?: string
}

export const useStopTask = () => {
  const readTask = useReadTaskFn()

  const modalApi = useModalApi()
  const { mutateAsync: stopTask } = useStopTaskMutation()

  return useCallback(
    async (
      taskId: TaskSchema['id'],
      options: Options = {}
    ): Promise<boolean> => {
      const { source = 'none' } = options

      const task = await readTask(taskId)
      const parentChunkId = getParentChunkId(task)
      const parentChunkTask =
        parentChunkId != null ? await readTask(parentChunkId) : null
      const finalTask = parentChunkTask ?? task

      if (
        finalTask == null ||
        finalTask.type === 'RECURRING_TASK' ||
        task == null ||
        task.type === 'RECURRING_TASK'
      ) {
        return false
      }

      // The suggested completed duration should be based on the `task` rather than `finalTask`
      // Because if it's a chunk, we want it based on the chunk data
      const suggestedCompletedDuration = task.scheduledStart
        ? Math.max(
            Math.round(
              DateTime.now()
                .diff(DateTime.fromISO(task.scheduledStart))
                .as('minutes')
            ),
            0
          )
        : (task.duration ?? 0)

      const totalDuration = finalTask.duration ?? 0
      const completedDuration =
        finalTask.type !== 'CHUNK' ? finalTask.completedDuration : 0
      const suggestedRemainingDuration = Math.max(
        totalDuration - completedDuration - suggestedCompletedDuration,
        0
      )

      try {
        const stopTaskResponse = await modalApi.prompt('stop-task', {
          name: task.name,
          suggestedAdditionalCompleted: suggestedCompletedDuration,
          suggestedRemaining: suggestedRemainingDuration,
        })

        if (stopTaskResponse === ModalDismissed) return false

        recordAnalyticsEvent('TASK_STOP', {
          duration: totalDuration,
          completedDuration,
          source,
        })

        await stopTask({
          id: finalTask.id,
          additionalCompletedDuration: stopTaskResponse.completed,
          remainingDuration: stopTaskResponse.remaining,
        })
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useStopTask',
          },
        })

        showErrorToast(e)
      }
      return true
    },
    [readTask, modalApi, stopTask]
  )
}
