import { priorityLevels } from '@motion/rpc/types'
import { FieldButton, type FieldButtonProps } from '@motion/ui/base'
import { priorityLabels } from '@motion/ui-logic'

import { PriorityBadge } from '~/global/components/badges'
import { PriorityDropdown } from '~/global/components/dropdowns'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export type FormPriorityDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth' | 'iconOnly'
>

export const FormPriorityDropdown = ({
  ...buttonProps
}: FormPriorityDropdownProps) => {
  const { form } = useProjectForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'priorityLevel',
    control,
  })

  const selectedPriorityLevel = watch('priorityLevel')

  return (
    <PriorityDropdown
      items={priorityLevels}
      selectedItem={selectedPriorityLevel}
      onChange={(item) => {
        field.onChange(item)
      }}
    >
      <FieldButton {...buttonProps}>
        <PriorityBadge value={selectedPriorityLevel} hideTooltip />
        {!buttonProps.iconOnly && priorityLabels.get(selectedPriorityLevel)}
      </FieldButton>
    </PriorityDropdown>
  )
}
