import { type CustomFieldValuesSchema } from '@motion/rpc-types'
import {
  type AllAvailableCustomFieldSchema,
  formatToReadableWeekDayMonth,
} from '@motion/ui-logic'

import { type BadgeSize } from '~/global/components/badges'
import {
  Label,
  PrettyAssignees,
  PrettyLabels,
  UserLabel,
} from '~/global/components/labels'

export type CustomFieldValueLabelProps = {
  customField: AllAvailableCustomFieldSchema
  size?: BadgeSize
  value: CustomFieldValuesSchema
}

export const CustomFieldValueDisplay = ({
  value,
  customField,
  size,
}: {
  value: CustomFieldValuesSchema
  customField: CustomFieldValueLabelProps['customField']
  size: CustomFieldValueLabelProps['size']
}) => {
  if (
    (value.type === 'person' && customField.type === 'person') ||
    (value.type === 'multiPerson' && customField.type === 'multiPerson')
  ) {
    if (value.value == null) {
      return <UserLabel nameOnly size={size} value={null} />
    }
    const userIds = Array.isArray(value.value) ? value.value : [value.value]
    return <PrettyAssignees nameOnly userIds={userIds} size={size} />
  }

  if (value.type === 'select' && customField.type === 'select') {
    const {
      metadata: { options },
    } = customField

    const selectedOption = options.find((opt) => opt.id === value.value)

    if (value.value == null || selectedOption == null) {
      return 'None'
    }

    return (
      <Label
        size={size === 'small' || size === 'xsmall' ? size : undefined}
        value={{
          id: selectedOption.id,
          name: selectedOption.value,
          color: selectedOption.color,
        }}
      />
    )
  }

  if (value.type === 'multiSelect' && customField.type === 'multiSelect') {
    const {
      metadata: { options },
    } = customField

    const selectedOptionLabels = value.value
      ?.map((id) => options.find((opt) => opt.id === id))
      .filter(Boolean)
      .map((opt) => ({
        id: opt.id,
        name: opt.value,
        color: opt.color,
      }))

    if (value.value == null || selectedOptionLabels == null) {
      return 'None'
    }

    return (
      <PrettyLabels
        size={size === 'small' || size === 'xsmall' ? size : undefined}
        values={selectedOptionLabels}
      />
    )
  }

  if (value.type === 'date' && customField.type === 'date') {
    return (
      <div className='flex'>
        <span className='truncate'>
          {value.value ? formatToReadableWeekDayMonth(value.value) : 'None'}
        </span>
      </div>
    )
  }

  return null
}
