import { type HttpValidationError } from '@motion/rpc'
import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'
import { CUSTOM_FIELDS_VALIDATIONS } from '@motion/zod/client'

export class DuplicateOptionError extends Error {
  constructor(duplicates: string[]) {
    super(`Please remove duplicate options: ${duplicates.join(', ')}`)
  }
}

export const validateNoDuplicateOptions = <T extends { value: string }>(
  options: T[]
): void => {
  const duplicates = findDuplicates(options.map((option) => option.value))
  if (duplicates.length > 0) {
    throw new DuplicateOptionError(duplicates)
  }
}

function findDuplicates<T>(arr: T[]): T[] {
  const duplicates = new Set<T>()
  const seen = new Set<T>()
  for (const item of arr) {
    if (seen.has(item)) {
      duplicates.add(item)
    } else {
      seen.add(item)
    }
  }
  return [...duplicates]
}

export function getCustomFieldValidationErrorMessage(
  validationError: HttpValidationError['body']['errors'][number],
  customFields: AllAvailableCustomFieldSchema[]
): string | null {
  const customFieldValuesIndex = validationError.path.findIndex(
    (path) => path === 'customFieldValues'
  )

  if (validationError.code === 'too_big' && customFieldValuesIndex !== -1) {
    const customFieldId = validationError.path[customFieldValuesIndex + 1]
    const customField = customFields.find((field) => field.id === customFieldId)

    if (
      customField?.type === 'multiSelect' ||
      customField?.type === 'select' ||
      customField?.type === 'multiPerson'
    ) {
      return `${customField.name} has over the max limit of ${CUSTOM_FIELDS_VALIDATIONS.categories.multiSelect.options.maxSelected} values`
    }

    if (customField?.type === 'text' || customField?.type === 'url') {
      return `${customField.name} can't be over ${CUSTOM_FIELDS_VALIDATIONS.categories.text.value.maxLength} characters`
    }

    if (customField?.type === 'number') {
      return `${customField.name} can't be over ${CUSTOM_FIELDS_VALIDATIONS.categories.number.value.maxLength} characters`
    }
  }

  return null
}
