import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
} from '@motion/rpc-types'
import { classed } from '@motion/theme'

import { ModalFieldButton } from '~/areas/task-project/components'
import { ConnectedUserLabel } from '~/global/components/labels'
import { useProject, useProjectDefinition } from '~/global/hooks'
import { useDebugMode } from '~/global/hooks/use-debug-mode'

import { useProjectForm } from '../hooks'

export const ProjectDebugInfo = () => {
  const isEnabled = useDebugMode()

  if (!isEnabled) {
    return null
  }

  return <InnerProjectDebugInfo />
}

const InnerProjectDebugInfo = () => {
  const { form } = useProjectForm()

  const { watch } = form

  const projectId = watch('id')
  const project = useProject(projectId)

  const projectDefinitionId = watch('projectDefinitionId')
  const projectDefinition = useProjectDefinition(projectDefinitionId)

  return (
    <DebugInfoBox>
      <ModalFieldButton label='Project ID'>{project?.id}</ModalFieldButton>
      <ModalFieldButton label='Definition ID'>
        {projectDefinition?.id}
      </ModalFieldButton>
      {project?.variableInstances && projectDefinition?.variables && (
        <VariableInstanceDebugInfo
          variableInstances={project.variableInstances}
          variables={projectDefinition.variables}
        />
      )}
    </DebugInfoBox>
  )
}

const VariableInstanceDebugInfo = ({
  variableInstances,
  variables,
}: {
  variableInstances: ProjectSchema['variableInstances']
  variables: ProjectDefinitionSchema['variables']
}) => {
  return variableInstances.map((variableInstance) => {
    const variable = variables.find((v) => v.id === variableInstance.variableId)

    if (!variable) {
      return null
    }

    return (
      <ModalFieldButton key={variableInstance.variableId} label={variable.name}>
        {variable.type === 'person' ? (
          <ConnectedUserLabel userId={variableInstance.value} />
        ) : (
          variableInstance.value
        )}
      </ModalFieldButton>
    )
  })
}

const DebugInfoBox = classed('div', {
  base: `
    m-2
    border
    border-semantic-warning-border-default p-1
    text-semantic-neutral-text-default
    rounded
    text-xs
    flex flex-col gap-1
  `,
})
