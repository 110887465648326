import { ConnectedFolderBreadcrumbs } from '~/areas/folders/components'
import { useProjectUpdater } from '~/areas/project/hooks'
import { WorkspacesTreeDropdown } from '~/global/components/dropdowns'
import { FolderLabel } from '~/global/components/labels'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CellAction, CellText } from './components'

type ProjectFolderCellProps = {
  item: ProjectWithRelations
}

export const ProjectFolderCell = ({ item }: ProjectFolderCellProps) => {
  const folder = item.folder
  const updateFolder = useProjectUpdater()
  const selectedId = folder
    ? folder.type === 'USER'
      ? folder.id
      : folder.targetId
    : item.workspaceId

  return (
    <WorkspacesTreeDropdown
      selectedId={selectedId}
      onChange={({ folderId }) => {
        updateFolder(item, { folderId })
      }}
      leafNodeType='FOLDER'
      computeDisabled={({ workspace }) => workspace.id !== item.workspaceId}
      showCreateFolder
    >
      <CellAction>
        <ConnectedFolderBreadcrumbs
          leafNodeId={selectedId}
          abbreviated='start'
          omitWorkspace
        />
      </CellAction>
    </WorkspacesTreeDropdown>
  )
}

type TaskFolderCellProps = {
  item: TaskWithRelations
}

export const TaskFolderCell = (props: TaskFolderCellProps) => {
  const { item } = props

  return (
    <CellText>
      {item.project?.folderId ? (
        <ConnectedFolderBreadcrumbs
          leafNodeId={item.project.folderId}
          abbreviated='start'
          omitWorkspace
        />
      ) : (
        <FolderLabel value={null} />
      )}
    </CellText>
  )
}
