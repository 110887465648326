import { type CoursePayloadSchema } from '@motion/rpc-types'

import { useMemo } from 'react'

import { type TutorialLesson } from '../types'

type ActiveTutorialData = {
  activeLesson: TutorialLesson | undefined
  activeSessionTitle: string
  activeSectionIndex: number
  activeLessonIndex: number
}

export function useActiveTutorialData(
  tutorialData: CoursePayloadSchema | undefined,
  activeLessonId: string | undefined
) {
  return useMemo(() => {
    let result: ActiveTutorialData = {
      activeLesson: undefined,
      activeSessionTitle: '',
      activeSectionIndex: 0,
      activeLessonIndex: 0,
    }

    if (!tutorialData?.sections.length) {
      return result
    }

    tutorialData.sections.some((section, sectionIdx) => {
      const lessonIdx = section.lessons.findIndex(
        (lesson) => lesson.id === activeLessonId
      )
      if (lessonIdx < 0) return false

      result = {
        activeLesson: section.lessons[lessonIdx],
        activeSessionTitle: section.title,
        activeSectionIndex: sectionIdx,
        activeLessonIndex: lessonIdx,
      }
      return true
    })

    return result
  }, [activeLessonId, tutorialData])
}
