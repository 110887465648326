import { Tooltip } from '@motion/ui/base'
import {
  formatTime,
  formatToReadableWeekDayMonth,
  templateStr,
} from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { CellText } from './components'

type DateTimeCellProps = {
  dateTime: string | null
}

export const DateTimeCell = ({ dateTime }: DateTimeCellProps) => {
  const parsedDate = dateTime ? parseDate(dateTime) : null

  if (parsedDate == null) return null

  const formatted = formatToReadableWeekDayMonth(parsedDate)

  const tooltipContent = templateStr('{{date}} at {{time}}', {
    date: formatted,
    time: formatTime(parsedDate),
  })

  return (
    <CellText>
      <Tooltip asChild content={tooltipContent}>
        <span className='truncate'>{formatted}</span>
      </Tooltip>
    </CellText>
  )
}
