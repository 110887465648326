import { type SupportedNumberFilterSchema } from '@motion/ui-logic/pm/data'

import { ALLOWED_NUMBER_OPERATORS } from './types'

import { getNarrowedFilter } from '../utils'

export function getDisplayText(
  filters: Record<string, SupportedNumberFilterSchema> | null
) {
  const valueText = formatValue(filters)
  const filter = getNarrowedFilter(filters)
  const prefix = ALLOWED_NUMBER_OPERATORS.find(
    (x) => x.id === filter?.operator
  )?.label
  return [prefix, valueText].filter(Boolean).join(' ')
}

function formatValue(
  filters: Record<string, SupportedNumberFilterSchema> | null
): string | null {
  const filter = getNarrowedFilter(filters)
  if (filter == null) {
    return 'none'
  }

  if (filter.operator === 'empty') {
    return null
  }

  if (filter.operator === 'range') {
    return `${filter.value.min} - ${filter.value.max}`
  }

  return `${filter.value}`
}

export const changeOperation = (
  filter: SupportedNumberFilterSchema | null,
  op: SupportedNumberFilterSchema['operator']
): SupportedNumberFilterSchema | null => {
  if (filter == null) return null

  const prevOp = filter.operator
  if (prevOp === op) return filter

  if (op === 'empty') {
    return {
      inverse: filter.inverse,
      operator: op,
    }
  }

  if (op === 'range') {
    return {
      ...filter,
      operator: op,
      value: {
        min: 'value' in filter ? filter.value : 0,
        max: 'value' in filter ? (filter.value as number) + 10 : 10,
      },
    } as Extract<SupportedNumberFilterSchema, { operator: 'range' }>
  } else if (prevOp === 'range') {
    return {
      ...filter,
      operator: op,
      value: filter.value.min,
    }
  }

  return {
    ...filter,
    value: 'value' in filter ? filter.value : 0,
    operator: op,
  }
}
