// eslint-disable-next-line import-x/no-restricted-paths
import { useConferenceSettingsApi } from '~/areas/settings/state'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import {
  selectIsBrowserNotificationsEnabled,
  selectIsDesktopSubWindow,
} from '~/state/mainSlice'
import {
  fetchUserNotificationPreferences,
  selectUserNotificationPreferences,
} from '~/state/projectManagementSlice'
import { useCallback, useEffect, useState } from 'react'

import { useGenerateBrowserNotification } from './use-generate-browser-notification'
import { useTodaysScheduledEntities } from './use-todays-scheduled-entities'

import { getNextEvent } from '../utils'

// Check for new events every 10 seconds
const CHECK_INTERVAL = 10000

/*
 * Keeps track of the last notification id that was shown to the user
 * Shows the next event as a browser notification when the event is due (using the users reminder preferences)
 */
export const useNextEventBrowserNotification = () => {
  const [lastNotificationId, setLastNotificationId] = useState<string | null>(
    null
  )
  const entities = useTodaysScheduledEntities()
  const reminderSettings = useConferenceSettingsApi().selectReminderSettings()
  const dispatch = useAppDispatch()
  const generateBrowserNotification = useGenerateBrowserNotification()

  // TODO: Remove this when we get a better query
  useEffect(() => {
    void dispatch(fetchUserNotificationPreferences())
  }, [dispatch])

  // TODO: When backend gets chance to move the backend route to a better typed route,
  // we can move it out of redux into rpc
  const userNotificationPreferences = useAppSelector(
    selectUserNotificationPreferences
  )

  // TODO: See if we can move out any of the logic for browser notifications into this hook itself
  const isBrowserNotificationsEnabled = useAppSelector(
    selectIsBrowserNotificationsEnabled
  )

  const isBrowserNotificationPrevented = useAppSelector(
    selectIsDesktopSubWindow
  )

  const checkNextEvent = useCallback(() => {
    // If we do not have notifications enabled or if the browser is in a subwindow, then we do not show the notification
    if (!isBrowserNotificationsEnabled || isBrowserNotificationPrevented) {
      return
    }

    const nextEvent = getNextEvent(entities, {
      warningInSeconds: reminderSettings.warning,
      durationInSeconds: reminderSettings.duration,
      onlyToday: true,
      ignoreUpcomingEvents: true,
      ignoreCalendarEvents:
        !userNotificationPreferences?.browserMeetingReminder,
      ignoreTasksAndChunks: !userNotificationPreferences?.browserTaskReminder,
    })

    if (!nextEvent) {
      return
    }

    // If the next event is the same as the last event that was shown, then we don't show the notification
    if (nextEvent.id === lastNotificationId) {
      return
    }

    // Show the notification
    setLastNotificationId(nextEvent.id)
    generateBrowserNotification(nextEvent)
  }, [
    entities,
    generateBrowserNotification,
    isBrowserNotificationPrevented,
    isBrowserNotificationsEnabled,
    lastNotificationId,
    reminderSettings.duration,
    reminderSettings.warning,
    userNotificationPreferences?.browserMeetingReminder,
    userNotificationPreferences?.browserTaskReminder,
  ])

  useEffect(() => {
    // We check for a new event to notify about every 5 seconds in case the
    const checkEventTimer = setInterval(checkNextEvent, CHECK_INTERVAL)

    return () => {
      clearTimeout(checkEventTimer)
    }
  }, [checkNextEvent])
}
