import {
  ArrowSmDownSolid,
  ArrowSmUpSolid,
  DownloadSolid,
  DragSolid,
  RefreshSolid,
} from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { createNoneId, isNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { Button, IconButton, PopoverTrigger, Tooltip } from '@motion/ui/base'
import {
  isCustomFieldKey,
  parseCustomFieldInfoFromMaybeDelimitedKey,
} from '@motion/ui-logic'
import { type FilterTarget } from '@motion/ui-logic/pm/data'
import { Compare } from '@motion/utils/array'

import {
  closestCenter,
  DndContext,
  type DndContextProps,
  PointerSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual'
import {
  getLabelComponent,
  type TaskFieldLabelProps,
} from '~/global/components/labels'
import {
  type CSSProperties,
  type FC,
  type ReactNode,
  useRef,
  useState,
} from 'react'

import {
  getGroupNameFromGroupType,
  type GroupableField,
  useAvailableGroups,
} from '../../grouping'
import { buildGroupComparator } from '../../grouping/utils/multi-group/sort'

export type ReorderGroupByButtonProps = {
  value: GroupableField[]
  target: FilterTarget
  sortOrder: Record<string, string[]>
  onChange: (groupId: string, order: string[]) => void
  onResetOrder(field: string): void
}

export const ReorderGroupByButton = ({
  value,
  sortOrder,
  target,
  onChange,
  onResetOrder,
}: ReorderGroupByButtonProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => (
        <ReorderGroupByPopoverContent
          groups={value}
          sortOrder={sortOrder}
          onResetOrder={onResetOrder}
          target={target}
          onChange={onChange}
        />
      )}
    >
      <Button variant='outlined' sentiment='neutral' size='small'>
        Sort Groups
      </Button>
    </PopoverTrigger>
  )
}

type ReorderGroupByPopoverContentProps = {
  groups: ReorderGroupByButtonProps['value']
  target: ReorderGroupByButtonProps['target']
  onChange: ReorderGroupByButtonProps['onChange']
  sortOrder: Record<string, string[]>
  onResetOrder(field: string): void
}

const ReorderGroupByPopoverContent = ({
  groups,
  sortOrder,
  target,
  onChange,
  onResetOrder,
}: ReorderGroupByPopoverContentProps) => {
  const allGroupsValues = useAvailableGroups(target)

  return (
    <div
      className='grid grid-flow-col auto-cols-fr'
      style={{ width: `${240 * groups.length}px` }}
    >
      {groups.map((g) => {
        const group = allGroupsValues.find((x) => x.type === g.id)
        if (group == null) return null
        return (
          <GroupColumn
            key={g.id}
            group={group}
            sortOrder={sortOrder[group.type]}
            onResetOrder={onResetOrder}
            onChange={(ids) => {
              onChange(g.id, ids)
            }}
          />
        )
      })}
    </div>
  )
}

type GroupColumnProps = {
  group: ReturnType<typeof useAvailableGroups>[number] | undefined
  onChange: (rows: string[]) => void
  sortOrder: string[] | undefined
  onResetOrder(field: string): void
}
const GroupColumn = ({
  group,
  onChange,
  sortOrder,
  onResetOrder,
}: GroupColumnProps) => {
  const sensors = useSensors(useSensor(PointerSensor))

  const [rows, setRows] = useDependantState(() => {
    if (group == null || group.initialValues == null) return []

    // Dedupe any noneKey values
    let dedupedNoneValues = [...group.initialValues]
    const noneValues = dedupedNoneValues.filter((g) => isNoneId(g.key))

    if (noneValues.length > 1) {
      dedupedNoneValues = dedupedNoneValues.filter((g) => !isNoneId(g.key))

      dedupedNoneValues.push({
        key: createNoneId('null'),
        value: noneValues[0]?.value,
      })
    }

    return dedupedNoneValues.sort(buildGroupComparator<any>(group, sortOrder))
  }, [group, sortOrder])

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 33,
    overscan: 5,
    rangeExtractor: (range) => {
      const next =
        activeId != null
          ? [
              ...new Set([
                rows.findIndex((r) => r.key === activeId),
                ...defaultRangeExtractor(range),
              ]),
            ].sort(Compare.numeric)
          : defaultRangeExtractor(range)
      return next
    },
  })

  const handleDragStart: DndContextProps['onDragStart'] = ({ active }) => {
    setActiveId(active.id)
  }

  const moveItems = (oldIndex: number, newIndex: number) => {
    const newRows = arrayMove(rows, oldIndex, newIndex)

    setRows(newRows)
    setActiveId(null)
    onChange(newRows.map((r) => r.key))
  }

  const handleDragEnd: DndContextProps['onDragEnd'] = (event) => {
    const { active, over } = event
    if (over == null) return
    if (active.id === over.id) return

    const oldIndex = rows.findIndex((r) => r.key === active.id)
    const newIndex = rows.findIndex((r) => r.key === over.id)
    moveItems(oldIndex, newIndex)
  }

  const handleChangePosition: SortableListItemProps['onChangePosition'] = (
    id,
    position
  ) => {
    const oldIndex = rows.findIndex((r) => r.key === id)
    const newIndex =
      position === 'prev'
        ? oldIndex - 1
        : position === 'bottom'
          ? rows.length - 1
          : position === 'next'
            ? oldIndex + 1
            : 0

    moveItems(oldIndex, newIndex)
  }

  if (group == null) return null

  const Label = getLabelComponent(
    isCustomFieldKey(group.type)
      ? (parseCustomFieldInfoFromMaybeDelimitedKey(group.type)
          ?.customFieldType ?? 'unknown')
      : group.type
  ) as FC<TaskFieldLabelProps<any>>

  return (
    <div className='p-1 pt-2 min-w-0 even:bg-semantic-neutral-surface-overlay-bg-subtlest'>
      <div className='px-2 text-semantic-neutral-text-subtle font-semibold leading-5 text-[12px] grid [grid-template-columns:1fr_auto]'>
        <span>{group.name}</span>
        {group.isBounded && (
          <Tooltip content='Reset order'>
            <Button
              sentiment='neutral'
              variant='muted'
              size='small'
              onClick={() => onResetOrder(group.type)}
            >
              <RefreshSolid />
            </Button>
          </Tooltip>
        )}
      </div>
      {!group.isBounded && (
        <div className='text-semantic-neutral-text-subtle text-xs px-2 py-1'>
          {getGroupNameFromGroupType(group.groupType)} groups can&apos;t be
          reordered
        </div>
      )}
      {group.isBounded && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={() => setActiveId(null)}
        >
          <SortableContext
            items={rows.map((r) => r.key)}
            strategy={verticalListSortingStrategy}
          >
            <div ref={parentRef} className='overflow-auto max-h-[400px]'>
              <ul
                className='relative'
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`,
                }}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const row = rows[virtualRow.index]

                  return (
                    <SortableListItem
                      key={row.key}
                      id={row.key}
                      style={{
                        position: 'absolute',
                        top: virtualRow.start,
                        left: 0,
                        width: '100%',
                        height: virtualRow.size,
                      }}
                      onChangePosition={handleChangePosition}
                      canChangePosition={{
                        prev: virtualRow.index > 0,
                        next: virtualRow.index < rows.length - 1,
                      }}
                    >
                      <Label value={row.value} keyProp={row.key} />
                    </SortableListItem>
                  )
                })}
              </ul>
            </div>
          </SortableContext>
        </DndContext>
      )}
    </div>
  )
}

type SortableListItemProps = {
  children: ReactNode
  id: string
  style: CSSProperties
  onChangePosition: (
    id: string,
    position: 'top' | 'prev' | 'next' | 'bottom'
  ) => void
  canChangePosition: { prev: boolean; next: boolean }
}
const SortableListItem = ({
  id,
  children,
  style,
  onChangePosition,
  canChangePosition,
}: SortableListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  return (
    <ListItem
      ref={setNodeRef}
      {...attributes}
      style={{
        ...style,
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <button
        ref={setActivatorNodeRef}
        {...listeners}
        className='shrink-0 cursor-grab'
      >
        <DragSolid className='w-3 h-3 text-semantic-neutral-icon-default' />
      </button>
      <div className='flex-1 overflow-hidden'>{children}</div>
      <div className='shrink-0 flex-nowrap hidden group-hover/listitem:flex group-focus-within/listitem:flex'>
        <Tooltip content='Move to top'>
          <Button
            sentiment='neutral'
            variant='muted'
            iconOnly
            size='xsmall'
            onClick={() => onChangePosition(id, 'top')}
            disabled={!canChangePosition.prev}
          >
            <DownloadSolid className='rotate-180' />
          </Button>
        </Tooltip>
        <Tooltip content='Move above'>
          <IconButton
            icon={ArrowSmUpSolid}
            sentiment='neutral'
            variant='muted'
            size='xsmall'
            onClick={() => onChangePosition(id, 'prev')}
            disabled={!canChangePosition.prev}
          />
        </Tooltip>
        <Tooltip content='Move below'>
          <IconButton
            icon={ArrowSmDownSolid}
            sentiment='neutral'
            variant='muted'
            size='xsmall'
            onClick={() => onChangePosition(id, 'next')}
            disabled={!canChangePosition.next}
          />
        </Tooltip>
        <Tooltip content='Move to bottom'>
          <IconButton
            icon={DownloadSolid}
            sentiment='neutral'
            variant='muted'
            size='xsmall'
            onClick={() => onChangePosition(id, 'bottom')}
            disabled={!canChangePosition.next}
          />
        </Tooltip>
      </div>
    </ListItem>
  )
}

const ListItem = classed('li', {
  base: `
  group/listitem
  py-1.5 px-2
  flex gap-2 items-center
  min-w-0
  rounded

  hover:bg-dropdown-item-bg-hover`,
})
