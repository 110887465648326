import {
  getCacheEntryValue,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import type { ProjectSchema, StageSchema } from '@motion/rpc-types'
import { getStageTense } from '@motion/ui-logic/pm/project'
import { logInDev } from '@motion/web-base/logging'

import type { QueryClient } from '@tanstack/react-query'
import { getProjectQueryFilters, getTaskQueryFilters } from '~/global/cache'
import { DateTime } from 'luxon'

export function optimisticUpdateStageTasks(
  queryClient: QueryClient,
  stageDefinitionId: string,
  project: ProjectSchema,
  newProjectStages: StageSchema[]
): OptimisticUpdateValue[] {
  // Only update the modified stage and future stages (skip past stages)
  const affectedStages = project.stages.filter(
    (stage) =>
      getStageTense(project, stage.stageDefinitionId) !== 'past' ||
      stage.stageDefinitionId === stageDefinitionId
  )
  const newAffectedStages = newProjectStages.filter(
    (stage) =>
      getStageTense(project, stage.stageDefinitionId) !== 'past' ||
      stage.stageDefinitionId === stageDefinitionId
  )

  // Update tasks for each affected stage
  return affectedStages
    .flatMap((oldStage, index) => {
      const newStage = newAffectedStages[index]
      const stageTasks = MotionCache.hydrateFromIndex(
        queryClient,
        'stage-definition-tasks',
        oldStage.stageDefinitionId
      )

      // Skip if no date change or no tasks
      if (!oldStage.dueDate || !newStage?.dueDate || !stageTasks.length) {
        return
      }

      const delta = DateTime.fromISO(newStage.dueDate).diff(
        DateTime.fromISO(oldStage.dueDate),
        'days'
      ).days

      // Skip if no actual change in days
      if (delta === 0) return

      // Update all stage tasks' due dates
      return stageTasks.map((task) => {
        if (!task.dueDate) return

        const newDueDate = DateTime.fromISO(task.dueDate)
          .plus({ days: delta })
          .toISO()

        const { rollback } = MotionCache.upsert(
          queryClient,
          getTaskQueryFilters(task.id),
          {
            models: {
              tasks: {
                [task.id]: { ...task, dueDate: newDueDate },
              },
            },
          }
        )

        return {
          withRollback<T>(p: Promise<T>) {
            return p.catch((ex) => {
              rollback()
              throw ex
            })
          },
          rollback,
        }
      })
    })
    .filter(Boolean)
}

export function optimisticUpdateProjectStage(
  queryClient: QueryClient,
  projectId: ProjectSchema['id'],
  updatedStage: StageSchema
): OptimisticUpdateValue | null {
  const project = getCacheEntryValue(queryClient, 'projects', projectId)

  if (project == null) {
    logInDev('Project not found in cache, returning', { projectId })
    return null
  }

  const { rollback } = MotionCache.upsert(
    queryClient,
    getProjectQueryFilters(projectId),
    {
      models: {
        projects: {
          [projectId]: {
            ...project,
            stages: project.stages.map((stage) =>
              stage.id === updatedStage.id ? updatedStage : stage
            ),
          },
        },
      },
    }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
