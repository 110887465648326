import { API, createFetch } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type V2ResponseStoreShape,
} from '@motion/rpc-cache'
import { merge } from '@motion/utils/core'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { buildQueryContext, type MutationIds } from './utils'

import { log } from '../log'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]

const updateFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshProjects(
  ctx: HandlerContext,
  mutations: MutationIds
) {
  log.info('refresh-projects', mutations)

  const getProject = createFetch<typeof API.projectsV2.getById>(
    API.projectsV2.getById,
    buildQueryContext(ctx)
  )

  const idsToFetch = Array.from(
    new Set([...mutations.update, ...mutations.create])
  )

  const projectResolutions = await Promise.allSettled(
    idsToFetch.map((id) => {
      return getProject({ projectId: id })
    })
  )
  const projects = projectResolutions
    .filter((x) => x.status === 'fulfilled')
    .map((x) => x.value)

  const singleUpdate: V2ResponseStoreShape = {
    ids: projects.map((p) => p.id),
    meta: {
      model: 'projects',
    },
    models: {
      projects: merge({}, ...projects.map((x) => x.models.projects)),
    },
  }

  notifyManager.batch(() => {
    MotionCache.upsert(ctx.client, updateFilter, singleUpdate)

    if (mutations.delete.length) {
      MotionCache.delete(ctx.client, updateFilter, 'projects', mutations.delete)
    }
  })
}
