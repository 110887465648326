import { PlusSolid } from '@motion/icons'
import { Button, ButtonGroup } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { FlowTutorialButton } from '~/areas/flows'
import { useFlowTemplateModalUrl } from '~/areas/flows/flow-template-modal'
import { useProjectDefinitions } from '~/global/hooks'
import { useParams } from 'react-router-dom'

import { FlowTemplateCard } from './flow-template-card'
import {
  FlowSettingsTable,
  SettingsTitle,
  SettingsTitleText,
} from './stage-table'

export function FlowTemplateTable() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const flowProjectTemplates = useProjectDefinitions(workspaceId)

  const buildFlowTemplateModalUrl = useFlowTemplateModalUrl()
  const newFlowTemplateUrl = buildFlowTemplateModalUrl({
    forWorkspace: workspaceId,
    template: 'create',
  })

  const handleOpenFlowModal = () => {
    recordAnalyticsEvent('FLOW_TEMPLATE_MODAL_OPENED', { new: true })
  }

  return (
    <FlowSettingsTable>
      <SettingsTitle>
        <SettingsTitleText>Project Templates</SettingsTitleText>
        <ButtonGroup>
          <FlowTutorialButton rounded={false} />
          <Button
            onClick={handleOpenFlowModal}
            url={newFlowTemplateUrl}
            size='small'
          >
            <PlusSolid /> Create project template
          </Button>
        </ButtonGroup>
      </SettingsTitle>
      <div className='grid grid-cols-3 rounded gap-3 self-stretch'>
        {flowProjectTemplates.map((flowTemplate) => (
          <FlowTemplateCard key={flowTemplate.id} flowTemplate={flowTemplate} />
        ))}
      </div>
    </FlowSettingsTable>
  )
}
