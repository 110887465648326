import { CogSolid, PlusSolid, TutorialGradientSolid } from '@motion/icons'
import { ActionList, Button, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { Events } from '~/analyticsEvents'
import { selectBookingTemplates } from '~/state/booking'
import { useAppSelector } from '~/state/hooks'
import { sortBookingLinkTemplates } from '~/utils/booking-utils'
import { type FC, type PropsWithChildren, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { LinkItem } from './link-item'
import { TemplateRow } from './template-row'

import { useBookingLoadingState, useIsBookingOnboarded } from '../../hooks'
import { OnboardingGraphicCarousel } from '../onboarding-graphic-carousel'

export const BookingLinksDropdown: FC<PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const isOnboarded = useIsBookingOnboarded()
  const isBookingReady = useBookingLoadingState()

  /**
   * The legacy booking page redirects back to the calendar with a query
   * parameter of "booking", which we use to open the booking link popover on
   * mount. This just removes it once the page mounts.
   */
  useEffect(() => {
    if (searchParams.has('booking')) {
      setSearchParams(
        (params) => {
          params.delete('booking')

          return params
        },
        {
          replace: true,
        }
      )
    }
  }, [searchParams, setSearchParams])

  if (isOnboarded && !isBookingReady) return null

  return (
    <PopoverTrigger
      openOnMount={searchParams.has('booking')}
      placement='bottom-start'
      renderPopover={({ close }) =>
        isOnboarded ? (
          <BookingLinks close={close} />
        ) : (
          <BookingLinksOnboarding />
        )
      }
    >
      {children}
    </PopoverTrigger>
  )
}

export type BookingLinksDropdownProps = {
  close: () => void
}

const BookingLinks = ({ close }: BookingLinksDropdownProps) => {
  const navigate = useNavigate()
  const bookingTemplates = useAppSelector(selectBookingTemplates)

  const links = useMemo(
    () => sortBookingLinkTemplates(bookingTemplates),
    [bookingTemplates]
  )

  return (
    <div className='min-w-[460px] overflow-y-auto max-h-[calc(100vh-80px)]'>
      <ul className='p-1'>
        <LinkItem
          title='One-time link'
          hint='Create a one-time link that expires after being used'
        >
          <Button
            size='xsmall'
            sentiment='neutral'
            variant='outlined'
            url='/web/calendar/booking/create-link'
          >
            Create one-time link
          </Button>
        </LinkItem>

        {links.map((link) => (
          <TemplateRow key={link.id} template={link} closeParent={close} />
        ))}
      </ul>

      <div className='border-t border-dropdown-border'>
        <ActionList
          onActionAnyItem={close}
          sections={[
            {
              items: [
                {
                  content: 'Create new booking link',
                  prefix: <PlusSolid />,
                  url: '/web/calendar/booking/new',
                  onAction: () => {
                    recordAnalyticsEvent(
                      Events.CALENDAR_AVAILABILITIES_TEMPLATE_NEW
                    )
                  },
                },
                {
                  content: 'Booking settings',
                  prefix: <CogSolid />,
                  url: '/web/settings/booking',
                },
              ],
            },
            {
              items: [
                {
                  content: (
                    <span className='text-semantic-purple-text-default'>
                      Watch booking tutorial
                    </span>
                  ),
                  prefix: <TutorialGradientSolid />,
                  onAction: () => {
                    navigate('/web/booking_tutorial')
                    recordAnalyticsEvent(
                      'CALENDAR_AVAILABILITIES_TUTORIAL_CLICK'
                    )
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  )
}

const BookingLinksOnboarding = () => {
  const navigate = useNavigate()

  const handleCreateButton = () => {
    void recordAnalyticsEvent('SCHEDULER_ONBOARDING_OPEN')
    navigate('/web/booking_tutorial')
  }

  useEffect(() => {
    void recordAnalyticsEvent('SCHEDULER_ONBOARDING_PROMPT_SHOW')
  }, [])

  return (
    <div className='max-w-[440px] p-6 text-center'>
      <p className='font-semibold text-base mb-2'>
        The smartest booking link ever made
      </p>

      <p className='text-sm mb-5'>
        It books meetings, maximizes deep work time, and improves your focus.
      </p>

      <div className='mb-5'>
        <Button onClick={handleCreateButton}>Create booking link</Button>
      </div>

      <OnboardingGraphicCarousel />
    </div>
  )
}
