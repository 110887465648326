import { ChevronDownSolid } from '@motion/icons'
import { type TaskDeadlineType } from '@motion/rpc-types'
import { ActionDropdown, type ActionItem, Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { canTaskBeExtended } from '@motion/ui-logic/pm/task'
import { parseDate } from '@motion/utils/dates'
import { type TaskSchema } from '@motion/zod/client'

import { useCalendarStartDay } from '~/areas/calendar/hooks'
import {
  ContextAwareDateDropdownContent,
  DeadlineToggle,
} from '~/areas/project-management/components'
import { useI18N } from '~/global/contexts'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'

import { type CommonButtonProps } from './type'

import {
  useTasksLoadingState,
  useUpdateAllToSelectedDate,
  useUpdateTasksToScheduledDateOrWeek,
  useUpdateTasksToStageOrProjectDeadline,
} from '../hooks'

function getInitialDay(task: TaskSchema) {
  const today = DateTime.now().endOf('day')

  const candidate = task.scheduledStart ?? task.dueDate ?? task.startDate
  if (!candidate) return today

  try {
    const scheduledDate = parseDate(candidate)
    return scheduledDate < today ? today : scheduledDate
  } catch (err) {
    return today
  }
}

export const ChangeDeadlineButton = (props: CommonButtonProps) => {
  const { tasks, type } = props

  const [deadlineType, setDeadlineType] = useState<TaskDeadlineType>(
    tasks.every((task) => task.deadlineType === 'SOFT') ? 'SOFT' : 'HARD'
  )
  const calendarStartDay = useCalendarStartDay()
  const updateTasksToScheduledDateOrWeek = useUpdateTasksToScheduledDateOrWeek()
  const updateTasksToProjectOrStageDeadline =
    useUpdateTasksToStageOrProjectDeadline()
  const [isLoading, setIsLoading] = useTasksLoadingState(tasks)
  const updateTasksToSelectedDate = useUpdateAllToSelectedDate({
    type,
    tasks,
    setIsLoading,
  })
  const { pluralize } = useI18N()

  const commonTaskItems = useMemo(() => {
    // Given the taskIds, we want to ensure that actions presented match all tasks
    const canExtend = tasks.every((task) => canTaskBeExtended(task, null))
    const anyRecurring = tasks.some(
      (task) => task.type === 'RECURRING_INSTANCE'
    )
    let selectedDay = DateTime.now().endOf('day')
    if (tasks.length === 1) {
      const task = tasks[0]
      selectedDay = getInitialDay(task)
    }

    const isScheduled = tasks.every((task) => {
      const isScheduledOnCalendar =
        task.type === 'NORMAL' &&
        !!task.scheduledStatus &&
        task.scheduledStatus !== 'UNFIT_PAST_DUE'

      const isScheduledOnRecurring =
        task.type === 'RECURRING_INSTANCE' && !!task.estimatedCompletionTime

      return isScheduledOnCalendar || isScheduledOnRecurring
    })

    const hasStageOrProjectDeadline = tasks.every(
      (task) =>
        task.projectId &&
        (task.project?.dueDate ||
          (task.type === 'NORMAL' && task.stageDefinitionId))
    )

    return {
      canExtend,
      deadlineType,
      anyRecurring,
      selectedDay,
      isScheduled,
      hasStageOrProjectDeadline,
    }
  }, [deadlineType, tasks])

  const items = useMemo(() => {
    const {
      anyRecurring,
      selectedDay,
      hasStageOrProjectDeadline,
      isScheduled,
    } = commonTaskItems
    const items: ActionItem[] = [
      {
        content: 'Scheduled day',
        description: 'Extend the deadline to the scheduled day',
        disabled: isLoading || !isScheduled,
        tooltip: !isScheduled
          ? `${templateStr(
              "{{taskDescription}} can't fit before the deadline",
              {
                taskDescription: pluralize(
                  tasks.length,
                  'This task',
                  anyRecurring
                    ? 'One of the recurring instances'
                    : 'One of the selected tasks'
                ),
              }
            )}`
          : undefined,
        onAction: () => {
          void updateTasksToScheduledDateOrWeek({
            tasks,
            setIsLoading,
            type,
            updateType: 'DATE',
          })
        },
      },
      {
        content: 'Scheduled week',
        description: 'Extend the deadline to the end of the scheduled week',
        disabled: isLoading || !isScheduled,
        tooltip: !isScheduled
          ? `${templateStr(
              "{{taskDescription}} can't fit before the deadline",
              {
                taskDescription: pluralize(
                  tasks.length,
                  'This task',
                  anyRecurring
                    ? 'One of the recurring instances'
                    : 'One of the selected tasks'
                ),
              }
            )}`
          : undefined,
        onAction: () =>
          void updateTasksToScheduledDateOrWeek({
            tasks,
            setIsLoading,
            type,
            updateType: 'WEEK',
          }),
      },
      {
        content: 'Stage or project deadline',
        description: 'Extend the deadline to the stage or project deadline',
        disabled: anyRecurring || !hasStageOrProjectDeadline || isLoading,
        tooltip: anyRecurring
          ? "This action can't be done on recurring tasks"
          : !hasStageOrProjectDeadline
            ? `${templateStr(
                '{{taskDescription}} does not have a stage or project with a deadline',
                {
                  taskDescription: pluralize(
                    tasks.length,
                    'This task',
                    'One of the selected tasks'
                  ),
                }
              )}`
            : undefined,
        onAction: () => {
          updateTasksToProjectOrStageDeadline({
            tasks,
            type,
            setIsLoading,
          })
        },
      },
      {
        content: 'Pick a date',
        description: templateStr(
          'Pick your own date. Note that this date will be the new deadline for {{taskModifier}}',
          {
            taskModifier: pluralize(
              tasks.length,
              'the selected task',
              'all selected tasks'
            ),
          }
        ),
        disabled: anyRecurring || isLoading,
        tooltip: anyRecurring
          ? "This action can't be done on recurring tasks"
          : undefined,
        renderPopover({ close }: { close: () => void }) {
          if (anyRecurring) return null

          return (
            <ContextAwareDateDropdownContent
              calendarStartDay={calendarStartDay}
              value={selectedDay.toISO()}
              disabledDate={(value) => value < DateTime.now().startOf('day')}
              disableClearing
              onChange={(value) => {
                if (!value) return
                updateTasksToSelectedDate(value, deadlineType)
                close()
              }}
              renderCalendarFooter={() => (
                <DeadlineToggle
                  deadlineType={deadlineType}
                  onChange={(value) => setDeadlineType(value)}
                />
              )}
            />
          )
        },
      },
    ]

    // Need to check the item exists because ActionItem can be a boolean value
    const areAllOptionsDisabled = items.every((item) => !item || item.disabled)
    if (areAllOptionsDisabled) return []

    return items
  }, [
    commonTaskItems,
    isLoading,
    pluralize,
    tasks,
    updateTasksToScheduledDateOrWeek,
    setIsLoading,
    type,
    updateTasksToProjectOrStageDeadline,
    calendarStartDay,
    updateTasksToSelectedDate,
    deadlineType,
  ])

  if (items.length === 0) return null

  return (
    <ActionDropdown placement='bottom-end' items={items}>
      <Button
        disabled={isLoading}
        variant='outlined'
        sentiment='neutral'
        size='small'
      >
        Extend deadline
        <ChevronDownSolid width={16} height={16} />
      </Button>
    </ActionDropdown>
  )
}
