import { API, createFetch } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'

import { notifyManager } from '@tanstack/react-query'

import { type HandlerContext } from './types'
import { buildQueryContext } from './utils'

import { log } from '../log'
import { type ProjectDefinitionBatchOperations } from '../types'

const KEYS_TO_UPDATE = [MODEL_CACHE_KEY, API.workspacesV2.queryKeys.root]

const updateFilter = createQueryFilter(KEYS_TO_UPDATE)

export async function refreshProjectDefinition(
  ctx: HandlerContext,
  mutation: ProjectDefinitionBatchOperations['data']
) {
  log.info('refresh-projects-definition', mutation)
  const { workspaceId, definitionId } = mutation

  if (!definitionId || !workspaceId) {
    log.warn('Missing definitionId or workspaceId')
    return
  }

  const getProjectDefinition = createFetch<
    typeof API.projectsDefinitions.getById
  >(API.projectsDefinitions.getById, buildQueryContext(ctx))

  const projectDefinition = await getProjectDefinition({
    id: definitionId,
    workspaceId,
  })

  notifyManager.batch(() => {
    MotionCache.upsert(ctx.client, updateFilter, projectDefinition)
  })
}
