import { type UploadedFileSchema } from '@motion/rpc-types'
import { ActionList } from '@motion/ui/base'

import { useAttachmentActionItems } from './hooks'

export type AttachmentActionListProps = {
  attachment: UploadedFileSchema
  close: () => void
  onRename: () => void
}

export const AttachmentActionList = ({
  attachment,
  close,
  onRename,
}: AttachmentActionListProps) => {
  const { sections } = useAttachmentActionItems(attachment, { onRename })

  return <ActionList sections={sections} onActionAnyItem={close} />
}
