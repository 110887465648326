import { PencilSolid, ThumbDownSolid, ThumbUpSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, type ButtonProps, Tooltip } from '@motion/ui/base'

import { useState } from 'react'

export type FeedbackQuestionProps = {
  question: string
  onThumbUpClick?: () => void
  onThumbDownClick?: () => void
  onPencilClick?: () => void
  pencilButtonUrl?: string
}

export function FeedbackQuestion({
  question,
  onThumbUpClick,
  onThumbDownClick,
  onPencilClick,
  pencilButtonUrl,
}: FeedbackQuestionProps) {
  const [thumbClicked, setThumbClicked] = useState<'up' | 'down' | null>(null)

  return (
    <MainContainer>
      <QuestionText>{question}</QuestionText>
      <ButtonsContainer>
        <Button
          iconOnly
          {...ICON_BUTTON_COMMON_PROPS}
          sentiment={thumbClicked === 'up' ? 'primary' : 'neutral'}
          onClick={() => {
            setThumbClicked('up')
            onThumbUpClick?.()
          }}
          pressed={thumbClicked === 'up'}
        >
          <ThumbUpSolid height={12} width={12} className='m-0.5' />
        </Button>
        <Button
          iconOnly
          {...ICON_BUTTON_COMMON_PROPS}
          sentiment={thumbClicked === 'down' ? 'primary' : 'neutral'}
          onClick={() => {
            setThumbClicked('down')
            onThumbDownClick?.()
          }}
          pressed={thumbClicked === 'down'}
        >
          <ThumbDownSolid height={12} width={12} className='m-0.5' />
        </Button>
        <DividerContainer>
          <Divider />
        </DividerContainer>
        <Tooltip asChild content='Tell us what you think'>
          <Button
            iconOnly
            {...ICON_BUTTON_COMMON_PROPS}
            sentiment='neutral'
            onClick={onPencilClick}
            url={pencilButtonUrl}
          >
            <PencilSolid
              height={12}
              width={12}
              className='mx-0.5 mt-[1.5px] mb-[3px]'
            />
          </Button>
        </Tooltip>
      </ButtonsContainer>
    </MainContainer>
  )
}

const ICON_BUTTON_COMMON_PROPS: Partial<ButtonProps> = {
  size: 'xsmall',
  variant: 'outlined',
}

const MainContainer = classed('div', {
  base: 'flex flex-row gap-4 items-center',
})

const QuestionText = classed('span', {
  base: 'text-2xs leading-4 text-semantic-neutral-text-subtle min-w-72',
})

const ButtonsContainer = classed('div', {
  base: 'flex flex-row gap-[3px]',
})

const DividerContainer = classed('div', {
  base: 'flex mx-[5px]',
})

const Divider = classed('div', {
  base: 'h-[80%] w-px bg-semantic-neutral-border-default rounded-full self-center',
})
