import { Button } from '@motion/ui/base'

import { YoutubeVideo } from '../../Common/YoutubeVideo/YoutubeVideo'

export const AddICloudInstructions = ({ onNext }: { onNext: () => void }) => {
  return (
    <div className='flex flex-col gap-4 text-semantic-neutral-text-default'>
      <p>
        To add an iCloud calendar, first create an app-specific password in
        iCloud:
      </p>
      <ol>
        <li>
          1. Sign into your Apple ID{' '}
          <a
            href='https://appleid.apple.com/sign-in'
            target='_blank'
            className='underline text-semantic-primary-text-default'
            rel='noopener noreferrer'
          >
            here
          </a>
          .
        </li>
        <li>2. Click &quot;App-Specific&quot; passwords.</li>
        <li>3. Generate an app-specific password.</li>
        <li>4. Copy the app-specific password.</li>
        <li>5. Click Next to continue.</li>
      </ol>

      <div className='w-full h-[270px] rounded-md overflow-hidden'>
        <YoutubeVideo skipAutoPlay muteOnStart={false} videoId='_SH7ubmHsTU' />
      </div>

      <Button onClick={onNext} fullWidth>
        <div className='w-full flex items-center justify-center'>Next</div>
      </Button>
    </div>
  )
}
