import { CalendarSolid } from '@motion/icons'
import { StageAdjusterStrategy } from '@motion/shared/flows'
import { Button } from '@motion/ui/base'
import { FieldLabel } from '@motion/ui/forms'
import { stageAdjusterFn } from '@motion/ui-logic/pm/project'
import { getDateButtonText } from '@motion/ui-logic/pm/task'

import { DeadlineDropdown } from '~/areas/project-management/components'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'
import { useSetupProjectDateRangeColors } from '../../../hooks/use-setup-project-date-range-colors'

export const ControlledDeadlineField = () => {
  const { form } = useSetupProjectForm()

  const { control, watch, setValue, getValues } = form

  const { field } = useController({
    name: 'dueDate',
    control,
  })

  const projectId = watch('projectId')

  const dateButtonString = getDateButtonText(field.value, {
    placeholder: 'None',
  })

  const stageDateRangeColors = useSetupProjectDateRangeColors()

  return (
    <FieldLabel label='Deadline' fullWidth>
      <div className='mt-1'>
        <DeadlineDropdown
          value={field.value}
          onChange={(value) => {
            if (value == null) return
            stageAdjusterFn({
              params: {
                startDate: getValues('startDate'),
                dueDate: getValues('dueDate'),
                stageDueDates: getValues('stageDueDates'),
              },
              onAction: (adjuster) => {
                adjuster.prepareProjectAdjustment({
                  strategy: StageAdjusterStrategy.DISTRIBUTE,
                  target: 'due',
                  value,
                })
              },
              onResult: ({ startDate, dueDate, stageDueDates }) => {
                setValue('startDate', startDate)
                setValue('stageDueDates', stageDueDates)
                field.onChange(dueDate)
              },
            })
          }}
          hideTimeOptions
          disableClearing
          contextProps={{ projectId }}
          dropdownTarget='project'
          overrides={{
            stageDateRangeColors,
          }}
        >
          <Button
            variant='outlined'
            sentiment='neutral'
            fullWidth
            alignment='left'
            size='small'
          >
            <CalendarSolid />
            {dateButtonString}
          </Button>
        </DeadlineDropdown>
      </div>
    </FieldLabel>
  )
}
