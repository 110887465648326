import { sleep } from '@motion/utils/promise'
import { makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { getCurrentUserToken } from '~/utils/auth'
import { io, type Socket } from 'socket.io-client'

import * as api from '../chromeApi/webappChromeApiBackground'

const log = makeLog('[websockets]')

const MAX_RETRY_DELAY = 20_000

export type RoomSubscription = {
  room: 'feed'
  args: {
    id: string
    type: 'task' | 'project'
  }
}

export class WebsocketsService {
  static id = 'WebsocketsService' as const

  socket!: Socket
  isConnected = false
  rooms: Map<string, number> = new Map()
  shouldReconnect = true
  maxReconnectAttempts = 0

  /**
   * Convert the emit callback function into async/await
   * @param event
   * @param args
   * @returns
   */
  emit(event: string, args?: object): Promise<any> {
    if (!this.isConnected) {
      return Promise.reject(new Error('Websocket is not connected'))
    }

    return new Promise((resolve) => {
      log('emit', { event, args })
      this.socket.emit(event, args, (...res: any[]) => {
        resolve(res)
      })
    })
  }

  private roomHash(room: RoomSubscription) {
    // Needs to get leveled up when we have more than one room
    return `${room.room}:${room.args.id}:${room.args.type}`
  }

  private roomSubFromHash(roomStr: string): RoomSubscription | null {
    try {
      const [room, id, type] = roomStr.split(':')
      return { room, args: { id, type } } as RoomSubscription
    } catch (e) {
      log.error({ e, roomStr }, 'Error parsing roomHash')
      return null
    }
  }

  join(sub: RoomSubscription) {
    const roomHash = this.roomHash(sub)
    const count = this.rooms.get(roomHash) ?? 0

    if (count > 0) {
      this.rooms.set(roomHash, count + 1)
      return () => this.leave(sub)
    }

    this.rooms.set(roomHash, 1)
    if (!this.isConnected) {
      // We are not currently connected to the websocket so we will not try to emit the subscription
      // We still want to 'leave' the room when the cleanup function is called in case we connect
      // in between calling 'join' and 'leave'
      return () => this.leave(sub)
    }

    void this.emit(`${sub.room}.subscribe`, sub.args).catch((ex) => {
      Sentry.captureException(ex, {
        tags: {
          source: 'websockets',
        },
        extra: {
          message: `Failed to join "${roomHash}".`,
        },
      })

      const newCount = (this.rooms.get(roomHash) ?? 1) - 1
      if (newCount <= 0) {
        this.rooms.delete(roomHash)
      } else {
        this.rooms.set(roomHash, newCount)
      }
    })
    return () => this.leave(sub)
  }

  leave(sub: RoomSubscription) {
    const roomHash = this.roomHash(sub)
    if (!this.rooms.has(roomHash)) return
    const count = this.rooms.get(roomHash) ?? 0
    if (count > 1) {
      this.rooms.set(roomHash, count - 1)
      return
    }
    this.rooms.delete(roomHash)

    if (!this.isConnected) return
    void this.emit(`${sub.room}.unsubscribe`, sub.args)
  }

  refreshRooms() {
    void this.emit('rooms.refresh')
  }

  async connect() {
    if (this.isConnected) return
    const token = await getCurrentUserToken()
    this.socket = io(`${__WEBSOCKETS_HOST__}`, {
      auth: {
        token: token,
        // Specific handling for dev environment as socket.io is not able to
        // access the cookie in the dev environment without a domain specified.
        cookie: __ENV__ === 'dev' ? document.cookie : undefined,
      },
      transports: ['websocket'],
      reconnectionDelay: 1000,
      reconnectionDelayMax: 20_000,
      withCredentials: true,
      reconnectionAttempts: 10,
    })

    this.socket.on('connect', this.onConnect.bind(this))
    this.socket.on('connect_error', this.onError.bind(this))
    this.socket.on('error', this.onError.bind(this))
    this.socket.on('event', this.onEvent.bind(this))
    this.socket.on('disconnect', this.onDisconnect.bind(this))
    log('Initializing')
  }

  async reconnect() {
    if (this.socket == null) return

    this.isConnected = false
    this.socket.disconnect()

    this.socket.connect()
  }

  private onConnect() {
    // deprecated
    void api.sendContentScriptMessage(
      { type: 'team.connect' },
      'teamWebsocketMessage'
    )
    log('connected', this.socket.id)

    this.isConnected = true

    const copyJoinedRooms = Array.from(this.rooms.keys())
    this.rooms.clear()
    copyJoinedRooms.forEach((room) => {
      const sub = this.roomSubFromHash(room)
      if (sub) {
        this.join(sub)
      }
    })

    // Reset the retry counter after 10s
    this._retryReset = setTimeout(() => {
      this._retryCount = 0
      this._retryReset = undefined
    }, 10_000)
  }

  private onError(err: Error) {
    log(`connect_error due to ${err.message}`)

    switch (err.message) {
      case 'notFound':
        this.maxReconnectAttempts = 3
        break
      case 'userDeleted':
        this.shouldReconnect = false
        break
    }
  }

  private onEvent(...args: any[]) {
    log('event', ...args)
    this._retryCount = 0

    // deprecated
    void api.sendContentScriptMessage(
      { ...args[0] },
      'teamWebsocketMessage',
      false
    )
    void api.sendContentScriptMessage(
      { ...args[0] },
      'websocketsMessage',
      false
    )
  }

  private _retryCount = 0
  private _retryReset: any = undefined

  private async onDisconnect(reason: string) {
    this.isConnected = false
    clearTimeout(this._retryReset)
    this._retryReset = undefined

    // https://socket.io/docs/v4/client-socket-instance/#disconnect
    log('disconnected', this.socket.id, reason)
    if (reason === 'io server disconnect' && this.shouldReconnect) {
      if (
        this.maxReconnectAttempts > 0 &&
        this._retryCount >= this.maxReconnectAttempts
      ) {
        log(`max reconnect attempts reached ${this.maxReconnectAttempts}`)
        this.shouldReconnect = false
        return
      }

      // The disconnection was initiated by the server, most likely due to the
      // Firebase ID token expiring Reconnect manually
      this._retryCount += 1
      const sleepAmount =
        this._retryCount < 5
          ? Math.min(Math.pow(this._retryCount, 2) * 1000, MAX_RETRY_DELAY)
          : MAX_RETRY_DELAY

      log(`sleeping ${sleepAmount / 1000}s`, this._retryCount)

      await sleep(sleepAmount)
      const token = await getCurrentUserToken(true)
      this.socket.auth = { ...this.socket.auth, token }
      this.socket.connect()
    } else {
      this._retryCount = 0
    }
    // else the socket will automatically try to reconnect
  }
}

export const websocketsService = new WebsocketsService()
if (__IS_DEV__) {
  // @ts-expect-error only in dev
  window.ws = (...args) => websocketsService.onEvent(...args)
}
