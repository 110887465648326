import { VideoCameraSolid } from '@motion/icons'

import { GenericField } from '../../shared/fields'

export interface ConferenceFieldProps {
  conferenceName: string
}

export const ConferenceField = (props: ConferenceFieldProps) => {
  const { conferenceName } = props

  return <GenericField icon={VideoCameraSolid}>{conferenceName}</GenericField>
}
