import { type SvgIcon } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

import { IconWrapper } from './icon-wrapper'

interface ModalTitleProps extends VariantProps<typeof Title> {
  text: string
  subtext: string
  icon: SvgIcon
}

export const ModalTitle = ({ text, subtext, icon, size }: ModalTitleProps) => {
  return (
    <div className='flex flex-col items-center justify-center text-center gap-4'>
      <IconWrapper icon={icon} />
      <Title size={size}>{text}</Title>
      <span className='text-sm text-semantic-neutral-text-subtle'>
        {subtext}
      </span>
    </div>
  )
}

const Title = classed('h1', {
  base: 'text-base font-semibold text-semantic-neutral-text-default',
  variants: {
    size: {
      large: 'text-2xl',
    },
  },
})
