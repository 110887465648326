import { useProjectStatusUpdater } from '~/areas/project/hooks'
import { StatusField, type StatusFieldProps } from '~/areas/task-project/fields'
import { useProject } from '~/global/hooks'

type ProjectStatusFieldProps = {
  projectId: string
  onChange?: StatusFieldProps['onChange']
}

export function ProjectStatusField({
  projectId,
  onChange: onChangeFromProps,
}: ProjectStatusFieldProps) {
  const updateProjectStatus = useProjectStatusUpdater()
  const project = useProject(projectId)

  if (project == null) {
    throw new Error('Project is required')
  }

  const onChange: StatusFieldProps['onChange'] = async (statusId) => {
    await updateProjectStatus(projectId, statusId)
    onChangeFromProps?.(statusId)
  }

  return (
    <StatusField
      value={project.statusId}
      onChange={onChange}
      workspaceId={project.workspaceId}
    />
  )
}
