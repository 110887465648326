import { priorityLabels } from '@motion/ui-logic'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { PriorityBadge } from '~/global/components/badges'
import { PriorityDropdown } from '~/global/components/dropdowns'

import { ModalFieldButton } from '../components'

export type PriorityFieldProps = {
  value: PriorityLevelSchema
  list: PriorityLevelSchema[]
  onChange: (value: PriorityLevelSchema) => void
  disabled?: boolean
}

export const PriorityField = ({
  value,
  list,
  onChange,
  disabled,
}: PriorityFieldProps) => {
  return (
    <PriorityDropdown
      items={list}
      selectedItem={value}
      onChange={(item) => {
        onChange(item)
      }}
    >
      <ModalFieldButton label='Priority' disabled={disabled}>
        <PriorityBadge value={value} hideTooltip />
        {priorityLabels.get(value)}
      </ModalFieldButton>
    </PriorityDropdown>
  )
}
