import { getBaseHeaders, HttpConnectionError } from '@motion/rpc'
import { MOTION_CLIENT_HEADER } from '@motion/shared/common'
import { getMotionClient } from '@motion/web-base/env'
import { Sentry } from '@motion/web-base/sentry'

import { getCurrentUserToken } from '~/utils/auth'
import { v4 as uuidv4 } from 'uuid'

export class HttpException extends Error {
  context?: Record<string, any>

  constructor(
    public readonly path: string,
    public readonly statusCode: number,
    public readonly statusMessage: string,
    public readonly reqId: string,
    public readonly error?: any,
    public readonly errorMessage?: string,
    public readonly extraParams?: Record<string, any>
  ) {
    super(
      errorMessage ||
        `${path}: ${statusCode} - ${statusMessage || 'Unknown Error'}`
    )
    this.context = {
      ...extraParams,
    }
  }
}

export const request = async (
  path: string,
  method: string,
  host: string = __BACKEND_HOST__,
  body?: Record<string, any> | string,
  stringify = true
) => {
  let stringifiedBody
  if (method.toLowerCase() !== 'get') {
    stringifiedBody = typeof body === 'string' ? body : JSON.stringify(body)
  }

  const token = await getCurrentUserToken()
  if (!token) {
    throw new Error('No user token provided for api call.')
  }

  const reqId = uuidv4()
  const headers = {
    ...getBaseHeaders({
      token,
      reqId,
      ...(stringify ? { contentType: 'json' } : {}),
    }),
    'x-motion-web-version': __SENTRY_RELEASE__,
    [MOTION_CLIENT_HEADER]: getMotionClient(),
  }

  const url = `${host}/${path}`

  const response = await fetch(url, {
    body: stringify && body ? stringifiedBody : (body as any),
    headers,
    method,
    credentials: 'include',
  }).catch((ex) => {
    throw new HttpConnectionError({ uri: path, method, cause: ex, reqId })
  })

  return parseResponse(response, { method, host, path, reqId })
}

export const parseResponse = async (
  response: Response,
  extra: {
    reqId?: string
    method?: string
    host?: string
    path?: string
  } = {}
) => {
  const { reqId = '', method, host, path = '' } = extra

  const url = new URL(path, host).toString()

  // Cover the case where an endpoint just returns a 204 with no JSON body
  if (response.status === 204) {
    try {
      const text = await response.text()
      if (!text) return {}

      if (text.startsWith('[') || text.startsWith('{')) {
        return JSON.parse(text)
      }

      return text || {}
    } catch (err) {
      Sentry.captureException(
        new Error('Failed to deserialize response', { cause: err }),
        {
          extra: { fetch: `${method} ${url}` },
          tags: {
            position: 'request',
          },
        }
      )
      return {}
    }
  }

  let json: any
  try {
    // .json can throw if malformed response body
    json = await response.json()
  } catch (err) {
    Sentry.captureException(
      new Error('Failed to deserialize response', { cause: err }),
      {
        extra: { fetch: `${method} ${url}` },
        tags: {
          position: 'request',
        },
      }
    )

    throw new HttpException(
      path,
      response.status,
      response.statusText,
      reqId,
      err instanceof Error ? err : undefined,
      typeof err === 'string' ? err : undefined,

      {
        fetch: `${method} ${url}`,
      }
    )
  }

  if (!response.ok) {
    throw new HttpException(
      path,
      response.status,
      response.statusText,
      reqId,
      json?.error,
      json?.message,
      {
        ...json,
        fetch: `${method} ${url}`,
      }
    )
  }

  return json
}
