import { formatAppend, formatPrepend } from './text-formatters'

export function updateTextWithVariable(
  text: string | null | undefined,
  operation: 'prepend' | 'append' | 'replace',
  wrappedKey: string,
  replacementKey: string | undefined
): string {
  if (text == null) {
    return ''
  }

  let trimmedText = text.trim()

  switch (operation) {
    case 'prepend':
      if (!trimmedText.startsWith(formatPrepend(wrappedKey))) {
        return formatPrepend(wrappedKey) + trimmedText
      }
      break
    case 'append':
      if (!trimmedText.endsWith(formatAppend(wrappedKey))) {
        return trimmedText + formatAppend(wrappedKey)
      }
      break
    case 'replace':
      if (replacementKey == null) {
        throw new Error('replacementKey is required for replace operation')
      }
      return trimmedText.replaceAll(wrappedKey, replacementKey)
  }

  return trimmedText
}
