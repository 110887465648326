import { MotionLogoSolid, type SvgIcon } from '@motion/icons'
import { classed } from '@motion/theme'

import { usePageData } from '~/areas/project-management/pages/pm-v3/routes'
import React, { forwardRef, type ReactNode } from 'react'

import { HeaderSection } from './shared-components'

export const HeaderTabs = () => {
  const { title } = usePageData()
  const activeTabTitle = title === 'Unknown' ? 'Motion' : title

  return (
    <HeaderSection className='-ml-px border-b'>
      <ul className='flex flex-row h-full !items-stretch max-w-full pt-1.5'>
        {/* TODO update icon based on page */}
        <Tab title={activeTabTitle} active icon={MotionLogoSolid} />
      </ul>
    </HeaderSection>
  )
}

type TabProps = {
  title?: ReactNode
  active?: boolean
  disabled?: boolean
  icon?: SvgIcon
  onClick?: () => void
}

const Tab = forwardRef<HTMLLIElement, TabProps>(function Tab(
  { title, active = false, disabled = false, icon: Icon, onClick },
  ref
) {
  return (
    <StyledTab
      active={active}
      disabled={disabled}
      iconOnly={!title}
      onClick={onClick}
      ref={ref}
    >
      {Icon != null && <Icon className='size-4 shrink-0' />}
      {title && <span className='truncate'>{title}</span>}
    </StyledTab>
  )
})

const StyledTab = classed('li', {
  base: 'relative inline-flex items-center overflow-hidden cursor-pointer text-sm font-medium electron-no-drag w-[250px]',
  variants: {
    active: {
      true: 'bg-semantic-neutral-surface-bg-default text-semantic-neutral-text-default -mb-0.5 pb-0.5 border border-semantic-neutral-border-default border-b-0 rounded-t-lg',
      false: 'text-semantic-neutral-text-subtle',
    },
    iconOnly: {
      true: 'basis-10 max-w-10 justify-center shrink-0',
      false: 'basis-[180px] max-w-[180px] pl-3 pr-2 gap-2',
    },
    disabled: {
      true: 'cursor-not-allowed',
      false: '',
    },
  },
  compoundVariants: [
    {
      active: false,
      disabled: false,
      class: 'hover:bg-semantic-neutral-bg-hover',
    },
  ],
  defaultVariants: {
    active: false,
    iconOnly: false,
  },
})
