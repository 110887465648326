import { CheckSolid } from '@motion/icons'
import { type TaskSchema } from '@motion/zod/client'

import { useAgendaCurrentTaskActions } from '~/areas/agenda/hooks'

import { CurrentTaskButton } from './current-task-button'

type StopAndCompleteButtonProps = {
  task: TaskSchema
}

export function StopAndCompleteButton({ task }: StopAndCompleteButtonProps) {
  const { stopAndComplete, taskUpdateInFlight } = useAgendaCurrentTaskActions({
    task,
  })

  return (
    <CurrentTaskButton
      sentiment='success'
      action='stop-and-complete'
      onClick={stopAndComplete}
      loading={taskUpdateInFlight}
    >
      <CheckSolid /> Stop and complete
    </CurrentTaskButton>
  )
}
