import { logEvent } from '@motion/web-base/analytics'
import { logInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import {
  type Action,
  type AnyAction,
  createAction,
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'
import type { RootState } from './types'

import { Events } from '../analyticsEvents'
import api from '../chromeApi/chromeApiContentScript'
import { persistSettings } from '../localServices/firebase/web'

const browserNotificationsHandler = getProxy('BrowserNotifications')

export interface MainState {
  backgroundLoaded: boolean
  showCompletedTasks: boolean
  showEventsInAgenda: boolean
  fetchErrorMessage?: string
  isBrowserNotificationsEnabled: boolean
  isMigrating: boolean
  // For duplicate windows on desktop
  isDesktopSubWindow: boolean
}

const initialState: Readonly<MainState> = {
  backgroundLoaded: __IS_EXTENSION__,
  isBrowserNotificationsEnabled: false,
  isMigrating: false,
  isDesktopSubWindow: false,
  showCompletedTasks: true,
  showEventsInAgenda: true,
}

interface RejectedAction extends Action {
  error: Error
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected')
}

export const clearFetchErrorMessage = createAction<void>(
  'main/clearFetchErrorMessage'
)

export const enableBrowserNotifications = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>('main/checkBrowserNotificationStatus', async (): Promise<boolean> => {
  if (Notification.permission === 'granted') {
    logInDev('Browser notifications already enabled')
    return browserNotificationsHandler.setEnableNotifications(true)
  }

  try {
    const permission = await Notification.requestPermission()
    logInDev('Notification.requestPermission: Got permission', permission)

    if (permission === 'granted') {
      void logEvent(Events.BROWSER_NOTIFICATIONS_ENABLED)
      return browserNotificationsHandler.setEnableNotifications(true)
    }
    return false
  } catch (e) {
    logInDev('Could not get permission', { e })
    return false
  }
})

// Saves the setting to firebase for the new calendar footer toggle.
// Should be replaced with a backend call.
export const persistShowCompletedTasks = createAsyncThunk(
  'main/saveShowCompletedTasks',
  async ({ showCompletedTasks }: { showCompletedTasks: boolean }) => {
    return await persistSettings({ showCompletedTasks })
  }
)

export const handleAutoRescheduleState = createAsyncThunk(
  'main/handleAutoRescheduleState',
  async () => {
    return await api.runtime.sendMessage({ event: 'handleAutoRescheduleState' })
  }
)

export const mainSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(enableBrowserNotifications.fulfilled, (state, action) => {
      state.isBrowserNotificationsEnabled = action.payload
    })

    builder.addCase(clearFetchErrorMessage, (state) => {
      state.fetchErrorMessage = ''
    })
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.fetchErrorMessage = action.error.message
    })
  },
  initialState,
  name: 'main',
  reducers: {
    reset: () => ({ ...initialState, backgroundLoaded: true }),
    setBackgroundLoaded: (state: MainState, action: PayloadAction<boolean>) => {
      Sentry.addBreadcrumb({
        message: `setBackgroundLoaded: ${action.payload}`,
      })
      state.backgroundLoaded = action.payload
    },
    setIsDesktopSubWindow: (
      state: MainState,
      action: PayloadAction<boolean>
    ) => {
      state.isDesktopSubWindow = action.payload
    },
    setShowCompletedTasks: (
      state: MainState,
      action: PayloadAction<boolean>
    ) => {
      state.showCompletedTasks = action.payload
    },
    setShowEventsInAgenda: (
      state: MainState,
      action: PayloadAction<boolean>
    ) => {
      state.showEventsInAgenda = action.payload
    },
  },
})

export const {
  reset,
  setBackgroundLoaded,
  setIsDesktopSubWindow,
  setShowCompletedTasks,
  setShowEventsInAgenda,
} = mainSlice.actions

export const selectBackgroundLoaded = (state: RootState) =>
  state.main.backgroundLoaded
export const selectIsBrowserNotificationsEnabled = (state: RootState) =>
  state.main.isBrowserNotificationsEnabled
export const selectIsMigrating = (state: RootState) => state.main.isMigrating
export const selectShowAgendaEvents = (state: RootState) =>
  state.main.showEventsInAgenda

export const selectIsDesktopSubWindow = (state: RootState) =>
  state.main.isDesktopSubWindow

export const mainReducer = mainSlice.reducer
