import { useClosure } from '@motion/react-core/hooks'
import { isRecurringTaskParent } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TaskSchema } from '@motion/zod/client'

import { useProjectModalUrl } from '~/global/navigation'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

type CreateProjectFromTaskArgs = {
  onNavigateAway?: () => Promise<void> | void
}

export function useCreateProjectFromTask({
  onNavigateAway = () => {},
}: CreateProjectFromTaskArgs) {
  const navigate = useNavigate()
  const buildProjectModalUrl = useProjectModalUrl()

  const onNavigateAwayClosure = useClosure(onNavigateAway)

  return useCallback(
    async (task: TaskSchema) => {
      const isRecurring = isRecurringTaskParent(task)
      const isRecurringInstance = task.type === 'RECURRING_INSTANCE'

      if (isRecurring || isRecurringInstance) return

      recordAnalyticsEvent('PROJECT_MANAGEMENT_CLICK_CREATE_PROJECT_FROM_TASK')

      await onNavigateAwayClosure()
      navigate(
        buildProjectModalUrl({
          project: 'create',
          forTaskId: task.id,
        })
      )
    },
    [buildProjectModalUrl, navigate, onNavigateAwayClosure]
  )
}
