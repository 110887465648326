import { FilledChevronDownSolid } from '@motion/icons'
import { Button, ButtonGroup } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { SelectedMenuDropdown } from './dropdowns'
import { FieldsSection } from './field-section'
import { Content, Header, NumSelected, Wrapper } from './styled'

import { type BulkOpsValue } from '../contexts'
import {
  useBulkOpsCanSave,
  useBulkOpsConfig,
  useBulkUpdateFields,
} from '../hooks'

export type BulkOpsSectionProps = {
  selectedIds: BulkOpsValue['selectedIds']
  onCancel: () => void
  renderCoreFields: () => ReactNode
  renderCustomFields: () => ReactNode
}

export function BulkOpsSection({
  selectedIds,
  onCancel,
  renderCoreFields,
  renderCustomFields,
}: BulkOpsSectionProps) {
  const config = useBulkOpsConfig()
  const canSave = useBulkOpsCanSave()
  const sendBulkUpdate = useBulkUpdateFields()

  return (
    <Wrapper>
      <Header>
        <ButtonGroup>
          <SelectedMenuDropdown>
            <Button sentiment='neutral' variant='muted' size='small'>
              <NumSelected>{selectedIds.length}</NumSelected> selected
              <FilledChevronDownSolid />
            </Button>
          </SelectedMenuDropdown>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            sentiment='primary'
            variant='solid'
            size='small'
            disabled={!canSave}
            onClick={() => sendBulkUpdate()}
          >
            Save
          </Button>
        </ButtonGroup>
      </Header>
      <Content>
        {config.showCoreFields && (
          <FieldsSection name='Core fields'>{renderCoreFields()}</FieldsSection>
        )}
        {config.showCustomFields && (
          <>
            <div className='h-px bg-semantic-neutral-border-subtle' />
            <FieldsSection name='Custom fields'>
              {renderCustomFields()}
            </FieldsSection>
          </>
        )}
      </Content>
    </Wrapper>
  )
}
