import { type ClientFirebaseSettingsDto } from '@motion/rpc-types'
import {
  useSaveFirebaseSettings,
  useSettings,
} from '@motion/web-common/settings'

import { useCallback } from 'react'

import {
  type PersistedConferenceSettingsState,
  type PersistedConferenceSettingsStateUpdate,
} from '../types'

export const usePersistedConferenceSettingsStateFirestore = () => {
  const { mutateAsync: saveToFirebase } = useSaveFirebaseSettings()

  const data: PersistedConferenceSettingsState | undefined = useSettings([
    'conferenceSettings',
    'reminderSettings',
    'zoomAccessToken',
    'zoomExpiration',
    'zoomRefreshToken',
    'zoomNeedsRefresh',
  ])

  const update = useCallback(
    (data: PersistedConferenceSettingsStateUpdate) =>
      saveToFirebase(data as Partial<ClientFirebaseSettingsDto>),
    [saveToFirebase]
  )

  return [data, update] as const
}
