import {
  type FlowTemplateFormTask,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'
import { createLookupById, createLookupByKey } from '@motion/utils/object'

import { useCallback, useMemo } from 'react'

import { useFlowTemplateForm } from './use-flow-template-form'

export type UseTemplateTaskReturn = {
  all: FlowTemplateFormTask[]
  byId: Record<string, FlowTemplateFormTask>
  ids: string[]
  blockedById: Record<string, string>
  blockingById: Record<string, string[]>
}

export const useTemplateTasks = (): UseTemplateTaskReturn => {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const getTemplateTasks = useTemplateTasksFn()
  const stages = watch('stages')

  return useMemo(() => getTemplateTasks(stages), [getTemplateTasks, stages])
}

export const useTemplateTasksFn = (): ((
  stages: FlowTemplateStage[]
) => UseTemplateTaskReturn) => {
  return useCallback((stages) => {
    const allTasks = stages.flatMap((stage) => stage.tasks)
    const allTaskIds = allTasks.map((task) => task.id)

    const onlyBlocked = allTasks.filter((task) => task.blockedByTaskId != null)
    const blockedById = createLookupByKey(
      onlyBlocked,
      'id',
      (task) => task.blockedByTaskId as string
    )

    const blockingById = Object.entries(blockedById).reduce(
      (result, [blockedId, blockingId]) => {
        result[blockingId] ||= []
        result[blockingId].push(blockedId)
        return result
      },
      {} as Record<string, string[]>
    )

    const byId = createLookupById(allTasks)

    return {
      all: allTasks,
      byId,
      ids: allTaskIds,
      blockedById,
      blockingById,
    }
  }, [])
}
