import { getCacheEntryValue, MotionCache } from '@motion/rpc-cache'

import { type QueryClient } from '@tanstack/react-query'
import { getProjectQueryFilters } from '~/global/cache/models/project'

import { type ExtractSocketEvent } from '../listeners/types'

export function cacheUpdateOnProjectStatsUpdate(
  client: QueryClient,
  projectPayload: ExtractSocketEvent<'workspace.project.stats.update'>['data']['project']
): boolean {
  const {
    id,
    completedDuration,
    duration,
    canceledDuration,
    completedTaskCount,
    taskCount,
    canceledTaskCount,
  } = projectPayload
  const project = getCacheEntryValue(client, 'projects', id)
  if (!project) {
    return false
  }

  const newProject = {
    ...project,
    completedDuration,
    duration,
    canceledDuration,
    completedTaskCount,
    taskCount,
    canceledTaskCount,
  }

  MotionCache.patch(client, getProjectQueryFilters(id), 'projects', {
    [id]: newProject,
  })

  return true
}
