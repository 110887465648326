import { type TaskSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useBulkUpdateTasks } from './use-bulk-update-tasks'

export function useCompleteTaskBlockers() {
  const readTask = useReadTaskFn()
  const bulkUpdate = useBulkUpdateTasks()
  const { getWorkspaceCompletedStatus } = useWorkspaceFns()

  return useCallback(
    async (taskOrId: TaskSchema | TaskSchema['id']) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the status of a Chunk task')
      }
      if (
        task.type === 'RECURRING_INSTANCE' ||
        task.type === 'RECURRING_TASK'
      ) {
        throw new Error('Cannot complete blockers of a recurring task')
      }

      if (task.blockedByTaskIds.length === 0) {
        return false
      }

      await bulkUpdate([
        {
          currentWorkspaceId: task.workspaceId,
          taskIds: task.blockedByTaskIds,
          update: {
            type: 'bulk-field-update',
            statusId: getWorkspaceCompletedStatus(task.workspaceId).id,
          },
        },
      ])

      return true
    },
    [bulkUpdate, getWorkspaceCompletedStatus, readTask]
  )
}
