import { type BulkUpdateSchema, type TaskDeadlineType } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useCallback } from 'react'

import { type ActionArgs } from './types'
import { useBulkUpdateRows } from './use-bulk-update-rows'

export const useUpdateAllToSelectedDate = (args: ActionArgs) => {
  const { type, ...rest } = args
  const bulkUpdateRows = useBulkUpdateRows()
  const updateTask = useTaskUpdater()

  return useCallback(
    (selectedDay: string, deadlineType?: TaskDeadlineType) => {
      recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_EXTEND_TO_SELECTED_DATE', {
        type,
        numTasks: rest.tasks.length,
      })

      if (rest.tasks.length === 1) {
        return updateTask(rest.tasks[0], {
          dueDate: selectedDay,
          deadlineType,
        })
      }

      let updates: BulkUpdateSchema = {
        type: 'bulk-field-update',
        dueDate: selectedDay,
      }

      if (deadlineType) {
        updates = {
          ...updates,
          deadlineType,
        }
      }

      return bulkUpdateRows({
        ...rest,
        updates,
      })
    },
    [type, rest, bulkUpdateRows, updateTask]
  )
}
