import { Tag } from '@motion/ui/base'
import { type GROUP_BY_DATE_OPTIONS } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { type DateRow } from '../tree-list'
import { type ViewStateGroupByField } from '../view-state'

export function DateGroupCurrentTag({
  item,
}: {
  item: DateRow & ViewStateGroupByField
}) {
  if (item.by == null) return null

  const groupDateRange = getTimeRangeForDateGroup(item.value, item.by)
  const currentTime = DateTime.now()

  if (currentTime < groupDateRange.start || currentTime > groupDateRange.end)
    return null

  return (
    <Tag color='blue' size='small'>
      Current
    </Tag>
  )
}

const getTimeRangeForDateGroup = (
  value: string | DateTime,
  groupBy: GROUP_BY_DATE_OPTIONS
) => {
  const end = (
    DateTime.isDateTime(value) ? value : DateTime.fromISO(value)
  ).endOf('day')

  switch (groupBy) {
    case 'day':
    case 'week':
    case 'month':
    case 'quarter':
    case 'year':
      return {
        start: end.startOf(groupBy),
        end: end,
      }
    case 'half':
      return {
        start: end.minus({ months: 6 }),
        end: end,
      }
    default: {
      return {
        start: end,
        end: end,
      }
    }
  }
}
