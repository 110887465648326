import { type SVGProps } from 'react'

/**
 * @deprecated use `CheckSolid`
 */
export function CheckmarkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={11}
      height={10}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='m.767 4.91 2.961 3.384L10.242.849'
        stroke='currentColor'
        strokeWidth={1.353}
      />
    </svg>
  )
}
