import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { EventResult } from './event-result'

import { useOpenSearchEvent } from '../../hooks'
import { useSearchContext } from '../../search-context'
import { type CalendarEventSearchSchema } from '../../utils'

export interface ConnectedEventResultProps {
  item: CalendarEventSearchSchema
  active: boolean
  onItemClick: () => void
}

export function ConnectedEventResult({
  item,
  onItemClick,
}: ConnectedEventResultProps) {
  const { activeCalendars } = useSearchContext()
  const openSearchEvent = useOpenSearchEvent()

  const goToEvent = useCallback(() => {
    openSearchEvent({
      providerId: item.providerId,
      start: item.start,
      end: item.end,
    })
  }, [item.end, item.providerId, item.start, openSearchEvent])

  const handleClick = async () => {
    recordAnalyticsEvent('SEARCH_RESULT_OPENED', {
      type: item.recurringEventId ? 'recurring_event' : 'event',
    })

    try {
      goToEvent()
      onItemClick()
    } catch (e) {
      showErrorToast(e)
    }
  }

  return (
    <EventResult
      activeCalendars={activeCalendars}
      item={item}
      onClick={handleClick}
    />
  )
}
