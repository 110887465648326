import { useForceUpdate } from '@motion/react-core/hooks'

import { useMemo, useState } from 'react'

export const LabelWidthCache = new Map<string, number>()
const GAP_BETWEEN_LABELS = 4

type UseCalculatePrettyLabelsProps<
  T extends {
    id: string
    name?: string
  },
> = {
  values: T[]
  labelWidth?: number
}

export const useCalculatePrettyLabels = <
  T extends { id: string; name?: string },
>({
  values,
  labelWidth,
}: UseCalculatePrettyLabelsProps<T>) => {
  const [container, setContainer] = useState<HTMLSpanElement | null>(null)
  const refreshLabels = useForceUpdate()

  const containerWidth = container?.getBoundingClientRect().width ?? 0

  const { ids } = values.reduce<{ maxWidth: number; ids: string[] }>(
    (acc, v) => {
      const currentLabelWidth =
        labelWidth ?? LabelWidthCache.get(v.name ?? v.id) ?? 0
      const potentialNewWidth =
        acc.maxWidth + currentLabelWidth + GAP_BETWEEN_LABELS

      // Default 30 for overflow
      // TODO compute the real size for the overflow element (30 should be enough for 2 digits though)
      if (potentialNewWidth <= containerWidth - (labelWidth ?? 30)) {
        return {
          maxWidth: potentialNewWidth,
          ids: [...acc.ids, v.id],
        }
      }
      return acc
    },
    { maxWidth: 0, ids: [] }
  )

  const usedLabels = useMemo(() => {
    // No labels fit within the container width
    // So we pick the first label that we'll truncate

    if (ids.length === 0 && values.length > 0) {
      return values.slice(0, 1)
    }
    // All labels are visible
    if (ids.length === values.length) {
      return values
    }

    return values.filter((v) => ids.includes(v.id))
  }, [values, ids])

  const nbOverflow = values.length - usedLabels.length

  return { refreshLabels, usedLabels, nbOverflow, setContainer }
}
