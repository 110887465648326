import { createAsyncThunk } from '@reduxjs/toolkit'

import { type UploadImageDto } from '../../services/workspacesService'
import { getProxy } from '../backgroundProxy'

const THUNK_PREFIX = 'projectManagement/workspace'

const workspacesService = getProxy('WorkspacesService')

export const uploadWorkspaceImage = createAsyncThunk(
  `${THUNK_PREFIX}/image/create`,
  async (uploadImageProps: UploadImageDto) => {
    return workspacesService.uploadImage(uploadImageProps)
  }
)
