import {
  type Active,
  type ClientRect,
  closestCenter,
  type Collision,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  type UniqueIdentifier,
} from '@dnd-kit/core'
import { type DroppableContainer, type RectMap } from '@dnd-kit/core/dist/store'
import { type Coordinates } from '@dnd-kit/utilities'

import { pastVerticalCenter } from '../../../../../../../utils/pm-revamp/collision-detection-utils'
import { type GroupedNode, type Tree } from '../../../grouping'

export const isContainer = <T extends GroupedNode>(
  tree: Tree<T>,
  id: UniqueIdentifier
) => {
  return tree.children.some((item) => item.qualifiedKey === id)
}
export const findContainer = <T extends GroupedNode>(
  tree: Tree<T>,
  id: UniqueIdentifier
): Tree<T> | undefined => {
  const containerIndex = tree.children.findIndex(
    (item) => item.qualifiedKey === id
  )

  // If container is found, return it
  if (containerIndex > -1) {
    return tree.children[containerIndex]
  }

  // Return the key of the container the item is in
  const containers = tree.children.filter((group) => {
    return group.values.findIndex((item) => item?.qualifiedKey === id) > -1
  })

  return containers[0]
}

export const collisionDetectionStrategy = <T extends GroupedNode>({
  tree,
  lastOverId,
  activeId,
  ...args
}: {
  tree: Tree<T>
  lastOverId: React.MutableRefObject<UniqueIdentifier | null>
  activeId: UniqueIdentifier | null
  active: Active
  collisionRect: ClientRect
  droppableRects: RectMap
  droppableContainers: DroppableContainer[]
  pointerCoordinates: Coordinates | null
}): Collision[] => {
  const groups = tree.children

  const containerIds = groups.map((item) => item.key)

  // If we're dragging a container, return the closest container
  if (activeId && containerIds.includes(activeId as string)) {
    const collisionFunc = pastVerticalCenter

    return collisionFunc({
      ...args,
      droppableContainers: args.droppableContainers.filter((container) =>
        groups.some((i) => i.key === container.id)
      ),
    })
  }

  const pointerIntersections = pointerWithin(args)

  // If there are droppables intersecting with the pointer, return those
  const intersections =
    pointerIntersections.length > 0
      ? pointerIntersections
      : rectIntersection(args)

  let overId = getFirstCollision(intersections, 'id')

  if (overId != null) {
    if (groups.some((i) => overId === i.key)) {
      const containerItems = tree.children.find(
        (i) => i.key === overId
      )?.children

      if (containerItems && containerItems.length > 0) {
        overId = closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) =>
              container.id !== overId &&
              containerItems.some((i) => i.qualifiedKey === container.id)
          ),
        })[0]?.id
      }
    }

    lastOverId.current = overId
    return [{ id: overId }]
  }

  // If no droppable is matched, return the last match
  return lastOverId.current ? [{ id: lastOverId.current }] : []
}
