import {
  EnterpriseBillingPlanCard,
  IndividualBillingPlanCard,
  TeamBillingPlanCard,
} from '@motion/web-billing'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { markExposure, useHasTreatment } from '@motion/web-common/flags'

import {
  INDIVIDUAL_DEFAULT_TRIAL_LENGTH,
  TEAM_DEFAULT_TRIAL_LENGTH,
} from '~/areas/onboarding/constants'
import { useEffect } from 'react'

interface ChoosePlanProps {
  onSelectIndividual: () => void
  onSelectTeam: () => void
  teamSavings: number
  teamAnnualPricePerMonth: number
}

export function ChoosePlan({
  onSelectIndividual,
  onSelectTeam,
  teamSavings,
  teamAnnualPricePerMonth,
}: ChoosePlanProps) {
  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')
  const shouldShowPrices = useHasTreatment('show-prices-on-plan-selection-page')
  const shouldHidePrices = !shouldShowPrices || shouldUseNoCcTrial

  const user = useAuthenticatedUser()

  useEffect(function trackExposure() {
    markExposure('show-prices-on-plan-selection-page')
    markExposure('no-cc-combined-trial')
  }, [])

  return (
    <div className='flex h-full w-full pt-20 justify-center'>
      <div className='w-full px-6'>
        <h1 className='text-light-1200 mb-2 lg:mb-6 text-center text-2xl lg:text-[32px] font-bold leading-[42px] select-none'>
          Welcome to Motion!
        </h1>
        <p className='text-light-1100 max-w-[288px] lg:max-w-full mx-auto lg:mx-0 mb-6 lg:mb-12 text-center text-base font-normal select-none'>
          Do you plan on using Motion by yourself, or with your team?
        </p>
        <div className='flex gap-4 items-stretch flex-wrap justify-center'>
          <IndividualBillingPlanCard
            onClick={onSelectIndividual}
            buttonText={
              shouldUseNoCcTrial
                ? `Start ${INDIVIDUAL_DEFAULT_TRIAL_LENGTH}-day trial as Individual`
                : ''
            }
            hidePrice={shouldHidePrices}
          />
          <TeamBillingPlanCard
            onClick={onSelectTeam}
            buttonText={
              shouldUseNoCcTrial
                ? `Start ${TEAM_DEFAULT_TRIAL_LENGTH}-day trial as Team`
                : ''
            }
            hidePrice={shouldHidePrices}
            savings={teamSavings}
            price={teamAnnualPricePerMonth}
          />
          <EnterpriseBillingPlanCard
            hidePrice={shouldHidePrices}
            location='onboardingChoosePlan'
            email={user.email}
            displayName={user.displayName ?? undefined}
          />
        </div>
      </div>
    </div>
  )
}
