import { isEmailValid } from '@motion/utils/string'

import { ContactsAutocomplete } from '~/components/contacts-autocomplete'
import { useCallback, useContext, useState } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const EventGuestsRow = () => {
  const {
    template: {
      state: { guests },
      setters: { setGuests },
    },
  } = useContext(BookingTemplateContext)

  const [error, setError] = useState(false)

  const guestsSelectHandler = useCallback(
    (emails: string[]) => {
      if (emails.length && !isEmailValid(emails[emails.length - 1])) {
        setError(true)
        setTimeout(() => void setError(false), 5_000)

        return
      }

      setError(false)
      setGuests(emails)
    },
    [setGuests]
  )

  return (
    <SectionRow
      title='Guests'
      subtitle='Add others to the event after guest books a time (note: this will not check for their conflicts - use Attendance requirements above to check for conflicts)'
    >
      <div className='space-y-2'>
        <ContactsAutocomplete
          teamValues={guests}
          contactsSource='general'
          teamSelectHandler={guestsSelectHandler}
          placeholder='Search email'
          allowSelectQuery
          className='[&_input]:w-min'
        />

        {error ? (
          <p className='text-semantic-error-text-default'>
            Please enter a valid email address
          </p>
        ) : null}
      </div>
    </SectionRow>
  )
}
