import { createPlaceholderId } from '@motion/shared/identifiers'
import { uniqueId } from '@motion/utils/core'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { isRoleVariable, isTextVariable } from '~/areas/flows/utils'

import { DEFAULT_TEXT_VARIABLES } from '../../utils'

export function useInitialVariables(
  projectDefinition: ProjectDefinitionSchema | null,
  isCreatingFromTemplate: boolean
) {
  const roles = projectDefinition?.variables.filter(isRoleVariable) ?? []
  const userTextVariables =
    projectDefinition?.variables.filter(isTextVariable) ?? []
  const textVariables = [
    ...DEFAULT_TEXT_VARIABLES.filter(
      (defaultVar) => !userTextVariables.some((v) => v.key === defaultVar.key)
    ),
    ...userTextVariables,
  ]

  if (isCreatingFromTemplate) {
    return {
      roles: roles.map((role) => ({
        ...role,
        id: createPlaceholderId(uniqueId('role')),
      })),
      textVariables: textVariables.map((textVariable) => ({
        ...textVariable,
        id: createPlaceholderId(uniqueId('text-variable')),
      })),
    }
  }

  return {
    roles,
    textVariables,
  }
}
