import { PencilSolid, PlusSolid, TrashSolid } from '@motion/icons'
import { type Schedule } from '@motion/rpc-types'
import { templateStr } from '@motion/ui-logic'
import { logEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useCallback, useMemo } from 'react'

import { Events } from '../../../../analyticsEvents'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import {
  deleteSchedule,
  selectSchedules,
  setSchedules,
} from '../../../../state/settingsSlice'
import { sortSchedules } from '../../../../utils/settingsUtils'
import { Paragraph, TextButton } from '../../../Common'
import { SettingList } from '../../Components/SettingList/SettingList'
import { SettingPage } from '../../Components/SettingPage/SettingPage'

interface ScheduleSettingsProps {
  openScheduleModal: (scheduleId: string | undefined) => void
}

export const ScheduleSettings = ({
  openScheduleModal = () => void 0,
}: ScheduleSettingsProps) => {
  const dispatch = useAppDispatch()
  const schedules = useAppSelector(selectSchedules)
  const modalApi = useModalApi()

  const sortedSchedules: { id: string; schedule: Schedule }[] = useMemo(() => {
    return sortSchedules(schedules)
  }, [schedules])

  const deleteScheduleHandler = useCallback(
    async (deleteId: string) => {
      if (deleteId === 'work') {
        return
      }
      const tempSchedules = { ...schedules } as any
      delete tempSchedules[deleteId]

      await dispatch(setSchedules(tempSchedules))
      await dispatch(deleteSchedule(deleteId))

      void logEvent(Events.SCHEDULE_DELETE)
    },
    [dispatch, schedules]
  )

  const confirmDeleteSchedule = (deleteId: string, scheduleName: string) => {
    void modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-schedule',
      },
      confirmButtonText: 'Delete schedule',
      destructive: true,
      async onValue(value) {
        if (value) {
          await deleteScheduleHandler(deleteId)
        }
      },
      title: `You are about to delete a custom schedule`,
      description: templateStr(
        'Any tasks that are currently using the schedule {{scheduleName}} will now be using the Work hours default schedule.',
        {
          scheduleName: <span className='font-semibold'>{scheduleName}</span>,
        }
      ),
    })
  }

  return (
    <SettingPage title='Schedules' className='gap-3'>
      <SettingList
        items={sortedSchedules.map(({ id, schedule }) => (
          <div key={id} className='flex w-full justify-between p-4'>
            <Paragraph className='truncate text-sm'>{schedule.title}</Paragraph>
            <div className='flex gap-3.5'>
              <TextButton
                icon={PencilSolid}
                onClick={() => {
                  openScheduleModal(id)
                }}
              />
              {id !== 'work' && (
                <TextButton
                  icon={TrashSolid}
                  onClick={() => confirmDeleteSchedule(id, schedule.title)}
                />
              )}
            </div>
          </div>
        ))}
      />
      <TextButton
        className='dark:text-dark-100 text-light-1200'
        icon={PlusSolid}
        onClick={() => {
          openScheduleModal(undefined)
        }}
      >
        New schedule
      </TextButton>
    </SettingPage>
  )
}
