import { Button, type ButtonProps } from '@motion/ui/base'
import {
  formatTime,
  formatToReadableWeekDayMonth,
  handleEndDateChange,
  handleStartDateChange,
  handleStartTimeChange,
} from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { useCalendarStartDay } from '~/areas/calendar/hooks'
import { SearchableTimeDropdown } from '~/areas/project-management/components/dropdowns'
import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { type ComponentRef, forwardRef } from 'react'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventDateTimeFields = () => {
  const calendarStartDay = useCalendarStartDay()
  const modalState = useEventModalState()
  const { form } = useEventForm()
  const { control, watch } = form

  const { field: startField } = useController({
    name: 'start',
    control,
  })
  const { field: endField } = useController({
    name: 'end',
    control,
  })

  if (modalState.isLoading) return null

  const { isReadOnly } = modalState

  const isAllDay = watch('isAllDay')

  const onChange = async (start: string, end: string) => {
    startField.onChange(start)
    endField.onChange(end)
  }

  const startDateTime = parseDate(startField.value)
  const startOfTheDay = startDateTime.startOf('day')

  const endDateTime = isAllDay
    ? parseDate(endField.value).minus({ days: 1 })
    : parseDate(endField.value)
  const endDateTimeISO = endDateTime.toISO()

  const startTimeFormatted = formatTime(startField.value)
  const endTimeFormatted = formatTime(endField.value)

  const showEndDateField =
    isAllDay || !startDateTime.hasSame(endDateTime, 'day')
  const showTimeFields = !isAllDay

  return (
    <div className='flex gap-1.5 items-center'>
      <SimpleDateDropdown
        value={startField.value}
        calendarStartDay={calendarStartDay}
        disabledDate={() => false}
        onChange={(value) => {
          if (!value) return

          const { start, end } = handleStartDateChange(parseDate(value), {
            start: startField.value,
            end: endField.value,
            isAllDay,
          })

          onChange(start, end)
        }}
      >
        <MaybeReadOnlyButton
          size='small'
          variant='outlined'
          sentiment='neutral'
          aria-label='Start event date'
          readOnly={isReadOnly}
        >
          {formatToReadableWeekDayMonth(startField.value)}
        </MaybeReadOnlyButton>
      </SimpleDateDropdown>
      {showTimeFields && (
        <SearchableTimeDropdown
          startDateTime={startOfTheDay}
          value={startTimeFormatted}
          onChange={(value) => {
            const { start, end } = handleStartTimeChange(value, {
              start: startField.value,
              end: endField.value,
            })
            onChange(start, end)
          }}
        >
          <MaybeReadOnlyButton
            size='small'
            variant='outlined'
            sentiment='neutral'
            aria-label='Start event time'
            readOnly={isReadOnly}
          >
            {startTimeFormatted}
          </MaybeReadOnlyButton>
        </SearchableTimeDropdown>
      )}
      <span className='text-field-text-default'>-</span>
      {showEndDateField && (
        <SimpleDateDropdown
          value={endDateTimeISO}
          calendarStartDay={calendarStartDay}
          disabledDate={() => false}
          onChange={(value) => {
            if (!value) return

            const { start, end } = handleEndDateChange(parseDate(value), {
              start: startField.value,
              end: endDateTimeISO,
              isAllDay,
            })

            onChange(start, end)
          }}
        >
          <MaybeReadOnlyButton
            size='small'
            variant='outlined'
            sentiment='neutral'
            aria-label='End event date'
            readOnly={isReadOnly}
          >
            {formatToReadableWeekDayMonth(endDateTime)}
          </MaybeReadOnlyButton>
        </SimpleDateDropdown>
      )}
      {showTimeFields && (
        <SearchableTimeDropdown
          startDateTime={startDateTime}
          value={endTimeFormatted}
          showDuration
          onChange={(value) => {
            endField.onChange(value.toUTC().toISO())
          }}
        >
          <MaybeReadOnlyButton
            size='small'
            variant='outlined'
            sentiment='neutral'
            aria-label='End event time'
            readOnly={isReadOnly}
          >
            {endTimeFormatted}
          </MaybeReadOnlyButton>
        </SearchableTimeDropdown>
      )}
    </div>
  )
}

type MaybeReadOnlyButtonProps = ButtonProps & {
  readOnly?: boolean
}

const MaybeReadOnlyButton = forwardRef<
  ComponentRef<typeof Button>,
  MaybeReadOnlyButtonProps
>(function MaybeReadOnlyButton({ readOnly, children, ...buttonProps }, ref) {
  if (readOnly) {
    return (
      <span
        ref={ref}
        className='border border-transparent text-field-text-default'
      >
        {children}
      </span>
    )
  }

  return (
    <Button ref={ref} {...buttonProps}>
      {children}
    </Button>
  )
})
