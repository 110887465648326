import { useProjectNameUpdater } from '~/areas/project/hooks'
import { NameField, type NameFieldProps } from '~/areas/task-project/fields'
import { NAME_FIELD_RULE } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledNameField = () => {
  const updateProjectName = useProjectNameUpdater()
  const { form } = useProjectForm()

  const { control, watch } = form

  const {
    field,
    formState: { errors },
  } = useController({
    name: 'name',
    control,
    rules: NAME_FIELD_RULE,
  })
  const id = watch('id')

  const onChange: NameFieldProps['onChange'] = async (value) => {
    if (id != null) {
      const response = await updateProjectName(id, value)
      if (response) {
        form.setValue('name', value)
      }
    } else {
      field.onChange(value)
    }
  }

  return (
    <NameField
      triggerOnChangeOnBlur={id != null}
      error={errors.name != null}
      placeholder='Project title'
      label='Project title'
      value={field.value}
      onChange={onChange}
    />
  )
}
