import {
  useNextEventBrowserNotification,
  useTodaysScheduledEntities,
} from '~/global/upcoming-event/hooks'
import { getNextEvent } from '~/global/upcoming-event/utils'

import {
  UpcomingCalendarEvent,
  UpcomingEmptyEvent,
  UpcomingTaskEvent,
} from './components'

export const UpcomingEvent = () => {
  const upcomingEvents = useTodaysScheduledEntities()
  const nextEvent = getNextEvent(upcomingEvents, {
    onlyToday: true,
  })

  useNextEventBrowserNotification()

  if (nextEvent?.type === 'TASK' || nextEvent?.type === 'CHUNK') {
    return <UpcomingTaskEvent entity={nextEvent} />
  }

  if (nextEvent?.type === 'EVENT') {
    return <UpcomingCalendarEvent entity={nextEvent} />
  }

  return <UpcomingEmptyEvent />
}
