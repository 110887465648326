import { type ConferenceSettings } from '../../types/settingsTypes'

export const isZoomReady = (
  conferenceSettings: ConferenceSettings | undefined,
  zoomNeedsRefresh: boolean
) => {
  if (!conferenceSettings) return false

  const isZoomAuthUpToDate = !zoomNeedsRefresh

  const hasValidZoomSettings =
    conferenceSettings.zoomAccount ||
    (conferenceSettings.zoomLinkType === 'manual' &&
      conferenceSettings.zoomManualLink)

  return isZoomAuthUpToDate && hasValidZoomSettings
}
