import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useEffect } from 'react'

import { type CancelFlowScreen } from './types'

export const useCancellationAnalytics = (screen: CancelFlowScreen) => {
  useEffect(() => {
    switch (screen) {
      case 'before_you_go':
        recordAnalyticsEvent('CANCELLATION_INTERCEPTION_BEFORE_YOU_GO')
        break
      case 'cancellation_reason':
        recordAnalyticsEvent('CANCELLATION_INTERCEPTION_REASON')
        break
      case 'discount':
        recordAnalyticsEvent('CANCELLATION_INTERCEPTION_DISCOUNT')
        break
      case 'discount_success':
        recordAnalyticsEvent('CANCELLATION_INTERCEPTION_DISCOUNT_SUCCESS')
        break
      case 'final_interception':
        recordAnalyticsEvent('CANCELLATION_INTERCEPTION_FINAL_INTERCEPTION')
        break
      case 'cancellation_confirmation':
        recordAnalyticsEvent('CANCELLATION_INTERCEPTION_CONFIRM_CANCELLATION')
        break
      default:
    }
  }, [screen])
}
