import { useTheme } from '@motion/theme/dom'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import {
  NoCalendarIllustrationDark,
  NoCalendarIllustrationLight,
} from './illustrations'

type NoCalendarErrorStateProps = {
  close: () => void
}

export function NoCalendarErrorState({ close }: NoCalendarErrorStateProps) {
  const ErrorIcon = useNoCalendarIllustration()
  const modalApi = useModalApi()

  return (
    <div className='grid w-full h-full place-items-center text-semantic-neutral-text-default'>
      <div className='flex flex-col gap-4 text-center min-w-[50%] m-auto'>
        <ErrorIcon className='m-auto size-[140px]' />
        <p>Link a calendar to create an event</p>
        <div>
          <Button
            variant='solid'
            sentiment='primary'
            onClick={() => {
              close()

              modalApi.open('link-calendars')
              recordAnalyticsEvent('LINK_CALENDARS_MODAL_OPEN', {
                source: 'event-modal-no-calendars',
              })
            }}
          >
            Link calendar
          </Button>
        </div>
      </div>
    </div>
  )
}

function useNoCalendarIllustration() {
  const theme = useTheme()

  return theme.theme === 'dark'
    ? NoCalendarIllustrationDark
    : NoCalendarIllustrationLight
}
