import { type DateLike, parseDate } from '@motion/utils/dates'

import { DateTime } from 'luxon'

/*
 * This function should return true if the date and corresponding items should be added to the agenda.
 * If the date is in the todays week, it should only add the date and items that are after the selected date
 * Otherwise, it should add all dates and items.
 * @param dateToCheck: DateLike - The date to check
 * @param selectedIsoDate: DateLike - The selected date (the current date in calendar)
 * @returns boolean
 */
export const shouldAddDate = (
  dateToCheck: DateLike,
  selectedIsoDate: DateLike
) => {
  const date = parseDate(dateToCheck)
  const selectedDate = parseDate(selectedIsoDate)

  if (selectedDate.weekNumber === DateTime.now().weekNumber) {
    return date.toISODate() >= selectedDate.toISODate()
  }

  return true
}
