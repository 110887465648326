import { useContext } from 'react'

import {
  ModalListOrderContext,
  type ModalListOrderValue,
} from '../modal-list-order-context'

export function useModalListOrder(): ModalListOrderValue {
  return useContext(ModalListOrderContext)
}
