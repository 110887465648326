import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { type TextCustomField } from '@motion/ui-logic'

import {
  CustomField,
  type CustomFieldInputProps,
} from '~/areas/project-management/custom-fields'
import { type ControlledCustomFieldProps } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { type FC } from 'react'

export const ControlledText: FC<
  ControlledCustomFieldProps<TextCustomField>
> = ({ value, onSubmit: submitHandler, customField, disabled }) => {
  const [internalValue, setInternalValue] = useDependantState(
    () => value,
    [value]
  )

  const onChange = (v: string) => {
    setInternalValue(v)
  }

  const onSubmit = useDebouncedCallback(async () => {
    if (internalValue === value) {
      return
    }

    try {
      await submitHandler(internalValue)
    } catch (error) {
      setInternalValue(value)
    }
  }, 600)

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key } = evt

    if (key === 'Enter') {
      return onSubmit()
    }
  }

  const hasValue = internalValue !== ''

  return (
    <CustomField.Text
      name={customField.definition.name}
      value={internalValue ?? ''}
      onChange={onChange}
      onBlur={onSubmit}
      disabled={disabled}
      onKeyDown={handleKeyDown}
      size='xsmall'
      hideIcon={hasValue}
    />
  )
}
