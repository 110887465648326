import * as Menu from '@radix-ui/react-dropdown-menu'
import React, { Fragment, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { type DropdownMenuOption } from './types'

import { dropdownContainerColorClasses } from '../GeneralComponentStyles'
import { MenuItem } from '../MenuItem/MenuItem'

interface DropdownSubMenuProps {
  trigger: ReactElement
  options: DropdownMenuOption[]
  onOpenChange?: (open: boolean) => void
  className?: string
  containerClassName?: string
  triggerClassName?: string
}

/**
 * @deprecated Use a `PopoverTrigger` with an `ActionList` and the `renderPopover` for the nested menu menu instead
 */
export const DropdownSubMenu = ({
  trigger,
  options = [],
  onOpenChange = () => null,
  onSelect = () => null,
  className = '',
  containerClassName = '',
  triggerClassName = '',
}: DropdownSubMenuProps & { onSelect: () => void }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleSelect = (event: Event, selected: DropdownMenuOption) => {
    event.preventDefault()
    event.stopPropagation()

    if (!selected.onSelect) {
      return
    }
    selected.onSelect(event, selected)
  }

  return (
    <Menu.Sub
      onOpenChange={(open) => {
        onOpenChange(open)
        setIsOpen(open)
      }}
      open={isOpen}
    >
      <Menu.SubTrigger
        className={twMerge(
          'focus:bg-light-400 dark:focus:bg-dark-700 flex cursor-pointer items-center rounded focus:outline-none focus:ring-0',
          triggerClassName
        )}
      >
        {trigger}
      </Menu.SubTrigger>
      <Menu.SubContent
        className={twMerge(
          'z-20',
          'radix-state-open:animate-fadein transition-opacity duration-1000 ease-out',
          containerClassName
        )}
      >
        <div
          className={twMerge(
            'mt-1 max-h-60 overflow-y-auto rounded border shadow-lg',
            dropdownContainerColorClasses,
            className
          )}
        >
          {options
            .filter((option) => option && !!option.label)
            .map((option) => (
              <Fragment key={`dropdown-menu-${option.label}`}>
                {!option.children && (
                  <Menu.Item
                    asChild
                    onSelect={(e) => {
                      handleSelect(e, option)
                      onSelect()
                    }}
                    disabled={option.disabled || option.isTitle}
                  >
                    <MenuItem {...option} />
                  </Menu.Item>
                )}
                {option.children && (
                  <DropdownSubMenu
                    trigger={<MenuItem {...option} />}
                    onSelect={onSelect}
                    options={option.children}
                  />
                )}
              </Fragment>
            ))}
        </div>
      </Menu.SubContent>
    </Menu.Sub>
  )
}
