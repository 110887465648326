import { isSchedulingTask } from '@motion/ui-logic/pm/task'

import { TaskTag } from '~/areas/tasks/modals/task-modal/fields'
import { useMemo } from 'react'

import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const Header = () => {
  const {
    initialTaskData: { task: initialTask },
  } = useTaskDefinitionModalState()

  const isSchedulingTaskType = useMemo(
    () => isSchedulingTask(initialTask),
    [initialTask]
  )

  return (
    <header className='grid grid-cols-[1fr_auto] auto-rows-max electron-drag'>
      <div className='flex flex-row items-center gap-1 text-semantic-neutral-text-disabled text-xs'>
        <TaskTag showAs='normal' isScheduleTask={isSchedulingTaskType} />
      </div>

      <span className='text-semantic-neutral-text-subtle text-xs'>
        type “/” to use text variables
      </span>
    </header>
  )
}
