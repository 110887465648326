import {
  createNewFullCalendarMotionEvent,
  eventToFullCalendarEvent,
  type FullCalendarMotionEvent,
  type FullCalendarMotionNewEvent,
} from '~/areas/calendar/utils'
import { useLookup } from '~/global/cache'
import { selectEmailAccountsMap } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'
import { useMemo } from 'react'

import {
  useScheduleAssistantCalendarContext,
  useSelectExistingScheduleEntities,
  useUserCalendarEvents,
} from '../../../hooks'
import { disableEventEditable } from '../utils'

export function useSelectExistingEvents() {
  const [calendarState] = useScheduleAssistantCalendarContext()

  const { selectedCalendarEvent, mode } = calendarState

  /**
   * Data required for populating calendar events in Select Existing mode
   */
  const { calendarEvents: userCalendarEvents } = useUserCalendarEvents({
    enabled: mode === 'select-existing',
  })
  const { data } = useSelectExistingScheduleEntities({
    enabled: mode === 'select-existing',
  })
  const emailAccountMap = useAppSelector(selectEmailAccountsMap)
  const lookup = useLookup()

  return useMemo(() => {
    const newEvent = selectedCalendarEvent?.new
      ? createNewFullCalendarMotionEvent(selectedCalendarEvent.new)
      : null

    const allEvents: (
      | FullCalendarMotionNewEvent
      | FullCalendarMotionEvent
      | null
    )[] = [newEvent]

    if (
      userCalendarEvents.length > 0 &&
      data &&
      calendarState.mode === 'select-existing'
    ) {
      const events = userCalendarEvents.flatMap((event) => {
        const scheduledEntity = data.models.scheduledEntities[event.id]

        if (scheduledEntity == null) return null

        const fullCalendarEvent = eventToFullCalendarEvent({
          calendarEvent: event,
          entity: scheduledEntity,
          emailAccountMap,
          lookupFn: lookup,
          modifier: 'select_existing',
        })

        if (fullCalendarEvent == null) return []

        return disableEventEditable(fullCalendarEvent)
      })

      allEvents.push(...events)
    }

    return allEvents.filter(Boolean)
  }, [
    calendarState,
    data,
    emailAccountMap,
    lookup,
    selectedCalendarEvent?.new,
    userCalendarEvents,
  ])
}
