import { configureStore } from '@reduxjs/toolkit'
import { type StorageChangeResult } from '~/chromeApi/chromeApiTypes'
import { bookingReducer } from '~/state/booking'

import { accountsReducer } from './accountsSlice'
import * as accountsActions from './accountsSlice'
import { apiReducer } from './api-slice'
import * as apiActions from './api-slice'
import * as bookingActions from './booking/booking-slice'
import { calendarReducer } from './calendar/calendarSlice'
import * as calendarActions from './calendar/calendarSlice'
import { calendarListReducer } from './calendar-list/calendar-list-slice'
import * as calendarListActions from './calendar-list/calendar-list-slice'
import { calendarEventsReducer } from './calendarEvents/calendarEventsSlice'
import * as calendarEventsActions from './calendarEvents/calendarEventsSlice'
import { desktopReducer } from './desktopSlice'
import * as desktopActions from './desktopSlice'
import { emailAccountsReducer } from './email-accounts/email-accounts-slice'
import * as emailAccountsActions from './email-accounts/email-accounts-slice'
import { mainReducer } from './mainSlice'
import * as mainActions from './mainSlice'
import { modalsReducer } from './modalsSlice'
import * as modalsActions from './modalsSlice'
import { projectManagementReducer } from './projectManagementSlice'
import * as projectManagementActions from './projectManagementSlice'
import { setStore } from './proxy'
import { settingsReducer } from './settingsSlice'
import * as settingsActions from './settingsSlice'
import { teamReducer } from './teamSlice'
import * as teamActions from './teamSlice'
import { timezoneReducer } from './timezone-slice'
import * as timezoneActions from './timezone-slice'
import { userReducer } from './userSlice'
import * as userActions from './userSlice'

import api from '../chromeApi/chromeApiContentScript'

export const reducer = {
  accounts: accountsReducer,
  api: apiReducer,
  timezone: timezoneReducer,
  booking: bookingReducer,
  calendar: calendarReducer,
  calendarEvents: calendarEventsReducer,
  calendarList: calendarListReducer,
  emailAccounts: emailAccountsReducer,
  desktop: desktopReducer,
  main: mainReducer,
  modals: modalsReducer,
  projectManagement: projectManagementReducer,
  settings: settingsReducer,
  team: teamReducer,
  user: userReducer,
}

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  reducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type Store = typeof store
setStore(store)

export const resetStore = () => {
  store.dispatch(mainActions.reset())
  store.dispatch(settingsActions.reset())
  store.dispatch(projectManagementActions.reset())
  store.dispatch(calendarActions.reset())
  store.dispatch(accountsActions.reset())
  store.dispatch(teamActions.reset())
  store.dispatch(userActions.reset())
  store.dispatch(apiActions.reset())
  store.dispatch(modalsActions.reset())
  store.dispatch(timezoneActions.reset())
  store.dispatch(calendarEventsActions.reset())
  store.dispatch(desktopActions.reset())
  store.dispatch(bookingActions.reset())
  store.dispatch(calendarListActions.reset())
  store.dispatch(emailAccountsActions.reset())
}

interface StoreInitSyncEntry {
  /**
   * Storage key to pull/monitor
   */
  key: string
  /**
   * The specific store value will only be dispatched if the function returns
   * to true. The function is passed in the data to be dispatched
   * @param data
   */
  updateOn?: (data: any) => boolean
  /**
   * If conditionalOn() resolves to true or is undefined, then this dispatch
   * function is called, which should execute the associated store action
   * reducer
   * @param store
   * @param data
   */
  dispatch: (dispatch: AppDispatch, data: any) => void
}

/**
 * @deprecated should be fetching the majority of these via a backend endpoint
 */
const storeInitSyncParams: StoreInitSyncEntry[] = [
  {
    dispatch: (dispatch, data) =>
      dispatch(calendarActions.setCalendarEvents(data)),
    key: 'calendarEvents',
  },
  {
    dispatch: (dispatch, data) =>
      dispatch(mainActions.setShowCompletedTasks(data)),
    key: 'showCompletedTasks',
  },
  {
    dispatch: (dispatch, data) => dispatch(teamActions.setTeam(data)),
    key: 'team',
  },
  {
    dispatch: (dispatch, data) => dispatch(teamActions.setInvitedTeams(data)),
    key: 'invitedTeams',
  },
  {
    dispatch: (dispatch, data) => dispatch(userActions.setUser(data)),
    key: 'user',
  },
  {
    dispatch: (dispatch, data) => dispatch(settingsActions.setSchedules(data)),
    key: 'schedules',
  },
  {
    dispatch: (dispatch, data) =>
      dispatch(userActions.setStripeSubscription(data)),
    key: 'stripeSubscription',
  },
  {
    dispatch: (dispatch, data) =>
      dispatch(desktopActions.setDesktopSettings(data)),
    key: 'desktop',
  },
  {
    dispatch: (dispatch, data) => dispatch(settingsActions.setTheme(data)),
    key: 'theme',
  },
  {
    dispatch: (dispatch, data) => dispatch(userActions.setLastActiveDate(data)),
    key: 'lastActiveDate',
  },
]

// Listen to storage change events emitted by the background script, and update
// redux state accordingly.
setTimeout(async () => {
  api.storage.onChanged.addListener((changeData: StorageChangeResult) => {
    storeInitSyncParams.forEach((entry) => {
      if (entry.key in changeData) {
        const data = changeData[entry.key].newValue
        if (!entry.updateOn || entry.updateOn(data)) {
          entry.dispatch(store.dispatch, data)
        }
      }
    })
  })
})
