import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useDeleteTask } from '~/areas/tasks/hooks'
import { useCallback } from 'react'

import { type ActionArgs } from './types'
import { useBulkUpdateRows } from './use-bulk-update-rows'

// Given multiple task IDs
export const useDeleteTasks = (args: ActionArgs) => {
  const { type, ...rest } = args

  const bulkUpdateRows = useBulkUpdateRows()
  const deleteTask = useDeleteTask()

  return useCallback(async () => {
    recordAnalyticsEvent('PAST_DUE_MODAL_ACTION_DELETE', {
      type,
      numTasks: rest.tasks.length,
    })

    if (rest.tasks.length === 1) {
      return deleteTask(rest.tasks[0].id)
    }

    return bulkUpdateRows({
      ...rest,
      updates: {
        type: 'bulk-delete',
      },
    })
  }, [type, rest, bulkUpdateRows, deleteTask])
}
