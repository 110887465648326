import { AutoscheduleSolid, CalendarSolid } from '@motion/icons'
import { type FieldButtonProps } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { useTemplateTaskForm } from '../hooks'

type RenderOptions = {
  icon: ReactNode
  date: string | null
  disabled: boolean
  placeholder: string
  tooltip?: string
} & Pick<FieldButtonProps, 'onClear' | 'clearTooltipContent' | 'sentiment'>

type FormDeadlineDateDropdownProps = {
  children: (opts: RenderOptions) => ReactNode
}

export const FormDeadlineDateDropdown = ({
  children,
}: FormDeadlineDateDropdownProps) => {
  const { form, disabledFields } = useTemplateTaskForm()

  const { watch } = form

  const isAutoScheduled = watch('isAutoScheduled')

  const isDisabled = disabledFields.has('deadlineDate')

  return children({
    icon: isAutoScheduled ? <AutoscheduleSolid /> : <CalendarSolid />,
    date: null,
    disabled: isDisabled,
    placeholder: 'TBD',
    sentiment: isAutoScheduled ? 'ai' : 'neutral',
    tooltip: 'Deadline is set when using the template.',
    onClear: undefined,
    clearTooltipContent: 'Remove deadline',
  })
}
