import { useSharedStateSendOnly } from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'

import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { type PageParams } from '../routes/types'
import { ViewStateKey, type WorkspaceViewState } from '../view-state'
import { useSelectView } from '../views/hooks'

type SyncRouteProps = {
  route: PageParams
}
export const SyncRoute = (props: SyncRouteProps) => {
  const { route } = props
  const setViewState = useSharedStateSendOnly(ViewStateKey)
  const setFilter = useSharedStateSendOnly(ActiveFilterKey)
  const selectView = useSelectView()
  const [search, setSearch] = useSearchParams()

  const viewFromUri = route.search.view

  useEffect(() => {
    if (!viewFromUri) return
    if (!search.has('view')) return

    // load the current view and remove the `view` from the search parameters
    selectView(viewFromUri, { updateOnly: true })
    search.delete('view')
    setSearch(search, { replace: true })
  }, [search, selectView, setSearch, viewFromUri])

  useEffect(() => {
    if (viewFromUri) return
    setViewState((prev) => {
      const newPage = route.variant === 'archive' ? 'list' : route.variant
      const newViewId = viewFromUri || prev.viewId

      if (prev.page === newPage && prev.viewId === newViewId) return prev

      return {
        ...prev,
        page: newPage,
        viewId: newViewId,
      } as WorkspaceViewState
    })

    setFilter((prev) => {
      if (prev.target === route.type) return prev
      return { ...prev, target: route.type }
    })
  }, [
    route.type,
    route.variant,
    selectView,
    setFilter,
    setSearch,
    setViewState,
    viewFromUri,
  ])

  return null
}
