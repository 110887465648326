import { type FolderSchema } from '@motion/rpc-types'

import { useLookup } from '~/global/cache'
import { useCallback } from 'react'

export const useGetFolderById = () => {
  const lookup = useLookup()
  return useCallback<(folderId: FolderSchema['id']) => FolderSchema | null>(
    (folderId: string) => lookup('folders', folderId) ?? null,
    [lookup]
  )
}
