import { stats } from '@motion/web-common/performance'

import { useEffect, useRef } from 'react'

import { useAppSelector } from '../../state/hooks'
import { selectBackgroundLoaded } from '../../state/mainSlice'
import { useAuth } from '../auth'

export const CaptureLoginMetrics = () => {
  const auth = useAuth()
  const backgroundLoaded = useAppSelector(selectBackgroundLoaded)

  const tags = useRef<string[]>([])
  if (import.meta.env.MOTION_ENV !== 'production') {
    tags.current = []
  }

  useEffect(() => {
    if (window._timings.loginMetricStatus != null) return
    if (auth.status === 'authenticating') return
    if (auth.status === 'unauthenticated') {
      window._timings.loginMetricStatus = 'ignored'
      performance.clearMarks('htmlStart')
      performance.clearMarks('extensionStart')
      return
    }

    stats.measure('auth', 'htmlStart', tags.current)
    stats.measure('auth.extension', 'extensionStart', tags.current)
    window._timings.loginMetricStatus = 'captured'
  }, [auth.status])

  useEffect(() => {
    if (!backgroundLoaded) return
    stats.measure('background_loaded', 'htmlStart', tags.current)
    stats.measure('background_loaded.extension', 'extensionStart', tags.current)
  }, [backgroundLoaded])

  return null
}
