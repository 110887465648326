import { type VersionedViewV2 } from '@motion/zod/client'

import { type PageParams, type PageType } from '../../../routes/types'

type PageViewOrderType = 'PAGE' | 'WORKSPACE'
export type PageViewOrderId = `${PageViewOrderType}/${string}`

export const isViewDisabled = (view: VersionedViewV2, pageType: PageType) => {
  if (pageType !== 'project') return false
  if (view.definition.type === 'dashboard') return true
  return view.definition.itemType === 'projects'
}

export function getPageViewOrderId(
  pageParams: PageParams
): PageViewOrderId | null {
  const type = getViewOrderTypeFromPage(pageParams)
  const id = getViewOrderIdFromPage(pageParams)

  if (type == null || id == null) return null

  return `${type}/${id}`
}

export function extractPageViewOrderId(pageViewId: PageViewOrderId | null): {
  type: PageViewOrderType | null
  id: string | null
} {
  if (pageViewId == null) return { type: null, id: null }

  const [type, id] = pageViewId.split('/')
  return { type: type as PageViewOrderType, id }
}

function getViewOrderTypeFromPage(
  pageParams: PageParams
): PageViewOrderType | null {
  if (
    pageParams.page === 'my-tasks' ||
    pageParams.page === 'all-tasks' ||
    pageParams.page === 'all-projects'
  ) {
    return 'PAGE'
  }
  if (
    pageParams.page === 'workspace' ||
    pageParams.page === 'folder' ||
    pageParams.page === 'project'
  ) {
    return 'WORKSPACE'
  }

  return null
}

function getViewOrderIdFromPage(pageParams: PageParams): string | null {
  const type = getViewOrderTypeFromPage(pageParams)

  if (type === 'PAGE') return pageParams.page
  if (type === 'WORKSPACE') return pageParams.params.workspaceId

  return null
}

export function getEditTooltipFromPage(pageParams: PageParams) {
  if (pageParams.page === 'my-tasks') {
    return 'Edit and reorder views for My Tasks'
  }

  if (pageParams.page === 'all-tasks' || pageParams.page === 'all-projects') {
    return 'Edit and reorder views for All Tasks / All Projects'
  }

  return 'Edit and reorder views for this workspace'
}
