import { ExclamationCircleSolid } from '@motion/icons'
import { Button, TitleModal } from '@motion/ui/base'
import {
  recordAnalyticsEvent,
  useOnMountAnalyticsEvent,
} from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { addAccount } from '~/utils/auth'

import { selectEmailAccounts } from '../../../../state/email-accounts/email-accounts-slice'
import { useAppSelector } from '../../../../state/hooks'

export const ContactsPermissionModal = () => {
  useOnMountAnalyticsEvent('CONTACT_PERMISSION_SHOW')
  const modalApi = useModalApi()
  const emailAccounts = useAppSelector(selectEmailAccounts)

  const closeModal = () => {
    modalApi.dismiss()
  }

  // This only parses google email accounts and checks for missing scopes. This
  // should ideally be handled on the backend.
  const accountRows: { email: string; needsAuth: boolean }[] = []
  emailAccounts.forEach((emailAccount) => {
    const needsAuth =
      emailAccount.providerType === 'GOOGLE' &&
      !emailAccount.scope.includes(
        'https://www.googleapis.com/auth/contacts.other.readonly'
      )
    accountRows.push({ email: emailAccount.email, needsAuth })
  })

  return (
    <TitleModal
      visible
      onClose={closeModal}
      title='Update your contact permissions'
    >
      {accountRows.map(({ email, needsAuth }) => (
        <div key={email} className='flex justify-between items-center h-8 mb-4'>
          {email}
          {needsAuth ? (
            <Button
              sentiment='error'
              variant='outlined'
              onClick={() => {
                addAccount()
                recordAnalyticsEvent('CONTACT_PERMISSION_ADD')
              }}
            >
              <ExclamationCircleSolid /> Update
            </Button>
          ) : (
            <Button sentiment='neutral' variant='muted' disabled>
              Done
            </Button>
          )}
        </div>
      ))}
    </TitleModal>
  )
}
