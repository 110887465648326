import { type FieldTypeSchema } from '@motion/zod/client'

import {
  type BadgeSize,
  getBadgeSizeInPixels,
} from '~/global/components/badges'

import { getCustomFieldIcon } from '../utils'

type CustomFieldBadgeProps = {
  type: FieldTypeSchema
  size?: BadgeSize
}

export const CustomFieldBadge = ({ type, size }: CustomFieldBadgeProps) => {
  const pixelSize = getBadgeSizeInPixels(size)

  const Icon = getCustomFieldIcon(type)
  return (
    <Icon
      className='text-semantic-neutral-icon-default'
      width={pixelSize}
      height={pixelSize}
    />
  )
}
