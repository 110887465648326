import { useMember } from '~/global/hooks'

type ActivityAssigneeNameProps = {
  /**
   * The user schema ID of the assignee.
   */
  assigneeId?: string | null
}

export function ActivityAssigneeName({
  assigneeId,
}: ActivityAssigneeNameProps) {
  const assignee = useMember(assigneeId)

  if (!assigneeId) {
    return 'Unassigned'
  }

  // If the user is deleted, `assignee.user.name` will be `Deleted User`.
  // However, if assignee is null, it means the user is not available in any of the workspaces, most likely due to deletion.
  return (
    assignee?.user.name ?? (
      <span className='line-through'>Unavailable user</span>
    )
  )
}
