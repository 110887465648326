import { useDependantState } from '@motion/react-core/hooks'
import { Portal, showToast } from '@motion/ui/base'
import { Compare } from '@motion/utils/array'

import {
  DndContext,
  type DragEndEvent,
  DragOverlay,
  type Modifier,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import {
  defaultRangeExtractor,
  type Range,
  useVirtualizer,
} from '@tanstack/react-virtual'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { ActiveKanbanItem } from './active-item'
import { BoardHeaders } from './board-headers'
import { useActiveItem, useKanbanDndHooks } from './hooks'
import { collisionDetectionStrategy, findContainer, isContainer } from './utils'

import { SortableItem } from '../../../../components/sortable/sortable-item'
import { type GroupedNode, type Tree } from '../../grouping'
import { useViewState } from '../../view-state'
import { KanbanColumn } from '../column'
import { useCanDragItemToGroup, useMoveProject, useMoveTask } from '../hooks'

type KanbanDndProps<T extends GroupedNode> = {
  tree: Tree<T>
  activeTab: Tree<T>[]
  selectTab: (index: number, value: Tree<T>) => void
}

const COLUMN_WIDTH = 284
const COLUMN_MARGIN_RIGHT = 16

const last = <T,>(arr: T[]) => arr[arr.length - 1]

export const KanbanDndContainer = <T extends GroupedNode>({
  tree,
  activeTab,
  selectTab,
}: KanbanDndProps<T>) => {
  const [, setViewState] = useViewState()
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const [treeCopy, setTreeCopy] = useDependantState<Tree<T>>(() => tree, [tree])

  const [laneItems, setLaneItems] = useDependantState<Tree<T>>(
    () => last(activeTab) ?? tree,
    [activeTab, tree]
  )
  const prevLaneItems = useRef(laneItems.clone())

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  const canDragIntoGroup = useCanDragItemToGroup()
  const moveTask = useMoveTask()
  const moveProject = useMoveProject()

  // Track the currently over group
  const [overGroupId, setOverGroupId] = useState<UniqueIdentifier | null>(null)
  const lastOverId = useRef<UniqueIdentifier | null>(null)
  const recentlyMovedToNewContainer = useRef(false)

  const activeIndex = useMemo(
    () =>
      laneItems.children.findIndex((group) => group.qualifiedKey === activeId),
    [activeId, laneItems]
  )

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 0 } })
  )

  const activeItem = useActiveItem({
    laneItems,
    activeTabs: activeTab,
    activeId,
  })

  const dndModifiers: Modifier[] = useMemo(() => {
    if (
      activeItem?.type === 'tab' ||
      activeItem?.type === 'pill' ||
      activeItem?.type === 'container'
    ) {
      return [restrictToHorizontalAxis]
    }
    return []
  }, [activeItem?.type])

  // Return the active child item
  const activeChild = useMemo(() => {
    if (!activeId) return null

    const groups = laneItems.children.filter((group) =>
      group.values.some((i) => i.qualifiedKey === activeId)
    )

    if (!groups || !groups.length) return null

    const item = groups[0].values.find(
      (i) => i.qualifiedKey === activeId
    ) as GroupedNode

    if (!item) return null

    return item
  }, [activeId, laneItems])

  const onColumnOrderChange = useCallback(
    (groupId: string, order: Array<string>) => {
      setViewState((prev) => ({
        ...prev,
        groupBy: {
          ...prev.groupBy,
          order: {
            ...prev.groupBy.order,
            [groupId]: order,
          },
        },
      }))
    },
    [setViewState]
  )

  const { handleDragCancel, handleDragOver, handleDragStart } =
    useKanbanDndHooks({
      laneItems,
      setActiveId,
      setOverGroupId,
    })

  // TODO: Move this to the above hook
  const handleDragEnd = useCallback(
    async ({ active, over }: DragEndEvent) => {
      setOverGroupId(null)

      if (activeChild && !!over?.id) {
        const canDragResult = canDragIntoGroup({
          item: activeChild,
          group: findContainer(laneItems, over?.id),
        })

        if (!canDragResult.canDrag) {
          setActiveId(null)

          if (canDragResult.errorMessage !== null) {
            showToast('error', canDragResult.errorMessage)
          }

          return
        }
      }

      if (active.data.current?.type === 'tab') {
        // Reorder the first level of the tree
        const activeIndex = tree.children.findIndex(
          (group) => group.qualifiedKey === active.id
        )

        const overIndex = tree.children.findIndex(
          (group) => group.qualifiedKey === over?.id
        )

        if (activeIndex < 0 || overIndex < 0) return

        const newGroupOrder = arrayMove(tree.children, activeIndex, overIndex)

        if (!tree?.groups[0].type) return

        onColumnOrderChange(
          tree?.groups[0].type,
          newGroupOrder.map((group) => group.key)
        )

        setTreeCopy({
          ...tree,
          children: newGroupOrder,
        })
      } else if (active.data.current?.type === 'pill') {
        const pillLevel = tree.children.find(
          (group) => group.key === activeTab[0]?.key
        )

        if (!pillLevel) return

        // Reorder the second level of the tree
        const activeIndex = pillLevel.children.findIndex(
          (group) => group.qualifiedKey === active.id
        )

        const overIndex = pillLevel.children.findIndex(
          (group) => group.qualifiedKey === over?.id
        )

        if (activeIndex < 0 || overIndex < 0) return

        const newGroupOrder = arrayMove(
          pillLevel.children,
          activeIndex,
          overIndex
        )

        if (!tree?.groups[1]?.type) return

        onColumnOrderChange(
          tree?.groups[1]?.type,
          newGroupOrder.map((group) => group.key)
        )

        setTreeCopy({
          ...tree,
          children: tree.children.map((group) => {
            if (group.qualifiedKey === activeTab[0].key) {
              return {
                ...group,
                children: newGroupOrder,
              }
            }
            return group
          }),
        })
      } else if (isContainer(laneItems, active.id) && over?.id) {
        // If item was a container, we shift the order of the groups
        // TODO: Persist order of tree (once grouping order is added to view state)

        const prev = prevLaneItems.current

        const activeIndex = prev.children.findIndex(
          (group) => group.qualifiedKey === active.id
        )

        const overIndex = prev.children.findIndex(
          (group) => group.qualifiedKey === over.id
        )

        if (activeIndex < 0 || overIndex < 0) return prev

        const clonedTree = prev.clone()

        const newGroupOrder = arrayMove(
          clonedTree.children,
          activeIndex,
          overIndex
        )

        const activeContainer = clonedTree.children[activeIndex]

        if (!activeContainer.item?.value.type) return prev

        onColumnOrderChange(
          activeContainer.item.value.type,
          newGroupOrder.map((group) => group.key)
        )

        setLaneItems({
          ...clonedTree,
          children: newGroupOrder,
        })
      } else {
        const activeGroup = findContainer(laneItems, active.id)
        let overGroup: Tree<T> | undefined

        if (over?.id) {
          overGroup = findContainer(laneItems, over.id)
        } else if (overGroupId) {
          overGroup = findContainer(laneItems, overGroupId)
        }

        if (!activeGroup || !overGroup) {
          setActiveId(null)
          return
        }

        // If moved to same container as before, we don't need to do anything
        // TODO: When manual sorting is added, we need to update the order of the items
        if (
          !recentlyMovedToNewContainer.current &&
          activeGroup.qualifiedKey === overGroup.qualifiedKey
        ) {
          setActiveId(null)
          return
        }

        if (activeGroup && overGroup) {
          const prev = prevLaneItems.current
          // if the item was a child node, we shift the order of the items in the group
          const activeIndex = activeGroup.values.findIndex(
            (item) => item.qualifiedKey === active.id
          )

          let overIndex = overGroup.values.findIndex(
            (item) => item.qualifiedKey === over?.id
          )

          // Always place the item at the top of the list if it was moved to a new container
          if (!overIndex) {
            overIndex = 0
          }

          if (activeIndex < 0) {
            setLaneItems(prev)
            return
          }

          const clonedTree = prev.clone()

          const overGroupIndex = prev.children.findIndex(
            (group) => group.qualifiedKey === overGroup.qualifiedKey
          )

          const activeGroupIndex = prev.children.findIndex(
            (group) => group.qualifiedKey === activeGroup.qualifiedKey
          )

          if (overGroupIndex < 0 || activeGroupIndex < 0) {
            setLaneItems(prev)
            return
          }

          const activeItem =
            clonedTree.children[activeGroupIndex].values[activeIndex]

          clonedTree.children[overGroupIndex].values = [
            ...overGroup.values.slice(0, overIndex),
            activeGroup.values[activeIndex],
            ...overGroup.values.slice(overIndex, overGroup.values.length),
          ]

          clonedTree.children[activeGroupIndex].values =
            activeGroup.values.filter((i) => i.qualifiedKey !== active.id)

          if (activeItem?.value.type === 'task') {
            void moveTask({
              item: activeItem,
              sourceGroup: clonedTree.children[activeGroupIndex],
              group: clonedTree.children[overGroupIndex],
            })
          } else if (activeItem?.value.type === 'project') {
            const response = await moveProject({
              item: activeItem,
              group: clonedTree.children[overGroupIndex],
              sourceGroup: clonedTree.children[activeGroupIndex],
            })

            if (response === false) {
              setLaneItems(prev)
              return
            }
          }

          setLaneItems(clonedTree)
        }
      }

      // Delay clearing the activeId to allow for the drop animation to finish and avoid flickering
      setTimeout(() => {
        setActiveId(null)
        setOverGroupId(null)
      }, 300)
    },
    [
      activeChild,
      laneItems,
      canDragIntoGroup,
      tree,
      onColumnOrderChange,
      setTreeCopy,
      activeTab,
      setLaneItems,
      overGroupId,
      moveTask,
      moveProject,
    ]
  )

  useEffect(() => {
    // Never update the local state if the activeId is set
    if (activeId) return
    recentlyMovedToNewContainer.current = false
    prevLaneItems.current = laneItems.clone()
  }, [activeId, laneItems, tree])

  const columnVirtualizer = useVirtualizer({
    count: laneItems.children.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: () => COLUMN_WIDTH + COLUMN_MARGIN_RIGHT,
    horizontal: true,
    rangeExtractor: (range: Range) => {
      const next =
        activeIndex > -1
          ? [...new Set([activeIndex, ...defaultRangeExtractor(range)])].sort(
              Compare.numeric
            )
          : defaultRangeExtractor(range)
      return next
    },
  })

  return (
    <div className='flex flex-col gap-3 flex-1 overflow-hidden'>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragCancel={handleDragCancel}
        collisionDetection={(args) =>
          collisionDetectionStrategy({
            tree: laneItems,
            lastOverId,
            activeId,
            ...args,
          })
        }
        modifiers={dndModifiers}
      >
        <BoardHeaders
          tree={treeCopy}
          activeTabs={activeTab}
          selectTab={selectTab}
          activeId={activeId}
        />
        <div
          ref={scrollRef}
          className='flex-1 overflow-x-auto overflow-y-hidden relative'
        >
          <div
            style={{
              width: columnVirtualizer.getTotalSize() + COLUMN_MARGIN_RIGHT * 2,
            }}
            className='relative h-full'
          >
            <SortableContext
              items={laneItems.children.map((item) => item.qualifiedKey)}
              strategy={horizontalListSortingStrategy}
            >
              {columnVirtualizer.getVirtualItems().map((virtualItem) => {
                const child = laneItems.children[virtualItem.index]

                return (
                  <SortableItem
                    key={child.qualifiedKey}
                    id={child.qualifiedKey}
                    hasDragHandle
                    data={{
                      type: 'container',
                      children: child.values.values,
                    }}
                    style={{
                      left: virtualItem.start + COLUMN_MARGIN_RIGHT,
                      width: COLUMN_WIDTH + COLUMN_MARGIN_RIGHT,
                      height: '100%',
                    }}
                    renderItem={(sortableProps) => {
                      const dragInDisabled =
                        !!activeChild &&
                        canDragIntoGroup({
                          item: activeChild,
                          group: child,
                        }).canDrag !== true

                      return (
                        <KanbanColumn
                          activeId={activeId?.toString()}
                          setActivatorNodeRef={
                            sortableProps.setActivatorNodeRef
                          }
                          listeners={sortableProps.listeners}
                          item={child}
                          // TODO: Replace this when we want specific item placement
                          draggingOver={
                            overGroupId === child.key &&
                            activeItem?.type === 'item'
                          }
                          dragInDisabled={dragInDisabled}
                        />
                      )
                    }}
                  />
                )
              })}
            </SortableContext>
          </div>
        </div>
        <Portal>
          <DragOverlay
            dropAnimation={{
              duration: 0,
            }}
            modifiers={dndModifiers}
          >
            <ActiveKanbanItem
              activeItem={activeItem}
              activeId={activeId}
              activeTabs={activeTab}
            />
          </DragOverlay>
        </Portal>
      </DndContext>
    </div>
  )
}
