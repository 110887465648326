export const BlockerLinesSvg = ({ chainLength }: { chainLength: number }) => {
  const height =
    chainLength * (16 + 8) + // row height + gap
    16 // extra height for the last row

  const dotX = 31
  const dotYOffset = 7
  const dotY = height - dotYOffset
  const dotRadius = 4

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height={height}
      viewBox={`0 0 35 ${height}`}
      fill='none'
    >
      <BlockerLinesPaths taskCount={chainLength} />

      <circle
        id='Dot'
        cx={dotX}
        cy={dotY}
        r={dotRadius}
        className='text-semantic-neutral-text-subtle'
        fill='currentColor'
      />
    </svg>
  )
}

export const BlockerLinesPaths: React.FC<{ taskCount: number }> = ({
  taskCount,
}) => {
  const paths = generatePaths(taskCount)

  return (
    <>
      {paths.map((d, i) => (
        <path key={i} d={d} stroke='currentColor' />
      ))}
    </>
  )
}

const generatePaths = (taskCount: number) => {
  const paths: string[] = []
  const baseSpacing = 24
  const ySpacingOffset = 8

  for (let i = 0; i < taskCount; i++) {
    const isLast = i === taskCount - 1
    const startY = i * baseSpacing + ySpacingOffset
    const controlY = startY - 0.5
    const endY = startY + 6

    if (isLast) {
      paths.push(getFinalXPath({ startY, controlY, endY }))
    } else {
      paths.push(generatePath({ startY, controlY, endY }))
    }
  }

  return paths
}

interface PathConfig {
  startY: number
  controlY: number
  endY: number
}

const startX = 6.75391
const controlX = 4.69417
const dipX = 0.574707
const finalX = 28.1232

const generatePath = ({ startY, controlY, endY }: PathConfig) => {
  const basePath = `M${startX} ${startY}C${controlX} ${controlY} ${dipX} ${controlY} ${dipX} ${endY}`

  return `${basePath}V${endY + 30}`
}

const getFinalXPath = ({ startY, controlY, endY }: PathConfig) => {
  return `M${startX} ${startY}C${controlX} ${controlY} ${dipX} ${controlY} ${dipX} ${endY}V${endY + 14.6239}C${dipX + 0.207208} ${
    endY + 16.0908
  } ${dipX + 1.37744} ${endY + 18.987} ${dipX + 4.4007} ${endY + 18.987}L${finalX} ${endY + 19.1565}`
}
