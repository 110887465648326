import { memo } from 'react'

import { ProjectCard } from './project-card'
import { TaskCard } from './task-card'

import { type GroupedNode } from '../../grouping'

type KanbanCardProps<T extends GroupedNode> = {
  item: T
  dragging?: boolean
}
export const KanbanCard = memo(
  <T extends GroupedNode>({ item, dragging }: KanbanCardProps<T>) => {
    const { type, value } = item.value

    if (type === 'task') {
      return <TaskCard task={value} dragging={dragging} />
    }
    if (type === 'project') {
      return <ProjectCard project={value} dragging={dragging} />
    }

    return null
  }
)

KanbanCard.displayName = 'KanbanCard'
