import { priorityLevels } from '@motion/rpc/types'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { useProjectUpdater } from '~/areas/project/hooks'
import { useTaskPriorityData } from '~/areas/tasks/hooks'
import { PriorityBadge } from '~/global/components/badges'
import { PriorityDropdown } from '~/global/components/dropdowns'
import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'

import { CardButton } from '../components'

export type TaskPriorityFieldProps = {
  task: TaskWithRelations
}
export const TaskPriorityField = ({ task }: TaskPriorityFieldProps) => {
  const { onChange, priorities, disabled } = useTaskPriorityData(task)

  return (
    <PriorityField
      priorities={priorities}
      value={task.priorityLevel}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export type ProjectPriorityFieldProps = {
  project: ProjectWithRelations
}
export const ProjectPriorityField = ({
  project,
}: ProjectPriorityFieldProps) => {
  const updateProject = useProjectUpdater()

  return (
    <PriorityField
      priorities={priorityLevels}
      value={project.priorityLevel}
      onChange={(priorityLevel) => updateProject(project, { priorityLevel })}
    />
  )
}

type PriorityFieldProps = {
  priorities: PriorityLevelSchema[]
  value: PriorityLevelSchema
  onChange: (priority: PriorityLevelSchema) => void
  disabled?: boolean
}
const PriorityField = ({
  priorities,
  value,
  onChange,
  disabled,
}: PriorityFieldProps) => {
  return (
    <PriorityDropdown
      items={priorities}
      selectedItem={value}
      onChange={onChange}
    >
      <CardButton disabled={disabled} iconOnly>
        <PriorityBadge value={value} />
      </CardButton>
    </PriorityDropdown>
  )
}
