import { QuestionMarkCircleSolid } from '@motion/icons'
import { type DeadlineType } from '@motion/rpc/types'
import { Toggle, Tooltip } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'

import { SubItemIndentMarker } from './styled'

type HardDeadlineFieldProps = {
  value: DeadlineType
  onChange: (value: DeadlineType) => void
  disabled?: boolean
}

export const HardDeadlineField = ({
  value,
  onChange,
  disabled,
}: HardDeadlineFieldProps) => {
  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />

      <HardDeadlineFieldToggle
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

const TOOLTIP_CONTENT =
  'If this is toggled on, Motion will prioritize this task and make sure it schedules before deadline; if needed, Motion may schedule it outside of work hours (or another selected schedule) in order to meet deadline.'

const HardDeadlineFieldToggle = ({
  value,
  onChange,
  disabled,
}: HardDeadlineFieldProps) => {
  return (
    <ModalFieldButton
      label='Hard deadline'
      size='normal'
      variant='subtle'
      disabled={disabled}
    >
      <Tooltip content={disabled ? undefined : TOOLTIP_CONTENT}>
        <QuestionMarkCircleSolid />
      </Tooltip>
      <Toggle
        checked={value === 'HARD'}
        size='small'
        onChange={() => {
          onChange(value === 'HARD' ? 'SOFT' : 'HARD')
        }}
        disabled={disabled}
      />
    </ModalFieldButton>
  )
}
