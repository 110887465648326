import { ButtonTabs, type ButtonTabsProps, Tooltip } from '@motion/ui/base'
import { type StageCardType } from '@motion/ui-logic/pm/project'

import { type ReactNode, useMemo } from 'react'

type TaskCardHeaderProps = {
  value: StageCardType
  onChange: (type: StageCardType) => void
  suffix?: ReactNode
  isTaskDirty?: boolean
}

export function TaskCardHeader({
  suffix,
  value,
  onChange,
  isTaskDirty,
}: TaskCardHeaderProps) {
  const tabItems = useMemo<ButtonTabsProps['items']>(
    () => [
      {
        value: 'task',
        content: 'Task',
        onAction: () => {
          onChange('task')
        },
      },
      {
        value: 'event',
        content: 'Event',
        onAction: () => {
          onChange('event')
        },
      },
    ],
    [onChange]
  )

  return (
    <div className='flex items-center justify-between bg-semantic-neutral-surface-bg-subtlest p-1'>
      <ButtonTabs items={tabItems} activeValue={value} size='xsmall' />

      <div className='flex items-center gap-3'>
        {isTaskDirty && (
          <Tooltip
            content='Updates to this task will be applied to the template once the template is saved,
and to all active projects if this task is in a future stage'
          >
            <span className='text-semantic-orange-bg-strong-default text-2xs italic'>
              Modified
            </span>
          </Tooltip>
        )}
        {suffix}
      </div>
    </div>
  )
}
