import { LoadingSpinner, UnstyledModal } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { memo } from 'react'

import {
  CalendarShell,
  CalendarShellFooter,
  CreateNewSidebar,
  GridFormShell,
  ScheduleAssistantCalendarView,
  SelectExistingSidebar,
} from './components'
import { ScheduleAssistantProvider } from './context'
import { useScheduleAssistantCalendarContext } from './hooks'
import {
  type CreateNewInitialData,
  type ScheduleAssistantPromptCallback,
} from './types'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'schedule-assistant': PromptCallbacks<ScheduleAssistantPromptCallback> &
      (
        | {
            mode: 'select-existing'
            initialData?: never
          }
        | {
            mode: 'create-new'
            initialData: CreateNewInitialData
          }
      )
  }
}

export function ConnectedScheduleAssistantModal({
  close,
  ...rest
}: ModalTriggerComponentProps<'schedule-assistant'>) {
  let createNewData: CreateNewInitialData | null = null

  if (rest.mode === 'create-new') {
    createNewData = rest.initialData
  }

  return (
    <UnstyledModal
      data-testid='schedule-assistant-modal'
      visible
      onClose={close}
      withAnimation
      overlayClassName='bg-modal-overlay'
      type='page'
    >
      <ScheduleAssistantProvider mode={rest.mode} createNewData={createNewData}>
        <ScheduleAssistantModalBody close={close} />
      </ScheduleAssistantProvider>
    </UnstyledModal>
  )
}

type ScheduleAssistantModalBodyProps = Pick<
  ModalTriggerComponentProps<'schedule-assistant'>,
  'close'
>
const ScheduleAssistantModalBody = memo(function ScheduleAssistantModalBody({
  close,
}: ScheduleAssistantModalBodyProps) {
  const [{ mode, loadingMode }] = useScheduleAssistantCalendarContext()

  if (loadingMode) {
    return (
      <GridFormShell>
        <div className='col-span-full row-span-full w-full h-full grid place-items-center'>
          <LoadingSpinner />
        </div>
      </GridFormShell>
    )
  }

  return (
    <GridFormShell>
      {mode === 'create-new' ? <CreateNewSidebar /> : <SelectExistingSidebar />}

      <CalendarShell>
        <ScheduleAssistantCalendarView />

        <CalendarShellFooter close={close} />
      </CalendarShell>
    </GridFormShell>
  )
})
