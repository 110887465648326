import { StartDateSolid } from '@motion/icons'

import { DateTriggerButton } from '~/areas/task-project/components'

export const ControlledStartDateField = () => {
  return (
    <DateTriggerButton
      label='Start date'
      icon={<StartDateSolid />}
      disabled
      date={null}
      placeholder='Stage Start'
    />
  )
}
