import { PlusSolid } from '@motion/icons'
import { TreeviewItem } from '@motion/ui/base'

import { useProjectModalUrl } from '~/global/navigation'
import { Link } from 'react-router-dom'

type NewProjectItemProps = {
  workspaceId: string
}

export const NewProjectItem = ({ workspaceId }: NewProjectItemProps) => {
  const buildProjectModalUrl = useProjectModalUrl()

  return (
    <TreeviewItem
      isButton
      label={
        <Link
          to={buildProjectModalUrl({ forWorkspace: workspaceId })}
          className='flex-1 flex items-center gap-2 px-2'
        >
          <PlusSolid className='w-4 h-4' />
          Add project
        </Link>
      }
    />
  )
}
