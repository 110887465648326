import { type TemplateProjectType } from '@motion/rpc/types'
import { createQueryFilter, MotionCache } from '@motion/rpc-cache'
import { type COLOR } from '@motion/shared/common'
import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { isFlowTemplate } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectDefinitionSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { useWorkspaceFns } from '~/global/hooks'
import { useCreateProjectFromTemplate } from '~/global/rpc'
import { projectCacheKeysToUpdate } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { DateTime } from 'luxon'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { useSetupProjectModalUrl } from '../../modals/setup-project-modal'
import { useRedirectToProject } from '../use-redirect-to-project'

export const useSelectProjectTemplate = (
  { skipConfirm } = {
    skipConfirm: false,
  }
) => {
  const queryClient = useQueryClient()
  const modalApi = useModalApi()
  const { mutateAsync: createProjectFromTemplate } =
    useCreateProjectFromTemplate()
  const redirectToProject = useRedirectToProject()
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const navigate = useNavigate()

  return useCallback(
    async (
      template: TemplateProjectType | ProjectDefinitionSchema,
      projectId?: string
    ) => {
      if (isFlowTemplate(template)) {
        if (projectId != null) {
          const project = getWorkspaceProjectById(projectId)
          if (project == null) {
            return
          }

          const response = await modalApi.prompt('confirm', {
            analytics: {
              name: 'existing-project-use-template',
            },
            title:
              'Are you sure you want to apply this template to this project?',
            description:
              'Tasks inside this project will be added to the first stage.',
            closeButtonText: 'Cancel',
            confirmButtonText: 'Continue',
          })

          if (response === ModalDismissed) {
            return
          }

          navigate(
            buildSetupProjectModalUrl({
              flowTemplateId: template.id,
              forStartDate: project.startDate ?? undefined,
              // Strip time from due date to avoid conversion issues
              forDueDate: project.dueDate
                ? DateTime.fromISO(project.dueDate).toISODate()
                : undefined,
              forProjectId: projectId,
            })
          )
          return
        }

        return
      }

      if (!skipConfirm) {
        const response = await modalApi.prompt('confirm', {
          analytics: {
            name: 'project-use-template',
          },
          title: templateStr('Use template “{{ templateName }}”', {
            templateName: template.name,
          }),
          description:
            'Are you sure you want to use this template? Any information added will be replaced with the template.',
          closeButtonText: 'Cancel',
          confirmButtonText: 'Create project from template',
        })

        if (response === ModalDismissed) {
          return
        }
      }

      recordAnalyticsEvent('PROJECT_MANAGEMENT_USE_TEMPLATE_PROJECT')

      try {
        const createdProject = await createProjectFromTemplate({
          templateId: template.id,
          taskModifiers: template.project.tasks.map((t) => ({
            id: t.id ?? '',
            dueDate: DateTime.now().endOf('day').toISO(),
          })),
        })

        // Add the newly created project from a legacy template within our caches
        MotionCache.upsert(
          queryClient,
          createQueryFilter(projectCacheKeysToUpdate),
          {
            id: createdProject.id,
            meta: { model: 'projects' },
            models: {
              projects: {
                [createdProject.id]: {
                  ...createdProject,
                  color: createdProject.color as COLOR,
                  description: createdProject.description ?? '',
                  labelIds: createdProject.labels.map((l) => l.labelId),
                  customFieldValues: {},
                  stages: [],
                  variableInstances: [],
                  completion: 0,
                  uploadedFileIds: [],
                  folderId: null,
                  deadlineStatus: 'none',
                  scheduledStatus: null,
                  estimatedCompletionTime: null,
                },
              },
            },
          }
        )

        showToast('success', 'Project created')

        setTimeout(() => {
          redirectToProject(createdProject.id)
        }, 0)
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useSelectTemplate',
          },
        })

        showErrorToast(e)
        throw e
      }
    },
    [
      buildSetupProjectModalUrl,
      createProjectFromTemplate,
      getWorkspaceProjectById,
      modalApi,
      navigate,
      queryClient,
      redirectToProject,
      skipConfirm,
    ]
  )
}
