import { FormModal } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { errorInDev } from '@motion/web-base/logging'
import { useModalApi } from '@motion/web-common/modals'

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  type Stripe,
  type StripePaymentElementChangeEvent,
} from '@stripe/stripe-js'
import { useUncancelSubscriptionAndUpdate } from '~/components/cancel-account-modal/rpc-hooks'
import { ElementsWrapper } from '~/components/Common/Stripe/ElementsWrapper'
import { useUpdateDefaultPaymentMethod } from '~/global/rpc'
import { createSetupIntent } from '~/state/corrilySlice'
import { useAppDispatch } from '~/state/hooks'
import { reusableSetupIntent } from '~/state/teamSlice'
import { getStripe } from '~/utils/stripe'
import { useCallback, useEffect, useState } from 'react'

export type ChangePaymentMethodModalProps =
  ConnectedChangePaymentMethodModalProps & {
    activeTeamId?: string
    resetClientSecret: () => void
    error?: string
    setError: (error: string) => void
  }

const ChangePaymentMethodModal = ({
  activeTeamId,
  resetClientSecret,
  error,
  setError,
  hasPaymentMethod,
}: ChangePaymentMethodModalProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const modalTitle = hasPaymentMethod
    ? 'Change Payment Method'
    : 'Add Payment Method'
  const { mutateAsync: updateDefaultPaymentMethodAsync } =
    useUpdateDefaultPaymentMethod({
      onError: (error) => {
        recordAnalyticsEvent(
          activeTeamId
            ? 'TEAM_BILLING_ADD_CARD_ERROR'
            : 'INDIVIDUAL_BILLING_ADD_CARD_ERROR'
        )
        errorInDev(error)
        setError(error.message ?? '')
        resetClientSecret()
        return
      },
    })

  const [isPaymentFilledOut, setIsPaymentFilledOut] = useState(false)

  const modalApi = useModalApi()

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setIsPaymentFilledOut(event.complete)
  }
  const { doUncancelSubscription } = useUncancelSubscriptionAndUpdate()

  const submitPayment = async () => {
    if (!elements) {
      return
    }

    const result = await stripe?.confirmSetup({
      elements,
      redirect: 'if_required',
    })
    if (result?.error) {
      errorInDev(result.error)
      setError(result.error?.message ?? '')
      return
    }

    // TODO: HACK stripe types seem to think this is impossible
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const setupIntent = result.setupIntent! as any

    if (!activeTeamId) {
      await doUncancelSubscription()
    }

    await updateDefaultPaymentMethodAsync({
      setupIntentId: setupIntent.id,
    })

    modalApi.dismiss('change-payment-method')
    recordAnalyticsEvent(
      activeTeamId
        ? 'TEAM_BILLING_ADD_CARD_SUCCESS'
        : 'INDIVIDUAL_BILLING_ADD_CARD_SUCCESS'
    )
  }

  return (
    <FormModal
      visible
      onClose={() => modalApi.dismiss('change-payment-method')}
      title={modalTitle}
      submitAction={{
        text: 'Save',
        onAction: submitPayment,
        disabled: !isPaymentFilledOut,
      }}
      data-testid='payment-method-section'
    >
      <div className='min-w-[490px] max-w-[534px] flex flex-col gap-4'>
        <PaymentElement onChange={handlePaymentElementChange} />
        {error && (
          <div className='text-semantic-error-text-default'>{error}</div>
        )}
      </div>
    </FormModal>
  )
}

export type ConnectedChangePaymentMethodModalProps = {
  hasPaymentMethod: boolean
  activeTeamId?: string
}

export const ConnectedChangePaymentMethodModal = (
  props: ConnectedChangePaymentMethodModalProps
) => {
  const { activeTeamId, ...rest } = props
  const dispatch = useAppDispatch()
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [stripe, setStripe] = useState<null | Stripe>(null)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    const load = async () => {
      const stripe = await getStripe()
      setStripe(stripe)
    }

    load().catch(errorInDev)
  }, [])

  const resetClientSecret = useCallback(async () => {
    if (activeTeamId) {
      const getSecret = async () => {
        const paymentIntent = await dispatch(
          reusableSetupIntent(activeTeamId)
        ).unwrap()
        setClientSecret(paymentIntent.clientSecret)
      }
      getSecret().catch(errorInDev)
    } else {
      const getSecret = async () => {
        const paymentIntent = await dispatch(createSetupIntent()).unwrap()
        setClientSecret(paymentIntent?.clientSecret ?? null)
      }
      getSecret().catch(errorInDev)
    }
  }, [dispatch, activeTeamId])

  useEffect(() => {
    void resetClientSecret()
  }, [resetClientSecret])

  if (stripe !== null && clientSecret !== null) {
    return (
      <ElementsWrapper clientSecret={clientSecret} stripe={stripe}>
        <ChangePaymentMethodModal
          {...rest}
          activeTeamId={activeTeamId}
          resetClientSecret={resetClientSecret}
          error={error}
          setError={setError}
        />
      </ElementsWrapper>
    )
  }

  return null
}
