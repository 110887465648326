import { useSharedStateQuery } from '@motion/react-core/shared-state'

import { AppWorkspaceContext } from '~/global/contexts'

import { type WorkspaceCustomField } from '../types'
import { getFilteredWorkspaceCustomField } from '../utils'

export const useAllCustomFields = (): WorkspaceCustomField[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) =>
    state.customFields.all.map((field) =>
      getFilteredWorkspaceCustomField(field)
    )
  )
}
