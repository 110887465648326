import { useClosure } from '@motion/react-core/hooks'

import { useEffect } from 'react'

import { type BulkOpsAction, useBulkOpsState } from '../contexts'

/**
 * Hook to allow components to register a callback to be called when an action is triggered
 * @param action
 * @param fn
 */
export function useOnBulkOpsAction(action: BulkOpsAction, fn: () => void) {
  const { onAction } = useBulkOpsState()
  const closure = useClosure(fn)

  useEffect(() => {
    const unsub = onAction(action, closure)

    return unsub
  }, [action, closure, onAction])
}
