import { useEffect } from 'react'
import { useBlocker } from 'react-router'

import { useUnsavedChangesPrompt } from './use-unsaved-changes-prompt'

interface PromptOptions {
  when: boolean
  message?: string
}

/**
 * Wrapper around useBlocker to show a custon confirmation modal
 *
 * https://github.com/remix-run/react-router/blob/2041d9c7ebc8575ff99ed127d3c9ab8b79347b1e/decisions/0001-use-blocker.md
 */
export function useRouteConfirmationPromptBeforeLeaving({
  when,
  message,
}: PromptOptions) {
  const showUnsavedPrompt = useUnsavedChangesPrompt()
  const blocker = useBlocker(when)

  useEffect(() => {
    const run = async () => {
      if (blocker.state === 'blocked') {
        if (blocker.location?.state?.force === true) {
          setTimeout(blocker.proceed, 0)
          return
        }

        const proceed = await showUnsavedPrompt(message)

        if (proceed) {
          // This timeout is needed to avoid a weird "race" on POP navigations
          // between the `window.history` revert navigation and the result of
          // `window.confirm`
          setTimeout(blocker.proceed, 0)
          return
        }

        blocker.reset()
      }
    }

    void run()
  }, [blocker, message, showUnsavedPrompt])

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset()
    }
  }, [blocker, when])
}
