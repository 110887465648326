import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type RedirectProps = {
  to: string
  replace?: boolean
}
/* This will eventually be shared more widely, but want to wait for full react-router first */
export const Redirect = (props: RedirectProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (props.to !== location.pathname) {
      navigate(props.to, { replace: props.replace })
    }
  }, [location.pathname, navigate, props.replace, props.to])

  return null
}
