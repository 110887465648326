import { PuzzleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { useSetupProjectModalUrl } from '../../../setup-project-modal'

export const TemplateDropdown = () => {
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()

  return (
    <Button
      sentiment='neutral'
      size='small'
      variant='muted'
      url={buildSetupProjectModalUrl()}
    >
      <PuzzleSolid />
      Use template
    </Button>
  )
}
