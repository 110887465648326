import { getTaskDeadlineDateChangedFields } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export function useTaskDeadlineUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      newDeadline: TaskSchema['dueDate']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the deadline of a Chunk task')
      }
      if (task.type === 'RECURRING_TASK') {
        throw new Error('Cannot update the deadline of a recurring task')
      }

      const updates = {
        dueDate: newDeadline,
        ...getTaskDeadlineDateChangedFields({ ...task, dueDate: newDeadline }),
      }

      await updateTask(task, updates)
    },
    [readTask, updateTask]
  )
}
