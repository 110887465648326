import { ViewGridSolid } from '@motion/icons'

import {
  type MinChunkDropdownButtonProps,
  MinChunkDurationDropdown,
} from '~/areas/project-management/components'
import { useController } from 'react-hook-form'

import { useTemplateTaskForm } from '../hooks'

export const FormChunkDropdown = ({
  ...buttonProps
}: MinChunkDropdownButtonProps) => {
  const { form, options, disabledFields } = useTemplateTaskForm()

  const { control, watch } = form
  const { chunkDurations } = options

  const { field: minChunkDurationField } = useController({
    name: 'minChunkDuration',
    control,
  })

  const selectedMinChunkDuration = watch('minChunkDuration')

  return (
    <MinChunkDurationDropdown
      chunkDurations={chunkDurations}
      selectedMinChunkDuration={selectedMinChunkDuration}
      onChange={minChunkDurationField.onChange}
      icon={<ViewGridSolid />}
      {...buttonProps}
      disabled={disabledFields.has('duration')}
    />
  )
}
