import { PlusSolid } from '@motion/icons'

import { type Row } from '@tanstack/react-table'
import { useSetupProjectModalUrl } from '~/areas/project/modals/setup-project-modal/hooks'
import { useProjectModalUrl } from '~/global/navigation'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'

import { parseProjectValuesToSearchParams } from './utils'

import { type GroupedNode } from '../../../grouping'
import { useInferProjectDataHandler } from '../../../tree-list/cells/hooks'
import { type ProjectInferItem } from '../../../tree-list/cells/hooks/utils'
import { RowNameContainer } from '../common'
import { SelectableProjectDatesRow } from '../selectable-project-dates-row'

export const AddProjectRow = <T extends GroupedNode>(props: {
  row?: Row<T>
}) => {
  const navigate = useNavigate()
  const item = props.row?.original.value ?? {}
  const inferProjectData = useInferProjectDataHandler()
  const buildProjectModalUrl = useProjectModalUrl()
  const buildSetupProjectModalUrl = useSetupProjectModalUrl()

  const inferredData =
    'value' in item ? inferProjectData(item as ProjectInferItem) : null
  const inferredSearchParams = useMemo(() => {
    if (inferredData == null) {
      return {}
    }
    return parseProjectValuesToSearchParams(inferredData)
  }, [inferredData])

  const selectProjectDates = (startDate: string, dueDate: string) => {
    navigate(
      buildSetupProjectModalUrl({
        forStartDate: startDate,
        forDueDate: dueDate,
        ...inferredSearchParams,
      })
    )
  }

  return (
    <div className='w-full h-full flex flex-row items-center relative'>
      <RowNameContainer
        className='text-semantic-neutral-text-subtle text-xs'
        onClick={() =>
          navigate(
            buildProjectModalUrl({ project: 'new', ...inferredSearchParams })
          )
        }
      >
        <PlusSolid className='w-4 h-4 mr-2' />
        Add Project Row
      </RowNameContainer>
      <div className='w-full h-full absolute border-b border-semantic-neutral-border-light'>
        <SelectableProjectDatesRow
          onSelectDates={selectProjectDates}
          stacked={false}
        />
      </div>
    </div>
  )
}
