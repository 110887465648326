import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { TutorialButtonLink } from '~/global/components'

type FlowTutorialButtonProps = {
  rounded?: boolean
}
export function FlowTutorialButton({
  rounded = true,
}: FlowTutorialButtonProps) {
  return (
    <TutorialButtonLink
      lesson='workflow-templates'
      rounded={rounded}
      onClick={() => {
        recordAnalyticsEvent('FLOW_TUTORIAL_CLICK')
      }}
    />
  )
}
