import { calculateDurationInMinutes } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import type { RecurringTaskSchema, TaskSchema } from '@motion/zod/client'

import { type UFFeedbackFromElement } from '~/@types/analytics'
import { usePage } from '~/routing'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

function useUFFeedbackFromElement(): UFFeedbackFromElement {
  const page = usePage()
  if (page === 'calendar') {
    return 'calendar'
  }
  if (page === 'agenda') {
    return 'agenda'
  }
  return 'pm'
}

export function useRecordCalendarTaskChangeUFFeedback() {
  const fromElement = useUFFeedbackFromElement()

  return useCallback(
    (originalTask: TaskSchema, newTask: TaskSchema) => {
      if (
        originalTask?.scheduledStart == null ||
        originalTask?.scheduledEnd == null ||
        newTask?.scheduledStart == null ||
        newTask?.scheduledEnd == null
      ) {
        return
      }

      const originalDuration = calculateDurationInMinutes(
        originalTask.scheduledStart,
        originalTask.scheduledEnd
      )
      const newDuration = calculateDurationInMinutes(
        newTask.scheduledStart,
        newTask.scheduledEnd
      )

      if (
        originalDuration != null &&
        newDuration != null &&
        originalDuration !== newDuration
      ) {
        recordAnalyticsEvent('UF_FEEDBACK', {
          type: 'task_duration_incorrect',
          good_or_bad: 'bad',
          from_element: fromElement,
          user_action:
            newDuration > originalDuration
              ? 'increased_time'
              : 'decreased_time',
          old_duration: originalDuration,
          new_duration: newDuration,
        })
      }

      // also moved task completed and not
      if (originalTask.scheduledStart !== newTask.scheduledStart) {
        const userAction = getUserActionForTaskMove(originalTask, newTask)

        if (userAction == null) return

        recordAnalyticsEvent('UF_FEEDBACK', {
          type:
            newTask.completedTime != null
              ? 'completed_task_incorrect'
              : 'time_was_incorrect',
          good_or_bad: 'bad',
          from_element: fromElement,
          user_action: userAction,
          from_date: originalTask.scheduledStart,
          to_date: newTask.scheduledStart,
        })
      }
    },
    [fromElement]
  )
}

function getUserActionForTaskMove(
  originalTask: TaskSchema,
  newTask: TaskSchema
):
  | 'moved_earlier'
  | 'moved_later'
  | 'moved_future_date'
  | 'moved_past_date'
  | 'completed_earlier_date'
  | 'completed_earlier_time'
  | 'completed_later_date'
  | 'completed_later_time'
  | null {
  if (originalTask.scheduledStart == null || newTask.scheduledStart == null) {
    return null
  }

  const taskIsCompleted =
    originalTask.completedTime != null && newTask.completedTime != null
  const originalScheduledDate = DateTime.fromISO(originalTask.scheduledStart)
  const newScheduledDate = DateTime.fromISO(newTask.scheduledStart)

  const movedLater =
    originalScheduledDate.toMillis() < newScheduledDate.toMillis()

  const movedWithinSameDay = originalScheduledDate.hasSame(
    newScheduledDate,
    'day'
  )

  if (taskIsCompleted) {
    if (movedWithinSameDay) {
      return movedLater ? 'completed_later_time' : 'completed_earlier_time'
    }
    // moved across days
    return movedLater ? 'completed_later_date' : 'completed_earlier_date'
  }

  // not a completed task
  if (movedWithinSameDay) {
    return movedLater ? 'moved_later' : 'moved_earlier'
  }

  // moved across days
  return movedLater ? 'moved_future_date' : 'moved_past_date'
}

export function useRecordTaskStartDateChangeUFFeedback() {
  const fromElement = useUFFeedbackFromElement()

  return useCallback(
    (
      originalTask: TaskSchema | RecurringTaskSchema | null | undefined,
      newTask: TaskSchema | RecurringTaskSchema | null | undefined
    ) => {
      if (
        originalTask?.type === 'RECURRING_TASK' ||
        newTask?.type === 'RECURRING_TASK' ||
        originalTask?.startDate == null ||
        newTask?.startDate == null
      ) {
        return
      }

      const userAction = getUserActionForTaskStartDateChange(
        originalTask,
        newTask
      )

      if (userAction == null) return

      recordAnalyticsEvent('UF_FEEDBACK', {
        type: 'task_start_date_incorrect',
        good_or_bad: 'bad',
        from_element: fromElement,
        user_action: userAction,
        from_date: originalTask.startDate,
        to_date: newTask.startDate,
      })
    },
    [fromElement]
  )
}

function getUserActionForTaskStartDateChange(
  originalTask: TaskSchema,
  newTask: TaskSchema
):
  | 'moved_earlier'
  | 'moved_later'
  | 'moved_future_date'
  | 'moved_past_date'
  | null {
  if (originalTask.startDate == null || newTask.startDate == null) {
    return null
  }

  const originalStartDate = DateTime.fromISO(originalTask.startDate)
  const newStartDate = DateTime.fromISO(newTask.startDate)

  const movedLater = originalStartDate.toMillis() < newStartDate.toMillis()
  const movedWithinSameDay = originalStartDate.hasSame(newStartDate, 'day')

  if (movedWithinSameDay) {
    return movedLater ? 'moved_later' : 'moved_earlier'
  }

  return movedLater ? 'moved_future_date' : 'moved_past_date'
}

export function useRecordTriedDraggingAgendaItemUFFeedback() {
  return useCallback(() => {
    recordAnalyticsEvent('UF_FEEDBACK', {
      type: 'tried_dragging',
      from_element: 'agenda',
      good_or_bad: 'bad',
    })
  }, [])
}

export function useRecordSurveyUFFeedback() {
  return useCallback(
    (
      feedbackType:
        | 'day_was_correct'
        | 'day_was_incorrect'
        | 'order_was_correct'
        | 'order_was_incorrect',
      goodOrBad: 'good' | 'bad'
    ) =>
      () => {
        recordAnalyticsEvent('UF_FEEDBACK', {
          type: feedbackType,
          good_or_bad: goodOrBad,
          from_element: 'agenda',
        })
      },
    []
  )
}

export function useRecordResolvePastDueTaskUFFeedback() {
  return useCallback(
    (
      userAction:
        | 'meet_deadline_asap'
        | 'meet_deadline_hard'
        | 'extend'
        | 'ignore'
        | 'complete_or_cancel'
    ) => {
      recordAnalyticsEvent('UF_FEEDBACK', {
        type: 'task_past_due',
        good_or_bad: 'bad',
        from_element: 'past_due',
        user_action: userAction,
      })
    },
    []
  )
}
