import { twMerge } from 'tailwind-merge'

export type HeaderProps = {
  className?: string
}

export const Header = ({
  className,
  children,
}: HeaderProps & JSX.IntrinsicElements['h1']) => {
  return (
    <h1
      className={twMerge(
        'dark:text-dark-100 text-light-1200 mb-0 text-3xl font-semibold',
        className
      )}
    >
      {children}
    </h1>
  )
}
