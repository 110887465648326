import { NowIndicator } from '@motion/ui/calendar'

import { type CalendarOptions, type EventMountArg } from '@fullcalendar/core'
import { SlotLabelContent } from '~/areas/calendar/main-calendar/slot-label-content'
import { useScheduleAssistantCalendarContext } from '~/areas/event/modals/schedule-assistant/hooks'
import { useIsShiftKeyPressed } from '~/components/Calendar/components/Main/use-isshiftpressed'
import { CALENDAR_LICENSE_KEY } from '~/storageConstants'
import { useMemo } from 'react'

import { CalendarDayHeader } from '../calendar-day-header'

export function useScheduleAssistantCommonProps(): CalendarOptions {
  const [{ mode, syncSessionRange }] = useScheduleAssistantCalendarContext()
  const isShiftKeyPressed = useIsShiftKeyPressed()

  return useMemo(() => {
    return {
      allDayContent: () => '',
      allDaySlot: true,
      dragScroll: false,
      // Disable all editing for schedule assistant events,
      // New Events editing is enabled on `useFullCalendarEvents`
      editable: false,
      eventResizableFromStart: true,
      selectMinDistance: 2,
      eventTimeFormat: {
        hour: 'numeric',
        meridiem: false,
        minute: '2-digit',
        omitZeroMinute: true,
      },
      headerToolbar: false,
      height: '100%',
      nowIndicator: true,
      schedulerLicenseKey: CALENDAR_LICENSE_KEY,
      // When the date range changes, don't reset the current scroll time
      // https://fullcalendar.io/docs/scrollTimeReset
      scrollTimeReset: false,
      selectMirror: true,
      selectable: mode === 'create-new',
      slotDuration: '00:15',
      slotLabelInterval: '01:00',
      slotEventOverlap: false,
      snapDuration: isShiftKeyPressed ? '00:01' : '00:15',
      eventClassNames: ['group/calendar-event'],
      validRange:
        mode === 'select-existing' && syncSessionRange != null
          ? {
              start: syncSessionRange.start.toISODate(),
              end: syncSessionRange.end.toISODate(),
            }
          : undefined,
      eventDidMount: (arg: EventMountArg) => {
        arg.el.dataset.eventId = arg.event.id
      },
      nowIndicatorContent: ({ isAxis }) => {
        if (!isAxis) {
          return <NowIndicator />
        }
      },
      slotLabelContent: (info) => <SlotLabelContent info={info} />,
      dayHeaderContent: (info) => <CalendarDayHeader info={info} />,
    }
  }, [isShiftKeyPressed, mode, syncSessionRange])
}
