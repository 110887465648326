import { ArrowLeftSolid, ArrowRightSolid, CheckSolid } from '@motion/icons'
import { GradientButton, IconButton } from '@motion/ui/base'

import { TutorialPlayer } from '~/areas/tutorials'

import { type TutorialLesson } from '../types'

type TutorialViewerProps = {
  lesson: TutorialLesson | undefined
  sectionTitle: string
  completed: boolean
  onNextLesson: () => void
  onPrevLesson: () => void
  onCompleteLesson: () => void
}

export const TutorialViewer = ({
  lesson,
  sectionTitle,
  completed,
  onNextLesson,
  onPrevLesson,
  onCompleteLesson,
}: TutorialViewerProps) => {
  if (!lesson) return null

  return (
    <div className='flex flex-col gap-6'>
      <div>
        <p className='text-semantic-neutral-text-subtle text-xs pb-2'>
          {sectionTitle}
        </p>
        <p className='text-xl font-semibold text-semantic-neutral-text-default'>
          {lesson.title}
        </p>
        <div
          className='text-semantic-neutral-text-subtle text-sm pt-4'
          dangerouslySetInnerHTML={{ __html: lesson.description }}
        />
      </div>
      <div className='flex w-full rounded-lg overflow-hidden'>
        <TutorialPlayer youtubeId={lesson.youtubeId} lessonId={lesson.id} />
      </div>
      <div className='flex justify-between'>
        <GradientButton
          size='small'
          sentiment={completed ? 'promotion' : 'purple'}
          disabled={completed}
          onClick={onCompleteLesson}
        >
          {completed ? (
            <>
              <CheckSolid /> Completed
            </>
          ) : (
            'Complete'
          )}
        </GradientButton>
        <div className='flex flex-row items-center gap-2'>
          <IconButton
            icon={ArrowLeftSolid}
            sentiment='neutral'
            onClick={onPrevLesson}
          />
          <IconButton
            icon={ArrowRightSolid}
            sentiment='neutral'
            onClick={onNextLesson}
          />
        </div>
      </div>
    </div>
  )
}
