import { type TeamMemberSerializer } from '@motion/rpc-types'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { BackendApiService } from './BackendApiService'
import { type MutationResponse } from './teamsService'

import { Events as AnalyticsEvents } from '../analyticsEvents'
import { type PMTeamType } from '../components/ProjectManagement/types'
import { type TeamMemberInvite } from '../state/TeamTypes'

export interface RemoveMemberResponse extends MutationResponse {
  leftTeam: boolean
}

export class TeamMembersService extends BackendApiService {
  static id = 'TeamMembersService' as const

  async update(
    member: Partial<TeamMemberSerializer>,
    teamId: PMTeamType['id']
  ): Promise<TeamMemberSerializer> {
    recordAnalyticsEvent(AnalyticsEvents.PROJECT_MANAGEMENT_UPDATE_TEAM_MEMBER)
    return this.request(`teams/${teamId}/members/${member.id}`, 'PATCH', member)
  }

  async inviteTeamMembers(
    teamMemberInvites: TeamMemberInvite[],
    teamId: string
  ): Promise<MutationResponse> {
    return this.request(
      `teams/${teamId}/members`,
      'POST',
      JSON.stringify(teamMemberInvites)
    )
  }

  async remove(
    memberId: string,
    teamId: string
  ): Promise<RemoveMemberResponse> {
    return this.request(`teams/${teamId}/members/${memberId}`, 'DELETE')
  }
}

export const instance = new TeamMembersService()
