import { usePrevious } from '@motion/react-core/hooks'
import { getCacheEntry } from '@motion/rpc-cache'
import { fuzzySearch } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'

import { useQueryClient } from '@tanstack/react-query'
import { useFileUploadState } from '~/areas/attachments/contexts'
import { useMemo } from 'react'

import { type AttachmentGroup } from '../types'

type UseFilterAttachmentGroupsParams = {
  query?: string
  groups: AttachmentGroup[]
}

export function useFilterAttachmentGroups({
  query,
  groups,
}: UseFilterAttachmentGroupsParams) {
  const queryClient = useQueryClient()

  const { activeFileUploads } = useFileUploadState()

  const activeFileUploadsTargets = useMemo(
    () => activeFileUploads.map(({ targetId }) => targetId),
    [activeFileUploads]
  )

  const prevActiveFileUploadsTargets = usePrevious(activeFileUploadsTargets)

  const groupFilter = ({ targetId, uploadedFileIds }: AttachmentGroup) =>
    uploadedFileIds.length > 0 ||
    activeFileUploadsTargets.includes(targetId) ||
    prevActiveFileUploadsTargets?.includes(targetId)

  if (!query)
    return {
      filteredGroups: groups.filter(groupFilter),
    }

  const groupsWithAttachments = groups.map(
    ({ targetId, targetType, workspaceId, uploadedFileIds }) => {
      const attachments = uploadedFileIds
        .map((id) => {
          const cachedFile = getCacheEntry(queryClient, 'uploadedFiles', id)

          // The file should always be in the cache, but if it's not, log an error
          if (!cachedFile) {
            Sentry.captureMessage('Attachment not found in cache', {
              extra: { attachmentId: id },
            })
            return
          }

          return cachedFile.value
        })
        .filter(Boolean)

      return {
        targetId,
        targetType,
        workspaceId,
        attachments,
      }
    }
  )

  const filteredGroupsWithAttachments = groupsWithAttachments.map(
    ({ attachments, ...rest }) => {
      const filteredAttachments = fuzzySearch({
        query,
        items: attachments,
        keys: ['fileName'],
      })

      return {
        ...rest,
        attachments: filteredAttachments,
      }
    }
  )

  const filteredGroups = filteredGroupsWithAttachments
    .map(
      ({ attachments, ...rest }) =>
        ({
          ...rest,
          uploadedFileIds: attachments.map(({ id }) => id),
        }) satisfies AttachmentGroup
    )
    .filter(groupFilter)

  return {
    filteredGroups,
  }
}
