import { PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

export function StageTable() {
  // TODO: add open stage creation/edit modal, fetch stages

  const handleOpenStageModal = () => {
    recordAnalyticsEvent('STAGE_MODAL_OPENED', { new: true })
  }
  return (
    <FlowSettingsTable>
      <SettingsTitle>
        <SettingsTitleText>Workspace Stages</SettingsTitleText>
        <Button onClick={handleOpenStageModal} size='small'>
          <PlusSolid />
          Create new stages
        </Button>
      </SettingsTitle>
      Stages placeholder
    </FlowSettingsTable>
  )
}

export const FlowSettingsTable = classed('div', {
  base: 'flex flex-col items-start gap-4 self-stretch',
})

export const SettingsTitle = classed('div', {
  base: 'flex justify-between items-center self-stretch',
})

export const SettingsTitleText = classed('span', {
  base: 'text-semantic-neutral-text-default text-center text-base font-semibold leading-5',
})
