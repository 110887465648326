import { type PMTaskType } from '@motion/rpc/types'

import { TemplateBlockersDropdown } from './blockers/template-blockers-dropdown'

import { useTemplateTaskForm } from '../hooks'

export const FormBlockersDropdownContainer = ({
  type,
}: {
  type: 'blockedBy' | 'blocks'
}) => {
  const {
    form,
    options: { savedTemplateProject, savedTemplateTask },
  } = useTemplateTaskForm()

  const projectId = form.watch('projectId')

  const task = {
    ...savedTemplateTask?.task,
    templateProjectId: savedTemplateProject?.id,
  } as PMTaskType

  const sameProject = task?.projectId === projectId
  const disabled = task == null || !sameProject

  return (
    <TemplateBlockersDropdown
      type={type}
      task={task}
      disabled={disabled}
      sameProject={sameProject}
    />
  )
}
