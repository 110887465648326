import { createAsyncThunk } from '@reduxjs/toolkit'

import { getProxy } from './backgroundProxy'

export interface SubscriptionProps {
  isMonthly: boolean
  setupIntentId?: string
  trialLength: number
}

export interface SetupIntentResponse {
  id: string
  clientSecret: string
}

export interface SubscriptionResponse {
  error?: string
  id?: string
  clientSecret?: string
  subscription?: {
    id: string
  }
}

const corrilyServiceHandler = getProxy('CorrilyService')

export const createSetupIntent = createAsyncThunk<
  SetupIntentResponse | undefined,
  void
>('corrily/createSetupIntent', () => {
  return corrilyServiceHandler.createSetupIntent()
})

export const createSubscription = createAsyncThunk<
  SubscriptionResponse,
  SubscriptionProps
>('corrily/createSubscription', ({ isMonthly, setupIntentId, trialLength }) => {
  return corrilyServiceHandler.createSubscription(
    isMonthly,
    trialLength,
    setupIntentId
  )
})
