import { useSharedStateContext } from '@motion/react-core/shared-state'
import { type Group } from '@motion/ui/base'

import { AppWorkspaceContext } from '~/global/contexts'
import { type ReactNode } from 'react'

export type WorkspaceItemProps<T extends { workspaceId: string }> = {
  item: T
  children: ReactNode
}

export const WorkspaceItem = <T extends { workspaceId: string }>(
  props: WorkspaceItemProps<T>
) => {
  const api = useSharedStateContext()
  const ctx = api.get(AppWorkspaceContext)

  const workspaceName =
    ctx.workspaces.byId[props.item.workspaceId]?.name ?? 'unknown'

  return (
    <div className='grid grid-cols-[auto,auto,minmax(0,auto)] overflow-hidden gap-x-1 items-center'>
      {props.children}
      <span className='text-xs text-dropdown-item-text-disabled text-nowrap text-ellipsis overflow-hidden'>
        ({workspaceName})
      </span>
    </div>
  )
}

export type WorkspaceItemGroupHeaderProps<T extends { workspaceId: string }> = {
  group: Group<T>
  children: ReactNode
}

export const WorkspaceItemGroupHeader = <T extends { workspaceId: string }>(
  props: WorkspaceItemGroupHeaderProps<T>
) => {
  const api = useSharedStateContext()
  const ctx = api.get(AppWorkspaceContext)

  const subtitle =
    props.group.items.length === 1
      ? ctx.workspaces.byId[props.group.items[0].workspaceId].name
      : `${props.group.items.length} workspaces`

  return (
    <div className='grid grid-cols-[auto,minmax(0,auto)] overflow-hidden gap-x-1 items-center'>
      {props.children}
      <span className='text-xs text-dropdown-item-text-disabled text-nowrap text-ellipsis overflow-hidden'>
        ({subtitle})
      </span>
    </div>
  )
}
