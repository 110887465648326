import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { NEW_EVENT_ID } from '~/areas/calendar/utils'
import { microTask } from '~/areas/calendar/utils/micro-task'
import { FullCalendarThemeSelector } from '~/components/Common/FullCalendarThemeSelector/FullCalendarThemeSelector'
import { selectCalendarStartDay } from '~/state/calendar/calendarSlice'
import { useAppSelector } from '~/state/hooks'
import { useEffect, useRef } from 'react'

import { useScheduleAssistantCalendarProps } from './hooks'

import { useScheduleAssistantCalendarContext } from '../../hooks'

export function ScheduleAssistantCalendar() {
  const calendarRef = useRef<FullCalendar | null>(null)
  const calendarStartDay = useAppSelector(selectCalendarStartDay)
  const [calendarState, setCalendarState] =
    useScheduleAssistantCalendarContext()

  const calendarProps = useScheduleAssistantCalendarProps()

  useEffect(
    function handleBaseDateChange() {
      const baseDate = calendarState.selectedDate.toJSDate()
      // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
      microTask(() => {
        calendarRef.current?.getApi().gotoDate(baseDate)
      })
    },
    [calendarState.selectedDate]
  )

  useEffect(
    // When user selects a new event, we want to scroll to the new event
    function handleSelectedNewEventChange() {
      if (calendarState.mode !== 'create-new') return

      const newEvent = calendarState.selectedCalendarEvent?.new

      if (newEvent == null) return

      const calendarApi = calendarRef.current?.getApi()

      microTask(() => {
        calendarApi?.gotoDate(newEvent.start)
      })
    },
    [calendarState.mode, calendarState.selectedCalendarEvent?.new]
  )

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const target = e.target as HTMLElement

      if (
        !target.closest('.fc-view') ||
        target.closest('.fc-event') ||
        calendarState.mode !== 'create-new'
      )
        return

      // Hide the existing new event when we're dragging to create a new event
      microTask(() => {
        calendarRef.current
          ?.getApi()
          .getEvents()
          .forEach((event) => {
            if (event.id === NEW_EVENT_ID) {
              event.setProp('display', 'none')
            }
          })
      })
    }

    window.addEventListener('mousedown', handleMouseDown)
    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
    }
  }, [setCalendarState, calendarState.mode])

  useEffect(() => {
    if (calendarState.selectedCalendarEvent == null) return
    // Micro task is needed due to a rendering quirk of FullCalendar, should be fixed in newer versions.
    // (?) Manually unselect all the events when we set a selected even to clear FullCalendar's temporary selection
    microTask(() => {
      calendarRef.current?.getApi().unselect()
    })
  }, [calendarState.selectedCalendarEvent])

  return (
    <FullCalendarThemeSelector>
      <FullCalendar
        plugins={[interactionPlugin, timeGridPlugin, resourceTimeGridPlugin]}
        ref={calendarRef}
        initialView='timeGridWeek'
        {...calendarProps}
        dayHeaders
        selectConstraint={{ startTime: '00:00', endTime: '24:00' }}
        firstDay={calendarStartDay === 'sunday' ? 0 : 1}
      />
    </FullCalendarThemeSelector>
  )
}
