import { canParseUrl } from '@motion/utils/string'
import { type GetCustomFieldCategories } from '@motion/zod/client'

export type CustomFieldCategoriesConfig =
  GetCustomFieldCategories['models']['customFieldCategories'][number]

export function getCustomFieldCategoryConfigList(
  categoryList: GetCustomFieldCategories['ids'],
  categoryMap: GetCustomFieldCategories['models']['customFieldCategories']
): CustomFieldCategoriesConfig[] {
  return categoryList.map((type) => categoryMap[type])
}

export function validateUrl(value: string | null) {
  return value != null && canParseUrl(value)
}
