import { Paragraph } from '../../Common'
import { ActionDialog } from '../../Common/Dialog/ActionDialog/ActionDialog'

type Props = {
  onConfirm: () => void
  onCancel: () => void
}

export const CalendarTurnIntoFixedTimeTaskModal = ({
  onConfirm,
  onCancel,
}: Props) => {
  return (
    <ActionDialog
      actionButtonText='Move task'
      actionButtonHandler={onConfirm}
      size='large'
      isOpen
      onClose={onCancel}
      cancelButtonHandler={onCancel}
      showClose
      title='Are you sure you want to move this task?'
      className='max-w-xl'
    >
      <Paragraph>
        Moving this task will turn it into a fixed-time task. Motion won&apos;t
        automatically reschedule it until the task ends. If the task ends
        without being completed, Motion will convert it back into an
        auto-scheduled task.
      </Paragraph>
    </ActionDialog>
  )
}
