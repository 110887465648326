import {
  type DefaultAssigneeDropdownOption,
  sortMembers,
  unassignedMember,
} from '@motion/ui-logic'
import { type FlowTemplateRoleAssignee } from '@motion/ui-logic/pm/project'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useWorkspaceActiveMembers } from '~/global/hooks'
import { useMemo } from 'react'

// Add additional types here as a discriminated union on `type`
type AdditionalDropdownOption = FlowTemplateRoleAssignee

export type AssigneeDropdownOption =
  | DefaultAssigneeDropdownOption
  | AdditionalDropdownOption

type Props = {
  workspaceId: string
  additionalOptions?: AdditionalDropdownOption[]
  removeUnassignedOption?: boolean
}

export function useAssigneeDropdownSortedOptions({
  workspaceId,
  additionalOptions = [],
  removeUnassignedOption = false,
}: Props): AssigneeDropdownOption[] {
  const { uid: currentUserId } = useAuthenticatedUser()
  const workspaceUsers = useWorkspaceActiveMembers(workspaceId)

  return useMemo(() => {
    const sortedList = sortMembers({
      members: [
        removeUnassignedOption ? null : unassignedMember,
        ...workspaceUsers,
      ]
        .filter(Boolean)
        .map((user) => ({
          ...user,
          type: 'default' as const,
        })),
      currentUserId,
      unassignedUserId: unassignedMember.user.id,
    })

    return [
      ...additionalOptions.filter((opt) => opt.name !== ''),
      ...sortedList,
    ]
  }, [additionalOptions, removeUnassignedOption, workspaceUsers, currentUserId])
}
