import { type Inclusion } from '@motion/zod/client'

import { ConditionSelector } from './filter-item/condition-dropdown'
import { FilterItem } from './filter-item/filter-item'
import { type ConditionOption } from './filter-item/types'

import { useFieldFilter } from '../../../context'
import { type DropdownFilterProps } from '../../types'

const INCLUSION_CONDITIONS = [
  { label: 'Yes', id: 'include' },
  { label: 'No', id: 'exclude' },
] satisfies ConditionOption<Inclusion>[]

export const ConnectedAutoScheduledFilter = (
  props: DropdownFilterProps<'tasks'>
) => {
  const [value, setValue] = useFieldFilter(props.target, 'autoScheduled')

  const label =
    INCLUSION_CONDITIONS.find((c) => c.id === value)?.label ??
    INCLUSION_CONDITIONS[0].label

  return (
    <FilterItem
      hideInvert
      label='Auto-scheduled'
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConditionSelector
          conditions={INCLUSION_CONDITIONS}
          value={value}
          onSelect={(condition: Inclusion) => setValue(condition)}
        />
      )}
    >
      {label}
    </FilterItem>
  )
}
