import { useState } from 'react'
import { OptionSpaceDesktopContainer } from '../OptionSpace/OptionSpaceDesktopContainer'

export type DesktopOnlyPageOptions = 'optionSpace'

export const DesktopOnlyContainer = () => {
  const [desktopPage] = useState<DesktopOnlyPageOptions>('optionSpace')

  switch (desktopPage) {
    case 'optionSpace':
      return (
        <div className='bg-calendar-bg-default flex-1 overflow-auto'>
          <OptionSpaceDesktopContainer />
        </div>
      )
  }
}
