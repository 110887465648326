import { AlertDialog } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useEffect } from 'react'

const COOKIES_COPY = `We use cookies to enhance the
performance, user experience and
functionality. By using Motion, you agree to
the use of cookies for these purposes.`

interface GdprCookiesAlertProps {
  acceptCookies: () => void
  customizeCookies: () => void
}

export const GdprCookiesAlert = ({
  acceptCookies,
  customizeCookies,
}: GdprCookiesAlertProps) => {
  useEffect(function logAnalytics() {
    recordAnalyticsEvent('GDPR_COOKIES_MODAL_SHOWN')
  }, [])

  return (
    <AlertDialog
      visible
      title='Cookies'
      description={COOKIES_COPY}
      onClose={() => {}}
      actionBarOrientation='horizontal'
      actions={[
        {
          label: 'Customize',
          sentiment: 'neutral',
          value: false,
          onClick: customizeCookies,
        },
        {
          label: 'Accept all cookies',
          sentiment: 'primary',
          value: true,
          onClick: acceptCookies,
        },
      ]}
    />
  )
}
