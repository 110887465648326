import {
  type TaskTextVariableUpdater,
  updateTextWithVariable,
} from '~/areas/flows/flow-template-modal/utils'
import { type VariableLocation } from '~/areas/flows/tiptap-extensions'

import { useTaskDefinitionForm } from '../../use-task-definition-form'

export function useTaskTextVariableUpdater(
  type: VariableLocation
): TaskTextVariableUpdater {
  const {
    form: { setValue, getValues },
  } = useTaskDefinitionForm()

  return ({ wrappedKey, operation, replacementKey }) => {
    const updatedText = updateTextWithVariable(
      getValues(type),
      operation,
      wrappedKey,
      replacementKey
    )

    setValue(type, updatedText, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }
}
