import { Tooltip } from '@motion/ui/base'
import { ChunkBubbleText } from '@motion/ui/calendar'

import { type ScheduledTaskChunkWithRelation } from '~/global/proxies'

import { ChunkInfoTooltipContent } from '../tooltips/chunk-info-tooltip-content'

type TaskChunkBubbleTextProps = {
  entity: ScheduledTaskChunkWithRelation
}

export function TaskChunkBubbleText({ entity }: TaskChunkBubbleTextProps) {
  if (entity.task == null) return null

  const tooltipContent = (
    <div className='flex flex-col gap-2 text-left'>
      <ChunkInfoTooltipContent
        currentTaskId={entity.task.id}
        parentChunkTaskId={entity.task.parentChunkTask.id}
      />
    </div>
  )

  return (
    <Tooltip renderContent={() => tooltipContent}>
      <ChunkBubbleText
        chunkNumber={entity.chunkNumber}
        chunkTotal={entity.chunkTotal}
      />
    </Tooltip>
  )
}
