export const TOOLTIP_MESSAGES = {
  CANCELED_BLOCKERS:
    'This task cannot be scheduled because its {{ blockerText }} {{ linkingVerb }} cancelled. Please remove the {{ blockerText }} to schedule this task.',

  UNSCHEDULED_BLOCKERS:
    'This task cannot be scheduled because its {{ blockerText }} {{ linkingVerb }} not scheduled. Please turn on auto-scheduling on the {{ blockerText }}, or remove the {{ blockerText }} to schedule this task.',

  PAST_DUE_BLOCKERS:
    'This task is scheduled past deadline because its {{ blockerText }} {{ linkingVerb }} scheduled past deadline.',

  CALENDAR_HOVER_HINT:
    'Hover over the blocked icon on this task to see its blockers.',
} as const
