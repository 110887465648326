import { clearTaskForParams } from '@motion/ui-logic/pm/task'

import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useCloseTaskModal = () => {
  const [, setSearchParams] = useSearchParams()

  return useCallback(() => {
    setSearchParams((prev) => {
      clearTaskForParams(prev)
      prev.delete('task')
      return prev
    })
  }, [setSearchParams])
}
