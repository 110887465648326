import { useDependantState } from '@motion/react-core/hooks'
import { getWindowData } from '@motion/web-base/env'

import {
  CustomField,
  type CustomFieldInputProps,
} from '~/areas/project-management/custom-fields'
import { ModalFieldButton } from '~/areas/task-project/components'
import { forwardRef } from 'react'

export const Text = forwardRef<HTMLLabelElement, CustomFieldInputProps>(
  (props, ref) => {
    const { value, onChange: submitHandler, size, ...rest } = props
    const { isMac } = getWindowData()

    const [internalValue, setInternalValue] = useDependantState(
      () => value,
      [value]
    )

    const onChange = (v: string) => {
      setInternalValue(v)
    }

    const onSubmit = () => {
      if (internalValue === value) {
        return
      }

      try {
        submitHandler(internalValue)
      } catch (error) {
        setInternalValue(value)
      }
    }

    const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
      const { key, metaKey, ctrlKey } = evt

      if (['Enter', 'Escape'].includes(key)) {
        evt.preventDefault()
        return onSubmit()
      }

      if (
        (isMac && metaKey && key === 's') ||
        (!isMac && ctrlKey && key === 's')
      ) {
        evt.preventDefault()
        onSubmit()
      }
    }

    return (
      <ModalFieldButton
        label={props.name}
        disabled={props.disabled}
        size={size}
      >
        <CustomField.Text
          ref={ref}
          value={internalValue}
          onChange={onChange}
          onBlur={onSubmit}
          onKeyDown={handleKeyDown}
          {...rest}
        />
      </ModalFieldButton>
    )
  }
)

Text.displayName = 'TaskModalCustomFieldText'
