import { DocumentTextSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, Tab } from '@motion/ui/base'

import { APIKeySettings } from './APIKeys'
import { LazyAPIOverview } from './lazy-api-overview'

import { TabbedSettingsPage } from '../../Components/SettingPage'

export const APISettings = () => {
  const header = (
    <div className='flex justify-between items-center'>
      <span>API</span>
      <Button url='https://docs.usemotion.com/'>
        <DocumentTextSolid /> View API Documentation
      </Button>
    </div>
  )

  return (
    <Container>
      <TabbedSettingsPage title={header}>
        <Tab name='Overview' value='overview'>
          <div className='px-12 py-6'>
            <LazyAPIOverview />
          </div>
        </Tab>
        <Tab name='API Keys' value='keys'>
          <div className='px-12 py-6'>
            <APIKeySettings />
          </div>
        </Tab>
      </TabbedSettingsPage>
    </Container>
  )
}

const Container = classed('div', {
  base: 'bg-light-100 dark:bg-dark-1100 flex h-full w-full min-h-0 overflow-x-auto',
})
