import { CheckSolid, PencilSolid, XSolid } from '@motion/icons'
import { type UploadedFileSchema } from '@motion/rpc-types'
import { splitFilename, validateFilename } from '@motion/shared/files'
import { classed } from '@motion/theme'
import { IconButton, Tooltip } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { type Dispatch, Fragment, type SetStateAction, useState } from 'react'

import {
  VARIANT_ICON_BUTTON_SIZE,
  VARIANT_TEXTFIELD_SIZE,
} from '../../constants'

export type AttachmentFilenameProps = {
  fileName: UploadedFileSchema['fileName']
  isRenaming: boolean
  setIsRenaming: Dispatch<SetStateAction<boolean>>
  variant?: 'default' | 'compact'
  onRename: (newFileName: UploadedFileSchema['fileName']) => void
}

export function AttachmentFilename({
  fileName: fileNameProp,
  isRenaming,
  setIsRenaming,
  variant = 'default',
  onRename,
}: AttachmentFilenameProps) {
  const [errors, setErrors] = useState<string[]>([])
  const [fileName, fileExtension] = splitFilename(fileNameProp)

  const [newFileName, setNewFileName] = useState(fileName)

  const handleFilenameChange = (value: string) => {
    setNewFileName(value)

    const newErrors = validateFilename(
      value + (fileExtension ? `.${fileExtension}` : '')
    )
    setErrors(newErrors)
  }

  const handleFilenameKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveRenameClick()
    } else if (e.key === 'Escape') {
      handleCancelRenameClick()
    }
  }

  const handleRenameClick = () => {
    setIsRenaming(true)
  }

  const handleCancelRenameClick = () => {
    setIsRenaming(false)
    setNewFileName(fileName)
  }

  const handleSaveRenameClick = () => {
    if (errors.length > 0) return

    onRename(newFileName + (fileExtension ? `.${fileExtension}` : ''))
  }

  if (!isRenaming)
    return (
      <FilenameWrapper>
        <div className='flex-1 overflow-hidden text-ellipsis whitespace-nowrap'>
          {fileNameProp}
        </div>
        {variant === 'default' && (
          <ActionsContainer
            onClick={(e) => e.stopPropagation()}
            variant={variant}
          >
            <div className='opacity-0 group-hover:opacity-100'>
              <IconButton
                size={VARIANT_ICON_BUTTON_SIZE[variant]}
                variant='outlined'
                sentiment='neutral'
                aria-label='Rename attachment'
                icon={PencilSolid}
                onClick={handleRenameClick}
              />
            </div>
          </ActionsContainer>
        )}
      </FilenameWrapper>
    )

  return (
    <FilenameWrapper onClick={(e) => e.stopPropagation()}>
      <Tooltip
        renderContent={() => (
          <>
            {errors.map((error) => (
              <Fragment key={error}>
                {error}
                <br />
              </Fragment>
            ))}
          </>
        )}
        asChild
      >
        <>
          <TextField
            value={newFileName}
            onChange={handleFilenameChange}
            onKeyUp={handleFilenameKeyUp}
            placeholder='Enter a new name'
            sentiment={errors.length > 0 ? 'error' : 'default'}
            size={VARIANT_TEXTFIELD_SIZE[variant]}
            fullWidth
            autoFocus
          />
          {fileExtension ? (
            <span className='mr-1'>.{fileExtension}</span>
          ) : null}
        </>
      </Tooltip>
      <ActionsContainer variant={variant}>
        <IconButton
          size={VARIANT_ICON_BUTTON_SIZE[variant]}
          variant='muted'
          sentiment='primary'
          aria-label='Save attachment name'
          icon={CheckSolid}
          onClick={handleSaveRenameClick}
          disabled={errors.length > 0}
        />
        <IconButton
          size={VARIANT_ICON_BUTTON_SIZE[variant]}
          variant='muted'
          sentiment='neutral'
          aria-label='Cancel renaming attachment'
          icon={XSolid}
          onClick={handleCancelRenameClick}
        />
      </ActionsContainer>
    </FilenameWrapper>
  )
}

const FilenameWrapper = classed('div', {
  base: `
    flex flex-1 items-center gap-1 overflow-hidden
  `,
})

const ActionsContainer = classed('div', {
  base: `
    flex gap-1
  `,
  variants: {
    variant: {
      default: `mr-4`,
      compact: '',
    },
  },
})
