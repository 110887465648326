import { ScheduleSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { type ScheduleCollection } from '@motion/rpc-types'
import { PopoverTrigger } from '@motion/ui/base'
import { useFirestoreSettings } from '@motion/web-common/settings'

import { ModalFieldButton } from '~/areas/task-project/components'
import {
  CustomSchedulePanel,
  type CustomSchedulePanelProps,
} from '~/areas/tasks/modals/task-modal/components'
import { ScheduleDropdownContent } from '~/global/components/dropdowns'
import { useCallback, useState } from 'react'

type ScheduleFieldProps = {
  value: string | null
  onChange: (value: string) => void
}

export const ScheduleField = ({ value, onChange }: ScheduleFieldProps) => {
  const { data: firestoreSettings, refetch } = useFirestoreSettings()
  const [scheduleValue, setScheduleValue] = useDependantState(
    () => getSelectedSchedule(value, firestoreSettings?.schedules),
    [value, firestoreSettings?.schedules]
  )

  const changeHandler = useCallback(
    (value: string): void => {
      setScheduleValue(getSelectedSchedule(value, firestoreSettings?.schedules))
      onChange(value)
    },
    [firestoreSettings?.schedules, onChange, setScheduleValue]
  )

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ScheduleFieldContent
          close={close}
          onUpdate={refetch}
          value={value}
          onChange={changeHandler}
        />
      )}
    >
      <ModalFieldButton label='Schedule'>
        <ScheduleSolid />
        {scheduleValue?.title ?? 'None'}
      </ModalFieldButton>
    </PopoverTrigger>
  )
}

const getSelectedSchedule = (
  value: string | null,
  schedules: ScheduleCollection | undefined
) =>
  value != null ? (schedules?.[value as keyof typeof schedules] ?? null) : null

type ScheduleFieldContentProps = {
  close: () => void
  onUpdate: () => void
  value: string | null
  onChange: (value: string) => void
}

function ScheduleFieldContent({
  value,
  close,
  onUpdate,
  onChange,
}: ScheduleFieldContentProps) {
  const [scheduleStartTime, setScheduleStartTime] = useState('')
  const [scheduleEndTime, setScheduleEndTime] = useState('')
  const [scheduleIdealTime, setScheduleIdealTime] = useState<string | null>(
    null
  )

  const timeOnChange: CustomSchedulePanelProps['onChange'] = (values) => {
    setScheduleStartTime(values.start)
    setScheduleEndTime(values.end)
    setScheduleIdealTime(values.ideal)
  }

  return (
    <ScheduleDropdownContent
      close={close}
      selectedScheduleId={value ?? ''}
      onChange={onChange}
      onUpdate={onUpdate}
      renderCustomSchedulePanel={() => (
        <div className='border-l border-dropdown-border p-3'>
          <CustomSchedulePanel
            startTime={scheduleStartTime}
            endTime={scheduleEndTime}
            idealTime={scheduleIdealTime}
            onChange={timeOnChange}
          />
        </div>
      )}
    />
  )
}
