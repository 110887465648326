import { type FieldOperationSchema } from '@motion/shared/custom-fields'
import { PopoverTrigger } from '@motion/ui/base'
import { type WorkspaceSchema } from '@motion/zod/client'

import { MultiSelectContent } from '~/areas/custom-fields/components'
import { type ReactNode, useState } from 'react'

import { type BulkCustomFieldDropdownSwitchProps } from './bulk-global-custom-field-dropdown'

import { OperationTabsPanel } from '../operation-tabs-panel'

type MultiSelectCFType = Extract<
  BulkCustomFieldDropdownSwitchProps['customField'],
  { type: 'multiSelect' }
>

export type BulkMultiSelectDropdownProps = {
  workspaceId: WorkspaceSchema['id']
  customField: MultiSelectCFType
  onChange: (value: MultiSelectCFType['value']) => void
  children: ReactNode
}

export const BulkMultiSelectDropdown = ({
  workspaceId,
  customField,
  onChange,
  children,
}: BulkMultiSelectDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <BulkMultiSelectDropdownContent
          close={close}
          workspaceId={workspaceId}
          customField={customField}
          onChange={onChange}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type BulkMultiSelectDropdownContentProps = Omit<
  BulkMultiSelectDropdownProps,
  'children'
> & {
  close: () => void
}

const BulkMultiSelectDropdownContent = ({
  workspaceId,
  customField,
  onChange,
  close,
}: BulkMultiSelectDropdownContentProps) => {
  const value = customField.value ?? []
  const selectedIds = value.map((v) => v.id)
  const operationFromValue = value.length > 0 ? value[0].operation : 'add'

  const [operation, setOperation] =
    useState<FieldOperationSchema>(operationFromValue)

  const onValueChange = (op: FieldOperationSchema, value: string[]) => {
    onChange(value.map((id) => ({ id, operation: op })))
  }

  return (
    <MultiSelectContent
      close={close}
      workspaceId={workspaceId}
      customFieldId={customField.instanceId}
      selectedItemIds={selectedIds}
      onChange={(value) => {
        if (value.length === 0) {
          onChange(undefined)
        } else {
          onValueChange(operation, value.filter(Boolean))
        }
      }}
      hideBottomActionsSection
      renderContentTopSection={() => {
        return (
          <OperationTabsPanel
            selectedOperation={operation}
            onChange={(op) => {
              setOperation(op)
              if (selectedIds.length > 0) {
                onValueChange(op, selectedIds)
              }
            }}
          />
        )
      }}
    />
  )
}
