import { cloneDeep } from '@motion/utils/core'
import { DB, readPersistedStateMany } from '@motion/web-common/storage'

import { type RouteData } from '~/areas/project-management/pages/pm-v3/routes/types'
import { getDefaultView } from '~/areas/project-management/pages/pm-v3/views/defaults'
import { type MotionRoute } from '~/routing/api'
import { type LoaderFunctionArgs, Outlet } from 'react-router'

import {
  getPageTypeFromLoader,
  getStateKeyFromLoaderArgs,
  getStatePrefix,
  loadState,
  pageLoader,
  persistView,
} from './loaders'
import { log } from './log'

import {
  loggedRedirect,
  redirectLoader,
  relativeRedirect,
} from '../../../areas/project-management/pages/pm-v3/routes/utils/redirect'
import {
  type ViewState,
  ViewStateKey,
} from '../../../areas/project-management/pages/pm-v3/view-state'
import { ShellWithContext } from '../shell/shell'
import { ViewShell } from '../shell/view-shell'

let redirectCount = 0

function getViewFromUrlQuery(uri: string) {
  const parsed = new URL(uri)
  return parsed.searchParams.get('view')
}

function legacyLoader() {
  return (args: LoaderFunctionArgs) => {
    const viewId = getViewFromUrlQuery(args.request.url) ?? 'default'
    return relativeRedirect(args, `../../views/${viewId}`, { removeView: true })
  }
}

const pages: MotionRoute[] = [
  {
    path: '',
    index: true,
    async loader(args) {
      const stateKey = getStateKeyFromLoaderArgs(args)
      await DB.open()

      // TODO: Store the most recent view for a given page so that we can return to it
      // when someone click on the page again

      if (stateKey == null) return redirectLoader('./views/default')(args)

      const state = await readPersistedStateMany(DB.state, [ViewStateKey], {
        prefix: stateKey,
      })
      let viewId = (state.get(ViewStateKey) as ViewState | undefined)?.viewId
      if (viewId == null || viewId === '<default!view>') {
        viewId = 'default'
      }
      return redirectLoader(`./views/${viewId}`)(args)
    },
  },
  // TODO: folders aren't using default route params so it comes in as /views
  {
    path: 'views',
    async loader(args) {
      const stateKey = getStateKeyFromLoaderArgs(args)
      await DB.open()

      if (stateKey == null) return redirectLoader('./default')(args)

      const state = await readPersistedStateMany(DB.state, [ViewStateKey], {
        prefix: stateKey,
      })
      let viewId = (state.get(ViewStateKey) as ViewState | undefined)?.viewId
      if (viewId == null || viewId === '<default!view>') {
        viewId = 'default'
      }
      return redirectLoader(`./${viewId}`)(args)
    },
  },
  {
    path: 'views/:viewId',
    Component: ViewShell,
    async loader(args, ctx) {
      const pageType = getPageTypeFromLoader(args)
      return pageLoader(pageType)(args)
    },
  },
  {
    path: 'tasks/*',
    loader: (args) => {
      if (++redirectCount > 2) {
        redirectCount = 0
        throw new Error('too many redirects')
      }
      return redirectLoader('../../views/default')(args)
    },
  },
  {
    path: 'projects/list',
    loader: legacyLoader(),
  },
  {
    path: 'projects/kanban',
    loader: legacyLoader(),
  },
  {
    path: 'projects/gantt',
    loader: legacyLoader(),
  },
  {
    path: 'tasks/list',
    loader: legacyLoader(),
  },
  {
    path: 'tasks/kanban',
    loader: legacyLoader(),
  },
  {
    path: 'tasks/gantt',
    loader: legacyLoader(),
  },
  {
    path: 'projects/*',
    loader: (args) => {
      if (++redirectCount > 2) {
        redirectCount = 0
        throw new Error('too many redirects')
      }
      return redirectLoader('../../views/default')(args)
    },
  },
  {
    path: '*',
    loader(args) {
      if (++redirectCount > 2) {
        redirectCount = 0
        throw new Error('too many redirects')
      }

      log('load.*', args)

      return loggedRedirect(args, '/web/pm/all-tasks/views/default')
    },
  },
]

export const v4Routes: MotionRoute[] = [
  {
    id: 'pm-v4-root',
    path: '',
    Component: Outlet,
    featureFlag: 'pm-view-based-routing',
    children: [
      {
        id: 'all-tasks',
        path: 'all-tasks',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: ShellWithContext,
        children: pages,
      },
      {
        id: 'all-projects',
        path: 'all-projects',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: ShellWithContext,
        children: pages,
      },
      {
        id: 'my-tasks',
        path: 'my-tasks',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: ShellWithContext,
        children: [
          ...pages,
          {
            id: 'my-tasks-archive',
            path: 'archive',
            Component: ViewShell,
            loader: async (args) => {
              return {
                page: 'my-tasks',
                variant: 'archive',
                key: 'my-tasks-archive',
                type: 'tasks',
                filter: {
                  views: 'my-tasks',
                  archive: true,
                },
                state: await loadState('my-tasks', 'my-tasks_archived', args),
              } satisfies Partial<RouteData>
            },
          },
        ],
      },
      {
        id: 'workspace-project',
        path: 'workspaces/:workspaceId/projects/:projectId',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: ShellWithContext,
        children: [
          ...pages,
          {
            id: 'project-tasks',
            path: 'task-list',
            async loader(args) {
              const prefixKey = getStatePrefix('project', {
                projectId: args.params.projectId,
              })

              await DB.open()
              const defaultView = cloneDeep(getDefaultView('project'))
              defaultView.definition.itemType = 'tasks'
              defaultView.definition.layout = 'list'
              await persistView(prefixKey, defaultView)

              return relativeRedirect(args, '../views/default')
            },
          },
        ],
      },
      {
        id: 'workspace-folder',
        path: 'workspaces/:workspaceId/folders/:folderId',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: ShellWithContext,
        children: pages,
      },
      {
        id: 'workspace-detail',
        path: 'workspaces/:workspaceId',
        routing: {
          relative: true,
          template: 'views/:viewId',
          defaults: {
            viewId: 'default',
          },
        },
        Component: ShellWithContext,
        children: [
          ...pages,
          {
            id: 'workspace-archive',
            path: 'archive',
            Component: ViewShell,
            loader: async (args) => {
              return {
                page: 'workspace',
                variant: 'archive',
                type: 'tasks',
                key: `workspace_${args.params.workspaceId}_archived`,
                filter: {
                  views: 'workspace',
                  workspaceId: args.params.workspaceId,
                  archive: true,
                },
                state: await loadState(
                  'workspace',
                  `workspace_${args.params.workspaceId}_archived`,
                  args
                ),
              } satisfies Partial<RouteData>
            },
          },
        ],
      },
    ],
  },
]
