import { LoginButton, LoginScreen } from '@motion/ui/login'
import { logEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { Events } from '../../../analyticsEvents'
import { useOnDesktopEvent } from '../../../localServices/desktop'
import { useOnboardingIframeUrl } from '../../../localServices/experiments'
import {
  openFirebaseAuth,
  signInWithCustomToken,
} from '../../../localServices/firebase/web'

export const LoginPage = () => {
  const urlParams = new URLSearchParams(window.location.search)

  // if an emailCode was sent, we're onboarding right now
  const emailCode = urlParams.get('emailCode')
  const hasEmailPasswordLoginEnabled = useHasTreatment('email-signin-checkout')
  const iframeUrl = useOnboardingIframeUrl('login')

  const signInHandler = async () => {
    openFirebaseAuth(emailCode)
    void logEvent(Events.ACCOUNT_SIGNIN_CLICKED, {
      provider: 'google',
    })
  }

  const email = emailCode && window.atob(emailCode)

  useOnDesktopEvent('loginDesktop', async (token: string) => {
    if (!token) return
    await signInWithCustomToken(token)
  })

  return (
    <LoginScreen
      iframeUrl={iframeUrl}
      title={email ? `👋 Log in with ${email}` : '👋 Welcome back!'}
      subtitle={`Log in with your Google, Microsoft, or Apple account.${
        hasEmailPasswordLoginEnabled ? ' Or log in with your email' : ''
      }`}
    >
      <LoginButton onClick={signInHandler} prefix='Log in' />
    </LoginScreen>
  )
}
