import { type GROUP_BY_DATE_OPTIONS } from '@motion/zod/client'

import { createContext, useContext } from 'react'

import { type SelectedGroupByField } from './group-by-button'

export const GroupByPopoverContext = createContext<{
  fields: SelectedGroupByField[]
  setFieldOption: (id: string, by: GROUP_BY_DATE_OPTIONS) => void
} | null>(null)

/* eslint react-refresh/only-export-components: ["warn"] */
export const useGroupByPopoverContext = () => useContext(GroupByPopoverContext)!
