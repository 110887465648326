import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'

import { useFormContext } from 'react-hook-form'

import { getTaskPathFromStageTaskFieldPath } from '../../components/stages/fields/utils'
import { useFlowsModalState } from '../../contexts'
import {
  getTaskNameVariableUpdater,
  type TaskTextVariableUpdater,
} from '../../utils'

export function useTaskNameVariableUpdater(): TaskTextVariableUpdater {
  const { watch, setValue, getValues } =
    useFormContext<FlowTemplateFormFields>()
  const { setDirtyTasksMap } = useFlowsModalState()
  const stages = watch('stages')

  const updater = getTaskNameVariableUpdater(
    stages,
    (name, currentStageIndex, currentTaskIndex) => {
      const stageTaskNamePath =
        `stages.${currentStageIndex}.tasks.${currentTaskIndex}.name` as const

      setValue(stageTaskNamePath, name, {
        shouldDirty: true,
        shouldValidate: true,
      })

      const taskPath = getTaskPathFromStageTaskFieldPath(stageTaskNamePath)
      const taskId = getValues(`${taskPath}.id`)

      setDirtyTasksMap((dirtyTasksMap) => ({
        ...dirtyTasksMap,
        [taskId]: true,
      }))
    }
  )

  return updater
}
