import { PencilSolid } from '@motion/icons'
import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { classed } from '@motion/theme'
import {
  addSchemeIfNeeded,
  getDisplayableLink,
  type TextCustomField,
} from '@motion/ui-logic'

import {
  CustomField,
  type CustomFieldInputProps,
  validateUrl,
} from '~/areas/project-management/custom-fields'
import { type ControlledCustomFieldProps } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { type FC, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const ControlledUrl: FC<ControlledCustomFieldProps<TextCustomField>> = ({
  value,
  onSubmit: submitHandler,
  customField,
  disabled,
}) => {
  const [internalValue, setInternalValue] = useDependantState(
    () => value,
    [value]
  )
  const [isEditing, setIsEditing] = useState(internalValue ? false : true)

  const onChange = (v: string) => {
    setInternalValue(v)
  }

  const onSubmit = useDebouncedCallback(async () => {
    if (internalValue === value) {
      setIsEditing(!value)
      return
    }

    const parsedValue = addSchemeIfNeeded(internalValue)

    try {
      await submitHandler(parsedValue)
      const isValidUrl = validateUrl(parsedValue)
      setIsEditing(isValidUrl ? false : true)
    } catch (error) {
      setInternalValue(value)
    }
  }, 400)

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key } = evt

    if (key === 'Enter') {
      return onSubmit()
    }
  }

  if (!isEditing) {
    return (
      <Container>
        <a
          className={twMerge(
            `text-2xs truncate
          hover:text-semantic-primary-text-default text-semantic-neutral-text-default`,
            !internalValue &&
              'hover:text-semantic-neutral-text-subtle text-semantic-neutral-text-subtle'
          )}
          href={internalValue ? internalValue : undefined}
          target='_blank'
          rel='noopener noreferrer'
        >
          {internalValue ? getDisplayableLink(internalValue) : 'None'}
        </a>
        <PencilSolid
          className='text-semantic-neutral-icon-subtle cursor-pointer'
          height={16}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setIsEditing(true)
          }}
        />
      </Container>
    )
  }

  const hasValue = internalValue !== ''

  return (
    <Container>
      <CustomField.Url
        name={customField.definition.name}
        value={internalValue ?? ''}
        onChange={onChange}
        onBlur={onSubmit}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        size='xsmall'
        hideIcon={hasValue}
      />
    </Container>
  )
}

const Container = classed('div', 'flex items-center gap-2')
