import { getPrioritiesForTask } from '@motion/ui-logic'

import { PriorityField } from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useTaskDefinitionForm } from '../use-task-definition-form'

export const ControlledPriorityField = () => {
  const { form, disabledFields } = useTaskDefinitionForm()

  const { control, watch } = form
  const type = watch('type')

  const { field } = useController({
    name: 'priorityLevel',
    control,
  })

  return (
    <PriorityField
      list={getPrioritiesForTask({ type })}
      value={field.value}
      onChange={field.onChange}
      disabled={disabledFields.has('priorityLevel')}
    />
  )
}
