import {
  type TaskSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import {
  AssigneeDropdown,
  type AssigneeDropdownProps,
} from '~/areas/project-management/components'
import { UserBadge } from '~/global/components/badges'
import { UserLabel } from '~/global/components/labels'
import { useWorkspaceMember } from '~/global/hooks'

import { ModalFieldButton } from '../components'

export type AssigneeFieldProps = {
  value: TaskSchema['assigneeUserId']
  onChange: (value: TaskSchema['assigneeUserId']) => void
  disabled?: boolean
  workspaceId: WorkspaceSchema['id']
  removeUnassignedOption?: AssigneeDropdownProps['removeUnassignedOption']
  disableOptionFn?: AssigneeDropdownProps['disableOptionFn']
  disabledOptionTooltip?: AssigneeDropdownProps['disabledOptionTooltip']
}

export const AssigneeField = ({
  value,
  disabled,
  onChange,
  workspaceId,
  removeUnassignedOption,
  disableOptionFn,
  disabledOptionTooltip,
}: AssigneeFieldProps) => {
  const selectedAssignee = useWorkspaceMember(workspaceId, value)

  return (
    <AssigneeDropdown
      selectedUserId={value}
      onChange={(assigneeId) => {
        onChange(assigneeId)
      }}
      workspaceId={workspaceId}
      removeUnassignedOption={removeUnassignedOption}
      disabledOptionTooltip={disabledOptionTooltip}
      disableOptionFn={disableOptionFn}
    >
      <ModalFieldButton label='Assignee' disabled={disabled}>
        <AssingeeLabel user={selectedAssignee?.user} />
      </ModalFieldButton>
    </AssigneeDropdown>
  )
}

type UserLabelProps = {
  user: UserInfoSchema | undefined
}

const AssingeeLabel = ({ user }: UserLabelProps) => {
  return (
    <>
      <UserBadge value={user ?? null} />
      <UserLabel value={user ?? null} nameOnly />
    </>
  )
}
