import { XSolid } from '@motion/icons'
import { Checkbox } from '@motion/ui/forms'

import React from 'react'

import { Paragraph, PrimaryButton, TextButton } from '../../Common'

export type OpenInDesktopAppResult = {
  openInDesktopApp: boolean
  persist: boolean
}

export type OpenInDesktopAppProps = {
  onResult: (result: OpenInDesktopAppResult) => void
}

export const OpenInDesktopApp = ({ onResult }: OpenInDesktopAppProps) => {
  const [rememberChoice, setRememberChoice] = React.useState(false)

  return (
    <div
      data-motion-desktop-deeplink-popup
      className='bg-light-100 border-light-500 dark:bg-dark-900 dark:border-dark-800 flex w-[450px] flex-col gap-5 rounded-lg border p-4'
    >
      <div className='flex w-full justify-between'>
        <Paragraph className='text-base font-semibold'>
          Open in desktop app?
        </Paragraph>
        <TextButton
          icon={XSolid}
          onClick={() => {
            onResult({
              openInDesktopApp: false,
              persist: false,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center justify-between'>
        <Checkbox
          checked={rememberChoice}
          onChange={(checked) => setRememberChoice(!!checked)}
          label='Remember choice'
        />
        <div className='flex gap-4'>
          <TextButton
            onClick={() => {
              onResult({
                openInDesktopApp: false,
                persist: rememberChoice,
              })
            }}
          >
            No thanks
          </TextButton>
          <PrimaryButton
            onClick={() => {
              onResult({
                openInDesktopApp: true,
                persist: rememberChoice,
              })
            }}
          >
            Open in app
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}
