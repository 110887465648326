import { type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export const useTaskProjectUpdater = () => {
  const updateTask = useTaskUpdater()

  return useCallback(
    async (task: TaskSchema, projectId: TaskSchema['projectId']) => {
      await updateTask(task, { projectId })
    },
    [updateTask]
  )
}
