import { isNoneId } from '@motion/shared/identifiers'
import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectSchema,
  type ProjectsV2UpdateRequestSchema,
} from '@motion/zod/client'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useAutosaveContext } from '~/areas/task-project/contexts'
import { useLookup } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks'
import { createProjectProxy } from '~/global/proxies'
import { useUpdateProject as useUpdateProjectCall } from '~/global/rpc/v2'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useAddProjectStateSetter, useAddProjectStateValue } from '../../states'
import { useProjectAnalytics } from '../use-project-analytics'
import { handleProjectUpdateErrors } from '../utils'

export function useProjectUpdater() {
  const realProjectUpdater = useRealProjectUpdater()
  const fakeProjectUpdater = useFakeProjectUpdater()

  return useCallback(
    async (
      projectOrId: ProjectSchema | ProjectSchema['id'],
      updates: ProjectsV2UpdateRequestSchema
    ) => {
      const isFake =
        typeof projectOrId === 'string'
          ? isNoneId(projectOrId)
          : isNoneId(projectOrId.id)

      if (isFake) {
        return fakeProjectUpdater(projectOrId, updates)
      }
      return realProjectUpdater(projectOrId, updates)
    },
    [fakeProjectUpdater, realProjectUpdater]
  )
}

const useFakeProjectUpdater = () => {
  const addProjectStateValue = useAddProjectStateValue()
  const setAddProjectState = useAddProjectStateSetter()
  const lookup = useLookup()

  return useCallback(
    async (
      projectOrId: ProjectSchema | ProjectSchema['id'],
      updates: ProjectsV2UpdateRequestSchema
    ) => {
      const project = addProjectStateValue.project

      // A project should already exist in the state if we hit this here
      if (project == null) {
        throw new Error(`Can't update an invalid project`)
      }

      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_TEMPORARY_PROJECT')

      const mergedCustomFields = {
        ...project.customFieldValues,
        ...(updates.customFieldValues ?? {}),
      }

      const newProjectSchema = {
        ...project,
        ...updates,
        customFieldValues: mergedCustomFields,
      }

      const newProjectState = {
        ...addProjectStateValue,
        project: createProjectProxy(newProjectSchema, lookup),
      }

      setAddProjectState(newProjectState)

      return {
        id: project.id,
        meta: {
          model: 'projects',
        },
        models: {
          projects: {
            [project.id]: newProjectSchema,
          },
        },
      }
    },
    [addProjectStateValue, lookup, setAddProjectState]
  )
}

const useRealProjectUpdater = () => {
  const { shouldSupressSavingToast, setLastSavedTime } = useAutosaveContext()
  const { mutateAsync: updateProject } = useUpdateProjectCall()
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const { getCustomFields } = useCustomFieldsFns()
  const getProjectAnalyticContext = useProjectAnalytics()

  return useCallback(
    async (
      projectOrId: ProjectSchema | ProjectSchema['id'],
      updates: ProjectsV2UpdateRequestSchema
    ) => {
      const project =
        typeof projectOrId === 'string'
          ? getWorkspaceProjectById(projectOrId)
          : projectOrId

      if (project == null) {
        throw new Error(`Can't update an invalid project`)
      }

      recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PROJECT_CONTEXT', {
        ...getProjectAnalyticContext(project.id),
      })

      try {
        const response = await updateProject({
          projectId: project.id,
          ...updates,
        })

        setLastSavedTime(DateTime.now())
        if (!shouldSupressSavingToast) {
          showToast('success', 'Project saved')
        }

        return response
      } catch (e) {
        Sentry.captureException(e, { tags: { position: 'update-project' } })

        if (e instanceof Error) {
          handleProjectUpdateErrors(e, getCustomFields(project.workspaceId))
        }

        throw e
      }
    },
    [
      getWorkspaceProjectById,
      getProjectAnalyticContext,
      updateProject,
      setLastSavedTime,
      shouldSupressSavingToast,
      getCustomFields,
    ]
  )
}
