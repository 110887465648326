import { type CustomFieldValuesSchema } from '@motion/rpc-types'

import { type BadgeSize, ConnectedUserBadge } from '~/global/components/badges'

import { CustomFieldBadge } from './custom-field'

type CustomFieldValueBadgeProps = {
  value: CustomFieldValuesSchema
  size?: BadgeSize
}

export const CustomFieldValueBadge = ({
  value,
  size,
}: CustomFieldValueBadgeProps) => {
  if (value.type === 'person') {
    return <ConnectedUserBadge userId={value.value} size={size} />
  }

  return <CustomFieldBadge size={size} type={value.type} />
}
