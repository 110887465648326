import { createNoneProject } from '@motion/ui-logic/pm/data'
import { HasExperiment } from '@motion/web-common/flags'

import {
  ModalFieldButton,
  WorkspaceSectionContainer,
  WorkspaceSectionTitle,
} from '~/areas/task-project/components'
import {
  FolderField,
  StageField,
  WorkspaceField,
} from '~/areas/task-project/fields'
import { ProjectBadge } from '~/global/components/badges'
import { ProjectLabel } from '~/global/components/labels'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'

import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const ControlledWorkspaceProjectField = () => {
  const { flowFormData } = useTaskDefinitionModalState()
  const flowTemplateFolderId = flowFormData?.folderId
  const { form } = useTaskDefinitionForm()
  const { control, getValues } = form

  const { field: stageIdField } = useController({
    name: 'stageDefinitionId',
    control,
  })

  const workspaceId = getValues('workspaceId')
  const projectId = getValues('projectId')

  const noneProject = useMemo(() => {
    const project = createNoneProject(workspaceId)
    project.name = 'Project Name'
    return project
  }, [workspaceId])

  return (
    <WorkspaceSectionContainer>
      <WorkspaceSectionTitle showStage />

      <WorkspaceField workspaceId={workspaceId} onChange={() => {}} disabled />

      <HasExperiment name='flows-folders'>
        <FolderField
          disabled
          workspaceId={workspaceId}
          folderId={flowTemplateFolderId}
          onChange={() => {}}
        />
      </HasExperiment>

      <ModalFieldButton
        size='normal'
        icon={<ProjectBadge value={null} hideTooltip />}
        disabled
      >
        <ProjectLabel value={noneProject} nameOnly />
      </ModalFieldButton>

      <StageField
        workspaceId={workspaceId}
        projectId={projectId}
        stageDefinitionId={stageIdField.value!}
        disabled
        onChange={() => {}}
      />
    </WorkspaceSectionContainer>
  )
}
