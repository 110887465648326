export type IntegrationBlockProps = {
  bodyText: string
  logo: string
  title: string
  isBeta?: boolean
  integrationButton: React.ReactNode
  secondaryButton?: React.ReactNode
}

export const IntegrationBlock = ({
  bodyText,
  integrationButton,
  secondaryButton,
  isBeta,
  logo,
  title,
}: IntegrationBlockProps) => {
  return (
    <div className='mb-3 flex h-[68px] min-w-[500px] max-w-[800px] gap-2 bg-semantic-neutral-bg-disabled'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex items-center justify-start'>
          <img
            src={logo}
            alt={`${title} logo`}
            className='ml-4 mr-3 h-8 rounded'
          />
          <div className='flex flex-col'>
            <div className='flex'>
              <span className='text-semantic-neutral-text-default font-semibold'>
                {title}
              </span>

              {isBeta && (
                <span className='text-primary-300 bg-primary-300/20 ml-2 gap-2 rounded-xl pl-1.5 pr-1.5'>
                  Beta
                </span>
              )}
            </div>
            <span className='mt-1 text-sm text-semantic-neutral-text-subtle'>
              {bodyText}
            </span>
          </div>
        </div>
        <div className='mr-3 flex flex-row items-center'>
          {secondaryButton && <div className='mr-3'>{secondaryButton}</div>}
          <div>{integrationButton}</div>
        </div>
      </div>
    </div>
  )
}
