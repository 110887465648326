import { classed } from '@motion/theme'

export const SubItemIndentMarker = classed(
  'div',
  'w-2 h-4 ml-2',
  'self-start',
  'shrink-0',
  'border-l border-l-semantic-neutral-border-strong',
  'border-b border-b-semantic-neutral-border-strong',
  'rounded-bl-sm',
  'pointer-events-none'
)
