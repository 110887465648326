import { Checkbox } from '@motion/ui/forms'

import { forwardRef, type ReactElement, type Ref } from 'react'
import { twMerge } from 'tailwind-merge'

import { TextButton } from '../Button/TextButton/TextButton'

interface MenuItemProps {
  label: string
  slotOnClick?: () => void
  slotClasses?: string
  leftSlot?: ReactElement | boolean
  rightSlot?: ReactElement | boolean
  className?: string
  openHandler?: (visible: boolean) => void
  isTitle?: boolean
  checkable?: boolean
  isChecked?: boolean
  disabled?: boolean
}

export const MenuItem = forwardRef(function MenuItem(
  {
    label,
    leftSlot,
    rightSlot,
    slotClasses = '',
    slotOnClick,
    openHandler = () => {},
    className = '',
    isTitle = false,
    checkable = false,
    isChecked = false,
    disabled = false,
    ...props
  }: MenuItemProps,
  ref: Ref<HTMLDivElement>
) {
  const generalSlotClasses =
    'text-light-1000 dark:text-dark-300 flex h-4 w-4 items-center justify-center'

  return (
    <div
      ref={ref}
      className={twMerge(
        ' flex w-full select-none items-center gap-2 truncate px-3 focus:outline-none',
        isTitle
          ? 'text-light-1100 dark:text-dark-300 h-6 pt-3 pb-2 text-sm'
          : 'text-light-1200  dark:text-dark-100 focus:bg-light-400 dark:focus:bg-dark-700 h-8 cursor-pointer',
        disabled && 'cursor-not-allowed opacity-50',
        className
      )}
      {...props}
    >
      {checkable && (
        <Checkbox
          checked={isChecked}
          disabled={disabled}
          onChange={() => {}}
          label=''
          labelHidden
        />
      )}
      {leftSlot &&
        typeof leftSlot !== 'boolean' &&
        (slotOnClick ? (
          <TextButton
            className={twMerge('[&_svg]:h-4 [&_svg]:w-4', slotClasses)}
            onClick={(e) => {
              e.preventDefault()
              slotOnClick()
              openHandler(false)
            }}
            disabled={!slotOnClick}
          >
            {leftSlot}
          </TextButton>
        ) : (
          <span className={twMerge(generalSlotClasses, slotClasses)}>
            {leftSlot}
          </span>
        ))}
      <span className={twMerge(className)}>{label}</span>
      {rightSlot &&
        typeof rightSlot !== 'boolean' &&
        (slotOnClick ? (
          <TextButton
            className={twMerge('[&_svg]:h-4 [&_svg]:w-4', slotClasses)}
            onClick={(e) => {
              e.preventDefault()
              slotOnClick()
              openHandler(false)
            }}
            disabled={!slotOnClick}
          >
            {rightSlot}
          </TextButton>
        ) : (
          <span className={twMerge(generalSlotClasses, slotClasses)}>
            {rightSlot}
          </span>
        ))}
    </div>
  )
})
