import { DatePicker } from '@motion/ui/base'

// eslint-disable-next-line no-restricted-imports
import { Popover, Transition } from '@headlessui/react'
import { DateTime } from 'luxon'
import { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'

import { inputFocusClasses } from '../../../../components/Common/GeneralComponentStyles'
import { type Task } from '../types'

type DatePickerPopoverProps = {
  task: Task
  onSelectDeadline: (task: Task, date: string) => void
  children: React.ReactNode
}

export function DatePickerPopover({
  task,
  onSelectDeadline,
  children,
}: DatePickerPopoverProps) {
  return (
    <Popover as='div' className='relative w-full'>
      <div>
        <Popover.Button
          className={twMerge(
            'text-light-1200  inline-flex w-full items-center justify-between rounded-md hover:bg-opacity-30',
            inputFocusClasses
          )}
        >
          {children}
        </Popover.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Popover.Panel className='border-light-400 absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {({ close }) => (
            <div className='px-1 py-1'>
              <DatePicker
                disabledDate={(date: DateTime) =>
                  date < DateTime.now().minus({ day: 1 })
                }
                value={task.deadlineISO}
                onChange={(date) => {
                  onSelectDeadline(task, date as string)
                  close()
                }}
              />
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
