import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton, Modal } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { keys } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { usePastDueTasksBySchedule } from '~/areas/calendar/hooks'

import {
  MissedInstancesRow,
  ScheduledAfterDeadlineRow,
  SelectTasksRow,
} from './components'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'past-due-modal': never
  }
}

export const PastDueModal = ({
  close,
}: ModalTriggerComponentProps<'past-due-modal'>) => {
  const {
    hardDeadlineMissed,
    softDeadlineMissed,
    recurringTasksMissed,
    recurringHardDeadlineMissed,
    recurringSoftDeadlineMissed,
  } = usePastDueTasksBySchedule({ shouldSeparateRecurring: true })

  const onClose = () => {
    recordAnalyticsEvent('PAST_DUE_MODAL_CLOSE')
    close()
  }

  const recurringText =
    recurringHardDeadlineMissed.length > 0 ||
    recurringSoftDeadlineMissed.length > 0
      ? templateStr(
          ' ({{hardDeadlineMissed}}{{hasBoth}}{{softDeadlineMissed}})',
          {
            hardDeadlineMissed:
              recurringHardDeadlineMissed.length > 0
                ? `${recurringHardDeadlineMissed.length} hard deadline`
                : '',
            hasBoth:
              recurringHardDeadlineMissed.length > 0 &&
              recurringSoftDeadlineMissed.length > 0
                ? ', '
                : '',
            softDeadlineMissed:
              recurringSoftDeadlineMissed.length > 0
                ? `${recurringSoftDeadlineMissed.length} soft deadline`
                : '',
          }
        )
      : ''

  return (
    <Modal visible onClose={onClose}>
      <Container>
        <Header>
          <Title>Tasks scheduled after deadline</Title>
          <IconButton
            onClick={onClose}
            icon={XSolid}
            variant='muted'
            sentiment='neutral'
            aria-label='Close modal'
          />
        </Header>
        <SelectTasksRow />
        <Body>
          {hardDeadlineMissed.length === 0 &&
            softDeadlineMissed.length === 0 &&
            keys(recurringTasksMissed).length === 0 && (
              <div className='flex h-full w-full flex-col items-center justify-center gap-3'>
                <p className='text-sm text-semantic-neutral-text-subtle'>
                  Nice! No more tasks are scheduled after deadline.
                </p>
                <Button sentiment='neutral' size='small' onClick={onClose}>
                  Close
                </Button>
              </div>
            )}
          {hardDeadlineMissed.length > 0 && (
            <SectionContainer type='hard'>
              <SectionTitle>Scheduled After Hard Deadline</SectionTitle>
              <RowsContainer>
                {hardDeadlineMissed.map((task) => (
                  <ScheduledAfterDeadlineRow key={task.id} task={task} />
                ))}
              </RowsContainer>
            </SectionContainer>
          )}
          {softDeadlineMissed.length > 0 && (
            <SectionContainer type='soft'>
              <SectionTitle>Scheduled After Soft Deadline</SectionTitle>
              <RowsContainer>
                {softDeadlineMissed.map((task) => (
                  <ScheduledAfterDeadlineRow key={task.id} task={task} />
                ))}
              </RowsContainer>
            </SectionContainer>
          )}
          {keys(recurringTasksMissed).length > 0 && (
            <SectionContainer type='recurring'>
              <SectionTitle>
                Recurring Past Due
                {recurringText}
              </SectionTitle>
              <RowsContainer>
                {keys(recurringTasksMissed).map((key) => (
                  <MissedInstancesRow
                    key={key}
                    parentId={key}
                    instances={recurringTasksMissed[key]}
                  />
                ))}
              </RowsContainer>
            </SectionContainer>
          )}
        </Body>
      </Container>
    </Modal>
  )
}

const Container = classed('div', {
  base: `
    grid grid-rows-[auto_1fr]
    w-[998px] h-100 overflow-hidden
  `,
})

const Header = classed('div', {
  base: `
    grid grid-cols-[1fr_auto]
    bg-modal-bg
    pl-4 pr-3 py-3
    items-center
  `,
})

const Title = classed('h2', {
  base: `text-semantic-neutral-text-default text-base font-semibold`,
})

const Body = classed('div', {
  base: `
    flex flex-col gap-5 p-4 overflow-y-auto max-h-[80vh] text-semantic-neutral-text-default text-base`,
})

const SectionContainer = classed('div', {
  base: `
    flex flex-col gap-2
  `,
  variants: {
    type: {
      hard: 'themevar-[highlight=semantic-error-text-default]',
      soft: 'themevar-[highlight=semantic-warning-text-default]',
      recurring: 'text-semantic-neutral-text-subtle',
    },
  },
})

const RowsContainer = classed('div', {
  base: `
    flex flex-col
  `,
})

const SectionTitle = classed('span', {
  base: `
    capitalize
    text-[var(--highlight)]
    font-bold text-sm
    pb-2
  `,
})
