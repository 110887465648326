import { PopoverTrigger, type PopoverTriggerProps } from '@motion/ui/base'

import { type ReactNode } from 'react'

import {
  ContactsDropdownContent,
  type ContactsDropdownContentProps,
  SubmitContactsDropdownContent,
  type SubmitContactsDropdownContentProps,
} from './dropdown-content'

export type ContactsDropdownProps = {
  children: ReactNode
  placement?: PopoverTriggerProps['placement']
} & Omit<ContactsDropdownContentProps, 'close' | 'onChange'> &
  (
    | {
        variant?: 'default'
        onChange: ContactsDropdownContentProps['onChange']
      }
    | {
        variant?: 'submit'
        onChange: SubmitContactsDropdownContentProps['onChange']
      }
  )

export const ContactsDropdown = ({
  onChange,
  children,
  placement = 'bottom-start',
  selectedEmails,
  variant = 'default',
}: ContactsDropdownProps) => {
  const DropdownContent =
    variant === 'submit'
      ? SubmitContactsDropdownContent
      : ContactsDropdownContent

  return (
    <PopoverTrigger
      placement={placement}
      renderPopover={({ close }) => (
        <DropdownContent
          close={close}
          onChange={onChange as any}
          selectedEmails={selectedEmails}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
