import { SearchSolid } from '@motion/icons'
import { useDebouncedValue, useOnValueChange } from '@motion/react-core/hooks'
import { LoadingSpinner } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { fuzzySearch, SYNC_SESSION_DAYS_SPAN } from '@motion/ui-logic'
import { byValue, Compare } from '@motion/utils/array'
import { parseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'

import {
  useScheduleAssistantCalendarContext,
  useUserCalendarEvents,
} from '../../../hooks'
import { RecentlyCreatedEvent } from '../recently-created-event'

export function SearchSection() {
  const [calendarState] = useScheduleAssistantCalendarContext()
  const [searchQuery, setSearchQuery] = useState('')

  const debouncedSearchQuery = useDebouncedValue(searchQuery, {
    timeoutMs: 750,
  })

  const { calendarEvents: userCalendarEvents, isLoading } =
    useUserCalendarEvents({
      enabled: calendarState.mode === 'select-existing',
    })

  const recentlyCreatedCalendarEvents = useMemo(() => {
    const nonMeetingEvents = userCalendarEvents.filter(
      (event) => event.meetingTaskId == null
    )

    // Default to 3 weeks ago if no created time is available, this is a rare case and createdTime should always be available
    const defaultDate = DateTime.now().minus({
      days: SYNC_SESSION_DAYS_SPAN.before,
    })

    // Default to showing the most recently created events
    if (searchQuery === '') {
      return nonMeetingEvents
        .sort(
          byValue(
            (e) => parseDate(e.createdTime ?? defaultDate),
            Compare.dateTime.desc
          )
        )
        .slice(0, 3)
    }

    return fuzzySearch({
      query: searchQuery,
      items: nonMeetingEvents,
      keys: ['title'],
      threshold: 0.4,
    })
  }, [searchQuery, userCalendarEvents])

  useOnValueChange(debouncedSearchQuery, () => {
    recordAnalyticsEvent('SCHEDULE_ASSISTANT_SEARCH')
  })

  return (
    <>
      <TextField
        prefix={<SearchSolid />}
        placeholder='Search events'
        label='Search events'
        labelHidden
        size='small'
        value={searchQuery}
        onChange={setSearchQuery}
        showClearButton
      />

      <section className='flex flex-col gap-2'>
        {searchQuery === '' && (
          <h4 className='text-2xs font-semibold text-semantic-neutral-text-subtle'>
            Recently created
          </h4>
        )}

        <div className='flex flex-col gap-1'>
          {recentlyCreatedCalendarEvents.map((event) => (
            <RecentlyCreatedEvent key={event.id} event={event} />
          ))}

          {isLoading ? (
            <div className='self-center'>
              <LoadingSpinner size={16} />
            </div>
          ) : recentlyCreatedCalendarEvents.length === 0 ? (
            <p className='text-semantic-neutral-text-subtle text-2xs'>
              No events found
            </p>
          ) : null}
        </div>
      </section>
    </>
  )
}
