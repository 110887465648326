import {
  type PMTaskType,
  priorityLevels,
  recurringTaskPriorityLevels,
} from '@motion/rpc/types'
import { isRecurringTaskParent } from '@motion/ui-logic'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { useCallback, useMemo } from 'react'

import { useTemplateTaskUpdate } from './use-template-task-update'

export const useTemplateTaskPriorityDropdown: (task: PMTaskType) => {
  priorities: PriorityLevelSchema[]
  onChange: (priorityLevel: PriorityLevelSchema) => void
  dropdownValue: 'ASAP' | 'HIGH' | 'MEDIUM' | 'LOW'
} = (task: PMTaskType) => {
  const updateTemplateTask = useTemplateTaskUpdate()

  const onChange = useCallback(
    async (priorityLevel: PriorityLevelSchema) => {
      await updateTemplateTask(task, { priorityLevel })
    },
    [updateTemplateTask, task]
  )

  const isRecurring = isRecurringTaskParent(task)

  const priorities = useMemo(() => {
    return isRecurring ? recurringTaskPriorityLevels : priorityLevels
  }, [isRecurring])

  return {
    onChange,
    dropdownValue: task.priorityLevel,
    priorities,
  }
}
