import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { clearFlowTemplateSearchParams } from '../utils'

export const useCloseFlowTemplateModal = () => {
  const [, setSearchParams] = useSearchParams()

  return useCallback(() => {
    setSearchParams((prev) => {
      clearFlowTemplateSearchParams(prev)

      prev.delete('template')
      return prev
    })
  }, [setSearchParams])
}
