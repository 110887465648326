import { Tooltip } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import {
  type EntityFilterState,
  type FilterTarget,
  useActiveFilter,
} from '@motion/ui-logic/pm/data'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ReactNode } from 'react'

import { usePageData } from '../routes'

export const ConnectedShowCompletedButton = () => {
  const [filter, setFilter] = useActiveFilter()
  const data = usePageData()

  const toggle = (type: FilterTarget, checked: boolean) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_SHOW_COMPLETED', {
      type,
      checked,
    })
    setFilter((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        filters: {
          ...prev[type].filters,
          completed: checked ? 'include' : 'exclude',
        },
      },
    }))
  }

  const isProjectPage = data.page === 'project'

  const isTasksChecked = isChecked(filter.tasks)
  const isProjectsChecked = isChecked(filter.projects)

  const tasksTooltip =
    isTasksChecked === 'indeterminate' ? 'Overridden by filter' : null
  const projectsTooltip =
    isProjectsChecked === 'indeterminate' ? 'Overridden by filter' : null

  return (
    <div className='flex items-center h-[26px]'>
      {filter.target === 'tasks' && (
        <TooltipWrapper content={tasksTooltip}>
          <Checkbox
            checked={isTasksChecked}
            onChange={(checked) => toggle('tasks', checked)}
            label='Show completed tasks'
            size='small'
            disabled={!!tasksTooltip}
          />
        </TooltipWrapper>
      )}
      {filter.target === 'projects' && !isProjectPage && (
        <TooltipWrapper content={projectsTooltip}>
          <Checkbox
            checked={isProjectsChecked}
            onChange={(checked) => toggle('projects', checked)}
            label='Show completed projects'
            size='small'
            disabled={!!projectsTooltip}
          />
        </TooltipWrapper>
      )}
    </div>
  )
}

type TooltipWrapperProps = {
  content?: string | undefined | null
  children: ReactNode
}
function TooltipWrapper(props: TooltipWrapperProps) {
  if (!props.content) return props.children
  return (
    <Tooltip asChild content={props.content}>
      {props.children}
    </Tooltip>
  )
}

function isChecked(
  entity: EntityFilterState['tasks'] | EntityFilterState['projects']
) {
  if (entity.filters.statusIds) return 'indeterminate'
  if ('completedTime' in entity.filters && entity.filters.completedTime)
    return 'indeterminate'

  return entity.filters.completed === 'include'
}
