// Channels that can be received from Electron

import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DesktopShortcutKeys } from '~/components/Settings/Pages/DesktopSettings'

import type { RootState } from './types'

// Channels that can be received from Electron
// Ensure they match the channels in the main process in the monorepo
export type ReceivableChannels =
  | 'appVersion'
  | 'desktopDistribution'
  | 'canNavigate'
  | 'closeOptionSpace'
  | 'loadCalendar'
  | 'logEvent'
  | 'loginDesktop'
  | 'navigateInApp'
  | 'openOptionSpace'
  | 'openTask'
  | 'updateAppAvailable'
  | 'updateAppDownloaded'
  | 'updateUserSettings'
  | 'zoom-oauth'

// Channels that can be sent to Electron
// Ensure they match the channels in the main process in the monorepo
export type SendableChannels =
  | 'forceReload'
  | 'userReady'
  // TODO: Remove this when enough users update
  | 'getVersion'
  | 'getInitialData'
  | 'cancelOptionSpace'
  | 'expandWindow'
  | 'logOutUser'
  | 'navigate'
  | 'openTask'
  | 'removeShortcuts'
  | 'requestUpdateDownloaded'
  | 'requestUpdateAvailable'
  | 'requestUpdateStatus'
  | 'showNotification'
  | 'updateAppLater'
  | 'updateAppNow'
  | 'updateSettings'
  | 'updateUserSettings'

export interface DesktopSettings {
  deepLinks?: {
    alwaysOpenInApp: boolean
    hasDismissedDeepLinkPopup: boolean
  }
  hasDesktopApp?: string | null
  openAtLaunch?: boolean
  shortcuts: {
    [key in DesktopShortcutKeys]: string
  }
}

export const defaultDesktopShortcuts: {
  [key in DesktopShortcutKeys]: string
} = {
  addTask: 'Alt+Space',
  openCalendar: 'Alt+C',
}

export interface DesktopStateInterface {
  // Settings to sync with firestore
  desktopSettings?: DesktopSettings
  versionNumber: string
}

export const initialDesktopState: DesktopStateInterface = {
  desktopSettings: {
    deepLinks: {
      alwaysOpenInApp: false,
      hasDismissedDeepLinkPopup: false,
    },
    hasDesktopApp: null,
    openAtLaunch: false,
    shortcuts: defaultDesktopShortcuts,
  },
  versionNumber: '',
}

export const desktopSlice = createSlice({
  initialState: initialDesktopState,
  name: 'Desktop',
  reducers: {
    reset: () => initialDesktopState,
    setDesktopSettings: (
      state: DesktopStateInterface,
      action: PayloadAction<DesktopSettings>
    ) => {
      state.desktopSettings = action.payload
    },
    setDesktopVersion: (
      state: DesktopStateInterface,
      action: PayloadAction<string>
    ) => {
      state.versionNumber = action.payload
    },
  },
})

export const { setDesktopSettings, setDesktopVersion, reset } =
  desktopSlice.actions

export const selectDesktopSettings = (state: RootState) =>
  state.desktop.desktopSettings
export const selectDesktopVersion = (state: RootState) =>
  state.desktop.versionNumber

export const desktopReducer = desktopSlice.reducer
