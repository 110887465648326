import { type MeetingUrlSearchParams } from '@motion/ui-logic'

import { useSearchParams } from '~/routing'

import { ConnectedMeetingModal } from '../event/modals/event-modal'

export const GlobalMeetingModal = () => {
  const { mTask } = useSearchParams<MeetingUrlSearchParams>()

  const showMeetingModal = mTask != null

  return <ConnectedMeetingModal open={showMeetingModal} />
}
