import { clamp } from '@motion/utils/math'

import { memo, useCallback, useLayoutEffect, useRef, useState } from 'react'

import { usePlannerProps, useScrollPosition } from '../context'
import { useAnimatedValue } from '../hooks'
import { ZIndexMap } from '../shared-constants'

type ScrollBarProps = {
  setPosition: (pos: number) => void
}

export const ScrollBar = memo(function ScrollBar(props: ScrollBarProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const scrollingRange = useRef({ min: -2500, max: 2500 })
  const [scrollBarWidth, setScrollBarWidth] = useState(0)
  const [plannerProps] = usePlannerProps()
  const [scrollPosition] = useScrollPosition()
  const animateConstantSpeed = useAnimatedValue()
  const scrollPositionRef = useRef(scrollPosition)
  // Refs are used so that the mouse move function below always has access to the latest values.
  // Scroll bar width also needs to be in the state otherwise it doesn't rerender the component when it changes.
  const scrollBarWidthRef = useRef(0)

  useLayoutEffect(() => {
    if (!plannerProps.containerRect?.width) return

    scrollPositionRef.current = scrollPosition
    if (scrollPosition < scrollingRange.current.min) {
      scrollingRange.current.min = scrollPosition
    }
    if (scrollPosition > scrollingRange.current.max) {
      scrollingRange.current.max = scrollPosition
    }

    const containerPercentage =
      plannerProps.containerRect.width /
      (scrollingRange.current.max - scrollingRange.current.min)
    scrollBarWidthRef.current = Math.max(
      25,
      containerPercentage * plannerProps.containerRect.width
    )
    setScrollBarWidth((prev) =>
      scrollBarWidthRef.current === prev ? prev : scrollBarWidthRef.current
    )
  }, [scrollPosition, plannerProps.containerRect?.width])

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      const bounds = containerRef.current?.getBoundingClientRect()
      if (!bounds) return
      const handleMouseMove = (e: MouseEvent) => {
        const posX =
          (e.clientX - bounds.left - scrollBarWidthRef.current / 2) /
          (bounds.width - scrollBarWidthRef.current)
        const range = scrollingRange.current.max - scrollingRange.current.min
        if (posX < 0 || posX > 1) {
          animateConstantSpeed.animate(scrollPositionRef.current, {
            type: 'constantSpeed',
            speed: posX < 0 ? 3 : -3,
            onUpdate: props.setPosition,
          })
        } else {
          animateConstantSpeed.cancelAnimation()
          props.setPosition(scrollingRange.current.max - posX * range)
        }
      }

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
        animateConstantSpeed.cancelAnimation()
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    },
    [animateConstantSpeed, props]
  )

  const currentRange = scrollingRange.current.max - scrollingRange.current.min
  const containerSize = plannerProps.containerRect?.width ?? 0
  const percentRange = clamp(
    (scrollingRange.current.max - scrollPosition) / currentRange,
    0,
    1
  )

  return (
    <div
      className='flex absolute bottom-0 pb-1.5 right-0 pointer-events-auto bg-semantic-neutral-surface-bg-default'
      ref={containerRef}
      style={{ left: 0, zIndex: ZIndexMap.dayIndicator }}
    >
      <div
        className='h-2 z-[5] relative rounded-full bg-primitives-neutral-800'
        style={{
          left: (containerSize - scrollBarWidth) * percentRange,
          width: scrollBarWidth,
        }}
        onMouseDown={handleMouseDown}
      />
    </div>
  )
})
