import { logEvent } from '@motion/web-base/analytics'
import { removeLocalStorageValue } from '@motion/web-common/storage'

import { Events } from '../../analyticsEvents'
import {
  terminateWorker,
  terminateWorkersOnOtherTabs,
} from '../../chromeApi/webappChromeApiContentScript'
import { resetStore } from '../../state/store'
import { signOut } from '../firebase/web'

export async function clearAuthState() {
  window.location.hash = ''
  ;(['motionLastPmUri', 'motionLogout'] as const).forEach((k) =>
    removeLocalStorageValue(k)
  )

  window.history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search
  )
  terminateWorkersOnOtherTabs()

  // Reset redux state
  resetStore()

  await signOut()
  void logEvent(Events.ACCOUNT_SIGNOUT_CLICKED)

  await terminateWorker()

  try {
    window.Intercom('shutdown')
  } catch (e) {
    void e
  }
}
