import { type EmailAccount } from '@motion/rpc/types'

import { BackendApiService } from './BackendApiService'

export class EmailAccountsService extends BackendApiService {
  static id = 'EmailAccountsService' as const

  getAll(): Promise<{
    emailAccounts: EmailAccount[]
    mainEmailAccountId: string
  }> {
    return this.request('email_accounts', 'GET')
  }

  delete(emailAccountId: string): Promise<void> {
    return this.request(`email_accounts/${emailAccountId}`, 'DELETE')
  }

  setAsMain(
    emailAccountId: string
  ): Promise<{ assigned: boolean; success: boolean }> {
    return this.request(`email_accounts/${emailAccountId}/main`, 'POST')
  }

  canAccessCalendars(
    emailAccountId: string,
    calendarEmails: string[]
  ): Promise<{ accessibleCalendars: string[] }> {
    return this.request(
      `email_accounts/${emailAccountId}/calendar_access`,
      'POST',
      { calendarEmails }
    )
  }
}

export const instance = new EmailAccountsService()
