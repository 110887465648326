import { PopoverTrigger } from '@motion/ui/base'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useProject, useProjectDefinitionForStageId } from '~/global/hooks'
import { type ReactNode } from 'react'

import { StageSearchableList } from './stage-searchable-list'

export type StageDropdownProps = Omit<StageDropdownContentProps, 'close'> & {
  children?: ReactNode
}

export const StageDropdown = ({
  selectedStageId,
  workspaceId,
  projectId,
  onChange,
  children,
}: StageDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <StageDropdownContent
          workspaceId={workspaceId}
          projectId={projectId}
          selectedStageId={selectedStageId}
          close={close}
          onChange={onChange}
        />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type StageDropdownContentProps = {
  close: () => void
  selectedStageId: StageDefinitionSchema['id']
  workspaceId: WorkspaceSchema['id']
  projectId: ProjectSchema['id'] | null | undefined
  onChange(stageDefinition: StageDefinitionSchema): void
}

const StageDropdownContent = ({
  close,
  onChange,
  workspaceId,
  projectId,
  selectedStageId,
}: StageDropdownContentProps) => {
  const projectDefinition = useProjectDefinitionForStageId(
    workspaceId,
    selectedStageId
  )
  const project = useProject(projectId)

  if (projectDefinition == null || project == null) return null

  return (
    <StageSearchableList
      stages={projectDefinition.stages.map((s) => {
        const projectStage = project.stages.find(
          (ps) => ps.stageDefinitionId === s.id
        )
        return {
          ...s,
          canceledTime: projectStage?.canceledTime ?? null,
          completedTime: projectStage?.completedTime ?? null,
        }
      })}
      selectedStageId={selectedStageId}
      close={close}
      onChange={onChange}
    />
  )
}
