import { CalendarProviderType } from '@motion/rpc/types'

import { useCalendarsByEmail } from '~/areas/calendar/hooks'

export const useSendingEmailsDisabled = (
  email?: string,
  calendarId?: string
) => {
  const calendars = useCalendarsByEmail(email, calendarId)
  const type = calendars?.length === 1 ? calendars[0].providerType : undefined
  let disabledMessage: string | undefined
  if (
    type === CalendarProviderType.APPLE &&
    !email?.endsWith('@icloud.com') &&
    !email?.endsWith('@me.com')
  ) {
    disabledMessage = `Connect an '@icloud.com' or '@me.com' address to send emails via iCloud`
  } else if (!calendars?.find((c) => c.isPrimary)) {
    disabledMessage =
      'Reminder emails only work if the host is one of your primary calendars'
  }
  return disabledMessage
}
