import { type ReactNode } from 'react'

import { useFlowsAIForm } from '../hooks'
import { type FlowsAIFormFields } from '../utils'

type FieldWrapperProps = {
  children: ReactNode
  field: keyof FlowsAIFormFields
}

export function FieldWrapper({ children, field }: FieldWrapperProps) {
  const {
    formState: { errors },
  } = useFlowsAIForm()

  return (
    <div className='flex flex-col gap-2'>
      {children}
      {errors[field] != null && (
        <span className='text-semantic-error-text-default text-sm'>
          {errors[field].message}
        </span>
      )}
    </div>
  )
}
