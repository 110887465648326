import { ClientValidationError } from '@motion/rpc'
import {
  convertFieldsForCreate,
  convertFormFieldsForUpdate,
  type TaskFormFields,
} from '@motion/ui-logic/pm/task'
import { keys } from '@motion/utils/object'

import { useCreateTask, useTaskUpdater } from '~/areas/tasks/hooks'
import { useReadTaskFn } from '~/global/rpc/v2'
import { checkTextRuleErrors, NAME_FIELD_RULE } from '~/global/rules'
import { useCallback } from 'react'

import { useTaskForm } from './use-task-form'

export function useSaveTask() {
  const { form } = useTaskForm()
  const updateTask = useTaskUpdater()
  const createTask = useCreateTask()
  const readTask = useReadTaskFn()

  const { dirtyFields } = form.formState

  const taskId = form.watch('id')

  return useCallback(
    async (fields: TaskFormFields) => {
      if (taskId != null) {
        throw new Error("Modal is autosaving. Manual save shouldn't be called")
      }

      const trimName = fields.name.trim()
      const errorMessage = checkTextRuleErrors(trimName, NAME_FIELD_RULE)
      if (errorMessage) {
        throw new ClientValidationError(errorMessage)
      }

      if (taskId != null) {
        const task = await readTask(taskId)
        if (task == null) {
          throw new Error("Task couldn't be found")
        }

        const dirtyKeys = keys(dirtyFields)
        const maybeDirtyCustomFields = Array.from(
          dirtyFields?.customFieldValuesFieldArray ?? []
        )
        const updates = convertFormFieldsForUpdate(
          fields,
          dirtyKeys,
          maybeDirtyCustomFields
        )
        // task and updates are not directly tied together so it's kinda messed up
        return updateTask(task as any, updates)
      }

      const createPayload = convertFieldsForCreate(fields)
      const task = await createTask(createPayload)

      return task
    },
    [taskId, createTask, readTask, dirtyFields, updateTask]
  )
}
