import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic'

import { type BadgeSize } from '~/global/components/badges'

import { CustomFieldBadge } from '../badges'
import { useCustomFieldById } from '../hooks'

export type CustomFieldLabelProps = {
  value: AllAvailableCustomFieldSchema | null | undefined
  size?: BadgeSize
  nameOnly?: boolean
}

export const CustomFieldLabel = ({
  value,
  size,
  nameOnly,
}: CustomFieldLabelProps) => {
  const label = <div className='truncate'>{value?.name ?? 'Unknown'}</div>

  if (nameOnly) return label

  return (
    <div className='flex gap-1 items-center overflow-hidden leading-4'>
      {value != null && <CustomFieldBadge size={size} type={value.type} />}
      {label}
    </div>
  )
}

type ConnectedCustomFieldLabelProps = Omit<CustomFieldLabelProps, 'value'> & {
  instanceId: string
}

export const ConnectedCustomFieldLabel = ({
  instanceId,
  size,
  nameOnly,
}: ConnectedCustomFieldLabelProps) => {
  const customField = useCustomFieldById(instanceId)

  return (
    <CustomFieldLabel value={customField} size={size} nameOnly={nameOnly} />
  )
}
