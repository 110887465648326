import {
  createQueryFilter,
  getCacheEntry,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { type ScheduledEntitySchema, type TaskSchema } from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'

export function getScheduledEntityQueryFilters() {
  return createQueryFilter([
    MODEL_CACHE_KEY as string[],
    ['v2/scheduled-entities'],
  ])
}

export function applyOptimisticScheduleEntityUpdates(
  client: QueryClient,
  entityId: string,
  updates: Pick<ScheduledEntitySchema, 'schedule'>
): OptimisticUpdateValue {
  const { rollback } = MotionCache.patch(
    client,
    getScheduledEntityQueryFilters(),
    'scheduledEntities',
    {
      [entityId]: updates,
    }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}

export function applyOptimisticScheduleEntityUpdateForTask(
  client: QueryClient,
  taskId: string,
  updates: Partial<TaskSchema>
) {
  const cachedScheduledEntity = getCacheEntry(
    client,
    'scheduledEntities',
    taskId
  )
  if (
    cachedScheduledEntity == null ||
    updates.scheduledStart == null ||
    updates.scheduledEnd == null
  ) {
    return undefined
  }
  return applyOptimisticScheduleEntityUpdates(client, taskId, {
    schedule: {
      ...cachedScheduledEntity.value.schedule,
      start: updates.scheduledStart,
      end: updates.scheduledEnd,
    },
  })
}
