import { FieldButton } from '@motion/ui/base'

import { RelativeDateDropdown } from './relative-date-dropdown'
import { RelativeDateLabel } from './relative-date-label'

export type RelativeDateFieldProps = {
  size: 'xsmall' | 'small'
  icon: JSX.Element
}

export const RelativeDateField = ({ size, icon }: RelativeDateFieldProps) => {
  return (
    <RelativeDateDropdown>
      <FieldButton variant='muted' fullWidth size={size}>
        {icon}

        <RelativeDateLabel
          value={{ name: 'Start', color: 'violet' }}
          size={size}
        />
      </FieldButton>
    </RelativeDateDropdown>
  )
}
