import { getCacheEntry, MotionCache } from '@motion/rpc-cache'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { notifyManager, type QueryClient } from '@tanstack/react-query'

import { getCalendarEventsQueryFilters } from './event-query-filters'

export function applyOptimisticCalendarEventUpdates(
  client: QueryClient,
  calendarEventId: string,
  updates: Partial<CalendarEventSchemaV2>
) {
  let rollbacks: ReturnType<typeof MotionCache.patch>['rollback'][] = []

  const { rollback: calendarEventRollback } = MotionCache.patch(
    client,
    getCalendarEventsQueryFilters(),
    'calendarEvents',
    {
      [calendarEventId]: updates,
    }
  )
  rollbacks.push(calendarEventRollback)

  const cachedScheduledEntity = getCacheEntry(
    client,
    'scheduledEntities',
    calendarEventId
  )
  if (cachedScheduledEntity) {
    const { rollback: cachedScheduledEntityRollback } = MotionCache.patch(
      client,
      getCalendarEventsQueryFilters(),
      'scheduledEntities',
      {
        [calendarEventId]: {
          ...cachedScheduledEntity.value,
          schedule: {
            ...cachedScheduledEntity.value.schedule,
            start: updates.start ?? cachedScheduledEntity.value.schedule.start,
            end: updates.end ?? cachedScheduledEntity.value.schedule.end,
          },
        },
      }
    )

    rollbacks.push(cachedScheduledEntityRollback)
  }

  function rollback() {
    notifyManager.batch(() => {
      rollbacks.forEach((rb) => rb())
    })
  }

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
