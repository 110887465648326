import { classed } from '@motion/theme'
import { VirtualizedRows } from '@motion/ui/helpers'
import { formatToReadableWeekDayMonth } from '@motion/ui-logic'

import { useModalListForNavigation } from '~/global/hooks'
import { type DateTime } from 'luxon'
import { useMemo } from 'react'

import { AgendaItem } from './agenda-item'
import { isDateRow } from './agenda-sidebar.types'
import { useAgendaItems } from './hooks'

/**
 * @deprecated This sidebar will be removed from the product soon and in favor of full-size agenda view
 */
export function AgendaVirtualizedList() {
  const rows = useAgendaItems()
  const tasksIdList = useMemo(() => {
    return (
      rows
        .filter(
          (entity) =>
            !isDateRow(entity) &&
            (entity.type === 'TASK' || entity.type === 'CHUNK')
        )
        // Remove consecutive duplicates (chunk tasks)
        .filter((item, index, array) => {
          if (item.type === 'CHUNK' && item.task) {
            if (index === 0) {
              return true
            }

            let prevItem = array[index - 1]
            if (prevItem.type === 'CHUNK' && prevItem.task) {
              return (
                item.task.parentChunkTaskId !== prevItem.task.parentChunkTaskId
              )
            }
          }

          return true
        })
        .map((entity) => {
          if (entity.type === 'CHUNK' && entity.task) {
            return entity.task.parentChunkTaskId
          }

          return entity.id
        })
    )
  }, [rows])

  useModalListForNavigation({ tasks: tasksIdList })

  return (
    <div className='w-full flex-grow min-h-0'>
      <VirtualizedRows
        items={rows}
        containerClassName='px-3'
        estimateHeight={(item) => (isDateRow(item) ? 32 : 50)}
        computeItemKey={(item) => item.id}
        renderItem={({ item }) => {
          if (!isDateRow(item)) {
            return <AgendaItem key={item.id} entity={item} />
          }

          if (item.isToday) {
            return <TodayDate date={item.date} />
          }

          return <RegularDate date={item.date} />
        }}
      />
    </div>
  )
}

function RegularDate({ date }: { date: DateTime }) {
  return (
    <DateLabel>
      <div className='flex gap-1 w-full'>
        <div className='flex justify-between font-semibold text-xs text-semantic-neutral-text-subtle w-full'>
          <div className='flex gap-1.5'>
            <span>{date.toFormat('dd')}</span>
            <span>{date.toFormat('MMM')}</span>
          </div>
          <span className='text-[11px] uppercase'>{date.toFormat('ccc')}</span>
        </div>
      </div>
    </DateLabel>
  )
}

function TodayDate({ date }: { date: DateTime }) {
  return (
    <DateLabel>
      <div className='flex gap-1 justify-between font-semibold text-xs w-full'>
        <span className='text-semantic-neutral-text-subtle'>
          {formatToReadableWeekDayMonth(date)}
        </span>
        <span className='bg-semantic-neutral-bg-strong-default text-semantic-primary-text-onDark px-1 rounded'>
          Today
        </span>
      </div>
    </DateLabel>
  )
}

const DateLabel = classed('div', {
  base: `rounded flex justify-between items-center self-stretch pr-2 pl-1 py-1 mb-2 bg-semantic-neutral-bg-subtle`,
})
