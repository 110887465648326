import { FilledChevronDownSolid } from '@motion/icons'
import {
  Button,
  CompanyLogo,
  PopoverTrigger,
  UserAvatar,
} from '@motion/ui/base'
import { getCalProvider } from '@motion/ui-logic'

import { useProfilePictureUrl } from '~/global/hooks'
import { useAuth } from '~/localServices/auth'
import { useNavigate } from 'react-router-dom'

import { AccountPopover } from './account-popover'

import { type User } from '../../../state/userSlice'

type UserAccountLinkProps = {
  user: User
}
export const UserAccountLink = (props: UserAccountLinkProps) => {
  const { user } = props

  const provider = getCalProvider(user.loginProvider)
  const email = splitEmail(user.email)
  const navigate = useNavigate()
  const { logout } = useAuth()
  const profileUrl = useProfilePictureUrl(user)

  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={({ close }) => (
        <AccountPopover
          close={close}
          logout={logout}
          navigate={navigate}
          profileUrl={profileUrl}
          provider={provider}
          user={user}
        />
      )}
    >
      <Button
        sentiment='neutral'
        variant='muted'
        data-testid='user-account-link'
        size='xsmall'
      >
        <UserAvatar
          id={user.id}
          name={user.displayName.trim() || user.email}
          profileUrl={profileUrl}
          bottomRight={
            provider ? <CompanyLogo provider={provider} /> : undefined
          }
          size={16}
        />

        <span className='text-xs text-left truncate text-semantic-neutral-text-subtle font-semibold max-w-full min-w-0 inline-block ml-1.5'>
          {user.displayName || email.account}
        </span>

        <FilledChevronDownSolid className='w-3 h-3' />
      </Button>
    </PopoverTrigger>
  )
}

function splitEmail(email: string) {
  const index = email.indexOf('@')
  if (index === -1) return { account: email }
  return { account: email.slice(0, index), domain: email.slice(index) }
}
