import { type TaskSchema } from '@motion/rpc-types'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { ActivityFeed } from '~/areas/activity-feed/components/activity-feed'
import { CollapsableContainer } from '~/areas/task-project/components'
import { useSubcribeToFeed } from '~/global/hooks/websockets'
import { useTaskByIdV2 } from '~/global/rpc/v2'

import { useEventModalState } from '../contexts'

export function EventActivityCollapsable({
  meetingTaskId,
}: {
  meetingTaskId: TaskSchema['id']
}) {
  const { setHasPendingComment } = useEventModalState()

  const { data: meetingTask } = useTaskByIdV2({ id: meetingTaskId })

  useSubcribeToFeed('task', meetingTask?.id)

  // Don't throw an error if the meeting task is not found,
  // It could be that the task is deleted, or not yet loaded
  if (!meetingTask || !isMeetingTask(meetingTask)) {
    return null
  }

  return (
    <CollapsableContainer
      title='Activity'
      onToggle={(expanded) => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_COLLAPSE_ACTIVITY', {
          collapsed: !expanded,
          type: 'event',
        })
      }}
    >
      <div className='pb-2'>
        <ActivityFeed
          type='event'
          target={meetingTask}
          workspaceId={meetingTask.workspaceId}
          onCommentChange={(value) =>
            setHasPendingComment(value.length > 0 && value !== '<p></p>')
          }
        />
      </div>
    </CollapsableContainer>
  )
}
