import { useIsTeamExpired } from '~/global/hooks/team'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { CreateTeam } from './components/CreateTeam/create-team'

import { useAppSelector } from '../../state/hooks'
import { selectPMTeamFetched } from '../../state/projectManagementSlice'
import { SettingPage } from '../Settings/Components/SettingPage'

export const ConnectedCreateTeamPage = () => {
  const pmTeamFetched = useAppSelector(selectPMTeamFetched)
  const teamId = useAppSelector((state) => state.projectManagement.teamId)
  const isTeamExpired = useIsTeamExpired()
  const navigate = useNavigate()
  const [isInProgress, setIsInProgress] = useState(false)

  useEffect(
    function redirectToTeamPage() {
      if (teamId && !isTeamExpired && !isInProgress) {
        navigate('/web/settings/team')
      }
    },
    [isTeamExpired, isInProgress, teamId, navigate]
  )

  if ((teamId && !isTeamExpired && !isInProgress) || !pmTeamFetched) {
    return null
  }

  return (
    <div className='bg-light-100 dark:bg-dark-1100 flex h-full w-full min-h-0'>
      <SettingPage className='max-w-full h-full'>
        <CreateTeam setIsInProgress={setIsInProgress} />
      </SettingPage>
    </div>
  )
}
