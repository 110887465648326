import { classed } from '@motion/theme'

export const ProjectPanelContainer = classed.div('flex flex-col h-full', {
  variants: {
    variant: {
      default: '',
      'side-panel':
        'bg-semantic-neutral-surface-overlay-bg-default border-l border-semantic-neutral-border-default',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
