import { type MultiSelectFieldSchema } from '@motion/shared/custom-fields'
import {
  type AvailableCustomFieldTypes,
  type CustomFieldValueSchemaByType,
} from '@motion/ui-logic'
import { safeParseDate } from '@motion/utils/dates'
import { createLookup } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'

import { type SelectOption } from '~/areas/custom-fields/components'
import { useProjectCustomFieldUpdater } from '~/areas/project/hooks'
import {
  type CustomFieldWithValue,
  type NormalTaskWithRelations,
  type ProjectWithRelations,
} from '~/global/proxies'
import { useCallback } from 'react'

import { useTaskCustomFieldUpdater } from '../../../../../tasks/hooks'
import { type GroupedNode, isNoneGroup } from '../../grouping'
import { type CustomFieldRowValue } from '../../tree-list'

export type CustomFieldMoveHandlerParams = {
  entity: NormalTaskWithRelations | ProjectWithRelations
  groupNode: GroupedNode
  sourceGroupNode: GroupedNode
}

export const useCustomFieldMoveHandler = () => {
  const updateTaskCustomField = useTaskCustomFieldUpdater()
  const updateProjectCustomField = useProjectCustomFieldUpdater()
  const getCorrectedValue = useCorrectedValueHandler()

  return useCallback(
    async ({
      entity,
      groupNode,
      sourceGroupNode,
    }: CustomFieldMoveHandlerParams) => {
      const field = entity.customFields[groupNode.value.type]
      if (field == null) {
        return false
      }

      const updater = isProject(entity)
        ? updateProjectCustomField
        : updateTaskCustomField

      if (isNoneGroup(groupNode)) {
        try {
          await updater(entity as any, field.definition, null)
          return true
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
              position: 'useCustomFieldMoveHandler',
            },
          })
          return false
        }
      }

      const correctedValue = getCorrectedValue({ field, groupNode })

      const dtoValue = createLookup<{
        [key in AvailableCustomFieldTypes]: (
          val: NonNullable<CustomFieldRowValue<key>>
        ) => CustomFieldValueSchemaByType<key>['value']
      }>(
        {
          text: (val) => val,
          url: (val) => val,
          number: (val) => parseFloat(val),
          date: (val) => safeParseDate(val)?.toISO() ?? null,
          person: (val) => val.id,
          select: (val) => val.id,
          multiPerson: multiValueHandler(
            (field.value ?? []) as string[],
            sourceGroupNode.value.value?.id ?? null
          ),
          multiSelect: multiValueHandler(
            (field.value ?? []) as string[],
            sourceGroupNode.value.value?.id ?? null
          ),
        },
        { defaultKey: 'text' }
      )(field.definition.type)(correctedValue)

      try {
        await updater(entity as any, field.definition, dtoValue)
        return true
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            position: 'useCustomFieldMoveHandler',
          },
        })
        return false
      }
    },
    [getCorrectedValue, updateProjectCustomField, updateTaskCustomField]
  )
}

function multiValueHandler<T extends { id: string }>(
  currentValue: T['id'][],
  sourceId: T['id'] | null
): (value: T) => T['id'][] {
  // We handled isNoneGroup case for destination node in the caller function
  return (value) => {
    if (sourceId == null) {
      return [...currentValue, value.id]
    }

    const filtered = currentValue.filter((option) => option !== sourceId)

    return [...filtered, value.id]
  }
}

// It's possible for custom fields across workspaces have the same selectable values.
// But they are grouped by value, and the groupNode has only one workspace id, meaning it can be wrong.
// In that case, we should find the correct value for the workspace.
export const useCorrectedValueHandler = () => {
  const getCorrectedSelectValue = useCallback(
    (
      def: MultiSelectFieldSchema,
      newValue: SelectOption & { workspaceId: string }
    ): SelectOption | undefined => {
      if (newValue.workspaceId !== def.workspaceId) {
        return def.metadata?.options.find(
          (option) => option.value === newValue.value
        )
      }
      return newValue
    },
    []
  )

  return useCallback(
    ({
      field,
      groupNode,
    }: {
      field: CustomFieldWithValue
      groupNode: GroupedNode
    }) => {
      let correctedValue = groupNode.value.value

      if (['multiSelect', 'select'].includes(field.definition.type)) {
        correctedValue = getCorrectedSelectValue(
          field.definition as MultiSelectFieldSchema,
          groupNode.value.value
        )
      }

      return correctedValue
    },
    [getCorrectedSelectValue]
  )
}

const isProject = (
  entity: NormalTaskWithRelations | ProjectWithRelations
): entity is ProjectWithRelations => 'projectDefinitionId' in entity
