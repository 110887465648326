import { type ComponentRef, forwardRef, type ReactNode } from 'react'

import { type PlannerRowProps, StyledPlannerRow } from './common'

export const StyledPlannerRowContainer = forwardRef<
  ComponentRef<typeof StyledPlannerRow>,
  PlannerRowProps<any> & { children?: ReactNode }
>(function PlannerRow({ children, ...props }, ref) {
  return (
    <StyledPlannerRow ref={ref} {...props}>
      {children}
    </StyledPlannerRow>
  )
})
