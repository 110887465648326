import { priorityLevels } from '@motion/rpc/types'

import { useProjectUpdater } from '~/areas/project/hooks'
import {
  PriorityField,
  type PriorityFieldProps,
} from '~/areas/task-project/fields'
import { useController } from 'react-hook-form'

import { useProjectForm } from '../hooks'

export const ControlledPriorityField = () => {
  const { form } = useProjectForm()
  const updateProject = useProjectUpdater()

  const { control, watch } = form

  const { field } = useController({
    name: 'priorityLevel',
    control,
  })
  const id = watch('id')

  const onChange: PriorityFieldProps['onChange'] = async (priorityLevel) => {
    if (id != null) {
      return await updateProject(id, { priorityLevel })
    }

    field.onChange(priorityLevel)
  }

  return (
    <PriorityField
      list={priorityLevels}
      value={field.value}
      onChange={onChange}
    />
  )
}
