import { MotionCache, type OptimisticUpdateValue } from '@motion/rpc-cache'
import { createNoneId } from '@motion/shared/identifiers'
import {
  createTemporaryCalendarEvent,
  createTemporaryScheduledEntity,
} from '@motion/ui-logic'
import {
  type CalendarEventSchemaV2,
  type CreateCalendarEventSchema,
} from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'

import { getCalendarEventsQueryFilters } from './event-query-filters'

export function applyOptimisticCalendarEventCreate(
  client: QueryClient,
  calendarEvent: CreateCalendarEventSchema & { calendarId: string }
): OptimisticUpdateValue {
  const id = createNoneId()
  const { rollback } = MotionCache.upsert(
    client,
    getCalendarEventsQueryFilters(),
    {
      models: {
        calendarEvents: {
          [id]: createTemporaryCalendarEvent(id, calendarEvent),
        },
        scheduledEntities: {
          [id]: createTemporaryScheduledEntity(id, calendarEvent),
        },
      },
    }
  )

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}

export function applyCalendarEventCreateToCaches(
  client: QueryClient,
  calendarEvent: CalendarEventSchemaV2
) {
  MotionCache.upsert(client, getCalendarEventsQueryFilters(), {
    models: {
      calendarEvents: { [calendarEvent.id]: calendarEvent },
      scheduledEntities: {
        [calendarEvent.id]: createTemporaryScheduledEntity(
          calendarEvent.id,
          calendarEvent
        ),
      },
    },
  })
}
