import { type SvgIcon } from '@motion/icons'

import { twMerge } from 'tailwind-merge'

export type TextCenteredIconProps = {
  colorToken: string
  icon: SvgIcon
  size: 'small' | 'medium'
  text: string | number
}

// component intended to be used to show the date in the calendar icon and
// the first character of a name in the workspace icon. will move to UI if more uses appear
export function TextCenteredIcon({
  colorToken,
  icon: Icon,
  size,
  text,
}: TextCenteredIconProps) {
  const sizeMap = {
    small: 'w-4 h-4',
    medium: 'w-5 h-5',
  }

  const fontSizeMap = {
    small: 'text-[7px]',
    medium: 'text-[8px]',
  }

  return (
    <div className={twMerge('flex flex-col items-center relative', colorToken)}>
      <Icon className={twMerge(sizeMap[size])} />
      <span
        className={twMerge(
          'absolute translate-x-[-10%] translate-y-[40%] tracking-tighter font-black',
          fontSizeMap[size]
        )}
      >
        {text}
      </span>
    </div>
  )
}
