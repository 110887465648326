import { type NumberInputFormat } from '@motion/ui/helpers'
import { type NumberCustomField } from '@motion/ui-logic'

export const numberCustomFieldToNumberInputFormat: Record<
  NumberCustomField['metadata']['format'],
  NumberInputFormat
> = {
  plain: { type: 'decimal', decimalPlaces: 2, grouped: false },
  percent: { type: 'percent', decimalPlaces: 2, grouped: false },
  formatted: { type: 'decimal', decimalPlaces: 2, grouped: true },
}
