import { createContext, useContext } from 'react'

import { type TemplateProjectFormActions } from '../types'

const defaultValue: TemplateProjectFormActions = {
  closeModal: () => Promise.resolve(undefined),
  uploadImage: () => Promise.resolve(undefined),
  openLinkModalPrompt: () => Promise.resolve(null),
  submitTemplate: () => Promise.resolve({ id: '' }),
}

export const TemplateProjectFormHandlersContext = createContext(defaultValue)

export function useTemplateProjectFormHandlers() {
  return useContext(TemplateProjectFormHandlersContext)
}
