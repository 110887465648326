import { AdjustmentsSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import { type WorkspaceSchema } from '@motion/zod/client'

import { MultiStatusDropdown } from '~/areas/project-management/components'
import { useWorkspaceStatuses } from '~/global/hooks'

import { useWorkspacesTreeviewContext } from '../../../hooks/use-workspaces-treeview-context'

interface WorkspaceFilterButtonProps {
  workspace: WorkspaceSchema
  filteredStatusIds: string[]
  isDefaultValue: boolean
}

export function WorkspaceFilterButton(props: WorkspaceFilterButtonProps) {
  const { workspace, filteredStatusIds, isDefaultValue } = props

  const { updateWorkspaceStatusFilters } = useWorkspacesTreeviewContext()
  const statuses = useWorkspaceStatuses(workspace.id)

  return (
    <MultiStatusDropdown
      items={statuses}
      onChange={(items) => {
        updateWorkspaceStatusFilters(
          workspace.id,
          items.map((item) => item.id)
        )
      }}
      computeSelected={(item) => filteredStatusIds.includes(item.id)}
      footerActions={[
        {
          label: 'Reset',
          onAction: () => {
            updateWorkspaceStatusFilters(workspace.id, null)
          },
          closeOnAction: true,
        },
        {
          label: 'Select all',
          onAction: () => {
            updateWorkspaceStatusFilters(
              workspace.id,
              statuses.map((item) => item.id)
            )
          },
        },
      ]}
    >
      <Tooltip content='Filter projects in sidebar' asChild>
        <IconButton
          icon={AdjustmentsSolid}
          size='xsmall'
          sentiment={isDefaultValue ? 'neutral' : 'primary'}
          variant='muted'
        />
      </Tooltip>
    </MultiStatusDropdown>
  )
}
