import { UnfitTaskTooltipHeader } from './unfit-task-tooltip-header'

export const UnfitFallbackTooltip = () => {
  return (
    <div className='flex flex-col gap-2 text-sm leading-5 py-0.5 px-1 text-left'>
      <UnfitTaskTooltipHeader />

      <span className='text-tooltip-text-subtle italic'>
        This is because there’s no space in your calendar. Please check for any
        busy events that may be blocking scheduling of tasks.
      </span>
    </div>
  )
}
