import { type FC, memo, type ReactNode } from 'react'

import { BulkOpsSection } from './components'
import {
  BulkOpsFieldsProvider,
  BulkOpsGroupsProvider,
  BulkOpsProvider,
  type BulkOpsTarget,
  BulkSelectionItemsWorkspaceContext,
  useBulkOpsState,
} from './contexts'
import { CoreFields, CustomFields } from './fields'

export type BulkOpsContainerProps = {
  target: BulkOpsTarget
  children?: ReactNode
}

export const BulkOpsContainer: FC<BulkOpsContainerProps> = ({
  target,
  children,
}) => {
  return (
    <BulkOpsProvider target={target}>
      <InnerBulkOpsContainer>{children}</InnerBulkOpsContainer>
    </BulkOpsProvider>
  )
}

export type InnerBulkOpsContainerProps = {
  children?: ReactNode
}

const InnerBulkOpsContainer = memo(function InnerBulkOpsContainer({
  children,
}: InnerBulkOpsContainerProps) {
  const { selectedIds, setSelectedIds } = useBulkOpsState()

  const shouldShowBulkSection = selectedIds.length > 0

  return (
    <div className='grid w-full grid-rows-[1fr_min-content]'>
      <section className='flex min-w-0 min-h-0'>{children}</section>
      {shouldShowBulkSection && (
        <BulkOpsGroupsProvider>
          <BulkOpsFieldsProvider>
            <BulkSelectionItemsWorkspaceContext>
              <BulkOpsSection
                onCancel={() => setSelectedIds([])}
                selectedIds={selectedIds}
                renderCoreFields={() => <CoreFields />}
                renderCustomFields={() => <CustomFields />}
              />
            </BulkSelectionItemsWorkspaceContext>
          </BulkOpsFieldsProvider>
        </BulkOpsGroupsProvider>
      )}
    </div>
  )
})
