import {
  type ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'
import { type Range, useVirtualizer } from '@tanstack/react-virtual'
import { useCallback, useEffect, useRef, useState } from 'react'

import { ColumnNameContainer } from './column-name-container'
import { getHeightAndStackedProjects, isStackRow } from './utils'

import { type GroupedNode, type Tree } from '../../grouping'
import { useViewState } from '../../view-state'
import { useOOOEventsForRowFn, useSidebarSize } from '../hooks'
import {
  ConnectedStackedPlannerRow,
  LegacyHeaderRow,
  SinglePlannerRow,
} from '../row'
import {
  BASE_SIDEBAR_WIDTH,
  MULTI_PROJECT_ROW_GAP,
  OOO_EVENT_GAP,
  ZIndexMap,
} from '../shared-constants'
import { showOOOEventForRow } from '../utils'

type ContainerProps<T extends GroupedNode<any>> = {
  tree: Tree<T>
}

export const LegacyVirtualContainer = <T extends GroupedNode>(
  props: ContainerProps<T>
) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const [viewState] = useViewState()
  const sidebarSize = useSidebarSize()
  const getOOOEventsForRow = useOOOEventsForRowFn()

  // If Stacking is on in the view state, then stack the projects
  const stackProjects = viewState.groupBy.stackProjects

  const [expanded, setExpanded] = useState<ExpandedState>(() => {
    const data: Record<string, boolean> = {}

    function expand(item: Tree<T>) {
      // Only group nodes have children. Leafs don't
      if (item.children != null) {
        data[item.qualifiedKey] = true
        item.children.forEach(expand)
      }
    }

    props.tree.children.forEach(expand)
    return data
  })

  // Get flat list of all nodes and virtualized list of rows to render
  const table = useReactTable({
    data: props.tree.values,
    columns: [],
    state: {
      expanded: expanded,
    },
    filterFromLeafRows: true,
    getRowId: (row) => row.qualifiedKey,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => {
      if (stackProjects && isStackRow(row, props.tree)) {
        // If group level is more than 1 and this is the last group level, then we stack (group) the projects (remove all children)
        return undefined
      }

      return row.children as T[] | undefined
    },
    getExpandedRowModel: getExpandedRowModel(),
  })

  const { rows } = table.getRowModel()

  const rangeExtractor = useCallback((range: Range) => {
    // Full page of rows
    return Array.from(
      { length: Math.min(range.endIndex + range.overscan, range.count) },
      (_, i) => i
    )
  }, [])

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48,
    getScrollElement: () => parentRef.current,
    getItemKey: (index) => rows[index].id,
    rangeExtractor,
    overscan: 5,
  })

  const toggleExpandAllRows = useCallback(
    (expand: boolean) => table.toggleAllRowsExpanded(expand),
    [table]
  )

  // Expand all by default
  useEffect(() => {
    toggleExpandAllRows(true)
  }, [toggleExpandAllRows])

  const virtualItems = rowVirtualizer.getVirtualItems()

  return (
    <div
      className='overflow-y-auto w-full h-full overflow-x-hidden pb-4'
      ref={parentRef}
    >
      <div
        className='relative min-h-full'
        style={{
          height: rowVirtualizer.getTotalSize(),
        }}
      >
        <ColumnNameContainer
          style={{
            width: sidebarSize,
            minWidth: BASE_SIDEBAR_WIDTH,
            zIndex: ZIndexMap.nameBackgroundContainer,
          }}
        />
        {virtualItems.map((virtualItem, currentIndex) => {
          const row = rows[virtualItem.index]
          const visible =
            virtualItem.index >= (rowVirtualizer.range?.startIndex ?? 0)
          const canExpand = row.getCanExpand()

          const depth = getRowDepth(row)
          const { rowHeight, stackedProjects } = getHeightAndStackedProjects({
            row,
            tree: props.tree,
            stackProjects,
          })

          if (stackedProjects) {
            // Check if next row is not a stacked row
            const isLastRow =
              rows.length > 0 &&
              props.tree.groups.length > 1 &&
              rows[virtualItem.index + 1]?.original &&
              !isStackRow(rows[virtualItem.index + 1].original, props.tree)

            const GAP_HEIGHT = isLastRow ? 0 : MULTI_PROJECT_ROW_GAP

            const eventsToShow = getOOOEventsForRow(row)
            const OOO_GAP = eventsToShow.length > 0 ? OOO_EVENT_GAP : 0

            return (
              <ConnectedStackedPlannerRow
                key={row.id}
                ref={rowVirtualizer.measureElement}
                stackedProjects={stackedProjects}
                expandable={canExpand}
                visible={visible}
                data-index={virtualItem.index}
                style={{
                  // @ts-expect-error - css
                  '--depth': depth,
                  height: rowHeight + GAP_HEIGHT + OOO_GAP,
                }}
                gapHeight={GAP_HEIGHT}
                row={row}
              />
            )
          }

          const RowComponent = canExpand ? LegacyHeaderRow : SinglePlannerRow
          const showOOOForRow = showOOOEventForRow(row)
          let eventHeight = 0
          if (canExpand && showOOOForRow) {
            const nextHeaderRowIndex = virtualItems.findIndex((item, index) => {
              return (
                index > currentIndex && showOOOEventForRow(rows[item.index])
              )
            })
            const eventRowEndIndex =
              nextHeaderRowIndex === -1
                ? virtualItems.length
                : nextHeaderRowIndex

            for (let i = currentIndex + 1; i < eventRowEndIndex; i++) {
              const item = virtualItems[i]
              const { rowHeight, stackedProjects } =
                getHeightAndStackedProjects({
                  row: rows[item.index],
                  tree: props.tree,
                  stackProjects,
                })
              eventHeight += rowHeight

              if (stackedProjects) {
                eventHeight += MULTI_PROJECT_ROW_GAP
              }
            }
          }

          return (
            <RowComponent
              key={row.id}
              ref={rowVirtualizer.measureElement}
              expandable={canExpand}
              visible={visible}
              data-index={virtualItem.index}
              toggleExpandAllRows={toggleExpandAllRows}
              eventHeight={eventHeight}
              style={{
                // @ts-expect-error - css
                '--depth': depth,
                height: rowHeight,
              }}
              row={row}
            />
          )
        })}
      </div>
    </div>
  )
}

function getRowDepth<T extends GroupedNode>(row: Row<T>) {
  return row.getCanExpand() ? row.depth : Math.max(row.depth - 1, 0)
}
