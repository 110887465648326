import { type NewEventType } from '~/areas/calendar/utils'
import { DateTime } from 'luxon'

import { type ScheduleAssistantMode } from './types'

export const ModeToLabels: Record<
  ScheduleAssistantMode,
  {
    calloutLabel: string
    submitLabel: string
  }
> = {
  'select-existing': {
    calloutLabel: 'Click on any event on your calendar to select.',
    submitLabel: 'Select event',
  },
  'create-new': {
    calloutLabel: 'Select a meeting time by dragging on the calendar.',
    submitLabel: 'Select time',
  },
}

export const defaultNewEvent: NewEventType = {
  allDay: false,
  start: DateTime.now().toISO(),
  end: DateTime.now().plus({ minutes: 30 }).toISO(),
}
