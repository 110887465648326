import { TextField } from '@motion/ui/forms'

import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

export const EventNameRow = () => {
  const {
    template: {
      state: { externalEventName },
      setters: { setExternalEventName },
    },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow title='Event name' subtitle='Your invitee sees this'>
      <div>
        <TextField
          placeholder='e.g. User interview'
          value={externalEventName}
          onChange={(e) => {
            setExternalEventName(e)
          }}
        />
      </div>
    </SectionRow>
  )
}
