import { PlusSolid } from '@motion/icons'
import { type CustomFieldSchema } from '@motion/shared/custom-fields'
import {
  ActionList,
  PopoverTrigger,
  type PopoverTriggerProps,
  SearchableList,
} from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'
import { byProperty, byValue, cascade, ordered } from '@motion/utils/array'
import { useModalApi } from '@motion/web-common/modals'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useCustomFieldById } from '~/areas/custom-fields/hooks'
import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { Label } from '~/global/components/labels'
import { type ReactNode, useMemo, useState } from 'react'

export type MultiSelectDropdownProps = {
  selectedItemIds: string[]
  onChange: (ids: string[]) => void
  onClose?: PopoverTriggerProps['onClose']
  workspaceId: WorkspaceSchema['id']
  customFieldId: CustomFieldSchema['id']
  children?: ReactNode
  disabled?: boolean
  hideBottomActionsSection?: boolean
  renderContentTopSection?: () => ReactNode
  size?: 'small' | 'xsmall'
}

export const MultiSelectDropdown = ({
  children,
  onClose,
  ...rest
}: MultiSelectDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      onClose={onClose}
      renderPopover={({ close }) => (
        <MultiSelectContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export type MultiSelectContentProps = MultiSelectDropdownProps & {
  close: () => void
}

export const MultiSelectContent = ({
  close,
  selectedItemIds,
  onChange,
  customFieldId,
  hideBottomActionsSection,
  renderContentTopSection,
  size,
}: MultiSelectContentProps) => {
  const workspaceMultiSelect = useCustomFieldById(customFieldId, 'multiSelect')
  if (workspaceMultiSelect == null) {
    return null
  }

  return (
    <InnerMultiSelectContent
      workspaceMultiSelect={workspaceMultiSelect}
      selectedItemIds={selectedItemIds}
      close={close}
      onChange={onChange}
      hideBottomActionsSection={hideBottomActionsSection}
      renderContentTopSection={renderContentTopSection}
      size={size}
    />
  )
}

function InnerMultiSelectContent({
  workspaceMultiSelect,
  selectedItemIds,
  close,
  onChange,
  hideBottomActionsSection = false,
  renderContentTopSection,
  size,
}: Omit<MultiSelectDropdownProps, 'customFieldId' | 'workspaceId'> & {
  workspaceMultiSelect: Extract<WorkspaceCustomField, { type: 'multiSelect' }>
  close: () => void
}) {
  const modalApi = useModalApi()
  const openAddSelectOptionModal = () => {
    setTimeout(async () => {
      await modalApi.prompt('add-custom-field-select-option', {
        workspaceId: workspaceMultiSelect.workspaceId,
        customFieldId: workspaceMultiSelect.id,
        name: workspaceMultiSelect.name,
        onValue: (newOption) => {
          onChange([...selectedItemIds, newOption.id])
        },
      })
    }, 200)
  }

  const {
    metadata: { options },
  } = workspaceMultiSelect

  const computeSelected = (item: { id: string }) =>
    selectedItemIds.includes(item.id)

  // Keep the initial sort on mount, so checking/unchecking items while it opens doesn't reshuffle everything
  const [initialSortedItemIds] = useState(() =>
    [...options]
      .sort(
        cascade(
          byValue(computeSelected, ordered([true, false]))
          // byProperty('sortPosition', Compare.caseInsensitive)
        )
      )
      .map((l) => l.id)
  )

  const sortedItems = useMemo(
    () =>
      [...options].sort(
        cascade(
          byProperty('id', ordered(initialSortedItemIds))
          // byProperty('sortPosition', Compare.caseInsensitive)
        )
      ),
    [options, initialSortedItemIds]
  )

  return (
    <>
      {options.length === 0 ? (
        <ActionList
          items={[
            {
              content: 'No options found',
              onAction: close,
              disabled: true,
            },
          ]}
        />
      ) : (
        <>
          {renderContentTopSection?.()}
          <SearchableList
            itemType='checkbox'
            items={sortedItems}
            computeSelected={computeSelected}
            computeKey={(item) => item.id}
            computeSearchValue={(item) => item.value}
            inputProps={{
              placeholder: `Choose ${truncateAtSpace(workspaceMultiSelect.name)}`,
            }}
            renderItem={(item) => (
              <Label
                value={{ id: item.id, name: item.value, color: item.color }}
                size={size}
              />
            )}
            onSelect={(v) => {
              const newValues = v.map((item) => item.id)
              onChange(newValues)
            }}
          />
        </>
      )}
      {!hideBottomActionsSection && (
        <div className='flex flex-col border-t border-dropdown-border mt-1'>
          <ActionList
            onActionAnyItem={close}
            items={[
              {
                prefix: <PlusSolid />,
                content: `Add ${truncateAtSpace(workspaceMultiSelect.name)} option`,
                onAction: openAddSelectOptionModal,
              },
            ]}
          />
        </div>
      )}
    </>
  )
}
