import {
  createStateKey,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'

import { type ProjectWithRelations } from '~/global/proxies'

export type AddProjectState = {
  parentRowId: string | null
  project: ProjectWithRelations | null
}

const defaultValue: AddProjectState = {
  parentRowId: null,
  project: null,
}

export const AddProjectState = createStateKey<AddProjectState>(
  'add-project-state',
  {
    defaultValue,
  }
)

export const useAddProjectStateValue = () => useSharedState(AddProjectState)[0]
export const useAddProjectStateSetter = () =>
  useSharedStateSendOnly(AddProjectState)
