import { ArrowDownSolid, ArrowUpSolid } from '@motion/icons'
import { IconButton, Tooltip, useShortcut } from '@motion/ui/base'
import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'

import { TaskDefinitionPrevNextTooltipContent } from './task-definition-prev-next-tooltip-content'

import { useShowNotSavedModalIfDirtyFn } from '../hooks'
import { useTaskDefinitionForm } from '../use-task-definition-form'
import { useTaskDefinitionModalState } from '../use-task-definition-modal-state'

export const PreviousNextModalButtons = () => {
  const {
    form: { watch },
  } = useTaskDefinitionForm()
  const { flowFormData } = useTaskDefinitionModalState()

  const tasks = flowFormData?.stages.flatMap((stage) => stage.tasks)
  if (!tasks) return null

  const taskId = watch('id')

  const taskIndex = tasks.findIndex((task) => task.id === taskId)

  const prevTask = tasks[taskIndex - 1] ?? null
  const nextTask = tasks[taskIndex + 1] ?? null

  return (
    <InnerArrowButtons
      prevTask={prevTask}
      nextTask={nextTask}
      renderTooltip={(taskDefinition, shortcut, isNext) => (
        <TaskDefinitionPrevNextTooltipContent
          taskDefinition={taskDefinition}
          shortcut={shortcut}
          isNext={isNext}
        />
      )}
    />
  )
}

interface InnerArrowButtonsProps {
  prevTask: FlowTemplateFormTask | null
  nextTask: FlowTemplateFormTask | null
  renderTooltip: (
    taskDefinition: FlowTemplateFormTask,
    shortcut: string,
    isNext?: boolean
  ) => React.ReactNode
}

const InnerArrowButtons = ({
  prevTask,
  nextTask,
  renderTooltip,
}: InnerArrowButtonsProps) => {
  const { setTaskId } = useTaskDefinitionModalState()
  const showNotSavedModalIfDirty = useShowNotSavedModalIfDirtyFn({
    confirmButtonText: 'Proceed without saving',
  })

  const canGoPrev = prevTask != null
  const canGoNext = nextTask != null

  const prevId = prevTask?.id
  const nextId = nextTask?.id

  const navigateToTask = async (taskId: string | undefined) => {
    const response = await showNotSavedModalIfDirty()

    if (response === true && taskId) {
      setTaskId(taskId)
    }
  }

  useShortcut('mod+arrowup', () => navigateToTask(prevId), {
    enabled: canGoPrev,
  })
  useShortcut('mod+arrowdown', () => navigateToTask(nextId), {
    enabled: canGoNext,
  })

  return (
    <div className='flex flex-col gap-1'>
      <Tooltip
        asChild
        renderContent={
          prevTask ? () => renderTooltip(prevTask, 'mod+arrowup') : undefined
        }
      >
        <IconButton
          icon={ArrowUpSolid}
          sentiment='onDark'
          size='small'
          variant='muted'
          disabled={!canGoPrev}
          onClick={() => navigateToTask(prevId)}
        />
      </Tooltip>
      <Tooltip
        asChild
        renderContent={
          nextTask
            ? () => renderTooltip(nextTask, 'mod+arrowdown', true)
            : undefined
        }
      >
        <IconButton
          icon={ArrowDownSolid}
          sentiment='onDark'
          size='small'
          variant='muted'
          disabled={!canGoNext}
          onClick={() => navigateToTask(nextId)}
        />
      </Tooltip>
    </div>
  )
}
