import { Tooltip } from '@motion/ui/base'
import { isUnassignedUser } from '@motion/ui-logic'

import { ConnectedInvitePlaceholderTeammateButton } from '~/areas/project-management/components/invite-placeholder-teammate-button'
import { UserLabel } from '~/global/components/labels'

import { MultiListContent } from './multi-list-content'
import { SingleListContent } from './single-list-content'

import { useAssigneeDropdownSortedOptions } from '../hooks'
import {
  type AssigneeDropdownContentProps,
  type SharedListProps,
} from '../types'

export const AssigneeDropdownContent = ({
  close,
  workspaceId,
  removeUnassignedOption = false,
  disableOptionFn = () => false,
  disabledOptionTooltip,
  hideBottomActionsSection = false,
  renderContentTopSection,
  ...rest
}: AssigneeDropdownContentProps) => {
  const listItems = useAssigneeDropdownSortedOptions({
    removeUnassignedOption,
    workspaceId,
  })

  const selectedIds =
    rest.type === 'multi'
      ? rest.selectedUserIds
      : rest.selectedUserId === undefined
        ? []
        : [rest.selectedUserId]
  const isSelectedIncludingUnassigned = selectedIds.includes(null)

  const sharedProps: SharedListProps = {
    items: listItems,
    computeKey: (item) => item.id,
    computeSearchValue: (item) => item.user.name,
    computeSelected: (item) =>
      selectedIds.includes(item.userId) ||
      (isSelectedIncludingUnassigned && isUnassignedUser(item)),
    renderItem: (item) => (
      <Tooltip
        content={disableOptionFn(item) ? disabledOptionTooltip : undefined}
      >
        <div className='flex gap-1 items-center w-full'>
          <div className='flex-1'>
            <UserLabel
              value={isUnassignedUser(item) ? null : item.user}
              disabled={disableOptionFn(item)}
            />
          </div>
          <div onClick={(event) => event.stopPropagation()}>
            <ConnectedInvitePlaceholderTeammateButton userId={item.userId} />
          </div>
        </div>
      </Tooltip>
    ),
    inputProps: { placeholder: 'Choose assignee...' },
    close,
    workspaceId,
    hideBottomActionsSection,
    renderContentTopSection,
    disableOptionFn,
  }

  return rest.type === 'multi' ? (
    <MultiListContent {...sharedProps} onChange={rest.onChange} />
  ) : (
    <SingleListContent {...sharedProps} onChange={rest.onChange} />
  )
}
