import { templateStr } from '@motion/ui-logic'

import { useI18N } from '~/global/contexts'
import { Fragment, type ReactNode } from 'react'

import { ActivityAssigneeName } from '../activity-assignee-name'
import { ActivityValue } from '../activity-value'

type ActivityMultiPersonProps = {
  oldValues: string[]
  newValues: string[]
  name: string
  workspaceId: string
}

export function ActivityMultiPerson({
  oldValues,
  newValues,
  name,
  workspaceId,
}: ActivityMultiPersonProps) {
  const { pluralize } = useI18N()

  const addedPeople = newValues.filter((value) => !oldValues.includes(value))
  const removedPeople = oldValues.filter((value) => !newValues.includes(value))

  return templateStr(
    `{{removedValues}}{{and}}{{addedValues}} {{direction}} {{name}}`,
    {
      and: addedPeople.length > 0 && removedPeople.length > 0 ? ' and ' : '',
      direction: removedPeople.length > 0 ? 'from' : 'to',
      removedValues:
        removedPeople.length > 0 ? (
          <>
            {`removed ${pluralize(removedPeople.length, 'person', 'people')} `}
            <ActivityValue>
              {removedPeople.map<ReactNode>((person, index) => (
                <Fragment key={person}>
                  {index > 0 && ', '}
                  <ActivityAssigneeName assigneeId={person} />
                </Fragment>
              ))}
            </ActivityValue>
          </>
        ) : null,
      addedValues:
        addedPeople.length > 0 ? (
          <>
            {`added ${pluralize(addedPeople.length, 'person', 'people')} `}
            <ActivityValue>
              {addedPeople.map<ReactNode>((person, index) => (
                <Fragment key={person}>
                  {index > 0 && ', '}
                  <ActivityAssigneeName assigneeId={person} />
                </Fragment>
              ))}
            </ActivityValue>
          </>
        ) : null,
      name: <ActivityValue>{name}</ActivityValue>,
    }
  )
}
