import { PuzzleSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'

import { AppWorkspaceContext } from '~/global/contexts'

import { ConnectedGroupedItemDropdown } from './connected-grouped-item-dropdown'
import { FilterItem } from './filter-item/filter-item'

import { useFieldFilter } from '../../../context'
import {
  WorkspaceItem,
  WorkspaceItemGroupHeader,
} from '../../buttons/workspace-item'
import { MultiSelectFilterValue } from '../../multi-select-filter-value'
import { type DropdownFilterProps } from '../../types'

export const ConnectedProjectDefinitionFilter = (
  props: DropdownFilterProps
) => {
  const [value, setValue] = useFieldFilter('projects', 'projectDefinitionIds')
  const ids = value?.value ?? null
  const [ctx] = useSharedState(AppWorkspaceContext)

  const getAllItems = () => ctx.projectDefinitions.all

  return (
    <FilterItem
      label='Templates'
      canInvert
      inverted={value?.inverse}
      onInvertChanged={(invert) => {
        if (value == null) return
        setValue({ ...(value ?? {}), inverse: invert })
      }}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConnectedGroupedItemDropdown
          applyTo='projects'
          field='projectDefinitionIds'
          getAllItems={getAllItems}
          placeholder='Choose templates...'
          renderItem={(item) => (
            <WorkspaceItem item={item}>
              <div className='max-w-xs truncate'>{item.name}</div>
            </WorkspaceItem>
          )}
          renderHeader={(group) => (
            <WorkspaceItemGroupHeader group={group}>
              <div className='max-w-xs truncate'>{group.items[0].name}</div>
            </WorkspaceItemGroupHeader>
          )}
          close={close}
        />
      )}
    >
      <MultiSelectFilterValue
        Icon={PuzzleSolid}
        type='projectDefinitions'
        ids={ids}
        getAllItems={getAllItems}
      />
    </FilterItem>
  )
}
