import { type SupportedCustomFieldFilterNumberOperators } from '@motion/ui-logic/pm/data'

import { type ConditionOption } from '../../filter-item'

export const ALLOWED_NUMBER_OPERATORS = [
  { id: 'equals', label: 'equal to' },
  { id: 'gt', label: 'greater than' },
  { id: 'lt', label: 'less than' },
  { id: 'gte', label: 'greater than or equal to' },
  { id: 'lte', label: 'less than or equal to' },
  { id: 'range', label: 'between' },
  { id: 'empty', label: 'none' },
] as const satisfies ConditionOption<SupportedCustomFieldFilterNumberOperators>[]
