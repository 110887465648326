import {
  Banner,
  type BannerProps,
  type BannerSentiment,
  BannerSentimentValues,
} from '@motion/ui/base'
import { useLocalStorage } from '@motion/web-common/storage'

import { useExperiment } from '../../localServices/experiments'

/**
 * Example JSON payload in amplitude flag
 {
  "id": "subtasks-deprecation", // Change the id to show a new banner.
  "title": "Subtasks are moving!",
  "action": {
    "url": "https://example.com",
    "label": "Learn more"
  }, // Shows a button with a link
  "forRoutes": ["/web/projectManagement"], // Optionally control on which routes the banner is rendered
  "sentiment": "default", // the banner color: default, warning or error
  "dismissible": true
  }
 */

const ONE_DAY = 24 /* hours*/ * 60 /* minutes*/ * 60 /* seconds*/ * 1000 /* ms*/

export const FeatureFlaggedBanner = ({}) => {
  const bannerExperiment = useExperiment('feature_flagged_banner')
  const [_localStorageValue, setLocalStorageValue] = useLocalStorage(
    'motionDismissedFeatureFlaggedBanner'
  )
  const localStorageValue = _localStorageValue ?? {
    payload: bannerExperiment.payload,
    dismissedAt: 0,
  }

  if (
    !(
      bannerExperiment?.value === 'on' &&
      bannerExperiment.payload &&
      bannerExperiment.payload.id &&
      bannerExperiment.payload.title
    )
  )
    return null

  // if there's a routes array in the payload only render if current route has at least one match.
  if (
    Array.isArray(bannerExperiment.payload.forRoutes) &&
    !bannerExperiment.payload.forRoutes.some((route) =>
      window.location.pathname.includes(route)
    )
  )
    return null

  const isDismissible = bannerExperiment.payload?.dismissible ?? false

  let isDismissed = false
  if (
    isDismissible &&
    localStorageValue.payload &&
    localStorageValue.payload.id &&
    localStorageValue.payload.id === bannerExperiment.payload?.id &&
    localStorageValue.dismissedAt &&
    Date.now() - localStorageValue.dismissedAt < ONE_DAY
  ) {
    isDismissed = true
  }

  let sentiment: BannerSentiment = 'default'
  if (
    bannerExperiment.payload.sentiment &&
    BannerSentimentValues.includes(bannerExperiment.payload.sentiment)
  ) {
    sentiment = bannerExperiment.payload.sentiment
  }

  let action: NonNullable<BannerProps['actions']>[number] | undefined =
    undefined
  if (
    bannerExperiment.payload.action?.label &&
    bannerExperiment.payload.action.url
  ) {
    action = bannerExperiment.payload.action
  }

  return (
    <Banner
      actions={action ? [action] : undefined}
      sentiment={sentiment}
      onDismiss={
        isDismissible
          ? () => {
              setLocalStorageValue({
                payload: { id: bannerExperiment.payload.id },
                dismissedAt: Date.now(),
              })
            }
          : undefined
      }
      isDismissed={isDismissed}
    >
      {bannerExperiment.payload.title.trim()}
    </Banner>
  )
}
