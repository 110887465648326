import { type TaskSchema } from '@motion/zod/client'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import {
  ControlledCustomFieldsSidebar,
  type ControlledCustomFieldsSidebarProps,
} from '~/areas/task-project/components'
import { useTaskCustomFieldUpdater } from '~/areas/tasks/hooks'

import { useTaskForm } from '../../hooks'

type CustomFieldsSidebarSectionProps = {
  workspaceId: string
  taskId: TaskSchema['id'] | null | undefined
  disabled?: boolean
}

export const CustomFieldsTaskSidebarSection = ({
  workspaceId,
  taskId,
  disabled,
}: CustomFieldsSidebarSectionProps) => {
  const {
    form: { control },
  } = useTaskForm()

  const updateTaskCustomFields = useTaskCustomFieldUpdater()
  const { getCustomField } = useCustomFieldsFns()

  const shouldTriggerAutoSave = taskId != null

  const onChange: ControlledCustomFieldsSidebarProps['onChange'] = async (
    customFieldId,
    valueArg
  ) => {
    if (shouldTriggerAutoSave) {
      const customField = getCustomField(customFieldId)
      if (customField == null) {
        throw new Error('Custom field not found')
      }

      return await updateTaskCustomFields(taskId, customField, valueArg)
    }
  }

  return (
    <ControlledCustomFieldsSidebar
      control={control as any}
      workspaceId={workspaceId}
      disabled={disabled}
      onChange={shouldTriggerAutoSave ? onChange : undefined}
    />
  )
}
