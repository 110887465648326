import { type PMProjectType, type PMTaskType } from '@motion/rpc/types'
import {
  getMeetingTaskStaticURL,
  getProjectStaticURL,
  getTaskStaticURL,
} from '@motion/ui-logic'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useUriByRouteId } from '~/routing'
import { useCallback } from 'react'

import { useClipboard } from '../../../localServices/clipboard'

type CopyWorkspaceLinkToClipboard = {
  workspaceId: WorkspaceSchema['id']
  workspaceName: WorkspaceSchema['name']
}

export const useCopyWorkspaceLinkToClipboard = () => {
  const copyLink = useCopyHtmlLinkToClipboard()
  const getRouteUri = useUriByRouteId()

  return useCallback(
    ({ workspaceId, workspaceName }: CopyWorkspaceLinkToClipboard) => {
      const workspacePath = getRouteUri('workspace-detail', { workspaceId })
      const fullUrl = new URL(workspacePath, window.location.origin).toString()

      copyLink(fullUrl, workspaceName)
    },
    [copyLink, getRouteUri]
  )
}

export const useCopyProjectLinkToClipboard = () => {
  const copyLink = useCopyHtmlLinkToClipboard()
  const getRouteUri = useUriByRouteId()

  return useCallback(
    ({ workspaceId, projectId, projectName }: CopyProjectLinkToClipboard) => {
      const projectPath = getRouteUri('workspace-project', {
        workspaceId,
        projectId,
      })
      const fullUrl = new URL(projectPath, window.location.origin).toString()

      copyLink(fullUrl, projectName)
    },
    [copyLink, getRouteUri]
  )
}

type CopyProjectLinkToClipboard = {
  workspaceId: NonNullable<PMProjectType['workspaceId']>
  projectName: NonNullable<PMProjectType['name']>
  projectId: NonNullable<PMProjectType['name']>
}

export const useCopyProjectModalLinkToClipboard = () => {
  const copyPMLink = useCopyHtmlLinkToClipboard()

  return useCallback(
    ({ workspaceId, projectId, projectName }: CopyProjectLinkToClipboard) => {
      const projectURL = getProjectStaticURL({ workspaceId, projectId })
      copyPMLink(projectURL, projectName)
    },
    [copyPMLink]
  )
}

interface CopyLinkArgs {
  workspaceId: PMTaskType['workspaceId']
  taskId: PMTaskType['id']
  taskName: PMTaskType['name']
  isMeeting?: boolean
}

export const useCopyTaskLinkToClipboard = () => {
  const copyPMLink = useCopyHtmlLinkToClipboard()

  return useCallback(
    ({ workspaceId, taskId, taskName, isMeeting }: CopyLinkArgs) => {
      const taskURL = isMeeting
        ? getMeetingTaskStaticURL({ workspaceId, taskId })
        : getTaskStaticURL({ workspaceId, taskId })

      copyPMLink(taskURL, taskName)
    },
    [copyPMLink]
  )
}

export function useCopyHtmlLinkToClipboard() {
  const copy = useCopyLinkToClipboard()

  return useCallback(
    (url: string, title: string) => {
      const html = `<a href="${url}">${title}</a>`
      copy({ text: url, html })
    },
    [copy]
  )
}

function useCopyLinkToClipboard() {
  const clipboard = useClipboard()

  return useCallback(
    (textAndHtmlLink: { text: string; html: string }) => {
      void clipboard.write(textAndHtmlLink, {
        notificationText: 'Link copied successfully',
      })
    },
    [clipboard]
  )
}
