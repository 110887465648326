import { SHORT_TASK_DURATION } from '@motion/ui-logic'
import { DEFAULT_SCHEDULE_ID } from '@motion/ui-logic/pm/task'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useTaskUpdater } from '~/areas/tasks/hooks'
import {
  ScheduleDropdown,
  type ScheduleDropdownProps,
} from '~/global/components/dropdowns'
import { type TaskWithRelations } from '~/global/proxies'
import { useAppSelector } from '~/state/hooks'
import { selectSchedules } from '~/state/settingsSlice'

import { CellAction } from './components'

type TaskScheduleCellProps = {
  task: TaskWithRelations
}
export const TaskScheduleCell = ({ task }: TaskScheduleCellProps) => {
  const updateTask = useTaskUpdater()
  const { uid: currentUserId } = useAuthenticatedUser()
  const userSchedules = useAppSelector(selectSchedules)

  const isOwnTask = task.assigneeUserId === currentUserId
  const isRecurringInstance = task.type === 'RECURRING_INSTANCE'
  const isShortTask = task.duration === SHORT_TASK_DURATION

  const disabled =
    !isOwnTask ||
    !task.isAutoScheduled ||
    isRecurringInstance ||
    isShortTask ||
    task.archivedTime != null

  const currentSchedule = task.scheduleId
    ? userSchedules[task.scheduleId]
    : null
  const shouldShowName =
    isOwnTask && task.isAutoScheduled && currentSchedule != null && !isShortTask

  const onChange: ScheduleDropdownProps['onChange'] = (scheduleId) => {
    void updateTask(task, { scheduleId })
  }

  return (
    <ScheduleDropdown
      selectedScheduleId={task.scheduleId ?? DEFAULT_SCHEDULE_ID}
      onChange={onChange}
    >
      <CellAction disabled={disabled}>
        <span className='truncate'>
          {shouldShowName ? currentSchedule.title : ''}
        </span>
      </CellAction>
    </ScheduleDropdown>
  )
}
