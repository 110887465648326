import { useClosure } from '@motion/react-core/hooks'
import { groupInto } from '@motion/utils/array'

import { useMemo } from 'react'

import { useBulkOpsGroups } from '../contexts'

export type BulkItem<T extends { id: string }> = T[]

/**
 * Filter the data to only include the items that are present in all the workspaces
 * @param data
 * @param groupBy
 * @returns The filtered group of items
 */
export function useBulkFilteredData<
  T extends { id: string; workspaceId: string },
>(data: T[], groupBy: (item: T) => string): BulkItem<T>[] {
  const { workspaceGroups } = useBulkOpsGroups()
  const groupByClosure = useClosure(groupBy)

  const workspaceIds = useMemo(
    () => workspaceGroups.map((g) => g.key),
    [workspaceGroups]
  )

  return useMemo(() => {
    const groups = groupInto(data, groupByClosure)

    // Filter the groups to only include the groups that have all the workspaces
    const filteredGroups = groups.filter((g) => {
      if (g.items.length !== workspaceIds.length) return false
      return workspaceIds.every(
        (wId) => g.items.find((s) => s.workspaceId === wId) != null
      )
    })

    return filteredGroups.map((g) => {
      return g.items
    })
  }, [data, groupByClosure, workspaceIds])
}
