import { type ForwardedRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { type ButtonProps } from './ButtonDefaults'

import { LoadingSvg } from '../Icons/LoadingSvg'

/**
 * @deprecated Use `<Button>` from @motion/ui
 */
export const BaseButton = forwardRef(
  (
    props: ButtonProps & JSX.IntrinsicElements['button'],
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      className,
      children,
      childrenClassName = '',
      icon,
      iconAlign = 'left',
      iconClassName = '',
      justifyContent = 'justify-center',
      size = 'regular',
      loading = false,
      disabled,
      isPadded = true,
      keyboardShortCuts = [],
      shortCutContainerClasses,
      shortCutClasses,
      ...theirProps
    } = props
    const Icon = icon

    let sizeClasses = ''
    let iconClasses = ''
    let keyboardShortCutsContainerSizing = ''

    if (size === 'regular') {
      sizeClasses = 'text-sm'
      iconClasses = 'h-4 w-4 min-w-[16px]'
      keyboardShortCutsContainerSizing = 'w-4.5 h-4.5 text-xs'
    } else if (size === 'small') {
      sizeClasses = 'text-xs'
      iconClasses = 'h-3 w-3 min-w-[12px]'
      keyboardShortCutsContainerSizing = 'w-4 h-4 text-[10px]'
    }

    const keyboardShortCutsContainerClasses = twMerge(
      'flex items-center justify-center rounded-sm py-[1px] px-[2.5px]',
      keyboardShortCutsContainerSizing,
      shortCutContainerClasses ? shortCutContainerClasses : 'bg-dark-100/[0.15]'
    )
    const keyboardShortCutsClasses = twMerge(
      'font-base',
      shortCutClasses ? shortCutClasses : 'text-inherit'
    )

    if (isPadded) {
      if (children || keyboardShortCuts.length > 0) {
        // Children with optional icon
        if (size === 'regular') {
          sizeClasses += ' px-3 py-1.5'
        } else if (size === 'small') {
          sizeClasses += ' px-2 py-1'
        }
      } else if (size === 'regular') {
        sizeClasses = 'p-2'
      } else if (size === 'small') {
        sizeClasses = 'p-1'
      }
    }

    return (
      <button
        ref={ref}
        className={twMerge(
          'relative cursor-pointer rounded border font-normal enabled:focus:outline enabled:focus:outline-2 disabled:cursor-not-allowed disabled:opacity-40',
          sizeClasses,
          className
        )}
        disabled={disabled || loading}
        type='button'
        {...theirProps}
      >
        {loading && (
          <div className='absolute top-0 left-0 flex h-full w-full items-center justify-center'>
            <LoadingSvg />
          </div>
        )}
        <div
          className={twMerge(
            'flex items-center justify-center gap-2',
            justifyContent,
            loading && 'invisible'
          )}
        >
          {Icon && iconAlign === 'left' && (
            <Icon
              className={twMerge('text-inherit', iconClasses, iconClassName)}
            />
          )}
          {children && (
            <span
              className={twMerge(
                'font-semibold text-inherit',
                childrenClassName
              )}
            >
              {children}
            </span>
          )}
          {Icon && iconAlign === 'right' && (
            <Icon
              className={twMerge('text-inherit', iconClasses, iconClassName)}
            />
          )}
          {keyboardShortCuts.length > 0 && (
            <div
              className={twMerge(
                'ml-1 flex',
                size === 'regular' ? 'gap-1' : 'gap-0.5'
              )}
            >
              {keyboardShortCuts.map((shortCut, index) => (
                <div
                  className={keyboardShortCutsContainerClasses}
                  key={`${shortCut}-${index}`}
                >
                  <span className={keyboardShortCutsClasses}>{shortCut}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </button>
    )
  }
)
BaseButton.displayName = 'BaseButton'
