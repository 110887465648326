import { classed } from '@motion/theme'
import { UserAvatar } from '@motion/ui/base'
import { getNameAndSecondNameInitial } from '@motion/ui-logic'

import { NameAndEmailLabel } from './name-and-email-label'

type ContactLabelProps = {
  item: { email?: string; displayName?: string; profilePic?: string }
  size?: 'small' | 'xsmall'
  hideAvatar?: boolean
}

export const ContactLabel = (props: ContactLabelProps) => {
  const { size = 'small', item, hideAvatar = false } = props
  const { email, displayName, profilePic } = item
  const name =
    displayName && displayName !== email
      ? getNameAndSecondNameInitial(displayName).slice(0, 15)
      : email
  if (!name) return null
  return (
    <ContactLabelContainer size={size}>
      {!hideAvatar && (
        <UserAvatar
          name={name.toUpperCase()}
          size={size === 'small' ? 20 : 16}
          id={email}
          profileUrl={profilePic}
        />
      )}
      <NameAndEmailLabel name={name} email={email} />
    </ContactLabelContainer>
  )
}

const ContactLabelContainer = classed('div', {
  base: `truncate max-w-[260px] flex items-center text-field-text-default grow text-`,
  variants: {
    size: { small: `text-sm`, xsmall: `text-xs` },
  },
})
