import {
  getCacheEntryValue,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { getChunkIdsForTask, isMeetingTask } from '@motion/ui-logic/pm/task'

import { notifyManager, type QueryClient } from '@tanstack/react-query'

import { getTaskQueryFilters } from './task-query-filters'

import { applyOptimisticCalendarEventUpdates } from '../calendar-event/event-update'

export function applyOptimisticTaskDelete(
  client: QueryClient,
  taskId: string
): OptimisticUpdateValue {
  const cachedTask = getCacheEntryValue(client, 'tasks', taskId)

  const cacheUpdates: (
    | ReturnType<(typeof MotionCache)['delete']>
    | ReturnType<typeof applyOptimisticCalendarEventUpdates>
  )[] = [
    MotionCache.delete(
      client,
      getTaskQueryFilters(taskId),
      ['tasks', 'recurringTasks', 'scheduledEntities'],
      [taskId, ...getChunkIdsForTask(cachedTask)]
    ),
  ]

  // When deleting a "meeting task", we need to remove the relationship from the event
  if (isMeetingTask(cachedTask)) {
    cacheUpdates.push(
      applyOptimisticCalendarEventUpdates(client, cachedTask.meetingEventId, {
        meetingTaskId: undefined,
      })
    )
  }

  function rollback() {
    notifyManager.batch(() => {
      cacheUpdates.forEach((c) => c.rollback())
    })
  }

  return {
    withRollback<T>(p: Promise<T>) {
      return p.catch((ex) => {
        rollback()
        throw ex
      })
    },
    rollback,
  }
}
