import { logInDev, warnInDev } from '@motion/web-base/logging'
import { firebase } from '@motion/web-common/firebase'
import { featureFlags } from '@motion/web-common/flags'

import { signInWithCustomToken as fb_signInWithCustomToken } from 'firebase/auth'
import diff from 'microdiff'

import { getSyncUserID } from './utils'

import api from '../../chromeApi/chromeApiContentScript'

export const openFirebaseAuth = (emailCode: string | null) => {
  const isElectron =
    globalThis?.navigator?.userAgent?.toLowerCase()?.indexOf(' electron/') > -1
  const params = new URLSearchParams()
  if (isElectron) {
    params.append('desktop', 'true')
  } else {
    params.append('webapp', 'true')
  }

  if (emailCode) {
    params.append('emailCode', emailCode)
  }

  const url = `${__FRONTEND_HOST__}/firebase-auth?${params.toString()}`

  window.open(url, '_blank', 'height=800,width=600')
}

/**
 * @deprecated avoid reading from/writing to firestore settings. Use Postgres
 * settings and/or introduce new endpoints
 */
export const persistSettings = async (
  settings: Record<string, any>,
  pushToStorage = false,
  merge = false
) => {
  const syncUserID = getSyncUserID()
  if (!syncUserID) {
    warnInDev('syncUserID is empty')
    return
  }
  if (pushToStorage) {
    await api.storage.local.set(settings)
  }

  logInDev('persistSettings', settings)
  // @ts-expect-error - legacy
  const oldData = api.storage.local.get(Object.keys(settings))
  const diffResult = diff(oldData, settings)
  if (!diffResult.length) {
    logInDev('persistSettings - no changes, ignoring persist')
    return
  }

  logInDev('change', diffResult)
  await api.storage.local.set(settings)
  const ref = firebase.firestore().collection('settings').doc(syncUserID)

  if (merge) {
    await ref.set(settings, { merge: true })
  } else {
    await ref.update(settings)
  }
}

export const signOut = async () => {
  await firebase.auth().signOut()

  await api.storage.local.reset()

  featureFlags.clear()
}

export const signInWithCustomToken = async (token: string) => {
  await fb_signInWithCustomToken(firebase.auth(), token)
}
