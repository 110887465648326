import { getNextRank } from '@motion/shared/pm'

import { type ModalTriggerComponentProps, NewOptionModal } from '~/areas/modals'
import { useCreateLabel, useWorkspaceFns } from '~/global/hooks'

export type ConnectedNewLabelModalProps = {
  workspaceId: string
}

export function ConnectedNewLabelModal({
  workspaceId,
  close,
}: ModalTriggerComponentProps<'add-label'>) {
  const { createLabel } = useCreateLabel()
  const { getWorkspaceLabels } = useWorkspaceFns()

  const addLabel = async (name: string, color: string) => {
    const newLabel = await createLabel({
      name,
      color,
      workspaceId,
      sortPosition: getNextRank(getWorkspaceLabels(workspaceId).length),
    })

    if (newLabel) {
      close(newLabel)
    }
  }

  return (
    <NewOptionModal
      title='Add label'
      placeholder='Enter label name'
      onClose={close}
      onSave={addLabel}
    />
  )
}
