import { ConnectedSaveViewButton } from './save-view-button'
import { ConnectedSelectViewButton } from './select-view-button'

export const ConnectedViewSection = () => {
  return (
    <>
      <ConnectedSaveViewButton />
      <ConnectedSelectViewButton />
    </>
  )
}
