import { showToast } from '@motion/ui/base'
import {
  type ProjectDefinitionSchema,
  type UpdateProjectDefinitionRequestSchema,
} from '@motion/zod/client'

import { useUpdateProjectDefinition as useUpdateProjectDefinitionCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

export function useUpdateProjectDefinition() {
  const { mutateAsync: updateProjectDefinition } =
    useUpdateProjectDefinitionCall()

  return useCallback(
    async (
      projectDefinitionId: ProjectDefinitionSchema['id'],
      workspaceId: string,
      updates: UpdateProjectDefinitionRequestSchema
    ) => {
      const response = await updateProjectDefinition({
        id: projectDefinitionId,
        workspaceId,
        definition: updates.definition,
      })

      const savedProjectDefinition =
        response.models[response.meta.model][response.id]

      showToast('success', 'Project definition saved')

      return savedProjectDefinition
    },
    [updateProjectDefinition]
  )
}
