import { PuzzleSolid } from '@motion/icons'
import {
  Button,
  PopoverTrigger,
  SearchableList,
  SearchableListLoadingSpinner,
} from '@motion/ui/base'

import { NoTemplatesFound } from '~/areas/task-project/components'
import { useTemplatesByWorkspaceId } from '~/global/rpc'

import { useSelectTemplate, useTaskForm } from '../hooks'

export const TemplateDropdown = () => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => <TemplateDropdownContent close={close} />}
    >
      <Button sentiment='neutral' size='small' variant='muted'>
        <PuzzleSolid />
        Use template
      </Button>
    </PopoverTrigger>
  )
}

type TemplateDropdownContentProps = {
  close: () => void
}
const TemplateDropdownContent = ({ close }: TemplateDropdownContentProps) => {
  const { form } = useTaskForm()
  const selectTemplate = useSelectTemplate()
  const { watch } = form

  const workspaceId = watch('workspaceId')

  const { data: templates, isLoading } = useTemplatesByWorkspaceId({
    workspaceId,
  })

  if (isLoading) {
    return <SearchableListLoadingSpinner />
  }

  if (templates == null) {
    // TODO Render error?
    return null
  }

  if (templates.templateTasks.length === 0) {
    return <NoTemplatesFound />
  }

  return (
    <SearchableList
      searchable={false}
      items={templates.templateTasks}
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={() => false}
      onSelect={(item) => {
        close()
        selectTemplate(item)
      }}
      renderItem={(item) => (
        <div className='flex flex-row items-center gap-1.5 max-w-xs'>
          <PuzzleSolid className='w-4 h-4 text-semantic-neutral-icon-default shrink-0' />
          <div className='truncate'>{item.name}</div>
        </div>
      )}
    />
  )
}
