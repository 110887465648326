import { AssigneeDropdown } from '~/areas/project-management/components'
import { useTaskAssigneeData } from '~/areas/tasks/hooks'
import { ConnectedAssigneeBadge } from '~/global/components/badges'

import { RowButton } from './common'
import { type WithTaskProps } from './types'

export const AssigneeButton = (props: WithTaskProps) => {
  const { disabled, onChange } = useTaskAssigneeData(props.task)

  return (
    <AssigneeDropdown
      selectedUserId={props.task.assigneeUserId}
      workspaceId={props.task.workspaceId}
      onChange={onChange}
    >
      <RowButton disabled={disabled}>
        <ConnectedAssigneeBadge
          size='small'
          userId={props.task.assigneeUserId}
        />
      </RowButton>
    </AssigneeDropdown>
  )
}
