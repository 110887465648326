import { twMerge } from 'tailwind-merge'

type LabelTagProps = {
  text: string
  type?: 'default' | 'alert' | 'warning' | 'primary' | 'success'
  size?: 'regular' | 'small'
  className?: string
  muted?: boolean
  onClick?: () => void
  disabled?: boolean
  icon?: React.ReactNode
}

/**
 * @deprecated Use `Tag` from Motion UI
 */
export const LabelTag = ({
  text = '',
  type = 'default',
  size = 'regular',
  className = '',
  muted = false,
  onClick,
  disabled = false,
  icon,
}: LabelTagProps) => {
  const baseClasses =
    'flex items-center justify-center rounded-full font-semibold'
  const colorClasses = {
    default: twMerge(
      'dark:bg-dark-1200 dark:text-dark-100 text-light-100 bg-light-1100',
      muted &&
        'dark:bg-dark-300/10 bg-light-400 dark:text-dark-400 text-light-1100'
    ),
    primary: twMerge(
      'bg-primary-600 dark:text-dark-100 text-light-100',
      muted && 'bg-primary-300/[0.15] text-primary-300 dark:text-primary-300'
    ),
    success: twMerge(
      'bg-success-500 dark:text-dark- 100 text-light-100',
      muted && 'bg-success-300/[0.15] text-success-300 dark:text-success-300'
    ),
    warning: twMerge(
      'bg-warning-300 text-warning-700 dark:text-warning-700',
      muted && 'bg-warning-400/[0.15] text-warning-400 dark:text-warning-400'
    ),
    alert: twMerge(
      'bg-alert-500 dark:text-dark-100 text-light-100',
      muted && 'bg-alert-400/[0.15] text-alert-400 dark:text-alert-400'
    ),
  }

  const sizeClasses = {
    regular: 'py-0.5 px-2 text-xs',
    small: 'py-0 px-1.5 text-[11px]',
  }

  if (onClick) {
    return (
      <button
        className={twMerge(
          baseClasses,
          colorClasses[type],
          sizeClasses[size],
          className,
          'enabled:hover:opacity-80',
          'disabled:cursor-not-allowed disabled:opacity-40'
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && <span className='pr-1'>{icon}</span>}
        <span className={twMerge('text-center text-inherit')}>{text}</span>
      </button>
    )
  }

  return (
    <div
      className={twMerge(
        baseClasses,
        sizeClasses[size],
        colorClasses[type],
        className
      )}
    >
      {icon && <span className='pr-1'>{icon}</span>}
      <span className={twMerge('text-center text-inherit')}>{text}</span>
    </div>
  )
}
