import { formattedCurrencyAmount, templateStr } from '@motion/ui-logic'
import { type WorkspaceSummarySchema } from '@motion/zod/client'

export const discountPriceString = (
  price: number,
  discount: number,
  currency = 'USD',
  round = false
) => {
  const discountMultiplier = (100 - discount) / 100
  return formattedCurrencyAmount(price * discountMultiplier, currency, round)
}

export const formatAndFilterWorkspaceSummary = (
  workspaceSummary?: WorkspaceSummarySchema
): string[] => {
  if (!workspaceSummary) {
    return []
  }

  const countItems: { count: number; labels: Record<number, string> }[] = [
    {
      count: workspaceSummary.workspaceCount,
      labels: { 0: 'Workspaces', 1: 'workspace', 2: 'workspaces' },
    },
    {
      count: workspaceSummary.projectCount,
      labels: { 0: 'Projects', 1: 'project', 2: 'projects' },
    },
    {
      count: workspaceSummary.taskCount,
      labels: { 0: 'Tasks', 1: 'task', 2: 'tasks' },
    },
  ]

  return countItems.map((item) => {
    if (item.count === 0) {
      return item.labels[0]
    }

    return templateStr('{{count}} {{label}}', {
      count: item.count,
      label: item.count >= 2 ? item.labels[2] : item.labels[item.count],
    })
  })
}
