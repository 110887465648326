import { makeLog } from '@motion/web-base/logging'
import { bus } from '@motion/web-common/event-bus'
import { websocketsEventSubscriber } from '@motion/web-common/websockets'

let isRegistered = false
const log = makeLog('[RegisterWebsocketSubscribers]')

export const registerWebsocketSubscribers = () => {
  if (isRegistered) {
    return
  }

  isRegistered = true
  log('Registering calendar websocket events')

  const eventsToListen = [
    'calendar.updated',
    'calendarEntry.updated',
    'calendarEntry.created',
    'calendarEntry.deleted',
  ] as const

  const unsubs = eventsToListen.map((name) =>
    websocketsEventSubscriber.on(name, (args) => {
      bus.emit('legacy:calendar:websockets', {
        type: name,
        data: args,
      } as any)
    })
  )

  return () => {
    unsubs.map((unsub) => unsub())
    isRegistered = false
  }
}
