import { AlertModal } from '@motion/ui/base'

type SubmittedInfoModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const SubmittedInfoModal = ({
  isOpen,
  onClose,
}: SubmittedInfoModalProps) => {
  return (
    <AlertModal
      visible={isOpen}
      onClose={onClose}
      title='Thanks for submitting your info!'
      actions={[
        {
          label: 'Finish onboarding',
          onAction: onClose,
          sentiment: 'neutral',
          variant: 'outlined',
        },
      ]}
      blocking
    >
      <p className='text-semantic-neutral-text-subtle'>
        Our team may reach out to schedule a call. Please finish onboarding.
      </p>
    </AlertModal>
  )
}
