import { isMeetingTask } from '@motion/ui-logic/pm/task'

import { ContactsDropdown, PrettyAttendees } from '~/areas/event/components'
import { useTaskGuestsData } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'

import { CellAction, CellText } from './components'

type GuestsCellsProps = {
  task: TaskWithRelations
}

export function GuestsCells({ task }: GuestsCellsProps) {
  const { onChange, disabled } = useTaskGuestsData(task)

  const guests =
    isMeetingTask(task) && task.meetingEvent ? task.meetingEvent.attendees : []

  if (disabled) {
    return (
      <CellText>
        <span className='text-semantic-neutral-text-subtle'>-</span>
      </CellText>
    )
  }

  return (
    <ContactsDropdown
      selectedEmails={guests.map((g) => g.email)}
      variant='submit'
      onChange={onChange}
    >
      <CellAction disabled={disabled}>
        {guests.length === 0 ? (
          <span className='text-semantic-neutral-text-subtle'>None</span>
        ) : (
          <PrettyAttendees attendees={guests} size='normal' hideRsvpStatus />
        )}
      </CellAction>
    </ContactsDropdown>
  )
}
