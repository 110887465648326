import {
  useGetTutorialPayload,
  useGetUserTutorialCompletion,
} from '~/components/Settings/Pages/tutorials/rpc-hooks'
import { useMemo } from 'react'

export function useCompletedTutorialPercentage() {
  const { data: tutorialData } = useGetTutorialPayload()
  const { data: userCompletionData } = useGetUserTutorialCompletion()

  return useMemo(() => {
    const numLessons =
      tutorialData?.sections.reduce(
        (sum, section) => sum + section.lessons.length,
        0
      ) ?? 0
    const completedLessonIds = userCompletionData?.completedTutorialIds ?? []
    return Math.round((completedLessonIds.length / numLessons) * 100)
  }, [userCompletionData, tutorialData])
}
