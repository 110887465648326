import { useClickOutside } from '@motion/react-core/hooks'

export type AddItemCellRefOptions = {
  onClickOutside: () => void
}
export function useAddItemCellRef({ onClickOutside }: AddItemCellRefOptions) {
  const ref = useClickOutside<HTMLFormElement>((target) => {
    const row = ref.current?.closest('[role="row"],tr')

    if (
      !row?.contains(target) &&
      !target.closest('[data-inline-add-button]') &&
      // After opening a dropdown and clicking outside to close it, the target becomes the body because that floating UI element gets removed from the dom and the node is gone.
      // That's why we cannot use that as comparison.
      target !== document.body
    ) {
      onClickOutside()
    }
  })

  return ref
}
