import { Shortcut } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { useProject } from '~/global/hooks'

export interface ProjectPrevNextTooltipContent {
  id: string
  shortcut: string
  isNext?: boolean
}

export function ProjectPrevNextTooltipContent({
  id,
  shortcut,
  isNext = false,
}: ProjectPrevNextTooltipContent) {
  const project = useProject(id)

  if (project == null) return null

  return (
    <div className='flex flex-col gap-1 items-center'>
      <p>
        {templateStr('{{previousNext}} project: {{name}}', {
          previousNext: isNext ? 'Next' : 'Previous',
          name: project.name,
        })}
      </p>
      <Shortcut shortcut={shortcut} size='small' />
    </div>
  )
}
