import { StartDateSolid } from '@motion/icons'
import { sleep } from '@motion/utils/promise'

import { useProjectStartDateUpdater } from '~/areas/project/hooks'
import {
  StartDateDropdown,
  type StartDateDropdownProps,
} from '~/areas/project-management/components'
import { DateTriggerButton } from '~/areas/task-project/components'
import { useProject } from '~/global/hooks'
import { DateTime } from 'luxon'

type ProjectStartDateFieldProps = {
  projectId: string
  onChange?: StartDateDropdownProps['onChange']
}

export function ProjectStartDateField({
  projectId,
  onChange: onChangeFromProps,
}: ProjectStartDateFieldProps) {
  const updateProjectStartDate = useProjectStartDateUpdater()

  const project = useProject(projectId)
  const today = DateTime.now().toISODate()

  if (project == null) {
    throw new Error('Project is required')
  }

  const onChange: StartDateDropdownProps['onChange'] = async (
    startDate: string | null
  ) => {
    // Sleeping a bit to make sure the dropdown gets closed before prompting the modal to keep the focus within the modal
    await sleep(1)
    await updateProjectStartDate(project, startDate)
    onChangeFromProps?.(startDate)
  }

  return (
    <StartDateDropdown
      value={project.startDate}
      onChange={onChange}
      contextProps={{ projectId }}
      dropdownTarget='project'
    >
      <DateTriggerButton
        label='Start date'
        icon={<StartDateSolid />}
        onClear={
          project.startDate === today ? undefined : () => onChange(today)
        }
        date={project.startDate}
        placeholder='None'
        clearTooltipContent='Reset start date to today'
      />
    </StartDateDropdown>
  )
}
