import { type TaskSchema } from '@motion/rpc-types'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { formatDateTime, formatDateTimeRange } from '@motion/ui-logic'

import {
  ModalFieldButton,
  SidebarSection,
} from '~/areas/task-project/components'
import { useDebugMode } from '~/global/hooks/use-debug-mode'
import { useTaskByIdV2 } from '~/global/rpc/v2'

import { useTaskForm } from '../hooks'

export const TaskDebugInfo = () => {
  const shouldShowDebugInfo = useDebugMode()

  if (!shouldShowDebugInfo) return null

  return (
    <SidebarSection className='relative'>
      <TaskDebugInfoContent />
    </SidebarSection>
  )
}

const TaskDebugInfoContent = () => {
  const taskForm = useTaskForm()
  const { form } = taskForm
  const { watch } = form

  const taskId = watch('id')

  return (
    <DebugInfoBox>
      <TaskIdInfo taskId={taskId} />
      {taskId != null && <InstanceInfo taskId={taskId} />}
    </DebugInfoBox>
  )
}

const TaskIdInfo = ({ taskId }: { taskId?: string }) => {
  return (
    <Tooltip content='Click to copy'>
      <ModalFieldButton
        label='Task ID'
        disabled={taskId == null}
        onClick={() => {
          navigator.clipboard.writeText(taskId ?? '')
        }}
      >
        <span className='font-mono truncate'>{taskId}</span>
      </ModalFieldButton>
    </Tooltip>
  )
}

const InstanceInfo = ({ taskId }: { taskId: string }) => {
  const { data } = useTaskByIdV2({ id: taskId })

  if (data == null || data.type !== 'NORMAL') return null

  return (
    <>
      <ScheduledTimeInfo task={data} />
      <Tooltip content='If true, updates to the task definition will be reflected in this task.'>
        <ModalFieldButton label='Syncing'>
          {String(data.isSyncingWithDefinition)}
        </ModalFieldButton>
      </Tooltip>
      <ModalFieldButton label='Fixed time'>
        {String(data.isFixedTimeTask)}
      </ModalFieldButton>
      <ModalFieldButton label='Needs Reschedule'>
        {String(data.needsReschedule)}
      </ModalFieldButton>
      <ModalFieldButton label='Future Schedulable'>
        {String(data.isFutureSchedulable)}
      </ModalFieldButton>
      {data.completedTime != null && (
        <ModalFieldButton label='Completed time'>
          {formatDateTime(data.completedTime)}
        </ModalFieldButton>
      )}
      {data.taskDefinitionId != null && (
        <ModalFieldButton label='Definition ID'>
          {data.taskDefinitionId}
        </ModalFieldButton>
      )}
    </>
  )
}

const ScheduledTimeInfo = ({ task }: { task: TaskSchema }) => {
  const { scheduledStart, scheduledEnd } = task

  if (scheduledStart == null || scheduledEnd == null) return null

  return (
    <ModalFieldButton label='Scheduled'>
      {formatDateTimeRange(scheduledStart, scheduledEnd)}
    </ModalFieldButton>
  )
}

const DebugInfoBox = classed('div', {
  base: `
    border
    border-semantic-warning-border-default p-1 m-[-4px]
    text-semantic-neutral-text-default
    rounded
    text-xs
    flex flex-col gap-1
  `,
})
