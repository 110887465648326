import {
  StatusDropdown,
  type StatusDropdownProps,
} from '~/areas/project-management/components'
import { GhostStatusDescription } from '~/global/components/tooltips'

export function GhostTaskStatusDropdown({
  children,
  ...rest
}: StatusDropdownProps) {
  return (
    <StatusDropdown
      {...rest}
      iconVariant='isUnvisitedStage'
      renderContentTopSection={() => <GhostStatusDescription size='2xs' />}
    >
      {children}
    </StatusDropdown>
  )
}
