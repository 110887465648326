import {
  DuplicateSolid,
  PencilSolid,
  RefreshSolid,
  SaveAsSolid,
  SaveSolid,
  TrashSolid,
} from '@motion/icons'
import { ActionList, type ActionSection } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import { type VersionedViewV2 } from '@motion/zod/client'

import { useMemo } from 'react'

import { wrapWithAnalytics } from './wrap-with-analytics'

import { usePageData } from '../../../routes'
import {
  isDefaultView,
  useCopyViewLink,
  useDeleteView,
  useEffectiveView,
  useResetView,
  useSaveView,
} from '../../../views'
import { isViewDisabled } from '../utils'

type ViewActionListProps = {
  close: () => void
  view: VersionedViewV2
}

export const ViewActionList = ({ close, view }: ViewActionListProps) => {
  const { sections } = useViewActionItems(view)

  return <ActionList sections={sections} onActionAnyItem={close} />
}

const useViewActionItems = (
  view: VersionedViewV2
): { sections: ActionSection[] } => {
  const modalApi = useModalApi()
  const copyLink = useCopyViewLink()
  const info = useEffectiveView()
  const reset = useResetView()
  const save = useSaveView()
  const deleteView = useDeleteView()
  const data = usePageData()

  const disabled = isViewDisabled(view, data.page)

  const value = useMemo(() => {
    const canUpdate = !isDefaultView(view)

    return {
      sections: [
        {
          items: [
            canUpdate && {
              prefix: <PencilSolid />,
              content: 'Rename view',
              onAction: () =>
                wrapWithAnalytics('rename', modalApi.prompt)('save-view-v2', {
                  asNew: false,
                  view,
                } as unknown as ModalDefinitions['save-view-v2']),
            },
            !disabled && {
              prefix: <DuplicateSolid />,
              content: 'Copy link to view',
              onAction: () => wrapWithAnalytics('copy_link', copyLink)(view),
            },
          ],
        },
        info.state !== 'clean' && {
          items: [
            canUpdate && {
              prefix: <SaveSolid />,
              content: 'Save view',
              onAction: () =>
                wrapWithAnalytics('save', save)({ view: info.view }),
            },
            {
              prefix: <SaveAsSolid />,
              content: 'Save as new view',
              onAction: () =>
                wrapWithAnalytics('save_as_new', modalApi.prompt)(
                  'save-view-v2',
                  {
                    view: info.view,
                    asNew: true,
                  } as unknown as ModalDefinitions['save-view-v2']
                ),
            },
            {
              prefix: <RefreshSolid />,
              content: 'Reset view',
              onAction: () => wrapWithAnalytics('reset', reset)(),
            },
          ],
        },
        canUpdate && {
          items: [
            {
              prefix: <TrashSolid />,
              destructive: true,
              content: 'Delete view',
              onAction: () => wrapWithAnalytics('delete', deleteView)(view),
            },
          ],
        },
      ] as ActionSection[],
    }
  }, [
    copyLink,
    deleteView,
    disabled,
    info.state,
    info.view,
    modalApi.prompt,
    reset,
    save,
    view,
  ])

  return value
}
