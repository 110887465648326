import { type CustomFieldSchema } from '@motion/shared/custom-fields'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import {
  type MultiSelectDropdownProps,
  type SelectOption,
  useMultiSelectFieldSelectedValues,
} from '~/areas/custom-fields/components'
import { useCustomFieldById } from '~/areas/custom-fields/hooks'
import { Label, PrettyLabels } from '~/global/components/labels'
import { Fragment, type ReactNode } from 'react'

import { FieldIconContainer } from './field-icon-container'
import { Text } from './styled-text'

type Props = {
  selectedItemIds: MultiSelectDropdownProps['selectedItemIds']
  customFieldId: CustomFieldSchema['id']
  hideIcon?: boolean
  type?: 'multiSelect' | 'select'
  size?: 'xsmall' | 'small'
  disabled?: boolean
  usePrettyLabels?: boolean
}

export const SelectTriggerValues = ({
  selectedItemIds,
  customFieldId,
  hideIcon = false,
  type = 'multiSelect',
  size,
  disabled,
  usePrettyLabels = true,
}: Props) => {
  const workspaceField = useCustomFieldById(customFieldId, type)
  if (workspaceField == null) {
    return null
  }

  const {
    metadata: { options },
  } = workspaceField

  return (
    <InnerSelectTriggerValues
      selectedItemIds={selectedItemIds}
      options={options}
      hideIcon={hideIcon}
      type={type}
      size={size}
      disabled={disabled}
      usePrettyLabels={usePrettyLabels}
    />
  )
}

function InnerSelectTriggerValues({
  selectedItemIds,
  hideIcon,
  options,
  type,
  size,
  disabled,
  usePrettyLabels,
}: {
  selectedItemIds: Props['selectedItemIds']
  hideIcon: Props['hideIcon']
  type: 'multiSelect' | 'select'
  options: SelectOption[]
  size?: Props['size']
  disabled?: boolean
  usePrettyLabels?: boolean
}) {
  const selectedValues = useMultiSelectFieldSelectedValues(
    selectedItemIds,
    options
  )

  if (selectedValues.length === 0) {
    return (
      <FieldIconContainer size={size}>
        {!hideIcon && <CustomFieldBadge type={type} />}
        <Text size={size ?? 'small'} aria-disabled={disabled || undefined}>
          None
        </Text>
      </FieldIconContainer>
    )
  }

  return usePrettyLabels ? (
    <PrettyLabels values={selectedValues} size={size} />
  ) : (
    selectedValues.map<ReactNode>((value, index) => (
      <Fragment key={value.id}>
        {index > 0 && ' '}
        <Label value={value} size={size} />
      </Fragment>
    ))
  )
}
