import { type FieldOperationSchema } from '@motion/shared/custom-fields'
import { type CustomFieldFieldArrayValue } from '@motion/ui-logic'

export const operationsLabel: Record<FieldOperationSchema, string> = {
  add: 'Add',
  remove: 'Remove',
}

export const supportedCustomFields: CustomFieldFieldArrayValue['type'][] = [
  'person',
  'select',
  'multiPerson',
  'multiSelect',
  'date',
]
