import { parseCustomFieldInfoFromMaybeDelimitedKey } from '@motion/ui-logic'

import { UserLabel } from '~/global/components/labels'

import {
  type CustomFieldMultiPersonRow,
  type CustomFieldPersonRow,
} from '../../../types'
import { GroupHeaderWithFieldName } from '../../group-headers'

export function CustomFieldPersonGroupHeader({
  item,
}: {
  item: (CustomFieldPersonRow | CustomFieldMultiPersonRow) & {
    key: string
  }
}) {
  const res = parseCustomFieldInfoFromMaybeDelimitedKey(item.type)
  if (res == null) return null
  const { name } = res

  return (
    <GroupHeaderWithFieldName fieldName={name}>
      <UserLabel value={item.value} />
    </GroupHeaderWithFieldName>
  )
}
