import { useCurrentTeam } from '~/global/rpc/team'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

export function useIsTeamExpired() {
  const { data: team } = useCurrentTeam()
  return useMemo(() => {
    if (!team) {
      return false
    }
    if (
      !team?.teamSubscription ||
      (team.teamSubscription.subscription as any).status === 'canceled'
    ) {
      return true
    }
    return (
      (team.teamSubscription.subscription as any).ended_at &&
      DateTime.fromSeconds(
        (team.teamSubscription.subscription as any).ended_at
      ) < DateTime.now()
    )
  }, [team])
}
