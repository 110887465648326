import { FormModal } from '@motion/ui/base'
import { RadioButton } from '@motion/ui/forms'
import { type ProjectsV2ResolveTasks } from '@motion/zod/client'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useState } from 'react'

import { Container, LabelContainer, Question } from './styled'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'resolve-project': PromptCallbacks<TasksAction> & {
      type: 'complete' | 'cancel'
    }
  }
}

type TasksAction = ProjectsV2ResolveTasks

export const ResolveProjectModal = ({
  close,
  type,
}: ModalTriggerComponentProps<'resolve-project'>) => {
  const [value, setValue] = useState<TasksAction>('noop')

  return (
    <FormModal
      visible
      onClose={() => close()}
      submitAction={{
        onAction: () => close(value),
        text: 'Continue',
      }}
      cancelAction={{
        shortcut: 'esc',
      }}
      title={type === 'complete' ? 'Complete project' : 'Cancel project'}
    >
      <ResolveProjectModalBody value={value} setValue={setValue} />
    </FormModal>
  )
}

type ResolveProjectModalBodyProps = {
  value: TasksAction | null
  setValue: (tasksAction: TasksAction) => void
}

const ResolveProjectModalBody = ({
  value,
  setValue,
}: ResolveProjectModalBodyProps) => {
  return (
    <Container>
      <Question>
        What do you want to do for tasks and stages that are not completed or
        canceled?
      </Question>

      <RadioButton
        checked={value === 'complete'}
        onChange={() => setValue('complete')}
        align='start'
        renderLabel={() => (
          <LabelContainer>
            <span className='leading-3'>Complete tasks and stages</span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              This will complete any tasks that are not in the Completed or
              Canceled status
            </span>
          </LabelContainer>
        )}
      />

      <RadioButton
        checked={value === 'cancel'}
        onChange={() => setValue('cancel')}
        align='start'
        renderLabel={() => (
          <LabelContainer>
            <span className='leading-3'>Cancel tasks and stages</span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              This will cancel any tasks that are not in the Completed or
              Canceled status
            </span>
          </LabelContainer>
        )}
      />

      <RadioButton
        checked={value === 'backlog'}
        onChange={() => setValue('backlog')}
        align='start'
        renderLabel={() => (
          <LabelContainer>
            <span className='leading-3'>Backlog tasks and stages</span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              This will move any tasks that are not in the Completed or Canceled
              status into the Backlog (Todo if Backlog doesn’t exist) status and
              unschedule them.
            </span>
          </LabelContainer>
        )}
      />

      <RadioButton
        checked={value === 'noop'}
        onChange={() => setValue('noop')}
        align='start'
        renderLabel={() => (
          <LabelContainer>
            <span className='leading-3'>Do nothing</span>
            <span className='text-semantic-neutral-text-subtle text-xs'>
              This will do nothing to any tasks that are not in the Completed or
              Canceled status. You can always bulk edit them from the list view.
            </span>
          </LabelContainer>
        )}
      />
    </Container>
  )
}
