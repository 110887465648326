import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { groupInto } from '@motion/utils/array'
import { type StatusSchema } from '@motion/zod/client'

import { StatusLabel, type StatusLabelProps } from '~/global/components/labels'
import { AppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

export type BulkStatusLabelProps = Omit<StatusLabelProps, 'value'> & {
  value: StatusSchema[]
}

export function BulkStatusLabel({ value }: BulkStatusLabelProps) {
  const colorGroups = useMemo(() => groupInto(value, (s) => s.color), [value])

  if (value.length === 1 || colorGroups.length === 1) {
    return <StatusLabel value={value[0]} />
  }

  const blackStatus = {
    ...value[0],
    color: '#889097',
  }

  return <StatusLabel value={blackStatus} />
}

export type ConnectedBulkStatusLabelProps = Omit<StatusLabelProps, 'value'> & {
  ids: StatusSchema['id'][]
}

export function ConnectedBulkStatusLabel({
  ids,
}: ConnectedBulkStatusLabelProps) {
  const statuses = useSharedStateQuery(AppWorkspaceContext, (state) =>
    ids.map((id) => state.statuses.byId[id])
  )

  return <BulkStatusLabel value={statuses} />
}
