import {
  type CustomFieldSchema,
  type DateFieldSchema,
  type MultiPersonFieldSchema,
  type MultiSelectFieldSchema,
  type PersonFieldSchema,
  type SelectFieldSchema,
} from '@motion/shared/custom-fields'
import { byProperty, ordered } from '@motion/utils/array'
import {
  type NormalTaskSchema,
  type ProjectSchema,
  type RecurringTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { type LookupFn } from '~/global/cache'
import { DateTime } from 'luxon'

import { type NormalTaskWithRelations } from './types'

const proxyMap = new WeakMap<
  NormalTaskSchema['customFieldValues'],
  NormalTaskWithRelations['customFields']
>()

export function createCustomFieldProxy(
  task: TaskSchema | RecurringTaskSchema | ProjectSchema,
  lookup: LookupFn
) {
  if (task.type !== 'NORMAL' || task.customFieldValues == null) return {}

  const item = task.customFieldValues

  const proxy = proxyMap.get(item)
  if (proxy != null) {
    return proxy
  }

  const createdProxy = new Proxy(item, {
    has(target, prop) {
      if (prop === Symbol.toPrimitive) return true

      if (typeof prop !== 'string') {
        return Reflect.has(target, prop)
      }

      const all = lookup('customFields').filter(
        (x) => x.workspaceId === task.workspaceId
      )

      const parts = prop.split('/')
      const name = parts[parts.length - 1]

      return all.some((c) => c.id === prop || c.name === name)
    },
    get(target, prop, reciever) {
      if (typeof prop !== 'string') {
        return Reflect.get(target, prop, reciever)
      }

      const all = lookup('customFields')
      const byId = all.find((x) => x.id === prop)
      if (byId) {
        const localValue = Reflect.get(target, prop)
        const def = lookup('customFields', prop)
        if (def == null) return undefined

        return Object.freeze(formatField({ lookup }, def, localValue?.value))
      }

      const parts = prop.split('/')
      const name = parts[parts.length - 1]

      const field = lookup('customFields')?.find(
        (x) => x.workspaceId === task.workspaceId && x.name === name
      )
      if (field == null) return undefined

      const localValue = Reflect.get(target, field.id)
      return Object.freeze(formatField({ lookup }, field, localValue?.value))
    },
  }) as NormalTaskWithRelations['customFields']

  proxyMap.set(item, createdProxy)
  return createdProxy
}

type Ctx = { lookup: LookupFn }
function formatField(ctx: Ctx, def: CustomFieldSchema, value: unknown) {
  switch (def.type) {
    case 'person':
      return formatPerson(ctx, def, value as string | null)
    case 'multiPerson':
      return formatMultiPerson(ctx, def, value as string[] | null)
    case 'select':
      return formatSelect(ctx, def, value as string | null)
    case 'multiSelect':
      return formatMultiSelect(ctx, def, value as string[] | null)
    case 'date':
      return formatDate(ctx, def, value)
    default:
      return {
        value,
        uiValue: value,
        type: def.type,
        definition: def,
      }
  }
}

function formatSelect(ctx: Ctx, def: SelectFieldSchema, value: string | null) {
  const hydratedValue =
    value == null
      ? null
      : (def.metadata.options.find(
          (x) => x.id === value && x.deletedTime == null
        ) ?? null)

  return {
    value: hydratedValue?.id ?? null,
    uiValue: hydratedValue,
    type: def.type,
    definition: def,
  }
}

function formatMultiSelect(
  ctx: Ctx,
  def: MultiSelectFieldSchema,
  value: string[] | null
) {
  const hydratedValue =
    value == null
      ? []
      : def.metadata.options
          .filter((x) => value.includes(x.id) && x.deletedTime == null)
          .sort(byProperty('id', ordered(value as string[])))

  return {
    value: hydratedValue.map((x) => x.id),
    uiValue: hydratedValue,
    type: def.type,
    definition: def,
  }
}

function formatPerson(ctx: Ctx, def: PersonFieldSchema, value: string | null) {
  const hydratedValue = value == null ? undefined : ctx.lookup('users', value)

  return {
    value,
    uiValue: hydratedValue,
    type: def.type,
    definition: def,
  }
}

function formatMultiPerson(
  ctx: Ctx,
  def: MultiPersonFieldSchema,
  value: string[] | null
) {
  const hydratedValue =
    value == null
      ? []
      : ctx
          .lookup('users')
          .filter((u) => (value as string[]).includes(u.id))
          .sort(byProperty('id', ordered(value)))

  return {
    value,
    uiValue: hydratedValue,
    type: def.type,
    definition: def,
  }
}

function formatDate(ctx: Ctx, def: DateFieldSchema, value: unknown) {
  return {
    value,
    uiValue:
      value == null
        ? null
        : DateTime.fromISO(value as string, { zone: 'UTC', setZone: true }),
    type: def.type,
    definition: def,
  }
}
