import { type ScheduledEntitySchema } from '@motion/zod/client'

import { type DateTime } from 'luxon'

export type DateRow = {
  id: string
  type: 'date-row'
  date: DateTime
  isToday: boolean
}

export function isDateRow(x: ScheduledEntitySchema | DateRow): x is DateRow {
  return (x as DateRow).type === 'date-row'
}
