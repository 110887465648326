import {
  useMyUserSettings,
  useSavePostgresConferenceSettings,
} from '@motion/web-common/settings'

import { useCallback } from 'react'

import {
  type PersistedConferenceSettingsState,
  type PersistedConferenceSettingsStateUpdate,
} from '../types'

export const usePersistedConferenceSettingsStatePostgres = (opts: {
  read: boolean
  write: boolean
}): [
  PersistedConferenceSettingsState | undefined,
  (data: PersistedConferenceSettingsStateUpdate) => Promise<void>,
] => {
  const { mutateAsync: saveToPostgres } = useSavePostgresConferenceSettings()

  const { data } = useMyUserSettings(undefined, {
    enabled: opts.read,
    meta: { source: 'usePersistedConferenceSettingsStatePostgres' },
  })

  const update = useCallback(
    async (data: PersistedConferenceSettingsStateUpdate) => {
      if (!opts.write) return
      await saveToPostgres(data)
    },
    [opts.write, saveToPostgres]
  )

  return [data?.conference, update] as const
}
