import { type Contact, type EmailAccount } from '@motion/rpc/types'
import { Button, ScrollArea } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { formatDurationTime, getConferenceDataForType } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type CalendarSchema } from '@motion/zod/client'

import { Events } from '~/analyticsEvents'
import { useBookingConferenceOptions } from '~/components/Booking/components/template-form/hooks/use-booking-conference-options'
import { useBookingTemplate } from '~/components/Booking/components/template-form/hooks/use-booking-template'
import { Combobox } from '~/components/Common'
import { ContactsAutocomplete } from '~/components/contacts-autocomplete'
import {
  createManualLink,
  selectAvailabilityError,
  selectAvailabilityGuests,
  selectAvailabilityLinkLoading,
  selectAvailabilityLoading,
  selectAvailabilityText,
  selectBookingSettings,
  selectLinkDuration,
  setAvailabilityConflictCalendars,
  setAvailabilityError,
  setAvailabilityGuests,
  setLinkDuration,
  setRichAvailabilityMessage,
  updateRichAvailabilityMessageSetting,
} from '~/state/booking'
import {
  selectEmailAccounts,
  selectMainEmailAccount,
} from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { showPromptModal } from '~/state/modalsSlice'
import {
  selectAvailabilityTimezone,
  setAvailabilityTimezone,
} from '~/state/timezone-slice'
import { selectUser } from '~/state/userSlice'
import { linkDurationChoicesMin } from '~/storageConstants'
import { formatCalendarTitle } from '~/utils/calendarUtils'
import { getTzAbbr } from '~/utils/time'
import { memo, useCallback, useState } from 'react'

import { AvailabilityMessage } from './availability-message'

import {
  useAllCalendars,
  useEditableCalendars,
  useMainCalendar,
  useSelectTeammates,
} from '../../hooks'

const durationOptions = linkDurationChoicesMin.map((choice) => ({
  label: formatDurationTime(choice),
  value: choice,
}))

const calIdToAccount: Record<string, string> = {}

type BookingCreateLinkSidebarProps = {
  onLinkCreated: () => void
}

export const Sidebar = ({ onLinkCreated }: BookingCreateLinkSidebarProps) => {
  const dispatch = useAppDispatch()
  const modalApi = useModalApi()
  const { displayLinksText, displayText } = useAppSelector(
    selectAvailabilityText
  )
  const bookingSettings = useAppSelector(selectBookingSettings)
  const isAvailabilityMessageRichText =
    !!bookingSettings?.isAvailabilityMessageRichText

  const linkDuration = useAppSelector(selectLinkDuration)
  const availabilityError = useAppSelector(selectAvailabilityError)
  const availabilityLinkLoading = useAppSelector(selectAvailabilityLinkLoading)
  const availabilityLoading = useAppSelector(selectAvailabilityLoading)
  const availabilityGuests = useAppSelector(selectAvailabilityGuests)
  const availabilityTimezone = useAppSelector(selectAvailabilityTimezone)

  // new calendar schema
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const { calendars } = useAllCalendars()
  const editableCalendars = useEditableCalendars()
  const { mainCalendar } = useMainCalendar()
  const [hostCalendar, setHostCalendar] = useState<
    CalendarSchema | null | undefined
  >(mainCalendar)

  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const [_, handleSelectedTeammates] = useSelectTeammates()

  const [hostEmailAccount, setHostEmailAccount] = useState<
    EmailAccount | undefined
  >(mainEmailAccount)
  const user = useAppSelector(selectUser)

  const getPhoneNumber = async () => {
    const phoneNumber = await dispatch(
      showPromptModal({ type: 'phoneNumber' })
    ).unwrap()

    return phoneNumber as string
  }

  const getCustomLocation = async () => {
    const customLocation = await dispatch(
      showPromptModal({ type: 'customLocation' })
    ).unwrap()

    return customLocation as string
  }

  const {
    conferenceOptions,
    conferenceType,
    conferenceTypesLoaded,
    getConferenceType,
    onConferenceTypeChange,
    setConferenceType,
  } = useBookingConferenceOptions({
    calendars,
    emailAccounts,
    getCustomLocation,
    getPhoneNumber,
    hostCalendar,
    hostEmailAccount,
  })

  const { onHostCalendarChanged } = useBookingTemplate({
    calendars,
    conferenceType,
    conferenceTypesLoaded,
    emailAccounts,
    getConferenceType,
    hostCalendar,
    hostEmailAccount,
    setConferenceType,
    setHostCalendar,
    setHostEmailAccount,
    user,
  })

  const handleLinkDurationChange = useCallback(
    (time: number) => {
      dispatch(setLinkDuration(time))

      recordAnalyticsEvent(Events.CALENDAR_AVAILABILITIES_DURATION_CHANGE)
    },
    [dispatch]
  )

  const handleRichAvailabilityChange = useCallback(
    (value: boolean) => {
      dispatch(updateRichAvailabilityMessageSetting(value))
      dispatch(setRichAvailabilityMessage(value))

      recordAnalyticsEvent(Events.RICH_AVAILABILITIES_TOGGLE, {
        checked: value,
      })
    },
    [dispatch]
  )

  const handleTeamSelect = (
    values: string[],
    options: Contact[] | undefined
  ) => {
    const conflictCalendars = (options ?? [])
      .filter((option) => !!option)
      .map((option) => {
        if (option.email && option.account) {
          calIdToAccount[option.email] = option.account
        }

        return {
          calendarId: option.email,
          email:
            option.account ||
            (option.email ? calIdToAccount[option.email] : ''),
          inCalendarList: false,
          title: option.email,
        }
      })

    dispatch(setAvailabilityGuests(values))
    dispatch(setAvailabilityConflictCalendars(conflictCalendars))

    void handleSelectedTeammates(values, options)
  }

  const handleTimezoneSelect = async () => {
    modalApi.prompt('timezone-picker', {
      onTimezoneChange: (tz: string | null) => {
        if (tz === null) return

        dispatch(setAvailabilityTimezone(tz))
      },
    })
  }

  const handleCreateManualLink = (linkOnly: boolean) => {
    if (!hostEmailAccount || !hostCalendar) {
      return void dispatch(
        setAvailabilityError(
          'Missing a host calendar. Please select one from the "Meeting host" dropdown.'
        )
      )
    }

    dispatch(
      createManualLink({
        linkOnly,
        conferenceType,
        hostEmail: hostEmailAccount?.email,
        hostCalendarId: hostCalendar?.providerId,
        onSuccess: onLinkCreated,
      })
    )
  }

  return (
    <div className='flex flex-col h-full w-full bg-semantic-neutral-bg-default overflow-auto'>
      <ScrollArea>
        <div className='p-4 space-y-4'>
          <div className='space-y-2'>
            <p className='text-sm'>Duration</p>

            <Combobox
              autoComplete
              value={linkDuration}
              onChange={handleLinkDurationChange}
              options={durationOptions}
            />
          </div>

          <div className='space-y-2'>
            <p className='text-sm'>Add teammates to meeting</p>

            <ContactsAutocomplete
              teamValues={availabilityGuests}
              contactsSource='team'
              teamSelectHandler={handleTeamSelect}
              hideIcon
              placeholder='(Optional) Search email'
            />
          </div>

          <div className='space-y-2'>
            <p className='text-sm'>Conference link</p>

            <Combobox
              autoComplete
              value={conferenceType}
              onChange={onConferenceTypeChange}
              options={conferenceOptions.map((option) => {
                const data = getConferenceDataForType(option)

                return {
                  label: data.title,
                  labelIcon: data.link ? (
                    <img alt={data.title} src={data.link} role='presentation' />
                  ) : undefined,
                  value: option,
                }
              })}
            />
          </div>

          <div className='space-y-2'>
            <p className='text-sm'>Meeting host</p>

            <Combobox
              autoComplete
              value={hostCalendar?.id}
              onChange={onHostCalendarChanged}
              options={editableCalendars.map((c) => ({
                label: formatCalendarTitle(
                  c.title,
                  emailAccounts.find((e) => e.id === c.emailAccountId)?.email
                ),
                value: c.id,
              }))}
            />
          </div>

          <div className='space-y-2'>
            <div className='flex items-center justify-between'>
              <p className='text-sm'>Message</p>

              <Button
                sentiment='neutral'
                variant='outlined'
                size='small'
                onClick={handleTimezoneSelect}
              >
                {getTzAbbr(availabilityTimezone)}
              </Button>
            </div>

            <AvailabilityMessage
              richLinks={isAvailabilityMessageRichText}
              message={
                isAvailabilityMessageRichText ? displayLinksText : displayText
              }
            />
          </div>

          <div>
            <Checkbox
              checked={isAvailabilityMessageRichText}
              onChange={handleRichAvailabilityChange}
              label='Clickable time slots'
            />
          </div>
        </div>
      </ScrollArea>

      <div className='border-modal-border mt-auto flex w-full flex-col border-t px-4 py-3'>
        {availabilityError && (
          <p className='text-semantic-error-text-default mb-3'>
            {availabilityError}
          </p>
        )}

        <div className='flex items-center justify-between gap-3'>
          <Button
            sentiment='neutral'
            variant='outlined'
            loading={availabilityLinkLoading}
            onClick={() => void handleCreateManualLink(true)}
            fullWidth
          >
            Copy link
          </Button>

          <Button
            loading={availabilityLoading}
            onClick={() => void handleCreateManualLink(false)}
            fullWidth
          >
            Copy message
          </Button>
        </div>
      </div>
    </div>
  )
}

export const BookingCreateLinkSidebar = memo(Sidebar)
