import { getCacheEntryValue } from '@motion/rpc-cache'
import { Sentry } from '@motion/web-base/sentry'

import { useQueryClient } from '@tanstack/react-query'
import { SelectedWorkspaceIdsContext } from '~/global/contexts'
import { type ReactNode, useMemo } from 'react'

import { useBulkOpsState } from './bulk-ops'

type BulkSelectionItemsWorkspaceContextProps = {
  children: ReactNode
  name?: string
}

export const BulkSelectionItemsWorkspaceContext = ({
  name,
  children,
}: BulkSelectionItemsWorkspaceContextProps) => {
  const { selectedIds } = useBulkOpsState()
  const client = useQueryClient()

  /**
   * Grab the list of unique workspace ids based on the selected task
   */
  const workspaceIds = useMemo(() => {
    return Array.from(
      new Set(
        selectedIds
          .map((id) => {
            const task = getCacheEntryValue(client, 'tasks', id)
            if (!task) {
              Sentry.captureException(
                new Error('Cannot find task in cache', {
                  cause: {
                    taskId: id,
                  },
                })
              )
              return null
            }

            return task.workspaceId
          })
          .filter(Boolean)
      )
    )
  }, [client, selectedIds])

  return (
    <SelectedWorkspaceIdsContext
      name={name}
      selectedWorkspaceIds={workspaceIds}
    >
      {children}
    </SelectedWorkspaceIdsContext>
  )
}
