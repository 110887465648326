import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { BackendApiService } from './BackendApiService'

import { Events as AnalyticsEvents } from '../analyticsEvents'
import { type PMProjectType } from '../components/ProjectManagement/types'

/**
 * CRUD wrapper for Project Mangement Projects
 */
export class ProjectsService extends BackendApiService {
  static id = 'ProjectsService' as const

  async create(project: Partial<PMProjectType>): Promise<PMProjectType> {
    recordAnalyticsEvent(AnalyticsEvents.PROJECT_MANAGEMENT_CREATE_PROJECT, {
      project_type: 'regular',
    })
    // @ts-expect-error If the backend gets these values from the frontend, it will think the front is still using the old priority info
    delete project.priorityId
    // @ts-expect-error If the backend gets these values from the frontend, it will think the front is still using the old priority info
    delete project.priority
    return this.request('projects', 'POST', project)
  }

  async update(project: Partial<PMProjectType>): Promise<PMProjectType> {
    recordAnalyticsEvent(AnalyticsEvents.PROJECT_MANAGEMENT_UPDATE_PROJECT, {
      project_type: 'regular',
    })
    // @ts-expect-error If the backend gets these values from the frontend, it will think the front is still using the old priority info
    delete project.priorityId
    // @ts-expect-error If the backend gets these values from the frontend, it will think the front is still using the old priority info
    delete project.priority
    return this.request(`projects/${project.id}`, 'PATCH', project)
  }
}

export const instance = new ProjectsService()
