import { TextField } from '@motion/ui/forms'

import { selectUrlPrefix } from '~/state/booking'
import { useAppSelector } from '~/state/hooks'
import { useContext } from 'react'

import { BookingTemplateContext } from '../../../booking-template-context'
import { SectionRow } from '../../../components/section-row'

type BookingLinkRowProps = {
  editable?: boolean
  prefix?: string
  onPrefixChange?: (prefix: string) => void
  prefixError?: string
}

export const BookingLinkRow = ({
  editable = false,
  prefix = '',
  onPrefixChange,
  prefixError,
}: BookingLinkRowProps) => {
  const individualUrlPrefix = useAppSelector(selectUrlPrefix)

  const {
    template: {
      state: { linkSlug },
      setters: { setLinkSlug },
    },
  } = useContext(BookingTemplateContext)

  return (
    <SectionRow
      title='Link'
      subtitle={
        "Customize your booking link's URL." +
        (editable
          ? " Note: changing this template's link means that the previous link for this template will no longer work."
          : '')
      }
    >
      <div className='flex w-full items-center whitespace-nowrap'>
        <span>app.usemotion.com/meet/</span>

        {editable ? (
          <span className='mx-1'>
            <TextField
              value={prefix}
              onChange={(value) => {
                onPrefixChange?.(value)
              }}
            />
          </span>
        ) : (
          <span>{individualUrlPrefix ?? ''}</span>
        )}

        <span className='mr-1'>/</span>

        <TextField
          value={linkSlug}
          onChange={(value) => {
            setLinkSlug(value)
          }}
        />
      </div>

      {prefixError && (
        <p className='text-semantic-error-text-default font-semibold mt-2'>
          {prefixError}
        </p>
      )}
    </SectionRow>
  )
}
