import { showToast } from '@motion/ui/base'
import { getProjectStageFromDefinitionId } from '@motion/ui-logic/pm/project'
import {
  type NormalTaskSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { optimisticUpdateProjectStage } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks'
import { useReadProjectFn, useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

import { useHasUnfinishedBlockersFn } from '../helpers'

export const useTaskStageUpdater = () => {
  const queryClient = useQueryClient()
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const readProject = useReadProjectFn()
  const { getWorkspaceCanceledStatus } = useWorkspaceFns()
  const hasUnfinishedBlockersInStage = useHasUnfinishedBlockersFn()

  return useCallback(
    async (
      taskOrId: NormalTaskSchema | NormalTaskSchema['id'],
      stageDefinition: StageDefinitionSchema
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type !== 'NORMAL') {
        throw new Error('Cannot update a stage for a non NORMAL task')
      }

      const hasUnfinishedBlockers = await hasUnfinishedBlockersInStage(
        task,
        task.stageDefinitionId
      )

      if (hasUnfinishedBlockers) {
        showToast(
          'error',
          'This task cannot be moved to a different stage because it has blockers. Please remove the blockers and try again.'
        )
        return
      }

      const project =
        task.projectId != null ? await readProject(task.projectId) : null

      // Best-effort optimistic update stage
      if (project != null) {
        const projectStage = getProjectStageFromDefinitionId(
          project,
          stageDefinition.id
        )

        const isTaskCanceled =
          task.statusId === getWorkspaceCanceledStatus(task.workspaceId).id

        // If the task is not canceled, and the stage we're moving the task into is canceled, un-cancel it
        if (
          !isTaskCanceled &&
          projectStage != null &&
          projectStage.canceledTime != null
        ) {
          optimisticUpdateProjectStage(queryClient, project.id, {
            ...projectStage,
            canceledTime: null,
          })
        }
      }

      await updateTask(task, { stageDefinitionId: stageDefinition.id })
    },
    [
      getWorkspaceCanceledStatus,
      hasUnfinishedBlockersInStage,
      queryClient,
      readProject,
      readTask,
      updateTask,
    ]
  )
}
