import { getTaskParentId } from '@motion/ui-logic/pm/task'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

import { type TaskByIdApi, useReadTaskFn, useTaskByIdV2 } from '~/global/rpc/v2'
import { useCallback } from 'react'

export function useReadTopmostParentTask() {
  const readTask = useReadTaskFn()

  const readParent = useCallback(
    async (task: TaskSchema | RecurringTaskSchema | undefined) => {
      const parentTaskId = getTaskParentId(task)

      if (parentTaskId) {
        return readTask(parentTaskId)
      }

      return null
    },
    [readTask]
  )

  return useCallback(
    async (taskId: TaskSchema['id']) => {
      const task = await readTask(taskId)

      // First level of parent is either a chunk parent or a recurring task parent
      // If the task is a chunk, it will always be a chunk parent
      const parent = await readParent(task)

      // So a recurring chunk will have a first parent that is the chunk parent, then the parent of the that will be the recurring task
      const secondParent = parent != null ? await readParent(parent) : null

      return secondParent ?? parent
    },
    [readParent, readTask]
  )
}

export function useParentTask(
  task: TaskSchema | RecurringTaskSchema | null | undefined,
  opts?: TaskByIdApi['UseQueryOptions']
) {
  const parentId = getTaskParentId(task)

  const { data, isInitialLoading } = useTaskByIdV2(
    {
      id: parentId ?? '',
    },
    {
      enabled: !!parentId,
      ...opts,
    }
  )

  return { data, isLoading: isInitialLoading }
}

export function useParentChunkTask(
  task: TaskSchema | RecurringTaskSchema | null | undefined,
  opts?: TaskByIdApi['UseQueryOptions']
) {
  const parentId = task?.type === 'CHUNK' ? task.parentChunkTaskId : null

  const { data, isInitialLoading } = useTaskByIdV2(
    {
      id: parentId ?? '',
    },
    {
      enabled: !!parentId,
      ...opts,
    }
  )

  return { data, isLoading: isInitialLoading }
}
