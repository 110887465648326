import { wrapVariableInDelimiters } from '@motion/shared/flows'
import { createPlaceholderId } from '@motion/shared/identifiers'
import { type VariableDefinitionSchema } from '@motion/zod/client'

import { type NodeViewProps } from '@tiptap/core'
import {
  type FlowVariableProps,
  FlowVariableSearchableList,
  NodeVariableLabelView,
  type VariableLocation,
} from '~/areas/flows/components'
import { createNewTextVariable } from '~/areas/flows/flow-template-modal/utils'

import { useTaskTextVariableUpdater } from './use-task-text-variable-updater'

type ConnectedNodeVariableLabelAttrs = {
  id: string
  taskId: string
  hideAddNew?: boolean
  size?: 'xsmall' | 'small'
}

export const ConnectedNodeVariableLabel = ({
  node: { attrs },
  editor,
  type,
}: NodeViewProps & { type: VariableLocation }) => {
  const { id, taskId, size } = attrs as ConnectedNodeVariableLabelAttrs

  const { stages, textVariables } = editor.storage.flowVariable as Omit<
    FlowVariableProps,
    'taskId'
  >

  const updater = useTaskTextVariableUpdater(type)

  if (id == null || taskId == null || stages == null || textVariables == null) {
    return null
  }

  const idWithPlaceholder = createPlaceholderId(taskId)
  const stageIndex = stages.findIndex((s) =>
    s.tasks.some((t) => t.id === taskId || t.id === idWithPlaceholder)
  )
  if (stageIndex === -1) {
    return null
  }

  const taskIndex = stages[stageIndex].tasks.findIndex(
    (t) => t.id === taskId || t.id === idWithPlaceholder
  )
  if (taskIndex === -1) {
    return null
  }

  const variable =
    textVariables.find((v) => v.key === id) ?? createNewTextVariable('Unknown')

  const handleReplace = (item: VariableDefinitionSchema) => {
    const wrappedKey = wrapVariableInDelimiters(variable.key, type)
    const replacementKey = wrapVariableInDelimiters(item.key, type)
    updater({
      wrappedKey,
      replacementKey,
      operation: 'replace',
      stageIndex,
      taskIndex,
    })

    // Run updater twice for description;
    // descriptions look like <span data-flow-variable-id="flow_key_56" ...>{{flow_key_56}}</span>
    // so we need to update both key references
    if (type === 'description') {
      const wrappedKey = wrapVariableInDelimiters(variable.key, 'name')
      const replacementKey = wrapVariableInDelimiters(item.key, 'name')
      updater({
        wrappedKey,
        replacementKey,
        operation: 'replace',
        stageIndex,
        taskIndex,
      })
    }
  }

  return (
    <NodeVariableLabelView
      variable={variable}
      size={size}
      renderPopover={({ close }) => (
        <FlowVariableSearchableList
          items={textVariables}
          close={close}
          onChange={handleReplace}
          selectedItem={variable}
          hideAddNew
        />
      )}
    />
  )
}
