import { RadioButton } from '@motion/ui/forms'
import { keys } from '@motion/utils/object'

import { type BulkDuplicateTaskType } from '~/areas/tasks/hooks'
import { useController, useFormContext } from 'react-hook-form'

const DUPLICATION_TYPES = {
  number: 'Duplicate by choosing the number of duplicates',
  assignees: 'Duplicate by selecting the assignees',
} as const

export const TypeSelectionStepForm = () => {
  const form = useFormContext()

  const { field: typeField } = useController({
    name: 'type',
    control: form.control,
  })

  const handleTypeChange = (value: BulkDuplicateTaskType) => () =>
    typeField.onChange(value)

  return (
    <div className='flex flex-col gap-3'>
      {keys(DUPLICATION_TYPES).map((key) => (
        <RadioButton
          key={key}
          label={DUPLICATION_TYPES[key]}
          checked={typeField.value === key}
          onChange={handleTypeChange(key)}
        />
      ))}
    </div>
  )
}
