import { Button, ButtonGroup } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '~/areas/modals'

import { Footer } from './styled'

import { ModeToLabels } from '../constants'
import { useScheduleAssistantCalendarContext } from '../hooks'

type CalendarShellFooterProps = Pick<
  ModalTriggerComponentProps<'schedule-assistant'>,
  'close'
>

export function CalendarShellFooter({ close }: CalendarShellFooterProps) {
  const [context] = useScheduleAssistantCalendarContext()

  return (
    <Footer>
      <ButtonGroup>
        <Button
          onClick={() => close()}
          sentiment='neutral'
          variant='muted'
          type='reset'
        >
          Cancel
        </Button>
        <Button
          type='submit'
          onClick={() =>
            context.selectedCalendarEvent != null &&
            close({
              ...context.selectedCalendarEvent,
              attendees:
                context.mode === 'create-new' ? context.attendees : undefined,
            })
          }
          disabled={context.selectedCalendarEvent == null}
        >
          {ModeToLabels[context.mode].submitLabel}
        </Button>
      </ButtonGroup>
    </Footer>
  )
}
