import { classed } from '@motion/theme'

export const FieldIconContainer = classed('div', {
  base: `
    inline-flex items-center gap-2
    [&_[data-icon]]:text-semantic-neutral-icon-subtle
  `,
  variants: {
    size: {
      xsmall: `[&_[data-icon]]:w-3 [&_[data-icon]]:h-3`,
      small: `[&_[data-icon]]:w-3 [&_[data-icon]]:h-3`,
      normal: `[&_[data-icon]]:w-4 [&_[data-icon]]:h-4`,
    },
  },
  defaultVariants: {
    size: 'small',
  },
})
