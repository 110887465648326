import { useParams } from 'react-router-dom'

import { type PMPageUrlParams } from '../../routes/types'

/**
 * Depending on the current page's param, this function will return the label for the target type.
 * @returns 'project' | 'folder' | 'workspace' | null
 */
export function useTargetTypeLabel() {
  const { folderId, workspaceId, projectId } = useParams<PMPageUrlParams>()

  if (projectId) return 'project'
  if (folderId) return 'folder'
  if (workspaceId) return 'workspace'

  return null
}
