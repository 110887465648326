import { HttpError } from '@motion/rpc'
import { FullscreenModal } from '@motion/ui/base'

import { AttachmentErrorContent } from './components/attachment-error-content'

const ERROR_NOT_FOUND_TITLE = 'This attachment is unavailable'
const ERROR_NOT_FOUND_MESSAGE =
  "You might not have access to view this attachment or it doesn't exist anymore"

export type AttachmentErrorModalProps = {
  error: any
  closeModal: () => void
}

export function AttachmentErrorModal({
  error,
  closeModal,
}: AttachmentErrorModalProps) {
  const errorIsNotFound = error instanceof HttpError && error?.status === 404

  return (
    <FullscreenModal
      headerTitle={errorIsNotFound ? 'Attachment not found' : undefined}
      onClose={closeModal}
      visible
    >
      <div className='flex items-center justify-center h-full w-full'>
        <AttachmentErrorContent
          title={errorIsNotFound ? ERROR_NOT_FOUND_TITLE : undefined}
          message={errorIsNotFound ? ERROR_NOT_FOUND_MESSAGE : undefined}
          closeModal={closeModal}
        />
      </div>
    </FullscreenModal>
  )
}
