import { QuestionMarkCircleSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'

import { MotionLink } from '~/global/components'
import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface ModalErrorStateProps {
  close: () => void
  children: ReactNode
  className?: string
}

export function ModalErrorState(props: ModalErrorStateProps) {
  const { close, children, className } = props

  return (
    <div
      className={twMerge(
        'grid w-full h-full place-items-center text-semantic-neutral-text-default',
        className
      )}
    >
      <div className='flex flex-col gap-4 text-center min-w-[50%]'>
        <div className='m-auto'>
          <QuestionMarkCircleSolid className='w-10 h-10' />
        </div>
        <p className='font-semibold text-lg'>{children}</p>
        <p className='text-semantic-neutral-text-subtle'>
          It may have been deleted.
        </p>
        <div className='m-auto'>
          <Button variant='outlined' sentiment='neutral' onClick={close}>
            Close
          </Button>
        </div>
        <hr className='border-semantic-neutral-border-active' />
        <MotionLink url='https://help.usemotion.com' external>
          Contact Support
        </MotionLink>
      </div>
    </div>
  )
}
