import { NonVisitedStatusSolid } from '@motion/icons'
import { classed } from '@motion/theme'

export type GhostStatusDescriptionProps = {
  size?: 'sm' | '2xs'
}

export function GhostStatusDescription({
  size = 'sm',
}: GhostStatusDescriptionProps) {
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex gap-2'>
        <NonVisitedStatusSolid className='w-3 h-3 shrink-0 my-1' />

        <Text size={size}>
          This task is in a pending state because it’s in a stage that hasn’t
          started yet.
        </Text>
      </div>

      <Text size={size} className='italic text-semantic-neutral-text-subtle'>
        {`You can hide these tasks from your calendar. Calendar -> Display Options -> Hide projected tasks on calendar. Note: Tasks will still be scheduled, so hiding them may result in perceived gaps in the calendar.`}
      </Text>
    </div>
  )
}

const Text = classed('span', {
  base: 'break-words max-w-xs text-left',
  variants: {
    size: {
      '2xs': 'text-2xs leading-4',
      sm: 'text-sm leading-5',
    },
  },
})
