import { useClosure } from '@motion/react-core/hooks'

import { useCallback, useContext, useEffect } from 'react'

import { DesktopIpcContext } from './context'
import type { IpcCallback } from './types'

import {
  type ReceivableChannels,
  type SendableChannels,
} from '../../state/desktopSlice'

export const useOnDesktopEvent = (key: ReceivableChannels, cb: IpcCallback) => {
  const ctx = useContext(DesktopIpcContext)

  const callback = useClosure(cb)

  useEffect(() => {
    return ctx.on(key, callback)
  }, [key, callback, ctx])
}

export const useSendToDesktop = () => {
  const ctx = useContext(DesktopIpcContext)
  return useCallback(
    (message: SendableChannels, ...args: unknown[]) => {
      ctx.send(message, ...args)
    },
    [ctx]
  )
}
