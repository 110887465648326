import { type StripePaymentMethodSchema } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { BillingSection, BillingSectionTitle } from './billing-section'

export type PaymentMethodSectionProps = {
  paymentMethod?: StripePaymentMethodSchema
  teamId?: string
}

export const PaymentMethodSection = ({
  paymentMethod,
  teamId,
}: PaymentMethodSectionProps) => {
  const modalApi = useModalApi()
  const shouldUseNoCcTrial = useHasTreatment('no-cc-combined-trial')

  return (
    <BillingSection data-testid='billing-payment-method-section'>
      <div className='flex flex-row items-center p-6 justify-between'>
        <div className='flex flex-col gap-2'>
          <BillingSectionTitle>Payment method</BillingSectionTitle>
          {paymentMethod?.card ? (
            <h1 className='text-2xl text-semantic-neutral-text-default font-semibold'>
              {templateStr('{{brand}} ending in {{last4}}', {
                brand: (
                  <span className='capitalize'>{paymentMethod.card.brand}</span>
                ),
                last4: paymentMethod.card.last4,
              })}
            </h1>
          ) : (
            <h2 className='text-sm text-semantic-neutral-text-default font-medium'>
              No payment method on file
            </h2>
          )}
        </div>
        <Button
          sentiment='neutral'
          onClick={() => {
            if (shouldUseNoCcTrial) {
              modalApi.open('choose-plan-modal')
            } else {
              modalApi.open('change-payment-method', {
                hasPaymentMethod: !!paymentMethod,
                activeTeamId: teamId,
              })
            }
          }}
        >
          {paymentMethod ? 'Change payment method' : 'Add payment method'}
        </Button>
      </div>
    </BillingSection>
  )
}
