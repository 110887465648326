import { MagicWandSolid } from '@motion/icons'
import { GradientButton } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useParams } from 'react-router-dom'

export function FlowsAICard() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const modalApi = useModalApi()

  const openModal = () => {
    modalApi.open('flows-ai-modal', {
      workspaceId,
    })
  }

  return (
    <div className='py-5 px-4 flex gap-2 items-center justify-between border border-semantic-purple-border-default rounded-lg shadow-[0_0_30px_-20px_rgba(0,0,0,0.3)] shadow-semantic-pink-border-active'>
      <div className='flex flex-col gap-2'>
        <h4 className='text-sm font-semibold text-semantic-neutral-text-default'>
          Upload your SOP or Project Guidelines
        </h4>
        <p className='text-sm text-semantic-neutral-text-default'>
          Motion will automatically create the stages and tasks required for the
          project.
        </p>
      </div>

      <GradientButton sentiment='purple' onClick={openModal}>
        <MagicWandSolid />
        Create with AI
      </GradientButton>
    </div>
  )
}
