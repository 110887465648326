import { usePlannerProps } from '../context'

export const useScreenValues = () => {
  const [plannerProps] = usePlannerProps()

  const screenSize = plannerProps.containerRect?.width || 1000

  // Place the current date 1/3 of the way from the left
  const positionOffset = screenSize / 3

  return {
    screenSize,
    positionOffset,
  }
}
