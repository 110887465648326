import { Sentry } from '@motion/web-base/sentry'
import { type ProjectSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { END_PROJECT_BUFFER } from './constants'
import { getInitialStageLocation } from './get-initial-stage-location'
import { getStageLocationProjectResize } from './get-stage-location-project-resize'
import { getStageLocationStageResize } from './get-stage-location-stage-resize'

import { type Side } from '../../../resize-handle'
import { type ResizeStageDetails, type StageWithDates } from '../../types'
import { isFirstStage } from '../is-first-stage'
import { isLastStage } from '../is-last-stage'

type GetStageLocationArgs = {
  dayPx: number
  stageWithDates: StageWithDates
  currentSide?: Side
  project: ProjectSchema
  projectDeltaWidth?: number
  stageDetails?: ResizeStageDetails
  shiftModifier?: boolean
}

export function getStageLocation({
  dayPx,
  stageWithDates,
  project,
  currentSide,
  projectDeltaWidth = 0,
  stageDetails,
  shiftModifier = false,
}: GetStageLocationArgs) {
  const { stage, start } = stageWithDates

  let {
    maxStageWidth,
    pixelsFromProjectStart,
    completedDuration,
    initialProjectWidth,
  } = getInitialStageLocation({
    dayPx,
    stageWithDates,
    project,
  })

  let maxStageLeft = initialProjectWidth
  let stageLeft = pixelsFromProjectStart
  let projectResizeResult

  if (projectDeltaWidth !== 0) {
    try {
      projectResizeResult = getStageLocationProjectResize({
        dayPx,
        currentSide,
        stageWithDates,
        projectDeltaWidth,
        initialProjectWidth,
        stageLeft,
        maxStageLeft,
        maxStageWidth,
        project,
        shiftModifier,
      })
    } catch (err) {
      Sentry.captureException('gantt resizing stage adjuster error', {
        tags: {
          projectId: project.id,
          stageId: stage.id,
        },
      })
      projectResizeResult = {
        maxStageWidth,
        maxStageLeft,
        stageLeft,
      }
    }

    stageLeft = projectResizeResult.stageLeft
    maxStageWidth = projectResizeResult.maxStageWidth
    maxStageLeft = projectResizeResult.maxStageLeft
  } else if (stageDetails?.delta != null && stageDetails.delta !== 0) {
    const stageResizeResult = getStageLocationStageResize({
      stage,
      stageLeft,
      maxStageLeft: initialProjectWidth,
      maxStageWidth,
      project,
      stageDetails,
      shiftModifier,
    })

    stageLeft = stageResizeResult.stageLeft
    maxStageWidth = stageResizeResult.maxStageWidth
    maxStageLeft = stageResizeResult.maxStageLeft
  }

  // If the stage left is greater than the maxStageLeft, we should adjust the stage left
  if (stageLeft >= maxStageLeft) {
    stageLeft = maxStageLeft - END_PROJECT_BUFFER
  }

  // If the maxStageWidth is less than 0, we should set it to 0
  if (maxStageWidth < 0) {
    maxStageWidth = 0
  }

  // left side stageLeft date calculation wasn't working so return adjusted dates to use instead
  // Get number of days the stage has been shifted
  let adjustedStartDate =
    projectResizeResult && 'adjustedStartDate' in projectResizeResult
      ? DateTime.fromISO(projectResizeResult.adjustedStartDate as string)
      : DateTime.fromISO(start).plus({
          days: (stageLeft - pixelsFromProjectStart) / dayPx,
        })

  let adjustedEndDate = adjustedStartDate.plus({
    days: maxStageWidth / dayPx,
  })

  const firstStage = isFirstStage(stage.id, project.stages)
  const capLastStage =
    currentSide === 'left' && isLastStage(stage.id, project.stages)
  if (firstStage) {
    stageLeft = 0
  }

  if (capLastStage && project.dueDate && projectDeltaWidth !== 0) {
    adjustedEndDate = DateTime.fromISO(project.dueDate)
  }

  return {
    stageLeft: Math.max(stageLeft, 0),
    stageProgressWidth: (completedDuration / 100) * maxStageWidth,
    maxStageWidth,
    adjustedStartDate,
    adjustedEndDate,
  }
}
