import { CalendarPalette } from '@motion/ui/calendar'
import { type ColorId } from '@motion/ui-logic'

export interface CalendarFieldProps {
  email: string
  colorId: ColorId
}

export const CalendarField = (props: CalendarFieldProps) => {
  const { email, colorId } = props

  return (
    <div className='text-[11px] leading-3 flex items-center gap-1'>
      <CalendarPalette colorId={colorId}>
        <div className='inline-block w-1.5 h-1.5 rounded-full bg-calendar-palette-highlight-default' />
      </CalendarPalette>
      {email}
    </div>
  )
}
