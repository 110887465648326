import { useForceUpdate } from '@motion/react-core/hooks'
import {
  isEnabledStage,
  isValidStageDeadline,
} from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'

import { DateTime } from 'luxon'
import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router'

import { SetupProjectFormNavigationContext } from './setup-project-form-navigation-context'

import { useActiveTab } from '../use-active-tab'
import { useNavigateTabs } from '../use-navigate-tabs'
import { useSetupProjectForm } from '../use-setup-project-form'
import { useSetupProjectModalSubmitHandler } from '../use-setup-project-modal-submit-handler'

export const SetupProjectFormNavigationProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const value = useSetupProjectFormNavigation()

  return (
    <SetupProjectFormNavigationContext.Provider value={value}>
      {children}
    </SetupProjectFormNavigationContext.Provider>
  )
}

export type SetupProjectFormNavigationContextType = ReturnType<
  typeof useSetupProjectFormNavigation
>

const useSetupProjectFormNavigation = () => {
  const {
    form: {
      watch,
      formState: { isSubmitting },
    },
  } = useSetupProjectForm()
  const onSubmit = useSetupProjectModalSubmitHandler()

  const activeTab = useActiveTab()
  const navigate = useNavigate()
  const { prevUrl, nextUrl } = useNavigateTabs()

  const isContinuing = useRef(false)

  const name = watch('name')
  const textReplacements = watch('textReplacements')
  const stageDueDates = watch('stageDueDates')
  const startDate = watch('startDate')
  const dueDate = watch('dueDate')

  let disabledMessage = null

  const isNameTabDisabled =
    activeTab === 'name' && (!name || name.trim().length === 0)

  const isTextVariablesTabDisabled =
    activeTab === 'textVariables' &&
    !textReplacements.every((textReplacement) => textReplacement.value)
  const isDatesTabDisabled =
    activeTab === 'dates' &&
    !stageDueDates.filter(isEnabledStage).every((s) =>
      isValidStageDeadline(DateTime.fromISO(s.dueDate), s.stageDefinitionId, {
        startDate,
        dueDate,
        stages: stageDueDates.map((s) => ({
          id: s.stageDefinitionId,
          dueDate: s.dueDate,
        })),
      })
    )

  if (isDatesTabDisabled) {
    disabledMessage = 'Fix the invalid stage deadlines to continue'
  }

  const isDisabled =
    isSubmitting ||
    isNameTabDisabled ||
    isTextVariablesTabDisabled ||
    isDatesTabDisabled

  const forceUpdate = useForceUpdate()

  const goForward = useCallback(
    async (e?: KeyboardEvent) => {
      if (isDisabled) return
      isContinuing.current = true
      // Go to next click, allow isContinuing to be set
      forceUpdate()
      await sleep(1)

      if (nextUrl != null) {
        navigate(nextUrl)
      } else {
        onSubmit(e as any)
      }

      isContinuing.current = false
    },
    [isDisabled, forceUpdate, nextUrl, navigate, onSubmit]
  )

  const goBack = useCallback(async () => {
    if (prevUrl != null) {
      isContinuing.current = true
      // Go to next click, allow isContinuing to be set
      forceUpdate()
      await sleep(1)

      navigate(prevUrl)

      isContinuing.current = false
    }
  }, [forceUpdate, navigate, prevUrl])

  return {
    isDisabled,
    disabledMessage,
    goForward,
    goBack: prevUrl != null ? goBack : null,
    isContinuing: isContinuing.current,
  }
}
