import { PlusSolid } from '@motion/icons'
import { isCompletedStatus } from '@motion/shared/common'
import { Button } from '@motion/ui/base'

import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'

import { type LookupArgs } from './lookup-header'
import { ToggleTasksButton } from './toggle-tasks-button'

import { useSidebarTaskContext } from '../context'

export function StatusHeader({ groupId, toggle, row }: LookupArgs) {
  const status = useWorkspaceStatusById(groupId)
  const { enableInlineAdd: ctxEnableInlineAdd } = useSidebarTaskContext()

  const enableInlineAdd = ctxEnableInlineAdd && !isCompletedStatus(status)

  return (
    <>
      <div className='flex flex-row items-center gap-1 flex-1'>
        <div className='flex flex-row items-center gap-2 overflow-hidden'>
          <StatusBadge value={status} hideTooltip />
          <h3
            key={groupId}
            className='font-bold text-sm text-semantic-neutral-text-default'
          >
            {status.name}
          </h3>
        </div>

        {row?.subRows.length ? <ToggleTasksButton row={row} /> : null}
      </div>

      {enableInlineAdd && (
        <Button
          iconOnly
          size='xsmall'
          variant='muted'
          sentiment='neutral'
          onClick={() => toggle({ statusId: status.id })}
        >
          <PlusSolid />
        </Button>
      )}
    </>
  )
}
