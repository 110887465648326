import { showToast } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { type BulkTaskOperations } from '../types'

export async function bulkTaskOperations(
  currentUserId: string,
  taskOperations: BulkTaskOperations[],
  pluralize: (
    count: number,
    singular: string,
    plural: string
  ) => ReturnType<typeof templateStr>
) {
  // NOTE: While it is possible that the individual temporal job completes in an interval far enough that the results are picked up by different batches,
  // and hence the total count of failures might not be accurate, we are not handling that case here. We are assuming that the temporal jobs are fast enough
  // such that all WS events are handled in the same batch.
  // (https://usemotion.slack.com/archives/C07FXKYT0D9/p1726177831182469?thread_ts=1726097279.736809&cid=C07FXKYT0D9)
  const failureCount = taskOperations
    .filter((taskOperation) => taskOperation.data.requesterId === currentUserId)
    .reduce((prev, acc) => prev + acc.data.results.failures, 0)

  if (failureCount > 0) {
    showToast(
      'error',
      templateStr(
        'Oops something went wrong! {{count}} {{type}} {{linkingVerb}} updated. Please try again.',
        {
          type: pluralize(failureCount, 'task', 'tasks'),
          linkingVerb: pluralize(failureCount, "wasn't", "weren't"),
          count: failureCount,
        }
      )
    )
  }
}
