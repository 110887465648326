import { Button } from '@motion/ui/base'

export type DatePickerActionBar = {
  disabled?: boolean
  onClear(): void
  onApply(): void
}

export function FilterActionBar(props: DatePickerActionBar) {
  return (
    <div className='flex p-2 border-t border-dropdown-border justify-between'>
      <Button
        type='reset'
        sentiment='neutral'
        variant='outlined'
        size='small'
        onClick={() => {
          props.onClear()
        }}
      >
        Clear
      </Button>
      <Button
        disabled={props.disabled}
        type='submit'
        sentiment='primary'
        size='small'
        shortcut='Enter'
        onClick={() => {
          props.onApply()
        }}
      >
        Apply
      </Button>
    </div>
  )
}
