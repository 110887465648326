import { ModalTrigger } from '~/areas/modals'

import { ConnectedAddCustomFieldModal } from './add-custom-field-modal'
import { ConnectedDuplicateCustomFieldModal } from './duplicate-custom-field-modal'
import { ConnectedEditCustomFieldModal } from './edit-custom-field-modal'

export function CustomFieldModalTriggers() {
  return (
    <>
      <ModalTrigger
        name='add-custom-field'
        component={ConnectedAddCustomFieldModal}
      />
      <ModalTrigger
        name='edit-custom-field'
        component={ConnectedEditCustomFieldModal}
      />
      <ModalTrigger
        name='duplicate-custom-field'
        component={ConnectedDuplicateCustomFieldModal}
      />
    </>
  )
}
