import { API, createUseMutation } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
} from '@motion/rpc-cache'

import { useQueryClient } from '@tanstack/react-query'

const cacheKeysToUpdate = [API.workspacesV2.queryKeys.root, MODEL_CACHE_KEY]
const queryFilter = createQueryFilter(cacheKeysToUpdate)

const useCreateLabelMutation = createUseMutation(API.workspacesV2.createLabel)
export const useCreateLabel = () => {
  const queryClient = useQueryClient()

  return useCreateLabelMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useUpdateLabelMutation = createUseMutation(API.workspacesV2.updateLabel)
export const useUpdateLabel = () => {
  const queryClient = useQueryClient()

  return useUpdateLabelMutation({
    onSuccess: (data) => {
      MotionCache.upsert(queryClient, queryFilter, data)
    },
  })
}

const useDeleteLabelMutation = createUseMutation(API.workspacesV2.deleteLabel)
export const useDeleteLabel = () => {
  const queryClient = useQueryClient()

  return useDeleteLabelMutation({
    onSuccess: (_, { labelId }) => {
      MotionCache.delete(queryClient, queryFilter, 'labels', labelId)
    },
  })
}
