import { useSharedState } from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'

import { ConnectedHeader } from '~/areas/project-management/pages/pm-v3/header/connected-header'
import { usePageData } from '~/areas/project-management/pages/pm-v3/routes/hooks/use-v3-page-data'

export const SelectHeader = () => {
  const data = usePageData()
  const [activeFilter] = useSharedState(ActiveFilterKey)

  return <ConnectedHeader target={activeFilter.target} title={data.title} />
}
