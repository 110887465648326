import { TextField, type TextFieldProps } from '@motion/ui/forms'

import { getTextFieldRules } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

const EVENT_TITLE_RULES = getTextFieldRules('Event title')

export const ControlledEventTitleField = () => {
  const { isLoading, isReadOnly } = useEventModalState()
  const { form } = useEventForm()
  const { control } = form

  const {
    field,
    formState: { errors },
  } = useController({
    name: 'title',
    control,
    rules: EVENT_TITLE_RULES,
  })

  if (isLoading) return null

  const onChange: TextFieldProps['onChange'] = async (value) => {
    field.onChange(value)
  }

  return (
    <TextField
      label='Event title'
      labelHidden
      placeholder='Event title'
      variant='minimal'
      sentiment={errors.title ? 'error' : 'default'}
      size='large'
      value={field.value}
      onChange={onChange}
      autoFocus={!isReadOnly}
      readOnly={isReadOnly}
    />
  )
}
