import { useOnce } from '@motion/react-core/hooks'
import { LoadingSpinner } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useNavigate, useParams } from 'react-router'

export function AttachmentPreviewPage() {
  const modalApi = useModalApi()
  const navigate = useNavigate()

  const { attachmentId } = useParams()

  useOnce(() => {
    if (attachmentId) {
      modalApi.open('attachment-preview', {
        attachmentId,
        onClose: () => navigate('/'),
      })
    }
  })

  return (
    <div className='absolute inset-0 flex items-center justify-center'>
      <LoadingSpinner />
    </div>
  )
}
