import { RefreshSolid } from '@motion/icons'
import { recurrenceRuleToText } from '@motion/ui-logic'

import { GenericField } from '../../shared'

export interface RecurrenceFieldProps {
  recurrence: string
}

export const RecurrenceField = (props: RecurrenceFieldProps) => {
  const { recurrence } = props

  return (
    <GenericField icon={RefreshSolid}>
      {recurrenceRuleToText(recurrence)}
    </GenericField>
  )
}
