import { Callout } from './callout'
import { GuestSection } from './guest-section'
import { EventTimeSection } from './time-section'

import { ModeToLabels } from '../../constants'
import { useScheduleAssistantCalendarContext } from '../../hooks'
import { Sidebar, SidebarSection } from '../styled'

export function CreateNewSidebar() {
  const [{ mode }] = useScheduleAssistantCalendarContext()

  return (
    <Sidebar>
      <SidebarSection hasBottomBorder>
        <Callout label={ModeToLabels[mode].calloutLabel} />

        <EventTimeSection />
      </SidebarSection>

      <SidebarSection>
        <Callout label='Add or remove attendees. Check the box to display their schedule (for team members)' />
        <GuestSection />
      </SidebarSection>
    </Sidebar>
  )
}
