import { CalendarSolid, XSolid } from '@motion/icons'
import { useDependantState } from '@motion/react-core/hooks'
import { type ComponentProps, type VariantProps } from '@motion/theme'
import {
  Button,
  ButtonGroup,
  IconButton,
  LoadingSpinner,
  UnstyledModal,
  useShortcut,
} from '@motion/ui/base'
import { PaletteProvider } from '@motion/ui/palette'
import {
  type ColorId,
  type EventFormFields,
  templateStr,
} from '@motion/ui-logic'
import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalTitle } from '@motion/web-common/html'
import {
  type CalendarEventSchemaV2,
  type NormalTaskSchema,
  type TaskSchema,
} from '@motion/zod/client'

import {
  type AnimatedModalTriggerComponentProps,
  useModalPromptBeforeDismiss,
} from '~/areas/modals'
import {
  ProjectPanel,
  ShellActions,
  SidebarSection,
  SidebarShell,
  SidebarWorkspaceSection,
  SidePanel,
  TaskAttachmentsCollapsable,
} from '~/areas/task-project/components'
import { useCachedItem } from '~/global/cache'
import { ErrorBoundary } from '~/global/components'
import { useProject } from '~/global/hooks'
import { useTrackEntityOpen } from '~/global/hooks/use-track-entity-open'
import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { type FormEvent, memo, type ReactNode, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ActionDotsMenu,
  ConnectedAddToProjectButton,
  ConnectedJoinConferenceButton,
  CopyMeetingLinkButton,
  ErrorState,
  EventActivityCollapsable,
  EventFieldsContainer,
  EventTag,
  FooterShell,
  FormShell,
  GridShell,
  HeaderShell,
  MainSectionShell,
  SchedulingAssistantButton,
  ShellVars,
} from './components'
import { EventModalStateProvider, useEventModalState } from './contexts'
import { EventForm } from './event-form'
import {
  ControlledEventAllDayField,
  ControlledEventColorField,
  ControlledEventConferenceField,
  ControlledEventCustomFieldsField,
  ControlledEventDateTimeFields,
  ControlledEventDescriptionField,
  ControlledEventGuestsListField,
  ControlledEventGuestsSearchField,
  ControlledEventHostField,
  ControlledEventLabelsField,
  ControlledEventLocationField,
  ControlledEventRecurrenceField,
  ControlledEventRSVPField,
  ControlledEventStatusField,
  ControlledEventTitleField,
  ControlledEventTravelTimesField,
  ControlledEventViewAsCalendarField,
  ControlledEventVisibilityField,
  ControlledEventWorkspaceProjectField,
} from './fields'
import { useEventForm, useSubmitEventForm } from './hooks'
import { validateTask } from './utils'

import { getEventColorHue } from '../../utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'event-modal': {
      eventId?: CalendarEventSchemaV2['id']
      start?: CalendarEventSchemaV2['start']
      end?: CalendarEventSchemaV2['end']
      isAllDay?: CalendarEventSchemaV2['isAllDay']
      attendees?: CalendarEventSchemaV2['attendees']
      schedulingTaskId?: TaskSchema['id']
    }
  }
}

type ConnectedEventModalProps =
  AnimatedModalTriggerComponentProps<'event-modal'> & {
    meetingTaskId?: NormalTaskSchema['id']
  }

export function ConnectedEventModal({
  open,
  ...rest
}: ConnectedEventModalProps) {
  return (
    <UnstyledModal
      data-testid='event-modal'
      type='page'
      visible={open}
      onClose={() => rest.close()}
      withAnimation
      overlayClassName='bg-modal-overlay'
    >
      <ErrorBoundary
        renderFallback={({ error }) => (
          <EventModalBodyWrapper close={() => rest.close()}>
            <ErrorState close={() => rest.close()} error={error} />
          </EventModalBodyWrapper>
        )}
      >
        <InnerEventModal {...rest} />
      </ErrorBoundary>
    </UnstyledModal>
  )
}

function InnerEventModal({
  close,
  eventId,
  start,
  end,
  isAllDay,
  attendees,
  schedulingTaskId,
  meetingTaskId,
}: Omit<ConnectedEventModalProps, 'open'>) {
  // Freezing the values when it's null so we still have the previous data when the modal closes
  const [initialValues] = useDependantState(
    () => ({
      id: eventId,
      start,
      end,
      isAllDay,
      attendees,
      schedulingTaskId,
      meetingTaskId,
    }),
    [eventId, start, end, isAllDay, attendees, schedulingTaskId, meetingTaskId],
    {
      freezeDependencyUpdates: [
        eventId,
        start,
        end,
        isAllDay,
        attendees,
        schedulingTaskId,
        meetingTaskId,
      ].every((d) => d == null),
    }
  )

  const { data: schedulingTask, isInitialLoading: isLoadingSchedulingTask } =
    useTaskByIdV2({
      id: initialValues.schedulingTaskId,
    })

  const { data: meetingTask, isInitialLoading: isLoadingMeetingTask } =
    useTaskByIdV2({
      id: initialValues.meetingTaskId,
    })

  const eventIdToLoad =
    initialValues.id ??
    (isMeetingTask(meetingTask) ? meetingTask.meetingEventId : null)

  const event = useCachedItem('calendarEvents', eventIdToLoad)
  const eventRecurringParent = useCachedItem(
    'calendarEvents',
    event?.recurringParentId
  )

  if (
    !validateTask(
      initialValues.schedulingTaskId,
      schedulingTask,
      isLoadingSchedulingTask
    )
  ) {
    throw new Error('Scheduling task cannot be found or invalid', {
      cause: {
        schedulingTaskId: initialValues.schedulingTaskId,
        schedulingTask,
        isLoadingSchedulingTask,
      },
    })
  }

  if (
    !validateTask(
      initialValues.meetingTaskId,
      meetingTask,
      isLoadingMeetingTask
    )
  ) {
    throw new Error('Meeting task cannot be found or invalid', {
      cause: {
        meetingTaskId: initialValues.meetingTaskId,
        meetingTask,
        isLoadingMeetingTask,
      },
    })
  }

  if (
    meetingTask != null &&
    event != null &&
    event.meetingTaskId !== meetingTask.id
  ) {
    throw new Error("Event and meeting task don't match", {
      cause: {
        eventId: event.id,
        eventMeetingTaskId: event.meetingTaskId,
        meetingTaskId,
      },
    })
  }

  const initialFormValues = useMemo<Partial<EventFormFields>>(
    () => ({
      ...initialValues,
      id: eventIdToLoad ?? undefined,
    }),
    [eventIdToLoad, initialValues]
  )

  return (
    <EventForm
      initialValues={initialFormValues}
      isLoading={isLoadingMeetingTask || isLoadingSchedulingTask}
      event={event}
      eventRecurringParent={eventRecurringParent}
      meetingTask={meetingTask}
      schedulingTask={schedulingTask}
    >
      <EventModalStateProvider
        event={event}
        eventRecurringParent={eventRecurringParent}
        meetingTask={meetingTask}
        schedulingTask={schedulingTask}
      >
        <EventModalBody close={close} />
      </EventModalStateProvider>
    </EventForm>
  )
}

type EventModalBodyWrapperProps = {
  close: () => void
  columns?: VariantProps<typeof FormShell>['columns']
  isLoading?: boolean
  isSidePanelOpen?: boolean
  onSubmit?: ComponentProps<typeof FormShell>['onSubmit']
  renderSidePanel?: () => ReactNode
  children: ReactNode
}
export const EventModalBodyWrapper = ({
  close,
  columns = 1,
  isLoading = false,
  isSidePanelOpen = false,
  onSubmit,
  renderSidePanel,
  children,
}: EventModalBodyWrapperProps) => {
  return (
    <ShellVars>
      <FormShell
        columns={columns}
        onSubmit={onSubmit}
        className={twMerge(
          'transition-transform',
          !isLoading &&
            isSidePanelOpen &&
            'setvar-[modal-offset=292px] -translate-x-[150px]'
        )}
      >
        <ShellActions
          className={twMerge(
            'transition-transform',
            !isLoading && isSidePanelOpen && 'translate-x-[var(--modal-offset)]'
          )}
        >
          <IconButton
            icon={XSolid}
            sentiment='onDark'
            size='small'
            variant='muted'
            onClick={() => close()}
          />
        </ShellActions>
        {children}
      </FormShell>
      {renderSidePanel?.()}
    </ShellVars>
  )
}

type EventModalBodyProps = {
  close: () => void
}
export const EventModalBody = memo(function EventModalBody({
  close,
}: EventModalBodyProps) {
  const {
    isLoading,
    initialSchedulingTask,
    initialMeetingTask,
    initialEvent,
    hasPendingComment,
    sidePanelOpen,
  } = useEventModalState()
  const submitForm = useSubmitEventForm()
  const { form, hiddenFields } = useEventForm()
  const {
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  useTrackEntityOpen({ id: initialMeetingTask?.id, type: 'TASK' })

  const hasDirtyFormFields = isDirty && !isSubmitting

  const eventId = watch('id')
  const isLoadingForm = watch('isLoading')
  const eventTitle = watch('title')
  const calendarId = watch('calendarId')
  const colorId = watch('colorId')

  const isLoadingSomething = isLoadingForm || isLoading

  const calendar = useCachedItem('calendars', calendarId)

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (!isDirty) return

    await form.handleSubmit(submitForm, (validationErrors) => {
      const errors = Object.values(validationErrors)
      if (errors.length < 1) return

      const message = errors[0].message
      if (typeof message !== 'string' || !message) return

      showErrorToast(message)
    })(e)
  }

  useOnMountAnalyticsEvent('EVENT_OPEN', {
    properties: {
      inProject: initialMeetingTask != null,
    },
    enabled: !isLoadingSomething && initialEvent != null,
  })

  useShortcut('escape', () => close())
  useShortcut('mod+s', () => onSubmit(), {
    enabled: isDirty,
  })

  useModalTitle(eventId ? eventTitle : 'New event')

  // When the event is in a project, the modal is route based
  useRouteConfirmationPromptBeforeLeaving({
    when:
      (hasDirtyFormFields || hasPendingComment) && initialMeetingTask != null,
    message: hasPendingComment ? 'Your comment has not been saved' : undefined,
  })
  // When the event is not in a project, the modal is managed by ModalAPI
  useModalPromptBeforeDismiss({
    when: hasDirtyFormFields && initialMeetingTask == null,
  })

  const relatedTask = !isLoading
    ? (initialMeetingTask ?? initialSchedulingTask)
    : null
  const isMeetingTaskMode = relatedTask != null

  const organizerName =
    initialEvent?.organizer?.displayName ?? initialEvent?.organizer?.email

  const project = useProject(initialMeetingTask?.projectId)

  const colorHue = getEventColorHue(colorId, {
    projectColor: project?.color,
    calendarColorId: calendar?.colorId as ColorId | undefined,
  })

  return (
    <PaletteProvider colorHue={colorHue}>
      <EventModalBodyWrapper
        columns={isMeetingTaskMode ? 2 : 1}
        close={close}
        onSubmit={onSubmit}
        isLoading={isLoadingSomething}
        isSidePanelOpen={!isLoadingSomething && sidePanelOpen}
        renderSidePanel={() =>
          !isLoadingSomething && (
            <SidePanel open={sidePanelOpen}>
              {relatedTask?.projectId != null ? (
                <ProjectPanel
                  projectId={relatedTask.projectId}
                  workspaceId={relatedTask.workspaceId}
                  currentTaskId={relatedTask.id}
                  enableInlineAdd={relatedTask.id != null}
                />
              ) : (
                <div className='flex items-center justify-center h-full'>
                  <LoadingSpinner />
                </div>
              )}
            </SidePanel>
          )
        }
      >
        <GridShell layout={isMeetingTaskMode ? 'meeting' : 'event'}>
          {isLoadingSomething ? (
            <div className='row-span-full col-span-full grid place-items-center'>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <HeaderShell layout={isMeetingTaskMode ? 'meeting' : 'event'}>
                <div className='flex justify-between gap-2'>
                  <div className='flex gap-3 items-center overflow-hidden'>
                    <EventTag>
                      <CalendarSolid /> Event
                    </EventTag>
                    <ControlledEventViewAsCalendarField />
                  </div>
                  <div className='flex gap-1 items-center shrink-0'>
                    <ConnectedAddToProjectButton />
                    <ConnectedJoinConferenceButton />
                    {isMeetingTaskMode && <CopyMeetingLinkButton />}
                    {eventId != null && <ActionDotsMenu eventId={eventId} />}
                  </div>
                </div>
                <ControlledEventTitleField />

                <div className='flex gap-1 justify-between items-start flex-col @[500px]/header:flex-row @[500px]/header:items-center'>
                  <ControlledEventDateTimeFields />
                  <SchedulingAssistantButton />
                </div>
                <div className='flex gap-2 items-center'>
                  <ControlledEventAllDayField />
                  <ControlledEventRecurrenceField />
                  <ControlledEventTravelTimesField />
                </div>
              </HeaderShell>
              <MainSectionShell>
                <EventFieldsContainer>
                  <section className='flex flex-col gap-2'>
                    <ControlledEventConferenceField />
                    <ControlledEventLocationField />
                    <div className='flex flex-col @[500px]/main:flex-row gap-2'>
                      <div className='w-full overflow-hidden'>
                        <ControlledEventStatusField />
                      </div>
                      <div className='w-full overflow-hidden'>
                        <ControlledEventVisibilityField />
                      </div>
                    </div>
                    <div className='flex flex-col @[500px]/main:flex-row gap-2'>
                      <div className='w-full overflow-hidden'>
                        <ControlledEventHostField />
                      </div>
                      {!hiddenFields.has('colorId') && (
                        <div className='w-full overflow-hidden'>
                          <ControlledEventColorField />
                        </div>
                      )}
                    </div>
                    <ControlledEventDescriptionField />
                  </section>
                  <aside className='flex flex-col gap-3'>
                    <ControlledEventRSVPField />
                    <ControlledEventGuestsSearchField />
                    <ControlledEventGuestsListField />
                  </aside>
                </EventFieldsContainer>

                {initialMeetingTask != null && (
                  <div className='col-span-2'>
                    <TaskAttachmentsCollapsable
                      taskId={initialMeetingTask.id}
                    />
                  </div>
                )}

                {initialMeetingTask != null && (
                  <div className='col-span-2'>
                    <EventActivityCollapsable
                      meetingTaskId={initialMeetingTask.id}
                    />
                  </div>
                )}
              </MainSectionShell>
              {isMeetingTaskMode && (
                <SidebarShell>
                  <SidebarWorkspaceSection>
                    <ControlledEventWorkspaceProjectField />
                  </SidebarWorkspaceSection>

                  <SidebarSection className='pt-3 modal-lg:pt-5'>
                    <ControlledEventLabelsField />
                    <ControlledEventCustomFieldsField />
                  </SidebarSection>
                </SidebarShell>
              )}
              <FooterShell>
                <div className='text-semantic-neutral-text-subtle text-2xs'>
                  {organizerName != null &&
                    templateStr('Organized by: {{name}}', {
                      name: organizerName,
                    })}
                </div>
                <ButtonGroup>
                  <Button
                    variant='muted'
                    sentiment='neutral'
                    size='small'
                    shortcut='escape'
                    onClick={close}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    variant='solid'
                    sentiment='primary'
                    size='small'
                    shortcut='mod+s'
                    disabled={!isDirty}
                    loading={isSubmitting}
                  >
                    {eventId == null ? 'Create event' : 'Save'}
                  </Button>
                </ButtonGroup>
              </FooterShell>
            </>
          )}
        </GridShell>
      </EventModalBodyWrapper>
    </PaletteProvider>
  )
})
