import { classed } from '@motion/theme'
import { Sentry } from '@motion/web-base/sentry'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { PrettyAssignees, type UserBadgeSize } from '~/global/components/labels'
import { useEffect } from 'react'

import { FieldIconContainer } from '../field-icon-container'
import { Text } from '../styled-text'

export const AssigneesDisplay = ({
  userIds,
  type,
  size = 'normal',
  hideIcon,
  hideIconWhenValue,
  disabled,
}: {
  userIds: string[] | null
  type: 'person' | 'multiPerson'
  size?: UserBadgeSize
  hideIcon?: boolean
  hideIconWhenValue?: boolean
  disabled?: boolean
}) => {
  // TODO @nmurphy remove after debugging
  useEffect(() => {
    if (userIds && !Array.isArray(userIds)) {
      Sentry.captureException(
        new Error(`AssigneesDisplay: userIds should be an array`),
        {
          extra: { userIds },
        }
      )
    }
  }, [userIds])

  if (userIds == null || !Array.isArray(userIds) || userIds.length === 0)
    return (
      <FieldIconContainer size={size}>
        {!hideIcon && <CustomFieldBadge type={type} />}
        <Text size={size} aria-disabled={disabled || undefined}>
          None
        </Text>
      </FieldIconContainer>
    )

  return (
    <AssigneesContainer>
      {!(hideIconWhenValue || hideIcon) && (
        <FieldIconContainer size={size}>
          <CustomFieldBadge type={type} />
        </FieldIconContainer>
      )}

      <PrettyAssignees userIds={userIds} size={size} />
    </AssigneesContainer>
  )
}

const AssigneesContainer = classed('div', {
  base: `flex items-center gap-1 w-full`,
})
