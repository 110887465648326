import {
  getTaskHiddenAndDisabledFields,
  type TaskFormFields,
} from '@motion/ui-logic/pm/task'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { useTaskModalState } from '../contexts'

export function useTaskForm() {
  const form = useFormContext<TaskFormFields>()
  const { initialTaskData } = useTaskModalState()
  const { uid: currentUserId } = useAuthenticatedUser()

  return useMemo(
    () => ({
      form,
      ...getTaskHiddenAndDisabledFields(form, {
        initialTask: initialTaskData.task,
        currentUserId,
      }),
    }),
    [currentUserId, form, initialTaskData.task]
  )
}
