import { XSolid } from '@motion/icons'
import { Button, ButtonGroup, IconButton, type Section } from '@motion/ui/base'

import { BaseMultiDropdown } from '~/global/components'
import { type ReactNode, useMemo } from 'react'

import { type DropdownFilterProps } from '../types'

const DEFAULT_RENDER_SELECTED = <T extends { id: string; name: string }>(
  selected: T[],
  items: T[]
) => {
  return selected.length === items.length
    ? 'All'
    : selected.length === 1
      ? selected[0].name
      : selected.length > 1
        ? `${selected.length} selected`
        : 'All' // an empty list is the same as no filter
}

type MultiProps<T> = {
  itemType: 'checkbox'
  items: T[]
}

type SectionedProps<T> = {
  itemType: 'sectioned-checkbox'
  sections: Section<T>[]
  renderEmpty: () => ReactNode
  sectionHeaderControls?(section: Section<T>): ReactNode
}
type ItemProps<T> = MultiProps<T> | SectionedProps<T>

type FilterButtonProps<T extends { id: string; name: string }> = Pick<
  DropdownFilterProps,
  'openOnMount' | 'onBlur'
> &
  ItemProps<T> & {
    onSelect(items: T[] | null): void

    label: string
    computeSelected(item: T): boolean
    renderSelected?(selected: T[], items: T[]): ReactNode
    renderItem(item: T): ReactNode

    searchPlaceholder?: string

    stretch?: boolean
  }

export const FilterButton = <T extends { id: string; name: string }>(
  props: FilterButtonProps<T>
) => {
  const {
    computeSelected,
    label,
    renderSelected = DEFAULT_RENDER_SELECTED,
    renderItem,
    onBlur,
    searchPlaceholder,
    openOnMount,
    // @ts-expect-error - may be there
    sectionHeaderControls,
    ...listProps
  } = props

  const allItems = useMemo(
    () =>
      listProps.itemType === 'sectioned-checkbox'
        ? listProps.sections.flatMap((s) => s.items)
        : listProps.items,
    // @ts-expect-error - conditional property
    [listProps.itemType, listProps.items, listProps.sections]
  )

  const info = useMemo(() => {
    const selected = allItems.filter((x) => computeSelected(x))
    const text = renderSelected(selected, allItems)
    return { all: allItems, selected, text }
  }, [allItems, computeSelected, renderSelected])

  return (
    <BaseMultiDropdown
      searchable
      computeSearchValue={(value) => value.name}
      computeSelected={computeSelected}
      computeKey={idKey}
      renderItem={renderItem}
      onBlur={onBlur}
      openOnMount={openOnMount}
      searchPlaceholder={searchPlaceholder}
      // @ts-expect-error - on sub type
      actionsVisibility='on-hover'
      actions={sectionHeaderControls}
      {...listProps}
    >
      <ButtonGroup stretch={props.stretch} segmented nowrap>
        <Button sentiment='neutral' variant='outlined' size='small'>
          {label}: <div className='font-medium'>{info.text}</div>
        </Button>
        <IconButton
          sentiment='neutral'
          variant='outlined'
          size='small'
          icon={XSolid}
          onClick={(e) => {
            e.stopPropagation()
            listProps.onSelect(null)
          }}
        />
      </ButtonGroup>
    </BaseMultiDropdown>
  )
}
function idKey<T extends { id: string }>(item: T) {
  return item.id
}
