import { SwitchVerticalSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { PopoverTrigger } from '@motion/ui/base'

import { ConnectedMemberSortPanel } from './member-sort'

export const SortMembersButton = () => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => <ConnectedMemberSortPanel />}
    >
      <CellButton style={{ gridRow: 1, gridColumn: 1 }}>
        <SwitchVerticalSolid width={18} height={18} />
      </CellButton>
    </PopoverTrigger>
  )
}

const CellButton = classed('button', {
  base: `
    sticky left-0 top-0 
    
    grid items-center justify-center
    
    cursor-pointer
    bg-calendar-bg-default
    text-semantic-neutral-icon-default 

    hover:bg-button-neutral-muted-bg-hover
    hover:text-button-neutral-muted-text-default

  `,
})
