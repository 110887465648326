import {
  defaultTaskSortFn,
  type PartialDataFilters,
  toDataFilter,
  useActiveFilter,
} from '@motion/ui-logic/pm/data'
import { merge } from '@motion/utils/core'
import { type OrNullable } from '@motion/utils/object'
import { type GetTasksV2FilterWithOperatorsSchema } from '@motion/zod/client'

import { type TaskLoaderProps, useTaskLoader } from '~/areas/tasks/context'
import { useMemo } from 'react'

import { useTaskFields } from '../fields'
import { getSortFn } from '../fields/sorting'
import { useViewState } from '../view-state'

export type ConnectedTaskLoaderProps = {
  overrides?: PartialDataFilters
  queryOverrides?: OrNullable<GetTasksV2FilterWithOperatorsSchema>
  postProcess?: TaskLoaderProps['postProcess']
}

export const ConnectedTaskLoader = ({
  overrides,
  queryOverrides,
  postProcess,
}: ConnectedTaskLoaderProps) => {
  const [viewState] = useViewState()
  const taskFields = useTaskFields()

  const loaderQueryOverrides = useMemo(
    () => ({
      ...queryOverrides,
    }),
    [queryOverrides]
  )

  const [filterState] = useActiveFilter()

  const filter = useMemo(
    () => merge({}, toDataFilter(filterState), overrides),
    [filterState, overrides]
  )

  const sortBy = useMemo(() => {
    return viewState.sortBy
      ? getSortFn(viewState.sortBy, taskFields)
      : defaultTaskSortFn
  }, [taskFields, viewState.sortBy])

  useTaskLoader({
    filter,
    queryOverrides: loaderQueryOverrides,
    // TODO: This should move to the server
    postProcess,
    sortBy,
  })

  return null
}
