import { AlertModal } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from '../modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'team-trial-ended': {
      onStartTeamPlan: () => void
      doNotShowAgain: () => void
    }
  }
}

export const TeamTrialEndedModal = ({
  doNotShowAgain = () => {
    void 0
  },
  onStartTeamPlan,
  close,
}: ModalTriggerComponentProps<'team-trial-ended'>) => {
  const handleDismiss = () => {
    doNotShowAgain()
    close()
  }

  return (
    <AlertModal
      title='Your team trial has ended'
      actions={[
        {
          label: 'Start team plan',
          onAction: onStartTeamPlan,
        },
        {
          label: 'No thanks',
          onAction: handleDismiss,
          sentiment: 'neutral',
          variant: 'muted',
        },
      ]}
      onClose={() => {}}
      visible
      blocking
    >
      <p className='text-base'>
        Team members that aren&apos;t on a paid plan have lost access to Motion.
        Start your team plan to keep team members on Motion.
      </p>
    </AlertModal>
  )
}
