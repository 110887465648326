import { WorkspaceSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { ProjectColoredIcon } from '@motion/ui/project'
import { type ProjectSchema, type TaskResponseSchema } from '@motion/zod/client'

import { GenericField } from './generic-field'

interface WorkspaceProjectFieldProps {
  workspaceName: TaskResponseSchema['workspace']['name']
  projectName?: TaskResponseSchema['project']['name']
  projectColor?: ProjectSchema['color']
}

export const WorkspaceProjectField = (props: WorkspaceProjectFieldProps) => {
  const { workspaceName, projectName, projectColor } = props

  return (
    <GenericField icon={WorkspaceSolid}>
      <span className='truncate max-w-[150px]'>{workspaceName}</span>
      {projectName != null && (
        <>
          <span>/</span>
          <ProjectColoredIcon
            width={12}
            height={12}
            color={projectColor as COLOR | undefined}
          />
          <span className='truncate max-w-[150px]'>{projectName}</span>
        </>
      )}
    </GenericField>
  )
}
