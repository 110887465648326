import { ProjectColoredIcon } from '@motion/ui/project'
import { formatNumberValue, templateStr } from '@motion/ui-logic'

import { getAllIdsOfTypeInFolder } from '~/areas/folders/utils'
import type { PMPageUrlParams } from '~/areas/project-management/pages/pm-v3/routes/types'
import { useI18N } from '~/global/contexts'
import { useWorkspaceById } from '~/global/hooks'
import { useFolders } from '~/global/rpc/folders'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { EntityCountTag } from './entity-count-tag'
import { useTargetTypeLabel } from './hooks'

export function ProjectsCountTag() {
  const { pluralize } = useI18N()
  const { folderId, workspaceId, projectId } = useParams<PMPageUrlParams>()
  const { data } = useFolders()
  const workspace = useWorkspaceById(workspaceId)

  const label = useTargetTypeLabel()

  const projectCount = useMemo(() => {
    // If we are on a project page, we don't want to show the count tag
    if (projectId) return null

    if (folderId != null) {
      return getAllIdsOfTypeInFolder(
        data?.models.systemFolders?.workspaces?.items ?? [],
        folderId,
        'PROJECT'
      ).length
    }

    if (workspaceId != null && workspace) {
      return workspace.projectIds.length
    }

    return null
  }, [
    data?.models.systemFolders?.workspaces?.items,
    folderId,
    projectId,
    workspace,
    workspaceId,
  ])

  if (projectCount == null) return null

  return (
    <EntityCountTag
      count={projectCount}
      icon={ProjectColoredIcon}
      tooltipText={templateStr(`{{countText}}{{page}}`, {
        countText: pluralize(
          projectCount,
          '1 project',
          `${formatNumberValue(projectCount, 'formatted')} projects`
        ),
        page: label ? ` in this ${label}` : '',
      })}
    />
  )
}
