import { ConfirmationModal, type ConfirmationModalProps } from '@motion/ui/base'

import { type ModalTriggerComponentProps } from './modal-trigger'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    confirm: PromptCallbacks<boolean> & {
      title: ConfirmationModalProps['title']
      description?: ConfirmationModalProps['description']
      destructive?: ConfirmationModalProps['destructive']
      blocking?: ConfirmationModalProps['blocking']
      closeButtonText?: ConfirmationModalProps['closeLabel']
      confirmButtonText?: ConfirmationModalProps['action']['label']
      loading?: ConfirmationModalProps['loading']
      analytics: {
        name: string
      }
    }
  }
}

export function ConnectedConfirmationModal({
  close,
  title,
  description,
  destructive = false,
  blocking = false,
  closeButtonText = 'Close',
  confirmButtonText = 'Confirm',
  analytics: { name: analyticsName },
}: ModalTriggerComponentProps<'confirm'>) {
  return (
    <ConfirmationModal
      data-testid={`modal-${analyticsName}`}
      visible
      title={title}
      description={description}
      destructive={destructive}
      blocking={blocking}
      onClose={() => close()}
      closeLabel={closeButtonText}
      action={{
        label: confirmButtonText,
        onAction: () => close(true),
      }}
    />
  )
}
