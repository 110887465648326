import { classed } from '@motion/theme'
import { LoadingSpinner } from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

const VARIANT_LOADER_SIZE = {
  default: 18,
  compact: 16,
}

type AttachmentItemPlaceholderProps = {
  fileName: string
  progress?: number
  variant?: 'default' | 'compact'
}

export function AttachmentItemPlaceholder({
  fileName,
  progress,
  variant = 'default',
}: AttachmentItemPlaceholderProps) {
  return (
    <ItemContainer variant={variant}>
      <div
        className={twMerge(
          'flex items-center justify-center',
          variant === 'compact' ? 'size-4' : 'size-6'
        )}
      >
        <LoadingSpinner
          size={VARIANT_LOADER_SIZE[variant]}
          className='!text-semantic-primary-icon-default'
        />
      </div>
      <div>
        {fileName}
        {progress != null && ` ${progress}%`}
      </div>
    </ItemContainer>
  )
}

const ItemContainer = classed('div', {
  base: `
    flex items-center p-2 gap-2 
    text-sm text-semantic-neutral-text-subtle
    rounded
  `,
  variants: {
    variant: {
      default: '',
      compact: `
        text-xs
      `,
    },
  },
})
