import { ColorCheckSolid, ColorSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { getColorFromColorClassId } from '@motion/ui/palette'
import { ProjectPalette } from '@motion/ui/project'
import { type ColorId, getColorClassForColorId } from '@motion/ui-logic'

import { type ComponentRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

export type ColorItemBadgeProps = (
  | {
      colorId?: ColorId | string
      color?: never
    }
  | {
      colorId?: never
      color: COLOR
    }
) & {
  size?: BadgeSize
  selected?: boolean
}

export const ColorItemBadge = forwardRef<
  ComponentRef<typeof ColorSolid>,
  ColorItemBadgeProps
>(function ColorItemBadge({ color, colorId, size, selected = false }, ref) {
  const finalColor =
    color ?? getColorFromColorClassId(getColorClassForColorId(colorId))

  const pixelSize = getBadgeSizeInPixels(size)

  const Icon = selected ? ColorCheckSolid : ColorSolid

  return (
    <ProjectPalette color={finalColor}>
      <Icon
        ref={ref}
        width={pixelSize}
        height={pixelSize}
        // using ! because the badge can be inside buttons, which overrides the color
        className={twMerge(
          '!text-palette-highlight-default',
          selected && 'rounded outline outline-palette-highlight-default'
        )}
      />
    </ProjectPalette>
  )
})
