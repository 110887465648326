import { type NormalTaskSchema, type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskLabelsUpdater } from '../updaters'

export function useTaskLabelsData(task: TaskSchema) {
  const updateTaskLabels = useTaskLabelsUpdater()

  const onChange = useCallback(
    (labelIds: NormalTaskSchema['labelIds']) => {
      void updateTaskLabels(task, labelIds)
    },
    [task, updateTaskLabels]
  )

  const disabled =
    task.type === 'RECURRING_INSTANCE' || task.archivedTime != null

  return {
    onChange,
    disabled,
  }
}
