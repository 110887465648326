import { getWindowData } from '@motion/web-base/env'
import { useExperiment } from '@motion/web-common/flags'

import { useMemo } from 'react'

import { useHasMinimumDesktopVersion } from './use-has-minimum-desktop-version'

export function useUserMustUpdate() {
  const hasMinimumDesktopVersion = useHasMinimumDesktopVersion()
  const shouldCheckVersion = useExperiment('desktop-minimum-version')
  const { isElectron } = getWindowData()

  return useMemo(() => {
    if (
      !shouldCheckVersion ||
      !isElectron ||
      !shouldCheckVersion.payload?.minimumVersionNumber
    )
      return false

    const MINIMUM_ALLOWED_VERSION =
      shouldCheckVersion.payload?.minimumVersionNumber

    return !hasMinimumDesktopVersion(MINIMUM_ALLOWED_VERSION)
  }, [hasMinimumDesktopVersion, isElectron, shouldCheckVersion])
}
