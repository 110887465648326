import { DateTime } from 'luxon'

import { type DateRow } from '../agenda-sidebar.types'

export const createDateRow = (date: string): DateRow => {
  const today = DateTime.now().toISODate()

  return {
    id: date,
    type: 'date-row',
    date: DateTime.fromISO(date),
    isToday: DateTime.fromISO(date).toISODate() === today,
  }
}
