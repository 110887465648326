import { useCalendarState } from '~/areas/calendar/hooks'
import { useNewCalendarEvent } from '~/areas/calendar/main-calendar/hooks/use-new-calendar-event'
import { NEW_EVENT_ID } from '~/areas/calendar/utils'
import { useCachedItem } from '~/global/cache'
import { parseFullCalendarEventId } from '~/state/calendarEvents/calendarEventsUtils'

export const useSelectedCalendarEvent = () => {
  const [state] = useCalendarState()
  const { id } = parseFullCalendarEventId(state.selectedCalendarEvent?.id ?? '')
  const existingCalendarEvent = useCachedItem('calendarEvents', id)
  const recurringEventParent = useCachedItem(
    'calendarEvents',
    existingCalendarEvent?.recurringParentId
  )
  const newCalendarEvent = useNewCalendarEvent()

  const event = newCalendarEvent ?? existingCalendarEvent

  const targetId = newCalendarEvent
    ? NEW_EVENT_ID
    : state.selectedCalendarEvent?.id

  return { targetId, event, recurringEventParent }
}
