import { usePrevious } from '@motion/react-core/hooks'
import { findDefaultStatus } from '@motion/shared/common'
import { pick } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import {
  useLegacyProjectTemplateById,
  useMyTasksWorkspace,
  useProject,
  useWorkspaceById,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { useSearchParams } from '~/routing'
import { type ReactNode, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { TemplateProjectFormHandlers } from './template-project-form-handlers'
import { TemplateProjectFormOptions } from './template-project-form-options'
import { type TemplateProjectFormFields } from './types'

export type TemplateProjectFormProps = {
  children: ReactNode
}

export const TemplateProjectForm = ({ children }: TemplateProjectFormProps) => {
  const initialFormData = useInitialFormData()

  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const { isDirty } = form.formState

  const previousId = usePrevious(initialFormData.id)
  useEffect(() => {
    if (!isDirty || previousId !== initialFormData.id) {
      form.reset(initialFormData)
    }
  }, [form, initialFormData, isDirty, previousId])

  return (
    <FormProvider {...form}>
      <TemplateProjectFormOptions>
        <TemplateProjectFormHandlers>{children}</TemplateProjectFormHandlers>
      </TemplateProjectFormOptions>
    </FormProvider>
  )
}

function useInitialProjectFormData(): TemplateProjectFormFields {
  const { workspaceId: workspaceIdParam, projectId: projectIdParam } =
    useParams<{
      workspaceId: string
      projectId: string
    }>()
  const { project: projectSearchParam } = useSearchParams<{ project: string }>()

  const projectId =
    projectSearchParam === 'new'
      ? undefined
      : projectSearchParam === 'edit'
        ? projectIdParam
        : projectSearchParam

  const { uid: currentUserId } = useAuthenticatedUser()
  const defaultWorkspace = useMyTasksWorkspace()

  const isViewingProjectDetails = !!projectId

  const project = useProject(projectId)

  const forWorkspaceId =
    project?.workspaceId ?? workspaceIdParam ?? defaultWorkspace?.id ?? ''

  const workspace = useWorkspaceById(forWorkspaceId)
  const statuses = useWorkspaceStatuses(forWorkspaceId)

  const priorityLevel = project?.priorityLevel ?? 'MEDIUM'

  const defaultStatusId =
    workspace != null ? findDefaultStatus(statuses)?.id : undefined
  const statusId = project?.statusId ?? defaultStatusId ?? ''

  const managerId = isViewingProjectDetails
    ? (project?.managerId ?? null)
    : currentUserId

  return useMemo(() => {
    return {
      isLoading: false,
      id: project?.id,
      workspaceId: workspace?.id ?? '',
      name: project?.name ?? '',
      description: project?.description ?? '',
      priorityLevel,
      managerId,
      statusId,
      labelIds: project?.labelIds ?? [],
    }
  }, [
    project?.labelIds,
    project?.id,
    project?.name,
    project?.description,
    workspace?.id,
    priorityLevel,
    managerId,
    statusId,
  ])
}

function useInitialFormData(): TemplateProjectFormFields {
  // these params are used in PMv2 or the new settings
  const { templateProjectId } = useParams<{
    workspaceId: string
    templateProjectId: string
  }>()

  const formDataFromProject = useInitialProjectFormData()

  const templateProject = useLegacyProjectTemplateById(templateProjectId)

  return useMemo(() => {
    if (!templateProjectId) {
      return formDataFromProject
    }

    return {
      ...formDataFromProject,
      isLoading: formDataFromProject.isLoading,

      ...(templateProject != null
        ? {
            templateId: templateProject.id,
            templateName: templateProject.name,
            workspaceId: templateProject.workspaceId,
            ...pick(templateProject.project, [
              'id',
              'name',
              'description',
              'managerId',
              'priorityLevel',
              'statusId',
            ]),
            labelIds: templateProject.project?.labelIds ?? [],
          }
        : {}),
    }
  }, [formDataFromProject, templateProject, templateProjectId])
}
