import { warnInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { BackendApiService } from './BackendApiService'

import {
  type BookingLinkTemplate,
  type BookingSettings,
} from '../state/booking'
import {
  type AvailabilitySettings,
  type CreateManualLinkDto,
  type CreateTemplateChildLinkDto,
  type CreateTemplateLinkDto,
  type GetBookingLinkDto,
  type UpdateTemplateChildLinkDto,
  type UpdateTemplateLinkDto,
} from '../types/bookingTypes'

export class BookingService extends BackendApiService {
  static id = 'BookingService' as const

  /**
   * Migrated from updateAvailabilityId
   */
  async setUrlPrefix(nextId?: string) {
    try {
      const { urlPrefix } = await this.request('booking/domainId', 'POST', {
        nextId,
      })
      return urlPrefix
    } catch (e: any) {
      warnInDev('failed to set url prefix', e)
      if (e?.status === 400) {
        throw new Error('This URL has already been taken')
      }
      throw e
    }
  }

  async createManualLink(data: CreateManualLinkDto) {
    try {
      const res = await this.request('booking/manualLink', 'POST', data)
      return { linkId: res.linkId, linkSlug: res.linkSlug }
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'createManualLink' },
      })
      warnInDev('createManualLink: request error', e)
      return null
    }
  }

  async createTemplateChildLink(data: CreateTemplateChildLinkDto) {
    try {
      const res = await this.request(
        'booking/cockroachTemplateChildLink',
        'POST',
        data
      )
      return { linkId: res.linkId, linkSlug: res.linkSlug }
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'createTemplateChildLink' },
      })
      warnInDev('createManualLink: request error', e)
      return null
    }
  }

  async updateTemplateChildLink(data: UpdateTemplateChildLinkDto) {
    try {
      const res = await this.request(
        'booking/updateTemplateChildLink',
        'PATCH',
        data
      )
      return { linkId: res.linkId, linkSlug: res.linkSlug }
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'updateTemplateChildLink' },
      })
      warnInDev('updateCockroachChildLink: request error', e)
      return null
    }
  }

  async createTemplateLink(
    templateData: CreateTemplateLinkDto
  ): Promise<BookingLinkTemplate | null> {
    try {
      const res = await this.request('booking/templateLink', 'POST', {
        ...templateData,
      })
      return res.bookingLink
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'createTemplateLink' },
      })
      warnInDev('createAvailabilityTemplate: request error', e)
      return null
    }
  }

  async updateTemplateLink(
    templateData: UpdateTemplateLinkDto,
    previousAvailabilitiesLinkId: string
  ): Promise<BookingLinkTemplate | null> {
    try {
      const res = await this.request(
        `booking/templateLink/${previousAvailabilitiesLinkId}`,
        'PATCH',
        {
          ...templateData,
        }
      )
      return res.bookingLink
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'updateTemplateLink' },
      })
      warnInDev('updateAvailabilityTemplate: request error', e)
      return null
    }
  }

  async deleteTemplateLink(linkId: string) {
    try {
      return await this.request(`booking/templateLink/${linkId}`, 'DELETE')
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'deleteTemplateLink' } })
      warnInDev('deleteAvailabilityTemplate: request error', e)
      return null
    }
  }

  async updateAvailabilitySettings(settings: AvailabilitySettings) {
    try {
      return await this.request(`booking/settings`, 'POST', settings)
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'updateAvailabilitySettings' },
      })
      warnInDev('updateAvailabilitySetting: request error', e)
    }
  }

  async updateRichAvailabilityMessageSetting(enabled: boolean) {
    try {
      return await this.request(`booking/settings/richMessage`, 'POST', {
        enabled,
      })
    } catch (e) {
      Sentry.captureException(e, {
        tags: { position: 'updateRichAvailabilityMessageSetting' },
      })
      warnInDev('updateRichAvailabilityMessageSetting: request error', e)
    }
  }

  async getTemplates() {
    try {
      return (await this.request(
        'booking/templates',
        'GET'
      )) as BookingLinkTemplate[]
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'getTemplates' } })
      warnInDev('getTemplates: request error', e)
    }
  }

  async getBookingSettings() {
    try {
      return await this.request('booking/settings', 'GET')
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'getBookingSettings' } })
      warnInDev('getBookingSettings: request error', e)
    }
  }

  async initializeBookingSettings() {
    return (await this.request(
      'booking/settings/init',
      'POST'
    )) as BookingSettings
  }

  async getBookingLink(dto: GetBookingLinkDto) {
    const linkId = dto.linkId
    if (!linkId) {
      return null
    }
    try {
      let urlWithParams = `booking/link/data?linkId=${linkId}`
      const keys = Object.keys(dto) as (keyof GetBookingLinkDto)[]
      keys.forEach((key) => {
        if (dto[key] && key !== 'linkId') {
          urlWithParams = `${urlWithParams}&${key}=${dto[key]}`
        }
      })
      return await this.request(urlWithParams, 'GET')
    } catch (e) {
      Sentry.captureException(e, { tags: { position: 'getBookingLink' } })
      warnInDev('getBookingLink: request error', e)
    }
  }
}
export const instance = new BookingService()
