import { PencilSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useController } from 'react-hook-form'

import { useStageEventField } from './hooks'

import { useFlowTemplateForm } from '../../../hooks'

type Props = {
  taskBasePath: `stages.${number}.tasks.${number}`
}

export const ControlledStageTaskDescriptionField = ({
  taskBasePath,
}: Props) => {
  const modalApi = useModalApi()
  const { form } = useFlowTemplateForm()
  const { control, watch } = form

  const { field } = useController({
    name: `${taskBasePath}.description`,
    control,
  })

  const [stageCardType] = useStageEventField(taskBasePath)

  const workspaceId = watch('workspaceId')

  const openDescriptionModal = async () => {
    const response = await modalApi.prompt('description-modal', {
      workspaceId,
      description: field.value,
      title: 'Edit task description',
    })

    if (response !== ModalDismissed) {
      field.onChange(response)
    }
  }

  return (
    <Button
      variant='outlined'
      sentiment='neutral'
      size='xsmall'
      onClick={openDescriptionModal}
    >
      {templateStr('{{icon}} {{text}}', {
        icon: <PencilSolid />,
        text: stageCardType === 'event' ? 'Task Description' : 'Description',
      })}
    </Button>
  )
}
