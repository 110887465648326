import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useCreateWorkspaceMembers as useCreateWorkspaceMembersCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useCreateWorkspaceMembers = () => {
  const {
    mutateAsync: createWorkspaceMembers,
    isLoading,
    isError,
  } = useCreateWorkspaceMembersCall()

  const context = useRouteAnalyticsMetadata()

  return {
    createWorkspaceMembers: useCallback(
      async (data: {
        workspaceId: WorkspaceSchema['id']
        userIds: string[]
      }) => {
        try {
          if (data.userIds.length === 0) {
            return
          }

          recordAnalyticsEvent('PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBERS', {
            view: context.page === 'settings' ? 'settings' : 'inline',
            count: data.userIds.length,
          })

          const response = await createWorkspaceMembers(data)

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'create-workspace-members',
            },
          })

          showErrorToast(e)
        }
      },
      [createWorkspaceMembers, context.page]
    ),
    isLoading,
    isError,
  }
}
