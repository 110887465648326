import { PuzzleSolid } from '@motion/icons'
import { Button, PopoverTrigger } from '@motion/ui/base'

import { TemplateDropdownContent } from './template-dropdown-content'

export type FlowTemplateDropdownProps = {
  projectId: string
}

export const FlowTemplateDropdown = ({
  projectId,
}: FlowTemplateDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <TemplateDropdownContent
          close={close}
          shouldUseFlowTemplates
          searchable
          projectId={projectId}
        />
      )}
    >
      <Button sentiment='neutral' size='small' variant='muted'>
        <PuzzleSolid />
        Use template
      </Button>
    </PopoverTrigger>
  )
}
