import { classed } from '@motion/theme'
import { templateStr } from '@motion/ui-logic'
import { parseDate } from '@motion/utils/dates'

import { type DayHeaderContentArg } from '@fullcalendar/core'
import { useBlockingTimeslots } from '~/global/rpc'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'

import { useCalendarState } from '../../hooks'
import { BlockingTimeslotDropdown } from '../blocking-timeslot-dropdown'

type CalendarDayHeaderProps = {
  info: DayHeaderContentArg
}
export const CalendarDayHeader = (props: CalendarDayHeaderProps) => {
  const { data: blockingTimeslots } = useBlockingTimeslots()
  const [calendarState] = useCalendarState()
  const { info } = props
  if (!info.date) return

  const date = parseDate(info.date)
  const dateIsoDate = date.toISODate()

  const isToday = dateIsoDate === DateTime.local().toISODate()
  const isSelectedDate = calendarState.selectedDate.toISODate() === dateIsoDate
  const isBeforeToday = date < DateTime.local().startOf('day')
  const timeslotIds =
    !isBeforeToday && blockingTimeslots
      ? blockingTimeslots
          ?.filter(
            (t) => DateTime.fromISO(t.startTime).toISODate() === dateIsoDate
          )
          .map((t) => t.id)
      : []

  return (
    <div className='relative'>
      <span
        className={twMerge(
          'text-sm h-[40px] font-medium inline-flex items-center  gap-1',
          isToday || isSelectedDate
            ? 'text-semantic-neutral-text-default'
            : 'text-semantic-neutral-text-subtle'
        )}
      >
        {templateStr('{{weekday}} {{number}}', {
          weekday: date.toFormat('ccc'),
          number: (
            <DateNumber today={isToday} selected={isSelectedDate}>
              {date.toFormat('d')}
            </DateNumber>
          ),
        })}
      </span>
      {!isBeforeToday && (
        <div
          className={twMerge(
            'absolute top-[9px] [&_svg]:!text-semantic-neutral-icon-subtle',
            false ? 'right-0' : 'right-[-4px]'
          )}
        >
          <BlockingTimeslotDropdown date={date} timeslotIds={timeslotIds} />
        </div>
      )}
    </div>
  )
}

const DateNumber = classed(
  'span',
  'h-6 p-1 inline-flex items-center',
  ' rounded font-semibold',
  {
    variants: {
      today: {
        true: 'bg-semantic-primary-bg-strong-default text-semantic-primary-text-onDark',
      },
      selected: { true: '' },
    },
    compoundVariants: [
      {
        today: false,
        selected: true,
        className:
          'bg-semantic-primary-bg-default text-semantic-primary-text-default',
      },
    ],
  }
)
