import { type CalendarEventSchemaV2 } from '@motion/rpc-types'
import { getColorClassForColorId } from '@motion/ui-logic'

import {
  DEFAULT_CALENDAR_COLOR,
  type FullCalendarMotionCreateNewEvent,
} from '~/areas/calendar/utils'

import { type UserScheduleInfo } from '../../types'

export function scheduleAssistantEventToFullCalendar(
  calendarEvent: CalendarEventSchemaV2,
  scheduleInfo: Pick<UserScheduleInfo, 'colorId'>,
  isCurrentUser?: boolean
): FullCalendarMotionCreateNewEvent {
  return {
    id: `event|${calendarEvent.id}|create_new`,
    title: calendarEvent.title,
    start: calendarEvent.start,
    end: calendarEvent.end,
    allDay: calendarEvent.isAllDay,
    extendedProps: {
      colorId: getColorClassForColorId(
        isCurrentUser ? DEFAULT_CALENDAR_COLOR : scheduleInfo.colorId
      ),
      variant: isCurrentUser ? 'dashed' : 'default',
    },
  }
}
