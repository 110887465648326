import { useDependantState } from '@motion/react-core/hooks'
import { PopoverButton, SearchableDropdown } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { type CustomFieldSchemaByType } from '@motion/ui-logic'
import { type SupportedStringFilterSchema } from '@motion/ui-logic/pm/data'

import { useCustomFieldFilter } from '~/areas/project-management/filters/context/hooks'

import { ALLOWED_STRING_OPERATORS, type TextFilterAllowedFields } from './types'

import { type DropdownFilterProps } from '../../../../types'
import { FilterActionBar } from '../../action-bar'
import { type ConditionOption } from '../../filter-item'
import { getNarrowedFilter } from '../utils'

type Props = {
  customField: CustomFieldSchemaByType<TextFilterAllowedFields>
  onSubmit: (schema: SupportedStringFilterSchema) => void
  close: () => void
} & Pick<DropdownFilterProps, 'target'>

export function TextFilterDropdown({
  onSubmit,
  customField,
  close,
  target,
}: Props) {
  const [filters, setFilterValue] = useCustomFieldFilter(target, customField)
  const filter = getNarrowedFilter(filters)

  const [selectedOperator, setSelectedOperator] = useDependantState<
    ConditionOption<(typeof ALLOWED_STRING_OPERATORS)[number]['id']>
  >(
    () =>
      ALLOWED_STRING_OPERATORS.find((op) => op.id === filter?.operator) ??
      ALLOWED_STRING_OPERATORS[0],
    [filter]
  )
  const [localValue, setLocalValue] = useDependantState(
    () => (filter != null && 'value' in filter ? filter.value : ''),
    [filter]
  )

  function handleSubmit() {
    if (selectedOperator.id === 'empty') {
      return onSubmit({
        inverse: filter?.inverse,
        operator: selectedOperator.id,
      })
    }

    return onSubmit({
      ...filter,
      operator: selectedOperator.id,
      value: localValue,
    })
  }

  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      return handleSubmit()
    }
  }

  return (
    <div className='w-[220px] flex flex-col'>
      <div className='flex flex-col gap-2 m-2'>
        <SearchableDropdown
          items={ALLOWED_STRING_OPERATORS}
          renderItem={(item) => <div>{item.label}</div>}
          selectedItem={selectedOperator}
          onChange={(item) => {
            setSelectedOperator(item)
          }}
        >
          <PopoverButton>{selectedOperator.label}</PopoverButton>
        </SearchableDropdown>

        {selectedOperator.id !== 'empty' && (
          <TextField
            value={localValue}
            onChange={setLocalValue}
            onKeyDown={handleKeyDown}
            variant='default'
            placeholder='Text'
            showClearButton
          />
        )}
      </div>
      <FilterActionBar
        onClear={() => {
          setFilterValue(null)
          close()
        }}
        onApply={handleSubmit}
      />
    </div>
  )
}
