import {
  convertDataFieldsToCreateSchema,
  type EventFormFields,
} from '@motion/ui-logic'

import { useCreateEvent } from '~/areas/event/hooks'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { useEventModalState } from '../contexts'

export function useFormCreateEvent() {
  const modalState = useEventModalState()
  const createEvent = useCreateEvent()

  return useCallback(
    async (fields: EventFormFields) => {
      try {
        return createEvent(convertDataFieldsToCreateSchema(fields, modalState))
      } catch (e) {
        showErrorToast(e)
      }
    },
    [createEvent, modalState]
  )
}
