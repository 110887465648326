import { byProperty, Compare } from '@motion/utils/array'

import { useAppWorkspaceContext } from '~/global/contexts'
import { useMemo } from 'react'

import { FilterButton } from './filter-button'

import { useFieldFilter } from '../../context'
import { buildIdFilter } from '../../utils'
import { type DropdownFilterProps } from '../types'

export const ConnectedWorkspaceFilter = (props: DropdownFilterProps) => {
  const [context] = useAppWorkspaceContext({ scope: 'shell' })

  const workspaces = useMemo(
    () =>
      [...context.workspaces.all].sort(
        byProperty('name', Compare.caseInsensitive)
      ),
    [context.workspaces]
  )

  const [filter, setFilter] = useFieldFilter('workspaces', 'ids')

  return (
    <FilterButton
      itemType='checkbox'
      items={workspaces}
      computeSelected={(item) => filter?.value.includes(item.id) ?? false}
      onSelect={(values) => {
        setFilter(buildIdFilter(values, filter))
      }}
      label='Workspace'
      renderItem={(item) => <div>{item.name}</div>}
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      stretch
    />
  )
}
