import { byProperty, Compare } from '@motion/utils/array'

import { LexoRank } from 'lexorank'
import { useMemo } from 'react'

import { useTemplatesByWorkspaceId } from '../../../../global/rpc'

/*
 * This hook is used to get the next rank for a task in a project template.
 * @param workspaceId - The id of the workspace.
 * @param projectTemplateId - The id of the project template.
 */
export const useNextTemplateTaskRank = (
  workspaceId: string,
  projectTemplateId: string = ''
) => {
  const workspaceTemplates = useTemplatesByWorkspaceId(
    { workspaceId },
    { enabled: !!workspaceId && !!projectTemplateId }
  )

  const projectTemplate = workspaceTemplates.data?.templateProjects.find(
    (templateProject) => templateProject.id === projectTemplateId
  )

  return useMemo(() => {
    const tasks =
      projectTemplate?.project.tasks?.sort(
        byProperty('rank', Compare.caseSensitive)
      ) ?? []

    if (tasks.length === 0) {
      return LexoRank.middle().format()
    }

    // If the last task rank is empty, return empty since this is the first task with no rank
    if (!tasks[tasks.length - 1].rank) {
      return ''
    }

    return LexoRank.parse(tasks[tasks.length - 1].rank ?? '')
      .genNext()
      .format()
  }, [projectTemplate?.project.tasks])
}
