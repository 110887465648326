import { type PersonCustomField } from '@motion/ui-logic'

import {
  AssigneesDisplay,
  CustomField,
} from '~/areas/project-management/custom-fields'
import { type ControlledCustomFieldProps } from '~/areas/project-management/pages/pm-v3/tree-list/cells'
import { CellAction } from '~/areas/project-management/pages/pm-v3/tree-list/cells/components'
import { type FC } from 'react'

export const ControlledPerson: FC<
  ControlledCustomFieldProps<PersonCustomField>
> = ({ value, onSubmit, customField, disabled }) => {
  return (
    <CustomField.Person
      selectedUserId={value}
      onChange={onSubmit}
      workspaceId={customField.definition.workspaceId}
    >
      <CellAction isFake={disabled} disabled={disabled} unstyled>
        <AssigneesDisplay
          userIds={value ? [value] : null}
          type='person'
          size='xsmall'
          hideIconWhenValue
        />
      </CellAction>
    </CustomField.Person>
  )
}
