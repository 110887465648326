import { SearchSolid } from '@motion/icons'
import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { IconButton, useShortcut } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { useUIContext } from '@motion/ui/providers'
import { useHasTreatment } from '@motion/web-common/flags'

import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export const NameSearchButton = (props: {
  onSearch: (search: string) => void
  value: string
  placeholder?: string
}) => {
  const { hasVisibleModals } = useUIContext()
  const [isFocused, setIsFocused] = useState(false)
  const [searchValue, setSearchValue] = useDependantState(
    () => props.value,
    [props.value]
  )
  const textRef = useRef<HTMLLabelElement>(null)
  const hasSavedViewsAsTabs = useHasTreatment('saved-views-as-tabs')

  const debouncedCallback = useDebouncedCallback(props.onSearch, 400)

  useShortcut(
    'mod+f',
    () => {
      setIsFocused(true)
      textRef.current?.focus()
    },
    { enabled: !hasVisibleModals }
  )

  const isOpened = isFocused || !!searchValue

  if (hasSavedViewsAsTabs) {
    return (
      <div
        className={twMerge(
          'transition-all flex items-center',
          isOpened ? 'w-[180px]' : 'w-[80px]'
        )}
      >
        <TextField
          ref={textRef}
          label={props.placeholder}
          labelHidden
          fullWidth
          showClearButton
          sentiment={searchValue ? 'active' : 'default'}
          variant='minimal'
          prefix={<SearchSolid />}
          placeholder={props.placeholder}
          value={searchValue}
          onChange={(searchValue) => {
            setSearchValue(searchValue)
            debouncedCallback(searchValue)
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          size='small'
        />
      </div>
    )
  }

  return (
    <div
      className={twMerge(
        'w-6 h-[26px] transition-all flex items-center',
        isOpened && 'w-[180px]'
      )}
    >
      {isOpened ? (
        <TextField
          ref={textRef}
          showClearButton
          sentiment={searchValue ? 'active' : 'default'}
          prefix={<SearchSolid />}
          placeholder={props.placeholder}
          value={searchValue}
          onChange={(searchValue) => {
            setSearchValue(searchValue)
            debouncedCallback(searchValue)
          }}
          onBlur={() => setIsFocused(false)}
          autoFocus
          size='small'
        />
      ) : (
        <IconButton
          icon={SearchSolid}
          onClick={() => setIsFocused(true)}
          size='small'
          variant='outlined'
          sentiment='neutral'
        />
      )}
    </div>
  )
}
