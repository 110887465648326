import { ChevronDownSolid, XSolid } from '@motion/icons'
import { Portal } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'

// eslint-disable-next-line no-restricted-imports
import { Combobox as Base, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'

import { TextButton } from '../Button/TextButton/TextButton'
import {
  chevronClasses,
  dropdownContainerClasses,
  dropdownContainerColorClasses,
  generalDropdownItemClasses,
  inputContainerClasses,
  inputContainerDisabledClasses,
  inputDisabledClasses,
  inputErrorClasses,
  inputFocusClasses,
  inputIconColorClasses,
  inputIconSizeClasses,
  inputMutedContainerClasses,
  inputPlaceholderClasses,
  inputTextClasses,
  inputUnselectedClasses,
  selectedDropdownItemClasses,
} from '../GeneralComponentStyles'
import { LoadingSvg } from '../Icons/LoadingSvg'
import { type SelectOption } from '../Select/Select'
import { SubParagraph } from '../Text'

export type ComboboxProps = {
  options: SelectOption[]
  value?: string | string[] | number | number[] | null
  disabled?: boolean
  hasError?: boolean
  placeholder?: string
  onChange?: (value: any) => void
  className?: string
  /**
   * Control whether the component allows multiple values to be selected. If
   * this is true, you must provide a value that is an array.
   */
  multiple?: boolean
  muted?: boolean
  /**
   * Allows searching of option label values
   */
  autoComplete?: boolean
  /**
   * Called in conjunction with autoComplete
   */
  onQuery?: (query: string) => void
  noResultsText?: string
  allowSelectQuery?: boolean
  onFocus?: () => void
  onBlur?: () => void
  onKeyPress?: (e: any) => void
  inputContainerClassName?: string
  showArrow?: boolean
  allowEmptyString?: boolean
  clearable?: boolean
  /**
   * Show loading symbol and do not allow selecting while loading
   */
  isLoadingOptions?: boolean
  /**
   * Controls the alignment of the dropdown
   */
  dropdownAlign?: 'left' | 'right'
  /**
   * Control the width of the dropdown. When set to 'normal' (the default value),
   * the dropdown will be the same width as the control. Setting to 'large' will
   * fix the dropdown width.
   */
  dropdownWidth?: 'normal' | 'large'
  /**
   * Optional icon to display in the input box
   */
  icon?: React.ElementType
  /**
   * Optional, wether to render the value directly in the input instead of the label
   * Useful when the provided value might not be in the list but you still want to display it.
   */
  renderValueToInput?: boolean
}

/**
 * @deprecated use `PopoverTrigger` with a `SearchableList`
 */
export function Combobox(props: ComboboxProps) {
  const {
    options,
    value,
    disabled,
    hasError,
    onChange,
    onQuery,
    allowSelectQuery = false,
    noResultsText,
    onFocus = () => void 0,
    onBlur = () => void 0,
    clearable = false,
    showArrow = true,
    allowEmptyString = false,
    isLoadingOptions = false,
    inputContainerClassName = '',
    icon,
    placeholder,
    autoComplete,
    dropdownAlign,
    dropdownWidth,
    multiple,
    muted = false,
    className,
    renderValueToInput,
  } = props
  const [query, setQuery] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([])
  const [selectedQuery, setSelectedQuery] = useState<string>('')
  const Icon = icon

  const popperElRef = useRef(null)
  const [targetElement, setTargetElement] = React.useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = React.useState(null)
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'left'],
        },
      },
    ],
  })

  // Filter results based on input text provided by user
  useEffect(
    function filterOptions() {
      setFilteredOptions(
        query.length > 0
          ? options.filter((option) =>
              option.label.toLowerCase().includes(query.toLowerCase())
            )
          : options
      )
    },
    [options, query]
  )

  const renderDisplayValue = (
    value: ComboboxProps['value'],
    usePlaceholder = false
  ) => {
    let valueArray: ComboboxProps['value'][] = []
    if (Array.isArray(value)) {
      valueArray = value
    } else if (value != null && (value !== '' || allowEmptyString)) {
      // Not strict to handle values that are "0"
      valueArray = [value]
    }

    if (!valueArray.length && usePlaceholder) {
      return placeholder ?? ''
    }

    if (
      allowSelectQuery &&
      selectedQuery.trim().length > 0 &&
      !options.map((option) => option.value).includes(selectedQuery)
    ) {
      return selectedQuery
    }

    if (renderValueToInput) {
      return valueArray.join(', ')
    }

    return options
      .filter((option) => valueArray.includes(option.value))
      .map((option) => option.label)
      .join(', ')
  }

  useEffect(
    function setSelectedQueryValue() {
      if (
        allowSelectQuery &&
        value != null &&
        typeof value === 'string' &&
        !options.map((option) => option.label).includes(value)
      ) {
        setSelectedQuery(value as string)
      } else {
        setSelectedQuery('')
      }
    },
    // TODO - investigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const popperStyle = {
    ...styles.popper,
    opacity: popperElement && styles.popper.transform ? 1 : 0,
  }

  const valueIcon =
    !Array.isArray(value) &&
    options.find((option) => option.value === value)?.labelIcon
  return (
    <div className={twMerge('group w-full max-w-sm', className)}>
      <Base
        value={value}
        onChange={onChange}
        disabled={disabled}
        // @ts-expect-error property multiple is type 'true' which seems wrong.
        multiple={multiple}
      >
        {({ open }) => (
          <div className='relative'>
            <Base.Button
              as='div'
              ref={setTargetElement}
              className={twMerge(
                muted && 'border-transparent',
                muted ? inputMutedContainerClasses : inputContainerClasses,
                inputFocusClasses,
                'relative h-8 w-full cursor-pointer rounded border py-1.5 px-2 text-left text-sm leading-5',
                showArrow && 'pr-8',
                disabled && inputContainerDisabledClasses,
                hasError && inputErrorClasses,
                inputContainerClassName
              )}
            >
              {open && autoComplete && (
                <Base.Input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  className={twMerge(
                    'relative flex w-full cursor-pointer bg-inherit focus:outline-none',
                    inputTextClasses,
                    inputPlaceholderClasses,
                    disabled && inputDisabledClasses
                  )}
                  onChange={(event) => {
                    if (onQuery) {
                      onQuery(event.target.value)
                    }

                    setQuery(event.target.value)

                    if (allowSelectQuery) {
                      setSelectedQuery(event.target.value)
                    }
                  }}
                  displayValue={renderDisplayValue}
                  placeholder={placeholder}
                />
              )}
              {(!open || !autoComplete) && (
                <div className='flex items-center gap-2'>
                  {Icon && (
                    <Icon
                      className={twMerge(
                        'mt-1',
                        inputIconColorClasses,
                        inputIconSizeClasses
                      )}
                    />
                  )}
                  <Base.Label
                    title={renderDisplayValue(value)}
                    className={twMerge(
                      'flex w-full cursor-pointer items-center gap-2 truncate',
                      value ? inputTextClasses : inputUnselectedClasses,
                      disabled && inputContainerDisabledClasses
                    )}
                  >
                    {valueIcon && (
                      <div
                        className={twMerge(
                          inputIconSizeClasses,
                          inputIconColorClasses
                        )}
                      >
                        {valueIcon}
                      </div>
                    )}
                    {renderDisplayValue(value, true)}
                  </Base.Label>
                </div>
              )}
              {showArrow && !isLoadingOptions && (
                <span
                  className={twMerge(
                    'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2',
                    clearable && value ? 'group-hover:hidden' : ''
                  )}
                >
                  <ChevronDownSolid
                    className={chevronClasses}
                    aria-hidden='true'
                  />
                </span>
              )}
              {!disabled && clearable && value && !isLoadingOptions && (
                <span className='absolute inset-y-0 right-0 hidden items-center pr-2 group-hover:flex'>
                  <TextButton
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      onChange && onChange(null)
                    }}
                    icon={XSolid}
                  />
                </span>
              )}
              {isLoadingOptions && (
                <span className='dark:text-primary-200 text-primary-300 absolute inset-y-0 right-0 flex items-center pr-2'>
                  <LoadingSvg />
                </span>
              )}
            </Base.Button>
            <Portal>
              <div
                className='z-20'
                ref={popperElRef}
                style={popperStyle}
                {...attributes.popper}
              >
                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                  afterLeave={() => {
                    setPopperElement(null)
                    setQuery('')
                  }}
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                >
                  <Base.Options
                    className={twMerge(
                      dropdownContainerClasses,
                      dropdownContainerColorClasses,
                      dropdownAlign === 'right' ? 'right-0' : '',
                      dropdownWidth === 'large' ? 'w-64' : 'w-full'
                    )}
                    style={{
                      width:
                        dropdownWidth === 'large'
                          ? 256
                          : targetElement?.clientWidth,
                      left:
                        dropdownAlign === 'right' && dropdownWidth === 'large'
                          ? (targetElement?.clientWidth || 100) / 2 - 256
                          : -(targetElement?.clientWidth || 100) / 2,
                    }}
                  >
                    {isLoadingOptions && (
                      <Base.Option
                        className={twMerge(
                          'flex items-center justify-center p-4',
                          inputTextClasses
                        )}
                        disabled
                        value={0}
                      >
                        <LoadingSvg />
                      </Base.Option>
                    )}

                    {!isLoadingOptions &&
                      filteredOptions.map((option, optionIdx) => (
                        <Base.Option
                          key={optionIdx}
                          className={({ active }) =>
                            twMerge(
                              generalDropdownItemClasses,
                              active && selectedDropdownItemClasses
                            )
                          }
                          value={option.value}
                        >
                          {({ selected }) => (
                            <div className='flex items-center gap-2'>
                              {multiple && (
                                <Checkbox
                                  checked={selected}
                                  onChange={() => void 0}
                                  label={
                                    option.customRender
                                      ? option.customRender
                                      : option.label
                                  }
                                  labelHidden
                                />
                              )}
                              <span className='flex items-center gap-2 truncate'>
                                {option.labelIcon && (
                                  <span
                                    className={twMerge(
                                      inputIconSizeClasses,
                                      inputIconColorClasses
                                    )}
                                  >
                                    {option.labelIcon}
                                  </span>
                                )}
                                {option.customRender
                                  ? option.customRender
                                  : option.label}
                              </span>
                            </div>
                          )}
                        </Base.Option>
                      ))}

                    {!isLoadingOptions &&
                      allowSelectQuery &&
                      query.trim().length > 0 && (
                        <Base.Option
                          className={({ active }) =>
                            twMerge(
                              generalDropdownItemClasses,
                              active && selectedDropdownItemClasses
                            )
                          }
                          value={query}
                        >
                          {({ selected }) => (
                            <div className='flex items-center gap-2'>
                              {multiple && (
                                <Checkbox
                                  checked={selected}
                                  onChange={() => void 0}
                                  label={query}
                                  labelHidden
                                />
                              )}
                              <span className='flex items-center gap-2 truncate'>
                                {query}
                              </span>
                            </div>
                          )}
                        </Base.Option>
                      )}

                    {noResultsText &&
                      !isLoadingOptions &&
                      ((filteredOptions.length === 0 && !allowSelectQuery) ||
                        (allowSelectQuery &&
                          filteredOptions.length === 0 &&
                          query.length === 0)) && (
                        <Base.Option
                          key={0}
                          className={() =>
                            twMerge(
                              generalDropdownItemClasses,
                              'flex items-center justify-center p-4'
                            )
                          }
                          disabled
                          value='disabled'
                        >
                          <SubParagraph className='text-center'>
                            {noResultsText}
                          </SubParagraph>
                        </Base.Option>
                      )}
                  </Base.Options>
                </Transition>
              </div>
            </Portal>
          </div>
        )}
      </Base>
    </div>
  )
}
