import { type AttachmentGroup } from './types'

export function getParentTarget(groups: AttachmentGroup[]) {
  if (groups.length === 1) {
    const group = groups[0]

    return {
      targetId: group.targetId,
      targetType: group.targetType,
      workspaceId: group.workspaceId,
    }
  }

  const projectGroup = groups.find((group) => group.targetType === 'PROJECT')

  if (projectGroup) {
    return {
      targetId: projectGroup.targetId,
      targetType: projectGroup.targetType,
      workspaceId: projectGroup.workspaceId,
    }
  }

  return {
    targetId: undefined,
    targetType: undefined,
    workspaceId: undefined,
  }
}
