/* eslint-disable react-refresh/only-export-components */
import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import {
  useMyUserSettings,
  useUpdateFolderSettings,
} from '@motion/web-common/settings'

import { useSidebarSearchContext } from '~/areas/search/hook'
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  HIGHLIGHTABLE_DATA_ATTRIBUTE,
  HIGHLIGHTABLE_DATASET_ATTRIBUTE,
} from '../constants'
import type { GlobalSidebarState } from '../hooks'

type FolderState = {
  expanded: boolean
}

type GlobalSidebarContext = {
  openedPanel: keyof GlobalSidebarState['sections'] | null
  setOpenedPanel: (panel: keyof GlobalSidebarState['sections'] | null) => void
  isInitialLoading: boolean
  folderState: Record<string, FolderState>
  updateFolderState: (data: FolderState & { folderId: string }) => void
  highlightedId: string | null
  updateHighlightedByDirection: (direction: 1 | -1) => void
  selectHighlighted: () => void
  clearHighlighted: () => void
}

export const GlobalSidebarContext = createContext<GlobalSidebarContext>({
  openedPanel: null,
  setOpenedPanel: () => void 0,
  isInitialLoading: true,
  folderState: {},
  updateFolderState: () => void 0,
  highlightedId: null,
  updateHighlightedByDirection: () => void 0,
  selectHighlighted: () => void 0,
  clearHighlighted: () => void 0,
})

export const GlobalSidebarContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { searchQuery } = useSidebarSearchContext()
  const { data: mySettings, isInitialLoading } = useMyUserSettings(undefined, {
    meta: { source: 'GlobalContextProvider' },
  })
  const { mutate: mutateFolderState } = useUpdateFolderSettings()

  const containerRef = useRef<HTMLDivElement | null>(null)
  const highlightedIndex = useRef(-1)

  const [openedPanel, setOpenedPanel] =
    useState<GlobalSidebarContext['openedPanel']>(null)
  const [highlightedId, setHighlightedId] = useState<string | null>(null)

  const [folderState, setOptimisticFolderState] = useDependantState<
    GlobalSidebarContext['folderState']
  >(() => mySettings?.folderState ?? {}, [mySettings?.folderState])

  const commitOptimisticUpdates = useDebouncedCallback(
    () => {
      Object.keys(folderState).forEach((folderId) => {
        const currentState = mySettings?.folderState[folderId]
        const optimisticState = folderState[folderId]

        if (
          !currentState ||
          (optimisticState &&
            optimisticState.expanded !== currentState.expanded)
        ) {
          mutateFolderState({ folderId, ...optimisticState })
        }
      })
    },
    500,
    {
      trailing: true,
    }
  )

  const updateFolderState = useCallback<
    GlobalSidebarContext['updateFolderState']
  >(
    ({ folderId, ...data }) => {
      setOptimisticFolderState((prev) => ({
        ...prev,
        [folderId]: data,
      }))

      commitOptimisticUpdates()
    },
    [commitOptimisticUpdates, setOptimisticFolderState]
  )

  const highlightIndex = useCallback(
    (newIndex: number) =>
      requestAnimationFrame(() => {
        if (!containerRef.current) return

        const items = containerRef.current.querySelectorAll<HTMLElement>(
          `[${HIGHLIGHTABLE_DATA_ATTRIBUTE}]`
        )

        if (newIndex < 0 || newIndex >= items.length) return

        highlightedIndex.current = newIndex

        setHighlightedId(
          items[newIndex].dataset[HIGHLIGHTABLE_DATASET_ATTRIBUTE] ?? null
        )
      }),
    []
  )

  const updateHighlightedByDirection = useCallback(
    (direction: -1 | 1) => {
      highlightIndex(highlightedIndex.current + direction)
    },
    [highlightIndex]
  )

  const clearHighlighted = useCallback(() => {
    setHighlightedId(null)
    highlightedIndex.current = -1
  }, [])

  function selectHighlighted() {
    if (!containerRef.current) return

    const items = containerRef.current.querySelectorAll<HTMLElement>(
      `[${HIGHLIGHTABLE_DATA_ATTRIBUTE}]`
    )

    const activeItem = items[highlightedIndex.current]

    if (!activeItem) return

    if (activeItem.matches('a')) {
      activeItem.click()
    } else {
      const link = activeItem.querySelector('a')

      if (link) {
        link.click()
      }
    }

    setHighlightedId(null)
  }

  const value = useMemo(
    () => ({
      clearHighlighted,
      folderState,
      highlightedId,
      isInitialLoading,
      openedPanel,
      selectHighlighted,
      setOpenedPanel,
      updateFolderState,
      updateHighlightedByDirection,
    }),
    [
      clearHighlighted,
      folderState,
      highlightedId,
      isInitialLoading,
      openedPanel,
      updateFolderState,
      updateHighlightedByDirection,
    ]
  )

  useEffect(() => {
    if (searchQuery) {
      highlightIndex(0)
    } else {
      clearHighlighted()
    }
  }, [clearHighlighted, highlightIndex, searchQuery])

  return (
    <GlobalSidebarContext.Provider value={value}>
      <div ref={containerRef} className='contents'>
        {children}
      </div>
    </GlobalSidebarContext.Provider>
  )
}
