import { getCacheEntryValue, MotionCache } from '@motion/rpc-cache'
import { replaceByField } from '@motion/utils/array'

import { type QueryClient } from '@tanstack/react-query'
import { getProjectQueryFilters } from '~/global/cache/models/project'

import { type ExtractSocketEvent } from '../listeners/types'

export function cacheUpdateOnStageUpdate(
  client: QueryClient,
  stagePayload: ExtractSocketEvent<'workspace.stage.update'>['data']['stage']
): boolean {
  const { id, projectId, canceledTime, completedTime, dueDate } = stagePayload
  const stages = getCacheEntryValue(client, 'projects', projectId)?.stages
  if (!stages) {
    return false
  }

  const matchingStage = stages.find((s) => s.id === id)
  if (!matchingStage) {
    return false
  }

  const newStages = replaceByField(stages, {
    ...matchingStage,
    canceledTime,
    completedTime,
    dueDate,
  })

  MotionCache.patch(client, getProjectQueryFilters(projectId), 'projects', {
    [projectId]: {
      stages: newStages,
    },
  })

  return true
}
