import { FieldButton, type FieldButtonProps } from '@motion/ui/base'

import { StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { StatusDropdown } from '../../../components'
import { useProjectForm } from '../hooks'

export type FormStatusDropdownProps = Pick<
  FieldButtonProps,
  'variant' | 'size' | 'fullWidth'
>

export const FormStatusDropdown = ({
  ...buttonProps
}: FormStatusDropdownProps) => {
  const { form } = useProjectForm()

  const { control, watch } = form

  const { field } = useController({
    name: 'statusId',
    control,
  })

  const selectedStatusId = watch('statusId')
  const selectedStatus = useWorkspaceStatusById(selectedStatusId)
  const workspaceId = watch('workspaceId')

  return (
    <StatusDropdown
      selectedStatusId={selectedStatusId}
      onChange={(statusId) => {
        field.onChange(statusId)
      }}
      workspaceId={workspaceId}
    >
      <FieldButton {...buttonProps}>
        <StatusBadge
          value={selectedStatus}
          size={buttonProps.size}
          hideTooltip
        />
        {selectedStatus.name}
      </FieldButton>
    </StatusDropdown>
  )
}
