import {
  createStateKey,
  type SharedStateSetterValue,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'

import { useCallback } from 'react'

type DesktopState = {
  desktopVersion?: string
}
export const DesktopStateKey = createStateKey<DesktopState>('desktopState', {
  defaultValue: {
    desktopVersion: undefined,
  },
})

export const useSendDesktopState = () => {
  const setState = useSharedStateSendOnly(DesktopStateKey)
  return useCallback(
    (changes: Partial<DesktopState> | SharedStateSetterValue<DesktopState>) => {
      if (typeof changes === 'function') {
        setState(changes)
        return
      }
      setState((prev) => ({ ...prev, ...changes }))
    },
    [setState]
  )
}

export const useDesktopState = () => useSharedState(DesktopStateKey)
