import { useTeamMembers } from '~/global/hooks/team'
import { useTeamMateBusyEvents } from '~/global/rpc'
import { selectMainEmailAccount } from '~/state/email-accounts/email-accounts-slice'
import { useAppSelector } from '~/state/hooks'

import { useViewState } from '../../view-state'
import { ONE_HOUR } from '../shared-constants'

export function useTeamMateOOOEvents() {
  const mainEmailAccount = useAppSelector(selectMainEmailAccount)
  const [viewState] = useViewState()
  const teamMembers = useTeamMembers()
  const showOOOEvents =
    viewState.showOOOEventsByAssignee &&
    teamMembers.length > 0 &&
    !!mainEmailAccount

  const { data: teammateOOOEvents } = useTeamMateBusyEvents(
    {
      userIds: teamMembers.map((teammate) => teammate.user.id),
    },
    {
      enabled: showOOOEvents,
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
    }
  )

  return {
    teammateOOOEvents,
    showOOOEvents: showOOOEvents && teammateOOOEvents != null,
  }
}
