/* eslint-disable react-refresh/only-export-components */
import React, { useMemo } from 'react'

export type SidebarTasksContext = {
  isProjectModal?: boolean
  currentTaskId: string | undefined
  projectId: string
  workspaceId: string
  enableInlineAdd: boolean
}

export const SidebarTasksContext =
  React.createContext<SidebarTasksContext | null>(null)

export const SidebarTasksProvider = ({
  children,
  ...props
}: SidebarTasksContext & { children: React.ReactNode }) => {
  const value = useMemo(() => props, [props])

  return (
    <SidebarTasksContext.Provider value={value}>
      {children}
    </SidebarTasksContext.Provider>
  )
}
