import { type TaskSchema } from '@motion/rpc-types'
import { type VariantProps } from '@motion/theme'
import { getEtaLabel, getTaskNoEtaReason } from '@motion/ui-logic'

import { useExtendedNoneEta } from '~/areas/eta/hooks'
import { useWorkspaceFns } from '~/global/hooks'

import { type RibbonContainer } from '../styled'

export const useGetSentimentFromDeadlineStatus = (
  task: TaskSchema
): [
  sentiment: VariantProps<typeof RibbonContainer>['sentiment'] | null,
  label: string | null,
] => {
  const { getWorkspaceStatuses } = useWorkspaceFns()
  const deadlineStatus = useExtendedNoneEta({ entity: task, type: 'task' })

  // We want only the generic label of 'Scheduled past due' for the ribbon, no task-specific labels
  switch (deadlineStatus) {
    case 'missed-deadline':
      return ['error', getEtaLabel(deadlineStatus)]
    case 'scheduled-past-deadline':
      return ['warning', getEtaLabel(deadlineStatus)]
    case 'on-track':
      return ['success', getEtaLabel(deadlineStatus)]
    case 'cancelled':
    case 'completed':
    case 'none': {
      const workspaceStatuses = getWorkspaceStatuses(task.workspaceId)
      return [
        deadlineStatus === 'none' ? 'info' : deadlineStatus,
        getTaskNoEtaReason(task, workspaceStatuses),
      ]
    }
    default:
      return [null, null]
  }
}
