import {
  ArrowRightSolid,
  QuestionMarkCircleSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { wrapVariableInDelimiters } from '@motion/shared/flows'
import { getSchedulingTaskTitle } from '@motion/shared/meetings'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { CalendarEvent, CalendarTask } from '@motion/ui/calendar'

import { StatusBadge } from '~/global/components/badges'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { useFlowTemplateForm } from '../../../hooks'
import { useStageTaskField } from '../fields'

type StageEventDetailsSectionProps = {
  taskBasePath: `stages.${number}.tasks.${number}`
}

const CONST_START_TIME = DateTime.fromISO('2022-01-01T09:00:00.000Z')
const CONST_END_TIME = CONST_START_TIME.plus({ hour: 1 })
const DEFAULT_EVENT_NAME = 'Event name'

const MEETINGS_IN_PROJECT_TOOLTIP = (
  <p>
    A task to schedule the event is created. <br /> Once task is completed and
    the event is created, the event is added to the project
  </p>
)

export function StageEventDetailsSection({
  taskBasePath,
}: StageEventDetailsSectionProps) {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const [name] = useStageTaskField(`${taskBasePath}.name`)
  const textVariables = watch('textVariables')

  const nameWithVariables = useMemo(() => {
    if (!name) {
      return DEFAULT_EVENT_NAME
    }

    return textVariables.reduce((acc, variable) => {
      return acc.replace(wrapVariableInDelimiters(variable.key), variable.name)
    }, name)
  }, [name, textVariables])

  return (
    <DetailsSection>
      <div className='flex items-center gap-1'>
        <p className='text-2xs text-semantic-neutral-text-subtle font-semibold'>
          How it works
        </p>
        <Tooltip asChild renderContent={() => MEETINGS_IN_PROJECT_TOOLTIP}>
          <QuestionMarkCircleSolid className='size-3 text-semantic-neutral-icon-subtle' />
        </Tooltip>
      </div>
      <div className='flex gap-1 h-9'>
        <CalendarSlot>
          <CalendarTask
            name={getSchedulingTaskTitle(nameWithVariables)}
            duration={60}
            hideTime
            startTime={CONST_START_TIME}
            endTime={CONST_END_TIME}
            renderNameTooltipContent={() =>
              getSchedulingTaskTitle(nameWithVariables)
            }
            renderStatus={() => (
              <StatusBadge
                value={{
                  name: 'Test',
                  type: 'DEFAULT',
                  color: '#A1A1A1',
                }}
                hideTooltip
                size='xsmall'
                taskVariant='scheduling'
              />
            )}
            colorOption='blue'
          />
        </CalendarSlot>

        <Tooltip asChild renderContent={() => MEETINGS_IN_PROJECT_TOOLTIP}>
          <div className='self-center flex items-center '>
            <div className='border-t w-1.5 border-semantic-neutral-border-hover' />
            <StatusCompletedSolid className='size-3' />
            <ArrowRightSolid className='size-2 text-semantic-neutral-border-hover -ml-px' />
          </div>
        </Tooltip>

        <CalendarSlot>
          <CalendarEvent
            name={nameWithVariables}
            hideTime
            startTime={CONST_START_TIME}
            endTime={CONST_END_TIME}
            colorId='0'
          />
        </CalendarSlot>
      </div>
    </DetailsSection>
  )
}

const DetailsSection = classed('div', {
  base: `
    bg-semantic-neutral-surface-raised-bg-subtlest
    border border-t-0 border-semantic-neutral-border-light
    p-4
    rounded-b-[4px]
    mx-1 -mt-px -z-10
    flex flex-col gap-3
    shadow-sm
  `,
})

const CalendarSlot = classed('div', {
  base: `
    flex-1 min-w-0
  `,
})
