import {
  type ProjectSchema,
  type StageSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import { classed } from '@motion/theme'
import {
  getProjectEtaReason,
  getProjectEtaTitle,
  getStageEtaReason,
  getStageEtaTitle,
  getTaskEtaReason,
  getTaskEtaTitle,
  normalizeProjectDeadlineStatus,
  normalizeStageDeadlineStatus,
  normalizeTaskDeadlineStatus,
  templateStr,
} from '@motion/ui-logic'

import { EtaBadge } from '~/global/components/badges'
import { ConnectedStageLabel } from '~/global/components/labels'
import { useI18N } from '~/global/contexts'
import { useMemo } from 'react'

type EtaBannerProps =
  | {
      entity: TaskSchema
      type: 'task'
    }
  | {
      entity: StageSchema
      type: 'stage'
    }
  | {
      entity: ProjectSchema
      type: 'project'
    }

export const EtaBanner = ({ entity, type }: EtaBannerProps) => {
  const { pluralize } = useI18N()

  const { deadlineStatus, bannerTitle, reason } = useMemo(() => {
    switch (type) {
      case 'task':
        return {
          deadlineStatus: normalizeTaskDeadlineStatus(entity),
          bannerTitle: getTaskEtaTitle(entity),
          reason: getTaskEtaReason(entity, pluralize),
        }
      case 'stage':
        return {
          deadlineStatus: normalizeStageDeadlineStatus(entity, {
            normalizeToAtRisk: true,
          }),
          bannerTitle: templateStr('{{stageLabel}} {{stageEtaTitle}}', {
            stageLabel: (
              <div className='max-w-[130px]'>
                <ConnectedStageLabel
                  size='small'
                  id={entity.stageDefinitionId}
                />
              </div>
            ),
            stageEtaTitle: getStageEtaTitle(entity).toLowerCase(),
          }),
          reason: getStageEtaReason(entity, pluralize),
        }
      case 'project':
        return {
          deadlineStatus: normalizeProjectDeadlineStatus(entity),
          bannerTitle: getProjectEtaTitle(entity, pluralize),
          reason: getProjectEtaReason(entity, pluralize),
        }
    }
  }, [entity, type, pluralize])

  return (
    <EtaBannerContainer etaState={deadlineStatus}>
      <div className='self-start'>
        <EtaBadge value={deadlineStatus} />
      </div>
      <EtaBannerTitleContainer>
        <EtaBannerTitle>{bannerTitle}</EtaBannerTitle>
        <EtaBannerSubtitle>{reason}</EtaBannerSubtitle>
      </EtaBannerTitleContainer>
    </EtaBannerContainer>
  )
}

export const EtaBannerContainer = classed('div', {
  base: `
    p-3 flex items-start gap-2 text-semantic-neutral-text-default
  `,
  variants: {
    etaState: {
      'ahead-of-schedule': `bg-semantic-blue-bg-default`,
      'missed-deadline': `bg-semantic-error-bg-default`,
      'scheduled-past-deadline': `bg-semantic-orange-bg-default`,
      'at-risk': `bg-semantic-warning-bg-default`,
      'on-track': `bg-dropdown-bg`,
      none: `bg-dropdown-bg`,
    },
  },
  defaultVariants: {
    etaState: 'none',
  },
})

export const EtaBannerTitleContainer = classed('div', {
  base: 'flex-col flex w-full gap-2 text-inherit',
})

export const EtaBannerTitle = classed('div', {
  base: 'text-sm text-inherit font-semibold flex items-center gap-0.5',
})

export const EtaBannerSubtitle = classed('p', {
  base: 'text-2xs',
})
