import { InformationCircleSolid } from '@motion/icons'

import { useEffect, useState } from 'react'

import {
  useOnDesktopEvent,
  useSendToDesktop,
} from '../../../localServices/desktop'
import {
  Paragraph,
  PrimaryButton,
  SubParagraph,
  TextButton,
} from '../../Common'

interface DesktopUpdateAvailableProps {
  open?: boolean
  setOpen?: (open: boolean) => void
}

interface DesktopUpdateAvailableContentProps {
  downloadUpdate: () => void
  remindLater: () => void
}

export const DesktopUpdateAvailable = ({
  open,
  setOpen = () => {},
}: DesktopUpdateAvailableProps) => {
  const sendToDesktop = useSendToDesktop()

  useOnDesktopEvent('updateAppAvailable', () => setOpen(true))

  if (!open) return null
  return (
    <DesktopUpdateAvailableContent
      remindLater={() => {
        setOpen(false)
        sendToDesktop('updateAppLater')
      }}
      downloadUpdate={() => {
        sendToDesktop('updateAppNow')
      }}
    />
  )
}

export const DesktopUpdateAvailableContent = ({
  downloadUpdate = () => {},
  remindLater = () => {},
}: DesktopUpdateAvailableContentProps) => {
  const [isUpdateDownloaded, setIsUpdateDownloaded] = useState(false)
  const sendToDesktop = useSendToDesktop()

  useEffect(() => {
    // For users on older versions of the desktop app, we need to request the update status (changed in 0.24.0 to requestUpdateDownloaded)
    sendToDesktop('requestUpdateStatus')
    // Do not let user restart to update until we confirm update is downloaded (and not just available)
    sendToDesktop('requestUpdateDownloaded')
  }, [sendToDesktop])

  useOnDesktopEvent('updateAppDownloaded', () => {
    setIsUpdateDownloaded(true)
  })

  return (
    <div className='dark:bg-dark-1000 bg-light-100 dark:border-dark-900 border-light-500 flex w-[323px] flex-col gap-4 rounded-lg border p-4 drop-shadow'>
      <div className='bg-light-300 dark:bg-dark-900 h-9 w-9 rounded-lg p-2.5'>
        <InformationCircleSolid className='dark:text-dark-300 text-light-1100 text-base h-4 w-4' />
      </div>
      <div className='flex flex-col gap-2'>
        <Paragraph className='font-semibold'>New update available!</Paragraph>
        <SubParagraph className='text-base'>
          We’ve just released an update to improve your experience with Motion.
        </SubParagraph>
      </div>
      <div className='flex w-full gap-2'>
        <PrimaryButton
          className='w-full'
          loading={!isUpdateDownloaded}
          onClick={() => {
            setIsUpdateDownloaded(false)
            downloadUpdate()
          }}
        >
          Restart to Update
        </PrimaryButton>
        <TextButton className='w-full' onClick={remindLater}>
          Remind me later
        </TextButton>
      </div>
    </div>
  )
}
