import { classed } from '@motion/theme'

import { ErrorPage } from '~/global/components/error-page'
import { ActiveFilterItemsWorkspaceContext } from '~/global/contexts/active-filter-items-workspace-context'

import { SelectContents } from './select-contents'
import { SelectHeader } from './select-header'

export const ViewShell = () => {
  return (
    <ActiveFilterItemsWorkspaceContext>
      <Container>
        <SelectHeader />
        {/* View Tabs */}
        <ErrorPage>
          <SelectContents />
        </ErrorPage>
      </Container>
    </ActiveFilterItemsWorkspaceContext>
  )
}

const Container = classed('div', {
  base: `
  grid grid-rows-[auto_1fr]
  w-full overflow-hidden
  bg-calendar-bg-default
  isolate
  relative
  `,
})
