import { usePrevious } from '@motion/react-core/hooks'
import { type PMTaskType, type RecurringTask } from '@motion/rpc/types'
import { findDefaultStatus } from '@motion/shared/common'
import { DEFAULT_DURATION, NO_CHUNK_DURATION } from '@motion/ui-logic'
import {
  DEFAULT_SCHEDULE_ID,
  getLegacyTaskFormChangedFields,
  type TaskFormChangedFieldOptions,
} from '@motion/ui-logic/pm/task'
import { pick } from '@motion/utils/object'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useCustomFieldsFns } from '~/areas/custom-fields/hooks'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useSearchParams } from '~/routing'
import { type ReactNode, useEffect, useMemo } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useParams } from 'react-router'

import { useFormUpdateSchedule, useTemplateTask } from './hooks'
import { TemplateTaskFormHandlers } from './template-task-form-handlers'
import { TemplateTaskFormOptions } from './template-task-form-options'
import { type TemplateTaskFormFields } from './types'
import { useTemplateTaskFormOptions } from './use-template-task-form-options'
import { convertToUpdateTeamTaskSchema } from './utils'

import { useWorkspaceFns, useWorkspaceStatuses } from '../../../global/hooks'

export type TemplateTaskFormProps = {
  children: ReactNode
}

export const TemplateTaskForm = ({ children }: TemplateTaskFormProps) => {
  const initialFormData = useInitialFormData()
  const form = useForm({
    defaultValues: initialFormData,
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  })

  const { isDirty } = form.formState

  const previousId = usePrevious(initialFormData.id)
  useEffect(() => {
    if (!isDirty || previousId !== initialFormData.id) {
      form.reset(initialFormData)
    }
  }, [form, initialFormData, isDirty, previousId])

  return (
    <FormProvider {...form}>
      <TemplateTaskFormOptions>
        <TemplateTaskFormHandlers>
          <TaskFormValuesUpdater />
          {children}
        </TemplateTaskFormHandlers>
      </TemplateTaskFormOptions>
    </FormProvider>
  )
}

const taskFieldsToFormField: Partial<
  Record<keyof (PMTaskType & RecurringTask), keyof TemplateTaskFormFields>
> = {
  startingOn: 'startDate',
  assigneeUserId: 'assigneeId',
  dueDate: 'deadlineDate',
  minimumDuration: 'minChunkDuration',
}

const formFieldsToTaskFields: Partial<
  Record<
    keyof TemplateTaskFormFields,
    TaskFormChangedFieldOptions['fieldNameBeingUpdated']
  >
> = {
  deadlineDate: 'dueDate',
  assigneeId: 'assigneeUserId',
}

const TaskFormValuesUpdater = () => {
  const form = useFormContext<TemplateTaskFormFields>()
  const options = useTemplateTaskFormOptions()
  const {
    getWorkspaceById,
    getWorkspaceStatuses,
    getWorkspaceActiveMembers,
    getWorkspaceMembers,
    getWorkspaceProjects,
  } = useWorkspaceFns()
  const { getCustomFields } = useCustomFieldsFns()

  const { currentUserId, selectedWorkspace } = options

  useFormUpdateSchedule(form, options)

  // Listen for the form and apply any derived values needed
  useEffect(() => {
    const subscription = form.watch((formValues, { name }) => {
      if (name == null || !selectedWorkspace) return

      const { assigneeId, workspaceId } = formValues

      if (
        name === 'isAutoScheduled' ||
        name === 'startDate' ||
        name === 'deadlineDate' ||
        name === 'assigneeId' ||
        name === 'statusId' ||
        name === 'duration'
      ) {
        const taskCandidate = convertToUpdateTeamTaskSchema(
          formValues as TemplateTaskFormFields
        )
        const changedFields = getLegacyTaskFormChangedFields(
          taskCandidate as PMTaskType | RecurringTask,
          {
            fieldNameBeingUpdated: (formFieldsToTaskFields[name] ??
              name) as TaskFormChangedFieldOptions['fieldNameBeingUpdated'],
            currentUserId: currentUserId ?? '',
            statuses: getWorkspaceStatuses(workspaceId ?? ''),
            projects: getWorkspaceProjects(workspaceId ?? ''),
            members: getWorkspaceActiveMembers(workspaceId ?? ''),
            customFields: getCustomFields(workspaceId ?? ''),
          }
        )

        Object.keys(changedFields).forEach((_key) => {
          const key = _key as keyof typeof changedFields

          const formFieldKey = taskFieldsToFormField[key] ?? key

          if (!(formFieldKey in formValues)) {
            throw new Error(
              `cannot set value of unknown key "${formFieldKey}" in form`
            )
          }

          return form.setValue(
            formFieldKey as keyof TemplateTaskFormFields,
            changedFields[key] as any,
            {
              shouldDirty: true,
            }
          )
        })
      }

      if (name === 'workspaceId') {
        // reset the labels when changing workspace
        form.setValue('labelIds', [], { shouldDirty: true })

        // Check and reset the assignee if needed
        const member = getWorkspaceMembers(workspaceId ?? '').find(
          (m) => m.user.id === assigneeId
        )
        if (!member && workspaceId) {
          const workspace = getWorkspaceById(workspaceId)
          form.setValue(
            'assigneeId',
            workspace?.isPersonalWorkspace ? currentUserId : null,
            { shouldDirty: true }
          )
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [
    currentUserId,
    form,
    selectedWorkspace,
    getWorkspaceStatuses,
    getWorkspaceActiveMembers,
    getCustomFields,
    getWorkspaceProjects,
    getWorkspaceById,
    getWorkspaceMembers,
  ])

  return null
}

function useInitialFormData(): TemplateTaskFormFields {
  const { workspaceId, templateTaskId, templateProjectId } = useParams<{
    workspaceId: string
    projectId: string
    templateTaskId: string
    templateProjectId: string
  }>()

  const { task: taskParam } = useSearchParams<{ task: string }>()

  const { data: fromTask, isInitialLoading: fromTaskLoading } = useTaskByIdV2(
    { id: taskParam ?? '' },
    { enabled: taskParam != null, keepPreviousData: false }
  )

  const finalWorkspaceId = workspaceId

  const templateTaskParamId =
    templateTaskId === 'new' ? undefined : templateTaskId

  const { uid: userId } = useAuthenticatedUser()
  const { templateTask, isLoading: isLoadingTemplateTask } = useTemplateTask(
    {
      id: templateTaskParamId,
      templateProjectId,
      workspaceId: finalWorkspaceId,
    },
    {
      enabled: templateTaskParamId != null && workspaceId != null,
    }
  )
  const workspaceStatuses = useWorkspaceStatuses(workspaceId)

  return useMemo(() => {
    return {
      isLoading: fromTaskLoading || isLoadingTemplateTask,
      templateId: templateTaskParamId,
      templateProjectId: templateProjectId,

      startDate: null,
      deadlineDate: null,

      ...(fromTask != null
        ? {
            ...pick(fromTask, [
              'id',
              'workspaceId',
              'name',
              'statusId',
              'priorityLevel',
              'duration',
              'isAutoScheduled',
            ]),
            scheduleId: fromTask.scheduleId ?? DEFAULT_SCHEDULE_ID,
            description: fromTask.description ?? '',
            assigneeId: fromTask.assigneeUserId,
            projectId: 'projectId' in fromTask ? fromTask.projectId : null,
            minChunkDuration:
              'minimumDuration' in fromTask ? fromTask.minimumDuration : 0,
            labelIds: 'labelIds' in fromTask ? fromTask.labelIds : [],
          }
        : templateTask
          ? {
              id: templateTask.task.id,
              templateName: templateTask.name,
              workspaceId: templateTask.workspaceId ?? '',
              projectId: templateTask.task.projectId ?? null,
              name: templateTask.task.name ?? '',
              description: templateTask.task.description ?? '',
              statusId: templateTask.task.statusId ?? '',
              priorityLevel: templateTask.task.priorityLevel ?? 'MEDIUM',
              duration: templateTask.task.duration ?? DEFAULT_DURATION,
              isAutoScheduled: templateTask.task.isAutoScheduled ?? true,
              minChunkDuration:
                templateTask.task.minimumDuration ?? NO_CHUNK_DURATION,
              scheduleId: templateTask.task.schedule ?? DEFAULT_SCHEDULE_ID,
              assigneeId: templateTask.task.assigneeUserId ?? null,
              labelIds:
                templateTask.task.labels.map((label) => label.labelId) ?? [],
            }
          : {
              id: undefined,
              tempplateName: '',
              workspaceId: workspaceId ?? '',
              projectId: null,
              name: '',
              description: '',
              statusId: findDefaultStatus(workspaceStatuses)?.id ?? '',
              priorityLevel: 'MEDIUM',
              duration: DEFAULT_DURATION,
              isAutoScheduled: true,
              minChunkDuration: NO_CHUNK_DURATION,
              scheduleId: DEFAULT_SCHEDULE_ID,
              assigneeId: userId,
              labelIds: [],
            }),
    }
  }, [
    fromTaskLoading,
    isLoadingTemplateTask,
    templateTaskParamId,
    templateProjectId,
    fromTask,
    templateTask,
    workspaceId,
    workspaceStatuses,
    userId,
  ])
}
