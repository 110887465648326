import { classed } from '@motion/theme'

import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'

export type DayHeaderProps = {
  day: string
  gridRow: number
  gridColumn: number
}

export const DayHeader = (props: DayHeaderProps) => {
  const date = DateTime.fromISO(props.day)
  const isToday = date.hasSame(DateTime.now(), 'day')
  return (
    <div
      className={twMerge(
        'flex items-center justify-center gap-1 text-xs sticky top-0 bg-calendar-bg-default font-medium text-semantic-neutral-text-subtle',
        isToday
          ? 'text-semantic-neutral-text-default'
          : 'text-semantic-neutral-text-subtle'
      )}
      style={{ gridRow: props.gridRow, gridColumn: props.gridColumn }}
    >
      <span>{date.toFormat('ccc')}</span>
      <DayNumber today={isToday}>{date.toFormat('d')}</DayNumber>
    </div>
  )
}

const DayNumber = classed('span', {
  base: '',
  variants: {
    today: {
      true: `
        w-[20px] h-[20px]
        grid items-center justify-center
        rounded
        bg-semantic-primary-bg-strong-default
        text-semantic-primary-text-onDark
      `,
    },
  },
})
