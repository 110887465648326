import { QuestionMarkCircleSolid, TutorialGradientSolid } from '@motion/icons'
import { useOnValueChange } from '@motion/react-core/hooks'
import { classed } from '@motion/theme'
import { Button, ProgressBar } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { OpenLeftSidebarButton } from '~/areas/sidebar/components'
import { TutorialList, TutorialViewer } from '~/areas/tutorials/components'
import {
  useActiveTutorialData,
  useCompletedTutorialPercentage,
} from '~/areas/tutorials/hooks'
import {
  useGetTutorialPayload,
  useGetUserTutorialCompletion,
  useUpdateTutorialCompletion,
} from '~/components/Settings/Pages/tutorials/rpc-hooks'
import { useNavigateByRouteId } from '~/routing'
import { useToggleIntercom } from '~/utils/toggleIntercom'
import { useMemo } from 'react'
import { Navigate, useParams } from 'react-router'

export const TutorialsPage = () => {
  const navigate = useNavigateByRouteId()
  const params = useParams()
  const toggleIntercom = useToggleIntercom()

  const { data: tutorialData } = useGetTutorialPayload()
  const { mutateAsync: updateTutorialCompletion } =
    useUpdateTutorialCompletion()
  const { data: userCompletionData } = useGetUserTutorialCompletion()

  const completedLessonIds = userCompletionData?.completedTutorialIds ?? []
  let activeLessonId = params.lessonId
  const flatLessons =
    useMemo(() => {
      return tutorialData?.sections.flatMap((section) => section.lessons)
    }, [tutorialData?.sections]) ?? []
  const completedPercent = useCompletedTutorialPercentage()

  useOnValueChange(completedPercent, () => {
    if (completedPercent === 100) {
      recordAnalyticsEvent('TUTORIALS_ALL_COMPLETED')
    }
  })

  if (!activeLessonId) {
    activeLessonId =
      flatLessons.find((lesson) => !completedLessonIds.includes(lesson.id))
        ?.id ||
      flatLessons[0]?.id ||
      ''
  }

  const {
    activeLesson,
    activeSessionTitle,
    activeSectionIndex,
    activeLessonIndex,
  } = useActiveTutorialData(tutorialData, activeLessonId)

  function onNextLesson() {
    const currLessonIdx = flatLessons.findIndex(
      (lesson) => lesson.id === activeLessonId
    )
    if (currLessonIdx > -1 && currLessonIdx + 1 < flatLessons.length) {
      navigate('tutorial-lesson', {
        lessonId: flatLessons[currLessonIdx + 1].id,
      })
    }
  }

  function onPrevLesson() {
    const currLessonIdx = flatLessons.findIndex(
      (lesson) => lesson.id === activeLessonId
    )
    if (currLessonIdx > 0) {
      navigate('tutorial-lesson', {
        lessonId: flatLessons[currLessonIdx - 1].id,
      })
    }
  }

  async function onCompleteLesson() {
    await updateTutorialCompletion({ tutorialId: activeLessonId ?? '' })
    recordAnalyticsEvent('TUTORIALS_LESSON_COMPLETED', {
      lessonId: activeLessonId ?? '',
    })
  }

  function onSelectLesson(lessonId: string) {
    navigate('tutorial-lesson', { lessonId })
  }

  if (!tutorialData?.sections.length) {
    return
  }

  return (
    <PageWrapper>
      {!activeLesson && <Navigate to='/tutorials' />}
      <ContentWrapper>
        <Header className='justify-between'>
          <div className='flex flex-row items-center gap-2'>
            <OpenLeftSidebarButton />
            <TutorialGradientSolid className='size-4' />
            Tutorials
          </div>
          <div className='flex flex-row items-center gap-3 text-xs'>
            {completedPercent}% complete
            <div className='w-40'>
              <ProgressBar value={completedPercent} sentiment='gradient' />
            </div>
            <Button sentiment='neutral' size='small' onClick={toggleIntercom}>
              <QuestionMarkCircleSolid /> Help
            </Button>
          </div>
        </Header>
        <div className='grid grid-cols-[320px_1fr] w-full max-w-7xl items-start gap-8 px-10 py-6 mx-auto'>
          <TutorialList
            sections={tutorialData.sections}
            completedLessonIds={completedLessonIds}
            activeSectionIndex={activeSectionIndex}
            activeLessonIndex={activeLessonIndex}
            onSelectLesson={onSelectLesson}
          />
          <TutorialViewer
            lesson={activeLesson}
            sectionTitle={activeSessionTitle}
            completed={completedLessonIds.includes(activeLessonId)}
            onNextLesson={onNextLesson}
            onPrevLesson={onPrevLesson}
            onCompleteLesson={onCompleteLesson}
          />
        </div>
      </ContentWrapper>
    </PageWrapper>
  )
}

const PageWrapper = classed('div', {
  base: 'h-full w-full flex p-0 overflow-y-auto bg-semantic-neutral-bg-disabled group',
})

const ContentWrapper = classed('div', {
  base: 'flex flex-col min-h-full w-full bg-semantic-neutral-surface-bg-default',
})

const Header = classed('div', {
  base: 'py-3 px-6 border-b border-semantic-neutral-border-default flex flex-row items-center gap-1.5',
})
