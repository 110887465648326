import { BookingCreateLinkSidebar } from '~/areas/calendar/booking/components/create-link-sidebar'
import { BookingShell } from '~/areas/calendar/booking/shell'
import { BookingTimeslotCalendarHeader } from '~/areas/calendar/booking/time-slot-calendar/components/calendar-header'
import { TimeSlotCalendar } from '~/areas/calendar/booking/time-slot-calendar/time-slot-calendar'
import { pathForRoute } from '~/routing'
import { setAvailabilityGuests } from '~/state/booking'
import {
  setCalendarVisible,
  setDragTimeslotsVisible,
} from '~/state/calendar/calendarSlice'
import { clearSelectedAvailabilityFullCalendarEvents } from '~/state/calendarEvents/calendarEventsSlice'
import { useAppDispatch } from '~/state/hooks'
import { setBookingContainerVisible } from '~/state/modalsSlice'
import { useNavigate } from 'react-router'

export const BookingCreateLinkPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    dispatch(clearSelectedAvailabilityFullCalendarEvents())
    dispatch(setDragTimeslotsVisible(false))
    dispatch(setCalendarVisible(false))
    dispatch(setBookingContainerVisible(true))
    dispatch(setAvailabilityGuests([]))
  }

  const handleLinkCreation = () => {
    handleClose()
    navigate(pathForRoute('/calendar'))
  }

  return (
    <BookingShell heading='Share Availability' onBackClick={handleClose}>
      <div className='relative z-0 grid grid-cols-[280px_auto] h-full w-full min-h-0'>
        <BookingCreateLinkSidebar onLinkCreated={handleLinkCreation} />

        <div className='flex flex-col border-l border-semantic-neutral-border-subtle bg-calendar-bg-default'>
          <BookingTimeslotCalendarHeader />
          <TimeSlotCalendar />
        </div>
      </div>
    </BookingShell>
  )
}
