import {
  DotsHorizontalSolid,
  DuplicateOutline,
  ExclamationCircleSolid,
  PencilSolid,
  TerminalSolid,
  TrashSolid,
} from '@motion/icons'
import {
  ActionList,
  Button,
  PopoverTrigger,
  showToast,
  Tooltip,
} from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { CopyLinkButton } from '~/areas/calendar/booking/components/booking-links-dropdown/copy-link-button'
import { type BookingLinksDropdownProps } from '~/areas/calendar/booking/components/booking-links-dropdown/index'
import { LinkItem } from '~/areas/calendar/booking/components/booking-links-dropdown/link-item'
import { OneOffLinkButton } from '~/areas/calendar/booking/components/booking-links-dropdown/one-off-link-button'
import { useAllCalendars } from '~/areas/calendar/hooks'
import {
  type BookingLinkTemplate,
  createBookingLinkTemplate,
  deleteBookingLinkTemplate,
  selectBookingSettings,
} from '~/state/booking'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { createFullTemplateUrl, linkIdGenerator } from '~/utils/booking-utils'
import { nanoid } from 'nanoid'
import { useCallback } from 'react'

type TemplateRowProps = {
  template: BookingLinkTemplate
  closeParent: BookingLinksDropdownProps['close']
}

export const TemplateRow = ({ template, closeParent }: TemplateRowProps) => {
  const { id, name, linkSlug, durationChoices, hostCalendarId, hostEmail } =
    template

  const dispatch = useAppDispatch()
  const modalApi = useModalApi()
  const { urlPrefix } = useAppSelector(selectBookingSettings)
  const { calendars, isLoading: calendarsLoading } = useAllCalendars()
  const emailAccount = useAppSelector(selectEmailAccounts).find(
    (e) => e.email === hostEmail
  )

  const hostCalendarMissing =
    !calendars.find(
      (c) =>
        c.providerId === hostCalendarId && c.emailAccountId === emailAccount?.id
    ) && !calendarsLoading

  const fullUrl = createFullTemplateUrl(urlPrefix ?? '', linkSlug)
  const displayUrl = createFullTemplateUrl(urlPrefix ?? '', linkSlug).replace(
    /^https?:\/\//,
    ''
  )

  const buttonProps = {
    id,
    linkSlug,
    durationChoices,
    disabled: hostCalendarMissing,
    closeParent,
  }

  const handleDelete = useCallback(async () => {
    const response = await modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-booking-template',
      },
      destructive: true,
      title: (
        <span>
          Delete booking template &quot;<strong>{name}</strong>&quot;?
        </span>
      ),
      description:
        'Deleting this template would cause previous links you sent out with this template to stop working. Do you still want to delete?',
    })

    if (response === ModalDismissed || !response) return

    void dispatch(deleteBookingLinkTemplate(id))

    showToast('success', 'Link deleted successfully')
  }, [modalApi, name, dispatch, id])

  const handleEmbed = useCallback(() => {
    modalApi.open('embed-booking', {
      link: fullUrl,
    })
  }, [modalApi, fullUrl])

  const handleDuplicate = useCallback(async () => {
    const templateCopy = { ...template }

    templateCopy.id = nanoid(5)
    templateCopy.name = `${templateCopy.name} (copy)`
    templateCopy.linkSlug = linkIdGenerator().toLowerCase()

    void dispatch(createBookingLinkTemplate(templateCopy))

    showToast('success', 'Link duplicated successfully')
  }, [dispatch, template])

  return (
    <LinkItem
      title={
        <span className='inline-flex'>
          {name ?? ''}
          {hostCalendarMissing && <HostCalendarMissingNotice />}
        </span>
      }
      hint={displayUrl}
    >
      <PopoverTrigger
        placement='bottom'
        renderPopover={({ close }) => (
          <ActionList
            sections={[
              {
                items: [
                  {
                    content: 'Edit',
                    prefix: <PencilSolid />,
                    url: `/web/calendar/booking/${linkSlug}`,
                  },
                  {
                    content: 'Embed on website',
                    prefix: <TerminalSolid />,
                    onAction: () => {
                      handleEmbed()
                      closeParent()
                    },
                  },
                  {
                    content: 'Duplicate',
                    prefix: <DuplicateOutline />,
                    onAction: () => {
                      void handleDuplicate()
                      close()
                    },
                  },
                ],
              },
              {
                items: [
                  {
                    content: 'Delete',
                    destructive: true,
                    prefix: <TrashSolid />,
                    onAction: () => {
                      void handleDelete()
                      closeParent()
                    },
                  },
                ],
              },
            ]}
          />
        )}
      >
        <Button size='xsmall' sentiment='neutral' variant='muted' iconOnly>
          <div className='size-4 flex items-center justify-center'>
            <DotsHorizontalSolid />
          </div>
        </Button>
      </PopoverTrigger>

      <OneOffLinkButton {...buttonProps} />

      <CopyLinkButton {...buttonProps} />
    </LinkItem>
  )
}

const HostCalendarMissingNotice = () => (
  <Tooltip
    asChild
    renderContent={() => (
      <>
        <p>This booking link is missing a host calendar.</p>
        <p>Edit this booking link&apos;s settings to specify a host.</p>
      </>
    )}
  >
    <ExclamationCircleSolid className='size-4 ml-1 text-semantic-error-icon-default' />
  </Tooltip>
)
