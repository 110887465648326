import { useUtilizedSubscription } from '~/global/rpc'
import { useMotionLocalStorage } from '~/localServices/storage'

export function useIsNoCardTrialing() {
  const subscription = useUtilizedSubscription()
  const { stripeCustomer } = useMotionLocalStorage(['stripeCustomer'])

  return (
    (subscription &&
      subscription.cancel_at_period_end &&
      subscription.status === 'trialing' &&
      !stripeCustomer?.invoice_settings?.default_payment_method) ??
    false
  )
}
