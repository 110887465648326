import { UserSolid } from '@motion/icons'
import { CalendarPalette } from '@motion/ui/calendar'
import { type ColorId, getColorClassForColorId } from '@motion/ui-logic'
import { type CalendarSchema } from '@motion/zod/client'

type UserCalendarItemProps = {
  calendar: CalendarSchema
}

/**
 * NOTE: There's already a similar component used in the `RightSidebar` component, but it uses a `Calendar` object.
 */
export function UserCalendarItem({ calendar }: UserCalendarItemProps) {
  return (
    <div className='flex items-center gap-2'>
      <CalendarItemIcon colorId={getColorClassForColorId(calendar.colorId)} />
      <span className='text-xs text-semantic-neutral-text-default'>
        {calendar.title}
      </span>
    </div>
  )
}

type CalendarItemIconProps = {
  colorId: ColorId
}

function CalendarItemIcon({ colorId }: CalendarItemIconProps) {
  return (
    <CalendarPalette colorId={colorId}>
      {/* p-0.5 causes misalignment */}
      <div className='bg-calendar-palette-bg-selected rounded-md p-[1.5px] flex items-center justify-center'>
        <UserSolid className='text-form-icon-active' width={14} height={14} />
      </div>
    </CalendarPalette>
  )
}
