import { merge } from '@motion/utils/core'

import {
  defaultSidebarState,
  type SidebarState,
  useSidebarState,
} from '~/global/hooks/sidebars'
import { useCallback, useMemo } from 'react'

export type AgendaSidebarState = SidebarState

const defaultAgendaSidebarState: AgendaSidebarState = {
  ...defaultSidebarState,
}

export const useAgendaSidebarState = () => {
  const [localStorageState, setSidebarState] =
    useSidebarState<AgendaSidebarState>('agenda')

  const sidebarState = useMemo(
    () =>
      merge(
        {},
        defaultAgendaSidebarState,
        localStorageState
      ) as AgendaSidebarState,
    [localStorageState]
  )

  const toggleSidebar = useCallback(
    (newOpenState: boolean = !sidebarState.open) => {
      setSidebarState({
        open: newOpenState,
      })
    },
    [setSidebarState, sidebarState.open]
  )

  return {
    sidebarState,
    setSidebarState,
    toggleSidebar,
  }
}
