import { TagSolid } from '@motion/icons'

import { PrettyLabels } from '~/global/components/labels'
import { useWorkspaceLabels } from '~/global/hooks'

export const InlineLabels = ({
  labelIds,
  workspaceId,
}: {
  labelIds: string[]
  workspaceId: string
}) => {
  const workspaceLabels = useWorkspaceLabels(workspaceId)
  const labels = workspaceLabels.filter((label) => labelIds.includes(label.id))

  return labels.length === 0 ? (
    <div className='flex flex-row items-center gap-1'>
      <TagSolid />
      Labels
    </div>
  ) : (
    <PrettyLabels values={labels} />
  )
}
