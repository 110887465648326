import { type DateTime } from 'luxon'

export function mergeDateWithTime(
  newDate: DateTime,
  oldDateTime: DateTime | null
) {
  if (!oldDateTime) {
    return newDate
  }

  return newDate.set({
    hour: oldDateTime.hour,
    minute: oldDateTime.minute,
    second: oldDateTime.second,
    millisecond: oldDateTime.millisecond,
  })
}
