import { isMobileExperience } from '@motion/web-base/env'
import { HasExperiment } from '@motion/web-common/flags'

import { useOnboardingApi } from '~/areas/onboarding/state/use-onboarding-api'
import { type AmplitudeExperimentName } from '~/services/amplitudeFeatureFlagsService'
import { type ComponentType, Fragment } from 'react'

import { AlertContainer } from './AlertContainer'
import { AlertsProvider } from './AlertsContext'

import { DesktopUpdateAvailable } from '../DesktopUpdateAvailable/DesktopUpdateAvailable'
import { DownloadDesktopPopup } from '../DownloadDesktopPopup/DownloadDesktopPopup'
import { DownloadMobilePopup } from '../DownloadMobilePopup/download-mobile-popup'
import { GdprCookiesAlert } from '../gdpr-cookies-alert'
import { ReleaseNotesAlert } from '../release-notes-alert/release-notes-alert'

// Manages which alert is shown and makes sure only one alert is shown at a time.
// Each alert requests to open based on it's own rules, however if multiple alerts trigger, only the one
// with the highest priority will be shown.
// Once an alert is dismissed, the next one that requested to open will be shown, if any.
// All alerts should be wrapped in <AlertContainer /> which injects and open and setOpen props to the child component.

export const AlertManager = () => {
  const { selectIsOnboarding } = useOnboardingApi()
  const onboarding = selectIsOnboarding()
  const isMobile = isMobileExperience()

  if (onboarding || isMobile) return null

  return (
    <AlertsProvider>
      {alertList.map((alert) => {
        const Alert = alert.component
        const container = (
          <AlertContainer id={alert.id} priority={alert.priority}>
            <Alert />
          </AlertContainer>
        )
        if (alert.experiment)
          return (
            <HasExperiment key={alert.id} name={alert.experiment}>
              {container}
            </HasExperiment>
          )
        return <Fragment key={alert.id}>{container}</Fragment>
      })}
    </AlertsProvider>
  )
}

type AlertItem<P> = {
  id: string
  component: ComponentType<P>
  priority: number
  experiment?: AmplitudeExperimentName
}

/* eslint react-refresh/only-export-components: ["warn"] */
export const alertList: AlertItem<any>[] = [
  {
    id: 'desktop-app-download-prompt',
    component: DownloadDesktopPopup,
    priority: 2,
  },
  {
    id: 'desktop-app-update-available',
    component: DesktopUpdateAvailable,
    priority: 3,
  },
  {
    id: 'mobile-app-download-prompt',
    component: DownloadMobilePopup,
    priority: 4,
  },
  {
    id: 'release-notes',
    component: ReleaseNotesAlert,
    priority: 5,
  },
  {
    id: 'gdpr-cookies',
    component: GdprCookiesAlert,
    priority: 7,
    experiment: 'cookie-alert',
  },
]
