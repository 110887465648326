import { type PMTeamSubscriptionType } from '@motion/rpc/types'
import { Banner } from '@motion/ui/base'
import { OVERDUE_SUBSCRIPTION_STATES } from '@motion/ui-logic/billing'
import { logEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useIsEnterpriseSubscription } from '~/global/hooks'
import React from 'react'

import { type StripeSubscription } from '../../state/userSlice'
import { useOpenPortalLink } from '../Account/hooks'

type OutdatedPaymentBannerProps = {
  pmTeamSubscription?: PMTeamSubscriptionType
  userStripeSubscription?: StripeSubscription
}

export const OutdatedPaymentBanner = ({
  pmTeamSubscription,
  userStripeSubscription,
}: OutdatedPaymentBannerProps) => {
  const modalApi = useModalApi()
  const { isEnterprise, isLoading: isEnterpriseLoading } =
    useIsEnterpriseSubscription()
  const teamInvalidPayment = OVERDUE_SUBSCRIPTION_STATES.includes(
    pmTeamSubscription?.status ?? ''
  )
  const personalInvalidPayment = OVERDUE_SUBSCRIPTION_STATES.includes(
    userStripeSubscription?.status ?? ''
  )
  const isInvalidPayment = teamInvalidPayment || personalInvalidPayment

  const { openPortalLink } = useOpenPortalLink({
    stripeCustomerId: userStripeSubscription?.customer,
    urlSuffix: '/payment-methods',
  })

  React.useEffect(() => {
    if (isInvalidPayment) {
      void logEvent('BANNER_SHOW_FAILED_PAYMENT')
    }
  }, [isInvalidPayment])

  if (!isInvalidPayment || isEnterpriseLoading || isEnterprise) {
    return null
  }

  async function handleUpdatePayment() {
    void logEvent('BANNER_CTA_CLICK_FAILED_PAYMENT')
    // Todo: Check payment method, and open modal for both team and individual
    if (teamInvalidPayment) {
      return modalApi.open('change-payment-method', {
        hasPaymentMethod: true,
        activeTeamId:
          pmTeamSubscription?.status !== 'cancelled'
            ? pmTeamSubscription?.teamId
            : undefined,
      })
    }

    openPortalLink()
  }

  return (
    <>
      <Banner
        sentiment='error'
        actions={[
          {
            label: 'Update payment method',
            onAction: handleUpdatePayment,
          },
        ]}
      >
        Your most recent subscription payment has failed, please update your
        payment method.
      </Banner>
    </>
  )
}
