import { useFieldFilter } from '@motion/ui-logic/pm/data'
import { type TaskTypeFilterSchema } from '@motion/zod/client'

import { useAppDataContext } from '~/global/contexts'

import { ConditionSelector } from './filter-item/condition-dropdown'
import { FilterItem } from './filter-item/filter-item'
import { type ArrayConditionOption } from './filter-item/types'

import { type DropdownFilterProps } from '../../types'

const TASK_TYPE_CONDITIONS = [
  { label: 'Yes', id: ['RECURRING_INSTANCE'] },
  { label: 'No', id: ['NORMAL'] },
] satisfies ArrayConditionOption<TaskTypeFilterSchema[]>[]

export const ConnectedRecurringFilter = (
  props: DropdownFilterProps<'tasks'>
) => {
  const ctx = useAppDataContext()
  const [value, setValue] = useFieldFilter(ctx, 'tasks', 'type')
  const label =
    TASK_TYPE_CONDITIONS.find(
      (c) =>
        value &&
        c.id.length === value.length &&
        c.id.every((taskType) => value.includes(taskType))
    )?.label ?? TASK_TYPE_CONDITIONS[0].label

  return (
    <FilterItem
      hideInvert
      label='Recurring'
      onBlur={props.onBlur}
      openOnMount={props.openOnMount}
      onDismiss={() => setValue(null)}
      renderDropdown={({ close }) => (
        <ConditionSelector
          conditions={TASK_TYPE_CONDITIONS}
          value={value}
          onSelect={(condition: TaskTypeFilterSchema[]) => {
            setValue(condition)
            close()
          }}
        />
      )}
    >
      {label}
    </FilterItem>
  )
}
