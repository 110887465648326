import { classed } from '@motion/theme'
import { HoverRow } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type PastDueRow } from '~/@types/analytics'
import { useTaskModalUrl } from '~/global/navigation'
import { useNavigate } from 'react-router'

type RowContainerProps = {
  taskId: string
  type?: PastDueRow
  children: React.ReactNode
  actions: React.ReactNode
  // Override the default onClick behavior
  onClickOverride?: () => void
}

export const RowContainer = ({
  taskId,
  type = 'NORMAL',
  children,
  onClickOverride,
  actions,
}: RowContainerProps) => {
  const navigate = useNavigate()
  const buildTaskModalUrl = useTaskModalUrl()

  const editTaskHandler = () => {
    recordAnalyticsEvent('PAST_DUE_MODAL_TASK_CLICKED', { type })
    navigate(buildTaskModalUrl({ task: taskId }))
  }

  return (
    <Row
      actionsCentered
      actions={actions}
      override='show'
      className='size-full'
    >
      <div
        onClick={(e) => {
          e.stopPropagation()
          if (onClickOverride) {
            onClickOverride()
            return
          }

          editTaskHandler()
        }}
      >
        {children}
      </div>
    </Row>
  )
}

const Row = classed(HoverRow, {
  base: `
    size-full
    px-2 py-1.5 gap-2
    rounded
    cursor-pointer
    text-semantic-neutral-text-default
    hover:bg-semantic-neutral-bg-active-default
  `,
})
