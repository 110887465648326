import { classed } from '@motion/theme'
import { formatTimeSlot } from '@motion/ui-logic'

import { type SlotLabelContentArg } from '@fullcalendar/core'
import { DateTime } from 'luxon'

type SlotLaneContentProps = {
  info: SlotLabelContentArg
  secondaryTimezone?: string | null
}
export const SlotLabelContent = ({
  info,
  secondaryTimezone,
}: SlotLaneContentProps) => {
  const date = DateTime.fromFormat(info.text, 'ha')

  return (
    <div className='text-semantic-neutral-text-subtle text-[10px] pr-2'>
      <TimeSpan>{formatTimeSlot(date)}</TimeSpan>
      {secondaryTimezone && (
        <TimeSpan>{formatTimeSlot(date, secondaryTimezone)}</TimeSpan>
      )}
    </div>
  )
}

const TimeSpan = classed('div', 'inline-block relative -top-2 w-10')
