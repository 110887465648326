import { TeamMemberRole } from '@motion/rpc/types'
import { LoadingSpinner } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { errorInDev } from '@motion/web-base/logging'
import { useHasTreatment } from '@motion/web-common/flags'

import { type Stripe } from '@stripe/stripe-js'
import { useIsIapSubscription } from '~/global/hooks'
import { useIndividualSubscription } from '~/global/rpc/subscriptions'
import { useInviteTeamMembers, useRenameTeam } from '~/global/rpc/team'
import { useLocation } from '~/routing'
import { useToggleIntercom } from '~/utils/toggleIntercom'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { CreateTeamForm } from './CreateTeamForm'
import { CreateTeamLanding } from './CreateTeamLanding'
import { PayTeamForm } from './PayTeamForm'

import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { fetchTeam } from '../../../../state/projectManagement/teamThunks'
import { selectTeam } from '../../../../state/team'
import { createSetupIntent } from '../../../../state/teamSlice'
import { getStripe } from '../../../../utils/stripe'
import { ElementsWrapper } from '../../../Common/Stripe/ElementsWrapper'

enum CreatePhase {
  LANDING,
  CREATING,
  PAYING,
  LOADING,
}

type CreateTeamProps = {
  setIsInProgress: (isInProgress: boolean) => void
}

export const CreateTeam = ({ setIsInProgress }: CreateTeamProps) => {
  const [phase, setPhase] = useState<CreatePhase>(CreatePhase.LOADING)
  const [clientSecret, setClientSecret] = useState<null | string>(null)
  const [stripe, setStripe] = useState<null | Stripe>(null)
  const [payTeamError, setPayTeamError] = useState<null | string>(null)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { mutateAsync: renameTeam } = useRenameTeam()
  const { mutateAsync: inviteTeamMembers } = useInviteTeamMembers()
  const isIapSubscription = useIsIapSubscription()
  const toggleIntercom = useToggleIntercom()
  const individualSubscription = useIndividualSubscription()

  const shouldCreateNewTeamCustomerEnabled = useHasTreatment(
    'team-trial-for-individual'
  )

  const shouldCreateNewTeamCustomer =
    shouldCreateNewTeamCustomerEnabled &&
    individualSubscription?.status !== 'trialing'

  const team = useAppSelector(selectTeam)
  const { search } = useLocation()

  const setupIntentAndGoToPayment = useCallback(
    async (teamName: string) => {
      const paymentIntent = await dispatch(
        createSetupIntent({
          name: teamName,
          forceNewCustomer: shouldCreateNewTeamCustomer,
        })
      ).unwrap()
      setClientSecret(paymentIntent.clientSecret)
      setPhase(CreatePhase.PAYING)
    },
    [dispatch, shouldCreateNewTeamCustomer]
  )

  useEffect(
    function initializePhase() {
      if (search) {
        const urlParams = new URLSearchParams(search)
        if (urlParams.has('phase') && urlParams.get('phase') === 'paying') {
          setPhase(CreatePhase.LOADING)
          return void setupIntentAndGoToPayment('My team')
        }
      }

      setPhase(CreatePhase.LANDING)
    },
    [search, setupIntentAndGoToPayment]
  )

  const onUpdateSetupIntentSecret = useCallback(
    (secret: string) => {
      setClientSecret(secret)
    },
    [setClientSecret]
  )

  useEffect(() => {
    const load = async () => {
      const stripe = await getStripe()
      setStripe(stripe)
    }

    load().catch(errorInDev)
  }, [])

  if (isIapSubscription) {
    return (
      <div className='h-full w-full'>
        {templateStr(
          'Your subscription is currently managed in the Apple App Store. If you would like to switch to a team plan, please {{contactUs}}.',
          {
            contactUs: (
              <a
                className='underline text-semantic-primary-text-default'
                onClick={toggleIntercom}
              >
                contact us
              </a>
            ),
          }
        )}
      </div>
    )
  }

  return (
    <div className='h-full w-full overflow-auto'>
      {phase === CreatePhase.PAYING &&
        stripe !== null &&
        clientSecret !== null && (
          <ElementsWrapper clientSecret={clientSecret} stripe={stripe}>
            <PayTeamForm
              error={payTeamError ?? ''}
              setError={setPayTeamError}
              onRerender={onUpdateSetupIntentSecret}
              goToNextScreen={async () => {
                setIsInProgress(true)
                await dispatch(fetchTeam())
                return setPhase(CreatePhase.CREATING)
              }}
              shouldCreateNewTeamCustomer={shouldCreateNewTeamCustomer}
            />
          </ElementsWrapper>
        )}
      {phase === CreatePhase.CREATING && (
        <CreateTeamForm
          recommendations={[]}
          hasBucketPricing
          onClick={async (payTeamFormProps) => {
            if (!payTeamFormProps.createTeamProps) {
              return
            }
            const { teamName, emails } = payTeamFormProps.createTeamProps
            if (!team) {
              return
            }
            if (emails.length) {
              const invitees = emails.map((guest) => ({
                email: guest,
                role: TeamMemberRole.MEMBER,
                workspaces: [],
              }))
              await inviteTeamMembers({
                id: team?.id,
                invitees,
                seats: payTeamFormProps.newSeats,
              })
            }
            await renameTeam({
              id: team?.id,
              name: teamName,
            })
            setIsInProgress(false)
            await dispatch(fetchTeam())
            navigate('/web/settings/team')
          }}
          seats={team?.pmTeamSubscription?.bucketSeats}
          isAnnual={!team?.pmTeamSubscription?.isMonthly}
        />
      )}
      {phase === CreatePhase.LANDING && (
        <CreateTeamLanding
          onClick={async () => {
            return await setupIntentAndGoToPayment('My team')
          }}
        />
      )}
      {phase === CreatePhase.LOADING && (
        <div className='flex h-full w-full items-center justify-center'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}
