import {
  getTaskDefaultDates,
  type GetTaskDefaultDatesArgs,
  getTaskDefaultDueDate,
  getTaskInitialStartDate,
} from '@motion/ui-logic/pm/task'
import { Sentry } from '@motion/web-base/sentry'

export function getTaskDefaultDatesWithErrorLogging(
  args: GetTaskDefaultDatesArgs
) {
  let startDate = getTaskInitialStartDate() // today
  let dueDate = getTaskDefaultDueDate() // tomorrow

  try {
    return getTaskDefaultDates(args)
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        position: 'getTaskDefaultDates',
      },
      extra: args,
    })
  }

  // Fallback to today and tomorrow
  return { startDate, dueDate }
}
