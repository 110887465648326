import { DragSolid } from '@motion/icons'
import { useSharedStateSendOnly } from '@motion/react-core/shared-state'

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { UserBadge } from '~/global/components/badges'
import { useState } from 'react'

import { TeamScheduleActiveViewOptionsKey } from '../../context'
import { useSortedUsers } from '../../hooks/use-sorted-users'
import { type UserInfo } from '../../types'

export const ConnectedMemberSortPanel = () => {
  const setViewState = useSharedStateSendOnly(TeamScheduleActiveViewOptionsKey)
  const sortedUsers = useSortedUsers()

  return (
    <MemberSortPanel
      users={sortedUsers}
      onChange={(newOrder) =>
        setViewState((prev) => ({
          ...prev,
          rowOrder: newOrder.map((x) => x.id),
        }))
      }
    />
  )
}

type MemberSortPanelProps = {
  users: UserInfo[]
  onChange(users: UserInfo[]): void
}

export const MemberSortPanel = (props: MemberSortPanelProps) => {
  const sensors = useSensors(useSensor(PointerSensor))

  const [items, setItems] = useState(props.users)

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (over == null) return
    if (active.id === over.id) return

    const oldIndex = props.users.findIndex((x) => x.id === active.id)
    const newIndex = props.users.findIndex((x) => x.id === over.id)
    const newItems = arrayMove(items, oldIndex, newIndex)

    setItems(newItems)
    props.onChange(newItems)
  }

  return (
    <div className='bg-dropdown-bg px-1 py-2 flex flex-col gap-2 max-h-80 overflow-y-auto'>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((u) => (
            <SortableUser key={u.id} user={u} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  )
}
type SortableUserProps = {
  user: UserInfo
}
const SortableUser = (props: SortableUserProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.user.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <div
      ref={setNodeRef}
      className='flex gap-2 items-center text-semantic-neutral-text-default leading-5 px-2 py-1.5'
      style={style}
      {...attributes}
      {...listeners}
    >
      <DragSolid
        width={18}
        height={18}
        className='text-semantic-neutral-icon-default'
      />
      <UserBadge
        value={{
          id: props.user.id,
          name: props.user.name,
          email: props.user.email,
          picture: props.user.picture,
          deleted: false,
          onboardingComplete: true,
          isPlaceholder: false,
          hasActiveSubscription: true,
        }}
        size='large'
      />
      {props.user.name}
    </div>
  )
}
