import { isMeetingTask } from '@motion/ui-logic/pm/task'
import { type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskAssigneeUpdater } from '../updaters'

export function useTaskAssigneeData(task: TaskSchema) {
  const updater = useTaskAssigneeUpdater()

  const disabled =
    task.type === 'RECURRING_INSTANCE' ||
    task.archivedTime != null ||
    isMeetingTask(task)

  const onChange = useCallback(
    (userId: TaskSchema['assigneeUserId']) => {
      void updater(task, userId)
    },
    [task, updater]
  )

  return {
    onChange,
    disabled,
  }
}
