import { type ProjectSchema } from '@motion/rpc-types'
import { formatDurationToShort } from '@motion/ui-logic'

import { CellText } from '../components'

export type ProjectTotalDurationCellProps = {
  project: ProjectSchema
}

export const ProjectTotalDurationCell = ({
  project,
}: ProjectTotalDurationCellProps) => {
  return (
    <CellText className='flex-nowrap gap-2'>
      <span className='text-semantic-neutral-text-subtle leading-5 truncate'>
        {formatDurationToShort(project.duration)}
      </span>
    </CellText>
  )
}
