import { TextField } from '@motion/ui/forms'
import { getTextLengthValidationRules } from '@motion/ui-logic'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { forwardRef } from 'react'

import { FieldIconContainer } from '../common/field-icon-container'
import { type CustomFieldInputProps } from '../types'

export const Url = forwardRef<HTMLLabelElement, CustomFieldInputProps>(
  ({ hideIcon, ...props }, ref) => {
    const { maxLength } = getTextLengthValidationRules('url')

    return (
      <TextField
        fullWidth
        maxLength={maxLength}
        ref={ref}
        label={props.name}
        size='small'
        variant='muted'
        {...props}
        onClick={(e) => {
          e.stopPropagation()
        }}
        labelHidden
        placeholder='None'
        prefix={
          !hideIcon && (
            <FieldIconContainer size={props.size}>
              <CustomFieldBadge type='url' />
            </FieldIconContainer>
          )
        }
        type='url'
      />
    )
  }
)
Url.displayName = 'CustomFieldUrl'
