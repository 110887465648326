import {
  type FlowTemplateFormFields,
  type FlowTemplateFormTask,
} from '@motion/ui-logic/pm/project'
import { cloneDeep, isEqual } from '@motion/utils/core'

import { useFlowsModalState } from '~/areas/flows/flow-template-modal/contexts'
import { useRegisterFieldArray } from '~/hooks'
import { useEffect, useMemo, useRef } from 'react'
import { type FieldPath, type RegisterOptions } from 'react-hook-form'

import { useFlowTemplateForm } from '../../../../hooks'
import { getTaskPathFromStageTaskFieldPath } from '../utils'

export const useStageTaskField = <
  T extends Extract<
    FieldPath<FlowTemplateFormFields>,
    `stages.${number}.tasks.${number}.${string}`
  >,
>(
  path: T,
  options?: RegisterOptions<FlowTemplateFormFields>
) => {
  const {
    form: {
      register,
      watch,
      formState: { isSubmitting },
    },
  } = useFlowTemplateForm()

  const { setDirtyTasksMap } = useFlowsModalState()

  const { onChange } = useRegisterFieldArray(path, register, options)

  const taskPath = getTaskPathFromStageTaskFieldPath(path)

  const value = watch(path)
  const task = watch(taskPath)
  const originalValueRef = useRef<FlowTemplateFormTask>(cloneDeep(task))

  const currentIndicies = useMemo(
    () => getIndiciesFromStageTaskPath(path),
    [path]
  )
  const originalIndiciesRef = useRef([...currentIndicies])

  /**
   * Reset original values when submitting
   */
  useEffect(() => {
    if (isSubmitting) {
      originalValueRef.current = cloneDeep(task)
      originalIndiciesRef.current = [...currentIndicies]
    }
  }, [isSubmitting, task, currentIndicies])

  useEffect(() => {
    // When tasks are created, they don't have a name and they are set dirty
    // Keep that dirty state
    if (!task.name) return

    const isDirty =
      !isEqual(originalValueRef.current, task) ||
      !isEqual(originalIndiciesRef.current, currentIndicies)

    setDirtyTasksMap((dirtyTasksMap) => ({
      ...dirtyTasksMap,
      [task.id]: isDirty,
    }))
    // Run on value change or indicies change (task maintains same reference)
  }, [task, task.id, value, currentIndicies, setDirtyTasksMap])

  return useMemo(() => [value, onChange] as const, [value, onChange])
}

function getIndiciesFromStageTaskPath(
  path: `stages.${number}.tasks.${number}.${string}`
) {
  const [stageIndex, _, taskIndex] = path.split('.').slice(1).map(Number)
  return [stageIndex, taskIndex] as const
}
