import { type DashboardViewDefinitionV2 } from '@motion/rpc-types'

import { v4 as uuid } from 'uuid'

export function createDefaultDashboardViewDefinition(): DashboardViewDefinitionV2 {
  return {
    $version: 2,
    type: 'dashboard',
    cells: [
      {
        id: uuid(),
        x: 0,
        y: 0,
        width: 2,
        height: 1,
        title: 'Open Tasks',
        chart: {
          type: 'number',
          item: 'tasks',
          aggregate: { type: 'count' },
          filters: {
            tasks: {
              ordered: [],
              filters: {
                archived: 'exclude',
                completed: 'exclude',
              },
            },
            projects: {
              ordered: [],
              filters: {},
            },
            workspaces: {
              ordered: [],
              filters: {},
            },
          },
        },
      },
      {
        id: uuid(),
        x: 2,
        y: 0,
        width: 2,
        height: 1,
        title: 'Completed Tasks',
        chart: {
          type: 'number',
          item: 'tasks',
          aggregate: { type: 'count' },
          filters: {
            tasks: {
              ordered: [],
              filters: {
                archived: 'exclude',
                completed: 'only',
              },
            },
            projects: {
              ordered: [],
              filters: {},
            },
            workspaces: {
              ordered: [],
              filters: {},
            },
          },
        },
      },
      {
        id: uuid(),
        x: 0,
        y: 1,
        width: 12,
        height: 2,
        title: 'Tasks by assignee',
        chart: {
          type: 'bar',
          item: 'tasks',
          layout: 'grouped',
          orientation: 'vertical',
          aggregate: {
            type: 'count',
          },
          filters: {
            tasks: {
              ordered: [],
              filters: {
                completed: 'exclude',
                archived: 'exclude',
                type: ['NORMAL'],
              },
            },
            projects: {
              ordered: [],
              filters: {},
            },
            workspaces: {
              ordered: [],
              filters: {},
            },
          },
          groupBy: [
            {
              field: 'assigneeUserId',
            },
            {
              field: 'scheduledStatus',
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 4,
        y: 3,
        width: 8,
        height: 2,
        title: 'My Completed Time by Day',
        chart: {
          type: 'line',
          item: 'tasks',
          aggregate: {
            type: 'sum',
            field: 'duration',
          },
          filters: {
            tasks: {
              ordered: [],
              filters: {
                completed: 'only',
                archived: 'exclude',
                type: ['CHUNK'],
                completedTime: {
                  operator: 'defined-relative',
                  name: 'this-month',
                },
                assigneeUserIds: {
                  operator: 'in',
                  value: ['@me'],
                },
              },
            },
            projects: {
              ordered: [],
              filters: {},
            },
            workspaces: {
              ordered: [],
              filters: {},
            },
          },
          groupBy: [
            {
              field: 'completedTime',
              by: 'day',
            },
            {
              field: 'assigneeUserId',
            },
          ],
        },
      },
      {
        id: uuid(),
        x: 0,
        y: 3,
        title: 'Task Overview',
        width: 4,
        height: 2,
        chart: {
          type: 'pie',
          aggregate: { type: 'count' },
          item: 'tasks',
          filters: {
            tasks: {
              filters: {
                recurring: 'CURRENT',
                completed: 'exclude',
                type: ['NORMAL', 'RECURRING_INSTANCE'],
                archived: 'exclude',
              },
              ordered: [],
            },
            projects: { filters: {}, ordered: [] },
            workspaces: { filters: {}, ordered: [] },
          },
          groupBy: [{ field: 'scheduledStatus' }],
        },
      },
    ],
  }
}
