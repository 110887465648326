import { PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

// Why doesn't invisible hover:visible work?
const SkinnyContainer = classed('button', {
  base: `
    flex w-5
    align-middle items-center justify-center
    h-full
    bg-semantic-neutral-surface-raised-bg-subtle
    cursor-pointer
    opacity-0 hover:opacity-100 transition-opacity
  `,
})

export type AddStageSkinnyProps = {
  insert: () => void
}

export const AddStageSkinny = ({ insert }: AddStageSkinnyProps) => {
  return (
    <Tooltip content='Add Stage'>
      <SkinnyContainer
        onClick={(e) => {
          e.preventDefault()
          insert()
          recordAnalyticsEvent('FLOW_TEMPLATE_STAGE_MODIFIED', {
            type: 'add',
          })
        }}
      >
        <PlusSolid className='w-3 h-3 text-button-neutral-outlined-icon-default' />
      </SkinnyContainer>
    </Tooltip>
  )
}
