import {
  PriorityASAPSolid,
  PriorityHighSolid,
  PriorityLowSolid,
  PriorityMediumSolid,
  type SvgIcon,
} from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import { priorityLabels } from '@motion/ui-logic'
import { type PriorityLevelSchema } from '@motion/zod/client'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { type BadgeSize, getBadgeSizeInPixels } from './utils'

export type PriorityBadgeProps = {
  value: PriorityLevelSchema
  size?: BadgeSize
  hideTooltip?: boolean
}

export const PriorityBadge = ({
  value,
  size,
  hideTooltip = false,
}: PriorityBadgeProps) => {
  const icon = <PriorityColoredIcon value={value} size={size} />

  if (hideTooltip) return icon

  return (
    <Tooltip asChild content={`Priority: ${priorityLabels.get(value)}`}>
      {icon}
    </Tooltip>
  )
}

type PriorityColoredIconProps = {
  value: PriorityLevelSchema
  size?: BadgeSize
}
const PriorityColoredIcon = forwardRef<SVGSVGElement, PriorityColoredIconProps>(
  function PriorityColoredIcon({ value, size }, ref) {
    const pixelSize = getBadgeSizeInPixels(size)

    const Icon = value && priorityIcons.get(value)
    const colorClass = value && priorityColorClasses.get(value)

    return (
      Icon && (
        <Icon
          ref={ref}
          width={pixelSize}
          height={pixelSize}
          className={twMerge('self-center shrink-0', colorClass)}
        />
      )
    )
  }
)

const priorityIcons = new Map<PriorityLevelSchema, SvgIcon>([
  ['ASAP', PriorityASAPSolid],
  ['HIGH', PriorityHighSolid],
  ['MEDIUM', PriorityMediumSolid],
  ['LOW', PriorityLowSolid],
])

const priorityColorClasses = new Map<PriorityLevelSchema, string>([
  ['ASAP', '!text-semantic-error-icon-default'],
  ['HIGH', '!text-semantic-error-icon-default'],
  ['MEDIUM', '!text-semantic-warning-icon-default'],
  ['LOW', '!text-semantic-neutral-icon-default'],
])
