import { errorInDev, logInDev, warnInDev } from '@motion/web-base/logging'

import {
  type CreateWindow,
  type LocalStorageSetResponse,
  type MessageListener,
  type PromiseTracker,
  type WorkerEvent,
  type WorkerEvents,
} from './chromeApiTypes'
import { emitter, storage } from './shared'
import { workerSide } from './shared/workers'
import localStorage from './webAppLocalStorage'

const ee = emitter

// Track promises made when making requests to the content script.
let promiseIndex = 0
const contentScriptPromises: PromiseTracker = {}

/**
 * Send a message to the content script, with (usually) a promise that will
 * eventually be resolved once a response from the content is received.
 * @param message
 * @param eventType
 * @param createPromise
 */
export const sendContentScriptMessage = async (
  message: any,
  eventType?: WorkerEvents,
  createPromise = true
) => {
  const event: WorkerEvent = {
    eventType,
    message,
    // @ts-expect-error - promiseIndex should not be undefined. Ignored for turn on strict mode.
    promiseIndex: createPromise ? promiseIndex : undefined,
    source: 'motion-backend',
  }

  if (createPromise) {
    return await new Promise((resolve, reject) => {
      contentScriptPromises[promiseIndex] = { reject, resolve }
      workerSide.postMessage(event)
      promiseIndex++
    })
  }
  workerSide.postMessage(event)
  return await Promise.resolve()
}

export const runtime = {
  getManifest: () => {
    return {
      update_url: __IS_PROD__ ? 'update_url' : null,
    }
  },
  onConnect: {
    addListener: () => {
      logInDev('runtime.onConnect.addListener STUB')
    },
  },
  onMessage: {
    addListener: (listener: MessageListener) => {
      ee.addListener('onMessage', listener)
    },
    removeListener: (listener: MessageListener) => {
      ee.removeListener('onMessage', listener)
    },
  },
  sendMessage: async (data: any) => {
    await sendContentScriptMessage(data, 'runtimeMessage', false)
  },
  setUninstallURL: () => {
    // TODO
  },
}

export { storage }

export const tabs = {
  async create(data: CreateWindow) {
    // Since there's no Window object in the background script, send a message
    // to the content script to handle
    return await sendContentScriptMessage(data, 'createWindow', false)
  },
  async query() {
    return await Promise.resolve([])
  },
  remove() {
    // TODO basically need to keep track of window objects created by the
    // content script
  },
  async sendMessage(tabId: any, data: any) {
    // Ignore tabId since there's only one 'content script'
    return await sendContentScriptMessage(data, 'runtimeMessage', false)
  },
}

export const windows = {
  create: tabs.create,
}

/**
 * Handles messages posted to this worker.
 * @param e
 */
workerSide.onmessage = async (e) => {
  if (
    !e.data ||
    !e.data.source ||
    !e.data.source.startsWith('motion-frontend')
  ) {
    return
  }

  const event = e.data as WorkerEvent

  switch (event.source) {
    case 'motion-frontend':
      // Special messages for internal logic
      if (!event.eventType) {
        return
      }
      switch (event.eventType) {
        case 'localStorageSet':
          // Content script has set some data into local storage - fire listeners in
          // case some code is listening.
          const { changeData } = event.message as LocalStorageSetResponse
          localStorage.syncChanges(changeData)

          ee.listeners('storage.onChanged').forEach((listener) =>
            listener(changeData)
          )
          break
        case 'terminate':
          logInDev('BG - terminating')
          break
        default:
          errorInDev('Unexpected event received', event.eventType, event)
      }
      return
    case 'motion-frontend-promise-response':
      // Responding to a prior worker message event, which requires a response
      if (!(event.promiseIndex in contentScriptPromises)) {
        logInDev('got message for promise that has already been processed', e)
        return
      }
      contentScriptPromises[event.promiseIndex].resolve(event.message)
      delete contentScriptPromises[event.promiseIndex]
      return
    case 'motion-frontend-onMessage':
      // Forward the message directly to the runtime.onMessage listeners
      ee.listeners('onMessage').forEach((listener) => {
        const fakeSender = { tab: {} }
        listener(event.message, fakeSender, (message: any) => {
          try {
            const workerMessage: WorkerEvent = {
              message,
              promiseIndex: event.promiseIndex,
              source: 'motion-backend-promise-response',
            }
            workerSide.postMessage(workerMessage)
          } catch (e) {
            warnInDev('sendContentScriptMessage postMessage fail')
            warnInDev(e)
          }
        })
      })
  }
}
