import { PlusSolid } from '@motion/icons'
import { isSystemStatus } from '@motion/shared/common'
import { getNextRank } from '@motion/shared/pm'
import { Button, showToast } from '@motion/ui/base'
import { statusColorOptions } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'
import {
  type StatusSchema,
  type V2CreateTaskStatusSchema,
} from '@motion/zod/client'

import {
  useCreateStatus,
  useDeleteStatus,
  useUpdateStatus,
  useWorkspaceById,
  useWorkspaceStatuses,
} from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'
import { useState } from 'react'
import { useParams } from 'react-router'

import { AddWorkspaceStatusRow } from './add-workspace-status-row'
import { UneditableStatus } from './common'
import { WorkspaceStatusItem } from './workspace-status-item'

import { findUnusedColor } from '../../../components/ProjectManagement/constants'
import {
  SettingTable,
  type SettingTableProps,
} from '../components/setting-table'

export function WorkspaceStatusSettings() {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>()
  const [isAddingStatus, setIsAddingStatus] = useState(false)
  const { createStatus, isLoading } = useCreateStatus()
  const { updateStatus } = useUpdateStatus()
  const { deleteStatus } = useDeleteStatus()

  const workspace = useWorkspaceById(workspaceId)
  const statuses = useWorkspaceStatuses(workspaceId)

  if (!workspace) {
    return null
  }

  async function internalUpdateStatus(
    field: string,
    value: string | boolean,
    taskStatus: StatusSchema
  ) {
    if (!workspace) {
      return
    }

    if (field === 'name' && value === taskStatus.name) return
    if (field === 'name' && typeof value === 'string' && value.trim() === '') {
      return showToast('error', 'Status name cannot be empty')
    }

    await updateStatus({
      workspaceId: workspace.id,
      id: taskStatus.id,
      [field]: value,
    })
  }

  async function internalCreateStatus(status: V2CreateTaskStatusSchema) {
    if (!workspace) {
      return
    }

    try {
      await createStatus({
        workspaceId: workspace.id,
        ...status,
      })

      setIsAddingStatus(false)
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          position: 'create-status',
        },
      })

      showErrorToast(e)
    }
  }

  const settingsListItems: SettingTableProps['items'] = statuses.map(
    (status) => ({
      id: status.id,
      label: (
        <WorkspaceStatusItem
          key={status.id}
          status={status}
          updateTaskStatus={internalUpdateStatus}
        />
      ),
      onDelete: !isSystemStatus(status)
        ? () => deleteStatus(status)
        : undefined,
    })
  )

  if (isAddingStatus) {
    const newColor = findUnusedColor(statuses, statusColorOptions)
    const newStatus: V2CreateTaskStatusSchema = {
      name: '',
      sortPosition: getNextRank(statuses.length),
      autoScheduleEnabled: true,
      color: newColor,
    }
    settingsListItems.push({
      id: 'add-status',
      label: (
        <AddWorkspaceStatusRow
          key='add-status'
          status={newStatus}
          icon={
            <UneditableStatus
              item={{
                ...newStatus,
                type: null,
              }}
            />
          }
          onCancel={() => setIsAddingStatus(false)}
          onSave={(status) => internalCreateStatus(status)}
          loading={isLoading}
          placeholder='Enter status name'
        />
      ),
    })
  }

  return (
    <div className='flex w-full flex-col gap-3'>
      <SettingTable items={settingsListItems} title='Statuses'></SettingTable>
      <div>
        {!isAddingStatus && (
          <Button
            onClick={() => setIsAddingStatus(true)}
            size='small'
            variant='muted'
          >
            <PlusSolid />
            Add status
          </Button>
        )}
      </div>
    </div>
  )
}
