import { ChevronDownSolid, PencilSolid } from '@motion/icons'
import { Checkbox } from '@motion/ui/forms'

import { twMerge } from 'tailwind-merge'

import { Paragraph, TextButton } from '../../Common'

interface ListPickerHeaderProps {
  title: string
  isExpanded: boolean
  setExpanded: (expanded: boolean) => void
  showCheckbox?: boolean
  checked?: boolean
  setChecked?: (checked: boolean) => void
  allowEdit?: boolean
  onEdit?: () => void
}

export const ListPickerHeader = ({
  title,
  isExpanded = false,
  setExpanded = () => {},
  showCheckbox = false,
  checked = false,
  setChecked = () => {},
  allowEdit = false,
  onEdit = () => {},
}: ListPickerHeaderProps) => {
  return (
    <div className='dark:border-dark-1000 dark:bg-dark-1200 bg-light-300 border-light-500 flex w-full justify-between border-b px-3 py-1.5'>
      <div className='flex items-center gap-2'>
        {showCheckbox && (
          <Checkbox
            checked={checked}
            onChange={setChecked}
            label={title}
            labelHidden
          />
        )}
        <Paragraph>{title}</Paragraph>
      </div>
      <div className='flex items-center gap-2'>
        {allowEdit && (
          <TextButton
            className='dark:text-dark-100'
            icon={PencilSolid}
            onClick={onEdit}
            size='small'
          />
        )}
        <TextButton
          icon={ChevronDownSolid}
          className='dark:text-dark-100'
          iconClassName={twMerge(
            'transition-transform duration-300',
            isExpanded && 'rotate-180'
          )}
          onClick={() => setExpanded(!isExpanded)}
        />
      </div>
    </div>
  )
}
