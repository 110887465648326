import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ViewActionListActionName } from '@motion/web-base/analytics/events'

type WrappedFunction<T extends (...args: any[]) => any> = (
  ...args: Parameters<T>
) => Promise<ReturnType<T>>

export function wrapWithAnalytics<T extends (...args: any[]) => any>(
  actionName: ViewActionListActionName,
  fn: T
): WrappedFunction<T> {
  return (...args) => {
    recordAnalyticsEvent('VIEW_ACTION_LIST_CLICKED', {
      action: actionName,
    })

    return fn(...args)
  }
}
