import { useModalApi } from '@motion/web-common/modals'

import { useCallback } from 'react'

export function useUnsavedChangesPrompt() {
  const modalApi = useModalApi()

  return useCallback(
    async (message = `Your changes aren't saved`) => {
      const response = await modalApi.prompt('confirm', {
        analytics: {
          name: 'before-leaving',
        },
        title: message,
        closeButtonText: 'Go back',
        confirmButtonText: 'Close without saving',
      })

      return response === true
    },
    [modalApi]
  )
}
