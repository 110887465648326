import { BackendApiService } from './BackendApiService'

import api from '../chromeApi/chromeApiBackground'
import { signInWithCustomToken } from '../localServices/firebase/background'
import { defaultSettings } from '../storageConstants'

type AdminServiceDto = {
  bearerToken: string
  emailToLogInAs: string
  initiator: string
}

type AdminServiceResponse = Record<string, string>

/**
 * Admin Endpoints for Motion
 */
export class AdminService extends BackendApiService {
  static id = 'AdminService' as const

  async loginAsAnotherUser({
    bearerToken,
    emailToLogInAs,
    initiator,
  }: AdminServiceDto): Promise<AdminServiceResponse> {
    if (!__QA_ENABLED__) {
      return Promise.reject(new Error('Not Implemented'))
    }

    const bearer = `Bearer ${bearerToken}`
    let response
    try {
      response = await fetch(
        `${__BACKEND_HOST__}/admin/users/generateFirebaseTokenForEmailAddress`,
        {
          method: 'POST',
          headers: {
            Authorization: bearer,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ emailAddress: emailToLogInAs, initiator }),
        }
      )
    } catch (e: unknown) {
      return { error: e instanceof Error ? e.message : 'Unknown error' }
    }

    if (response.status !== 201) {
      const responseBody = await response.json()
      return { error: responseBody.message }
    }

    const { token } = await response.json()
    if (!token) {
      return { error: 'No token returned' }
    }

    // Log out
    await api.storage.local.clear()
    await api.storage.local.set(defaultSettings)

    await signInWithCustomToken(token)
    return {}
  }
}

export const instance = new AdminService()
