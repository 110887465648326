import {
  type UpdateUploadedFileRequest,
  type UploadedFileSchema,
} from '@motion/rpc-types'
import { showToast, ToasterState } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import {
  useDeleteAttachment,
  useUpdateAttachment,
} from '~/areas/attachments/hooks'
import { useDownloadAttachment } from '~/areas/attachments/hooks/use-download-attachment'
import { useCopyHtmlLinkToClipboard } from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'
import { useUriByRouteId } from '~/routing'

type DeleteAttachmentResult = {
  deleted: boolean
}

export function useAttachmentActions() {
  const modalApi = useModalApi()

  const { mutate: deleteFile } = useDeleteAttachment()
  const { mutateAsync: updateFile } = useUpdateAttachment()

  const getRouteUri = useUriByRouteId()

  const { downloadFile } = useDownloadAttachment()

  const copyToClipboard = useCopyHtmlLinkToClipboard()

  const deleteAttachment = async (
    attachment: UploadedFileSchema
  ): Promise<DeleteAttachmentResult> => {
    const response = await modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-attachment',
      },
      destructive: true,
      title: 'Delete attachment',
      description: (
        <>
          Are you sure you want to delete{' '}
          <span className='font-semibold'>{attachment.fileName}</span>?
        </>
      ),
      confirmButtonText: 'Delete attachment',
    })

    if (response !== ModalDismissed) {
      try {
        await deleteFile(attachment)

        recordAnalyticsEvent('ATTACHMENT_FILE_DELETED', {
          file_size: attachment.fileSize,
          mime_type: attachment.mimeType,
          target_type: attachment.targetType ?? 'none',
        })

        showToast('success', 'Attachment deleted successfully.')

        return { deleted: true }
      } catch (error) {
        Sentry.captureException(error, {
          tags: { position: 'deleteAttachment' },
          extra: {
            fileId: attachment.id,
          },
        })

        showToast('error', 'Failed to delete attachment. Please try again.')
      }
    }

    return { deleted: false }
  }

  const updateAttachment = async ({
    id,
    fileName,
    fileSize,
    mimeType,
    targetType,
  }: Pick<UploadedFileSchema, 'id' | 'fileSize' | 'mimeType' | 'targetType'> &
    UpdateUploadedFileRequest) => {
    try {
      await updateFile({
        id,
        fileName,
      })

      recordAnalyticsEvent('ATTACHMENT_FILE_RENAMED', {
        file_size: fileSize,
        mime_type: mimeType,
        target_type: targetType ?? 'none',
      })

      showToast('success', 'Attachment renamed successfully.')
    } catch (error) {
      Sentry.captureException(error, {
        tags: { position: 'updateAttachment' },
        extra: {
          fileId: id,
        },
      })

      showErrorToast(error, 'Failed to rename attachment. Please try again.')
    }
  }

  const downloadAttachment = async (attachment: UploadedFileSchema) => {
    try {
      const toast = showToast('loading', 'Downloading attachment...')

      await downloadFile({
        fileId: attachment.id,
        fileName: attachment.fileName,
        mimeType: attachment.mimeType,
      })

      recordAnalyticsEvent('ATTACHMENT_FILE_DOWNLOADED', {
        file_size: attachment.fileSize,
        mime_type: attachment.mimeType,
        target_type: attachment.targetType ?? 'none',
      })

      ToasterState.dismiss(toast)
    } catch (error) {
      Sentry.captureException(error, {
        tags: { position: 'downloadAttachment' },
        extra: {
          fileId: attachment.id,
        },
      })

      showToast('error', 'Failed to download attachment. Please try again.')
    }
  }

  const copyAttachmentLink = (attachment: UploadedFileSchema) => {
    if (!attachment.workspaceId) {
      showToast('error', 'Failed to copy attachment link. Please try again.')
      return
    }

    recordAnalyticsEvent('ATTACHMENT_FILE_COPY_LINK', {
      file_size: attachment.fileSize,
      mime_type: attachment.mimeType,
      target_type: attachment.targetType ?? 'none',
    })

    const attachmentUri = getRouteUri('attachment-preview', {
      attachmentId: attachment.id,
    })

    const attachmentUrl = new URL(
      attachmentUri,
      window.location.origin
    ).toString()

    copyToClipboard(attachmentUrl, attachment?.fileName || 'Attachment link')
  }

  return {
    deleteAttachment,
    updateAttachment,
    downloadAttachment,
    copyAttachmentLink,
  }
}
