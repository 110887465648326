import { groupInto } from '@motion/utils/array'
import { type LabelSchema } from '@motion/zod/client'

import { Label, type LabelProps } from '~/global/components/labels'
import { useMemo } from 'react'

type BulkLabelsLabelProps = Omit<LabelProps, 'value'> & {
  values: LabelSchema[]
}

export function BulkLabelsLabel({ values }: BulkLabelsLabelProps) {
  const labelGroups = useMemo(() => groupInto(values, (s) => s.color), [values])

  if (values.length === 1 || labelGroups.length === 1) {
    return <Label value={values[0]} />
  }

  const blackLabel = {
    ...values[0],
    color: 'grey',
  }

  return <Label value={blackLabel} />
}
