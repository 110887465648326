import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import { focusFirstParentFocusableNode } from '@motion/ui/utils'
import { type NumberCustomField } from '@motion/ui-logic'

import {
  CustomField,
  type CustomFieldInputProps,
} from '~/areas/project-management/custom-fields'
import { type CustomFieldWithValue } from '~/global/proxies'
import { type FC, useRef, useState } from 'react'

import { CellText } from '../../components'
import { type ControlledCustomFieldProps } from '../types'

export const ControlledNumberCell: FC<
  ControlledCustomFieldProps<NumberCustomField>
> = ({ value, onSubmit: submitHandler, customField, disabled }) => {
  const ref = useRef<HTMLLabelElement>(null)
  const [isDirty, setIsDirty] = useState(false)
  const [internalValue, setInternalValue] = useDependantState<number | null>(
    () => value,
    [value],
    { freezeDependencyUpdates: isDirty }
  )

  const onChange = (v: number | null) => {
    setInternalValue(v)
  }

  const onSubmit = useDebouncedCallback(async () => {
    if (internalValue === value) {
      setIsDirty(false)
      return
    }
    try {
      await submitHandler(internalValue)
      // Focus on the cell if input is still focused
      if (ref.current != null && document.activeElement === ref.current) {
        focusFirstParentFocusableNode(ref.current)
      }
    } catch (error) {
      setInternalValue(value)
    } finally {
      setIsDirty(false)
    }
  }, 600)

  const handleKeyDown: CustomFieldInputProps['onKeyDown'] = (evt) => {
    const { key } = evt

    if (key === 'Enter') {
      return onSubmit()
    }
  }

  return (
    <CellText withHover isFake={disabled}>
      <CustomField.Number
        ref={ref}
        name={customField.definition.name}
        format={
          (customField as Extract<CustomFieldWithValue, { type: 'number' }>)
            .definition.metadata.format
        }
        value={internalValue}
        onFocus={() => setIsDirty(true)}
        onChange={onChange}
        onBlur={onSubmit}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        hideIcon
      />
    </CellText>
  )
}
