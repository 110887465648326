import { PlusCircleSolid } from '@motion/icons'
import { MAX_NUM_STAGES } from '@motion/shared/flows'
import { classed } from '@motion/theme'
import {
  mapCustomFieldToFieldArrayWithValue,
  templateStr,
} from '@motion/ui-logic'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { sleep } from '@motion/utils/promise'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useWorkspaceStatuses } from '~/global/hooks'
import { Fragment, type RefObject } from 'react'
import { type UseFieldArrayReturn } from 'react-hook-form'

import { AddStageSkinny } from './add-stage-skinny'
import { StageTaskColumn } from './stage-task-column'

import { useFlowsModalState } from '../../contexts'
import { useFlowTemplateForm } from '../../hooks'
import { getNewStageData } from '../../utils'
import { StageColumn } from '../styled'

export type StageColumnsProps = {
  scrollColumnsRef: RefObject<HTMLDivElement>
  fieldArray: UseFieldArrayReturn<FlowTemplateFormFields, 'stages', 'id'>
}

export const StageColumns = ({
  scrollColumnsRef,
  fieldArray: { append, insert, remove },
}: StageColumnsProps) => {
  const {
    form: { watch, reset },
  } = useFlowTemplateForm()
  const stages = watch('stages')
  const numStages = stages.length

  const workspaceId = watch('workspaceId')
  const workspaceStatuses = useWorkspaceStatuses(workspaceId)
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)
  const customFieldValuesFieldArray = workspaceCustomFields.map((field) =>
    mapCustomFieldToFieldArrayWithValue(field, {})
  )

  const { uid: currentUserId } = useAuthenticatedUser()
  const { setDirtyTasksMap } = useFlowsModalState()

  return (
    <StageColumnsContainer ref={scrollColumnsRef}>
      {stages.map((stage, i) => (
        <Fragment key={stage.id}>
          <AddStageSkinny
            insert={() => {
              const newStage = getNewStageData({
                workspaceStatuses,
                currentUserId,
                customFieldValuesFieldArray,
              })

              insert(i, newStage)
              /**
               * Modifying field array makes the whole form dirty.
               * We reset the form to mitigate that.
               */
              reset({}, { keepValues: true })

              /**
               * Mark new tasks as dirty
               */
              setDirtyTasksMap((prev) => ({
                ...prev,
                ...Object.fromEntries(
                  newStage.tasks.map((task) => [task.id, true])
                ),
              }))
            }}
          />

          <StageTaskColumn index={i} onRemove={() => remove(i)} />
        </Fragment>
      ))}
      {numStages <= MAX_NUM_STAGES && (
        <>
          <div />
          <StageColumn newStage>
            <AddStageButton
              onClick={async (e) => {
                e.preventDefault()

                const newStage = getNewStageData({
                  currentUserId,
                  workspaceStatuses,
                  customFieldValuesFieldArray,
                })

                append(newStage)
                /**
                 * Modifying field array makes the whole form dirty.
                 * We reset the form to mitigate that.
                 */
                reset({}, { keepValues: true })

                /**
                 * Mark new tasks as dirty
                 */
                setDirtyTasksMap((prev) => ({
                  ...prev,
                  ...Object.fromEntries(
                    newStage.tasks.map((task) => [task.id, true])
                  ),
                }))

                recordAnalyticsEvent('FLOW_TEMPLATE_STAGE_MODIFIED', {
                  type: 'add',
                })
                // Sleeping to scroll in a next frame to make sure the item has been added
                await sleep(1)
                scrollColumnsRef.current?.scrollBy({
                  left: 500,
                  behavior: 'smooth',
                })
              }}
            >
              <div className='flex flex-col items-center justify-center gap-2.5'>
                <PlusCircleSolid className='w-6 h-6 text-semantic-neutral-icon-default' />
                <span className='text-sm leading-5 font-medium'>Add stage</span>
              </div>

              <InlineHint>
                <span>
                  {templateStr(
                    'To track time gaps between stages, add a {{ stage }} with a single reminder task.',
                    {
                      stage: <strong>Waiting Stage</strong>,
                    }
                  )}
                </span>
                <span>
                  For example, “Waiting for approval” or “Waiting for event to
                  start.”
                </span>
              </InlineHint>
            </AddStageButton>
          </StageColumn>
        </>
      )}
    </StageColumnsContainer>
  )
}

const InlineHint = classed('div', {
  base: `
    flex flex-col text-left p-2 gap-2.5
    text-semantic-neutral-text-subtle text-2xs leading-4
    rounded border border-semantic-neutral-border-default bg-semantic-neutral-bg-subtle shadow-sm
  `,
})

const StageColumnsContainer = classed('div', {
  base: `
    flex-1
    grid grid-rows-1 grid-flow-col auto-cols-[20px_290px]
    gap-0.5

    overflow-x-auto

    pt-[18px] px-5 pl-0.5
  `,
})

const AddStageButton = classed('button', {
  base: `
    flex flex-col items-center justify-center gap-6
    w-full h-full
    p-4
    text-semantic-neutral-text-subtle
    bg-semantic-neutral-surface-raised-bg-subtlest
    rounded-[4px]
    hover:bg-semantic-neutral-surface-raised-bg-subtle
    focus-visible:ring focus-visible:ring-semantic-neutral-ring-default
  `,
})
