import { ArchiveSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'

import { FieldButton } from '../components'
import { isBulkOpsTaskGroups } from '../contexts'
import { useBulkArchiveTasks, useBulkFieldValidation } from '../hooks'

export function ArchiveField() {
  const bulkArchive = useBulkArchiveTasks()

  const { isValid, errMsg } = useBulkFieldValidation([
    ({ target, groups }) => ({
      isValid: isBulkOpsTaskGroups(groups, target)
        ? groups.every((group) =>
            group.items.every((task) => task.isAutoScheduled === false)
          )
        : true,
      errMsg: 'Archiving auto-scheduled tasks is not supported',
    }),
  ])

  return (
    <Tooltip asChild content={errMsg}>
      <FieldButton
        disabled={!isValid}
        analyticsName='archive'
        onClick={() => {
          bulkArchive()
        }}
      >
        <ArchiveSolid />
        Archive
      </FieldButton>
    </Tooltip>
  )
}
