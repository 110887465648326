import { type NormalTaskSchema } from '@motion/zod/client'

import { GhostTaskStatusDropdown } from '~/areas/flows'
import {
  StatusDropdown,
  type StatusDropdownProps,
} from '~/areas/project-management/components'

export const TaskStatusDropdown = ({
  children,
  isUnvisitedStage,
  ...rest
}: StatusDropdownProps & {
  isUnvisitedStage: NormalTaskSchema['isUnvisitedStage']
}) => {
  const EffectiveStatusDropdown = isUnvisitedStage
    ? GhostTaskStatusDropdown
    : StatusDropdown

  return <EffectiveStatusDropdown {...rest}>{children}</EffectiveStatusDropdown>
}
