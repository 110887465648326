import { PlusSolid } from '@motion/icons'
import {
  MAX_NUMBER_PWT_VARIABLES,
  wrapVariableInDelimiters,
} from '@motion/shared/flows'
import { Button, Tooltip } from '@motion/ui/base'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useFieldArray } from 'react-hook-form'

import { TextVariableField } from './text-variable-field'
import { useClearTaskNameVariable } from './use-clear-task-name-variable'

import { useFlowTemplateForm } from '../../hooks'
import { createNewTextVariable } from '../../utils'

export const ControlledTextVariableFields = () => {
  const { form } = useFlowTemplateForm()
  const { control, watch, getValues } = form
  const modalApi = useModalApi()

  // Adding rules here isn't working.
  // Checking on each field instead.
  const { append, remove } = useFieldArray({
    control,
    name: 'textVariables',
  })

  const fields = watch('textVariables')

  const clearTextVariable = useClearTaskNameVariable()

  const onRemove = async (index: number) => {
    const res = await modalApi.prompt('confirm', {
      analytics: {
        name: 'template-delete-variable',
      },
      title: 'Are you sure you want to delete this text variable?',
      description: `It will be removed anywhere it's used.`,
      closeButtonText: 'Cancel',
      confirmButtonText: 'Delete text variable',
    })
    if (res === ModalDismissed) return
    const variableKey = getValues(`textVariables.${index}.key`)
    const wrappedKey = wrapVariableInDelimiters(variableKey)
    clearTextVariable(wrappedKey)
    remove(index)
  }

  function addNewVariable() {
    const newVariable = createNewTextVariable('')
    append(newVariable)
  }

  return (
    <>
      <div className='flex flex-col gap-1.5 pl-1 pb-2'>
        {fields.map((field, i) => (
          <TextVariableField
            key={field.id}
            name={field.name}
            keyPath={`textVariables.${i}.key`}
            namePath={`textVariables.${i}.name`}
            onRemove={() => onRemove(i)}
            addNewVariable={addNewVariable}
          />
        ))}
      </div>

      <Tooltip
        content={
          fields.length >= MAX_NUMBER_PWT_VARIABLES
            ? `You can only add up to ${MAX_NUMBER_PWT_VARIABLES} variables`
            : undefined
        }
      >
        <Button
          variant='muted'
          sentiment='neutral'
          alignment='left'
          size='small'
          disabled={fields.length >= MAX_NUMBER_PWT_VARIABLES}
          onClick={() => {
            addNewVariable()
          }}
        >
          <PlusSolid />
          Add text variable
        </Button>
      </Tooltip>
    </>
  )
}
