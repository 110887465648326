import { groupInto } from '@motion/utils/array'
import { LineChart } from '@motion/web-charts/line'
import {
  type CategoryValuePointSchema,
  GroupableDateFieldNameSchema,
  type GroupableDateSchema,
} from '@motion/zod/client'

import { useChartQuery } from '~/areas/charts/rpc'
import { useLookup } from '~/global/cache'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { GridTile } from '../components/grid-tile'
import { type GridTileLayoutProps } from '../components/types'

export type CompletedTimeByAssigneeChartProps = GridTileLayoutProps & {}

export const CompletedTimeByAssigneeChart = (
  props: CompletedTimeByAssigneeChartProps
) => {
  const response = useChartQuery({
    source: 'tasks',
    aggregate: {
      type: 'sum',
      field: 'duration',
    },
    filters: [
      {
        completed: 'only',
        archived: 'exclude',
        type: ['CHUNK'],
        completedTime: {
          operator: 'gte',
          value: DateTime.now().startOf('day').minus({ week: 2 }).toISO(),
        },
        assigneeUserIds: {
          operator: 'in',
          value: ['@me'],
        },
      },
    ],
    groupBy: [
      {
        field: 'completedTime',
        by: 'day',
      },
      {
        field: 'assigneeUserId',
      },
    ],
  })

  const lookup = useLookup()
  const getLabel = useCallback(
    (group: string, key: string, index: number) => {
      if (group === 'assigneeUserId') {
        return lookup('users', key)?.name
      }
    },
    [lookup]
  )

  const data = groupInto(response.data?.data ?? [], (x) =>
    String(x['assigneeUserId'])
  ).flatMap((g) => {
    return ensureDomain(g.items, { field: 'completedTime', by: 'day' }, 0)
  })

  return (
    <GridTile title='My Completed Time by Day' {...props}>
      {data.length ? (
        <LineChart
          data={data}
          categoryKey='completedTime'
          groupKey='assigneeUserId'
          labelAccessor={getLabel}
        />
      ) : (
        <div>No data found</div>
      )}
    </GridTile>
  )
}

// TODO: Move this to the server
function ensureDomain(
  points: CategoryValuePointSchema[],
  group: GroupableDateSchema,
  emptyValue: number | null = null
) {
  if (!GroupableDateFieldNameSchema.includes(group.field)) return [...points]
  if (group.by === 'half') return [...points]

  const values = points
    .map((x) => x[group.field])
    .filter(Boolean)
    .map((x) => x as string)
  const range = values.reduce(
    (acc, value) => {
      if (value < acc.min) {
        acc.min = value
      }

      if (value > acc.max) {
        acc.max = value
      }
      return acc
    },
    { min: values[0], max: values[0] }
  )

  const minDate = DateTime.fromISO(range.min)
  const maxDate = DateTime.fromISO(range.max)
  const diff = maxDate.diff(minDate, group.by).as(group.by)
  const domainValues = Array.from(new Array(diff), (_, i) =>
    minDate.plus({ [group.by]: i }).toISODate()
  )

  const newPoints = [...points]
  for (const d of domainValues) {
    if (newPoints.every((x) => x[group.field] !== d)) {
      newPoints.push(
        Object.assign({}, points[0], { [group.field]: d, value: emptyValue })
      )
    }
  }
  return newPoints
}
