import { BRACKET_WRAP_RE } from '@motion/shared/common'
import {
  type FlowTemplateFormFields,
  type FlowTemplateStage,
} from '@motion/ui-logic/pm/project'

import { mergeAttributes, nodePasteRule } from '@tiptap/core'
import { type NodeViewProps } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { type ComponentType } from 'react'

import { suggestion } from './suggestion'
import { type SuggestionDropdownProps } from './suggestion/suggestion-dropdown'
import { type MentionOptions, type SuggestionStorage } from './types'

export const pasteRegex = /\{\{(.*?)\}\}/g

export type VariableLocation = 'name' | 'description'

export type FlowVariableProps = {
  taskId: string
  stages: FlowTemplateStage[]
  textVariables: FlowTemplateFormFields['textVariables']
  as: ComponentType<NodeViewProps & { type: VariableLocation }>
  dropdownComponent: ComponentType<SuggestionDropdownProps>
  labelSize?: 'xsmall' | 'small'
  type: VariableLocation
}

export const FlowVariable = ({
  taskId,
  stages,
  textVariables,
  as: Component,
  dropdownComponent: DropdownComponent,
  labelSize,
  type,
}: FlowVariableProps) =>
  Mention.extend<MentionOptions, SuggestionStorage>({
    name: 'flowVariable',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,

    addOptions() {
      return {
        HTMLAttributes: {},
        renderLabel: (item) => '',
        suggestion: suggestion(this.name, DropdownComponent),
      }
    },

    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-flow-variable-id'),
          renderHTML: (attributes) => {
            if (!attributes.id) {
              return {}
            }

            return {
              'data-flow-variable-id': attributes.id,
            }
          },
        },
        taskId: {
          // Breaks regex parsing if not removed
          default: taskId.replace('|<placeholder>', ''),
        },
        size: {
          default: labelSize,
        },
      }
    },

    parseHTML() {
      return [{ tag: 'span[data-flow-variable-id]' }]
    },

    renderHTML({ HTMLAttributes, node }) {
      return [
        'span',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        `{{${node.attrs.id}}}`,
      ]
    },

    addPasteRules() {
      return [
        nodePasteRule({
          find: BRACKET_WRAP_RE,
          type: this.type,
          getAttributes: (match) => ({
            id: match[1],
          }),
        }),
      ]
    },

    addStorage() {
      return {
        suggestions: [],
        stages,
        textVariables,
      }
    },

    addNodeView() {
      return ReactNodeViewRenderer((props) => (
        <Component {...props} type={type} />
      ))
    },
  })
