import { type AvailableCustomFieldTypes } from '@motion/ui-logic'

import {
  ControlledDateCell,
  ControlledMultiPersonCell,
  ControlledMultiSelectCell,
  ControlledNumberCell,
  ControlledPersonCell,
  ControlledSelectCell,
  ControlledTextCell,
  ControlledUrlCell,
} from './controlled'

export const CONTROLLED_CUSTOM_FIELD_CELL_LOOKUP = {
  text: ControlledTextCell,
  date: ControlledDateCell,
  multiSelect: ControlledMultiSelectCell,
  number: ControlledNumberCell,
  select: ControlledSelectCell,
  url: ControlledUrlCell,
  multiPerson: ControlledMultiPersonCell,
  person: ControlledPersonCell,
} as const satisfies {
  [T in AvailableCustomFieldTypes]: React.ComponentType<any>
}
