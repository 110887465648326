import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { clearSetupProjectParams } from '../utils'

export const useCloseSetupProjectModal = () => {
  const [, setSearchParams] = useSearchParams()

  return useCallback(() => {
    setSearchParams((prev) => {
      clearSetupProjectParams(prev)
      return prev
    })
  }, [setSearchParams])
}
