import { useSharedState } from '@motion/react-core/shared-state'
import {
  FormModal,
  PopoverButton,
  SearchableDropdown,
  showToast,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useModalStatus } from '@motion/web-common/modals'
import { type ModalDefinitions } from '@motion/web-common/modals/definitions'
import { type VersionedViewV2 } from '@motion/zod/client'

import { ActiveFilterItemsWorkspaceContext } from '~/global/contexts'
import { useCurrentTeam } from '~/global/rpc/team'
import { useCreateView, useUpdateView } from '~/global/rpc/v2'
import { useState } from 'react'

import { ConnectedLegacyFilterButtons, FilterBar } from '../../../../../filters'
import { ConnectedFieldSelector } from '../../components/filter/field-selector'
import { ConnectedWeekendsToggle } from '../../components/filter/weekends-toggle'
import { DEFAULT_VIEW, TeamScheduleActiveViewOptionsKey } from '../../context'
import {
  createEntityCache,
  TeamSchedulePageContextKey,
} from '../../context/page-data-context'
import { definitions } from '../../filters'
import { type TeamScheduleView } from '../../types'
import { toViewDefinition } from '../utils'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'save-team-view': { asNew: boolean; personal: boolean }
  }
}

export const SaveTeamViewModalTrigger = () => {
  const status = useModalStatus('save-team-view')
  if (!status.visible) return null

  return <ConnectedCreateTeamViewModal close={status.close} {...status.props} />
}

type ConnectedCreateTeamViewModalProps = ModalDefinitions['save-team-view'] & {
  close: () => void
}
export const ConnectedCreateTeamViewModal = (
  props: ConnectedCreateTeamViewModalProps
) => {
  const [ctx, setContext] = useSharedState(TeamSchedulePageContextKey)
  const [filterState] = useSharedState(ActiveFilterKey)
  const [viewState, setViewState] = useSharedState(
    TeamScheduleActiveViewOptionsKey
  )
  const user = useAuthenticatedUser()
  const team = useCurrentTeam()

  const definition = toViewDefinition(filterState, viewState)

  const selectedView = ctx.views.byId[viewState.viewId] ?? DEFAULT_VIEW

  const initialId = props.asNew ? '' : selectedView.id

  const view: TeamScheduleView = {
    id: initialId,
    type: 'team-schedule',
    name: selectedView === DEFAULT_VIEW || props.asNew ? '' : selectedView.name,
    isPrivate: props.personal,
    definition,
  }
  const createView = useCreateView()
  const updateView = useUpdateView()

  return (
    <CreateTeamViewModal
      view={view}
      onSave={async (viewToSave) => {
        const target =
          team.data == null
            ? { targetId: user.uid, targetType: 'USER' as const }
            : { targetId: team.data.id, targetType: 'TEAM' as const }

        const response = await (initialId === ''
          ? createView.mutateAsync({
              data: {
                ...viewToSave,
                creatorUserId: user.uid,
                ...target,
              },
            })
          : updateView.mutateAsync({
              viewId: initialId,
              data: { ...viewToSave },
            }))

        setViewState((prev) => ({ ...prev, viewId: response.id }))
        const newView = response.models.views[response.id]
        if (initialId) {
          setContext((prev) => ({
            ...prev,
            views: createEntityCache([
              ...prev.views.all.filter((x) => x.id !== initialId),
              newView,
            ] as TeamScheduleView[]),
          }))
        } else {
          setContext((prev) => ({
            ...prev,
            views: createEntityCache([
              ...prev.views.all,
              newView,
            ] as TeamScheduleView[]),
          }))
        }

        return newView
      }}
      onClose={props.close}
    />
  )
}

type CreateTeamViewModalProps = {
  view: TeamScheduleView
  onSave(view: TeamScheduleView): Promise<VersionedViewV2>
  onClose(): void
}

export const CreateTeamViewModal = (props: CreateTeamViewModalProps) => {
  const [name, setName] = useState(props.view.name)
  const [isPrivate, setIsPrivate] = useState(props.view.isPrivate)
  const isNew = !props.view.id

  return (
    <FormModal
      onClose={props.onClose}
      submitAction={{
        onAction: async () => {
          await props
            .onSave({
              ...props.view,
              name,
              isPrivate,
            })
            .then((response) => {
              showToast('success', 'Saved view')
              props.onClose()
              return response
            })
            .catch(() => {
              showToast('error', 'Failed to save view')
              return null
            })
        },
        disabled: name.length < 2,
      }}
      title={isNew ? 'Save view' : 'Update view'}
      visible
    >
      <div className='flex flex-col items-start gap-4 self-stretch min-w-[668px]'>
        <div className='flex flex-col w-full gap-4 justify-between'>
          <div className='w-full grid grid-cols-2 gap-[12px]'>
            <TextField
              autoFocus
              placeholder='View name'
              value={name}
              onChange={setName}
              size='normal'
            />
            <SearchableDropdown
              items={[true, false].map((item) => ({
                id: `${item}`,
                label: item ? 'Personal view' : 'Shared view',
                value: item,
              }))}
              renderItem={(item) => item.label}
              onChange={(item) => setIsPrivate(item.value)}
            >
              <PopoverButton className='h-[34px]'>
                {isPrivate ? 'Personal view' : 'Shared view'}
              </PopoverButton>
            </SearchableDropdown>
          </div>
          <ActiveFilterItemsWorkspaceContext>
            <FilterBar>
              <ConnectedFieldSelector />
              <ConnectedLegacyFilterButtons
                definitions={definitions}
                applyTo='tasks'
              />
            </FilterBar>
          </ActiveFilterItemsWorkspaceContext>
          <ConnectedWeekendsToggle />
        </div>
      </div>
    </FormModal>
  )
}
