import { type EntityFilterState } from '@motion/ui-logic/pm/data'
import { isEqual } from '@motion/utils/core'
import { diff } from '@motion/utils/object'
import { makeLog } from '@motion/web-base/logging'
import {
  type TeamScheduleViewDefinitionV2,
  type ViewDefinition,
  type ViewDefinitionV2,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

import {
  fromViewDefinitionFiltersToFilterState,
  toViewDefinitionFilters,
} from '../../../pm-v3/views/utils'
import {
  type TeamSchedulePivotColumn,
  type TeamScheduleViewState,
} from '../context'
import { type TeamScheduleView } from '../types'

const log = makeLog('team-schedule.views')
export const areViewsEqual = (
  left: ViewDefinitionV2,
  right: ViewDefinitionV2
) => {
  if (isEqual(left, right)) return true

  const changes = diff(left, right)
  log.debug('diff', changes, { left, right })
  return false
}

export function toViewDefinition(
  filterState: EntityFilterState,
  viewState: TeamScheduleViewState
): TeamScheduleViewDefinitionV2 {
  const filters = toViewDefinitionFilters(filterState)

  return {
    $version: 2,
    type: 'team-schedule',
    itemType: 'tasks',
    filters,
    sort: [
      {
        field: fromPivotColumn(viewState.column.field),
        direction: 'ASC',
      },
    ],

    grouping: {
      fields: [{ key: 'assignee' }],
      order: { assignee: viewState.rowOrder ?? [] },
      hideEmptyGroups: false,
      dateRange: 'month',
    },
    options: {
      hideWeekends: viewState.hideWeekends,
    },
  }
}

export function fromViewDefinition(view: TeamScheduleView): {
  filter: EntityFilterState
  view: TeamScheduleViewState
} {
  const definition = view.definition
  const filterState = fromViewDefinitionFiltersToFilterState(
    'tasks',
    definition.filters
  )

  const viewState: TeamScheduleViewState = {
    column: toPivotColumn(definition.sort[0]?.field ?? 'scheduledStart'),
    hideWeekends: definition.options.hideWeekends,
    viewId: view.id,
    rowOrder: definition.grouping.order['assignee'] ?? [],
  }
  return {
    filter: filterState,
    view: viewState,
  }
}

function fromPivotColumn(
  sortColumn: TeamSchedulePivotColumn['field']
): ViewDefinition['sortStrategy']['sortByCategory'] {
  switch (sortColumn) {
    case 'scheduledStart':
      return 'scheduled-date'
    case 'dueDate':
      return 'due-date'
    default:
      return 'scheduled-date'
  }
}

function toPivotColumn(
  sortColumn: ViewDefinition['sortStrategy']['sortByCategory']
): TeamSchedulePivotColumn {
  switch (sortColumn) {
    case 'scheduled-date':
      return {
        field: 'scheduledStart',
        from: DateTime.now().startOf('day').toISO(),
      }
    case 'due-date': {
      return {
        field: 'dueDate',
        from: DateTime.now().startOf('day').toISO(),
      }
    }
    default:
      return {
        field: 'scheduledStart',
        from: DateTime.now().startOf('day').toISO(),
      }
  }
}
