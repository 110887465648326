import { useFeedById } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { type ActivityFeedType, feedTypeToRpcType } from '../types'

export function useActivityFeed(
  type: ActivityFeedType['type'],
  targetId: string
) {
  const { data: v2Activities } = useFeedById(
    {
      type: feedTypeToRpcType[type],
      id: targetId,
    },
    {
      refetchInterval: false,
      // When activity feed is mounted, always refetch the data, even if it's already in the cache
      // Once mounted, WS events will keep the data up to date
      refetchOnMount: 'always',
    }
  )

  return useMemo(() => v2Activities ?? [], [v2Activities])
}
