import { Button, ButtonGroup } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { type V2CreateTaskStatusSchema } from '@motion/zod/client'

import { useState } from 'react'

import { WorkspaceStatusAutoScheduleCheckbox } from './workspace-status-auto-schedule-checkbox'

type AddWorkspaceStatusRowProps = {
  icon: React.ReactNode
  status: V2CreateTaskStatusSchema
  onCancel(): void
  onSave(status: V2CreateTaskStatusSchema): void
  placeholder?: string
  loading?: boolean
}
export const AddWorkspaceStatusRow = ({
  icon,
  onCancel,
  onSave,
  placeholder = 'Enter name',
  status,
  loading = false,
}: AddWorkspaceStatusRowProps) => {
  const [name, setName] = useState('')
  const [autoScheduleEnabled, setAutoScheduleEnabled] = useState<boolean>(
    Boolean(status.autoScheduleEnabled)
  )

  return (
    <div className='flex gap-3 items-center'>
      {icon}
      <div className='flex-grow'>
        <TextField
          autoFocus
          onChange={setName}
          size='normal'
          placeholder={placeholder}
          value={name}
        />
      </div>

      <WorkspaceStatusAutoScheduleCheckbox
        value={!autoScheduleEnabled}
        onChange={(checked) => {
          setAutoScheduleEnabled(!checked)
        }}
        statusName={name}
      />

      <ButtonGroup>
        <Button
          onClick={onCancel}
          variant='outlined'
          sentiment='neutral'
          size='small'
        >
          Cancel
        </Button>
        <Button
          disabled={name.length === 0}
          loading={loading}
          onClick={() =>
            onSave({
              ...status,
              name,
              autoScheduleEnabled,
            })
          }
          size='small'
        >
          Save
        </Button>
      </ButtonGroup>
    </div>
  )
}
