import {
  CalendarSolid,
  CalendarXSolid,
  CheckCircleSolid,
  ExclamationCircleSolid,
} from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import {
  formatTimeRange,
  formatToReadableWeekDayMonth,
  prettyDateDay,
} from '@motion/ui-logic'
import { byValue, Compare } from '@motion/utils/array'

import { StatusBadge } from '~/global/components/badges'
import { DateTime } from 'luxon'

import { RowButton } from './common'
import { type WithTaskProps } from './types'
import { isTaskPastDue } from './utils'

import { type TaskData, useTaskContextApi } from '../../../context/task-context'
import { type TaskSchema } from '../../../types'

export const ScheduledDateButton = (props: WithTaskProps) => {
  const { task } = props
  const info = usePastDueInfo(task)

  return (
    <Tooltip noPadding renderContent={() => <TooltipContent info={info} />}>
      <RowButton>
        <DueDateIcon pastDue={info.pastDue} />
      </RowButton>
    </Tooltip>
  )
}

type TooltipContentProps = {
  info: DeadlineInfo
}
const TooltipContent = ({ info }: TooltipContentProps) => {
  if (info.pastDue && info.dueDate && !info.task.isUnfit) {
    const endDate = getLastScheduledDate(info)

    const dateDiff = Math.ceil(
      endDate.diff(DateTime.fromISO(info.dueDate), 'day').days
    )
    const dayLabel = dateDiff === 1 ? 'day' : 'days'

    return (
      <div className='flex flex-col'>
        <div className='bg-semantic-error-bg-strong-default flex gap-1 px-2 py-1 items-center text-semantic-error-text-onDark text-xs font-medium'>
          <ExclamationCircleSolid
            width={12}
            height={12}
            className='text-semantic-error-icon-onDark'
          />
          Scheduled {dateDiff} {dayLabel} past deadline
        </div>
        <div className='flex flex-col items-start py-1 px-2'>
          <TooltipContentBody info={info} />
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col items-start py-1 px-2'>
      <TooltipContentBody info={info} />
    </div>
  )
}

function getLastScheduledDate(info: DeadlineInfo) {
  const lastChunk = info.chunks.findLast(Boolean)
  if (!lastChunk || !lastChunk.task.scheduledEnd) return DateTime.now()
  return DateTime.fromISO(lastChunk.task.scheduledEnd)
}

const TooltipContentBody = ({ info }: { info: DeadlineInfo }) => {
  const scheduledDates = info.scheduled.filter(Boolean)

  if (info.completed) {
    return `Completed on: ${formatToReadableWeekDayMonth(info.completed)}`
  }

  if (scheduledDates.length === 0) {
    return 'Not Scheduled'
  }

  if (info.scheduled.length === 1 && scheduledDates.length === 0) {
    return 'Not Scheduled'
  }

  if (scheduledDates.length === 1) {
    return `Scheduled on: ${formatToReadableWeekDayMonth(scheduledDates[0])}`
  }

  return (
    <>
      <div>This task is divided into {info.scheduled.length} chunks</div>
      <ol
        type='1'
        className='my-0 pl-0 flex flex-col items-start text-left gap-[2px]'
      >
        {info.chunks.map((chunk, idx) => {
          if (!chunk) return <div key={idx}>Not Scheduled</div>
          return (
            <li
              key={idx}
              className='list-decimal list-inside flex items-center gap-1'
            >
              <div className='w-4 text-center'>{idx + 1}.</div>
              <ScheduledStatus info={chunk} parentDueDate={info.dueDate} />
            </li>
          )
        })}
      </ol>
    </>
  )
}

type ScheduledStatusProps = {
  info: TaskData
  parentDueDate: string | null
}
const ScheduledStatus = ({ info, parentDueDate }: ScheduledStatusProps) => {
  const { task, status } = info
  const date = task.scheduledStart
  if (date == null) {
    return <div>Not Scheduled</div>
  }

  const timeRange =
    !task.scheduledStart || !task.scheduledEnd
      ? 'Unfit'
      : formatTimeRange(task.scheduledStart, task.scheduledEnd)

  const chunkIsPastDue =
    task.scheduledStart && parentDueDate && task.scheduledStart > parentDueDate

  return (
    <div className='items-center gap-1 inline-flex'>
      {task.completedTime != null ? (
        <CheckCircleSolid
          width={14}
          height={14}
          className='text-semantic-success-icon-default'
        />
      ) : chunkIsPastDue ? (
        <ExclamationCircleSolid
          width={14}
          height={14}
          className='text-semantic-error-icon-default'
        />
      ) : (
        <StatusBadge size='xsmall' value={status} hideTooltip />
      )}
      <span>{prettyDateDay(date)}</span>
      <span>({timeRange})</span>
    </div>
  )
}

type DueDateIconProps = {
  pastDue: boolean
}
const DueDateIcon = (props: DueDateIconProps) => {
  if (props.pastDue) {
    return (
      <CalendarXSolid
        width={10}
        height={10}
        className='text-semantic-error-icon-default hover:text-semantic-error-icon-hover'
      />
    )
  }
  return (
    <CalendarSolid
      width={10}
      height={10}
      className='text-semantic-neutral-icon-default hover:text-semantic-neutral-icon-hover'
    />
  )
}

type DeadlineInfo = {
  task: TaskSchema
  dueDate: string | null
  scheduled: (string | null)[]
  chunks: (TaskData | null)[]
  pastDue: boolean
  completed: string | null
}

const usePastDueInfo = (entryTask: TaskSchema): DeadlineInfo => {
  const api = useTaskContextApi()
  const info =
    entryTask.type === 'CHUNK'
      ? api.getTaskData(entryTask.parentChunkTaskId)
      : api.getTaskData(entryTask)

  if (info == null || info.task.type === 'CHUNK') {
    return {
      task: entryTask,
      dueDate: entryTask.dueDate,
      scheduled: [entryTask.scheduledStart],
      chunks: [info],
      pastDue: false,
      completed: entryTask.completedTime,
    }
  }

  const task = info.task
  if (task.chunkIds.length === 0) {
    return {
      task,
      dueDate: task.dueDate,
      scheduled: [task.duration === 0 ? task.dueDate : task.scheduledStart],
      chunks: [info],
      pastDue: isTaskPastDue(info),
      completed: entryTask.completedTime,
    }
  }

  const chunks = task.chunkIds
    .map((x) => api.getTaskData(x))
    .sort(byValue((x) => x?.task.scheduledStart, Compare.caseInsensitive))
  const lastChunk = chunks[chunks.length - 1]

  return {
    task,
    dueDate: task.dueDate,
    scheduled: chunks.map((x) => x?.task.scheduledStart ?? null),
    pastDue: lastChunk == null ? true : isTaskPastDue(lastChunk),
    chunks,
    completed: entryTask.completedTime,
  }
}
