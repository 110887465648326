import { getAnonymousId } from '@motion/web-base/analytics'

const segmentWriteKey = __IS_PROD__
  ? 'WQQU1TebtGFCogK5Ty5V9FnfqdLuSRKS'
  : 'CDoWkXaIAAxZYrjFZYr85W9aaZDitaAu'

const anonymousId = getAnonymousId()
const setAnonymousIdStr = anonymousId
  ? `analytics.setAnonymousId("${anonymousId}");`
  : ''

export const segmentScriptText = `
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://segmentcdn.usemotion.com/snippet/" + key;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentWriteKey}";analytics._cdn="https://segmentcdn.usemotion.com";analytics.SNIPPET_VERSION="4.15.3";
${setAnonymousIdStr}analytics.load("${segmentWriteKey}",{integrations:{"Hotjar":false}});
}}();
`
