import {
  byProperty,
  cascade,
  type CompareFn,
  ordered,
} from '@motion/utils/array'

import { type GroupDefinition, type GroupValue } from './types'

export function buildGroupComparator<T>(
  group: GroupDefinition<T>,
  valueOrder: string[] | undefined
): CompareFn<GroupValue<T>> {
  const defaultSort = group.sortBy ?? (() => 0)

  return valueOrder?.length
    ? cascade(
        byProperty<GroupValue<T>, 'key'>('key', ordered(valueOrder)),
        defaultSort
      )
    : defaultSort
}
