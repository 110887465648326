import { type UniqueIdentifier } from '@dnd-kit/core'

import { type ActiveItem } from './hooks'

import { TabHeader } from '../../components'
import { type GroupedNode, type Tree } from '../../grouping'
import { KanbanCard } from '../card'
import { KanbanColumn } from '../column'
import { Tab } from '../tab'

export const ActiveKanbanItem = <T extends GroupedNode>({
  activeItem,
  activeId,
  activeTabs,
}: {
  activeItem: ActiveItem
  activeId: UniqueIdentifier | null
  activeTabs: Tree<T>[]
}) => {
  switch (activeItem?.type) {
    case null:
      return null
    case 'tab':
      const tab = activeItem.item as Tree<T>

      return (
        <Tab display='tab' active={tab.key === activeTabs[0]?.key}>
          <TabHeader
            item={tab}
            active={tab.key === activeTabs[0]?.key}
            size='normal'
            shouldShowCountType
          />
        </Tab>
      )
    case 'pill':
      const pill = activeItem.item as Tree<T>

      return (
        <Tab display='pill' active={pill.key === activeTabs[1]?.key}>
          <TabHeader
            item={pill}
            active={pill.key === activeTabs[1]?.key}
            size='small'
            shouldShowCountType
          />
        </Tab>
      )
    case 'container':
      const container = activeItem.item as Tree<T>
      return <KanbanColumn activeId={activeId?.toString()} item={container} />
    case 'item':
      const item = activeItem.item as GroupedNode
      return <KanbanCard item={item} dragging />
  }
}
