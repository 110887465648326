import { parseCustomFieldInfoFromMaybeDelimitedKey } from '@motion/ui-logic'

import { UrlLabel } from '~/global/components/labels/url'

import { type CustomFieldUrlRow } from '../../../types'
import { GroupHeaderWithFieldName } from '../../group-headers'

type CustomFieldUrlGroupHeaderProps = {
  item: CustomFieldUrlRow & {
    key: string
  }
}
export function CustomFieldUrlGroupHeader({
  item,
}: CustomFieldUrlGroupHeaderProps) {
  const res = parseCustomFieldInfoFromMaybeDelimitedKey(item.type)
  if (res == null) return null
  const { name } = res

  return (
    <GroupHeaderWithFieldName fieldName={name}>
      <UrlLabel value={item.value ?? ''} />
    </GroupHeaderWithFieldName>
  )
}
