import { useMemo } from 'react'

import {
  useAvailableProjectGroups,
  useAvailableTaskGroups,
} from '../../grouping'
import { useViewState } from '../view-state'

export const useTaskGroupBy = () => {
  const [viewState] = useViewState()

  const availableGroups = useAvailableTaskGroups()

  return useMemo(
    () =>
      viewState.groupBy.fields
        .map((g) => availableGroups.find((x) => x.type === g.key))
        .filter(Boolean),
    [availableGroups, viewState.groupBy]
  )
}

export const useProjectGroupBy = () => {
  const [viewState] = useViewState()

  const availableGroups = useAvailableProjectGroups()

  return useMemo(
    () =>
      viewState.groupBy.fields
        .map((g) => availableGroups.find((x) => x.type === g.key))
        .filter(Boolean),
    [availableGroups, viewState.groupBy]
  )
}
