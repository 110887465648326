import { type ReactNode } from 'react'
import {
  UNSAFE_LocationContext as LocationContext,
  UNSAFE_RouteContext as RouteContext,
} from 'react-router'

const DEFAULT_ROUTE_CONTEXT = {
  outlet: null,
  matches: [],
  isDataRoute: false,
}

type ResetRouterProps = {
  children: ReactNode
}

/**
 * This resets the RouterContext and allows use to use a nest router.
 */
export const ResetRouter = (props: ResetRouterProps) => {
  return (
    <LocationContext.Provider value={null as never}>
      <RouteContext.Provider value={DEFAULT_ROUTE_CONTEXT}>
        {props.children}
      </RouteContext.Provider>
    </LocationContext.Provider>
  )
}
