import { CalendarSolid } from '@motion/icons'
import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

import { useUpdateProjectStageDueDate } from '~/areas/project/hooks'
import { DeadlineDropdown } from '~/areas/project-management/components'
import { ConnectedStageLabel } from '~/global/components/labels'

import { type StageEtaPopoverContentProps } from './types'

import {
  PopoverExtendDeadlineContainer,
  PopoverExtendDeadlineText,
} from '../styled'

export const StageExtendDeadlineRow = ({
  project,
  stage,
  onClose,
}: StageEtaPopoverContentProps) => {
  const updateStageDeadline = useUpdateProjectStageDueDate()

  const changeDeadline = (value: string) => {
    updateStageDeadline(project.id, stage.stageDefinitionId, value)
    onClose()
  }

  return (
    <div className='flex items-center justify-between gap-2'>
      <PopoverExtendDeadlineContainer>
        <CalendarSolid className='size-4' />
        <PopoverExtendDeadlineText>
          {templateStr('Extend {{stage}} deadline', {
            stage: (
              <div className='max-w-[120px]'>
                <ConnectedStageLabel
                  size='small'
                  id={stage.stageDefinitionId}
                />
              </div>
            ),
          })}
        </PopoverExtendDeadlineText>
      </PopoverExtendDeadlineContainer>
      <DeadlineDropdown
        value={stage.dueDate}
        onChange={changeDeadline}
        hideTimeOptions
        contextProps={{ projectId: project.id }}
        dropdownTarget='project'
      >
        <Button sentiment='primary' variant='link' size='small'>
          Choose date
        </Button>
      </DeadlineDropdown>
    </div>
  )
}
