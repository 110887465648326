import { FormModal, PopoverButton, showToast } from '@motion/ui/base'
import { formatTime } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { SearchableTimeDropdown } from '~/areas/project-management/components'
import { DateTime } from 'luxon'
import { useState } from 'react'

import { Paragraph } from '../../../../components/Common'
import { useCreateBookingTimeslot } from '../../../global/rpc'

export type ConnectedBlockOutHoursModalProps = {
  initialTime: DateTime
}

export const BlockOutHoursModal = ({
  close,
  initialTime,
}: ModalTriggerComponentProps<'block-out-hours'>) => {
  const [startValue, setStartValue] = useState(initialTime.toISO())
  const [endValue, setEndValue] = useState(
    initialTime.plus({ hours: 1 }).toISO()
  )

  const startConstraints = {
    start: initialTime.set({ hour: 0 }),
    end: initialTime.set({ hour: 23, minute: 30 }),
  }
  const endConstraints = {
    start: initialTime.set({ hour: 0, minute: 30 }),
    end: initialTime.set({ hour: 23, minute: 59 }),
  }

  const { mutateAsync: createBookingTimeslot } = useCreateBookingTimeslot()

  const onAction = async () => {
    recordAnalyticsEvent('FLEXIBLE_HOURS_BLOCK_OUT_HOURS')
    try {
      await createBookingTimeslot({
        startTime: startValue,
        endTime: endValue,
      })

      showToast(
        'success',
        'Your hours have changed. Tasks will be rescheduled!'
      )

      close()
    } catch (e) {
      showToast('error', 'Error blocking out time')
    }
  }

  const onStartTimeChange = (value: DateTime) => {
    setStartValue(value.toISO())

    if (value > startConstraints.end) {
      setEndValue(endConstraints.end.toISO())
      return
    }

    if (value >= DateTime.fromISO(endValue)) {
      const newEndTimeDate = value.plus({ hours: 1 })
      setEndValue(newEndTimeDate.toISO())
    }
  }

  const onEndTimeChange = (value: DateTime) => {
    setEndValue(value.toISO())

    if (value <= endConstraints.start) {
      setStartValue(startConstraints.start.toISO())
      return
    }

    if (value <= DateTime.fromISO(startValue)) {
      const newStartTimeDate = value.minus({ hours: 1 })
      setStartValue(newStartTimeDate.toISO())
    }
  }

  const formattedStart = formatTime(startValue)
  const formattedEnd = formatTime(endValue)

  return (
    <FormModal
      onClose={close}
      submitAction={{
        text: 'Apply',
        onAction,
      }}
      title='Block out hours'
      visible
    >
      <div className='flex flex-row items-center gap-2 w-[400px]'>
        <Paragraph>Block out</Paragraph>
        <SearchableTimeDropdown
          startDateTime={initialTime.startOf('day')}
          value={formattedStart}
          onChange={(val) => {
            onStartTimeChange(val)
          }}
        >
          <div>
            <PopoverButton>{formattedStart}</PopoverButton>
          </div>
        </SearchableTimeDropdown>
        <Paragraph>-</Paragraph>
        <SearchableTimeDropdown
          startDateTime={initialTime.startOf('day')}
          value={formattedEnd}
          onChange={(val) => {
            onEndTimeChange(val)
          }}
        >
          <div>
            <PopoverButton>{formattedEnd}</PopoverButton>
          </div>
        </SearchableTimeDropdown>
      </div>
    </FormModal>
  )
}
