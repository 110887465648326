import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { Sentry } from '@motion/web-base/sentry'
import {
  type StatusSchema,
  type V2CreateLabelsSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { useUpdateLabel as useUpdateLabelCall } from '~/global/rpc/v2'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

export const useUpdateLabel = () => {
  const {
    mutateAsync: updateWorkspaceLabel,
    isLoading,
    isError,
  } = useUpdateLabelCall()

  return {
    updateLabel: useCallback(
      async (
        data: Partial<V2CreateLabelsSchema> & {
          id: StatusSchema['id']
          workspaceId: WorkspaceSchema['id']
        }
      ) => {
        if (data.name != null && data.name.trim().length < 1) {
          showToast('error', 'Label name cannot be empty')
          return null
        }

        recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_WORKSPACE_LABEL')

        try {
          const { id: labelId, workspaceId, name, ...rest } = data
          const response = await updateWorkspaceLabel({
            workspaceId,
            labelId,
            name: name != null ? name.trim() : undefined,
            ...rest,
          })

          showToast('success', 'Label updated')

          return response.models[response.meta.model][response.id]
        } catch (e) {
          Sentry.captureException(e, {
            tags: {
              position: 'update-label',
            },
          })

          showErrorToast(e)
        }

        return null
      },
      [updateWorkspaceLabel]
    ),
    isLoading,
    isError,
  }
}
