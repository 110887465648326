import { useSharedState } from '@motion/react-core/shared-state'
import { toDataFilter, useActiveFilter } from '@motion/ui-logic/pm/data'
import { byProperty, byValue, Compare } from '@motion/utils/array'
import { parseDate } from '@motion/utils/dates'
import {
  type GetTasksV2FilterWithOperatorsSchema,
  type TaskSchema,
} from '@motion/zod/client'

import { useTaskLoader } from '~/areas/tasks/context'
import { useMemo } from 'react'

import { TeamScheduleActiveViewOptionsKey } from '../context'

const byScheduledStart = byValue<TaskSchema, string>(
  (task) => ('scheduledStart' in task ? (task.scheduledStart ?? '') : ''),
  Compare.caseInsensitive
)
const byName = byProperty<TaskSchema, 'name'>('name', Compare.caseInsensitive)

export const ConnectedTeamScheduleTaskFetch = () => {
  const [viewState] = useSharedState(TeamScheduleActiveViewOptionsKey)
  const [filterState] = useActiveFilter()

  const staticFilters = useMemo(() => {
    const firstDay = parseDate(viewState.column.from)
    const dateField = viewState.column.field

    return {
      [dateField]: {
        operator: 'range',
        value: {
          from: firstDay.toISO(),
          to: firstDay.plus({ day: 7 }).startOf('day').toISO(),
        },
      },
      type:
        viewState.column.field === 'dueDate'
          ? ['NORMAL', 'RECURRING_INSTANCE']
          : ['NORMAL', 'RECURRING_INSTANCE', 'CHUNK'],
      recurring: 'ALL',
      completed: 'include',
    } as GetTasksV2FilterWithOperatorsSchema
  }, [viewState.column.field, viewState.column.from])

  useTaskLoader({
    filter: toDataFilter(filterState),
    sortBy:
      viewState.column.field === 'scheduledStart' ? byScheduledStart : byName,
    queryOverrides: staticFilters,
  })

  return null
}
