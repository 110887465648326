import { AttachmentOutline } from '@motion/icons'
import { classed, type ComponentProps } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { type AttachmentsModalProps } from '~/areas/attachments/modals'
import { type MouseEvent } from 'react'

export type AttachmentCountButtonProps = ComponentProps<typeof StyledButton> & {
  targetId: AttachmentsModalProps['targetId']
  targetType: AttachmentsModalProps['targetType']
  count?: number
  derivedCount?: number
}

export const AttachmentCountButton = ({
  targetId,
  targetType,
  count,
  derivedCount,
  ...rest
}: AttachmentCountButtonProps) => {
  const modalsApi = useModalApi()

  if (!count) return null

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    modalsApi.open('attachments', {
      targetId,
      targetType,
    })
  }

  return (
    <StyledButton
      sentiment='neutral'
      variant='muted'
      size='xsmall'
      onClick={handleClick}
      {...rest}
    >
      <AttachmentOutline
        width={14}
        height={14}
        className='!text-semantic-neutral-icon-subtle'
      />
      {count}
      {derivedCount && ` (${derivedCount})`}
    </StyledButton>
  )
}

const StyledButton = classed(Button, {
  base: `
    flex items-center gap-1 px-1
    text-semantic-neutral-text-subtle font-normal
  `,
})
