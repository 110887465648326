import { useProjects } from './hooks'
import { ProjectPlanner } from './project-planner'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { useProjectGroupBy, useViewState } from '../view-state'

export const ConnectedProjectPlanner = () => {
  const groupDefinitions = useProjectGroupBy()
  const [viewState] = useViewState()
  const projects = useProjects()

  if (projects == null) {
    return <LoadingPage />
  }

  return (
    <ProjectPlanner
      projects={projects}
      groupBy={groupDefinitions}
      hideEmptyGroups={viewState.groupBy.hideEmpty}
    />
  )
}
