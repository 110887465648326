import { type IndexCappedAt100 } from '@motion/web-base/analytics/events'

/**
 * Create a capped index at 100.
 * @param index
 * @returns
 */
export function createCappedIndex(index: number): IndexCappedAt100 {
  return Math.min(index, 100) as IndexCappedAt100
}
