import { useAllWorkspaces } from '~/global/hooks'

export const useHasCreatedWorkspace = () => {
  const allWorkspaces = useAllWorkspaces()

  const customWorkspaces = allWorkspaces.filter(
    (workspace) =>
      workspace.name !== 'My Tasks (Private)' &&
      workspace.name !== 'My Team' &&
      !workspace.name.endsWith(' - Onboardings')
  )

  return customWorkspaces.length > 0
}
