export function join(left: string, right: string | undefined) {
  if (!right) return left

  if (left.endsWith('/')) {
    left = left.slice(0, -1)
  }
  if (right.startsWith('/')) {
    right = right.slice(1)
  }

  const joined = [left, right].filter(Boolean).join('/')
  if (joined.startsWith('/')) return joined
  return '/' + joined
}
