import { type DashboardViewChartSchema } from '@motion/zod/client'

import { type Layout } from 'react-grid-layout'

export const chartDefaultLayoutSettings: Record<
  DashboardViewChartSchema['type'],
  Partial<Layout>
> = {
  number: {
    minH: 1,
    minW: 2,
  },
  bar: {
    minH: 2,
    minW: 4,
  },
  line: {
    minH: 2,
    minW: 4,
  },
  pie: {
    minH: 2,
    minW: 4,
  },
}
