import { Toaster } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'

import { FileUploadProvider } from '~/areas/attachments/contexts'
import {
  useDesktopGlobalEvents,
  useShouldDeeplink,
  useUserMustUpdate,
} from '~/areas/desktop/hooks'
import { GlobalTaskProjectModals } from '~/areas/modals'
import { GlobalMeetingModal } from '~/areas/modals/global-meeting-modal'
import { CollectPageData } from '~/areas/project-management/pages/pm-v3/routes/hooks/use-v3-page-data'
import { SidebarSearchContextProvider } from '~/areas/search'
import { RouteAnalyticsMetadata } from '~/global/analytics'
import { ModalListOrderProvider } from '~/global/contexts'
import { NavigationContextProvider } from '~/global/contexts/navigation-context'
import { useGlobalKeyboardShortcuts } from '~/global/hooks'
import { useGetCalendars } from '~/global/rpc/v2'
import { memo, useEffect, useState } from 'react'
import { Outlet } from 'react-router'

import { DesktopUserMustUpdate } from '../../areas/desktop/components'
import desktopLogo from '../../assets/logo_desktop.png'
import { RecordPageView } from '../../localServices/performance/record-page-view'
import { useMotionLocalStorage } from '../../localServices/storage'
import { AnnualUpgradeModal } from '../../modals'
import { setCalendarStartDay } from '../../state/calendar/calendarSlice'
import { registerCalendarWebsocketSubscribers } from '../../state/calendar-list/calendar-list-websocket-subscribers'
import { registerWebsocketSubscribers } from '../../state/calendarEvents/calendarEventsWebsocketEvents'
import { fetchAllEmailAccounts } from '../../state/email-accounts/email-accounts-thunks'
import { useEmailAccountWebSocketSubscription } from '../../state/email-accounts/email-accounts-websocket-subscribers'
import { useAppDispatch } from '../../state/hooks'
import { registerOnboardingWebsocketSubscribers } from '../../state/onboarding/onboardingWebsocketEvents'
import { OpenInDesktopAppAlertWrapper } from '../Alerts/OpenInDesktopApp/OpenInDesktopAppWrapper'
import { CalendarListPickerModal } from '../Calendar/components/Modals/calendar-list-picker-modal/calendar-list-picker-modal'
import { ModalContainer } from '../ModalHandlers/ModalContainer'
import { OpenDesktopAppPage } from '../OpenDesktopAppPage/OpenDesktopAppPage'

export const MotionTabShell = () => {
  return (
    <RouteAnalyticsMetadata>
      <NavigationContextProvider>
        <FileUploadProvider>
          <ModalListOrderProvider>
            <Toaster>
              <CollectPageData>
                <DesktopEventWrapper>
                  <MotionTabShellInternal />
                </DesktopEventWrapper>
              </CollectPageData>
            </Toaster>
          </ModalListOrderProvider>
        </FileUploadProvider>
      </NavigationContextProvider>
    </RouteAnalyticsMetadata>
  )
}

const MotionTabShellInternal = memo(function MotionTabShellInternal() {
  const [upgradeShow, setUpgradeShow] = useState(false)

  useGlobalKeyboardShortcuts()

  const dispatch = useAppDispatch()

  const user = useAuthenticatedUser()
  const { stripeSubscription, calendarStartDay } = useMotionLocalStorage([
    'stripeSubscription',
    'calendarStartDay',
  ])

  // Preload calendars
  useGetCalendars({
    enabled: user.uid != null,
  })

  useEffect(() => {
    if (calendarStartDay) {
      dispatch(setCalendarStartDay(calendarStartDay))
    }
  }, [calendarStartDay, dispatch])

  useEffect(function initSubscribers() {
    const subscriptions = [
      registerWebsocketSubscribers(),
      registerCalendarWebsocketSubscribers(),
      registerOnboardingWebsocketSubscribers(),
    ]
    return () => {
      subscriptions.forEach((unsub) => unsub?.())
    }
  }, [])
  useEmailAccountWebSocketSubscription()

  useEffect(() => {
    if (!user.uid) return

    void dispatch(fetchAllEmailAccounts())
  }, [dispatch, user.uid])

  return (
    <>
      <SidebarSearchContextProvider>
        <div className='flex flex-col gap-2 h-full min-h-0'>
          <Outlet />
        </div>

        <RecordPageView />
      </SidebarSearchContextProvider>

      <ModalContainer />
      <GlobalTaskProjectModals />
      <GlobalMeetingModal />

      {upgradeShow && stripeSubscription ? (
        <AnnualUpgradeModal
          subscription={stripeSubscription}
          onClose={() => setUpgradeShow(false)}
        />
      ) : null}

      <CalendarListPickerModal />
    </>
  )
})

const DesktopEventWrapper = memo(function DesktopShell({
  children,
}: {
  children: React.ReactNode
}) {
  useDesktopGlobalEvents()

  const {
    showOpenDesktopPage,
    setShowOpenDesktopPage,
    showOpenDesktopPopup,
    setShowOpenDesktopPopup,
  } = useShouldDeeplink()

  // Confirm if user must update desktop app
  const desktopUserMustUpdate = useUserMustUpdate()

  if (desktopUserMustUpdate) {
    return <DesktopUserMustUpdate />
  }

  if (showOpenDesktopPage) {
    return (
      <OpenDesktopAppPage
        imgUrl={desktopLogo}
        onDismiss={() => {
          recordAnalyticsEvent('DESKTOP_DEEPLINK_OPEN_IN_WEB')
          setShowOpenDesktopPage(false)
        }}
      />
    )
  }

  return (
    <>
      <OpenInDesktopAppAlertWrapper
        isVisible={showOpenDesktopPopup}
        setIsVisible={setShowOpenDesktopPopup}
      />
      {children}
    </>
  )
})
