import { showRecalculatingTasksToast } from '~/areas/tasks/utils'
import { dangerouslyTriggerUpdateFunction } from '~/state/calendar/calendarThunks'
import { useAppDispatch } from '~/state/hooks'
import { useCallback, useState } from 'react'

export const useRefreshTasks = (): [boolean, () => Promise<void>] => {
  const dispatch = useAppDispatch()
  const [scheduleLoading, setScheduleLoading] = useState(false)

  const refreshTasks = useCallback(async () => {
    try {
      setScheduleLoading(true)
      // TODO: Confirm what to replace this with since depr
      await dispatch(dangerouslyTriggerUpdateFunction())
      showRecalculatingTasksToast()
    } finally {
      setScheduleLoading(false)
    }
  }, [dispatch])

  return [scheduleLoading, refreshTasks]
}
