import { DateTime } from 'luxon'

import { useSendAgendaCalendarState } from './use-agenda-calendar-state'

export function useAgendaCalendarActions() {
  const sendCalendarState = useSendAgendaCalendarState()

  const goToNextDay = () => {
    sendCalendarState((state) => ({
      ...state,
      selectedDate: state.selectedDate.plus({ days: 1 }),
      selectedDateManuallyChanged: true,
    }))
  }

  const goToPrevDay = () => {
    sendCalendarState((state) => ({
      ...state,
      selectedDate: state.selectedDate.minus({ days: 1 }),
      selectedDateManuallyChanged: true,
    }))
  }

  const goToToday = () => {
    sendCalendarState({
      selectedDate: DateTime.local(),
      selectedDateManuallyChanged: false,
    })
  }

  return {
    goToNextDay,
    goToPrevDay,
    goToToday,
  }
}
