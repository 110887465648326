import { PencilSolid, PuzzleSolid } from '@motion/icons'
import { IconButton, Tooltip } from '@motion/ui/base'
import {
  type ProjectDefinitionSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import { MotionLink, StageTags } from '~/global/components'

import { FlowTemplateCard } from './styled'

export type FlowTemplateCardLinkProps = {
  name: ProjectDefinitionSchema['name']
  stages: readonly Pick<StageDefinitionSchema, 'name' | 'color' | 'id'>[]
  url: string
  editTemplateUrl?: string
}

export function FlowTemplateCardLink({
  name,
  stages,
  url,
  editTemplateUrl,
}: FlowTemplateCardLinkProps) {
  return (
    <MotionLink url={url}>
      <FlowTemplateCard>
        <div className='flex flex-1 flex-col px-3 pt-3 relative truncate'>
          <StageTags stages={stages} isColumn hideScroll />
          <div className='h-8 absolute left-0 right-0 bottom-0 bg-gradient-to-t from-modal-bg to-transparent ' />
        </div>

        <div className='p-3 flex gap-2 items-center h-10'>
          <PuzzleSolid className='h-4 w-4 text-semantic-neutral-icon-default' />
          <h3 className='font-semibold text-sm truncate text-semantic-neutral-text-default'>
            {name}
          </h3>
        </div>

        {editTemplateUrl && (
          <div className='fixed right-1.5 top-1.5'>
            <Tooltip content='Edit Template'>
              <IconButton
                icon={PencilSolid}
                sentiment='neutral'
                size='small'
                url={editTemplateUrl}
                aria-label='Edit Template'
              />
            </Tooltip>
          </div>
        )}
      </FlowTemplateCard>
    </MotionLink>
  )
}
