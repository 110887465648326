import { createPlaceholderId } from '@motion/shared/identifiers'
import { getDefaultChunkDuration } from '@motion/ui-logic'
import { uniqueId } from '@motion/utils/core'

import {
  DEFAULT_EVENT_DURATION,
  DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
} from '~/areas/flows/utils'

import {
  type CreateProjectDefinitionSchemaWithoutWorkspaceFields,
  getVariableKeyByName,
  getWrappedVariableKeyByName,
} from './utils'

import {
  createNewRole,
  createNewTextVariable,
  DEFAULT_TEXT_VARIABLES,
} from '../../utils'

export const clientServicesPreset = () => {
  const roles = [
    createNewRole('Account Manager'),
    createNewRole('Project Manager'),
    createNewRole('QA Specialist'),
    createNewRole('Finance Specialist'),
  ]
  const textVariables = [
    ...DEFAULT_TEXT_VARIABLES,
    createNewTextVariable('Client Name'),
    createNewTextVariable('Client ID'),
  ]

  return {
    name: 'Client Onboarding and Project Delivery',
    description:
      '<p>Use this template if you run a client services business</p>',
    managerId: null,
    labelIds: [],
    folderId: undefined,
    priorityLevel: 'MEDIUM' as const,
    color: 'gray' as const,
    variables: [...roles, ...textVariables],
    stages: [
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Client Onboarding',
        color: 'teal',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 5,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Collect Client Requirements`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Set up client account`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Send Welcome Email`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 15,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(15),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Project Kickoff with ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Project Planning',
        color: 'purple',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 11,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Develop project scope`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create Project Timeline`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Establish Communication Channels`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Prepare Risk Management Plan`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `Review project plan with stakeholders for ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Execution',
        color: 'yellow',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 32,
        tasks: [
          {
            name: `Setting up check-in with ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create and assign Project Tasks`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create Project Milestones`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Review & QA',
        color: 'blue',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 15,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create Quality Plan`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('QA Specialist', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Conduct Testing`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('QA Specialist', roles),
            duration: 960,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(960),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Approve Testing Results`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 60,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(60),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Delivery',
        color: 'green',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 7,
        tasks: [
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Create Training Materials`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: 240,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(240),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Deliver Project to Client`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
      {
        id: createPlaceholderId(uniqueId('stage')),
        name: 'Closure',
        color: 'pink',
        automaticallyMoveToNextStage: true,
        deadlineIntervalDays: 3,
        tasks: [
          {
            name: `Post-delivery review with ${getWrappedVariableKeyByName('Client Name', textVariables)}`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Project Manager', roles),
            duration: DEFAULT_EVENT_DURATION,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(DEFAULT_EVENT_DURATION),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: DEFAULT_SCHEDULE_MEETING_WITHIN_DAYS,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Billing & Invoicing (${getWrappedVariableKeyByName('Client ID', textVariables)})`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName(
              'Finance Specialist',
              roles
            ),
            duration: 120,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(120),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
          {
            name: `${getWrappedVariableKeyByName('Client Name', textVariables)} - Reach out to customer for future projects`,
            assigneeUserId: null,
            assigneeVariableKey: getVariableKeyByName('Account Manager', roles),
            duration: 30,
            priorityLevel: 'MEDIUM' as const,
            isAutoScheduled: true,
            description: '',
            labelIds: [],
            minimumDuration: getDefaultChunkDuration(30),
            id: createPlaceholderId(uniqueId('task')),
            blockedByTaskId: null,
            scheduleMeetingWithinDays: null,
          },
        ],
      },
    ],
  } satisfies CreateProjectDefinitionSchemaWithoutWorkspaceFields
}
