import { type VariantProps } from '@motion/theme'
import type { StageDefinitionSchema } from '@motion/zod/client'

import { getBadgeSizeInPixels, StageBadge } from '~/global/components/badges'
import { type DateTime } from 'luxon'

import {
  DateTimeContainer,
  ProjectDateLabel,
  StageDateLabel,
  StageItemContainer,
} from './styled'

type DateTimeRowProps = {
  startDate: DateTime
  endDate: DateTime
}

export function DateTimeRow({ startDate, endDate }: DateTimeRowProps) {
  return (
    <DateTimeContainer>
      <ProjectDateText date={startDate} />
      <ProjectDateText date={endDate} />
    </DateTimeContainer>
  )
}

function ProjectDateText({ date }: { date: DateTime }) {
  return <ProjectDateLabel>{date.toFormat('EEE MMM d')}</ProjectDateLabel>
}

type StageItemProps = {
  stageDefinition: StageDefinitionSchema
  positionPercentage: number
  date: DateTime
  rotatedText?: VariantProps<typeof StageDateLabel>['rotated']
  showText?: boolean
}

export function StageItem({
  stageDefinition,
  positionPercentage,
  date,
  rotatedText,
  showText = true,
}: StageItemProps) {
  const stageBadgeSize = 'normal'
  const stageIconWidth = getBadgeSizeInPixels(stageBadgeSize)

  return (
    <StageItemContainer
      style={{
        left: `max(0px, calc(${positionPercentage}% - ${stageIconWidth}px))`,
      }}
    >
      <StageBadge value={stageDefinition} size={stageBadgeSize} />
      {showText && <StageDateText date={date} rotated={rotatedText} />}
    </StageItemContainer>
  )
}

function StageDateText({
  date,
  rotated,
}: {
  date: DateTime
  rotated: VariantProps<typeof StageDateLabel>['rotated']
}) {
  return (
    <StageDateLabel rotated={rotated}>{date.toFormat('M/d')}</StageDateLabel>
  )
}
