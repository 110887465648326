import { useDelayedUnmount } from '@motion/react-core/hooks'
import { UnstyledCollapsableContainer } from '@motion/ui/base'
import { useHasTreatment } from '@motion/web-common/flags'

import { type Row } from '@tanstack/react-table'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { lookupHeader } from './lookup-header'

import { type SortBy } from '../../../utils'
import { useSidebarTaskContext } from '../context'
import {
  InlineTaskCreationForm,
  type InlineTaskCreationFormProps,
} from '../inline-create'
import { HEADER_GROUP_HEIGHT } from '../utils'

export type SidebarTasksGroupHeaderProps = {
  sort: keyof typeof SortBy
  groupId: string
  index: number
  measureElement?: (node: HTMLDivElement | null) => void
  row?: Row<any>
  isAddTaskExpanded: boolean
  toggleAddTaskExpanded: () => void
}

export function SidebarTasksGroupHeader({
  sort,
  groupId,
  index,
  measureElement,
  row,
  isAddTaskExpanded,
  toggleAddTaskExpanded,
}: SidebarTasksGroupHeaderProps) {
  const { enableInlineAdd, workspaceId, projectId, isProjectModal } =
    useSidebarTaskContext()
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')

  const [initialAddFormData, setInitialAddFormData] =
    useState<InlineTaskCreationFormProps['initialData']>(undefined)
  const mounted = useDelayedUnmount(isAddTaskExpanded, 200)

  if (sort !== 'STAGES' && sort !== 'STATUS') {
    return null
  }

  const toggle = (initialData?: InlineTaskCreationFormProps['initialData']) => {
    toggleAddTaskExpanded()

    setInitialAddFormData(initialData)
  }

  return (
    <UnstyledCollapsableContainer
      expanded={isAddTaskExpanded}
      toggle={toggle}
      renderHeaderInline
      renderHeader={() => (
        <div
          data-index={index}
          ref={measureElement}
          className={twMerge(
            'sticky top-0 z-[2] transition-height flex flex-row items-center justify-between pl-3.5 py-2 pr-2 overflow-hidden',
            hasBetterResizeStages && sort === 'STAGES' && 'pl-1 flex-shrink-0',
            isProjectModal
              ? 'bg-semantic-neutral-surface-raised-bg-subtle'
              : 'bg-semantic-neutral-surface-overlay-bg-default'
          )}
          style={{
            height: HEADER_GROUP_HEIGHT,
          }}
        >
          {lookupHeader(sort)({ groupId, toggle, row })}
        </div>
      )}
    >
      {mounted && enableInlineAdd && (
        <InlineTaskCreationForm
          close={toggleAddTaskExpanded}
          workspaceId={workspaceId}
          projectId={projectId}
          initialData={
            sort === 'STAGES'
              ? {
                  stageDefinitionId: groupId,
                  ...initialAddFormData,
                }
              : initialAddFormData
          }
        />
      )}
    </UnstyledCollapsableContainer>
  )
}
