import type { ScheduledTaskWithRelation } from '~/global/proxies'
import { useTodaysScheduledEntities } from '~/global/upcoming-event/hooks'
import {
  getNextEvent,
  type GetNextEventOptions,
} from '~/global/upcoming-event/utils'
import { useMemo } from 'react'

export type UseNextAgendaTaskProps = GetNextEventOptions

export function useNearestAgendaTask(
  props: UseNextAgendaTaskProps = {}
): ScheduledTaskWithRelation | null {
  const upcomingScheduledEntities = useTodaysScheduledEntities()

  return useMemo(() => {
    const upcomingTasks: ScheduledTaskWithRelation[] =
      upcomingScheduledEntities.filter(
        (entity) => entity.type === 'TASK' || entity.type === 'CHUNK'
      )

    return getNextEvent(upcomingTasks, {
      onlyToday: true,
      ignoreUpcomingEvents: true,
      ...props,
    })
  }, [props, upcomingScheduledEntities])
}
