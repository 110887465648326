import {
  ChevronDoubleLeftSolid,
  CogSolid,
  QuestionMarkCircleSolid,
  SearchSolid,
} from '@motion/icons'
import { IconButton, Shortcut, Tooltip } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import { type User } from '@motion/web-common/auth'

import { UserAccountLink } from './user-account-link'

type SidebarHeaderProps = {
  closeSidebar: () => void
  handleSearch: () => void
  showCloseSidebar: boolean
  toggleIntercom: () => void
  user: User
}

export const SidebarHeader = ({
  closeSidebar,
  handleSearch,
  showCloseSidebar,
  toggleIntercom,
  user,
}: SidebarHeaderProps) => {
  return (
    <div className='self-stretch flex justify-between pr-3 pl-1.5'>
      <div className='min-w-0 [&_button]:max-w-full'>
        <UserAccountLink
          user={{
            ...user,
            id: user.uid,
            displayName: user.displayName?.split(' ')[0] || '',
          }}
        />
      </div>

      <div className='flex items-center'>
        <IconButton
          data-testid='navbar-intercom'
          icon={QuestionMarkCircleSolid}
          onClick={toggleIntercom}
          size='small'
          sentiment='neutral'
          variant='muted'
        />

        <IconButton
          data-testid='navbar-settings'
          icon={CogSolid}
          sentiment='neutral'
          size='small'
          url='/web/settings'
          variant='muted'
        />

        <Tooltip
          asChild
          renderContent={() =>
            templateStr('Search {{shortcut}}', {
              shortcut: <Shortcut shortcut='mod+k' />,
            })
          }
        >
          <IconButton
            icon={SearchSolid}
            onClick={handleSearch}
            sentiment='neutral'
            size='small'
            variant='outlined'
          />
        </Tooltip>

        {showCloseSidebar && (
          <IconButton
            icon={ChevronDoubleLeftSolid}
            onClick={closeSidebar}
            sentiment='neutral'
            size='small'
            variant='muted'
          />
        )}
      </div>
    </div>
  )
}
