import { XSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'

type CancelModalListItemProps = VariantProps<
  typeof StyledCancelModalListItem
> & {
  text: string
}

export const CancelModalListItem = ({
  text,
  small,
}: CancelModalListItemProps) => {
  return (
    <StyledCancelModalListItem small={small}>
      <XSolid className='h-4 w-4 text-semantic-error-icon-default' />
      {text}
    </StyledCancelModalListItem>
  )
}

const StyledCancelModalListItem = classed('div', {
  base: 'flex flex-row items-center gap-2 text-sm text-semantic-neutral-text-default',
  variants: {
    small: { true: 'text-xs' },
  },
})
