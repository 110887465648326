import { websocketsService } from '~/services/websockets-service'
import { useEffect } from 'react'

export const useSubcribeToFeed = (
  type: 'task' | 'project',
  id: string | undefined
) => {
  useEffect(() => {
    if (!id) return
    return websocketsService.join({ room: 'feed', args: { id, type } })
  }, [id, type])
}
