import { API, createUseMutation, createUseQuery } from '@motion/rpc'

export const useGetTutorialPayload = createUseQuery(
  API.tutorials.getTutorialPayload
)

export const useGetUserTutorialCompletion = createUseQuery(
  API.tutorials.getUserTutorialCompletion
)

export const useUpdateTutorialCompletion = createUseMutation(
  API.tutorials.addUserTutorialCompletion
)
