import { BillingSection } from './billing-section'

export const IapSection = () => {
  return (
    <BillingSection className='p-4'>
      Your subscription is managed by the Apple App Store. To make changes to
      your subscription, please do so through the App Store.
    </BillingSection>
  )
}
