export const Events = {
  // background
  PULSE: 'PULSE',
  THEME_CHANGED: 'THEME_CHANGED',

  // sidebar
  SIDEBAR_TUTORIAL_CLICK: 'SIDEBAR_TUTORIAL_CLICK',

  // calendar
  CALENDAR_SAVE_EVENT_CLICK: 'CALENDAR_SAVE_EVENT_CLICK',
  CALENDAR_CLOSE_BUTTON_CLICK: 'CALENDAR_CLOSE_BUTTON_CLICK',
  CALENDAR_JOIN_CALL_CLICK: 'CALENDAR_JOIN_CALL_CLICK',
  CALENDAR_REMINDER_JOIN_CALL: 'CALENDAR_REMINDER_JOIN_CALL',
  CALENDAR_CHECKBOX_CLICK: 'CALENDAR_CHECKBOX_CLICK',
  CALENDAR_ADD_ACCOUNT_CLICK: 'CALENDAR_ADD_ACCOUNT_CLICK',
  CALENDAR_REMOVE_ACCOUNT_CLICK: 'CALENDAR_REMOVE_ACCOUNT_CLICK',
  CALENDAR_ACCOUNT_MODAL_MAIN_SWITCH: 'CALENDAR_ACCOUNT_MODAL_MAIN_SWITCH',
  CALENDAR_MARK_MAIN_CLICK: 'CALENDAR_MARK_MAIN_CLICK',
  CALENDAR_AUTHLESS_ACCOUNT_SIGNIN: 'CALENDAR_AUTHLESS_ACCOUNT_SIGNIN',
  CALENDAR_AUTHLESS_ACCOUNT_DELETE: 'CALENDAR_AUTHLESS_ACCOUNT_DELETE',
  CALENDAR_SWITCH_ACCOUNT_CLICK: 'CALENDAR_SWITCH_ACCOUNT_CLICK',
  CALENDAR_EVENT_CLICK: 'CALENDAR_EVENT_CLICK',
  CALENDAR_ZOOM_CLICK: 'CALENDAR_ZOOM_CLICK',
  CALENDAR_PHONE_MODAL_OPEN: 'CALENDAR_PHONE_MODAL_OPEN',
  CALENDAR_CUSTOM_LOCATION_OPEN: 'CALENDAR_CUSTOM_LOCATION_OPEN',
  CALENDAR_ZOOM_AUTH_OPEN: 'CALENDAR_ZOOM_AUTH_OPEN',
  CALENDAR_SETTINGS_WARNING_CHANGE: 'CALENDAR_SETTINGS_WARNING_CHANGE',
  CALENDAR_SETTINGS_DISAPPEAR_CHANGE: 'CALENDAR_SETTINGS_DISAPPEAR_CHANGE',
  CALENDAR_SETTINGS_START_DAY_CHANGE: 'CALENDAR_SETTINGS_START_DAY_CHANGE',
  CALENDAR_SETTINGS_CONFERENCE_CHANGE: 'CALENDAR_SETTINGS_CONFERENCE_CHANGE',
  CALENDAR_SETTINGS_ZOOM_CLEAR: 'CALENDAR_SETTINGS_ZOOM_CLEAR',
  CALENDAR_SETTINGS_ZOOM_TYPE_CHANGE: 'CALENDAR_SETTINGS_ZOOM_TYPE_CHANGE',
  CALENDAR_SETTINGS_ZOOM_CONNECT: 'CALENDAR_SETTINGS_ZOOM_CONNECT',
  CALENDAR_WEEKLY_TOGGLE: 'CALENDAR_WEEKLY_TOGGLE',
  CALENDAR_MODAL_SEARCH_CLICK: 'CALENDAR_MODAL_SEARCH_CLICK',
  CALENDAR_MODAL_SEARCH_INSUFFICIENT_PERMISSION:
    'CALENDAR_MODAL_SEARCH_INSUFFICIENT_PERMISSION',
  CALENDAR_MODAL_OPTIONS_CLICK: 'CALENDAR_MODAL_OPTIONS_CLICK',
  CALENDAR_TIMEZONE_SHOW: 'CALENDAR_TIMEZONE_SHOW',
  CALENDAR_TIMEZONE_EDIT: 'CALENDAR_TIMEZONE_EDIT',
  CALENDAR_TIMEZONE_ADD: 'CALENDAR_TIMEZONE_ADD',
  CALENDAR_TIMEZONE_REMOVE: 'CALENDAR_TIMEZONE_REMOVE',
  CALENDAR_COLOR_CHANGE: 'CALENDAR_COLOR_CHANGE',
  CALENDAR_EVENT_COLOR_CHANGE: 'CALENDAR_EVENT_COLOR_CHANGE',
  CALENDAR_AVAILABILITIES_CLEAR: 'CALENDAR_AVAILABILITIES_CLEAR',
  CALENDAR_AVAILABILITIES_COPY: 'CALENDAR_AVAILABILITIES_COPY',
  CALENDAR_AVAILABILITY_ID_CHANGE: 'CALENDAR_AVAILABILITY_ID_CHANGE',
  CALENDAR_AVAILABILITY_SETTINGS_SAVE: 'CALENDAR_AVAILABILITY_SETTINGS_SAVE',
  CALENDAR_AVAILABILITIES_DURATION_CHANGE:
    'CALENDAR_AVAILABILITIES_DURATION_CHANGE',
  CALENDAR_AVAILABILITIES_CONFERENCE_CHANGE:
    'CALENDAR_AVAILABILITIES_CONFERENCE_CHANGE',
  CALENDAR_AVAILABILITIES_CONFERENCE_ERROR:
    'CALENDAR_AVAILABILITIES_CONFERENCE_ERROR',
  CALENDAR_AVAILABILITIES_TEMPLATE_MANUAL:
    'CALENDAR_AVAILABILITIES_TEMPLATE_MANUAL',
  CALENDAR_AVAILABILITIES_TEMPLATE_NEW: 'CALENDAR_AVAILABILITIES_TEMPLATE_NEW',
  CALENDAR_AVAILABILITIES_TEMPLATE_EDIT:
    'CALENDAR_AVAILABILITIES_TEMPLATE_EDIT',
  CALENDAR_AVAILABILITIES_TEMPLATE_SAVE:
    'CALENDAR_AVAILABILITIES_TEMPLATE_SAVE',
  CALENDAR_AVAILABILITIES_TEMPLATE_TIMES_EDIT:
    'CALENDAR_AVAILABILITIES_TEMPLATE_TIMES_EDIT',
  CALENDAR_AVAILABILITIES_TEMPLATE_CALENDARS_EDIT:
    'CALENDAR_AVAILABILITIES_TEMPLATE_CALENDARS_EDIT',
  CALENDAR_AVAILABILITIES_TEMPLATE_GUESTS_AUTO_ADD:
    'CALENDAR_AVAILABILITIES_TEMPLATE_GUESTS_AUTO_ADD',
  CALENDAR_AVAILABILITIES_TEMPLATE_COPY_LINK:
    'CALENDAR_AVAILABILITIES_TEMPLATE_COPY_LINK',
  CALENDAR_AVAILABILITIES_TEMPLATE_CONVERT_LINK:
    'CALENDAR_AVAILABILITIES_TEMPLATE_CONVERT_LINK',
  CALENDAR_AVAILABILITIES_TEMPLATE_OPEN_LINK:
    'CALENDAR_AVAILABILITIES_TEMPLATE_OPEN_LINK',
  CALENDAR_AVAILABILITIES_ONEOFF_LINK: 'CALENDAR_AVAILABILITIES_ONEOFF_LINK',
  CALENDAR_AVAILABILITIES_ONEOFF_COPY: 'CALENDAR_AVAILABILITIES_ONEOFF_COPY',
  CALENDAR_AVAILABILITIES_ONEOFF_TEAM_SELECT:
    'CALENDAR_AVAILABILITIES_ONEOFF_TEAM_SELECT',
  CALENDAR_AVAILABILITIES_TUTORIAL_CLICK:
    'CALENDAR_AVAILABILITIES_TUTORIAL_CLICK',
  CALENDAR_AVAILABILITIES_OPEN_QUESTIONS:
    'CALENDAR_AVAILABILITIES_OPEN_QUESTIONS',
  CALENDAR_AVAILABILITIES_QUESTIONS_SAVE:
    'CALENDAR_AVAILABILITIES_QUESTIONS_SAVE',
  MINI_CALENDAR_DATE_SELECT: 'MINI_CALENDAR_DATE_SELECT',
  CALENDAR_LINK_EDIT_CLICK: 'CALENDAR_LINK_EDIT_CLICK',
  CALENDAR_LINK_DONE_CLICK: 'CALENDAR_LINK_DONE_CLICK',
  CALENDAR_LINK_DISMISS: 'CALENDAR_LINK_DISMISS',
  CALENDAR_SEND_UPDATE: 'CALENDAR_SEND_UPDATE',
  CALENDAR_SYNC_MODAL_ADD: 'CALENDAR_SYNC_MODAL_ADD',
  CALENDAR_SYNC_MODAL_OPEN: 'CALENDAR_SYNC_MODAL_OPEN',
  CALENDAR_SYNC_DELETE: 'CALENDAR_SYNC_DELETE',
  RICH_AVAILABILITIES_TOGGLE: 'RICH_AVAILABILITIES_TOGGLE',
  EMBED_BOOKING_SHOW: 'EMBED_BOOKING_SHOW',
  EMBED_BOOKING_COPY: 'EMBED_BOOKING_COPY',
  CALENDAR_GROUP_MODAL_SAVE: 'CALENDAR_GROUP_MODAL_SAVE',
  CALENDAR_GROUP_MODAL_OPEN: 'CALENDAR_GROUP_MODAL_OPEN',
  CALENDAR_MINE_ADD: 'CALENDAR_MINE_ADD',
  CALENDAR_MINE_REMOVE: 'CALENDAR_MINE_REMOVE',
  CALENDAR_FREQUENT_ADD: 'CALENDAR_FREQUENT_ADD',
  CALENDAR_FREQUENT_REMOVE: 'CALENDAR_FREQUENT_REMOVE',
  CALENDAR_RE_ADD_PROVIDER_MISMATCH: 'CALENDAR_RE_ADD_PROVIDER_MISMATCH',
  CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_ON:
    'CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_ON',
  CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_OFF:
    'CALENDAR_TOGGLE_SHOW_COMPLETED_TASKS_OFF',
  CALENDAR_TEAMMATE_SEARCH_SELECT: 'CALENDAR_TEAMMATE_SEARCH_SELECT',
  CALENDAR_BANNER_SHOW_RATE_LIMIT: 'BANNER_SHOW_RATE_LIMIT',
  CALENDAR_TASK_EVENT_UNLOCK: 'CALENDAR_TASK_EVENT_UNLOCK',
  CALENDAR_DELETE: 'CALENDAR_DELETE',

  // New onboarding flow
  NEW_ONBOARDING_INDIVIDUAL_OR_TEAM_PROMPT:
    'NEW_ONBOARDING_INDIVIDUAL_OR_TEAM_PROMPT',
  NEW_ONBOARDING_SELECT_INDIVIDUAL: 'NEW_ONBOARDING_SELECT_INDIVIDUAL',
  NEW_ONBOARDING_SELECT_INDIVIDUAL_NO_CARD:
    'NEW_ONBOARDING_SELECT_INDIVIDUAL_NO_CARD',
  NEW_ONBOARDING_SELECT_TEAM: 'NEW_ONBOARDING_SELECT_TEAM',
  NEW_ONBOARDING_SELECT_TEAM_INVITEE: 'NEW_ONBOARDING_SELECT_TEAM_INVITEE',
  NEW_ONBOARDING_SELECT_TEAM_WITH_CARD: 'NEW_ONBOARDING_SELECT_TEAM_WITH_CARD',
  NEW_ONBOARDING_BILLING: 'NEW_ONBOARDING_BILLING',
  NEW_ONBOARDING_TEAM_BILLING: 'NEW_ONBOARDING_TEAM_BILLING',
  NEW_ONBOARDING_MOBILE_BILLING: 'NEW_ONBOARDING_MOBILE_BILLING',
  NEW_ONBOARDING_1_MIN_VIDEO: 'NEW_ONBOARDING_1_MIN_VIDEO',
  NEW_ONBOARDING_1_MIN_VIDEO_SKIP: 'NEW_ONBOARDING_1_MIN_VIDEO_SKIP',
  NEW_ONBOARDING_CONNECT_CALENDAR: 'NEW_ONBOARDING_CONNECT_CALENDAR',
  NEW_ONBOARDING_CONNECT_CALENDAR_SKIP_MODAL:
    'NEW_ONBOARDING_CONNECT_CALENDAR_SKIP_MODAL',
  NEW_ONBOARDING_CONNECT_CALENDAR_SKIP: 'NEW_ONBOARDING_CONNECT_CALENDAR_SKIP',
  NEW_ONBOARDING_CHOOSE_WORK_HOURS: 'NEW_ONBOARDING_CHOOSE_WORK_HOURS',
  NEW_ONBOARDING_PM_TM_VIDEO: 'NEW_ONBOARDING_PM_TM_VIDEO',
  NEW_ONBOARDING_CREATE_TEAM: 'NEW_ONBOARDING_CREATE_TEAM',
  NEW_ONBOARDING_TEAM_INVITE: 'NEW_ONBOARDING_TEAM_INVITE',
  NEW_ONBOARDING_TEAM_SKIP: 'NEW_ONBOARDING_TEAM_SKIP',
  NEW_ONBOARDING_SELECT_MAIN_CALENDAR: 'NEW_ONBOARDING_SELECT_MAIN_CALENDAR',
  NEW_ONBOARDING_SELECT_MY_CALENDARS: 'NEW_ONBOARDING_SELECT_MY_CALENDARS',
  NEW_ONBOARDING_CREATE_FIRST_PROJECT: 'NEW_ONBOARING_CREATE_FIRST_PROJECT',
  NEW_ONBOARDING_CREATE_FIRST_TASKS: 'NEW_ONBOARDING_CREATE_FIRST_TASKS',
  NEW_ONBOARDING_CREATE_FIRST_WORKSPACE:
    'NEW_ONBOARDING_CREATE_FIRST_WORKSPACE',
  NEW_ONBOARDING_CREATE_RECURRING_TASKS:
    'NEW_ONBOARDING_CREATE_RECURRING_TASKS',
  NEW_ONBOARDING_CHOOSE_THEME: 'NEW_ONBOARDING_CHOOSE_THEME',
  NEW_ONBOARDING_RECURRING_TASKS_MISSING_WORKSPACE_ERROR:
    'NEW_ONBOARDING_RECURRING_TASKS_MISSING_WORKSPACE_ERROR',
  NEW_ONBOARDING_TEAM_INTERCEPTION: 'NEW_ONBOARDING_TEAM_INTERCEPTION',
  NEW_ONBOARDING_TEAM_INVITE_SUCCESS_MODAL_COPY:
    'NEW_ONBOARDING_TEAM_INVITE_SUCCESS_MODAL_COPY',

  // Checkout onboarding
  ADD_TO_CART: 'ADD_TO_CART',
  CHECKOUT_SURVEY: 'CHECKOUT_SURVEY',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  REJECT_PREPAID: 'REJECT_PREPAID',
  TYPING_CARD: 'TYPING_CARD',

  // email
  EMAIL_SEND: 'EMAIL_SEND',
  EMAIL_CLOSE: 'EMAIL_CLOSE',
  EMAIL_SWITCH_ACCOUNT_CLICK: 'EMAIL_SWITCH_ACCOUNT_CLICK',
  EMAIL_ADD_ACCOUNT_CLICK: 'EMAIL_ADD_ACCOUNT_CLICK',
  EMAIL_MODAL_CONNECT_CLICK: 'EMAIL_MODAL_CONNECT_CLICK',
  EMAIL_SIGNATURES_OPEN: 'EMAIL_SIGNATURES_OPEN',
  EMAIL_SIGNATURES_SAVE: 'EMAIL_SIGNATURES_SAVE',
  EMAIL_CC_CLICK: 'EMAIL_CC_CLICK',
  EMAIL_BCC_CLICK: 'EMAIL_BCC_CLICK',

  TEMPLATE_CLICK: 'TEMPLATE_CLICK',

  // global shortcuts
  SHORTCUT_CALENDAR: 'SHORTCUT_CALENDAR',
  SHORTCUT_AVAILABILITY: 'SHORTCUT_AVAILABILITY',
  SHORTCUT_TASKS: 'SHORTCUT_TASKS',
  SHORTCUT_OPT_SPACE: 'SHORTCUT_OPT_SPACE',

  // account
  ACCOUNT_SIGNIN_CLICKED: 'ACCOUNT_SIGNIN_CLICKED',
  ACCOUNT_SIGNOUT_CLICKED: 'ACCOUNT_SIGNOUT_CLICKED',
  ACCOUNT_NO_SUBSCRIPTION: 'ACCOUNT_NO_SUBSCRIPTION',
  ACCOUNT_AUTOREFUND_CLICKED: 'ACCOUNT_AUTOREFUND_CLICKED',
  CALENDARS_DETACH: 'CALENDARS_DETACH',

  TRIAL_START_WALLET: 'TRIAL_START_WALLET',
  CONTACT_PERMISSION_SHOW: 'CONTACT_PERMISSION_SHOW',
  CONTACT_PERMISSION_ADD: 'CONTACT_PERMISSION_ADD',
  CONTACT_PERMISSION_DONE: 'CONTACT_PERMISSION_DONE',

  MOTIONTAB_CALENDAR_SHOW: 'MOTIONTAB_CALENDAR_SHOW',
  MOTIONTAB_SCHEDULER_SHOW: 'MOTIONTAB_SCHEDULER_SHOW',
  MOTIONTAB_DESKTOP_SHOW: 'MOTIONTAB_DESKTOP_SHOW',
  MOTIONTAB_TASKS_SHOW: 'MOTIONTAB_TASKS_SHOW',
  MOTIONTAB_ACCOUNT_SHOW: 'MOTIONTAB_ACCOUNT_SHOW',

  GOOGLE_PERMISSIONS_AUTH: 'GOOGLE_PERMISSIONS_AUTH',
  MS_PERMISSIONS_AUTH: 'MS_PERMISSIONS_AUTH',

  TEAM_CREATE: 'TEAM_CREATE',
  TEAM_INVITE: 'TEAM_INVITE',
  TEAM_SUBSCRIPTION_EXPIRED_SIGN_OUT: 'TEAM_SUBSCRIPTION_EXPIRED_SIGN_OUT',
  TEAM_SUBSCRIPTION_EXPIRED_INDIVIDUAL_RESUB:
    'TEAM_SUBSCRIPTION_EXPIRED_INDIVIDUAL_RESUB',
  TEAM_SUBSCRIPTION_EXPIRED_TEAM_RESUB: 'TEAM_SUBSCRIPTION_EXPIRED_TEAM_RESUB',
  TEAM_TRIAL_EXPIRED_SIGN_OUT: 'TEAM_TRIAL_EXPIRED_SIGN_OUT',
  TEAM_TRIAL_EXPIRED_INDIVIDUAL_RESUB: 'TEAM_TRIAL_EXPIRED_INDIVIDUAL_RESUB',
  TEAM_TRIAL_EXPIRED_TEAM_RESUB: 'TEAM_TRIAL_EXPIRED_TEAM_RESUB',

  SCHEDULER_ONBOARDING_OPEN: 'SCHEDULER_ONBOARDING_OPEN',
  SCHEDULER_ONBOARDING_FINISH: 'SCHEDULER_ONBOARDING_FINISH',
  SCHEDULER_ONBOARDING_NEXT: 'SCHEDULER_ONBOARDING_NEXT',
  SCHEDULER_ONBOARDING_PROMPT_SHOW: 'SCHEDULER_ONBOARDING_PROMPT_SHOW',
  SCHEDULER_VIDEO_FINISHED: 'SCHEDULER_VIDEO_FINISHED',

  ZOOM_PERMISSIONS_AUTH: 'ZOOM_PERMISSIONS_AUTH',

  TASK_CREATE_DESKTOP_SUCCESS: 'TASK_CREATE_DESKTOP_SUCCESS',

  TASK_START: 'TASK_START',
  TASK_STOP: 'TASK_STOP',
  TASK_MAKE_ASAP: 'TASK_MAKE_ASAP',
  TASK_DO_LATER: 'TASK_DO_LATER',
  TASK_ALLOW_DEADLINE: 'TASK_ALLOW_DEADLINE',
  TASK_CHANGE_DEADLINE: 'TASK_CHANGE_DEADLINE',
  SCHEDULE_SAVE: 'SCHEDULE_SAVE',
  SCHEDULE_DELETE: 'SCHEDULE_DELETE',
  SCHEDULE_MODAL_OPEN: 'SCHEDULE_MODAL_OPEN',
  SCHEDULED_AFTER_DEADLINE_MODAL_OPEN: 'SCHEDULED_AFTER_DEADLINE_MODAL_OPEN',
  TASK_NAME_SETTINGS_UNHIDE: 'TASK_NAME_SETTINGS_UNHIDE',
  TASK_SCHEDULE_MODAL_OPEN: 'TASK_SCHEDULE_MODAL_OPEN',
  TASK_SCHEDULE_SHOW: 'TASK_SCHEDULE_SHOW',
  TASK_SCHEDULE_SAVE: 'TASK_SCHEDULE_SAVE',
  TASK_SCHEDULE_UNDO: 'TASK_SCHEDULE_UNDO',
  TASK_SCHEDULE_DRAG: 'TASK_SCHEDULE_DRAG',
  TASK_SCHEDULE_ALLOW_DEADLINE_ALL: 'TASK_SCHEDULE_ALLOW_DEADLINE_ALL',

  PROJECT_MANAGEMENT_V2_UPSELL_SHOWN: 'PROJECT_MANAGEMENT_V2_UPSELL_SHOWN',
  PROJECT_MANAGEMENT_V2_UPSELL_CLICKED: 'PROJECT_MANAGEMENT_V2_UPSELL_CLICKED',
  PROJECT_MANAGEMENT_V2_UPSELL_DISMISSED:
    'PROJECT_MANAGEMENT_V2_UPSELL_DISMISSED',

  PROJECT_MANAGEMENT_VISIT_PAGE: 'PROJECT_MANAGEMENT_VISIT_PAGE',
  PROJECT_MANAGEMENT_VIEW_SAVED_VIEW: 'PROJECT_MANAGEMENT_VIEW_SAVED_VIEW',
  PROJECT_MANAGEMENT_VIEW_EDIT_SAVED_VIEW_CLICK:
    'PROJECT_MANAGEMENT_VIEW_EDIT_SAVED_VIEW_CLICK',
  PROJECT_MANAGEMENT_UPDATE_FILTER: 'PROJECT_MANAGEMENT_UPDATE_FILTER',
  PROJECT_MANAGEMENT_UPDATE_SORT: 'PROJECT_MANAGEMENT_UPDATE_SORT',
  PROJECT_MANAGEMENT_UPDATE_SORT_DIRECTION:
    'PROJECT_MANAGEMENT_UPDATE_SORT_DIRECTION',
  PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD: 'PROJECT_MANAGEMENT_CLICK_PRIMARY_ADD',
  PROJECT_MANAGEMENT_UPDATE_GROUPBY: 'PROJECT_MANAGEMENT_UPDATE_GROUPBY',
  PROJECT_MANAGEMENT_CREATE_PROJECT: 'PROJECT_MANAGEMENT_CREATE_PROJECT',
  PROJECT_MANAGEMENT_UPDATE_PROJECT: 'PROJECT_MANAGEMENT_UPDATE_PROJECT',
  PROJECT_MANAGEMENT_DELETE_PROJECT: 'PROJECT_MANAGEMENT_DELETE_PROJECT',
  PROJECT_MANAGEMENT_CREATE_TASK: 'PROJECT_MANAGEMENT_CREATE_TASK',
  PROJECT_MANAGEMENT_DELETE_TASK: 'PROJECT_MANAGEMENT_DELETE_TASK',
  PROJECT_MANAGEMENT_UPDATE_BLOCKERS: 'PROJECT_MANAGEMENT_UPDATE_BLOCKERS',
  PROJECT_MANAGEMENT_CREATE_WORKSPACE: 'PROJECT_MANAGEMENT_CREATE_WORKSPACE',
  PROJECT_MANAGEMENT_DELETE_WORKSPACE: 'PROJECT_MANAGEMENT_DELETE_WORKSPACE',
  PROJECT_MANAGEMENT_RENAME_WORKSPACE: 'PROJECT_MANAGEMENT_RENAME_WORKSPACE',
  PROJECT_MANAGEMENT_CONVERT_WORKSPACE_TO_TEAM:
    'PROJECT_MANAGEMENT_CONVERT_WORKSPACE_TO_TEAM',
  PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBER:
    'PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBER',
  PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBERS:
    'PROJECT_MANAGEMENT_ADD_WORKSPACE_MEMBERS',
  PROJECT_MANAGEMENT_UPDATE_WORKSPACE_MEMBER:
    'PROJECT_MANAGEMENT_UPDATE_WORKSPACE_MEMBER',
  PROJECT_MANAGEMENT_DELETE_WORKSPACE_MEMBER:
    'PROJECT_MANAGEMENT_DELETE_WORKSPACE_MEMBER',
  PROJECT_MANAGEMENT_DELETE_WORKSPACE_MEMBERS:
    'PROJECT_MANAGEMENT_DELETE_WORKSPACE_MEMBERS',
  PROJECT_MANAGEMENT_CREATE_WORKSPACE_LABEL:
    'PROJECT_MANAGEMENT_CREATE_WORKSPACE_LABEL',
  PROJECT_MANAGEMENT_DELETE_WORKSPACE_LABEL:
    'PROJECT_MANAGEMENT_DELETE_WORKSPACE_LABEL',
  PROJECT_MANAGEMENT_UPDATE_WORKSPACE_LABEL:
    'PROJECT_MANAGEMENT_UPDATE_WORKSPACE_LABEL',
  PROJECT_MANAGEMENT_UPDATE_WORKSPACE_STATUS:
    'PROJECT_MANAGEMENT_UPDATE_WORKSPACE_STATUS',
  PROJECT_MANAGEMENT_DELETE_WORKSPACE_STATUS:
    'PROJECT_MANAGEMENT_DELETE_WORKSPACE_STATUS',
  PROJECT_MANAGEMENT_CREATE_WORKSPACE_STATUS:
    'PROJECT_MANAGEMENT_CREATE_WORKSPACE_STATUS',
  PROJECT_MANAGEMENT_UPDATE_TEAM_MEMBER:
    'PROJECT_MANAGEMENT_UPDATE_TEAM_MEMBER',
  PROJECT_MANAGEMENT_UPLOAD_IMAGE: 'PROJECT_MANAGEMENT_UPLOAD_IMAGE',
  PROJECT_MANAGEMENT_UPDATE_NOTIFICATION_PREFERENCES:
    'PROJECT_MANAGEMENT_UPDATE_NOTIFICATION_PREFERENCES',
  PROJECT_MANAGEMENT_CREATE_TASK_COMMENT:
    'PROJECT_MANAGEMENT_CREATE_TASK_COMMENT',
  PROJECT_MANAGEMENT_COMPLETE_TASK: 'PROJECT_MANAGEMENT_COMPLETE_TASK',

  // Templates
  PROJECT_MANAGEMENT_CREATE_TEMPLATE_TASK:
    'PROJECT_MANAGEMENT_CREATE_TEMPLATE_TASK',
  PROJECT_MANAGEMENT_UPDATE_TEMPLATE_TASK:
    'PROJECT_MANAGEMENT_UPDATE_TEMPLATE_TASK',
  PROJECT_MANAGEMENT_DELETE_TEMPLATE_TASK:
    'PROJECT_MANAGEMENT_DELETE_TEMPLATE_TASK',
  PROJECT_MANAGEMENT_CREATE_TEMPLATE_PROJECT:
    'PROJECT_MANAGEMENT_CREATE_TEMPLATE_PROJECT',
  PROJECT_MANAGEMENT_UPDATE_TEMPLATE_PROJECT:
    'PROJECT_MANAGEMENT_UPDATE_TEMPLATE_PROJECT',
  PROJECT_MANAGEMENT_DELETE_TEMPLATE_PROJECT:
    'PROJECT_MANAGEMENT_DELETE_TEMPLATE_PROJECT',
  PROJECT_MANAGEMENT_CREATE_TEMPLATE_PROJECT_TASK:
    'PROJECT_MANAGEMENT_CREATE_TEMPLATE_PROJECT_TASK',
  PROJECT_MANAGEMENT_UPDATE_TEMPLATE_PROJECT_TASK:
    'PROJECT_MANAGEMENT_UPDATE_TEMPLATE_PROJECT_TASK',
  PROJECT_MANAGEMENT_DELETE_TEMPLATE_PROJECT_TASK:
    'PROJECT_MANAGEMENT_DELETE_TEMPLATE_PROJECT_TASK',
  // ----

  INDIVIDUAL_BILLING_TRIAL_BADGE_CLICK: 'INDIVIDUAL_BILLING_TRIAL_BADGE_CLICK',

  TEAM_BILLING_TRIAL_BADGE_CLICK: 'TEAM_BILLING_TRIAL_BADGE_CLICK',
  TEAM_BILLING_SUBSCRIBE: 'TEAM_BILLING_SUBSCRIBE',
  TEAM_BILLING_INVITE: 'TEAM_BILLING_INVITE',
  TEAM_BILLING_REMOVE: 'TEAM_BILLING_REMOVE',
  TEAM_BILLING_CANCEL_SUB: 'TEAM_BILLING_CANCEL_SUB',
  TEAM_BILLING_CANCEL_TRIAL: 'TEAM_BILLING_CANCEL_TRIAL',
  TEAM_BILLING_ADD_CARD: 'TEAM_BILLING_ADD_CARD',
  CLICK_TEAM_BILLING_CONVERT_TO_ANNUAL: 'CLICK_TEAM_BILLING_CONVERT_TO_ANNUAL',

  PERMISSIONS_MODAL_RETRY_CLICK: 'PERMISSIONS_MODAL_RETRY_CLICK',

  CLICK_HELP: 'CLICK_HELP',

  AUTH_BANNER_MANAGE_CLICK: 'AUTH_BANNER_MANAGE_CLICK',
  AUTH_BANNER_RECONNECT_CLICK: 'AUTH_BANNER_RECONNECT_CLICK',

  BROWSER_NOTIFICATIONS_ENABLED: 'BROWSER_NOTIFICATIONS_ENABLED',

  TASK_MIGRATION_STARTED: 'TASK_MIGRATION_STARTED',
  TASK_MIGRATION_COMPLETED: 'TASK_MIGRATION_COMPLETED',
  COMPLETED_TASK_MIGRATION_STARTED: 'COMPLETED_TASK_MIGRATION_STARTED',
  COMPLETED_TASK_MIGRATION_COMPLETED: 'COMPLETED_TASK_MIGRATION_COMPLETED',

  // cta's
  NAVBAR_CTA_ACCOUNT_UPGRADE_ANNUAL: 'NAVBAR_CTA_ACCOUNT_UPGRADE_ANNUAL',
  NAVBAR_CTA_CREATE_TEAM: 'NAVBAR_CTA_CREATE_TEAM',
  NAVBAR_CTA_CHANGE_TO_TEAM_PLAN: 'NAVBAR_CTA_CHANGE_TO_TEAM_PLAN',

  DESKTOP_DOWNLOAD_CTA_SHOWN: 'DESKTOP_DOWNLOAD_CTA_SHOWN',
  DESKTOP_DOWNLOAD_CTA_AUTO_DISMISSED: 'DESKTOP_DOWNLOAD_CTA_AUTO_DISMISSED',
  DESKTOP_DOWNLOAD_CTA_CLICKED: 'DESKTOP_DOWNLOAD_CTA_CLICKED',
  DESKTOP_DOWNLOAD_CTA_DISMISSED: 'DESKTOP_DOWNLOAD_CTA_DISMISSED',

  MOBILE_DOWNLOAD_CTA_SHOWN: 'MOBILE_DOWNLOAD_CTA_SHOWN',
  MOBILE_DOWNLOAD_CTA_CLICKED: 'MOBILE_DOWNLOAD_CTA_CLICKED',
  MOBILE_DOWNLOAD_CTA_DISMISSED: 'MOBILE_DOWNLOAD_CTA_DISMISSED',

  TIME_BLOCK_SETTINGS_SET_DEFAULT: 'TIME_BLOCK_SETTINGS_SET_DEFAULT',
  TIME_BLOCK_SETTINGS_SET_FREE: 'TIME_BLOCK_SETTINGS_SET_FREE',
  TIME_BLOCK_SETTINGS_SET_NO_EVENTS: 'TIME_BLOCK_SETTINGS_SET_NO_EVENTS',

  DESKTOP_SETTINGS_VISIT: 'DESKTOP_SETTINGS_VISIT',
  DESKTOP_SETTINGS_DOWNLOAD: 'DESKTOP_SETTINGS_DOWNLOAD',
  DESKTOP_SETTINGS_OPEN_AT_LOGIN: 'DESKTOP_SETTINGS_OPEN_AT_LOGIN',
  DESKTOP_SETTINGS_SHORTCUT_CHANGE: 'DESKTOP_SETTINGS_SHORTCUT_CHANGE',

  // Desktop Deeplink Settings
  DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_ALWAYS:
    'DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_ALWAYS',
  DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_ONCE:
    'DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_ONCE',
  DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_DISMISS_ALWAYS:
    'DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_DISMISS_ALWAYS',
  DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_DISMISS_ONCE:
    'DESKTOP_SETTINGS_DEEPLINK_OPEN_IN_APP_DISMISS_ONCE',
  DESKTOP_WAS_DEEP_LINKED: 'DESKTOP_WAS_DEEP_LINKED',
  DESKTOP_DEEPLINK_OPEN_IN_WEB: 'DESKTOP_DEEPLINK_OPEN_IN_WEB',

  UPDATE_FUNCTION_MIGRATION_MODAL_CLICK:
    'UPDATE_FUNCTION_MIGRATION_MODAL_CLICK',

  // Setting
  ACCOUNT_SETTINGS_PAGE_VIEWED: 'ACCOUNT_SETTINGS_PAGE_VIEWED',
  BILLING_SETTINGS_PAGE_VIEWED: 'BILLING_SETTINGS_PAGE_VIEWED',
  INTEGRATION_SETTINGS_PAGE_VIEWED: 'INTEGRATION_SETTINGS_PAGE_VIEWED',
  ZAPIER_INTEGRATION_CLICK: 'ZAPIER_INTEGRATION_CLICK',
  EMAIL_INTEGRATION_CLICK: 'EMAIL_INTEGRATION_CLICK',
  PROJECT_MANAGEMENT_LABEL_SETTINGS_PAGE_VIEWED:
    'PROJECT_MANAGEMENT_LABEL_SETTINGS_PAGE_VIEWED',
  PROJECT_MANAGEMENT_OVERVIEW_SETTINGS_PAGE_VIEWED:
    'PROJECT_MANAGEMENT_OVERVIEW_SETTINGS_PAGE_VIEWED',
  PROJECT_MANAGEMENT_STATUS_SETTINGS_PAGE_VIEWED:
    'PROJECT_MANAGEMENT_STATUS_SETTINGS_PAGE_VIEWED',
  PROJECT_MANAGEMENT_TEMPLATE_SETTINGS_PAGE_VIEWED:
    'PROJECT_MANAGEMENT_TEMPLATE_SETTINGS_PAGE_VIEWED',
  PROJECT_MANAGEMENT_CUSTOM_FIELD_SETTINGS_PAGE_VIEWED:
    'PROJECT_MANAGEMENT_CUSTOM_FIELD_SETTINGS_PAGE_VIEWED',

  PROJECT_MANAGEMENT_FLOW_PROJECT_TEMPLATE_SETTINGS_PAGE_VIEWED:
    'PROJECT_MANAGEMENT_FLOW_PROJECT_TEMPLATE_SETTINGS_PAGE_VIEWED',
  PROJECT_MANAGEMENT_TASK_TEMPLATE_SETTINGS_PAGE_VIEWED:
    'PROJECT_MANAGEMENT_TASK_TEMPLATE_SETTINGS_PAGE_VIEWED',

  // Team members
  TEAM_MEMBERS_TABLE_BULK_EDIT_APPLIED: 'TEAM_MEMBERS_TABLE_BULK_EDIT_APPLIED',

  // Contacts
  CONTACTS_SELECTED_CUSTOM_CONTACT: 'CONTACTS_SELECTED_CUSTOM_CONTACT',
  CONTACTS_SELECTED_SEARCH_RESULT: 'CONTACTS_SELECTED_SEARCH_RESULT',
  CONTACTS_SELECTED_CACHED_RESULT: 'CONTACTS_SELECTED_CACHED_RESULT',

  // Tutorials
  TUTORIALS_VIDEO_PLAYED: 'TUTORIALS_VIDEO_PLAYED',
  TUTORIALS_LESSON_COMPLETED: 'TUTORIALS_LESSON_COMPLETED',
  TUTORIALS_VIDEO_COMPLETED_WATCHING: 'TUTORIALS_VIDEO_COMPLETED_WATCHING',
  TUTORIALS_ALL_COMPLETED: 'TUTORIALS_ALL_COMPLETED',

  // Folders
  FOLDERS_DRAGGED_SIDEBAR_ITEM: 'FOLDERS_DRAGGED_SIDEBAR_ITEM',
  FOLDERS_SHIFT_SIDEBAR_ITEM: 'FOLDERS_SHIFT_SIDEBAR_ITEM',
  FOLDERS_CREATED_FOLDER: 'FOLDERS_CREATED_FOLDER',
  FOLDERS_UPDATED_FOLDER: 'FOLDERS_UPDATED_FOLDER',
  FOLDERS_DELETED_FOLDER: 'FOLDERS_DELETED_FOLDER',
  FOLDERS_TOGGLED_SIDEBAR_VISIBILITY: 'FOLDERS_TOGGLED_SIDEBAR_VISIBILITY',
  FOLDERS_CLICKED_SIDEBAR_BUTTON: 'FOLDERS_CLICKED_SIDEBAR_BUTTON',
  FOLDERS_COPIED_LINK: 'FOLDERS_COPIED_LINK',

  SYNC_CALENDARS_DEPRECATION_ANNOUNCEMENT_CLICK:
    'SYNC_CALENDARS_DEPRECATION_ANNOUNCEMENT_CLICK',

  PERMISSIONS_ERROR: 'PERMISSIONS_ERROR',

  ARCHIVE_TASKS_ANNOUNCEMENT_CLICK: 'ARCHIVE_TASKS_ANNOUNCEMENT_CLICK',

  TIMEZONE_CHANGE_DETECTED_MODAL_SHOWN: 'TIMEZONE_CHANGE_DETECTED_MODAL_SHOWN',
  TIMEZONE_CHANGE_DETECTED_ACCEPTED_CHANGE:
    'TIMEZONE_CHANGE_DETECTED_ACCEPTED_CHANGE',
  TIMEZONE_CHANGE_DETECTED_DISMISSED_CHANGE:
    'TIMEZONE_CHANGE_DETECTED_DISMISSED_CHANGE',

  RESCHEDULE_STALE_TASKS_MODAL_SHOW: 'RESCHEDULE_STALE_TASKS_MODAL_SHOW',
  RESCHEDULE_STALE_TASKS_OPTION_RESCHEDULE:
    'RESCHEDULE_STALE_TASKS_OPTION_RESCHEDULE',
  RESCHEDULE_STALE_TASKS_OPTION_NO_RESCHEDULE:
    'RESCHEDULE_STALE_TASKS_OPTION_NO_RESCHEDULE',

  ERROR_CAPTURED_EXCEPTION: 'ERROR_CAPTURED_EXCEPTION',

  AI_TASK_FEEDBACK: 'AI_TASK_FEEDBACK',

  RELEASE_NOTES_SHOWN: 'RELEASE_NOTES_SHOWN',
  RELEASE_NOTES_DISMISSED: 'RELEASE_NOTES_DISMISSED',
  RELEASE_NOTES_OPENED_URL: 'RELEASE_NOTES_OPENED_URL',

  INTEGRATIONS_BOOKING_ALERT_SHOWN: 'INTEGRATIONS_BOOKING_ALERT_SHOWN',
  INTEGRATIONS_BOOKING_ALERT_OPENED_URL:
    'INTEGRATIONS_BOOKING_ALERT_OPENED_URL',
  INTEGRATIONS_BOOKING_ALERT_DISMISSED: 'INTEGRATIONS_BOOKING_ALERT_DISMISSED',

  GDPR_DELETE_USER: 'GDPR_DELETE_USER',
  GDPR_COOKIES_MODAL_SHOWN: 'GDPR_COOKIES_MODAL_SHOWN',
  GDPR_COOKIES_MODAL_ACCEPTED: 'GDPR_COOKIES_MODAL_ACCEPTED',
  GDPR_COOKIES_MODAL_CUSTOMIZE: 'GDPR_COOKIES_MODAL_CUSTOMIZE',

  FLEXIBLE_HOURS_START_TASKS_LATER: 'FLEXIBLE_HOURS_START_TASKS_LATER',
  FLEXIBLE_HOURS_STOP_TASKS_EARLY: 'FLEXIBLE_HOURS_STOP_TASKS_EARLY',
  FLEXIBLE_HOURS_BLOCK_OUT_HOURS: 'FLEXIBLE_HOURS_BLOCK_OUT_HOURS',
  FLEXIBLE_HOURS_BLOCK_OUT_WHOLE_DAY: 'FLEXIBLE_HOURS_BLOCK_OUT_WHOLE_DAY',
  FLEXIBLE_HOURS_RESET_HOURS: 'FLEXIBLE_HOURS_RESET_HOURS',

  TASK_DO_LATER_SAVE: 'TASK_DO_LATER_SAVE',
  CALENDAR_TASK_EVENT_UNSNOOZE: 'CALENDAR_TASK_EVENT_UNSNOOZE',

  LINK_CALENDARS_MODAL_OPEN: 'LINK_CALENDARS_MODAL_OPEN',
  LINK_CALENDARS_MODAL_PROVIDER_CLICK: 'LINK_CALENDARS_MODAL_PROVIDER_CLICK',

  PEEK_MODAL_OPEN_EVENT_FROM_NON_MAIN_CALENDAR:
    'PEEK_MODAL_OPEN_EVENT_FROM_NON_MAIN_CALENDAR',

  TRIAL_END_ADD_PAYMENT_BANNER_SHOW: 'TRIAL_END_ADD_PAYMENT_BANNER_SHOW',
  TRIAL_END_ADD_PAYMENT_BANNER_CLICK: 'TRIAL_END_ADD_PAYMENT_BANNER_CLICK',

  MOBILE_WEB_CHECKOUT_PLAN_CLICK: 'MOBILE_WEB_CHECKOUT_PLAN_CLICK',

  TASK_DEFAULT_SETTINGS_PAGE_VIEWED: 'TASK_DEFAULT_SETTINGS_PAGE_VIEWED',
  TASK_DEFAULT_SETTINGS_CHANGED: 'TASK_DEFAULT_SETTINGS_CHANGED',
  TASK_DEFAULT_SETTINGS_SAVED_FROM_TASK_MODAL:
    'TASK_DEFAULT_SETTINGS_SAVED_FROM_TASK_MODAL',
} as const

export type Events = keyof typeof Events
