import { showToast } from '@motion/ui/base'
import { formatToReadableWeekDayMonth, templateStr } from '@motion/ui-logic'
import {
  getDidIncreaseProjectLength,
  getUpdatedStages,
  isFlowProject,
  type StageWithDates,
} from '@motion/ui-logic/pm/project'
import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { applyOptimisticProjectUpdates } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { AdjustDatesDescription } from './adjust-modal-description'

import { useProjectUpdater } from '../../updaters/use-project-updater'

type AdjustProjectDatesArgs = {
  startDate?: string
  dueDate?: string
}

// Given either/both a start date or due date, prompt the user with the stages that will be affected
export const useAdjustProjectDates = () => {
  const modalApi = useModalApi()
  const updateProject = useProjectUpdater()
  const { getWorkspaceProjectById } = useWorkspaceFns()
  const queryClient = useQueryClient()
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')

  const updateProjectDates = useCallback(
    async (
      project: ProjectSchema,
      {
        startDate,
        dueDate,
        stages,
      }: AdjustProjectDatesArgs & {
        stages: StageSchema[]
      }
    ) => {
      let updates = {
        ...(startDate ? { startDate } : {}),
        ...(dueDate ? { dueDate } : {}),
      }

      /**
       * Optimistically update cache with new data (including stages)
       */
      const cacheUpdates = await applyOptimisticProjectUpdates(
        queryClient,
        project.id,
        {
          ...project,
          ...updates,
          stages,
        }
      )

      try {
        await updateProject(project, updates)
      } catch (e) {
        cacheUpdates?.rollback()
      }
    },
    [queryClient, updateProject]
  )

  return useCallback(
    async (
      id: ProjectSchema['id'],
      { startDate, dueDate }: AdjustProjectDatesArgs
    ) => {
      const project = getWorkspaceProjectById(id)
      if (!project) return false

      let stagesWithDates: StageWithDates[] = []
      let updatedStages: Record<'unchanged' | 'changed', StageWithDates[]> = {
        unchanged: [],
        changed: [],
      }

      if (isFlowProject(project)) {
        try {
          const {
            updatedStages: adjustedUpdatedStages,
            stagesWithDates: adjustedStagesWithDates,
          } = getUpdatedStages({
            project,
            startDate,
            dueDate,
          })
          updatedStages = adjustedUpdatedStages
          stagesWithDates = adjustedStagesWithDates
        } catch (e) {
          Sentry.captureException(e)
          errorInDev(e)
          showToast('error', 'There was an error updating the stage dates')
          return false
        }
      }

      let projectStartDate = startDate || project.startDate
      let projectDueDate = dueDate || project.dueDate

      const sideChanged =
        startDate && project.startDate !== startDate ? 'start' : 'due'

      const didIncreaseProjectLength = getDidIncreaseProjectLength(
        project,
        projectStartDate,
        projectDueDate
      )

      // Show confirmation text for shortening the project
      if (!didIncreaseProjectLength && !hasBetterResizeStages) {
        const title = templateStr(
          'Are you sure you want to make this project shorter ({{startDate}} - {{dueDate}})?',
          {
            startDate: projectStartDate
              ? formatToReadableWeekDayMonth(projectStartDate)
              : 'N/A',
            dueDate: projectDueDate
              ? formatToReadableWeekDayMonth(projectDueDate)
              : 'N/A',
          }
        )

        // Prompt the user with the stages that will be adjusted
        const response = await modalApi.prompt('confirm', {
          analytics: {
            name: 'adjust-project-dates',
          },
          title,
          description:
            stagesWithDates.length > 0 ? (
              <AdjustDatesDescription
                updatedStages={updatedStages}
                sideChanged={sideChanged}
              />
            ) : undefined,
        })

        if (response === ModalDismissed) {
          return false
        }
      }

      // Flow projects currently requires to be ISO Date only
      const finalDueDate =
        dueDate != null && isFlowProject(project)
          ? DateTime.fromISO(dueDate).toISODate()
          : dueDate

      const updatedProjectStages = project.stages.map((stage) => ({
        ...stage,
        dueDate:
          stagesWithDates.find(
            (stageWithDates) =>
              stageWithDates.stage.stageDefinitionId === stage.stageDefinitionId
          )?.due ?? stage.dueDate,
      }))

      updateProjectDates(project, {
        startDate,
        dueDate: finalDueDate,
        stages: updatedProjectStages,
      })

      return true
    },
    [
      getWorkspaceProjectById,
      hasBetterResizeStages,
      updateProjectDates,
      modalApi,
    ]
  )
}
