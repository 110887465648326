import {
  DotsVerticalSolid,
  LoadingSolid,
  PencilSolid,
  PlusSolid,
} from '@motion/icons'
import { type ProviderType } from '@motion/rpc-types'
import { Button } from '@motion/ui/base'
import { CalendarTitle } from '@motion/ui/calendar'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type CalendarSchema } from '@motion/zod/client'

import {
  useFrequentlyMetCalendars,
  useMainCalendarId,
  useMyCalendars,
} from '~/areas/calendar/hooks'
import { useCallback, useEffect } from 'react'

import { EmailAccountRow } from './email-account-row'

import { useInNoExternalCalendarsMode } from '../../../../hooks/use-in-no-external-calendars-mode'
import { setCalendarListPickerModal } from '../../../../state/calendar-list/calendar-list-slice'
import { updateCalendar } from '../../../../state/calendar-list/calendar-list-thunks'
import { type CalendarListPickerSectionType } from '../../../../state/calendar-list/calendar-list-types'
import {
  selectCalendarsLoadingState,
  selectEmailAccounts,
  selectEmailAccountsMap,
} from '../../../../state/email-accounts/email-accounts-slice'
import { fetchAllEmailAccounts } from '../../../../state/email-accounts/email-accounts-thunks'
import { useAppDispatch, useAppSelector } from '../../../../state/hooks'
import { addAccount } from '../../../../utils/auth'
import { AddAccountDropdown } from '../../../Account/add-account-dropdown'
import {
  DropdownMenu,
  Paragraph,
  SecondaryButton,
  TextButton,
} from '../../../Common'
import { LabelTag } from '../../../Common/LabelTag/LabelTag'
import { LinkCalendarsCTA } from '../../Components/link-calendars-cta'
import { SettingList } from '../../Components/SettingList/SettingList'
import { SettingPage } from '../../Components/SettingPage'

interface CalendarSettingsProps {
  onShowDetachCalendarModal: () => void
}

export const CalendarSettings = ({
  onShowDetachCalendarModal,
}: CalendarSettingsProps) => {
  const dispatch = useAppDispatch()
  const myCalendars = useMyCalendars()
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const emailAccountsMap = useAppSelector(selectEmailAccountsMap)
  const { mainCalendarId } = useMainCalendarId()
  const frequentlyMetCalendars = useFrequentlyMetCalendars()
  const { noExternalCalendarsMode } = useInNoExternalCalendarsMode()
  const emailAccountsLoadingState = useAppSelector(selectCalendarsLoadingState)

  const onAddAccount = (provider: ProviderType) => {
    addAccount(provider)
    recordAnalyticsEvent('CALENDAR_ADD_ACCOUNT_CLICK')
  }

  const openCalListPickerModal = useCallback(
    (section: CalendarListPickerSectionType) => {
      dispatch(setCalendarListPickerModal({ section, visible: true }))
      recordAnalyticsEvent('CALENDAR_GROUP_MODAL_OPEN', { section })
    },
    [dispatch]
  )

  const removeCalendarFromMyCalendars = (calendar: CalendarSchema) => {
    void dispatch(
      updateCalendar({
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isInMyCalendars: false,
      })
    )
  }

  const removeFrequentlyMetCalendar = (calendar: CalendarSchema) => {
    void dispatch(
      updateCalendar({
        id: calendar.id,
        emailAccountId: calendar.emailAccountId,
        isInFrequentlyMet: false,
      })
    )
  }

  useEffect(
    function loadInitialData() {
      void dispatch(fetchAllEmailAccounts())
    },
    [dispatch]
  )

  if (noExternalCalendarsMode) {
    const isLoading =
      emailAccountsLoadingState === 'preload' ||
      emailAccountsLoadingState === 'loading'
    return (
      <SettingPage title='Calendars' className='gap-12'>
        <div className='flex w-full h-full flex-col gap-3 items-center'>
          {isLoading && (
            <div className='flex w-full h-full flex-col gap-3 items-center'>
              <LoadingSolid className='animate-spin text-semantic-neutral-icon-default' />
            </div>
          )}
          {!isLoading && <LinkCalendarsCTA />}
        </div>
      </SettingPage>
    )
  }

  return (
    <SettingPage title='Calendars' className='gap-12'>
      <div className='flex w-full flex-col gap-3'>
        <div className='flex w-full items-center justify-between'>
          <Paragraph className='text-base font-semibold'>Accounts</Paragraph>
          <Button
            size='small'
            sentiment='error'
            onClick={() => onShowDetachCalendarModal()}
          >
            Delete Motion Tasks
          </Button>
        </div>
        <SettingList
          items={emailAccounts.map((emailAccount) => (
            <EmailAccountRow
              key={emailAccount.id}
              emailAccount={emailAccount}
            />
          ))}
        />

        <AddAccountDropdown onAddAccount={onAddAccount}>
          <div>
            <Button variant='muted' sentiment='neutral' size='small'>
              <PlusSolid />
              Add account
            </Button>
          </div>
        </AddAccountDropdown>
      </div>
      <div className='flex w-full flex-col gap-6'>
        <Paragraph className='text-base  font-semibold'>
          Calendar Grouping
        </Paragraph>
        <div className='flex w-full flex-col gap-2.5'>
          <div className='flex items-center gap-3'>
            <Paragraph className='font-semibold'>My Calendars</Paragraph>
            <SecondaryButton
              icon={PencilSolid}
              onClick={() => openCalListPickerModal('mine')}
              size='small'
            >
              Edit
            </SecondaryButton>
          </div>
          <SettingList
            items={myCalendars.map((calendar) => (
              <div key={calendar.id} className='flex justify-between p-4'>
                <Paragraph className='max-w-full gap-1 truncate'>
                  <CalendarTitle
                    calendar={calendar}
                    emailAccountsMap={emailAccountsMap}
                    isMyCalendarList
                  />
                </Paragraph>
                {mainCalendarId === calendar.id && (
                  <LabelTag muted text='Main Calendar' />
                )}
                {mainCalendarId !== calendar.id && (
                  <DropdownMenu
                    trigger={<TextButton icon={DotsVerticalSolid} />}
                    options={[
                      {
                        label: "Remove from 'My Calendars'",
                        onSelect: () => removeCalendarFromMyCalendars(calendar),
                      },
                    ]}
                  />
                )}
              </div>
            ))}
          />
        </div>
        <div className='flex w-full flex-col gap-2.5'>
          <div className='flex items-center gap-3'>
            <Paragraph className='font-semibold'>Frequently met with</Paragraph>
            <SecondaryButton
              icon={PencilSolid}
              onClick={() => openCalListPickerModal('frequent')}
              size='small'
            >
              Edit
            </SecondaryButton>
          </div>
          <SettingList
            items={frequentlyMetCalendars.map((calendar) => (
              <div key={calendar.id} className='flex justify-between p-4'>
                <Paragraph className='max-w-full gap-1 truncate'>
                  <CalendarTitle
                    calendar={calendar}
                    emailAccountsMap={emailAccountsMap}
                  />
                </Paragraph>
                <DropdownMenu
                  trigger={<TextButton icon={DotsVerticalSolid} />}
                  options={[
                    {
                      label: "Remove from 'Frequently met with'",
                      onSelect: () => removeFrequentlyMetCalendar(calendar),
                    },
                  ]}
                />
              </div>
            ))}
          />
        </div>
      </div>
    </SettingPage>
  )
}
