import { type Contact } from '@motion/rpc/types'
import { debounce } from '@motion/utils/core'
import { logEvent } from '@motion/web-base/analytics'

import { Events } from '~/analyticsEvents'
import { useCachedCalendarEvents } from '~/areas/calendar/hooks'
import { selectSearchContactsLoadingState } from '~/state/accountsSlice'
import { selectRankedSearchContacts } from '~/state/calendar/calendarSelectors'
import { searchContacts } from '~/state/calendar/contacts-thunks'
import { selectEmailAccounts } from '~/state/email-accounts/email-accounts-slice'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { v4 as uuid4 } from 'uuid'

export const useContacts = ({
  contactsSource,
}: {
  contactsSource: 'team' | 'general' | 'custom'
}) => {
  const dispatch = useAppDispatch()
  const calendarEvents = useCachedCalendarEvents()
  const contacts = useAppSelector((state) =>
    selectRankedSearchContacts(state, calendarEvents)
  )
  const loadingState = useAppSelector(selectSearchContactsLoadingState)
  const emailAccounts = useAppSelector(selectEmailAccounts)
  const emailList = emailAccounts.map((account) => account.email)

  const currentSearchId = useRef<string | undefined>()

  const debouncedSearch = useMemo(
    () =>
      debounce((search: string) => {
        if (search.length > 1) {
          currentSearchId.current = uuid4().toString()
          void dispatch(
            searchContacts({
              search,
              teamOnly: contactsSource === 'team',
              uniqueId: currentSearchId.current,
            })
          )
        }
      }, 400),
    [contactsSource, dispatch]
  )

  const onContactSelectedAnalytics = useCallback(
    (email: string) => {
      const contact = contacts[email]
      if (!contact) {
        if (email) {
          void logEvent(Events.CONTACTS_SELECTED_CUSTOM_CONTACT)
        }
        return
      }
      if (contact.searchId === currentSearchId.current) {
        void logEvent(Events.CONTACTS_SELECTED_SEARCH_RESULT)
      } else {
        void logEvent(Events.CONTACTS_SELECTED_CACHED_RESULT)
      }
    },
    [contacts]
  )

  useEffect(
    function loadInitialContacts() {
      if (loadingState === 'preload') {
        void dispatch(searchContacts({ search: '', uniqueId: 'initial' }))
      }
    },
    [dispatch, loadingState]
  )

  let filtered: Contact[] = Object.values(contacts)

  if (contactsSource === 'team') {
    filtered = filtered.filter(
      (c) => c.teamDomain && !emailList.includes(c.email)
    )
  }

  const sortedContacts = useMemo(
    () =>
      [...filtered].sort((a, b) => {
        if (a.teamDomain !== b.teamDomain) {
          return a.teamDomain ? -1 : 1
        }
        return (b.rank ?? 0) - (a.rank ?? 0)
      }),
    [filtered]
  )

  return useMemo(
    () => ({
      contacts,
      sortedContacts,
      onSearch: debouncedSearch,
      onContactSelectedAnalytics,
      loadingState,
    }),
    [
      contacts,
      debouncedSearch,
      loadingState,
      onContactSelectedAnalytics,
      sortedContacts,
    ]
  )
}
