import { ViewGridSolid } from '@motion/icons'
import { IconButton, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { getChunkDurations } from '@motion/ui-logic'

type Props = {
  duration: number | null
  minimumDuration: number | null
  onMinimumDurationChange: (value: number | null) => void
}

export const MinimumDurationField = ({
  duration,
  minimumDuration,
  onMinimumDurationChange,
}: Props) => {
  const chunkDurations = getChunkDurations(duration)

  if (duration == null || chunkDurations.length === 0) {
    return null
  }

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <MinChunkDurationDropdownContent
          close={close}
          onChange={onMinimumDurationChange}
          chunkDurations={chunkDurations}
          minimumDuration={minimumDuration}
        />
      )}
    >
      <IconButton
        icon={ViewGridSolid}
        variant='outlined'
        sentiment='neutral'
        size='xsmall'
      />
    </PopoverTrigger>
  )
}

type MinChunkDurationDropdownContentProps = {
  close: () => void
  onChange: (value: number | null) => void
  chunkDurations: { label: string; value: number | null }[]
  minimumDuration: number | null
}

const MinChunkDurationDropdownContent = ({
  close,
  onChange,
  chunkDurations,
  minimumDuration,
}: MinChunkDurationDropdownContentProps) => {
  return (
    <SearchableList
      searchable={false}
      items={chunkDurations}
      computeKey={(item) => String(item.value)}
      computeSelected={(item) => item.value === minimumDuration}
      onSelect={(item) => {
        onChange(item.value)
        close()
      }}
      renderItem={(item) => item.label}
    />
  )
}
