import { type FieldTypeSchema } from '@motion/shared/custom-fields'
import { showToast } from '@motion/ui/base'
import { getOptionValidationRules, hasTypeOptions } from '@motion/ui-logic'
import {
  type CreateCustomFieldSchema,
  type UpdateCustomFieldSchema,
} from '@motion/zod/client'

import { type FieldErrors } from 'react-hook-form'

export type AddCustomFieldFormFields = CreateCustomFieldSchema
export type EditCustomFieldFormFields = UpdateCustomFieldSchema

export type CustomFieldFormFields =
  | AddCustomFieldFormFields
  | EditCustomFieldFormFields

export const handleOptionErrors = (
  errors: FieldErrors<CustomFieldFormFields>,
  fieldType: FieldTypeSchema
) => {
  if (hasTypeOptions(fieldType)) {
    const optionErrors = (
      errors as FieldErrors<
        Extract<
          CustomFieldFormFields,
          {
            type: 'multiSelect' | 'select'
          }
        >
      >
    )?.metadata?.options?.filter?.(Boolean)

    if (optionErrors == null) return

    if (optionErrors.some((field) => field?.value?.type === 'required')) {
      showToast('error', `Select options are required`)
    }

    const { maxLength } = getOptionValidationRules(fieldType)
    if (optionErrors.some((field) => field?.value?.type === 'maxLength')) {
      showToast(
        'error',
        `Option too long (max length is ${maxLength} characters)`
      )
    }
  }
}
