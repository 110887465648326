import { type COLOR, type COLORS } from '@motion/shared/common'
import { PopoverTrigger } from '@motion/ui/base'

import { type ReactNode } from 'react'

type ChooseColorDropdownProps = {
  colorOptions: string[] | typeof COLORS
  renderItem(colorOption: string | COLOR, onClose: () => void): ReactNode
  trigger: ReactNode
}

/**
 * @deprecated use `ColorDropdown` instead
 */
export const LegacyColorDropdown = ({
  colorOptions,
  renderItem,
  trigger,
}: ChooseColorDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => {
        return (
          <div className='flex w-[154px] flex-wrap p-2 gap-1 rounded shadow-md'>
            {colorOptions.map((colorOption) => renderItem(colorOption, close))}
          </div>
        )
      }}
    >
      {trigger}
    </PopoverTrigger>
  )
}
