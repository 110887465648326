import {
  ExternalLinkSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { type ProjectSchema, type StageSchema } from '@motion/rpc-types'
import { type ActionItem } from '@motion/ui/base'

import { useCancelStage, useCompleteStage } from '~/areas/project/hooks'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useMemo } from 'react'

import { type ActionOptions } from './types'

export const useStageActions = (
  project: ProjectSchema,
  stage: StageSchema,
  actionList: ActionOptions[] = [],
  onClose: () => void
) => {
  const completeStage = useCompleteStage()
  const cancelStage = useCancelStage()
  const navigateToModal = useNavigateByGlobalModalId()

  return useMemo<ActionItem[]>(() => {
    return actionList
      .map((action) => {
        switch (action) {
          case 'complete':
            return {
              content: 'Complete stage',
              prefix: <StatusCompletedSolid />,
              onAction: () => {
                completeStage(project, stage.stageDefinitionId)
                onClose()
              },
            }
          case 'cancel':
            return {
              content: 'Cancel stage',
              prefix: (
                <StatusCanceledSolid className='text-dropdown-item-icon-default' />
              ),
              onAction: () => {
                cancelStage(project, stage.stageDefinitionId)
                onClose()
              },
            }
          case 'edit':
            return {
              content: 'Go to project',
              prefix: <ExternalLinkSolid />,
              onAction: () => {
                navigateToModal('project', { project: project.id })
                onClose()
              },
            }
          default:
            return null
        }
      })
      .filter(Boolean) as ActionItem[]
  }, [
    actionList,
    cancelStage,
    completeStage,
    navigateToModal,
    onClose,
    project,
    stage.stageDefinitionId,
  ])
}
