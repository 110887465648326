import { Tooltip } from '@motion/ui/base'
import { Label } from '@motion/ui/pm'
import {
  formatDurationToShort,
  templateStr,
  type variantColors,
} from '@motion/ui-logic'
import { isSameDay } from '@motion/utils/dates'

import { useTaskDeadlineBadgeData } from '~/areas/tasks/hooks'
import { useI18N } from '~/global/contexts'
import { type ScheduledTaskWithRelation } from '~/global/proxies'
import { DateTime } from 'luxon'
import { type ReactNode } from 'react'

import { type TaskDeadlineStatus, useTaskDeadlineStatus } from '../../../hooks'

type TaskDeadlineBadgeProps = {
  scheduledTask: ScheduledTaskWithRelation
  size?: 'xsmall' | 'small'
}

export function TaskDeadlineBadge({
  scheduledTask,
  size = 'xsmall',
}: TaskDeadlineBadgeProps) {
  const {
    dayNumberTooltipContent,
    deadlineDelayMinutes,
    absoluteDelayHours,
    absoluteDelayDays,
    isDueSameDay,
  } = useTaskDeadlineBadgeData(scheduledTask.task)
  const deadlineStatus = useTaskDeadlineStatus(scheduledTask)
  const taskScheduledForToday = isSameDay(
    DateTime.now(),
    DateTime.fromISO(scheduledTask.schedule.start)
  )

  const { pluralize } = useI18N()

  if (taskScheduledForToday && deadlineStatus != null) {
    return (
      <Label
        color={TASK_DEADLINE_STATUS_TO_BADGE_COLOR[deadlineStatus]}
        size={size}
        noTruncate
      >
        {TASK_DEADLINE_STATUS_TO_BADGE_TEXT[deadlineStatus]}
      </Label>
    )
  }

  let badge: ReactNode

  if (deadlineDelayMinutes < 0) {
    badge = (
      <Label color='red' size={size} noTruncate>
        {templateStr('Past Due ({{duration}})', {
          duration: formatDurationToShort(
            absoluteDelayHours * 60,
            ['days', 'hours'],
            true
          ),
        })}
      </Label>
    )
  } else {
    let label: string | ReactNode
    if (taskScheduledForToday) {
      if (absoluteDelayDays === 0) {
        // The above cases handle when something is due today
        // this case is when something is completed early
        return null
      }

      label = templateStr('(Due in {{days}})', {
        days: pluralize(absoluteDelayDays, '1 day', '{{count}} days'),
      })
    } else {
      label = isDueSameDay
        ? '(Due on this day)'
        : templateStr('(Due {{days}} later)', {
            days: pluralize(absoluteDelayDays, '1 day', '{{count}} days'),
          })
    }

    badge = (
      <Label color='transparent' size={size} noTruncate>
        <span className='text-gray-500'>{label}</span>
      </Label>
    )
  }

  if (dayNumberTooltipContent != null) {
    return (
      <Tooltip renderContent={() => dayNumberTooltipContent} asChild>
        {badge}
      </Tooltip>
    )
  }

  return badge
}

const TASK_DEADLINE_STATUS_TO_BADGE_TEXT: Record<TaskDeadlineStatus, string> = {
  'due-today': 'Due Today',
  'due-tomorrow': 'Due Tomorrow',
}

const TASK_DEADLINE_STATUS_TO_BADGE_COLOR: Record<
  TaskDeadlineStatus,
  keyof typeof variantColors
> = {
  'due-today': 'yellow',
  'due-tomorrow': 'grey',
}
