import {
  type EndRelativeDateOption,
  type StartRelativeDateOption,
} from '@motion/shared/common'
import { SearchableDropdown } from '@motion/ui/base'

import type { ReactNode } from 'react'

import { UserTaskSettingRelativeDateLabels } from './utils'

type RelativeDateDropdownProps = {
  children: ReactNode
  options: readonly StartRelativeDateOption[] | readonly EndRelativeDateOption[]
  onChange:
    | ((item: StartRelativeDateOption) => void)
    | ((item: EndRelativeDateOption) => void)
}

export const RelativeDateDropdown = ({
  children,
  options,
  onChange,
}: RelativeDateDropdownProps) => {
  const relativeDateOptions = options.map((id) => ({
    label: UserTaskSettingRelativeDateLabels[id],
    id,
  }))

  return (
    <SearchableDropdown
      placement='bottom-start'
      items={relativeDateOptions}
      renderItem={(item) => <div>{item.label}</div>}
      onChange={(item) => onChange(item.id as any)}
    >
      {children}
    </SearchableDropdown>
  )
}
