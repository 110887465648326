// entry point for app
import './index.css'
import './areas/css/index.css'

import { MotionUIProvider } from '@motion/ui/providers'
import { useMountMark, useMountMeasure } from '@motion/web-common/performance'

import { UpdateOnIdle } from '~/areas/update/update-on-idle'
import { VersionCheckProvider } from '~/areas/update/version-check-context'
import { MotionLink } from '~/global/components'
import { cleanupOldLocalStorageKeys } from '~/utils/localStorage'
import { Provider } from 'react-redux'

import { AppShell } from './areas/shell/app-shell'
import { SyncSubscription } from './areas/subscriptions/sync-subscription'
import { HandleWebsocketEvents } from './areas/websockets'
import { WebappWrapper } from './components/WebappWrapper'
import { AuthProvider } from './localServices/auth'
import { CaptureLoginMetrics } from './localServices/performance/capture-login-metrics'
import { useTheme } from './state/hooks'
import { store } from './state/store'

/* eslint react-refresh/only-export-components: ["warn"] */
export { routes } from './routing/routes'

// we need to import it dynamically so it's imported after the other styles
import('./content.css')

cleanupOldLocalStorageKeys()

export const EntryPoint = () => {
  useMountMark('extensionStart')
  useMountMeasure('extension.first_render', 'htmlStart')

  return (
    <Provider store={store}>
      <InnerEntryPoint />
    </Provider>
  )
}

const InnerEntryPoint = () => {
  const theme = useTheme()

  return (
    <AuthProvider>
      <CaptureLoginMetrics />
      <AppShell>
        <VersionCheckProvider>
          <UpdateOnIdle />

          <MotionUIProvider linkComponent={MotionLink} theme={theme}>
            <HandleWebsocketEvents />
            <SyncSubscription />

            <WebappWrapper />
          </MotionUIProvider>
        </VersionCheckProvider>
      </AppShell>
    </AuthProvider>
  )
}
