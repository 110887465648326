import { type AllAvailableCustomFieldSchema } from '@motion/ui-logic/pm'
import { byValue, cascade, ordered } from '@motion/utils/array'
import { createLookupByKey } from '@motion/utils/object'

import {
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useMemo } from 'react'

import {
  getColumnIdFromCustomFieldId,
  minSizeOfCustomField,
  sizeOfCustomField,
} from './utils'

import { useAvailableCustomFieldsGroupedByTypedName } from '../../grouping/custom-fields'
import {
  findFirstMatchingValueInCustomFieldValues,
  hasCustomFieldAndValue,
} from '../../header/sort-utils'
import { DEFAULT_STRING_SORT } from '../sorting'
import { type CustomFieldDefinition } from '../types'

export function useCustomFieldBaseDefinitions(): CustomFieldDefinition<
  TaskWithRelations | ProjectWithRelations
>[] {
  const availableCustomFieldsByTypedName =
    useAvailableCustomFieldsGroupedByTypedName()

  return useMemo(
    () =>
      Object.values(availableCustomFieldsByTypedName).map((fields) => {
        const { name, id, type } = fields[0]
        return {
          // Other columns use human-readable names for id because they're known ahead of time.
          // Custom fields are dynamic, so we use the field id to generate the column id.
          // It doesn't matter which of the fields this id comes from, so long as it's unique.
          id: getColumnIdFromCustomFieldId(id),
          name: name,
          size: {
            min: minSizeOfCustomField(type),
            default: sizeOfCustomField(type),
          },
          groupKey: `${type}/${name}`,
          sortFn: cascade<TaskWithRelations | ProjectWithRelations>(
            byValue(
              (item) => hasCustomFieldAndValue(item, fields),
              ordered(['hasValue', 'noValue', 'noField'])
            ),
            byValue(
              (item) => findFirstMatchingValueInCustomFieldValues(item, fields),
              DEFAULT_STRING_SORT
            )
          ),
          customFieldMeta: {
            type,
            workspaceIdToFieldId: createLookupByWorkspaceId(fields),
          },
        }
      }),
    [availableCustomFieldsByTypedName]
  )
}

function createLookupByWorkspaceId(fields: AllAvailableCustomFieldSchema[]) {
  return createLookupByKey(fields, 'workspaceId', (o) => o.id)
}
