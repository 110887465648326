import { ClientValidationError } from '@motion/rpc'
import { type TaskSchema } from '@motion/zod/client'

import { useReadTaskFn } from '~/global/rpc/v2'
import { checkTextRuleErrors, NAME_FIELD_RULE } from '~/global/rules'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

export function useTaskNameUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      name: TaskSchema['name']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update name of chunk')
      }
      if (task.type === 'RECURRING_INSTANCE') {
        throw new Error('Cannot update name of recurring instance')
      }

      const trimName = name.trim()
      const errorMessage = checkTextRuleErrors(trimName, NAME_FIELD_RULE)
      if (errorMessage) {
        throw new ClientValidationError(errorMessage)
      }

      await updateTask(task, {
        name: trimName,
      })
      return true
    },
    [readTask, updateTask]
  )
}
