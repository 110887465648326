import { useContext } from 'react'

import {
  type BulkOpsFieldName,
  BulkOpsFieldsContext,
  type BulkOpsFieldValue,
} from './bulk-ops-fields-context'

export function useBulkOpsFields() {
  return useContext(BulkOpsFieldsContext)
}

export function useBulkOpsField<T extends BulkOpsFieldName>(field: T) {
  const ctx = useBulkOpsFields()
  return [
    ctx.fields[field],
    (value: BulkOpsFieldValue<T>) => {
      ctx.setFieldValue(field, value)
    },
  ] as const
}
