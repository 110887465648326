import { TaskSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { formatDurationToShort } from '@motion/ui-logic'

import { IconLabel } from './icon-label'
import { VerticalUser } from './vertical-user'

import { type TaskSchema, type UserInfo } from '../../types'

type MemberHeaderProps = {
  member: UserInfo
  tasks: TaskSchema[]
  gridRow?: number
  gridColumn?: number
  showTotals: boolean
}

export const MemberHeader = (props: MemberHeaderProps) => {
  const totalTaskTime = props.tasks.reduce(
    (sum, task) => sum + (task.duration ?? 0),
    0
  )

  return (
    <Container
      style={{
        gridRow: props.gridRow,
        gridColumn: props.gridColumn ?? 1,
      }}
    >
      <VerticalUser user={props.member} />
      {props.showTotals && (
        <div className='flex flex-col gap-1 self-stretch'>
          <IconLabel
            Icon={TaskSolid}
            text={formatDurationToShort(totalTaskTime)}
          />
        </div>
      )}
    </Container>
  )
}

const Container = classed('div', {
  base: `
    flex flex-col items-center
    min-w-[85px]
    gap-2
    p-2
    sticky left-0
    bg-calendar-bg-default
  `,
})
