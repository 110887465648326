import {
  type PMProjectType,
  type PMTaskAssigneeType,
  type PMWorkspaceMemberType,
} from '../components/ProjectManagement/types'

export interface UserType {
  id: string
  name: string
  email: string // The email used to log in
  picture?: string
  tasks?: PMTaskAssigneeType
  projectsManaging?: PMProjectType[]
  teams: PMWorkspaceMemberType[]
  type: UserTypeType
  onboardingComplete?: boolean
}

export enum UserTypeType {
  NORMAL = 'NORMAL',
  PENDING_INVITED = 'PENDING_INVITED',
}
