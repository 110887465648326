import { CalendarSolid } from '@motion/icons'
import { FormModal, PopoverButton } from '@motion/ui/base'
import { formatTime, updateDateOnly } from '@motion/ui-logic'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'
import { parseDate } from '@motion/utils/dates'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { SimpleDateDropdown } from '~/global/components/dropdowns'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { selectCalendarStartDay } from '../../../../state/calendar/calendarSlice'
import { useAppSelector } from '../../../../state/hooks'
import { SearchableTimeDropdown } from '../../../project-management/components'

export type DoTaskLaterCallback = {
  snoozeUntil: DateTime
}

export type ConnectedDoTaskLaterModalProps = {
  initialTime: DateTime
}

type DateTimePickerRowProps = {
  value: DateTime
  onChange: (x: DateTime) => void
  calendarStartDay: CalendarStartDay
  className?: string
}

const DateTimePickerRow = ({
  value,
  onChange,
  calendarStartDay,
  className,
}: DateTimePickerRowProps) => {
  const handleDateChange = (selected: DateTime) => {
    onChange(updateDateOnly(value, selected))
  }

  const formatted = formatTime(value)
  return (
    <div className={twMerge('flex flex-row items-center', className)}>
      <SimpleDateDropdown
        value={value.toISO()}
        calendarStartDay={calendarStartDay}
        disabledDate={(value) => value < DateTime.now().startOf('day')}
        onChange={(value) => {
          if (!value) return
          handleDateChange(parseDate(value))
        }}
      >
        <PopoverButton className='mr-2 w-[250px]'>
          <div className='flex flex-row items-center'>
            <CalendarSolid className='text-semantic-neutral-icon-default h-5 w-5 mr-2' />
            {parseDate(value).toFormat('MMMM d, yyyy')}
          </div>
        </PopoverButton>
      </SimpleDateDropdown>
      <SearchableTimeDropdown
        startDateTime={value.startOf('day')}
        value={formatted}
        onChange={(val) => onChange(val)}
      >
        <div>
          <PopoverButton>{formatted}</PopoverButton>
        </div>
      </SearchableTimeDropdown>
    </div>
  )
}

export const DoTaskLaterModal = ({
  close,
  initialTime,
}: ModalTriggerComponentProps<'do-task-later'>) => {
  const [snoozeUntilValue, setSnoozeUntilValue] = useState(initialTime)
  const calendarStartDay = useAppSelector(selectCalendarStartDay)

  const onAction = () => {
    close({ snoozeUntil: snoozeUntilValue })
  }

  return (
    <FormModal
      onClose={close}
      submitAction={{
        text: 'Save',
        onAction,
      }}
      title='Do Later'
      visible
    >
      <div className='text-semantic-neutral-text-default font-medium pb-3'>
        Don&apos;t start task until
      </div>
      <DateTimePickerRow
        value={snoozeUntilValue}
        onChange={setSnoozeUntilValue}
        calendarStartDay={calendarStartDay}
        className='mb-3'
      />
    </FormModal>
  )
}
