import { useModalStatus } from '@motion/web-common/modals'

import { AnnualUpgradeModal } from './annual-upgrade-modal'

import { type StripeSubscription } from '../../state/userSlice'

export type OpenConnectedAnnualUpgradeModalProps = {
  subscription: StripeSubscription
}

export const ConnectedAnnualUpgradeModal = () => {
  const status = useModalStatus('upgrade-panel')

  if (!status.visible) {
    return null
  }

  return <AnnualUpgradeModal onClose={() => status.close()} {...status.props} />
}
