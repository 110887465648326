import { type PriorityLevelSchema } from '@motion/rpc-types'

import { useSearchParams } from '~/routing'
import { useParams } from 'react-router'

import { type ProjectUrlSearchParams } from '../utils'

export function useProjectModalUrlParams() {
  const { workspaceId, projectId } = useParams<{
    workspaceId: string
    projectId: string
  }>()
  const {
    project,
    forProjectId,
    forWorkspace: forWorkspaceParam,
    forPriority,
    forStatus,
    forManager,
    forLabel,
    forCustomField,
    forStartDate,
    forDueDate,
  } = useSearchParams<ProjectUrlSearchParams>()

  return {
    workspaceId: forWorkspaceParam ?? workspaceId,
    projectId:
      project === 'new' ? undefined : project === 'edit' ? projectId : project,
    forPriority: forPriority as PriorityLevelSchema | undefined,
    forStatus,
    forManager,
    forLabel,
    forStartDate,
    forDueDate,
    forCustomField,
    forProjectId,
  }
}
