import { type EventFormFields, isEventReadOnly } from '@motion/ui-logic'

import { useHostCalendar, useHostEmailAccount } from '~/areas/calendar/hooks'
import { type ReactNode, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { EventModalStateContext } from './event-modal-state-ctx'
import { type EventModalState } from './types'

import { type InitialFormOptions } from '../hooks'

export type EventModalStateProviderProps = {
  children: ReactNode
  event: InitialFormOptions['event']
  eventRecurringParent: InitialFormOptions['eventRecurringParent']
  meetingTask: InitialFormOptions['meetingTask']
  schedulingTask: InitialFormOptions['schedulingTask']
}

export function EventModalStateProvider({
  children,
  event,
  eventRecurringParent,
  meetingTask,
  schedulingTask,
}: EventModalStateProviderProps) {
  const form = useFormContext<EventFormFields>()
  const [sidePanelOpen, setSidePanelOpen] = useState(false)
  const [hasPendingComment, setHasPendingComment] = useState(false)

  const eventFieldValues = form.getValues()

  const hostEmailAccount = useHostEmailAccount(eventFieldValues.email)
  const { hostCalendar, isLoading: isLoadingHostCalendar } = useHostCalendar(
    eventFieldValues.email,
    eventFieldValues.calendarId
  )

  const value = useMemo<EventModalState>(() => {
    return {
      isLoading: isLoadingHostCalendar,
      initialEvent: event,
      initialEventRecurringParent: eventRecurringParent,
      initialMeetingTask: meetingTask,
      initialSchedulingTask: schedulingTask,
      sidePanelOpen,
      toggleSidePanel: () => setSidePanelOpen((prev) => !prev),
      isReadOnly: isEventReadOnly({ event, hostCalendar, hostEmailAccount }),
      hostCalendar,
      hostEmailAccount,
      hasPendingComment,
      setHasPendingComment,
    }
  }, [
    event,
    eventRecurringParent,
    hostEmailAccount,
    hostCalendar,
    isLoadingHostCalendar,
    meetingTask,
    schedulingTask,
    sidePanelOpen,
    hasPendingComment,
  ])

  return (
    <EventModalStateContext.Provider value={value}>
      {children}
    </EventModalStateContext.Provider>
  )
}
