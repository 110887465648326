import { type PersonCustomField } from '@motion/ui-logic'

import {
  AssigneesDisplay,
  CustomField,
} from '~/areas/project-management/custom-fields'
import { type FC } from 'react'

import { CellAction } from '../../components'
import { type ControlledCustomFieldProps } from '../types'

export const ControlledPersonCell: FC<
  ControlledCustomFieldProps<PersonCustomField>
> = ({ value, onSubmit, customField, disabled }) => {
  return (
    <CustomField.Person
      selectedUserId={value}
      onChange={onSubmit}
      workspaceId={customField.definition.workspaceId}
    >
      <CellAction isFake={disabled} disabled={disabled}>
        <AssigneesDisplay
          userIds={value ? [value] : null}
          type='person'
          hideIconWhenValue
        />
      </CellAction>
    </CustomField.Person>
  )
}
