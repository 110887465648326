import { merge } from '@motion/utils/core'
import { useLocalStorage } from '@motion/web-common/storage'

import { useCallback, useMemo } from 'react'

import { defaultSidebarState, sidebarKeys } from './constants'
import { type SidebarState } from './types'

type DeepStateSetter<T> = (val: Partial<T> | ((prev: T) => Partial<T>)) => void

export function useSidebarState<T extends SidebarState = SidebarState>(
  sidebarKey: keyof typeof sidebarKeys,
  initialWidth?: number
) {
  const initialState = useMemo(
    () =>
      merge({}, defaultSidebarState, {
        width: initialWidth ?? defaultSidebarState.width,
      }) as T,
    [initialWidth]
  )

  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    sidebarKeys[sidebarKey],
    initialState
  )

  const state = (localStorageValue ?? initialState) as T

  const setState = useCallback<DeepStateSetter<T>>(
    (val) => {
      if (typeof val === 'function') {
        const newState = val(state)

        return void setLocalStorageValue(merge({}, state, newState))
      }

      setLocalStorageValue(merge({}, state, val))
    },
    [setLocalStorageValue, state]
  )

  const toggleOpen = useCallback(() => {
    setLocalStorageValue(merge({}, state, { open: !state.open }))
  }, [setLocalStorageValue, state])

  return [state, setState, toggleOpen] as const
}
