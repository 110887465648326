import {
  API,
  type ApiTypes,
  type ApiUseMutationOptions,
  createUseMutation,
  useQueryOptionsFactory,
} from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
  useReplaceModelInCachesFn,
} from '@motion/rpc-cache'

import {
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'

import { applyOptimisticFolderItemUpdates } from '../cache'

export const folderCacheKeysToUpdate = [
  API.folders.queryKeys.getAll,
  MODEL_CACHE_KEY,
]

const folderCacheQueryFilter = createQueryFilter(folderCacheKeysToUpdate)

type QueryApi = ApiTypes<typeof API.folders.getFolders>

export const useFolders = (
  args?: QueryApi['args'],
  opts?: Omit<UseQueryOptions<QueryApi['queryFnData'], Error>, 'queryKey'>
): UseQueryResult<QueryApi['data']> => {
  const replaceModelInCaches = useReplaceModelInCachesFn()
  const queryOptionsOf = useQueryOptionsFactory(API.folders.getFolders)
  const queryArgs = queryOptionsOf(args, opts as any)

  return useQuery<QueryApi['queryFnData'], Error, QueryApi['data']>({
    ...queryArgs,
    // @ts-expect-error - typing
    async queryFn(ctx) {
      const value = await queryArgs.queryFn(ctx as any)
      replaceModelInCaches(value.models)
      return value
    },
    notifyOnChangeProps: ['data', 'dataUpdatedAt'],
  })
}

const useCreateFolderMutation = createUseMutation(API.folders.createFolder)

export const useCreateFolder = (
  opts?: ApiUseMutationOptions<typeof API.folders.createFolder>
) => {
  const queryClient = useQueryClient()

  return useCreateFolderMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(queryClient, folderCacheQueryFilter, data)

      opts?.onSuccess?.(data, variables, context)
    },
  })
}

const useUpdateFolderMutation = createUseMutation(API.folders.updateFolder)

export const useUpdateFolder = (
  opts?: ApiUseMutationOptions<typeof API.folders.updateFolder>
) => {
  const queryClient = useQueryClient()

  return useUpdateFolderMutation({
    ...opts,
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(queryClient, folderCacheQueryFilter, data)

      opts?.onSuccess?.(data, variables, context)
    },
  })
}

export const useDeleteFolderMutation = createUseMutation(
  API.folders.deleteFolder
)

const useUpdateItemInFolderMutation = createUseMutation(
  API.folders.updateItemInFolder
)

export const useUpdateItemInFolder = (
  opts?: ApiUseMutationOptions<typeof API.folders.updateItemInFolder>
) => {
  const queryClient = useQueryClient()

  return useUpdateItemInFolderMutation({
    ...opts,
    onMutate: async (vars) => {
      const ctx = opts?.onMutate?.(vars) as Record<string, unknown> | undefined

      const { itemId, parentFolderId, order } = vars

      const folderItemUpdate = await applyOptimisticFolderItemUpdates(
        queryClient,
        itemId,
        {
          parentFolderId,
          order,
        }
      )

      return { ...ctx, folderItemUpdate }
    },
    onSuccess: (data, variables, context) => {
      MotionCache.upsert(queryClient, folderCacheQueryFilter, data)

      opts?.onSuccess?.(data, variables, context)
    },
    onError: (err, vars, ctx) => {
      const { folderItemUpdate } = ctx as {
        folderItemUpdate: OptimisticUpdateValue
      }

      folderItemUpdate.rollback()

      if (opts?.onError) {
        opts.onError(err, vars, ctx)
      } else {
        throw err
      }
    },
  })
}
