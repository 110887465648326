import { isCanceledStatus } from '@motion/shared/common'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import { isMeetingTask, isSchedulingTask } from '@motion/ui-logic/pm/task'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type NormalTaskSchema } from '@motion/zod/client'

import { useRouteAnalyticsMetadata } from '~/global/analytics'
import { useLookup } from '~/global/cache'
import { useWorkspaceStatusById } from '~/global/hooks'
import { useTaskModalUrl } from '~/global/navigation'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { GhostName } from './ghost-name'
import { LegacyGhostName } from './legacy-ghost-name'

type TaskNameProps = {
  currentTaskId: string | undefined
  task: NormalTaskSchema
}

export function TaskName({ currentTaskId, task }: TaskNameProps) {
  const buildTaskModalUrl = useTaskModalUrl()

  const { id, name } = task

  const status = useWorkspaceStatusById(task.statusId)
  const lookup = useLookup()

  const isGhost = isGhostTask(task)
  const isMeeting = isMeetingTask(task)
  const isSchedulingTaskType = isSchedulingTask(task)

  const taskLink = buildTaskModalUrl({
    task: id,
    isMeeting,
  })

  const context = useRouteAnalyticsMetadata()
  const onLinkClick = () => {
    recordAnalyticsEvent('TASK_SIDEBAR_LINE_CLICK', {
      ...context,
      isGhost,
      type: isMeeting
        ? 'meeting'
        : isSchedulingTaskType
          ? 'scheduling'
          : 'task',
    })
  }

  if (isGhost) {
    const isLegacyGhost = lookup('tasks', id) == null
    if (!isLegacyGhost) {
      return (
        <GhostName
          isActive={currentTaskId === id}
          taskName={name}
          taskLink={taskLink}
          onLinkClick={onLinkClick}
          isCanceled={isCanceledStatus(status)}
        />
      )
    }

    return <LegacyGhostName name={name} />
  }

  return (
    <Link
      className={twMerge(
        'text-xs truncate grow text-semantic-neutral-text-default',
        currentTaskId === id && 'font-bold',
        isCanceledStatus(status) &&
          'text-semantic-neutral-text-subtle line-through'
      )}
      onClick={onLinkClick}
      to={taskLink}
    >
      {name}
    </Link>
  )
}
