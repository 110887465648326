import { WorkspaceSolid } from '@motion/icons'
import { FormModal, PopoverButton, showToast } from '@motion/ui/base'
import {
  type AllAvailableCustomFieldSchema,
  getHumanReadableCustomFieldType,
  templateStr,
  truncateAtSpace,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type WorkspaceSchema } from '@motion/zod/client'

import { CustomFieldBadge } from '~/areas/custom-fields/badges'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useDuplicateCustomField } from '~/areas/project-management/hooks'
import { MultiWorkspaceDropdown } from '~/global/components/dropdowns'
import { useWorkspaceById } from '~/global/hooks'
import { showErrorToast } from '~/global/toasts'
import { useState } from 'react'

import {
  CustomFieldDisplay,
  CustomFieldNameAndType,
  FieldInfoText,
} from './styled'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'duplicate-custom-field': {
      workspaceId: WorkspaceSchema['id']
      customField: AllAvailableCustomFieldSchema
    }
  }
}

export const ConnectedDuplicateCustomFieldModal = ({
  workspaceId,
  customField,
  close,
}: ModalTriggerComponentProps<'duplicate-custom-field'>) => {
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<
    WorkspaceSchema[]
  >([])
  const { mutateAsync: duplicateCustomField } = useDuplicateCustomField()

  const workspace = useWorkspaceById(workspaceId)
  if (workspace == null) {
    throw new Error('Workspace not found')
  }

  const onSubmit = async () => {
    try {
      if (selectedWorkspaces.length === 0) {
        throw new Error('No workspaces selected')
      }
      await duplicateCustomField({
        customFieldInstanceId: customField.id,
        workspaceId,
        destinationWorkspaceIds: selectedWorkspaces.map((w) => w.id),
      })
      recordAnalyticsEvent('CUSTOM_FIELD_DUPLICATED', {
        type: customField.type,
      })
      close()
      const toastMessage = getToastMessage(customField, selectedWorkspaces)
      showToast('success', toastMessage)
    } catch (error) {
      showErrorToast(error)

      throw error
    }
  }

  const disabled = selectedWorkspaces.length === 0

  return (
    <FormModal
      title='Duplicate custom field'
      visible
      onClose={close}
      cancelAction={{
        shortcut: 'Escape',
      }}
      submitAction={{
        text: 'Duplicate custom field',
        onAction: onSubmit,
        disabled,
      }}
    >
      <div className='flex flex-col gap-6'>
        <p className='text-sm text-semantic-neutral-text-subtle'>
          Select workspaces you want to duplicate this custom field to.
        </p>

        <div className='flex flex-col gap-1'>
          <CustomFieldDisplay>
            <CustomFieldBadge type={customField.type} />

            <CustomFieldNameAndType>
              {templateStr(`{{name}} {{type}}`, {
                name: (
                  <span className='max-w-xs truncate'>{customField.name}</span>
                ),
                type: (
                  <span className='text-semantic-neutral-text-disabled'>
                    ({getHumanReadableCustomFieldType(customField)})
                  </span>
                ),
              })}
            </CustomFieldNameAndType>
          </CustomFieldDisplay>

          <FieldInfo type={customField.type} />
        </div>

        <MultiWorkspaceDropdown
          onChange={setSelectedWorkspaces}
          selectedWorkspaceIds={selectedWorkspaces.map((w) => w.id)}
          filterWorkspaces={(workspace) => workspace.id !== workspaceId}
        >
          <PopoverButton icon={<WorkspaceSolid />}>
            {selectedWorkspaces.length > 0
              ? selectedWorkspaces.map((w) => w.name).join(', ')
              : 'Select workspaces'}
          </PopoverButton>
        </MultiWorkspaceDropdown>
      </div>
    </FormModal>
  )
}

function FieldInfo({ type }: { type: AllAvailableCustomFieldSchema['type'] }) {
  if (type === 'multiSelect' || type === 'select') {
    return (
      <FieldInfoText>
        If a custom field of the same name and type already exists in the new
        workspace, Motion will merge all values from both workspaces into a
        single, unified list.
      </FieldInfoText>
    )
  }

  if (type === 'multiPerson' || type === 'person') {
    return (
      <FieldInfoText>
        If a custom field of the same name and type already exists in the new
        workspace, Motion will automatically add any missing users to the new
        workspace as members.
      </FieldInfoText>
    )
  }

  return null
}

function getToastMessage(
  customField: AllAvailableCustomFieldSchema,
  selectedWorkspaces: WorkspaceSchema[]
) {
  const truncatedName = truncateAtSpace(customField.name)

  if (selectedWorkspaces.length === 1) {
    return `"${truncatedName}" has been duplicated to "${selectedWorkspaces[0].name}"`
  }

  return `"${truncatedName}" has been duplicated to ${selectedWorkspaces.length} workspaces`
}
