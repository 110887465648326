import {
  CogSolid,
  DesktopComputerSolid,
  DeviceMobileOutline,
  LogoutSolid,
} from '@motion/icons'
import { type CalendarProviderType } from '@motion/rpc/types'
import { ActionList, CompanyLogo, Shortcut, UserAvatar } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { getWindowData } from '@motion/web-base/env'
import { useHasTreatment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { type User } from '~/state/userSlice'
import { type NavigateFunction } from 'react-router-dom'

import { ConnectedThemeSettings } from './connected-theme-settings'

type AccountPopoverProps = {
  close: () => void
  logout: () => void
  navigate: NavigateFunction
  profileUrl?: string | null
  provider?: CalendarProviderType
  user: User
}

export function AccountPopover({
  close,
  logout,
  navigate,
  profileUrl,
  provider,
  user,
}: AccountPopoverProps) {
  const { isElectron } = getWindowData()
  const devPanelEnabled = useHasTreatment('development-panel')
  const modalApi = useModalApi()

  return (
    <>
      <UserEmailHeader
        profileUrl={profileUrl}
        provider={provider}
        user={user}
      />

      <ActionList
        sections={[
          {
            items: [
              {
                content: 'Settings',
                prefix: <CogSolid />,
                onAction: () => {
                  navigate('/web/settings/calendar')
                  close()
                },
              },
            ],
          },
          {
            items: [
              !isElectron && {
                content: 'Download desktop app',
                prefix: <DesktopComputerSolid />,
                onAction: () => {
                  recordAnalyticsEvent('DESKTOP_DOWNLOAD_CTA_CLICKED', {
                    view: 'navbar',
                  })
                  window.open('https://www.usemotion.com/desktop-app', '_blank')
                  close()
                },
              },
              {
                content: 'Download mobile app',
                prefix: <DeviceMobileOutline />,
                onAction: () => {
                  recordAnalyticsEvent('MOBILE_DOWNLOAD_CTA_CLICKED', {
                    view: 'navbar',
                  })
                  window.open(
                    `${window.location.protocol}//${window.location.host}/mobile`,
                    '_blank'
                  )
                  close()
                },
              },
            ],
          },
          {
            items: [
              {
                content: <ConnectedThemeSettings />,
              },
            ],
          },
          !__IS_PROD__ &&
            devPanelEnabled && {
              items: [
                {
                  content: (
                    <div className='flex items-center justify-between'>
                      Open Motion dev panel
                      <Shortcut shortcut='mod+/' />
                    </div>
                  ),
                  onAction: () => {
                    modalApi.open('motion-dev-modal')
                    close()
                  },
                },
              ],
            },
          {
            items: [
              {
                prefix: <LogoutSolid />,
                content: 'Log out',
                onAction: logout,
              },
            ],
          },
        ]}
      />
    </>
  )
}

const UserEmailHeader = ({
  profileUrl,
  provider,
  user,
}: {
  profileUrl?: string | null
  provider?: CalendarProviderType
  user: User
}) => (
  <div className='flex p-3 gap-3 self-stretch min-w-0 items-center bg-semantic-neutral-surface-overlay-bg-subtlest max-w-[280px]'>
    <UserAvatar
      id={user.id}
      name={user.displayName.trim() || user.email}
      profileUrl={profileUrl}
      bottomRight={provider ? <CompanyLogo provider={provider} /> : undefined}
      size={18}
    />

    <span className='text-semantic-neutral-text-subtle text-justify text-sm font-medium truncate'>
      {user.email}
    </span>
  </div>
)
