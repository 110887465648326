import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import {
  type DateSelectArg,
  type EventClickArg,
  type EventDropArg,
} from '@fullcalendar/core'
import {
  type DateClickArg,
  type EventResizeDoneArg,
} from '@fullcalendar/interaction/index.js'
import {
  type FullCalendarMotionEvent,
  NEW_EVENT_ID,
} from '~/areas/calendar/utils'
import {
  useScheduleAssistantCalendarContext,
  useUpdateSelectedCalendarEvent,
} from '~/areas/event/modals/schedule-assistant/hooks'
import { parseFullCalendarEventId } from '~/state/calendarEvents/calendarEventsUtils'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

export function useCalendarHandlers() {
  const [{ mode }] = useScheduleAssistantCalendarContext()
  const updateSelectedEvent = useUpdateSelectedCalendarEvent()

  const onEventClick = useCallback(
    (arg: EventClickArg) => {
      // Disable event click when creating a new event
      if (mode === 'create-new') {
        return
      }

      const { type, id } = parseFullCalendarEventId(arg.event.id)
      if (type !== 'event' || id == null) return

      const { meetingTaskProject } = arg.event
        .extendedProps as FullCalendarMotionEvent['extendedProps']

      // Only select the event if it's not a meeting task project
      if (meetingTaskProject == null) {
        updateSelectedEvent({
          id,
        })

        recordAnalyticsEvent('SCHEDULE_ASSISTANT_EVENT_SELECTED', {
          type: 'calendar',
        })
      }
    },
    [mode, updateSelectedEvent]
  )

  const onEmptySlotClick = useCallback(
    (arg: DateClickArg) => {
      // We don't allow creating all-day events in the schedule assistant
      if (mode === 'select-existing' || arg.allDay) return

      const startDate = DateTime.fromJSDate(arg.date)
      const endStr = startDate.plus({ minutes: 30 }).toISO()

      updateSelectedEvent({
        id: NEW_EVENT_ID,
        new: { allDay: arg.allDay, start: arg.dateStr, end: endStr },
      })

      recordAnalyticsEvent('SCHEDULE_ASSISTANT_SLOT_SELECTED')
    },
    [mode, updateSelectedEvent]
  )

  const onEmptySlotDragEnd = useCallback(
    (arg: DateSelectArg) => {
      // We don't allow creating all-day events in the schedule assistant
      if (mode === 'select-existing' || arg.allDay) return

      updateSelectedEvent({
        id: NEW_EVENT_ID,
        new: { allDay: arg.allDay, start: arg.startStr, end: arg.endStr },
      })

      recordAnalyticsEvent('SCHEDULE_ASSISTANT_SLOT_SELECTED')
    },
    [mode, updateSelectedEvent]
  )

  const onEventUpdate = useCallback(
    async (arg: EventDropArg | EventResizeDoneArg) => {
      const { type } = parseFullCalendarEventId(arg.event.id)

      switch (type) {
        case 'new_event':
          updateSelectedEvent({
            id: arg.event.id,
            new: {
              start: arg.event.startStr,
              end: arg.event.endStr,
              allDay: arg.event.allDay,
            },
          })
          break
        default:
          return
      }
    },
    [updateSelectedEvent]
  )

  return {
    onEventClick,
    onEmptySlotClick,
    onEmptySlotDragEnd,
    onEventUpdate,
  }
}
