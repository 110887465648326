import { type LinkLikeComponentProps } from '@motion/ui/providers'

import { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export type MotionLinkProps = LinkLikeComponentProps

export const MotionLink = forwardRef<HTMLAnchorElement, MotionLinkProps>(
  function MotionLink(props, ref) {
    const { url, external, children, ...rest } = props

    // if the url has the protocol, open in a new tab.
    if (external ?? url.includes('://')) {
      return (
        <a
          ref={ref}
          onClick={(e) => {
            e.stopPropagation()
          }}
          {...rest}
          target='_blank'
          rel='noopener noreferrer'
          href={url}
        >
          {children}
        </a>
      )
    }

    return (
      <Link
        ref={ref as any}
        onClick={(e) => {
          e.stopPropagation()
        }}
        {...rest}
        to={url}
        // global link in the app have a blue transition that we wouldn't want (most of the time)
        className={twMerge(
          'transition-none hover:text-semantic-primary-text-default',
          rest.className
        )}
      >
        {children}
      </Link>
    )
  }
)
