import { type TaskResponseSchema } from '@motion/zod/client'

import { ConnectedUserBadge } from '~/global/components/badges'

import { highlightEmText } from '../../../utils'

interface AssigneeFieldProps {
  assigneeUserId: TaskResponseSchema['assigneeUserId']
  assigneeName: TaskResponseSchema['assignee']['name']
}

export const AssigneeField = (props: AssigneeFieldProps) => {
  const { assigneeUserId, assigneeName } = props

  return (
    <div className='text-[11px] leading-3 flex items-center gap-1'>
      <ConnectedUserBadge userId={assigneeUserId} size='small' />
      <span className='truncate max-w-[150px]'>
        {assigneeName ? highlightEmText(assigneeName) : 'Unassigned'}
      </span>
    </div>
  )
}
