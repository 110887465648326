import { TemplateSolid } from '@motion/icons'
import { Button, PopoverTrigger } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useKanbanCardCustomFields } from './hooks'

import { VisibilityDropdownContent } from '../components'

export function KanbanCardFieldsButton() {
  const trigger = (
    <Button
      variant='outlined'
      sentiment='neutral'
      size='small'
      onClick={() => {
        recordAnalyticsEvent('PROJECT_MANAGEMENT_FIELD_VIZ_DROPDOWN_OPEN', {
          type: 'kanban',
        })
      }}
    >
      <TemplateSolid />
      <div className='font-medium whitespace-nowrap'>Card fields</div>
    </Button>
  )

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => <ConnectedKanbanVisibilityDropdownContent />}
    >
      {trigger}
    </PopoverTrigger>
  )
}

export const ConnectedKanbanVisibilityDropdownContent = () => {
  const { items, onOrderChange, toggleFieldVisibility } =
    useKanbanCardCustomFields()

  return (
    <VisibilityDropdownContent
      items={items}
      onOrderChange={onOrderChange}
      toggleFieldVisibility={toggleFieldVisibility}
    />
  )
}
