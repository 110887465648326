import { PlusSolid } from '@motion/icons'
import { type CustomFieldSchema } from '@motion/shared/custom-fields'
import {
  ActionList,
  PopoverTrigger,
  type PopoverTriggerProps,
  SearchableList,
} from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'
import { byProperty, byValue, cascade, ordered } from '@motion/utils/array'
import { useModalApi } from '@motion/web-common/modals'
import { type WorkspaceSchema } from '@motion/zod/client'

import { useCustomFieldById } from '~/areas/custom-fields/hooks'
import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { Label } from '~/global/components/labels'
import { type ReactNode, useMemo, useState } from 'react'

export type SelectDropdownProps = {
  selectedItemId: string | null
  onChange: (id: string | null) => void
  onClose?: PopoverTriggerProps['onClose']
  workspaceId: WorkspaceSchema['id']
  customFieldId: CustomFieldSchema['id']
  children?: ReactNode
  disabled?: boolean
  hideBottomActionsSection?: boolean
  size?: 'small' | 'xsmall'
}

export const SelectDropdown = ({
  children,
  onClose,
  ...rest
}: SelectDropdownProps) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      onClose={onClose}
      renderPopover={({ close }) => (
        <SelectDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

const SelectDropdownContent = ({
  close,
  selectedItemId,
  onChange,
  customFieldId,
  hideBottomActionsSection,
  size,
}: SelectDropdownProps & {
  close: () => void
}) => {
  const workspaceSelect = useCustomFieldById(customFieldId, 'select')
  if (workspaceSelect == null) {
    return null
  }

  return (
    <InnerSelectDropdownContent
      workspaceSelect={workspaceSelect}
      selectedItemId={selectedItemId}
      close={close}
      onChange={onChange}
      hideBottomActionsSection={hideBottomActionsSection}
      size={size}
    />
  )
}

function InnerSelectDropdownContent({
  workspaceSelect,
  selectedItemId,
  close,
  onChange,
  hideBottomActionsSection = false,
  size,
}: Omit<SelectDropdownProps, 'customFieldId' | 'workspaceId'> & {
  workspaceSelect: Extract<WorkspaceCustomField, { type: 'select' }>
  close: () => void
}) {
  const modalApi = useModalApi()
  const openAddSelectOptionModal = () => {
    setTimeout(async () => {
      await modalApi.prompt('add-custom-field-select-option', {
        workspaceId: workspaceSelect.workspaceId,
        customFieldId: workspaceSelect.id,
        name: workspaceSelect.name,
        onValue: (newOption) => {
          onChange(newOption.id)
        },
      })
    }, 200)
  }

  const {
    metadata: { options },
  } = workspaceSelect

  const computeSelected = (item: { id: string }) => selectedItemId === item.id

  // Keep the initial sort on mount, so checking/unchecking items while it opens doesn't reshuffle everything
  const [initialSortedItemIds] = useState(() =>
    [...options]
      .sort(
        cascade(
          byValue(computeSelected, ordered([true, false]))
          // byProperty('sortPosition', Compare.caseInsensitive)
        )
      )
      .map((l) => l.id)
  )

  const sortedItems = useMemo(
    () =>
      [...options].sort(
        cascade(
          byProperty('id', ordered(initialSortedItemIds))
          // byProperty('sortPosition', Compare.caseInsensitive)
        )
      ),
    [options, initialSortedItemIds]
  )

  return (
    <>
      {options.length === 0 ? (
        <ActionList
          items={[
            {
              content: 'No options found',
              onAction: close,
              disabled: true,
            },
          ]}
        />
      ) : (
        <SearchableList
          items={sortedItems}
          computeSelected={computeSelected}
          computeKey={(item) => item.id}
          computeSearchValue={(item) => item.value}
          inputProps={{
            placeholder: `Choose ${truncateAtSpace(workspaceSelect.name)} ...`,
          }}
          renderItem={(item) => (
            <Label
              size={size}
              value={{ id: item.id, name: item.value, color: item.color }}
            />
          )}
          onSelect={(v) => {
            if (v.id === selectedItemId) {
              onChange(null)
              return close()
            }
            onChange(v.id)
            close()
          }}
        />
      )}
      {!hideBottomActionsSection && (
        <div className='flex flex-col border-t border-dropdown-border mt-1'>
          <ActionList
            onActionAnyItem={close}
            items={[
              {
                prefix: <PlusSolid />,
                content: `Add ${truncateAtSpace(workspaceSelect.name)} option`,
                onAction: openAddSelectOptionModal,
              },
            ]}
          />
        </div>
      )}
    </>
  )
}
