import { ChevronDownSolid, QuestionMarkCircleSolid } from '@motion/icons'
import { ActionDropdown, Button, Tooltip } from '@motion/ui/base'
import {
  shouldAllowASAP,
  shouldAllowPromoteToHardDeadline,
} from '@motion/ui-logic'

import { type CommonButtonProps } from './type'

import {
  useTasksLoadingState,
  useUpdateTasksAsap,
  useUpdateTasksDeadline,
} from '../hooks'

export const DoEarlierButton = ({ tasks, type }: CommonButtonProps) => {
  const allowPromoteToHardDeadline = tasks.every((task) =>
    shouldAllowPromoteToHardDeadline(task)
  )

  const [isLoading, setIsLoading] = useTasksLoadingState(tasks)
  const updateTasksAsap = useUpdateTasksAsap({ tasks, setIsLoading, type })
  const updateTasksDeadline = useUpdateTasksDeadline({
    tasks,
    setIsLoading,
    type,
  })

  const allowAsap = tasks.every((task) => shouldAllowASAP(task))

  if (!allowAsap && !allowPromoteToHardDeadline) {
    return null
  }

  if (allowAsap && !allowPromoteToHardDeadline) {
    return (
      <Button
        disabled={isLoading}
        variant='outlined'
        sentiment='neutral'
        size='small'
        onClick={updateTasksAsap}
      >
        Do ASAP
      </Button>
    )
  }

  return (
    <ActionDropdown
      placement='bottom-end'
      items={[
        {
          content: 'Turn on: Hard Deadline',
          suffix: <Help />,
          onAction: updateTasksDeadline,
        },
        allowAsap && {
          onAction: updateTasksAsap,
          content: 'Do ASAP',
        },
      ]}
    >
      <Button
        disabled={isLoading}
        variant='outlined'
        sentiment='neutral'
        size='small'
      >
        Meet Deadline
        <ChevronDownSolid width={16} height={16} />
      </Button>
    </ActionDropdown>
  )
}

const Help = () => {
  return (
    <Tooltip content='This task will be scheduled outside of the assigned schedule to help it to get done on time.'>
      <QuestionMarkCircleSolid
        className='text-semantic-neutral-icon-default'
        width={16}
        height={16}
      />
    </Tooltip>
  )
}
