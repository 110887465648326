import { QuestionMarkCircleSolid } from '@motion/icons'
import { type DeadlineType } from '@motion/rpc/types'
import { Toggle, Tooltip } from '@motion/ui/base'

import { ModalFieldButton } from '~/areas/task-project/components'
import { useTaskUpdater } from '~/areas/tasks/hooks'
import { useController } from 'react-hook-form'

import { SubItemIndentMarker } from '../components'
import { useTaskForm } from '../hooks'

const TOOLTIP_CONTENT =
  'If this is toggled on, Motion will prioritize this task and make sure it schedules before deadline; if needed, Motion may schedule it outside of work hours (or another selected schedule) in order to meet deadline.'

export const DeadlineToggle = () => {
  const { form, disabledFields, hiddenFields } = useTaskForm()
  const updateTask = useTaskUpdater()

  const { control, watch } = form

  const { field: deadlineTypeField } = useController({
    name: 'deadlineType',
    control,
  })

  const taskId = watch('id')
  const deadlineType = deadlineTypeField.value
  const disabled = disabledFields.has('deadlineType')
  const shouldIndent = !hiddenFields.has('dueDate')

  const onChange = (value: DeadlineType) => {
    if (taskId != null) {
      return updateTask(taskId, { deadlineType: value })
    }

    deadlineTypeField.onChange(value)
  }

  const hardDeadlineField = (
    <ModalFieldButton
      label='Hard deadline'
      size='normal'
      variant='subtle'
      disabled={disabled}
    >
      <Tooltip content={disabled ? undefined : TOOLTIP_CONTENT}>
        <QuestionMarkCircleSolid />
      </Tooltip>
      <Toggle
        checked={deadlineType === 'HARD'}
        disabled={disabled}
        size='small'
        onChange={() => {
          onChange(deadlineType === 'HARD' ? 'SOFT' : 'HARD')
        }}
      />
    </ModalFieldButton>
  )

  if (!shouldIndent) return hardDeadlineField

  return (
    <div className='flex flex-row items-center gap-0.5'>
      <SubItemIndentMarker />
      {hardDeadlineField}
    </div>
  )
}
