import { classed } from '@motion/theme'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { useSchedulingFeedbackSurveyUrl } from '~/areas/agenda/hooks'
import { useRecordSurveyUFFeedback } from '~/global/hooks'

import { FeedbackQuestion } from './components'

export function SchedulingFeedback() {
  const surveyUrl = useSchedulingFeedbackSurveyUrl()
  const recordSurveyUFFeedback = useRecordSurveyUFFeedback()

  const onPencilClick = () => {
    recordAnalyticsEvent('AGENDA_OPENED_FEEDBACK_FORM', {
      from_element: 'pencil-button',
    })
  }

  return (
    <MainContainer>
      <FeedbackQuestion
        question='Are these the tasks you wanted to work on today?'
        onThumbUpClick={() => recordSurveyUFFeedback('day_was_correct', 'good')}
        onThumbDownClick={() =>
          recordSurveyUFFeedback('day_was_incorrect', 'bad')
        }
        onPencilClick={onPencilClick}
        pencilButtonUrl={surveyUrl}
      />
      <FeedbackQuestion
        question='Are these tasks scheduled in the right order?'
        onThumbUpClick={() =>
          recordSurveyUFFeedback('order_was_correct', 'good')
        }
        onThumbDownClick={() =>
          recordSurveyUFFeedback('order_was_incorrect', 'bad')
        }
        onPencilClick={onPencilClick}
        pencilButtonUrl={surveyUrl}
      />
    </MainContainer>
  )
}

const MainContainer = classed('div', {
  base: 'flex flex-col gap-2.5 px-3 py-2 ml-9 bg-semantic-neutral-surface-bg-subtlest rounded w-fit',
})
