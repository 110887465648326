import { CalendarSolid } from '@motion/icons'
import { type CalendarSchema } from '@motion/zod/client'

import { useCalendarByProviderId } from '~/areas/calendar/hooks'

import { type OOOEvent } from './types'

import { MULTI_PROJECT_ROW_GAP, ZIndexMap } from '../../shared-constants'
import { StyledPlannerRow } from '../common'
import { OOOEventsRow } from '../planner-row/ooo-event-column/ooo-events-row'

type CalendarRowProps = {
  calendar: CalendarSchema
  events: OOOEvent[]
}
export function ConnectedCalendarRow({
  events,
  providerId,
}: {
  events: OOOEvent[]
  providerId: string
}) {
  const calendar = useCalendarByProviderId(providerId)
  if (!calendar) return null

  return <CalendarRow calendar={calendar} events={events} />
}

export function CalendarRow({ calendar, events }: CalendarRowProps) {
  return (
    <StyledPlannerRow className='w-full left-0 flex flex-col'>
      <div className='relative h-full w-full max-h-full flex flex-row items-center'>
        <div
          className='flex h-8 p-3 pl-4 items-center gap-2 shrink-0 self-stretch min-w-0 w-[218px]'
          style={{ zIndex: ZIndexMap.rowName }}
        >
          <CalendarSolid className='h-3 w-3 text-semantic-neutral-icon-default shrink-0' />
          <span className='text-semantic-neutral-text-default text-xs font-medium truncate'>
            {calendar.title}
          </span>
        </div>
        <OOOEventsRow events={events} isFullScreen />
      </div>
      <div
        className='w-full relative flex bg-semantic-neutral-surface-overlay-bg-subtle'
        style={{
          height: MULTI_PROJECT_ROW_GAP,
          zIndex: ZIndexMap.gapRow,
        }}
      />
    </StyledPlannerRow>
  )
}
