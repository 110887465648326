import { type BulkUpdateSchema } from '@motion/rpc-types'
import { groupInto } from '@motion/utils/array'
import { Sentry } from '@motion/web-base/sentry'

import { useBulkUpdateTasks } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'
import { useCallback } from 'react'

type UpdateFromWorkspace = (workspaceId: string) => BulkUpdateSchema

type UpdateData = {
  tasks: TaskWithRelations[]
  updates: BulkUpdateSchema | UpdateFromWorkspace
  setIsLoading: (isLoading: boolean) => void
}

// Given multiple task IDs
export const useBulkUpdateRows = () => {
  const bulkUpdate = useBulkUpdateTasks()

  return useCallback(
    async ({ tasks, updates, setIsLoading }: UpdateData) => {
      const workspaceGroups = groupInto(tasks, (task) => task.workspaceId)

      const updateData = workspaceGroups.map((group) => {
        return {
          currentWorkspaceId: group.key,
          taskIds: group.items.map((t) => t.id),
          update: typeof updates === 'function' ? updates(group.key) : updates,
        }
      })

      setIsLoading(true)

      try {
        await bulkUpdate(updateData)
        setIsLoading(false)
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            position: 'useBulkUpdateRows - Past due modal',
          },
        })
        setIsLoading(false)
      }
    },
    [bulkUpdate]
  )
}
