import { showToast } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type TasksV2CreateSchema } from '@motion/zod/client'

import { useWorkspaceFns } from '~/global/hooks'
import { useCreateTask as useCreateTaskCall } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { getAmplitudeTaskChangedFields } from '../../utils'

export const useCreateTask = () => {
  const { mutateAsync: createTask } = useCreateTaskCall()
  const { getWorkspaceMembers } = useWorkspaceFns()

  return useCallback(
    async (data: TasksV2CreateSchema['data']) => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_CREATE_TASK', {
        workspaceSize: getWorkspaceMembers(data.workspaceId).length,
        autoScheduled: data.isAutoScheduled ?? false,
        type: data.type === 'NORMAL' ? undefined : 'recurring',
        taskDuration: data.duration ?? null,
        ...getAmplitudeTaskChangedFields(null, data),
      })

      const response = await createTask({ data })

      showToast('success', 'Task created')

      return response.models[response.meta.model][response.id]
    },
    [createTask, getWorkspaceMembers]
  )
}
