import { useLookup } from '~/global/cache/model-cache/lookup'
import { UserBadge } from '~/global/components/badges/user'
import { UserLabel } from '~/global/components/labels/user'
import { useCallback } from 'react'

import { colorOfScheduledStatus, labelOfScheduledStatus } from '../utils'

export const useChartLookups = () => {
  const lookup = useLookup()
  const getColor = useCallback((group: string, key: string, index: number) => {
    if (group === 'scheduledStatus') {
      return colorOfScheduledStatus(key as any)
    }
  }, [])

  const getLabel = useCallback(
    (group: string, key: string, index: number) => {
      if (group === 'scheduledStatus') {
        return labelOfScheduledStatus(key as any)
      }
      if (group === 'assigneeUserId') {
        const user = lookup('users', key)

        return {
          legend: user.name,
          tooltip: <UserLabel value={user} />,
          axis: (
            <UserBadge
              key={user?.id ?? key}
              value={user}
              size='normal'
              proxyProfileUri
            />
          ),
        }
      }
    },
    [lookup]
  )
  return {
    getLabel,
    getColor,
  }
}
