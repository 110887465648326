import { byProperty, ordered } from '@motion/utils/array'

import { TASK_FIELD_LIST } from '../fields/task-fields'

const DEFAULT_COLUMN_ORDERING = [
  'name',
  'deadlineStatus',
  'assignee',
  'completedAt',
  'duration',
  'deadline',
  'startDate',
  'priority',
  'stage',
  'status',
  'blockedBy',
  'blocking',
  'createdBy',
]

export const DEFAULT_VIEW_TASK_COLUMNS = TASK_FIELD_LIST.map((t) => {
  if (!DEFAULT_COLUMN_ORDERING.includes(t.id)) return null

  /**
   * Not importing convertTaskFieldToColumn
   * to avoid circular dependency
   */
  return {
    id: t.id,
    width: sizeOf(t),
    visible: true,
    pinned: false,
  }
})
  .filter(Boolean)
  .sort(byProperty('id', ordered(DEFAULT_COLUMN_ORDERING)))

function sizeOf(field: any) {
  if (typeof field.size === 'number') return field.size
  return field.size.default
}
