import {
  ArchiveSolid,
  ArrowDownSolid,
  ArrowUpSolid,
  CogSolid,
  DotsHorizontalSolid,
  PlusSolid,
} from '@motion/icons'
import { ActionDropdown, IconButton } from '@motion/ui/base'

import { useProjectModalUrl } from '~/global/navigation'
import { useUriByRouteId } from '~/routing'
import { useNavigate } from 'react-router'

import { useWorkspacesTreeviewContext } from '../../../hooks/use-workspaces-treeview-context'

type WorkspaceActionMenuProps = {
  workspaceId: string
  moveActionDisabled: { up: boolean; down: boolean }
}

export const WorkspaceActionMenu = (props: WorkspaceActionMenuProps) => {
  const { workspaceId, moveActionDisabled } = props
  const { handleMoveWorkspaceByDirection } = useWorkspacesTreeviewContext()
  const buildProjectModalUrl = useProjectModalUrl()
  const navigate = useNavigate()
  const getRouteUri = useUriByRouteId()

  return (
    <ActionDropdown
      sections={[
        {
          items: [
            {
              prefix: <PlusSolid />,
              content: 'New Project',
              onAction: () => {
                navigate(buildProjectModalUrl({ forWorkspace: workspaceId }))
              },
            },
            {
              prefix: <ArchiveSolid />,
              content: 'Archived Tasks',
              onAction: () => {
                navigate(getRouteUri('workspace-archive', { workspaceId }))
              },
            },
          ],
        },
        {
          items: [
            {
              prefix: <ArrowUpSolid />,
              content: 'Move up',
              disabled: moveActionDisabled.up,
              onAction: () => handleMoveWorkspaceByDirection(workspaceId, -1),
            },
            {
              prefix: <ArrowDownSolid />,
              content: 'Move down',
              disabled: moveActionDisabled.down,
              onAction: () => handleMoveWorkspaceByDirection(workspaceId, 1),
            },
          ],
        },
        {
          items: [
            {
              prefix: <CogSolid />,
              content: 'Workspace Settings',
              url: getRouteUri('workspace-settings', { workspaceId }),
            },
          ],
        },
      ]}
    >
      <IconButton
        variant='muted'
        sentiment='neutral'
        size='xsmall'
        icon={DotsHorizontalSolid}
      />
    </ActionDropdown>
  )
}
