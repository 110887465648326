import { CalendarSolid } from '@motion/icons'
import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'

import { TeamScheduleActiveViewOptionsKey, useViewOptions } from '../../context'

export const ConnectedFieldSelector = () => {
  const [viewOptions, setViewOptions] = useViewOptions(
    TeamScheduleActiveViewOptionsKey
  )

  const onChangeField = (field: 'scheduledStart' | 'dueDate') => {
    setViewOptions((prev) => ({
      ...prev,
      column: {
        field,
        from: prev.column.from,
      },
    }))
  }

  return (
    <FieldSelector field={viewOptions.column.field} onChange={onChangeField} />
  )
}

type FieldSelectorProps = {
  field: 'scheduledStart' | 'dueDate'
  onChange(value: 'scheduledStart' | 'dueDate'): void
}

const OPTIONS = [
  { key: 'scheduledStart' as const, label: 'Scheduled Date' },
  { key: 'dueDate' as const, label: 'Deadline' },
]

export const FieldSelector = (props: FieldSelectorProps) => {
  const selectedLabel =
    OPTIONS.find((x) => x.key === props.field)?.label ?? OPTIONS[0].label

  const isDefault = props.field === OPTIONS[0].key

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          computeKey={(item) => item.key}
          computeSelected={(item) => item.key === props.field}
          items={OPTIONS}
          searchable={false}
          onSelect={(item) => {
            props.onChange(item.key)
            close()
          }}
          renderItem={(item) => <div>{item.label}</div>}
        />
      )}
    >
      <Button
        variant='outlined'
        sentiment={isDefault ? 'neutral' : 'primary'}
        size='small'
      >
        <CalendarSolid />
        <div className='font-medium whitespace-nowrap'>
          Show: {selectedLabel}
        </div>
      </Button>
    </PopoverTrigger>
  )
}
