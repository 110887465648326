import { PlusSolid } from '@motion/icons'
import { Button, type ButtonProps } from '@motion/ui/base'

export function InlineAddTaskButton({ onClick }: Pick<ButtonProps, 'onClick'>) {
  return (
    <div className='pl-[18px] mt-1'>
      <Button
        size='xsmall'
        variant='muted'
        sentiment='neutral'
        onClick={onClick}
      >
        <PlusSolid />
        Add task
      </Button>
    </div>
  )
}
