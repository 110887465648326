import { type StageDefinitionSchema, type TaskSchema } from '@motion/zod/client'

import { useCallback } from 'react'

import { useTaskStageUpdater } from '../updaters'

export function useTaskStageData(task: TaskSchema) {
  const stageUpdater = useTaskStageUpdater()

  const disabled = task.type !== 'NORMAL' || task.stageDefinitionId == null

  const onChange = useCallback(
    (stage: StageDefinitionSchema) => {
      if (disabled) return

      stageUpdater(task, stage)
    },
    [disabled, stageUpdater, task]
  )

  return {
    onChange,
    disabled,
  }
}
