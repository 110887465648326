import { PlusOutline } from '@motion/icons'
import { Button, type ButtonProps } from '@motion/ui/base'

import {
  AttachmentUploadShell,
  type AttachmentUploadShellProps,
} from './attachment-upload-shell'

export type AttachmentUploadButtonProps = ButtonProps &
  Omit<AttachmentUploadShellProps, 'children'> & {
    label?: string
  }

export const AttachmentUploadButton = ({
  label = 'Add attachment',
  targetId,
  targetType,
  workspaceId,
  onUpload,
  onUploadSettled,
  ...rest
}: AttachmentUploadButtonProps) => {
  return (
    <AttachmentUploadShell
      targetId={targetId}
      targetType={targetType}
      workspaceId={workspaceId}
      onUpload={onUpload}
      onUploadSettled={onUploadSettled}
    >
      {({ trigger }) => {
        return (
          <Button
            onClick={trigger}
            variant='muted'
            sentiment='neutral'
            aria-label='Upload attachment'
            {...rest}
          >
            <PlusOutline />
            {label}
          </Button>
        )
      }}
    </AttachmentUploadShell>
  )
}
