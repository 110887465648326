import {
  createStateKey,
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'

import { type TaskWithRelations } from '~/global/proxies'

export type AddTaskState = {
  parentRowId: string | null
  task: TaskWithRelations | null
}

const defaultValue: AddTaskState = {
  parentRowId: null,
  task: null,
}

export const AddTaskState = createStateKey<AddTaskState>('add-task-state', {
  defaultValue,
})

export const useAddTaskStateValue = () => useSharedState(AddTaskState)[0]
export const useAddTaskStateSetter = () => useSharedStateSendOnly(AddTaskState)
