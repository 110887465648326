import {
  type EntryValue,
  getModelCache,
  getUnknown,
  MODEL_CACHE_KEY,
  type ModelCache,
  type ModelCacheRoot,
  type ModelId,
} from '@motion/rpc-cache'
import { values } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import {
  type CalendarEventSchemaV2,
  type LabelSchema,
  type ProjectSchema,
  type ScheduledEntitySchema,
  type StatusSchema,
  type TaskSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { type ScopeContext } from '@sentry/types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createProjectProxy,
  createTaskProxy,
  type ProjectWithRelations,
  type TaskWithRelations,
} from '~/global/proxies'
import { useCallback } from 'react'

export type AllowableModelCacheKeys = Exclude<
  keyof ModelCache,
  'scheduledEntities'
>

export interface ProxyLookupFn {
  (type: 'tasks', id: string): TaskWithRelations | undefined
  (type: 'projects', id: string): ProjectWithRelations | undefined
  (type: 'calendarEvents', id: string): CalendarEventSchemaV2 | undefined
  (type: 'scheduledEntities', id: string): ScheduledEntitySchema | undefined
  <T extends AllowableModelCacheKeys>(
    type: T,
    id: string
  ): EntryValue<T> | undefined
}

export function useProxyLookup(): ProxyLookupFn {
  const lookup = useLookup()
  // @ts-expect-error - typed above
  return useCallback(
    <T extends AllowableModelCacheKeys>(type: T, id: string) => {
      const rawValue = lookup(type, id)
      if (!rawValue) return undefined

      if (type === 'projects') {
        return createProjectProxy(rawValue as unknown as ProjectSchema, lookup)
      } else if (type === 'tasks') {
        return createTaskProxy(rawValue as unknown as TaskSchema, lookup)
      }
      return rawValue
    },
    [lookup]
  )
}
export interface LookupFn {
  (type: 'workspaces', id: string): WorkspaceSchema
  (type: 'labels', id: string): LabelSchema
  (type: 'statuses', id: string): StatusSchema
  (type: 'users', id: string): UserInfoSchema
  <T extends AllowableModelCacheKeys>(
    type: T,
    id: ModelId
  ): EntryValue<T> | undefined
  <T extends AllowableModelCacheKeys>(type: T): EntryValue<T>[]
}

export type LookupFnT<T extends AllowableModelCacheKeys> = (
  type: T,
  id: string
) => EntryValue<T> | undefined

export function useLookup(): LookupFn {
  const client = useQueryClient()

  return useCallback(
    <T extends AllowableModelCacheKeys>(...args: [T] | [T, ModelId]) => {
      const type = args[0]
      const data = getModelCache(client)

      if (args.length === 1) {
        if (data == null || data[type] == null) return []
        const values = Object.keys(data[type]).map((id) => data[type][id].value)

        return filterFalsyAndReport(values, {
          data: {
            extra: {
              type,
              models: JSON.stringify(data[type], null, 2),
              values,
            },
          },
        })
      }

      if (data == null || data[type] == null) {
        return undefined
      }

      const id = args[1]
      const entry = data[type][id]
      if (entry == null) {
        return getUnknown(type, id)
      }

      return entry.value
    },
    [client]
  )
}

export function useCachedItem<T extends AllowableModelCacheKeys>(
  type: T,
  id: ModelId | undefined | null
): EntryValue<T> | null
export function useCachedItem<T extends AllowableModelCacheKeys>(
  type: T,
  id: ModelId[]
): EntryValue<T>[]
export function useCachedItem<T extends AllowableModelCacheKeys>(
  type: T,
  id: ModelId | ModelId[] | undefined | null
): EntryValue<T> | EntryValue<T>[] | null {
  const { data } = useQuery({
    queryKey: MODEL_CACHE_KEY,
    select: (data: ModelCacheRoot) => {
      if (id == null) return null

      if (Array.isArray(id)) {
        return id
          .map((itemId) => data.models[type][itemId]?.value ?? null)
          .filter(Boolean)
      }

      return data.models[type][id]?.value ?? null
    },
    enabled: id != null,
    staleTime: Infinity,
    notifyOnChangeProps: ['data', 'dataUpdatedAt'],
  })

  return data
}

export function useCachedItems<T extends AllowableModelCacheKeys, U = T>(
  type: T,
  selector?: (data: EntryValue<T>[]) => U
): U {
  const { data } = useQuery({
    queryKey: MODEL_CACHE_KEY,
    select: (data: ModelCacheRoot) => {
      const entryValues = values(data.models[type]).reduce<EntryValue<T>[]>(
        (prev, entry) => {
          if (entry.value) {
            prev.push(entry.value)
          }

          return prev
        },
        []
      )

      return selector ? selector(entryValues) : entryValues
    },
    staleTime: Infinity,
    notifyOnChangeProps: ['data', 'dataUpdatedAt'],
  })

  return data as U
}

function filterFalsyAndReport<T>(
  values: (T | null | undefined)[],
  logMeta: {
    errorMessage?: string
    data?: Partial<ScopeContext>
  } = {}
): T[] {
  const { errorMessage = 'Undefined value in model cache', data } = logMeta
  const type = data?.extra?.type?.toString() ?? 'unknown'
  const message = type ? `[${type}] ${errorMessage}` : errorMessage

  if (values.some((v) => !v)) {
    Sentry.captureException(message, {
      level: 'warning',
      ...data,
      fingerprint: [
        'model-cache-lookup',
        'undefined-value-in-model-cache',
        type,
      ],
    })
  }

  return values.filter(Boolean)
}
