import { DONE_FOR_THE_DAY_MESSAGE } from '~/global/utils'

import { EventContainer, EventMessage } from './common'

export const UpcomingEmptyEvent = () => {
  return (
    <EventContainer eventType='none'>
      <EventMessage eventType='none'>{DONE_FOR_THE_DAY_MESSAGE}</EventMessage>
    </EventContainer>
  )
}
