import { Sentry } from '@motion/web-base/sentry'

import {
  type MotionLocalStorageItems,
  type MotionLocalStorageResult,
} from '~/localServices/storage/types'

import { emitter } from './events'

import { defaultSettings } from '../../storageConstants'
import { type StorageChangeResult } from '../chromeApiTypes'
import localStorage from '../webAppLocalStorage'

export type TypedStorageChangeResult<
  T extends Partial<MotionLocalStorageItems>,
> = {
  [TKey in keyof T]: {
    oldValue?: T[TKey]
    newValue?: T[TKey]
  }
}

export type LocalStorageSetOptions = {
  source: 'firestore' | 'direct'
}

interface LocalStorage {
  reset(): Promise<void>
  clear(): Promise<void>

  get<T extends keyof MotionLocalStorageItems>(
    keys: T | readonly T[]
  ): Promise<MotionLocalStorageResult<T>>

  remove<T extends keyof MotionLocalStorageItems>(
    key: T
  ): Promise<MotionLocalStorageItems[T]>
  set<TValues extends Partial<MotionLocalStorageItems>>(
    values: TValues,
    options?: Partial<LocalStorageSetOptions>
  ): Promise<TypedStorageChangeResult<TValues> | null>
}

interface Storage {
  local: LocalStorage
  onChanged: {
    addListener(
      listener: (
        changeData: StorageChangeResult,
        options: LocalStorageSetOptions
      ) => void
    ): () => void
    removeListener(listener: (changeData: StorageChangeResult) => void): void
  }
}

export const storage: Storage = {
  local: {
    async reset() {
      await localStorage.clear()
      await localStorage.set(defaultSettings)
    },
    clear: localStorage.clear,
    async get<T extends keyof MotionLocalStorageItems>(
      keys: readonly T[]
    ): Promise<MotionLocalStorageResult<T>> {
      return (await localStorage.get(keys)) as Promise<
        MotionLocalStorageResult<T>
      >
    },
    async remove<T extends keyof MotionLocalStorageItems>(key: T) {
      const oldValue = await localStorage.get(key)
      return await localStorage.remove(key).then((res) => {
        emitter.listeners('storage.onChanged').forEach((listener) =>
          listener({
            [key]: {
              newValue: null,
              oldValue,
            },
          })
        )
        return res
      })
    },
    async set<TValues extends Partial<MotionLocalStorageItems>>(
      values: TValues,
      options: Partial<LocalStorageSetOptions> = { source: 'direct' }
    ) {
      const changeData = await localStorage.set(values)
      if (!changeData) {
        return null
      }

      const actualChanges = Object.keys(changeData).reduce((acc, key) => {
        acc[key] = changeData[key]
        return acc
      }, {} as StorageChangeResult)

      if (
        'conferenceSettings' in actualChanges &&
        actualChanges.conferenceSettings?.newValue == null
      ) {
        Sentry.captureException(
          new Error('Conference settings is being set to undefined'),
          {
            extra: {
              hadValue: actualChanges.conferenceSettings?.oldValue != null,
            },
          }
        )
      }

      // Notify listeners that data has changed
      emitter
        .listeners('storage.onChanged')
        .forEach((listener) => listener(actualChanges, options))
      return changeData as TypedStorageChangeResult<TValues>
    },
  },
  onChanged: {
    addListener(
      listener: (
        changeData: StorageChangeResult,
        options: LocalStorageSetOptions
      ) => void
    ): () => void {
      emitter.addListener('storage.onChanged', listener)
      return () => emitter.removeListener('storage.onChanged', listener)
    },
    removeListener(listener: (changeData: StorageChangeResult) => void) {
      emitter.removeListener('storage.onChanged', listener)
    },
  },
}

if (!__IS_PROD__) {
  // @ts-expect-error - local
  window.motionLocalStorage = storage.local
}
