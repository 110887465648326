import { SearchSolid } from '@motion/icons'
import { TextField } from '@motion/ui/forms'

import { forwardRef, useState } from 'react'

export const SearchField = forwardRef<HTMLLabelElement>(
  function SearchField(props, ref) {
    const [searchQuery, setSearchQuery] = useState('')

    return (
      <TextField
        ref={ref}
        size='normal'
        rounded='default'
        prefix={<SearchSolid />}
        placeholder='Search for a view...'
        value={searchQuery}
        onChange={(value) => {
          setSearchQuery(value)
        }}
        autoFocus
        showClearButton
      />
    )
  }
)
