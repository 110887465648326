import {
  AutoScheduleToggle,
  type AutoScheduleToggleProps,
  Tooltip,
} from '@motion/ui/base'
import { scheduleTypeToInfo } from '@motion/ui-logic'

import { AutoScheduleDate } from '~/global/components/labels'
import { useController } from 'react-hook-form'

import { useTemplateTaskForm } from '../hooks'

type FormAutoScheduleToggleProps = {
  showDate?: boolean
  onChange?: (checked: boolean) => void
}

export const FormAutoScheduleToggle = ({
  showDate = false,
  onChange,
}: FormAutoScheduleToggleProps) => {
  const { form, disabledFields } = useTemplateTaskForm()

  const { control } = form

  const { field } = useController({
    name: 'isAutoScheduled',
    control,
  })

  const handleOnChange: AutoScheduleToggleProps['onChange'] = (e) => {
    const { checked } = e.currentTarget

    field.onChange(checked)
    onChange?.(checked)
  }

  const tooltipMessage = disabledFields.has('statusId')
    ? undefined
    : field.value
      ? 'Turn off auto-scheduling'
      : 'Turn on auto-scheduling'

  const scheduleDateInfo = scheduleTypeToInfo['notScheduled']

  const disabled =
    disabledFields.has('statusId') || disabledFields.has('isAutoScheduled')

  return (
    <>
      <Tooltip content={tooltipMessage}>
        <AutoScheduleToggle
          checked={field.value}
          onChange={handleOnChange}
          disabled={disabled}
        >
          <AutoScheduleDate>
            {field.value ? 'Auto-scheduled' : 'Not auto-scheduled'}
          </AutoScheduleDate>
        </AutoScheduleToggle>
      </Tooltip>
      {showDate && (
        <AutoScheduleDate disabled={disabled}>
          {scheduleDateInfo.text}
        </AutoScheduleDate>
      )}
    </>
  )
}
