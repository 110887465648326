import { classed } from '@motion/theme'
import { Button } from '@motion/ui/base'
import { FieldLabel } from '@motion/ui/forms'

import { isRoleVariable } from '~/areas/flows'
import { AssigneeDropdown } from '~/areas/project-management/components'
import { UserBadge } from '~/global/components/badges'
import { type UserLabelProps } from '~/global/components/labels'
import { useWorkspaceMember } from '~/global/hooks'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export const ControlledRoleAssigneeField = ({
  rolePath,
}: {
  rolePath: `roleAssignees.${number}`
}) => {
  const { form } = useSetupProjectForm()

  const { control, watch, setFocus } = form

  const { field: roleValueField } = useController({
    name: `${rolePath}.value`,
    control,
  })

  const workspaceId = watch('workspaceId')
  const selectedAssigneeId = roleValueField.value
  const selectedAssignee = useWorkspaceMember(workspaceId, selectedAssigneeId)

  const roleVariableId = watch(`${rolePath}.variableId`)
  const roles = watch('projectDefinition.variables').filter(isRoleVariable)
  const selectedRole = roles.find((role) => role.id === roleVariableId)

  if (selectedRole == null) {
    return null
  }

  return (
    <FieldLabel label={selectedRole.name} column>
      <AssigneeDropdown
        selectedUserId={roleValueField.value ?? null}
        onChange={(value) => {
          roleValueField.onChange(value)
        }}
        onClose={() => {
          setFocus(`${rolePath}.value`)
        }}
        workspaceId={workspaceId}
      >
        <FieldButton
          variant='outlined'
          sentiment='neutral'
          ref={roleValueField.ref}
        >
          <RoleUserLabel value={selectedAssignee?.user} />
        </FieldButton>
      </AssigneeDropdown>
    </FieldLabel>
  )
}

const FieldButton = classed(Button, {
  base: `
    w-[300px]
    justify-start
  `,
})

const RoleUserLabel = ({ value, size }: UserLabelProps) => {
  const label = <div className='truncate'>{value?.name ?? 'Unassigned'}</div>

  return (
    <div className='grid grid-cols-[auto_1fr] gap-2 text-sm font-normal items-center'>
      <UserBadge value={value} size={size} />
      {label}
    </div>
  )
}
