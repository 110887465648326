import { Button, PopoverTrigger, SearchableList } from '@motion/ui/base'
import { keys } from '@motion/utils/object'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { RANGE_DAY_PIXEL_MAP } from './utils'

export type RANGE_OPTIONS = 'month' | 'quarter' | 'year'

type PlannerDateRangeDropdownProps = {
  onChange: (value: RANGE_OPTIONS) => void
  value: RANGE_OPTIONS
}

export const PlannerDateRangeDropdown = (
  props: PlannerDateRangeDropdownProps
) => {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <SearchableList
          searchable={false}
          renderItem={(item) => <div className='capitalize'>{item}</div>}
          computeKey={(item) => item}
          items={keys(RANGE_DAY_PIXEL_MAP)}
          computeSelected={(val) => val === props.value}
          onSelect={(val) => {
            recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_SET_RANGE', {
              range: val,
            })
            props.onChange(val)
            close()
          }}
        />
      )}
    >
      <Button size='small' sentiment='neutral' variant='outlined'>
        <span className='capitalize'>{props.value}</span>
      </Button>
    </PopoverTrigger>
  )
}
