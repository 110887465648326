import { Button, useShortcut } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { useUnsavedChangesPrompt } from '~/global/navigation'

import { FlowTutorialButton } from './flow-tutorial-button'
import { MainButtonFooter, ShellFooter } from './styled'

import { useFlowsModalState } from '../contexts'

type FlowsTemplateFooterProps = {
  isSubmitDisabled: boolean
  isSubmitting: boolean
  close: () => void
}

export function FlowsTemplateFooter({
  isSubmitDisabled,
  isSubmitting,
  close,
}: FlowsTemplateFooterProps) {
  const modalApi = useModalApi()
  const showUnsavedPrompt = useUnsavedChangesPrompt()
  const modalState = useFlowsModalState()

  const handleCancel = async () => {
    if (modalState.mode === 'ai-generation') {
      const close = await showUnsavedPrompt()
      if (!close) return

      modalApi.dismiss('flows-ai-modal')
    }

    close()
  }

  const handleGoBack = () => {
    modalApi.dismiss('flows-template-modal')
  }

  useShortcut('escape', () => handleCancel())

  return (
    <ShellFooter>
      <FlowTutorialButton />

      <MainButtonFooter>
        <Button
          sentiment='neutral'
          variant='muted'
          shortcut='esc'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {modalState.mode === 'ai-generation' && (
          <Button sentiment='neutral' variant='outlined' onClick={handleGoBack}>
            Go back and edit prompt
          </Button>
        )}
        <Button
          type='submit'
          sentiment='primary'
          variant='solid'
          shortcut='mod+s'
          disabled={isSubmitDisabled}
          loading={isSubmitting}
        >
          Save
        </Button>
      </MainButtonFooter>
    </ShellFooter>
  )
}
