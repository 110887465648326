import { classed } from '@motion/theme'
import { type FlowTemplateFormTask } from '@motion/ui-logic/pm/project'

import { useDebugMode } from '~/global/hooks/use-debug-mode'

export const TaskTemplateDebugInfo = ({
  task,
}: {
  task: FlowTemplateFormTask
}) => {
  const isEnabled = useDebugMode()

  if (!isEnabled) {
    return null
  }

  return <DebugContainer>Definition ID: {task.id}</DebugContainer>
}

const DebugContainer = classed(
  'div',
  `
  flex flex-col gap-2
  text-semantic-neutral-text-default text-xs
  p-2
  border border-semantic-warning-border-default rounded-md
  truncate max-w-full
  `
)
