import { BlockedBySolid, BlocksSolid, PlusSolid } from '@motion/icons'
import { type NormalTaskSchema } from '@motion/zod/client'

import { ModalFieldButton } from '~/areas/task-project/components'
import { BlockersTypeDropdown } from '~/areas/tasks/components/dropdowns'
import { useI18N } from '~/global/contexts'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { twMerge } from 'tailwind-merge'

import { BlockerTaskList } from './blocker-task-list'

export type BlockersFieldProps = {
  type: 'blockedBy' | 'blocks'
  taskId: NormalTaskSchema['id'] | null | undefined
  blockerIds: NormalTaskSchema['id'][]
  disabled?: boolean
}

export const BlockersField = ({
  type,
  taskId,
  blockerIds,
  disabled = false,
}: BlockersFieldProps) => {
  const { pluralize } = useI18N()

  const { data: task } = useTaskByIdV2({
    id: taskId,
  })

  if (task?.type !== 'NORMAL') return null

  const Icon = type === 'blockedBy' ? BlockedBySolid : BlocksSolid
  const label = type === 'blockedBy' ? 'Blocked By' : 'Blocking'

  const blockerCount = blockerIds.length

  return (
    <>
      <ModalFieldButton
        label={label}
        disabled={disabled}
        suffix={
          <span
            className={twMerge(
              'flex items-center gap-1 text-[10px] font-semibold text-button-neutral-muted-text-default',
              'cursor-pointer',
              disabled
                ? 'invisible'
                : 'cursor-pointer invisible group-hover/modal-field-button:visible group-focus-within/modal-field-button:visible'
            )}
          >
            <PlusSolid width={16} />
            Add task
          </span>
        }
      >
        <BlockersTypeDropdown task={task} type={type}>
          <div className='flex items-center gap-1.5'>
            <Icon
              className={
                // These icons stay the same color when field is disabled
                type === 'blockedBy'
                  ? '!text-semantic-error-icon-default'
                  : '!text-semantic-warning-icon-default'
              }
            />

            <button
              type='button'
              className={twMerge(
                'flex items-center gap-1',
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
              disabled={disabled}
            >
              {blockerCount > 0
                ? pluralize(blockerCount, '{{count}} task', '{{count}} tasks')
                : 'None'}
            </button>
          </div>
        </BlockersTypeDropdown>
      </ModalFieldButton>

      <BlockerTaskList type={type} task={task} />
    </>
  )
}
