import { type COLOR } from '@motion/shared/common'
import {
  getColorClassIdForColor,
  getColorFromColorClassId,
  getColorHueValue,
} from '@motion/ui/palette'
import {
  type ColorClassId,
  type ColorId,
  colorIdToColorClassId,
  isColorId,
} from '@motion/ui-logic'
import { type ProjectSchema } from '@motion/zod/client'

type ColorOptions = {
  projectColor: ProjectSchema['color'] | undefined
  calendarColorId: ColorId | null | undefined
}

export function getEventColor(
  colorId: ColorId | null,
  options: ColorOptions
): COLOR {
  const { projectColor, calendarColorId } = options

  if (colorId != null) {
    return getColorFromColorClassId(colorIdToColorClassId[colorId])
  }

  if (projectColor != null) {
    return projectColor
  }

  if (calendarColorId != null && isColorId(calendarColorId)) {
    return getColorFromColorClassId(colorIdToColorClassId[calendarColorId])
  }

  // For legacy reasons, the default color is blue instead of gray for calendars/events
  return 'blue'
}

/**
 * Returns the color hue for a given event
 * Returns the hue based on the color id when the event has a custom color id.
 * Otherwise reads it from the project when the event is in a project, or from the calendar
 */
export function getEventColorHue(
  colorId: ColorId | null,
  options: ColorOptions
): number {
  return getColorHueValue(getEventColor(colorId, options))
}

/**
 * Returns the color hue for a given event
 * Returns the hue based on the color id when the event has a custom color id.
 * Otherwise reads it from the project when the event is in a project, or from the calendar
 */
export function getEventColorClassId(
  colorId: ColorId | null,
  options: ColorOptions
): ColorClassId | null {
  return getColorClassIdForColor(getEventColor(colorId, options))
}
