import type { FieldsByTypedName, TypedNameKey } from '@motion/ui-logic'

import { useMemo } from 'react'

import { useAvailableCustomFields } from '../../../filtering/custom-fields/use-available-custom-fields'
import { getTypedNameKey } from '../utils'

/**
 * Groups available custom fields across workspaces by type + name.
 *
 * @example { 'text_Example': [field1, field2], 'number_Different One': [field3] }
 */

export const useAvailableCustomFieldsGroupedByTypedName =
  (): FieldsByTypedName => {
    const availableCustomFields = useAvailableCustomFields()

    return useMemo(() => {
      const fieldsByName: FieldsByTypedName = {}
      availableCustomFields.forEach((field) => {
        const nameWithType: TypedNameKey = getTypedNameKey(field)
        const values = fieldsByName[nameWithType] ?? []
        const newValues = values.concat([field])
        fieldsByName[nameWithType] = newValues as any
      })
      return fieldsByName
    }, [availableCustomFields])
  }
