import { type CustomFieldValuesRecord } from '@motion/rpc/types'
import { type RecurringTaskSchema, type TaskSchema } from '@motion/zod/client'

const toFromUpdateKeysAndPrefixes = [
  'isAutoScheduled',
  'priorityLevel',
  'duration',
  'customField',
]
export function getAmplitudeTaskChangedFields(
  task: TaskSchema | RecurringTaskSchema | null,
  updates: Partial<TaskSchema | RecurringTaskSchema>
) {
  const fromTo: Record<
    string,
    { from: string | undefined; to: string | undefined } | boolean
  > = {}
  for (const key in updates) {
    const prev = task == null ? undefined : task[key as keyof typeof task]
    const next = updates[key as keyof typeof updates]

    if (next === undefined || prev === next) {
      continue
    }
    if (key === 'customFieldValues') {
      const customFieldValues = next as unknown as CustomFieldValuesRecord
      for (const cfValueObject of Object.values(customFieldValues)) {
        const { type } = cfValueObject
        const customFieldKey = `customField/${type}`
        fromTo[customFieldKey] = true
      }
    } else {
      fromTo[key] = {
        from: String(prev),
        to: String(next),
      }
    }
  }
  return {
    fields: Object.keys(fromTo),
    fromTo: Object.fromEntries(
      Object.entries(fromTo).filter(([key]) =>
        toFromUpdateKeysAndPrefixes.filter((k) => key.startsWith(k))
      )
    ),
  }
}
