import { usePrevious } from '@motion/react-core/hooks'
import { type ComponentProps } from '@motion/theme'
import { useContextMenu } from '@motion/ui/base'
import { getEnabledStagesWithDates } from '@motion/ui-logic/pm/project'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'

import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { ProjectActionList } from '~/areas/project/components/project-action-list'
import {
  useAdjustProjectDates,
  useProjectStartDateUpdater,
  useUpdateProjectStageDueDate,
} from '~/areas/project/hooks'
import { type ProjectWithRelations } from '~/global/proxies'
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import { ProjectCardContent, ProjectItem } from './project-content'
import { ProjectItemTooltip } from './project-item-tooltip'
import { ResizeHandle, type Side } from './resize-handle'
import { useResizeProjectFromStage } from './stages-bar'
import { OriginalStagesBar } from './stages-bar/original-stages-bar'

import { usePlannerProps } from '../../context'
import { useScreenValues } from '../../hooks'
import { PROJECT_ITEM_GAP } from '../../shared-constants'
import { getProjectStartAndEnd, pixelToDate } from '../../utils'
import { ItemContainer } from '../common'

export type ResizeableProjectItemProps = ComponentProps<
  typeof ItemContainer
> & {
  project: ProjectWithRelations
  shouldTruncate?: boolean
}

export const ResizeableProjectItem = memo(function ResizeableProjectItem(
  props: ResizeableProjectItemProps
) {
  const { project } = props
  const [showTooltip, setShowTooltip] = useState(false)

  const [
    { width: projectDeltaWidth, left: projectDeltaLeft },
    setProjectDelta,
  ] = useState<{
    width: number
    left: number
  }>({
    width: 0,
    left: 0,
  })
  const { width: stageDeltaWidth, left: stageDeltaLeft } =
    useResizeProjectFromStage(project.id)
  const previousProject = usePrevious(project)
  const { positionOffset } = useScreenValues()

  // Dimensions used to calculate total size and location (given deltas)
  const [
    { width: projectWidth, left: projectLeft },
    setCalculatedProjectLocation,
  ] = useState({
    width: 0,
    left: 0,
  })

  const [currentSide, setCurrentSide] = useState<Side | undefined>(undefined)

  const [plannerProps, setPlannerProps] = usePlannerProps()
  const { shiftModifier } = plannerProps
  const isResizing = plannerProps.resizingId === project.id
  const isResizingStages = stageDeltaWidth !== 0 || stageDeltaLeft !== 0
  const hasBetterResizeStages = useHasTreatment('flows-better-resize-stages')
  const adjustProjectDates = useAdjustProjectDates()
  const updateStage = useUpdateProjectStageDueDate()
  const updateProjectStartDate = useProjectStartDateUpdater()

  const { handleContextMenu, ContextMenuPopover } = useContextMenu({
    onOpen: () => {
      recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RIGHT_CLICK')
    },
  })
  const minWidth = plannerProps.dayPx

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    isDragging,
    transform,
  } = useDraggable({
    id: project.id,
  })

  const resetState = useCallback(() => {
    setProjectDelta({ width: 0, left: 0 })
    setCurrentSide(undefined)
    setPlannerProps((prev) => ({ ...prev, resizingId: null }))
  }, [setPlannerProps])

  // Store in a useEffect so that the resetState is only called when the project is detected to have been changed
  useEffect(
    function calculateDimensions() {
      function getTotalWidth({
        end,
        start,
        stageDeltaWidth,
        projectDeltaWidth,
      }: {
        end: number
        start: number
        stageDeltaWidth: number
        projectDeltaWidth: number
      }) {
        return end - start + stageDeltaWidth + projectDeltaWidth
      }

      function getTotalLeft({
        start,
        positionOffset,
        projectDeltaLeft,
        stageDeltaLeft,
      }: {
        start: number
        positionOffset: number
        projectDeltaLeft: number
        stageDeltaLeft: number
      }) {
        return start + positionOffset + projectDeltaLeft + stageDeltaLeft
      }

      let { start, end } = getProjectStartAndEnd(project, plannerProps.dayPx)
      if (
        (previousProject && previousProject.id !== project.id) ||
        previousProject?.startDate !== project.startDate ||
        previousProject?.dueDate !== project.dueDate
      ) {
        resetState()
        setCalculatedProjectLocation({
          width: getTotalWidth({
            start,
            end,
            projectDeltaWidth: 0,
            stageDeltaWidth: 0,
          }),
          left: getTotalLeft({
            start,
            positionOffset,
            projectDeltaLeft: 0,
            stageDeltaLeft: 0,
          }),
        })
        return
      }

      setCalculatedProjectLocation({
        width: getTotalWidth({
          start,
          end,
          projectDeltaWidth,
          stageDeltaWidth,
        }),
        left: getTotalLeft({
          start,
          positionOffset,
          projectDeltaLeft,
          stageDeltaLeft,
        }),
      })
    },
    [
      project,
      plannerProps.dayPx,
      stageDeltaWidth,
      stageDeltaLeft,
      previousProject,
      positionOffset,
      resetState,
      projectDeltaWidth,
      projectDeltaLeft,
    ]
  )

  const itemStartAndEnd = useMemo(() => {
    return getProjectStartAndEnd(project, plannerProps.dayPx)
  }, [project, plannerProps.dayPx])

  const handleResizeStart = (side: Side) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RESIZE_START')
    setProjectDelta({ width: 0, left: 0 })
    setCurrentSide(side)
    setPlannerProps((prev) => ({ ...prev, resizingId: project.id }))
  }

  const handleOnSizeChange = useCallback(
    (change: { width: number; left: number }) => {
      let finalWidth = change.width
      const maxWidthChange =
        itemStartAndEnd.start - itemStartAndEnd.end + minWidth

      // Check that the other side is 'set' and that the user is not changing it
      // If not, width should stay constant
      if (!project.dueDate && currentSide !== 'right') {
        setProjectDelta({ width: 0, left: change.left })

        return
      }

      if (!project.startDate && currentSide !== 'left') {
        setProjectDelta({ width: 0, left: change.width })
        return
      }

      // clamp with so that it doesn't go below minWidth
      if (change.width <= maxWidthChange) {
        setProjectDelta({
          width: maxWidthChange,
          left: change.left ? -maxWidthChange : 0,
        })
        return
      }
      setProjectDelta({ width: finalWidth, left: change.left })
    },
    [
      currentSide,
      itemStartAndEnd.end,
      itemStartAndEnd.start,
      minWidth,
      project.dueDate,
      project.startDate,
    ]
  )

  const handleResizeEnd = useCallback(
    async (side: Side) => {
      setCurrentSide(undefined)

      if (side === 'left') {
        const newStartDatePx = itemStartAndEnd.start + projectDeltaLeft
        const newStartDate = pixelToDate(
          newStartDatePx,
          plannerProps.dayPx
        ).toISODate()

        if (shiftModifier && hasBetterResizeStages) {
          await updateProjectStartDate(project, newStartDate, {
            skipConfirmModal: true,
            dateAdjustmentStrategy: 'ABSORB',
          })
          recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RESIZE_SHIFT_END')
          resetState()
          return
        }

        const res = await adjustProjectDates(project.id, {
          startDate: pixelToDate(
            newStartDatePx,
            plannerProps.dayPx
          ).toISODate(),
        })

        if (!res) {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RESIZE_CANCEL')
          resetState()
        } else {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RESIZE_END', {
            side: 'left',
          })
        }
      }
      if (side === 'right') {
        const newEndDatePx = itemStartAndEnd.start + projectWidth

        if (shiftModifier) {
          // Update last stage due date instead
          const lastStage = project.stages[project.stages.length - 1]
          if (lastStage) {
            void updateStage(
              project.id,
              lastStage.stageDefinitionId,
              pixelToDate(newEndDatePx, plannerProps.dayPx).toISODate(),
              { skipConfirmModal: true }
            )

            recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RESIZE_SHIFT_END')
            resetState()
            return
          }
        }

        const res = await adjustProjectDates(project.id, {
          dueDate: pixelToDate(newEndDatePx, plannerProps.dayPx).toISO(),
        })

        if (!res) {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RESIZE_CANCEL')
          resetState()
        } else {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_PLANNER_RESIZE_END', {
            side: 'right',
          })
        }
      }

      // State will auto reset on next render
    },
    [
      itemStartAndEnd.start,
      projectDeltaLeft,
      plannerProps.dayPx,
      shiftModifier,
      hasBetterResizeStages,
      adjustProjectDates,
      project,
      updateProjectStartDate,
      resetState,
      projectWidth,
      updateStage,
    ]
  )

  useLayoutEffect(() => {
    setProjectDelta({ width: 0, left: 0 })
  }, [
    itemStartAndEnd.start,
    itemStartAndEnd.end,
    plannerProps.resizingId,
    project.id,
  ])

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Shift') {
        setPlannerProps((prev) => ({ ...prev, shiftModifier: true }))
      }
    }

    function handleKeyUp(event: KeyboardEvent) {
      if (event.key === 'Shift') {
        setPlannerProps((prev) => ({ ...prev, shiftModifier: false }))
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [setPlannerProps])

  const hasStartDate = project.startDate || currentSide === 'left'
  const hasEndDate = project.dueDate || currentSide === 'right'
  const projectStart = hasStartDate
    ? pixelToDate(projectLeft - positionOffset, plannerProps.dayPx)
    : undefined

  const projectEnd = hasEndDate
    ? pixelToDate(
        // Subtract a single day since the due date is inclusive
        projectWidth + projectLeft - positionOffset - 1,
        plannerProps.dayPx
      )
    : undefined

  const stagesWithDates = getEnabledStagesWithDates(project.stages ?? [], {
    start: project.startDate,
    due: project.dueDate,
  })
  return (
    <>
      <ItemContainer
        style={{
          left: projectLeft,
          width: projectWidth - PROJECT_ITEM_GAP,
          opacity: isDragging ? 0 : 1,
        }}
        data-type='draggable'
        className='group/planner-project-item'
        onContextMenu={handleContextMenu}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <ProjectItemTooltip
          placement='top-end'
          open={
            showTooltip ||
            (!isDragging && isResizing) ||
            (!isDragging && isResizingStages)
          }
          setOpen={setShowTooltip}
          container={plannerProps.containerNode?.parentElement ?? null}
          renderContent={(close) => (
            <ProjectCardContent
              projectStart={projectStart}
              projectEnd={projectEnd}
              project={project}
              isDragging={isResizing}
              close={close}
            />
          )}
        >
          <div className='h-10 w-full relative'>
            <ResizeHandle
              onChange={handleOnSizeChange}
              onResizeStart={handleResizeStart}
              onResizeEnd={handleResizeEnd}
              onResizeCancel={resetState}
              side='left'
              tooltipContent='Hold Shift + drag to change only the project start date'
            />

            <div
              ref={setNodeRef}
              {...attributes}
              className='absolute inset-0'
              style={{
                transform: CSS.Transform.toString(transform),
              }}
            >
              <ProjectItem
                project={project}
                currentSide={currentSide}
                deltaWidth={projectDeltaWidth}
                isDragging={isDragging || isResizing}
                fadedLeft={!project.startDate}
                fadedRight={!project.dueDate}
                left={projectLeft}
                width={projectWidth - PROJECT_ITEM_GAP}
                listeners={listeners}
                setActivatorNodeRef={setActivatorNodeRef}
              />
              <OriginalStagesBar
                project={project}
                stagesWithDates={stagesWithDates}
                projectDeltaWidth={projectDeltaWidth}
                currentSide={currentSide}
              />
            </div>
            <ResizeHandle
              onChange={handleOnSizeChange}
              onResizeStart={handleResizeStart}
              onResizeEnd={handleResizeEnd}
              onResizeCancel={resetState}
              tooltipContent='Hold Shift + drag to change only the project deadline'
              side='right'
            />
          </div>
        </ProjectItemTooltip>
      </ItemContainer>
      <ContextMenuPopover
        renderContent={({ close }) => (
          <ProjectActionList
            close={close}
            options={{ showColors: true, allowProjectResolution: true }}
            project={project}
          />
        )}
      />
    </>
  )
})
