import { classed } from '@motion/theme'

export const Details = classed('div', {
  base: `
    flex gap-2 items-start py-0.5
  `,
})

export const TaskTextArea = classed('div', {
  base: `
    flex flex-col gap-1.5 w-full overflow-hidden items-start
  `,
})

export const TaskTitleBar = classed('div', {
  base: `flex gap-1 items-center text-xs w-full overflow-hidden truncate`,
})

export const TaskTitle = classed('span', {
  base: `
    whitespace-nowrap text-ellipsis overflow-hidden
    text-sm font-semibold
  `,
})
