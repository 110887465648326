import { DEFAULT_SCHEDULE_ID } from '@motion/ui-logic/pm/task'

import { useEffect } from 'react'
import { type UseFormReturn } from 'react-hook-form'

import {
  type TemplateTaskFormFields,
  type TemplateTaskFormOptions,
} from '../types'

export function useFormUpdateSchedule(
  form: UseFormReturn<TemplateTaskFormFields>,
  options: TemplateTaskFormOptions
) {
  const { currentUserId } = options

  const scheduleId = form.watch('scheduleId')
  const assigneeId = form.watch('assigneeId')

  const isOwnTask = currentUserId != null && currentUserId === assigneeId

  useEffect(() => {
    // Tasks assigned to other people are always on the work schedule
    if (!isOwnTask && scheduleId !== DEFAULT_SCHEDULE_ID) {
      form.setValue('scheduleId', DEFAULT_SCHEDULE_ID, { shouldDirty: true })
      return
    }
  }, [form, isOwnTask, scheduleId])
}
