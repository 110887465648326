import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { ActiveFilterKey } from '@motion/ui-logic/pm/data'

import { type ReactNode } from 'react'

import { SelectedWorkspaceIdsContext } from './selected-workspace-ids-context'

type ActiveFilterItemsWorkspaceContextProps = {
  children: ReactNode
  name?: string
  removeNoneProjectIds?: boolean
}

export const ActiveFilterItemsWorkspaceContext = (
  props: ActiveFilterItemsWorkspaceContextProps
) => {
  const selectedWorkspaceIds = useSharedStateQuery(
    ActiveFilterKey,
    (state) => state.workspaces.filters.ids?.value ?? null
  )

  return (
    <SelectedWorkspaceIdsContext
      name={props.name}
      selectedWorkspaceIds={selectedWorkspaceIds}
      removeNoneProjectIds={props.removeNoneProjectIds}
    >
      {props.children}
    </SelectedWorkspaceIdsContext>
  )
}
