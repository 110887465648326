import 'react-grid-layout/css/styles.css'
import './grid-layout.css'

import { useMemoDeep } from '@motion/react-core/hooks'
import { type DashboardViewCellSchema } from '@motion/rpc-types'
import { cloneDeep, isEqual } from '@motion/utils/core'
import { values } from '@motion/utils/object'

import { useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import { GridTile } from './grid-tile'
import { useViewToLayouts } from './hooks'
import { type Breakpoints } from './utils'
import { chartComponentLookup } from './utils/lookups'

import { useDashboardViewState } from '../view-state'

/**
 * This breakpoint is the width of the react-grid-layout container, not viewport
 */
const breakpoints: Record<Breakpoints, number> = {
  sm: 640,
  md: 900,
  lg: 1024,
}

const cols: Record<Breakpoints, number> = {
  sm: 2,
  md: 12,
  lg: 12,
}

const rowHeight: Record<Breakpoints, number> = {
  sm: 200,
  md: 200,
  lg: 200,
}

declare module 'react-grid-layout' {
  interface Layout {
    cell: DashboardViewCellSchema
  }
}

const ResponsiveGridLayout = WidthProvider(Responsive)

export function DashboardGridLayout() {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoints>('md')

  const [{ view: selectedRaw }, setViewState] = useDashboardViewState()
  const selected = useMemoDeep(selectedRaw)
  const layouts = useViewToLayouts(selected)

  const handleBreakPointChange = (newBreakpoint: Breakpoints) => {
    setCurrentBreakpoint(newBreakpoint)
  }

  if (layouts == null || selected.type !== 'dashboard') return null

  // These two are direct mapping
  const cells = selected.cells
  const layoutCells = values(layouts)[0]

  return (
    <ResponsiveGridLayout
      layouts={layouts}
      rowHeight={rowHeight[currentBreakpoint]}
      breakpoints={breakpoints}
      cols={cols}
      resizeHandles={currentBreakpoint === 'sm' ? [] : ['se', 'sw']}
      autoSize={false}
      className='dashboard-grid-layout'
      margin={[16, 16]}
      compactType='vertical'
      onBreakpointChange={handleBreakPointChange}
      draggableHandle='.drag-handle'
      onLayoutChange={(layout, allLayouts) => {
        const cloned = cloneDeep(selected)

        cloned.cells = allLayouts.lg
          .map((l) => {
            const cell = cloned.cells.find((x) => x.id === l.i)
            if (!cell) return
            cell.x = l.x
            cell.y = l.y
            cell.width = l.w
            cell.height = l.h
            return cell
          })
          .filter(Boolean)
        if (isEqual(cloned, selected)) return

        setViewState((prev) => ({
          ...prev,
          view: cloned,
        }))
      }}
    >
      {cells.map((cell, index) => {
        // We generated an unique id for each cell in the layout
        const cellId = layoutCells[index].i
        const Chart = chartComponentLookup(cell.chart.type)
        if (Chart == null) return null

        return (
          <GridTile
            title={cell.title}
            key={cellId}
            renderContent={() => <Chart cell={cell} />}
          />
        )
      })}
    </ResponsiveGridLayout>
  )
}
