import { type FieldOperationSchema } from '@motion/shared/custom-fields'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'
import {
  type BulkFieldUpdateSchema,
  type LabelSchema,
} from '@motion/zod/client'

import { useState } from 'react'

import { BulkLabelsLabel } from '../bulk-labels-label'
import { DropdownContentTopSection } from '../dropdown-content-top-section'
import { OperationTabsPanel } from '../operation-tabs-panel'
import { DropdownContainer } from '../styled'

type BulkLabelsDropdownProps = Omit<BulkLabelsDropdownContentProps, 'close'> & {
  children?: React.ReactNode
}

export function BulkLabelsDropdown({
  children,
  ...rest
}: BulkLabelsDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={() => <BulkLabelsDropdownContent {...rest} />}
    >
      {children}
    </PopoverTrigger>
  )
}

type BulkLabelsDropdownContentProps = {
  onChange: (labelsField: BulkFieldUpdateSchema['labels']) => void
  selectedLabelsField: BulkFieldUpdateSchema['labels']
  filteredLabels: LabelSchema[][]
}

function BulkLabelsDropdownContent({
  onChange,
  selectedLabelsField,
  filteredLabels,
}: BulkLabelsDropdownContentProps) {
  const labelFields = selectedLabelsField ?? []

  const operationFromValue =
    selectedLabelsField && selectedLabelsField.length > 0
      ? selectedLabelsField[0].operation
      : 'add'

  const [operation, setOperation] =
    useState<FieldOperationSchema>(operationFromValue)

  const onTabChange = (operation: FieldOperationSchema) => {
    setOperation(operation)

    const newValue = labelFields.map((field) => ({
      id: field.id,
      operation,
    }))

    onChange(newValue)
  }

  return (
    <DropdownContainer>
      <DropdownContentTopSection>
        Only showing labels shared between all tasks you selected
      </DropdownContentTopSection>
      <OperationTabsPanel
        onChange={onTabChange}
        selectedOperation={operation}
      />
      <SearchableList
        searchable
        items={filteredLabels}
        computeKey={(items) => items[0].name}
        computeSearchValue={(items) => items[0].name}
        computeSelected={(items) =>
          labelFields.some((field) => items.some((l) => l.id === field.id))
        }
        itemType='checkbox'
        renderItem={(items) => <BulkLabelsLabel values={items} />}
        onSelect={(items) => {
          onChange(
            items.flatMap((item) => item.map((i) => ({ id: i.id, operation })))
          )
        }}
      />
    </DropdownContainer>
  )
}
