import { ActionList, Button, PopoverTrigger, Tooltip } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { Events } from '~/analyticsEvents'
import type { OpenTimezoneModalProps } from '~/areas/calendar/components/calendar-header/timezone-group/types'
import type { BookingLinkTemplate } from '~/state/booking'
import { useCallback } from 'react'

import { type BookingLinksDropdownProps } from './index'

type OneOffLinkButtonProps = Pick<
  BookingLinkTemplate,
  'id' | 'durationChoices'
> & {
  disabled: boolean
  closeParent: BookingLinksDropdownProps['close']
}
export const OneOffLinkButton = ({
  id,
  durationChoices,
  disabled,
  closeParent,
}: OneOffLinkButtonProps) => {
  const modalApi = useModalApi()

  const openTimezoneModal = useCallback(
    ({
      clearableValue,
      changeHandler,
      showDefault,
    }: OpenTimezoneModalProps) => {
      modalApi.prompt('timezone-picker', {
        onTimezoneChange: changeHandler,
        showDefault: showDefault,
        clearableTimezoneValue: clearableValue,
      })
    },
    [modalApi]
  )

  const selectOneOffTemplate = (durationChoice?: number) => {
    closeParent()

    modalApi.open('booking-oneoff', {
      templateId: id,
      openTimezoneModal: openTimezoneModal,
      selectedChoice: durationChoice,
    })

    recordAnalyticsEvent(Events.CALENDAR_AVAILABILITIES_ONEOFF_LINK)
  }

  return (
    <Tooltip
      renderContent={() => (
        <div className='flex max-w-xs flex-col gap-1'>
          <p>Custom message + link</p>
          <p className='text-xs'>
            Generate available times in text, plus a link to a personalized
            booking page.
          </p>
        </div>
      )}
    >
      {durationChoices && durationChoices.length > 1 ? (
        <PopoverTrigger
          placement='bottom-end'
          renderPopover={({ close }) => (
            <ActionList
              items={[
                ...durationChoices.map((choice) => ({
                  content: `${choice} min`,
                  onAction: () => void selectOneOffTemplate(choice),
                })),
              ]}
              onActionAnyItem={() => void close()}
            />
          )}
        >
          <Button
            size='xsmall'
            sentiment='neutral'
            variant='outlined'
            disabled={disabled}
          >
            One-off link
          </Button>
        </PopoverTrigger>
      ) : (
        <Button
          size='xsmall'
          sentiment='neutral'
          variant='outlined'
          onClick={() => void selectOneOffTemplate()}
          disabled={disabled}
        >
          One-off link
        </Button>
      )}
    </Tooltip>
  )
}
