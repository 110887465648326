import { type PMTaskType } from '@motion/rpc/types'
import { PopoverTrigger, SearchableList } from '@motion/ui/base'

import { type ReactNode } from 'react'

import {
  useTaskModalBlockers,
  useTaskModalProjectTasks,
  useTemplateTaskAddBlocker,
} from '../../hooks'

export type TemplateBlockersTypeDropdownProps = {
  task: PMTaskType
  type: 'blockedBy' | 'blocks'
  children: ReactNode
}

export function TemplateBlockersTypeDropdown(
  props: TemplateBlockersTypeDropdownProps
) {
  const { task, type, children } = props

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <TemplateTaskBlockersContent close={close} task={task} type={type} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type TemplateTaskBlockersContentProps = Pick<
  TemplateBlockersTypeDropdownProps,
  'type'
> & {
  task: PMTaskType
  close: () => void
}

const TemplateTaskBlockersContent = ({
  close,
  task,
  type,
}: TemplateTaskBlockersContentProps) => {
  const { projectTasks } = useTaskModalProjectTasks()
  const { blockedTasks, blockingTasks } = useTaskModalBlockers({
    task,
    projectTasks,
  })

  const currentBlockersToCheckAgainst =
    type === 'blocks' ? blockedTasks : blockingTasks

  const otherProjectTasks = projectTasks.filter(
    (pt) =>
      pt.id !== task.id &&
      !currentBlockersToCheckAgainst.some((t) => t.id === pt.id)
  )

  const addBlocker = useTemplateTaskAddBlocker({
    task,
    currentBlockersToCheckAgainst,
    type,
  })

  return (
    <SearchableList
      searchable
      items={otherProjectTasks}
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={() => false}
      onSelect={(item) => {
        close()
        addBlocker(item)
      }}
      renderItem={(task) => (
        <div className='max-w-xs truncate'>{task.name}</div>
      )}
      inputProps={{ placeholder: 'Search tasks...' }}
    />
  )
}
