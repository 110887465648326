import { CalendarExclamationSolid, CalendarSolid } from '@motion/icons'
import { Tooltip } from '@motion/ui/base'
import {
  type ScheduledType,
  scheduleTypeToStandAloneTemplateStr,
  templateStr,
} from '@motion/ui-logic'

import { twMerge } from 'tailwind-merge'

type DueDateIconProps = {
  labelVariant: 'error' | 'on' | 'off'
  type: ScheduledType
  formattedScheduledDate: string
  formattedDueDate: string | null
  formattedCompletedDate: string | null
}
export const DueDateIcon = (props: DueDateIconProps) => {
  const {
    labelVariant,
    formattedScheduledDate,
    formattedDueDate,
    formattedCompletedDate,
    type,
  } = props
  const scheduleDateTemplateStr = scheduleTypeToStandAloneTemplateStr[type]

  const scheduledOnText = templateStr(scheduleDateTemplateStr, {
    date: formattedCompletedDate ?? formattedScheduledDate,
  })

  const dueDateText =
    formattedDueDate &&
    templateStr('Deadline: {{date}}', {
      date: formattedDueDate,
    })

  const icon =
    labelVariant === 'error' ? (
      <CalendarExclamationSolid className='w-3 h-3 text-semantic-error-bg-strong-default' />
    ) : (
      <CalendarSolid
        className={twMerge(
          'w-3 h-3 text-semantic-warning-icon-default',
          type === 'notScheduled'
            ? 'text-semantic-warning-icon-default'
            : 'text-semantic-neutral-icon-default'
        )}
      />
    )
  return (
    <Tooltip
      asChild
      renderContent={() => (
        <>
          <div>{scheduledOnText}</div>
          <div>{dueDateText}</div>
        </>
      )}
    >
      {icon}
    </Tooltip>
  )
}
