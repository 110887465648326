import { PlusSolid } from '@motion/icons'
import { ActionList, Button, SearchableList } from '@motion/ui/base'
import { type FlowTemplateFormFields } from '@motion/ui-logic/pm/project'
import { useModalApi } from '@motion/web-common/modals'

import { VariableLabel } from '~/global/components/labels'

export type FlowVariableSearchableListProps<T> = {
  items: T[]
  close?: () => void
  onChange: (item: T) => void
  selectedItem?: T
  hideAddNew?: boolean
  onAddNew?: (name: string) => void
}

export function FlowVariableSearchableList<
  T extends FlowTemplateFormFields['textVariables'][number],
>({
  items,
  close,
  onChange,
  selectedItem,
  hideAddNew,
  onAddNew,
}: FlowVariableSearchableListProps<T>) {
  const modalApi = useModalApi()

  const addNewHandler = async () => {
    void modalApi.prompt('add-flow-template-text-variable-modal', {
      onValue({ name }) {
        onAddNew?.(name)
      },
    })
  }

  return (
    <>
      {items.length === 0 ? (
        <ActionList
          items={[
            {
              content: 'No items found',
              onAction: close,
              disabled: true,
            },
          ]}
        />
      ) : (
        <SearchableList
          searchable
          itemType='select'
          items={items}
          onSelect={(item) => {
            close?.()
            onChange(item)
          }}
          renderItem={(item) => (
            <VariableLabel
              value={{
                name: item.name,
                color: 'blue',
              }}
            />
          )}
          computeKey={(item) => item.key}
          computeSearchValue={(item) => item.name}
          computeSelected={(item) => item.id === selectedItem?.id}
          inputProps={{ placeholder: 'Choose text variable...' }}
        />
      )}

      {!hideAddNew && (
        <div className='border-t border-dropdown-border p-1.5'>
          <Button
            variant='muted'
            sentiment='neutral'
            size='small'
            onClick={addNewHandler}
          >
            <PlusSolid />
            New text variable
          </Button>
        </div>
      )}
    </>
  )
}
