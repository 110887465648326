import {
  type DefinedRelativeDateFilterSchema,
  type DefinedRelativeQueryName,
  type EmptyFilterSchema,
  type ViewDateFilterSchema,
} from '@motion/zod/client'

import { DateTime } from 'luxon'

export type DateRangeOption =
  | {
      key: DefinedRelativeQueryName | `next-${string}` | `last-${string}`
      label: string
      filter: ViewDateFilterSchema
    }
  | {
      key: 'relative' | 'logical' | 'range'

      default: () => ViewDateFilterSchema
      label: string
    }
  | { key: 'empty'; label: string; default: () => EmptyFilterSchema }
  | { key: 'separator'; label?: never }

export const DATE_RANGE_OPTIONS: DateRangeOption[] = [
  { key: 'this-week', label: 'This week', filter: query('this-week') },
  { key: 'today', label: 'Today', filter: query('today') },
  { key: 'yesterday', label: 'Yesterday', filter: query('yesterday') },
  { key: 'tomorrow', label: 'Tomorrow', filter: query('tomorrow') },
  { key: 'separator' },
  { key: 'next-7-days', label: 'Next 7 days', filter: query('next-7-days') },
  {
    key: 'next-14-days',
    label: 'Next 14 days',
    filter: query('next-14-days'),
  },
  { key: 'next-week', label: 'Next week', filter: query('next-week') },
  { key: 'this-month', label: 'This Month', filter: query('this-month') },
  { key: 'next-month', label: 'Next Month', filter: query('next-month') },
  { key: 'separator' },
  { key: 'last-7-days', label: 'Last 7 days', filter: query('last-7-days') },
  {
    key: 'last-14-days',
    label: 'Last 14 days',
    filter: query('last-14-days'),
  },
  { key: 'last-week', label: 'Last week', filter: query('last-week') },
  { key: 'last-month', label: 'Last month', filter: query('last-month') },
  { key: 'separator' },
  {
    key: 'relative',
    label: 'Relative dates',
    default: () => ({ operator: 'relative', duration: 'P7D' }),
  },
  {
    key: 'logical',
    label: 'On/before/after date',
    default: () => ({ operator: 'equals', value: DateTime.now().toISO() }),
  },
  {
    key: 'range',
    label: 'Date range',
    default: () => ({
      operator: 'range',
      value: {
        from: DateTime.now().startOf('day').toISO(),
        to: DateTime.now().plus({ days: 7 }).toISO(),
      },
    }),
  },
  {
    key: 'empty',
    label: 'None',
    default: () => ({ operator: 'empty' }),
  },
]

export function findSelected(value: ViewDateFilterSchema) {
  if (value.operator === 'range') return keyed('range')
  if (value.operator === 'defined-relative') {
    return keyed(value.name)
  }
  if (value.operator === 'relative') {
    const found = DATE_RANGE_OPTIONS.find(
      (x) =>
        'filter' in x &&
        x.filter.operator === 'relative' &&
        x.filter.duration === value.duration
    )
    if (found) return found
    return keyed('relative')
  }

  if (value.operator !== 'empty') {
    return keyed('logical')
  }

  return keyed('empty')
}

function keyed(key: string) {
  return DATE_RANGE_OPTIONS.find((x) => x.key === key)!
}

function query(
  name: DefinedRelativeQueryName
): DefinedRelativeDateFilterSchema {
  return { operator: 'defined-relative', name }
}
