import { type MotionRoute } from '~/routing/api'

import { TutorialsPage } from './tutorials-page'

declare module '~/routing/hooks/navigate-by-id-params' {
  interface NavigateByIdParams {
    tutorials: undefined
    'tutorial-lesson': {
      lessonId: string
    }
  }
}

export const tutorialRoutes: MotionRoute[] = [
  {
    id: 'tutorials',
    path: 'tutorials',
    element: <TutorialsPage />,
    children: [
      {
        id: 'tutorial-lesson',
        path: ':lessonId',
        element: <TutorialsPage />,
      },
    ],
  },
]
