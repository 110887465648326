import { byValue, ordered } from '@motion/utils/array'
import { PieChart } from '@motion/web-charts/pie'

import { useChartQuery } from '~/areas/charts/rpc'
import { useCallback, useMemo } from 'react'

import { colorOfScheduledStatus, labelOfScheduledStatus } from './utils'

import { GridTile } from '../components/grid-tile'
import { type GridTileLayoutProps } from '../components/types'

export type ConnectedScheduledStatusPieChartsProps = GridTileLayoutProps & {}

export const ConnectedScheduledStatusPieChart = (
  props: ConnectedScheduledStatusPieChartsProps
) => {
  const response = useChartQuery({
    source: 'tasks',
    aggregate: {
      type: 'count',
    },
    filters: [
      {
        recurring: 'CURRENT',
        completed: 'exclude',
        type: ['NORMAL', 'RECURRING_INSTANCE'],
        archived: 'exclude',
      },
    ],
    groupBy: [
      {
        field: 'scheduledStatus',
      },
    ],
  })

  const getLabel = useCallback((group: string, key: string, index: number) => {
    if (group === 'scheduledStatus') {
      return labelOfScheduledStatus(key as any)
    }
  }, [])

  const getColor = useCallback((group: string, key: string, index: number) => {
    if (group === 'scheduledStatus') {
      return colorOfScheduledStatus(key as any)
    }
  }, [])

  const data = useMemo(() => {
    if (response.data == null) return null
    return response.data?.data.sort(
      byValue(
        (x) => String(x.scheduledStatus ?? 'UNKNOWN'),
        ordered(['ON_TRACK', 'PAST_DUE', 'UNFIT_SCHEDULABLE', 'UNFIT_PAST_DUE'])
      )
    )
  }, [response.data])

  if (!data) return null

  return (
    <GridTile {...props} title='Task Overview'>
      <PieChart
        data={data}
        categoryKey='scheduledStatus'
        labelAccessor={getLabel}
        colorAccessor={getColor}
      />
    </GridTile>
  )
}
