import { type TaskSchema } from '@motion/zod/client'

import { useLookup } from '~/global/cache'
import { createTaskProxy } from '~/global/proxies'
import { useTaskByIdV2 } from '~/global/rpc/v2'
import { useMemo } from 'react'

import { useOngoingAndUpcomingScheduledEntities } from './use-ongoing-and-upcoming-scheduled-entities'

import { getParentChunkId } from '../../utils'

interface IsTaskStartedValue {
  isStarted: boolean
  isSiblingStarted: boolean
  startedTask: TaskSchema | null
}

const defaultReturnValue: IsTaskStartedValue = {
  isStarted: false,
  isSiblingStarted: false,
  startedTask: null,
}

export const useIsTaskStarted = (
  taskId?: TaskSchema['id'] | null
): IsTaskStartedValue => {
  const { ongoingEvents } = useOngoingAndUpcomingScheduledEntities()

  const ongoingIds = useMemo(
    () =>
      new Set(
        ongoingEvents
          .filter((o) => o.type === 'TASK' || o.type === 'CHUNK')
          .map((o) => o.id)
          .filter(Boolean)
      ),
    [ongoingEvents]
  )

  const lookup = useLookup()
  const { data: task } = useTaskByIdV2(
    { id: taskId ?? '' },
    { enabled: !!taskId }
  )
  const taskWithRelations = useMemo(
    () =>
      task != null && task.type !== 'RECURRING_TASK'
        ? createTaskProxy(task, lookup)
        : null,
    [task, lookup]
  )

  const parentChunkId = getParentChunkId(task)
  const { data: parentChunkTask } = useTaskByIdV2(
    { id: parentChunkId ?? '' },
    { enabled: parentChunkId != null }
  )
  const parentChunkTaskWithRelations = useMemo(
    () =>
      parentChunkTask != null && parentChunkTask.type !== 'RECURRING_TASK'
        ? createTaskProxy(parentChunkTask, lookup)
        : null,
    [parentChunkTask, lookup]
  )

  if (taskWithRelations == null) {
    return defaultReturnValue
  }

  if (isTaskStarted(taskWithRelations, ongoingIds)) {
    return {
      ...defaultReturnValue,
      isStarted: true,
      startedTask: taskWithRelations,
    }
  }

  if (taskWithRelations.type !== 'CHUNK') {
    const chunk = taskWithRelations.chunks.find((c) =>
      isTaskStarted(c, ongoingIds)
    )
    if (chunk != null) {
      return {
        ...defaultReturnValue,
        isStarted: true,
        startedTask: chunk,
      }
    }
  }

  if (
    parentChunkTaskWithRelations != null &&
    parentChunkTaskWithRelations.type !== 'CHUNK'
  ) {
    const sibling = parentChunkTaskWithRelations.chunks.find((c) =>
      isTaskStarted(c, ongoingIds)
    )
    if (sibling != null && sibling.id !== taskWithRelations.id) {
      return {
        isStarted: true,
        isSiblingStarted: true,
        startedTask: sibling,
      }
    }
  }

  return defaultReturnValue
}

function isTaskStarted(
  task: TaskSchema | null,
  ongoingIds: Set<TaskSchema['id']>
) {
  return task != null && task.manuallyStarted && ongoingIds.has(task.id)
}
