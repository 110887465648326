import { getProjectStageFromDefinitionId } from '@motion/ui-logic/pm/project'
import { getTaskStatusChangedFields } from '@motion/ui-logic/pm/task'
import { type NormalTaskSchema, type TaskSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { optimisticUpdateProjectStage } from '~/global/cache'
import { useWorkspaceFns } from '~/global/hooks'
import { useReadProjectFn, useReadTaskFn } from '~/global/rpc/v2'
import { useCallback } from 'react'

import { useTaskUpdater } from './use-task-updater'

import { useResolveTask } from '../actions/use-resolve-task'

export function useTaskStatusUpdater() {
  const updateTask = useTaskUpdater()
  const readTask = useReadTaskFn()
  const updateCacheOnTaskStatusChange = useUpdateCacheOnTaskStatusChangeFn()
  const {
    getWorkspaceStatuses,
    getWorkspaceCompletedStatus,
    getWorkspaceCanceledStatus,
  } = useWorkspaceFns()
  const { completeTask, cancelTask } = useResolveTask({
    supressRecalcToast: true,
  })

  return useCallback(
    async (
      taskOrId: TaskSchema | TaskSchema['id'],
      statusId: TaskSchema['statusId']
    ) => {
      const task =
        typeof taskOrId === 'string' ? await readTask(taskOrId) : taskOrId

      if (task == null) {
        throw new Error('Task not found')
      }
      if (task.type === 'CHUNK') {
        throw new Error('Cannot update the status of a Chunk task')
      }

      if (task.type === 'NORMAL') {
        updateCacheOnTaskStatusChange(task, statusId)
      }

      let updates = {
        statusId,
        ...getTaskStatusChangedFields(
          { ...task, statusId },
          { statuses: getWorkspaceStatuses(task.workspaceId) }
        ),
      }

      const isCompletedStatus =
        statusId === getWorkspaceCompletedStatus(task.workspaceId).id

      if (isCompletedStatus) {
        return completeTask(task.id)
      }

      const isCanceledStatus =
        statusId === getWorkspaceCanceledStatus(task.workspaceId).id

      if (isCanceledStatus) {
        return cancelTask(task.id)
      }

      await updateTask(task, updates)
    },
    [
      cancelTask,
      completeTask,
      getWorkspaceCanceledStatus,
      getWorkspaceCompletedStatus,
      getWorkspaceStatuses,
      readTask,
      updateCacheOnTaskStatusChange,
      updateTask,
    ]
  )
}

function useUpdateCacheOnTaskStatusChangeFn() {
  const queryClient = useQueryClient()
  const readProject = useReadProjectFn()
  const { getWorkspaceStatuses, getWorkspaceCanceledStatus } = useWorkspaceFns()

  return useCallback(
    async (task: NormalTaskSchema, newStatusId: TaskSchema['statusId']) => {
      const project =
        task.projectId != null ? await readProject(task.projectId) : null

      if (project == null || task.stageDefinitionId == null) {
        return
      }

      const projectStage = getProjectStageFromDefinitionId(
        project,
        task.stageDefinitionId
      )

      if (projectStage == null) {
        return
      }

      // If the stage is canceled, and we're updating a task status to not canceled,
      // we should un-cancel the stage
      if (projectStage.canceledTime != null) {
        const newStatus = getWorkspaceStatuses(task.workspaceId).find(
          (s) => s.id === newStatusId
        )
        if (newStatus == null) {
          return
        }

        const isNewStatusCanceled =
          newStatus.id === getWorkspaceCanceledStatus(task.workspaceId).id
        if (isNewStatusCanceled) {
          return
        }

        optimisticUpdateProjectStage(queryClient, project.id, {
          ...projectStage,
          canceledTime: null,
        })
      }
    },
    [getWorkspaceCanceledStatus, getWorkspaceStatuses, queryClient, readProject]
  )
}
