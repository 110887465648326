import { type ReactNode } from 'react'

type SidebarRowProps = {
  label: ReactNode
  children: ReactNode
}
export function SidebarRow({ label, children }: SidebarRowProps) {
  return (
    <>
      <div className='text-sm text-semantic-neutral-text-subtle pt-1.5 truncate min-h-8'>
        {label}
      </div>
      <div className='overflow-x-hidden'>{children}</div>
    </>
  )
}
