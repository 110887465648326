import { NumberChart, type NumberChartProps } from '@motion/web-charts/number'

import { ChartQueryDataProvider } from './chart-query-data-provider'
import { type ConnectedChartProps } from './types'

type ConnectedNumberChartProps = ConnectedChartProps &
  Omit<NumberChartProps, 'value'>

export const ConnectedNumberChart = ({
  chartQuery,
  ...props
}: ConnectedNumberChartProps) => {
  return (
    <ChartQueryDataProvider chartQuery={chartQuery}>
      {(chartQueryData) => (
        <NumberChart value={chartQueryData.data[0]?.value} {...props} />
      )}
    </ChartQueryDataProvider>
  )
}
