import { formatDurationToShort } from '@motion/ui-logic'

import { DurationDropdown } from '~/areas/project-management/components'
import { useTaskCompletedDurationData } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'

import { CardButton } from '../components'

export type TaskCompletedDurationFieldProps = {
  task: TaskWithRelations
}
export const TaskCompletedDurationField = ({
  task,
}: TaskCompletedDurationFieldProps) => {
  const { onChange, disabled, completedDuration, choices } =
    useTaskCompletedDurationData(task)

  return (
    <DurationDropdown
      value={completedDuration}
      choices={choices}
      onChange={(value) => value && onChange(value)}
    >
      <CardButton disabled={disabled}>
        <span className='truncate'>
          {formatDurationToShort(completedDuration)}
        </span>
      </CardButton>
    </DurationDropdown>
  )
}
