import { usePrevious } from '@motion/react-core/hooks'
import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { type EventConferenceType } from '@motion/shared/common'
import { type LimitedEventConferenceType } from '@motion/ui-logic'
import { logEvent } from '@motion/web-base/analytics'
import { type CalendarSchema } from '@motion/zod/client'

import { useCallback, useEffect, useState } from 'react'

import { Events } from '../../../../../analyticsEvents'
import { useConferenceSettingsApi } from '../../../../../areas/settings/state'
import api from '../../../../../chromeApi/chromeApiContentScript'
import { selectCalendars } from '../../../../../state/calendar/calendarSlice'
import { getCalendars } from '../../../../../state/calendar/calendarThunks'
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks'
import { openZoomAuthWindow } from '../../../../../utils/auth'
import { availableOptions } from '../../../../../utils/availabilityTemplateUtils'
import { isZoomReady } from '../../../../../utils/booking'
import { doesAllowMeetInCalendar } from '../template-form.utils'

type Props = {
  calendars: CalendarSchema[]
  editingTemplateId?: string
  emailAccounts: EmailAccount[]
  getPhoneNumber: () => Promise<string>
  getCustomLocation: () => Promise<string>
  hostCalendar?: CalendarSchema | null
  hostEmailAccount?: EmailAccount
}

/**
 * Custom hook for managing conference options & conference type state. This
 * handles loading in `allowedConferenceTypes` for all the relevant calendars &
 * provides a callback function for recalculating the appropriate conference
 * options for the selected host email account.
 */
export function useBookingConferenceOptions(props: Props) {
  const {
    calendars,
    editingTemplateId,
    emailAccounts,
    getPhoneNumber,
    getCustomLocation,
    hostEmailAccount,
    hostCalendar,
  } = props

  const prevHostEmailAccount = usePrevious(hostEmailAccount)

  const dispatch = useAppDispatch()

  const [conferenceOptions, setConferenceOptions] = useState([
    ...availableOptions,
  ])

  const { selectConferenceSettings, setConferenceSettings } =
    useConferenceSettingsApi()
  const conferenceSettings = selectConferenceSettings()

  // This legacy code needs to be included because the `calendarList` in
  // firestore does not persist `allowedConferenceTypes` which is necessary
  // for selecting conference types. For the time being, we'll make this
  // request (which requests the calendars from the external provider on-demand)
  // and store the `allowedConferenceTypes` in in a map.
  // By the time we start migrating calendars into Cockroach, we should have
  // this field populated and not require this code.
  const legacyCalendars = useAppSelector(selectCalendars)

  const [
    allowedConferenceTypesByCalendarMap,
    setAllowedConferenceTypesByCalendarMap,
  ] = useState<Record<string, string[]> | null>(null)

  const [conferenceType, setConferenceType] =
    useState<EventConferenceType>('none')
  const openZoomAuth = useCallback(() => {
    openZoomAuthWindow()
    void logEvent(Events.CALENDAR_SETTINGS_ZOOM_CONNECT)
  }, [])

  useEffect(
    function initDefaultConferenceState() {
      if (!conferenceSettings || conferenceType) return
      setConferenceType(conferenceSettings?.conferenceType ?? 'none')
    },
    [conferenceSettings, conferenceType]
  )

  const getConferenceType = useCallback(
    (
      conferenceCalculation = true,
      // Override certain state values if provided - this is usually done if
      // the callback function is called on the same cycle the state value(s)
      // are updated
      overrides?: { emailAccount: EmailAccount; calendar: Calendar }
    ) => {
      let newConferenceType: EventConferenceType | undefined = undefined

      const overridedHostCalendar = overrides?.calendar ?? hostCalendar
      const overridedHostEmailAccount =
        overrides?.emailAccount ?? hostEmailAccount

      // Legacy code to populate allowedConferencedTypes. Can be removed
      // once the calendars are stored in cockroach
      const populatedHostCalendar: Calendar = {
        ...overridedHostCalendar,
        allowedConferenceTypes: overridedHostCalendar?.allowedConferenceTypes
          .length
          ? overridedHostCalendar.allowedConferenceTypes
          : allowedConferenceTypesByCalendarMap
            ? (allowedConferenceTypesByCalendarMap[
                `${overridedHostEmailAccount?.email}${overridedHostCalendar?.providerId}`
              ] ?? [])
            : [],
      } as Calendar

      let newConferenceOptions
      if (overridedHostEmailAccount?.providerType === 'GOOGLE') {
        if (!doesAllowMeetInCalendar(populatedHostCalendar)) {
          newConferenceOptions = availableOptions.filter(
            (type) => type !== 'meet' && type !== 'teamsForBusiness'
          )
        } else {
          newConferenceOptions = availableOptions.filter(
            (type) => type !== 'teamsForBusiness'
          )
        }
      } else if (overridedHostEmailAccount?.providerType === 'MICROSOFT') {
        newConferenceOptions = availableOptions.filter(
          (type) => type !== 'meet'
        )
      } else if (overridedHostEmailAccount?.providerType === 'APPLE') {
        newConferenceOptions = availableOptions.filter(
          (type) => type !== 'meet' && type !== 'teamsForBusiness'
        )
      } else {
        newConferenceOptions = availableOptions
      }

      if (!editingTemplateId || (editingTemplateId && conferenceCalculation)) {
        if (conferenceSettings?.conferenceType === 'zoom') {
          newConferenceType = 'zoom'
        } else if (conferenceSettings?.conferenceType === 'phone') {
          newConferenceType = 'phone'
        } else if (conferenceSettings?.conferenceType === 'customLocation') {
          newConferenceType = 'customLocation'
        } else if (overridedHostEmailAccount?.providerType === 'MICROSOFT') {
          newConferenceType = 'teamsForBusiness'
        } else if (
          overridedHostEmailAccount?.providerType === 'GOOGLE' &&
          doesAllowMeetInCalendar(populatedHostCalendar)
        ) {
          newConferenceType = 'meet'
        } else if (
          conferenceSettings?.zoomAccount ||
          conferenceSettings?.zoomManualLink
        ) {
          newConferenceType = 'zoom'
        } else {
          newConferenceType = undefined
        }
      }
      setConferenceOptions(newConferenceOptions)

      return {
        conferenceOptions: newConferenceOptions,
        conferenceType: newConferenceType ?? conferenceType,
      }
    },
    [
      allowedConferenceTypesByCalendarMap,
      conferenceSettings,
      conferenceType,
      hostCalendar,
      hostEmailAccount,
      editingTemplateId,
    ]
  )

  const onConferenceTypeChange = useCallback(
    async (val: LimitedEventConferenceType) => {
      const { zoomNeedsRefresh } = await api.storage.local.get([
        'zoomNeedsRefresh',
      ])

      if (
        val === 'zoom' &&
        !isZoomReady(conferenceSettings, zoomNeedsRefresh)
      ) {
        void openZoomAuth()
      } else if (val === 'phone' && !conferenceSettings?.phoneNumber) {
        void logEvent(Events.CALENDAR_PHONE_MODAL_OPEN)
        const phoneNumber = await getPhoneNumber()
        if (phoneNumber) {
          await setConferenceSettings({
            ...conferenceSettings,
            phoneNumber,
          })
          setConferenceType(val)
        }
      } else if (val === 'customLocation') {
        void logEvent(Events.CALENDAR_CUSTOM_LOCATION_OPEN)
        const customLocation = await getCustomLocation()

        if (customLocation) {
          await setConferenceSettings({
            ...conferenceSettings,
            customLocation,
          })
          setConferenceType(val)
        }
      } else {
        setConferenceType(val)
      }
    },
    [
      conferenceSettings,
      setConferenceSettings,
      openZoomAuth,
      getPhoneNumber,
      getCustomLocation,
    ]
  )

  useEffect(
    function refreshConferenceTypeOptions() {
      if (
        !allowedConferenceTypesByCalendarMap ||
        !hostCalendar ||
        !hostEmailAccount
      ) {
        return
      }

      getConferenceType(false)
    },
    [
      allowedConferenceTypesByCalendarMap,
      getConferenceType,
      hostCalendar,
      hostEmailAccount,
    ]
  )

  // Updates the conference type if the host changes to a different provider,
  // and the selected type is not available
  useEffect(
    function handleHostChange() {
      if (
        !hostEmailAccount ||
        !hostCalendar ||
        !prevHostEmailAccount ||
        prevHostEmailAccount.id === hostEmailAccount.id
      )
        return

      const result = getConferenceType(true)
      if (!result) return

      const found = result.conferenceOptions.find(
        (option) => option === conferenceType
      )
      if (!found) {
        setConferenceType(result.conferenceType ?? 'none')
      }
    },
    [
      conferenceType,
      getConferenceType,
      hostCalendar,
      hostEmailAccount,
      prevHostEmailAccount,
      setConferenceType,
    ]
  )

  // TODO remove this once calendars are stored in cockroach
  useEffect(
    function resolveAllowedConferenceTypes() {
      async function fetchAndPopulateAllowedConferenceTypes() {
        if (allowedConferenceTypesByCalendarMap != null) {
          return
        }

        const map: Record<string, string[]> = {}

        // Lookup email address for a given email account ID
        const emailAccountsMap = emailAccounts.reduce(
          (acc, emailAccount) => {
            acc[emailAccount.id] = emailAccount.email
            return acc
          },
          {} as Record<string, string>
        )

        let hasAllowedConferenceTypes = false

        // Populate the map with the allowed conference types from the postgres
        // calendars where applicable
        for (const calendar of calendars) {
          map[
            `${emailAccountsMap[calendar.emailAccountId]}${calendar.providerId}`
          ] = calendar.allowedConferenceTypes ?? []

          if (calendar.allowedConferenceTypes?.length) {
            hasAllowedConferenceTypes = true
          }
        }

        // Fallback to manually fetching calendars from provider
        if (!hasAllowedConferenceTypes) {
          const fetchedCalendars =
            legacyCalendars ?? (await dispatch(getCalendars()).unwrap())

          for (const calendar of fetchedCalendars) {
            map[`${calendar.email}${calendar.id}`] =
              calendar.allowedConferenceTypes ?? []
          }
        }

        setAllowedConferenceTypesByCalendarMap(map)
      }

      void fetchAndPopulateAllowedConferenceTypes()
    },
    [
      allowedConferenceTypesByCalendarMap,
      dispatch,
      legacyCalendars,
      calendars,
      emailAccounts,
    ]
  )

  return {
    conferenceOptions,
    conferenceType,
    onConferenceTypeChange,
    conferenceTypesLoaded:
      Object.values(allowedConferenceTypesByCalendarMap ?? {}).length > 0,
    getConferenceType,
    setConferenceType,
  }
}
