import { FormModal, useShortcut } from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { type ModalTriggerComponentProps } from '~/areas/modals'
import { DescriptionEditor } from '~/areas/task-project/components'
import { useState } from 'react'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'description-modal': PromptCallbacks<string> & {
      workspaceId: string
      taskId?: string
      description?: string
      title?: string
    }
  }
}

type DescriptionModalProps = ModalTriggerComponentProps<'description-modal'>

export const DescriptionModal = ({
  close,
  workspaceId,
  taskId,
  description: initialDescription = '',
  title = 'Description',
}: DescriptionModalProps) => {
  const [description, setDescription] = useState(initialDescription)
  const modalApi = useModalApi()
  const submitDisabled = description.trim().length === 0

  function handleSubmit() {
    close(description)
  }

  const isDirty = description !== initialDescription

  const onCancel = async () => {
    if (!isDirty) {
      close()
      return
    }

    const response = await modalApi.prompt('confirm', {
      analytics: {
        name: 'pending-changes',
      },
      title: `Your changes aren't saved`,
      closeButtonText: 'Go back',
      confirmButtonText: 'Close without saving',
    })

    if (response === true) {
      close()
    }
  }

  // Not working, probably because main form also uses this shortcut
  // and calls event.stopPropagation()
  useShortcut('mod+s', () => handleSubmit(), {
    enabled: !submitDisabled,
  })
  useShortcut('escape', () => onCancel())

  return (
    <FormModal
      title={title}
      visible
      submitAction={{
        onAction: handleSubmit,
        disabled: submitDisabled,
        shortcut: 'mod+s',
      }}
      cancelAction={{
        shortcut: 'esc',
      }}
      onClose={onCancel}
    >
      {/* Seeing FOUC without this */}
      <div className='flex h-[511px] w-[549px] overflow-y-auto overflow-x-hidden'>
        <DescriptionEditor
          workspaceId={workspaceId}
          taskId={taskId}
          taskType='NORMAL'
          value={description}
          onChange={setDescription}
          disableImageUpload
        />
      </div>
    </FormModal>
  )
}
