import { classed } from '@motion/theme'
import { Button, showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { HasExperiment } from '@motion/web-common/flags'
import { useModalApi } from '@motion/web-common/modals'

import { SettingPage } from '~/components/Settings/Components/SettingPage'
import { useDeleteProfilePicture, useUpdateName } from '~/global/rpc/user'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { selectUser, setUser, type User } from '~/state/userSlice'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useUploadProfilePicture } from './utils'

import { ProfilePhoto } from '../components/profile-photo'

export function ConnectedAccountSettings() {
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const uploadProfilePicture = useUploadProfilePicture(user.id)
  const { mutateAsync: deleteProfilePicture } = useDeleteProfilePicture()
  const { mutateAsync: updateNameAsync, isLoading: updateNameLoading } =
    useUpdateName()

  async function onUploadPhoto(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0]
    if (file) {
      try {
        recordAnalyticsEvent('ACCOUNT_SETTINGS_UPLOAD_PHOTO')
        await uploadProfilePicture(file)
      } catch (err) {
        if (err instanceof Error && 'message' in err) {
          const message =
            file.size > 1 * 1024 * 1024
              ? 'Please use a file smaller than 1MB'
              : err.message
          showToast('error', message)
        }
      }
    }
  }

  async function onRemovePhoto() {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_DELETE_PHOTO')
    await deleteProfilePicture({ id: user.id })
  }

  async function onUpdateName(name: string) {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_UPDATE_NAME')
    await updateNameAsync({ name: name.trim() })
    await dispatch(setUser({ ...user, displayName: name }))
  }

  return (
    <AccountSettings
      onRemovePhoto={onRemovePhoto}
      onUploadPhoto={onUploadPhoto}
      updateName={onUpdateName}
      updateNameLoading={updateNameLoading}
      user={user}
    />
  )
}

type AccountSettingsProps = {
  onRemovePhoto: () => Promise<void>
  onUploadPhoto: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  updateName: (name: string) => Promise<void>
  updateNameLoading: boolean
  user: User
}
function AccountSettings({
  onRemovePhoto,
  onUploadPhoto,
  updateName,
  updateNameLoading,
  user,
}: AccountSettingsProps) {
  const [name, setName] = useState(user.displayName)
  const [didUpdateName, setDidUpdateName] = useState(false)
  const isPasswordUser = user.loginProvider === 'password'
  const modalApi = useModalApi()

  function handleBlurName() {
    if (!name.length) {
      setName(user.displayName)
    }
  }

  function handleUpdateEmail() {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_OPEN_CHANGE_EMAIL_MODAL')
    modalApi.open('change-email')
  }

  function handleDeleteTasks() {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_OPEN_DETACH_CALENDAR_MODAL')
    modalApi.open('detach-calendar')
  }
  function handleDisconnectCalendars() {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_OPEN_DISCONNECT_CALENDARS_MODAL')
    modalApi.open('disconnect-calendars')
  }
  function handleDeleteAccount() {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_OPEN_DELETE_ACCOUNT_MODAL')
    modalApi.open('delete-account')
  }

  function handleChangePassword() {
    recordAnalyticsEvent('ACCOUNT_SETTINGS_OPEN_CHANGE_PASSWORD_MODAL')
    modalApi.open('change-password')
  }

  async function onUpdateName() {
    await updateName(name)
    setDidUpdateName(true)
  }

  return (
    <SettingPage className='gap-4' title='Account settings'>
      <div className='max-w-[650px] flex flex-col gap-8 mb-6'>
        <AccountSection>
          <SectionTitle>Profile</SectionTitle>
          <div className='flex gap-4 flex-grow items-center mt-1'>
            <ProfilePhoto
              onRemovePhoto={onRemovePhoto}
              onUpload={onUploadPhoto}
              user={user}
            />
            <div className='flex flex-col items-center gap-2 w-full relative'>
              <div className='flex gap-4 items-center w-full'>
                <TextField
                  fullWidth
                  labelHidden
                  onBlur={handleBlurName}
                  onChange={(name) => {
                    setDidUpdateName(false)
                    setName(name)
                  }}
                  onKeyDown={async (e) => {
                    const { key } = e
                    if (key === 'Enter' && name.trim().length) {
                      await onUpdateName()
                    }
                  }}
                  value={name}
                />
                {!!name.length && name !== user.displayName && (
                  <Button disabled={updateNameLoading} onClick={onUpdateName}>
                    Save
                  </Button>
                )}
              </div>
              {didUpdateName && (
                <span className='text-semantic-success-text-default text-xs font-normal absolute left-1 bottom-[-24px]'>
                  Name changed successfully
                </span>
              )}
            </div>
          </div>
        </AccountSection>
        <HasExperiment name='change-email'>
          <AccountSection>
            <SectionContent>
              <div className='flex flex-col gap-2'>
                <SectionTitle>Email</SectionTitle>
                <SectionSubTitle>{user.email}</SectionSubTitle>
                <span className='text-semantic-neutral-text-subtle text-sm max-w-[412px]'>
                  This email is only used to log in. It is not connected to your
                  calendar. To change your calendar accounts, go to{' '}
                  <Link
                    className='text-semantic-primary-text-default underline'
                    to='/web/settings/calendar'
                  >
                    Calendars
                  </Link>
                  .
                </span>
              </div>
              <Button sentiment='neutral' onClick={handleUpdateEmail}>
                Change email
              </Button>
            </SectionContent>
          </AccountSection>
        </HasExperiment>
        {isPasswordUser && (
          <AccountSection>
            <SectionContent>
              <div className='flex flex-col gap-2'>
                <SectionTitle>Password</SectionTitle>
                <span className='text-semantic-neutral-text-subtle text-sm'>
                  Used to log in to your account
                </span>
              </div>

              <Button sentiment='neutral' onClick={handleChangePassword}>
                Change password
              </Button>
            </SectionContent>
          </AccountSection>
        )}
        <div className='flex flex-col gap-6'>
          <SectionTitle isDanger>Danger zone</SectionTitle>
          <ActionSection
            title='Delete motion tasks from your calendar'
            bodyText={
              <span className='text-sm font-normal text-semantic-neutral-text-subtle max-w-[480px]'>
                All tasks will be removed from your Motion calendar (and any
                synced external calendars). Future tasks can still be added
                later.
                <span className='font-medium'>
                  {' '}
                  This will not cancel your subscription.
                </span>
              </span>
            }
            submitButton={
              <Button
                onClick={handleDeleteTasks}
                sentiment='error'
                size='small'
                variant='outlined'
              >
                Delete Motion tasks
              </Button>
            }
          />
          <ActionSection
            title='Disconnect external calendars'
            bodyText={
              <span className='text-sm font-normal text-semantic-neutral-text-subtle max-w-[430px]'>
                This will disconnect any external calendar connections and allow
                you to reconnect them. This is a safe way to re-sync any
                external calendars if they are having sync issues.
              </span>
            }
            submitButton={
              <Button
                onClick={handleDisconnectCalendars}
                sentiment='error'
                size='small'
                variant='outlined'
              >
                Disconnect external calendars
              </Button>
            }
          />
          <div>
            <Button
              fullWidth={false}
              onClick={handleDeleteAccount}
              sentiment='neutral'
            >
              More Options
            </Button>
          </div>
        </div>
      </div>
    </SettingPage>
  )
}

type ActionSectionProps = {
  title: string
  bodyText: React.ReactNode
  submitButton: React.ReactNode
}
function ActionSection({ title, bodyText, submitButton }: ActionSectionProps) {
  return (
    <SectionContent>
      <div className='flex flex-col gap-1'>
        <SectionSubTitle>{title}</SectionSubTitle>
        {bodyText}
      </div>
      {submitButton}
    </SectionContent>
  )
}

const AccountSection = classed('div', {
  base: 'flex flex-col gap-2 pb-8 border-b border-semantic-neutral-border-default',
})

const SectionTitle = classed('span', {
  base: 'text-semantic-neutral-text-default text-[16px] leading-5 font-semibold',
  variants: {
    isDanger: {
      true: 'text-semantic-error-text-default',
    },
  },
})

const SectionContent = classed('div', {
  base: 'flex flex-grow items-center justify-between',
})

const SectionSubTitle = classed('span', {
  base: 'font-semibold text-semantic-neutral-text-default text-sm',
})
