import { type CustomFieldSchemaByType } from '@motion/ui-logic'

import { useProjectCustomFieldUpdater } from '~/areas/project/hooks'
import {
  type CustomFieldWithValue,
  type ProjectWithRelations,
} from '~/global/proxies'
import { type FC } from 'react'

import { type ControlledCustomFieldProps } from '../types'

export const ConnectedProjectCell = ({
  project,
  customField,
  component: Component,
  disabled,
}: {
  project: ProjectWithRelations
  customField: CustomFieldWithValue
  component: FC<
    ControlledCustomFieldProps<CustomFieldSchemaByType<typeof customField.type>>
  >
  disabled: boolean
}) => {
  const updateProjectCustomField = useProjectCustomFieldUpdater()
  const { value } = customField

  const onSubmit = async (newValue: typeof value) => {
    await updateProjectCustomField(project, customField.definition, newValue)
  }

  return (
    <Component
      customField={customField}
      value={value ?? null}
      onSubmit={onSubmit}
      disabled={disabled}
    />
  )
}
