import { useSharedStateQuery } from '@motion/react-core/shared-state'
import { createUnknownStage } from '@motion/rpc-cache'
import { isObjectNoneId } from '@motion/shared/identifiers'
import { Sentry } from '@motion/web-base/sentry'
import {
  type ProjectDefinitionSchema,
  type StageDefinitionSchema,
} from '@motion/zod/client'

import {
  AppWorkspaceContext,
  type WorkspaceStageDefinition,
} from '~/global/contexts'

export const useStageDefinition = (
  id: StageDefinitionSchema['id'] | null | undefined
): WorkspaceStageDefinition => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    const stageDefinition =
      id != null
        ? (state.stageDefinitions.byId[id] ?? createUnknownStage(id))
        : createUnknownStage(id)

    if (id != null && isObjectNoneId(stageDefinition)) {
      Sentry.captureMessage('No stage definition found', {
        extra: {
          id,
        },
      })
    }

    return stageDefinition
  })
}

export const useStageDefinitions = (
  ids: StageDefinitionSchema['id'][] | null | undefined
): WorkspaceStageDefinition[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    if (ids == null) {
      return []
    }

    return ids
      .map((id) => {
        const stageDefinition =
          state.stageDefinitions.byId[id] ?? createUnknownStage(id)

        if (isObjectNoneId(stageDefinition)) {
          Sentry.captureMessage('No stage definition found', {
            extra: {
              id,
            },
          })
          return null
        }

        return stageDefinition
      })
      .filter(Boolean) as WorkspaceStageDefinition[]
  })
}

export const useStageDefinitionsByProjectDefinitionId = (
  projectDefinitionId: ProjectDefinitionSchema['id'] | null | undefined
): StageDefinitionSchema[] => {
  return useSharedStateQuery(AppWorkspaceContext, (state) => {
    return state.stageDefinitions.all.filter(
      (sd) => sd.projectDefinitionId === projectDefinitionId
    )
  })
}
