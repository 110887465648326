import { useAppDispatch } from '~/state/hooks'
import { updateTeam } from '~/state/projectManagement/teamThunks'
import { type Team } from '~/state/TeamTypes'
import { twMerge } from 'tailwind-merge'

import { EditableSettingsRow } from '../../../../Settings/Components/EditableSettingsRow/EditableSettingsRow'

interface DetailsPageProps {
  team: Team
  isAdmin: boolean
}

export const DetailsPage = ({ team, isAdmin }: DetailsPageProps) => {
  const dispatch = useAppDispatch()

  return (
    <div
      className={twMerge(
        'flex h-full w-full flex-col gap-2 overflow-auto pb-[200px]'
      )}
    >
      <div className='flex flex-row items-center gap-4'>
        <div className='w-8 h-8 pl-2 pr-[7px] py-1.5 bg-gray-200 rounded justify-center items-center inline-flex'>
          <div className="text-zinc-500 text-lg font-bold font-['SF Pro'] leading-tight">
            {team.name.trim().charAt(0).toUpperCase()}
          </div>
        </div>
        <EditableSettingsRow
          className='!py-0'
          disabled={!isAdmin}
          id={team.id}
          name={team.name}
          onChange={(teamId, name) => {
            if (name.trim() === '') return
            void dispatch(updateTeam({ teamId, name }))
          }}
          canDelete={false}
        />
      </div>
    </div>
  )
}
