import { classed } from '@motion/theme'
import { ListItem, SortableList, Toggle } from '@motion/ui/base'
import { truncateAtSpace } from '@motion/ui-logic'

export type VizField = {
  id: string
  visible?: boolean | undefined
  name: string
  workspaceName?: string
}

type Props = {
  items: VizField[]
  onOrderChange: (newItems: VizField[]) => void
  toggleFieldVisibility: (fieldId: string, isVisible: boolean) => void
}

export function VisibilityDropdownContent({
  items,
  onOrderChange,
  toggleFieldVisibility,
}: Props) {
  return (
    <DropdownContainer>
      <SortableList
        items={items}
        onChange={onOrderChange}
        renderDraggableRowSection={(item) => {
          if (!item) {
            return null
          }
          const { name, workspaceName } = item

          return (
            <ListItem className='hover:bg-transparent'>
              <div className='flex gap-1'>
                <span>{truncateAtSpace(name, 30)}</span>
                {workspaceName != null && (
                  <span className='text-semantic-neutral-text-subtle'>{`(${workspaceName})`}</span>
                )}
              </div>
            </ListItem>
          )
        }}
        renderRow={(item) => {
          if (!item) {
            return null
          }
          const { visible, id } = item

          return (
            <div className='flex items-center gap-1 pl-1 py-1.5 pr-2'>
              <Toggle
                checked={visible}
                onChange={() => toggleFieldVisibility(id, !visible)}
              />
            </div>
          )
        }}
      />
    </DropdownContainer>
  )
}

const DropdownContainer = classed('div', {
  base: `
    p-1 scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden max-h-[400px]
  `,
})
