import { useContext } from 'react'

import { FileUploadContext } from '../file-upload-context'

export function useFileUploadState() {
  const context = useContext(FileUploadContext)

  if (!context) {
    throw new Error(
      'useFileUploadState must be used within a FileUploadProvider'
    )
  }

  return context
}
