import { useScheduleAssistantCalendarContext } from '~/areas/event/modals/schedule-assistant/hooks'
import { useMemo } from 'react'

import { useCreateNewEvents } from './use-create-new-events'
import { useSelectExistingEvents } from './use-select-existing-events'

export function useFullCalendarEvents() {
  const [calendarState] = useScheduleAssistantCalendarContext()

  const { mode } = calendarState

  const selectExistingEvents = useSelectExistingEvents()
  const createNewEvents = useCreateNewEvents()

  return useMemo(() => {
    if (mode === 'create-new') {
      return createNewEvents.filter(Boolean)
    }

    if (mode === 'select-existing') {
      return selectExistingEvents.filter(Boolean)
    }

    return []
  }, [createNewEvents, mode, selectExistingEvents])
}
