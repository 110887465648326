import { type TaskUrlSearchParams } from '@motion/ui-logic/pm/task'
import { entries } from '@motion/utils/object'

import { useCallback } from 'react'

import { type GroupedNode, type Tree } from '../../../grouping'
import {
  type InferTaskDataHandler,
  useInferTaskDataHandler,
} from '../../../tree-list/cells/hooks'
import { getTaskInferItem } from '../../../tree-list/cells/hooks/utils'

const groupTaskParamKeyLookup = {
  workspaceId: 'forWorkspace',
  projectId: 'forProject',
  assigneeId: 'forAssignee',
  statusId: 'forStatus',
  startDate: 'forStartDate',
  deadline: 'forDueDate',
  priorityLevel: 'forPriority',
  labelId: 'forLabel',
  customFieldValues: 'forCustomField',
  stageDefinitionId: 'forStage',
} as const satisfies {
  [K in keyof ReturnType<InferTaskDataHandler>]: Exclude<
    keyof TaskUrlSearchParams,
    'task'
  >
}

export const useInferTaskNavigateParams = () => {
  const inferTaskData = useInferTaskDataHandler()

  const getNodeData = useCallback(
    <T extends GroupedNode>(
      group: Tree<T>,
      workspaceId: string
    ): TaskUrlSearchParams => {
      // Check the node item type and return the appropriate data
      const node = group.item
      if (node == null) {
        return {}
      }

      const inferItem = getTaskInferItem({
        row: node,
      })
      const data = inferTaskData(inferItem, workspaceId)
      const params = entries(data).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [groupTaskParamKeyLookup[
            key as keyof typeof groupTaskParamKeyLookup
          ]]: value,
        }),
        {}
      )

      return params
    },
    [inferTaskData]
  )

  return useCallback(
    <T extends GroupedNode>(
      group: Tree<T>,
      workspaceId: string
    ): TaskUrlSearchParams => {
      const navigateData = getNodeData(group, workspaceId)

      return {
        ...navigateData,
        forWorkspace: workspaceId,
      }
    },
    [getNodeData]
  )
}
