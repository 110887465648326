import { Portal } from '@motion/ui/base'

import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'

import { DraggableStageTaskWrapper } from './draggable-stage-task-card'

import { useFlowTemplateForm, useTemplateTasks } from '../../../../hooks'
import { type StageColumnsProps } from '../../stage-columns'
import { StageTaskCard } from '../../task-card'
import { useTemplateActiveTask, useTemplateDndHandlerHooks } from '../hooks'

export const StageColumnsDnd = ({
  fieldArray,
  children,
}: Pick<StageColumnsProps, 'fieldArray'> & {
  children: React.ReactNode
}) => {
  const {
    form: { watch },
  } = useFlowTemplateForm()

  const stages = watch('stages')
  const templateTasks = useTemplateTasks()

  const { activeTask, setActiveId, stageTasksPath, activeTaskIndex } =
    useTemplateActiveTask(stages, templateTasks.all)

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 0 } })
  )

  const handlers = useTemplateDndHandlerHooks({
    setActiveId,
    replace: fieldArray.replace,
  })

  return (
    <DndContext sensors={sensors} {...handlers}>
      <SortableContext items={templateTasks.ids}>{children}</SortableContext>
      <Portal>
        <DragOverlay
          dropAnimation={{
            duration: 0,
          }}
        >
          {activeTask != null &&
            activeTaskIndex != null &&
            stageTasksPath != null && (
              <DraggableStageTaskWrapper isDragging>
                <StageTaskCard
                  task={activeTask}
                  stageTasksPath={stageTasksPath}
                  taskBasePath={`${stageTasksPath}.${activeTaskIndex}` as const}
                />
              </DraggableStageTaskWrapper>
            )}
        </DragOverlay>
      </Portal>
    </DndContext>
  )
}
