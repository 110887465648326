import {
  ExclamationCircleSolid,
  PriorityASAPSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { type ActionItem } from '@motion/ui/base'

import { HardDeadlineTooltipIcon } from '~/areas/project-management/components'
import { useBulkUpdateTasks } from '~/areas/tasks/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { useCallback, useMemo } from 'react'

type ActionOptions = 'doASAP' | 'hardDeadline' | 'complete' | 'cancel' | 'edit'

export const useBulkTaskActions = (
  workspaceId: string,
  tasks: TaskSchema[],
  actionList: ActionOptions[] = [],
  onClose: () => void
) => {
  const bulkUpdateTasks = useBulkUpdateTasks()
  const { getWorkspaceCompletedStatus, getWorkspaceCanceledStatus } =
    useWorkspaceFns()

  const taskIds = tasks.map((task) => task.id)

  const doTasksAsap = useCallback(() => {
    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          priorityLevel: 'ASAP',
        },
      },
    ])

    onClose()
  }, [bulkUpdateTasks, onClose, taskIds, workspaceId])

  const doTasksHardDeadline = useCallback(() => {
    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          deadlineType: 'HARD',
        },
      },
    ])

    onClose()
  }, [bulkUpdateTasks, onClose, taskIds, workspaceId])

  const completeTasks = useCallback(() => {
    const completedStatus = getWorkspaceCompletedStatus(workspaceId)

    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          statusId: completedStatus.id,
        },
      },
    ])

    onClose()
  }, [
    bulkUpdateTasks,
    onClose,
    getWorkspaceCompletedStatus,
    taskIds,
    workspaceId,
  ])

  const cancelTasks = useCallback(() => {
    const canceledStatus = getWorkspaceCanceledStatus(workspaceId)

    void bulkUpdateTasks([
      {
        currentWorkspaceId: workspaceId,
        taskIds,
        update: {
          type: 'bulk-field-update',
          statusId: canceledStatus.id,
        },
      },
    ])

    onClose()
  }, [
    bulkUpdateTasks,
    onClose,
    getWorkspaceCanceledStatus,
    taskIds,
    workspaceId,
  ])

  return useMemo<ActionItem[]>(() => {
    return actionList
      .map((action) => {
        switch (action) {
          case 'doASAP':
            return {
              // TODO: Fix this color issue
              prefix: (
                <PriorityASAPSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Do all ASAP',
              onAction: doTasksAsap,
            }
          case 'hardDeadline':
            return {
              // TODO: Fix this color issue
              prefix: (
                <ExclamationCircleSolid className='text-dropdown-item-icon-default' />
              ),
              content: 'Turn on hard deadline for all',
              suffix: <HardDeadlineTooltipIcon />,
              onAction: doTasksHardDeadline,
            }
          case 'complete':
            return {
              content: 'Complete all tasks',
              prefix: <StatusCompletedSolid />,
              onAction: completeTasks,
            }
          case 'cancel':
            return {
              content: 'Cancel all tasks',
              prefix: (
                <StatusCanceledSolid className='text-dropdown-item-icon-default' />
              ),
              onAction: cancelTasks,
            }
          default:
            return null
        }
      })
      .filter(Boolean) as ActionItem[]
  }, [actionList, cancelTasks, completeTasks, doTasksAsap, doTasksHardDeadline])
}
