import { TaskSolid } from '@motion/icons'
import { showToast, useShortcut } from '@motion/ui/base'

import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'
import { type FormEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ExpandedTaskContent } from './expanded-task-content'
import { FormTemplateNameField } from './form-template-name-field'
import { FormWorkspaceProjectDropdown } from './form-workspace-project-dropdown'
import { TemplateTaskDotsDropdown } from './template-task-dots-dropdown'

import { useSearchParams } from '../../../../../routing'
import { TaskProjectModalContent } from '../../../components'
import { useTemplateTaskForm } from '../hooks'
import { type TemplateTaskFormFields } from '../types'

export type TaskFormContentProps = {
  onClose(): void
}

export const TaskFormContent = (props: TaskFormContentProps) => {
  const { onClose } = props

  const { templateProjectId, templateTaskId } = useParams<{
    templateProjectId: string
    templateTaskId: string
  }>()
  const { template } = useSearchParams<{ template: string }>()
  const navigate = useNavigate()

  const { form, actions } = useTemplateTaskForm()

  const { submitTemplate, closeModal } = actions
  const { isDirty, isSubmitting } = form.formState

  const taskTemplateId = form.watch('templateId')
  const taskName = form.watch('name')

  useRouteConfirmationPromptBeforeLeaving({ when: isDirty && !isSubmitting })

  const onFormSubmit = async (data: TemplateTaskFormFields) => {
    await submitTemplate(data)
    form.reset({ templateName: '' }, { keepValues: true })

    // When saving a template from a task, we go back to the task view
    if (template === 'new') {
      closeModal()
      return
    }

    // From new settings page
    if (templateProjectId || templateTaskId) {
      return navigate('../..', { relative: 'path' })
    }

    return
  }

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (!isDirty) return

    await form.handleSubmit(onFormSubmit, (validationErrors) => {
      const errors = Object.values(validationErrors)
      if (errors.length < 1) return

      const message = errors[0].message
      if (typeof message !== 'string' || !message) return

      showToast('error', message)
    })(e)
  }

  // Always get this shortcut enabled and checking the dirty in the handler
  // this avoid having the native mod+s shortcut triggering when the form is clean
  useShortcut('mod+s', () => onSubmit())

  return (
    <TaskProjectModalContent
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      disableSubmit={!isDirty || !taskName.trim()}
      actionButtonLabel={
        taskTemplateId != null ? 'Save template' : 'Create template'
      }
      renderLeftHeader={() => {
        return (
          <div className='grid grid-cols-[min-content_min-content_min-content_1fr] items-center gap-1'>
            <TaskSolid className='w-[14px] h-[14px] text-semantic-neutral-icon-subtle' />
            <FormWorkspaceProjectDropdown />
          </div>
        )
      }}
      renderRightHeader={() => {
        return <TemplateTaskDotsDropdown />
      }}
      renderLeftFooter={() => {
        // Template task within a template project do not require a template name
        if (templateProjectId) return
        return <FormTemplateNameField />
      }}
    >
      <ExpandedTaskContent />
    </TaskProjectModalContent>
  )
}
