import { type Schedule } from '@motion/rpc-types'

import { BackendApiService } from './BackendApiService'

/**
 * Service for fetching and persisting user settings
 */
export class SettingsService extends BackendApiService {
  static id = 'SettingsService' as const

  async updateSchedules(schedules: Record<string, Schedule>) {
    return await this.request('settings/updateSchedules', 'POST', schedules)
  }

  async deleteSchedule(scheduleId: string) {
    return await this.request(`settings/schedules/${scheduleId}`, 'DELETE')
  }
}

export const instance = new SettingsService()
