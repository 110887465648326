import { PlusSolid } from '@motion/icons'
import {
  Button,
  type ButtonProps,
  PopoverTrigger,
  Tooltip,
} from '@motion/ui/base'
import {
  type AllAvailableCustomFieldSchema,
  isMaxNumberWorkspaceFields,
} from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'
import { useModalApi } from '@motion/web-common/modals'

import { useCustomFieldsByWorkspaceId } from '~/areas/custom-fields/hooks'
import { useWorkspaceById } from '~/global/hooks'

import { AddCustomFieldCategoriesPopover } from './add-custom-field-categories-popover'

export function AddCustomFieldButton({
  workspaceId,
  sentiment = 'primary',
  size = 'small',
  usePopover,
  disabled,
  onCreate,
}: {
  workspaceId: string
  sentiment?: ButtonProps['sentiment']
  size?: ButtonProps['size']
  usePopover?: boolean
  disabled?: boolean
  onCreate?: (value: AllAvailableCustomFieldSchema | undefined) => void
}) {
  const workspace = useWorkspaceById(workspaceId)
  const modalApi = useModalApi()
  const workspaceCustomFields = useCustomFieldsByWorkspaceId(workspaceId)

  if (!workspace) {
    Sentry.captureException(new Error('No workspace found for workspace ID'), {
      tags: { workspaceId, position: 'AddCustomFieldButton' },
    })
    return <div className='text-red-700'>Error loading workspace</div>
  }

  const isDisabled =
    isMaxNumberWorkspaceFields(workspaceCustomFields.length) || disabled

  const openAddCustomFieldModal = async () => {
    await modalApi.prompt('add-custom-field', {
      workspace,
    })
  }

  const trigger = (
    <Tooltip
      content={
        isMaxNumberWorkspaceFields(workspaceCustomFields.length)
          ? 'Max of 25 custom fields per workspace'
          : ''
      }
    >
      <Button
        onClick={() => !usePopover && openAddCustomFieldModal()}
        size={size}
        variant='muted'
        sentiment={sentiment}
        disabled={isDisabled}
      >
        <PlusSolid />
        Add custom field
      </Button>
    </Tooltip>
  )

  if (usePopover) {
    return (
      <PopoverTrigger
        renderPopover={({ close }) => (
          <AddCustomFieldCategoriesPopover
            close={close}
            workspace={workspace}
            onValue={onCreate}
          />
        )}
        placement='bottom-start'
      >
        {trigger}
      </PopoverTrigger>
    )
  }

  return trigger
}
