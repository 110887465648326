import { type SVGProps } from 'react'

export function DarkThemeSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={204}
      height={126}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#a)'>
        <rect width={204} height={136} rx={8} fill='#1F1F1F' />
        <mask id='b' fill='#fff'>
          <path d='M1 15h39v121H1V15Z' />
        </mask>
        <path d='M1 15h39v121H1V15Z' fill='#1C1C1C' />
        <rect x={6} y={22} width={27} height={3} rx={1.5} fill='#292929' />
        <rect x={6} y={29} width={27} height={3} rx={1.5} fill='#292929' />
        <rect x={6} y={36} width={27} height={3} rx={1.5} fill='#292929' />
        <rect x={6} y={43} width={27} height={3} rx={1.5} fill='#292929' />
        <rect x={6} y={50} width={27} height={3} rx={1.5} fill='#292929' />
        <rect x={6} y={123} width={29} height={7} rx={3} fill='#242424' />
        <path d='M39 15v121h2V15h-2Z' fill='#262626' mask='url(#b)' />
        <mask id='c' fill='#fff'>
          <path d='M1 0h205v15H1V0Z' />
        </mask>
        <path d='M1 0h205v15H1V0Z' fill='#1C1C1C' />
        <circle cx={9.5} cy={7.5} r={3.5} fill='#292929' />
        <path d='M206 14H1v2h205v-2Z' fill='#292929' mask='url(#c)' />
        <rect x={46} y={21} width={19} height={2} rx={1} fill='#363636' />
        <rect x={46} y={26} width={49} height={18} rx={1} fill='#262626' />
        <rect x={49} y={29} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={49} y={34} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={46} y={46} width={49} height={18} rx={1} fill='#262626' />
        <rect x={49} y={49} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={49} y={54} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={46} y={66} width={49} height={18} rx={1} fill='#262626' />
        <rect x={49} y={69} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={49} y={74} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={46} y={86} width={49} height={18} rx={1} fill='#262626' />
        <rect x={49} y={89} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={49} y={94} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={46} y={106} width={49} height={18} rx={1} fill='#262626' />
        <rect x={49} y={109} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={49} y={114} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={101} y={21} width={19} height={2} rx={1} fill='#363636' />
        <rect x={101} y={26} width={49} height={18} rx={1} fill='#262626' />
        <rect x={104} y={29} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={104} y={34} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={101} y={46} width={49} height={18} rx={1} fill='#262626' />
        <rect x={104} y={49} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={104} y={54} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={101} y={66} width={49} height={18} rx={1} fill='#262626' />
        <rect x={104} y={69} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={104} y={74} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={101} y={86} width={49} height={18} rx={1} fill='#262626' />
        <rect x={104} y={89} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={104} y={94} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={101} y={106} width={49} height={18} rx={1} fill='#262626' />
        <rect x={104} y={109} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={104} y={114} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={156} y={21} width={19} height={2} rx={1} fill='#363636' />
        <rect x={156} y={26} width={49} height={18} rx={1} fill='#262626' />
        <rect x={159} y={29} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={159} y={34} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={156} y={46} width={49} height={18} rx={1} fill='#262626' />
        <rect x={159} y={49} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={159} y={54} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={156} y={66} width={49} height={18} rx={1} fill='#262626' />
        <rect x={159} y={69} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={159} y={74} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={156} y={86} width={49} height={18} rx={1} fill='#262626' />
        <rect x={159} y={89} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={159} y={94} width={14} height={1} rx={0.5} fill='#363636' />
        <rect x={156} y={106} width={49} height={18} rx={1} fill='#262626' />
        <rect x={159} y={109} width={22} height={3} rx={1.5} fill='#363636' />
        <rect x={159} y={114} width={14} height={1} rx={0.5} fill='#363636' />
        <path
          fill='#fff'
          fillOpacity={0.5}
          d='M-3 1h212v142H-3z'
          style={{
            mixBlendMode: 'overlay',
          }}
        />
      </g>
      <rect
        x={0.5}
        y={0.5}
        width={203}
        height={135}
        rx={7.5}
        stroke='#2B2B2B'
      />
      <defs>
        <linearGradient
          id='d'
          x1={70.5}
          y1={83}
          x2={70.5}
          y2={125}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1F1F1F' stopOpacity={0} />
          <stop offset={1} stopColor='#1F1F1F' />
        </linearGradient>
        <linearGradient
          id='e'
          x1={125.5}
          y1={83}
          x2={125.5}
          y2={125}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1F1F1F' stopOpacity={0} />
          <stop offset={1} stopColor='#1F1F1F' />
        </linearGradient>
        <linearGradient
          id='f'
          x1={180.5}
          y1={83}
          x2={180.5}
          y2={125}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1F1F1F' stopOpacity={0} />
          <stop offset={1} stopColor='#1F1F1F' />
        </linearGradient>
        <clipPath id='a'>
          <rect width={204} height={136} rx={8} fill='#fff' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function LightThemeSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={204}
      height={126}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#a)'>
        <rect width={204} height={136} rx={8} fill='#F1F1F2' />
        {/* <mask id='b' fill='#fff'>
          <path d='M1 15h39v121H1V15Z' />
        </mask> */}
        <path d='M1 15h39v121H1V15Z' fill='#fff' />
        <rect x={6} y={22} width={27} height={3} rx={1.5} fill='#E9E9EB' />
        <rect x={6} y={29} width={27} height={3} rx={1.5} fill='#E9E9EB' />
        <rect x={6} y={36} width={27} height={3} rx={1.5} fill='#E9E9EB' />
        <rect x={6} y={43} width={27} height={3} rx={1.5} fill='#E9E9EB' />
        <rect x={6} y={50} width={27} height={3} rx={1.5} fill='#E9E9EB' />
        <rect x={6} y={123} width={29} height={7} rx={3} fill='#E9E9EB' />
        <path d='M39 15v121h2V15h-2Z' fill='#F1F1F1' mask='url(#b)' />
        <mask id='c' fill='#fff'>
          <path d='M1 0h205v15H1V0Z' />
        </mask>
        <path d='M1 0h205v15H1V0Z' fill='#fff' />
        <circle cx={9.5} cy={7.5} r={3.5} fill='#E9E9EB' />
        <path d='M206 14H1v2h205v-2Z' fill='#F1F1F1' mask='url(#c)' />
        <rect x={46} y={21} width={19} height={2} rx={1} fill='#DBDBDD' />
        <rect x={46} y={26} width={49} height={18} rx={1} fill='#fff' />
        <rect x={49} y={29} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={49} y={34} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={46} y={46} width={49} height={18} rx={1} fill='#fff' />
        <rect x={49} y={49} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={49} y={54} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={46} y={66} width={49} height={18} rx={1} fill='#fff' />
        <rect x={49} y={69} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={49} y={74} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={46} y={86} width={49} height={18} rx={1} fill='#fff' />
        <rect x={49} y={89} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={49} y={94} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={46} y={106} width={49} height={18} rx={1} fill='#fff' />
        <rect x={49} y={109} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={49} y={114} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={101} y={21} width={19} height={2} rx={1} fill='#DBDBDD' />
        <rect x={101} y={26} width={49} height={18} rx={1} fill='#fff' />
        <rect x={104} y={29} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={104} y={34} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={101} y={46} width={49} height={18} rx={1} fill='#fff' />
        <rect x={104} y={49} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={104} y={54} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={101} y={66} width={49} height={18} rx={1} fill='#fff' />
        <rect x={104} y={69} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={104} y={74} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={101} y={86} width={49} height={18} rx={1} fill='#fff' />
        <rect x={104} y={89} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={104} y={94} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={101} y={106} width={49} height={18} rx={1} fill='#fff' />
        <rect x={104} y={109} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={104} y={114} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={156} y={21} width={19} height={2} rx={1} fill='#DBDBDD' />
        <rect x={156} y={26} width={49} height={18} rx={1} fill='#fff' />
        <rect x={159} y={29} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={159} y={34} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={156} y={46} width={49} height={18} rx={1} fill='#fff' />
        <rect x={159} y={49} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={159} y={54} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={156} y={66} width={49} height={18} rx={1} fill='#fff' />
        <rect x={159} y={69} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={159} y={74} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={156} y={86} width={49} height={18} rx={1} fill='#fff' />
        <rect x={159} y={89} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={159} y={94} width={14} height={1} rx={0.5} fill='#DBDBDD' />
        <rect x={156} y={106} width={49} height={18} rx={1} fill='#fff' />
        <rect x={159} y={109} width={22} height={3} rx={1.5} fill='#A2A2AC' />
        <rect x={159} y={114} width={14} height={1} rx={0.5} fill='#DBDBDD' />
      </g>
      <rect
        x={0.5}
        y={0.5}
        width={203}
        height={135}
        rx={7.5}
        stroke='#E9E9EB'
      />
      <defs>
        <linearGradient
          id='d'
          x1={70.5}
          y1={83}
          x2={70.5}
          y2={125}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F3F3F4' stopOpacity={0} />
          <stop offset={1} stopColor='#F3F3F4' />
        </linearGradient>
        <linearGradient
          id='e'
          x1={125.5}
          y1={83}
          x2={125.5}
          y2={125}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F3F3F4' stopOpacity={0} />
          <stop offset={1} stopColor='#F3F3F4' />
        </linearGradient>
        <linearGradient
          id='f'
          x1={180.5}
          y1={83}
          x2={180.5}
          y2={125}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F3F3F4' stopOpacity={0} />
          <stop offset={1} stopColor='#F3F3F4' />
        </linearGradient>
        <clipPath id='a'>
          <rect width={204} height={136} rx={8} fill='#fff' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function SystemSettingsSvgs() {
  return (
    <div className='relative h-full'>
      <div className='bg-dark-900 absolute inset-0 flex h-full w-full'>
        <div className='mx-auto translate-y-4'>
          <DarkThemeSvg />
        </div>
      </div>

      <div className='bg-light-500 absolute top-0 bottom-0 right-0 w-1/2 overflow-hidden'>
        <div className='absolute inset-0 flex -translate-x-3/4'>
          <div className='mx-auto translate-y-4'>
            <LightThemeSvg />
          </div>
        </div>
      </div>
    </div>
  )
}
