import type { FlowTemplateStage } from '@motion/ui-logic/pm/project'

import { updateTextWithVariable } from './update-text-with-variable'

export type TaskTextVariableUpdater = (args: {
  wrappedKey: string
  operation: 'prepend' | 'append' | 'replace'
  replacementKey?: string
  stageIndex?: number
  taskIndex?: number
}) => void

export const getTaskNameVariableUpdater = (
  stages: FlowTemplateStage[],
  setName: (
    name: string,
    currentStageIndex: number,
    currentTaskIndex: number
  ) => void
): TaskTextVariableUpdater => {
  return ({ wrappedKey, operation, replacementKey, stageIndex, taskIndex }) => {
    for (const [currentStageIndex, stage] of stages.entries()) {
      if (stageIndex != null && currentStageIndex !== stageIndex) continue
      for (const [currentTaskIndex, task] of stage.tasks.entries()) {
        if (taskIndex != null && currentTaskIndex !== taskIndex) continue

        const name = updateTextWithVariable(
          task.name,
          operation,
          wrappedKey,
          replacementKey
        )

        if (name !== task.name) {
          setName(name, currentStageIndex, currentTaskIndex)
        }
      }
    }
  }
}
