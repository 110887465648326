import { isStageActive } from '@motion/ui-logic/pm/project'
import { type StageDefinitionSchema } from '@motion/zod/client'

import { type FC, type PropsWithChildren } from 'react'

import { GroupHeaderWithFieldName } from './common'

import { useProjectInTree } from '../hooks'

export const StageGroupHeaderWithFieldName: FC<
  PropsWithChildren<{
    stage: StageDefinitionSchema | null
  }>
> = ({ stage, children }) => {
  const project = useProjectInTree()

  const isActiveStage = isStageActive(project, stage?.id)

  return (
    <GroupHeaderWithFieldName
      fieldName={isActiveStage ? 'Active Stage' : 'Stage'}
    >
      {children}
    </GroupHeaderWithFieldName>
  )
}
