import { type ProjectSchema, type TaskSchema } from '@motion/rpc-types'
import { type Group } from '@motion/utils/array'

import { type BulkOpsItem } from './types'

import { type BulkOpsTarget } from '../bulk-ops'

export const isBulkOpsTaskItem = (
  item: BulkOpsItem,
  target: BulkOpsTarget
): item is TaskSchema => target === 'tasks'

export const isBulkOpsProjectItem = (
  item: BulkOpsItem,
  target: BulkOpsTarget
): item is ProjectSchema => target === 'projects'

export const isBulkOpsTaskGroups = (
  groups: Group<BulkOpsItem, string>[],
  target: BulkOpsTarget
): groups is Group<TaskSchema, string>[] => target === 'tasks'

export const isBulkOpsProjectGroups = (
  groups: Group<BulkOpsItem, string>[],
  target: BulkOpsTarget
): groups is Group<ProjectSchema, string>[] => target === 'projects'
