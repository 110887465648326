import { StageAdjusterStrategy } from '@motion/shared/flows'
import { Tooltip } from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { isEnabledStage, stageAdjusterFn } from '@motion/ui-logic/pm/project'

import { type ReactNode } from 'react'
import { useController } from 'react-hook-form'

import { useSetupProjectForm } from '../../../hooks'

export type ControlledSkipStageCheckboxProps = {
  index: number
  valuePath: `stageDueDates.${number}.skipped`
  label: NonNullable<ReactNode>
}

export function ControlledSkipStageCheckbox({
  index,
  valuePath,
  label,
}: ControlledSkipStageCheckboxProps) {
  const {
    form: { control, watch, setValue, getValues },
  } = useSetupProjectForm()

  const stages = watch('stageDueDates')

  const { field } = useController({
    name: valuePath,
    control,
  })

  const isLastRemaining =
    stages.filter(isEnabledStage).length === 1 && !field.value

  return (
    <Tooltip
      content={
        isLastRemaining ? 'At least one stage must be active' : undefined
      }
      asChild
    >
      <Checkbox
        label={label}
        checked={!field.value}
        onChange={(value) => {
          if (value == null) return
          stageAdjusterFn({
            params: {
              startDate: getValues('startDate'),
              dueDate: getValues('dueDate'),
              stageDueDates: getValues('stageDueDates'),
            },
            onAction: (adjuster) => {
              if (value) return
              adjuster.prepareStageResolve({
                strategy: StageAdjusterStrategy.SHRINK,
                stageDefinitionId: stages[index]?.stageDefinitionId,
                status: 'cancel',
              })
            },
            onResult: ({ startDate, dueDate, stageDueDates }) => {
              if (stageDueDates[index]) {
                stageDueDates[index].skipped = !value
              }
              setValue('startDate', startDate)
              setValue('stageDueDates', stageDueDates)
              setValue('dueDate', dueDate)
              field.onChange(!value)
            },
          })
        }}
        disabled={isLastRemaining}
      />
    </Tooltip>
  )
}
