import { TextField } from '@motion/ui/forms'

import { ModalFieldButton } from '~/areas/task-project/components'
import { getTextFieldRules } from '~/global/rules'
import { useController } from 'react-hook-form'

import { useFlowTemplateForm } from '../hooks'

export const ControlledNameField = () => {
  const { form } = useFlowTemplateForm()
  const { control } = form

  const { field } = useController({
    name: 'name',
    control,
    rules: getTextFieldRules('Template name'),
  })

  return (
    <ModalFieldButton label='Template name'>
      <TextField
        ref={field.ref}
        placeholder='Untitled'
        label='Template name'
        labelHidden
        size='small'
        value={field.value}
        onChange={(value) => field.onChange(value)}
        variant='minimal'
      />
    </ModalFieldButton>
  )
}
