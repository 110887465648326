import { type Model } from '@motion/rpc-cache'
import { type AllModelsSchema } from '@motion/rpc-types'
import { type Group } from '@motion/utils/array'

import { useMemo } from 'react'

import { useBulkOpsGroups, useBulkOpsState } from '../contexts'

type ValidationFnParams<T extends keyof AllModelsSchema> = {
  target: T
  groups: Group<Model<T>, string>[]
}

type ValidationFn = (arg0: ValidationFnParams<'tasks' | 'projects'>) => {
  isValid: boolean
  errMsg: string
}

/**
 * Hook to check if a field is enabled for bulk operations
 * @param validations - List of validations to check all groups against
 */
export function useBulkFieldValidation(validations: ValidationFn[]) {
  const { target } = useBulkOpsState()
  const { workspaceGroups } = useBulkOpsGroups()

  return useMemo(() => {
    // Go through all validations and return the first error
    for (const validation of validations) {
      const result = validation({
        target,
        groups: workspaceGroups,
      })

      if (!result.isValid) {
        return { isValid: false, errMsg: result.errMsg }
      }
    }

    return {
      isValid: true,
    }
  }, [target, workspaceGroups, validations])
}
