import { type ForwardedRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { BaseButton } from '../BaseButton'
import { type ButtonProps } from '../ButtonDefaults'

/**
 * @deprecated Use `<Button>` from @motion/ui
 */
export const SecondaryButton = forwardRef(
  (
    props: Omit<ButtonProps & JSX.IntrinsicElements['button'], 'ref'>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { className, ...theirProps } = props

    const lightClasses =
      'border-light-600 enabled:hover:border-light-800 enabled:focus:outline-none enabled:focus:outline-offset-0 enabled:focus-visible:outline-primary-200 enabled:active:outline-primary-200 bg-light-100 text-dark-1300 '
    const darkClasses =
      'dark:bg-dark-1000 dark:enabled:hover:bg-dark-900 dark:enabled:hover:border-dark-700 dark:enabled:focus:outline-none dark:enabled:focus:outline-offset-0 dark:enabled:focus-visible:outline-dark-400 dark:enabled:active:outline-dark-400 dark:border-dark-800 dark:text-white'
    const shortCutContainerClasses = 'dark:bg-dark-100/[0.15] bg-light-400'
    const shortCutClasses = 'dark:text-inherit text-light-1100'

    return (
      <BaseButton
        ref={ref}
        className={twMerge(lightClasses, darkClasses, className)}
        {...theirProps}
        shortCutContainerClasses={shortCutContainerClasses}
        shortCutClasses={shortCutClasses}
      />
    )
  }
)
SecondaryButton.displayName = 'SecondaryButton'
