import { useCurrentUserOrNull } from '@motion/web-common/auth'

import { useCurrentUser } from '../rpc/user'

type IdWithPicture = {
  id: string
  picture?: string | null
}

export const useProfilePictureUrl = (user: IdWithPicture) => {
  const firebaseUser = useCurrentUserOrNull()

  const isCurrentUser = Boolean(firebaseUser && firebaseUser.uid === user.id)

  const { data: motionUser } = useCurrentUser(undefined, {
    enabled: isCurrentUser,
  })

  return isCurrentUser
    ? (motionUser?.picture ?? user.picture)
    : (motionUser?.picture ?? null)
}
