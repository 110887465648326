import { MenuAlt2Solid } from '@motion/icons'
import {
  RichTextContent,
  RichTextToolbar,
  useRichTextEditor,
} from '@motion/ui/rte'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useController } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import { useEventModalState } from '../contexts'
import { useEventForm } from '../hooks'

export const ControlledEventDescriptionField = () => {
  const { isLoading, isReadOnly } = useEventModalState()
  const modalApi = useModalApi()
  const { form, hiddenFields } = useEventForm()
  const { control } = form

  const { field } = useController({
    name: 'description',
    control,
  })

  const editor = useRichTextEditor({
    className: isReadOnly ? '' : 'min-h-40',
    placeholder: 'Enter message',
    value: field.value,
    disableImageUpload: true,
    onChange(v) {
      field.onChange(v)
    },
    disabled: isLoading || isReadOnly,
  })

  if (isLoading || hiddenFields.has('description')) return null

  return (
    <div
      className={twMerge(
        !isReadOnly &&
          'border border-field-border-default bg-field-bg-default rounded'
      )}
    >
      {!isReadOnly && (
        <div className='bg-semantic-neutral-bg-disabled rounded-t p-1'>
          <RichTextToolbar
            editor={editor}
            disableImageUpload
            size='small'
            onLinkClick={async (initialLink: string = '') => {
              const response = await modalApi.prompt('link-modal', {
                initialLink,
              })

              if (response !== ModalDismissed) {
                return response
              }
              return null
            }}
          />
        </div>
      )}
      <div className='flex gap-2 p-2'>
        {isReadOnly && (
          <MenuAlt2Solid className='shrink-0 size-3 text-semantic-neutral-icon-default mt-0.5' />
        )}
        <RichTextContent
          editor={editor}
          readOnly={isReadOnly}
          className='flex-grow'
        />
      </div>
    </div>
  )
}
