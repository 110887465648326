import { XSolid } from '@motion/icons'
import { type EmailAccount, type UpdateCalendarDto } from '@motion/rpc/types'
import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
} from '@motion/ui/base'
import { Checkbox } from '@motion/ui/forms'
import { calendarProviderTypeToName, templateStr } from '@motion/ui-logic'
import { type CalendarSchema } from '@motion/zod/client'

import { useRefetchEventsOnAddCalendar } from '~/areas/calendar/hooks'
import { useUpdateCalendars, useUpdatePrimaryCalendar } from '~/global/rpc/v2'
import { useEffect, useState } from 'react'

import { useStateObject } from '../../../hooks/use-state-object'

type Props = {
  calendars: CalendarSchema[]
  emailAccount: EmailAccount
  onComplete: () => void
}

export const EmailAccountAddedModal = (props: Props) => {
  const updatePrimaryCalendar = useUpdatePrimaryCalendar()
  const { mutateAsync: updateCalendars } = useUpdateCalendars()
  const refetchEventsOnAddCalendar = useRefetchEventsOnAddCalendar()

  const { calendars, emailAccount, onComplete } = props
  const [primaryCalendar, setPrimaryCalendar] = useState<CalendarSchema | null>(
    () => {
      const primary = calendars.find((c) => c.isPrimary)
      if (primary) {
        return primary
      }

      if (emailAccount.providerType === 'APPLE') {
        return calendars[0]
      }

      return null
    }
  )

  // Tracks calendars that have been checked by the user.
  const [checkedItems, setCheckedItems] = useStateObject<
    Record<string, boolean>
  >({})

  const [isSaving, setIsSaving] = useState(false)

  const onChecked = (
    calendar: CalendarSchema,
    checked: boolean | 'indeterminate'
  ) => {
    setCheckedItems(
      calendar.id,
      checked === 'indeterminate' ? false : Boolean(checked)
    )
  }

  const onSave = async () => {
    if (isSaving) return
    setIsSaving(true)

    try {
      const dtos: UpdateCalendarDto[] = []
      for (const [id, checked] of Object.entries(checkedItems)) {
        if (!checked) continue

        const calendar = calendars.find((c) => c.id === id)
        if (!calendar) continue

        dtos.push({
          id,
          emailAccountId: calendar.emailAccountId,
          isInMyCalendars: true,
        })
      }

      await updateCalendars({ calendars: dtos })

      // Check previous primary calendar, if its not the same as the new one, update it
      const previousPrimaryCalendar = calendars.find((c) => c.isPrimary)

      if (
        primaryCalendar &&
        previousPrimaryCalendar &&
        previousPrimaryCalendar.id !== primaryCalendar.id
      ) {
        await updatePrimaryCalendar.mutateAsync({
          id: primaryCalendar.id,
        })
      }

      refetchEventsOnAddCalendar()
      onComplete()
    } catch (e) {
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(
    function setDefaultCheckedItems() {
      for (const calendar of calendars) {
        if (calendar.isPrimary || calendar.isInMyCalendars) {
          setCheckedItems(calendar.id, true)
        }
      }
    },
    [calendars, setCheckedItems]
  )

  return (
    <Modal onClose={onComplete} visible>
      <div className='flex flex-col gap-3'>
        <div className='dark:text-dark-100 text-light-1200 flex justify-between border-b px-4 py-3'>
          <p className='text-base font-semibold text-inherit dark:text-inherit'>
            {templateStr('Set up {{email}} ({{providerType}})', {
              email: emailAccount.email,
              providerType:
                calendarProviderTypeToName[emailAccount.providerType],
            })}
          </p>
          <IconButton
            icon={XSolid}
            onClick={onComplete}
            sentiment='neutral'
            size='small'
            variant='muted'
            aria-label='Close email account setup modal'
          />
        </div>

        <div className='px-4'>
          <p className='font-semibold text-sm text-semantic-neutral-text-default mb-2'>
            Select the calendars that belong to you
          </p>
          <p className='text-semantic-neutral-text-subtle'>
            Choose the calendars that you want to check for conflicts when
            scheduling tasks.
          </p>

          <div className='flex flex-col space-y-2 mt-6'>
            {calendars.map((c) => (
              <Checkbox
                key={c.id}
                checked={Boolean(checkedItems[c.id])}
                onChange={(checked) => onChecked(c, checked)}
                disabled={isSaving || primaryCalendar?.id === c.id}
                label={c.title}
              />
            ))}
          </div>

          {emailAccount.providerType === 'APPLE' && (
            <div className='fex flex-col space-y-2 mt-6'>
              <p className='font-semibold text-sm text-semantic-neutral-text-default mb-2'>
                Create events/tasks on:
              </p>
              <PopoverTrigger
                placement='bottom-start'
                renderPopover={({ close }) => (
                  <SearchableList
                    items={calendars}
                    computeKey={(item) => item.id}
                    computeSearchValue={(item) => item.title}
                    computeSelected={(item) => item.id === primaryCalendar?.id}
                    onSelect={(item) => {
                      if (primaryCalendar && primaryCalendar !== item) {
                        onChecked(primaryCalendar, false)
                      }
                      setPrimaryCalendar(item)
                      onChecked(item, true)
                      close()
                    }}
                    renderItem={(item) => item.title}
                  />
                )}
              >
                <PopoverButton>{primaryCalendar?.title}</PopoverButton>
              </PopoverTrigger>
            </div>
          )}
        </div>

        <div className='flex justify-end mt-3.5 py-3 px-4 border-t'>
          <ButtonGroup>
            <Button sentiment='primary' onClick={onSave} disabled={isSaving}>
              Next
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Modal>
  )
}
