import { type COLOR } from '@motion/shared/common'
import { SearchableList } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema, type WorkspaceSchema } from '@motion/zod/client'

import { useProjectAnalytics } from '~/areas/project/hooks'
import { WorkspaceBadge } from '~/global/components/badges'
import { useAllWorkspaces } from '~/global/hooks'
import { useUpdateProject } from '~/global/rpc/v2'

type ProjectActionMenuProps = {
  onClose(): void
  project: ProjectSchema
}

export const MoveProjectToPopover = ({
  onClose,
  project,
}: ProjectActionMenuProps) => {
  const workspaces = useAllWorkspaces()
  const modalApi = useModalApi()
  const { mutateAsync: updateProject } = useUpdateProject()
  const getProjectAnalyticContext = useProjectAnalytics()

  const workspacesToShow = workspaces.filter(
    (workspace) => workspace.id !== project.workspaceId
  )

  function openConfirmationModal(workspace: WorkspaceSchema) {
    void modalApi.prompt('confirm', {
      analytics: {
        name: 'move-project-workspace',
      },
      confirmButtonText: 'Move project',
      description:
        'Some statuses, labels and custom fields may be reset if they aren’t available in the new workspace.',
      title: `Are you sure you want to move this project ${project.name} to the workspace ${workspace.name}?`,
      onValue: async (confirmed) => {
        if (confirmed && project.id) {
          recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_PROJECT_CONTEXT', {
            ...getProjectAnalyticContext(project.id),
          })

          await updateProject({
            ...project,
            description: project.description ?? undefined,
            projectId: project.id,
            workspaceId: workspace.id,
            color: project.color as COLOR,
          })
        }
      },
    })
  }

  return (
    <SearchableList
      computeKey={(item) => item.id}
      computeSearchValue={(item) => item.name}
      computeSelected={() => false}
      inputProps={{
        placeholder: 'Choose workspace',
      }}
      items={workspacesToShow}
      renderItem={(item) => (
        <div className='flex gap-2 items-center'>
          <WorkspaceBadge />
          {item.name}
        </div>
      )}
      onSelect={(item) => {
        openConfirmationModal(item)
        onClose()
      }}
    />
  )
}
