import { showToast } from '@motion/ui/base'
import { getOptionValidationRules } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type UpdateOptionsSchema } from '@motion/zod/client'

import { type SelectOption } from '~/areas/custom-fields/components'
import { useCustomFieldById } from '~/areas/custom-fields/hooks'
import { type WorkspaceCustomField } from '~/areas/custom-fields/types'
import { useUpdateCustomField } from '~/areas/project-management/hooks/custom-fields/rpc'

import { type ModalTriggerComponentProps } from '../modal-trigger'
import { NewOptionModal } from '../new-option-modal'

declare module '@motion/web-common/modals/definitions' {
  interface ModalDefinitions {
    'add-custom-field-select-option': ConnectedNewCustomFieldSelectOptionModalProps &
      PromptCallbacks<SelectOption>
  }
}

export type ConnectedNewCustomFieldSelectOptionModalProps = {
  workspaceId: string
  customFieldId: string
  name: string
}

export function ConnectedNewCustomFieldSelectOptionModal({
  workspaceId,
  customFieldId,
  name,
  close,
}: ModalTriggerComponentProps<'add-custom-field-select-option'>) {
  const { mutateAsync: updateCustomField } = useUpdateCustomField()
  const workspaceCustomField = useCustomFieldById(
    customFieldId
    // This is used also for select
  ) as Extract<WorkspaceCustomField, { type: 'multiSelect' }> | undefined

  const addOption = async (value: string, color: string) => {
    try {
      // Should not happen
      if (!workspaceCustomField) {
        throw new Error('Custom field not found')
      }

      const newValue = value.trim()
      const rules = getOptionValidationRules(workspaceCustomField.type)

      if (newValue.length > rules.maxLength) {
        showToast(
          'error',
          `Option too long (max length is ${rules.maxLength} characters)`
        )
        return
      } else if (
        workspaceCustomField.metadata.options.length >= rules.maxOptions
      ) {
        showToast('error', `Too many options (max is ${rules.maxOptions})`)
        return
      }

      const newOption: UpdateOptionsSchema['options'][number] = {
        color,
        value: newValue,
      }

      recordAnalyticsEvent('CUSTOM_FIELD_CREATE_NEW_OPTION', {
        name,
        type: workspaceCustomField.type,
        option: newOption,
      })
      const {
        metadata: { options: currentOptions },
      } = workspaceCustomField

      if (currentOptions.some((option) => option.value === value)) {
        showToast('error', 'Option already exists')

        return
      }

      const newOptions: UpdateOptionsSchema['options'] = [
        ...currentOptions,
        newOption,
      ]
      const res = await updateCustomField({
        workspaceId,
        customFieldId,
        type: workspaceCustomField.type,
        name,
        metadata: {
          // PATCH semantics require full desired array to be sent
          // https://datatracker.ietf.org/doc/html/rfc7386
          options: newOptions,
        },
      })

      const fieldFromServer = res.models.customFields[workspaceCustomField.id]
      if (
        fieldFromServer == null ||
        !('metadata' in fieldFromServer) ||
        !('options' in fieldFromServer.metadata)
      ) {
        throw new Error('Return from update custom field is not valid')
      }

      const newOptionFromServer = fieldFromServer.metadata.options.find(
        (option) => !currentOptions.some((o) => o.id === option.id)
      )
      if (newOptionFromServer == null) {
        throw new Error('New option not found')
      }

      close(newOptionFromServer)
    } catch (error) {
      showToast('error', 'Error updating custom field')
      throw error
    }
  }

  return (
    <NewOptionModal
      title='Add option'
      placeholder='Enter option'
      onClose={close}
      onSave={addOption}
    />
  )
}
