import { API } from '@motion/rpc'
import {
  type Calendar,
  type Contact,
  type CreateCalendarDto,
  type UpdateCalendarDto,
} from '@motion/rpc/types'
import { findCalendarForContact } from '@motion/ui-logic'
import { client } from '@motion/web-common/rpc'
import { type CalendarSchema } from '@motion/zod/client'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { selectSelectedTeammateContacts } from './calendar-list-selectors'
import { setSelectedTeammateContacts } from './calendar-list-slice'

import { type CalendarService } from '../../services/calendarService'
import { getProxy } from '../backgroundProxy'
import { selectEmailAccounts } from '../email-accounts/email-accounts-slice'
import { type RootState } from '../store'

const THUNK_PREFIX = 'calendar-list'

const calendarService = getProxy('CalendarService')

/**
 * Updates fields for a calendar. This will only patch fields that have been
 * provided by the user.
 */
export const updateCalendar = createAsyncThunk<
  Awaited<ReturnType<CalendarService['update']>>,
  Parameters<CalendarService['update']>[0],
  { state: RootState }
>(
  `${THUNK_PREFIX}/updateCalendar`,
  async (data: Parameters<CalendarService['update']>[0]) => {
    const res = await calendarService.update(data)
    return res
  }
)

/**
 * Takes contacts that have been searched via the "Meet with teammates" search
 * box and updates redux/backend state accordingly:
 * - Contacts selected should be 'enabled' so that their calendar events are
 *   visible on the calendar
 * -
 */
export const updateSelectedTeammateContacts = createAsyncThunk<
  Awaited<Promise<UpdateCalendarDto[]>>,
  { contacts: Contact[]; calendars: CalendarSchema[] },
  { state: RootState }
>(
  `${THUNK_PREFIX}/updateSelectedTeammateContacts`,
  async ({ contacts, calendars }, thunkAPI) => {
    const state = thunkAPI.getState()

    const emailAccounts = selectEmailAccounts(state)

    thunkAPI.dispatch(setSelectedTeammateContacts(contacts))

    const calendarChanges = new Map<string, UpdateCalendarDto>()

    // Uncheck all previous contacts - when we iterate through the current
    // contacts later, we will remove the update of existing contacts where
    // applicable.
    for (const contact of selectSelectedTeammateContacts(state)) {
      const calendar = findCalendarForContact(contact, emailAccounts, calendars)
      if (!calendar) {
        continue
      }

      if (calendar.isEnabled) {
        calendarChanges.set(calendar.id, {
          id: calendar.id,
          emailAccountId: calendar.emailAccountId,
          isEnabled: false,
        })
      }
    }

    const promises: Promise<Calendar>[] = []

    // For each contact, check whether we need to create a calendar entry.
    for (const contact of contacts) {
      const calendar = findCalendarForContact(contact, emailAccounts, calendars)
      if (calendar) {
        // Contact may have been part of the previous contacts and isEnabled
        // set to false - remove this change
        if (calendarChanges.has(calendar.id)) {
          calendarChanges.delete(calendar.id)
        }

        // Make sure calendar is enabled
        if (!calendar.isEnabled) {
          calendarChanges.set(calendar.id, {
            id: calendar.id,
            emailAccountId: calendar.emailAccountId,
            isEnabled: true,
          })
        }

        continue
      }

      // Don't try to create the calendar again if already creating
      const loadingState =
        state.calendarList.createCalendarLoadingState[contact.email]
      if (loadingState && ['loading', 'refetching'].includes(loadingState)) {
        continue
      }

      // Create the new calendar on the backend
      const emailAccount = emailAccounts.find(
        (e) => e.email === contact.account
      )
      if (!emailAccount) {
        continue
      }

      promises.push(
        thunkAPI
          .dispatch(
            createCalendar({
              emailAccountId: emailAccount.id,
              providerId: contact.email,
              title: contact.email,
              isEnabled: true,
            })
          )
          .unwrap()
      )
    }

    await Promise.all(promises)

    if (promises.length > 0) {
      client.invalidateQueries(API.scheduledEntities.queryKeys.root)
    }

    return [...calendarChanges].map(([, value]) => value)
  }
)

export const createCalendar = createAsyncThunk(
  `${THUNK_PREFIX}/createCalendar`,
  async (data: CreateCalendarDto) => {
    return await calendarService.create(data)
  }
)
