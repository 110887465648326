import { ChevronDownSolid, PencilSolid, PlusSolid } from '@motion/icons'
import { useSharedState } from '@motion/react-core/shared-state'
import {
  ButtonGroup,
  IconButton,
  PopoverTrigger,
  ResponsiveTabList,
  Tooltip,
} from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  byProperty,
  byValue,
  cascade,
  Compare,
  ordered,
} from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useModalApi } from '@motion/web-common/modals'

import { useCachedItem } from '~/global/cache'
import { AppWorkspaceContext } from '~/global/contexts'
import { type ReactNode, useCallback, useMemo } from 'react'

import { ConnectedViewItem, ViewDropdownContent } from './components'
import { useReorderViews } from './hooks'
import {
  getEditTooltipFromPage,
  getPageViewOrderId,
  isViewDisabled,
} from './utils'

import { usePageData } from '../../routes'
import { isDefaultView, useEffectiveView, useSelectedView } from '../../views'

export type ConnectedViewTabsProps = {
  children?: ReactNode
}

export const ConnectedViewTabs = ({ children }: ConnectedViewTabsProps) => {
  const [ctx] = useSharedState(AppWorkspaceContext)
  const modalApi = useModalApi()

  const [selected, selectView] = useSelectedView()
  const info = useEffectiveView()
  const data = usePageData()

  const pageViewOrderId = getPageViewOrderId(data)
  const pageViewSettingsOrder = useCachedItem(
    'pageViewSettings',
    pageViewOrderId
  )
  const updateViewsOrder = useReorderViews(pageViewOrderId)

  const handleAddView = useCallback(() => {
    recordAnalyticsEvent('VIEW_ADD_NEW', { section: 'tab' })

    void modalApi.prompt('create-view-v2', {
      currentView: info.view,
    })
  }, [info.view, modalApi])

  const sortedViews = useMemo(
    () =>
      ctx.views.all.toSorted(
        cascade(
          byProperty('id', ordered(pageViewSettingsOrder?.viewOrder ?? [])),
          byValue((view) => isDefaultView(view), ordered([true, false])),
          byProperty('name', Compare.string)
        )
      ),
    [ctx.views.all, pageViewSettingsOrder?.viewOrder]
  )

  const renderViewDropdown = useCallback(
    (close: () => void) => {
      return (
        <ViewDropdownContent
          close={close}
          views={sortedViews}
          selectedView={selected}
          onSelect={(view) => selectView(view)}
        />
      )
    },
    [selectView, selected, sortedViews]
  )

  const tabs = useMemo(() => {
    return sortedViews
      .map((view) => {
        const disabled = isViewDisabled(view, data.page)

        return {
          value: view.id,
          name: view.name,
          disabled,
          content: (
            <Tooltip
              asChild
              content={
                disabled
                  ? "Project views can't be viewed on Project pages"
                  : null
              }
            >
              <div className='bg-calendar-bg-default'>
                <ConnectedViewItem view={view} />
              </div>
            </Tooltip>
          ),
          onAction: () => {
            selectView(view)
          },
        }
      })
      .filter(Boolean)
  }, [data.page, selectView, sortedViews])

  const handleOrderChange = async (itemIds: string[]) => {
    recordAnalyticsEvent('VIEW_REORDER', { section: 'tab' })
    updateViewsOrder(itemIds)
  }

  return (
    <div className='px-4 border-b border-solid border-tab-border-default flex gap-2 items-center'>
      <div className='flex-1 h-9 overflow-hidden [&>[role="tablist"]]:h-full'>
        <ResponsiveTabList
          sortable
          onSortOrderChange={handleOrderChange}
          variant='minimal'
          activeValue={selected.id}
          items={tabs}
          renderDisclosureButtonContent={({ hiddenIndices }) => {
            return (
              <div className='flex gap-1 flex-nowrap pt-2.5 pb-2'>
                {templateStr('{{count}} more', {
                  count: hiddenIndices.length,
                })}
                <ChevronDownSolid className='size-4 shrink-0 text-semantic-neutral-icon-default' />
              </div>
            )
          }}
          renderDisclosurePanelContent={({ close }) =>
            renderViewDropdown(close)
          }
          renderCreateNewElement={() => (
            <ButtonGroup size='small'>
              <PopoverTrigger
                placement='bottom-end'
                offset={2.5}
                renderPopover={({ close }) => renderViewDropdown(close)}
              >
                <Tooltip
                  asChild
                  renderContent={() => getEditTooltipFromPage(data)}
                >
                  <IconButton
                    icon={PencilSolid}
                    size='small'
                    variant='outlined'
                    sentiment='neutral'
                    aria-label='Edit and reorder views'
                  />
                </Tooltip>
              </PopoverTrigger>

              <Tooltip asChild content='Create new view'>
                <IconButton
                  icon={PlusSolid}
                  size='small'
                  variant='outlined'
                  sentiment='neutral'
                  onClick={handleAddView}
                  aria-label='Create new view'
                />
              </Tooltip>
            </ButtonGroup>
          )}
        />
      </div>
      {children}
    </div>
  )
}
