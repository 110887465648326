import { Tooltip } from '@motion/ui/base'

export function LegacyGhostName({ name }: { name: string }) {
  return (
    <Tooltip asChild content='The task will be created when the stage begins'>
      <span className='text-xs truncate grow text-semantic-neutral-text-default'>
        {name}
      </span>
    </Tooltip>
  )
}
