import { ConnectedResetViewButton } from './connected-reset-view-button'
import { ConnectedViewButton } from './connected-select-view-button'
import { ConnectedSaveViewButton } from './save-changes-button'

export const ConnectectedViewSection = () => {
  return (
    <div className='flex gap-1'>
      <ConnectedViewButton />
      <ConnectedSaveViewButton />
      <ConnectedResetViewButton />
    </div>
  )
}
