import { useCreateAiFlows } from '~/global/rpc'
import { showErrorToast } from '~/global/toasts'
import { useCallback } from 'react'

import { type FlowsAIFormFields } from '../utils'

export function useSubmitFlowsAi() {
  const { mutateAsync: createAiFlows } = useCreateAiFlows()

  return useCallback(
    async (data: FlowsAIFormFields) => {
      try {
        return await createAiFlows(data)
      } catch (e) {
        if (e instanceof Error) {
          showErrorToast(e)

          throw new Error('Failed to submit AI flows', e)
        }
      }
    },
    [createAiFlows]
  )
}
