import { type IconButtonProps } from '@motion/ui/base'
import { type TextFieldProps } from '@motion/ui/forms'

export type Variant = 'default' | 'compact'

export const VARIANT_ICON_SIZE = {
  default: 24,
  compact: 16,
}

export const VARIANT_ICON_BUTTON_SIZE: Record<
  Variant,
  IconButtonProps['size']
> = {
  default: 'small',
  compact: 'xsmall',
}

export const VARIANT_TEXTFIELD_SIZE: Record<Variant, TextFieldProps['size']> = {
  default: 'small',
  compact: 'xsmall',
}
